import React from 'react'
import { getStyleConfig } from '../util'

export class Legend extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            list: []
        }
    }

    componentWillMount() {
        this.initList(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.initList(nextProps)
    }

    initList = (props) => {
        const { color } = props
        const { list } = this.state
        if (color && color.items.length) {
            this.setState({
                list: color.items.map((item) => {
                    const originItem = list.find((i) => i.name === item.name)
                    const originConfig = originItem && originItem.localConfig
                    const configValues = Object.entries(item.config.values).reduce((obj, [key, value]) => {
                        obj[key] = {
                            value,
                            visible: originConfig ? originConfig.values[key].visible : true
                        }
                        return obj
                    }, {})
                    return {
                        ...item,
                        localConfig: {
                            values: configValues
                        }
                    }
                })
            })
        } else {
            this.setState({
                list: []
            })
        }
    }

    legendSelect = (name, key) => () => {
        const { list } = this.state
        const selectedItem = list.find((i) => i.name === name)
        const visible = selectedItem.localConfig.values[key].visible
        selectedItem.localConfig.values[key].visible = !visible
        this.setState({ list })
        this.props.onLegendSelect(name, key)
    }

    render() {
        const { chartStyles } = this.props
        const { list } = this.state
        const { color: fontColor } = getStyleConfig(chartStyles).pivot

        const legendBoxes = list.map((i) => {
            const { values } = i.localConfig
            const listItems = Object.entries(values).map(([key, value]) => {
                return (
                    <li
                        key={key}
                        onClick={this.legendSelect(i.name, key)}
                        className={`${!value.visible ? 'disabled' : ''}`}
                        style={{
                            color: fontColor
                        }}
                    >
                        <span style={{ background: value.value }} />
                        {key}
                    </li>
                )
            })
            return (
                <div key={i.name} className='legendBox'>
                    <h4
                        style={{
                            color: fontColor
                        }}
                    >
                        {i.name}
                    </h4>
                    <ul className='list'>
                        {listItems}
                    </ul>
                </div>
            )
        })

        return (
            <div className={`legend ${list.length ? 'shown' : ''}`}>
                {legendBoxes}
            </div>
        )
    }
}

export default Legend
