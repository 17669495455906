import defaultEchartsTheme from './Chart/echarts_theme';
export const DEFAULT_SPLITER = '@davinci@';

export const FieldSortTypes = {
  Asc: 'asc',
  Desc: 'desc',
  Custom: 'custom',
};

export const SERIAL_STYLE = {
  'flex-start': 'left',
  center: 'center',
  'flex-end': 'right',
};

export const SortTypesToOrder = {
  asc: 'ascend',
  desc: 'descend',
};

export const PivotTypes = {
  PivotTable: 1,
  Line: 2,
  Bar: 3,
  Scatter: 4,
  Pie: 5,
};

export const OperatorTypes = {
  Equal: '=',
  NotEqual: '!=',
  Contain: 'like',
  NotContain: 'not like',
  Between: 'between',
  In: 'in',
  NotIn: 'not in',
  LessThan: '<',
  GreaterThan: '>',
  LessThanOrEqual: '<=',
  GreaterThanOrEqual: '>=',
};

export const FieldFormatTypes = {
  Default: 'default',
  Numeric: 'numeric',
  Currency: 'currency',
  Percentage: 'percentage',
  ScientificNotation: 'scientificNotation',
  Date: 'date',
  NPS: 'NPS',
  Custom: 'custom',
};

export const ReferenceType = {
  Line: 'line',
  Band: 'band',
};

export const ReferenceValueType = {
  Constant: 'constant',
  Average: 'average',
  Max: 'max',
  Min: 'min',
};

export const NumericUnit = {
  None: '无',
  TenThousand: '万',
  OneHundredMillion: '亿',
  Thousand: 'k',
  Million: 'M',
  Giga: 'G',
  Second: '秒',
  Millisecond: '毫秒',
  Minute: '分',
  Hour: '小时',
};

export const ViewModelTypes = {
  Category: 'category',
  Value: 'value',
};

export const DrillType = {
  UP: 'up',
  DOWN: 'down',
};

export const PIVOT_CHART_FONT_FAMILIES = [
  { name: '苹方', value: 'PingFang SC' },
  { name: '微软雅黑', value: 'Microsoft YaHei' },
  { name: '宋体', value: 'SimSun' },
  { name: '黑体', value: 'SimHei' },
  { name: 'Helvetica Neue', value: '"Helvetica Neue"' },
  { name: 'Helvetica', value: 'Helvetica' },
  { name: 'Arial', value: 'Arial' },
  { name: 'sans-serif', value: 'sans-serif' },
];

export const PIVOT_CHART_FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

export const ControlDefaultValueTypes = {
  Dynamic: 'dynamic',
  Fixed: 'fixed',
};
export const SQL_NUMBER_TYPES = [
  'BIT',
  'TINYINT',
  'SMALLINT',
  'MEDIUMINT',
  'INT',
  'INTEGER',
  'BIGINT',
  'FLOAT',
  'DOUBLE',
  'DOUBLE PRECISION',
  'REAL',
  'DECIMAL',
  'BIT',
  'SERIAL',
  'BOOL',
  'BOOLEAN',
  'DEC',
  'FIXED',
  'NUMBER',
  'NUMERIC',
  'UINT8',
  'UINT16',
  'UINT32',
  'UINT64',
  'INT8',
  'INT16',
  'INT32',
  'INT64',
  'FLOAT32',
  'FLOAT64',
  'DECIMAL32',
  'DECIMAL64',
  'DECIMAL128',
];
export const ControlTypes = {
  Select: 'select',
  Radio: 'radio',
  Date: 'date',
  DateRange: 'dateRange',
  InputText: 'inputText',
  NumberRange: 'numberRange',
  Slider: 'slider',
  TreeSelect: 'treeSelect',
};
export const ViewVariableValueTypes = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  Date: 'date',
  SqlExpression: 'sql',
};

/**
 * 图表类型
 *
 */
export const ChartTypes = {
  /**
   * 表格
   */
  Table: 1,

  /**
   * 折线图
   */
  Line: 2,

  /**
   * 条形图
   */
  Bar: 3,

  /**
   * 散点图
   */
  Scatter: 4,

  /**
   * 饼图
   */
  Pie: 5,

  /**
   * 地图
   */
  CMap: 7,

  /**
   * 平行坐标图
   */
  Parallel: 8,

  /**
   * 漏斗图
   */
  Funnel: 6,

  /**
   * 桑基图
   */
  Sankey: 9,

  /**
   * 雷达图
   */
  Radar: 10,

  /**
   * 词云图
   */
  WordCloud: 11,

  /**
   * 瀑布图
   */
  Waterfall: 12,

  /**
   * 翻牌器
   */
  Scorecard: 13,

  /**
   * 内嵌网页
   */
  Iframe: 14,

  /**
   * 富文本
   */
  RichText: 15,

  /**
   * 双Y轴
   */
  DoubleYAxis: 16,

  /**
   * 仪表盘
   */
  Gauge: 17,
};

export const DEFAULT_FONT_WEIGHT = 'normal';
export const DEFAULT_FONT_STYLE = 'normal';
export const DEFAULT_FONT_SIZE = '14px';
export const DEFAULT_FONT_FAMILY =
  '-apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif';

export const PIVOT_MAX_CONTENT_WIDTH = 200;
export const PIVOT_LINE_HEIGHT = 24;
export const PIVOT_TITLE_SIZE = 27;
export const PIVOT_CELL_PADDING = 6;
export const PIVOT_CELL_BORDER = 1;
export const PIVOT_BORDER = 1;
export const PIVOT_YAXIS_SIZE = 64;
export const PIVOT_XAXIS_SIZE = 50;
export const PIVOT_CHART_METRIC_AXIS_MIN_SIZE = 80;
export const PIVOT_LEGEND_ITEM_PADDING = 32;
export const PIVOT_DEFAULT_SCATTER_SIZE = 10;
export const PIVOT_DEFAULT_QUADRANT_SIZE = 20;
export const DEFAULT_CHART_FONT_SIZE = 14;
export const PIVOT_LEGEND_PADDING = 16;
export const PIVOT_CHART_ELEMENT_MIN_WIDTH = 24;
export const PIVOT_CANVAS_AXIS_SIZE_LIMIT = 8000;
export const PIVOT_XAXIS_ROTATE_LIMIT = 30;
export const PIVOT_XAXIS_TICK_SIZE = 12;
export const PIVOT_CHART_SPLIT_SIZE = 40;
export const PIVOT_CANVAS_POLAR_SIZE_LIMIT = 8000;
export const PIVOT_CANVAS_SIZE_LIMIT = 3000;
export const PIVOT_CHART_POINT_LIMIT = 100;
export const TABLE_PAGE_SIZES = [10, 20, 30, 40, 50, 100];
export const DEFAULT_TABLE_FIXED_WIDTH = 100;
export const PIVOT_DEFAULT_SCATTER_SIZE_TIMES = 4;

export const PIVOT_DEFAULT_AXIS_LINE_COLOR = '#D9D9D9';
export const PIVOT_DEFAULT_FONT_COLOR = '#050a13';
export const PIVOT_SECOND_FONT_COLOR = '#7a8493';
export const PIVOT_DEFAULT_HEADER_BACKGROUND_COLOR = '#f5f5f5';

export const DEFAULT_ECHARTS_THEME = defaultEchartsTheme().theme;

export const EmptyFontSetting = {
  fontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
  fontStyle: DEFAULT_FONT_STYLE,
  fontSize: DEFAULT_FONT_SIZE,
  fontWeight: DEFAULT_FONT_WEIGHT,
  fontColor: PIVOT_DEFAULT_FONT_COLOR,
};

export const EmptyStack = {
  on: false,
  percentage: false,
  group: [],
  sum: {
    show: false,
    font: { ...EmptyFontSetting },
  },
};

export const DefaultTableCellStyle = {
  fontSize: '12',
  fontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
  fontWeight: PIVOT_CHART_FONT_WEIGHTS[0],
  fontColor: PIVOT_DEFAULT_FONT_COLOR,
  fontStyle: DEFAULT_FONT_STYLE,
  // backgroundColor: 'transparent',
  justifyContent: 'flex-start',
  inflexible: false,
  width: DEFAULT_TABLE_FIXED_WIDTH,
};

export const TableConditionStyleTypes = {
  BackgroundColor: 'backgroundColor',
  TextColor: 'textColor',
  NumericBar: 'numericBar',
  Custom: 'custom',
};

export const CHART_LABEL_POSITIONS = [
  { name: '上', value: 'top' },
  { name: '左', value: 'left' },
  { name: '右', value: 'right' },
  { name: '下', value: 'bottom' },
  { name: '内', value: 'inside' },
  { name: '内左', value: 'insideLeft' },
  { name: '内右', value: 'insideRight' },
  { name: '内上', value: 'insideTop' },
  { name: '内下', value: 'insideBottom' },
  { name: '内左上', value: 'insideTopLeft' },
  { name: '内左下', value: 'insideBottomLeft' },
  { name: '内右上', value: 'insideTopRight' },
  { name: '内右下', value: 'insideBottomRight' },
];

export const CHART_PIE_LABEL_POSITIONS = [
  { name: '外侧', value: 'outside' },
  { name: '内部', value: 'inside' },
  { name: '中心', value: 'center' },
];

export const CHART_FUNNEL_LABEL_POSITIONS = [
  { name: '左侧', value: 'left' },
  { name: '右侧', value: 'right' },
  { name: '内部', value: 'inside' },
];

export const CHART_LEGEND_POSITIONS = [
  { name: '右', value: 'right' },
  { name: '上', value: 'top' },
  { name: '下', value: 'bottom' },
  { name: '左', value: 'left' },
];

export const CHART_VISUALMAP_POSITIONS = [
  { name: '左下', value: 'leftBottom' },
  { name: '左上', value: 'leftTop' },
  { name: '右上', value: 'rightTop' },
  { name: '右下', value: 'rightBottom' },
];

export const CHART_SORT_MODES = [
  { name: '降序', value: 'descending' },
  { name: '升序', value: 'ascending' },
  { name: '无', value: 'none' },
];

export const ControlFieldTypes = {
  Column: 'column',
  Variable: 'variable',
};

export const RelativeDateValueType = {
  Prev: 'prev',
  Current: 'current',
  Next: 'next',
};

export const ControlPanelTypes = {
  Global: 'global',
  Local: 'local',
};

export const CHART_ALIGNMENT_MODES = [
  { name: '居中', value: 'center' },
  { name: '居左', value: 'left' },
  { name: '居右', value: 'right' },
];

export const CHART_LINES_SYMBOL_TYPE = [
  { name: '圆形', value: 'circle' },
  { name: '矩形', value: 'rect' },
  { name: '圆角矩形', value: 'roundRect' },
  { name: '三角形', value: 'triangle' },
  { name: '菱形', value: 'diamond' },
  { name: '大头针形', value: 'pin' },
  { name: '箭头形', value: 'arrow' },
];

export const All_LINES_SYMBOL_TYPE = ['emptyCircle', 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'];

export const tooltipStyle = {
  backgroundColor: 'rgba(5,10,19,1)',
  borderWidth: 0,
  textStyle: {
    color: '#fff',
    fontSize: 12,
    lineHeight: 20,
  },
};

export const ViewModelVisualTypes = {
  Number: 'number',
  String: 'string',
  Date: 'date',
  GeoCountry: 'geoCountry',
  GeoProvince: 'geoProvince',
  GeoCity: 'geoCity',
};


