import React from 'react';
import CommonTable from '../CommonTable/index';
import util from '../../util';
import { computed, observable } from 'mobx';
import mathUtil from '../../utils/mathUtil';
import { Link } from 'react-router-dom';

export default class PagePathTable extends React.Component {
  constructor(props) {
    super(props);
    this.keyDim = util.getQueryString('keyDim');
    this.keyDimStr = this.keyDim.split('_')[0];
    this.string = '';
  }

  @computed get dimAndData() {
    if (this.keyDim.includes('pageset')) {
      this.string = 'set';
    } else if (this.keyDim.includes('_group')) {
      this.string = 'Group';
    }

    if (this.props.dims === 'path_channel') {
      return {
        dim: `path_channel${this.string}`,
        rateFields: `pagePathBounceRate`,
      };
    } else if (this.props.dims === 'path_page_before') {
      return {
        dim: `path_page${this.string}_before`,
        rateFields: `pagePathBounceRate`,
      };
    } else if (this.props.dims === 'path_page_after') {
      return {
        dim: `path_page${this.string}_after`,
        rateFields: `pagePathExitRate`,
      };
    }
  }

  addContent = (store) => {
    store.columns.forEach((item, itemIndex) => {
      if (itemIndex === 1 && String(store.field[0]).includes('page')) {
        item.render = (text, record, index) => {
          return {
            children: (
              <div className="childrenContent">
                <div className="rateContent">
                  <div className="rateContent-title" title={text}>
                    {mathUtil.spliceHttpAndDomin(text)}
                  </div>
                  <div className="rateContent-urlName">
                    <Link
                      to={`/data/${this.keyDimStr}/detail?keyDim=${this.keyDim}&key=${encodeURIComponent(text)}&name=${encodeURIComponent(
                        store.parentData[index][`${this.dimAndData.dim}_name`]
                      )}`}
                      title={`${store.parentData[index][`${this.dimAndData.dim}_name`]}的页面详情`}>
                      {`${store.parentData[index][`${this.dimAndData.dim}_name`]}`}
                    </Link>
                  </div>
                </div>
                <div className="eventDetailIcon" title="打开链接">
                  <i className="iconfont icon-yemian_" onClick={() => window.open(`${text}`)} />
                </div>
              </div>
            ),
          };
        };
      }
    });
  };
  @observable params = {
    fields: `pagePathUv,pagePathUvRatio,pagePathPv`,
    dims: this.dimAndData.dim,
    pageCount: 10,
    currentPage: 1,
    orderColumn: 'pagePathUv',
    filters: [{ dim: this.dimAndData.dim, value: util.getQueryString('key'), formula: '=' }],
    orderDesc: true,
  };
  render() {
    return <CommonTable addContent={this.addContent} {...this.params} />;
  }
}
