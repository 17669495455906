import React, { Component } from 'react';
import { Select } from 'antd';
import pageHeaderStore from '../PageHeader/PageHeaderStore';
import util from '../../util';
import api from '../../api';
import intl from 'react-intl-universal';
const Option = Select.Option;
export default class CustomBoardSelect extends Component {
  constructor() {
    super();
    this.state = {
      boardList: [],
    };
  }
  componentDidMount() {
    // 看板列表
    let param = {
      idsite: pageHeaderStore.parameter.idsite,
      currentPage: 1,
      pageCount: 10000,
    };
    util.get(
      api.board.listWithoutTemplate,
      param,
      (res) => {
        if (res) {
          this.setState({
            boardList: res.data,
          });
        }
      },
      null,
      false
    );
  }
  render() {
    const { boardList } = this.state;
    return (
      <Select labelInValue placeholder={intl.get('Pleaseselect')} style={{ width: 120 }} onChange={this.props.onChange}>
        {boardList
          ? boardList.map((item) => {
              return (
                <Option disabled={!item.edit} value={item.id}>
                  {item.name}
                </Option>
              );
            })
          : null}
      </Select>
    );
  }
}
