import React, { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import Contact from '../../../containers/Contact/index';
import UXSDrawer from '../../../component/UXSDrawer';
import Loading from '../../../component/Loading/Loading';
import util from '../../../util';
import api from '../../../api';
import SurveyDetail from './SurveyDetail';
import CleanRuleDetail from './CleanRuleDetail';
import DashboardDetail from './DashboardDetail';

const auditingStatus = [2, 6, 9];

function AuditModal(props) {
  const { show, onHide, pass, reject, editInfo } = props;
  const { editId, auditType, auditStatus } = editInfo;
  console.log(auditStatus);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // 是否加载状态

  useEffect(() => {
    auditType == '2' &&
      util.get(
        api.audit.info,
        {
          id: editId,
        },
        (res) => {
          res && res.auditSourceInfo && (setData({ data: [res.auditSourceInfo] }), setLoading(false));
        }
      );
  }, []);
  return (
    <UXSDrawer
      className="full-right scroll-drawer"
      title={
        <div>
          <div>提交审核确认</div>
          {auditingStatus.includes(auditStatus) && (
            <div className="drawer-btn-content">
              <Button type="primary" onClick={() => pass(editId)}>
                通过
              </Button>
              <Button onClick={() => reject(editId)}>拒绝</Button>
            </div>
          )}
        </div>
      }
      show={show}
      onClose={onHide}
      width={600}>
      {auditType == '2' ? (
        !loading ? (
          <Contact type="audit" parentData={data} />
        ) : (
          <Loading />
        )
      ) : auditType == '5' ? (
        <CleanRuleDetail editInfo={editInfo} type="detail" />
      ) : auditType == '1' ? (
        <DashboardDetail editInfo={editInfo} type="detail" />
      ) : (
        <SurveyDetail editInfo={editInfo} type="detail" />
      )}
    </UXSDrawer>
  );
}

export default Form.create()(AuditModal);
