import Audit from '../Audit';

export default {
  title: '审核通知',
  components: [
    {
      title: '审核通知',
      isCard: true,
      className: 'single-card',
      children: [{ component: Audit }],
    },
  ],
};
