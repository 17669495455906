import React, { Component } from 'react';
import { Tabs } from 'antd';
import CombineLayout from './CombineLayout';
import CombineStyle from './CombineStyle';
import DropBox from './DropBox';

const { TabPane } = Tabs;

class WidgetItem extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { data } = this.props;

    return (
      <div className="combine-setting">
        <h5 className="combine-setting-title">融合卡片样式</h5>
        <CombineLayout {...this.props} />
        <Tabs size="small">
          <TabPane tab="选区" key="area">
            <DropBox {...this.props} />
          </TabPane>
          <TabPane tab="样式" key="style">
            <CombineStyle {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default WidgetItem;
