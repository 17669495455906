import React, { Component } from 'react';
import { Collapse } from 'antd';
const Panel = Collapse.Panel;
class AnalyseLayoutConfig extends Component {
  render() {
    return (
      <Collapse
        defaultActiveKey={Object.keys(this.props.config).map((item, index) => {
          return `${index}`;
        })}
        bordered={false}>
        {this.props.config
          ? Object.keys(this.props.config).map((item, index) => {
              return this.props.config[item]['show'] ? (
                <Panel header={this.props.config[item]['configName']} key={`${index}`}>
                  {this.props.config[item].hasOwnProperty('component')
                    ? this.props.config[item]['component']
                    : this.props.config[item]['components'].map((item, index) => {
                        return item.show ? (
                          <div key={index} className="AnalyseLayoutConfig-childrenPanel-item">
                            <div className="AnalyseLayoutConfig-childrenPanel-item-configName">{item.configName}</div>
                            <div className="AnalyseLayoutConfig-childrenPanel-item-configCop">{item.component}</div>
                          </div>
                        ) : null;
                      })}
                </Panel>
              ) : null;
            })
          : null}
      </Collapse>
    );
  }
}
export default AnalyseLayoutConfig;
