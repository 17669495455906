import React from 'react'
import Yaxis from './Yaxis'
import { spanSize, getPivotCellWidth, getPivotCellHeight, getAxisData, getAggregatorLocale, getStyleConfig } from '../util'
import { PIVOT_LINE_HEIGHT, DEFAULT_SPLITER } from '../constants'

export class RowHeader extends React.Component {
    render() {
        const { rows, rowKeys, colKeys, rowWidths, rowTree, colTree, tree, chartStyles, drawingData, dimetionAxis, metrics, metricAxisConfig, hasMetricNameDimetion } = this.props
        const { elementSize, unitMetricHeight } = drawingData
        const {
            color: fontColor,
            fontSize,
            fontFamily,
            lineColor,
            lineStyle,
            headerBackgroundColor
        } = getStyleConfig(chartStyles).pivot

        let headerCount = 0

        const headers = []

        if (rows.length) {
            let elementCount = 0
            let x = -1
            let hasAuxiliaryLine = false

            rowKeys.forEach((rk, i) => {
                const flatRowKey = rk.join(String.fromCharCode(0))
                const header = []
                const { height, records } = rowTree[flatRowKey]
                const maxElementCount = tree[flatRowKey]
                    ? Math.max(...Object.values(tree[flatRowKey]).map((r) => r ? r.length : 0))
                    : records.length
                let cellHeight = 0

                rk.forEach((txt, j) => {
                    if (dimetionAxis === 'row') {
                        if (j === rk.length - 1) {
                            x = -1
                        } else if (j === rk.length - 2) {
                            const lastRk = rowKeys[i + 1] || []
                            elementCount += 1
                            if (rk[j] === lastRk[j]) {
                                return
                            } else {
                                cellHeight = elementCount * elementSize
                                x = 1
                                elementCount = 0
                                hasAuxiliaryLine = true
                            }
                        } else {
                            x = spanSize(rowKeys, i, j)
                        }
                    } else {
                        if (j === rk.length - 1) {
                            cellHeight = dimetionAxis === 'col'
                                ? unitMetricHeight * metrics.length
                                : maxElementCount === 1
                                    ? getPivotCellHeight(height)
                                    : getPivotCellHeight(maxElementCount * (hasMetricNameDimetion ? 1 : metrics.length) * PIVOT_LINE_HEIGHT)
                            hasAuxiliaryLine = dimetionAxis === 'col'
                        }
                        x = spanSize(rowKeys, i, j)
                    }

                    if (x !== -1) {
                        let colContent
                        if (txt.includes(DEFAULT_SPLITER)) {
                            const [name, id, agg] = txt.split(DEFAULT_SPLITER)
                            colContent = `[${getAggregatorLocale(agg)}]${name}`
                        } else {
                            colContent = txt
                        }
                        header.push(
                            <th
                                key={`${txt}${j}`}
                                rowSpan={x}
                                colSpan={1}
                                className='topBorder bottomBorder'
                                style={{
                                    width: getPivotCellWidth(rowWidths[j]),
                                    ...(!!cellHeight && { height: cellHeight }),
                                    ...!dimetionAxis && { backgroundColor: headerBackgroundColor },
                                    color: fontColor,
                                    fontSize: Number(fontSize),
                                    fontFamily,
                                    borderColor: lineColor,
                                    borderStyle: lineStyle
                                }}
                            >
                                <p
                                    className={`${hasAuxiliaryLine ? 'hasAuxiliaryLine' : ''}`}
                                    {...(!!cellHeight && { style: { height: cellHeight - 1, lineHeight: `${cellHeight - 1}px` } })}
                                >
                                    {colContent}
                                    {hasAuxiliaryLine && (
                                        <span
                                            className='line'
                                            style={{
                                                borderColor: lineColor,
                                                borderStyle: lineStyle
                                            }}
                                        />
                                    )}
                                </p>
                            </th>
                        )
                        headerCount++
                    }
                })

                headers.push(
                    <tr key={flatRowKey}>
                        {header}
                    </tr>
                )
            })
        }

        let yAxis
        if (dimetionAxis && !(dimetionAxis === 'row' && !colKeys.length && !rowKeys.length)) {
            const { data, length } = getAxisData('y', rowKeys, colKeys, rowTree, colTree, tree, metrics, drawingData, dimetionAxis)
            yAxis = (
                <Yaxis
                    height={length}
                    metrics={metrics}
                    data={data}
                    chartStyles={chartStyles}
                    dimetionAxis={dimetionAxis}
                    metricAxisConfig={metricAxisConfig}
                />
            )
        }


        return (
            <div className={`rowBody hideScrollbar ${!dimetionAxis ? 'raw' : ''}`}>
                <table className='pivot' style={{ height: `${headerCount * 37}px` }}>
                    <thead>
                        {headers}
                    </thead>
                </table>
                {yAxis}
            </div>
        )
    }
}

export default RowHeader
