import { SQL_STRING_TYPES, SQL_NUMBER_TYPES, SQL_DATE_TYPES, SQL_TYPES } from './globalConstants';

export const ViewVariableTypes = {
  Query: 'query',
  Authorization: 'auth',
};

export const ViewVariableTypesLocale = {
  [ViewVariableTypes.Query]: '查询变量',
  [ViewVariableTypes.Authorization]: '权限变量',
};

export const ViewVariableValueTypes = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  Date: 'date',
  SqlExpression: 'sql',
};

export const ViewVariableValueTypesLocale = {
  [ViewVariableValueTypes.String]: '字符串',
  [ViewVariableValueTypes.Number]: '数字',
  [ViewVariableValueTypes.Boolean]: '布尔',
  [ViewVariableValueTypes.Date]: '日期',
  [ViewVariableValueTypes.SqlExpression]: 'SQL表达式',
};

export const ViewModelTypes = {
  Category: 'category',
  Value: 'value',
};

export const DefaultModelTypeSqlTypeSetting = {
  [ViewModelTypes.Value]: SQL_NUMBER_TYPES,

  // except SQL_STRING_TYPES field
  // SQL_NUMBER_TYPES and SQL_DATE_TYPES field can also take it as ViewModelTypes.Category
  [ViewModelTypes.Category]: SQL_TYPES,
};

export const ViewModelTypesLocale = {
  [ViewModelTypes.Category]: '维度',
  [ViewModelTypes.Value]: '指标',
};

export const ViewModelVisualTypes = {
  Number: 'number',
  String: 'string',
  Date: 'date',
  GeoCountry: 'geoCountry',
  GeoProvince: 'geoProvince',
  GeoCity: 'geoCity',
};

export const VisualTypeSqlTypeSetting = {
  [ViewModelVisualTypes.Number]: SQL_NUMBER_TYPES,

  // SQL_NUMBER_TYPES field can also take it as ViewModelVisualTypes.String
  [ViewModelVisualTypes.String]: SQL_STRING_TYPES.concat(SQL_NUMBER_TYPES),
  [ViewModelVisualTypes.Date]: SQL_DATE_TYPES,
};

export const ViewModelVisualTypesLocale = {
  [ViewModelVisualTypes.Number]: '数字',
  [ViewModelVisualTypes.String]: '字符',
  [ViewModelVisualTypes.Date]: '日期',
  [ViewModelVisualTypes.GeoCountry]: '地理国家',
  [ViewModelVisualTypes.GeoProvince]: '地理省份',
  [ViewModelVisualTypes.GeoCity]: '地理城市',
};

export const DEFAULT_SQL_LIMIT = 500;
export const DEFAULT_SQL_PREVIEW_PAGE_SIZE = 100;
export const SQL_PREVIEW_PAGE_SIZE_OPTIONS = [100, 200, 500, 1000];
