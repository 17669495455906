import CommonChart from '../../containers/Common/CommonChart';
import CommonSummary from '../../containers/CommonSummary';
import CommonTable from '../../containers/CommonTable';
import CommonTab from '../../containers/CommonTab';
import ShareUserTable from '../../containers/ShareUserTable/index';

export default {
  title: '搜索引擎',
  components: [
    {
      // 调用tab组件分页
      isCard: true,
      component: CommonTab,
      props: {
        tab: [
          {
            name: '分享页面',
            children: [
              {
                component: CommonSummary,
                col: 24,
                props: {
                  fields: 'shareUv,shareCount,shareFissionUv',
                  comparision: 'chain',
                },
              },
              {
                component: CommonChart,
                col: 24,
                props: {
                  dims: 'date',
                  fields: 'shareUv,shareCount,shareFissionUv',
                  showAll: true,
                },
              },
              {
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'channel_share_page',
                  fields: 'shareUv,shareCount,shareFissionUv',
                  orderColumn: 'shareUv',
                  orderDesc: true,
                  currentPage: 1,
                  pageCount: 10,
                },
              },
            ],
          },
          {
            name: '分享用户',
            children: [
              {
                component: ShareUserTable,
                col: 24,
              },
            ],
          },
        ],
      },
    },
  ],
};
