import React from 'react'
import { getPivotCellWidth, getStyleConfig } from '../util'

export function ColumnTitle(props) {
    const { cols, colKeys, colTree, chartStyles, drawingData, dimetionAxis, colsDisplay } = props
    const { elementSize, unitMetricWidth } = drawingData
    const { color: fontColor } = getStyleConfig(chartStyles).pivot

    let tableWidth = 0

    if (dimetionAxis) {
        tableWidth = dimetionAxis === 'col'
            ? elementSize * colKeys.length
            : elementSize * unitMetricWidth
    } else {
        tableWidth = Object.values(colTree).reduce((sum, d) => sum + getPivotCellWidth(d.width), 0)
    }
    const titles = cols.map((item) => {
        return colsDisplay[item] || item
    })
    return (
        <div
            className='columnTitle'
            style={{
                width: tableWidth,
                color: fontColor
            }}
        >
            {titles.join(`  /  `)}
        </div>
    )
}

export default ColumnTitle
