
import React, { Component } from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = {hasError: false};
    }
  
    componentDidCatch(error, info) {
      this.setState({hasError: true});
    }
  
    render() {
      if(this.state.hasError) return <div>我出错啦!!</div>;
      return this.props.children;
    }
  }

  export default ErrorBoundary;