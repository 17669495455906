/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { tooltipStyle } from '../../constants';
import { decodeMetricName, getTextWidth, getFormattedValue, getFieldAlias } from '../../util';
import { getLegendOption, getLabelOption, getFieldFormat } from './util';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps, drillOptions) {
  const { width, height, data, cols, metrics, chartStyles, color, tip } = chartProps;

  const { label, legend, spec, toolbox, grid } = chartStyles;

  const { legendPosition, fontSize } = legend;

  const { circle, roseType } = spec;
  const { selectedItems } = drillOptions;
  // formatter: '{b}({d}%)'

  const labelOption = {
    label: getLabelOption('pie', label, metrics, label.pieLabelPosition === 'center', {
      overflow: 'breakAll',
      width: 100,
      lineHeight: parseInt(label.labelFontSize) * 1.5,
    }),
  };

  const roseTypeValue = roseType ? 'radius' : '';
  const radiusValue = (!circle && !roseType) || (!circle && roseType) ? `80%` : ['58%', '80%'];

  let seriesObj = {};
  const seriesArr = [];
  let legendData = [];
  let grouped = {};

  if (metrics.length <= 1) {
    const groupColumns = color.items
      .map((c) => c.randomName || c.name)
      .concat(cols.map((c) => c.randomName || c.name))
      .reduce((distinctColumns, col) => {
        if (!distinctColumns.includes(col)) {
          distinctColumns.push(col);
        }
        return distinctColumns;
      }, []);

    grouped = data.reduce((obj, val) => {
      const groupingKey = groupColumns.reduce((keyArr, col) => keyArr.concat(val[col]), []).join(String.fromCharCode(0));
      if (!obj[groupingKey]) {
        obj[groupingKey] = [];
      }
      obj[groupingKey].push(val);
      return obj;
    }, {});

    metrics.forEach((metric) => {
      const decodedMetricName = decodeMetricName(metric.name);

      const seriesData = [];
      Object.entries(grouped).forEach(([key, value]) => {
        const legendStr = key.replace(String.fromCharCode(0), ' ');
        legendData.push(legendStr);
        value.forEach((v) => {
          const obj = {
            name: legendStr,
            value: v[metric.randomName || `${metric.agg}(${decodedMetricName})`],
          };
          seriesData.push(obj);
        });
      });
      let leftValue;
      let topValue;
      const pieLeft = 56 + Math.max(...legendData.map((s) => getTextWidth(s, '', `${fontSize}px`)));
      switch (legendPosition) {
        case 'top':
          leftValue = width / 2;
          topValue = (height + 32) / 2;
          break;
        case 'bottom':
          leftValue = width / 2;
          topValue = (height - 32) / 2;
          break;
        case 'left':
          leftValue = (width + pieLeft) / 2;
          topValue = height / 2;
          break;
        case 'right':
          leftValue = (width - pieLeft) / 2;
          topValue = height / 2;
          break;
      }

      seriesObj = {
        name: '',
        type: 'pie',
        avoidLabelOverlap: false,
        // center: legend.showLegend ? [leftValue, topValue] : [width / 2, height / 2],
        data: seriesData.map((data, index) => {
          return {
            ...data,
            itemStyle: {
              normal: {
                color: (color.items.length && color.items[0].config.values[data.name]) || defaultThemeColors[index % defaultThemeColors.length],
                opacity: selectedItems && selectedItems.length ? (selectedItems.includes(index) ? 1 : 0.25) : 1,
              },
            },
          };
        }),
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        ...labelOption,
        roseType: roseTypeValue,
        radius: radiusValue,
      };

      seriesArr.push(seriesObj);
    });
  } else {
    legendData = [];
    seriesObj = {
      type: 'pie',
      avoidLabelOverlap: false,
      center: ['50%', '45%'],
      data: metrics.map((metric, index) => {
        const decodedMetricName = decodeMetricName(metric.name);
        const displayName = getFieldAlias(metric.field, {}) || metric.fieldDisplay || decodedMetricName;
        legendData.push(displayName);

        return {
          name: displayName,
          value: data.reduce((sum, record) => sum + record[metric.randomName || `${metric.agg}(${decodedMetricName})`], 0),
          itemStyle: {
            normal: {
              color: color.value[metric.name] || defaultThemeColors[index % defaultThemeColors.length],
              opacity: selectedItems && selectedItems.length ? (selectedItems.includes(index) ? 1 : 0.25) : 1,
            },
          },
        };
      }),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      ...labelOption,
      roseType: roseTypeValue,
      radius: radiusValue,
    };
    seriesArr.push(seriesObj);
  }
  const fieldFormat = getFieldFormat(metrics, 'name');

  const tooltip = {
    trigger: 'item',
    confine: 'true',
    extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
    formatter(params) {
      const { color, name, value, percent, dataIndex } = params;
      const tooltipLabels = [];
      if (color) {
        tooltipLabels.push(`<span class="widget-tooltip-circle" style="background: ${color}"></span>`);
      }
      tooltipLabels.push(`${name}<br/>${getFormattedValue(value, metrics[metrics.length > 1 ? dataIndex : 0].format)}（${percent}%）`);
      return tooltipLabels.join('');
    },
    ...tooltipStyle,
  };

  return {
    tooltip,
    legend: getLegendOption(legend, legendData),
    series: seriesArr,
    grid: {
      show: true,
      borderColor: 'transparent',
      backgroundColor: grid ? grid.backgroundColor : 'transparent',
    },
  };
}
