import React, { FC } from 'react';
import classnames from 'classnames';
import SplitPane, { ISplitPaneProps } from '../SplitPane';
import './ListFormLayout.less';

const ListFormLayout = ({ children, className, ...rest }) => {
  const containerClass = classnames({
    'list-container': true,
    [className]: !!className,
  });
  return (
    <SplitPane className={containerClass} {...rest}>
      {children}
    </SplitPane>
  );
};

export default ListFormLayout;
