import React from 'react';
import chartlibs from '../config/chart';
import * as echarts from 'echarts/lib/echarts';
import chartOptionGenerator from '../render/chart';
import './Chart.sass';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import BIStore from '../store';
import { ChartTypes } from '../config/chart/ChartTypes';
import defaultTheme from './echarts_theme';
import { handleAnalysis } from '../util';
import util from '../../../../src/util';

const unSelectableCharts = [ChartTypes.WordCloud];

// 移动端性能顶不住会白屏崩溃 doClear函数中 ctx 即canvas实例获取不到，用svg
const useCanvasOrSvg = util.isMobile() ? 'svg' : 'canvas';

export class Chart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.asyncEmitTimer = null;
    this.container = null;
    this.instance;
    const echartTheme = defaultTheme();
    echarts.registerTheme('default', echartTheme.theme);
  }
  componentDidMount() {
    this.renderChart(this.props);
  }

  componentDidUpdate() {
    // 拖动卡片大小时重新渲染
    this.renderChart(this.props);
  }

  renderChart = (props) => {
    const { selectedChart, renderType, getDataDrillDetail, isDrilling, onError, index, handlePreview } = props;
    if (renderType === 'loading') {
      return;
    }
    if (!this.instance) {
      this.instance = echarts.init(this.container, 'default', { renderer: useCanvasOrSvg });
    } else {
      if (renderType === 'rerender') {
        this.instance.dispose();
        this.instance = null;
        this.instance = echarts.init(this.container, 'default', { renderer: useCanvasOrSvg });
      }
      if (renderType === 'clear') {
        this.instance.clear();
      }
    }

    try {
      this.instance.off('click');
      this.instance.on('click', (params) => {
        this.collectSelectedItems(params);
      });
      const { selectedChart, showAnalysis } = props;

      if ([2, 3].includes(selectedChart) && showAnalysis) {
        const options = chartOptionGenerator(
          chartlibs.find((cl) => cl.id === selectedChart).name,
          props,
          {
            instance: this.instance,
            isDrilling,
            getDataDrillDetail,
            selectedItems: this.props.selectedItems,
            callback: (seriesData) => {
              this.instance.off('click');
              this.instance.on('click', (params) => {
                this.collectSelectedItems(params, seriesData);
              });
            },
          },
          this.instance
        );

        this.instance.off('legendselectchanged');
        this.instance.on('legendselectchanged', (e) => {
          const { series } = options;
          const currentId = series.find((i) => i.name === e.name).workViewParamId;
          this.instance.setOption({
            legend: { selected: { [e.name]: true } },
          });
          handleAnalysis(currentId, index, handlePreview);
        });
      } else {
        this.instance.off('legendselectchanged');
      }

      this.instance.setOption(
        chartOptionGenerator(
          chartlibs.find((cl) => cl.id === selectedChart).name,
          props,
          {
            instance: this.instance,
            isDrilling,
            getDataDrillDetail,
            selectedItems: this.props.selectedItems,
            callback: (seriesData) => {
              this.instance.off('click');
              this.instance.on('click', (params) => {
                this.collectSelectedItems(params, seriesData);
              });
            },
          },
          this.instance
        )
      );
      this.instance.resize();
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  componentWillUnmount() {
    if (this.instance) {
      this.instance.off('click');
    }
    if (this.asyncEmitTimer) {
      clearTimeout(this.asyncEmitTimer);
    }
  }

  collectSelectedItems = (params, seriesData) => {
    const { data, selectedChart, onDoInteract, getDataDrillDetail, onSelectChartsItems, onCheckTableInteract, id, cols, rows, metrics } = this.props;

    const { seriesItems } = this.props;
    if (!unSelectableCharts.includes(selectedChart)) {
      let selectedItems = [];
      let series = [];
      if (this.props.selectedItems && this.props.selectedItems.length) {
        selectedItems = [...this.props.selectedItems];
      }
      let dataIndex = params.dataIndex;
      if (selectedChart === 4) {
        dataIndex = params.seriesIndex;
      }
      if (selectedItems.length === 0) {
        selectedItems.push(dataIndex);
      } else {
        const isb = selectedItems.some((item) => item === dataIndex);
        if (isb) {
          for (let index = 0, l = selectedItems.length; index < l; index++) {
            if (selectedItems[index] === dataIndex) {
              selectedItems.splice(index, 1);
              break;
            }
          }
        } else {
          selectedItems.push(dataIndex);
        }
      }

      if (seriesData) {
        const { seriesIndex, dataIndex } = params;
        const char = `${seriesIndex}&${dataIndex}`;
        if (seriesItems && Array.isArray(seriesItems)) {
          series = seriesItems.includes(char) ? seriesItems.filter((item) => item !== char) : seriesItems.concat(char);
          this.props.onSelectSeriesItems(series);
        }
      }
      const resultData = selectedItems.map((item, index) => {
        if (seriesData) {
          const seriesIndex = series[index] ? series[index].split('&')[0] : null;
          return seriesData[seriesIndex] ? seriesData[seriesIndex][item] : [];
        }
        return data[item];
      });
      // 替换resultData中的randomKey
      const randomNames = {};
      cols.concat(rows, metrics).forEach((item) => {
        item.randomName && (randomNames[item.randomName] = item.name);
      });
      resultData.forEach((item) => {
        for (let key in item) {
          randomNames[key] && (item[randomNames[key]] = item[key]);
        }
      });

      const brushed = [{ 0: Object.values(resultData) }];
      const sourceData = Object.values(resultData);
      const isInteractiveChart = PageHeaderStore.checkLinkageConfig(id, sourceData);
      if (isInteractiveChart) {
        const triggerData = sourceData;
        PageHeaderStore.setCurrentLinkager(id, triggerData);
        BIStore.setIsLinkingWidget(id, true);
      }
      this.asyncEmitTimer = setTimeout(() => {
        if (getDataDrillDetail) {
          getDataDrillDetail(JSON.stringify({ range: null, brushed, sourceData }));
        }
      }, 500);
      if (onSelectChartsItems) {
        onSelectChartsItems(selectedItems);
      }
    }
  };

  render() {
    return <div className="chartContainer" ref={(f) => (this.container = f)} />;
  }
}

export default Chart;
