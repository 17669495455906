import React, { Component } from 'react';
import Loading from '../Loading/Loading';
import { Select, TreeSelect } from 'antd';
import { observer } from 'mobx-react';

/*
TODO:
@param {参数类型} @参数名 参数说明
1、{string} @selectType 
 该参数为事件下拉框的类型
'normal'为普通下拉框
'multiple'则为多选框
2、{boolean} @disabled
是否禁用 默认为false
3、{object} @style
下拉框样式 默认{width:96px} 多选框{ width: 250 }
4、{function} @onChange
变化时回调函数
普通下拉框参数(value)
多选框参数(value, label, extra)
5、{object} @value 
当前选中的值
6、{Array.Object} selectOption 
所有选项值default
7、{string} @size
该参数为下拉框的大小类型
small 为无边框  

*/
@observer
export default class UXSNewSelect extends Component {
  translateToTreeData(targetOption) {
    targetOption = targetOption.map((item, index) => {
      item['title'] = item.label;
      delete item.label;
      return item;
    });
    return targetOption;
  }
  render() {
    return (
      <div className="UXSNewSelect">
        {!this.props.isLoading ? (
          this.props.selectType === 'multiple' ? (
            <TreeSelect
              className={this.props.className ? this.props.className : ''}
              disabled={this.props.disabled}
              treeData={this.translateToTreeData(this.props.selectOption)}
              value={!this.props.isLoading && this.props.value}
              onChange={(value, label, extra) => {
                this.props.onChange(value, label, extra);
              }}
              labelInValue
              treeCheckStrictly={true}
              multiple={true}
              style={this.props.style ? this.props.style : { width: 250 }}></TreeSelect>
          ) : (
            <Select
              defaultValue={this.props.defaultValue}
              size={this.props.size ? this.props.size : 'default'}
              dropdownMatchSelectWidth={false}
              className={this.props.className ? this.props.className : ''}
              disabled={this.props.disabled}
              style={this.props.style ? this.props.style : { width: 96 }}
              labelInValue
              placeholder={this.props.placeholder ? this.props.placeholder : '请选择'}
              onChange={this.props.onChange}
              onSelect={this.props.onSelect}
              value={this.props.value}
              {...this.props}>
              {this.props.extraOption
                ? this.props.extraOption.map((item, index) => {
                  return (
                    <Select.Option className="uxs-new-select-option" key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })
                : null}
              {this.props.selectOption &&
                this.props.selectOption.map((item, index) => {
                  return (
                    <Select.Option className="uxs-new-select-option" key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
            </Select>
          )
        ) : (
          <Loading size="small"></Loading>
        )}
      </div>
    );
  }
}
