import React, { useEffect, useState } from 'react';
import { Form, Select, Button, message, Radio } from 'antd';
import intl from 'react-intl-universal';
import util from '../../../util';
import api from '../../../api';
import mathUtil from '../../../utils/mathUtil';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import UXSDrawer from '../../../component/UXSDrawer';
import UXSRangePicker from '../../../component/UXSRangePicker';
import NavbarTopStore from '../../../component/NavbarTop/store';
import './index.sass';

const { Option } = Select;
function Main(props) {
  const { visible, onHide, form, id } = props;
  const [dateObj, setDateObj] = useState({ date: mathUtil.keyToDate('last30'), lastDate: 'last30' });
  const [rules, setRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    getRuleList();
  }, []);

  const { getFieldDecorator, getFieldValue } = form;
  function submit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let { exportType, transverseType } = values;
        let fileType = 'excel';
        if (values.exportType.endsWith('-spss')) {
          exportType = exportType.split('-')[0];
          fileType = 'spss';
        } else if (values.exportType.endsWith('-csv')) {
          exportType = exportType.split('-')[0];
          fileType = 'csv';
        }
        const param = {
          idsite: PageHeaderStore.parameter.idsite,
          surveyId: props.id,
          exportType,
          fileType,
          dateRange: dateObj.date,
          type: 'surveyAnswer',
          transverseType,
          excludedRuleIds: values.excludedRuleIds,
        };
        util.post(api.download.submit, param, (res) => {
          res.code === 200 && (message.success('下载任务创建成功'), onHide(), NavbarTopStore.getDownloadList());
        });
      }
    });
  }
  function getDateAndlastDate(lastDate, date) {
    const data = { lastDate: lastDate || '', date: lastDate ? mathUtil.keyToDate(lastDate) : date };
    setDateObj({ ...dateObj, ...data });
  }

  const getRuleList = () => {
    util.get(
      api.dataclean.list,
      {
        idsite: PageHeaderStore.parameter.idsite,
        pageCount: 9999,
        currentPage: 1,
        sourceType: 'survey',
      },
      (res) => {
        res.data.forEach((i) => {
          if (!i.isDefault) {
            i.name = `自定义: ${i.name}`;
          }
        });
        setRules(res.data);
      }
    );
  };

  const handlePreview = () => {
    const values = getFieldValue('excludedRuleIds');
    if (!values.length) {
      message.error('请选择过滤规则');
      return;
    }
    setIsLoading(true);
    util.get(
      api.dataclean.preview,
      {
        ids: values.join(','),
        surveyId: id,
        dateRange: dateObj.date,
      },
      (res) => {
        setPreviewData(res);
        setIsLoading(false);
      }
    );
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  return (
    <UXSDrawer
      // className="full-right scroll-drawer"
      title={
        <div>
          <div>问卷数据下载</div>
          <Button type="primary" onClick={submit}>
            {intl.get('Confirm')}
          </Button>
        </div>
      }
      show={visible}
      onClose={onHide}
      width={500}>
      <Form {...formItemLayout} onSubmit={submit}>
        <Form.Item label="时间">
          {getFieldDecorator(
            'dateRange',
            {}
          )(
            <UXSRangePicker
              className="white-picker"
              getDateAndlastDate={getDateAndlastDate}
              useDateByStore={false}
              lastDate={dateObj.lastDate || ''}
              date={dateObj.date || ''}
              isBoardPicker
            />
          )}
        </Form.Item>
        <Form.Item label="导出类型">
          {getFieldDecorator('exportType', {
            rules: [{ required: true, message: '请选择导出类型' }],
            initialValue: null,
          })(
            <Select className="md-select">
              <Option key="txt">文本xlsx</Option>
              <Option key="num">数字xlsx</Option>
              <Option key="txt-csv">文本csv</Option>
              <Option key="num-csv">数字csv</Option>
              <Option key="txt-spss">文本spss</Option>
              <Option key="num-spss">数字spss</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="多选题展现形式">
          {getFieldDecorator('transverseType', {
            rules: [{ required: true, message: '请选择多选题展现形式' }],
            initialValue: null,
          })(
            <Radio.Group>
              <Radio value={2}>一题一列</Radio>
              <Radio value={1}>一题多列</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label="请选择过滤规则">
          {getFieldDecorator('excludedRuleIds', {
            initialValue: [],
          })(
            <Select
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              className="lg-select"
              style={{ marginRight: '8px' }}
              mode="multiple"
              allowClear
              placeholder="请选择过滤规则">
              {rules.map((rule) => {
                return (
                  <Option key={rule.id} vallue={rule.id}>
                    {rule.name}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="数据过滤量">
          <span onClick={handlePreview} className={!getFieldValue('excludedRuleIds').length ? 'Grey' : 'MAIN-COLOR'}>
            预览
          </span>
          {isLoading && (
            <div>
              <Button type="primary" shape="circle" loading />
              <span style={{ paddingLeft: '10px' }}>请稍后数据加载中</span>
            </div>
          )}
          {!isLoading && previewData && (
            <div>
              当前过滤规则排除 {previewData.filterAnswerNum} 份样本， 有效样本 {previewData.remainingNum}
            </div>
          )}
        </Form.Item>
      </Form>
    </UXSDrawer>
  );
}

export default Form.create()(Main);
