import React, { PureComponent, GetDerivedStateFromProps } from 'react';
import { Select, InputNumber, Input } from 'antd';
import { RelativeDateType, RelativeDateTypeLabels, RelativeDateValueType, RelativeDateValueTypeLables } from './constants';
import { getDefaultRelativeDate } from './util';
const Option = Select.Option;
const InputGroup = Input.Group;

class IRelativeDatePicker extends PureComponent {
  state = {
    value: getDefaultRelativeDate(),
  };

  static getDerivedStateFromProps = (props, state) => {
    const { type, valueType, value } = state.value;
    return {
      value: {
        type: props.value?.type || type,
        valueType: props.value?.valueType || valueType,
        value: props.value?.value || value,
      },
    };
  };

  changeType = (type) => {
    const { onChange } = this.props;
    const nextValueState = { ...this.state.value, type };
    this.setState({
      value: nextValueState,
    });
    if (onChange) {
      onChange(nextValueState);
    }
  };

  changeValueType = (valueType) => {
    const { onChange } = this.props;
    const valueState = this.state.value;
    const nextValueState = {
      ...valueState,
      valueType,
      value: valueType !== RelativeDateValueType.Current ? (valueState.value === 0 ? 1 : valueState.value) : 0,
    };

    this.setState({
      value: nextValueState,
    });
    if (onChange) {
      onChange(nextValueState);
    }
  };

  changeValue = (val) => {
    const { onChange } = this.props;
    const nextValueState = { ...this.state.value, value: val };
    this.setState({
      value: nextValueState,
    });
    if (onChange) {
      onChange(nextValueState);
    }
  };

  render() {
    const { type, valueType, value } = this.state.value;

    const relativeDateTypeOptions = Object.entries(RelativeDateTypeLabels).map(([val, label]) => (
      <Option key={val} value={val}>
        {label}
      </Option>
    ));
    const relativeDateValueTypeOptions = Object.entries(RelativeDateValueTypeLables).map(([val, label]) => (
      <Option key={val} value={val}>
        {val === RelativeDateValueType.Current ? label[type] : label}
      </Option>
    ));

    const selectStyle = {
      width: valueType !== RelativeDateValueType.Current ? '33%' : '50%',
    };
    return (
      <InputGroup compact>
        <Select dropdownMatchSelectWidth={false} value={valueType} onChange={this.changeValueType} style={selectStyle}>
          {relativeDateValueTypeOptions}
        </Select>
        {valueType !== RelativeDateValueType.Current && (
          <InputNumber min={1} precision={0} value={value} onChange={this.changeValue} style={{ width: '34%' }} />
        )}
        <Select dropdownMatchSelectWidth={false} value={type} onChange={this.changeType} style={selectStyle}>
          {relativeDateTypeOptions}
        </Select>
      </InputGroup>
    );
  }
}

export default IRelativeDatePicker;
