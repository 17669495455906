import React, { Component } from 'react';
import CommonChart from '../../../Common/CommonChart';
import { autorun, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import TableNullData from '../../../../component/TableNullData/index';
import './index.sass';
import util from '../../../../util';
import api from '../../../../api';
import { currency, distributeTools } from '../../../../utils/analyseUtil';
import Loading from '../../../../component/Loading/Loading';
import ErrorBoundary from '../../../../component/ErrorBoundary/index';
import pageUtil from '../../../../utils/pageUtil';
import moment from 'moment';
import UXSRangePicker from '../../../../component/UXSRangePicker';
import mathUtil from '../../../../utils/mathUtil';
@observer
class DistributionChart extends Component {
  @observable cop = <TableNullData></TableNullData>;
  @observable chartProps;
  @observable data;
  @observable dataType = 'value';
  @observable isLoading = true;
  @observable hasError = false;
  @observable fetchParams;
  changeCommonChart(form) {
    this.chartProps = {
      idsite: pageHeaderStore.parameter.idsite,
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : form.customizeParam.dateObj
            ? form.customizeParam.dateObj.lastDate
              ? mathUtil.keyToDate(form.customizeParam.dateObj.lastDate)
              : form.customizeParam.dateObj.date
            : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')}`,
      userGroupId: pageHeaderStore.parameter.userGroupId,
      ruleContent: JSON.stringify(toJS(form.ruleContent)),
      orderDesc: true,
      currentPage: 1,
      pageCount: 10,
      filterObject: JSON.stringify(toJS(form.filterObject)),
      ignoreCache: true,
      analysisType: 7,
      noDefaultFields: true,
      customizeParam: form.customizeParam === 'distribution_date' ? form.customizeParam : JSON.stringify(toJS(form.customizeParam)),
      // commonFilterObject: !this.props.setDateObj ? JSON.stringify(pageHeaderStore.commonFilterObject) : '',
    };
    if (toJS(form.subDividedDimGroup)[0] !== '') {
      this.chartProps['orderColumn'] = `${toJS(form.subDividedDimGroup)[0]}_key`;
    }
    if (this.props.type === 'heatmap') {
      this.chartProps['dims'] = toJS(form.subDividedDimGroup)[0] === '' ? 'date' : toJS(form.subDividedDimGroup)[0];
    }
    let fetchParams = pageUtil.getFetchParams(toJS(this.chartProps));
    util.post(api.analysis.getMixAnalysis, fetchParams, (res) => {
      if (this.props.type === 'bar') {
        this.data = distributeTools.formateChartData(res.data, 'value', toJS(form.ruleContent)[0].target);
      } else {
        this.data = distributeTools.formateHeatMapData(res.data, 'value', fetchParams.dims);
      }

      this.isLoading = false;
    });
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      let count = this.props.form.count;
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      let userGroupId = pageHeaderStore.parameter.userGroupId;
      let period = pageHeaderStore.parameter.period;
      let dateObj = this.props.form.customizeParam.dateObj;
      // let commonFilterObject = pageHeaderStore.commonFilterObject;

      if (currency.judgeRightCondition(this.props.form.filterObject)) {
        clearInterval(this.delay);
        this.delay = setTimeout(() => {
          this.isLoading = true;
          this.cop = this.changeCommonChart(toJS(this.props.form));
        }, 800);
      }
    });
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    let date = `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`;
    let dateStr =
      pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
        ? pageHeaderStore.parameter.date.replace(/-/g, '/').replace(',', ' ~ ')
        : !this.props.setDateObj &&
        (this.props.form.customizeParam.dateObj
          ? this.props.form.customizeParam.dateObj.date.replace(/-/g, '/').replace(',', ' ~ ')
          : date.replace(/-/g, '/').replace(',', ' ~ '));
    return (
      <React.Fragment>
        {this.props.setDateObj ? (
          this.props.isLoading ? (
            <Loading size="small" />
          ) : (
            <UXSRangePicker
              useDateByStore={false}
              getDateAndlastDate={this.props.setDateObj}
              date={this.props.form.customizeParam.dateObj ? this.props.form.customizeParam.dateObj.date : date}
              lastDate={this.props.form.customizeParam.dateObj ? this.props.form.customizeParam.dateObj.lastDate : ''}
              isBoardPicker={true}
            />
          )
        ) : (
          <p className="board-chart-date">{dateStr}</p>
        )}
        <ErrorBoundary>
          {!this.isLoading ? <CommonChart fullHeight={true} type={this.props.type} parentData={this.data} showAll={true}></CommonChart> : <Loading></Loading>}
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}
export default DistributionChart;
