import { action, observable } from 'mobx';
import api from '../../api';
import util from '../../util';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';

class SelectStore {
  @observable eventSelected;
  @observable item = 0;
  @observable isLoading = true;
  @observable disabledArr = [false, false, false, false];

  @action.bound setDisabled(arr) {
    arr.forEach(item => {
      this.disabledArr[item] = true
    })
  }
  @action.bound getLoadData(index, type) {
    if (this.item < 4) {
      this.isLoading = true;
      this.eventSelected = [
        {
          value: 'default',
          label: '预置事件',
          isLeaf: false,
          disabled: this.disabledArr[0],
        },
        {
          value: 'customize',
          label: '埋点事件',
          isLeaf: false,
          disabled: this.disabledArr[1],
        },
        {
          value: 'funnel',
          label: '漏斗',
          isLeaf: false,
          disabled: this.disabledArr[2],
        },
        {
          value: 'survey',
          label: '调研',
          isLeaf: false,
          disabled: this.disabledArr[3],
        }
      ];
      util.post(
        api.pack.dimOptions,
        { dim: `analysis_${type}`, idsite: pageHeaderStore.parameter.idsite },
        (res) => {
          if (JSON.stringify(res.data) === '{}' || !res.data) {
            this.eventSelected[index].children = [{ disabled: true, label: '暂无数据', value: '暂无数据' }];
          } else {
            let arrChild = [];
            Object.keys(res.data).forEach((key) => {
              if (key.includes(',')) {
                arrChild.push({ label: res.data[key], value: key });
              } else {
                arrChild.push({ label: res.data[key], value: key });
              }
            });
            this.eventSelected[index].children = arrChild;
          }
          this.item++;
          if (this.item === 4) {
            this.isLoading = false;
          }
        },
        null,
        false
      );
    }
  }
}
export default new SelectStore();
