import React, { Component } from 'react';
import { Card } from '../../component/Card/Card';
import { Row, Col } from 'antd';
import util from '../../util';

class Main extends Component {
  constructor(props) {
    super(props);
    this.parameter = {
      keyDim: util.getQueryString('keyDim'),
    };
    this.setParams(this.parameter.keyDim);
  }
  setParams() {
    if (this.parameter.keyDim.includes('visited')) {
      this.fields = 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,exits,exitRate';
    } else if (this.parameter.keyDim.includes('entry')) {
      this.fields = 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,bounces,bounceRate';
    } else if (this.parameter.keyDim.includes('page_group')) {
      this.fields = 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,exits,exitRate';
      if (this.props.noNeedIsHasParam === true) {
        this.fields;
      } else {
        this.fields.concat(',isHasParam');
      }
    }
  }
  render() {
    return (
      // 该页面用于一级目录向二级目录跳转时传二级目录所需要的parameter

      <Row>
        {/* ActionPageDetail配置文件中props的children */}
        {this.props.children.map((item) => {
          return item.isCard ? (
            <Card
              card={{
                title: item.title ? item.title : '',
                content: (
                  <Col md={item.col}>
                    {item.key === 'CommonSummary' ? (
                      <item.component fields={this.fields}></item.component>
                    ) : (
                      <item.component dims={this.parameter.keyDim} fields={this.fields} {...item.props} {...this.parameter}></item.component>
                    )}
                  </Col>
                ),
              }}
            />
          ) : (
            <Col md={item.col}>
              {item.key === 'CommonSummary' ? (
                <item.component fields={this.fields} {...item.props}></item.component>
              ) : (
                <item.component fields={this.fields} {...item.props} {...this.parameter}></item.component>
              )}
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default Main;
