// 图表对应的数字以及名称
const config = [
  { key: 1, value: 'CommonChart_line' },
  { key: 2, value: 'CommonChart_bar_area' },
  { key: 3, value: 'CommonSummary' },
  { key: 4, value: 'CommonChart_line_area' },
  {
    key: 5,
    value: 'CommonChart_pie',
  },
  {
    key: 6,
    value: 'CommonChart_bar',
  },
  {
    key: 7,
    value: 'CommonChart_bar_horizontalRankStack',
  },
  {
    key: 8,
    value: 'CommonTable',
  },
  {
    key: 9,
    value: 'CommonChart_map',
  },
  {
    key: 10,
    value: 'CommonChart_radar',
  },
  {
    key: 11,
    value: 'CommonChart_scatter',
  },
  {
    key: 12,
    value: 'CommonChart_gauge',
  },
  {
    key: 13,
    value: 'CommonSankey',
  },
  {
    key: 14,
    value: 'CommonFunnel',
  },
  {
    key: 15,
    value: 'CommonChart_heatmap',
  },
  {
    key: 16,
    value: 'TextTable',
  },
  {
    key: 17,
    value: 'CommonChart_bar_stack',
  },
  {
    key: 18,
    value: 'CommonChart_bar_horizontal',
  },
  {
    key: 19,
    value: 'CommonChart_treemap',
  },
  {
    key: 20,
    value: 'CommonChart_scatter_quadrant',
  },
  {
    key: 21,
    value: 'CommonChart_wordCloud',
  },
  {
    key: 22,
    value: 'CommonChart_scatter_custom',
  },
];

export default {
  changeNumberToType(value) {
    let result = typeof value === 'number' ? 'CommonChart_line' : 1;
    config.forEach((item) => {
      if (typeof value === 'number') {
        if (item.key === value) {
          result = item.value;
          return false;
        }
        return true;
      }
      if (item.value === value) {
        result = item.key;
        return false;
      }
      return true;
    });
    return result;
  },
};
