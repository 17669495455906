import React, { Component, Fragment } from 'react';
import { action, observable, autorun, toJS } from 'mobx';
import { Select, DatePicker, Button, Tag } from 'antd';
import { SearchBar } from 'antd-mobile-v2';
import { Divider, SearchBar as SearchBarV5, Mask } from 'antd-mobile';
import { observer } from 'mobx-react';
import api from '../../../api';
import util from '../../../util';
import PageHeaderStore from '../PageHeaderStore';
import Loading from '../../Loading/Loading';
import './index.sass';
import moment from 'moment';
import intl from 'react-intl-universal';
import mathUtil from '../../../utils/mathUtil';
import ControlSelect from './component/ControlSelect';
import ControlSearch from './component/ControlSearch';
import ControlRangePicker from './component/ControlRangePicker';
import { normalViews, viewToName, nameToView } from './config';
import MobileCalandar from '../../MobileCalandar';
import MobileV5Calandar from '../../MobileV5Calandar';
import MobileSelect from '../../MobileSelect';
import MobileArrows from '../../MobileArrows';
import MobileRadio from '../../../component/PageHeader/Control/Control/Radio';
import MobileSlider from '../../../component/PageHeader/Control/Control/Slider';
import MobileNumberRange from '../../../component/NumberRange';
import { getDateDefaultValue, getFormatOldControl, getWithKeyValue, getFormatValue } from '../Control/Config/util';
import { transformOptions, getPreciseDefaultValue, getControlVisibility, getPanelRenderState, getAllChildren } from '../Control/util';
import _ from 'lodash';
@observer
class QuickFilter extends Component {
  constructor() {
    super();
    this.lastFilters = [];
    this.state = {
      mobileFilterOverflow: true,
      resetModalVisible: false,
      current: 1,
      step: 1,
      showRange: [0, 1],
    };
    this.lastBoardConfig = {};
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      let reloadGlobalContolCount = PageHeaderStore.reloadGlobalContolCount;
      const boardConfig = {
        reloadGlobalContolCount: PageHeaderStore.reloadGlobalContolCount,
        currentBoardId: PageHeaderStore.currentBoardId,
      };
      // 获取控制器列
      if (this.props.type !== 'insideCard' && JSON.stringify(boardConfig) !== JSON.stringify(this.lastBoardConfig)) {
        this.filtersValue = [];
        this.filters = [];
        PageHeaderStore.currentBoardId &&
          util.get(api.globalControl.list, { id: PageHeaderStore.currentBoardId }, (res) => {
            // 看板权限
            let { edit, isDelete, share, name } = res;
            PageHeaderStore.setAuthorityObject({ edit, isDelete, share });
            PageHeaderStore.setCurrentBoardName(name);
            // 全局控制器配置
            this.filterList = res.config ? getFormatOldControl(JSON.parse(res.config)) : [];
            this.optionList = [];
            this.initFilter(res.config ? getFormatOldControl(JSON.parse(res.config)) : []);
            PageHeaderStore.setLinkageConfig(res.linkConfig ? JSON.parse(res.linkConfig) : []);
            PageHeaderStore.setPagePadding();
          });
        this.lastBoardConfig = boardConfig;
      } else {
        this.filterList = getFormatOldControl(this.props.parentControls);
        this.optionList = [];
        this.initFilter(this.filterList);
      }
    });
    let commonFilterObject = this.props.type !== 'insideCard' ? PageHeaderStore.commonFilterValue : [];
    commonFilterObject = commonFilterObject ? commonFilterObject : [];
    this.filters = commonFilterObject;
    this.setPropsCommonFilter();
    let filtersValue = [];
    commonFilterObject.forEach((item, index) => {
      let value = [];
      item &&
        item.value &&
        Object.keys(item.value).forEach((e) => {
          item.value[e].forEach((d) => {
            if (Array.isArray(d.value)) {
              value = [value, ...d.value];
            } else {
              value = d.value;
            }
          });
        });
      filtersValue[index] = value;
    });
    this.filtersValue = filtersValue;
  }
  componentWillUnmount() {
    this.disposer();
    PageHeaderStore.setQuickFilters(false);
  }
  @observable filterList = []; //筛选列表
  @observable optionList = []; //下拉列表
  @observable filters = []; //存储值
  @observable filtersValue = []; //下拉框值

  //初始化控制器，从url上获取筛选项
  initFilter = (filterConfig) => {
    let haveToReload = false; //url是否带有筛选
    const queryObj = util.getAllQueryString(); //获取url上所有参数
    delete queryObj.idsite;
    delete queryObj.date;
    delete queryObj.userGroupId;
    delete queryObj.period;
    let keyList = []; //获取所有参数key
    for (let key in queryObj) {
      keyList.push(key);
    }
    filterConfig.length &&
      filterConfig.forEach((item, index) => {
        const relatedViews = item.relatedViews;
        if (item.type === 'radio') {
          this.getOption(relatedViews, index);
        }
        let filtersValue = toJS(this.filtersValue);
        if (item.key) {
          filtersValue[index] = getPreciseDefaultValue(item);
          if (item.type === 'date' || item.type === 'datetime' || item.type === 'quickDateRange') {
            filtersValue[index] = getDateDefaultValue(item, filtersValue[index]);
          }
          this.filtersValue = [...filtersValue];
        }
        for (let key in relatedViews) {
          if (keyList.indexOf(relatedViews[key].name) > -1) {
            haveToReload = true;
            const value = decodeURIComponent(queryObj[relatedViews[key].name]);
            switch (item.type) {
              case 'datetime':
              case 'quickDateRange':
                this.onDateChange(item, index, value, null, true);
                break;
              case 'select':
                this.onSelect(value, item, index);
                break;
              case 'search':
                this.onSearchChange(value, index);
                this.handleSearch(value, item, index);
                break;
            }
            keyList.splice(keyList.indexOf(relatedViews[key].name), 1);
          } else if (item.type === 'datetime' || item.type === 'quickDateRange') {
            haveToReload = true;
            if (!item.key) {
              this.onDateChange(item, index, null, 'last30', true);
            }
          }
        }
        this.initRequest(item, filtersValue[index], index);
      });
    haveToReload && this.setPropsCommonFilter();
  };

  initRequest(item, value, index) {
    if (!value || (value instanceof Array && !value.length)) return;
    const isNewControl = item.key;
    switch (item.type) {
      case 'datetime':
      case 'date':
        this.onDateChange(item, index, value.date, null, index === 0, item.type);
        break;
      case 'select':
        this.onSelect(value, item, index, index === 0, isNewControl && !item.multiple, isNewControl ? item.operator : 'in', true);
        break;
      case 'search':
        this.onSearchChange(value, item, index, isNewControl ? item.operator : 'in');
        break;
      case 'radio':
        this.onSumChange(value, item, index, isNewControl ? item.operator : '=');
        break;
      case 'slider':
      case 'numberRange':
        this.onNumberChange(value, item, index);
        break;
    }
    this.setPropsCommonFilter();
  }

  getNewSelectFilter = (newIndex) => {
    let filters = [];
    if (!newIndex && typeof newIndex !== 'number') {
      return [];
    }
    const filter = this.filters[newIndex];
    if (filter && filter.value) {
      for (let key in filter.value) {
        filters = filters.concat(toJS(filter.value[key]));
      }
    }
    return filters;
  };

  //下拉框聚焦时发起请求
  @action.bound
  getOption(relatedViews, index) {
    if (this.optionList[index]) {
      this.optionList[index] = null;
    }
    const current = toJS(this.filterList)[index];
    let parentIndex;
    // 级联查询
    if (current.parent) {
      parentIndex = toJS(this.filterList).findIndex((i) => i.key === current.parent);
    }
    let workViewFilters = [];
    for (let key in relatedViews) {
      normalViews.includes(key) ? workViewFilters.push({ viewName: nameToView[key], ...relatedViews[key] }) : workViewFilters.push({ ...relatedViews[key] });
    }
    util.post(
      api.pack.dimOptions,
      {
        idsite: PageHeaderStore.parameter.idsite,
        dim: 'work_view_filter',
        workViewFilters: JSON.stringify(workViewFilters),
        filters: JSON.stringify(current.key ? this.getNewSelectFilter(parentIndex) : this.getSeletcedFilter(index)),
        // filters: this.getNewSelectFilter(index),
        dashboardId: PageHeaderStore.currentBoardId,
      },
      (res) => {
        let optionList = toJS(this.optionList) || [];
        // 去重
        const data = res.data;
        const keys = Object.keys(data);
        if (keys.length) {
          const arr = data[keys[0]];
          const obj = {};
          data[keys[0]] = arr
            .filter((i) => !!i.value)
            .reduce(function(item, next) {
              obj[next.value] ? '' : (obj[next.value] = true && item.push(next));
              return item;
            }, []);
        }
        optionList[index] = data;
        this.optionList = [...optionList];
      }
    );
  }

  //时间选择
  @action.bound
  onDateChange(item, index, date, lastDate, isRealTime, type = 'datetime') {
    let filtersValue = toJS(this.filtersValue);
    date = lastDate ? mathUtil.keyToDate(lastDate) : date;
    filtersValue[index] = { lastDate: lastDate ? lastDate : '', date };
    this.filtersValue = [...filtersValue];

    let filters = toJS(this.filters);
    if (date) {
      let relatedViews = {};
      const relatedCards = item.relatedCards.map((item) => {
        relatedViews[item.id] = nameToView[item.view] || item.view;
        return item.id;
      });
      let values = {};
      Object.keys(item.relatedViews).map((key) => {
        if (nameToView[key]) {
          let obj = {
            name: item.relatedViews[key].name,
            viewName: nameToView[key],
            value: date,
            operator: '=',
            sqlType: 'DATETIME',
            type: 'filter',
            viewId: key,
          };
          values[nameToView[key]] = [obj];
        } else {
          if (type === 'datetime') {
            let obj1 = {
              name: item.relatedViews[key].name,
              value: `'${date.split(',')[0]}'`,
              operator: '>=',
              sqlType: item.relatedViews[key].sqlType,
              type: 'filter',
              viewId: key,
              workViewParamId: item.relatedViews[key].workViewParamId,
              customType: item.relatedViews[key].customType,
            };
            let obj2 = {
              name: item.relatedViews[key].name,
              value: `'${date.split(',')[1]}'`,
              operator: '<=',
              sqlType: item.relatedViews[key].sqlType,
              type: 'filter',
              viewId: key,
              workViewParamId: item.relatedViews[key].workViewParamId,
              customType: item.relatedViews[key].customType,
            };
            values[key] = [obj1, obj2];
          } else {
            values[key] = [
              {
                name: item.relatedViews[key].name,
                value: `'${date}'`,
                operator: '=',
                sqlType: item.relatedViews[key].sqlType,
                type: 'filter',
                viewId: key,
                workViewParamId: item.relatedViews[key].workViewParamId,
                customType: item.relatedViews[key].customType,
              },
            ];
          }
        }
      });
      filters[index] = { relatedCards, relatedViews, value: values };
    } else {
      filters[index] = null;
    }
    this.filters = [...filters];
    isRealTime && this.setPropsCommonFilter();
  }
  //下拉框选择时的回调
  @action.bound
  onSelect(value, item, index, isRealTime, noMultiple, operator = 'in', isInit = false) {
    let filtersValue = toJS(this.filtersValue);
    if (noMultiple) {
      if (value) {
        filtersValue[index] = [value];
      } else {
        filtersValue[index] = [];
      }
    } else {
      filtersValue[index] = filtersValue[index] || [];
      filtersValue[index].push(value);
    }
    if (!isInit) {
      this.filtersValue = [...filtersValue];
    }

    let filters = toJS(this.filters);
    let relatedViews = {};
    filters[index] = filters[index] || {};
    const relatedCards = item.relatedCards.map((item) => {
      relatedViews[item.id] = item.view;
      return item.id;
    });
    let values = noMultiple ? {} : filters[index].value || {};
    Object.keys(item.relatedViews).map((viewName) => {
      values[viewName] = values[viewName] || [];
      let sameIndex = null;
      let info = item.relatedViews[viewName];
      values[viewName].forEach((e, i) => {
        e.name === info.name && (sameIndex = i);
      });
      if (sameIndex !== null) {
        values[viewName][sameIndex].value.push(`'${value}'`);
      } else {
        let obj = {
          name: info.name,
          value: [`'${value}'`],
          operator: nameToView[viewName] ? '=' : operator,
          sqlType: info.sqlType || 'string',
          type: 'filter',
          viewId: viewName,
          workViewParamId: info.workViewParamId,
          customType: info.customType,
        };
        nameToView[viewName] && (obj.viewName = nameToView[viewName]);
        values[viewName].push(obj);
      }
    });
    filters[index] = { relatedCards, relatedViews, value: values };
    this.filters = [...filters];
    PageHeaderStore.setPagePadding();
    isRealTime && this.setPropsCommonFilter();
  }

  //下拉框删除时的回调
  @action.bound
  onDeselect(value, item, index) {
    let filtersValue = this.filtersValue;
    filtersValue[index].splice(filtersValue[index].indexOf(value), 1);
    this.filtersValue = [...filtersValue];
    // 新控制器
    if (item.key) {
      const { flatTree } = getPanelRenderState('global', toJS(this.filterList), '');
      const childrenKeys = getAllChildren(item.key, flatTree);
      const val = this.filtersValue[index];
      if (childrenKeys.length && (!val || !val.length)) {
        childrenKeys.map((key) => {
          const current = this.filterList.findIndex((filter) => filter.key === key);
          this.filtersValue[current] = undefined;
        });
      }
    }

    let filters = this.filters;
    Object.keys(item.relatedViews).map((viewName) => {
      let sameIndex = null;
      let info = item.relatedViews[viewToName[viewName] ? viewToName[viewName] : viewName];
      filters[index].value[viewName].forEach((e, i) => {
        e.name === info.name && (e.value.splice(e.value.indexOf(`'${value}'`), 1), (sameIndex = i));
      });
      filters[index].value[viewName][sameIndex].value.length === 0 && filters[index].value[viewName].splice(sameIndex, 1);
    });

    this.filters = [...filters];
    // this.setPropsCommonFilter()
  }
  //下拉框搜索时的回调
  @action.bound
  onSearch(value, relatedViews, index) {
    if (this.optionList[index]) {
      this.optionList[index] = null;
    }
    const current = toJS(this.filterList)[index];
    let parentIndex;
    // 级联查询
    if (current.parent) {
      parentIndex = toJS(this.filterList).findIndex((i) => i.key === current.parent);
    }
    let workViewFilters = [];
    let filters = [];
    for (let key in relatedViews) {
      normalViews.includes(key) ? workViewFilters.push({ viewName: nameToView[key], ...relatedViews[key] }) : workViewFilters.push({ ...relatedViews[key] });
      filters.push({
        name: relatedViews[key].fields[0],
        type: 'filter',
        value: [value],
        sqlType: relatedViews[key].sqlType,
        operator: 'like',
        viewId: key,
        filterType: 'likeFilter',
      });
    }
    util.post(
      api.pack.dimOptions,
      {
        idsite: PageHeaderStore.parameter.idsite,
        dim: 'work_view_filter',
        workViewFilters: JSON.stringify(workViewFilters),
        filters: JSON.stringify(filters.concat(current.key ? this.getNewSelectFilter(parentIndex) : this.getSeletcedFilter(index))),
        dashboardId: PageHeaderStore.currentBoardId,
      },
      (res) => {
        let optionList = toJS(this.optionList) || [];
        // 去重
        const data = res.data;
        const keys = Object.keys(data);
        if (keys.length) {
          const arr = data[keys[0]];
          const obj = {};
          data[keys[0]] = arr
            .filter((i) => !!i.value)
            .reduce(function(item, next) {
              obj[next.value] ? '' : (obj[next.value] = true && item.push(next));
              return item;
            }, []);
        }
        optionList[index] = data;
        this.optionList = [...optionList];
      }
    );
  }
  // 搜索框输入的回调
  @action.bound
  onSearchChange(value, item, index, operator = 'in') {
    let filtersValue = toJS(this.filtersValue);
    filtersValue[index] = value;
    this.filtersValue = [...filtersValue];
    this.handleSearch(value, item, index, operator);
  }

  // 滑动值修改
  @action.bound
  onNumberChange(value, item, index) {
    let filtersValue = toJS(this.filtersValue);
    filtersValue[index] = value;
    this.filtersValue = [...filtersValue];
    let filters = toJS(this.filters);
    let relatedViews = {};
    const relatedCards = item.relatedCards.map((item) => {
      relatedViews[item.id] = nameToView[item.view] || item.view;
      return item.id;
    });
    let values = {};
    Object.keys(item.relatedViews).map((key) => {
      let obj1 = {
        name: item.relatedViews[key].name,
        value: `'${value[0]}'`,
        operator: '>=',
        sqlType: item.relatedViews[key].sqlType,
        type: 'filter',
        viewId: key,
        workViewParamId: item.relatedViews[key].workViewParamId,
        customType: item.relatedViews[key].customType,
      };
      let obj2 = {
        name: item.relatedViews[key].name,
        value: `'${value[1]}'`,
        operator: '<=',
        sqlType: item.relatedViews[key].sqlType,
        type: 'filter',
        viewId: key,
        workViewParamId: item.relatedViews[key].workViewParamId,
        customType: item.relatedViews[key].customType,
      };
      values[key] = [obj1, obj2];
    });
    filters[index] = { relatedCards, relatedViews, value: values };
    this.filters = [...filters];
    // PageHeaderStore.setPagePadding();
    // this.setPropsCommonFilter();
  }

  @action.bound
  onSumChange(value, item, index, operator) {
    let filtersValue = toJS(this.filtersValue);
    filtersValue[index] = value;
    this.filtersValue = [...filtersValue];
    let filters = toJS(this.filters);
    let relatedViews = {};
    const relatedCards = item.relatedCards.map((item) => {
      relatedViews[item.id] = nameToView[item.view] || item.view;
      return item.id;
    });
    let values = {};
    Object.keys(item.relatedViews).map((key) => {
      let obj1 = {
        name: item.relatedViews[key].name,
        value: [`'${value}'`],
        operator,
        sqlType: item.relatedViews[key].sqlType,
        type: 'filter',
        viewId: key,
        workViewParamId: item.relatedViews[key].workViewParamId,
        customType: item.relatedViews[key].customType,
      };
      values[key] = [obj1];
    });
    filters[index] = { relatedCards, relatedViews, value: values };
    this.filters = [...filters];
  }

  //搜索框搜索的回调
  @action.bound
  handleSearch(value, item, index, operator) {
    let filters = toJS(this.filters);
    let relatedViews = {};
    filters[index] = filters[index] || {};
    const relatedCards = item.relatedCards.map((item) => {
      relatedViews[item.id] = item.view;
      return item.id;
    });
    let values = filters[index].value || {};
    Object.keys(item.relatedViews).map((viewName) => {
      values[viewName] = values[viewName] || [];
      let sameIndex = null;
      let info = item.relatedViews[viewName];
      values[viewName].forEach((e, i) => {
        e.name === info.name && (sameIndex = i);
      });
      if (sameIndex !== null) {
        values[viewName][sameIndex].value = [`'${value}'`];
      } else {
        let obj = {
          name: info.name,
          value: [`'${value}'`],
          sqlType: info.sqlType || 'string',
          type: 'filter',
          filterType: 'likeFilter',
          viewId: viewName,
          workViewParamId: info.workViewParamId,
          customType: info.customType,
        };
        if (nameToView[viewName]) {
          obj.operator = '=';
        } else {
          obj.operator = operator;
          if (!['=', '!='].includes(operator)) {
            delete obj.filterType;
          }
        }
        nameToView[viewName] && (obj.viewName = nameToView[viewName]);
        values[viewName].push(obj);
      }
    });
    filters[index] = { relatedCards, relatedViews, value: values };
    this.filters = [...filters];
  }

  // 组装筛选数据
  getCommonFilter() {
    const filters = [];
    this.filters &&
      this.filters.forEach((item) => {
        item && item.value && filters.push(item);
      });
    return filters;
  }
  // 获取已筛选数量，第一个除外
  getFilterCount = () => {
    let count = 0;
    toJS(this.filtersValue).forEach((item, index) => {
      (util.showNewMobileStyle() ? true : index !== 0) && item && (count += 1);
    });
    return count;
  };
  @action.bound
  setPropsCommonFilter() {
    if (JSON.stringify(this.lastFilters) !== JSON.stringify(this.filters)) {
      const filters = this.getCommonFilter();
      this.props.setCommonFilter && this.props.setCommonFilter([...filters]);
      this.props.setCommonFilterValue(this.filters);
      this.lastFilters = JSON.parse(JSON.stringify(this.filters));
      const filterCount = this.getFilterCount();
      PageHeaderStore.setGlobalControlFilterCount(filterCount);
    }
  }
  // 清空筛选
  @action.bound
  cleanFilter() {
    this.initFilter(this.filterList);
    const filterCount = this.getFilterCount();
    PageHeaderStore.setGlobalControlFilterCount(filterCount);
    this.filters = [];
    this.setPropsCommonFilter();
    PageHeaderStore.setPagePadding(400);
    // let filters = toJS(this.filters)
    // let filtersValue = toJS(this.filtersValue)
    // this.filterList.forEach((item, index) => {
    //   switch (item.type) {
    //     case 'datetime':
    //       if (!item.key) {
    //         this.onDateChange(item, index, null, 'last30');
    //       } else {
    //         this.filters[index] = null;
    //         this.filtersValue[index] = null;
    //       }
    //       // filtersValue[index] = { lastDate: 'last30', date: mathUtil.keyToDate('last30') }
    //       break;
    //     case 'slider':
    //     case 'numberRange':
    //       this.filters[index] = null;
    //       this.filtersValue[index] = [];
    //       break;
    //     default:
    //       this.filters[index] = null;
    //       this.filtersValue[index] = null;
    //       break;
    //   }
    // });
    // this.filtersValue = [...filtersValue]
  }
  //获取已选的所有筛选条件
  getSeletcedFilter(focusIndex) {
    let filters = [];
    this.filters.forEach((item, index) => {
      if (focusIndex !== index && item && item.value) {
        for (let key in item.value) {
          filters = filters.concat(toJS(item.value[key]));
        }
      }
    });
    return filters;
  }

  mobileFilterOverflowToggle = (bool) => {
    this.setState({
      mobileFilterOverflow: bool,
    });
  };

  mobileSelectToggle = (index, item) => {
    this.filterList[index].visible = !this.filterList[index].visible;
    if (this.filterList[index].visible) {
      this.getOption(item.relatedViews, index);
    }
    this.forceUpdate();
  };

  //让选择框内容始居中
  isEmptySelect = (item, index, filtersValue) => {
    return item.type === 'select' && (!filtersValue[index] || !(filtersValue[index] && filtersValue[index].length));
  };

  renderNewMobileControl = (item, index, filtersValue) => {
    const isNewControl = item.key;
    switch (item.type) {
      case 'datetime':
      case 'date':
      case 'quickDateRange':
        return (
          <MobileV5Calandar
            tagClick={item.show}
            index={index}
            onToggle={this.mobileFilterOverflowToggle}
            closeCalender={this.closeCalender}
            className="quick-filter-select"
            dateChange={(lastDate, dateArr) =>
              this.onDateChange(item, index, item.type !== 'date' ? dateArr.join(',') : dateArr, lastDate, index === 0, item.type)
            }
            value={
              filtersValue[index] && filtersValue[index] && filtersValue[index].date
                ? item.type !== 'date'
                  ? [moment(filtersValue[index].date.split(',')[0]), moment(filtersValue[index].date.split(',')[1])]
                  : [moment(filtersValue[index].date)]
                : null
            }
            lastDate={filtersValue[index] && filtersValue[index].lastDate ? filtersValue[index].lastDate : null}
            type={item.type}
            isNewControl={isNewControl}
          />
        );
      case 'select':
        return (
          <MobileSelect
            visible={item.visible || false}
            onToggle={() => {
              this.mobileSelectToggle(index, item);
            }}
            onDeselect={(value) => this.onDeselect(value, item, index)}
            onSelect={(value) => this.onSelect(value, item, index, index === 0, isNewControl && !item.multiple, isNewControl ? item.operator : 'in')}
            onSearch={_.debounce((value) => this.onSearch(value, item.relatedViews, index), 200)}
            value={filtersValue[index] || []}
            index={index}
            optionList={this.optionList}
            item={item}
            isNewControl={isNewControl}
            multiple={item.multiple}
          />
        );
      case 'search':
        return (
          <SearchBarV5
            placeholder="Search"
            value={filtersValue[index] || ''}
            onChange={(value) => this.onSearchChange(value, item, index, isNewControl ? item.operator : 'in')}
            onSearch={() => {
              index === 0 && this.setPropsCommonFilter();
            }}
          />
        );
      case 'radio':
        let options = [];
        if (this.optionList && this.optionList[index]) {
          Object.keys(this.optionList[index]).map((key) => {
            let el = this.optionList[index];
            options = el[key].map((o) => {
              return {
                [o.key]: o.value,
              };
            });
          });
        }
        return (
          <MobileRadio
            control={item}
            value={filtersValue[index]}
            options={transformOptions(item, options || [])}
            onChange={(e) => {
              this.onSumChange(e.target.value, item, index, isNewControl ? item.operator : '=');
            }}
          />
        );
      case 'slider':
        return (
          <MobileSlider
            value={filtersValue[index]}
            control={item}
            onChange={(value) => {
              this.onNumberChange(value, item, index);
            }}
          />
        );
      case 'numberRange':
        return (
          <div style={{ width: '50%' }}>
            <MobileNumberRange
              value={filtersValue[index]}
              onChange={(value) => {
                this.onNumberChange(value, item, index);
              }}
            />
          </div>
        );

      default:
        return <div>开发中..</div>;
    }
  };

  renderMobileFilter = (item, index, filtersValue) => {
    if (item.key) {
      const withKeyValues = getWithKeyValue(this.filterList, filtersValue);
      if (!getControlVisibility(this.filterList, item, withKeyValues)) {
        return null;
      }
    }

    return (
      <div>
        <div className="mobile-filters-item">
          <div className={`mobile-filters-item-header`}>{item.name}</div>
          {item.type === 'select' && (!filtersValue[index] || (Array.isArray(filtersValue[index]) && !filtersValue[index].length)) && (
            <div className="mobile-filters-item-content">
              <Tag
                className="mobile-main-color"
                style={{ fontSize: '14px', padding: '1px 5px' }}
                onClick={() => {
                  this.mobileSelectToggle(index, item);
                }}>
                <i onClick={() => {}} style={{ fontSize: '14px' }} className="iconfont icon-jia mobile-filter-add-icon" />
                <span style={{ paddingLeft: '5px' }}>选择</span>
              </Tag>
            </div>
          )}
          {
            <div className={`mobile-filters-item-content ${this.isEmptySelect(item, index, filtersValue) ? 'mobile-filters-empty-select-content' : ''}`}>
              {this.renderNewMobileControl(item, index, filtersValue)}
            </div>
          }
        </div>
        <Divider className="mobile-filters-divider" />
      </div>
    );
  };

  renderMobileControl = (item, index, filtersValue) => {
    const isNewControl = item.key;
    switch (item.type) {
      case 'datetime':
      case 'date':
        return (
          <MobileCalandar
            // defaultValue={[moment(store.parameter.date.split(',')[0]), moment(store.parameter.date.split(',')[1])]}
            onToggle={this.mobileFilterOverflowToggle}
            className="quick-filter-select"
            dateChange={(lastDate, dateArr) => this.onDateChange(item, index, item.type !== 'date' ? dateArr.join(',') : dateArr, null, index === 0, item.type)}
            value={
              filtersValue[index] && filtersValue[index] && filtersValue[index].date
                ? [moment(filtersValue[index].date.split(',')[0]), moment(filtersValue[index].date.split(',')[1])]
                : null
            }
            type={item.type}
            isNewControl={item.key}
          />
        );
      case 'select':
        return (
          <Select
            className="quick-filter-select"
            id={`${index}`}
            getPopupContainer={() => document.getElementById(`${index}`)}
            mode={item.key && !item.multiple ? 'default' : 'multiple'}
            onFocus={() => this.getOption(item.relatedViews, index)}
            onDeselect={(value) => this.onDeselect(value, item, index)}
            onSelect={(value) => this.onSelect(value, item, index, index === 0, item.key && !item.multiple, isNewControl ? item.operator : 'in')}
            value={filtersValue[index] || []}
            onSearch={_.debounce((value) => this.onSearch(value, item.relatedViews, index), 200)}>
            {this.optionList && this.optionList[index] ? (
              Object.keys(this.optionList[index]).map((key) => {
                let el = this.optionList[index];
                return el[key].map((e) => {
                  let info = item.relatedViews[viewToName[key] || key];
                  return info && e.value && <Select.Option key={e.value}>{e.value}</Select.Option>;
                });
              })
            ) : (
              <Select.Option key="loading" disabled>
                加载中...
              </Select.Option>
            )}
          </Select>
        );
      case 'search':
        return (
          <SearchBar
            placeholder="Search"
            onChange={(value) => this.onSearchChange(value, item, index, isNewControl ? item.operator : 'in')}
            onSearch={() => {
              index === 0 && this.setPropsCommonFilter();
            }}
            value={filtersValue[index] || ''}
          />
        );
      case 'radio':
        let options = [];
        if (this.optionList && this.optionList[index]) {
          Object.keys(this.optionList[index]).map((key) => {
            let el = this.optionList[index];
            options = el[key].map((o) => {
              return {
                [o.key]: o.value,
              };
            });
          });
        }
        return (
          <div style={{ padding: '10px 0' }}>
            <MobileRadio
              control={item}
              value={filtersValue[index]}
              options={transformOptions(item, options || [])}
              onChange={(e) => {
                this.onSumChange(e.target.value, item, index, isNewControl ? item.operator : '=');
              }}
            />
          </div>
        );
      case 'slider':
        return (
          <div style={{ padding: '10px 0' }}>
            <MobileSlider
              value={filtersValue[index]}
              control={item}
              onChange={(value) => {
                this.onNumberChange(value, item, index);
              }}
            />
          </div>
        );
      case 'numberRange':
        return (
          <div style={{ width: '50%', padding: '10px 0' }}>
            <MobileNumberRange
              value={filtersValue[index]}
              onChange={(value) => {
                this.onNumberChange(value, item, index);
              }}
            />
          </div>
        );

      default:
        return <div>开发中...</div>;
    }
  };

  getMobileComponent = (item, index, filtersValue) => {
    return (
      <div className={`quick-filter-item mobile-quick-filter-item ${index === 0 ? 'first-mobile-quick-filter-item' : ''}`} key={`${item.name}${index}`}>
        {index !== 0 && item.name}
        {this.renderMobileControl(item, index, filtersValue)}
      </div>
    );
  };

  handleOpenResetModal = () => {
    this.setState({
      resetModalVisible: true,
    });
  };

  handleCloseResetModal = () => {
    this.setState({
      resetModalVisible: false,
    });
  };

  handleTurn = (way) => {
    let { current, step } = this.state;
    const isEnd = current % step === 0;
    let page = Math.floor(current / step);
    let destination;
    if (way === 'left') {
      if (isEnd) {
        destination = (page - 2) * step;
      } else {
        destination = (page - 1) * step;
      }
      current = current - step;
    } else {
      if (isEnd) {
        destination = page * step;
      } else {
        destination = (page + 1) * step;
      }
      current = current + step;
    }
    this.setState({
      showRange: [destination, destination + step],
      current: current,
    });
  };

  tagVisible = (filtersValue, current) => {
    const filter = this.filterList[current - 1];
    if (filter.key) {
      const withKeyValues = getWithKeyValue(this.filterList, filtersValue);
      if (!getControlVisibility(this.filterList, filter, withKeyValues)) {
        return false;
      }
    }
    return true;
  };

  renderTag = (filtersValue, current) => {
    const filter = this.filterList[current - 1];
    const type = filter.type;
    const isNewControl = filter.key;
    const isEmpty = !filtersValue[current - 1] || (Array.isArray(filtersValue[current - 1]) && !filtersValue[current - 1].length);
    switch (type) {
      case 'datetime':
        return isEmpty ? '请设置时间' : `${filtersValue[current - 1].date.split(',')[0]}~${filtersValue[current - 1].date.split(',')[1]}`;
      case 'date':
        return isEmpty ? '请设置时间' : filtersValue[current - 1].date;
      case 'select':
        return isEmpty ? '请选择' : filter.key && !filter.multiple ? filtersValue[current - 1] : filtersValue[current - 1].join(',');
      case 'radio':
      case 'search':
        return isEmpty ? '请设置' : filtersValue[current - 1];
      case 'slider':
      case 'numberRange':
        return isEmpty ? '请设置' : filtersValue[current - 1].join('~');
    }
  };

  closeCalender = (index) => {
    this.filterList[index].show = false;
    this.forceUpdate();
  };
  render() {
    const filtersValue = toJS(this.filtersValue);
    const { resetModalVisible, current, step } = this.state;
    return this.filterList ? (
      <Fragment>
        {/* 新版控制器 */}
        {util.isMobile() &&
          util.showNewMobileStyle() &&
          (this.filterList.length > 0 ? (
            <div className="mobile-filters-outer">
              {this.tagVisible(filtersValue, current) ? (
                <div
                  className="mobile-filters-outer-tag"
                  onClick={() => {
                    const filter = this.filterList[current - 1];
                    const type = filter.type;
                    if (['select'].includes(type)) {
                      this.mobileSelectToggle(current - 1, filter);
                    }
                    if (['datetime', 'date'].includes(type)) {
                      this.filterList[current - 1].show = true;
                      this.forceUpdate();
                    }
                    PageHeaderStore.mobileQuickFilterToggle();
                  }}>
                  {this.renderTag(filtersValue, current)}
                </div>
              ) : (
                <div></div>
              )}
              <div style={{ lineHeight: '32px' }}>
                <MobileArrows
                  iconType={2}
                  total={this.filterList.length}
                  current={current}
                  step={step}
                  iconLeftStyle={{ marginRight: '5px', fontSize: '15px' }}
                  iconRightStyle={{ marginLeft: '5px', fontSize: '15px' }}
                  onClickLeft={() => {
                    this.handleTurn('left');
                  }}
                  onClickRight={() => {
                    this.handleTurn('right');
                  }}
                  withPage={true}
                  pageText={`${current}/${this.filterList.length}`}
                />
              </div>
            </div>
          ) : (
            <div className="mobile-filters-outer"></div>
          ))}
        {this.filterList.map((item, index) => {
          return item.type === 'datetime' || item.type === 'quickDateRange' ? (
            <ControlRangePicker
              getDateAndlastDate={this.onDateChange}
              lastDate={filtersValue[index] ? filtersValue[index].lastDate : null}
              date={filtersValue[index] ? filtersValue[index].date : null}
              item={item}
              index={index}
            />
          ) : item.type === 'select' ? (
            <ControlSelect
              onFocus={this.getOption}
              onDeselect={this.onDeselect}
              onSelect={this.onSelect}
              value={filtersValue[index] || []}
              onSearch={this.onSearch}
              optionList={this.optionList && this.optionList[index] ? this.optionList[index] : null}
              item={item}
              index={index}
              style={{ minWidth: '180px' }}
            />
          ) : (
            <ControlSearch value={filtersValue[index] || null} onChange={this.onSearchChange} item={item} index={index} style={{ minWidth: '180px' }} />
          );
        })}
        {/* <Button className="quick-filter-btn quick-filter-icon-btn pc-quick-filter" onClick={() => this.setShowQuickFilterModal(true)}>
          <i className="iconfont icon-jia" />
        </Button> */}
        {this.filterList && this.filterList.length ? (
          <Fragment>
            <Button type="primary" className="quick-filter-btn pc-quick-filter" onClick={this.setPropsCommonFilter}>
              {intl.get('Query')}
            </Button>
            <Button className="quick-filter-btn pc-quick-filter clean-btn" onClick={this.cleanFilter}>
              {intl.get('Clear')}
            </Button>
          </Fragment>
        ) : null}
        {PageHeaderStore.isShowMobileQuickFilter && util.isMobile() && util.showNewMobileStyle() && (
          <div className="mobile-filters">
            {/* <div className="mobile-filters-header">
              <Button
                className="mobile-filters-back"
                type="primary"
                ghost
                onClick={() => {
                  PageHeaderStore.mobileQuickFilterToggle();
                }}>
                <i className="iconfont icon-fenye-fanye" style={{ marginRight: '5px' }} />
                返回
              </Button>
              <Button
                type="primary"
                className="mobile-filters-reset"
                onClick={() => {
                  this.handleOpenResetModal();
                }}>
                <i className="iconfont icon-zhongzhi" style={{ marginRight: '5px' }} />
                重置
              </Button>
            </div> */}
            <div className="mobile-filters-content">
              <div className="mobile-filters-header">
                {/* <Button
                  className="mobile-filters-back"
                  type="primary"
                  ghost
                  onClick={() => {
                    PageHeaderStore.mobileQuickFilterToggle();
                  }}>
                  <i className="iconfont icon-fenye-fanye" style={{ marginRight: '5px' }} />
                  返回
                </Button> */}
                <div>
                  <i
                    onClick={() => {
                      PageHeaderStore.mobileQuickFilterToggle();
                    }}
                    className="iconfont icon-fenye-fanye MAIN-COLOR"
                    style={{ marginRight: '5px' }}
                  />
                </div>
                <Button
                  // type="primary"
                  className="mobile-filters-reset reloadButton"
                  onClick={() => {
                    this.handleOpenResetModal();
                  }}>
                  <i className="iconfont icon-zhongzhi" style={{ marginRight: '5px' }} />
                  重置
                </Button>
              </div>
              {this.filterList.map((item, index) => {
                return this.renderMobileFilter(item, index, filtersValue);
              })}
            </div>
            <div className="mobile-filters-footer">
              <Button
                type="primary"
                className="mobile-filters-sure"
                onClick={() => {
                  this.setPropsCommonFilter();
                  PageHeaderStore.mobileQuickFilterToggle();
                }}>
                确定
              </Button>
            </div>
          </div>
        )}
        {/* 筛选框移动端 */}
        {!(util.isMobile() && util.showNewMobileStyle()) && (
          <div className="quick-filter">
            {this.filterList.length ? this.getMobileComponent(this.filterList[0], 0, filtersValue) : null}
            <div
              className={`mobile-quick-filter ${PageHeaderStore.isShowMobileQuickFilter ? 'show' : 'hide'} ${
                this.state.mobileFilterOverflow ? 'scroll-mobile-quick-filter' : 'visible-mobile-quick-filter'
              }`}>
              {this.filterList.slice(1).map((item, index) => {
                return this.getMobileComponent(item, index + 1, filtersValue);
              })}
              <div className="mobile-quick-filter-footer">
                <Button
                  type="primary"
                  className="quick-filter-btn"
                  onClick={() => {
                    this.setPropsCommonFilter();
                    PageHeaderStore.mobileQuickFilterToggle();
                  }}>
                  {intl.get('Query')}
                </Button>
                <Button
                  type="primary"
                  className="quick-filter-btn"
                  onClick={() => {
                    this.cleanFilter(true);
                    PageHeaderStore.mobileQuickFilterToggle();
                  }}>
                  {intl.get('Clear')}
                </Button>
              </div>
            </div>
          </div>
        )}
        <Mask
          visible={resetModalVisible}
          onMaskClick={() => {
            this.handleCloseResetModal();
          }}>
          <div className="mobile-reset-modal">
            <div className="mobile-reset-modal-main">您确定要重置筛选条件吗</div>
            <div className="mobile-reset-modal-buttons">
              <div
                className="mobile-reset-modal-button"
                style={{ borderRight: '1px #D9D9DE solid' }}
                onClick={() => {
                  this.cleanFilter(true);
                  PageHeaderStore.mobileQuickFilterToggle();
                  this.handleCloseResetModal();
                }}>
                确定
              </div>
              <div
                className="mobile-reset-modal-button"
                onClick={() => {
                  this.handleCloseResetModal();
                }}>
                取消
              </div>
            </div>
          </div>
        </Mask>
      </Fragment>
    ) : (
      <Loading size="small" />
    );
  }
}
export default QuickFilter;
