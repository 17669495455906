import React, { Component } from 'react';
import store from '../Sidebar/store';
import pageHeaderStore from '../PageHeader/PageHeaderStore';
import './index.sass';
import sideMenu from '../../config/sideMenu';
import { toJS, observable, autorun, action } from 'mobx';
import { withRouter, NavLink } from 'react-router-dom';
import util from '../../util';
import api from '../../api';
import loginStore from '../../Personal/Login/store';
import { observer } from 'mobx-react';
import BoardCustomStore from '../../views/BoardCustomList/store';
import { Divider } from 'antd-mobile';
import sidebarStore from '../Sidebar/store';
import { CheckList, Popup, SearchBar } from 'antd-mobile';
import manageUtil from '../../Product/Management/util';

const tabs = [{ title: '洞察' }, { title: '行动' }, { title: '个人' }];

const otherList = [];

// const otherList = [{ title: '个人中心', icon: 'icon-gerenzhongxin1', href: '/person' }];

@withRouter
@observer
class MobileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '',
      productList: pageHeaderStore.dropdownList.product,
      initialProductList: pageHeaderStore.dropdownList.product,
    };
    this.lastProps = {};
    this.boardList = [];
    this.actionList = [];
    this.personalList = [];
    this.boardCustomList = [];
    autorun(() => {
      const length = this.boardCustomList.length;
      if (BoardCustomStore.list && BoardCustomStore.list.length) {
        this.boardCustomList = BoardCustomStore.list;
      }
      if (length) {
        this.getFolderDashboardList();
      }
    });
  }
  @observable allMenus;

  componentDidMount() {
    store.isCollapsed = true;
    this.allMenus = sideMenu;
    this.initActiveKey();
    this.getBoard = autorun(() => {
      let idsite = pageHeaderStore.parameter.idsite;
      let currentProps = {
        idsite,
      };
      if (JSON.stringify(currentProps) !== JSON.stringify(this.lastProps)) {
        this.getSidebarMenuList();
        this.lastProps = currentProps;
      }
    });
    this.getBoardList = autorun(() => {
      let idsite = pageHeaderStore.parameter.idsite;
      if (idsite !== this.lastIdsite) {
        this.getFolderDashboardList();
        this.lastIdsite = idsite;
      }
    });
  }

  componentDidUpdate(preProps) {
    if (this.props.location.pathname !== preProps.location.pathname && this.allMenus) {
      this.getSidebarMenuList();
    }
  }

  initActiveKey = () => {
    const pathName = this.props.location.pathname;
    if (this.checkMenu(['/action'], pathName)) {
      this.setState({
        activeKey: '行动',
      });
      return;
    } else if (this.checkMenu(['/customDetail'], pathName)) {
      this.setState({
        activeKey: '洞察',
      });
      return;
    } else {
      this.setState({
        activeKey: '个人',
      });
    }
  };

  // 看板文件夹层级
  listToTree(list, parentId) {
    const ret = [];
    for (const i in list) {
      if (list[i].parentId === parentId) {
        const leftList = list.filter((l) => l.id !== list[i].id);
        list[i].folder && (list[i].children = this.listToTree(leftList, list[i].id));
        ret.push(list[i]);
      }
    }
    return ret;
  }

  getFolderDashboardList = () => {
    const pathName = this.props.location.pathname;
    const pathNameWithOutParam = pathName.substr(0, pathName.indexOf('?') > '-1' ? pathName.indexOf('?') : pathName.length);
    util.get(api.folder.getFolderDashboardList, { idsite: pageHeaderStore.parameter.idsite }, (res) => {
      if (res) {
        if (res.length !== 0) {
          store.setAllFolderList(res);
          // const list = [];
          // res.forEach((item) => {
          //   if (item.type === 2) {
          //     item.key = 'dashboard';
          //     item.href = `/customDetail/${item.id}`;
          //     item.show = true;
          //     if (item.parentId === null) {
          //       item.parentId = 0;
          //     }
          //     list.push(item);
          //   }
          // });
          // this.boardList = list;
          let allMenus = this.allMenus;
          // allMenus.boardInsight[this.allMenus.boardInsight.length - 2].children = [];
          allMenus.boardInsight[this.allMenus.boardInsight.length - 1].children = [];
          let custom = [];
          // let share = [];
          if (res.length !== 0) {
            // let questionnaireDashboard = [];
            // let surveyDetailDashboard = [];
            let hasPush = false;
            res.forEach((item) => {
              item.key = 'dashboard';
              if (!item.folder) {
                const link = manageUtil.getBoardLink({ ...item, type: store.dashboardType });
                item.href = link;
                item.show = true;
              }
              if (item.parentId === null) {
                item.parentId = 0;
              }
              custom.push(item);
              // !item.share ? custom.push(item) : share.push(item);
            });
            // allMenus.questionnaire.splice(1, 0, ...questionnaireDashboard);
            // allMenus.questionnaire.splice(-1, 0, ...surveyDetailDashboard);
            const customDashboard = this.listToTree(custom, 0);
            // const shareDashboard = this.listToTree(share, 0);
            allMenus.boardInsight[this.allMenus.boardInsight.length - 1].children = customDashboard;
            // allMenus.boardInsight[this.allMenus.boardInsight.length - 1].children = shareDashboard;
            this.getSidebarMenuList();
          }
        }
      } else {
        loginStore.setPermission(true);
      }
    });
  };

  checkMenu(paths, pathName) {
    let flag = false;
    paths.forEach((item) => {
      pathName.includes(item) && (flag = true);
    });
    return flag;
  }

  handleSelect = (list, select) => {
    if (!list.length) {
      return;
    }
    list.forEach((l) => {
      if (l.href === select) {
        l.isActive = true;
      } else {
        l.isActive = false;
      }
    });
  };

  getSidebarMenuList = () => {
    const pathName = this.props.location.pathname;
    const pathNameWithOutParam = pathName.substr(0, pathName.indexOf('?') > '-1' ? pathName.indexOf('?') : pathName.length);
    this.allMenus && this.allMenus.boardInsight ? this.allMenus.boardInsight : this.allMenus.questionnaire;
    this.allMenus && this.allMenus.boardAction && (this.actionList = this.allMenus.boardAction[0].children);
    this.allMenus && this.allMenus.account && (this.personalList = this.allMenus.account[0].children.slice(0, 2));
    this.boardList = this.allMenus.boardInsight.find((i) => i.name === '自建看板').children;
    this.handleSelect(this.boardList, pathNameWithOutParam);
    this.handleSelect(this.actionList, pathNameWithOutParam);
    this.handleSelect(this.personalList, pathNameWithOutParam);
  };

  setActiveKey = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  navigate = (href) => {
    // store.isCollapsed = false;
    sidebarStore.collapsedChange();
    this.props.history.push(href);
  };

  getStatus = (currentIndex) => {
    const { activeKey } = this.state;
    const activeIndex = tabs.findIndex((i) => i.title === activeKey);
    let isTop = false,
      isBottom = false;
    if (currentIndex === activeIndex - 1) {
      isTop = true;
    }
    if (currentIndex === activeIndex + 1) {
      isBottom = true;
    }
    return `${isTop ? 'isTop' : ''} ${isBottom ? 'isBottom' : ''}`;
  };

  @action.bound
  handleSearch = (value) => {
    const { initialProductList } = this.state;
    this.setState({ productList: value ? initialProductList.filter((product) => product.label.includes(value)) : initialProductList });
  };

  changeProduct = (val) => {
    manageUtil.changeLinkPath(this.props.history, val[0]);
    sidebarStore.closeCheckList();
    store.collapsedClosed();
  };

  renderBoard = (i) => {
    return (
      <>
        <div
          id={i.isActive ? 1 : ''}
          className={`${i.isActive ? 'active' : ''}`}
          onClick={() => {
            if (i.isFolder) {
              return;
            }
            this.navigate(i.href);
          }}>
          <div className="sidebar-item">
            {i.isFolder && <i className="iconfont icon-bihewenjianjia" style={{ marginLeft: '-20px', marginRight: '5px' }}></i>}
            {i.name}
          </div>
          {!i.isActive && <Divider style={{ margin: 0, height: '2px' }} />}
        </div>
        {i.isFolder &&
          !!i.children.length &&
          i.children.map((o) => {
            return this.renderBoard(o);
          })}
      </>
    );
  };

  render() {
    const pathName = this.props.location.pathname;
    const { activeKey, productList } = this.state;
    return (
      <nav id="sidebar" className={`navbar-static-side ${store.isCollapsed ? 'collapsed-sidebar' : 'mobile-sidebar'}`}>
        <div className="sidebar-main">
          <div>
            {tabs.map((item, index) => (
              <div
                key={item.title}
                onClick={() => {
                  this.setActiveKey(item.title);
                }}
                className={`sidebar-menu-item ${this.getStatus(index)} ${item.title === activeKey ? 'isActive' : ''}`}>
                {item.title === activeKey && <div className="highlight"></div>}
                {item.title}
              </div>
            ))}
          </div>
          <div className={`other ${this.getStatus(tabs.length)}`}>
            {otherList.map((i) => {
              return (
                <div
                  className="sidebar-icon-container"
                  onClick={() => {
                    this.navigate(i.href);
                  }}>
                  {/* <i style={{ color: '#7a8493', paddingRight: '4px' }} className={`iconfont ${i.icon}`} /> */}
                  {i.title}
                </div>
              );
            })}
          </div>
        </div>
        {activeKey === '洞察' && (
          <div className="sidebar-content">
            {this.boardList.length > 0 &&
              this.boardList.map((i) => {
                return this.renderBoard(i);
              })}
          </div>
        )}
        {activeKey === '行动' && (
          <div className="sidebar-content">
            {this.actionList.length > 0 &&
              this.actionList
                .filter((i) => i.show)
                .map((i) => {
                  return (
                    <div
                      className={`${i.isActive ? 'active' : ''}`}
                      onClick={() => {
                        this.navigate(i.href);
                      }}>
                      <div className="sidebar-item">{i.name}</div>
                      {!i.isActive && <Divider style={{ margin: 0, height: '2px' }} />}
                    </div>
                  );
                })}
          </div>
        )}
        {activeKey === '个人' && (
          <div className="sidebar-content">
            {this.personalList.length > 0 &&
              this.personalList
                .filter((i) => i.show)
                .map((i) => {
                  return (
                    <div
                      className={`${i.isActive ? 'active' : ''}`}
                      onClick={() => {
                        this.navigate(i.href);
                      }}>
                      <div className="sidebar-item">{i.name}</div>
                      {!i.isActive && <Divider style={{ margin: 0, height: '2px' }} />}
                    </div>
                  );
                })}
          </div>
        )}
        <Popup
          visible={sidebarStore.isShowCheckList}
          onMaskClick={() => {
            sidebarStore.closeCheckList();
          }}
          destroyOnClose>
          <div className={'searchBarContainer'}>
            <SearchBar
              onChange={(value) => {
                this.handleSearch(value);
              }}
            />
          </div>
          <div className="checkContainer">
            <CheckList
              className="checkList"
              onChange={(val) => {
                this.changeProduct(val);
              }}>
              {productList.map((item) => (
                <CheckList.Item key={item.value} value={item.value}>
                  <div className="checkItem">{item.label}</div>
                </CheckList.Item>
              ))}
            </CheckList>
          </div>
        </Popup>
      </nav>
    );
  }
}

export default MobileSidebar;
