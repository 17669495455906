import SurveyTable from '../../containers/Wenjuan'

export default {
  title: '问卷数据',
  components: [
    {
      title: '问卷数据',
      isCard: true,
      children: [{ component: SurveyTable, props: { type: 'download' } }]
    }
  ]
}
