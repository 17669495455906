import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import intl from 'react-intl-universal';
import ControllerList from './ControllerList';
import ConfigForm from './ConfigForm';
import util from '../../../../util';
import api from '../../../../api';
import PageHeaderStore from '../../PageHeaderStore';
import './index.sass';

// config格式
// let config = [{
//     name: '控制1', //控制器名
//     type: 'select', //组件类型
//     relatedCards: [{ type: '10', id: '19' }],    //关联卡片，type：分析类型，id：卡片id
//     relatedViews: { 64: { name: 'var1', sqlType: 'string' } }//{ 64: 'var1' }  //关联工作表，viewName：工作表，fieldName:字段
// }]
function GlobalControlModal(props) {
  const { visible, onHide, form } = props;
  const [controllerList, setControllerList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [viewOptions, setViewOptions] = useState({});
  const [currentControllerIndex, setCurrentControllerIndex] = useState('0');
  const [isShowForm, setIsShowForm] = useState(true);

  const analysisList = [1, 2, 3, 8]; //  旧版分析列表
  const visitsAnalysisList = [1, 2, 3]; //  动作表分析列表

  useEffect(() => {
    // 获取控制器列表
    PageHeaderStore.currentBoardId &&
      util.get(api.globalControl.list, { id: PageHeaderStore.currentBoardId }, (res) => {
        setControllerList(res.config ? JSON.parse(res.config) : []);
        setListLoading(false);
      });
    // setControllerList(config)
    // setListLoading(false)
  }, [PageHeaderStore.currentBoardId]);

  function configSave(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        util.post(
          api.globalControl.save,
          {
            id: PageHeaderStore.currentBoardId,
            config: JSON.stringify(controllerList),
          },
          (res) => {
            res.code === 200 ? (message.success('保存成功'), onHide(), PageHeaderStore.reloadGlobalContol()) : message.error(res.msg);
          }
        );
      }
    });
  }

  function checkForm(callback) {
    form.validateFields((err, values) => {
      console.log('Received values of form: ', values);
      !err && callback && callback();
    });
  }

  function getOptions(type, view) {
    if (!viewOptions[view]) {
      const viewOptionsCopy = viewOptions;
      if (type === 10) {
        util.get(`${api.BI.views}/${view}`, {}, (res) => {
          const list = res.fieldModelList.map((item) => {
            return {
              ...item,
              value: item.fieldName,
              label: item.fieldDisplay || item.fieldName,
            };
          });
          viewOptionsCopy[view] = { name: res.name, list };
          setViewOptions({ ...viewOptionsCopy });
        });
      } else if (analysisList.includes(type)) {
        util.post(
          api.pack.dimOptions,
          {
            idsite: PageHeaderStore.parameter.idsite,
            dim: 'work_view_field',
            search: view,
          },
          (res) => {
            const result = res.data.visit_action_union_optimize || res.data.answer_visit_user_view;
            const list = result.map((item) => {
              return {
                ...item,
                value: item.fieldName,
                label: item.fieldDisplay,
              };
            });
            viewOptionsCopy[view] = { name: visitsAnalysisList.includes(type) ? '动作表' : '答案表', list };
            setViewOptions({ ...viewOptionsCopy });
          }
        );
      }
    }
  }

  // 菜单切换且销毁原有表单
  function setIndex(index) {
    setIsShowForm(false);
    setCurrentControllerIndex(index);
    setTimeout(() => {
      setIsShowForm(true);
    }, 0);
  }
  return (
    <Modal
      visible={visible}
      title={intl.get('GlobalControl')}
      className="global-control-modal"
      onCancel={onHide}
      onOk={configSave}
      width="90%"
      centered
      okText="保存"
      cancelText="取消">
      <ControllerList
        controllerList={controllerList}
        setControllerList={setControllerList}
        loading={listLoading}
        setIndex={setIndex}
        currentIndex={currentControllerIndex}
        checkForm={checkForm}
      />
      {controllerList[currentControllerIndex] && isShowForm && (
        <ConfigForm
          controllerList={controllerList}
          setControllerList={setControllerList}
          currentIndex={currentControllerIndex}
          config={controllerList[currentControllerIndex]}
          form={form}
          viewOptions={viewOptions}
          getOptions={getOptions}
        />
      )}
    </Modal>
  );
}

export default Form.create()(GlobalControlModal);
