import moment from 'moment';
import pageHeaderStore from '../component/PageHeader/PageHeaderStore';
import util from '../util';
import filtersUtil from './filtersUtil';
import customBoardUtil from './customBoardUtil';
import mathUtil from './mathUtil';
import chartUtil from './chartUtil';
import legendUtil from './legendUtil';
import { currency, eventTools } from './analyseUtil';
import { toJS } from 'mobx';

export default {
  setDocumentTitle(title) {
    document.title = process.env.REACT_APP_BDfixTitle || `${title} - ${pageHeaderStore.productName || util.getQueryString('productName') || ''}`;
  },
  getFetchParams(props, pageParam) {
    const paramsObj = {
      idsite: pageHeaderStore.parameter.idsite,
      date: props.date || pageHeaderStore.parameter.date,
    };
    if (pageHeaderStore.parameter.userGroupId && pageHeaderStore.parameter.userGroupId.trim().length > 0) {
      paramsObj.userGroupId = pageHeaderStore.parameter.userGroupId;
    }
    // if (pageHeaderStore.parameter.lastDate && pageHeaderStore.parameter.lastDate.trim().length > 0) {
    //   paramsObj.lastDate = pageHeaderStore.parameter.lastDate;
    // }
    // 2.将dims包含date的dim段转化为dim+period，并且获取第一个真实dim
    let dims = null;
    let firstDim = null;
    if (props.dims) {
      dims = props.dims
        .split(',')
        .map((dim) =>
          dim.indexOf('date') === 0 && dim.indexOf('date_') < 0
            ? `date_${pageHeaderStore.parameter.period && pageHeaderStore.parameter.period !== 'default' ? pageHeaderStore.parameter.period : 'day'}`
            : dim
        )
        .join(',');
      [firstDim] = dims.split(',');
      paramsObj.dims = dims;
    }

    if (props.dimsFromUrl) {
      let keyDim = util.getQueryString('keyDim');

      if (props.filterDims) {
        keyDim = filtersUtil.showFilterName(keyDim);
      }
      if (paramsObj.dims) {
        paramsObj.dims = `${paramsObj.dims},${keyDim}`;
      } else {
        paramsObj.dims = keyDim;
      }
    }
    if (!props.hasOwnProperty('fields') && !props.noDefaultFields) {
      paramsObj.fields = (paramsObj.hasOwnProperty('dims') ? `${paramsObj.dims}_key,${paramsObj.dims}_name,` : '').concat(
        `pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages,bounceRate`
      );
    }
    let keys = Object.keys(props);
    if (pageParam) {
      keys = new Set(keys.concat(Object.keys(pageParam)));
      if (pageParam.orderDesc !== null) {
        paramsObj.orderDesc = pageParam.orderDesc;
      }
    } else if (props.orderDesc === undefined || props.orderDesc === null) {
      paramsObj.orderDesc = false;
    } else {
      paramsObj.orderDesc = true;
    }
    keys.forEach((key) => {
      const propValue = (pageParam && pageParam[key]) || props[key];

      if (propValue === undefined || propValue === null || propValue === '') {
        return;
      }
      switch (key) {
        case 'fields':
          if (!props.noDefaultFields) {
            if (paramsObj.dims) {
              let dimFields;
              if (paramsObj.dims.includes(',')) {
                let array = [];
                paramsObj.dims.split(',').forEach((item) => {
                  array = array.concat(`${item}_key`, `${item}_name`);
                });
                dimFields = [...array, ...propValue.split(',')];
              } else {
                dimFields = [`${paramsObj.dims.split(',')[0]}_key`, `${paramsObj.dims.split(',')[0]}_name`, ...propValue.split(',')];
              }

              paramsObj.fields = dimFields.filter((item, pos) => dimFields.indexOf(item) === pos).join(',');
            } else {
              paramsObj.fields = propValue;
            }
          }

          break;
        case 'comparision':
        case 'currentPage':
        case 'pageCount':
        case 'orderColumn':
        case 'analysisType':
        case 'pathId':
        case 'workViewFilters':
        case 'actionType':
          paramsObj[key] = propValue;
          break;
        case 'cons':
          paramsObj[key] = propValue;
          break;
        case 'filterObject':
          paramsObj.filterObject = propValue;
          break;
        case 'ruleContent':
          paramsObj.ruleContent = propValue;
          break;
        case 'customizeParam':
          paramsObj.customizeParam = propValue;
          break;
        case 'filters':
          paramsObj.filters = propValue.concat(paramsObj.filters || []);
          break;
        case 'searchValue':
          paramsObj.filters = [{ dim: firstDim, value: propValue, formula: 'contain' }].concat(paramsObj.filters || []);
          break;
        // 自定义dim和value的filter
        case 'customSetFilter':
          if (props.customSetFilter) {
            paramsObj.filters = [
              {
                dim: props.customSetFilter.dims ? props.customSetFilter.dims : util.getQueryString('keyDim'),
                value: props.customSetFilter.value ? props.customSetFilter.value : util.getQueryString('key'),
                formula: '=',
              },
            ];
          }
          break;
        // 根据url定义dim和value的filter
        case 'addFilterFromUrl':
          if (util.getQueryString('key') && util.getQueryString('keyDim')) {
            paramsObj.filters = [{ dim: util.getQueryString('keyDim'), value: util.getQueryString('key'), formula: '=' }].concat(paramsObj.filters || []);
          }
          break;
        case 'addFilterObjectFromUrl':
          const filterObject = JSON.parse(props.filterObject);
          props.addFilterObjectFromUrl.forEach((item, index) => {
            item.forEach((el) => {
              filterObject.filters[index][el.key] = util.getQueryString(el.name);
            });
          });
          paramsObj.filterObject = JSON.stringify({ ...filterObject });
          break;
        case 'addConditionsFromUrl':
          if (util.getQueryString('key') && util.getQueryString('keyDim')) {
            paramsObj.conditions = JSON.stringify([{ dim: util.getQueryString('keyDim'), formula: '=', value: util.getQueryString('key') }]);
          }
          break;
        case 'ratio':
          paramsObj.ratio = props.ratio;
          break;
        case 'bulk':
          paramsObj.bulk = props.bulk;
          break;
        case 'needOther':
          paramsObj.needOther = props.needOther;
          break;
        case 'ignoreCache':
          paramsObj.ignoreCache = props.ignoreCache;
          break;
        case 'eventAnalytics':
          paramsObj.eventAnalytics = JSON.stringify(props.eventAnalytics);
        case 'workViewFilters':
          paramsObj.workViewFilters = props.workViewFilters;
        default:
          break;
      }
    });

    if (!paramsObj.filters || paramsObj.filters.length === 0) {
      delete paramsObj.filters;
    } else {
      paramsObj.filters = JSON.stringify(paramsObj.filters);
    }
    // 3. date相关维度默认正序及默认维度key排序字段
    if (firstDim) {
      if (!props.noOrderColumn) {
        if (!paramsObj.orderColumn) {
          paramsObj.orderColumn = `${firstDim}_key`;
        }
        if (paramsObj.orderDesc === undefined || paramsObj.orderDesc === null) {
          if (
            paramsObj.orderColumn.indexOf(firstDim) === 0 &&
            (paramsObj.orderColumn.indexOf('date_hour_key') === 0 || paramsObj.orderColumn.indexOf('date') !== 0 || !paramsObj.hasOwnProperty('currentPage'))
          ) {
            paramsObj.orderDesc = false;
          } else {
            paramsObj.orderDesc = true;
          }
        }
      }
    }
    return paramsObj;
  },
  // 调研分析请求参数
  getSurveyParam(form, config, chartType, setDateObj, param, noNeedTextCustomize, tableCurrentPage, tablePageCount, id) {
    const { surveySelectGroup, subDividedDimGroup, pageCount, orderDesc, orderDescIn, orderColumn } = form;
    // 兼容无chartType的类型时自动转化为系统匹配默认的图表类型
    const newRuleContent = surveySelectGroup.map((item) => {
      item.otherTarget && item.otherTarget.indexOf('experience') < 0 && delete item.experienceContent;
      delete item.filterItemLogic;
      delete item.childrenFilter;
      delete item.eventAndTargetGroup;
      delete item.count;
      return item;
    });
    const fields = [];
    for (const key in config) {
      if (key.includes('isNeed') && config[key]) {
        const char = key.replace('isNeed', '');
        fields.push(char.charAt(0).toLowerCase() + char.slice(1));
      }
    }
    const customizeParam = {};
    // chartType === 16 && !noNeedTextCustomize && (customizeParam.isTextCustomize = true);
    config.isNotRegion && (customizeParam.isNotRegion = true);
    config.isNeedOther && (customizeParam.isNeedOther = true);
    config.isTotalAnalysis && (customizeParam.isTotalAnalysis = true);
    config.isTextCustomize && (customizeParam.isTextCustomize = true);
    config.isRadioCheckboxOther && (customizeParam.isRadioCheckboxOther = true);
    config.isRadioCheckbox && (customizeParam.isRadioCheckbox = true);
    const customParam = {
      idsite: pageHeaderStore.parameter.idsite,
      date:
        (pageHeaderStore.isUseParameterDate || !pageHeaderStore.isFoldPicker) && !setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : config.dateObj
          ? config.dateObj.lastDate
            ? mathUtil.keyToDate(config.dateObj.lastDate)
            : config.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      ruleContent: JSON.stringify(newRuleContent),
      ignoreCache: true,
      analysisType: 8,
      customizeParam: JSON.stringify(customizeParam),
      workViewFilters: !setDateObj && id ? pageHeaderStore.getCommonFilter(id) : '',
      fields: fields.join(','),
      pageCount: pageCount || 10,
      currentPage: 1,
    };

    // pageHeaderStore.parameter.lastDate && (customParam.lastDate = pageHeaderStore.parameter.lastDate);
    pageHeaderStore.parameter.userGroupId && (customParam.userGroupId = pageHeaderStore.parameter.userGroupId);
    const comparision = chartUtil.isNeedChain(config);
    comparision && (customParam.comparision = comparision);
    // 连接数组
    customParam.dims = subDividedDimGroup.join(',');
    // 在看板中用周期替换dims
    pageHeaderStore.isUseParameterDate &&
      !setDateObj &&
      pageHeaderStore.parameter.period &&
      (customParam.dims = customParam.dims.replace(/date_[A-Za-z]{1,}/g, `date_${pageHeaderStore.parameter.period || 'day'}`));
    if (customBoardUtil.changeNumberToType(chartType).split('_')[0] === 'CommonChart') {
      if (customParam.dims.includes('date')) {
        customParam.orderColumn = orderColumn || `${subDividedDimGroup[0]}_key`;
        orderDesc !== 'default' &&
          (customParam.orderDesc = customBoardUtil.changeNumberToType(chartType).split('_')[1] === 'gauge' ? false : orderDesc !== 'false');
        orderDescIn !== 'default' && (customParam.orderDescIn = orderDescIn !== 'false');

        !orderColumn &&
          pageHeaderStore.isUseParameterDate &&
          !setDateObj &&
          pageHeaderStore.parameter.period &&
          (customParam.orderColumn = customParam.orderColumn.replace(/date_[A-Za-z]{1,}/g, `date_${pageHeaderStore.parameter.period || 'day'}`));
      } else {
        orderDesc !== 'default' && (customParam.orderDesc = orderDesc !== 'false');
        customParam.orderColumn = orderColumn || '';
        orderDescIn !== 'default' &&
          (customParam.orderDescIn = customBoardUtil.changeNumberToType(chartType).split('_')[1] === 'gauge' ? false : orderDescIn !== 'false');
      }
    } else if (
      customBoardUtil.changeNumberToType(chartType).split('_')[0] === 'CommonTable' ||
      customBoardUtil.changeNumberToType(chartType).split('_')[0] === 'TextTable'
    ) {
      // table更改传参
      customParam.pageCount = tablePageCount || pageCount || 10;
      customParam.currentPage = tableCurrentPage || 1;
      if (subDividedDimGroup[0] !== '') {
        // customParam['orderColumn'] = `${subDividedDimGroup[0]}_key`;
        customParam.orderDesc = true;
      }
      customParam.orderColumn = orderColumn || '';
    } else if (customBoardUtil.changeNumberToType(chartType) === 'CommonSummary') {
      customParam.orderDesc = false;
    }
    const newParam = param
      ? {
          ...customParam,
          ...param,
        }
      : {
          ...customParam,
        };
    if (newParam.dims === 'null') {
      delete newParam.dims;
    }
    return newParam;
  },
  // 事件分析请求参数
  getEventParam(form, config, chartType, id) {
    let { eventAndTargetGroup, subDividedDimGroup, filterObject, isDateChecked, isNeedCompare } = form;
    let newSubDividedDimGroup = ['date'].concat(Array.from(new Set(toJS(subDividedDimGroup)))).filter((item) => item !== '');
    let props = this.changeChartProps(eventAndTargetGroup, newSubDividedDimGroup, filterObject, 2000, config, id);
    const dateStr =
      pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
        ? pageHeaderStore.parameter.date
        : config.dateObj
        ? config.dateObj.lastDate
          ? mathUtil.keyToDate(config.dateObj.lastDate)
          : config.dateObj.date
        : `${moment()
            .subtract(8, 'days')
            .format('YYYY-MM-DD')},${moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')}`;
    let fetchParams;
    switch (chartType) {
      case 8:
        if (subDividedDimGroup < 2 || (subDividedDimGroup && !subDividedDimGroup.includes(''))) {
          if (eventTools.judgeRightParam(eventAndTargetGroup) && currency.judgeRightCondition(filterObject) && subDividedDimGroup) {
            // table的传参
            const tableProps = {
              eventAnalytics: eventAndTargetGroup,

              ignoreCache: true,
              orderDesc: true,
              showAll: true,

              noDefaultFields: true,
              noOrderColumn: true,
              combineTableHead: true,
              workViewFilters: id ? pageHeaderStore.getCommonFilter(id) : '',
            };
            if (filterObject && filterObject.filters) {
              tableProps.filterObject = JSON.stringify(filterObject);
            }
            subDividedDimGroup = subDividedDimGroup.filter((item) => item !== '');
            // 维度数组
            let dimsArr = (type) =>
              subDividedDimGroup.map((item) => {
                let target = item.includes('page_') ? `${item}_key` : `${item}_name`;
                // 判断中文还是源数据 为下面的tableheaddata做准备
                return type === 'cn' ? legendUtil.getTitle(target) : target;
              });
            //日期勾选或者维度数组长度为0
            if (isDateChecked || (!isDateChecked && subDividedDimGroup.length < 1)) {
              tableProps.dims = eventTools.changeDimsArray(subDividedDimGroup, 'string');
            }
            // 维度数组没勾选a
            else if (!isDateChecked) {
              tableProps.dims = `${subDividedDimGroup.join(',')}`;
            }

            if (tableProps.dims.length === 1 && tableProps.dims[0].includes('date')) {
              // 需要正序
              tableProps['noOrderColumn'] = false;
            }
            // 为包含时间维度的时候
            if (tableProps.dims.length > 1 && tableProps.dims[0].includes('date')) {
              tableProps['pageCount'] = 10;
              tableProps['orderColumn'] = 'pv';
            }
            tableProps.date = dateStr;

            fetchParams = this.getFetchParams(tableProps, {
              currentPage: 1,
              pageCount: 2000,
              orderDesc: true,
            });
          }
        }
        break;
      case 3:
        // 图表类型的数组['组件类型','图形的具体类型']

        let newprops = Object.assign(props, { noDefaultFields: false });
        let fields = eventAndTargetGroup.map((item) => {
          return item.target;
        });
        // let fields = 'analyticsName,EventOccurs';
        newprops.comparision = 'chain';
        newprops['fields'] = fields.join(',');
        delete newprops.dims;
        delete newprops.pageCount;
        delete newprops.orderDesc;
        fetchParams = this.getFetchParams(newprops);
        break;
      default:
        let chartTypeArr = eventTools.splitForString(customBoardUtil.changeNumberToType(chartType));

        let obj = this.changeDimsAndParamByChartType(props, chartTypeArr, isNeedCompare);
        fetchParams = this.getFetchParams({
          ...obj.props,
          ...obj.chartParams,
        });
    }
    return fetchParams;
  },
  changeChartProps(eventAndTargetGroup, subDividedDimGroup, filterObject, pageCount, config, id) {
    let dims = toJS(subDividedDimGroup);
    let props = {
      ruleContent: JSON.stringify(toJS(eventAndTargetGroup)),
      ignoreCache: true,
      orderDesc: true,
      showAll: true,
      noDefaultFields: true,
      dims: dims.join(','),
      noOrderColumn: true,
      analysisType: 1,
      date:
        pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : config.dateObj
          ? config.dateObj.lastDate
            ? mathUtil.keyToDate(config.dateObj.lastDate)
            : config.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      workViewFilters: pageHeaderStore.getCommonFilter(id),
    };
    if (filterObject && filterObject.filters) {
      props['filterObject'] = JSON.stringify(filterObject);
    }
    if (dims.length === 1 && dims[0].includes('date')) {
      props['noOrderColumn'] = false;
    }
    if (dims.length > 1 && dims[0].includes('date')) {
      props['pageCount'] = pageCount ? pageCount : 10;
      props['orderColumn'] = 'pv';
    }

    return props;
  },
  changeDimsAndParamByChartType(props, chartTypeArr, isNeedCompare) {
    let newProps = Object.assign({}, props);
    let newPropsDimArr = newProps.dims.split(',');
    let chartParams = {};
    let targetGroup = eventTools.getUniqTarget(JSON.parse(newProps.ruleContent));
    if (chartTypeArr[1]) {
      chartParams.type = chartTypeArr[1];
    }
    // 如果type不存在则为null
    chartParams[`${chartTypeArr[1]}Type`] = chartTypeArr[2] ? chartTypeArr[2] : null;
    // 有具体的type为柱状图的时候
    if (chartParams.type === 'bar') {
      if (chartParams.barType && chartParams.barType === 'horizontalRank') {
        if (newPropsDimArr.length === 3 || (newPropsDimArr.length === 2 && targetGroup.length > 1)) {
          chartParams.barType = 'horizontalRankStack';
        }
      }
      // 如果维度为一，指标为一个
      else if (newPropsDimArr.length === 1 && targetGroup.length === 1 && isNeedCompare === true) {
        chartParams.barType = 'withLine';
        newProps.comparision = 'chain';
      } else if (newPropsDimArr.length === 2 && targetGroup.length > 1) {
        chartParams.barType = 'stack';
      }
    } else if (chartParams.type === 'map') {
      if (newPropsDimArr.length === 2 && newPropsDimArr[1].includes('district_')) {
        // 存在两个维度时间和地域维度时，取地域维度，不需要交叉时间

        let districtDetail = newPropsDimArr[1].split('_')[1];
        chartParams[`${chartTypeArr[1]}Type`] = districtDetail;
        // newProps['multiple'] = true;
      }
    } else if (chartParams.type === 'scatter') {
      if (targetGroup.length === 3) {
        chartParams[`${chartTypeArr[1]}Type`] = 'withVisualMap';
      } else {
        chartParams[`${chartTypeArr[1]}Type`] = null;
      }
    }
    // 根据图形类型更改props的传参
    if (
      (chartParams.type === 'pie' ||
        chartParams.type === 'bar' ||
        chartParams.type === 'map' ||
        chartParams.type === 'radar' ||
        chartParams.type === 'scatter') &&
      newPropsDimArr.length >= 2
    ) {
      newProps.dims = props.dims
        .split(',')
        .slice(1, props.dims.split(',').length)
        .join(',');
    } else if (chartParams.type === 'gauge') {
      newProps.comparision = 'chain';
      newProps['fields'] = toJS(JSON.parse(newProps.ruleContent))
        .map((item) => {
          return item.target;
        })
        .join(',');
      delete newProps.dims;
      delete newProps.pageCount;
      delete newProps.orderDesc;
    }
    return {
      props: newProps,
      chartParams: chartParams,
    };
  },

  getPathParam(dims, customizeParam, chartType, id) {
    const dateObj = customizeParam
      ? JSON.parse(customizeParam).dateObj
      : {
          lastDate: 'last7',
          date: `${moment()
            .subtract(8, 'days')
            .format('YYYY-MM-DD')},${moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')}`,
        };

    const dateStr = dateObj.lastDate ? mathUtil.keyToDate(dateObj.lastDate) : dateObj.date;
    let fetchParams;
    const param = {
      noDefaultFields: true,
      orderDesc: true,
      currentPage: 1,
      pageCount: 10,
      pathId: id,
      date: dateStr,
      dims,
      orderColumn: `${dims}_key`,
      actionType: 8,
      workViewFilters: pageHeaderStore.getCommonFilter(id),
    };
    fetchParams = this.getFetchParams(param, {
      currentPage: 1,
      pageCount: 2000,
      orderDesc: true,
    });
    return fetchParams;
  },
  getRemainParam(form, customizeParam, chartType, id) {
    const dateObj = customizeParam
      ? JSON.parse(customizeParam).dateObj
      : {
          lastDate: 'last7',
          date: `${moment()
            .subtract(8, 'days')
            .format('YYYY-MM-DD')},${moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')}`,
        };

    const dateStr = dateObj.lastDate ? mathUtil.keyToDate(dateObj.lastDate) : dateObj.date;
    let fetchParams;
    switch (chartType) {
      case 1:
        const chartProps = {
          date: dateStr,
          ruleContent: JSON.stringify(toJS(form.ruleContent)),
          dims: 'date',
          noDefaultFields: true,
          getFieldByRes: true,
          workViewFilters: pageHeaderStore.getCommonFilter(id),
          analysisType: 3,
        };
        fetchParams = this.getFetchParams(chartProps);
        break;
      default:
        const param = {
          date: dateStr,
          ruleContent: JSON.stringify(form.ruleContent),
          noDefaultFields: true,
          getFieldByRes: true,
          pageCount: 10,
          currentPage: 1,
          orderDesc: false,
          tableType: 'analyseRemain',
          workViewFilters: pageHeaderStore.getCommonFilter(id),
          analysisType: 3,
          dims: form.dim.join(',') === '' ? 'date' : `date,${form.dim.join(',')}`,
          orderColumn: form.dim.join(',') === '' ? `date_${pageHeaderStore.parameter.period}_key` : `${form.dim.join(',')}_key`,
        };
        fetchParams = this.getFetchParams(param, {
          currentPage: 1,
          pageCount: 2000,
          orderDesc: true,
        });
        break;
    }

    return fetchParams;
  },
  getDistributionParam(form, chartType, id) {
    const dateStr = form.customizeParam.dateObj
      ? form.customizeParam.dateObj.lastDate
        ? mathUtil.keyToDate(form.customizeParam.dateObj.lastDate)
        : form.customizeParam.dateObj.date
      : `${moment()
          .subtract(8, 'days')
          .format('YYYY-MM-DD')},${moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')}`;
    let fetchParams;
    switch (chartType) {
      case 6:
        const type = customBoardUtil.changeNumberToType(chartType);
        const chartProps = {
          idsite: pageHeaderStore.parameter.idsite,
          date: dateStr,
          ruleContent: JSON.stringify(toJS(form.ruleContent)),
          orderDesc: true,
          currentPage: 1,
          pageCount: 10,
          filterObject: JSON.stringify(toJS(form.filterObject)),
          ignoreCache: true,
          analysisType: 7,
          noDefaultFields: true,
          customizeParam: form.customizeParam === 'distribution_date' ? form.customizeParam : JSON.stringify(toJS(form.customizeParam)),
          workViewFilters: pageHeaderStore.getCommonFilter(id),
        };
        if (toJS(form.subDividedDimGroup)[0] !== '') {
          chartProps['orderColumn'] = `${toJS(form.subDividedDimGroup)[0]}_key`;
        }
        if (type === 'heatmap') {
          chartProps['dims'] = toJS(form.subDividedDimGroup)[0] === '' ? 'date' : toJS(form.subDividedDimGroup)[0];
        }
        fetchParams = this.getFetchParams(chartProps);
        break;
      default:
        const param = {
          date: dateStr,
          ruleContent: JSON.stringify(toJS(form.ruleContent)),
          orderDesc: true,
          noDefaultFields: true,
          getFieldByRes: true,
          filterObject: JSON.stringify(toJS(form.filterObject)),
          currentPage: 1,
          pageCount: 10,
          ignoreCache: true,
          orderColumn: `${form.dims === '' ? `date_${pageHeaderStore.parameter.period}` : form.dims}_key`,
          analysisType: 7,
          customizeParam: form.customizeParam === 'distribution_date' ? form.customizeParam : JSON.stringify(toJS(form.customizeParam)),
        };
        fetchParams = this.getFetchParams(param, {
          currentPage: 1,
          pageCount: 2000,
          orderDesc: true,
        });

        break;
    }
    return fetchParams;
  },
};
