import { observable, action, toJS } from 'mobx';
import mathUtil from '../../utils/mathUtil';
import legendUtil from '../../utils/legendUtil';
import { Tooltip } from 'antd';
import React from 'react';

class CommonTableStore {
  @observable dataSource;
  @observable columns;
  @observable expanderType;
  @observable isExpandedRowRender;
  @observable cacheStore;
  @observable cacheParams;
  @observable customColumns;

  @action.bound setCacheStore(res) {
    this.cacheStore = res;
  }
  @action setCacheParams(params) {
    this.cacheParams = params;
  }
  @action setCustomColumns(customColumns) {
    this.customColumns = customColumns;
  }
  @action.bound resetStore(tableHeadData) {
    if (tableHeadData) {
      this.field = tableHeadData[1];
      this.legend = tableHeadData[0];
    } else {
      this.field = [];
      this.legend = [];
    }
    this.defaultSortOrder = null;
    this.dataSource = null;
    this.expandedRowRender = [];
    this.dataLastKey = []; // attrPage.data的最后一个key值，一般用来存放id
    this.switchCheckedArr = []; // switch的checked状态值
    this.count = 0; // 增加额外的行数据时，主要用来区分表格cell的key值
    this.dataIndex; // 所有列数据的dataIndex数组，dataIndex唯一
    if (this.isSort === undefined) {
      this.isSort = true;
    }
  }

  // 获取属性的类别进行表头区分
  @action.bound getTypeClassName(target, type, index, lastIndex) {
    let classArr = [];
    const classNamePrefix = 'column-for-';
    index === lastIndex && classArr.push('last-table-column');
    if (this.isExpandedRowRender && index === 0) {
      let subType = legendUtil.getSubType(target, type);
      if (subType) {
        classArr.push(`${classNamePrefix}expandedTableHead-${subType}`);
      } else {
        classArr.push(`${classNamePrefix}expandedTableHead`);
      }

      return classArr;
    }

    switch (type) {
      case 'iconLast':
      case 'iconHead':
      case 'key':
      case 'rate':
      case 'string':
      case 'operateBtn':
      case 'longString':
      case 'date':
      case 'obj':
        classArr.push(`${classNamePrefix}${type}`);
        break;
      default:
        classArr.push(`${classNamePrefix}data`);
        break;
    }
    return classArr;
  }
  // 格式化初始数据
  @action.bound formatTableData(tableAllData, hiddenKeys, customColumns, extraHeaders) {
    const haveChildren = tableAllData.data.findIndex((i) => i.children) > -1;
    this.parentData = tableAllData.data;
    // this.defaultSortOrder = 'descend';
    if (tableAllData.field) this.field = tableAllData.field;
    if (tableAllData.legend) this.legend = tableAllData.legend;
    if (tableAllData.page && tableAllData.page.orderDesc === true) {
      this.defaultSortOrder = 'descend';
    } else {
      this.defaultSortOrder = 'ascend';
    }
    // 将jsonArray格式统一转化为arrayArray二维数组data: [{},{}] => [[],[]]
    if (tableAllData.data) {
      if (tableAllData.data && tableAllData.data.length > 0 && tableAllData.data[0] instanceof Array) {
        this.data = tableAllData.data;
      } else {
        this.data = [];
        for (let i = 0; i < tableAllData.data.length; i++) {
          this.data.push([]);
          for (let j = 0; j < this.field.length; j++) {
            if (this.field[j] === 'channel_mix_name' || this.field[j] === 'channel_mix_key') {
              this.data[i].push(mathUtil.getChannelName(tableAllData.data[i][this.field[j]], tableAllData.data[i].dim_name));
            } else {
              this.data[i].push(tableAllData.data[i][this.field[j]]);
            }
          }
          if (haveChildren) {
            this.data[i].push(tableAllData.data[i]['children']);
          }
        }
      }
    } else {
      this.data = tableAllData;
    }
    let { currentPage, pageCount } = tableAllData.page ? tableAllData.page : { currentPage: 1, pageCount: 10 };
    // 组装antd需要的表头数据格式
    let columnsArrs = [];
    if (customColumns) {
      columnsArrs = customColumns;
      columnsArrs[0].dataIndex === 'serialNumber' && columnsArrs.shift();
    } else {
      for (let i = 0; i < this.legend.length; i++) {
        if (!hiddenKeys || hiddenKeys.indexOf(this.field[i]) < 0) {
          columnsArrs.push({
            dataIndex: this.field[i],
            key: this.field[i],
            // render: '',
            // 解决除日期为表头的表格点击以后获取数据为空
            sorter: this.isSort,
            // sorter: (a, b) => parseInt(a[this.field[i]]) - parseInt(b[this.field[i]]),
            extraKey: 'all',
            className: this.getTypeClassName(
              this.field[i].includes(',') ? this.field[i].split(',')[1] : this.field[i],
              //第一列左对齐
              legendUtil.getType(this.field[i].includes(',') ? this.field[i].split(',')[1] : this.field[i]),
              // i === 0 && this.legend[i] ? 'key' : legendUtil.getType(this.field[i].includes(',') ? this.field[i].split(',')[1] : this.field[i]),
              i,
              this.legend.length - 1
            ),
            // align: i === 0 ? 'left' : 'right',

            title: () => {
              return (
                <Tooltip title={`${legendUtil.getDesc(this.field[i] && this.field[i].includes(',') ? this.field[i].split(',')[1] : this.field[i])}`}>
                  <span> {this.legend[i]}</span>
                </Tooltip>
              );
            },
            // 将defaultSortOrder属性加入到符合条件的columns中
            defaultSortOrder: tableAllData.page && tableAllData.page.orderColumn === this.field[i] ? this.defaultSortOrder : null,
          });
        }
      }
    }
    // 加入数字
    (!hiddenKeys || hiddenKeys.indexOf('serialNumber') < 0) &&
      columnsArrs.unshift({
        dataIndex: 'serialNumber',
        extraKey: 'all',
        key: 'serialNumber',
        className: 'ant-table-column-serialNumber',
        sorter: false,
        title: '序号',
        width: 36,
        render: (text, record, index) => {
          return {
            children: record.isChildren ? null : <span className="number">{(currentPage - 1) * pageCount + index + 1}</span>,
          };
        },
      });
    if (extraHeaders) {
      extraHeaders.forEach((item) => {
        columnsArrs.push({
          dataIndex: item.key,
          key: item.key,
          sorter: false,
          title: item.title,
        });
      });
    }
    this.columns = columnsArrs;

    // 将column对应的dataIndex（即field）与parentData.data的值组装成antd需要的dataSource格式
    this.dataIndex = [];
    for (let m = 0; m < this.field.length; m++) {
      this.dataIndex.push(this.field[m]);
    }
    if (haveChildren) {
      this.dataIndex.push('children');
    }
    let dsArrs = [];
    if (this.data && this.data.length > 0) {
      for (let i = 0; i < this.data.length; i++) {
        let arr = {};
        for (let j = 0; j < this.dataIndex.length; j++) {
          //  this.dataIndex[j].includes(',') 此串代码兼容事件分析中的带逗号的fileld
          arr[this.dataIndex[j]] =
            !this.customColumns && !this.noFormat
              ? legendUtil.formateTitle(this.dataIndex[j].includes(',') ? this.dataIndex[j].split(',')[1] : this.dataIndex[j], this.data[i][j])
              : this.data[i][j];
        }
        if (arr['children'] && arr['children'] === '-') {
          delete arr['children'];
        }
        if (!arr['children']) {
          arr['key'] = i;
        }
        dsArrs.push(arr);
      }
    }
    this.dataIndex.unshift('serialNumber');

    this.dataSource = dsArrs;
  }
}

export default CommonTableStore;
