import React, { Component } from 'react';
import { Calendar, List } from 'antd-mobile-v2';
import mathUtil from '../../utils/mathUtil';
import './index.sass';
class MobileCalander extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  renderBtn(text) {
    return (
      <React.Fragment>
        <List.Item
          arrow="down"
          onClick={() => {
            this.props.onToggle && this.props.onToggle(false);
            this.setState({
              show: true,
            });
          }}>
          {text}
        </List.Item>
      </React.Fragment>
    );
  }
  onConfirm = (startTime, endTime) => {
    const isDateTime = this.props.type !== 'date';
    if (!isDateTime) {
      const date = mathUtil.formateDateString(startTime.toLocaleDateString('zh-CN'));
      this.props.dateChange(null, date);
    } else {
      const dateArr = [
        `${mathUtil.formateDateString(startTime.toLocaleDateString('zh-CN'))}`,
        `${mathUtil.formateDateString(endTime.toLocaleDateString('zh-CN'))}`,
      ];
      this.props.dateChange(null, dateArr);
    }
    this.setState({
      show: false,
      startTime,
      endTime,
    });
    this.props.onToggle && this.props.onToggle(true);
  };

  onCancel = () => {
    this.setState({
      show: false,
      startTime: undefined,
      endTime: undefined,
    });
    this.props.onToggle && this.props.onToggle(true);
  };
  render() {
    const now = new Date();
    const { isNewControl } = this.props;
    return (
      <div className={`mobileCalander ${this.props.className}`}>
        {this.renderBtn(
          !this.props.value || typeof this.props.value[0]._i !== 'string'
            ? '请选择时间'
            : this.props.type !== 'date'
            ? `${this.props.value[0]._i}~${this.props.value[1]._i}`
            : this.props.value[0]._i
        )}
        <Calendar
          visible={this.state.show}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          // getDateExtra={this.getDateExtra}
          // defaultDate={this.props.defaultDate}
          minDate={new Date(2015, 1, 1)}
          maxDate={new Date(now)}
          type={this.props.type !== 'date' ? 'range' : 'one'}
        />
      </div>
    );
  }
}
export default MobileCalander;
