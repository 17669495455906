import { Button, message, Form, Input, Select, Checkbox } from 'antd';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import api from '../../../api';
import util from '../../../util';
import pageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import UXSDrawer from '../../../component/UXSDrawer';
import './index.sass';
import { KeyTarget, TargetDetail, Frequency, PeriodTarget, TriggerTime } from './FormComponent.js';
import { WarningUserGroupComponent } from './WarningUserGroupComponent.js';
import intl from 'react-intl-universal';
// import { stringify } from 'json3';
// import moment from 'moment';
// import { values } from 'lodash';

export const MonitorSetting = {
  // 同比
  2: {
    // 监控粒度
    1: { text: '上一小时', compare: 'last_hour' },
    2: { text: '昨天', compare: 'yesterday' },
    3: { text: '上一周', compare: 'last_week' },
    4: { text: '上一月', compare: 'last_month' },
  },
  // 环比
  1: {
    1: { text: '昨天同期', compare: 'yesterday_hour' },
    2: { text: '上周同期', compare: 'last_week_day' },
    3: { text: '上月同期', compare: 'last_month_week' },
    4: { text: '上年同期', compare: 'last_year_month' },
  },
  3: {
    1: { text: '小时' },
    2: { text: '天' },
    3: { text: '周' },
    4: { text: '月' },
  },
};

const FormItem = Form.Item;
const Option = Select.Option;

@observer
class EarlyWarningForm extends React.Component {
  constructor() {
    super();
    this.defaultList = [];
    this.viewList = [];
    this.isInit = true;
  }
  componentDidMount() {
    const { modalType, parentData } = this.props;
    if (parentData && this.props.id) {
      parentData.ruleContent = JSON.parse(parentData.ruleContent);
      const data = {
        name: parentData.name,
        level: parentData.level,
        warningRole: parentData.warningRole,
        warningTime: parentData.warningTime === 'realtime' ? { warningType: 'realtime' } : { warningType: 'custom', time: parentData.warningTime },
        keyTarget: { dimValue: parentData.ruleContent.dimValue },
        targetDetail: parentData.ruleContent.rules
          ? parentData.ruleContent.rules
          : [
              {
                topicType: 8,
                target: parentData.ruleContent.target,
                formula: parentData.ruleContent.formula,
                value: parentData.ruleContent.value,
              },
            ],
        pushChannel: [],
        param: {},
        channel: [],
        timeName: parentData.ruleContent.timeName,
        monitorType: parentData.ruleContent.monitorType || '1',
        monitorPeriod: parentData.ruleContent.monitorPeriod || '1',
      };
      this.timeNameDisplay = parentData.ruleContent.timeNameDisplay || '';
      this.monitorPeriod = parentData.ruleContent.monitorPeriod || '1';
      this.monitorType = parentData.ruleContent.monitorType || '1';
      if (modalType == '2' || modalType == '3') {
        if (parentData.ruleContent.aggregators.length) {
          parentData.ruleContent.aggregators.map((a) => {
            if (a.filters && a.filters.length) {
              a.filters = a.filters.map((i) => {
                i = typeof i == 'object' ? i : JSON.parse(i);
                return i;
              });
            }
          });
        }

        console.log(parentData.ruleContent);
        data.targetDetail = parentData.ruleContent.aggregators;
      }
      if (parentData.warningTime === 'realtime') {
        data.warningTime = { surveyTime: 'realtime' };
      } else {
        const warningTime = JSON.parse(parentData.warningTime);
        for (let key in warningTime) {
          data.warningTime = { surveyTime: 'custom', customTime: warningTime[key], customUnit: key };
        }
      }
      modalType == '1' || modalType == '3'
        ? ((parentData.warningFrequencyPeriod = JSON.parse(parentData.warningFrequencyPeriod)),
          (data.warningFrequencyPeriod = {
            period: Object.keys(parentData.warningFrequencyPeriod)[0],
            number: parentData.warningFrequencyPeriod[Object.keys(parentData.warningFrequencyPeriod)[0]],
          }))
        : (((data.warningFrequencyPeriod = parentData.warningFrequencyPeriod), (this.warningPeriod = parentData.warningFrequencyPeriod)),
          (data.targetDetail.compare = parentData.ruleContent.compare));
      if (parentData.pushChannel) {
        const pushChannel = JSON.parse(parentData.pushChannel);
        pushChannel &&
          pushChannel.forEach((item) => {
            data.channel.push(JSON.stringify({ id: item.channel_id, channel_type: item.channel_type && item.channel_type }));
            const itemCopy = JSON.parse(JSON.stringify(item));
            delete itemCopy.channel_id;
            delete itemCopy.channel_type;
            this.getChannelFields(item.channel_type, item.channel_id, itemCopy);
            if (item.push_config && item.push_config.templateId) {
              data[`${item.channel_id}templateId`] = item.push_config.templateId.toString();
            } else if (item.template_id) {
              data[`${item.channel_id}templateId`] = item.template_id.toString();
            }
          });
      }
      this.setForm(data);
      util.get(
        api.surveyChannel.getChannelList,
        {
          idsite: pageHeaderStore.parameter.idsite,
          search: 'warning',
        },
        (result) => {
          const wayData = [],
            obj = {};
          result.forEach((item) => {
            wayData.push({
              value: JSON.stringify({ id: item.id, channel_type: item.channel_type }),
              label: item.name || item.title,
              id: item.id,
            });
            obj[item.id] = item.channel_type;
          });
          this.setWayList(wayData);
        }
      );
    }
    util.get(
      api.account.listContainOwn,
      {
        idsite: pageHeaderStore.parameter.idsite,
        pageSize: 100000,
        pageNum: 1,
      },
      (res) => {
        var data = [];
        res.data.forEach((item) => {
          data.push({ value: item.id, label: `${item.username}${item.realName ? `（${item.realName}）` : ''}` });
        });
        data.length && this.setAccepterList(data);
      }
    );

    const data = [];
    modalType == '1' &&
      util.get(api.trigger.mySurveyList, {}, (res) => {
        res.map((item, index) => {
          data.push({ value: item.id, label: item.surveyNameAndRemark });
        });
        this.setDimValueList(data);
      });
    (modalType == '2' || modalType == '3') &&
      util.get(
        api.BI.views,
        {
          projectId: 1,
        },
        (res) => {
          res.data.map((item, index) => {
            data.push({ value: item.id, label: item.name });
          });
          this.setDimValueList(data);
        }
      );
    !this.props.id &&
      util.get(
        api.surveyChannel.getChannelList,
        {
          idsite: pageHeaderStore.parameter.idsite,
          search: 'warning',
        },
        (result) => {
          const data = [],
            obj = {};
          result.forEach((item) => {
            data.push({
              value: JSON.stringify({ id: item.id, channel_type: item.channel_type }),
              label: item.name || item.title,
              id: item.id,
            });
          });
          this.setWayList(data);
        }
      );
  }

  @observable id = this.props.id ? this.props.id : '';
  @observable counter;
  // @observable warningCondition;
  @observable accepterList = [];
  @observable warningPeriod = 'hour';
  @observable wayList = [];
  @observable dimValueList = [];
  @observable templateObj = {};
  // @observable paramObj = {};
  @observable form;
  @observable channelFields = {};

  @observable targetObj = null;
  @observable targetDimValue = null;
  @observable monitorType = '1';
  @observable monitorPeriod = '1';

  // @action.bound
  // resetFormDefinition(definition) {
  //   this.warningCondition = definition;
  // }
  // @action.bound
  // setParamObj(data) {
  //   this.paramObj = data;
  // }
  // @action.bound
  // periodChange(value) {
  //   this.warningPeriod = value;
  //   this.props.form.setFieldsValue({ targetDetail: {} });
  // }
  @action.bound
  hideNewModal(isRefresh) {
    this.isShowNewModal = false;
    this.props.onShow();
    this.form.warningName = '';
    if (isRefresh) {
      this.counter++;
    }
  }
  // 选择问卷回调
  @action.bound
  setTargetObj(data, dimValue) {
    if (!this.isInit) {
      this.props.form.setFieldsValue({
        timeName: '',
      });
    }
    if (this.isInit) {
      this.isInit = false;
    }
    this.props.form.setFieldsValue({
      targetDetail:
        this.props.modalType == '1'
          ? [{ target: '', formula: '', value: '', topicType: 8 }]
          : this.props.modalType == '2'
          ? [{ formula: '', value: '', column: '', columnName: '', logic: 'and', func: '', operationType: '1' }]
          : [{ formula: '', value: '', column: '', columnName: '', logic: 'and', func: '' }],
    });
    this.targetObj = { ...data };
    this.targetDimValue = dimValue;
  }

  @action.bound
  setDim(data) {
    this.targetDimValue = data;
  }

  @action.bound
  setDimValueList(data) {
    this.dimValueList = data;
  }
  @action.bound
  setAccepterList(data) {
    this.accepterList = data;
  }
  @action.bound
  setWayList(data) {
    this.wayList = data;
  }
  @action.bound
  channelChange(e) {
    //选择预警方式的回调
    const { id, channel_type } = JSON.parse(e.target.value);
    if (e.target.checked) {
      //是否选中？控制显隐
      this.templateObj[id] ? (this.templateObj[id].isShow = true) : this.getChannelFields(channel_type, id);
    } else if (!e.target.checked) {
      this.templateObj[id] && (this.templateObj[id].isShow = false);
    }
  }
  @action.bound
  setForm(data) {
    this.form = data;
  }

  handleSubmit = (e) => {
    const { modalType } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          idsite: pageHeaderStore.parameter.idsite,
          name: values.name,
          level: values.level,
          type: modalType,
          warningRole: values.warningRole.join(','),
          warningTime:
            values.warningTime.surveyTime === 'realtime' ? 'realtime' : JSON.stringify({ [values.warningTime.customUnit]: values.warningTime.customTime }),
        };
        data.warningFrequencyPeriod =
          modalType == '1' || modalType == '3'
            ? JSON.stringify({
                [values.warningFrequencyPeriod.period]: values.warningFrequencyPeriod.period === 'every_time' ? 9999 : values.warningFrequencyPeriod.number,
              })
            : values.warningFrequencyPeriod;
        if (modalType == '2' || modalType == '3') {
          values.targetDetail.forEach((i) => {
            i.value = String(i.value);
            i.filters &&
              i.filters.forEach((f) => {
                delete f.values;
                delete f.isLoading;
              });
          });
        }
        data.ruleContent =
          modalType == '1'
            ? {
                dim: 'event',
                type: 'survey',
                dimValue: values.keyTarget.dimValue,
                logic: 'and',
                rules: values.targetDetail,
              }
            : modalType == '2'
            ? {
                dim: 'event',
                type: 'target',
                dimValue: values.keyTarget.dimValue,
                timeName: values.timeName,
                timeNameDisplay: this.getTimeNameDisplay(values.timeName),
                aggregators: values.targetDetail,
                dimValueName: this.dimValueList.length
                  ? this.dimValueList[this.dimValueList.findIndex((i) => String(i.value) === values.keyTarget.dimValue)].label
                  : '',
                monitorType: values.monitorType,
                monitorPeriod: values.monitorPeriod,
                compare: values.monitorType !== '3' ? MonitorSetting[this.monitorType][this.monitorPeriod].compare : '',
                timeParamId: values.timeParamId,
              }
            : {
                dim: 'event',
                type: 'action',
                dimValue: values.keyTarget.dimValue,
                timeName: values.timeName,
                timeParamId: values.timeParamId,
                timeNameDisplay: this.getTimeNameDisplay(values.timeName),
                dimValueName: this.dimValueList.length
                  ? this.dimValueList[this.dimValueList.findIndex((i) => String(i.value) === values.keyTarget.dimValue)].label
                  : '',
                aggregators: values.targetDetail,
              };
        data.ruleContent = JSON.stringify(data.ruleContent);
        let pushChannel = [];
        values.channel.forEach((item, index) => {
          const channelItem = JSON.parse(item);
          pushChannel.push({
            channel_id: channelItem.id,
            channel_type: channelItem.channel_type,
            ...(this.templateObj[channelItem.id] && this.templateObj[channelItem.id].formValue),
          });
        });
        data.pushChannel = JSON.stringify(pushChannel);
        this.props.id && (data.id = this.props.id);
        util.post(api.warning.save, data, (res) => {
          res.code === 200
            ? (message.success(`${intl.get('EarlyWarning')}${intl.get('setsuccessfully')}`), this.props.hideNewModal(true))
            : message.error(res.msg);
        });
      }
    });
  };
  checkForm = (rule, value, callback, msg) => {
    for (var key in value) {
      if (key === 'period' && value[key] === 'every_time') {
        //当选择每次都提醒时校验规则特殊处理
        return callback();
      } else if (key === 'surveyTime' && value[key] === 'realtime') {
        //当选择每次都提醒时校验规则特殊处理
        return callback();
      } else if (!value[key] && value[key] !== 0) {
        return callback(msg);
      }
    }
    callback();
  };
  checkTargetDetail = (rule, value, callback, msg) => {
    value.forEach((item) => {
      for (let key in item) {
        if (key === 'columnName') {
          return callback();
        }
        if (!item[key] && item[key] !== 0) {
          return callback(msg);
        }
      }
    });
    callback();
  };
  @action.bound
  getChannelFields(channelType, id, editData) {
    //获取子表单项
    util.get(api.warning.getChannelFields, { idsite: pageHeaderStore.parameter.idsite, channelType }, (res) => {
      if (res) {
        let data = this.templateObj;
        data[id] = { isShow: true, format: res, data: {}, formValue: editData ? editData : this.getInitialValue(res) };
        res.forEach((item) => {
          item.type === 'list' && this.getFieldOptions(channelType, item.key, null, id);
          // editData && item.children && item.children.type && this.getFieldOptions(channelType, item.children.key, null, id);
        });
        this.templateObj = { ...data };
      }
    });
  }
  @action.bound
  getFieldOptions(channelType, key, attach, id) {
    //获取下拉框的选项
    attach = attach || {};
    attach.sceneType = this.props.modalType;
    attach = JSON.stringify(attach);
    util.get(api.warning.getFieldOptions, { idsite: pageHeaderStore.parameter.idsite, pushChannelId: id, channelType, key, attach }, (res) => {
      let data = this.templateObj;
      data[id].data[key] = res;
      this.templateObj = { ...data };
    });
  }
  // getFormItemName(data) {
  //   //获取数据最内层的name
  //   const name = data.children ? this.getFormItemName(data.children) : data.name;
  //   return name;
  // }
  getInitialValue(data) {
    //根据格式设定初始值
    const obj = {};
    data.forEach((item) => {
      obj[item.key] = undefined;
      // item.children && (obj[item.children.key] = undefined);
    });
    return obj;
  }
  getTimeNameDisplay(value) {
    const index = this.targetObj[this.targetDimValue].findIndex((i) => i.column === value);
    const name = index > -1 ? this.targetObj[this.targetDimValue][index].columnName : '';
    return name;
  }
  @action.bound
  setFormValue(value, channel, item) {
    //子表单onchange的回调
    // let attach;
    //如果有children则获取children的下拉选项
    // item.type === 'list' &&
    //   item.children &&
    //   item.children.type === 'list' &&
    //   this.getFieldOptions(channel.channel_type, item.children.key, { [item.key]: value }, channel.id);
    const templateObjJson = JSON.parse(JSON.stringify(this.templateObj));
    const channelJson = JSON.parse(JSON.stringify(channel));
    const itemJson = JSON.parse(JSON.stringify(item));
    let data = this.templateObj;
    data[channel.id].formValue[item.key] = value;
    this.templateObj = { ...data };
  }
  // getFormValue(id, key, children) {
  //   //用于表单验证
  //   const data = { [key]: this.templateObj[id].formValue[key] };
  //   // children && (data[children.key] = this.templateObj[id].formValue[children.key]);
  //   return data;
  // }
  handleTimeChange(v) {
    const field = toJS(this.targetObj[this.targetDimValue]).find((i) => i.column === v).workViewParamId;
    this.props.form.setFieldsValue({
      timeParamId: field || null,
    });
  }
  getChildForm(channel, item) {
    //返回子表单组件，channel：渠道信息，item：子表单信息
    const templateJson = JSON.parse(JSON.stringify(this.templateObj));
    const channelObj = JSON.parse(channel.value);
    const { getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <FormItem label={item.name} className="children-form-item">
          {getFieldDecorator(`${channel.id}${item.key}`, {
            rules: [
              {
                required: true,
                message: intl.get('Thisitemisrequired'),
              },
            ],
            initialValue: this.templateObj[JSON.parse(channel.value).id].formValue[item.key],
          })(
            item.type === 'text' ? (
              <Input
                className="md-input"
                value={this.templateObj[channelObj.id].formValue[item.key]}
                onChange={(e) => this.setFormValue(e.target.value, channelObj, item)}
              />
            ) : item.type === 'textarea' ? (
              <Input.TextArea
                className="md-input"
                value={this.templateObj[channelObj.id].formValue[item.key]}
                onChange={(e) => this.setFormValue(e.target.value, channelObj, item)}
                row={4}
              />
            ) : item.type === 'list' || item.type === 'option' ? (
              <Select
                className="lg-select select-group"
                value={this.templateObj[channelObj.id].formValue[item.key]}
                onChange={(value) => this.setFormValue(value, channelObj, item)}>
                {this.templateObj[channelObj.id].data[item.key] &&
                  this.templateObj[channelObj.id].data[item.key].map((el) => {
                    return <Select.Option key={el.value}>{el.name}</Select.Option>;
                  })}
              </Select>
            ) : item.type === 'userComponent' ? (
              <WarningUserGroupComponent warningUserGroups={this.templateObj[channelObj.id].formValue[item.key]} />
            ) : (
              <></>
            )
          )}
        </FormItem>
        {/* {item.children && this.getChildForm(channel, item.children)} */}
      </Fragment>
    );
  }

  @action.bound
  setMonitorType(v) {
    this.monitorType = v;
  }

  @action.bound
  setMonitorPeriod(v) {
    this.monitorPeriod = v;
  }
  getContent() {
    const { getFieldDecorator } = this.props.form;
    const { modalType } = this.props;
    const formItemLayout = {
      labelCol: { xs: { span: 24 }, sm: { span: 5 } },
      wrapperCol: { xs: { span: 24 }, sm: { span: 18 } },
    };
    // const isRealtimePushClubOwnerOptions = [{ label: '', value: '1' }];
    return (
      // this.props.modalType = 1
      <Form>
        <FormItem {...formItemLayout} label={`${intl.get('EarlyWarning')}${intl.get('Name')}`} hasFeedback className="typeItem">
          {/* 预警名称 */}
          {getFieldDecorator('name', {
            initialValue: this.form ? this.form.name : '',
            rules: [
              {
                required: true,
                message: `${intl.get('EarlyWarning')}${intl.get('Name').toLowerCase()}${intl.get('cannotbeempty')}`,
              },
            ],
          })(
            <Input
              className="md-input"
              autoComplete="off"
              placeholder={`${intl.get('Pleaseselect')}${intl.get('EarlyWarning').toLowerCase()}${intl.get('Name').toLowerCase()}`}
              style={{ width: 200 }}
            />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label={`${intl.get('EarlyWarning')}${intl.get('Level')}`} className="typeItem">
          {/* 预警级别 */}
          {getFieldDecorator('level', {
            initialValue: this.form ? this.form.level : '',
            rules: [
              {
                required: true,
                message: `${intl.get('Pleaseselect')}${intl.get('EarlyWarning').toLowerCase()}${intl.get('Level').toLowerCase()}`,
              },
            ],
          })(
            <Select className="sm-select">
              <Option value="高">{intl.get('High')}</Option>
              <Option value="中">{intl.get('Middle')}</Option>
              <Option value="低">{intl.get('Low')}</Option>
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label={modalType == '1' ? intl.get('Questionnaire') : intl.get('WorkSheet')} className="typeItem">
          {/* 工作表 */}
          {getFieldDecorator('keyTarget', {
            initialValue: this.form ? this.form.keyTarget : { dimValue: '' },
            rules: [
              {
                required: true,
                validator: (rule, value, callback) =>
                  this.checkForm(rule, value, callback, `${intl.get('Pleaseselect')}${intl.get('WorkSheet').toLowerCase()}`),
              },
            ],
          })(
            <KeyTarget
              targetObj={this.targetObj}
              setTargetObj={this.setTargetObj}
              modalType={modalType}
              dimValueList={this.dimValueList}
              defaultList={this.defaultList}
              viewList={this.viewList}
              setDim={this.setDim}
            />
          )}
        </FormItem>
        {modalType != '1' && (
          <FormItem {...formItemLayout} label={`${intl.get('EarlyWarning')}${intl.get('Time')}${intl.get('Field')}`} className="typeItem">
            {/* 预警时间字段 */}
            {getFieldDecorator('timeName', {
              initialValue: this.form ? this.form.timeName : '',
              rules: [
                {
                  required: true,
                  message: `${intl.get('EarlyWarning')}${intl.get('Time')}${intl.get('Field')}`,
                },
              ],
            })(
              <Select
                className="lg-select"
                onChange={(value) => {
                  this.handleTimeChange(value);
                }}>
                {this.targetDimValue &&
                  this.targetObj &&
                  this.targetObj[this.targetDimValue] &&
                  this.targetObj[this.targetDimValue]
                    .filter((item) => item.visualType === 'date')
                    .map((item, index) => {
                      return (
                        <Option key={item.column} value={item.column}>
                          {item.columnName || item.column}
                        </Option>
                      );
                    })}
              </Select>
            )}
            {getFieldDecorator('timeParamId', {})(<Input style={{ display: 'none' }} />)}
          </FormItem>
        )}
        {this.targetObj && this.targetDimValue && (
          <FormItem {...formItemLayout} label={intl.get('WhenIndicators')} className="typeItem">
            {/* 当指标 */}
            {getFieldDecorator('targetDetail', {
              initialValue: this.form
                ? this.form.targetDetail
                : modalType == '1'
                ? [{ target: '', formula: '', value: '', topicType: 8 }]
                : modalType == '2'
                ? [{ formula: '', value: '', column: '', columnName: '', logic: 'and', func: '', operationType: '1' }]
                : [{ formula: '', value: '', column: '', columnName: '', logic: 'and', func: '' }],
              rules: [
                {
                  required: true,
                  validator: (rule, value, callback) => this.checkTargetDetail(rule, value, callback, `${intl.get('Pleaseinputindicatorsdetails')}`),
                },
              ],
            })(<TargetDetail modalType={modalType} targetDimValue={this.targetDimValue} targetObj={this.targetObj} />)}
          </FormItem>
        )}
        {(modalType == '1' || modalType == '3') && (
          <FormItem {...formItemLayout} label={`${intl.get('EarlyWarning')}${intl.get('FrequencyCi')}`} className="typeItem">
            {/* 预警频次 */}
            {getFieldDecorator('warningFrequencyPeriod', {
              initialValue: this.form ? this.form.warningFrequencyPeriod : { period: '', number: '' },
              rules: [
                {
                  required: true,
                  validator: (rule, value, callback) =>
                    this.checkForm(
                      rule,
                      value,
                      callback,
                      `${intl.get('Pleaseselect')}${intl.get('EarlyWarning').toLowerCase()}${intl.get('FrequencyCi').toLowerCase()}`
                    ),
                },
              ],
            })(<Frequency />)}
          </FormItem>
        )}
        <FormItem {...formItemLayout} label={intl.get('Receiver')} className="typeItem">
          {/* 接受人 */}
          {getFieldDecorator('warningRole', {
            initialValue: this.form ? this.form.warningRole.split(',') : [],
            rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('Receiver').toLowerCase()}` }],
          })(
            <Select
              className="md-input"
              mode="multiple"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {this.accepterList.map((item) => {
                return (
                  <Option key={item.value.toString()} title={item.label}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          )}
        </FormItem>

        {modalType == '2' && (
          <FormItem {...formItemLayout} label={`${intl.get('StatisticalCycle')}`} className="typeItem">
            {/* 统计周期 */}
            {getFieldDecorator('monitorPeriod', {
              initialValue: this.monitorPeriod,
              rules: [
                {
                  required: true,
                  message: `${intl.get('StatisticalCycle')}}`,
                },
              ],
            })(
              <Select className="sm-select" onChange={this.setMonitorPeriod}>
                <Option value="1">{intl.get('Hour')}</Option>
                <Option value="2">{intl.get('Day')}</Option>
                <Option value="3">{intl.get('Week')}</Option>
                <Option value="4">{intl.get('Month')}</Option>
              </Select>
            )}
            {this.monitorType !== '3' && <span style={{ paddingLeft: 20 }}>{MonitorSetting[this.monitorType][this.monitorPeriod].text}</span>}
          </FormItem>
        )}
        {modalType == '2' && (
          <FormItem {...formItemLayout} label={`${intl.get('Monitor')}${intl.get('Mode')}`} className="typeItem">
            {/* 监控方式 */}
            {getFieldDecorator('monitorType', {
              initialValue: this.monitorType,
              rules: [
                {
                  required: true,
                  message: `${intl.get('Monitor')}${intl.get('Mode')}`,
                },
              ],
            })(
              <Select className="sm-select" onChange={this.setMonitorType}>
                <Option value="1">{intl.get('SameComparison')}</Option>
                <Option value="2">{intl.get('ChainComparison')}</Option>
                <Option value="3">{intl.get('SpecialValue')}</Option>
              </Select>
            )}
          </FormItem>
        )}
        <FormItem {...formItemLayout} label={`${intl.get('EarlyWarning')}${intl.get('Time')}`} className="typeItem">
          {/* 预警时间 */}
          {getFieldDecorator('warningTime', {
            rules: [
              {
                required: true,
                validator: (rule, value, callback) =>
                  this.checkForm(
                    rule,
                    value,
                    callback,
                    `${intl.get('Pleaseselect')}${intl.get('EarlyWarning').toLowerCase()}${intl.get('Time').toLowerCase()}`
                  ),
              },
            ],
            initialValue: this.form ? this.form.warningTime : { surveyTime: '', customTime: '', customUnit: '' },
          })(<TriggerTime />)}
        </FormItem>
        <FormItem {...formItemLayout} label={`${intl.get('EarlyWarning')}${intl.get('Mode')}`} className="typeItem">
          {/* 预警方式 */}
          {getFieldDecorator('channel', {
            initialValue: this.form ? this.form.channel : [],
            rules: [],
          })(
            <Checkbox.Group>
              {this.wayList.map((item, index) => {
                return (
                  <div className="info-checkbox-content" key={item.value}>
                    <Checkbox value={item.value} onChange={(e) => this.channelChange(e)}>
                      {item.label}
                    </Checkbox>
                    {this.templateObj[item.id] &&
                      this.templateObj[item.id].isShow &&
                      (this.templateObj[item.id].format.length ? (
                        <Fragment>
                          {this.templateObj[item.id].format.map((el) => {
                            return this.getChildForm(item, el);
                          })}
                        </Fragment>
                      ) : null)}
                  </div>
                );
              })}
            </Checkbox.Group>
          )}
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <UXSDrawer
        className="full-right scroll-drawer"
        title={
          <div>
            <div>
              {intl.get('EarlyWarning')}
              {intl.get('Setting')}
              {/* 预警设置 */}
            </div>
            <Button type="primary" onClick={this.handleSubmit}>
              {intl.get('Save')}
            </Button>
          </div>
        }
        show={this.props.isShowNewModal}
        onHide={this.props.hideNewModal}
        width={960}>
        {this.getContent()}
      </UXSDrawer>
    );
  }
}

const Main = Form.create()(EarlyWarningForm);
export default Main;
