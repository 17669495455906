import React, { Component } from 'react';
import { Tabs, Input } from 'antd';
import AuthorityStore from '../../../../AuthorityStore/store';
import util from '../../../../util';
import api from '../../../../api';
import PageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import WidgetItem from './WidgetItem';

const { TabPane } = Tabs;

class WidgetOperate extends Component {
  constructor() {
    super();
    this.state = {
      widgetList: {},
      keyword: '',
      activeTabKey: '10',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  // 搜索回调
  onWidgetSearch = (keyword) => {
    this.setState({
      keyword,
    });
  };

  // tab切换回调
  onTabChange = (activeTabKey) => {
    this.setState(
      {
        activeTabKey,
      },
      () => {
        !this.state.widgetList[activeTabKey] && this.fetchData();
      }
    );
  };

  fetchData = () => {
    const { widgetList, activeTabKey } = this.state;
    switch (activeTabKey) {
      case '10':
        util.get(api.BI.widgets, { projectId: AuthorityStore.primaryAccount, type: 1, currentPage: 1, pageCount: 10000 }, (res) => {
          widgetList[activeTabKey] = res.data;
          this.setState({
            widgetList,
          });
        });
        break;
      default:
        util.get(api.analysis.list, { idsite: PageHeaderStore.parameter.idsite, currentPage: 1, pageCount: 10000, type: activeTabKey }, (res) => {
          widgetList[activeTabKey] = res.data;
          this.setState({
            widgetList,
          });
        });
        break;
    }
  };

  render() {
    const {} = this.props;
    const { widgetList, keyword, activeTabKey } = this.state;
    const filterWidgetList = widgetList[activeTabKey] ? widgetList[activeTabKey].filter((item) => item.name.includes(keyword)) : [];
    const widgetListElement = (
      <div className="widget-list">
        {filterWidgetList.map((item) => {
          return <WidgetItem key={item.id} data={item} activeTabKey={activeTabKey} {...this.props} />;
        })}
      </div>
    );
    const widgetTypeList = {
      default: [
        {
          name: '数据分析',
          key: '10',
        },
        {
          name: '关键任务',
          key: '2',
        },
        {
          name: '留存分析',
          key: '3',
        },
        {
          name: '分布分析',
          key: '7',
        },
      ],
      rainbow: [
        {
          name: '数据分析',
          key: '10',
        },
      ],
    };
    const widgetTypes = widgetTypeList[process.env.REACT_APP_BDkey] ? widgetTypeList[process.env.REACT_APP_BDkey] : widgetTypeList.default;
    return (
      <div className="widget-operate">
        <h5 className="widget-operate-title">卡片列表</h5>
        <Input.Search onChange={(e) => this.onWidgetSearch(e.target.value)} />
        <Tabs activeKey={activeTabKey} size="small" onChange={this.onTabChange}>
          {widgetTypes.map((item) => {
            return (
              <TabPane tab={item.name} key={item.key}>
                {widgetListElement}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default WidgetOperate;
