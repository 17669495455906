import BIStore from '../../../../containers/BIChart/store';
import PageHeaderStore from '../../PageHeaderStore';
import util from '../../../../util';
import api from '../../../../api';
import { uuid } from '../../../../containers/BIChart/util';
import { getTextWidth } from '../../../../containers/BIChart/util';
import { toJS } from 'mobx';
import moment from 'moment';

const action = [1, 2, 3];

const answer = [8];

const oldCard = ['动作表', '答案表'];

export const cnNameToView = (name) => {
  return name === '动作表' ? 'visit_action_union_optimize' : 'answer_visit_user_view';
};

// 初始化旧看板
export const oldCardInit = (cards) => {
  const haveAction = cards.findIndex((i) => action.includes(i.type)) > -1;
  const haveAnswer = cards.findIndex((i) => answer.includes(i.type)) > -1;
  if (haveAction) {
    getOptions('action');
  }
  if (haveAnswer) {
    getOptions('answer');
  }
};

// 旧看板数据获取
export const getOptions = (type) => {
  util.post(
    api.pack.dimOptions,
    {
      idsite: PageHeaderStore.parameter.idsite,
      dim: 'work_view_field',
      search: type,
    },
    (res) => {
      const result = res.data.visit_action_union_optimize || res.data.answer_visit_user_view;
      const model = {};
      result.map((item) => {
        model[item.fieldName] = item;
      });
      result.model = model;
      result.variable = [];
      const formedViews = BIStore.formedViews;
      formedViews[type] = {
        ...formedViews[type],
        ...result,
        name: type === 'action' ? 'visit_action_union_optimize' : 'answer_visit_user_view',
        id: type,
      };
      BIStore.addFormedViews(formedViews);
    }
  );
};
// (o[o.key] = o.value), delete o[o.key].key, delete o[o.key].value))
export const formatOptions = (options, withKey) => {
  options = options
    .map((o) => {
      if (o.value) {
        if (withKey) {
          return {
            [withKey]: o.value,
          };
        }
        return {
          [o.key]: o.value,
        };
      }
    })
    .filter((i) => !!i);
  return options;
};

// 兼容旧版看板数据结构
export const getFormatControl = (controllerList) => {
  // 屏蔽新控制器
  if (!util.showNewContorl()) {
    return controllerList;
  }
  if (!controllerList) return [];
  const isNewControl = controllerList.every((control) => control.key);
  if (!isNewControl) {
    controllerList = controllerList.map((control) => {
      return (control = {
        ...control,
        cache: false,
        expired: 300,
        key: uuid(8, 16),
        type: getControlType(control),
        width: 0,
        ...getControlOperator(control.type),
        visibility: 'visible',
        ...getRelatedItems(control.relatedCards),
        ...getRelatedViews(control.relatedViews),
      });
    });
  }

  return controllerList;
};

export const getControlType = (control) => {
  switch (control.type) {
    case 'select':
      return 'select';
    case 'search':
      return 'inputText';
    case 'datetime':
      return 'dateRange';
    default:
      return 'select';
  }
};

export const getControlOperator = (type) => {
  switch (type) {
    case 'select':
      return { multiple: true, operator: 'in', optionType: 'auto', defaultValueType: 'fixed' };
    case 'search':
      return { operator: 'like' };
    case 'datetime':
      return {
        dateFormat: 'YYYY-MM-DD',
        defaultValue: [{ type: 'day', valueType: 'prev', value: 30 }, { type: 'day', valueType: 'current', value: 0 }],
        defaultValueType: 'dynamic',
        width: 6,
      };
    default:
      return { multiple: true, operator: 'in', optionType: 'auto', defaultValueType: 'fixed' };
  }
};

export const getRelatedItems = (relatedCards) => {
  const relatedItems = {};
  relatedCards.map((card) => {
    if (!card.view) {
      return;
    }
    relatedItems[card.id] = {
      checked: true,
      viewId: card.view,
    };
  });
  return {
    relatedItems: relatedItems,
  };
};

export const getRelatedViews = (relatedViews) => {
  const newRelatedViews = Object.keys(relatedViews).reduce((obj, key) => {
    obj[key] = {
      fieldType: 'column',
      fields: [relatedViews[key].name],
      workFilters: [relatedViews[key]],
    };
    return obj;
  }, {});
  return {
    relatedViews: newRelatedViews,
  };
};

// 适配新版控制器结构， 移动端还是用的旧的
export const getFormatOldControl = (controllerList) => {
  controllerList = controllerList.map((control) => {
    if (control.key) {
      if (!getOldControlType(control.type)) {
        return;
      }
      control = {
        ...control,
        type: getOldControlType(control.type),
        relatedCards: getOldRelatedCards(control.relatedItems),
        relatedViews: getOldRelatedViews(control.relatedViews),
      };
    }
    return control;
  });
  return controllerList.filter((i) => !!i);
};

export const getOldControlType = (type) => {
  switch (type) {
    case 'select':
      return 'select';
    case 'inputText':
      return 'search';
    case 'dateRange':
      return 'datetime';
    default:
      return type;
  }
};

export const getOldRelatedCards = (relatedItems) => {
  const relatedCards = Object.keys(relatedItems).reduce((arr, key) => {
    if (relatedItems[key].checked) {
      arr.push({
        type: relatedItems[key].type,
        id: Number(key),
        view: relatedItems[key].viewId,
      });
    }
    return arr;
  }, []);
  return relatedCards;
};

export const getOldRelatedViews = (relatedViews) => {
  const newRelatedViews = Object.keys(relatedViews).reduce((obj, key) => {
    obj[key] = {
      ...relatedViews[key].workFilters[0],
      fields: relatedViews[key].fields,
      fieldType: relatedViews[key].fieldType,
    };
    return obj;
  }, {});
  return newRelatedViews;
};

export const transColToWidth = (width) => {
  switch (width) {
    case 24:
      return '100%';
    case 12:
      return '50%';
    case 6:
      return '25%';
    default:
      return '';
  }
};

export const getControlStyle = (control) => {
  let style = {};
  if (control.width) {
    style.width = transColToWidth(control.width);
  }
  switch (control.type) {
    case 'select':
    // case 'radio':
    case 'inputText':
    case 'date':
    case 'dateRange':
    case 'slider':
      if (!control.width) {
        style.minWidth = `${getTextWidth(control.name) + 120}px`;
      }
      if (control.type === 'dateRange') {
        style.overflow = 'hidden';
      }
      break;
    case 'numberRange':
      if (!control.width) {
        style.width = `${getTextWidth(control.name) + 230}px`;
      }
      break;
  }
  return style;
};

export const getControlMarginStyle = (control) => {
  let style = {};
  const isOutSide = ['slider', 'numberRange', 'radio'];
  if (isOutSide.includes(control.type)) {
    style = {
      marginRight: '16px',
    };
  }
  return style;
};

export const getWithKeyValue = (filterList, fitersValue) => {
  const withKeyValues = {};
  filterList.map((filter, index) => {
    withKeyValues[filter.key] = fitersValue[index];
  });
  return withKeyValues;
};

export const getDateDefaultValue = (item, fitersValue) => {
  if (!fitersValue || (Array.isArray(fitersValue) && !fitersValue.length)) {
    return fitersValue;
  }
  switch (item.type) {
    case 'date':
      return { lastDate: '', date: moment(fitersValue).format('YYYY-MM-DD') };
    case 'datetime':
    case 'quickDateRange':
      return { lastDate: '', date: `${moment(fitersValue[0]).format('YYYY-MM-DD')},${moment(fitersValue[1]).format('YYYY-MM-DD')}` };
  }
};

export const getFormatValue = (control, controlValue) => {
  const filter = {};
  filter.relatedCards = control.relatedItems
    ? Object.keys(control.relatedItems)
        .map((i) => {
          if (control.relatedItems[i].checked) {
            return (i = Number(i));
          }
        })
        .filter((i) => !!i)
    : ['1'];
  if (!filter.relatedCards.length) {
    return filter;
  }
  filter.relatedViews = control.relatedItems
    ? Object.keys(control.relatedItems).reduce((obj, key) => {
        obj[key] = String(control.relatedItems[key].viewId);
        return obj;
      }, {})
    : [];
  filter.value = Object.keys(control.relatedViews).reduce((obj, key) => {
    obj[key] = getFilterValue(control.operator, controlValue, control.relatedViews[key].workFilters[0], control.type, control);
    return obj;
  }, {});
  return filter;
};

export const getFilterValue = (operator, controlValue, filtersDetail, controlType, control) => {
  let isDateFormat;
  const viewName = filtersDetail.viewName;
  const isOldCard = oldCard.includes(viewName);
  if (control.dateFormat || controlType === 'quickDateRange') {
    isDateFormat = getIsDateFormat(control.dateFormat);
  }
  switch (controlType) {
    case 'select':
      if (['=', '!='].includes(operator)) {
        return [{ ...filtersDetail, operator, value: [`'${controlValue}'`], type: 'filter' }];
      } else if (['in', 'not in'].includes(operator)) {
        controlValue = controlValue.map((value) => {
          return `'${value}'`;
        });
        return [{ ...filtersDetail, operator, value: controlValue, type: 'filter' }];
      }
      break;
    case 'radio':
      return [{ ...filtersDetail, operator, value: [`'${controlValue}'`], type: 'filter' }];
    case 'inputText':
      if (['=', '!='].includes(operator)) {
        return [{ ...filtersDetail, operator, value: [`'${controlValue}'`], type: 'filter' }];
      } else if (['like', 'not like'].includes(operator)) {
        return [{ ...filtersDetail, operator, value: [`'${controlValue}'`], type: 'filter', filterType: 'likeFilter' }];
      }
      break;
    case 'date':
      return [
        {
          ...filtersDetail,
          name: isDateFormat || isOldCard ? filtersDetail.name : `toDateTime(${filtersDetail.name})`,
          sqlType: isDateFormat || isOldCard ? filtersDetail.sqlType : 'STRING',
          operator: '=',
          value: `'${controlValue.format(isDateFormat ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss')}'`,
          type: 'filter',
          viewName: isOldCard ? cnNameToView(viewName) : viewName,
        },
      ];
    case 'quickDateRange':
    case 'dateRange':
      if (!controlValue.every((i) => !!i)) {
        return;
      }
      if (isOldCard) {
        return [
          {
            ...filtersDetail,
            name: filtersDetail.name,
            sqlType: filtersDetail.sqlType,
            operator: '=',
            value: `${controlValue[0].format('YYYY-MM-DD')},${controlValue[1].format('YYYY-MM-DD')}`,
            type: 'filter',
            viewName: cnNameToView(viewName),
          },
        ];
      }
      return [
        {
          ...filtersDetail,
          name: isDateFormat ? filtersDetail.name : `toDateTime(${filtersDetail.name})`,
          sqlType: isDateFormat ? filtersDetail.sqlType : 'STRING',
          operator: '>=',
          value: `'${controlValue[0].format(isDateFormat ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss')}'`,
          type: 'filter',
        },
        {
          ...filtersDetail,
          name: isDateFormat ? filtersDetail.name : `toDateTime(${filtersDetail.name})`,
          sqlType: isDateFormat ? filtersDetail.sqlType : 'STRING',
          operator: '<=',
          value: `'${controlValue[1].format(isDateFormat ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss')}'`,
          type: 'filter',
        },
      ];
    case 'numberRange':
    case 'slider':
      return [
        { ...filtersDetail, operator: '>=', value: controlValue[0], type: 'filter' },
        { ...filtersDetail, operator: '<=', value: controlValue[1], type: 'filter' },
      ];
    default:
      break;
  }
};

export const getIsDateFormat = (format) => {
  if (!format) {
    return true;
  }
  const dateFormat = ['YYYY-MM-DD', 'YYYY-MM', 'YYYY-ww', 'YYYY'];
  return dateFormat.includes(format);
};

export const ViewModelVisualTypes = {
  Number: 'number',
  String: 'string',
  Date: 'date',
  GeoCountry: 'geoCountry',
  GeoProvince: 'geoProvince',
  GeoCity: 'geoCity',
};

export const getDragItemIconClass = (type) => {
  switch (type) {
    case ViewModelVisualTypes.Number:
      return 'icon-shuzhi-copy';
    case ViewModelVisualTypes.Date:
      return `icon-riqi-copy`;
    default:
      return 'icon-zifuchuan-copy';
  }
};

export const getIsOldStyle = (control) => {
  return ['select', 'date', 'dateRange', 'inputText'].includes(control.type);
};
