import { toJS } from 'mobx';
import mathUtil from './mathUtil';
import legendUtil from './legendUtil';
import tableUtil from './tableUtil';
import pageHeaderStore from '../component/PageHeader/PageHeaderStore';
import util from '../util';
// 通用
export const currency = {
  judgeRightCondition: (filterObject) => {
    let result = true;

    if (!filterObject) {
      return true;
    }
    if (toJS(filterObject.filters)) {
      toJS(filterObject.filters).forEach((item) => {
        if (JSON.stringify(item) === '{}') {
          result = false;
        } else if (!(item.hasOwnProperty('dim') && item.hasOwnProperty('formula') && item.hasOwnProperty('value'))) {
          result = false;
        } else if (item.value.length === 0) {
          result = false;
        } else if (item.dim === '') {
          result = false;
        } else if (item.formula === '') {
          result = false;
        }
      });
    } else {
      result = true;
    }

    return result;
  },
  judgeRightDim: (subDividedDimGroup, type) => {
    let result = true;
    if (type === 'save' && (toJS(subDividedDimGroup).length === 0 || (toJS(subDividedDimGroup).length === 1 && toJS(subDividedDimGroup)[0] === ''))) {
      return result;
    } else if (type === 'survey') {
      if (toJS(subDividedDimGroup).length === 0) {
        result = false;
      } else if (toJS(subDividedDimGroup).length === 1) {
        result = true;
      } else {
        toJS(subDividedDimGroup).forEach((item) => {
          if (item.length === 0) {
            result = false;
          }
        });
      }
    } else {
      toJS(subDividedDimGroup).forEach((item) => {
        if (item.length === 0) {
          result = false;
        }
      });
    }

    return result;
  },
};
// 事件分析
export const eventTools = {
  judgeRightParam: (eventAndTargetGroup) => {
    let result = true;
    if (toJS(eventAndTargetGroup)) {
      toJS(eventAndTargetGroup).forEach((item) => {
        if (JSON.stringify(item) === '{}') {
          result = false;
        }
        if (Object.getOwnPropertyNames(item).length < 2) {
          result = false;
        }
      });
    } else {
      result = false;
    }

    return result;
  },
  judgeRightDim: (subDividedDimGroup, type) => {
    let result = true;
    if (type === 'save' && (toJS(subDividedDimGroup).length === 0 || (toJS(subDividedDimGroup).length === 1 && toJS(subDividedDimGroup)[0] === ''))) {
      return result;
    }
    toJS(subDividedDimGroup).forEach((item) => {
      if (item.length === 0) {
        result = false;
      }
    });
    return result;
  },

  splitForString: (chartType) => {
    if (Object.prototype.toString.call(chartType) !== '[object String]') {
      return null;
    }
    if (!chartType) {
      return null;
    }
    if (chartType.includes('_')) {
      return chartType.split('_');
    }
    if (!chartType.includes('_')) {
      return [chartType];
    }
  },
  // 字符串和数组之间互换
  changeArrayOrString: (value) => {
    const type = Object.prototype.toString.call(value);
    if (type === '[object String]') {
      return [`date_${pageHeaderStore.parameter.period || 'day'}`].concat(value.split(',') ? value.split(',') : '');
    }
    if (type === '[object Array]') {
      return value.length > 0 && value[0] !== ''
        ? `date_${pageHeaderStore.parameter.period || 'day'},${value.join(',')}`
        : `date_${pageHeaderStore.parameter.period || 'day'}`;
    }
  },
  changeDimsArray: (subDividedDimGroup, type) => {
    const dimsArray = ['date'].concat(Array.from(new Set(toJS(subDividedDimGroup)))).filter((item) => item !== '');
    if (type === 'string') {
      return dimsArray.join(',');
    }
    return dimsArray;
  },
  getUniqTarget: (EventAndTargetGroup) => {
    const targetGroup = [];
    EventAndTargetGroup.forEach((EventAndTargetItem) => {
      EventAndTargetItem.target &&
        EventAndTargetItem.target.split(',').forEach((targetItem) => {
          targetGroup.push(`${EventAndTargetItem.dimValue},${targetItem}`);
        });
    });
    return mathUtil.duplicateRemove(targetGroup);
  },
};
// 留存分析
export const remainTools = {
  checkSaveData(ruleContent) {
    const newRuleContent = ruleContent[0].rules.map((item) => item.filterObject.filters);
    const isCheckData = () => {
      let nullArrNum = 0;
      newRuleContent.forEach((arrItem) => {
        if (arrItem.length === 0) {
          nullArrNum++;
        }
      });

      if (nullArrNum >= 2) {
        return false;
      }
      return true;
    };
    if (isCheckData() === true) {
      let result = true;
      newRuleContent.map(
        (arrItem) =>
          arrItem !== [] &&
          arrItem.map((arrChildItem) => {
            if (JSON.stringify(arrChildItem) === '{}') {
              result = false;
            } else if (arrChildItem.dimValue === '' || !arrChildItem.dimValue) {
              result = false;
            }
          })
      );
      return result;
    }
    return true;
  },
  periodFields(data) {
    let fields = [];
    let fieldsCn = [];
    const newData = data;
    newData.length > 0 &&
      Object.keys(newData[0]).forEach((key) => {
        fields.push(key);
      });

    fields = fields.filter((item) => {
      const itemArr = item.split('_');

      return itemArr[itemArr.length - 1] !== 'name';
    });
    fieldsCn = fields.map((item) => {
      if (item.includes('_name') || item.includes('_key')) {
        return legendUtil.getTitle(item);
      }
      if (item === 'lifeCycleVisitors') {
        return '访客数';
      }
      return this.fieldsToCnName(item);
    });
    return [fieldsCn, fields];
  },
  fieldsToCnName(field) {
    const stringArr = field.split('_');
    const num = stringArr[stringArr.length - 1];
    if (pageHeaderStore.parameter.period === 'month') {
      return num === 0 ? '当月' : `第${num}月`;
    }
    if (pageHeaderStore.parameter.period === 'week') {
      return num === 0 ? '当周' : `第${num}周`;
    }
    return num === 0 ? '当日' : `第${num}日`;
  },
  formateChartData(res, type, dims) {
    if (!res || !res.data || (res.data && res.data === []) || JSON.stringify(res) === '{}') {
      return { axis: [], legend: [], data: [] };
    }
    const newRes = toJS(res);
    newRes.data = tableUtil.setRemainRes(newRes.data, dims);
    let axis = [];
    let legend = [];
    const data = [];
    legend = this.periodFields(newRes.data)[0]
      .filter((item) => item !== '访客数' && !item.includes('日期'))
      .map((item) => `${item}${type === 'value' ? '留存数' : '留存率'}`);
    newRes.data.splice(0, 1);

    axis = newRes.data.map((dataItem) => dataItem[`${dims}_name`]);
    newRes.data.forEach((dataItem, dataIndex) => {
      const num = 0;
      data[dataIndex] = [];
      Object.keys(dataItem).every((key) => {
        if (key !== 'lifeCycleVisitors' && !key.includes('_key') && !key.includes('_name')) {
          data[dataIndex].push(dataItem[key][type]);
        }
        // if (num === 10) {
        //   return false;
        // }

        return true;
      });
    });

    newRes.legend = legend;
    newRes.axis = axis;
    newRes.data = data;
    newRes.axisField = legend.length > 0 ? legend.map((item) => type) : null;
    return newRes;
  },
};
// 分布分析
export const distributeTools = {
  checkCustomSection: (customSection) => {
    let result = true;
    const customSectionArr = customSection.split(',');
    customSectionArr.every((item) => {
      if (item === '') {
        result = false;
        return false;
      }
      return true;
    });
    return result;
  },
  periodFields: (data) => {
    let fields = [];
    let fieldsCn = [];
    const newData = data;
    newData.length > 0 &&
      Object.keys(newData[newData.length - 1]).forEach((key) => {
        fields.push(key);
      });
    fields = fields.filter((item) => {
      const itemArr = item.split('_');
      return itemArr[itemArr.length - 1] !== 'name';
    });
    fieldsCn = fields.map((item) => {
      if (item.includes('_name') || item.includes('_key')) {
        return legendUtil.getTitle(item);
      }
      if (item.includes('_count')) {
        return '总人数';
      }
      return item.split('_')[item.split('_').length - 1];
    });

    return [fieldsCn, fields];
  },
  formateChartData(res, type, target) {
    if (!res || !res.data || (res.data && res.data.length === 0) || JSON.stringify(res) === '{}') {
      return { axis: [], legend: [], data: [] };
    }
    let legend = [];
    const data = [];

    legend = this.getDistributionFields(toJS(res).data[0]);
    toJS(res).data.forEach((dataItem, dataIndex) => {
      Object.keys(dataItem)
        .filter((item) => item !== 'visitor_total_count')
        .forEach((key, keyIndex) => {
          if (!data[keyIndex]) {
            data[keyIndex] = [];
          }

          data[keyIndex].push(dataItem[key][type]);
        });
    });
    return {
      legend: [legendUtil.getTitle(target)],
      axis: legend.map((item) => item.split('_')[item.split('_').length - 1]),
      data,
      axisField: legend.map((item) => type),
    };
    // return { axis: [], legend: [], data: [] };
  },
  formateHeatMapData(res, type, dims) {
    if (!res || !res.data || (res.data && res.data.length === 0) || JSON.stringify(res) === '{}') {
      return { axis: [], legend: [], data: [] };
    }

    const data = [];
    const newRes = toJS(res);

    const axis = newRes.data.map((item) => item[`${dims}_name`]).filter((item) => item !== '全部用户');

    const legend = this.getDistributionFields(newRes.data[0]);
    axis.forEach((axisItem, axisIndex) => {
      legend.forEach((legendItem, legendIndex) => {
        if (type) {
          data.push([axisIndex, legendIndex, newRes.data[axisIndex][legendItem][type]]);
        } else {
          data.push([axisIndex, legendIndex, newRes.data[axisIndex][legendItem]]);
        }
      });
    });
    const dataMax = Math.max(
      ...util.customFlat(
        toJS(data)
          .map((item) => item[item.length - 1])
          .filter((item) => !isNaN(item)),
        1
      )
    );

    const field = [type];

    return {
      axis,
      legend: legend.map((item) => item.split('_')[item.split('_').length - 1]),
      data,
      dataMax,
      field,
    };
  },
  getDistributionFields: (data) =>
    Object.keys(data).filter((item) => {
      if (item === 'visitor_total_count') {
        return false;
      }
      if (item.includes('_key') || item.includes('_name')) {
        return false;
      }
      return true;
    }),
};
// 调研分析
export const surveyTools = {
  checkEventIsNull: function (eventAndTargetGroup) {
    let result = false;
    eventAndTargetGroup &&
      eventAndTargetGroup.every((item) => {
        let { dimValue, dimValueName, target, targetName } = item;
        if ((dimValue === '' && target === '' && targetName === '') || !(dimValue && target && targetName)) {
          result = true;
          return false;
        } else {
          return true;
        }
      });
    return result;
  },
  checkContentIsNull: function (groupItem) {
    let { dimValue, dimValueName, target, targetName } = toJS(groupItem);
    let result = false;
    if (dimValue === '' || dimValueName === '' || target === '' || targetName === '') {
      result = false;
    } else {
      result = true;
    }
    return result;
  },
  resetTopicOptionTableData: function (dimTarget, tableHead, data) {
    let totalArr = data.filter((item) => {
      return item.hasOwnProperty('surveyTotalSamples');
    });
    let npsArr = data.filter((item) => {
      return item.hasOwnProperty('otherSamples');
    });
    let tableHeadArr = mathUtil.duplicateRemove(
      toJS(data).map((item) => {
        return item[tableHead];
      })
    );
    let targetArr = mathUtil.duplicateRemove(
      toJS(data)
        .map((item) => {
          return item[`${dimTarget}_name`];
        })
        .filter((item) => {
          return item !== undefined;
        })
    );
    let newData = toJS(tableHeadArr).map((item) => {
      let newItem = {};
      newItem[`${tableHead}`] = item;
      return newItem;
    });

    toJS(tableHeadArr).forEach((tableHeadItem, tableHeadIndex) => {
      toJS(npsArr).forEach((npsArrItem) => {
        if (npsArrItem[tableHead] === tableHeadItem) {
          npsArrItem.hasOwnProperty('npsSatisfaction')
            ? (newData[tableHeadIndex]['npsSatisfaction'] = npsArrItem['npsSatisfaction'])
            : (newData[tableHeadIndex]['satisfaction'] = npsArrItem['satisfaction']);
          // newData[tableHeadIndex]['otherSamples'] = npsArrItem['otherSamples'];
        }
      });
      toJS(totalArr).forEach((npsArrItem) => {
        if (npsArrItem[tableHead] === tableHeadItem) {
          newData[tableHeadIndex]['totalSamples'] = npsArrItem['totalSamples'];

          // newData[tableHeadIndex]['otherSamples'] = npsArrItem['otherSamples'];
        }
      });
      targetArr.forEach((targetItem) => {
        toJS(data).forEach((dataItem) => {
          if (dataItem[`${dimTarget}_name`] === targetItem && dataItem[tableHead] === tableHeadItem) {
            newData[tableHeadIndex][`${targetItem}`] = dataItem['samples'];
          }
        });
      });
    });
    return {
      tableHeadData: [...targetArr],
      data: newData,
    };
    // let newData = tableHeadArr.map(item=>{

    //   return
    // })
    // tableHeadArr
  },
  // 判断是否存在同种题型
  // checkIsSameTopic: function(surveyGroup) {
  //   let newSurveyGroup = toJS(surveyGroup);
  //   if (newSurveyGroup) {
  //     let targetNameArr = newSurveyGroup.map((item) => {
  //       return item.targetName && item.targetName !== '' ? item.targetName.split('[')[1].split(']')[0] : '';
  //     });
  //     if (
  //       mathUtil.duplicateRemove(targetNameArr).length === 1 ||
  //       (mathUtil.duplicateRemove(targetNameArr).length === 2 && mathUtil.duplicateRemove(targetNameArr).includes(''))
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // },
  checkEventAndTargetGroup: function (eventAndTargetGroup) {
    let result = true;
    if (this.checkEventIsNull(eventAndTargetGroup)) {
      return false;
    } else {
      eventAndTargetGroup.every((item) => {
        let { dimValue, dimValueName, target, targetName } = item;
        let topicTypeArr = this.changeTargetNameToTopicType(targetName);

        if ((targetName && targetName.split(',')[1] === '') || (target && target.split(',')[1] === '')) {
          result = false;
          return false;
        } else if (targetName.split(',').length === 2 && targetName.split(',')[0] === targetName.split(',')[1]) {
          result = false;
          return false;
        } else {
          if (
            topicTypeArr.length === 2 &&
            (topicTypeArr.includes('单行文本题') ||
              topicTypeArr.includes('多行文本题') ||
              topicTypeArr.includes('填空题') ||
              topicTypeArr.includes('矩阵量表题'))
          ) {
            result = false;
            return false;
          }
          return true;
        }
      });

      return result;
    }
  },
  changeTargetNameToTopicType: function (targetName) {
    if (targetName === '') {
      return [''];
    } else {
      return targetName.split(',').map((item) => {
        return item.split(']')[0].split('[')[1];
      });
    }
  },
  resetMatrixTableData: function (row, col, target, data, rowKeyArr, colKeyArr) {
    if (data.length === 0 || !data) {
      return {
        data: [],
        colKeyData: [],
      };
    }
    let dataObj = {};
    let newData = [];
    let rowKey = rowKeyArr ? rowKeyArr : [];
    let colKey = colKeyArr ? colKeyArr : [];
    if (rowKey.length > 0 && colKey.length > 0) {
      data.forEach((item, index) => {
        dataObj[`${item[row]},${item[col]}`] = item[target];
      });
    } else {
      data.forEach((item, index) => {
        rowKey.push(item[row]);
        colKey.push(item[col]);
        dataObj[`${item[row]},${item[col]}`] = item[target];
      });
    }

    rowKey = mathUtil.duplicateRemove(rowKey);
    colKey = mathUtil.duplicateRemove(colKey);
    rowKey.forEach((rowItem, rowIndex) => {
      newData.push({});
      newData[rowIndex][row] = rowItem;
      colKey.forEach((colItem, colIndex) => {
        newData[rowIndex][colItem] = dataObj[`${rowItem},${colItem}`]
          ? dataObj[`${rowItem},${colItem}`]
          : {
            compare: null,
            key: target,
            npsRatio: null,
            perk: null,
            ratio: null,
            valley: null,
            value: '0',
          };
      });
    });
    return {
      data: newData,
      colKeyData: colKey,
    };
  },
  defaultChartType: function (topicType, subDividedDimGroup) {
    if (!subDividedDimGroup) {
      return 8;
    } else if (subDividedDimGroup.length === 1 && subDividedDimGroup[0] === '' && (topicType[0] === '量表题' || topicType[0] === 'NPS题')) {
      return 3;
    } else {
      return 8;
    }
  },
  checkHasDateDim(dimsArray) {
    let result = false;
    dimsArray.every((item) => {
      if (item.includes('date')) {
        result = true;
        return false;
      } else {
        true;
      }
    });
    return result;
  },
  checkFilterInterval(filterInterval) {
    let result = true;
    let newArr = filterInterval.filter((item) => {
      if (item !== undefined && item.length !== 0) {
        return true;
      }
      return false;
    });

    const allIsNull = () => {
      let num = [0, 0];
      newArr.every((item, index) => {
        item.forEach((itemData) => {
          if (itemData === '' || itemData == null) {
            num[index] = num[index] + 1;
          }
        });
      });

      if ((num[0] === 2 && num[1] === 0) || (num[1] === 2 && num[0] === 0)) {
        return true;
      } else {
        return false;
      }
    };

    if (allIsNull()) {
      result = true;
    } else {
      newArr.every((item) => {
        if (item.includes('') || item.includes(null)) {
          result = false;
          return false;
        } else if (item[0] > 10 || item[1] > 10 || item[0] < 0 || item[1] < 0) {
          result = false;
          return false;
        } else if (item.length !== 2) {
          result = false;
          return false;
        } else if (item[0] > item[1]) {
          result = false;
          return false;
        }
        return true;
      });
    }
    return result;
  },
  getFilterInterval(filterInterval) {
    let split = toJS(filterInterval).length === 2 ? ',' : '';
    let firstInterval = toJS(filterInterval)[0] ? toJS(filterInterval)[0].join('-') : '';
    let secondInterval = toJS(filterInterval)[1] ? toJS(filterInterval)[1].join('-') : '';
    if ((firstInterval === '' && secondInterval === '') || (firstInterval === '-' && secondInterval === '-')) {
      return '';
    }
    return `${firstInterval === '-' ? '' : firstInterval}${split}${secondInterval === '-' ? '' : secondInterval}`;
  },
  checkIntervalValue(filterInterval) {
    let result = true;
    if (Object.prototype.toString.call(toJS(filterInterval)) === '[object Array]') {
      toJS(filterInterval).forEach((item, index) => {
        // 分开为长度2
        if (item.split('-').length !== 2 || (item.split('-')[0] === '' || item.split('-')[1] === '')) {
          result = false;
        }
      });
    }
    return result;
  },
};
