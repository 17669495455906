import { autorun, action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import api from '../../api';
import Loading from '../../component/Loading/Loading';
import UXSChart from '../../component/UXSChart/index';
import util from '../../util';
import chartUtil from '../../utils/chartUtil';
import pageUtil from '../../utils/pageUtil';
import TableNullData from '../../component/TableNullData';
import './index.sass';
@observer
class Main extends React.Component {
  @observable isLoading = true;

  @observable data;
  @observable propsObj = this.props;
  @action.bound changeOrderColumn = (value) => {
    this.propsObj['orderColumn'] = value;
    this.propsObj['fields'] = value;
  };
  componentDidMount() {
    this.isLoading = true;
    if (this.props.params) {
      this.data = chartUtil.createAxis(this.props.parentData, this.props.params.dims, this.props.params.fields);
      this.isLoading = false;
    } else {
      this.disposer = autorun(() => {
        this.isLoading = true;
        if (this.props.parentData) {
          this.data = this.props.parentData;
          this.isLoading = false;
        } else {
          this.api = this.props.customApi || api.pack.newIndex;
          let fetchParams = this.props.order ? pageUtil.getFetchParams(this.propsObj) : pageUtil.getFetchParams(this.props);

          util.get(this.api, fetchParams, (res) => {
            if (this.props.commonChartType === 'analyseEvent') {
              // 堆叠图与其他图的格式不一样，需要在最后穿多一个参数名为this.props.lineType有则为堆叠图
              this.data = chartUtil.eventAnalyticsCreateAxis(toJS(res), fetchParams.dims, JSON.parse(fetchParams.ruleContent), this.props);
            } else if (this.props.commonChartType === 'multiDims') {
              this.data = chartUtil.multiDimsCreateAxis(toJS(res), fetchParams.dims, fetchParams.orderColumn, this.props);
            } else {
              this.data = chartUtil.createAxis(toJS(res), fetchParams.dims, fetchParams.fields || this.props.fields, this.props.ratio, null, null, this.props);
            }
            this.isLoading = false;
          });
        }
      });

      this.props.runOnce && this.disposer();
    }
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    return !this.isLoading ? (
      <div
        className={`${this.data && this.data.data.length === 0 ? 'commonchart-noData' : 'commonchart '} ${this.props.className ? this.props.className : ''}`}>
        {this.data && this.data.data.length > 0 ? (
          <UXSChart
            fullHeight={this.props.fullHeight}
            changeOrderColumn={this.changeOrderColumn}
            data={this.data}
            {...(this.props.order ? this.propsObj : this.props)}
          />
        ) : (
          <TableNullData />
        )}
      </div>
    ) : (
      <Loading />
    );
  }
}
export default Main;
