import React, { Component } from 'react';
import './index.sass';
import confirm from '../../Confirm';
import { iconfont } from '../../../config';
import util from '../../../util';
import pageHeaderStore from '../PageHeaderStore';
import Loading from '../../Loading/Loading';
import api from '../../../api';
import { observable, action, computed, autorun } from 'mobx';
import { observer } from 'mobx-react';
import { createForm } from 'rc-form';
import { Modal, Switch } from 'antd';
import { message } from 'antd';
import WarningModal from '../../../Report/EarlyWarning/Setting';

@observer
class Main extends Component {
  componentDidMount() {
    this.disposer = autorun(() => {
      if (this.props.show) {
        this.data = null;
        let counter = this.counter;
        util.get(api.warning.warningList, { idsite: pageHeaderStore.parameter.idsite }, (res) => {
          this.data = res;
        });
      }
    });
  }

  componentWillUnmount() {
    this.disposer();
  }

  @observable data;
  @observable counter;
  @observable isShowNewModal = false;
  @observable form = {
    warningId: '',
    idsite: '',
    warningName: '',
    warningUrl: document.location.href,
    warningCondition: null,
    warningPattern: '',
    warningFrequency: 0,
    limitTrigger: '',
    warningDate: '',
  };

  tableHeader = [
    {
      name: '预警名称',
      type: 'string',
    },
    {
      name: '当前状态',
      type: 'string',
    },
    {
      name: '触发时间',
      type: 'number',
    },
    {
      name: '预警状态',
      type: 'icon text-center',
    },
  ];

  frequency = [
    {
      value: 0,
      label: '每天预警次数',
    },
    {
      value: 1,
      label: '每周预警次数',
    },
    {
      value: 2,
      label: '每月预警次数',
    },
  ];

  @action.bound changeStatus(item) {
    let status = !item.status;
    let parameter = {
      warningId: item.warningId,
      status: status ? 1 : 0,
    };
    util.post(api.warning.warningStatus, parameter, () => {
      item.status = status;
      message.success('操作成功');
    });
  }

  @action.bound deleteWarningId(id) {
    let parameter = {
      warningId: id,
    };
    util.post(api.warning.warningDelete, parameter, () => {
      this.counter++;
      message.success('删除成功');
    });
  }

  @action.bound showConfirmModel(id) {
    confirm({
      yes: () => this.deleteWarningId(id),
    });
  }

  @action.bound showNewModal(id) {
    this.id = id;
    if (id) {
      util.get(api.warning.warningDetail, { warningId: id }, (res) => {
        this.form.warningName = res.detail.warningName;
        this.form.warningPattern = res.detail.warningPattern;
        this.form.warningFrequency = res.detail.warningFrequency;
        this.form.warningDate = res.detail.warningDate;
        this.form.limitTrigger = res.detail.limitTrigger;
        this.form.warningId = id;
        this.form.warningCondition = JSON.parse(res.condition);
        this.isShowNewModal = true;
        this.props.onHide();
      });
    } else {
      this.isShowNewModal = true;
      this.props.onHide();
    }
  }

  @action.bound hideNewModal(isRefresh) {
    this.isShowNewModal = false;
    this.props.onShow();
    this.form.warningName = '';
    this.form.warningCondition = null;
    this.form.warningPattern = '';
    this.form.warningFrequency = 0;
    this.form.limitTrigger = '';
    this.form.warningDate = '';
    this.form.warningId = '';
    if (isRefresh) {
      this.counter++;
    }
  }

  @action.bound resetFormDefinition(definition) {
    this.form.warningCondition = definition;
  }

  @action.bound getDate(date) {
    this.form.createTime = date;
  }

  @action.bound onSelectTag(array) {
    this.form.warningPattern = array.join(',');
  }

  @action.bound onSelectDropdown(item) {
    this.form.warningFrequency = item.value;
  }

  @computed get warningPatternArray() {
    if (this.form.warningPattern) {
      return this.form.warningPattern.split(',');
    } else {
      return [];
    }
  }

  @action.bound submit() {
    this.props.form.validateFields((error) => {
      if (!error) {
        this.form.idsite = pageHeaderStore.parameter.idsite;
        let warningCondition = JSON.stringify(this.form.warningCondition);
        util.post(api.warning.warningUpdate, { ...this.form, ...{ warningCondition: warningCondition } }, () => {
          this.hideNewModal(true);
        });
      }
    });
  }

  render() {
    return (
      <div>
        <Modal
          className="warning-modal header-space animated UXSModal default"
          destroyOnClose={true}
          cancelButtonProps={{
            className: 'uxs uxs-info hide-btn',
          }}
          okButtonProps={{ className: 'uxs uxs-info' }}
          title=" "
          width={800}
          visible={this.props.show}
          onCancel={this.props.onHide}
          okText="新建预警"
          onOk={() => this.showNewModal()}>
          <div>
            <table className="uxs">
              <thead>
                <tr>
                  {this.tableHeader.map((item, index) => {
                    return (
                      <th key={index} className={this.tableHeader[index].type}>
                        <span>{item.name}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.data &&
                  this.data.map((item, index) => {
                    return (
                      <tr key={index} className="suspension-trigger">
                        <td className="string suspension-wrapper" title={item.description}>
                          {item.warningName}
                          <span className="suspension">
                            {/* <i className="iconfont icon-wenjian" title='新建' onClick={()=> {this.handleSurveyClick(item)}} /> */}
                            <i className={iconfont.edit} title="编辑" onClick={() => this.showNewModal(item.warningId)} />
                            <i className={iconfont.delete} title="删除" onClick={() => this.showConfirmModel(item.warningId)} />
                          </span>
                        </td>
                        {/* <td className="sting" title={item.description}>{item.description}</td> */}
                        <td className="string">{item.trigger || '-'}</td>
                        <td className="number">{item.triggerTime || '-'}</td>
                        <td className="icon text-center">
                          <Switch isChecked={item.status} onChange={() => this.changeStatus(item)} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!this.data && <Loading />}
          </div>
        </Modal>
        {this.isShowNewModal && <WarningModal isShowNewModal={this.isShowNewModal} hideNewModal={this.hideNewModal} id={this.id} />}
      </div>
    );
  }
}

export default createForm()(Main);
