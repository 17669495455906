/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { toJS } from 'mobx';
import { decodeMetricName, getAggregatorLocale, metricAxisLabelFormatter, getFormattedValue, hasMatchedTheCondition } from '../../util';
import { getLegendOption, getGridPositions, getDimetionAxisOption, getCartesianChartReferenceOptions, getCartesianChartMetrics, getFieldFormat } from './util';
import { tooltipStyle, All_LINES_SYMBOL_TYPE } from '../../constants';
import BIStore from '../../store';
import ChartTypes from '../../config/chart/ChartTypes';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps, drillOptions) {
  const {
    data,
    cols,
    metrics,
    chartStyles,
    // color,
    // tip,
    references,
    id,
  } = chartProps;

  const { legend, spec, doubleYAxis, xAxis, splitLine, grid, markPoint, itemStyleConfig } = chartStyles;

  const { legendPosition, fontSize } = legend;

  const { stack, smooth, step, symbol, label } = spec;

  const { yAxisLeft, yAxisRight, yAxisSplitNumber, dataZoomThreshold } = doubleYAxis;

  const { labelColor, labelFontFamily, labelFontSize, lineColor, lineSize, lineStyle, showLabel, showLine, xAxisInterval, xAxisRotate } = xAxis;

  const {
    showVerticalLine,
    verticalLineColor,
    verticalLineSize,
    verticalLineStyle,
    showHorizontalLine,
    horizontalLineColor,
    horizontalLineSize,
    horizontalLineStyle,
  } = splitLine;

  const labelOption = {
    label: {
      normal: {
        show: label,
        position: 'top',
      },
    },
  };

  const { selectedItems } = drillOptions;
  const { secondaryMetrics } = chartProps;

  // 显示标注点，最大值最小值
  const markPointData = [];
  if (markPoint) {
    markPoint.showMax && markPointData.push({ name: '最大值', type: 'max' });
    markPoint.showMin && markPointData.push({ name: '最小值', type: 'min' });
  }

  const xAxisData = showLabel ? data.map((d) => d[cols[0].randomName || cols[0].name]) : [];
  const formattedData = [];
  const seriesData = secondaryMetrics
    ? getAixsMetrics(
        'metrics',
        metrics,
        data,
        stack,
        labelOption,
        references,
        selectedItems,
        { key: 'yAxisLeft', type: yAxisLeft },
        null,
        markPointData,
        itemStyleConfig
      ).concat(
        getAixsMetrics(
          'secondaryMetrics',
          secondaryMetrics,
          data,
          stack,
          labelOption,
          references,
          selectedItems,
          { key: 'yAxisRight', type: yAxisRight },
          metrics.length,
          markPointData,
          itemStyleConfig
        )
      )
    : getAixsMetrics(
        'metrics',
        metrics,
        data,
        stack,
        labelOption,
        references,
        selectedItems,
        { key: 'yAxisLeft', type: yAxisLeft },
        null,
        markPointData,
        itemStyleConfig
      );

  const seriesObj = {
    series: seriesData.map((series) => {
      if (series.type === 'line') {
        return {
          ...series,
          symbol: symbol ? series.symbol || 'emptyCircle' : 'none',
          smooth,
          step,
        };
      } else {
        return series;
      }
    }),
  };
  const fieldFormat = getFieldFormat(secondaryMetrics ? metrics.concat(secondaryMetrics) : metrics);
  let legendOption;
  let gridOptions;
  if (seriesData.length > 1) {
    const seriesNames = seriesData.map((s) => s.name);
    const seriesDisplay = seriesData.map((s) => s.fieldDisplay);
    legendOption = {
      legend: getLegendOption(legend, seriesNames, fieldFormat),
    };
    gridOptions = {
      grid: {
        ...getGridPositions(legend, seriesDisplay, 'doubleYAxis', false, null, xAxis, xAxisData, null, null, formattedData, null, markPoint),
        show: true,
        borderColor: 'transparent',
        backgroundColor: grid ? grid.backgroundColor : 'transparent',
      },
    };
  }

  let leftMax;
  let rightMax;

  if (stack) {
    leftMax = metrics.reduce((num, m) => num + Math.max(...data.map((d) => d[m.randomName || `${m.agg}(${decodeMetricName(m.name)})`])), 0);
    rightMax = secondaryMetrics.reduce((num, m) => num + Math.max(...data.map((d) => d[m.randomName || `${m.agg}(${decodeMetricName(m.name)})`])), 0);
  } else {
    leftMax = Math.max(...metrics.map((m) => Math.max(...data.map((d) => d[m.randomName || `${m.agg}(${decodeMetricName(m.name)})`]))));
    rightMax = Math.max(...secondaryMetrics.map((m) => Math.max(...data.map((d) => d[m.randomName || `${m.agg}(${decodeMetricName(m.name)})`]))));
  }

  const leftInterval = getYaxisInterval(leftMax, yAxisSplitNumber - 1);
  const rightInterval = rightMax > 0 ? getYaxisInterval(rightMax, yAxisSplitNumber - 1) : leftInterval;

  const inverseOption = xAxis.inverse ? { inverse: true } : null;

  const xAxisSplitLineConfig = {
    showLine: showVerticalLine,
    lineColor: verticalLineColor,
    lineSize: verticalLineSize,
    lineStyle: verticalLineStyle,
  };

  const allMetrics = secondaryMetrics ? [].concat(metrics).concat(secondaryMetrics) : metrics;
  const option = {
    tooltip: {
      trigger: 'axis',
      confine: true,
      extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
      axisPointer: { type: 'cross' },
      formatter(params) {
        const dimName = cols[0].randomName || cols[0].name;
        const originData = toJS(BIStore.currentItemsInfo[id].datasource.mappingMap);
        const dimValue = originData[dimName] ? originData[dimName][params[0].name] : getFormattedValue(params[0].name, cols[0].format);
        const tooltipLabels = [dimValue, '<br/>'];
        const newMetrics = getCartesianChartMetrics(allMetrics);
        params.reduce((acc, param) => {
          const { color, value, seriesIndex } = param;
          if (color) {
            acc.push(`<span class="widget-tooltip-circle" style="background: ${color}"></span>`);
          }
          acc.push(newMetrics[seriesIndex].displayName || '');
          acc.push(': ', getFormattedValue(value, allMetrics[seriesIndex].format), '<br/>');
          return acc;
        }, tooltipLabels);
        return tooltipLabels.join('');
      },
      ...tooltipStyle,
    },
    xAxis: getDimetionAxisOption(xAxis, xAxisSplitLineConfig, xAxisData),
    yAxis: [
      {
        type: 'value',
        key: 'yAxisIndex0',
        // min: 0,
        // max: rightMax > 0 ? rightInterval * (yAxisSplitNumber - 1) : leftInterval * (yAxisSplitNumber - 1),
        interval: rightInterval,
        position: 'right',
        ...getDoubleYAxis(doubleYAxis),
      },
      {
        type: 'value',
        key: 'yAxisIndex1',
        // min: 0,
        // max: leftInterval * (yAxisSplitNumber - 1),
        interval: leftInterval,
        position: 'left',
        ...getDoubleYAxis(doubleYAxis),
      },
    ],
    ...seriesObj,
    ...gridOptions,
    ...legendOption,
  };

  return option;

  function getAixsMetrics(
    type,
    axisMetrics,
    data,
    stack,
    labelOption,
    references,
    selectedItems,
    axisPosition,
    firstMetricsLength,
    markPointData,
    itemStyleConfig
  ) {
    const seriesNames = [];
    const seriesAxis = [];
    const referenceOptions = getCartesianChartReferenceOptions(references, ChartTypes.DoubleYAxis, axisMetrics, data);
    let lineSum = 0;

    const newMetrics = getCartesianChartMetrics(axisMetrics);
    newMetrics.forEach((i) => (i.fieldDisplay = i.displayName));
    newMetrics.forEach((m, amIndex) => {
      const decodedMetricName = decodeMetricName(m.name);
      const styleName = m.randomName || m.name;
      const itemStyleInfo = (itemStyleConfig && itemStyleConfig[styleName]) || {};
      const localeMetricName = `[${getAggregatorLocale(m.agg)}] ${decodedMetricName}`;
      seriesNames.push(decodedMetricName);
      const stackOption = stack && axisPosition.type === 'bar' && newMetrics.length > 1 ? { stack: axisPosition.key } : null;
      const itemData = data.map((g, index) => {
        const itemStyle =
          selectedItems && selectedItems.length && selectedItems.some((item) => item === index)
            ? { itemStyle: { normal: { opacity: 1, borderWidth: 6 } } }
            : {};
        const value = g[m.randomName ? m.randomName : `${m.agg}(${decodedMetricName})`];
        if (itemStyleInfo.conditionStyle) {
          const matchColors = [];
          itemStyleInfo.conditionStyle.forEach((item) => {
            const matchTheCondition = hasMatchedTheCondition(value, item.operatorType, item.conditionValues);
            matchTheCondition && matchColors.push(item.color);
          });
          matchColors.length && (itemStyle.itemStyle ? (itemStyle.itemStyle.color = matchColors[0]) : (itemStyle.itemStyle = { color: matchColors[0] }));
        }
        formattedData.push(m.format.formatType === 'default' ? metricAxisLabelFormatter(value) : getFormattedValue(value, m.format));
        return {
          value,
          ...itemStyle,
        };
      });

      const markPointDataCopy = JSON.parse(JSON.stringify(markPointData));

      if (markPointData.length && itemStyleInfo.conditionStyle) {
        const formatName = m.randomName || `${m.agg}(${decodedMetricName})`;
        const recordData = data.map((item) => item[formatName]);
        const max = Math.max(...recordData);
        const min = Math.min(...recordData);
        const maxColors = [];
        itemStyleInfo.conditionStyle.forEach((item) => {
          const matchTheCondition = hasMatchedTheCondition(max, item.operatorType, item.conditionValues);
          matchTheCondition && maxColors.push(item.color);
        });
        const minColors = [];
        itemStyleInfo.conditionStyle.forEach((item) => {
          const matchTheCondition = hasMatchedTheCondition(min, item.operatorType, item.conditionValues);
          matchTheCondition && minColors.push(item.color);
        });
        markPointDataCopy.forEach((item) => {
          item.type === 'max' && maxColors.length && (item.itemStyle = { color: maxColors[0] });
          item.type === 'min' && minColors.length && (item.itemStyle = { color: minColors[0] });
          item.type === 'max' && (item.label = { formatter: getFormattedValue(max, m.format) });
          item.type === 'min' && (item.label = { formatter: getFormattedValue(min, m.format) });
        });
      }
      const chartType = axisPosition && axisPosition.type ? axisPosition.type : type === 'metrics' ? 'line' : 'bar';
      seriesAxis.push({
        name: m.fieldDisplay || '',
        type: chartType,
        ...stackOption,
        yAxisIndex: type === 'metrics' ? 1 : 0,
        markPoint: {
          data: markPointDataCopy,
          symbolSize: 40,
        },
        data: itemData,
        ...labelOption,
        ...(amIndex === newMetrics.length - 1 && referenceOptions),
        itemStyle: {
          normal: {
            opacity: selectedItems && selectedItems.length > 0 ? 0.25 : 1,
            color: defaultThemeColors[(amIndex % defaultThemeColors.length) + (firstMetricsLength || 0)],
          },
        },
        symbol: getSymbolShape(itemStyleConfig, itemStyleInfo, lineSum),
        symbolSize: itemStyleInfo.symbolSize || 6,
        emphasis: {
          focus: 'series',
          lineStyle: {
            width: 3,
          },
        },
      });
      chartType === 'line' && (lineSum += 1);
    });
    return seriesAxis;
  }
}

function getSymbolShape(itemStyleConfig, itemStyleInfo, index) {
  if (itemStyleInfo && itemStyleInfo.symbolShape) {
    return itemStyleInfo.symbolShape;
  } else if (itemStyleConfig.cem_commonStyle && itemStyleConfig.cem_commonStyle.type === 'unify') {
    return itemStyleConfig.cem_commonStyle.symbolShape;
  } else {
    return All_LINES_SYMBOL_TYPE[index % All_LINES_SYMBOL_TYPE.length];
  }
}

export function getYaxisInterval(max, splitNumber) {
  const roughInterval = parseInt(`${max / splitNumber}`, 10);
  const divisor = Math.pow(10, `${roughInterval}`.length - 1);
  return (parseInt(`${roughInterval / divisor}`, 10) + 1) * divisor;
}

export function getDoubleYAxis(doubleYAxis) {
  const { inverse, showLine, lineStyle, lineSize, lineColor, showLabel, labelFontFamily, labelFontSize, labelColor } = doubleYAxis;

  return {
    inverse,
    axisLine: {
      show: showLine,
      lineStyle: {
        color: lineColor,
        width: Number(lineSize),
        type: lineStyle,
      },
    },
    axisLabel: {
      show: showLabel,
      color: labelColor,
      fontFamily: labelFontFamily,
      fontSize: Number(labelFontSize),
      formatter: metricAxisLabelFormatter,
    },
  };
}
