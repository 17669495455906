import React, { Component } from 'react';
import { Button, Form, Input, Radio, message } from 'antd';
import UXSDrawer from '../../../component/UXSDrawer/index';
import { observer } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import EventSelect from '../../../component/EventSelect/index';
import ChildrenFilter from '../../../containers/PageHeaderFilters/Setting/Setting/ChildrenFilter/index';
import DimSelect from '../../../component/DimSelect/index';
import './index.sass';
import intl from 'react-intl-universal';

const images = {
  sams: {
    analysePath1: require('../../../static/images/analysePath1-blue.png').default,
    analysePath2: require('../../../static/images/analysePath2-blue.png').default,
  },
  ipsos: {
    analysePath1: require('../../../static/images/analysePath1-blue.png').default,
    analysePath2: require('../../../static/images/analysePath2-blue.png').default,
  },
};

@observer
class Setting extends Component {
  constructor(props) {
    super(props);

    this.formStore = this.props.formStore;
    this.pathStepData = this.props.formStore.pathStepData;
    this.stepIndex = this.props.stepIndex;
    this.itemIndex = this.props.itemIndex;
  }
  @observable inputValue = this.pathStepData[this.stepIndex][this.itemIndex]['stepName'];
  @observable type = this.pathStepData[this.stepIndex][this.itemIndex]['type'];
  @observable eventStore = {
    form: {
      eventAndTargetGroup: [
        {
          dim: 'event',
          type: this.pathStepData[this.stepIndex][this.itemIndex]['ruleContent'][0].type,
          dimValue: this.pathStepData[this.stepIndex][this.itemIndex]['ruleContent'][0].dimValue,
          formula: '=',
        },
      ],
      filterObject: toJS(this.pathStepData[this.stepIndex][this.itemIndex]['filterObject']),
      subDividedDimGroup: [toJS(this.pathStepData[this.stepIndex][this.itemIndex]['dimension'])],
      filterItemLogic: toJS(this.pathStepData[this.stepIndex][this.itemIndex]['filterItemLogic']),
      childrenFilter: toJS(this.pathStepData[this.stepIndex][this.itemIndex]['childrenFilter']),
    },
    subDimOptions: [],
  };

  // childrenFilter所用到的参数
  @action.bound changeFilterLogic(logic, index) {
    this.eventStore.form.filterItemLogic[index] = logic;
    this.eventStore.form.filterObject.logic = logic;
  }
  @action.bound setChildrenFilter(filter, index) {
    this.eventStore.form.childrenFilter[index] = filter;
    this.eventStore.form.filterObject = {
      logic: this.eventStore.form.filterItemLogic[index],
      filters: this.eventStore.form.childrenFilter[index],
    };
  }
  // childrenFilter所用到的参数
  // 保存
  judgeFilterObject(obj) {
    let result = true;
    toJS(obj).forEach((item) => {
      if (!item.value) {
        result = false;
      }
    });
    return result;
  }
  handleSubmit = () => {
    let { eventAndTargetGroup, filterObject, subDividedDimGroup, filterItemLogic, childrenFilter } = this.eventStore.form;
    if (this.type === 2 && subDividedDimGroup[0].length === 0) {
      message.info(intl.get('Subdivisiondimensionofintelligentpathcannotbeempty'));
    } else if (toJS(filterObject.filters) && !this.judgeFilterObject(toJS(filterObject.filters))) {
      message.info(intl.get('Pleaseaddadvancedscreeningcriteria'));
    } else {
      let dimension = subDividedDimGroup.join(',');
      if (this.type === 1) {
        dimension = '';
      }
      //  改变步骤名
      this.formStore.changeStepName(this.inputValue, this.stepIndex, this.itemIndex);
      // 改变类型是否是智能步骤
      this.formStore.changeType(this.type, this.stepIndex, this.itemIndex);
      // 改变事件
      this.formStore.changeFilters(eventAndTargetGroup, this.stepIndex, this.itemIndex);
      // 改变筛选条件
      this.formStore.changeFilterObject(filterObject, this.stepIndex, this.itemIndex);
      // 改变筛选条件的逻辑
      this.formStore.changeFilterItemLogic(filterItemLogic, this.stepIndex, this.itemIndex);
      // 改变筛选条件的中间件
      this.formStore.changeChildrenFilter(childrenFilter, this.stepIndex, this.itemIndex);
      // 改变维度值
      this.formStore.changeDimension(dimension, this.stepIndex, this.itemIndex);
      message.success(intl.get('Savedsuccessfully'));
      this.props.showModal();
    }
  };

  // 更改步骤名
  changeInputValue = (e) => {
    this.inputValue = e.target.value;
  };
  // 切换路径类型
  changeType = (e) => {
    this.type = e.target.value;
  };
  // 每个事件有不同的维度选项，所以该变量subDimOptions为事件切换后的维度下拉选项
  @action.bound resetSubDimOptions = (value) => {
    this.eventStore.subDimOptions = value;
  };
  // 智能路径显示单个表单维度 暂时默认修改第一位（下标为0）
  @action.bound setSubDividedDim = (value, index) => {
    this.eventStore.form.subDividedDimGroup[0] = value[1];
  };
  // 单个表单事件 暂时默认修改第一位（下标为0）
  @action.bound changeEventAndTarget(value, index) {
    this.eventStore.form.eventAndTargetGroup[0] = value;
  }
  // 单个表单高级筛选 暂时默认修改第一位（下标为0）
  @action.bound resetFormDefinition(definition) {
    this.eventStore.form['filterObject'] = definition;
  }

  render() {
    const formItemLayout = {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 15,
      },
    };
    return (
      <UXSDrawer
        destroyOnClose={true}
        className="full-right scroll-drawer"
        title={
          <div>
            {intl.get('Create')}
            {intl.get('Step')}
            <Button type="primary" onClick={this.handleSubmit}>
              {intl.get('Save')}
            </Button>
          </div>
        }
        show={this.props.isShowModal}
        onHide={this.props.showModal}
        width={800}>
        <div className="pathStep_form">
          <Form {...formItemLayout}>
            <Form.Item label={`${intl.get('Step')}${intl.get('Name')}:`} colon={false}>
              <Input
                className="name-input md-input"
                value={this.inputValue}
                onChange={(e) => this.changeInputValue(e)}
                placeholder={intl.get('PleaseEnter')}
                type="text"
                autoComplete="off"
              />
              <div className="pathStep_form-pic">
                {/* 普通路径选择之后不能再选择智能路径 */}
                <div className="pathStep_form-pic-item">
                  <Radio.Group disabled={this.itemIndex >= 1} onChange={(e) => this.changeType(e)} value={this.type}>
                    <div
                      className={`pathStep_form-pic-item-box ${this.itemIndex >= 1 ? 'disabled' : ''}`}
                      onClick={(e) => this.itemIndex < 1 && this.changeType({ target: { value: 1 } })}>
                      <Radio style={{ position: 'absolute', top: 12, left: 16 }} value={1}>
                        <span style={{ color: '#050A13' }}>
                          {intl.get('Ordinary')}
                          {intl.get('Step')}
                        </span>
                      </Radio>
                      {this.type === 1 ? (
                        <img
                          src={
                            process.env.REACT_APP_BDkey && images[process.env.REACT_APP_BDkey]
                              ? images[process.env.REACT_APP_BDkey].analysePath1
                              : require('../../../static/images/analysePath1-green.png').default
                          }
                          alt="cemyun"
                          className="cemyun-logo"
                        />
                      ) : (
                        <img src={require('../../../static/images/analysePath1-gray.png').default} alt="cemyun" className="cemyun-logo" />
                      )}
                    </div>
                    <div
                      className={`pathStep_form-pic-item-box ${this.itemIndex >= 1 ? 'disabled' : ''}`}
                      onClick={(e) => this.itemIndex < 1 && this.changeType({ target: { value: 2 } })}>
                      <Radio style={{ position: 'absolute', top: 12, left: 16 }} value={2}>
                        <span style={{ color: '#050A13' }}>
                          {intl.get('Intelligent')}
                          {intl.get('Step')}
                        </span>
                      </Radio>
                      {this.type === 1 ? (
                        <img src={require('../../../static/images/analysePath2-gray.png').default} alt="cemyun" className="cemyun-logo" />
                      ) : (
                        <img
                          src={
                            process.env.REACT_APP_BDkey && images[process.env.REACT_APP_BDkey]
                              ? images[process.env.REACT_APP_BDkey].analysePath2
                              : require('../../../static/images/analysePath2-green.png').default
                          }
                          alt="cemyun"
                          className="cemyun-logo"
                        />
                      )}
                    </div>
                  </Radio.Group>
                </div>

                {this.type === 1 ? (
                  <div className="pathStep_form-pic-text">{intl.get('Thepathmapwillonlyshowthestepsyouset')}</div>
                ) : (
                  <div className="pathStep_form-pic-text">
                    {intl.get('Thefirstthreepagesoreventsbetweenthepreviousandnextstepsofintelligentcomputingaredisplayedinthepathgraph')}
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label={`${intl.get('SelectEvent')}:`} colon={false}>
              <div className="pathStep-setting">
                <EventSelect
                  useType={'onlyEvent'}
                  eventAndTargetGroup={this.eventStore.form.eventAndTargetGroup}
                  eventValue={this.eventStore.form.eventAndTargetGroup[0]}
                  index={0}
                  changeEventAndTarget={this.changeEventAndTarget}
                  noTargetSelect={true}
                  formStore={this.eventStore}></EventSelect>
                <div className="pathStep-setting-childrenFilter">
                  <ChildrenFilter
                    filterCount={0}
                    isShow={true} //判断是否显示
                    eventValue={this.eventStore.form.eventAndTargetGroup}
                    type={'default'} //type
                    filter={this.eventStore.form['childrenFilter']} //二维数组
                    changeFilterLogic={this.changeFilterLogic}
                    needLogic={true}
                    filterItemLogic={this.eventStore.form['filterItemLogic']} //数组
                    hasPage={false} //是否页面访问
                    setChildrenFilter={this.setChildrenFilter}></ChildrenFilter>
                </div>
              </div>
            </Form.Item>

            {this.type === 2 ? (
              <Form.Item label={`${intl.get('SubdivisionDimension')}:`} colon={false}>
                <DimSelect
                  eventAndTargetGroup={this.eventStore.form.eventAndTargetGroup}
                  dimItem={this.eventStore.form.subDividedDimGroup[0]}
                  dimIndex={0}
                  setSubDividedDim={this.setSubDividedDim}></DimSelect>
              </Form.Item>
            ) : null}
          </Form>
        </div>
      </UXSDrawer>
    );
  }
}
export default Form.create()(Setting);
