import React, { Component } from 'react';
import { Empty, Steps } from 'antd';

import './index.sass';
const { Step } = Steps;
class Main extends Component {
  render() {
    const { msg, nullDataType } = this.props;
    switch (nullDataType) {
      case 'customBoard':
        return (
          <React.Fragment>
            {/* web-style与mobile-style区分自定义报告版的中不同文字 */}
            <div className="customBoard-null-data web-style">
              <Empty image={require('../../static/images/null_data.svg').default} description="暂无数据图表，可点击右上角添加数据图表" />
            </div>
            <div className="customBoard-null-data mobile-style">
              <Empty image={require('../../static/images/null_data.svg').default} description="暂无数据图表，可进入web端添加数据图表" />
            </div>
          </React.Fragment>
        );
      case 'pageGroup':
        return (
          <div className="table-null-data">
            <Empty image={require('../../static/images/null_data.svg').default} description="暂无页面组，您可以在设置中设置页面组" />
          </div>
        );
      case 'analysePath':
        return (
          <div className="table-null-step">
            <Steps direction="vertical" size="small" current={-1}>
              <Step title="增加路径步骤" description=" " />
              <Step title="编辑路径条件" description=" " />
              <Step title="选择细分维度(默认不选为时间维度)" description=" " />
              <Step title="生成预览报告" description=" " />
            </Steps>
          </div>
        );
      case 'analyseEvent':
        return (
          <div className="table-null-data">
            <Empty image={require('../../static/images/null_data.svg').default} description="暂无趋势图，可查看表格数据" />
          </div>
        );
      case 'analyseSurvey':
        return (
          <div className="table-null-step">
            <Steps direction="vertical" size="small" current={-1}>
              <Step title="选择要分析的调研问卷及题目(同一题目)" description=" " />
              <Step title="选择细分维度拆分指标" description=" " />
              <Step title="生成预览报告" description=" " />
            </Steps>
          </div>
        );
      case 'authority':
        return (
          <div className="table-null-data">
            <Empty image={require('../../static/images/null_data.svg').default} description="暂无权限访问" />
          </div>
        );
      case 'notFound':
        return (
          <div className="table-null-data">
            <Empty image={require('../../static/images/null_data.svg').default} description="您所访问的页面暂无权限访问或页面不存在" />
          </div>
        );
      case 'summary':
        return <div className="table-null-text">暂无数据</div>;
      case 'boardCard':
        return (
          <div className="table-null-data">
            <Empty image={require('../../static/images/null_data.svg').default} description={<pre className="perm-msg">{msg}</pre>} />
          </div>
        );
      default:
        return (
          <div className={`table-null-data ${this.props.className}`} style={{ ...this.props.style }}>
            <Empty image={require('../../static/images/null_data.svg').default} description="暂无数据" />
          </div>
        );
    }
  }
}

export default Main;
