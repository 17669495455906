import React, { FC, Suspense } from 'react';
import classnames from 'classnames';
import { Form, Row, Col, Radio as AntRadio, Icon, Input } from 'antd';
import RelativeDatePicker from '../../../../RelativeDatePicker';
import Select from '../../Control/Select';
import Radio from '../../Control/Radio';
import Date from '../../Control/Date';
import DateRange from '../../Control/DateRange';
import InputText from '../../Control/InputText';
import TreeSelect from '../../Control/TreeSelect';
import Slider from '../../Control/Slider';
import NumberRange from '../../../../NumberRange';
import { TreeNode } from 'antd/lib/tree-select';
import { ControlTypes, ControlDefaultValueTypes, ControlOptionTypes, IS_DATE_TYPE } from '../../constants';
import '../../../../../static/less/util.less';
import QuickDateRange from '../../Control/QuickDateRange';
const FormItem = Form.Item;
const RadioGroup = AntRadio.Group;
const RadioButton = AntRadio.Button;

const DefaultValue = ({ form, controlBase, defaultValueOptions, defaultValueLoading, onDefaultValueTypeChange, onGetDefaultValueOptions }) => {
  const { getFieldDecorator, setFieldsValue } = form;
  const { type, multiple, optionType, defaultValueType, customOptions } = controlBase;

  const colSpan = { xxl: 12, xl: 20 };
  const itemCols = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
  };

  const onTypeChange = (value) => {
    setFieldsValue({ quickPickerType: value });
  };

  let component;

  switch (type) {
    case ControlTypes.Select:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              {[ControlOptionTypes.Auto, ControlOptionTypes.Manual].includes(optionType) && (
                <a onClick={onGetDefaultValueOptions}>
                  {`点击加载 `}
                  {defaultValueLoading && <Icon type="loading" />}
                </a>
              )}
              {getFieldDecorator(
                'defaultValue',
                {}
              )(<Select control={controlBase} options={optionType === ControlOptionTypes.Custom ? customOptions || [] : defaultValueOptions} />)}
            </FormItem>
          </Col>
        </Row>
      );
      break;
    case ControlTypes.Radio:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              {[ControlOptionTypes.Auto, ControlOptionTypes.Manual].includes(optionType) && (
                <a onClick={onGetDefaultValueOptions}>
                  {`点击加载 `}
                  {defaultValueLoading && <Icon type="loading" />}
                </a>
              )}
              {getFieldDecorator('defaultValue', {
                rules: [{ required: true, message: '单选按钮默认值不能为空' }],
              })(<Radio control={controlBase} options={optionType === ControlOptionTypes.Custom ? customOptions || [] : defaultValueOptions} />)}
            </FormItem>
          </Col>
        </Row>
      );
      break;
    case ControlTypes.Date:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              {defaultValueType === ControlDefaultValueTypes.Fixed ? (
                <Suspense fallback={null}>{getFieldDecorator('defaultValue', {})(<Date control={controlBase} size="default" />)}</Suspense>
              ) : (
                getFieldDecorator('defaultValue', {})(<RelativeDatePicker />)
              )}
            </FormItem>
          </Col>
        </Row>
      );
      break;
    case ControlTypes.DateRange:
      component =
        defaultValueType === ControlDefaultValueTypes.Fixed ? (
          <Row>
            <Col {...colSpan}>
              <FormItem label="默认值" {...itemCols}>
                {getFieldDecorator('defaultValue', {})(<DateRange control={controlBase} />)}
              </FormItem>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col {...colSpan}>
                <FormItem label="默认值-起始" {...itemCols}>
                  {getFieldDecorator('defaultValueStart', {})(<RelativeDatePicker />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col {...colSpan}>
                <FormItem label="默认值-结束" {...itemCols}>
                  {getFieldDecorator('defaultValueEnd', {})(<RelativeDatePicker />)}
                </FormItem>
              </Col>
            </Row>
          </>
        );
      break;
    case ControlTypes.QuickDateRange:
      component = (
        <>
          <Row>
            <Col {...colSpan}>
              <FormItem label="允许切换周期" {...itemCols}>
                {getFieldDecorator(
                  'canChange',
                  {}
                )(
                  <RadioGroup size="small" defaultValue={true}>
                    <RadioButton value={true}>是</RadioButton>
                    <RadioButton value={false}>否</RadioButton>
                  </RadioGroup>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col {...colSpan}>
              <FormItem label="默认值" {...itemCols}>
                {getFieldDecorator('quickPickerType', {})(<Input className={'hide'} />)}
                {getFieldDecorator('defaultValue', {})(<QuickDateRange control={controlBase} onTypeChange={onTypeChange} />)}
              </FormItem>
            </Col>
          </Row>
        </>
      );
      break;
    case ControlTypes.InputText:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              {getFieldDecorator('defaultValue', {})(<InputText />)}
            </FormItem>
          </Col>
        </Row>
      );
      break;
    case ControlTypes.NumberRange:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              {getFieldDecorator('defaultValue', {})(<NumberRange />)}
            </FormItem>
          </Col>
        </Row>
      );
      break;
    case ControlTypes.Slider:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              {getFieldDecorator('defaultValue', {
                rules: [{ required: true, message: '数字滑块默认值不能为空' }],
              })(<Slider control={controlBase} />)}
            </FormItem>
          </Col>
        </Row>
      );
      break;
    case ControlTypes.TreeSelect:
      component = (
        <Row>
          <Col {...colSpan}>
            <FormItem label="默认值" {...itemCols}>
              <a onClick={onGetDefaultValueOptions}>
                {`点击加载 `}
                {defaultValueLoading && <Icon type="loading" />}
              </a>
              {getFieldDecorator('defaultValue', {})(<TreeSelect control={controlBase} options={defaultValueOptions} />)}
            </FormItem>
          </Col>
        </Row>
      );
      break;
  }

  return (
    <>
      <Row>
        <Col
          className={classnames({
            hide: !(IS_DATE_TYPE[type] && !multiple),
          })}
          {...colSpan}>
          <FormItem label="默认值类型" {...itemCols}>
            {getFieldDecorator(
              'defaultValueType',
              {}
            )(
              <RadioGroup onChange={onDefaultValueTypeChange}>
                <RadioButton value={ControlDefaultValueTypes.Fixed}>固定值</RadioButton>
                {type !== 'quickDateRange' && <RadioButton value={ControlDefaultValueTypes.Dynamic}>动态值</RadioButton>}
              </RadioGroup>
            )}
          </FormItem>
        </Col>
      </Row>
      {component}
    </>
  );
};

export default DefaultValue;
