import React, { PureComponent, GetDerivedStateFromProps } from 'react';
import DashboardControlPanelLayout from './Layouts/Dashboard';
import {
  SHOULD_LOAD_OPTIONS,
  CHANGE_IMMEDIATELY,
  ControlPanelTypes,
  ControlPanelLayoutTypes,
  ControlQueryMode,
  ControlFieldTypes,
  ControlOptionTypes,
} from '../../constants';
import {
  getVariableParams,
  getFilterParams,
  getAllChildren,
  getParents,
  getPanelRenderState,
  getCustomOptionVariableParams,
  cleanInvisibleConditionalControlValues,
} from '../../util';
import { toJS } from 'mobx';

class ControlPanel extends PureComponent {
  state = {
    renderTree: [],
    flatTree: {},
    defaultValues: {},
    prevControls: [],
    prevItems: '',
  };

  static getDerivedStateFromProps = (props, state) => {
    const { type, controls, items } = props;
    let nextState = {
      prevControls: controls,
      prevItems: items,
    };

    if (state.prevControls !== controls || state.prevItems !== items) {
      nextState = {
        ...nextState,
        ...getPanelRenderState(type, controls, items),
      };
    }

    return nextState;
  };

  componentDidMount() {
    // this.initFormValuesAndSelectOptions(this.props, this.state, Object.keys(this.props.formValues).length > 0);
    this.initFormValuesAndSelectOptions(this.props, this.state, true);
  }

  componentDidUpdate(prevProps) {
    const { controls, reload, onSearch, queryMod, isCurrentDashboardUpdated } = this.props;
    const { defaultValues } = this.state;

    if (prevProps.controls !== controls) {
      this.initFormValuesAndSelectOptions(this.props, this.state, isCurrentDashboardUpdated ? true : false);
    }

    // if (reload && queryMode === ControlQueryMode.Immediately) {
    //   onSearch(defaultValues);
    // }
  }

  initFormValuesAndSelectOptions(props, state, initiated) {
    const { formValues, onChange, onSearch } = props;
    const { flatTree, defaultValues } = state;
    const initialFormValues = initiated ? defaultValues : formValues;
    onSearch(initialFormValues);
    onChange(initialFormValues);
    // 待优化 穷途末路
    this.count = Object.values(flatTree).filter((i) => SHOULD_LOAD_OPTIONS[i.type]).length;
    Object.values(flatTree).forEach((control) => {
      if (SHOULD_LOAD_OPTIONS[control.type]) {
        (!initiated || control.type === 'radio') && this.loadOptions(control, flatTree, initialFormValues);
      }
    });
  }

  loadOptions = (renderControl, flatTree, controlValues, selectValue, isSelectSearch) => {
    const { formedViews, type, items, onGetOptions, mapOptions, onSetOptions } = this.props;
    const {
      key,
      parent,
      cache,
      expired,
      optionType,
      valueViewId,
      valueField,
      textField,
      parentField,
      customOptions,
      relatedViews,
      valueViewName,
      valueFieldSqlType,
      textFieldSqlType,
    } = renderControl;

    if (optionType === ControlOptionTypes.Custom) {
      // onGetOptions(key, true, customOptions, type === ControlPanelTypes.Local ? Number(items) : void 0);
      mapOptions[key] = customOptions;
      onSetOptions(mapOptions);
      this.count = this.count - 1;
      if (!this.count) {
        const final = { ...mapOptions };
        onSetOptions(final);
      }
      // const newOptions = { ...mapOptions };
      // newOptions[key] = customOptions;
      // onSetOptions(newOptions);
    } else {
      const parents = getParents(parent, flatTree);
      const requestParams = {};

      // get cascading conditions
      Object.entries(relatedViews).forEach(([viewId, relatedView]) => {
        let filters = [];
        const variables = [];

        parents.forEach((parentControl) => {
          const parentValue = controlValues[parentControl.key];

          Object.entries(parentControl.relatedViews).forEach(([parentViewId, parentRelatedView]) => {
            if (viewId === parentViewId) {
              let cascadeRelatedViewId;
              let cascadeRelatedFields;

              switch (optionType) {
                case ControlOptionTypes.Auto:
                  cascadeRelatedViewId = viewId;
                  cascadeRelatedFields = parentRelatedView.fields;
                  break;
                case ControlOptionTypes.Manual:
                  if (valueViewId === Number(parentViewId)) {
                    cascadeRelatedViewId = parentViewId;
                    cascadeRelatedFields = parentRelatedView.fields;
                  } else if (parentControl.optionType === ControlOptionTypes.Manual && valueViewId === parentControl.valueViewId) {
                    cascadeRelatedViewId = parentControl.valueViewId;
                    cascadeRelatedFields = [parentControl.valueField];
                  }
                  break;
              }

              if (cascadeRelatedViewId && cascadeRelatedFields && formedViews[cascadeRelatedViewId]) {
                const { model } = formedViews[cascadeRelatedViewId];
                filters = filters.concat(getFilterParams(parentControl, cascadeRelatedFields, parentValue, model, cascadeRelatedViewId));
              }
            }
          });
        });

        switch (optionType) {
          case ControlOptionTypes.Auto:
            if (relatedView.fieldType === ControlFieldTypes.Column) {
              requestParams[viewId] = {
                columns: relatedView.fields,
                filters: isSelectSearch ? this.getSelectFilter(filters, selectValue, renderControl) : filters,
                variables,
                cache,
                expired,
                workFilters: relatedView.workFilters,
              };
            }
            break;
          case ControlOptionTypes.Manual:
            requestParams[valueViewId] = {
              columns: [valueField, textField, parentField].filter((f) => !!f),
              filters: isSelectSearch ? this.getSelectFilter(filters, selectValue, renderControl) : filters,
              variables,
              cache,
              expired,
              workFilters: relatedView.workFilters,
              type: 'manual',
              valueField,
              textField,
              valueViewId,
              valueViewName,
              valueFieldSqlType,
              textFieldSqlType,
            };
            break;
        }
      });

      if (Object.keys(requestParams).length) {
        // onGetOptions(key, false, requestParams, type === ControlPanelTypes.Local ? Number(items) : void 0);
        onGetOptions(requestParams, (options) => {
          mapOptions[key] = options;
          onSetOptions(mapOptions);
          this.count = this.count - 1;
          if (!this.count) {
            const final = { ...mapOptions };
            onSetOptions(final);
          }
          // const newOptions = { ...mapOptions };
          // newOptions[key] = options;
          // onSetOptions(newOptions);
        });
      }
    }
  };

  change = (control, val) => {
    const { controls, queryMode, formValues, onChange, onSearch, mapOptions, onSetOptions } = this.props;
    const { flatTree } = this.state;
    const { key, type } = control;
    const childrenKeys = getAllChildren(key, flatTree);
    const SonFollowFather = {};
    // 父控制器清空子控制器跟随清空
    if (childrenKeys.length && (!val || !val.length)) {
      childrenKeys.map((key) => {
        SonFollowFather[key] = undefined;
      });
    }
    if (childrenKeys.length) {
      let copyOptions = { ...mapOptions };
      childrenKeys.map((key) => {
        delete copyOptions[key];
      });
      onSetOptions({ ...copyOptions });
    }
    const controlValue = {
      [key]: val,
    };
    const cleanedInvisibleValues = cleanInvisibleConditionalControlValues(controls, control, formValues);
    const updatedFormValues = {
      ...formValues,
      ...controlValue,
      ...cleanedInvisibleValues,
      ...SonFollowFather,
    };

    if (childrenKeys.length) {
      childrenKeys.forEach((childKey) => {
        const child = flatTree[childKey];
        if (SHOULD_LOAD_OPTIONS[child.type]) {
          this.loadOptions(child, flatTree, updatedFormValues);
        }
      });
    }

    onChange(updatedFormValues);

    if (queryMode === ControlQueryMode.Immediately && CHANGE_IMMEDIATELY[type]) {
      onSearch(controlValue);
    }
  };

  reset = () => {
    const { queryMode, onChange, onSearch } = this.props;
    const { defaultValues } = this.state;
    onChange(defaultValues);
    onSearch(defaultValues, 'reset');
  };

  onSelectSearch = (control, val) => {
    const { flatTree, defaultValues } = this.state;
    this.loadOptions(control, flatTree, defaultValues, val, true);
  };

  getSelectFilter = (filters, val, control) => {
    const { relatedViews } = control;
    const { formedViews } = this.props;
    if (!val) {
      return filters;
    }
    Object.entries(relatedViews).forEach(([viewId, relatedView]) => {
      const fields = relatedView.fields;
      const { model } = formedViews[viewId];
      const filter = getFilterParams(control, fields, val, model, viewId, true);
      if (filter.length) {
        filter[0].operator = 'like';
        filter[0].filterType = 'likeFilter';
      }
      filters = filters.concat(filter);
    });
    return filters;
  };

  render() {
    const { layoutType, queryMode, formValues, mapOptions, onSearch, parentControls } = this.props;

    const { renderTree } = this.state;

    const layoutProps = {
      queryMode,
      renderTree: parentControls || renderTree,
      formValues,
      mapOptions,
      onChange: this.change,
      onSearch,
      onReset: this.reset,
      onSelectSearch: this.onSelectSearch,
    };

    switch (layoutType) {
      case ControlPanelLayoutTypes.Dashboard:
        return <DashboardControlPanelLayout {...layoutProps} />;
      default:
        return null;
    }
  }
}

export default ControlPanel;
