export default {
  version: 1,
  themeName: '星空蓝',
  theme: {
    seriesCnt: '3',
    backgroundColor: '#20283d',
    titleColor: '#d3d6e0',
    subtitleColor: '#7a8299',
    textColorShow: false,
    textColor: '#333',
    markTextColor: '#d3d6e0',
    color: ['#238BDB', '#2AC5FF', '#85D9FF', '#14c4a2', '#71EB8E', '#C3FFB1', '#13a9c2', '#23D6DC', '#62EDF2', '#F37623', '#F9A360', '#FEDA6E'],
    borderColor: '#ffffff',
    borderWidth: '0',
    visualMapColor: ['#59c1fe', '#2aa3ff', '#3d63cc'],
    legendTextColor: '#d3d6e0',
    kColor: '#d0648a',
    kColor0: 'transparent',
    kBorderColor: '#d0648a',
    kBorderColor0: '#22c3aa',
    kBorderWidth: '1',
    lineWidth: '3',
    symbolSize: '8',
    symbol: 'emptyCircle',
    symbolBorderWidth: '2',
    lineSmooth: false,
    graphLineWidth: '1',
    graphLineColor: '#cccccc',
    mapLabelColor: '#28544e',
    mapLabelColorE: '#349e8e',
    mapBorderColor: '#999999',
    mapBorderColorE: '#22c3aa',
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: '#eeeeee',
    mapAreaColorE: 'rgba(34,195,170,0.25)',
    axes: [
      {
        type: 'all',
        name: '通用坐标轴',
        axisLineShow: true,
        axisLineColor: '#4e5466',
        axisTickShow: true,
        axisTickColor: '#4e5466',
        axisLabelShow: true,
        axisLabelColor: '#7a8299',
        splitLineShow: true,
        splitLineColor: ['#2f364a'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(207,50,50,0.05)', 'rgba(200,200,200,0.02)'],
      },
      {
        type: 'category',
        name: '类目坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: false,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'value',
        name: '数值坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'log',
        name: '对数坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'time',
        name: '时间坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: '#7a8299',
    toolboxEmpasisColor: '#3d63cc',
    tooltipAxisColor: '#4e5466',
    tooltipAxisWidth: 1,
    timelineLineColor: '#3d63cc',
    timelineLineWidth: '4',
    timelineItemColor: '#59c1fe',
    timelineItemColorE: '#2aa3ff',
    timelineCheckColor: '#3d63cc',
    timelineCheckBorderColor: '#59c1fe',
    timelineItemBorderWidth: 1,
    timelineControlColor: '#3d63cc',
    timelineControlBorderColor: '#3d63cc',
    timelineControlBorderWidth: '0.5',
    timelineLabelColor: '#858585',
    datazoomBackgroundColor: 'rgba(255,255,255,0)',
    datazoomDataColor: '#3d63cc',
    datazoomFillColor: '#374057',
    datazoomHandleColor: '#525867',
    datazoomHandleWidth: '100',
    datazoomLabelColor: '#7a8299',
  },
};
