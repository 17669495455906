import AnalyseTableLayout from '../../containers/AnalyseTableLayout/index';
export default {
  title: '数据分析',
  components: [
    {
      component: AnalyseTableLayout,
      props: {
        analyseType: 'BIAnalytics',
        type: 9,
        title: '数据分析',
      },
    },
  ],
};
