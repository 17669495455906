import React from 'react'
import { Input, Button, Form, Modal } from 'antd'
const FormItem = Form.Item

export class AliasExpressionTest extends React.PureComponent {
  constructor() {
    super()
    this.labelCol = { span: 8 }
    this.wrapperCol = { span: 14 }
  }

  renderQueryVarItem = (queryVariableName) => {
    const { form } = this.props
    const { getFieldDecorator } = form
    return (
      <FormItem key={queryVariableName} label={queryVariableName} labelCol={this.labelCol} wrapperCol={this.wrapperCol}>
        {getFieldDecorator(queryVariableName)(<Input />)}
      </FormItem>
    )
  }

  ok = () => {
    const { form, onTest } = this.props
    let queryVariables = form.getFieldsValue()
    queryVariables = Object.entries(queryVariables).reduce((obj, [key, value]) => {
      obj[`$${key}$`] = value
      return obj
    }, {})
    onTest(queryVariables)
  }

  close = () => {
    this.props.onClose()
  }

  testModalFooter = [(
    <Button
      key="cancel"
      size="large"
      onClick={this.close}
    >
      关 闭
    </Button>
  ), (
    <Button
      key="submit"
      size="large"
      type="primary"
      onClick={this.ok}
    >
      确 定
    </Button>
  )]

  render() {
    const { visible, queryVariableNames } = this.props

    return (
      <Modal
        title="变量值输入"
        wrapClassName="ant-modal-small"
        footer={this.testModalFooter}
        visible={visible}
        onCancel={this.close}
      >
        <Form>
          {queryVariableNames.map((name) => this.renderQueryVarItem(name))}
        </Form>
      </Modal>
    )
  }

}

export default Form.create()(AliasExpressionTest)
