import React, { useEffect, useState, Fragment } from 'react';
import api from '../../api';
import { Popconfirm, Switch } from 'antd';
import SearchHeader from '../../component/SearchHeader';
import CommonTable from '../CommonTable';
import Modal from './Modal';
import CopyModal from './Modal/CopyModal';
import util from '../../util';
import intl from 'react-intl-universal';
import TablePageStore from '../CommonTable/TablePageStore';
import moment from 'moment';
import pageheaderStore from '../../component/PageHeader/PageHeaderStore';
// import './index.sass';

function Contact(props) {
  const { type } = props;
  const [showModal, setShowModal] = useState(false); // 是否显示弹窗
  const [showCopyModal, setShowCopyModal] = useState(false); // 是否显示弹窗
  const [loading, setLoading] = useState(true); // 是否加载状态
  const [data, setData] = useState([]); // 触点列表
  const [keyword, setKeyword] = useState(''); // 搜索词
  const [editInfo, setEditInfo] = useState({}); // 编辑信息

  const periods = {
    Day: '天',
    Week: '周',
    Month: '月',
    Year: '年',
  };

  useEffect(() => {
    pageheaderStore.hidePageHeader();
    fetchData();
  }, [keyword]);

  function modalToggle(bool) {
    setShowModal(bool);
    !bool && setEditInfo({});
  }
  function copyModalToggle(bool) {
    setShowCopyModal(bool);
    !bool && setEditInfo({});
  }

  function fetchData(orderDesc = true, orderColumn = 'updatedAt', currentPage = 1, pageCount = 10) {
    setLoading(true);
    util.get(
      api.subscription.list,
      {
        idsite: pageheaderStore.parameter.idsite,
        search: keyword,
        orderDesc,
        orderColumn,
        currentPage,
        pageCount,
      },
      (res) => {
        res && setData(res);
        setLoading(false);
      }
    );
  }
  function edit(editId) {
    setEditInfo({
      editId,
    });
    modalToggle(true);
  }
  function copy(editId, name) {
    setEditInfo({
      name,
      editId,
    });
    copyModalToggle(true);
  }
  function deleteData(id) {
    util.get(
      api.subscription.delete,
      {
        idsite: pageheaderStore.parameter.idsite,
        id,
      },
      (res) => {
        fetchData();
      }
    );
  }
  function onStatusChange(id, checked) {
    util.post(api.subscription.status, {
      idsite: pageheaderStore.parameter.idsite,
      id,
      subscribeStatus: checked ? 1 : 2,
    });
  }
  function addContent(store) {
    for (let i = 0; i < store.columns.length; i++) {
      const currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'operate') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: (
              <div className="operateGroup">
                <Popconfirm
                  title={intl.get('DeleteWaring')}
                  okButtonProps={{ type: 'primary' }}
                  cancelButtonProps={{ type: 'cancel' }}
                  onConfirm={() => {
                    deleteData(record.id);
                  }}
                  okButtonProps={{ type: 'primary' }}
                  cancelButtonProps={{ type: 'cancel' }}
                  okText={intl.get('Confirm')}
                  cancelText={intl.get('Cancel')}
                  placement="topLeft">
                  <span className="operateDom-delete" title={intl.get('Delete')}>
                    {intl.get('Delete')}
                    {/* 删除 */}
                  </span>
                </Popconfirm>
                <span
                  className="operateDom-update"
                  onClick={() => {
                    copy(record.id, record.name);
                  }}>
                  复制
                </span>
                <span
                  className="operateDom-update"
                  onClick={() => {
                    edit(record.id);
                  }}>
                  编辑
                </span>
              </div>
            ),
          };
        };
      } else if (currentRowData === 'status') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: (
              <div className="operateGroup">
                <span className="operateDom-update">
                  <Switch defaultChecked={record.subscribeStatus == '1'} size="small" onChange={(checked) => onStatusChange(record.id, checked)} />
                </span>
              </div>
            ),
          };
        };
      } else if (currentRowData === 'subscribeStatus') {
        store.columns[i].render = (text, record, index) => {
          return (
            <Switch checkedChildren="正常" unCheckedChildren="暂停" defaultChecked={text == '1'} onChange={(checked) => onStatusChange(record.id, checked)} />
          );
        };
      } else if (currentRowData === 'dataDateConfig') {
        store.columns[i].render = (text, record, index) => {
          let str = '';
          if (text.indexOf('last') < 0) {
            str = text.replace(',', '~');
          } else {
            const num = text.replace(/[^\d]/g, '');
            const unit = text.replace('last', '').replace(num, '');
            str = `过去${num}${unit ? periods[unit] : '天'}`;
          }
          return str;
        };
      }
    }
  }

  return (
    <Fragment>
      <SearchHeader defaultValue={keyword} title="+ 新增订阅" onSearch={setKeyword} onClick={() => modalToggle(true)} />
      {showModal && <Modal show={showModal} onHide={() => modalToggle(false)} fetchData={fetchData} editInfo={editInfo} />}
      {showCopyModal && <CopyModal show={showCopyModal} onHide={() => copyModalToggle(false)} fetchData={fetchData} editInfo={editInfo} />}
      <CommonTable
        fetchData={fetchData}
        parentData={data}
        addContent={addContent}
        tableHeadData={[
          ['订阅名称', '订阅看板', '数据周期', '创建人', '最近修改时间', '创建时间', '操作', '状态'],
          [
            'name',
            'dashboardName',
            'dataDateConfig',
            'createdByName',
            'updatedAt',
            'createdAt',
            'operate',
            'status',
            'subTouchpoints',
            'id',
            'parentId',
            'isDraft',
            'parentPath',
            'dashboardUrl',
            'description',
            'subscribeStatus',
          ],
        ]}
        isLoading={loading}
        isSort={false}
      />
    </Fragment>
  );
}

export default Contact;
