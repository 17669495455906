import React, { Component } from 'react';
import CommonSankey from '../../../CommonSankey/index';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import util from '../../../../util';
import Loading from '../../../../component/Loading/Loading';
import { observer } from 'mobx-react';
import { observable, autorun, action, toJS } from 'mobx';
import api from '../../../../api';
import moment from 'moment';
import UXSRangePicker from '../../../../component/UXSRangePicker';
import mathUtil from '../../../../utils/mathUtil';

@observer
export default class AnalysePathSankey extends Component {
  constructor(props) {
    super(props);
    this.pathId = this.props.pathId;
    this.lastCurrentCommonFilterObject = [];
  }
  @observable isLoading = true;
  @observable pageCount = 4;
  @observable data = { data: [], nodes: [] };
  componentDidMount() {
    this.disposer = autorun(() => {
      let count = this.props.count;
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      let dateObj = this.props.dateObj;
      let commonFilterObject = pageHeaderStore.commonFilterObject;
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.pathId));
      // if (count && (!this.lastLayoutChangeCount || this.lastLayoutChangeCount === this.props.layoutChangeCount)) {
      if (
        (count && (!this.lastLayoutChangeCount || this.lastLayoutChangeCount === this.props.layoutChangeCount)) ||
        (JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject) && this.props.isVisible)
      ) {
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
          this.isLoading = true;
          if (this.props.dataFromParentData) {
            this.data = toJS(this.props.parentData);
            this.isLoading = false;
          } else {
            if (this.props.pathId !== 'null') {
              let date = pageHeaderStore.parameter.date;
              this.fetchData();
            } else {
              this.data = { data: [], nodes: [] };
              this.isLoading = false;
            }
          }
        }, 30);
        this.lastCurrentCommonFilterObject = currentCommonFilterObject;
      }
      this.lastLayoutChangeCount !== this.props.layoutChangeCount && (this.lastLayoutChangeCount = this.props.layoutChangeCount);
    });
  }

  componentDidUpdate(preProps) {
    if (this.props.isVisible !== preProps.isVisible && this.props.isVisible) {
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.pathId));
      if (JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject)) {
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
          this.isLoading = true;
          if (this.props.dataFromParentData) {
            this.data = toJS(this.props.parentData);
            this.isLoading = false;
          } else {
            if (this.props.pathId !== 'null') {
              let date = pageHeaderStore.parameter.date;
              this.fetchData();
            } else {
              this.data = { data: [], nodes: [] };
              this.isLoading = false;
            }
          }
        }, 30);
        this.lastCurrentCommonFilterObject = currentCommonFilterObject;
      }
    }
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  @action.bound fetchData = (orderDesc, orderColumn, currentPage = 1, pageCount = 3) => {
    this.pageCount = pageCount;

    let param = {
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : this.props.dateObj
          ? this.props.dateObj.lastDate
            ? mathUtil.keyToDate(this.props.dateObj.lastDate)
            : this.props.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      idsite: pageHeaderStore.parameter.idsite,
      orderDesc: true,
      currentPage: currentPage,
      pageCount: pageCount,
      pathId: this.props.pathId,
      actionType: this.props.actionType,
      workViewFilters: this.props.pathId ? pageHeaderStore.getCommonFilter(this.props.pathId) : '',
    };
    if (Number(pageHeaderStore.parameter.userGroupId) > 0) {
      param['userGroupId'] = pageHeaderStore.parameter.userGroupId;
    }
    util.get(api.pack.analyticsPath, { ...param }, (res) => {
      res && ((this.data = res), (this.isLoading = false));
    });
  };
  render() {
    let date = `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`;
    let dateStr =
      pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
        ? pageHeaderStore.parameter.date.replace(/-/g, '/').replace(',', ' ~ ')
        : !this.props.setDateObj &&
          (this.props.dateObj
            ? this.props.dateObj.lastDate
              ? mathUtil
                  .keyToDate(this.props.dateObj.lastDate)
                  .replace(/-/g, '/')
                  .replace(',', ' ~ ')
              : this.props.dateObj.date.replace(/-/g, '/').replace(',', ' ~ ')
            : date.replace(/-/g, '/').replace(',', ' ~ '));

    return (
      <React.Fragment>
        {/* 编辑的时候才显示控件，看板时为文字 */}
        {this.props.setDateObj ? (
          this.props.isLoading ? (
            <Loading size="small" />
          ) : (
            <UXSRangePicker
              useDateByStore={false}
              getDateAndlastDate={this.props.setDateObj}
              date={this.props.dateObj ? this.props.dateObj.date : date}
              lastDate={this.props.dateObj ? this.props.dateObj.lastDate : ''}
              isBoardPicker={true}
            />
          )
        ) : (
          <p className="board-chart-date">{dateStr}</p>
        )}
        {!this.isLoading ? (
          <CommonSankey
            // fullHeight={true}
            index={this.props.index}
            isNotShowOption={this.props.isNotShowOption}
            fetchData={this.fetchData}
            pageCount={this.pageCount}
            hasChildrenStep={this.props.hasChildrenStep}
            nodeFields={this.props.nodeFields}
            parentData={this.data}
            pathId={this.props.pathId}></CommonSankey>
        ) : (
          <Loading></Loading>
        )}
      </React.Fragment>
    );
  }
}
