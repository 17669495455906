import React, { Component } from 'react';
import GlobalConfig from './index';
import GlobalControl from '../../NewGlobalControl/Global';
import PageHeaderStore from '../../PageHeaderStore';
import util from '../../../../util';
import api from '../../../../api';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';
import { toJS, autorun, observable } from 'mobx';
import { observer } from 'mobx-react';
import BIStore from '../../../../containers/BIChart/store';
import { message } from 'antd';
import { normalViews, nameToView } from '../../GlobalControl/config';
import { formatOptions, getFormatValue } from './util';

const action = [1, 2, 3]; // 动作表
const answer = [8]; // 答案表
const view = [10]; // 新看板

@observer
class GlobalConfigWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      views: [],
      widgets: [],
    };
    this.lastFilters = null;
    this.lastDashboard = null;
    this.wrapper = autorun(() => {
      if (JSON.stringify(PageHeaderStore.currentDashboard !== '{}')) {
        this.currentDashboard = PageHeaderStore.currentDashboard;
        // 切换看板的时候重置控制器条件
        if (JSON.stringify(this.lastDashboard) !== JSON.stringify(this.currentDashboard)) {
          this.lastFilters = null;
        }
        this.lastDashboard = PageHeaderStore.currentDashboard;
        this.controllerList = PageHeaderStore.controllerList;
        this.cardList = BoardStore.cardList;
        PageHeaderStore.setPagePadding();
      }
    });
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      this.setState({
        views: BIStore.viewsData,
      });
    });
  }
  componentWillUnmount() {
    this.wrapper();
    this.disposer();
  }
  @observable currentDashboard = {};
  @observable cardList = [];
  @observable controllerList = [];
  loadViews() {}
  cardListFormat(cardList) {
    // 适配新版控制器数据结构
    const formatCardList = cardList.reduce((final, card) => {
      if (action.includes(card.type)) {
        card.dashboardId = 'action';
        card.id = card.cardId;
        card.name = card.title;
        final.push(card);
      } else if (answer.includes(card.type)) {
        card.dashboardId = 'answer';
        card.id = card.cardId;
        card.name = card.title;
        final.push(card);
      } else if (view.includes(card.type)) {
        card.dashboardId = card.viewId;
        final.push(card);
      }
      return final;
    }, []);
    return formatCardList;
  }
  saveControls = (controls, queryMode) => {
    util.post(
      api.globalControl.save,
      {
        id: PageHeaderStore.currentBoardId,
        config: JSON.stringify(controls),
        queryMode,
      },
      (res) => {
        if (res.code === 200) {
          message.success('保存成功');
          PageHeaderStore.showNewGlobalControlModal(false);
          PageHeaderStore.setControllerList();
        }
      }
    );
  };

  onLoadViewDetail(view) {
    const viewId = view[0];
    BIStore.getViewDetail(viewId);
  }
  // 获取选择框里面的数据
  onGetOptions = (e, callback) => {
    let workViewFilters = [];
    this.result = [];
    this.requestTime = 0;
    let keyNumbers = Object.keys(e).length;
    let filter = [];
    for (let key in e) {
      if (e[key].type && e[key].type === 'manual') {
        const haveText = e[key].columns.length === 2;
        // 取值， 文本字段， 1 的时候只有取值
        const workViewFiltersValue = [
          {
            viewId: String(e[key].valueViewId),
            viewName: e[key].valueViewName,
            name: e[key].valueField,
            sqlType: e[key].valueFieldSqlType,
          },
        ];
        this.getOptions(workViewFiltersValue, e[key].filters, callback, 'manual', haveText ? true : false);
        if (haveText) {
          const workViewFiltersText = [
            {
              viewId: String(e[key].valueViewId),
              viewName: e[key].valueViewName,
              name: e[key].textField,
              sqlType: e[key].textFieldSqlType,
            },
          ];
          this.getOptions(workViewFiltersText, e[key].filters, callback, 'manual', true);
        }
      } else {
        normalViews.includes(key)
          ? workViewFilters.push({ viewName: nameToView[key], ...e[key].workFilters[0] })
          : workViewFilters.push({ ...e[key].workFilters[0] });
        // 处理filter，合并成一个filter
        if (e[key].filters && e[key].filters.length) {
          filter.push({ ...e[key].filters[0] });
          // filter = e[key].filters;
        }
        if (keyNumbers === 1) {
          // let viewIds = workViewFilters
          //   .reduce((v, key) => {
          //     v.push(key.viewId);
          //     return v;
          //   }, [])
          // .join(',');
          // let filter = [];
          // if (e[key].filters && e[key].filters.length) {
          //   filter.push({ ...e[key].filters[0], viewId: viewIds });
          // }
          // if (filter.length) {
          //   filter[0].viewId = viewIds;
          // }
          keyNumbers = 0;
          this.getOptions(workViewFilters, filter, callback);
        } else {
          keyNumbers = keyNumbers - 1;
        }
      }
    }
  };

  // 具体请求
  getOptions = (workViewFilters, filters, callback, type, haveText) => {
    util.post(
      api.pack.dimOptions,
      {
        idsite: PageHeaderStore.parameter.idsite,
        dim: 'work_view_filter',
        workViewFilters: JSON.stringify(workViewFilters),
        filters: JSON.stringify(filters),
        dashboardId: PageHeaderStore.currentBoardId,
      },
      (res) => {
        if (type && type === 'manual') {
          this.requestTime = this.requestTime + 1;
        }
        if (JSON.stringify(res.data) === '{}') {
          if (type && type === 'manual' && haveText) {
            if (this.requestTime === 2) {
              callback(this.result);
              this.reset();
            }
          } else {
            callback([]);
          }
        } else {
          let withKey = null;
          if (workViewFilters.some((i) => ['visit_action_union_optimize', 'answer_visit_user_view'].includes(i.viewName))) {
            withKey = workViewFilters[0].name;
          }
          const formatData = formatOptions(Object.values(res.data)[0], withKey);
          if (type && type === 'manual' && haveText) {
            if (this.requestTime !== 2) {
              this.result = formatData;
            } else {
              const result = this.result.map((item, index) => {
                return { ...item, ...formatData[index] };
              });
              callback(result);
              this.reset();
            }
          } else {
            callback(formatData);
          }
        }
      }
    );
  };

  reset = () => {
    this.result = [];
    this.requestTime = 0;
  };

  // 点击查询
  onSearch = (type, itemId, formedValue, isReset) => {
    const filters = this.getControlValue(formedValue);
    // 没有控制器不触发
    const filterList = this.props.type === 'insideCard' ? this.props.parentControls : toJS(this.controllerList);
    if (!filterList.length) {
      return;
    }
    if (filters && filters.length && !isReset) {
      if (filters.every((f) => !f.relatedCards.length)) {
        message.warning('没有生效的查询条件!');
        return;
      }
    }
    if (JSON.stringify(this.lastFilters) === JSON.stringify(filters)) {
      // message.warning('当前查询条件未改变!');
      // return;
    }
    if (JSON.stringify(this.lastFilters) !== JSON.stringify(filters)) {
      if (this.props.type === 'insideCard') {
        this.props.insideControlFilter(filters);
      } else {
        PageHeaderStore.setCommonFilter([...filters]);
        PageHeaderStore.setCommonFilterValue(filters);
      }
      this.lastFilters = JSON.parse(JSON.stringify(filters));
    }
  };

  // 拿到所有控制器的值
  getControlValue = (formedValue) => {
    const values = [];
    Object.keys(formedValue).map((key) => {
      const controllerList = this.props.type === 'insideCard' ? this.props.parentControls : this.controllerList;
      const controlIndex = controllerList.findIndex((control) => control.key === key);
      const control = controllerList[controlIndex];
      // 下拉框， 单选按钮支持空值查找
      if (formedValue[key] || (control && ['select', 'radio'].includes(control.type) && formedValue[key] === '')) {
        // 选择框，取消时还是发起了请求需要去掉
        if (!this.getNeedRequest(control, formedValue[key])) {
          return;
        }
        const formatValue = getFormatValue(control, formedValue[key]);
        values.push(formatValue);
      }
    });
    return values;
  };

  getNeedRequest(control, controlValue) {
    // 值为空不请求
    if (Array.isArray(toJS(controlValue))) {
      return !controlValue.every((value) => value === '');
    }
    // 多选下拉框，没有选择
    if (control.type === 'select' && ['in', 'not in'].includes(control.operator) && !controlValue.length) {
      return false;
    }
    return true;
  }

  render() {
    const { views, widgets, show } = this.state;
    const { type } = this.props;

    return type === 'GlobalControl' ? (
      <GlobalConfig
        type={'global'} // 全局还是单个看板
        originalControls={this.controllerList} // 配置的filters
        currentItems={this.cardListFormat(this.cardList)} // 当前看板里面所有卡片信息
        views={views} //所有表
        formedViews={BIStore.formedViews} // views列表 + 表具体信息
        widgets={widgets} // widgets列表
        loading={false}
        queryMode={JSON.stringify(this.currentDashboard) !== '{}' ? this.currentDashboard.config.queryMode : 1}
        visible={PageHeaderStore.isShowNewGlobalControlModal}
        onCancel={() => PageHeaderStore.showNewGlobalControlModal(false)}
        onSave={this.saveControls}
        onLoadViews={this.loadViews} // 获取所有views
        onLoadViewDetail={this.onLoadViewDetail} //获取views的字段信息
        onGetOptions={this.onGetOptions} // getdistinct
      />
    ) : type === 'insideCard' ? (
      <GlobalControl
        currentItems={this.cardListFormat(this.cardList)}
        formedViews={BIStore.formedViews}
        layoutType="dashboard"
        currentDashboard={this.currentDashboard}
        onGetOptions={this.onGetOptions}
        onSearch={this.onSearch}
        onMonitoredSearchDataAction={() => {}}
        controllerList={this.props.parentControls}
        {...this.props}
      />
    ) : (
      <GlobalControl
        currentItems={this.cardListFormat(this.cardList)}
        formedViews={BIStore.formedViews}
        layoutType="dashboard"
        currentDashboard={this.currentDashboard}
        onGetOptions={this.onGetOptions}
        onSearch={this.onSearch}
        onMonitoredSearchDataAction={() => {}}
        controllerList={this.controllerList}
      />
    );
  }
}

export default GlobalConfigWrapper;
