import React, { useState } from 'react';
import { Popconfirm } from 'antd';
import api from '../../api';
import util from '../../util';
import Loading from '../../component/Loading/Loading';
import './index.sass';

// sourceType
// Dashboard("报告版", 1),
// TouchPoint("触点总览", 2),
// Feedback("调研", 3),
// Survey("问卷", 4),
// Forewarning("预警规则", 5),
// MsgTemplate("消息模板", 6),
// PushChannel("应用中心", 7),
// Role("角色", 8),
// Account("账号", 9),
// AccountGroup("账号组", 10)

function DeletePopconfirm(props) {
  // 常量
  const defaultTtitle = '你确定要删除这行内容吗？';
  const undeletableTitle = '无法删除';

  const { children, sourceId, onConfirm, sourceType } = props;

  const [deleteInfo, setDeleteInfo] = useState({});

  function onVisibleChange(visible, sourceId) {
    if (!visible) {
      setTimeout(() => {
        setDeleteInfo({});
      }, 0);
    } else {
      // 三种情况：
      // 一、无绑定，有默认title，若父组件有传info就有info，反之没有，可删除（title: '你确定要删除这行内容吗？'）
      // 二、无绑定有分享，有默认title，有默认info，可删除（title: '你确定要删除这行内容吗？', info: '删除后，系统自动删除分享设置，且无法恢复该内容'）
      // 三、有绑定，有默认title，info由后端返回（title: '无法删除'）
      util.post(api.delete.checkRelation, { sourceId, sourceType }, (res) => {
        if (!res.data) {
          setDeleteInfo({
            title: defaultTtitle,
            info: res.msg,
          });
          showDefaultBtn();
        } else {
          showBanBtn();
          const msgList = res.msg.split('\n');
          setDeleteInfo({
            title: undeletableTitle,
            info: msgList.map((item) => {
              return <p>{item}</p>;
            }),
            type: 'disable',
          });
        }
      });
    }
  }
  function showBanBtn() {
    var btns = document.querySelectorAll(`#delete_${sourceType}${sourceId}_confirm .ant-btn`);
    btns[1].style.visibility = 'visible';
  }
  function showDefaultBtn() {
    var btns = document.querySelectorAll(`#delete_${sourceType}${sourceId}_confirm .ant-btn`);
    btns[0].style.visibility = 'visible';
    btns[1].style.visibility = 'visible';
  }
  function confirm() {
    deleteInfo.type === 'disable' ? null : onConfirm();
  }

  return (
    <Popconfirm
      {...props}
      placement="topRight"
      arrowPointAtCenter={true}
      onConfirm={confirm}
      onVisibleChange={(visible) => onVisibleChange(visible, sourceId)}
      overlayClassName="delete-warning-confirm"
      id={`delete_${sourceType}${sourceId}_confirm`}
      title={
        deleteInfo.title ? (
          <div>
            <h5 className="delete-confirm-title">{deleteInfo.title}</h5>
            {deleteInfo.info && <div>{deleteInfo.info}</div>}
          </div>
        ) : (
          <Loading size="small" type="center" />
        )
      }
      okType="primary"
      okText={deleteInfo.type === 'disable' ? '知道了' : '确认'}
      okButtonProps={{ type: 'primary' }}
      cancelButtonProps={{ type: 'cancel' }}
      cancelText="取消">
      {children}
    </Popconfirm>
  );
}

export default DeletePopconfirm;
