import './index.sass';
import { Modal } from 'antd';
const { confirm } = Modal;

function confirmModal(props) {
  confirm({
    title: props.title || '你确定要删除这行内容吗？',
    centered: true,
    okText: '确认',
    cancelText: '取消',
    onOk() {
      props.yes && props.yes();
    },
    onCancel() {
      props.cancel && props.cancel();
    },
  });
}

export default confirmModal;
