import React, { Fragment, createRef } from 'react';
import { getRequestParams, getInitialPagination, getRequestBody, getUpdatedPagination, resetWidgetInfo, getRandomName, getInitialItemInfo } from './util';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { Dropdown } from 'antd';
import api from '../../api';
import util from '../../util';
import { SQL_DATE_TYPES, SQL_NUMBER_TYPES } from '../../config';
import BIStore from './store';
import Widget from './Widget';
import Loading from '../../component/Loading/Loading';
import TableNullData from '../../component/TableNullData';
import './index.sass';
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import BoardStore from '../../views/BoardCustom/BoardChart/BoardStore';
import mathUtil from '../../utils/mathUtil';
import EnhancerPanel from './components/EnhancerPanel';
import { ChartTypes } from './config/chart/ChartTypes';
import { DEFAULT_SPLITER } from './constants';
import { DrillableChart } from './config/chart/DrillableChart';
import GlobalConfigWrapper from '../../component/PageHeader/Control/Config/Wrapper';
import GlobalControl from '../../component/PageHeader/GlobalControl';

const tableTypes = [ChartTypes.Table, ChartTypes.TextTable, ChartTypes.ExtendTable, ChartTypes.ContentTable];

@observer
class BIChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      isLoading: true,
      whichDataDrillBrushed: false,
      NLPData: {},
      selectedItems: [],
      seriesItems: [],
      dataDrillPanelPosition: false,
      whichDataDrillBrushed: false,
      sourceDataOfBrushed: [],
      sourceDataGroup: [],
      drillFields: [],
      showScrollBar: true,
      data: { resultList: [], compareResultList: {} },
      currentQueryConditions: {},
    };
    this.lastProps = {};
    this.NLPRequestParams = {};
    const widgetInfo = resetWidgetInfo(this.props.widgetInfo);
    this.widgetInfo = widgetInfo;
    this.initialCols = widgetInfo.config.cols;
    this.lastCols = [];
    this.drillFiltersHistory = {};
    this.lastCompareFilterConfig = {};
    // this.lastCompareFilterConfig = widgetInfo.config.compareFilterConfig || {};
    // widgetInfo.config.compareFilterConfig && BoardStore.setCompareFilterConfig(widgetInfo.widgetId, widgetInfo.config.compareFilterConfig);
    this.requestParams = {};
    this.widgetContent = createRef();
    this.lastPropsExtraFilters = [];
  }
  componentDidMount() {
    const { widgetInfo } = this;
    this.setState({
      model: BIStore.formedViews[widgetInfo.viewId],
      extraFilters: {},
    });
    const currentItemsInfo = BIStore.currentItemsInfo;
    if (BIStore.currentItemsInfo === false) {
      currentItemsInfo[widgetInfo.id].loading = true;
      currentItemsInfo[widgetInfo.id].errorMessage = '';
    }
    currentItemsInfo[widgetInfo.id].rendered = true;
    BIStore.setItemsInfo(currentItemsInfo);
    this.props.boardChart && window.Cemyun && window.Cemyun.trackEvent('卡片浏览', { cardName: widgetInfo.name });
    // 全局控制器筛选或图表联动时刷新
    this.disposer = autorun(() => {
      let commonFilterObject = PageHeaderStore.commonFilterObject;
      let currentLinkager = PageHeaderStore.currentLinkager;
      const props = {
        commonFilterObject: PageHeaderStore.getCommonFilter(this.props.id),
      };
      if (JSON.stringify(this.lastProps) !== JSON.stringify(props) && (this.props.isVisible || !this.props.boardChart)) {
        this.getData('clear');
        this.lastProps = props;
      }
    });
    // 选择对比时刷新
    this.compareFilterReload = autorun(() => {
      const compareFilterConfig = BoardStore.compareFilterConfig[widgetInfo.id];
      if (compareFilterConfig && JSON.stringify(compareFilterConfig) !== JSON.stringify(this.lastCompareFilterConfig)) {
        // 对比配置修改为不保存
        // this.widgetInfo.config.compareFilterConfig = toJS(compareFilterConfig);
        this.getData('clear', widgetInfo.id);
        this.lastCompareFilterConfig = compareFilterConfig;
        // 将对比条件保存
        // this.saveWidgetConfig(this.widgetInfo);
      }
    });
    // 取消联动时执行
    this.cancelLinking = autorun(() => {
      const isLinking = BIStore.isLinkingWidget[this.widgetInfo.id];
      if (!isLinking) {
        this.setState({
          selectedItems: [],
          seriesItems: [],
        });
      }
    });
  }
  componentDidUpdate(preProps) {
    const { widgetInfo } = this.props;
    if (this.props.cardRefreshCount !== preProps.cardRefreshCount) {
      BIStore.setItemsInfo(getInitialItemInfo(widgetInfo, BIStore.formedViews), this.widgetInfo.id);
      this.widgetInfo = resetWidgetInfo(widgetInfo);
      this.setState({
        model: BIStore.formedViews[widgetInfo.viewId],
        extraFilters: {},
      });
      this.getData('clear', this.widgetInfo.id);
    }
    if (this.props.extraFilters && JSON.stringify(this.props.extraFilters) !== JSON.stringify(this.lastPropsExtraFilters)) {
      this.getData('nlpFilter', this.widgetInfo.id);
      this.lastPropsExtraFilters = JSON.parse(JSON.stringify(this.props.extraFilters));
    }
    if (this.props.isVisible !== preProps.isVisible && this.props.isVisible) {
      const props = {
        commonFilterObject: PageHeaderStore.getCommonFilter(this.props.id),
      };
      if (JSON.stringify(this.lastProps) !== JSON.stringify(props)) {
        this.getData('clear', widgetInfo.id);
        this.lastProps = props;
      }
    }
  }
  componentWillUnmount() {
    this.disposer();
    this.compareFilterReload();
    this.cancelLinking();
  }
  //获取数据
  getData = (renderType, itemId, queryConditions, cancelTokenSource, resetPagination, insideCardFilters) => {
    this.setState({
      isLoading: true,
    });
    const { widgetInfo } = this;
    const { extraFilters } = this.props;
    const itemInfo = BIStore.currentItemsInfo[widgetInfo.id];
    const { cols, selectedChart, mode, rows, dynamicFilters } = widgetInfo.config;
    const isExtendTable = selectedChart === 21 ? true : false;
    const needChildren = cols.length > 1;
    let commonFilters = PageHeaderStore.getCommonFilter(this.props.id);
    if (insideCardFilters) {
      commonFilters = JSON.stringify(JSON.parse(commonFilters).concat(insideCardFilters));
    }
    if (this.state.drillFields.length) {
      const { drillFilterFields, drillFilters } = this.getDrillFilter();
      commonFilters = JSON.parse(commonFilters).filter((item) => !drillFilterFields.includes(item.name));
      commonFilters = JSON.stringify(commonFilters.concat(drillFilters));
    }
    this.props.widgetType !== 2 && delete widgetInfo.config.noNA;
    const requestParams = getRequestParams(widgetInfo, itemInfo.queryConditions, renderType === 'flush', queryConditions, commonFilters);
    if (resetPagination) {
      const initialPagination = getInitialPagination(widgetInfo);
      if (initialPagination) {
        const { pageNo, pageSize } = initialPagination;
        requestParams.pagination = {
          ...requestParams.pagination,
          pageNo,
          pageSize,
        };
      }
    } else if (!queryConditions && widgetInfo.config.chartStyles.table) {
      const { pageSize, withPaging } = widgetInfo.config.chartStyles.table;
      if (withPaging) {
        requestParams.pagination = {
          ...requestParams.pagination,
          pageNo: rows.length ? 0 : 1,
          pageSize: rows.length ? 0 : +pageSize,
        };
      }
    }
    // 表格内部筛选以外的条件
    const originalFilters = requestParams.filters;
    if (extraFilters) {
      extraFilters.map((i) => (i.name = i.fieldName || i.name || ''));
      requestParams.filters = [...requestParams.filters, ...extraFilters];
    }
    this.requestParams = requestParams;
    let param = getRequestBody({
      widgetId: widgetInfo.id,
      dashboardId: PageHeaderStore.currentBoardId,
      ...requestParams,
      title: widgetInfo.name,
    });
    param = !this.props.simpleMode ? param : { pageNo: param.pageNo, pageSize: param.pageSize, filters: param.filters, ...this.props.parentParam };
    this.props.parentParam && (param = { ...param, ...this.props.parentParam });
    try {
      util.post(
        this.props.getDataApi || `${api.BI.views}/${widgetInfo.viewId}/getdata`,
        param,
        (res) => {
          // this.props.setOverviewData &&
          //   this.props.setOverviewData({
          //     totalCount: res.data.totalCount,
          //     filteredCount: res.data.filteredCount,
          //   });
          res.data = res.data || {};
          res.data.resultList = res.data.resultList || [];
          res.data.compareResultList = res.data.compareResultList || [];
          if (isExtendTable) {
            res.data.resultList.map((item) => {
              if (needChildren) {
                item.children = [];
              }
              item.id = `${new Date()} + ${Math.random()}`;
              item.searchIndex = 1;
            });
          }
          if (widgetInfo.config.chartStyles.table && widgetInfo.config.chartStyles.table.withPaging) {
            requestParams.pagination = getUpdatedPagination(requestParams.pagination, res.data);
          }
          let currentItemInfo = BIStore.currentItemsInfo[widgetInfo.id] || {};
          const { tempFilters, linkageFilters, globalFilters, variables, linkageVariables, globalVariables, pagination, nativeQuery } = requestParams;
          currentItemInfo = {
            ...currentItemInfo,
            loading: false,
            datasource: res.data,
            renderType: renderType,
            queryConditions: {
              ...currentItemInfo.queryConditions,
              tempFilters,
              linkageFilters,
              globalFilters,
              variables,
              linkageVariables,
              globalVariables,
              pagination,
              nativeQuery,
            },
            selectedItems: [],
          };
          BIStore.setItemsInfo(currentItemInfo, widgetInfo.id);
          this.setState({
            isLoading: false,
            selectedItems: [],
            data: res.data,
          });
          if (renderType === 'canshow') {
            this.setState({
              showScrollBar: true,
            });
          }
        },
        true
      );
      // 判断为NLP表格或内容表格时特殊处理请求数据
      if (
        (mode === 'chart' && selectedChart === ChartTypes.TextTable && renderType !== 'nlpFilter' && !queryConditions) ||
        (dynamicFilters && dynamicFilters.length)
      ) {
        const firstNLPField = dynamicFilters ? dynamicFilters[0] : cols[0];
        const secondNLPField = dynamicFilters ? dynamicFilters[1] : cols[1];
        const firstNLPRequestParams = {
          ...requestParams,
          filters: originalFilters,
          groups: [firstNLPField]
            .filter((g) => g.name !== '指标名称')
            .map((g) => {
              return g.randomName ? `${g.formula || g.name} AS ${g.randomName}` : g.name;
            }),
          aggregators: [
            {
              column: 'uniq(answer_id)',
              formula: 'uniq(answer_id)',
              func: 'original',
            },
          ],
          limit: null,
          pagination: {
            pageNo: 1,
            pageSize: 10,
          },
        };
        const secondNLPRequestParams = {
          ...requestParams,
          filters: originalFilters,
          groups: [secondNLPField]
            .filter((g) => g.name !== '指标名称')
            .map((g) => {
              return g.randomName ? `${g.formula || g.name} AS ${g.randomName}` : g.name;
            }),
          aggregators: [
            {
              column: 'uniq(answer_id)',
              formula: 'uniq(answer_id)',
              func: 'original',
            },
          ],
          limit: null,
          pagination: {
            pageNo: 1,
            pageSize: 10,
          },
        };
        delete firstNLPRequestParams.formulas;
        delete secondNLPRequestParams.formulas;
        const firstName = firstNLPField.randomName || firstNLPField.name;
        const secondName = secondNLPField.randomName || secondNLPField.name;
        this.NLPRequestParams = {
          [firstName]: firstNLPRequestParams,
          [secondName]: secondNLPRequestParams,
        };
        this.getNLPData(firstNLPRequestParams, firstName);
        secondNLPField && this.getNLPData(secondNLPRequestParams, secondName);
      }
    } catch (err) {}
  };

  //分页改变
  paginationChange = (pageNo, pageSize, orders) => {
    const { widgetInfo } = this;
    const currentItemsInfo = BIStore.currentItemsInfo;
    const { drillHistory } = currentItemsInfo[widgetInfo.id].queryConditions;
    const paginationConfig = {
      ...currentItemsInfo[widgetInfo.id].queryConditions.pagination,
    };
    const pagination = this.widgetInfo.config.pagination.withPaging
      ? {
          ...currentItemsInfo[widgetInfo.id].queryConditions.pagination,
          pageNo,
          pageSize: pageSize || paginationConfig.withPaging.pageSize,
        }
      : { ...currentItemsInfo[widgetInfo.id].queryConditions.pagination, pageNo: 0, pageSize: 0 };
    let currentQueryConditions = {};
    if (drillHistory && drillHistory.length) {
      const drillStatus = drillHistory[drillHistory.length - 1];
      currentQueryConditions = { pagination, orders, drillStatus };
    } else {
      currentQueryConditions = { pagination, orders };
    }
    this.setState({
      currentQueryConditions,
    });
    this.getData('canshow', widgetInfo.id, currentQueryConditions);
  };

  refreshData = () => {
    this.getData('canshow', null, this.state.currentQueryConditions);
  };

  getCompareFilterText(compareFilterConfig) {
    if (!compareFilterConfig) return null;
    const { compare, current, filed } = compareFilterConfig;
    if (SQL_DATE_TYPES.includes(filed.sqlType)) {
      const currentValue = (current.lastDate ? mathUtil.keyToDate(current.lastDate) : current.date).replace(',', '~');
      const compareValue = (compare.lastDate ? mathUtil.keyToDate(compare.lastDate) : compare.date).replace(',', '~');
      return { currentValue, compareValue };
    } else {
      return { currentValue: current, compareValue: compare };
    }
  }

  saveWidgetConfig(widgetInfo) {
    util.put(
      `${api.BI.widgets}/${widgetInfo.id}`,
      {
        description: widgetInfo.description,
        config: JSON.stringify(widgetInfo.config),
        id: widgetInfo.id,
        name: widgetInfo.name,
        projectId: widgetInfo.projectId,
        publish: widgetInfo.publish,
        type: 1,
        viewId: widgetInfo.viewId,
      },
      () => {},
      true
    );
  }
  deleteCompare = () => {
    delete this.widgetInfo.config.compareFilterConfig;
    this.saveWidgetConfig(this.widgetInfo);
    BoardStore.setCompareFilterConfig(this.widgetInfo.id, null);
    this.getData('clear', this.widgetInfo.id);
  };
  // 获取情感分析表格筛选数据
  getNLPData = (requestParams, name, pageSize) => {
    requestParams = requestParams || this.NLPRequestParams[name];
    pageSize && (requestParams.pagination.pageSize = pageSize);
    util.post(
      `${api.BI.views}/${this.widgetInfo.viewId}/getdata`,
      {
        ...getRequestBody(requestParams),
        orders: [
          {
            column: 'uniq(answer_id)',
            direction: 'desc',
          },
        ],
      },
      (res) => {
        res.data &&
          this.setState({
            NLPData: {
              ...this.state.NLPData,
              [name]: { data: res.data.resultList, totalCount: res.data.totalCount },
            },
          });
      },
      true
    );
  };

  // 选中某项
  onSelectChartsItems = (selectedItems) => {
    this.setState({
      selectedItems,
    });
  };
  onSelectSeriesItems = (seriesItems) => {
    this.setState({
      seriesItems,
    });
  };
  isHasDrillHistory = () => {
    const { drillHistory } = BIStore.currentItemsInfo[this.widgetInfo.id].queryConditions;
    return !!(drillHistory && drillHistory.length !== 0);
  };

  getLastDrillHistory() {
    const { drillHistory } = BIStore.currentItemsInfo[this.widgetInfo.id].queryConditions;
    return [...drillHistory].pop();
  }

  // 下钻
  drillDown = (name, dimensions) => {
    const { selectedChart } = this.widgetInfo.config;
    const col = this.getColDefaultConfig(name);
    const drillFields = [...this.state.drillFields, col];
    this.setState(
      {
        drillFields,
      },
      () => {
        this.lastCols = JSON.parse(JSON.stringify(this.widgetInfo.config.cols));
        tableTypes.includes(selectedChart)
          ? (this.widgetInfo.config.cols = [...this.initialCols, ...drillFields])
          : (this.widgetInfo.config.cols = [drillFields[drillFields.length - 1]]);
        this.getData('drill', this.widgetInfo.id);
      }
    );
  };
  // 下钻撤销
  cleanDrillDown = (index) => {
    const { selectedChart } = this.widgetInfo.config;
    let drillFields = this.state.drillFields;
    if (typeof index !== 'undefined') {
      drillFields.splice(index + 1, drillFields.length - index);
    } else {
      drillFields = [];
    }
    tableTypes.includes(selectedChart)
      ? (this.widgetInfo.config.cols = [...this.initialCols, ...drillFields])
      : (this.widgetInfo.config.cols = drillFields.length ? [drillFields[drillFields.length - 1]] : [...this.initialCols]);

    this.setState(
      {
        drillFields,
      },
      () => {
        this.getData('drill', this.widgetInfo.id);
      }
    );
  };

  getDataDrillDetail = (position) => {
    if (position && position.length) {
      try {
        const ps = JSON.parse(position);
        const { range, brushed, sourceData, sourceGroup } = ps;
        const dataDrillPanelPosition = void 0;
        const sourceDataOfBrushed = sourceData && sourceData.length ? sourceData : [];
        const whichDataDrillBrushed = brushed && brushed.length ? brushed : [];
        const sourceDataGroup = sourceGroup && sourceGroup.length ? sourceGroup : [];

        this.setState({
          dataDrillPanelPosition,
          whichDataDrillBrushed,
          sourceDataOfBrushed,
          sourceDataGroup,
        });
      } catch (error) {
        throw error;
      }
    }
  };
  // 下钻生成维度配置
  getColDefaultConfig = (name) => {
    let col = BIStore.formedViews[this.widgetInfo.viewId].model[name];
    col.type === 2 && (col.randomName = getRandomName());
    col.sqlType === 'DATETIME' && ((col.randomName = getRandomName()), (col.formula = JSON.stringify({ key: col.fieldName, period: 'day' })));
    col = {
      ...col,
      config: true,
      customType: col.type,
      field: { alias: '', desc: '', useExpression: false },
      format: { formatType: 'default' },
      name: col.fieldName,
      type: col.modelType,
    };
    return col;
  };
  // 下钻生成筛选配置
  getDrillFilter = () => {
    const { sourceDataOfBrushed } = this.state;
    const { config } = this.widgetInfo;
    let drillFilterFields = [];
    const filtersObj = {};
    let drillFilters = [];
    if (tableTypes.includes(config.selectedChart)) {
      // 图表类型为表格时
      sourceDataOfBrushed.forEach((item) => {
        console.log(BIStore.formedViews[this.widgetInfo.viewId], item.key);
        const name = item.key.split(DEFAULT_SPLITER)[0];
        const sqlType = BIStore.formedViews[this.widgetInfo.viewId].model[name].sqlType;

        if (SQL_DATE_TYPES.includes(sqlType)) {
          // 时间字段因为筛选格式不一样，需要特殊处理
          // if (!filtersObj[item.key]) {
          const colItem = config.cols.find((c) => c.name === item.key);
          // const name = this.getDateCkName(item.key, colItem.agg);
          const value = this.getDateCkValue(item.value, colItem.agg);
          filtersObj[item.key] = {
            name,
            operator: '=',
            sqlType,
            type: 'filter',
            value: `'${value}'`,
          };
          drillFilterFields.push(item.key);
          // } else {
          //   const colItem = config.cols.find((c) => c.name === item.key);
          //   const value = this.getDateCkValue(item.value, colItem.agg);
          //   filtersObj[item.key].value.push(`'${value}'`);
          // }
        } else if (SQL_NUMBER_TYPES.includes(sqlType)) {
          filtersObj[item.key] = {
            name,
            operator: '=',
            sqlType,
            type: 'filter',
            value: item.value,
          };
          drillFilterFields.push(item.key);
        } else {
          !filtersObj[item.key]
            ? ((filtersObj[item.key] = {
                name,
                operator: 'in',
                sqlType,
                type: 'filter',
                value: [`'${item.value}'`],
              }),
              drillFilterFields.push(item.key))
            : filtersObj[item.key].value.push(`'${item.value}'`);
        }
      });
      drillFilters = Object.keys(filtersObj).map((key) => filtersObj[key]);
    } else if (sourceDataOfBrushed.length) {
      const filed = this.lastCols[0];
      const filterKey = filed.randomName || filed.name;
      if (SQL_DATE_TYPES.includes(filed.sqlType)) {
        // 时间字段因为筛选格式不一样，需要特殊处理
        const name = this.getDateCkName(filed.name, filed.agg);
        this.drillFiltersHistory[filed.name] = {
          name,
          operator: 'in',
          sqlType: 'STRING',
          type: 'filter',
          value: sourceDataOfBrushed.map((item) => {
            return `'${this.getDateCkValue(item[filterKey], filed.agg)}'`;
          }),
        };
      } else {
        this.drillFiltersHistory[filed.name] = {
          name: filed.name,
          operator: 'in',
          sqlType: filed.sqlType,
          type: 'filter',
          value: sourceDataOfBrushed.map((item) => {
            return `'${item[filterKey]}'`;
          }),
        };
      }
      this.state.drillFields
        .slice(0, this.state.drillFields.length - 1)
        .concat([this.initialCols[0]])
        .forEach((item) => {
          this.drillFiltersHistory[item.name] && (drillFilters.push(this.drillFiltersHistory[item.name]), drillFilterFields.push(item.name));
        });
    }

    return { drillFilterFields, drillFilters };
  };
  getDateCkName = (name, agg) => {
    switch (agg) {
      case 'hour':
        return `formatDateTime(${name},'%H')`;
      case 'week':
        return `formatDateTime(${name},'%G-%V')`;
      case 'month':
        return `formatDateTime(${name},'%Y-%m')`;
      case 'quarter':
        return `concat(toString(toYear(toDateTime(${name}))),'-',toString(toQuarter(toDateTime(${name}))))`;
      case 'halfyear':
        return `concat(toString(toYear(toDateTime(${name}))),'-',toString(if(toQuarter(toDateTime(${name})) <3,1,2)))`;
      case 'year':
        return `formatDateTime(${name},'%Y')`;
      default:
        // 默认按天
        return `formatDateTime(${name},'%F')`;
    }
  };
  getDateCkValue = (value, agg) => {
    switch (agg) {
      case 'week':
        return value.replace('周', '');
      case 'quarter':
        return value.replace('Q', '');
      case 'halfyear':
        return value.replace('上', '-1').replace('下', '-2');
      default:
        return value;
    }
  };

  insideControlFilter = (filters) => {
    const currentFilters = [];
    filters.forEach((item) => {
      Object.keys(item.value).forEach((key) => {
        item.value[key].forEach((e) => {
          currentFilters.push(e);
        });
      });
    });
    this.getData('clear', null, null, null, null, currentFilters);
  };

  render() {
    const currentItem = BIStore.currentItemsInfo[this.widgetInfo.id];
    const { selectedChart, mode, controls } = this.widgetInfo.config;
    const compareFilterConfig = BoardStore.compareFilterConfig[this.widgetInfo.id];
    const compareFilterText = this.getCompareFilterText(compareFilterConfig);
    const { isLoading, sourceDataGroup, sourceDataOfBrushed, selectedItems, seriesItems, NLPData, whichDataDrillBrushed, drillFields, data } = this.state;
    const dataDrillPanel = (
      <EnhancerPanel
        currentData={data.resultList}
        widgetConfig={this.widgetInfo.config}
        onDataDrillDown={this.drillDown}
        // onDataDrillUp={this.drillUp}
        drillHistory={currentItem && currentItem.queryConditions.drillHistory}
        isSelectedfilter={sourceDataOfBrushed}
        isSelectedGroup={sourceDataGroup}
        chartStyle={this.widgetInfo.config.selectedChart}
        viewId={this.widgetInfo.viewId}
        widgetContentRef={this.widgetContent.current}
      />
    );
    const canDrill = DrillableChart.includes(selectedChart) && drillFields.length && !isLoading;
    return (
      <div className="bi-chart-content">
        {controls &&
          controls.length > 0 &&
          (util.isMobile() ? (
            <GlobalControl
              commonFilterObject={[]}
              setCommonFilterValue={this.insideControlFilter}
              type="insideCard"
              parentControls={controls}
              // lang={this.props.lang}
            />
          ) : (
            <GlobalConfigWrapper type="insideCard" parentControls={controls} insideControlFilter={this.insideControlFilter} />
          ))}

        {isLoading && !(mode === 'chart' && [ChartTypes.TextTable, ChartTypes.ContentTable].includes(selectedChart)) && <Loading />}
        {(!isLoading || tableTypes.indexOf(selectedChart) > -1) && (
          <Fragment>
            {compareFilterText && (
              <div className="widget-compare-info">
                <div className="widget-compare-text-content operate-text-btn" onClick={() => this.props.chartCompare(this.widgetInfo)}>
                  <div className="widget-compare-text">{compareFilterText.currentValue}</div>
                  <div className="widget-compare-text widget-compare-fixed-text">对比</div>
                  <div className="widget-compare-text">{compareFilterText.compareValue}</div>
                </div>
                <i className="iconfont icon-shanchu operate-text-btn" onClick={this.deleteCompare} />
              </div>
            )}
            {!isLoading &&
              !(mode === 'chart' && [ChartTypes.TextTable, ChartTypes.ContentTable].includes(selectedChart)) &&
              !data.resultList.length &&
              !data.compareResultList.length && <TableNullData className="bi-chart-nulldata" />}
            <Dropdown
              overlayClassName="drill-dropdown"
              getPopupContainer={() => this.widgetContent.current}
              overlay={dataDrillPanel}
              placement="topCenter"
              trigger={[ChartTypes.TextTable, ChartTypes.ContentTable].includes(selectedChart) ? [] : ['contextMenu']}>
              <div
                ref={this.widgetContent}
                className={`widget-content ${this.state.showScrollBar ? '' : 'widget-content-hideScrollBar'} ${
                  !(mode === 'chart' && [ChartTypes.TextTable, ChartTypes.ContentTable].includes(selectedChart)) &&
                  !data.resultList.length &&
                  !data.compareResultList.length
                    ? 'no-data-widget'
                    : ''
                } ${!canDrill ? 'un-select' : ''}`}
                // onMouseEnter={() => {
                //   this.setState({ showScrollBar: true });
                // }}
                // onMouseLeave={() => {
                //   this.setState({ showScrollBar: false });
                // }}
                // style={canDrill ? { height: 'calc(100% - 27px)' } : { height: '100%' }}
              >
                {data.resultList.length ||
                data.compareResultList.length ||
                (mode === 'chart' && [ChartTypes.TextTable, ChartTypes.ContentTable].includes(selectedChart)) ? (
                  <Widget
                    show={!isLoading || (mode === 'chart' && [ChartTypes.TextTable, ChartTypes.ContentTable].includes(selectedChart))}
                    {...this.props}
                    {...this.widgetInfo.config}
                    data={data.resultList}
                    compareData={data.compareResultList}
                    onPaginationChange={this.paginationChange}
                    pagination={currentItem ? currentItem.queryConditions.pagination : {}} //currentItemsInfo
                    getDataDrillDetail={this.getDataDrillDetail}
                    whichDataDrillBrushed={whichDataDrillBrushed} //
                    NLPData={NLPData}
                    getNLPData={this.getNLPData}
                    isLoading={isLoading}
                    selectedItems={selectedItems}
                    seriesItems={seriesItems}
                    onSelectChartsItems={this.onSelectChartsItems}
                    onSelectSeriesItems={this.onSelectSeriesItems}
                    // cancelLinking={this.cancelLinking}
                    model={BIStore.formedViews[this.widgetInfo.viewId] ? BIStore.formedViews[this.widgetInfo.viewId].model : null}
                    requestParams={this.requestParams}
                    showScrollBar={this.state.showScrollBar}
                    canDrill={canDrill}
                    dataDrillPanel={dataDrillPanel}
                    widgetContentRef={this.widgetContent.current}
                    cardRefreshWithoutConfig={this.refreshData}
                  />
                ) : null}
                {drillFields.length && !isLoading ? (
                  <ul className="drill-list">
                    <li className="drill-item">
                      <div className="drill-item-name uxs-pointer" onClick={() => this.cleanDrillDown()}>
                        返回
                      </div>
                    </li>
                    {drillFields.map((item, index) => {
                      return (
                        <li key={`${item.name}${index}`} className="drill-item">
                          <div>></div>
                          <div className="drill-item-name uxs-pointer" onClick={() => this.cleanDrillDown(index)}>
                            {item.fieldDisplay || item.name}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            </Dropdown>
          </Fragment>
        )}
      </div>
    );
  }
}

export default BIChart;
