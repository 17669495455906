import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Table } from 'antd';
import Loading from '../../../component/Loading/Loading';
import util from '../../../util';
import api from '../../../api';

function CleanRuleDetail(props) {
  const typeObj = {
    1: '过滤数据行',
  };
  const formulaObj = {
    '=': '等于',
    '!=': '不等于',
    in: '包含',
    notIn: '不包含',
    is: '为空',
    'is not': '非空',
    '<': '小于',
    '>': '大于',
    '<=': '小于等于',
    '>=': '大于等于',
    between: '区间',
  };

  const columns = [
    {
      title: '过滤规则',
      dataIndex: 'name',
    },
    {
      title: '说明',
      dataIndex: 'description',
      render: (text, record) => {
        return <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>;
      },
    },
  ];

  const { editInfo, type } = props;
  const { editId } = editInfo;

  const [data, setData] = useState();
  const [fieldObj, setFieldObj] = useState({});
  const [excludedRule, setExcludedRule] = useState(null);

  useEffect(() => {
    type === 'detail'
      ? util.get(
          api.audit.info,
          {
            id: editId,
          },
          (res) => {
            if (res && res.auditSourceInfo) {
              const { auditSourceInfo } = res;
              formatData(auditSourceInfo);
            }
          }
        )
      : util.get(`${api.filterConfig.info}/${editId}`, {}, (res) => {
          formatData(res);
        });
  }, []);

  function formatData(res) {
    const resCopy = res;
    resCopy.configFilter = JSON.parse(resCopy.configFilter);
    if (resCopy.configFilter.length) {
      getFileds(res.viewId);
    }
    if (res.complexFilter) {
      getDefaultExcludedRule(res.complexFilter);
    }
    setData(resCopy);
  }

  function getFileds(viewId) {
    util.get(`${api.BI.views}/${viewId}`, {}, (res) => {
      const fieldObjCopy = {};
      res.fieldModelList.forEach((item) => {
        fieldObjCopy[item.fieldName] = item;
      });
      setFieldObj(fieldObjCopy);
    });
  }

  function getDefaultExcludedRule(complexFilter) {
    const complexFilterArray = complexFilter.split(',');
    const excludedRuleCopy = [];
    util.get(
      api.dataclean.getDefaultExcludedRule,
      {
        sourceType: 'viewType',
      },
      (res) => {
        res.forEach((item) => {
          complexFilterArray.includes(item.defaultRuleId) && excludedRuleCopy.push(item);
        });
        setExcludedRule(excludedRuleCopy);
      }
    );
  }

  return data ? (
    <Row className="audit-detail">
      <Col span={24}>
        <Col span={8} className="title">
          规则名称：
        </Col>
        <Col span={16}>{data.name}</Col>
      </Col>
      <Col span={24}>
        <Col span={8} className="title">
          清洗类型：
        </Col>
        <Col span={16}>{typeObj[data.type]}</Col>
      </Col>
      {data.configFilter.length ? (
        <Col span={24}>
          <Col span={8} className="title">
            数据范围：
          </Col>
          <Col span={16}>
            {data.configFilter.map((item, index) => {
              return (
                <div className="clean-filter-item" key={`${item.name}${index}`}>
                  {fieldObj[item.name] ? fieldObj[item.name].fieldDisplay : item.name}
                  {` ${formulaObj[item.operator]} `}
                  {item.operator === 'between' ? `：${item.value[0]} 到 ${item.value[1]}` : ['is', 'is not'].includes(item.operator) ? null : item.value}
                </div>
              );
            })}
            <div style={{ marginTop: 16 }}>{data.complexFilter ? '已开启复杂规则' : '未开启复杂规则'}</div>
            {data.complexFilter && excludedRule && <Table columns={columns} dataSource={excludedRule} pagination={false} />}
          </Col>
        </Col>
      ) : null}
    </Row>
  ) : (
    <Loading />
  );
}

export default CleanRuleDetail;
