// 看板布局设置弹窗
import React from 'react';
import { Modal, Form, Radio, message } from 'antd';
import util from '../../../util';
import api from '../../../api';
import PageHeaderStore from '../PageHeaderStore';

function Main(props) {
  const { visible, onHide, form } = props;
  const { getFieldDecorator } = form;
  function submit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        util.post(
          api.globalControl.save,
          {
            id: PageHeaderStore.currentBoardId,
            flexConfig: JSON.stringify(values),
          },
          () => {
            onHide();
            PageHeaderStore.setCurrentDashboardLayout(values.verticalCompact !== 'false');
            PageHeaderStore.reloadBoard();
          }
        );
      }
    });
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
    },
    wrapperCol: {
      xs: { span: 24 },
    },
  };
  return (
    <Modal
      // className="full-right scroll-drawer"
      title="布局设置"
      visible={visible}
      onCancel={onHide}
      onOk={submit}
      centered
      okText="保存"
      cancelText="取消"
      width={500}
    >
      <Form {...formItemLayout} onSubmit={submit}>
        <Form.Item label="">
          {getFieldDecorator('verticalCompact', {
            initialValue: PageHeaderStore.currentDashboardLayout === false ? 'false' : 'true',
          })(
            <Radio.Group>
              <Radio value="true" disabled={PageHeaderStore.currentDashboardLayout === false}>
                弹性布局
              </Radio>
              <Radio value="false">自由布局（不限制宽度)</Radio>
            </Radio.Group>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Form.create()(Main);
