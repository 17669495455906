import React, { PureComponent, GetDerivedStateFromProps } from 'react';
import { Slider as AntSlider } from 'antd';
import { metricAxisLabelFormatter } from '../app/util';
import './Panel/Layouts/Layouts.less';

class Slider extends PureComponent {
  state = {
    value: void 0,
    prevValueProp: void 0,
  };

  static getDerivedStateFromProps = (props, state) => {
    if (state.prevValueProp !== props.value) {
      return {
        prevValueProp: props.value,
        value: props.value,
      };
    }
    return null;
  };

  sliderChange = (value) => {
    this.setState({ value });
  };

  sliderAfterChange = (value) => {
    this.props.onChange(value);
  };

  getTooltipPopupContainer = (node) => node.parentNode;

  render() {
    const { control } = this.props;
    const { value } = this.state;
    const { max, min, step, label } = control;
    const marks = label
      ? {
          marks: {
            // [min]: metricAxisLabelFormatter(min),
            // [max]: metricAxisLabelFormatter(max),
            ...(value && {
              [value[0]]: metricAxisLabelFormatter(value[0]),
              [value[1]]: metricAxisLabelFormatter(value[1]),
            }),
          },
        }
      : void 0;

    return (
      <div className="controlSlider">
        <AntSlider
          max={max}
          min={min}
          step={step}
          value={value}
          onChange={this.sliderChange}
          onAfterChange={this.sliderAfterChange}
          getTooltipPopupContainer={this.getTooltipPopupContainer}
          {...marks}
          range
        />
      </div>
    );
  }
}

export default Slider;
