import mathUtil from './mathUtil';

export const config = {
  event: {
    uv: { title: '事件访客数', desc: '一段时间内，触发该事件的访客数之和。' },
  },
  survey: {
    optimizeDim: { title: '调研对象', desc: '' },
    optimizeDimDetail: { title: '调研对象', desc: '' },
  },

  common: {
    pv: {
      title: '浏览量',
      desc: '一段时间内，访客浏览页面的次数。访客打开1个页面计为1次PV，在一页面不断刷新则PV累计。',
      type: 'number',
      unit: '次',
    },
    uv: { title: '访客数', desc: '一段时间内，来访的访客数。', type: 'number', unit: '人' },
    rUv: { title: '重复答题访客数', desc: '一段时间内，重复答题的访客数。', type: 'number', unit: '人' },
    uvNew: { title: '新访客数', desc: '一段时间内，第一次来访的访客数。', type: 'number', unit: '人' },
    visits: { title: '访问次数', desc: '一段时间内，访客的访问次数，一次访问浏览一个或多个页面。', type: 'number', unit: '次' },
    exitRate: { title: '退出率', desc: '', type: 'rate', unit: '%' },
    exits: { title: '退出次数', desc: '每次访问从该页面退出的访客数。', type: 'number', unit: '人' },
    bounceRate: { title: '跳出率', desc: '一段时间内，仅浏览1个页面就离开产品的访客占总访客的百分比', type: 'rate', unit: '%' },
    bounces: { title: '跳出次数', desc: '一段时间内，仅浏览了1个页面就离开的访客数。', type: 'number', unit: '人' },
    pages: { title: '浏览页面数', desc: '', type: 'number', unit: '页' },
    pvContribute: {
      title: '贡献下游浏览量',
      desc: '一段时间内，访客访问网站浏览了该页面后继续浏览了其它页面的浏览量之和。',
      type: 'number',
      unit: '次',
    },
    visitAvgTime: {
      title: '平均停留时长',
      desc: '一段时间内，访客每次访问的平均每次访问的时长。即每次访问中打开第1个页面到关闭最后1个页面的时间之差的平均值',
      type: 'time',
    },
    visitAvgPages: { title: '平均访问页数', desc: '一段时间内，访客访问平均访问的页面数。', type: 'number', unit: '页' },
    visitorAvgVisits: { title: '平均访问次数', desc: '一段时间内，访客平均访问次数。', type: 'number', unit: '次' },
    loadAvgTime: { title: '平均加载时长', desc: '访客从进入页面开始到页面内容加载完成时的平均时长。', type: 'minisecond' },
    pageVisitAvgTime: { title: '平均页面停留时长', desc: '', type: 'time' },
    dayAvgVisits: {
      title: '日均访问次数',
      desc: '一段时间内，访客每天访问的平均次数，可以判断产品对用户的粘性是否足够高。',
      type: 'number',
      unit: '次',
    },
    new_visitor: { title: '新老用户', desc: '', type: 'string' },
    visitTime: { title: '访问时长', desc: '', type: 'time' },

    // key以及name对应的名字
    analyticsName: { title: '事件名称', type: 'key', desc: '' },
    typeName: { title: '类型', type: 'string', desc: '' },
    value_key: { title: '自定义参数', type: 'key', desc: '' },
    value_name: { title: '自定义参数', type: 'key', desc: '' },
    funnelSteps_key: { title: '步骤名', type: 'key', desc: '' },
    funnelSteps_name: { title: '步骤名', type: 'key', desc: '' },

    date_hour_key: { title: '时间', type: 'key', desc: '' },
    date_hour_name: { title: '时间', type: 'key', desc: '' },
    date_day_key: { title: '日期', type: 'date', desc: '' },
    date_day_name: { title: '日期', type: 'date', desc: '' },
    date_week_key: { title: '日期', type: 'date', desc: '' },
    date_week_name: { title: '日期', type: 'date', desc: '' },
    date_month_key: { title: '日期', type: 'date', desc: '' },
    date_month_name: { title: '日期', type: 'date', desc: '' },
    date_year_key: { title: '日期', type: 'date', desc: '' },
    date_year_name: { title: '日期', type: 'date', desc: '' },
    district_city_key: { title: '城市', type: 'key', desc: '' },
    district_city_name: { title: '城市', type: 'key', desc: '' },
    district_province_key: { title: '省份', type: 'key', desc: '' },
    district_province_name: { title: '省份', type: 'key', desc: '' },
    district_country_key: { title: '国家', type: 'key', desc: '' },
    district_country_name: { title: '国家', type: 'key', desc: '' },
    terminal_resolution_key: { title: '分辨率', type: 'data', desc: '' },
    terminal_resolution_name: { title: '分辨率', type: 'key', desc: '' },
    terminal_browser_key: { title: '浏览器', type: 'key', desc: '' },
    terminal_browser_name: { title: '浏览器', type: 'key', desc: '' },
    terminal_browser_version_key: { title: '浏览器版本', type: 'data', desc: '' },
    terminal_browser_version_name: { title: '浏览器版本', type: 'data', desc: '' },
    terminal_os_key: { title: '操作系统', type: 'data', desc: '' },
    terminal_os_name: { title: '操作系统', type: 'key', desc: '' },
    terminal_os_version_key: { title: '操作系统版本', type: 'data', desc: '' },
    terminal_os_version_name: { title: '操作系统版本', type: 'key', desc: '' },
    terminal_type_name: { title: '设备', type: 'key', desc: '' },
    terminal_type_key: { title: '设备', type: 'key', desc: '' },
    terminal_brand_key: { title: '品牌', type: 'key', desc: '' },
    terminal_brand_name: { title: '品牌', type: 'key', desc: '' },
    terminal_model_key: { title: '机型', type: 'key', desc: '' },
    terminal_model_name: { title: '机型', type: 'key', desc: '' },
    terminal_network_key: { title: '网络类型', type: 'key', desc: '' },
    terminal_network_name: { title: '网络类型', type: 'key', desc: '' },
    channel_mix_key: { title: '渠道', type: 'key', desc: '' },
    channel_mix_name: { title: '来源类型', type: 'key', desc: '' },
    channel_type_key: { title: '来源类型', type: 'key', desc: '' },
    channel_type_name: { title: '来源类型', type: 'key', desc: '' },
    channel_link_key: { title: '外部链接', type: 'key', keySubType: 'url', desc: '' },
    channel_link_name: { title: '外部链接', type: 'key', keySubType: 'url', desc: '' },
    channel_link_info_key: { title: '外部链接', type: 'key', keySubType: 'url', desc: '' },
    channel_link_info_name: { title: '外部链接', type: 'key', keySubType: 'url', desc: '' },
    channel_keyword_key: { title: '搜索词', type: 'key', desc: '' },
    channel_keyword_name: { title: '搜索词', type: 'key', desc: '' },
    channel_search_key: { title: '搜索引擎', type: 'key', desc: '' },
    channel_search_name: { title: '搜索引擎', type: 'key', desc: '' },
    channel_utm_key: { title: '广告', type: 'key', desc: '' },
    channel_utm_name: { title: '广告', type: 'key', desc: '' },
    channel_scene_key: { title: '场景', type: 'key', desc: '' },
    channel_scene_name: { title: '场景', type: 'key', desc: '' },
    shareVisitor_name: { title: '访客ID', type: 'key', desc: '' },
    shareVisitor_key: { title: '访客ID', type: 'key', desc: '' },
    channel_share_page_key: { title: '分享页面', type: 'key', desc: '' },
    channel_share_page_name: { title: '分享页面', type: 'key', desc: '' },
    channel_share_visitor_key: { title: '访客ID', type: 'key', desc: '' },
    channel_share_visitor_name: { title: '访客ID', type: 'key', desc: '' },
    pageset_visited_key: { title: '页面集', type: 'key', keySubType: 'url', desc: '' },
    pageset_visited_name: { title: '页面集', type: 'key', keySubType: 'url', desc: '' },
    page_visited_key: { title: '页面', keySubType: 'url', type: 'key', desc: '' },
    page_visited_name: { title: '页面', keySubType: 'url', type: 'key', desc: '' },
    pageset_entry_key: { title: '页面集', type: 'key', keySubType: 'url', desc: '' },
    pageset_entry_name: { title: '页面集', type: 'key', keySubType: 'url', desc: '' },
    page_entry_key: { title: '页面', type: 'key', keySubType: 'url', desc: '' },
    page_entry_name: { title: '页面', type: 'key', keySubType: 'url', desc: '' },
    page_group_key: { title: '自定义页面', type: 'key', keySubType: 'url', desc: '' },
    page_group_name: { title: '自定义页面', type: 'key', keySubType: 'url', desc: '' },
    path_pageset_after_key: { title: '退出页面集', keySubType: 'url', type: 'key', desc: '' },
    path_page_after_key: { title: '退出页面', keySubType: 'url', type: 'key', desc: '' },
    path_pageset_before_key: { title: '进入页面集', keySubType: 'url', type: 'key', desc: '' },
    path_page_before_key: { title: '进入页面', keySubType: 'url', type: 'key', desc: '' },
    path_channelset_key: { title: '进入渠道', keySubType: 'url', type: 'key', desc: '' },
    path_channel_key: { title: '进入渠道', type: 'key', desc: '' },
    path_channelGroup_key: { title: '进入渠道', type: 'key', desc: '' },
    path_pageGroup_before_key: { title: '进入页面', type: 'key', desc: '' },
    path_pageGroup_after_key: { title: '退出页面', type: 'key', desc: '' },
    terminal_browser_engine_key: { title: '浏览器引擎', type: 'key', desc: '' },
    terminal_browser_engine_name: { title: '浏览器引擎', type: 'key', desc: '' },
    terminal_os_lang_key: { title: '浏览器语言', type: 'key', desc: '' },
    terminal_os_lang_name: { title: '浏览器语言', type: 'key', desc: '' },
    answer_topic_option_name: { title: '题目选项', desc: '', type: 'key' },
    answer_sentiment_name: { title: '情感', desc: '', type: 'key' },
    answer_topic_name: { title: '题目', desc: '', type: 'key' },
    answer_prop_name: { title: '观点', desc: '', type: 'key' },
    answer_point_name: { title: '观点', desc: '', type: 'key' },

    param_key: { title: '参数', type: 'key', desc: '' },
    visitor_key: { title: '访客ID', type: 'key', desc: '' },
    visitor_name: { title: '访客ID', type: 'key', desc: '' },
    visitor_nl_name: { title: '新老用户', type: 'key', desc: '' },
    visitor_nl_key: { title: '新老用户', type: 'key', desc: '' },
    // keyword_name: { title: '自定义字段', type: 'key', desc: '' },
    // keyword_key: { title: '自定义字段', type: 'key', desc: '' },
    // targetUrl_key: { title: '自定义参数', type: 'key', desc: '' },
    // targetUrl_name: { title: '自定义参数', type: 'key', desc: '' },
    // currentUrl_key: { title: '自定义参数', type: 'key', desc: '' },
    // currentUrl_name: { title: '自定义参数', type: 'key', desc: '' },
    // currentXpath_key: { title: '自定义参数', type: 'key', desc: '' },
    // currentXpath_name: { title: '自定义参数', type: 'key', desc: '' },

    event_key: { title: '事件名称', type: 'key', desc: '' },
    funnel_key: { title: '漏斗名称', type: 'key', desc: '' },
    funnel_name: { title: '漏斗名称', type: 'key', desc: '' },
    survey_key: { title: '调研名称', type: 'key', desc: '' },
    survey_name: { title: '调研名称', type: 'key', desc: '' },

    // 访客档案
    avgPageViews: { title: '平均访问次数', desc: '', type: 'number', unit: '次' },
    avgTime: { title: '平均停留时长', desc: '', type: 'time' },
    totalVisits: { title: '访问次数', desc: '', type: 'number', unit: '次' },
    avgVisitsDay: { title: '日均访问次数', desc: '', type: 'number', unit: '次' },
    // 答题访客
    visitorRefererType: { title: '来源', desc: '', type: 'string' },
    visitorKeyWord: { title: '搜索词', desc: '', type: 'string' },
    visitorIp: { title: 'IP', desc: '', type: 'string' },
    visitorCity: { title: '城市', desc: '', type: 'string' },
    visitorVisits: { title: '访问次数', desc: '', type: 'number', unit: '次' },
    visitorPageViews: { title: '访问页数', desc: '', type: 'number', unit: '页' },
    visitorVisitAvgTime: { title: '访问时长', desc: '', type: 'time' },
    visitorVisitTime: { title: '最近访问时间', desc: '', type: 'date' },
    visitorAnswerTime: { title: '答题时长', desc: '', type: 'time' },
    visitorJoinTime: { title: '答题时间', desc: '', type: 'string' },
    // 分享模块
    shareUv: { title: '分享访客数 ', desc: '', type: 'number', unit: '人' },
    shareCount: { title: '分享次数', desc: '', type: 'number', unit: '次' },
    shareFissionUv: { title: '裂变访客数', desc: '', type: 'number', unit: '人' },
    shareVisitorName: { title: '访客昵称', desc: '' },
    shareVisitorGender: { title: '性别', desc: '' },
    shareOneLevelUv: { title: '一度分享带来的访客量', desc: '', type: 'number', unit: '人' },
    shareTwoLevelUv: { title: '二度分享带来的访客量', desc: '', type: 'number', unit: '人' },
    shareThreeLevelUv: { title: '三度分享带来的访客量', desc: '', type: 'number', unit: '人' },

    eventUv: { title: '触发访客数', desc: '', type: 'number', unit: '人' },
    // 页面、事件参数
    paramOccurs: { title: '发生次数', desc: '', type: 'number', unit: '次' },
    paramUv: { title: '访客数', desc: '', type: 'number', unit: '人' },
    // 页面上下游
    pagePathUv: { title: '访客数', desc: '', type: 'number', unit: '人' },
    pagePathUvRatio: { title: '访客数占比', desc: '', type: 'rate', unit: '%' },
    pagePathPv: { title: '浏览量', desc: '', type: 'number', unit: '次' },
    pagePathPvRatio: { title: '浏览量占比', type: 'rate', unit: '%' },
    pagePathBounces: { title: '跳出数', type: 'number', unit: '人' },
    pagePathBounceRate: { title: '跳出率', desc: '', type: 'rate', unit: '%' },
    pagePathExits: { title: '退出数', type: 'number', unit: '人' },
    pagePathExitRate: { title: '退出率', desc: '', type: 'rate', unit: '%' },
    uvRatio: { title: '访客数占比', desc: '', type: 'rate', unit: '%' },
    uvExits: { title: '退出数', desc: '', type: 'number', unit: '人' },
    uvConver: {
      title: '转化访客数',
      desc: '',
      type: 'number',
      unit: '人',
    },
    conver: {
      title: '访问次数',
      type: 'number',
      desc: '',
      unit: '人',
    },
    converRate: {
      title: '转化次数占比',
      desc: '',
      type: 'rate',
      unit: '%',
    },
    // 事件

    currentUrl: { title: 'currentUrl', desc: '', type: 'number', unit: '次' },
    currentXpath: { title: 'currentXpath', desc: '', type: 'number', unit: '次' },
    targetUrl: { title: 'targetUrl', desc: '', type: 'number', unit: '次' },
    eventOccurs: { title: '触发次数', desc: '一段时间内，访客触发该事件的次数之和。', type: 'number', unit: '次' },
    eventAvgTime: { title: '平均触发时长', desc: '一段时间内，访客访问网站时从进入页面开始到触发事件的平均时长。', type: 'time' },
    // 漏斗
    funnelConversionRate: {
      title: '漏斗转化率',
      desc: '一段时间内，完成设定任务目标的次数占进入该任务的访客次数的比例。例如：有100人进入注册页面，成功注册为20人，漏斗转化率为20%。',
      type: 'rate',
      unit: '%',
    },
    funnelConversionSiteRate: {
      title: '转化率',
      desc: '一段时间内，完成任务目标的次数占网站总访问次数的百分比。漏斗的总访问次数/网站的总访问次数*100%。',
      type: 'rate',
      unit: '%',
    },
    funnelCreateTime: { title: '创建时间', desc: '', type: 'string' },
    funnelEntryVisitors: { title: '访客数', desc: '', type: 'number', unit: '人' },
    funnelConversionAvgTime: { title: '平均转化时长', desc: '一段时间内，完成设定任务目标的平均时长。', type: 'time' },
    funnelConversionVisitors: { title: '转化访客数', desc: '一段时间内，完成设定任务目标的访客数。', type: 'number', unit: '人' },
    funnelConversion: { title: '访问次数', desc: '一段时间内，完成设定任务目标的访客数。', type: 'number', unit: '人' },
    funnelEntries: { title: '访问次数', desc: '', type: 'number', unit: '次' },
    funnelMiss: { title: '流失数', desc: '在设定的任务目标中，未完成任务目标就离开的访客数。', type: 'number', unit: '人' },
    funnelStepConversionAvgTime: { title: '平均转化时长', desc: '', type: 'time' },
    funnelStepConversionRate: { title: '漏斗转化率', desc: '', type: 'rate', unit: '%' },
    funnelStepConversionSiteRate: { title: '转化率', desc: '', type: 'rate', unit: '%' },
    funnelStepEntryVisitors: { title: '访客数', desc: '', type: 'number', unit: '人' },
    funnelStepConversionVisitors: { title: '转化访客数', desc: '', type: 'number', unit: '人' },
    funnelStepConversion: { title: '访问次数', desc: '', type: 'number', unit: '次' },
    funnelStepEntries: { title: '访问次数', desc: '', type: 'number', unit: '次' },
    funnelStepMiss: { title: '流失数', desc: '', type: 'number', unit: '次' },
    funnelConversionTime: { title: '转化时长', desc: '', type: 'time' },

    funnelMissRate: { title: '流失率', desc: '', type: 'rate', unit: '%' },
    funnelMissVisitors: { title: '流失访客数', desc: '', type: 'number', unit: '人' },

    activelyRate: { title: '活跃度', desc: '', type: 'rate', unit: '%' },
    activelyVisitors: { title: '活跃访客数', desc: '', type: 'number', unit: '人' },
    channel: { title: '渠道', desc: '' },
    oprate: { title: '操作', desc: '' },
    name: { title: '名称', type: 'key', desc: '' },
    type: { title: '类型', type: 'key', desc: '' },
    pushTime: { title: '问题推送时间', desc: '' },
    optimizeTime: { title: '优化上线时间', desc: '' },
    conversionRate: { title: '转化率', desc: '', type: 'rate', unit: '%' },
    conversion: { title: '访问次数', desc: '', type: 'number', unit: '次' },
    satisfaction: { title: '满意度', desc: '', type: 'rate', unit: '%' },
    markRatio: { title: '满意度', desc: '', type: 'rate', unit: '%' },
    // 调研
    surveyName: { title: '问卷名称', desc: '', type: 'string' },
    feedbackName: { title: '调研名称', desc: '', type: 'string' },
    surveyUpdatedTime: { title: '更新时间', desc: '', type: 'string' },
    surveyId: { title: '调研ID', type: 'string' },
    surveyAlltimeInvited: { title: '总邀请次数', desc: '', type: 'number', unit: '次' },
    surveyAlltimeQueue: { title: '总待邀请访客数', desc: '', type: 'number', unit: '人' },
    surveyAlltimeAnswers: { title: '总回收量', desc: '', type: 'number', unit: '次' },
    validCount: { title: '总回收量', desc: '', type: 'number', unit: '次' },
    validCountForDate: { title: '回收量', desc: '', type: 'number', unit: '次' },
    surveyAlltimeOpens: { title: '总浏览量', desc: '', type: 'number', unit: '次' },
    surveyAlltimeSatisfaction: { title: '总满意度', desc: '' },
    surveyAlltimeAvgTime: { title: '总平均答题时长', desc: '', type: 'time' },
    surveyAlltimeTotalTime: { title: '总答题时长', type: 'time', desc: '' },
    surveyAnswers: { title: '回收量', desc: '', type: 'number' },
    surveyOpens: { title: '浏览量', desc: '', type: 'number' },
    surveySatisfaction: { title: '满意度', desc: '' },
    surveyAvgTime: { title: '平均答题时长', type: 'time', desc: '' },
    surveyTotalTime: { title: '总答题时长', desc: '', type: 'time' },
    surveyAlltimeFinishRate: { title: '完成率', desc: '', type: 'rate', unit: '%' },
    validRatio: { title: '完成率', desc: '', type: 'rate', unit: '%' },
    surveyStatus: { title: '问卷状态', type: 'iconHead', desc: '' },
    channelType: { title: '渠道类型', type: 'string', desc: '' },
    option_answer_content: { title: '内容', type: 'string', desc: '' },
    reply_time_long: { title: '结束答题时间', type: 'string', desc: '' },
    // 参数管理
    paramName: { title: '参数名称', type: 'key', desc: '' },
    paramType: { title: '变量类型', desc: '', type: 'string' },
    description: { title: '描述', desc: '', type: 'string' },
    identifier: { title: '标识符', desc: '', type: 'string' },
    // 自定义属性
    parameterName: { title: '自定义属性名称', type: 'key', desc: '' },
    // 页面组
    created_time: { title: '创建时间', desc: '', type: 'string' },
    // 其他
    rate: { title: '占比', type: 'rate', unit: '%' },
    dimDisplay: { title: '类型', type: 'string', desc: '' },
    detailDisplay: { title: '优化对象', type: 'key', desc: '' },
    subTotal: { title: '选择人数', desc: '', type: 'number', unit: '人' },
    recordDate: { title: '优化上线时间', type: 'string', desc: '' },
    behaviour: { title: '操作', desc: '' },
    optimizeDate: { title: '日期', type: 'key', desc: '' },
    optimizeDim: { title: '类型', type: 'string', desc: '' },
    optimizeDimDetail: { title: '优化对象', type: 'key', desc: '' },
    dataDate: { title: '数据时间', type: 'string', desc: '' },
    email: { title: '邮箱地址', desc: '', type: 'string' },
    createdTime: { title: '创建时间', desc: '', type: 'string' },
    createdAt: { title: '创建时间', desc: '', type: 'string' },
    surveyCreatedTime: { title: '创建时间', desc: '', type: 'string' },
    endCount: { title: '目标回收量', desc: '', type: 'number' },
    recoveryWeight: { title: '回收权重', desc: '', type: 'number' },
    recoveryWeightAdd: { title: '回收权重', desc: '', type: 'number' },
    intervalTime: { title: '调研答案时间区间', desc: '', type: 'string' },
    success: { title: '状态', desc: '', type: 'string' },
    tradeState: { type: 'iconHead', desc: '' },
    ts_last_edit: { title: '最后一次计算时间', desc: '', type: 'string' },
    tsCreated: { title: '创建时间', desc: '', type: 'string' },
    updatedAt: { title: '更新时间', desc: '', type: 'string' },
    user: { title: '创建人', type: 'string' },
    timeUpdate: { title: '创建时间', type: 'string' },
    visitorsCount: { title: '访客数', type: 'string' },
    radioUv: { title: '访客数占比', desc: '', type: 'rate', unit: '%' },
    value: { title: '值', desc: '', type: 'rate', unit: '%' },
    ratio: { title: '访客数占比', desc: '', type: 'rate', unit: '%' },
    time: { title: '时间', desc: '', type: 'string' },
    url: { title: 'URL', type: 'key', desc: '' },
    duration: { title: '时长', desc: '', type: 'string' },
    isHasParam: { title: '', type: 'iconLast', desc: '' },
    isHasEventParam: { title: '', type: 'iconLast', desc: '' },
    stick: { title: '是否关注', desc: '' },
    npsValue: { title: 'NPS', desc: '', type: 'NPS' },
    npsValueChain: { title: 'NPS环比', desc: '', type: 'rate', unit: '%' },
    summary: { title: '汇总', desc: '', type: 'rate', unit: '%' },
    lastVisitTime: { desc: '', type: 'string' },
    source: { desc: '', type: 'string' },
    kw: { desc: '', type: 'string' },
    IP: { desc: '', type: 'string' },
    city: { desc: '', type: 'string' },
    visitorId: { type: 'longString', desc: '' },
    before: { title: '优化前', desc: '', type: 'number' },
    content: { type: 'key', desc: '' },
    subTime: { type: 'longString', desc: '' },
    after: { title: '优化后', desc: '', type: 'number' },
    trend: { type: 'key' },
    eventName: { title: '事件名', desc: '', type: 'key' },
    operation: { title: '操作', desc: '', type: 'string' },
    idsite: { title: '产品', desc: '', type: 'key' },
    sdkType: { title: 'SDK类型', desc: '', type: 'string' },
    sdkVersion: { title: 'SDK版本', desc: '', type: 'string' },
    configVersion: { title: '配置版本', desc: '', type: 'string' },
    customEventName: { title: '埋点事件', desc: '', type: 'string' },
    aliasName: { title: '属性显示名', desc: '', type: 'string' },
    parameterType: { title: '属性类型', desc: '', type: 'string' },
    preset: { title: '预置', desc: '', type: 'string' },
    showLog: { title: '是否打印日志', desc: '', type: 'string' },
    batchSend: { title: '是否批量发送', desc: '', type: 'string' },
    compare: { title: '环比', desc: '', type: 'rate', unit: '%' },
    radio: { title: '满意度', desc: '', type: 'rate', unit: '%' },
    joinTime: { title: '答题时间', desc: '', type: 'string' },
    NPSRadio: { title: 'NPS', desc: '', type: 'NPS' },
    //模板
    templateType: { title: '渠道类型', desc: '', type: 'string' },
    templateStatus: { title: '使用状态', desc: '', type: 'string' },
    // 预警
    warningSurveyName: { title: '问卷名称', desc: '', type: 'string' },
    status: { title: '预警状态', desc: '', type: 'key' },
    level: { title: '预警等级', desc: '', type: 'string' },
    count: { title: '预警次数', desc: '', type: 'number' },
    warning_time: { title: '历史预警时间', desc: '', type: 'string' },
    warningTime: { title: '预警时间', desc: '', type: 'string' },
    hour24Status: { title: '24小时完成率', desc: '', type: 'rate', unit: '%' },
    created_at: { title: '创建时间', desc: '', type: 'string' },
    userGroupName: { title: '用户分群', desc: '', type: 'string' },
    npsRatio: { title: 'NPS', desc: '', type: 'NPS' },
    topicName: { title: '子题目', desc: '', type: 'key' },
    npsSatisfaction: { title: 'NPS', desc: '', type: 'NPS' },
    roleName: { title: '角色名称', desc: '', type: 'key' },
    remark: { title: '角色描述', desc: '', type: 'string' },
    createTime: { title: '创建时间', desc: '', type: 'string' },
    updateTime: { title: '更新时间', desc: '', type: 'string' },
    module: { title: '模块', desc: '', type: 'key' },
    permissions: { title: '权限', desc: '', type: 'key' },
    username: { title: '账号名', desc: '', type: 'key' },
    mobile: { title: '电话', desc: '', type: 'string' },
    appType: { title: '应用名称', desc: '', type: 'key' },
    boardType: { title: '类型', desc: '', type: 'operateBtn' },
    isPresetChannel: { title: '预置渠道', desc: '', type: 'string' },
    title: { title: '标题', desc: '', type: 'key' },
    
    registerType: { title: '用户类型', desc: '', type: 'string' },
    realName: { title: '真实姓名', desc: '', type: 'string' },
    example: { title: '示例', desc: '', type: 'string' },
    content: { title: '内容', desc: '', type: 'string' },
    answerContent: { title: 'NPS', desc: '', type: 'string' },
    NPS: { title: 'NPS', desc: '', type: 'NPS' },
    isWarning: { title: '是否预警', desc: '', type: 'string' },
    clubName: { title: '门店', desc: '', type: 'string' },
    surveyDetail: { title: '问卷详情', desc: '', type: 'string' },
    TransactionDateTime: { title: '交易日期', desc: '', type: 'string' },
    warningName: { title: '预警规则名称', desc: '', type: 'string' },
    warningStatus: { title: '预警状态', desc: '', type: 'string' },
    ruleContent: { title: '规则', desc: '', type: 'string' },
    warningTopicName: { title: '预警题目', desc: '', type: 'string' },
    visitorId: { title: '用户ID', desc: '', type: 'string' },
    warningRole: { title: '处理人', desc: '', type: 'string' },
    pushChannelData: { title: '所属触点', desc: '', type: 'string' },
    sceneType: { title: '应用场景', desc: '', type: 'string' },
    samples: { title: '频数', desc: '', type: 'number' },
    samplesValue: { title: '频数', desc: '', type: 'number' },
    otherSamples: { title: 'otherSamples', desc: '', type: 'number' },
    surveyTotalSamples: { title: '样本总数', desc: '', type: 'number' },
    surveyStatus: { title: '问卷状态', desc: '', type: 'string' },
    uniq: { title: '去重数', desc: '', type: 'number' },
    userCityName: { title: '城市', desc: '', type: 'string' },
    userRegion: { title: '区域', desc: '', type: 'string' },
    idvisitor: { title: '访客ID', desc: '', type: 'string' },
    sum: { title: '最大值', desc: '', type: 'number' },
    min: { title: '最小值', desc: '', type: 'number' },
    avg: { title: '平均数', desc: '', type: 'number' },
    processingStatus: { title: '处理中预警', desc: '', type: 'string' },
    pendingStatus: { title: '新预警', desc: '', type: 'string' },
    processedStatus: { title: '已完成预警', desc: '', type: 'string' },
    overtimeStatus: { title: '逾期预警', desc: '', type: 'string' },
    closedStatus: { title: '已关闭预警', desc: '', type: 'string' },
    totalStatus: { title: '预警汇总', desc: '', type: 'string' },
    replyTime: { title: '结束答题时间', desc: '', type: 'string' },
    samplesChain: { title: '频数环比', desc: '', type: 'rate', unit: '%' },
    samplesRatio: { title: '频数占比', desc: '', type: 'rate', unit: '%' },
    valueChain: { title: '值环比', desc: '', type: 'rate', unit: '%' },
    mark: { title: '满意度', desc: '', type: 'rate', unit: '%' },
    markValue: { title: '满意度', desc: '', type: 'rate', unit: '%' },
    markValueChain: { title: '满意度环比', desc: '', type: 'rate', unit: '%' },
    answer_analysis_name: { title: '分析项名', desc: '', type: 'string' },
    npsDValue: { title: 'NPS影响值', desc: '', type: 'number' },
    markDValue: { title: '满意度影响值', desc: '', type: 'number' },
    eValue: { title: '影响值', desc: '', type: 'number' },
    iEValue: { title: '重视度', desc: '', type: 'number' },
    upEValue: { title: '正向', desc: '', type: 'number' },
    downEValue: { title: '负向', desc: '', type: 'number' },
    eValueChain: { title: '影响值环比', desc: '', type: 'rate', unit: '%' },
    iEValueChain: { title: '重视度环比', desc: '', type: 'rate', unit: '%' },
    upEValueChain: { title: '正向环比', desc: '', type: 'rate', unit: '%' },
    downEValueChain: { title: '负向环比', desc: '', type: 'rate', unit: '%' },
    samplesUpRatio: { title: '正向频数占比', desc: '', type: 'rate', unit: '%' },
    samplesUp: { title: '正向频数', desc: '', type: 'number' },
    samplesDown: { title: '负向频数', desc: '', type: 'number' },
    samplesRank: { title: '频数排名变化', desc: '', type: 'number' },
    samplesRatioRank: { title: '频数占比排名变化', desc: '', type: 'number' },
    valueRank: { title: '值排名变化', desc: '', type: 'number' },
    eValueRank: { title: '影响值排名变化', desc: '', type: 'number' },
    iEValueRank: { title: '重视度排名变化', desc: '', type: 'number' },
    upEValueRank: { title: '正向排名变化', desc: '', type: 'number' },
    samplesUpRank: { title: '正向频数排名变化', desc: '', type: 'number' },
    samplesUpRatioRank: { title: '正向频数占比排名变化', desc: '', type: 'number' },
    downEValueRank: { title: '负向排名变化', desc: '', type: 'number' },
    samplesDownRank: { title: '负向频数排名变化', desc: '', type: 'number' },
    samplesDownRatioRank: { title: '负向频数占比排名变化', desc: '', type: 'number' },
    samplesDownRatio: { title: '负向频数占比', desc: '', type: 'rate', unit: '%' },
    addInfluence: { title: '正向 + 负向', desc: '', type: 'number' },
    subtractInfluence: { title: '正向 - 负向', desc: '', type: 'number' },
    clubName_visitor_user_param_name: { title: '门店', desc: '', type: 'string' },
    region_visitor_user_param_name: { title: '区域', desc: '', type: 'string' },
    clubUniqueID_visitor_user_param_name: { title: '门店号', desc: '', type: 'string' },
    answer_survey_name_name: { title: '问卷名称', desc: '', type: 'string' },
    answer_survey_id_name: { title: '问卷ID', desc: '', type: 'string' },
    answer_answer_id_name: { title: '答案ID', desc: '', type: 'string' },
    answer_reply_time_name: { title: '答题时间', desc: '', type: 'string' },
    answer_topic_option_other: { title: '题目选项（其他）', desc: '', type: 'string' },
    approvalStatus: { title: '', desc: '', type: 'string' },
    location_city: { title: '城市', desc: '', type: 'string' },
    location_ip: { title: 'IP', desc: '', type: 'string' },
    diffTime: { title: '答题时长', desc: '', type: 'time' },
    start_time: { title: '参与调研时间', desc: '', type: 'string' },
    auditTime: { title: '更新时间', desc: '', type: 'string' },
    displayChannelName: { title: '推送渠道', desc: '', type: 'string' },
    displayTemplateName: { title: '消息模板 ', desc: '', type: 'string' },
    displaySurveyStatus: { title: '问卷状态', desc: '', type: 'string' },
    displayPublishStatus: { title: '发布状态', desc: '', type: 'string' },
    displaySourceAuditStatus: { title: '审核状态', desc: '', type: 'string' },
    displaySurveyCollectorUrl: { title: '被动调研（链接）', desc: '', type: 'string' },
    displayCreatedBy: { title: '创建人', desc: '', type: 'string' },
    displayUpdatedBy: { title: '更新人', desc: '', type: 'string' },
    subTouchpoints: { title: '子节点', desc: '', type: 'childrenColumn' },
    isDraft: { title: '', desc: '', type: 'obj' },
    surveySimple: { title: '', desc: '', type: 'obj' },
    sourceAudit: { title: '', desc: '', type: 'obj' },
    dashboardUrl: { title: '', desc: '', type: 'string' },
    dataDateConfig: { title: '数据周期', desc: '', type: 'string' },
    dashboardName: { title: '订阅看板', desc: '', type: 'string' },
    surveyIds: { title: '问卷ID', desc: '', type: 'string' },
    createdByName: { title: '创建人', desc: '', type: 'string' },
    updatedByName: { title: '更新人', desc: '', type: 'string' },
    displaySourceFrom: { title: '来源', desc: '', type: 'string' },
    editable: { title: '可否编辑', desc: '', type: 'obj' },
    sourceName: { title: '名称', desc: '', type: 'string' },
    shareType: { title: '分享类型', desc: '', type: 'string' },
    radio: { title: '', desc: '', type: 'iconHead' },
    surveyNameAndRemark: { title: '问卷名称', desc: '', type: 'string' },
    openRatio: { title: '打开率', desc: '', type: 'rate', unit: '%' },
    answerRatio: { title: '回收率', desc: '', type: 'rate', unit: '%' },
    completeRatio: { title: '完成率', desc: '', type: 'rate', unit: '%' },
    invite_time: { title: '下发时间', desc: '', type: 'string', unit: '%' },
    ip: { title: 'IP地址', desc: '', type: 'string' },
    isDefault: { title: '默认角色', desc: '', type: 'string' },
    formulaDescribing: { title: '计算口径', desc: '', type: 'string' },
    sqlFragment: { title: '计算口径', desc: '', type: 'string' },
    description: { title: '描述', desc: '', type: 'longString' },
    displaySourceAuditTime: { title: '审核通过时间', desc: '', type: 'date' },
    updatedTime: { title: '更新时间', desc: '', type: 'date' },
    updateBy: { title: '更新人', desc: '', type: 'string' },
    createBy: { title: '创建人', desc: '', type: 'string' },
    formula: { title: '原字段名', desc: '', type: 'string' },
    displayName: { title: '字段显示名', desc: '', type: 'string' },
    visualType: { title: '字段类型', desc: '', type: 'string' },
    fieldSourceTypeName: { title: '字段来源', desc: '', type: 'string' },
    parentName: { title: '所引用工作表', desc: '', type: 'string' },
    numCard: { title: '内容模块', desc: '', type: 'string' },
    boardType: { title: '来源', desc: '', type: 'string' },
    warningLevel: { title: '预警等级', desc: '', type: 'string' },
    warningType: { title: '预警类型', desc: '', type: 'string' },
    belongTo: { title: '所属产品', desc: '', type: 'string' },
    transactor: { title: '处理人', desc: '', type: 'string' },
    associatedRole: { title: '关联角色', desc: '', type: 'string' },
    sourceType: { title: '数据源类型', desc: '', type: 'string' },
    auditType: { title: '审核类型', desc: '', type: 'string' },
    auditorName: { title: '审核人', desc: '', type: 'string' },
    auditStatus: { title: '审核状态', desc: '', type: 'string' },
    operationType: { title: '动作类型', desc: '', type: 'string' },
    businessType: { title: '业务类型', desc: '', type: 'string' },
    operate: { title: '操作', desc: '', type: 'string' },
    setAsTemplate: { title: '模板', desc: '', type: 'string' },
    subscribeStatus: { title: '状态', desc: '', type: 'string' },
    num: { title: '黑名单人数', desc: '', type: 'string' },
    updateType: { title: '更新方式', desc: '', type: 'string' },
    blockedKeyWord: { title: '屏蔽人', desc: '', type: 'string' },
    blackListName: { title: '所属黑名单', desc: '', type: 'string' },
    hitUserNum: { title: '黑名单人数', desc: '', type: 'string' },
    active: { title: '状态', desc: '', type: 'string' },
    dashboardNum: { title: '看板引用数', type: 'number', desc: '' },
    answer_content: { title: '答案内容', type: 'string', desc: '' },
    trigger: { title: '触发器', type: 'string', desc: '' },
    linkager: { title: '联动卡片', type: 'string', desc: '' },
    relation: { title: '关系', type: 'string', desc: '' },
    dataType: { title: '属性类型', type: 'string', desc: '' },
  },
};
// 根据指标名查找其中的属性
function getByKey(key, field, type) {
  if (!type || !config[type] || !config[type][field]) type = 'common';
  if (config[type]) {
    if (Array.isArray(field)) {
      return field.map((item) => (config[type][item] ? config[type][item][key] : ''));
    }
    if (config[type][field]) {
      return config[type][field][key];
    }
    switch (key) {
      case 'type':
        if (field.includes('_name') || field.includes('_key')) {
          return 'key';
        }
        return 'number';
      case 'title':
        // if (field.includes('_name') || field.includes('_key')) {
        //   return '自定义字段';
        // }
        return field;

      default:
        return null;
    }
  }
  return null;
}
// 根据中文名查找指标名
function getByCnName(name, key) {
  if (config.common) {
    let nameKey = null;
    Object.keys(config.common).forEach((commonKey, index) => {
      if (config.common[commonKey].title && config.common[commonKey].title === name) {
        if (key === 'field') {
          nameKey = commonKey;
        } else {
          nameKey = config.common[commonKey][key];
        }

        return false;
      }
    });
    return nameKey;
  }
}
// 单位格式化
function getkeyToFormate(key, value, withUnit) {
  if (!config.common.hasOwnProperty(key)) {
    if (key && key.includes('_name')) {
      return value;
    }
    if (parseFloat(value) == value) {
      return mathUtil.numberFormate(value);
    }
    if (String(value).includes('.')) {
      return Number(value).toFixed(2);
    }
    return value;
  }
  const fieldKey = config.common[key];
  // 判断是什么类型的指标
  function judgeType(key) {
    if (key.hasOwnProperty('type') && key.type && key) {
      if (key.type === 'number') {
        if (String(value).includes('.')) {
          return Number(value).toFixed(2);
        }
        return mathUtil.numberFormate(value);
      }
      if (key.type === 'time') {
        if (isNaN(Number(value)) === false) {
          return mathUtil.formatTime(value);
        }
        return value;
      }
      if (key.type === 'minisecond') {
        return mathUtil.formatMiniSecond(value);
      }
      if (key.type === 'rate') {
        return mathUtil.percentFrom04f(value).replace('%', '');
      }
      if (key.type === 'NPS') {
        return (value * 100).toFixed(1);
      }

      return value;
    }
  }

  // 判断是否带单位
  if (withUnit) {
    // 如果带单位
    if (fieldKey.unit) {
      // 带单位一般为数量
      if (fieldKey.type === 'number' && !String(value).includes('.')) {
        return mathUtil.formateUnit(value, fieldKey.unit);
      }
      return [judgeType(fieldKey), fieldKey.unit];
    }
    if (fieldKey.type === 'time') {
      return mathUtil.formatTime(value, true);
    }
    if (fieldKey.type === 'minisecond') {
      return mathUtil.formatMiniSecond(value, true);
    }
    return [judgeType(fieldKey)];
  }
  if (fieldKey.type === 'rate') {
    return `${judgeType(fieldKey)}${fieldKey.unit}`;
  }
  return judgeType(fieldKey);
}

export default {
  //
  formateTitle(field, value, withUnit = false) {
    if (typeof value === 'string' && (value.includes('%') || value.includes('('))) {
      return value;
    }
    if (
      (value === undefined || value === null || value === '' || (typeof value === 'number' && window.isNaN(value))) &&
      (!config.common[field] || config.common[field].type !== 'childrenColumn')
    ) {
      return '-';
    }
    return getkeyToFormate(field, value, withUnit);
  },
  getType(field, type, isAxis) {
    if (!field) {
      return 'number';
    }
    if (isAxis) {
      return this.changeTypeForAxisNum(getByKey('type', field, type));
    }
    return getByKey('type', field, type);
  },
  // 根据指标的type继续去细分当前指标的子类型
  // （指标，当前类型，维度类型）
  getSubType(field, fieldtype, type) {
    return getByKey(`${fieldtype}SubType`, field, type);
  },
  // 获取指标对应的中文
  getTitle(field, type) {
    return getByKey('title', field, type);
  },
  // 获取指标解释
  getDesc(field, type) {
    return getByKey('desc', field, type) === null ? '' : getByKey('desc', field, type);
  },
  // 获取指标的类
  getClass(field, type) {
    return getByKey('class', field, type);
  },
  getUnit(field, type) {
    if (this.getType(field) === 'minisecond') {
      return '毫秒';
    }
    if (this.getType(field) === 'time') {
      return '秒';
    }

    return getByKey('unit', field, type);
  },
  // 判断y轴时使用的 通过中文名获取指标
  getCnName(name, key, isAxis) {
    if (isAxis) {
      return this.changeTypeForAxisNum(getByCnName(name, key));
    }
    return getByCnName(name, key);
  },
  changeTypeForAxisNum(type) {
    if (type === 'rate') {
      return 1;
    }
    if (type === 'time' || type === 'minisecond') {
      return 2;
    }
    if (type === 'NPS') {
      return 3;
    }
    return 0;
  },
};
