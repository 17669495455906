import 'babel-polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'intro.js/introjs.css';
import './static/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'antd-mobile-v2/dist/antd-mobile.css';
import './static/animate.css';
import './static/less/antd.css';
import { ToastContainer } from 'react-toastify';
import Index from './Index/index';
import Home from './home';
import 'rc-trigger/assets/index.css';
import pageHeaderStore from './component/PageHeader/PageHeaderStore';
import TablePageStore from './containers/CommonTable/TablePageStore';
import loginStore from './Personal/Login/store';
import PageHeaderStore from './component/PageHeader/PageHeaderStore';
import { ClickToComponent } from 'click-to-react-component/src/index';
import './index.sass';
import './static/css/iconfont/iconfont.css';

const Apply = React.lazy(() => import('./Index/Apply'));
//const Demo = React.lazy(() => import('./Index/Demo'));
const AppStart = React.lazy(() => import('./Index/AppStart'));
const Authorization = React.lazy(() => import('./Index/Authorization'));
const EnterpriseAuthorization = React.lazy(() => import('./Index/EnterpriseAuthorization'));
const EnterpriseWxAuthorization = React.lazy(() => import('./Index/EnterpriseWxAuthorization'));
const EditSurvey = React.lazy(() => import('./containers/Wenjuan/EditSurvey'));
const CreateProduct = React.lazy(() => import('./Product/Create/index.lazy'));
const UXSRedirect = React.lazy(() => import('./Index/UXSRedirect'));

const customHistory = createBrowserHistory();
// let preUrl = customHistory.location.pathname;
// customHistory.listen((location, action) => {
//   // location is an object like window.location
//   if (location.pathname !== preUrl) {
//     pageHeaderStore.resetPage();
//     preUrl = location.pathname;
//     pageHeaderStore.replaceState(true);
//   } else if (!location.hash) {
//     // document.location.reload();
//   }
// });
// 绑定resize事件，以达到根元素字体大小随窗口变化的效果
const resize = () => {
  document.documentElement.style.fontSize = `${document.documentElement.clientWidth / 7.5}px`;
  PageHeaderStore.setPagePadding();
};
resize();
window.addEventListener('resize', resize);
// 监听url的变化对fetch进行拦截
function proxy(history, state, fn) {
  const cb = history[state];
  history[state] = function() {
    cb.apply(history, arguments),
      (function(history) {
        return formate(history, 'Function');
      })(fn) && fn(Array.prototype.slice.call(arguments));
  };
}
function formate(history, str) {
  return `[object ${str}]` === {}.toString.call(history);
}
// 设置全局历史浏览记录
TablePageStore.setHistory(window.location.pathname);
proxy(window.history, 'pushState', () => {
  if (window.controller) {
    window.controller.abort();
    window.controller = null;
    window.controller = new window.AbortController();
  }
  // 设置全局历史浏览记录
  TablePageStore.setHistory(window.location.pathname);
  loginStore.setPermission(true);
  PageHeaderStore.setPagePadding();
  PageHeaderStore.resetTime();
  pageHeaderStore.resetPage();
  pageHeaderStore.replaceState(true);
});
window.addEventListener('popstate', () => {
  // 设置全局历史浏览记录
  TablePageStore.setHistory(window.location.pathname);
  loginStore.setPermission(true);
  PageHeaderStore.setPagePadding();
  PageHeaderStore.resetTime();
  pageHeaderStore.resetPage();
  pageHeaderStore.replaceState(true);
});

ReactDOM.render(
  [
    <ClickToComponent />,
    <ToastContainer key="toast" className="uxstoasts" autoClose={3000} hideProgressBar closeButton={false} />,
    <Router history={customHistory} key="router" basename={process.env.PUBLIC_URL}>
      {/* <ConfigProvider locale={zhCN}> */}
      {/* Antd中文设置 */}
      <Switch>
        <Route exact path="/" component={Index} />
        <Route
          exact
          path="/weixinApply"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <Apply {...props} />
              </Suspense>
            );
          }}
        />
        {/* <Route
          exact
          path="/demo"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <Demo {...props} />
              </Suspense>
            );
          }}
        /> */}
        <Route
          exact
          path="/visual/qrcode"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <AppStart {...props} />
              </Suspense>
            );
          }}
        />
        <Route
          exact
          path="/auth/jump"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <Authorization {...props} />
              </Suspense>
            );
          }}
        />
        <Route
          exact
          path="/wx/tp/auth/jump"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <EnterpriseAuthorization {...props} />
              </Suspense>
            );
          }}
        />
        <Route
          exact
          path="/wx/tp/jump/bind"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <EnterpriseWxAuthorization {...props} />
              </Suspense>
            );
          }}
        />
        <Route
          exact
          path="/wj/detail"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <EditSurvey {...props} />
              </Suspense>
            );
          }}
        />
        <Route
          path="/product/create"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <CreateProduct {...props} />
              </Suspense>
            );
          }}
        />
        <Route
          path="/redirect"
          render={(props) => {
            return (
              <Suspense fallback={null}>
                <UXSRedirect {...props} />
              </Suspense>
            );
          }}
        />
        <Route path="/" component={Home} />
      </Switch>
      {/* </ConfigProvider> */}
    </Router>,
  ],
  document.getElementById('root')
);
