import CommonTab from '../../containers/CommonTab';
import PageOverviewTable from '../../containers/PageOverviewTable';
import CommonMixChart from '../../containers/CommonMixChart';

export default {
  title: '页面数据',
  components: [
    {
      // 调用tab组件分页
      isCard: true,
      title: '页面数据',
      component: CommonTab,
      props: {
        tab: [
          {
            name: 'VisitedPage',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,
                      type: 'pie',
                      dims: 'pageset_visited',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: false,
                      noLegendSelect: true,
                      orderColumn: 'pv',
                      needOther: true,
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,pageset_visited',
                      fields: 'pv',
                      pageCount: 10,
                      currentPage: 1,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: PageOverviewTable,
                col: 24,
                props: {
                  dims: 'pageset_visited',
                  fields: 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,exits,exitRate,isHasParam,operate',
                  tableHeadKeyForKey: true,
                  currentPage: 1,
                  orderDesc: true,
                  pageCount: 10,
                  orderColumn: 'pv',
                  expandedRowRender: true,
                },
              },
            ],
          },
          {
            name: 'EntryPage',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,
                      type: 'pie',
                      dims: 'pageset_entry',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: false,
                      noLegendSelect: true,
                      orderColumn: 'pv',
                      needOther: true,
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,pageset_entry',
                      fields: 'pv',
                      pageCount: 10,
                      currentPage: 1,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: PageOverviewTable,
                col: 24,
                props: {
                  dims: 'pageset_entry',
                  fields: 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,bounces,bounceRate,isHasParam,operate',
                  tableHeadKeyForKey: true,
                  currentPage: 1,
                  orderDesc: true,
                  pageCount: 10,
                  orderColumn: 'pv',
                  expandedRowRender: true,
                },
              },
            ],
          },
        ],
      },
    },
  ],
};
