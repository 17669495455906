import React, { Component } from 'react';
import CommonTable from '../../../CommonTable/index';
import { autorun, observable, toJS, action } from 'mobx';

import { observer } from 'mobx-react';

import TableNullData from '../../../../component/TableNullData/index';
import tableUtil from '../../../../utils/tableUtil';
import legendUtil from '../../../../utils/legendUtil';
import { currency, distributeTools } from '../../../../utils/analyseUtil';
import api from '../../../../api';
import { Tooltip } from 'antd';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import { Link } from 'react-router-dom';
import mathUtil from '../../../../utils/mathUtil';
import util from '../../../../util';
import moment from 'moment';
import Loading from '../../../../component/Loading/Loading';
import UXSRangePicker from '../../../../component/UXSRangePicker';

@observer
class DistributionTable extends Component {
  @observable cop = <TableNullData></TableNullData>;

  // 需要addcontent处理的那些fields的数组
  @observable periodArr;
  // 表格tableHeadData
  @observable tableHeadData;
  @observable isLoading;
  @action.bound addContent = (store) => {
    const config = toJS(this.periodArr).map((item) => {
      if (item.includes('page')) {
        // 把带维度的表头切成数组
        let dimArr = item.split('_');
        // 重新拼成维度名
        let dim = `${dimArr[0]}_${dimArr[1]}`;
        return {
          currentRowData: item,
          children: (store, text, record, index, item) =>
            text === '全部用户' ? (
              text
            ) : (
              <div className="childrenContent">
                <div className="rateContent">
                  <div className="rateContent-urlName" title={text}>
                    {mathUtil.spliceHttpAndDomin(text)}
                  </div>

                  <div className="rateContent-title">
                    <Link
                      to={`/data/page/detail?keyDim=${dim}&key=${encodeURIComponent(store.parentData[index][`${dim}_key`])}&name=${encodeURIComponent(text)}`}
                      title={`${store.parentData[index][`${dim}_key`]}的页面详情`}>
                      {mathUtil.spliceHttpAndDomin(store.parentData[index][`${dim}_key`])}
                    </Link>
                  </div>
                </div>
              </div>
            ),
        };
      } else if (item.includes('_key')) {
        let dimArr = item.split('_');
        let dim = dimArr.length === 3 ? `${dimArr[0]}_${dimArr[1]}` : `${dimArr[0]}`;

        return {
          currentRowData: item,
          string: (text, index, store) => {
            return store.parentData[index][`${dim}_name`];
          },
        };
      } else {
        return {
          currentRowData: item,

          style: (store, text, record, index, item) => {
            let ratio = store.parentData[index][item.currentRowData] ? store.parentData[index][item.currentRowData].ratio : 0;
            return { background: util.changeTableBgByTheme(ratio) };
          },

          children: (store, text, record, index, item) => {
            // 当前选择日期

            let parentData = store.parentData[index];
            const dimsName = store.field[0];
            const currentDim = parentData[`${dimsName}`];
            const renderContent = () => {
              return (
                <div>
                  <div>{parentData[item.currentRowData].value === '-' ? '-' : legendUtil.formateTitle('value', parentData[item.currentRowData].value)}</div>

                  <div>{legendUtil.formateTitle('ratio', parentData[item.currentRowData].ratio)}</div>
                </div>
              );
            };

            return parentData[item.currentRowData] !== undefined ? (
              index === 0 ? (
                renderContent()
              ) : (
                <Tooltip placement="top" mouseEnterDelay={0.5} arrowPointAtCenter>
                  {renderContent()}
                </Tooltip>
              )
            ) : (
              <div>
                <div>-</div>
                <div>-</div>
              </div>
            );
          },
        };
      }
    });

    tableUtil.addContentByConfig(store, config);
  };
  @action.bound distributionGetFields(data) {
    let fieldArr = distributeTools.periodFields(data);
    this.periodArr = fieldArr[1].filter((item) => item.includes('distribution_') || item.includes('_key'));
    return fieldArr;
  }
  // 判断是否是日期维度
  checkDateDim(dim) {
    return dim.join(',') === '';
  }
  // 根据父子表格传递不同参数
  getFetchParam(form, isExpand, parentData) {
    let dims = form.subDividedDimGroup;
    let fetchParam = {
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : form.customizeParam.dateObj
            ? form.customizeParam.dateObj.lastDate
              ? mathUtil.keyToDate(form.customizeParam.dateObj.lastDate)
              : form.customizeParam.dateObj.date
            : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')}`,
      ruleContent: JSON.stringify(toJS(form.ruleContent)),
      orderDesc: true,
      noDefaultFields: true,
      getFieldByRes: true,
      filterObject: JSON.stringify(toJS(form.filterObject)),
      currentPage: 1,
      pageCount: 10,
      ignoreCache: true,
      customApi: api.analysis.getMixAnalysis,
      orderColumn: `${dims === '' ? `date_${pageHeaderStore.parameter.period}` : dims}_key`,
      analysisType: 7,
      customizeParam: form.customizeParam === 'distribution_date' ? form.customizeParam : JSON.stringify(toJS(form.customizeParam)),
      // commonFilterObject: !this.props.setDateObj ? JSON.stringify(pageHeaderStore.commonFilterObject) : '',
    };

    if (isExpand) {
      fetchParam['dims'] = 'date';
      fetchParam['filters'] = [{ dim: dims[0], value: parentData[`${dims[0]}_key`], formula: '=' }];
      fetchParam['orderColumn'] = `date_${pageHeaderStore.parameter.period}_key`;
    } else {
      fetchParam['dims'] = this.checkDateDim(dims) ? 'date' : dims[0];
      fetchParam['orderColumn'] = this.checkDateDim(dims) ? `date_${pageHeaderStore.parameter.period}_key` : `${dims[0]}_key`;
    }

    return fetchParam;
  }
  // 渲染子表格
  expandedRowContent = (form, record) => {
    return (
      <CommonTable
        tableType={'analyseDistribution'}
        addContent={this.addContent}
        isSort={false}
        getFields={this.distributionGetFields}
        {...this.getFetchParam(form, true, record)}></CommonTable>
    );
  };
  // 渲染父表格
  changeCommonTable(form) {
    return (
      <CommonTable
        tableType={'analyseDistribution'}
        addContent={this.addContent}
        isSort={false}
        expandedRowRender={!this.checkDateDim(toJS(form.subDividedDimGroup))}
        expandedRowContent={(parentData) => this.expandedRowContent(form, parentData)}
        getFields={this.distributionGetFields}
        {...this.getFetchParam(form, false)}></CommonTable>
    );
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      let count = this.props.form.count;
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      // let commonFilterObject = pageHeaderStore.commonFilterObject;
      if (currency.judgeRightCondition(toJS(this.props.form.filterObject))) {
        clearInterval(this.delay);
        this.delay = setTimeout(() => {
          this.cop = this.changeCommonTable(this.props.form);
        }, 800);
      }
    });
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  render() {
    let date = `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`;
    let dateStr =
      pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
        ? pageHeaderStore.parameter.date.replace(/-/g, '/').replace(',', ' ~ ')
        : !this.props.setDateObj &&
        (this.props.form.customizeParam.dateObj
          ? this.props.form.customizeParam.dateObj.lastDate
            ? mathUtil
              .keyToDate(this.props.form.customizeParam.dateObj.lastDate)
              .replace(/-/g, '/')
              .replace(',', ' ~ ')
            : this.props.form.customizeParam.dateObj.date.replace(/-/g, '/').replace(',', ' ~ ')
          : date.replace(/-/g, '/').replace(',', ' ~ '));

    return (
      <React.Fragment>
        {this.props.setDateObj ? (
          this.props.isLoading ? (
            <Loading size="small" />
          ) : (
            <UXSRangePicker
              useDateByStore={false}
              getDateAndlastDate={this.props.setDateObj}
              date={this.props.form.customizeParam.dateObj ? this.props.form.customizeParam.dateObj.date : date}
              lastDate={this.props.form.customizeParam.dateObj ? this.props.form.customizeParam.dateObj.lastDate : ''}
              isBoardPicker={true}
            />
          )
        ) : (
          <p className="board-chart-date">{dateStr}</p>
        )}
        {this.cop}
      </React.Fragment>
    );
  }
}
export default DistributionTable;
