import React, { Fragment, Component } from 'react';
import { Select, InputNumber, Radio, Input, Cascader, TimePicker, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import intl from 'react-intl-universal';
import util from '../../../util';
import api from '../../../api';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import Loading from '../../../component/Loading/Loading';
import ChildrenFilter from '../../PageHeaderFilters/Setting/Setting/ChildrenFilter';
import moment from 'moment';
import ColorSelector from '../../../component/ColorSelector';

const { Option } = Select;
const inviteLimitDaysList = [
  {
    value: 7,
    label: '7天内', //'分钟',
  },
  {
    value: 15,
    label: '15天内', //'分钟',
  },
  {
    value: 30,
    label: '30天内', //'分钟',
  },
  {
    value: 60,
    label: '60天内', //'分钟',
  },
  {
    value: 90,
    label: '90天内', //'分钟',
  },
  {
    value: 'custom',
    label: '自定义', //'分钟',
  },
];
const inviteDays = [7, 15, 30, 60, 90];
const surveyAction = [
  {
    value: 'unlimit',
    label: 'Unlimit',
  },
  {
    value: 'invited',
    label: 'Invited',
  },
  {
    value: 'opened',
    label: 'Opened',
  },
  {
    value: 'answered',
    label: 'Answered',
  },
];
const limitTypes = [
  {
    value: 'system',
    label: 'Participatedinanyinvestigationofthesystem',
  },
  {
    value: 'survey',
    label: 'Participateinthisquestionnairesurvey',
  },
];
@observer
class Disturb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitTimeMode: '',
      limitDays: this.props.value.inviteLimitDays
        ? inviteDays.indexOf(this.props.value.inviteLimitDays) < 0
          ? 'custom'
          : this.props.value.inviteLimitDays
        : null,
      disturbWarning: '',
    };
  }
  componentDidMount() {
    this.props.value.disturbModel && this.setDisturbModel(this.props.value.disturbModel);
  }
  @observable disturbModel;
  @observable showCustomDisturb = false;
  @action.bound setDisturbModel(e) {
    this.disturbModel = e;
  }
  disturbModelChange = (e) => {
    const disturbModel = e.target.value;
    this.triggerChange({ disturbModel });
    disturbModel === 'custom' && this.setDisturbModel(disturbModel);
  };
  disturbStatusChange = (status) => {
    this.triggerChange({ status });
  };
  disturbNumChange = (value, key) => {
    this.triggerChange({ [`${key}num`]: value });
  };
  limitDaysChange = (inviteLimitDays) => {
    this.setState({ limitDays: inviteLimitDays });
    this.triggerChange({ inviteLimitDays: inviteLimitDays === 'custom' ? '' : inviteLimitDays });
  };
  limitDaysNumberChange = (inviteLimitDays) => {
    this.triggerChange({ inviteLimitDays });
  };
  inviteLimitPushCountChange = (inviteLimitPushCount) => {
    this.triggerChange({ inviteLimitPushCount });
  };
  inviteDateIntervalChange = (inviteDateInterval) => {
    this.triggerChange({ inviteDateInterval });
  };
  inviteLimitSurveyActionChange = (inviteLimitSurveyAction) => {
    let { value, onChange } = this.props;
    if (inviteLimitSurveyAction === 'unlimit') {
      onChange({
        inviteLimitSurveyAction,
      });
      util.post(
        api.trigger.checkDisturbConfig,
        {
          surveyId: this.props.form.getFieldValue('surveyId'),
          disturbConfig: JSON.stringify({
            ...value,
            msgRepeatAnswer: this.props.form.getFieldValue('msgRepeatAnswer') === 'true' ? true : false,
            msgValidDays: this.props.form.getFieldValue('msgValidDays'),
          }),
        },
        (res) => {
          if (res.data) {
            this.setState({
              disturbWarning: res.data,
            });
          }
        }
      );
    } else if (inviteLimitSurveyAction === 'invited') {
      delete value.inviteLimitPushCount;
      delete value.inviteDateInterval;
      onChange({
        ...value,
        inviteLimitSurveyAction,
      });
      this.setState({
        disturbWarning: '',
      });
    } else {
      this.triggerChange({ inviteLimitSurveyAction, inviteLimitPushCount: 1, inviteDateInterval: 1 });
      this.setState({
        disturbWarning: '',
      });
    }
  };
  inviteLimitScopeChange = (inviteLimitScope) => {
    let { value } = this.props;
    delete value.inviteLimitRelateSurvey;
    this.setState({ limitDays: null });
    inviteLimitScope === 'survey' && (value.inviteLimitRelateSurvey = []);
    this.triggerChange({ ...value, inviteLimitScope, inviteLimitDays: null });
  };
  inviteLimitRelateSurveyChange = (inviteLimitRelateSurvey) => {
    this.triggerChange({ inviteLimitRelateSurvey });
  };
  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  render() {
    const { value, disabled } = this.props;
    const { limitDays, disturbWarning } = this.state;
    return (
      <div className="filter-box survey-filter">
        <div>
          <Select
            className="md-select"
            value={value.inviteLimitSurveyAction}
            onChange={this.inviteLimitSurveyActionChange}
            style={{ marginRight: 16 }}
            disabled={disabled}>
            {surveyAction.map((item) => {
              return (
                <Option value={item.value} key={item.value}>
                  {intl.get(item.label) || item.label}
                </Option>
              );
            })}
          </Select>
          {disturbWarning && <div style={{ color: '#f5222d' }}>{disturbWarning}</div>}
          {value.inviteLimitSurveyAction !== 'unlimit' && (
            <Select className="lg-select" value={value.inviteLimitScope} onChange={this.inviteLimitScopeChange} disabled={disabled}>
              {limitTypes.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {intl.get(item.label) || item.label}
                  </Option>
                );
              })}
            </Select>
          )}
        </div>
        {value.inviteLimitScope === 'survey' && (
          <Select
            mode="multiple"
            allowClear
            placeholder={intl.get('Pleaseselectthequestionnaire')}
            className="lg-select survey-select"
            value={value.inviteLimitRelateSurvey}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={this.inviteLimitRelateSurveyChange}
            disabled={disabled}>
            {this.props.mySurveyList &&
              this.props.mySurveyList.map((item, index) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.surveyNameAndRemark}
                  </Option>
                );
              })}
          </Select>
        )}
        {value.inviteLimitSurveyAction !== 'unlimit' && (
          <div>
            <Select style={{ marginRight: '8px' }} className="sm-select" value={limitDays} onChange={this.limitDaysChange} disabled={disabled}>
              {inviteLimitDaysList.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {intl.get(item.label) || item.label}
                  </Option>
                );
              })}
            </Select>
            {limitDays === 'custom' && (
              <Fragment>
                <InputNumber value={value.inviteLimitDays} onChange={this.limitDaysNumberChange} min={1} disabled={disabled} />
                <span style={{ marginLeft: 8 }}>天内</span>
              </Fragment>
            )}
            <span style={{ marginLeft: 8 }}>不再邀请</span>
          </div>
        )}
        {value.inviteLimitSurveyAction !== 'unlimit' && value.inviteLimitSurveyAction !== 'invited' && value.inviteLimitDays && (
          <div>
            若不满足以上机制，{value.inviteLimitDays}天内可重复邀请次数
            <InputNumber
              value={value.inviteLimitPushCount}
              onChange={this.inviteLimitPushCountChange}
              min={1}
              style={{ margin: '0 16px' }}
              disabled={disabled}
            />
            ，间隔
            <InputNumber value={value.inviteDateInterval} onChange={this.inviteDateIntervalChange} min={1} style={{ margin: '0 16px' }} disabled={disabled} />天
          </div>
        )}
      </div>
    );
  }
}

const timeObj = [
  {
    value: 'unlimit',
    label: 'NotSpecifiedHoursAndMinutes',
    // 不延时
  },
  {
    value: 'custom',
    label: 'SpecifiedHoursAndMinutes',
    // 延时
  },
];
const limitTimeModels = [
  {
    value: 'unlimit',
    label: 'AllDay',
    // 全天
  },
  {
    value: 'custom',
    label: 'Periodoftime',
    // 每天时间段
  },
];
const unitObj = [
  {
    value: 'min',
    label: 'NumberofMinutes', //'分钟',
  },
  {
    value: 'hour',
    label: 'NumberofHours', //'小时',
  },
  {
    value: 'day',
    label: 'NumberofDays', //'天',
  },
  {
    value: 'week',
    label: 'NumberofWeeks', //'周',
  },
  {
    value: 'month',
    label: 'NumberofMonths', //'月',
  },
];

@observer
class TriggerTime extends Component {
  constructor() {
    super();
    this.state = {
      limitTimeModel: '',
      inviteDelayType: null,
    };
  }
  componentDidMount() {
    const { value } = this.props;
    value.inviteTimeRange &&
      this.setState({
        limitTimeModel: value.inviteTimeRange === 'unlimit' ? 'unlimit' : 'custom',
        inviteDelayType: value.inviteDelay && value.inviteDelay !== 'unlimit' ? 'custom' : 'unlimit',
      });
  }
  timeChange = (inviteDelay) => {
    inviteDelay === 'unlimit' ? this.triggerChange({ inviteDelay }) : this.triggerChange({ inviteDelay: null, inviteDelayVal: null });
    this.setState({
      inviteDelayType: inviteDelay,
    });
  };
  customUnitChange = (inviteDelay) => {
    this.triggerChange({ inviteDelay });
  };
  customTimeChange = (inviteDelayVal) => {
    this.triggerChange({ inviteDelayVal });
  };
  limitTimeModelChange = (limitTimeModel) => {
    this.setState({
      limitTimeModel,
    });
    this.triggerChange({ inviteTimeRange: limitTimeModel === 'unlimit' ? limitTimeModel : null });
  };
  inviteTimeRangeChange = (timeString, index) => {
    let { inviteTimeRange } = this.props.value;
    !Array.isArray(inviteTimeRange) && (inviteTimeRange = []);
    inviteTimeRange[index] = timeString;
    this.triggerChange({ inviteTimeRange });
  };
  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  render() {
    const { value, disabled } = this.props;
    const { limitTimeModel, inviteDelayType } = this.state;
    return (
      <Fragment>
        <div className="invite-time">
          <Select
            className="md-select"
            onChange={this.limitTimeModelChange}
            value={limitTimeModel || null}
            placeholder={intl.get('Pleaseselect')}
            disabled={disabled}>
            {limitTimeModels.map((item) => {
              return (
                <Option value={item.value} key={item.value}>
                  {intl.get(item.label)}
                </Option>
              );
            })}
          </Select>
          {limitTimeModel === 'custom' && (
            <Fragment>
              <TimePicker
                value={Array.isArray(toJS(value.inviteTimeRange)) && value.inviteTimeRange[0] ? moment(value.inviteTimeRange[0], 'HH:mm:ss') : null}
                onChange={(time, timeString) => this.inviteTimeRangeChange(timeString, 0)}
                placeholder={intl.get('Pleaseselect')}
                disabled={disabled}
              />
              <span style={{ marginLeft: 8 }}>至</span>
              <TimePicker
                value={Array.isArray(toJS(value.inviteTimeRange)) && value.inviteTimeRange[1] ? moment(value.inviteTimeRange[1], 'HH:mm:ss') : null}
                onChange={(time, timeString) => this.inviteTimeRangeChange(timeString, 1)}
                placeholder={intl.get('Pleaseselect')}
                disabled={disabled}
              />
            </Fragment>
          )}
        </div>
        <div>
          <Select className="md-select" onChange={this.timeChange} value={inviteDelayType} placeholder={intl.get('Pleaseselect')} disabled={disabled}>
            {timeObj.map((item) => {
              return (
                <Option value={item.value} key={item.value}>
                  {intl.get(item.label)}
                </Option>
              );
            })}
          </Select>
          {inviteDelayType === 'custom' && (
            <Fragment>
              <Select
                style={{ marginLeft: '8px' }}
                className="sm-select"
                value={value.inviteDelay === 'unlimit' ? null : value.inviteDelay}
                onChange={this.customUnitChange}
                disabled={disabled}>
                {unitObj.map((item) => {
                  return (
                    <Option value={item.value} key={item.value}>
                      {intl.get(item.label)}
                    </Option>
                  );
                })}
              </Select>
              <InputNumber
                value={value.inviteDelayVal}
                onChange={this.customTimeChange}
                className="xs-input"
                style={{ marginLeft: '8px', lineHeight: '1.5' }}
                min={0}
                disabled={disabled}
              />
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}
@observer
class EventSelect extends Component {
  constructor(props) {
    super(props);
    this.valueGroup = {};
    this.state = {
      options: [],
      logic: props.value.eventConfigFilter.rules[0].logic,
      reloadCount: 0,
    };
  }
  componentDidMount() {
    util.post(api.pack.dimOptions, { idsite: PageHeaderStore.parameter.idsite, dim: 'analysis_default' }, (res) => {
      let optionItem = {
        value: 'default',
        label: '预置事件',
        children: [],
      };
      res.data && this.setOptions(optionItem, res.data, 'default');
    });
    util.post(api.pack.dimOptions, { idsite: PageHeaderStore.parameter.idsite, dim: 'analysis_customize' }, (res) => {
      let optionItem = {
        value: 'customize',
        label: '埋点事件',
        children: [],
      };
      res.data && Object.keys(res.data).length && this.setOptions(optionItem, res.data, 'customize');
    });
  }
  setOptions = (item, data, group) => {
    const defaultEvents = ['$Forewarning', '访问'];
    let valueGroup = {};
    for (let key in data) {
      if (defaultEvents.indexOf(key) >= 0 || group !== 'default') {
        item.children.push({ value: key, label: data[key] });
        valueGroup[key] = group;
      }
    }
    let secondFlag = false;
    if (JSON.stringify(this.valueGroup) !== JSON.stringify({})) {
      secondFlag = true;
    }
    const valueGroupCopy = {
      ...this.valueGroup,
      ...valueGroup,
    };
    this.valueGroup = {
      ...valueGroupCopy,
    };
    if (secondFlag) {
      let hasEvent = false;
      for (let key in valueGroupCopy) {
        key === this.props.value.eventName && (hasEvent = true);
      }
      if (!hasEvent) {
        this.dimChange([null, null]);
      }
    }
    this.setState({
      options: [...this.state.options, item],
    });
  };
  changeFilterLogic = () => {
    this.setState({
      logic: this.state.logic === 'and' ? 'or' : 'and',
    });
  };
  dimChange = (value) => {
    this.triggerChange({
      eventName: value[1],
      eventConfigFilter: {
        type: 'rules_event',
        logic: 'and',
        rules: [
          {
            logic: 'and',
            type: 'rules_event',
            rules: [],
          },
        ],
      },
    });
    this.setState({
      reloadCount: this.state.reloadCount + 1,
    });
  };

  setChildrenFilter = (filter, index) => {
    let rules = [];
    filter.forEach((item) => {
      item = {
        ...item,
        dimName: item.dimValueName,
        dimValueName: `${this.props.value.eventName}_${this.valueGroup[this.props.value.eventName]}`,
        rulesType: 'fit',
        dateRange: 'startToToday',
        target: '',
        targetName: '',
      };
      rules.push({
        conditions: [item],
        type: 'rule_filter',
      });
    });
    let eventConfigFilter = {
      type: 'rules_event',
      logic: 'and',
      rules: [
        {
          logic: 'and',
          type: 'rules_event',
          rules: rules,
        },
      ],
    };
    this.triggerChange({ eventConfigFilter });
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  getFilters = (eventConfigFilter) => {
    let filters = [];
    eventConfigFilter.rules[0].rules.forEach((item) => {
      let conditions = item.conditions;
      const filterItem = {
        ...conditions[0],
        dimValueName: conditions[0].dimName,
      };
      filters.push(filterItem);
    });
    return filters;
  };
  render() {
    const { value, disabled } = this.props;
    const { options, logic, reloadCount } = this.state;
    const filters = this.getFilters(value.eventConfigFilter);
    return (
      <Fragment>
        {options.length ? (
          <Cascader
            options={options}
            placeholder="请选择"
            onChange={this.dimChange}
            value={value.eventName ? [this.valueGroup[value.eventName], value.eventName] : null}
            className="md-select"
            displayRender={(label) => {
              return label[label.length - 1];
            }}
            disabled={disabled}
          />
        ) : (
          <Loading size="small" />
        )}
        {value.eventName && (
          <ChildrenFilter
            filterCount={0}
            isShow={true} //判断是否显示
            type={'default'} //type
            eventValue={value.eventName ? [{ dim: 'event', type: this.valueGroup[value.eventName], dimValue: value.eventName }] : [{}]}
            filter={filters.length ? [filters] : [[]]} //二维数组
            changeFilterLogic={this.changeFilterLogic}
            filterItemLogic={[logic]} //数组
            hasPage={false} //是否页面访问
            needLogic={true}
            setChildrenFilter={this.setChildrenFilter}
            analysisType={0}
            reloadCount={reloadCount}
            disabled={disabled}
          />
        )}
      </Fragment>
    );
  }
}

const msgList = [
  {
    value: 'msgValidDays',
    label: '天数',
  },
  {
    value: 'msgValidCounts',
    label: '次数',
  },
];

const formatterState = (props) => {
  const { msgValidDays, msgValidCounts } = props.disturb || {};
  const value = msgValidDays || msgValidCounts;
  return {
    msgValidLimit: value === 'unlimit' ? 'unlimit' : 'custom',
    msgValidType: msgValidCounts ? 'msgValidCounts' : 'msgValidDays',
    customValue: value === 'unlimit' ? 1 : value,
  };
};

@observer
class MsgValidDays extends Component {
  constructor(props) {
    super(props);
    this.state = formatterState(this.props.value);
  }
  msgValidLimitChange = (limit) => {
    this.setState({
      msgValidLimit: limit,
    });
    if (limit === 'unlimit') {
      this.triggerChange({
        limit: limit,
        value: 1,
        type: this.state.msgValidType,
      });
    } else {
      this.setState({
        customValue: 1,
      });
      this.triggerChange({
        limit: limit,
        value: 1,
        type: this.state.msgValidType,
      });
    }
  };
  msgValidDaysNumberChange = (customValue) => {
    this.setState({
      customValue,
    });
    this.triggerChange({
      limit: 'custom',
      value: customValue,
      type: this.state.msgValidType,
    });
  };
  msgValidTypeChange = (msgValidType) => {
    this.setState({
      msgValidType,
    });
    this.triggerChange({
      limit: this.state.msgValidLimit,
      value: this.state.customValue,
      type: msgValidType,
    });
  };
  triggerChange = (obj) => {
    const { onChange } = this.props;
    let newObj = {};
    // 格式化数据 { msgValidxxx: value }
    if (obj.limit === 'unlimit') {
      // 不限制
      newObj = { [obj.type]: 'unlimit' };
    } else {
      // 自定义
      newObj = { [obj.type]: obj.value };
    }
    if (onChange) {
      onChange(newObj);
    }
  };
  render() {
    const { disabled } = this.props;
    const { msgValidType, customValue, msgValidLimit } = this.state;
    return (
      <Fragment>
        {
          <Select style={{ marginLeft: '8px' }} className="sm-select" value={msgValidLimit} onChange={this.msgValidLimitChange} disabled={disabled}>
            <Option value="unlimit"> 不限制 </Option>
            <Option value="custom"> 自定义 </Option>
          </Select>
        }
        {msgValidLimit === 'custom' && <InputNumber onChange={this.msgValidDaysNumberChange} value={customValue} min={1} disabled={disabled} />}
        {
          <Select style={{ marginLeft: '8px' }} className="sm-select" value={msgValidType} onChange={this.msgValidTypeChange} disabled={disabled}>
            {msgList.map((item) => {
              return (
                <Option value={item.value} key={item.value}>
                  {intl.get(item.label) || item.label}
                </Option>
              );
            })}
          </Select>
        }
      </Fragment>
    );
  }
}

const fixedPageTypes = [
  {
    value: 'all',
    label: '全站',
  },
  {
    value: 'page',
    label: '固定页面',
  },
];
const placementList = [
  {
    value: 'right',
    label: '右侧',
  },
  {
    value: 'left',
    label: '左侧',
  },
];
const urlFormulaList = [
  {
    value: 'equal',
    label: '等于',
  },
  {
    value: 'notEqual',
    label: '不等于',
  },
  {
    value: 'contain',
    label: '包含',
  },
  {
    value: 'notContain',
    label: '不包含',
  },
  {
    value: 'startWith',
    label: '开始于',
  },
  {
    value: 'endWith',
    label: '结束于',
  },
];
class FixedSurveyDisplay extends Component {
  typeChange = (type) => {
    this.triggerChange({ type });
  };
  valueChange = (changedValue) => {
    this.triggerChange(changedValue);
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { value, disabled } = this.props;
    const { type, placement, url, formula } = value;
    return (
      <Fragment>
        <div>
          <Radio.Group
            className="fixed-survey-display"
            value={type}
            onChange={(e) => {
              this.typeChange(e.target.value);
            }}
            disabled={disabled}>
            {fixedPageTypes.map((item) => {
              return (
                <Radio.Button value={item.value} key={item.value}>
                  {item.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
        {type === 'page' && (
          <Fragment>
            <Select
              className="sm-select"
              value={formula}
              style={{ marginRight: 8 }}
              onChange={(value) => {
                this.valueChange({ formula: value });
              }}
              disabled={disabled}>
              {urlFormulaList.map((item) => {
                return <Select.Option key={item.value}>{item.label}</Select.Option>;
              })}
            </Select>
            <Input
              className="md-input"
              value={url}
              placeholder="请输入URL"
              onChange={(e) => {
                this.valueChange({ url: e.target.value });
              }}
              disabled={disabled}
            />
          </Fragment>
        )}
        <div>
          <Radio.Group
            className="fixed-survey-display"
            value={placement}
            onChange={(e) => {
              this.valueChange({ placement: e.target.value });
            }}
            disabled={disabled}>
            {placementList.map((item) => {
              return (
                <Radio.Button value={item.value} key={item.value}>
                  {item.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
      </Fragment>
    );
  }
}

class FixedSurveyStyle extends Component {
  valueChange = (changedValue) => {
    this.triggerChange(changedValue);
    this.props.btnStyleChange(changedValue);
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { value, disabled } = this.props;
    const { backgroundColor, color, borderColor } = value;
    return (
      <Fragment>
        <div className="fixed-survey-style">
          <div className="fixed-survey-style-label">背景色</div>
          <ColorSelector
            className="setUpColorInput"
            onChange={(e) => this.valueChange({ backgroundColor: e.rgb })}
            value={backgroundColor}
            placement="bottomLeft"
            disabled={disabled}></ColorSelector>
          <div className="fixed-survey-style-label">边框色</div>
          <ColorSelector
            className="setUpColorInput"
            onChange={(e) => this.valueChange({ borderColor: e.rgb })}
            value={borderColor}
            placement="bottomLeft"
            disabled={disabled}></ColorSelector>
          <div className="fixed-survey-style-label">文字颜色</div>
          <ColorSelector
            className="setUpColorInput"
            onChange={(e) => this.valueChange({ color: e.rgb })}
            value={color}
            placement="bottomLeft"
            disabled={disabled}></ColorSelector>
        </div>
      </Fragment>
    );
  }
}
export { Disturb, TriggerTime, EventSelect, MsgValidDays, FixedSurveyDisplay, FixedSurveyStyle };
