import React, { Component } from 'react';
import { Modal, Form, Select, Input, message } from 'antd';
import PageHeaderStore from '../../../PageHeader/PageHeaderStore';
import api from '../../../../api';
import util from '../../../../util';
import SidebarStore from '../../store';
import './index.sass';

class FolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      sidebarMenuList: null,
      showPlaceHolder: true,
    };
  }

  componentDidMount() {
    const { currentFolderItem } = this.props;
    if (currentFolderItem && currentFolderItem.hasOwnProperty('share')) {
      this.setState({
        showPlaceHolder: false,
      });
    }
  }

  submit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        SidebarStore.dashboardType !== 1 && (values.dashboardType = SidebarStore.dashboardType);
        let param = {
          name: values.name,
          idsite: PageHeaderStore.parameter.idsite,
          // share: values.share == 2 ? true : false,
          type: SidebarStore.dashboardType,
        };
        this.props.currentFolderItem && (param.id = this.props.currentFolderItem.id);
        util.post(api.folder.saveOrUpdate, param, (res) => {
          res.code === 200 ? (this.props.onCancel(), this.props.getFolderDashboardList()) : message.error(res.msg);
        });
      }
    });
  };

  onChange = () => {
    const { showPlaceHolder } = this.state;
    if (showPlaceHolder) {
      this.setState({
        showPlaceHolder: false,
      });
    }
  };

  render() {
    const typeList = [
      {
        value: 1,
        label: '自建看板',
      },
      {
        value: 2,
        label: '共享看板',
      },
    ];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    const { currentFolderItem } = this.props;
    const { showPlaceHolder } = this.state;

    return (
      <Modal
        title={`${currentFolderItem ? '编辑' : '新建'}文件夹`}
        visible={this.props.visible}
        onOk={this.submit}
        onCancel={this.props.onCancel}
        okText="保存"
        cancelText="取消">
        <Form {...formItemLayout}>
          <Form.Item label="名称：">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入名称!' }],
              initialValue: currentFolderItem ? currentFolderItem.name : null,
            })(<Input className="md-input" placeholder="请输入名称" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(FolderModal);
