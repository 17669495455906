import React from 'react';
import { Modal, Form, Input } from 'antd';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import util from '../../../util';
import api from '../../../api';
import intl from 'react-intl-universal';

function CopyModal(props) {
  const { show, editInfo, onHide, form, fetchData } = props;
  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  function submit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const param = {
          idsite: PageHeaderStore.parameter.idsite,
          id: editInfo.editId,
          name: values.name,
        };
        console.log(param);
        util.post(api.subscription.copy, param, (res) => {
          onHide();
          fetchData();
        });
      }
    });
  }
  return (
    <Modal
      title="复制订阅"
      width={500}
      destroyOnClose
      visible={show}
      onCancel={onHide}
      okText={intl.get('Confirm')}
      cancelText={intl.get('Cancel')}
      onOk={submit}
    >
      <Form id="addborad" {...formItemLayout}>
        <Form.Item label="订阅名称：">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '请输入名称' }],
            initialValue: editInfo.name || null,
          })(<Input placeholder="请输入" className="md-input" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Form.create()(CopyModal);
