import IntroJs from 'intro.js/intro';
import { action, computed, observable, when, toJS } from 'mobx';
import { message } from 'antd';
import api from '../../api';
import util from '../../util';
import pageUtil from '../../utils/pageUtil';
import SelectStore from './../EventSelect/store';
import intl from 'react-intl-universal';
import loginStore from '../../Personal/Login/store';
import NavbarTopStore from '../NavbarTop/store';
import { getMappingLinkage, processLinkage } from '../../containers/BIChart/util';
import { DEFAULT_SPLITER } from '../../containers/BIChart/constants';
import BIStore from '../../containers/BIChart/store';
import { getFormatControl } from './Control/Config/util';

class Store {
  constructor() {
    //idsite必须等待product list加载完成后比较localstorage存储的idsite值是否有效才能赋值
    //this.parameter.idsite = parseInt(util.getQueryString('idsite'), 10) || parseInt(localStorage && localStorage.getItem('idsite'), 10) || '';
    this.parameter.idsite = null;
    // this.selectDateRange = util.getQueryString('date') || (localStorage && localStorage.getItem('date'));
    // this.selectLastDate = localStorage && localStorage.getItem('lastDate');
    // this.parameter.date = this.selectDateRange && this.selectDateRange.length === 21 ? this.selectDateRange : util.getLast30();
    this.parameter.date = util.getLast30();
    this.parameter.period = util.getQueryString('period') || (localStorage && localStorage.getItem('period')) || 'day';
    this.parameter.conditions = null;
    // this.parameter.lastDate = this.selectLastDate ? this.selectLastDate : this.selectDateRange && this.selectDateRange.length === 21 ? null : 'last30';
    this.parameter.lastDate = 'last30';
    this.parameter.userGroupId = '';
    this.lastPageheaderHeight = 0;
    this.parameter.name = '';
    // !this.selectLastDate && !this.selectDateRange && localStorage && localStorage.setItem('lastDate', 'last30');
  }

  @observable isShowMore = false;
  @observable isExportShow = false;
  @observable isPageHeaderShow = false;
  @observable dropdownDisabled = false; //pageheader里面的下拉菜单是否disabled
  // @observable isRestorePosition = true;

  @observable conditionsList;

  @observable data;
  @observable productCreateTime;

  @observable boardId;
  @observable boardList;
  @observable boardDetail;

  @observable
  parameter = {
    idsite: '',
    date: '',
    period: 'day',
    userGroupId: '',
    conditions: null,
    lastDate: null,
  };
  @observable productType = '';
  @observable industry;

  @observable exports = { type: '', idsite: this.parameter.idsite, date: '', period: '' };
  @observable boardGuide = { isShowGuide: false };
  @observable configure = { isShowConfigure: false };
  @observable boardButton = { isAddButtonShow: false, isAddDeleteShow: false };
  @observable exportButton = { isShow: false, config: null };

  @observable exportParameter = { id: null, layout: null };
  @observable isQuickFilters = false;
  @observable isShowMobileQuickFilter = false;
  //是否显示看板说明
  @observable isShowDesc = true;
  @action.bound
  setShowDesc(bool) {
    this.isShowDesc = bool;
    localStorage && localStorage.setItem('isShowDesc', bool);
  }
  //看板全屏
  @observable boardChartFullScreen = false;
  @action.bound
  setBoardChartFullScreen(bool) {
    this.boardChartFullScreen = bool;
  }
  //添加数据弹窗
  @observable addBoardCard = { show: false, type: '' };
  @action.bound
  setAddBoardCard(show, type) {
    this.addBoardCard = { show, type };
  }

  // 下载看板
  exportDashboard(type) {
    const param = {
      name: this.currentBoardName,
      fileType: type,
      captureUrl: window.location.href,
      type: 'capture',
    };
    util.post(api.download.submit, param, (res) => {
      res.code === 200 && (message.success('下载任务创建成功'), NavbarTopStore.getDownloadList());
    });
  }

  // 看板id
  currentBoardId = '';
  @observable currentBoardName = '';

  setCurrentBoardId(id) {
    this.currentBoardId = id;
    if (util.showNewContorl() && !util.isMobile()) {
      this.setControllerList();
    }
  }
  cleanCurrentBoardId() {
    this.currentBoardId = '';
  }

  @observable widgets = [];
  setWidgets() {
    util.get(`${api.BI.widgets}?projectId=1`, null, (res) => {
      this.widgets = res.data;
    });
  }

  @action.bound
  setCurrentBoardName(name) {
    this.currentBoardName = name;
  }

  // 全局控制器弹窗
  @observable isShowGlobalControlModal = false;
  @action.bound
  showGlobalControlModal(bool) {
    this.isShowGlobalControlModal = bool;
  }

  @observable isShowNewGlobalControlModal = false;
  @action.bound
  showNewGlobalControlModal(bool) {
    this.isShowNewGlobalControlModal = bool;
  }

  // 布局设置弹窗
  @observable isShowLayoutModal = false;
  @action.bound
  showLayoutModal(bool) {
    this.isShowLayoutModal = bool;
  }
  @observable currentDashboardLayout = true;
  @action.bound
  setCurrentDashboardLayout(bool) {
    this.currentDashboardLayout = bool;
  }

  @observable controllerList = [];
  @action.bound
  setControllerList() {
    this.setCurrentBoardName('');
    util.get(api.globalControl.list, { id: this.currentBoardId }, (res) => {
      if (res) {
        // 看板权限
        let { edit, isDelete, share, name } = res;
        this.setAuthorityObject({ edit, isDelete, share });
        this.setCurrentBoardName(name);
        pageUtil.setDocumentTitle(`${name}-洞察`);
        // 全局控制器配置
        let controllerList = getFormatControl(JSON.parse(res.config));
        this.controllerList = controllerList;
        this.setCurrentDashboard(controllerList, res.queryMode);
        this.setLinkageConfig(res.linkConfig ? JSON.parse(res.linkConfig) : []);
      }
    });
  }

  @observable currentDashboard = {};
  @action.bound
  setCurrentDashboard(controllerList, queryMode) {
    const currentDashboard = {
      config: {
        filters: [...controllerList],
        queryMode,
      },
    };
    this.currentDashboard = { ...currentDashboard };
  }

  //分享看板弹窗
  @observable isShowShareModal = false;
  @action.bound
  setShowShareModal(bool) {
    this.isShowShareModal = bool;
  }
  //看板权限，分享等
  @observable authorityObject = {};
  @action.bound
  setAuthorityObject(data) {
    this.authorityObject = data;
  }
  @observable reloadBoardCount = 0;
  @action.bound
  reloadBoard() {
    this.reloadBoardCount++;
  }

  //是否使用PageHeader时间，看板专用
  @observable isUseParameterDate = !!util.getQueryString('isUseParameterDate') || false;
  @action.bound
  setUseParameterDate(bool, isInit) {
    this.isUseParameterDate = bool;
    bool
      ? !isInit && ((this.parameter.date = ''), (this.parameter.lastDate = null), (this.parameter.period = ''))
      : (this.parameter.date || this.parameter.lastDate || this.parameter.period) && this.reloadBoard(); //展开则设置日期为空，收起则刷新报告板;
    this.setPagePadding();
  }
  @action.bound
  resetParameterDate() {
    !this.parameter.date && !this.parameter.lastDate && this.setUseParameterDate(false);
    !this.parameter.date &&
      !this.parameter.lastDate &&
      ((this.parameter.date = this.selectDateRange && this.selectDateRange.length === 21 ? this.selectDateRange : util.getLastWeek()),
      (this.parameter.period = util.getQueryString('period') || (localStorage && localStorage.getItem('period')) || 'day'));
  }
  @action.bound
  setQuickFilters(bool) {
    this.isQuickFilters = bool;
  }
  @action.bound
  mobileQuickFilterToggle() {
    this.isShowMobileQuickFilter = !this.isShowMobileQuickFilter;
  }
  //时间控件是否可折叠，看板专用
  @observable isFoldPicker = false;
  @action.bound
  setFoldPicker(bool) {
    this.isFoldPicker = null;
    this.isFoldPicker = bool;
    !util.getQueryString('downloadMode') && bool && this.changePeriod('default');
  }

  // 全局控制器相关
  @observable
  commonFilterObject = util.getQueryString('commonFilterObject') ? JSON.parse(util.getQueryString('commonFilterObject')) : [];
  @action.bound
  setCommonFilter(commonFilterObject) {
    this.commonFilterObject = [...commonFilterObject];
  }
  @observable globalControlFilterCount = 0;
  @action.bound
  setGlobalControlFilterCount(count) {
    this.globalControlFilterCount = count;
  }
  @action.bound
  getCommonFilter(id) {
    let filters = [];
    let linkKeys = [];
    const currentLinkager = this.currentLinkager[id];
    if (currentLinkager) {
      const currentLinkagerFilter = currentLinkager.filters;
      for (const key in currentLinkagerFilter) {
        filters = filters.concat(toJS(currentLinkagerFilter[key]));
        currentLinkagerFilter[key].forEach((item) => {
          linkKeys.push(item.name);
        });
      }
    }

    this.commonFilterObject.forEach((item) => {
      if (item.relatedCards.includes(id)) {
        if (item.value[item.relatedViews[id]]) {
          item.value[item.relatedViews[id]].forEach((e) => {
            !linkKeys.includes(e.name) && filters.push(e);
          });
        }
      }
    });
    return JSON.stringify(filters);
  }
  @observable commonFilterValue = [];
  @action.bound
  setCommonFilterValue(commonFilterObject) {
    this.commonFilterValue = [...commonFilterObject];
  }
  @observable reloadGlobalContolCount = 0;
  @action.bound
  reloadGlobalContol() {
    this.reloadGlobalContolCount++;
  }

  // 卡片联动相关
  @observable linkageConfig = [];
  @action.bound setLinkageConfig(data) {
    // let linkageConfig = {};
    // data.forEach((item) => {
    //   linkageConfig[item.trigger[0]] ? linkageConfig[item.trigger[0]].push(item) : (linkageConfig[item.trigger[0]] = [item]);
    // });
    this.linkageConfig = data;
  }
  // 判断当前操作是否触发联动
  @action.bound checkLinkageConfig = (id, triggerData) => {
    return this.linkageConfig.find((item) => {
      const triggerKey = item.trigger[1].split(DEFAULT_SPLITER)[0];
      const idEqual = parseInt(item.trigger[0]) === id;
      if (idEqual && triggerData && !triggerData.length) {
        BIStore.setIsLinkingWidget(id, false);
        this.cleanCurrentLinkager(id);
      }
      return triggerData ? idEqual && triggerData.length && typeof triggerData[0][triggerKey] !== 'undefined' : idEqual;
    });
  };
  // 返回联动触发字段
  getLinkageTriggerList = (id) => {
    const linkageTriggerList = [];
    this.linkageConfig.forEach((item) => {
      const idEqual = parseInt(item.trigger[0]) === id;
      if (idEqual) {
        const triggerKey = item.trigger[1].split(DEFAULT_SPLITER)[0];
        linkageTriggerList.push(triggerKey);
      }
    });
    return linkageTriggerList;
  };
  @action.bound getLinkageRemark = (id) => {
    const LinkageRemark = {};
    this.linkageConfig.forEach((item) => {
      const linkagerId = parseInt(item.linkager[0]);
      if (parseInt(item.trigger[0]) === id && BIStore.currentItemsInfo[linkagerId]) {
        const triggerKey = item.trigger[1].split(DEFAULT_SPLITER)[0];
        const triggerName = item.trigger[1].split(DEFAULT_SPLITER)[3] || triggerKey;
        LinkageRemark[linkagerId]
          ? LinkageRemark[linkagerId].triggerKeys.push(triggerName)
          : (LinkageRemark[linkagerId] = { triggerKeys: [triggerName], cardName: BIStore.currentItemsInfo[linkagerId].name });
      }
    });
    return JSON.stringify(LinkageRemark) != '{}' ? Object.values(LinkageRemark) : null;
  };
  @observable currentLinkager = {};
  @action.bound setCurrentLinkager(id, triggerData, isTable) {
    const mappingLinkage = getMappingLinkage(id, this.linkageConfig);
    const currentLinkager = processLinkage(id, triggerData, mappingLinkage, this.currentLinkager, isTable);
    this.currentLinkager = { ...currentLinkager };
  }
  @action.bound cleanCurrentLinkager(id) {
    let currentLinkager = this.currentLinkager;
    for (let key in currentLinkager) {
      delete currentLinkager[key].filters[id];
    }
    this.currentLinkager = { ...currentLinkager };
  }

  // 看板是否处于编辑状态
  @observable isBoardEdit = util.getQueryString('isBoardEdit') === 'true' ? true : false;
  @action.bound toggleBoardEdit(bool) {
    this.isBoardEdit = bool;
    let search = window.location.search;
    search = util.delQueryString(search, 'isBoardEdit');
    window.history.replaceState(null, null, bool ? search + '&isBoardEdit=' + this.isBoardEdit : search);
  }

  @action.bound
  init() {
    this.getProductList();
    when(
      () => this.parameter.idsite,
      () => {
        this.replaceState(true);
        // this.getBoardList();
        this.changePeriod('day');
        // this.getEventList();
      }
    );
  }

  @action
  getBoardList() {
    this.boardList = null;
    let param = {
      idsite: this.parameter.idsite,
      currentPage: 1,
      pageCount: 10000,
    };
    util.get(
      api.board.listWithoutTemplate,
      param,
      (res) => {
        if (res) {
          const { data } = res;
          data.forEach((item) => {
            item.isSelect = false;
          });
          this.boardList = data;
        } else {
          loginStore.setPermission(true);
        }
      },
      null,
      false
    );
  }
  @action
  getEventList() {
    // 四个dimoption请求
    SelectStore.item = 0;
    SelectStore.getLoadData(0, 'default');
    SelectStore.getLoadData(1, 'customize');
    SelectStore.getLoadData(2, 'funnel');
    SelectStore.getLoadData(3, 'survey');
  }

  //下载------------------------------------------------------------------------------------------------
  @observable downloading = false;
  @observable isShowDownLoadButton = false;

  @action.bound
  showDownLoadButton() {
    this.isShowDownLoadButton = true;
  }
  @action.bound
  hideDownLoadButton() {
    this.isShowDownLoadButton = false;
  }

  //预警------------------------------------------------------------------------------------------------
  @observable isShowWarningButton = false;
  @observable isShowWarningModal = false;
  @action.bound
  showWarningButton() {
    this.isShowWarningButton = true;
  }
  @action.bound
  hideWarningButton() {
    this.isShowWarningButton = false;
  }
  @action.bound
  showWarningModel() {
    this.isShowWarningModal = true;
    this.hideMore();
  }
  @action.bound
  hideWarningModal() {
    this.isShowWarningModal = false;
  }

  //发送报告------------------------------------------------------------------------------------------------
  @observable isShowReportButton = false;
  @observable isShowReportModal = false;
  @action.bound
  showReportButton() {
    this.isShowReportButton = true;
  }
  @action.bound
  hideReportButton() {
    this.isShowReportButton = false;
  }
  @action.bound
  showReportModal() {
    this.isShowReportModal = true;
    this.hideMore();
  }
  @action.bound
  hideReportModal() {
    this.isShowReportModal = false;
  }

  //转化报告板
  @observable isShowSurvey = false;
  @action.bound
  showSurvey(bool) {
    this.isShowSurvey = bool;
  }
  newSurvey() {
    localStorage && localStorage.setItem('showSurveyModal', 0);
    window.open('//' + window.location.host + '/data/survey', '_blank');
  }

  //URL ------------------------------------------------------------------------------------------------
  //URL参数拼接
  @observable queryString = '';
  @action.bound
  replaceState(changeRoute) {
    let url = window.location.pathname;
    // if (['/', '/login', '/register'].indexOf(url) > -1) {
    //   return;
    // }
    if (util.getQueryString('downloadMode')) {
      return;
    }
    const parameter = {
      idsite: this.parameter.idsite,
      period: this.parameter.period,
    };
    if (changeRoute) {
      this.queryString = util.parseObject(parameter);
      if (!window.location.search) {
        window.history.replaceState(null, null, '?' + this.queryString);
      } else {
        window.history.replaceState(null, null, this.queryString ? window.location.search + '&' + this.queryString : window.location.search);
      }
    } else {
      let tablePageConfigKeys = localStorage.getItem('TablePageConfigKeys');
      tablePageConfigKeys = tablePageConfigKeys ? JSON.parse(tablePageConfigKeys) : [];
      let tablePageConfig = {};
      tablePageConfigKeys.forEach((item) => {
        let value = util.getQueryString(item);
        value && (tablePageConfig[item] = value);
      });
      this.queryString = util.parseObject({ ...parameter, ...tablePageConfig });
      const otherQueryString = util.delQueryString(util.delQueryString(window.location.search, 'idsite'), 'period');
      window.history.replaceState(null, null, `${otherQueryString}${otherQueryString.length > 1 ? '&' : ''}${this.queryString}`);
    }
  }

  resetPage() {
    const boardName = util.getQueryString('boardName');
    pageUtil.setDocumentTitle(boardName ? `${boardName}-洞察` : '');
    this.hidePageHeader();
    this.mountExportButton();
    this.hideExportShow();
    this.hideDownLoadButton();
    this.hideWarningButton();
    this.hideReportButton();
    this.showSurvey(false);
    window.scrollTo(0, 0);
  }
  showPageHeader() {
    this.isPageHeaderShow = true;
    this.setPagePadding();
  }
  showDropDown() {
    this.dropdownDisabled = false;
  }
  hideDropDpwn() {
    this.dropdownDisabled = true;
  }
  hidePageHeader() {
    this.isPageHeaderShow = false;
    this.setPagePadding();
  }
  @action.bound
  changeProduct(id) {
    this.parameter.idsite = parseInt(id);
    localStorage && localStorage.setItem('idsite', id);
    document.title = document.title.split(' - ')[0] + ' - ' + this.productName;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].id === parseInt(this.parameter.idsite)) {
        this.productType = this.data[i].type.toLowerCase();
        this.industry = this.data[i].industry;
      }
    }

    // this.getFilterList(this.parameter.idsite);
    // this.getBoardList();
    // this.getEventList();
    this.replaceState();
    this.getProductCreateTime();
  }
  @action.bound
  reset() {
    this.parameter.idsite = null;
  }
  @action.bound
  getFilterList(idsite) {
    util.get(
      api.visitor.filtersAll,
      {
        idsite: idsite,
      },
      (res) => {
        this.conditionsList = null;
        const data = [];
        for (var key in res) {
          data.push({ value: key, label: res[key] });
        }
        this.conditionsList = data;
      },
      null,
      false
    );
  }
  //产品列表、产品下拉菜单-------------------------------------------------------------------------------
  @observable dropdownList = { product: [] };
  @action.bound
  getProductList() {
    this.parameter.userGroupId = ' ';
    util.get(api.product.list, null, (res) => {
      this.data = res;
      this.dropdownList.product = this.data.map((item) => {
        return { label: item.name, value: item.id, type: item.type };
      });
      let arr = this.data.map((item) => {
        return item.id;
      });
      if (!arr || arr.length === 0) {
        this.parameter.idsite = -1;
      } else {
        let urlIdsite = parseInt(util.getQueryString('idsite'), 10);
        let lsIdsite = parseInt(localStorage.getItem('idsite'), 10);
        let defaultIdsite = arr[0];
        if (urlIdsite && arr.indexOf(urlIdsite) >= 0) defaultIdsite = urlIdsite;
        else if (lsIdsite && arr.indexOf(lsIdsite) >= 0) defaultIdsite = lsIdsite;
        this.productType = this.data[arr.indexOf(defaultIdsite)].type.toLowerCase();
        this.industry = this.data[arr.indexOf(defaultIdsite)].industry;
        this.parameter.idsite = defaultIdsite;
        this.parameter.name = this.data[arr.indexOf(defaultIdsite)].name;
        // this.getFilterList(this.parameter.idsite);
      }
      this.replaceState();
      this.getProductCreateTime();
    });
  }
  @computed
  get getProductTitle() {
    if (this.dropdownList.product && this.dropdownList.product.length > 0) {
      for (let i = 0; i < this.dropdownList.product.length; i++) {
        if (parseInt(this.parameter.idsite) === this.dropdownList.product[i].value) {
          return this.dropdownList.product[i].label;
        }
      }
    } else {
      return '暂无选项';
    }
  }
  @action.bound
  getProductCreateTime() {
    if (this.data && this.parameter.idsite) {
      this.data.map((item) => {
        if (item.id == this.parameter.idsite) {
          const productCreateTime = new Date(item.tsCreated.toString().replace(/-/g, '/'));
          this.productCreateTime = productCreateTime.valueOf();
        }
      });
    }
  }
  @computed
  get productName() {
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === parseInt(this.parameter.idsite)) {
          return this.data[i].name;
        }
      }
      return '';
    } else {
      return '';
    }
  }

  @computed
  get productMainUrl() {
    if (!this.data) {
      return '正在加载产品';
    } else if (!this.data.length) {
      return '暂无产品';
    } else {
      let arr = this.data.filter((item) => {
        return item.id === this.parameter.idsite;
      });
      if (arr.length) {
        return arr[0].mainUrl;
      }
    }
  }

  @action.bound
  changeDate(date, key) {
    if (this.parameter.date === date) {
      return false;
    }

    this.parameter.date = date;
    this.parameter.lastDate = key;
    // localStorage && localStorage.setItem('date', date);
    // localStorage && localStorage.setItem('lastDate', key ? key : '');
    this.replaceState();
    this.isFoldPicker && this.reloadBoard(); //在报告板中则刷新报告板
  }

  @action.bound
  changePeriod(period) {
    this.parameter.period = period === 'default' ? '' : period;
    localStorage && localStorage.setItem('period', period);
    this.replaceState();
    // this.isFoldPicker && this.reloadBoard(); //在报告板中则刷新报告板
  }
  @action.bound
  changeCondition(definition) {
    this.parameter.conditions = definition;
    this.replaceState();
  }

  @action.bound
  changeUserGroupId(id) {
    this.parameter.userGroupId = id;
    this.replaceState();
  }
  @action.bound
  getChartType(type) {
    this.exports.type = type;
  }

  @action.bound
  selectExportButton(item) {
    item.isSelect = !item.isSelect;
    let arr = [];
    this.boardList.forEach((_item, index) => {
      if (item.id !== _item.id) _item.isSelect = false;
      if (_item.isSelect === true) {
        arr.push(item.id);
      }
    });
    if (this.exportButton.config.info || this.exportButton.config.exportType) {
      this.exportParameter.id = arr.join(',');
    }
  }

  //某页面导出时候可选择导出的模块
  @action.bound
  SelectExportType(item) {
    item.isSelected = !item.isSelected;
    let arr = [];
    this.exportButton.config.exportType.forEach((item, index) => {
      if (item.isSelected === true) {
        arr.push(item.info);
      }
    });
    this.exportParameter.layout = JSON.stringify(arr);
  }

  @action.bound
  changeExportShow() {
    if (this.isExportShow) {
      this.hideExportShow();
    } else {
      this.isExportShow = true;
      this.hideMore();
    }
  }

  @action.bound
  hideExportShow() {
    this.isExportShow = false;
    this.exports.type = '';
    this.exportParameter.layout = null;
    this.exportParameter.id = null;
    this.boardList &&
      this.boardList.forEach((item) => {
        item.isSelect = false;
      });
    this.exportButton.config &&
      this.exportButton.config.exportType &&
      this.exportButton.config.exportType.forEach((item) => {
        item.isSelected = false;
      });
  }

  @action.bound
  submitExport() {
    if (this.exportParameter.layout && this.exportParameter.id) {
      util.post(
        api.board.updateDashboard,
        {
          actionType: 'addCard',
          ...this.exportParameter,
          ...{ idsite: this.parameter.idsite },
        },
        (res) => {
          this.hideExportShow();
          message.success('导出成功');
        }
      );
    }
  }

  @action.bound
  mountBoardButton(show) {
    this.boardButton.isAddButtonShow = show;
    this.boardButton.isAddDeleteShow = show;
  }

  @action.bound
  showConfigure(show) {
    this.configure.isShowConfigure = show;
  }

  @action.bound
  showGuide(show) {
    this.boardGuide.isShowGuide = show;
  }

  showTemplateGuide() {
    IntroJs(document.getElementById('root'))
      .setOptions({
        nextLabel: '下一步',
        skipLabel: '跳过',
        doneLabel: '结束',
        hideNext: true,
        showStepNumbers: false,
        tooltipClass: 'guide-tooltip',
      })
      .start();
  }

  @action.bound
  mountExportButton(config) {
    if (config) {
      this.exportButton.isShow = true; //是否显示导出的图标
      this.exportButton.config = config; //导出配置
      Object.assign(this.exports, config.download); //导出文件参数
      this.exportParameter.layout = JSON.stringify(config.info); //导出的组件名称
    } else {
      this.exportButton.isShow = false;
      this.exportButton.config = null;
    }
  }

  // @action.bound slideUpPosition() {
  //   this.isRestorePosition = false;
  // }

  // @action.bound restorePosition() {
  //   this.isRestorePosition = true;
  // }
  @action.bound
  showMore() {
    this.isShowMore = true;
  }

  @action.bound
  hideMore() {
    this.isShowMore = false;
  }

  @computed
  get periodName() {
    if (this.parameter.period === 'hour') {
      return intl.get('AccordingHours');
    } else if (this.parameter.period === 'day') {
      return intl.get('AccordingDays');
    } else if (this.parameter.period === 'week') {
      return intl.get('AccordingWeeks');
    } else if (this.parameter.period === 'month') {
      return intl.get('AccordingMonths');
    } else if (this.parameter.period === 'Quarter') {
      return intl.get('AccordingQuarters');
    } else if (this.parameter.period === 'Year') {
      return intl.get('AccordingYears');
    }
  }

  // 设置页面上边距
  setPagePadding = (time = 0) => {
    setTimeout(() => {
      const padeheader = document.getElementsByClassName('page-header-wrapper')[0];
      if (padeheader) {
        const pageheaderHeight = padeheader.offsetHeight;
        if (this.lastPageheaderHeight !== pageheaderHeight) {
          document.getElementById('main').style.paddingTop = `${pageheaderHeight}px`;
          this.lastPageheaderHeight = pageheaderHeight;
        }
      }
    }, time);
  };

  // 每次路由跳转复位时间
  @action.bound
  resetTime = () => {
    this.parameter.date = util.getLast30();
    this.parameter.lastDate = 'last30';
  };

  // 是否展示footer，目前只有在使用iframe嵌套页面的时候不显示
  @observable isShowFooter = true;
  @action.bound
  setIsShowFooter = (bool) => {
    this.isShowFooter = bool;
  };
}

export default new Store();
