/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { decodeMetricName, getFormattedValue } from '../../util';
import { getLabelOption } from './util';
import { tooltipStyle } from '../../constants';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps) {
  const { width, height, data, cols, metrics, chartStyles, tip } = chartProps;

  const { label, spec, toolbox, grid } = chartStyles;

  const { nodeWidth, nodeGap, orient, draggable } = spec;

  const labelOption = {
    label: getLabelOption('sankey', label, metrics),
  };

  let dimensions = [];
  if (cols.length) {
    dimensions = dimensions.concat(cols);
  }

  const metricsName = decodeMetricName(metrics[0].name);
  const agg = metrics[0].agg;

  const nodesValues = [];
  const links = [];
  data.forEach((row) => {
    dimensions.forEach(({ name, randomName, fieldDisplay }, idx) => {
      // source和target重名时无法正常渲染（echart设计如此）
      const itemName = `${row[randomName || name]}(${fieldDisplay})`;
      if (!nodesValues.includes(itemName)) {
        nodesValues.push(itemName);
      }
      if (dimensions[idx - 1]) {
        const sourceKey = dimensions[idx - 1].randomName || dimensions[idx - 1].name;
        const targetKey = dimensions[idx].randomName || dimensions[idx].name;
        const source = `${row[sourceKey]}(${dimensions[idx - 1].fieldDisplay})`;
        const target = `${row[targetKey]}(${dimensions[idx].fieldDisplay})`;
        const value = +row[metrics[0].randomName ? metrics[0].randomName : `${agg}(${metricsName})`];
        if (isNaN(value)) {
          return;
        }
        const existedLink = links.length && links.find((lnk) => lnk.source === source && lnk.target === target);
        if (!existedLink) {
          links.push({
            source,
            target,
            value,
          });
        } else {
          existedLink.value += value;
        }
      }
    });
  });

  const tooltip = {
    trigger: 'item',
    confine: 'true',
    extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
    triggerOn: 'mousemove',
    formatter(params) {
      const { name, value, color } = params;
      const tooltipLabels = [];
      if (color) {
        tooltipLabels.push(`<span class="widget-tooltip-circle" style="background: ${color}"></span>`);
      }
      tooltipLabels.push(name);
      if (value) {
        tooltipLabels.push(': ');
        tooltipLabels.push(getFormattedValue(value, metrics[0].format));
      }
      return tooltipLabels.join('');
    },
    ...tooltipStyle,
  };
  return {
    tooltip,
    series: [
      {
        type: 'sankey',
        layout: 'none',
        ...labelOption,
        data: nodesValues.map((val, index) => ({
          name: val,
          itemStyle: {
            color: defaultThemeColors[index % 12],
          },
        })),
        links,
        orient,
        draggable,
        nodeWidth,
        nodeGap,
        focusNodeAdjacency: true,
        // itemStyle: {
        //   normal: {
        //     borderWidth: 1,
        //     borderColor: '#aaa'
        //   }
        // },
        lineStyle: {
          normal: {
            color: 'source',
            curveness: 0.5,
          },
        },
      },
    ],
    grid: {
      show: true,
      borderColor: 'transparent',
      backgroundColor: grid ? grid.backgroundColor : 'transparent',
    },
  };
}
