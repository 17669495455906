import React, { Component } from 'react';
import RemainTable from './RemainTable';
import RemainChart from './RemainChart';
import { observer } from 'mobx-react';
import './index.sass';
import moment from 'moment';
import UXSRangePicker from '../../../component/UXSRangePicker';
import Loading from '../../../component/Loading/Loading';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import mathUtil from '../../../utils/mathUtil';

@observer
export default class RemainChartGroup extends Component {
  componentDidMount() {
    this.props.boardChart && window.Cemyun && window.Cemyun.trackEvent('卡片浏览', { cardName: this.props.name });
  }
  render() {
    let date = `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`;
    let dateStr =
      PageHeaderStore.isUseParameterDate && PageHeaderStore.parameter.date
        ? PageHeaderStore.parameter.date.replace(/-/g, '/').replace(',', ' ~ ')
        : !this.props.setDateObj &&
          (this.props.form.dateObj
            ? this.props.form.dateObj.lastDate
              ? mathUtil
                  .keyToDate(this.props.form.dateObj.lastDate)
                  .replace(/-/g, '/')
                  .replace(',', ' ~ ')
              : this.props.form.dateObj.date.replace(/-/g, '/').replace(',', ' ~ ')
            : date.replace(/-/g, '/').replace(',', ' ~ '));
    return (
      <React.Fragment>
        {/* 编辑的时候才显示控件，看板时为文字 */}
        {this.props.setDateObj ? (
          this.props.isLoading ? (
            <Loading size="small" />
          ) : (
            <UXSRangePicker
              useDateByStore={false}
              getDateAndlastDate={this.props.setDateObj}
              date={this.props.form.dateObj ? this.props.form.dateObj.date : date}
              lastDate={this.props.form.dateObj ? this.props.form.dateObj.lastDate : ''}
              isBoardPicker={true}
              isBoardPicker={true}
            />
          )
        ) : (
          <p className="board-chart-date">{dateStr}</p>
        )}
        {this.props.chartType === 1 ? <RemainChart {...this.props}></RemainChart> : <RemainTable {...this.props}></RemainTable>}
      </React.Fragment>
    );
  }
}
