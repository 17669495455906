import CommonTab from '../../containers/CommonTab';
import Survey from '../../containers/Survey';
import CommonBreadCrumb from '../../containers/CommonBreadCrumb';
import CommonChart from '../../containers/Common/CommonChart';
import api from '../../api';
import util from '../../util';

import SurveyVisitorTable from '../../containers/SurveyVisitorTable/index';

export default {
  title: '调研详情',
  components: [
    {
      component: CommonBreadCrumb,
      col: 24,
      props: {
        breadCrumbName: '调研列表',
        breadCrumbSecName: 'surveyName',
      },
    },
    {
      isCard: true,
      component: CommonTab,
      props: {
        tab: [
          {
            name: '调研统计',
            children: [{ component: Survey, col: 24 }],
          },
          {
            name: '回收统计',

            children: [
              {
                component: Survey,
                col: 24,
                props: {
                  type: 2
                }
              },
            ],
          },
          {
            name: '调研样本',
            children: [
              {
                component: SurveyVisitorTable,
                col: 24,
              },
            ],
          },
        ],
      },
    },
  ],
};
