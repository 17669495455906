import React, { Component, useEffect, useState } from 'react';
import { Modal, Form, Select, message } from 'antd';
import intl from 'react-intl-universal';
import util from '../../../util';
import api from '../../../api';
import PageHeaderStore from '../PageHeaderStore';
import BIStore from '../../../containers/BIChart/store';
import Loading from '../../Loading/Loading';
import { Cascader } from 'antd4';
import { DEFAULT_SPLITER, SQL_NUMBER_TYPES } from '../../../containers/BIChart/constants';
import { decodeMetricName, getAggregatorLocale } from '../../../containers/BIChart/util';

// config格式
// let config = [{
//     name: '控制1', //控制器名
//     type: 'select', //组件类型
//     relatedCards: [{ type: '10', id: '19' }],    //关联卡片，type：分析类型，id：卡片id
//     relatedViews: { 64: { name: 'var1', sqlType: 'string' } }//{ 64: 'var1' }  //关联工作表，viewName：工作表，fieldName:字段
// }]
class FormModal extends Component {
  constructor() {
    super();
    this.state = { data: null, showFormModal: false, linkageConfigSource: [] };
  }

  componentDidMount() {
    !this.props.isLoading && this.getTriggerOption();
  }

  componentDidUpdate(preProps) {
    if (preProps.isLoading && !this.props.isLoading) {
      this.getTriggerOption();
    }
  }
  getTriggerOption() {
    const currentItemsInfo = BIStore.currentItemsInfo;
    const { viewDetails } = this.props;
    if (!currentItemsInfo) {
      return;
    }

    const linkageConfigSource = [];
    Object.keys(currentItemsInfo).forEach((infoKey) => {
      const widget = currentItemsInfo[infoKey];
      const { cols, rows, metrics, color, label } = widget.config;
      const view = viewDetails[widget.viewId];
      const { fieldModelList, model, variable } = view;

      let triggerDimensions = [...cols, ...rows].map(({ name }) => name); //卡片内已使用的字段名
      if (color) {
        triggerDimensions = triggerDimensions.concat(color.items.map(({ name }) => name));
      }
      if (label) {
        triggerDimensions = triggerDimensions.concat(label.items.map(({ name }) => name));
      }
      const triggerKeys = [];
      let triggerColumns = [
        ...[...new Set(triggerDimensions)]
          .filter((name) => model[name])
          .map((name) => {
            triggerKeys.push(name);
            const displayName = model[name].fieldDisplay || name;
            return {
              label: displayName,
              value: [name, model[name].sqlType, 'column', displayName].join(DEFAULT_SPLITER).replace(/\r\n\t/g, 'CEMYUN_Line_Feed'),
            };
          }),
        ...metrics.map(({ name, agg, fieldDisplay }) => {
          const metricName = decodeMetricName(name);
          const displayName = fieldDisplay || name;
          return {
            label: fieldDisplay || `${getAggregatorLocale(agg)} ${metricName}`,
            value: [`${agg}(${metricName})`, SQL_NUMBER_TYPES[SQL_NUMBER_TYPES.length - 1], 'column', displayName]
              .join(DEFAULT_SPLITER)
              .replace(/\r\n\t/g, 'CEMYUN_Line_Feed'),
          };
        }),
      ];

      fieldModelList.forEach((item) => {
        item.modelType !== 'value' &&
          !triggerKeys.includes(item.fieldName) &&
          triggerColumns.push({
            label: item.fieldDisplay || item.fieldName,
            value: [item.fieldName, item.sqlType, 'column', item.fieldDisplay || item.fieldName].join(DEFAULT_SPLITER).replace(/\r\n\t/g, 'CEMYUN_Line_Feed'),
          });
      });
      const linkagerColumns = fieldModelList.map((item) => ({
        label: item.fieldDisplay || item.fieldName,
        value: [item.fieldName, item.sqlType, 'column'].join(DEFAULT_SPLITER).replace(/\r\n\t/g, 'CEMYUN_Line_Feed'),
      }));

      //   const variables = variable.map(({ name }) => {
      //     return {
      //       label: `${name}[变量]`,
      //       value: [name, null, 'variable'].join(DEFAULT_SPLITER),
      //     };
      //   });

      linkageConfigSource.push({
        label: widget.name,
        value: infoKey,
        children: {
          triggerColumns,
          linkagerColumns,
          variables: [],
        },
      });
    });
    this.setState({
      linkageConfigSource,
    });
  }

  submit = (e) => {
    e.preventDefault();
    const { onHide, fetchData, linkageConfig, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        values.trigger[1] = values.trigger[1].replace(/CEMYUN_Line_Feed/g, '\r\n\t');
        values.linkager[1] = values.linkager[1].replace(/CEMYUN_Line_Feed/g, '\r\n\t');
        util.post(
          api.globalControl.save,
          {
            id: PageHeaderStore.currentBoardId,
            linkConfig: JSON.stringify([...linkageConfig, values]),
          },
          (res) => {
            res.code === 200 ? (message.success('保存成功'), onHide(), fetchData()) : message.error(res.msg);
          }
        );
      }
    });
  };

  // FIXME 解决循环联动问题
  checkRepeatAndType = (rule, value, callback) => {
    const form = this.props.form;
    const linkagerValue = form.getFieldValue('linkager');

    if (value && linkagerValue && value.length && linkagerValue.length) {
      const triggerValueArr = value[1].split(DEFAULT_SPLITER);
      const linkagerValueArr = linkagerValue[1].split(DEFAULT_SPLITER);
      const triggerSqlType = triggerValueArr[1];
      const linkagerSqlType = linkagerValueArr[1];
      const linkagerColumnType = linkagerValueArr[2];

      if (value[0] === linkagerValue[0]) {
        callback('不能联动自身');
      }

      if (linkagerColumnType !== 'variable') {
        if (triggerSqlType !== linkagerSqlType) {
          callback('字段类型不一致');
        } else {
          callback();
        }
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  confirmCheck = (rule, value, callback) => {
    if (value) {
      this.props.form.validateFields(['trigger'], { force: true }, () => void 0);
    }
    callback();
  };

  filter(inputValue, path) {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  render() {
    const { visible, onHide, form, isLoading } = this.props;
    const { getFieldDecorator } = form;
    const { linkageConfigSource } = this.state;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
    };
    const relationList = ['=', '!=', 'like', '<', '>', '<=', '>='];
    const triggerOptions = linkageConfigSource.map(({ label, value, children: { triggerColumns } }) => ({ label, value, children: triggerColumns }));
    const linkagerOptions = linkageConfigSource.map(({ label, value, children: { linkagerColumns, variables } }) => ({
      label,
      value,
      children: [].concat(linkagerColumns, variables),
    }));
    return (
      <Modal
        // className="linkager-modal"
        visible={visible}
        title={intl.get('AddLinkage')}
        width={400}
        onCancel={onHide}
        onOk={this.submit}
        centered
        okText="保存"
        cancelText="取消">
        {!isLoading ? (
          <Form {...formItemLayout} onSubmit={this.submit}>
            <Form.Item label="触发器">
              {getFieldDecorator('trigger', {
                rules: [
                  { required: true, message: intl.get('Thisitemcannotbeempty') },
                  {
                    validator: this.checkRepeatAndType,
                  },
                ],
              })(
                <Cascader
                  options={triggerOptions}
                  showSearch={{ filter: this.filter }}
                  className="my-cascader"
                  dropdownClassName="uxs-cascader-menu"
                  placeholder={'请选择'}
                />
              )}
            </Form.Item>
            <Form.Item label="联动图表">
              {getFieldDecorator('linkager', {
                rules: [
                  { required: true, message: intl.get('Thisitemcannotbeempty') },
                  {
                    validator: this.confirmCheck,
                  },
                ],
              })(
                <Cascader
                  options={linkagerOptions}
                  showSearch={{ filter: this.filter }}
                  className="my-cascader"
                  dropdownClassName="uxs-cascader-menu"
                  placeholder={'请选择'}
                />
              )}
            </Form.Item>
            <Form.Item label="关系">
              {getFieldDecorator('relation', {
                initialValue: '=',
              })(
                <Select className="s-select">
                  {relationList.map((item) => {
                    return <Select.Option key={item}>{item}</Select.Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </Form>
        ) : (
          <Loading />
        )}
      </Modal>
    );
  }
}

export default Form.create()(FormModal);
