import React, { useEffect, useState, Fragment } from 'react';
import api from '../../api';
import { Popconfirm, Tooltip, Dropdown, Menu } from 'antd';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import Loading from '../../component/Loading/Loading';
import CommonTable from '../CommonTable';
import util from '../../util';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import TablePageStore from '../CommonTable/TablePageStore';
import moment from 'moment';
import './index.sass';
import { auditStatus } from '../../config';

function Contact(props) {
  const { type, parentData } = props;
  const [showModal, setShowModal] = useState(false); // 是否显示弹窗
  const [loading, setLoading] = useState(true); // 是否加载状态
  const [data, setData] = useState({}); // 触点列表
  const [keyword, setKeyword] = useState(''); // 搜索词
  const [editInfo, setEditInfo] = useState({}); //编辑信息
  const [auditInfo, setAuitInfo] = useState(null); //编辑信息
  const [displayType, setDisplayType] = useState('table'); //显示类型
  const [showAuditModal, setShowAuditModal] = useState(false); //是否显示审核弹窗
  // const [visible, setVisible] = useState(false)

  useEffect(() => {
    TablePageStore.init();
    pageHeaderStore.hidePageHeader();
    type !== 'audit' ? fetchData() : (setData(parentData), setLoading(false));
  }, [keyword]);
  function modalToggle(bool) {
    setShowModal(bool);
    !bool &&
      setEditInfo({
        editId: '',
        parentId: '',
        parentPath: '',
      });
  }

  function fetchData(orderDesc, orderColumn, currentPage, pageCount, id) {
    setLoading(true);
    type === 'detail'
      ? util.get(
          api.touchpoint.info,
          {
            id: id || util.getQueryString('id'),
            isDraft: util.getQueryString('isDraft') === 'true' ? true : false,
          },
          (res) => {
            res && (setData({ data: [res] }), setLoading(false));
          }
        )
      : util.get(
          api.touchpoint.list,
          {
            search: TablePageStore.config.search || keyword,
            currentPage: TablePageStore.config.currentPage || currentPage || 1,
            pageCount: TablePageStore.config.pageCount || pageCount || 10,
          },
          (res) => {
            res && setData(res);
            setLoading(false);
          }
        );
  }
  function edit(id, isDraft, bussinessId) {
    setEditInfo({
      ...editInfo,
      editId: id,
      bussinessId,
      isDraft,
    });
    modalToggle(true);
  }
  function add(id, parentPath, version) {
    setEditInfo({
      ...editInfo,
      parentId: id,
      parentPath: `${parentPath == '-' ? '0' : parentPath},${id}`,
      version,
    });
    modalToggle(true);
  }
  function deleteData(id) {
    util.post(
      api.touchpoint.delete,
      {
        id,
      },
      (res) => {
        fetchData(null, null, null, null, res.data);
      }
    );
  }
  function audit(info) {
    setShowAuditModal(true);
    info && setAuitInfo(info);
  }

  return (
    <div className={`contact-overview ${displayType === 'chart' && type === 'detail' ? 'contact-overview-chart' : ''}`}>
      {!loading ? (
        <NestedTable fetchData={fetchData} parentData={data} isLoading={loading} edit={edit} deleteData={deleteData} add={add} type={type} audit={audit} />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Contact;

function NestedTable(props) {
  const {
    fetchData,
    parentData,
    edit,
    deleteData,
    add,
    type,
    audit,
    // , visible, onclose, showDrawer, submit, getContent
  } = props;
  function addContent(store) {
    for (let i = 0; i < store.columns.length; i++) {
      const currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'name') {
        store.columns[i].render = (text, record, index) => {
          // 看板菜单
          const relUrl = record.relUrl ? record.relUrl.split(',') : [];
          const dashboardMenu = (
            <Menu>
              {relUrl.map((item, index) => {
                return (
                  <Menu.Item key={`${item}${index}`}>
                    <a target="_blank" href={item} title={item} className="menu-link">
                      {item}
                    </a>
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          // 编辑问卷菜单
          const surveyIds = record.surveyIds ? record.surveyIds.split(',') : [];
          const surveyName = record.surveyName ? record.surveyName.split(',') : [];
          const surveyUrl = record.surveyUrl ? record.surveyUrl.split(',') : [];
          const surveyEditMenu = (
            <Menu>
              {surveyIds.map((item, index) => {
                return (
                  <Menu.Item key={`${item}${index}`}>
                    <a target="_blank" href={`/wj/detail?surveyId=${item}`} title={surveyName[index]} className="menu-link">
                      {surveyName[index]}
                    </a>
                  </Menu.Item>
                );
              })}
              {surveyUrl.map((item, index) => {
                return (
                  <Menu.Item key={`${item}${index}`}>
                    <a target="_blank" href={item} title={item} className="menu-link">
                      {item}
                    </a>
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          const surveyBrowseMenu = (
            <Menu>
              {surveyIds.map((item, index) => {
                return (
                  <Menu.Item key={`${item}${index}`}>
                    <a target="_blank" href={`${process.env.REACT_APP_Base_Url}/survey/survey/${item}/test`} title={surveyName[index]} className="menu-link">
                      {surveyName[index]}
                    </a>
                  </Menu.Item>
                );
              })}
              {surveyUrl.map((item, index) => {
                return (
                  <Menu.Item key={`${item}${index}`}>
                    <a target="_blank" href={item} title={item} className="menu-link">
                      {item}
                    </a>
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          return {
            children: (
              <div className="childrenContent">
                {!type && record.parentId == 0 && record.editable ? (
                  <Link to={`/data/contact/detail?id=${record.id}&name=${record.name}&isDraft=${record.isDraft}`} title="触点详情">
                    {text}
                  </Link>
                ) : (
                  text
                )}
                {record.description && record.description !== '-' && (
                  <div className="eventDetailIcon">
                    <Tooltip title={record.description}>
                      <i className="iconfont icon-tishiicon" />
                    </Tooltip>
                  </div>
                )}
                {record.relUrl && record.relUrl !== '-' && record.relUrl !== 'NaN' && (
                  <Dropdown overlay={dashboardMenu}>
                    <div className="eventDetailIcon" title="打开看板">
                      <i className="iconfont icon-yemian_" />
                    </div>
                  </Dropdown>
                )}
                {record.surveyIds && record.surveyIds !== '-' && record.surveyIds !== 'NaN' && (
                  <Fragment>
                    <Dropdown overlay={surveyEditMenu}>
                      <div className="eventDetailIcon" title="编辑问卷">
                        <i className="iconfont icon-card-bianji operateDom-update" />
                      </div>
                    </Dropdown>
                    <Dropdown overlay={surveyBrowseMenu}>
                      <div className="eventDetailIcon" title="浏览问卷">
                        <i className="iconfont icon-tiaoyan" />
                      </div>
                    </Dropdown>
                  </Fragment>
                )}
              </div>
            ),
          };
        };
      } else if (currentRowData === 'operate') {
        store.columns[i].render = (text, record, index) => {
          return {
            children:
              (record.editable === true || type) && ['2', '6'].indexOf(record.approvalStatus) < 0 && (type || record.parentId == 0) ? (
                <div className="operateGroup">
                  {type !== 'detail' && record.approvalStatus !== '4' ? (
                    <a
                      className="operateDom-update"
                      onClick={() => {
                        audit(record);
                      }}>
                      提交审核
                    </a>
                  ) : (
                    <span className="text-disabled">提交审核</span>
                  )}
                  <Popconfirm
                    title={intl.get('DeleteWaring')}
                    okButtonProps={{ type: 'primary' }}
                    cancelButtonProps={{ type: 'cancel' }}
                    onConfirm={() => {
                      deleteData(record.id);
                    }}
                    okButtonProps={{ type: 'primary' }}
                    cancelButtonProps={{ type: 'cancel' }}
                    okText={intl.get('Confirm')}
                    cancelText={intl.get('Cancel')}>
                    <span className="operateDom-delete" title={intl.get('Delete')}>
                      {intl.get('Delete')}
                      {/* 删除 */}
                    </span>
                  </Popconfirm>
                  {type === 'detail' && (
                    <span
                      className="operateDom-update"
                      onClick={() => {
                        add(record.bussinessId, record.parentPath, record.version);
                      }}>
                      添加
                    </span>
                  )}
                  {type === 'detail' ? (
                    <span
                      className="operateDom-update"
                      onClick={() => {
                        edit(record.id, record.isDraft, record.bussinessId);
                      }}>
                      编辑
                    </span>
                  ) : (
                    <Link className="operateDom-update" to={`/data/contact/detail?id=${record.id}&name=${record.name}&isDraft=${record.isDraft}`}>
                      编辑
                    </Link>
                  )}
                </div>
              ) : (
                <div className="operateGroup">
                  <span className="text-disabled">提交审核</span>
                  <span className="text-disabled">{intl.get('Delete')}</span>
                  <span className="text-disabled">编辑</span>
                </div>
              ),
          };
        };
      } else if (currentRowData === 'updatedAt') {
        store.columns[i].render = (text, record, index) => {
          if (type === 'audit') {
            return text;
          } else {
            let { year, monthValue, dayOfMonth, hour, minute, second } = record.updatedAt;
            return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
          }
        };
      } else if (currentRowData === 'approvalStatus') {
        store.columns[i].render = (text, record, index) => {
          return auditStatus[text];
        };
      }
    }
  }
  const tableHeadData =
    type !== 'audit'
      ? type == 'detail'
        ? [
            ['旅程&触点名称', '触点数量', '创建人', '更新时间', '操作'],
            [
              'name',
              'subTouchpointCount',
              'createdByName',
              'updatedAt',
              'operate',
              'subTouchpoints',
              'id',
              'parentId',
              'isDraft',
              'parentPath',
              'relUrl',
              'description',
              'surveyIds',
              'surveyName',
              'bussinessId',
              'version',
              'editable',
            ],
          ]
        : [
            ['旅程&触点名称', '触点数量', '创建人', '更新时间', '状态', '操作'],
            [
              'name',
              'subTouchpointCount',
              'createdByName',
              'updatedAt',
              'approvalStatus',
              'operate',
              'subTouchpoints',
              'id',
              'parentId',
              'isDraft',
              'parentPath',
              'relUrl',
              'description',
              'surveyIds',
              'surveyName',
              'bussinessId',
              'version',
              'editable',
            ],
          ]
      : [
          ['旅程&触点名称', '触点数量', '创建人', '更新时间'],
          [
            'name',
            'subTouchpointCount',
            'createdByName',
            'updatedAt',
            'subTouchpoints',
            'id',
            'parentId',
            'isDraft',
            'parentPath',
            'relUrl',
            'description',
            'surveyIds',
            'surveyName',
            'bussinessId',
            'version',
          ],
        ];
  return (
    // <div>
    <CommonTable
      className="expanded-table"
      fetchData={fetchData}
      parentData={parentData}
      addContent={addContent}
      tableHeadData={tableHeadData}
      childrenColumnName="subTouchpoints"
      defaultExpandAllRows={type === 'detail' || type === 'audit'}
      isSort={false}
      hiddenKeys={['serialNumber']}
      rowKey="id"
    />
    //   <UXSDrawer
    //     title={
    //       <div>
    //         <div>提交审核确定</div>
    //         <div>
    //           <Button type="primary" onClick={() => { submit() }}>
    //             保存
    //           </Button>
    //         </div>
    //       </div>
    //     }
    //     width={800}
    //     show={visible}
    //     onClose={() => { onclose() }}>
    //     {getContent()}
    //   </UXSDrawer>
    // </div>
  );
}
