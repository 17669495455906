import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/index';
class Main extends React.Component {
  addContent(store) {
    store.columns.forEach((item) => {
      if (item.dataIndex === 'operate') {
        item.sorter = false;
        item.render = (text, record, index) => {
          return {
            children: (
              <Fragment>
                <Link to={`/data/event/detail?keyDim=event&key=${record.event_key}`}>查看</Link>
                <i style={{ marginLeft: 8 }} className="iconfont icon-shujumokuai" title="此页面包含参数"></i>
              </Fragment>
            ),
          };
        };
      }
    });
  }

  render() {
    return (
      <CommonTable
        addContent={this.addContent}
        dims={'event'}
        fields={'eventOccurs,eventUv,operate'}
        currentPage={1}
        pageCount={10}
        orderColumn={'eventOccurs'}
        orderDesc={true}
        searchInside={true}
        hasDetail={true}
        details={['/data/event/detail']}></CommonTable>
    );
  }
}

export default Main;
