import React, { Component } from 'react';
import { Modal, Form, Select, Input, message } from 'antd';
import PageHeaderStore from '../../../PageHeader/PageHeaderStore';
import api from '../../../../api';
import util from '../../../../util';
import SidebarStore from '../../store';

class FolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderList: [],
    };
  }

  componentDidMount() {
    util.get(
      api.folder.list,
      {
        idsite: PageHeaderStore.parameter.idsite,
        // share: this.props.currentDashboardItem.share,
        // dashboardType: SidebarStore.dashboardType !== 1 ? SidebarStore.dashboardType : null,
        type: SidebarStore.dashboardType,
      },
      (res) => {
        this.setState({
          folderList: res,
        });
      }
    );
  }

  submit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (
          values.folderId !== this.props.currentDashboardItem.parentId &&
          !(this.props.currentDashboardItem.parentId === 0 && (values.folderId === 'custom' || values.folderId === 'share'))
        ) {
          let param = {
            idsite: PageHeaderStore.parameter.idsite,
            sourceId: this.props.currentDashboardItem.id,
          };
          values.folderId == 0
            ? (param.fromFolderId = this.props.currentDashboardItem.parentId)
            : ((param.toFolderId = values.folderId),
              this.props.currentDashboardItem.parentId !== 0 && (param.fromFolderId = this.props.currentDashboardItem.parentId));
          util.get(api.folder.addOrDelete, param, (res) => {
            res ? (this.props.onCancel(), message.success('操作成功'), this.props.getFolderDashboardList()) : message.error('操作失败');
          });
        }
      }
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    const { currentDashboardItem } = this.props;
    const { folderList } = this.state;
    const initialValue = currentDashboardItem && currentDashboardItem.parentId !== 0 ? currentDashboardItem.parentId.toString() : null;
    return (
      <Modal title="移动" visible={this.props.visible} onOk={this.submit} onCancel={this.props.onCancel} okText="保存" cancelText="取消">
        <Form {...formItemLayout}>
          <Form.Item label="所属文件夹：">
            {getFieldDecorator('folderId', {
              rules: [{ required: true, message: '请选择所属文件夹!' }],
              initialValue: initialValue || '0',
            })(
              <Select className="md-select" placeholder="请选择所属文件夹">
                <Select.Option key="0">根目录</Select.Option>
                {folderList &&
                  folderList.map((item) => {
                    return <Select.Option key={item.id}>{item.name}</Select.Option>;
                  })}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(FolderModal);
