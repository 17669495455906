import React, { Component } from 'react';
import CommonChart from '../../../Common/CommonChart';
import { autorun, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import TableNullData from '../../../../component/TableNullData/index';
import { Select } from 'antd';
import './index.sass';
import util from '../../../../util';
import api from '../../../../api';
import pageUtil from '../../../../utils/pageUtil';
import { remainTools } from '../../../../utils/analyseUtil';
import Loading from '../../../../component/Loading/Loading';
import moment from 'moment';
import mathUtil from '../../../../utils/mathUtil';
@observer
class RemainChart extends Component {
  constructor(props) {
    super(props);
    this.lastCurrentCommonFilterObject = [];
  }
  @observable cop = (<TableNullData></TableNullData>);
  @observable chartProps;
  @observable data;
  @observable isLoading = true;
  @observable dataType = 'value';
  @observable res;
  @observable fetchParams;
  changeCommonChart(form) {
    this.chartProps = {
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : this.props.form.dateObj
          ? this.props.form.dateObj.lastDate
            ? mathUtil.keyToDate(this.props.form.dateObj.lastDate)
            : this.props.form.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      ruleContent: JSON.stringify(toJS(form.ruleContent)),
      dims: 'date',
      noDefaultFields: true,
      getFieldByRes: true,
      workViewFilters: this.props.id ? pageHeaderStore.getCommonFilter(this.props.id) : '',
      analysisType: 3,
    };
    this.fetchParams = pageUtil.getFetchParams(this.chartProps);
    util.get(api.analysis.getMixAnalysis, toJS(this.fetchParams), (res) => {
      this.res = res;
      this.data = remainTools.formateChartData(res, this.dataType, this.fetchParams.dims);

      this.isLoading = false;
    });
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      let count = this.props.form.count;
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      let commonFilterObject = pageHeaderStore.commonFilterObject;
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.id));
      // let commonFilterObject = pageHeaderStore.commonFilterObject;
      if (
        (remainTools.checkSaveData(toJS(this.props.form.ruleContent)) &&
          JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject) &&
          this.props.isVisible) ||
        count !== this.lastCount
      ) {
        this.fetchData();
        this.lastCurrentCommonFilterObject = currentCommonFilterObject;
        this.lastCount = count;
      }
    });
  }
  componentDidUpdate(preProps) {
    if (this.props.form.count !== preProps.form.count || JSON.stringify(toJS(this.props.form.dateObj)) !== JSON.stringify(toJS(preProps.form.dateObj))) {
      this.fetchData();
    }
    if (this.props.isVisible !== preProps.isVisible && this.props.isVisible) {
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.id));
      if (
        remainTools.checkSaveData(toJS(this.props.form.ruleContent)) &&
        JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject)
      ) {
        this.fetchData();
        this.lastCurrentCommonFilterObject = currentCommonFilterObject;
      }
    }
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  fetchData() {
    const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.id));
    clearInterval(this.delay);
    this.delay = setTimeout(() => {
      this.isLoading = true;
      this.cop = this.changeCommonChart(toJS(this.props.form));
      this.lastCurrentCommonFilterObject = currentCommonFilterObject;
    }, 800);
  }

  changeDataType = (value) => {
    this.isLoading = true;
    setTimeout(() => {
      this.dataType = value.key;
      this.data = remainTools.formateChartData(this.res, this.dataType, this.fetchParams.dims);
      this.isLoading = false;
    }, 200);
  };
  render() {
    return (
      <React.Fragment>
        <div className="analyseRemainChart-buttonGroup">
          <Select
            size="small"
            style={{ width: 96, fontWeight: 400, border: 'none', marginTop: 16 }}
            labelInValue
            value={{ key: this.dataType }}
            onChange={this.changeDataType}>
            <Select.Option value="value">用户留存数</Select.Option>
            <Select.Option value="ratio">用户留存率</Select.Option>
          </Select>
          {/* <Radio.Group onChange={this.changeDataType} defaultValue={'value'}>
              <Radio.Button value="value">用户留存数</Radio.Button>
              <Radio.Button value="ratio">用户留存率</Radio.Button>
            </Radio.Group> */}
        </div>

        {!this.isLoading ? <CommonChart fullHeight={true} parentData={this.data} showAll={true}></CommonChart> : <Loading></Loading>}
      </React.Fragment>
    );
  }
}
export default RemainChart;
