import React, { Component } from 'react';
import { List, Picker } from 'antd-mobile-v2';
import './index.sass';

class MobilePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }
  render() {
    return (
      <div className="mobilePicker" style={this.props.style}>
        <div className="mobilePicker_icon" style={{ justifyContent: this.props.justifyContent ? this.props.justifyContent : 'flex-start' }}>
          <i className={`iconfont ${this.props.iconName}`}></i>
          {this.props.name ? <span>{this.props.name}</span> : null}
        </div>
        <Picker
          data={this.props.data}
          onChange={(v) => {
            this.setState({ value: v });
          }}
          value={this.props.value || this.state.value}
          onOk={(v) => {
            this.props.onOk(v[0]);
          }}
          cols={1}>
          <List.Item />
        </Picker>
      </div>
    );
  }
}
export default MobilePicker;
