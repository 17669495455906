import boardnull from '../static/images/boardnull.png';
import eventanalyzenull from '../static/images/eventanalyzenull.png';
import formnull from '../static/images/formnull.png';
import funnelnull from '../static/images/funnelnull.png';
import heatmapnull from '../static/images/heatmapnull.png';
import recordnull from '../static/images/recordnull.png';

export const productType = {
  Web: '网站/公众号',
  Android: 'Android应用',
  iOS: 'iOS应用',
  MPWechat: '微信小程序',
};

export const iconfont = {
  export: 'icon-shangxiayou iconfont',
  delete: 'icon-shanchu iconfont delect-icon',
  download: 'iconfont icon-xiazai1',
  add: 'icon-zengjia iconfont',
  search: 'icon-sousuo iconfont',
  arrowRight: 'icon-jiantou iconfont',
  arrowDown: 'icon-jiantou1 iconfont',
  left: 'iconfont icon-iconfontjiantou1',
  right: 'iconfont icon-fanhui',
  play: 'iconfont icon-baseline-play_arrow-',
  auto_play: 'iconfont icon-baseline-autorenew-',
  pause: 'iconfont icon-baseline-pause-px',
  replay: 'iconfont icon-baseline-replay-px',
  forward: 'iconfont icon-baseline-fast_forwar',
  rewind: 'iconfont icon-baseline-fast_rewind',
  skip_next: 'iconfont icon-baseline-skip_next-',
  skip_previous: 'iconfont icon-baseline-skip_previo',
  increase: 'iconfont icon-basic-zhengxu',
  decrease: 'iconfont icon-basic-daoxu',
  close: 'iconfont icon-close',
  user: 'iconfont icon-path-fangke',
  help: 'iconfont icon-bangzhu',
  toggle: 'iconfont icon-zengjia dataToggle',
  collapse: 'iconfont icon-jian collapse-button',
  spread: 'icon-zengjia iconfont collapse-button',
  research: 'iconfont icon-wenjian',
  record: 'iconfont icon-video-camera',
  dropdownArrow: 'iconfont icon-basic-xiala2',
  brush: 'iconfont icon-card-bianji',
  download: 'iconfont icon-xiazai',
  send: 'iconfont icon-zhuanfa',
  edit: 'iconfont icon-card-bianji',
  email: 'iconfont icon-youxiangzhaohui',
  mobile: 'iconfont icon-shouji',
  password: 'iconfont icon-mima',
  refresh: 'iconfont icon-shuaxin',
  loading: 'iconfont icon-shuaxin rotate',
  detail: 'iconfont icon-xiangqing',
  more: 'iconfont icon-menu-gengduo',
  showMore: 'iconfont icon-basic-shouhui1',
  remark: 'iconfont icon-xinxi',
  pv: 'iconfont icon-yanjing',
  uv: 'iconfont icon-path-fangke',
  ev: 'iconfont icon-path-shijiancishu',
  iv: 'iconfont icon-path-shijianfangkeliiang',
  tv: 'iconfont icon-path-pingjunshichang',
  zv: 'iconfont icon-path-zhanbi',
  avgTime: 'iconfont icon-path-pingjunshichang',
  exit: 'iconfont icon-tuichu',
  shangxiayou: 'iconfont icon-path-gongxian',
  warning: 'iconfont icon-warn',
  pc: 'iconfont icon-computer',
  pad: 'iconfont icon-ipad',
  phone: 'iconfont icon-shouji',
  star_full: 'iconfont icon-shoucang1 yellow',
  star_empty: 'iconfont icon-shoucang',
  full_screen: 'iconfont icon-quanping',
  non_full_screen: 'iconfont icon-suoxiaotuichuquanpingshouhui',
  compare: 'iconfont icon-biaodan',
  up: 'iconfont icon-basic-shangsheng',
  down: 'iconfont icon-basic-xiajiang',
  task: 'iconfont icon-toobar-guanjianrenwu',
  cursor: 'iconfont icon-changan',
  link: 'iconfont icon-yemian',
};

export const nullStateData = {
  form: {
    image: formnull,
    more: {
      introduce: '',
      setting: '',
      andMore: '',
    },
    content: [
      {
        iconName: 'iconfont icon-biaodan',
        titleName: '衡量表单的用户体验，有效促进业务增长',
        paragraphName: `您知道有多少访客填写、修改和完成您的表单吗？如果您的网站或APP中有像注册登录或者购买确认之类的表单，您一定会需要了解访客是如何操作的。${process.env.REACT_APP_BDtitle}行为分析能够为您精准统计访客在表单中的操作细节，用数据反映访客在填写过程中的用户体验好坏。`,
      },
      {
        iconName: 'iconfont icon-tongdian',
        titleName: '发现访客填写表单时的痛点，有针对性地改进表单',
        paragraphName: '通过表单分析的数据，您可以快速发现访客在填写过程中遇到了哪些问题，在哪些地方遇到了问题，从而帮助您有针对性地对表单进行改进与优化。',
      },
      {
        iconName: 'iconfont icon-yanzhengchenggong',
        titleName: '验证表单改进后的效果，持续优化获得成功',
        paragraphName:
          '在您优化了表单之后，访客填写表单时的完成率是否有提高呢？访客的用户体验是否有得到提升呢？表单分析将用数据对比为您验证优化效果，让您可以对表单进行持续优化，从而获得产品的成功。',
      },
    ],
  },
  board: {
    image: boardnull,
    more: {
      introduce: '',
      setting: '',
      andMore: '',
    },
    content: [
      {
        iconName: 'iconfont icon-shujumokuai',
        titleName: '随心添加您想掌握的数据模块',
        paragraphName:
          '有自己想要着重关注的数据模块？新建专属您的自建看板，您可以随心添加任何您想要添加的内容，并可任意切换它们的上下顺序，将您最关注的内容置顶。别忘了，您还可以将您的看板分享给您的同事一起查看哦。',
      },
      {
        iconName: 'iconfont icon-suishidongtai',
        titleName: '随时掌握您所关注的内容动态',
        paragraphName: `${process.env.REACT_APP_BDtitle}体验管理云，每日动态一目了然。如某处数据升降波动过大，系统将及时给出提醒，让您随时掌握看板数据动态，监测产品发展动向。`,
      },
      {
        iconName: 'iconfont icon-kanbanbaogao',
        titleName: '随地查看系统生成的看板报告',
        paragraphName: `当您在上下班路上，在会议开始之前，不管您在怎样的场景下，所使用的是PC、平板电脑或是智能手机，打开${process.env.REACT_APP_BDtitle}统计，您都可以查看与设备完美适配的看板报告，关键信息绝不落下。`,
      },
    ],
  },
  heatmap: {
    image: heatmapnull,
    more: {
      introduce: '',
      setting: '',
      andMore: '',
    },
    content: [
      {
        iconName: 'iconfont icon-lijiefangke',
        titleName: '真正理解您的访客',
        paragraphName:
          '热力图可以让您对访客的操作一目了然：访客尝试点击什么地方，如何移动鼠标，滚动屏幕的时候会滚动到页面靠下的什么位置，让您真正了解用户的所想所为。',
      },
      {
        iconName: 'iconfont icon-dongchatongdian',
        titleName: '洞察访客关键痛点',
        paragraphName: '获取您无法从传统报告中获得的洞察力。 轻松快速地发现用户真正关注的地方，他们遇到问题的地方，页面中无用的内容，以及产品内容的吸引力。',
      },
      {
        iconName: 'iconfont icon-zhuanhuahuibao',
        titleName: '获取可观的转化回报',
        paragraphName: '通过热力图发现关键问题后，即使很小的改进也能大幅增加您的产品注册、销售及转化量，以最小的成本充分利用现有的流量，获得可观的转化回报。',
      },
    ],
  },
  funnel: {
    image: funnelnull,
    more: {
      introduce: '',
      setting: '',
      andMore: '',
    },
    content: [
      {
        iconName: 'iconfont icon-fenxizhuanhuashuai',
        titleName: '跟踪分析漏斗转化率，优化流失节点',
        paragraphName:
          '漏斗图可让您随时跟踪分析漏斗每个步骤的浏览量、流失量，每个漏斗的转化次数及收益情况。了解访客在哪个节点流失最多，在哪个漏斗转化最多，从而对特定节点或页面进行针对性的优化。',
      },
      {
        iconName: 'iconfont icon-shishijiance',
        titleName: '实时监测漏斗转化趋势，检验优化结果',
        paragraphName: `${process.env.REACT_APP_BDtitle}行为分析为您实时监测漏斗的转化趋势，有效帮助您进行产品优化前后的转化率对比，验证优化措施的成效。`,
      },
      {
        iconName: 'iconfont icon-loudou',
        titleName: '漏斗目标一键转化为路径，验证访客真实流向',
        paragraphName: `${process.env.REACT_APP_BDtitle}行为分析支持将漏斗目标一键转化为路径进行查看，验证访客是否大都按照您预设的漏斗线路进行转化，访客的真实转化路径纠结有哪些。`,
      },
    ],
  },
  record: {
    image: recordnull,
    more: {
      introduce: '',
      setting: '',
      andMore: '',
    },
    content: [
      {
        iconName: 'iconfont icon-guancha',
        titleName: '身临其境观察访客真实操作',
        paragraphName:
          '操作录像就像眼动追踪，但只需几秒钟即可完成设置，速度更快，成本更低低。 观看真实访问者如何使用您的网站以及他们拥有什么体验。 找出他们离开的原因，他们正在寻找什么，等等。',
      },
      {
        iconName: 'iconfont icon-tigaokeyongxing',
        titleName: '有效提高网站可用性',
        paragraphName: '操作录像完整地记录了访客在网站上的操作过程，您可以身临其境地感受用户是如何在网站上进行操作的，这也是基于真实场景下的产品可用性测试。 ',
      },
      {
        iconName: 'iconfont icon-tigaotiyan',
        titleName: '基于事实提高产品用户体验',
        paragraphName: '依靠事实来证明您所做的更改是否实际改进了您的网站，这可以让您持续增加网站上的转化次数，并且变得更加成功。',
      },
    ],
  },
  eventAnalyze: {
    image: eventanalyzenull,
    more: {
      introduce: '',
      setting: '',
      andMore: '',
    },
    content: [
      {
        iconName: 'iconfont icon-fangkehudong',
        titleName: '衡量访客与产品之间的互动情况',
        paragraphName: '您可以通过事件设置，统计页面任意交互元素的触发情况。衡量您的访客与您的网站或APP之间的互动情况。',
      },
      {
        iconName: 'iconfont icon-dingweishijian',
        titleName: '快速定位事件表现是否正常或是否需要改善',
        paragraphName: `${process.env.REACT_APP_BDtitle}行为统计为您实时监测事件的详情情况，针对发生异常的事件对您进行及时的消息推送提醒，让您随时掌握产品动态。`,
      },
      {
        iconName: 'iconfont icon-shendufenxi',
        titleName: '配合路径、漏斗、交叉等其他功能进行深度分析',
        paragraphName: '在建立事件之后，您还可以配合路径、漏斗、交叉等功能，比较细分用户群体的行为差异，更好地满足您的业务需求。',
      },
    ],
  },
};

const hours = [];
const weeks = [];
const months = [];
const recentDate = [];
const weeksLabel = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];

const date1 = new Date();

for (let i = 0; i < 24; i += 1) {
  hours.push({
    value: i,
    label: `${i}点`,
  });
}
for (let i = 0; i < 7; i += 1) {
  weeks.push({
    value: i,
    label: weeksLabel[i],
    children: hours,
  });
}
for (let i = 0; i < 30; i += 1) {
  months.push({
    value: i,
    label: `${i + 1}号`,
    children: hours,
  });
}
for (let i = 0; i < 30; i += 1) {
  const day = date1.getDate();
  const month = date1.getMonth();
  const year = date1.getFullYear();
  const date2 = new Date(year, month, day + i);
  recentDate.push({
    value: `${date2.getMonth() + 1}-${date2.getDate()}`,
    label: `${date2.getMonth() + 1}月${date2.getDate()}号`,
    children: hours,
  });
}
export const sendTime = [
  {
    value: 'day',
    label: '每天',
    children: hours,
  },
  {
    value: 'week',
    label: '每周',
    children: weeks,
  },
  {
    value: 'month',
    label: '每月',
    children: months,
  },
];

export const auditStatus = {
  1: '待审核',
  2: '审核中',
  3: '未通过',
  4: '通过',
  5: '通过（草稿待审核）',
  6: '通过（草稿审核中）',
  7: '通过（草稿审核未通过）',
  8: '更改待审核',
  9: '删除审核中',
};
export const auditStatusClassName = {
  1: 'blue',
  2: 'orange',
  3: 'red',
  4: 'green',
  5: 'blue',
  6: 'orange',
  7: 'red',
  8: 'blue',
  9: 'orange',
};

export const differentPermissions = {
  1: ['insight', 'dashboard'],
  2: ['design', 'customerJourney'],
  3: ['design', 'blueprint'],
  4: ['wj', 'push'],
  5: ['wj', 'data'],
  6: ['action', 'warning'],
};

export const auditPermMap = {
  archiveEdit: [1],
  edit: [3, 4, 8],
  switch: [4],
  delete: [1, 3, 4, 8],
  submitAudit: [1, 3, 8],
  deleteAudit: [4, 8],
  rejectAudit: [3],
};

export const detailPaths = ['/data/visitor/record/info', '/data/survey/detail/visitor', '/customBoard/detail/visitor', '/action/warningLog/detail'];

export const sourceTypes = {
  2: '触点总览',
  3: '调研',
  4: '问卷',
  101: '普通看板',
  102: '用户旅程地图',
  103: '服务蓝图',
  104: '推送情况分布看板',
  105: '用户数据看板',
  106: '预警看板',
  107: 'iframe看板',
};

export const realSourceTypes = {
  101: 1,
  102: 1,
  103: 1,
  104: 1,
  105: 1,
  106: 1,
  107: 1,
};

export const xssFilterOptions = {
  whiteList: {
    a: ['href', 'title', 'target', 'style'],
    img: ['src', 'style'],
    p: ['style'],
    span: ['style'],
    h1: ['style'],
    h2: ['style'],
    h3: ['style'],
    h4: ['style'],
    h5: ['style'],
    br: ['style'],
    blockquote: ['style'],
    i: ['style'],
    b: ['style'],
    u: ['style'],
    font: ['size', 'style', 'color'],
    strike: ['style'],
    div: ['style'],
    strong: [],
    table: [],
    tr: [],
    td: [],
    tbody: [],
    th: [],
    s: [],
  },
};

export const stringFormulaList = [
  {
    value: '=',
    label: '等于',
  },
  {
    value: '!=',
    label: '不等于',
  },
  {
    value: 'in',
    label: '包含',
  },
  {
    value: 'notIn',
    label: '不包含',
  },
  {
    value: 'is',
    label: '为空',
  },
  {
    value: 'is not',
    label: '非空',
  },
];

export const numberFormulaList = [
  {
    value: '=',
    label: '等于',
  },
  {
    value: '!=',
    label: '不等于',
  },
  {
    value: '<',
    label: '小于',
  },
  {
    value: '>',
    label: '大于',
  },
  {
    value: '<=',
    label: '小于等于',
  },
  {
    value: '>=',
    label: '大于等于',
  },
  {
    value: 'between',
    label: '区间',
  },
  {
    value: 'is',
    label: '为空',
  },
  {
    value: 'is not',
    label: '非空',
  },
];

export const dateFormulaList = [
  {
    value: 'between',
    label: '区间',
  },
  {
    value: 'is',
    label: '为空',
  },
  {
    value: 'is not',
    label: '非空',
  },
];

export const SQL_STRING_TYPES = [
  'CHAR',
  'NCHAR',
  'VARCHAR',
  'NVARCHAR',
  'LONGVARCHAR',
  'LONGNVARCHAR',
  'VARCHAR2',
  'NVARCHAR2',
  'STRING',
  'TINYTEXT',
  'TEXT',
  'NTEXT',
  'MEDIUMTEXT',
  'LONGTEXT',
  'JSON',
  'XML',
  'LINESTRING',
  'MULTILINESTRING',
  'TINYBLOB',
  'MEDIUMBLOB',
  'BLOB',
  'LONGBLOB',
  'CBLOB',
  'BINARY',
  'VARBINARY',
  'LONGVARBINARY',
  'ENUM',
  'SET',
  'NULL',
  'ROWID',
  'FIXEDSTRING',
  'IPV4',
  'IPV6',
  'UUID',
  'String',
];

export const SQL_NUMBER_TYPES = [
  'BIT',
  'TINYINT',
  'SMALLINT',
  'MEDIUMINT',
  'INT',
  'INTEGER',
  'BIGINT',
  'FLOAT',
  'DOUBLE',
  'DOUBLE PRECISION',
  'REAL',
  'DECIMAL',
  'BIT',
  'SERIAL',
  'BOOL',
  'BOOLEAN',
  'DEC',
  'FIXED',
  'NUMBER',
  'NUMERIC',
  'UINT8',
  'UINT16',
  'UINT32',
  'UINT64',
  'INT8',
  'INT16',
  'INT32',
  'INT64',
  'FLOAT32',
  'FLOAT64',
  'DECIMAL32',
  'DECIMAL64',
  'DECIMAL128',
];

export const SQL_DATE_TYPES = ['DATE', 'DATETIME', 'TIMESTAMP', 'TIME', 'YEAR'];

export const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

export const largeFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
  },
};

export const weekOption = [
  {
    value: '1',
    label: '周一',
  },
  {
    value: '2',
    label: '周二',
  },
  {
    value: '3',
    label: '周三',
  },
  {
    value: '4',
    label: '周四',
  },
  {
    value: '5',
    label: '周五',
  },
  {
    value: '6',
    label: '周六',
  },
  {
    value: '7',
    label: '周日',
  },
];

export const auditStatusCNClassName = {
  待审核: 'blue',
  通过: 'green',
  未通过: 'red',
  更改待审核: 'blue',
  审核中: 'orange',
  删除审核中: 'orange',
};

export const periodList = [
  {
    value: 'day',
    label: '天',
  },
  {
    value: 'week',
    label: '周',
  },
  {
    value: 'month',
    label: '月',
  },
  {
    value: 'quater',
    label: '季度',
  },
  {
    value: 'year',
    label: '年',
  },
];

export const dashboardTemplateTypes = {
  0: '普通看板',
  1: '看板模板',
  2: '分析模板',
};

export const passwordReg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[*?!&￥$%^#,./@";:><\[\]}{\-=+_\\|》《。，、？'‘“”~ `]).{8,15}$/;

export const defaultSplitSize = 478;
export const maxSplitSize = defaultSplitSize * 1.5;
