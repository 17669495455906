import CommonChart from '../../containers/Common/CommonChart';
import CommonTable from '../../containers/CommonTable';
import CommonTab from '../../containers/CommonTab';

export default {
  title: '地域分布',
  components: [
    {
      component: CommonTab,
      isCard: true,
      props: {
        tab: [
          {
            name: '城市',
            key: 'city',
            children: [
              // {
              //   title: '地域分布',
              //   component: CommonChart,
              //   col: 24,
              //   props: {
              //     multiple: true,
              //     dims: 'district_city',
              //     fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
              //     orderDesc: true,
              //     currentPage: 1,
              //     pageCount: 10,
              //     orderColumn: 'pv',
              //     mapType: 'city',
              //     type: 'map',
              //   },
              // },
              {
                title: '地域分布',
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'district_city',
                  fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
                  orderDesc: true,
                  currentPage: 1,
                  pageCount: 10,
                  orderColumn: 'pv',
                },
              },
            ],
          },
          {
            name: '省份',
            children: [
              // {
              //   title: '地域分布',
              //   component: CommonChart,
              //   col: 24,
              //   props: {
              //     multiple: true,
              //     fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
              //     dims: 'district_province',
              //     orderDesc: true,
              //     currentPage: 1,
              //     pageCount: 10,
              //     orderColumn: 'pv',
              //     showAll: false,
              //     mapType: 'province',
              //     type: 'map',
              //   },
              // },
              {
                component: CommonTable,
                title: '地域分布',
                col: 24,
                props: {
                  dims: 'district_province',
                  fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
                  orderDesc: true,
                  currentPage: 1,
                  pageCount: 10,
                  orderColumn: 'pv',
                },
              },
            ],
          },
          {
            name: '国家',
            children: [
              // {
              //   title: '地域分布',
              //   component: CommonChart,
              //   col: 24,
              //   props: {
              //     multiple: true,
              //     fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
              //     dims: 'district_country',
              //     orderDesc: true,
              //     currentPage: 1,
              //     pageCount: 10,
              //     orderColumn: 'pv',
              //     showAll: false,
              //     mapType: 'country',
              //     type: 'map',
              //   },
              // },
              {
                component: CommonTable,
                title: '地域分布',
                col: 24,
                props: {
                  fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
                  dims: 'district_country',
                  orderColumn: 'pv',
                  currentPage: 1,
                  pageCount: 10,
                  orderDesc: true,
                },
              },
            ],
          },
        ],
      },
    },
  ],
};
