import React, { Component, Fragment } from 'react';
import { Tooltip } from 'antd';

class WidgetItem extends Component {
  render() {
    const { data, onDragStart, onDragEnd, activeTabKey } = this.props;
    const { name, description, remarks, dashboard } = data;
    const tips = (
      <Fragment>
        <p>{name}</p>
        <p>{description || remarks}</p>
        {<p>引用的看板：{dashboard && dashboard.length ? dashboard.map((item, index) => `${index !== 0 ? '、' : ''}${item.name}`) : '无'}</p>}
      </Fragment>
    );
    return (
      <Tooltip title={tips}>
        <div className="widget-item" title={name} onDragStart={() => onDragStart({ ...data, type: parseInt(activeTabKey) })} onDragEnd={onDragEnd} draggable>
          <i className="iconfont icon-guanjianrenwu" />
          <p>{name}</p>
        </div>
      </Tooltip>
    );
  }
}

export default WidgetItem;
