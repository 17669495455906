import React, { Component } from 'react';
import { Drawer } from 'antd';
import './index.sass';

class UXSDrawer extends Component {
  render() {
    const { id, show, onClose, onHide, className, dialogClassName, title, buttons, children, footer, width, ...props } = this.props;
    return (
      <Drawer
        className={className}
        id={id}
        visible={show}
        onClose={onClose || onHide}
        /* wrapClassName={dialogClassName} */
        title={title}
        /* animation ={false} */
        /* className={`${className} animated ${(className && className.indexOf('fill') !== -1 )? 'fadeInRightNoOpacity':'zoomIn'}`} */
        {...props}
        width={width}
        destroyOnClose={true}
        maskClosable={false}>
        {footer || buttons ? (
          <div className="uxs-drawer-content">
            {children}
            <div className="drawer-footer">
              {buttons &&
                buttons.map(
                  (button, index) =>
                    button && (
                      <button key={index} className="uxs uxs-info" onClick={button.onClick}>
                        {button.label}
                      </button>
                    )
                )}
              {footer}
            </div>
          </div>
        ) : (
          <div>{children}</div>
        )}
      </Drawer>
    );
  }
}

export default UXSDrawer;
