import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import api from '../../../../api';
import util from '../../../../util';
import { Button, Popconfirm, Input, Radio, Cascader, Switch, message, notification, Select, Form, Checkbox, Drawer } from 'antd';
import PageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';

function CustomParametersForm(props) {
  const { getFieldDecorator } = props.form;
  const { visible, onClose, elementInfo } = props;
  const [modalVisible, setModalVisible] = useState(false); //抽屉显隐
  const [num, setNum] = useState('');
  const [isLoading, setLoading] = useState(true); //元素属性
  const [customParameter, setCustomParameter] = useState({
    parameterName: '',
    originSelector: '',
    matchIndex: false,
    description: '',
    prameterType: 'String',
    matchFormula: 'equal',
    aliasName: '',
  }); //自定义参数的数据结构

  useEffect(() => {
    if (elementInfo) {
      setCustomParameter({
        parameterName: '',
        originSelector: elementInfo.data.data.element_selector,
        matchIndex: false,
        description: '',
        parameterType: 'String',
        matchFormula: 'equal',
        aliasName: '',
      });
      var n = elementInfo.data.data.element_selector.lastIndexOf(')');
      setNum(elementInfo.data.data.element_selector[n - 1]);
    }
  }, [elementInfo]);

  useEffect(() => {
    const id = window.location.search.split('&')[0].split('=')[0] === '?id' ? window.location.search.split('&')[0].split('=')[1] : null;
    if (id) {
      util.get(
        api.setting.editCustomParameters,
        {
          id,
          idsite: PageHeaderStore.parameter.idsite,
        },
        (res) => {
          setCustomParameter({
            parameterName: res.parameterName ? res.parameterName : '',
            originSelector: res.originSelector ? res.originSelector : '',
            matchIndex: res.matchIndex ? res.matchIndex : false,
            description: res.description ? res.description : '',
            parameterType: res.parameterType ? res.parameterType : 'String',
            matchFormula: 'equal',
            aliasName: res.aliasName ? res.aliasName : '',
          });
          var n = res.originSelector.lastIndexOf(')');
          n > 0 && res.originSelector[n - 1] !== '*' && setNum(res.originSelector[n - 1]);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, []);
  function submit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const id = window.location.search.split('&')[0].split('=')[0] === '?id' ? window.location.search.split('&')[0].split('=')[1] : '';
        let obj = {
          idsite: PageHeaderStore.parameter.idsite,
          ...customParameter,
        };
        if (id) {
          obj.id = id;
        }
        obj.originSelector = obj.limit && obj.limit.indexOf('order') < 0 ? obj.originSelector.replace(/(.*)\((.*)\)/, '$1(*)') : obj.originSelector;
        util.post(
          api.setting.createCustomParameters,
          {
            anchorType: '3',
            ...obj,
          },
          (res) => {
            message.success('更新成功！');
            window.history.back(-1);
          }
        );
      }
    });
  }

  function changeCustomParameter(type, value) {
    let obj = customParameter;
    switch (type) {
      case 'originSelector':
        obj.originSelector = value;
        break;
      case 'description':
        obj.description = value;
        break;
      case 'parameterName':
        obj.parameterName = value;
        break;
      case 'limit':
        obj.matchIndex = value.length > 0 ? true : false;
        break;
      case 'parameterType':
        obj.parameterType = value;
        break;
      case 'aliasName':
        obj.aliasName = value;
        break;
    }

    setCustomParameter({ ...obj });
  }
  const { Option } = Select;
  const formItemLayout = {
    // 对表单结构进行格式化的变量
    labelCol: {
      xs: { span: 6 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 10 },
    },
  };

  return !isLoading ? (
    <Drawer
      visible={visible}
      onClose={onClose}
      width={800}
      title={
        <div>
          <div>定义自定义属性</div>
          <Button type="primary" htmlType="submit" form="customParameter_form" onClick={submit}>
            保存
          </Button>
        </div>
      }>
      <Form id="customParameter_form" onSubmit={submit}>
        <Form.Item {...formItemLayout} label="属性路径:" colon={false}>
          <Input
            id="selector"
            className="lg-input"
            placeholder="属性路径"
            defaultValue={customParameter.originSelector ? customParameter.originSelector : undefined}
            onChange={(e) => {
              changeCustomParameter('originSelector', e.target.value);
            }}></Input>
        </Form.Item>

        <Form.Item label="限定内容:" {...formItemLayout}>
          <Checkbox.Group
            defaultValue={customParameter.matchIndex ? ['order'] : []}
            onChange={(value) => changeCustomParameter('limit', value)}
            options={[{ label: `顺序为第[${num}]位`, value: 'order' }]}
          />
        </Form.Item>
        <Form.Item {...formItemLayout} label="属性类型:" colon={false}>
          <Select
            className="sm-select"
            onSelect={(labeledValue) => changeCustomParameter('parameterType', labeledValue)}
            defaultValue={customParameter.parameterType ? customParameter.parameterType : 'String'}
            placeholder="请选择属性类型">
            <Option key="Number">数字</Option>
            <Option key="String">字符串</Option>
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} label="自定义属性字段名:" colon={false}>
          {getFieldDecorator('parameterName', {
            rules: [{ required: true, message: '请输入自定义属性字段名' }],
            initialValue: customParameter.parameterName ? customParameter.parameterName : undefined,
          })(
            <Input
              className="md-input"
              placeholder="请输入自定义属性字段名"
              onChange={(e) => {
                changeCustomParameter('parameterName', e.target.value);
              }}></Input>
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="描述:" colon={false}>
          <Input
            className="md-input"
            placeholder="请输入描述"
            defaultValue={customParameter.description ? customParameter.description : undefined}
            onChange={(e) => {
              changeCustomParameter('description', e.target.value);
            }}></Input>
        </Form.Item>
        <Form.Item {...formItemLayout} label="中文别名:" colon={false}>
          <Input
            className="md-input"
            placeholder="请输入中文别名"
            defaultValue={customParameter.aliasName ? customParameter.aliasName : undefined}
            onChange={(e) => {
              changeCustomParameter('aliasName', e.target.value);
            }}></Input>
        </Form.Item>
      </Form>
    </Drawer>
  ) : null;
}

export default Form.create()(CustomParametersForm);
