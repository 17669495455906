import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { autorun, observable, action } from 'mobx';
import { Row, Col, Card, Tooltip } from 'antd';
import mathUtil from '../../utils/mathUtil';
import pageUtil from '../../utils/pageUtil';
import legendUtil from '../../utils/legendUtil';
import util from '../../util';
import api from '../../api';
import Comparision from '../Comparision';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import './index.sass';
import Loading from '../../component/Loading/Loading';

@observer
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: props.defaultSelectedKey,
    };
  }
  componentDidMount() {
    if (this.props.parentData) {
      if (this.props.parentData.field) {
        this.fieldsLength = this.props.parentData.field.length;
      }
      this.data = this.props.parentData;
      if (!this.props.type) {
        this.type = 'row';
      }
      this.stopLoading();
    } else if (this.props.apiParentData) {
      this.data = mathUtil.getSummaryData(this.props.apiParentData, { fields: this.props.fields });
      this.stopLoading();
    } else {
      this.disposer = autorun(() => {
        this.isLoading = true;
        util.get(this.props.customApi || api.pack.newIndex, pageUtil.getFetchParams(this.props), (res) => {
          this.data = mathUtil.getSummaryData(res, pageUtil.getFetchParams(this.props), this.props.comparision, this.props.noComparision);
          this.stopLoading();
        });
      });
      this.props.runOnce && this.disposer();
    }
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  @observable data;
  @observable isLoading = true;

  @action.bound stopLoading() {
    this.isLoading = false;
  }
  @action.bound getTitle(res) {
    if (res instanceof Array) {
      return res.join('');
    }
    return res;
  }
  getComparisionDesc(name, data) {
    let obj = { compareArray1: null, compareArray2: null };
    const date = this.props.tooltipDate || pageHeaderStore.parameter.date;
    let dateArray1 = date.split(',')[0].split('-');
    let dateArray2 = date.split(',')[1].split('-');
    let { compareArray1, compareArray2 } = obj;
    compareArray1 = mathUtil
      .formatDate(date)
      .split('-')[0]
      .split('.');
    compareArray2 = mathUtil
      .formatDate(date)
      .split('-')[1]
      .split('.');
    function changeDateFormate(arrA, arrB) {
      if (arrA[0] === arrB[0]) {
        if (arrA[1] === arrB[1]) {
          return `${arrA.join('-')}至${arrB[2]}日`;
        } else {
          return `${arrA.join('-')}至${arrB[1]}-${arrB[2]}日`;
        }
      } else {
        return `${arrA.join('-')}至${arrB.join('-')}`;
      }
    }
    return `${changeDateFormate(dateArray1, dateArray2)}这段时间内${legendUtil.getTitle(name)}${legendUtil.formateTitle(
      name,
      data.value
    )}，对比${changeDateFormate(compareArray1, compareArray2)}${data.compare > 0 ? '上升' : '下降'}${mathUtil.percentFrom04f(Math.abs(data.compare))} `;
  }

  onItemClick(selectedKey) {
    if (this.props.onItemClick) {
      this.props.onItemClick(selectedKey);
      this.setState({
        selectedKey,
      });
    }
  }

  render() {
    return (
      <div className={`commonSummary ${this.props.from ? this.props.from : ''}  ${this.props.className ? this.props.className : ''}`}>
        <Row gutter={this.props.gutter || 0}>
          {!this.isLoading ? (
            this.data.field.map((item, index) => {
              // 有环比的情况
              if (this.data.data[index] && (this.data.data[index].compare || this.data.data[index].compare === 0)) {
                return (
                  <Col xs={12} lg={6} xl={4} xxl={3} className="summary-col" key={index}>
                    <Card bordered={false}>
                      <div className={`${this.props.type === 'dashboard' && 'summary-content'}`}>
                        <div className="summaryValue">
                          {!this.props.noTooltipsFields || this.props.noTooltipsFields.indexOf(item) < 0 ? (
                            <Tooltip placement="bottomLeft" title={this.getComparisionDesc(item, this.data.data[index])} mouseEnterDelay={0.5}>
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[0]} </span>
                              <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[1]} </span>
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[2]} </span>
                              <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[3]} </span>
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[4]} </span>
                              <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[5]} </span>
                            </Tooltip>
                          ) : (
                            <span>
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[0]} </span>
                              <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[1]} </span>
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[2]} </span>
                              <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[3]} </span>
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[4]} </span>
                              <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[5]} </span>
                            </span>
                          )}
                        </div>
                        <Comparision notShowToolTips={true} number={this.data.data[index].compare} />
                      </div>
                      {!this.props.noTitle && (
                        <div className={`summaryTitle ${this.props.from ? this.props.from : ''}`}>
                          <Tooltip placement="bottom" title={legendUtil.getDesc(item)} mouseEnterDelay={0.5}>
                            {this.data.legend && this.data.legend[index]
                              ? this.data.legend[index]
                              : item === 0 || item === null || item === ''
                              ? '-'
                              : legendUtil.getTitle(item)}
                          </Tooltip>
                        </div>
                      )}
                      {this.data.data[index].hasOwnProperty('countrywideValue') && this.data.data[index].countrywideValue !== '' && (
                        <div className="countrywide-value">全国 {legendUtil.formateTitle(item, this.data.data[index].countrywideValue)}</div>
                      )}
                    </Card>
                  </Col>
                );
              }
              // 无环比的状态
              else {
                return (
                  <Col xs={12} lg={6} xl={4} lg={3} className="summary-col" key={index} onClick={() => this.onItemClick(item)}>
                    <Card bordered={false} className={`${this.state.selectedKey === item ? 'selected-item' : ''}`}>
                      <div className="summaryValue" title={legendUtil.formateTitle(item, this.data.data[index].value)}>
                        {this.data.data[index] ? (
                          <span>
                            {this.props.noUnit ? (
                              <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, false)} </span>
                            ) : (
                              <Fragment>
                                <span className="summary-item">{legendUtil.formateTitle(item, this.data.data[index].value, true)[0]} </span>
                                <span className="summary-unit">{legendUtil.formateTitle(item, this.data.data[index].value, true)[1]} </span>
                              </Fragment>
                            )}
                          </span>
                        ) : (
                          '-'
                        )}
                      </div>
                      {!this.props.noTitle && (
                        <div className="summaryTitle">
                          {this.data.legend && this.data.legend[index]
                            ? this.data.legend[index]
                            : this.props.noComparision
                            ? legendUtil.getTitle(item)
                            : item === 0 || item === null || item === ''
                            ? '-'
                            : legendUtil.getTitle(item)}
                        </div>
                      )}
                      {this.data.data[index].hasOwnProperty('countrywideValue') && this.data.data[index].countrywideValue !== '' && (
                        <div className="countrywide-value">全国 {legendUtil.formateTitle(item, this.data.data[index].countrywideValue)}</div>
                      )}
                    </Card>
                  </Col>
                );
              }
            })
          ) : (
            // 空数据的状态
            <Col xs={12} sm={12} md={12} lg={4} className="summary-col">
              <Loading type="summary"></Loading>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
export default Main;
