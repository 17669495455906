import React, { Component } from 'react';
import './Card.sass';
import { observer } from 'mobx-react';
import { observable, action, computed, toJS } from 'mobx';
import intl from 'react-intl-universal';

@observer
class CardTool extends Component {
  constructor(props) {
    super(props);
    this.onRefresh = this.props.onRefresh ? this.props.onRefresh.bind(this, 'refresh') : null;
  }

  render() {
    return (
      <div className="card-tools" style={{ opacity: '1 !important' }}>
        {this.props.customTool && this.props.customTool.map((item, index) => React.cloneElement(item, { key: index }))}
        <i className={'iconfont icon-shuaxin'} onClick={this.onRefresh} />
        <i
          className={'iconfont icon-close'}
          onClick={() => {
            this.props.closeCard(this.props.onClose);
          }}
        />
      </div>
    );
  }
}

@observer
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: this.props.defaultCollapsed ? true : false,
    };
  }

  @observable
  isCloseCard = false;

  @observable
  customTool;

  @observable
  customTool2 = this.props.card.customTool;

  @observable
  content = this.props.card.content;

  @action.bound
  closeCard() {
    this.props.onClose && this.props.onClose(() => (this.content = null));
  }

  @action.bound
  getCustomTool(element) {
    this.customTool = element;
  }

  @action.bound
  autoRefresh() {
    /*当this.props.content里面的内容包含@observable属性，
           如漏斗的overview，使用this.content代替this.props.card.content时，
           @observable就会失效
        */
    let content = this.content;
    this.content = null;
    setTimeout(() => {
      this.content = content;
    });
  }

  @computed
  get getAllCustomTool() {
    let arr = this.customTool;
    let arr2 = this.customTool2;
    if (this.customTool && !Array.isArray(toJS(this.customTool))) {
      arr = [this.customTool];
    }
    if (this.customTool2 && !Array.isArray(toJS(this.customTool2))) {
      arr2 = [this.customTool2];
    }
    if (arr && arr2) {
      return [...arr, ...arr2];
    } else {
      return arr || arr2;
    }
  }

  collapsedToggle = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  };

  render() {
    const { collapsed, customCollapsedToggle, collapsedInBottom } = this.props;
    const isCollapsed = typeof this.props.isCollapsed !== 'undefined' ? this.props.isCollapsed : this.state.isCollapsed;
    return (
      !this.isCloseCard && (
        <div
          className={`card ${this.props.card.horizontal ? 'horizontal-card' : ''} ${this.props.className ? this.props.className : ''}`}
          id={this.props.id || ''}>
          {this.props.card.title && (
            <div
              className={`card-title ${collapsed ? 'collapsed-title' : ''}`}
              onClick={() => {
                collapsed && !collapsedInBottom && (customCollapsedToggle ? customCollapsedToggle() : this.collapsedToggle());
              }}>
              <h5>{typeof this.props.card.title === 'string' && intl.get(this.props.card.title) ? intl.get(this.props.card.title) : this.props.card.title}</h5>
              {this.props.card.tools && (
                <CardTool
                  tools={this.props.card.tools}
                  onRefresh={this.props.onRefresh || this.autoRefresh}
                  closeCard={this.closeCard}
                  customTool={this.getAllCustomTool}
                />
              )}
              {collapsed && !collapsedInBottom && <span className="collapse-tool color-link">{isCollapsed ? '展开' : '收起'}</span>}
            </div>
          )}
          <div className={`card-content ${isCollapsed && !customCollapsedToggle ? 'card-content-collapsed' : ''}`} style={this.props.style}>
            {!isCollapsed &&
              (this.props.board
                ? this.content &&
                  this.content.props.children.map((child) =>
                    React.cloneElement(child, {
                      getCustomTool: this.getCustomTool,
                    })
                  )
                : this.props.card.content)}
            <div>{this.props.card.addition}</div>
          </div>
          {collapsedInBottom && (
            <div
              onClick={() => {
                collapsed && (customCollapsedToggle ? customCollapsedToggle() : this.collapsedToggle());
              }}
              style={{ display: 'flex', cursor: 'pointer', fontSize: '16px', padding: '16px 20px', flexDirection: 'row-reverse' }}>
              {collapsed && <span className="collapse-tool color-link">{isCollapsed ? '展开' : '收起'}</span>}
            </div>
          )}
        </div>
      )
    );
  }
}

export { Card };
