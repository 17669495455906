import ShareList from '../ShareList'
import CommonTab from '../../containers/CommonTab'

export default {
  title: '分享管理',
  components: [
    {
      isCard: true,
      // title: '分享管理',
      component: CommonTab,
      className: 'single-card',
      props: {
        tab: [
          {
            name: '我的分享',
            children: [
              {
                component: ShareList,
                props: {
                  type: 'mine'
                },
                col: 24
              }
            ]
          },
          {
            name: '分享给我的',
            children: [
              {
                component: ShareList,
                props: {
                  type: 'other'
                },
                col: 24
              }
            ]
          }
        ]
      }
    }
  ]
}
