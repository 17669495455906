import React, { FC, forwardRef } from 'react';
import { Radio as AntRadio } from 'antd';
import '../Control/Panel/Layouts/Layouts.less';
const RadioGroup = AntRadio.Group;
const RadioButton = AntRadio.Button;

const Radio = ({ control, value, size, onChange, options }, ref) => {
  const RadioOption = control.radioType === 'button' ? RadioButton : AntRadio;
  return (
    <div className="controlRadio">
      <RadioGroup value={value} buttonStyle="solid" onChange={onChange} {...(size && { size })} ref={ref}>
        {options.map((o) => (
          <RadioOption key={o.value} value={o.value}>
            {o.text}
          </RadioOption>
        ))}
      </RadioGroup>
    </div>
  );
};

export default forwardRef(Radio);
