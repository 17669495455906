import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, autorun, action } from 'mobx';
import * as echarts from 'echarts/lib/echarts';
import { FunnelChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import api from '../../../api';
import util from '../../../util';
import mathUtil from '../../../utils/mathUtil';
import pageUtil from '../../../utils/pageUtil';
import legendUtil from '../../../utils/legendUtil';
import pageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import Loading from '../../../component/Loading/Loading';
import './index.sass';
const getFunnelColorById = (type) => {
  const bodyId = document.getElementsByTagName('body')[0].id;
  const IDGroup = ['default', 'ipsos', 'sams'];
  const colorResult = (colorArr) => (IDGroup.indexOf(bodyId) >= 0 ? colorArr[IDGroup.indexOf(bodyId)] : colorArr[0]);
  let color;
  if (type === 'funnelMaxColor') {
    color = ['rgba(242,48,85,0.8)', '#FF6b5c', '#FF6b5c'];
  } else if (type === 'funnelNotMaxColor') {
    color = ['rgb(20,196,162)', '#1F80DE', '#1F80DE'];
  } else if (type === 'innerFunnelMaxColor') {
    color = ['rgba(242,48,85,0.6)', 'rgba(255,107,92,0.8)', 'rgba(255,107,92,0.8)'];
  } else if (type === 'innerFunnelNotMaxColor') {
    color = ['rgba(20,196,162, 0.5)', 'rgba(31,128,222.0.5)', 'rgba(31,128,222.0.5)'];
  } else {
    color = ['#7A8493', '#7A8493', '#7A8493'];
  }

  return colorResult(color);
};
@observer
class Main extends Component {
  constructor() {
    super();
    this.pattern = new RegExp('[\u4E00-\u9FA5]+');
    this.myChart = React.createRef();
    echarts.use([TitleComponent, TooltipComponent, GridComponent, FunnelChart, CanvasRenderer]);
  }
  @observable data;
  @observable lastStep;
  @observable listArr;

  componentDidMount() {
    this.disposer = (x) => x;
    if (this.props.data) {
      this.data = Array.from(this.props.data);
      let dataLength = this.data.length;
      this.listArr = new Array(dataLength);
      this.listArr.fill({ funnelStepEntryList: [], funnelStepExitList: [] });
      if (dataLength >= 2) {
        this.lastStep = this.data.splice(dataLength - 1, 1);
      }
      var myChart = echarts.init(this.myChart.current);
      var option = this.createChartOption(this.data);
      myChart.setOption(option);
    } else {
      this.disposer = autorun(() => {
        this.data = null;
        let param = pageUtil.getFetchParams({
          filters: [{ dim: 'funnelSteps', value: `${this.props.id ? this.props.id : util.getQueryString('key')}`, formula: '=' }],
          dims: 'funnelSteps',
          fields:
            'funnelStepEntryVisitors,funnelStepEntryVisitors,funnelStepConversion,funnelStepConversionVisitors,funnelStepConversionAvgTime,funnelStepMiss,funnelStepMiss,funnelStepConversionRate,funnelStepConversionSiteRate,stepName',
        });
        util.get(api.pack.newIndex, param, (res) => {
          this.data = res.data;
          let dataLength = this.data.length;
          this.listArr = new Array(dataLength);
          this.listArr.fill({ funnelStepEntryList: [], funnelStepExitList: [] });
          if (dataLength >= 2) {
            this.lastStep = this.data.splice(dataLength - 1, 1);
          }
          var myChart = echarts.init(this.myChart.current);
          var option = this.createChartOption(this.data);
          myChart.setOption(option);
        });
      });
    }
  }

  componentWillUnmount() {
    this.disposer();
  }

  getMaxRate(data) {
    let maxExitRate = [];
    data.forEach((item) => maxExitRate.push(isNaN(parseInt(item.funnelMissRate, 10)) ? 0 : item.exit_rate));
    return maxExitRate.indexOf(Math.max.apply(null, maxExitRate));
  }

  getMaxNum(data) {
    let maxExitNum = [];
    data.forEach((item) => maxExitNum.push(parseInt(item.funnelStepMiss, 10)));
    return maxExitNum.indexOf(Math.max.apply(null, maxExitNum));
  }

  getMaxSum(data) {
    let maxExitNum = [];
    data.forEach((item) => maxExitNum.push(parseInt(item.funnelStepMiss, 10)));
    return Math.max.apply(null, maxExitNum);
  }

  @action.bound getList(visible, fields, stepNum, totalNum) {
    if (visible) {
      let list = [];
      util.get(
        api.pack.funnelentryoroutlist,
        {
          idsite: pageHeaderStore.parameter.idsite,
          date: pageHeaderStore.parameter.date,
          lastDate: pageHeaderStore.parameter.lastDate,

          userGroupId: pageHeaderStore.parameter.userGroupId,
          fields: fields,
          filters: JSON.stringify([
            { dim: 'funnel', value: this.props.id ? this.props.id : util.getQueryString('key'), formula: '=' },
            { dim: 'funnel', value: stepNum, formula: 'stepNum' },
            { dim: 'funnel', value: totalNum, formula: 'totalNum' },
          ]),
        },
        (res) => {
          for (var key in res[fields]) {
            list.push({ name: key, value: res[fields][key] });
          }
          this.listArr[stepNum - 1] = this.listArr[stepNum - 1] ? this.listArr[stepNum - 1] : {};
          this.listArr[stepNum - 1][fields] = list;
        }
      );
    }
  }

  // getValue(index) {
  //   this.listArr && this.listArr[index] && this.listArr[index].funnelStepEntryList ? this.listArr[index].funnelStepEntryList : [];
  // }

  createChartOption(data) {
    return {
      tooltip: {
        backgroundColor: 'rgba(52,60,73,0.8)',
        padding: [12, 10, 12, 16],
        textStyle: {
          lineHeight: 24,
          fontWeight: 400,
        },
        // 满足交互奇葩的需求,tooltips不允许遮盖echart以外的组件
        position: function(pos, params, dom, rect, size) {
          // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
          var obj = { top: 60 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 50;
          return obj;
        },
      },
      calculable: true,
      series: [
        {
          name: '漏斗图',
          type: 'funnel',
          left: 0,
          top: 0,
          bottom: 28,
          width: '100%',
          height: data && `${160 * data.length}`,
          min: 0,
          max: data && this.getMaxSum(data) * 1.1,
          minSize: '0%',
          maxSize: '80%',
          sort: 'none',
          gap: 1,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              borderColor: '#fff',
              borderWidth: 1,
            },
          },
          data:
            data &&
            data.map((item, index) => {
              return {
                value: parseInt(item.funnelStepMiss, 10) + this.getMaxSum(data) * 0.05,
                name: item.stepName,
                tooltip: {
                  trigger: 'item',
                  formatter: `<div style="width:150px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;">本步骤退出数: <span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(
                    'funnelStepMiss',
                    item.funnelStepMiss
                  )}</span></div>`,
                },
                itemStyle: {
                  normal: {
                    color: index === this.getMaxNum(data) ? getFunnelColorById('innerFunnelMaxColor') : getFunnelColorById('innerFunnelNotMaxColor'),
                  },
                },
              };
            }),
        },
        {
          name: '漏斗图',
          type: 'funnel',
          left: 0,
          top: 0,
          bottom: 28,
          width: '100%',
          height: data && `${160 * data.length}`,
          min: 0,
          max: data && this.getMaxSum(data) * 1.1,
          minSize: '0%',
          maxSize: '80%',
          sort: 'none',
          gap: 1,
          label: {
            normal: {
              show: true,
              position: 'inside',
              textBorderWidth: 0,
              rich: {
                a: {
                  fontSize: 12,
                  borderWidth: 1,
                  borderRadius: 10,
                  padding: [0, 0, 2, 0],
                  width: 16,
                  height: 14,
                },
                b: {
                  fontSize: 16,
                  lineHeight: 24,
                  padding: [0, 0, 2, 4],
                },
              },
            },
          },
          itemStyle: {
            normal: {
              borderColor: '#fff',
              borderWidth: 1,
            },
          },
          data:
            data &&
            data.map((item, index) => {
              return {
                value: parseInt(item.funnelStepConversionVisitors, 10) + this.getMaxSum(data) * 0.05,
                name: parseInt(item.funnelStepConversionVisitors, 10),
                tooltip: {
                  trigger: 'item',
                  formatter: `<div style="width:150px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;">本步骤退出数: <span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(
                    'funnelStepMiss',
                    item.funnelStepMiss
                  )}</span></div>`,
                },
                label: {
                  normal: {
                    formatter: `{b|${item.stepName} } \n {b| ${mathUtil.numberFormate(item.funnelStepConversionVisitors)} }\n {b| 访客数}`,
                    rich: {
                      a: {
                        color: index === this.getMaxNum(data) ? '#fff' : '#050A13',
                        borderColor: index === this.getMaxNum(data) ? '#fff' : '#050A13',
                      },
                      b: {
                        color: index === this.getMaxNum(data) ? '#fff' : '#050A13',
                      },
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: index === this.getMaxNum(data) ? getFunnelColorById('funnelMaxColor') : getFunnelColorById('funnelNotMaxColor'),
                  },
                },
              };
            }),
        },
      ],
    };
  }

  render() {
    return (
      <div className="funnel-card" style={this.data && { height: this.props.fullHeight ? '100%' : `${160 * (this.data.length + 1) + 60}px` }}>
        {/* <ReactEchartsCore
          className="e-funnel"
          echarts={echarts}
          style={this.data && { height: this.props.fullHeight ? '100%' : `${160 * this.data.length}px` }}
          option={this.createChartOption}
          theme={'wonderland'}
          opts={{ renderer: 'svg' }}
        /> */}
        {(this.data || this.props.data) && (
          <div ref={this.myChart} className="e-funnel" style={{ height: `${160 * (this.props.data ? this.props.data.length : this.data.length)}px` }}></div>
        )}
        <table style={this.props.fullHeight && { height: '100%' }} className="uxs funnel-table">
          <tbody>
            {this.data &&
              this.data.length >= 1 &&
              this.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td key={'info' + index} className="info-content">
                      <div className="funnel-table-data">
                        <div>
                          <div className="entry-list">
                            {/* {item.funnelStepEntryVisitors !== 0 ? (
                              this.listArr &&
                              this.listArr[index] && (
                                <Popover
                                  overlayClassName="entryUrl-card"
                                  placement="bottom"
                                  onVisibleChange={(visible) => {
                                    !this.listArr[index].funnelStepEntryList.length &&
                                      this.getList(visible, 'funnelStepEntryList', index + 1, item.funnelStepEntryVisitors);
                                  }}
                                  content={
                                    <List
                                      bordered
                                      loading={!this.listArr[index].funnelStepEntryList.length}
                                      dataSource={this.listArr && this.listArr[index] && this.listArr[index].funnelStepEntryList}
                                      renderItem={(el) => (
                                        <List.Item>
                                          <div className="list-content">
                                            <a
                                              href={`${this.pattern.test(el.name) ? eval('javascript:;') : '//' + el.name}`}
                                              className={`${this.pattern.test(el.name) && 'unable-link'}`}>
                                              {el.name}
                                            </a>
                                            <span>{el.value}</span>
                                          </div>
                                        </List.Item>
                                      )}
                                    />
                                  }> */}
                            {/* <p className="info-tips">访客数：{item.funnelStepEntryVisitors ? mathUtil.numberFormate(item.funnelStepEntryVisitors) : '0'}</p> */}
                            <p>访客数：{item.funnelStepEntryVisitors ? mathUtil.numberFormate(item.funnelStepEntryVisitors) : '0'}</p>
                            {/* </Popover> */}

                            {/* ) ) : (<p>访客数：{item.funnelStepEntryVisitors ? mathUtil.numberFormate(item.funnelStepEntryVisitors) : '0'}</p> */}
                            {/* )} */}
                          </div>
                          <p>步骤转化访客数：{item.funnelStepConversionVisitors ? mathUtil.numberFormate(item.funnelStepConversionVisitors) : '0'}</p>
                          <p>步骤转化次数：{item.funnelStepConversion ? mathUtil.numberFormate(item.funnelStepConversion) : '0'}</p>
                          <p>步骤转化率：{isNaN(parseInt(item.funnelStepConversionRate)) ? '0%' : mathUtil.percentFrom04f(item.funnelStepConversionRate)}</p>
                          <p>
                            步骤平均完成时长：
                            {item.funnelStepConversionAvgTime && legendUtil.formateTitle('funnelStepConversionAvgTime', item.funnelStepConversionAvgTime)}
                          </p>
                          <div className="entry-list">
                            <p>流失访客数：{item.funnelStepMiss ? mathUtil.numberFormate(item.funnelStepMiss) : '0'}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="funnel-btn-content">
                      <span className="funnel-step-analyse-link">
                        {/* <Link to={`/task/funnel/${this.props.id}/step/${index}?pageName=${this.props.funnelName}&stepName=${item.stepName}`}>自动分析</Link> */}
                      </span>
                    </td>
                    <td className="funnel-conversion-rate">
                      {isNaN(parseInt(item.funnelStepConversionRate, 10)) ? '0%' : mathUtil.percentFrom04f(item.funnelStepConversionRate)}
                    </td>
                  </tr>
                );
              })}
            {this.lastStep && this.data && (
              <tr>
                <td className="funnel-last-step">
                  <div className="funnel-last-step-content">
                    <p className="funnel-last-step-title">
                      转化目标：
                      {this.lastStep[0].stepName}
                    </p>
                    <div className="funnel-last-step-data" style={{ backgroundColor: getFunnelColorById('funnelNotMaxColor') }}>
                      <p>访客数：{legendUtil.formateTitle('funnelStepConversion', this.lastStep[0].funnelStepConversion)}</p>
                      <p>
                        总转化率：
                        {isNaN(parseInt(this.lastStep[0].funnelStepConversionRate, 10))
                          ? '0%'
                          : mathUtil.percentFrom04f(this.lastStep[0].funnelStepConversionRate)}
                      </p>
                    </div>
                  </div>
                </td>
                {/* <td className="funnel-btn-content">
                  <span className="funnel-step-analyse-link">
                    <Link to={`/task/funnel/${this.props.id}/step/${this.data.length}?pageName=${this.props.funnelName}&stepName=${this.lastStep[0].stepName}`}>
                      自动分析
                    </Link>
                  </span>
                </td> */}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Main;
