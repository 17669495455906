import React, { Component } from 'react';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import util from '../../util';
import api from '../../api';
import './index.sass';
import ChildrenFilter from '../../containers/PageHeaderFilters/Setting/Setting/ChildrenFilter/index';
import { message } from 'antd';
import moment from 'moment';

import EventSelect from '../../component/EventSelect/index';
import RemainFormStore from './store.js';
import { remainTools } from '../../utils/analyseUtil';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import Loading from '../../component/Loading/Loading';
import DimSelect from '../../component/DimSelect';
import AnalyseForm from '../AnalyseForm';
import AnalyseLayout from '../AnalyseLayout';
import AnalyseChartSelect from '../../component/AnalyseChartSelect/index';
import RemainChartGroup from './RemainChartGroup';
import customBoardUtil from '../../utils/customBoardUtil';
@observer
class AnalyseRemainForm extends Component {
  constructor(props) {
    super(props);
    this.formStore = new RemainFormStore();
  }
  @observable isLoading = true;
  @observable chartName = null;

  saveOrUpdate = (otherParam, callback, saveAs) => {
    let { ruleContent, dim, dateObj } = this.formStore.form;
    let fetchParams = {
      idsite: pageHeaderStore.parameter.idsite,
      dims: dim.join(',') === '' ? `date_${pageHeaderStore.parameter.period}` : dim.join(','),
      ruleContent: JSON.stringify(toJS(ruleContent)),
      ...otherParam,
      chartType: this.formStore.chartType,
      type: 3,
      customizeParam: JSON.stringify({ dateObj }),
    };
    if (this.formStore.id && !saveAs) {
      fetchParams['id'] = this.formStore.id;
    }
    util.post(api.analysis.saveOrUpdate, fetchParams, callback);
  };
  rulesForSave = () => {
    // 保存前的校验

    if (remainTools.checkSaveData(toJS(this.formStore.form.ruleContent))) {
      return true;
    } else {
      message.info('请补充筛选条件');
      return false;
    }
  };
  // 获取该事件管理的配置
  @action.bound getEventConfig(id, name) {
    if (id === 'default') {
      this.isLoading = true;
      this.chartName = null;
      this.formStore.init();
      this.formStore.setId(null);
      this.formStore.count++;
      this.formStore.chartType = 1;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    } else {
      this.isLoading = true;
      this.chartName = name;
      util.get(
        api.analysis.info,
        {
          ids: id,
        },
        (res) => {
          this.formStore.chartType = res[0].chartType;
          this.formStore.customizeParam = res[0].customizeParam ? JSON.stringify(res[0].customizeParam) : '';
          this.formStore.setRuleContent(JSON.parse(res[0].ruleContent));
          this.formStore.setId(res[0].id);
          this.formStore.form.dim[0] = res[0].dims.includes('date') ? '' : res[0].dims;
          this.formStore.form.remarks = res[0].remarks ? '' : res[0].remarks;
          this.formStore.form.name = res[0].name;

          this.formStore.count++;
          this.isLoading = false;
        }
      );
    }
  }
  @action.bound changeChartType(value) {
    this.formStore.chartType = customBoardUtil.changeNumberToType(value);
  }
  // };
  EventAndFilterSelect = (index) => (
    <div className="eventAndFilterSelect">
      <EventSelect
        useType={'onlyEvent'}
        eventAndTargetGroup={toJS(this.formStore.form.ruleContent[0].rules[index].conditions)}
        eventValue={toJS(this.formStore.form.ruleContent[0].rules[index].conditions[0])}
        index={index}
        changeEventAndTarget={this.formStore.changeBeforeEvent}
        noTargetSelect={true}></EventSelect>
      <ChildrenFilter
        filterCount={index}
        isShow={true} //判断是否显示
        eventValue={this.formStore.form.ruleContent[0].rules[index].conditions}
        type={'default'} //type
        filter={this.formStore.form['childrenFilter']} //二维数组
        changeFilterLogic={this.formStore.changeFilterLogic}
        needLogic={true}
        filterItemLogic={this.formStore.form['filterItemLogic']} //数组
        setChildrenFilter={this.formStore.setChildrenFilter}></ChildrenFilter>
    </div>
  );
  render() {
    const formItemLayout = {
      labelCol: {
        xl: { span: 24 },
        lg: { span: 24 },

        md: { span: 24 },
      },
      wrapperCol: {
        lg: { span: 24 },
        md: { span: 24 },
      },
    };

    return (
      <React.Fragment>
        <AnalyseLayout
          {...this.props}
          layoutTitle="留存分析"
          chartName={this.chartName}
          type={3}
          // 保存回调
          saveOrUpdate={this.saveOrUpdate}
          // 保存规则回调
          rulesForSave={this.rulesForSave}
          // 选择时回调函数
          getEventConfig={this.getEventConfig}
          // 事件的名字
          eventName={'留存分析'}
          chartName={this.chartName}
          id={this.formStore.id}
          chartConfig={this.formStore.form}
          analyseSelectCard={
            <Form {...formItemLayout}>
              <AnalyseForm
                formArr={[
                  {
                    label: '初始行为',
                    component: !this.isLoading ? this.EventAndFilterSelect(0) : <Loading size="small"></Loading>,
                  },

                  {
                    label: '留存行为',
                    component: !this.isLoading ? this.EventAndFilterSelect(1) : <Loading size="small"></Loading>,
                  },
                  {
                    label: '细分维度',
                    component: (
                      <DimSelect
                        analysisType={3}
                        eventAndTargetGroup={[
                          toJS(this.formStore.form.ruleContent)[0]['rules'][0]['conditions'][0],
                          toJS(this.formStore.form.ruleContent)[0]['rules'][1]['conditions'][0],
                        ]}
                        allowClear={true}
                        dimItem={this.formStore.form.dim[0]}
                        dimIndex={0}
                        setSubDividedDim={this.formStore.setSubDividedDim}></DimSelect>
                    ),
                  },
                ]}></AnalyseForm>
            </Form>
          }
          analyseChartContent={
            <div className="analyseRemain_content">
              <div className={`analyseLayout_content-chartGroup-chart ${this.formStore.chartType === 8 && 'scroll'}`}>
                <RemainChartGroup
                  index={null}
                  chartType={this.formStore.chartType}
                  form={this.formStore.form}
                  setDateObj={this.formStore.setDateObj}
                  isLoading={this.isLoading}></RemainChartGroup>
              </div>
            </div>
          }
          analyseChartConfig={{
            chartTypeSet: {
              show: true,
              configName: '图表类型',
              component: (
                <AnalyseChartSelect selectType="analyseRemain" chartType={this.formStore.chartType} changeChartType={this.changeChartType}></AnalyseChartSelect>
              ),
            },
          }}></AnalyseLayout>
      </React.Fragment>
    );
  }
}
const AnalyseRemain = Form.create()(AnalyseRemainForm);
export default AnalyseRemain;
