import React, { Component } from 'react';
import DropCard from './DropCard';

const ITEM_HEIGHT = 36;
const PADDING = 12;
const BOX_WIDTH = 368;
const BOX_MIN_HEIGHT = 62;

class WidgetItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prepareConfig: this.props.combineConfig[this.props.index],
      prepareIndex: -1,
      entering: false,
      active: false,
      draggindex: -1,
      dragLeaving: false,
    };
    this.container;
    this.x = 0;
    this.y = 0;
  }

  componentDidUpdate(preProps) {
    if (this.props.dragItem && !preProps.dragItem) {
      this.setState({
        active: true,
      });
    } else if (!this.props.dragItem && preProps.dragItem) {
      this.setState({
        active: false,
      });
    }
    if (JSON.stringify(this.props.combineConfig[this.props.index]) !== JSON.stringify(preProps.combineConfig[preProps.index])) {
      this.setState({
        prepareConfig: this.props.combineConfig[this.props.index],
      });
    }
  }

  //   选区拖拽事件相关
  dragEnter = () => {
    this.getBoxRect();
  };

  //   获取当前选区左上角坐标
  getBoxRect = () => {
    const rect = this.container.getBoundingClientRect();
    this.x = rect.x;
    this.y = rect.y;
  };

  dragOver = (e) => {
    e.preventDefault();
    this.setState({
      entering: true,
    });
    const { clientX, clientY } = e;
    const dropIndex = this.calcPhysicalDropIndex(clientX, clientY);
    if (dropIndex > -1) {
      let combineConfigCopy = JSON.parse(JSON.stringify(this.props.combineConfig[this.props.index]));
      if (this.state.draggindex > -1) {
        //   进行内部拖拽时
        combineConfigCopy.splice(this.state.draggindex, 1);
        combineConfigCopy.splice(dropIndex, 0, this.props.dragItem);
        this.setState({
          dragLeaving: false,
        });
      } else {
        combineConfigCopy.splice(dropIndex, 0, this.props.dragItem);
      }
      this.setState({
        prepareIndex: dropIndex,
        prepareConfig: combineConfigCopy,
      });
    }
  };

  //   计算当前拖拽项排序
  calcPhysicalDropIndex = (dragX, dragY) => {
    const relX = dragX - this.x;
    const relY = dragY - this.y;
    const limitX = BOX_WIDTH - PADDING;
    const limitY = Math.max(BOX_MIN_HEIGHT, this.state.prepareConfig.length * ITEM_HEIGHT + PADDING * 2);
    if (relX > PADDING && relY > PADDING && relX < limitX && relY < limitY) {
      return Math.floor((relY - PADDING) / ITEM_HEIGHT);
    } else {
      this.dragLeave();
    }
  };

  dragLeave = () => {
    if (this.state.draggindex > -1) {
      this.setState({
        prepareIndex: -1,
        entering: false,
        dragLeaving: true,
      });
    } else {
      this.setState({
        prepareIndex: -1,
        entering: false,
        prepareConfig: this.props.combineConfig[this.props.index],
      });
    }
  };

  drop = (prepareConfig) => {
    this.setState({
      prepareIndex: null,
      entering: false,
      active: false,
    });
    this.props.onDrop(this.props.index, prepareConfig || this.state.prepareConfig);
  };

  //   删除
  deleteItem = (index) => {
    let combineConfigCopy = this.props.combineConfig[this.props.index];
    combineConfigCopy.splice(index, 1);
    this.props.onDrop(this.props.index, combineConfigCopy);
    this.setState({
      prepareConfig: combineConfigCopy,
    });
  };

  //   是否进行内部拖拽状态切换
  setDraggingIndex = (draggindex) => {
    this.setState({
      draggindex,
    });
  };

  dragLeavingToggle = (bool) => {
    this.setState({
      dragLeaving: bool,
    });
  };

  render() {
    const { index } = this.props;
    const { prepareConfig, prepareIndex, entering, active, dragLeaving } = this.state;
    return (
      <div className="drop-item">
        <h5>
          选区{index === 0 ? '一' : '二'}
          <span className="warning">*</span>
        </h5>
        <div className={`drop-content ${entering ? 'enter' : ''}`} ref={(f) => (this.container = f)}>
          {prepareConfig.length === 0 ? (
            <div className="drop-placeholder">
              <i className="iconfont icon-jia"></i>请拖动卡片至此（可多选）
            </div>
          ) : (
            prepareConfig.map((item, cardIndex) => {
              return (
                <DropCard
                  key={`${item.id}${cardIndex}`}
                  prepareIndex={prepareIndex}
                  dragLeaving={dragLeaving}
                  item={item}
                  cardIndex={cardIndex}
                  prepareConfig={prepareConfig}
                  deleteItem={() => this.deleteItem(cardIndex)}
                  setDraggingIndex={this.setDraggingIndex}
                  dragLeavingToggle={this.dragLeavingToggle}
                  drop={this.drop}
                  {...this.props}
                />
              );
            })
          )}
          <div
            className={`mask ${active ? 'active' : ''}`}
            onDragEnter={this.dragEnter}
            onDragOver={this.dragOver}
            onDragLeave={this.dragLeave}
            onDrop={() => this.drop()}></div>
        </div>
      </div>
    );
  }
}

export default WidgetItem;
