import CommonMixChart from '../../containers/CommonMixChart/index';
import CommonTable from '../../containers/CommonTable';
import CommonBreadCrumb from '../../containers/CommonBreadCrumb';

export default {
  title: '外部链接详情',
  components: [
    {
      component: CommonBreadCrumb,
      props: {
        breadCrumbSecName: 'key',
      },
    },
    {
      isCard: true,
      children: [
        {
          component: CommonMixChart,
          col: 24,
          props: {
            options: ['pv', 'uv', 'uvNew', 'visits'],
            chartProps: [
              {
                col: 6,
                dimsFromUrl: true,
                filterDims: true,
                needOther: true,
                addFilterFromUrl: true,
                type: 'pie',
                fields: 'pv',
                pageCount: 9,
                orderDesc: true,
                noLegendSelect: true,
                orderColumn: 'pv',
                showAll: true,
              },
              {
                col: 18,
                lineType: 'area',
                dims: 'date',
                fields: 'pv',
                pageCount: 10,
                orderDesc: true,
                orderColumn: 'pv',
                showAll: true,
                ignoreCache: true,
                dimsFromUrl: true,
                filterDims: true,
                addFilterFromUrl: true,
              },
            ],
          },
        },
      ],
    },
    {
      isCard: true,
      component: CommonTable,
      props: {
        dimsFromUrl: true,
        filterDims: true,
        addFilterFromUrl: true,
        currentPage: 1,
        orderDesc: true,
        pageCount: 10,
        orderColumn: 'pv',
      },
    },
  ],
};
