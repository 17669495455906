import AnalyseTableLayout from '../../containers/AnalyseTableLayout';
export default {
  title: '组合分析',
  components: [
    {
      component: AnalyseTableLayout,
      props: {
        analyseType: 'combinationAnalytics',
        type: 14,
        title: '组合分析',
      },
    },
  ],
};
