/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { decodeMetricName, getFormattedValue } from '../../util';
import { tooltipStyle } from '../../constants';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps) {
  const { width, height, data, cols, metrics, chartStyles } = chartProps;

  const { spec, grid } = chartStyles;

  const {} = spec;

  const title = cols[0].randomName || cols[0].name;
  const agg = metrics[0].agg;
  const metricName = decodeMetricName(metrics[0].name);

  const tooltip = {
    confine: 'true',
    extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
    formatter(params) {
      const { name, value, color } = params;
      const tooltipLabels = [];
      if (color) {
        tooltipLabels.push(`<span class="widget-tooltip-circle" style="background: ${color}"></span>`);
      }
      tooltipLabels.push(name);
      if (data) {
        tooltipLabels.push(': ');
        tooltipLabels.push(getFormattedValue(value, metrics[0].format));
      }
      return tooltipLabels.join('');
    },
    ...tooltipStyle,
  };

  return {
    tooltip,
    series: [
      {
        type: 'wordCloud',
        sizeRange: document.body.clientWidth > 768 ? [12, 60] : [12, 36],
        // textStyle: {
        //   normal: {
        //     color: defaultThemeColors[Math.floor(Math.random() * defaultThemeColors.length)]
        //   }
        // },
        rotationRange: [0, 0],
        data: data
          .filter((d) => !!d[title])
          .map((d) => ({
            name: d[title],
            value: d[metrics[0].randomName || `${agg}(${metricName})`],
            textStyle: {
              color: defaultThemeColors[Math.floor(Math.random() * defaultThemeColors.length)],
            },
          })),
      },
    ],

    grid: {
      show: true,
      borderColor: 'transparent',
      // backgroundColor: grid || BoardStore.boardStyle === 'terse' ? grid.backgroundColor : 'transparent',
    },
  };
}
