export default {
  version: 1,
  themeName: '暗黑',
  theme: {
    seriesCnt: '3',
    backgroundColor: 'rgba(23, 23, 23, .9)',
    titleColor: '#d6d6d6',
    subtitleColor: '#858585',
    textColorShow: false,
    textColor: '#333',
    markTextColor: '#d6d6d6',
    color: ['#14c4a2', '#71EB8E', '#C3FFB1', '#238bdb', '#2AC5FF', '#85D9FF', '#13a9c2', '#23D6DC', '#62EDF2', '#F37623', '#F9A360', '#FEDA6E'],
    borderColor: '#ffffff',
    borderWidth: '0',
    visualMapColor: ['#facd46', '#14c4a2', '#adfff1'],
    legendTextColor: '#d6d6d6',
    kColor: '#d0648a',
    kColor0: 'transparent',
    kBorderColor: '#d0648a',
    kBorderColor0: '#22c3aa',
    kBorderWidth: '1',
    lineWidth: '3',
    symbolSize: '8',
    symbol: 'emptyCircle',
    symbolBorderWidth: '2',
    lineSmooth: false,
    graphLineWidth: '1',
    graphLineColor: '#cccccc',
    mapLabelColor: '#28544e',
    mapLabelColorE: '#349e8e',
    mapBorderColor: '#999999',
    mapBorderColorE: '#22c3aa',
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: '#eeeeee',
    mapAreaColorE: 'rgba(34,195,170,0.25)',
    axes: [
      {
        type: 'all',
        name: '通用坐标轴',
        axisLineShow: true,
        axisLineColor: '#3d3d3d',
        axisTickShow: true,
        axisTickColor: '#3d3d3d',
        axisLabelShow: true,
        axisLabelColor: '#858585',
        splitLineShow: true,
        splitLineColor: ['#262626'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(207,50,50,0.05)', 'rgba(200,200,200,0.02)'],
      },
      {
        type: 'category',
        name: '类目坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: false,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'value',
        name: '数值坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'log',
        name: '对数坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'time',
        name: '时间坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: '#d6d6d6',
    toolboxEmpasisColor: '#14c4a2',
    tooltipAxisColor: '#5c5c5c',
    tooltipAxisWidth: 1,
    timelineLineColor: '#14c4a2',
    timelineLineWidth: '4',
    timelineItemColor: '#14c4a2',
    timelineItemColorE: '#14c4a2',
    timelineCheckColor: '#34ac95',
    timelineCheckBorderColor: 'rgba(60,235,210,0.3)',
    timelineItemBorderWidth: 1,
    timelineControlColor: '#14c4a2',
    timelineControlBorderColor: '#14c4a2',
    timelineControlBorderWidth: '0.5',
    timelineLabelColor: '#858585',
    datazoomBackgroundColor: 'rgba(255,255,255,0)',
    datazoomDataColor: '#14c4a2',
    datazoomFillColor: '#2e2e2e',
    datazoomHandleColor: '#5c5c5c',
    datazoomHandleWidth: '100',
    datazoomLabelColor: '#858585',
  },
};
