/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { toJS } from 'mobx';
import { DEFAULT_SPLITER, tooltipStyle, All_LINES_SYMBOL_TYPE } from '../../constants';
import {
  getFormattedValue,
  decodeMetricName,
  getChartTooltipLabel,
  getFieldAlias,
  hasMatchedTheCondition,
  getCompareText,
  metricAxisLabelFormatter,
} from '../../util';
import {
  getDimetionAxisOption,
  getMetricAxisOption,
  getLabelOption,
  getLegendOption,
  getGridPositions,
  makeGrouped,
  getGroupedXaxis,
  getCartesianChartMetrics,
  getCartesianChartReferenceOptions,
} from './util';
import ChartTypes from '../../config/chart/ChartTypes';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';
import BIStore from '../../store';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps, drillOptions) {
  const { data, cols, chartStyles, color, tip, references, id, compareData } = chartProps;
  const metrics = getCartesianChartMetrics(chartProps.metrics);
  const { spec, xAxis, yAxis, splitLine, label, legend, grid, markPoint, itemStyleConfig } = chartStyles;
  const currentCompareFilterConfig = toJS(BoardStore.compareFilterConfig[id]); //  对比配置

  const {
    showVerticalLine,
    verticalLineColor,
    verticalLineSize,
    verticalLineStyle,
    showHorizontalLine,
    horizontalLineColor,
    horizontalLineSize,
    horizontalLineStyle,
  } = splitLine;

  const { smooth, step, miniCharts } = spec;

  const { selectedItems } = drillOptions;

  const labelOption = {
    label: miniCharts
      ? {
          normal: {
            show: false,
          },
        }
      : getLabelOption('line', label, metrics),
  };
  const referenceOptions = getCartesianChartReferenceOptions(references, ChartTypes.Line, metrics, data);

  const xAxisColumnName = cols[0].randomName || cols[0].name;
  let xAxisData = [];
  let grouped = {};

  if (color.items.length) {
    xAxisData = getGroupedXaxis(data, xAxisColumnName, metrics);
    grouped = makeGrouped(data, color.items.map((c) => c.randomName || c.name), xAxisColumnName, metrics, xAxisData);
  } else {
    xAxisData = data.map((d) => d[xAxisColumnName] || '');
  }

  // 显示标注点，最大值最小值
  const markPointData = [];
  if (markPoint && !miniCharts) {
    markPoint.showMax && markPointData.push({ name: '最大值', type: 'max' });
    markPoint.showMin && markPointData.push({ name: '最小值', type: 'min' });
  }

  const series = [];
  const seriesData = [];
  const formattedData = [];

  metrics.forEach((m, i) => {
    const decodedMetricName = decodeMetricName(m.name);
    const styleName = m.randomName || m.name;
    const itemStyleInfo = (itemStyleConfig && itemStyleConfig[styleName]) || {};
    if (color.items.length) {
      const groupedEntries = Object.entries(grouped);
      groupedEntries.forEach(([k, v], gIndex) => {
        const serieObj = {
          id: `${m.name}${DEFAULT_SPLITER}${DEFAULT_SPLITER}${k}`,
          name: `${k}${metrics.length > 1 ? ` ${m.displayName}` : ''}`,
          type: 'line',
          sampling: 'average',
          markPoint: {
            data: markPointData,
            symbolSize: 40,
          },
          workViewParamId: m.workViewParamId,
          data: v.map((g, index) => {
            const itemStyleObj =
              selectedItems && selectedItems.length && selectedItems.some((item) => item === index)
                ? {
                    itemStyle: {
                      normal: {
                        opacity: 1,
                        borderWidth: 6,
                      },
                    },
                  }
                : {};
            const value = g[m.randomName || `${m.agg}(${decodedMetricName})`];
            if (itemStyleInfo.conditionStyle) {
              const matchColors = [];
              itemStyleInfo.conditionStyle.forEach((item) => {
                const matchTheCondition = hasMatchedTheCondition(value, item.operatorType, item.conditionValues);
                matchTheCondition && matchColors.push(item.color);
              });
              matchColors.length &&
                (itemStyleObj.itemStyle ? (itemStyleObj.itemStyle.color = matchColors[0]) : (itemStyleObj.itemStyle = { color: matchColors[0] }));
            }
            formattedData.push(m.format.formatType === 'default' ? metricAxisLabelFormatter(value) : getFormattedValue(value, m.format));
            return {
              value,
              ...itemStyleObj,
            };
            // }
          }),
          itemStyle: {
            normal: {
              // opacity: interactIndex === undefined ? 1 : 0.25
              color: color.items[0].config.values[k] || defaultThemeColors[gIndex % defaultThemeColors.length],
              opacity: selectedItems && selectedItems.length > 0 ? 0.7 : 1,
            },
          },
          showSymbol: !miniCharts,
          symbol: getSymbolShape(itemStyleInfo, i * groupedEntries.length + gIndex),
          symbolSize: itemStyleInfo.symbolSize || 6,
          smooth: miniCharts ? true : smooth,
          step: miniCharts ? false : step,
          emphasis: {
            focus: 'self',
            lineStyle: {
              width: 3,
            },
          },
          ...labelOption,
          ...(gIndex === groupedEntries.length - 1 && i === metrics.length - 1 && referenceOptions),
        };
        miniCharts &&
          (serieObj.areaStyle = {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: color.items[0].config.values[k], // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#ffffff', // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          });
        series.push(serieObj);
        seriesData.push(grouped[k]);
      });
    } else {
      const areaStyle = {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: color.value[m.name] || defaultThemeColors[i % defaultThemeColors.length], // 0% 处的颜色
            },
            {
              offset: 1,
              color: '#ffffff', // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      };
      const serieObj = getSeriesObj(m, i, decodedMetricName, data, currentCompareFilterConfig ? 'current' : null, itemStyleInfo);
      miniCharts && (serieObj.areaStyle = areaStyle);
      series.push(serieObj);
      seriesData.push([...data]);
      if (currentCompareFilterConfig) {
        const compareSerieObj = getSeriesObj(m, i, decodedMetricName, compareData, 'compare', itemStyleInfo);
        series.push(compareSerieObj);
        seriesData.push([...compareData]);
      }
    }
  });

  const seriesNames = series.map((s) => s.name);

  // dataZoomOptions = dataZoomThreshold > 0 && dataZoomThreshold < dataSource.length && {
  //   dataZoom: [{
  //     type: 'inside',
  //     start: Math.round((1 - dataZoomThreshold / dataSource.length) * 100),
  //     end: 100
  //   }, {
  //     start: Math.round((1 - dataZoomThreshold / dataSource.length) * 100),
  //     end: 100,
  //     handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
  //     handleSize: '80%',
  //     handleStyle: {
  //       color: '#fff',
  //       shadowBlur: 3,
  //       shadowColor: 'rgba(0, 0, 0, 0.6)',
  //       shadowOffsetX: 2,
  //       shadowOffsetY: 2
  //     }
  //   }]
  // }

  const xAxisSplitLineConfig = {
    showLine: !miniCharts && showVerticalLine,
    lineColor: verticalLineColor,
    lineSize: verticalLineSize,
    lineStyle: verticalLineStyle,
  };

  const yAxisSplitLineConfig = {
    showLine: !miniCharts && showHorizontalLine,
    lineColor: horizontalLineColor,
    lineSize: horizontalLineSize,
    lineStyle: horizontalLineStyle,
  };

  const options = {
    xAxis: getDimetionAxisOption(
      xAxis,
      xAxisSplitLineConfig,
      xAxisData,
      {
        boundaryGap: false,
      },
      null,
      miniCharts
    ),
    yAxis: getMetricAxisOption(
      yAxis,
      yAxisSplitLineConfig,
      metrics.map((m) => getFieldAlias(m.field, {}) || m.fieldDisplay || decodeMetricName(m.name)).join(` / `),
      null,
      null,
      miniCharts
    ),
    series,
    tooltip: {
      trigger: 'axis',
      confine: true,
      extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
      formatter: getChartTooltipLabel(
        'line',
        seriesData,
        {
          cols,
          metrics,
          color,
          tip,
        },
        !!color.items.length,
        data.length,
        currentCompareFilterConfig,
        toJS(BIStore.currentItemsInfo[id].datasource.mappingMap)
      ),
      ...tooltipStyle,
    },
    legend: getLegendOption(legend, seriesNames, null, miniCharts),
    grid: miniCharts
      ? {
          top: 4,
          bottom: 0,
          left: 0,
          right: 0,
        }
      : {
          ...getGridPositions(legend, seriesNames, '', false, yAxis, xAxis, xAxisData, null, null, formattedData, null, markPoint),
          show: true,
          borderColor: 'transparent',
          backgroundColor: grid ? grid.backgroundColor : 'transparent',
        },
  };
  return options;

  function getSeriesObj(m, i, decodedMetricName, data, compareType, itemStyleInfo) {
    let compareTextObj;
    const markPointDataCopy = JSON.parse(JSON.stringify(markPointData));
    if (compareType) {
      compareTextObj = getCompareText(currentCompareFilterConfig);
    }
    if (markPoint && !miniCharts && itemStyleInfo.conditionStyle) {
      const formatName = m.randomName || `${m.agg}(${decodedMetricName})`;
      const itemData = data.map((item) => item[formatName]);
      const max = Math.max(...itemData);
      const min = Math.min(...itemData);
      const maxColors = [];
      itemStyleInfo.conditionStyle.forEach((item) => {
        const matchTheCondition = hasMatchedTheCondition(max, item.operatorType, item.conditionValues);
        matchTheCondition && maxColors.push(item.color);
      });
      const minColors = [];
      itemStyleInfo.conditionStyle.forEach((item) => {
        const matchTheCondition = hasMatchedTheCondition(min, item.operatorType, item.conditionValues);
        matchTheCondition && minColors.push(item.color);
      });
      markPointDataCopy.forEach((item) => {
        item.type === 'max' && maxColors.length && (item.itemStyle = { color: maxColors[0] });
        item.type === 'min' && minColors.length && (item.itemStyle = { color: minColors[0] });
        item.type === 'max' && (item.label = { formatter: getFormattedValue(max, m.format) });
        item.type === 'min' && (item.label = { formatter: getFormattedValue(min, m.format) });
      });
    }
    return {
      id: compareType ? `${m.name}@CEMYUN@${compareTextObj[compareType]}` : m.name,
      name: compareType ? `${compareTextObj[compareType]} - ${m.displayName}` : m.displayName,
      type: 'line',
      sampling: 'average',
      markPoint: {
        data: markPointDataCopy,
        symbolSize: 40,
      },
      workViewParamId: m.workViewParamId,
      data: data.map((g, index) => {
        const itemStyleObj =
          selectedItems && selectedItems.length && selectedItems.some((item) => item === index)
            ? {
                itemStyle: {
                  normal: {
                    opacity: 1,
                    borderWidth: 8,
                  },
                },
              }
            : {};
        const value = g[m.randomName || `${m.agg}(${decodedMetricName})`];
        if (itemStyleInfo.conditionStyle) {
          const matchColors = [];
          itemStyleInfo.conditionStyle.forEach((item) => {
            const matchTheCondition = hasMatchedTheCondition(value, item.operatorType, item.conditionValues);
            matchTheCondition && matchColors.push(item.color);
          });
          matchColors.length &&
            (itemStyleObj.itemStyle ? (itemStyleObj.itemStyle.color = matchColors[0]) : (itemStyleObj.itemStyle = { color: matchColors[0] }));
        }
        formattedData.push(m.format.formatType === 'default' ? metricAxisLabelFormatter(value) : getFormattedValue(value, m.format));
        return {
          value,
          ...itemStyleObj,
        };
      }),
      itemStyle: {
        normal: {
          opacity: compareType === 'compare' ? 0.5 : 1,
          color: color.value[m.name] || defaultThemeColors[i % defaultThemeColors.length],
          opacity: selectedItems && selectedItems.length > 0 ? 0.7 : 1,
        },
      },
      lineStyle: {
        opacity: compareType === 'compare' ? 0.5 : 1,
      },
      showSymbol: !miniCharts,
      symbol: getSymbolShape(itemStyleInfo, i),
      symbolOffset: itemStyleInfo.symbolShape === 'arrow' ? [0, '-50%'] : [0, 0],
      symbolSize: itemStyleInfo.symbolSize || 6,
      smooth: miniCharts ? true : smooth,
      step: miniCharts ? false : step,
      emphasis: {
        focus: 'self',
        lineStyle: {
          width: 3,
        },
      },
      ...labelOption,
      ...(i === metrics.length - 1 && referenceOptions),
    };
  }
  function getSymbolShape(itemStyleInfo, index) {
    if (itemStyleInfo && itemStyleInfo.symbolShape) {
      return itemStyleInfo.symbolShape;
    } else if (itemStyleConfig && itemStyleConfig.cem_commonStyle && itemStyleConfig.cem_commonStyle.type === 'unify') {
      return itemStyleConfig.cem_commonStyle.symbolShape;
    } else {
      return All_LINES_SYMBOL_TYPE[index % All_LINES_SYMBOL_TYPE.length];
    }
  }
}
