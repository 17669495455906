import React, { Fragment, Component } from 'react';
import Loading from '../../../component/Loading/Loading';
import api from '../../../api';
import util from '../../../util';
import { Modal, Radio } from 'antd';

class StatusModal extends Component {
  constructor() {
    super();
    this.state = {
      cacheOperationType: 1,
    };
  }

  cacheOperationTypeChange = (e) => {
    this.setState({
      cacheOperationType: e.target.value,
    });
  };

  render() {
    const { visible, onOk, onCancel, statusTotalCount } = this.props;
    const { cacheOperationType } = this.state;
    return (
      <Modal className="update-status-warning-modal" title="关闭提示" visible={visible} onOk={() => onOk(cacheOperationType)} onCancel={onCancel}>
        <h5>当前调研还有{statusTotalCount}条推送任务未下发</h5>
        <Radio.Group onChange={this.cacheOperationTypeChange} value={cacheOperationType}>
          <div>
            <Radio value={1}>继续将当前遗留任务下发完成</Radio>
          </div>
          <div>
            <Radio value={2}>清除当前遗留任务</Radio>
          </div>
        </Radio.Group>
      </Modal>
    );
  }
}

export default StatusModal;
