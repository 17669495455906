import React from 'react';
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/index';
class Main extends React.Component {
  addContent = (store) => {
    store.columns.forEach((item) => {
      if (item.dataIndex === 'operate') {
        item.render = (text, record) => {
          return {
            children: <Link to={`/data/visitor/terminal/detail?keyDim=${this.props.dims}&key=${record[`${this.props.dims}_key`]}`}>查看</Link>,
          };
        };
      }
    });
  };

  render() {
    return <CommonTable addContent={this.addContent} extraHeaders={[{ key: 'operate', title: '操作' }]} {...this.props}></CommonTable>;
  }
}

export default Main;
