import { action, observable, toJS } from 'mobx';
import util from '../util';
import api from '../api';
import pageHeaderStore from '../component/PageHeader/PageHeaderStore';

class Store {
  @observable authorityData = null;
  @observable authorityMenu = null;
  @observable isMainAccount = false;
  @observable primaryAccount = 1;
  @action.bound init(isNeedInitProductList = false) {
    // 获取权限列表
    util.get(api.authority.list, {}, (res) => {
      this.authorityData = res.perms;
      this.primaryAccount = res.primaryAccount;
      this.isMainAccount = res.isMainAccount;
      this.formulaData();
      // 是否需要重新获取产品列表
      isNeedInitProductList && pageHeaderStore.init();
    });
  }
  @action.bound formulaData() {
    if (toJS(this.authorityData) === 'all') {
      this.authorityMenu = {
        all: {},
      };
    } else {
      let authorityPermArr = toJS(this.authorityData)
        ? toJS(this.authorityData).map((item, index) => {
            return item.split(':');
          })
        : [];
      let authorityMenu = {};
      let firstMenuList = [];
      authorityPermArr.forEach((item, index) => {
        if (!firstMenuList.includes(item[0])) {
          // 一级菜单
          // 数据切开后长度为0则为一级菜单
          if (!authorityMenu.hasOwnProperty(item[0])) {
            authorityMenu[item[0]] = {};
          }
          if (item.length === 1) {
            firstMenuList.push(item[0]);
            authorityMenu[item[0]] = {};
          }
          // 二级菜单
          else if (item.length === 2) {
            //没有二级菜单key就默认加上
            authorityMenu[item[0]][item[1]] = {
              delete: true,
              update: true,
              share: true,
              add: true,
              view: true,
            };
          } else if (item.length === 3) {
            if (!authorityMenu[item[0]].hasOwnProperty(item[1])) {
              authorityMenu[item[0]][item[1]] = {
                delete: false,
                update: false,
                share: false,
                add: false,
                view: false,
              };
            }

            authorityMenu[item[0]][item[1]][item[2]] = true;
          }
        }
      });
      this.authorityMenu = authorityMenu;
    }
  }
  // 检查是否存在该权限 菜单存在 且 （全部权限 或者 （权限菜单存在一级菜单的key 且 （权限菜单的一级菜单为空对象 或者 （权限菜单的一级菜单有二级菜单的key 且 所对应的属性为真）））
  @action.bound checkAuthorityExist(firstMenu, secondMenu, attribute) {
    return (
      this.authorityMenu &&
      (this.authorityMenu.hasOwnProperty('all') ||
        (this.authorityMenu.hasOwnProperty(firstMenu) &&
          (JSON.stringify(this.authorityMenu[firstMenu]) === '{}' ||
            (this.authorityMenu[firstMenu].hasOwnProperty(secondMenu) && (attribute ? this.authorityMenu[firstMenu][secondMenu][attribute] === true : true)))))
    );
  }
  @action.bound checkAuthorityRoute(firstMenu, secondMenu) {
    return !secondMenu
      ? toJS(this.authorityMenu).hasOwnProperty('all') ||
          toJS(this.authorityMenu).hasOwnProperty(firstMenu) ||
          JSON.stringify(toJS(this.authorityMenu))[firstMenu] === '{}'
      : toJS(this.authorityMenu).hasOwnProperty('all') ||
          JSON.stringify(toJS(this.authorityMenu)[firstMenu]) === '{}' ||
          (toJS(this.authorityMenu)[firstMenu] && toJS(this.authorityMenu)[firstMenu].hasOwnProperty(secondMenu));
  }
}

export default new Store();
