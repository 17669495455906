import React, { Component } from 'react';
import { Row, Col, Radio, message } from 'antd';

class CombineLayout extends Component {
  constructor() {
    super();
  }

  render() {
    const { selectStyle, onStyleChange } = this.props;
    const styleList = [
      {
        value: 1,
        label: '左右等大',
      },
      {
        value: 2,
        label: '左窄右宽',
      },
      {
        value: 3,
        label: '左宽右窄',
      },
      {
        value: 4,
        label: '上下等大',
      },
      {
        value: 5,
        label: '上窄下宽',
      },
      {
        value: 6,
        label: '上宽下窄',
      },
    ];

    return (
      <div className="combine-layout-setting">
        <h5>
          版式<span>*</span>
        </h5>
        <Radio.Group className="combine-layout-setting-check" onChange={(e) => onStyleChange(e.target.value)} value={selectStyle}>
          <Row gutter={12}>
            {styleList.map(({ value, label }) => {
              return (
                <Col className="style-item" span={8} key={value}>
                  <div
                    className={`style-example style-example-${value} ${value > 3 ? 'style-example-column' : ''} ${selectStyle === value ? 'active' : ''}`}
                    onClick={() => onStyleChange(value)}>
                    <div className="style-area first-style-area">
                      <div className="serial-number">1</div>
                    </div>
                    <div className="style-area second-style-area">
                      <div className="serial-number">2</div>
                    </div>
                  </div>
                  <Radio value={value}>{label}</Radio>
                </Col>
              );
            })}
          </Row>
        </Radio.Group>
      </div>
    );
  }
}

export default CombineLayout;
