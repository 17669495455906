import AnalyseRemain from '../../containers/AnalyseRemain/index';

export default {
  title: '留存分析',
  components: [
    {
      component: AnalyseRemain,
    },
  ],
};
