import ChartTypes from './ChartTypes';
import { PIVOT_DEFAULT_AXIS_LINE_COLOR, PIVOT_CHART_FONT_FAMILIES, PIVOT_DEFAULT_FONT_COLOR, CHART_LABEL_POSITIONS } from '../../constants';

const quadrant = {
  id: ChartTypes.Quadrant,
  name: 'quadrant',
  title: '四象限图',
  icon: 'iconsixiangxian',
  coordinate: 'cartesian',
  rules: [{ dimension: [1, 9999], metric: 2 }],
  dimetionAxis: 'col',
  data: {
    cols: {
      title: '列',
      type: 'category',
    },
    rows: {
      title: '行',
      type: 'category',
    },
    metrics: {
      title: '指标',
      type: 'value',
    },
    filters: {
      title: '筛选',
      type: 'all',
    },
    size: {
      title: '尺寸',
      type: 'value',
    },
    // color: {
    //   title: '颜色',
    //   type: 'category'
    // },
    tip: {
      title: '提示信息',
      type: 'value',
    },
  },
  style: {
    spec: {},
    label: {
      showLabel: false,
      labelPosition: CHART_LABEL_POSITIONS[0].value,
      labelFontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
      labelFontSize: '12',
      labelColor: PIVOT_DEFAULT_FONT_COLOR,
    },
    xAxis: {
      showLine: true,
      lineStyle: 'solid',
      lineSize: '1',
      lineColor: PIVOT_DEFAULT_AXIS_LINE_COLOR,
      showLabel: false,
      labelFontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
      labelFontSize: '12',
      labelColor: PIVOT_DEFAULT_FONT_COLOR,
      showTitleAndUnit: true,
      titleFontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
      titleFontSize: '12',
      titleColor: PIVOT_DEFAULT_FONT_COLOR,
      nameLocation: 'middle',
      //   nameRotate: 90,
      nameGap: 40,
      min: null,
      max: null,
    },
    yAxis: {
      showLine: true,
      lineStyle: 'solid',
      lineSize: '1',
      lineColor: PIVOT_DEFAULT_AXIS_LINE_COLOR,
      showLabel: false,
      labelFontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
      labelFontSize: '12',
      labelColor: PIVOT_DEFAULT_FONT_COLOR,
      showTitleAndUnit: true,
      titleFontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
      titleFontSize: '12',
      titleColor: PIVOT_DEFAULT_FONT_COLOR,
      nameLocation: 'middle',
      nameRotate: 90,
      nameGap: 40,
      min: null,
      max: null,
    },
    splitLine: {
      showHorizontalLine: true,
      horizontalLineStyle: 'dashed',
      horizontalLineSize: '1',
      horizontalLineColor: PIVOT_DEFAULT_AXIS_LINE_COLOR,
      showVerticalLine: false,
      verticalLineStyle: 'dashed',
      verticalLineSize: '1',
      verticalLineColor: PIVOT_DEFAULT_AXIS_LINE_COLOR,
    },
    legend: {
      showLegend: true,
      legendPosition: 'bottom',
      selectAll: true,
      fontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
      fontSize: '12',
      color: PIVOT_DEFAULT_FONT_COLOR,
    },
  },
};

export default quadrant;
