import React, { Component } from 'react';
import DistributionTable from './DistributionTable';
import DistributionChart from './DistributionChart';
import { observer } from 'mobx-react';
import customBoardUtil from '../../../utils/customBoardUtil';

@observer
export default class DistributionChartGroup extends Component {
  componentDidMount() {
    this.props.boardChart && window.Cemyun && window.Cemyun.trackEvent('卡片浏览', { cardName: this.props.name });
  }
  render() {
    return (
      <React.Fragment>
        {this.props.chartType === 6 || this.props.chartType === 15 ? (
          <DistributionChart
            index={this.props.index}
            type={customBoardUtil.changeNumberToType(this.props.chartType).split('_')[1]}
            form={this.props.form}
            setDateObj={this.props.setDateObj}></DistributionChart>
        ) : (
          <DistributionTable form={this.props.form} isEdit={this.props.setDateObj}></DistributionTable>
        )}
      </React.Fragment>
    );
  }
}
