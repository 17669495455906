import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import util from '../../util';

class TablePageStore {
  // @observable
  config = {};
  history = [];
  keys = ['currentPage', 'pageCount', 'orderColumn', 'orderDesc', 'searchValue'];

  // @action.bound
  // 重置配置
  init(details) {
    const referer = this.history.length > 1 ? this.history.slice(-2) : '';
    if (!details) {
      (this.config = {}), this.setConfig();
    } else if (referer) {
      const arr = details.filter((item) => {
        return referer[0].includes(item) || referer[1].includes(item);
      });
      arr.length ? this.setConfig() : ((this.config = {}), this.setConfig());
    } else {
      let keys = JSON.parse(localStorage.getItem('TablePageConfigKeys'));
      keys = keys || [];
      const config = {};
      keys.forEach((item) => {
        const value = util.getQueryString(item);
        value && (config[item] = value);
      });
      this.setConfig(config);
    }
  }

  // @action.bound
  setConfig(info) {
    info = info || {};
    let keys = [];
    this.config = { ...this.config, ...info };
    for (let key in this.config) {
      keys.push(key);
    }
    const queryString = util.parseObject({ ...this.config });
    let search = window.location.search;

    for (let key in this.config) {
      search = util.delQueryString(search, key);
    }
    window.history.replaceState(null, null, queryString ? search + '&' + queryString : window.location.search);
    localStorage.setItem('TablePageConfigKeys', JSON.stringify(keys));
  }
  setHistory(url) {
    this.history.push(url);
  }
}
export default new TablePageStore();
