import React, { Component } from 'react';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Form, Spin, Select, InputNumber, Button } from 'antd';
import util from '../../util';
import api from '../../api';
import './index.sass';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import ChildrenFilter from '../../containers/PageHeaderFilters/Setting/Setting/ChildrenFilter/index';
import { message } from 'antd';
import { distributeTools } from '../../utils/analyseUtil';
import AnalyseLayout from '../AnalyseLayout/index';
import DistributionFormStore from './store.js';
import AnalyseForm from '../AnalyseForm/index';
import AnalyseChartSelect from '../../component/AnalyseChartSelect/index';
import DistributionChartGroup from './DistributionChartGroup/index';
import EventSelect from '../../component/EventSelect/index';
import DimSelect from '../../component/DimSelect/index';
import customBoardUtil from '../../utils/customBoardUtil';
@observer
class AnalyseEventForm extends Component {
  constructor(props) {
    super(props);
    this.formStore = new DistributionFormStore();
  }

  // 图表类型
  // @observable chartType = null;
  @observable isLoading = false;
  // 图表下拉框的配置
  @observable chartName = null;
  //时间分布指标
  @observable distributionDate = 'date_hour';
  //存储区间前后边界数值的数组
  @observable start = [];
  @observable end = [];
  // 子组件调用改变图表类型
  @action.bound changeChartType(value) {
    this.formStore.config.chartType = customBoardUtil.changeNumberToType(value);
  }
  @action.bound changeDistributionType(value) {
    this.isLoading = true;
    this.formStore.init();

    // 切换分布类型时更改form表单
    if (value.key === 'analyseDistribute_target') {
      this.formStore.form.customizeParam = { algorithm: 'fixed' };
      this.formStore.config.customSection = null;
      this.formStore.config.chartType = 6;
    } else {
      this.formStore.form.ruleContent[0]['target'] = 'date_hour';
      this.formStore.form.customizeParam = 'distribution_date';
      this.formStore.config.customSection = null;
      this.formStore.config.chartType = 15;
    }
    // 等所有配置配置好再转换分布分析的类型
    this.formStore.config.distributionType = value.key;
    // 更改完表单刷新页面
    setTimeout(() => {
      this.isLoading = false;
    });
    this.formStore.refresh();
  }
  // 保存的form表单
  saveOrUpdate = (otherParam, callback, isSaveAs) => {
    let { subDividedDimGroup, ruleContent, filterObject, customizeParam } = toJS(this.formStore.form);
    let { chartType, id } = this.formStore.config;
    let fetchParams = {
      idsite: pageHeaderStore.parameter.idsite,
      dims: subDividedDimGroup[0] === '' ? `date_${pageHeaderStore.parameter.period}` : subDividedDimGroup[0],
      ruleContent: JSON.stringify(ruleContent),
      customizeParam: JSON.stringify(customizeParam),
      chartType: chartType,
      type: 7,
      ...otherParam,
    };
    if (filterObject && filterObject.filters) {
      fetchParams['filterObject'] = JSON.stringify(filterObject);
    }
    // 如果是有id则是更新

    if (id && !isSaveAs) {
      fetchParams['id'] = id;
    }
    util.post(api.analysis.saveOrUpdate, fetchParams, (res) => callback(res));
  };
  // 保存前的验证
  rulesForSave = () => {
    // 如果是自定义区间则对其进行验证
    if (this.formStore.form.customizeParam.hasOwnProperty('customSection')) {
      if (!distributeTools.checkCustomSection(this.formStore.form.customizeParam.customSection)) {
        message.info('请完善时间区间');
        return false;
      }
    }

    return true;
  };
  // 获取该事件管理的配置
  @action.bound getEventConfig(id, name) {
    if (id === 'default') {
      // 初始化
      this.formStore.init();
      this.chartName = null;
      this.isLoading = false;
      // 暂存数据以表示显示不
    } else {
      this.isLoading = true;
      this.chartName = name;
      util.get(
        api.analysis.info,
        {
          ids: id,
        },
        async (res) => {
          this.setConfig(await this.asynGetTarget(res[0]), res[0]);
        }
      );
    }
  }
  asynGetTarget = async (res) => {
    // return new Promise((resolve) => {
    let dataArr = [];
    let filtersArr = JSON.parse(res.ruleContent);
    for (let item of filtersArr) {
      await util.post(
        api.pack.dimOptions,
        {
          dim: 'analysis_target',
          search: JSON.stringify({ type: item['type'], value: item['dimValue'] }),
          idsite: pageHeaderStore.parameter.idsite,
          date: pageHeaderStore.parameter.date,
        },
        (res) => {
          let data = [];
          Object.keys(res.data).map((key) => {
            if (key.includes('_')) {
              data.push({ label: res.data[key], value: key, children: this.state[`${key.split('_')[1]}Formula`] });
            } else {
              data.push({ label: res.data[key], value: key });
            }
          });
          dataArr.push(data);
        }
      );
      if (dataArr.length === filtersArr.length) {
        return dataArr;
      }
    }
  };
  // 设置暂存数据以显示在表单上
  @action.bound setConfig(dataArr, res) {
    this.formStore.form = {
      customizeParam: JSON.parse(res.customizeParam),
      ruleContent: JSON.parse(res.ruleContent),
      subDividedDimGroup: [res.dims.includes('date') ? '' : res.dims],
      filterObject: JSON.parse(res.filterObject),
      filterItemLogic: [JSON.parse(res.filterObject).logic],
      childrenFilter: [JSON.parse(res.filterObject).filters],
      count: 0,
    };
    this.formStore.config = {
      targetSelected: dataArr,
      distributionType: JSON.parse(res.customizeParam) === 'distribution_date' ? 'analyseDistribute_date' : 'analyseDistribute_target',
      customSection: JSON.parse(res.customizeParam).hasOwnProperty('customSection') ? JSON.parse(res.customizeParam).customSection.split(',') : null,
      chartType: res.chartType,
      id: res.id,
      remarks: res.remarks,
      name: res.name,
    };
    this.isLoading = false;
  }
  // 更改区间
  @action.bound changeSection = (value) => {
    if (value.key === '5' || value.key === '10' || value.key === '15' || value.key === '20') {
      this.formStore.form.customizeParam = { algorithm: 'change', groupCount: value.key };
      this.formStore.config.customSection = null;
    } else if (value.key === 'custom') {
      this.formStore.form.customizeParam = { customSection: '' };
      this.formStore.config.customSection = [''];
    } else {
      this.formStore.form.customizeParam = { algorithm: 'fixed' };
      this.formStore.config.customSection = null;
    }
    this.formStore.refresh();
  };

  //自定义区间添加区间
  @action.bound addSection = () => {
    this.start.push('');
    this.end.push('');
    this.formStore.config.customSection.push('');
  };

  //删除区间
  @action.bound deleteSection = (index) => {
    let customSection = toJS(this.formStore.config.customSection);
    customSection.splice(index, 1);
    this.formStore.config.customSection = customSection;
  };

  //修改区间数值
  @action.bound changeStartValue = (value, index) => {
    this.start[index] = value;
  };
  @action.bound changeEndValue = (value, index) => {
    this.end[index] = value;
    this.formateSection();
  };

  //将区间数据格式化处理
  @action.bound formateSection = () => {
    for (let i = 0; i < this.start.length; i++) {
      if (this.start[i] !== '' || this.end[i] !== '') {
        this.formStore.config.customSection[i] = this.start[i] + '-' + this.end[i];
      }
    }

    this.formStore.form.customizeParam.customSection = this.formStore.config.customSection.join(',');
    this.formStore.refresh();
  };

  render() {
    const formItemLayout = {
      labelCol: {
        lg: { span: 24 },
        xl: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        lg: { span: 24 },
        md: { span: 24 },
      },
      layout: 'horizontal',
    };
    return (
      <React.Fragment>
        {/* 第一个卡片 */}
        <AnalyseLayout
          type={7}
          // 保存回调
          saveOrUpdate={this.saveOrUpdate}
          // 保存规则回调
          rulesForSave={this.rulesForSave}
          // 选择时回调函数
          getEventConfig={this.getEventConfig}
          // 事件的名字
          id={this.formStore.config.id}
          eventName={'分布分析'}
          layoutTitle="分布分析"
          chartName={this.chartName}
          chartConfig={this.formStore.config}
          analyseSelectCard={
            <div className="analyseDistribute_config">
              <Form {...formItemLayout}>
                <AnalyseForm
                  formArr={[
                    {
                      label: '分布类型',
                      component: (
                        <div>
                          <Select
                            style={{ width: '96px' }}
                            placeholder="请选择分布类型"
                            labelInValue
                            value={{
                              key: this.formStore.config.distributionType,
                            }}
                            onChange={(value) => this.changeDistributionType(value)}>
                            <Select.Option key={1} value="analyseDistribute_target">
                              指标分布
                            </Select.Option>
                            <Select.Option key={2} value="analyseDistribute_date">
                              时间分布
                            </Select.Option>
                          </Select>
                        </div>
                      ),
                    },
                    {
                      label: '事件及指标',
                      component: (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {this.formStore.config.distributionType === 'analyseDistribute_target' ? (
                            <EventSelect
                              useType={null}
                              eventAndTargetGroup={toJS(this.formStore.form.ruleContent)}
                              eventValue={toJS(this.formStore.form.ruleContent[0])}
                              index={0}
                              setTargetSeletced={this.formStore.setTargetSeletced}
                              targetOption={this.formStore.config.targetSelected}
                              changeEventAndTarget={this.formStore.changeRuleContent}></EventSelect>
                          ) : (
                            <React.Fragment>
                              <EventSelect
                                useType={'analyseDistribute_date'}
                                eventAndTargetGroup={toJS(this.formStore.form.ruleContent)}
                                eventValue={toJS(this.formStore.form.ruleContent[0])}
                                index={0}
                                changeEventAndTarget={this.formStore.changeRuleContent}></EventSelect>
                            </React.Fragment>
                          )}
                        </div>
                      ),
                    },
                    {
                      show: this.formStore.config.distributionType === 'analyseDistribute_target',
                      label: '分布区间',
                      component: (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Select
                            value={{
                              key: toJS(this.formStore.form.customizeParam.algorithm)
                                ? toJS(this.formStore.form.customizeParam.algorithm === 'change')
                                  ? toJS(this.formStore.form.customizeParam.groupCount)
                                  : 'fixed'
                                : 'custom',
                            }}
                            labelInValue
                            style={{ width: '120px' }}
                            onChange={(value) => {
                              this.changeSection(value);
                            }}
                            dropdownMatchSelectWidth={true}>
                            <Select.Option key={1} value="5">
                              5等分
                            </Select.Option>
                            <Select.Option key={2} value="10">
                              10等分
                            </Select.Option>
                            <Select.Option key={3} value="15">
                              15等分
                            </Select.Option>
                            <Select.Option key={4} value="20">
                              20等分
                            </Select.Option>
                            <Select.Option key={5} value="fixed">
                              默认固定区间
                            </Select.Option>
                            <Select.Option key={6} value="custom">
                              自定义区间
                            </Select.Option>
                          </Select>
                          {this.formStore.config.customSection ? (
                            <div className="analyseDistribute_config-customSection">
                              {this.formStore.config.customSection.map((item, index) => {
                                return (
                                  <div className="analyseDistribute_config-customSection-item" key={index}>
                                    <InputNumber
                                      defaultValue={this.start[index]}
                                      min={0}
                                      key={`start${index}`}
                                      style={{ width: '64px' }}
                                      onChange={(value) => {
                                        this.changeStartValue(value, index);
                                      }}></InputNumber>
                                    <span>-</span>
                                    <InputNumber
                                      defaultValue={this.end[index]}
                                      key={`end${index}`}
                                      min={0}
                                      style={{ width: '64px' }}
                                      onChange={(value) => {
                                        this.changeEndValue(value, index);
                                      }}></InputNumber>
                                    <i
                                      className="iconfont condition-delete icon-shanchu"
                                      onClick={(index) => {
                                        this.deleteSection(index);
                                      }}></i>
                                  </div>
                                );
                              })}
                              <Button type="addButton" onClick={() => this.addSection()}>
                                +增加区间
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      ),
                    },
                    {
                      label: '细分维度',
                      component: (
                        <div style={{ padding: '4px 0px' }}>
                          <DimSelect
                            dimIndex={0}
                            analysisType={7}
                            allowClear={true}
                            dimItem={this.formStore.form.subDividedDimGroup[0]}
                            setSubDividedDim={this.formStore.setSubDividedDim}
                            eventAndTargetGroup={toJS(this.formStore.form.ruleContent)}></DimSelect>
                        </div>
                      ),
                    },
                    {
                      label: '筛选条件',
                      component: (
                        <div className="analyseDistribute_config-childrenFilter" style={{ padding: '4px 0px' }}>
                          {!this.isLoading ? (
                            <ChildrenFilter
                              filterCount={0}
                              isShow={true} //判断是否显示
                              type={'default'} //type
                              eventValue={toJS(this.formStore.form.ruleContent)}
                              filter={this.formStore.form.childrenFilter} //二维数组
                              changeFilterLogic={this.formStore.changeFilterLogic}
                              filterItemLogic={this.formStore.form.filterItemLogic} //数组
                              hasPage={false} //是否页面访问
                              needLogic={true}
                              setChildrenFilter={this.formStore.setChildrenFilter}></ChildrenFilter>
                          ) : (
                            <Spin></Spin>
                          )}
                        </div>
                      ),
                    },
                  ]}></AnalyseForm>
              </Form>
            </div>
          }
          analyseType={1}
          analyseChartContent={
            <React.Fragment>
              <div className={`analyseLayout_content-chartGroup-chart ${this.formStore.config.chartType === 8 && 'scroll'}`}>
                <DistributionChartGroup
                  chartType={this.formStore.config.chartType}
                  form={this.formStore.form}
                  setDateObj={this.formStore.setDateObj}
                  isLoading={this.isLoading}></DistributionChartGroup>
              </div>
            </React.Fragment>
          }
          analyseChartConfig={{
            chartTypeSet: {
              show: true,
              configName: '图表类型',
              component: (
                <AnalyseChartSelect
                  chartType={this.formStore.config.chartType}
                  selectType={this.formStore.config.distributionType}
                  dims={this.formStore.form.subDividedDimGroup}
                  changeChartType={this.changeChartType}></AnalyseChartSelect>
              ),
            },
          }}></AnalyseLayout>
        {/* 第二个卡片 */}
      </React.Fragment>
    );
  }
}
const AnalyseEvent = Form.create()(AnalyseEventForm);
export default AnalyseEvent;
