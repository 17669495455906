import React, { useEffect, useState, Fragment } from 'react';
import api from '../../api';
import util from '../../util';
import CommonTable from '../../containers/CommonTable';
import TablePageStore from '../../containers/CommonTable/TablePageStore';
import Loading from '../../component/Loading/Loading';
import { sourceTypes, realSourceTypes } from '../../config';
import ShareModelEnhance from '../../component/shareModelEnhance';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';

function ShareList(props) {
  const { type } = props;
  const [loading, setLoading] = useState(true); // 是否加载状态
  const [data, setData] = useState([]); // 审批列表
  const [shareModalVisible, setShareModalVisible] = useState(false); // 审批列表
  const [shareStore, setShareStore] = useState(null); // 审批列表
  const [shareIndex, setShareIndex] = useState(null); // 审批列表
  const [sourceType, setSourceType] = useState(''); // 审批列表

  useEffect(() => {
    pageHeaderStore.hidePageHeader();
    fetchData();
  }, []);

  function fetchData(orderDesc, orderColumn, currentPage, pageCount) {
    setLoading(true);
    type === 'mine'
      ? util.get(
          api.board.shareList,
          {
            currentPage: TablePageStore.config.currentPage || currentPage || 1,
            pageCount: TablePageStore.config.pageCount || pageCount || 10,
          },
          (res) => {
            res && setData(res);
            setLoading(false);
          }
        )
      : util.get(
          api.board.shareFromOther,
          {
            currentPage: TablePageStore.config.currentPage || currentPage || 1,
            pageCount: TablePageStore.config.pageCount || pageCount || 10,
          },
          (res) => {
            res && setData(res);
            setLoading(false);
          }
        );
  }

  function showShareModel(store, index, sourceType) {
    setShareModalVisible(true);
    setShareStore(store);
    setShareIndex(index);
    setSourceType(sourceType);
  }

  function addContent(store) {
    for (let i = 0; i < store.columns.length; i++) {
      const currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'operate') {
        store.columns[i].render = (text, record, index) => {
          return (
            <div className="operateGroup">
              <span
                className="operateDom-update"
                onClick={() => {
                  showShareModel(store, index, record.sourceType);
                }}>
                设置
              </span>
            </div>
          );
        };
      } else if (currentRowData === 'sourceType') {
        store.columns[i].render = (text, record, index) => {
          return sourceTypes[text];
        };
      } else if (currentRowData === 'sourceName' && type === 'other') {
        store.columns[i].render = (text, record, index) => {
          let link = '';
          let id = record.id.replace(/,/g, '');
          switch (record.sourceType) {
            case '4':
              link = `${process.env.REACT_APP_Base_Url}/wj/detail?surveyId=${id}`;
              break;
            case '102':
              link = `${process.env.REACT_APP_Base_Url}/design/customerJourney/detail/${id}?type=2&name=${text}`;
              break;
            case '103':
              link = `${process.env.REACT_APP_Base_Url}/design/blueprint/detail/${id}?type=3&name=${text}`;
              break;
            case '104':
              link = `${process.env.REACT_APP_Base_Url}/wj/surveyPush/${id}`;
              break;
            case '105':
              link = `${process.env.REACT_APP_Base_Url}/wj/surveyUser/${id}`;
              break;
            case '106':
              link = `${process.env.REACT_APP_Base_Url}/action/warningLog/dashboard/${id}`;
              break;
            default:
              link = `${process.env.REACT_APP_Base_Url}/customDetail/${id}?boardName=${record.name}&productName=${pageHeaderStore.parameter.name}`;
              break;
          }
          return link ? (
            <a href={link} target="_blank">
              {text}
            </a>
          ) : (
            text
          );
        };
      } else if (currentRowData === 'editable') {
        store.columns[i].render = (text, record, index) => {
          return text ? '分享可协作' : '分享仅查看';
        };
      } else if (currentRowData === 'createdByName') {
        store.columns[i].render = (text, record, index) => {
          const textArr = text.split('(');
          return (
            <Fragment>
              <div>{textArr[0]}</div>
              {textArr[1] ? <div>({textArr[1]}</div> : null}
            </Fragment>
          );
        };
      }
    }
  }

  const tableHeadData =
    type === 'mine'
      ? [['名称', '类型', '分享类型', '更新时间', '操作'], ['sourceName', 'sourceType', 'shareType', 'updatedAt', 'operate', 'id', 'sourceId']]
      : [
          ['名称', '类型', '来源', '分享人', '分享时间', '更新时间'],
          ['sourceName', 'sourceType', 'editable', 'createdByName', 'createdAt', 'updatedAt', 'id', 'sourceId'],
        ];
  return (
    <Fragment>
      {!loading ? <CommonTable fetchData={fetchData} parentData={data} addContent={addContent} tableHeadData={tableHeadData} isSort={false} /> : <Loading />}

      {shareModalVisible && (
        <ShareModelEnhance
          store={shareStore}
          index={shareIndex}
          type={realSourceTypes[sourceType] || parseInt(sourceType)}
          visible={shareModalVisible}
          shareOnCancel={() => {
            setShareModalVisible(false);
          }}
          reloadData={fetchData}
        />
      )}
    </Fragment>
  );
}

export default ShareList;
