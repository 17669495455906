import { action, observable } from 'mobx';
import sideMenu from '../../config/sideMenu';

class Store {
  @observable isCollapsed = false;
  @observable isShowCheckList = false;
  @observable isTemplate = [true, true, true];
  @observable sidebarAllMenu = sideMenu;
  @observable resetSelectkeyCount = 1; //方便其他模块刷新侧边栏选中项
  @observable allFolderList = [];

  @action.bound
  setAllFolderList(list) {
    this.allFolderList = [...list];
  }

  // 刷新侧边栏选中项
  @action.bound resetSelectkey() {
    this.resetSelectkeyCount += 1;
  }

  @action.bound
  collapsedChange() {
    this.isCollapsed = !this.isCollapsed;
  }
  @action.bound collapsedClosed() {
    this.isCollapsed = true;
  }
  @action.bound collapsedOpen() {
    this.isCollapsed = false;
  }

  @action.bound showCheckList() {
    this.isShowCheckList = true;
  }
  @action.bound closeCheckList() {
    this.isShowCheckList = false;
  }

  @action.bound
  isTemplateChange(num, bool) {
    this.isTemplate[num] = bool;
  }

  @action.bound setSidebarAllMenu(data) {
    this.sidebarAllMenu = { ...data };
  }

  @observable dashboardType = this.initDashboardType();
  @observable folderListRefreshCount = 1;
  @observable folderListRefreshCountNoRedirect = 1;
  @action.bound refreshFolderListNoRedirect = () => {
    this.folderListRefreshCountNoRedirect += 1;
  };
  @action.bound refreshFolderList = (type) => {
    type && (this.dashboardType = type);
    this.folderListRefreshCount += 1;
  };
  initDashboardType() {
    const pathName = window.location.pathname;
    if (pathName.includes('/customerJourney')) {
      return 2;
    } else if (pathName.includes('/blueprint')) {
      return 3;
    }
    return 1;
  }
}

export default new Store();
