export default {
  typeToHref(type) {
    switch (type) {
      case 1:
        return 'eventAnalytics';
      case 2:
        return 'pathAnalytics';

      case 3:
        return 'remainAnalytics';
      case 5:
        return 'surveyAnalytics';
      case 7:
        return 'distributionAnalytics';
      case 8:
        return 'surveyAnalytics';
      case 10:
        return 'BIAnalytics';
      case 14:
        return 'combinationAnalytics';
      default:
        return 'eventAnalytics';
    }
  },
};
