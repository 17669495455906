import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import loginStore from '../Personal/Login/store';
import './index.sass';
import authorityStore from '../AuthorityStore/store';
import manageUtil from '../Product/Management/util';
import util from '../util';
import pageHeaderStore from '../component/PageHeader/PageHeaderStore';
import AuthorityStore from '../AuthorityStore/store';
import Loading from '../component/Loading/Loading';

@observer
class Index extends Component {
  constructor() {
    super();
    this.title = '首页';
    const type = util.getQueryString('type');
    const code = util.getQueryString('code');
    const auth_code = util.getQueryString('auth_code');
    const c2 = util.getQueryString('c2');
    const c3 = util.getQueryString('c3');

    // code && c2 && c3 为飞书登录跳转链接
    this.isLogin = (type && code) || (type && auth_code) || (code && c2 && c3);
  }

  componentDidMount() {
    this.setHomeTitle();
    this.disposer = autorun(() => {
      if (!this.isLogin) {
        if (!pageHeaderStore.parameter.idsite) {
          AuthorityStore.init(true);
        } else {
          manageUtil.changeLinkPath(this.props.history, pageHeaderStore.parameter.idsite, true);
        }
      }
    });
  }

  componentWillUnmount() {
    this.disposer();
  }

  setHomeTitle() {
    document.title = `用户体验评估,设计,测试,提升_${process.env.REACT_APP_BDtitle}体验管理云`;
  }

  render() {
    return <Loading />;
  }
}

export default Index;
