import React, { useState, useEffect } from 'react';
import { Modal, Radio, message } from 'antd';
import CommonTable from '../../CommonTable';
import tableUtil from '../../../utils/tableUtil';
import { decodeMetricName } from '../util';
import Loading from '../../../component/Loading/Loading';
import util from '../../../util';
import api from '../../../api';

function NlpModal(props) {
  const { modalInfo, onCancel, cardId, cardRefreshWithoutConfig, keysDisplay } = props;
  const { visible, config, record } = modalInfo;

  const [nlpData, setNlpData] = useState(null);
  const [sentimentWordList, setSentimentWordList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    const detailIdField = keysDisplay.find((item) => item.name === config.onClick.detailIdField || item.randomName === config.onClick.detailIdField);
    if (detailIdField) {
      setConfirmLoading(false);
      const { randomName, agg, name } = detailIdField;
      const expression = randomName || `${agg}(${decodeMetricName(name)})`;
      util.get(api.nlp.getNlpPoints, { answerId: record[expression] }, (res) => {
        setNlpData(res);
        setSentimentWordList(res.map((item) => item.sentimentWord));
      });
    }
  }, []);

  function submit() {
    setConfirmLoading(true);
    const hide = message.loading('保存中，请勿操作！');
    const param = nlpData.map((item, index) => {
      return {
        ids: item.ids,
        prop: item.prop,
        sentimentWord: sentimentWordList[index],
      };
    });
    util.post(
      api.nlp.updateAndEtlNlpData,
      param,
      (res) => {
        setConfirmLoading(false);
        hide();
        res.code === 200 ? message.success(res.msg) : message.error(res.msg);
        onCancel();
        cardRefreshWithoutConfig(cardId);
      },
      true
    );
  }

  function onSentimentWordChange(value, index) {
    const sentimentWordListCopy = sentimentWordList;
    sentimentWordListCopy[index] = value;
    setSentimentWordList([...sentimentWordListCopy]);
  }

  function addContent(store) {
    const config = [
      {
        currentRowData: 'sentimentWords',
        children: (store, text, record, index) => {
          return (
            <Radio.Group className="nowrap-radio-group" value={sentimentWordList[index]} onChange={(e) => onSentimentWordChange(e.target.value, index)}>
              {store.parentData[index].sentimentWords.map((item) => {
                return (
                  <Radio value={item} key={item}>
                    {item}
                  </Radio>
                );
              })}
            </Radio.Group>
          );
        },
      },
    ];
    return tableUtil.addContentByConfig(store, config);
  }

  return (
    <Modal visible={visible} onOk={submit} onCancel={onCancel} width={800} confirmLoading={confirmLoading}>
      {!nlpData ? (
        <Loading />
      ) : (
        <CommonTable
          parentData={{ data: nlpData }}
          tableHeadData={[['观点', '评论选项'], ['prop', 'sentimentWords', 'sentimentWord']]}
          isSort={false}
          addContent={addContent}
          reloadContent={sentimentWordList}
        />
      )}
    </Modal>
  );
}

export default NlpModal;
