import CommonMixChart from '../../containers/CommonMixChart/index';
import CommonTable from '../../containers/CommonTable';

export default {
  title: '广告',
  components: [
    {
      isCard: true,
      title: '广告',
      children: [
        {
          component: CommonMixChart,
          col: 24,
          props: {
            options: ['pv', 'uv', 'uvNew', 'visits'],
            chartProps: [
              {
                col: 6,

                type: 'pie',
                dims: 'channel_utm_campaign',
                fields: 'pv',
                pageCount: 9,
                orderDesc: true,
                noLegendSelect: true,
                orderColumn: 'pv',
                showAll: true,
              },
              {
                col: 18,
                lineType: 'area',
                dims: 'date,channel_utm_campaign',
                fields: 'pv',
                pageCount: 10,
                orderDesc: true,
                orderColumn: 'pv',
                showAll: true,
                ignoreCache: true,
              },
            ],
          },
        },
      ],
    },
    {
      component: CommonTable,
      isCard: true,
      props: {
        dims: 'channel_utm_campaign',
        fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',

        orderDesc: true,
        currentPage: 1,
        pageCount: 10,
        orderColumn: 'pv',
      },
    },
  ],
};
