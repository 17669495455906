import React from 'react';
import './index.sass';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import mathUtil from '../../utils/mathUtil';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import { Tooltip } from 'antd';

@observer
class Main extends React.Component {
  @observable date = pageHeaderStore.parameter.date;
  render() {
    let floatNum = this.props.type === 'table' ? parseFloat(this.props.number) : this.props.number; //类型为table时传的number为转格式后的字符串百分比
    // const title = this.props.title || '环比';
    const title = '环比';
    let compareClass;
    let compareIconClass;
    if (Number(floatNum) === floatNum) {
      if (floatNum > 0) {
        compareClass = ' comparision-increase';
        compareIconClass = ' icon-basic-shangsheng';
      } else if (floatNum < 0) {
        compareClass = ' comparision-decrease';
        compareIconClass = ' icon-basic-xiajiang';
      } else {
        compareClass = ' comparision-balence';
        compareIconClass = ' icon-basic-pingheng';
      }
    }
    const { tip } = this.props;
    return (
      <Tooltip placement="bottomLeft" title={tip} mouseEnterDelay={0.5}>
        <div
          className={`comparision${compareClass ? compareClass : ''} ${this.props.size === 'middle' ? 'middleSize' : null} ${this.props.type &&
            `${this.props.type}-comparision`}`}>
          <Tooltip
            placement="bottom"
            title={
              this.props.notShowToolTips
                ? ''
                : `和${mathUtil.formatDate(pageHeaderStore.parameter.date)}的数据对比${floatNum > 0 ? '上升' : '下降'}${mathUtil.percentFrom04f(
                    Math.abs(floatNum)
                  )}`
            }
            mouseEnterDelay={0.5}>
            <span className="textColor">
              {/* 环比的提示 */}

              {this.props.noText ? '' : title}
            </span>
            {compareIconClass ? <i className={'iconfont' + compareIconClass} /> : <i className={'iconfont' + compareIconClass} />}
            <span className={Number(floatNum) === 0 ? 'noneColor' : 'noneNum'}>
              {this.props.type === 'table' ? this.props.number.replace('-', '') : mathUtil.percentFrom04f(Math.abs(floatNum))}
            </span>
          </Tooltip>
        </div>
      </Tooltip>
    );
  }
}
export default Main;
