import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE, DEFAULT_FONT_WEIGHT, SSO_URL } from './globalConstants';
import { message } from 'antd';
import { OptionProps } from 'antd/lib/select';

/**
 * UUID生成器
 * @param len 长度 number
 * @param radix 随机数基数 number
 * @returns {string}
 */
export const uuid = (len, radix = 62) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) {
      uuid[i] = chars[Math.floor(Math.random() * radix)];
    }
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = Math.floor(Math.random() * 16);
        uuid[i] = chars[i === 19 ? ((r % 4) % 8) + 8 : r];
      }
    }
  }
  return uuid.join('');
};

export function safeAddition(num1, num2) {
  const decimalDigits = Math.max(
    `${num1}`.indexOf('.') >= 0 ? `${num1}`.substr(`${num1}`.indexOf('.') + 1).length : 0,
    `${num2}`.indexOf('.') >= 0 ? `${num2}`.substr(`${num2}`.indexOf('.') + 1).length : 0
  );
  if (decimalDigits) {
    const times = Math.pow(10, decimalDigits);
    return (Math.round(num1 * times) + Math.round(num2 * times)) / times;
  } else {
    return num1 + num2;
  }
}

export function getErrorMessage(error) {
  return error.response ? (error.response.data.header ? error.response.data.header.msg : error.message) : error.message;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

let utilCanvas = null;

export const getTextWidth = (text, fontWeight, fontSize, fontFamily) => {
  const canvas = utilCanvas || (utilCanvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width);
};

// ref: https://github.com/segmentio/is-url/blob/master/index.js
const protocolAndDomainReg = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomainReg = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const nonLocalhostDomainReg = /^[^\s\.]+\.\S{2,}$/;
export function isUrl(str) {
  if (typeof str !== 'string') {
    return false;
  }

  const match = str.match(protocolAndDomainReg);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainReg.test(everythingAfterProtocol) || nonLocalhostDomainReg.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

const imageReg = /\.(bmp|png|jpg|jpeg|gif)$/;
export function isImagePath(pathname) {
  if (typeof pathname !== 'string') {
    return false;
  }
  return imageReg.test(pathname);
}

export function hasProperty(obj, key) {
  return obj[key] ? obj[key] : false;
}

export const tuple = (...args) => args;

export const tupleNum = (...args) => args;

export function filterSelectOption(input, option) {
  const text = option.props.children;
  return text.toLowerCase().includes(input.toLowerCase());
}

export function filterTreeSelectOption(input, treeNode) {
  const text = treeNode.props.title;
  return text.toLowerCase().includes(input.toLowerCase());
}

//设置cookie
export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var setting = 'expires=' + d.toUTCString() + '; path=' + '/';
  document.cookie = cname + '=' + cvalue + '; ' + setting;
}

//获取cookie
export function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return '';
}

//删除某项cookie
export function deleteCookie(name) {
  setCookie(name, '', -1);
}

// 获取URL中 name 的参数值
export function getQueryString(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i'); // 匹配目标参数
  const r = window.location.search.substr(1).match(reg); // 获取url中"?"符后的字符串并正则匹配
  if (r !== null) return decodeURIComponent(r[2]);
  return null;
}

export function metricAxisLabelFormatter(value) {
  if (value >= Math.pow(10, 12)) {
    return `${precision(value / Math.pow(10, 12))}T`;
  } else if (value >= Math.pow(10, 9) && value < Math.pow(10, 12)) {
    return `${precision(value / Math.pow(10, 9))}B`;
  } else if (value >= Math.pow(10, 6) && value < Math.pow(10, 9)) {
    return `${precision(value / Math.pow(10, 6))}M`;
  } else if (value >= Math.pow(10, 3) && value < Math.pow(10, 6)) {
    return `${precision(value / Math.pow(10, 3))}K`;
  } else {
    return value;
  }

  function precision(num) {
    return num >= 10 ? Math.floor(num) : num.toFixed(1);
  }
}
