import 'formdata-polyfill';
import CryptoJS from 'crypto-js';
import { message } from 'antd';
import { JSEncrypt } from 'jsencrypt';
import { SSO_DOMAIN } from './api';
import { passwordReg } from './config';
import loginStore from './Personal/Login/store';
// 不显示新版移动端的网站
const unShowList = ['sams'];

export const util = {
  // 显示新版控制器api
  showNewContorl() {
    // return process.env.REACT_APP_BDkey !== 'sams';
    return true;
  },
  isMobile() {
    const bodyWidth = document.body.offsetWidth;
    return bodyWidth < 768;
  },
  showNewMobileStyle() {
    return process.env.REACT_APP_Show_NewMobile;
  },
  changeTableBgByTheme(ratio) {
    if (process.env.REACT_APP_TableBg_Theme === 'blue') {
      return `rgba(31, 128, 222,${ratio})`;
    }
    return `rgba(20,196,162,${ratio}`;
  },
  customFlat(arr, num) {
    const currentArr = arr;

    function flatDeep(arr, d = 1) {
      return d > 0 ? arr.reduce((current, val) => current.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), []) : arr.slice();
    }

    return flatDeep(currentArr, num);
  },
  debounce(func, wait) {
    let timeout;
    return function() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func();
      }, wait);
    };
  },
  blockFetch() {
    if (window.controller) {
      window.controller.abort();
      window.controller = null;
      window.controller = new window.AbortController();
    }
  },
  // 获取URL中 name 的参数值
  getQueryString(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i'); // 匹配目标参数
    const r = window.location.search.substr(1).match(reg); // 获取url中"?"符后的字符串并正则匹配
    if (r !== null) return decodeURIComponent(r[2]);
    return null;
  },
  //获取url所有的参数
  getAllQueryString() {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    var queryObj = {};
    vars.forEach((item) => {
      var itemArr = item.split('=');
      queryObj[itemArr[0]] = itemArr[1];
    });
    return queryObj;
  },
  // 删除URL中的 name 参数
  delQueryString(url, name) {
    const baseUrl = `${url.split('?')[0]}?`;
    const query = url.split('?')[1];
    if (query && query.indexOf(name) > -1) {
      const obj = {};
      const arr = query.split('&');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split('=');
        obj[arr[i][0]] = arr[i][1];
      }
      delete obj[name];
      var url =
        baseUrl +
        JSON.stringify(obj)
          .replace(/[\"\{\}]/g, '')
          .replace(/\:/g, '=')
          .replace(/\,/g, '&');
      return url;
    }
    return url;
  },
  createQueryStringPath(path, obj) {
    const queryString =
      obj &&
      Object.keys(obj)
        .map((item) => `${item}=${obj[item] !== null ? encodeURIComponent(obj[item]) : ''}`)
        .join('&');
    return queryString ? `${path}?${queryString}` : path;
  },
  getPath() {
    const arr = document.location.pathname.split('/');
    const id = arr.pop();
    return {
      id,
      path: arr.join('/'),
      fullPath: document.location.pathname,
    };
  },
  getLast30() {
    const target = new Date();
    const target2 = new Date();
    target.setTime(target.getTime() - 86400000);
    target2.setTime(target2.getTime() - 2592000000);
    return `${target2.format('yyyy-MM-dd')},${target.format('yyyy-MM-dd')}`;
  },
  // 对URL编码,item中间加入&
  parseObject(obj) {
    return Object.keys(obj)
      .reduce((result, item) => {
        if (obj[item]) {
          result.push(`${item}=${encodeURIComponent(obj[item])}`);
        }
        return result;
      }, [])
      .join('&');
  },
  toCustomerSSO(params) {
    if (process.env.REACT_APP_CUSTOM_SSOURL) {
      // window.location.href = `${process.env.REACT_APP_CUSTOM_SSOURL}&redirect_uri=${encodeURIComponent(
      //   window.location.href.replace('&type=', '&_type=').replace('?type=', '?_type=') +
      //     (window.location.href.indexOf('?') > -1 ? '&' : '') +
      //     'type=' +
      //     process.env.REACT_APP_CUSTOM_SSOType
      // )}`;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('code');
      urlParams.delete('type');
      urlParams.append('type', process.env.REACT_APP_CUSTOM_SSOType);
      window.location.href = `${process.env.REACT_APP_CUSTOM_SSOURL}&redirect_uri=${encodeURIComponent(
        window.location.protocol + '//' + window.location.hostname + window.location.pathname + '?' + urlParams.toString()
      )}`;
    } else {
      this.toSSOLogout(params);
    }
  },
  toSSOLogout(params) {
    const ssoUrl = `${SSO_DOMAIN}/logout?redirect_url=${encodeURIComponent(window.location)}`;
    let urlParams = '';
    if (params) {
      params.map((param) => {
        urlParams += `&${param.key}=${param.value}`;
      });
    }
    window.location.href = `${ssoUrl}${urlParams}`;
  },
  toSSOLogin(data) {
    const ssoUrl = `${SSO_DOMAIN}/login?bindAccount=${data.bindAccount}&redirect_url=${data.redirect_url}`;
    window.location.href = ssoUrl;
  },
  processRes(withoutCode, res, cb, resolve, reject) {
    try {
      // 如果有res.header则是BI接口
      const code = res.header ? res.header.code : res.code;
      const msg = res.header ? res.header.msg : res.msg;
      let data;
      if (res.header) {
        data = withoutCode ? res.payload : { code, data: res.payload, msg };
      } else {
        data = withoutCode ? res.data : res;
      }
      switch (code) {
        case 200:
          // 接口正常返回时
          cb && cb(data, code);
          resolve && resolve(data);
          break;
        case 201:
        case 202:
          // 企业微信登陆失败
          this.toCustomerSSO([{ key: 'errorMsg', value: 'notWxTpAuth' }]);
          break;
        case 400:
        case 404:
        case 500:
          // 操作失败类
          message.error(msg, 1);
          cb && cb(data, code);
          break;
        case 407:
          // 无权限时
          message.error(msg);
          loginStore.setPermission(false);
          break;
        case 401:
        case 501:
        case 403:
          // 未登录时
          this.toCustomerSSO();
          break;
        case 2002:
          // 跳到绑定企业微信
          this.toSSOLogin(res.data);
          break;
        default:
          cb && cb(data, code, msg);
          reject && reject(res);
          break;
      }
      // if (res.code === 200) {
      //   cb && (withoutCode ? cb(res.data) : cb(res));
      //   if (resolve) {
      //     withoutCode ? resolve(res.data) : resolve(res);
      //   }
      // } else if (res.header && res.header.code === 200) {
      //   cb && (withoutCode ? cb(res.payload) : cb({ data: res.payload }));
      //   if (resolve) {
      //     withoutCode ? resolve(res.payload) : resolve({ data: res.payload });
      //   }
      // } else if (res.code === 401 || res.code === 501) {
      //   this.toCustomerSSO();
      // } else if (res.code === 407) {
      //   message.error(res.msg);
      //   loginStore.setPermission(false);
      //   withoutCode ? cb(res.data) : cb(res);
      //   if (resolve) {
      //     withoutCode ? resolve(res.data) : resolve(res);
      //   }
      // } else {
      //   withoutCode ? cb(res.data) : cb(res);
      //   // message.error(res.msg ? res.msg : '请求数据出错，请稍后再试');
      //   if (reject) {
      //     reject(res);
      //   }
      // }
    } catch (e) {
      console.error(e);
    }
  },
  get(url, parameter, cb, error, signal = true) {
    return new Promise((resolve, reject) => {
      const param = {
        method: 'GET',
        credentials: 'include',
      };
      // IE浏览器需要每次请求刷新时间戳，防止使用缓存数据
      if (isUsingIE) {
        parameter ? (parameter.ieReloadVer = new Date().getTime()) : (parameter = { ieReloadVer: new Date().getTime() });
      }
      signal && (param.signal = window.controller ? window.controller.signal : null);
      if (this.checkNeedSignApi(url) && parameter) {
        parameter.sign = this.encryptionParam(parameter);
      }
      fetch(parameter ? this.createQueryStringPath(url, parameter) : url, param)
        .then((res) => {
          if (!res.ok) {
            cb(null);
            reject(res);
          }
          return res.json();
        })
        .then((res) => {
          this.processRes(true, res, cb, resolve, reject);
        })
        .catch((e) => {
          // if (error) {
          // message.error('请求数据出错，请稍后再试');
          // }
        });
    });
  },
  post(url, form, cb, isBI) {
    // BI系统接口格式有所不同
    return new Promise((resolve, reject) => {
      let obj = {};
      if (Object.prototype.toString.call(form) === '[object HTMLFormElement]') {
        const formData = new FormData(form);
        formData.forEach((value, key) => (obj[key] = value));
      } else {
        obj = form;
      }
      if (this.checkNeedSignApi(url) && obj) {
        if (Array.isArray(obj)) {
          obj.forEach((item) => {
            item.sign = this.encryptionParam(item);
          });
        } else {
          obj.sign = this.encryptionParam(obj);
        }
      }
      const queryString = !isBI
        ? Object.keys(obj)
            .map((item) => `${item}=${encodeURIComponent(obj[item])}`)
            .join('&')
        : '';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': isBI ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded; charset=UTF-8', // BI请求头规范有所不同
        },
        credentials: 'include',
        body: isBI ? JSON.stringify(obj) : queryString,
      })
        .then((res) => res.json())
        .then((res) => {
          this.processRes(false, res, cb, resolve, reject);
        })
        .catch((e) => {
          // message.error('请求数据出错，请稍后再试');
        });
    });
  },
  upload(url, form, cb, isBI) {
    const file = new FormData();
    Object.entries(form).forEach(([fieldName, fieldValue]) => {
      if (fieldValue || fieldValue === 0) {
        file.append(fieldName, fieldValue);
      }
    });
    // BI系统接口格式有所不同
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: file,
      })
        .then((res) => res.json())
        .then((res) => {
          this.processRes(false, res, cb, resolve, reject);
        })
        .catch((e) => {
          // message.error('请求数据出错，请稍后再试');
        });
    });
  },
  // 下载文件流
  download(url, form, name, cb, isBI) {
    const hideMessage = message.loading(`${name}下载中，请不要刷新页面`, 0);
    return new Promise((resolve, reject) => {
      let obj = {};
      if (Object.prototype.toString.call(form) === '[object HTMLFormElement]') {
        const formData = new FormData(form);
        formData.forEach((value, key) => (obj[key] = value));
      } else {
        obj = form;
      }
      const queryString = !isBI
        ? Object.keys(obj)
            .map((item) => `${item}=${encodeURIComponent(obj[item])}`)
            .join('&')
        : '';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': isBI ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded; charset=UTF-8', // BI请求头规范有所不同
        },
        credentials: 'include',
        body: isBI ? JSON.stringify(form) : queryString,
      })
        .then((res) => res.blob())
        .then((res) => {
          if (isUsingIE) {
            try {
              window.navigator.msSaveBlob(res, name);
            } catch (e) {
              // console.log(e)
            }
          } else {
            const blob = res;
            const reader = new FileReader();
            reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
            reader.onload = function(e) {
              // 转换完成，创建一个a标签用于下载
              const a = document.createElement('a');
              a.href = e.target.result;
              a.download = name;
              document.getElementsByTagName('body')[0].append(a); // 修复firefox中无法触发click
              a.click();
              resolve(200);
              a.remove();
            };
          }
          hideMessage();
        })
        .then(() => {
          cb && cb();
        })
        .catch((e) => {});
    });
  },
  delete(url, parameter, cb, error, signal = true) {
    return new Promise((resolve, reject) => {
      const param = {
        method: 'DELETE',
        credentials: 'include',
      };
      signal && (param.signal = window.controller ? window.controller.signal : null);
      fetch(parameter ? this.createQueryStringPath(url, parameter) : url, param)
        .then((res) => {
          if (!res.ok) {
            cb(null);
            reject(res);
          }
          return res.json();
        })
        .then((res) => {
          this.processRes(true, res, cb, resolve, reject);
        })
        .catch((e) => {
          // if (error) {
          //   message.error('请求数据出错，请稍后再试');
          // }
        });
    });
  },
  put(url, form, cb) {
    return new Promise((resolve, reject) => {
      let obj = {};
      if (Object.prototype.toString.call(form) === '[object HTMLFormElement]') {
        const formData = new FormData(form);
        formData.forEach((value, key) => (obj[key] = value));
      } else {
        obj = form;
      }
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        credentials: 'include',
        body: JSON.stringify(form),
      })
        .then((res) => res.json())
        .then((res) => {
          this.processRes(false, res, cb, resolve, reject);
        })
        .catch((e) => {});
    });
  },
  postAllCode(url, form, cb, error) {
    let obj = {};
    if (Object.prototype.toString.call(form) === '[object HTMLFormElement]') {
      const formData = new FormData(form); // form = document.getElementsByTagName('form')[0]
      formData.forEach((value, key) => (obj[key] = value));
    } else {
      obj = form;
    }
    const queryString = Object.keys(obj)
      .map((item) => `${item}=${encodeURIComponent(obj[item])}`)
      .join('&');
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      credentials: 'include',
      body: queryString,
    })
      .then((res) => res.json())
      .then((res) => {
        this.processRes(false, res, cb);
      })
      .catch((e) => {
        if (error) {
          // typeof error === 'string' ?message.info(error) : error(e)
        } else {
          // message.error('请求数据出错，请稍后再试')
        }
      });
  },
  decrypt(word, keyStr) {
    // 解密
    keyStr = keyStr || 'bf960145b2a1338f';
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
  unescapeHTML(string) {
    return String(string)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
    // const entityMap = {
    //   '&lt;': '<',
    //   '&gt;': '>',
    //   '&quot;': '"',
    //   '&#39;': "'",
    //   '&#x2F;': '/',
    //   '&#x60;': '`',
    //   '&#x3D;': '=',
    //   '&#92;': '\\',
    // };
    // return String(string).replace(/&(?:#x3D|lt|gt|quot|#39|#x2F|#x60|#92);/g, (s) => entityMap[s]);
  },

  subStartAndEndStr(str, removedStr, startLength, endLength) {
    removedStr = removedStr || '';
    startLength = startLength || 0;
    endLength = endLength || 0;
    str = str.replace(removedStr, '');
    if (startLength === 0 && endLength === 0) {
      return str;
    }
    if (str.strLen() <= startLength + endLength) {
      return str;
    }
    const startStr = str.subCHStr(0, startLength);
    const endStr = str.subCHStr(str.strLen() - endLength, endLength);
    return `${startStr}...${endStr}`;
  },

  sankey: {
    resetChartData(data, id) {
      if (data && data.data) {
        let maxWeight = 1;
        let minWeight = 1;
        data.data = data.data.map((item) => {
          if (item.weight > maxWeight) {
            maxWeight = item.weight;
          }
          if (item.weight < minWeight) {
            minWeight = item.weight;
          }
          if (item.id.indexOf('exit') > -1) {
            item.colorIndex = '102';
            item.bakColorIndex = '102';
          } else {
            item.colorIndex = '101';
            item.bakColorIndex = '101';
          }
          return item;
        });
        const multi = 1 / (maxWeight / 100);
        const heightsMap = {};
        const heightsExitMap = {};
        data.data = data.data.map((item) => {
          item.dataWeight = item.weight;
          if (item.weight !== 0) {
            item.weight = 1 + item.weight * multi;
          }
          heightsMap[item.from] = (heightsMap.hasOwnProperty(item.from) ? heightsMap[item.from] : 0) + parseFloat(item.weight);
          if (item.to.indexOf('exit_') > -1) {
            heightsExitMap[item.from] = parseFloat(item.weight);
          }
          return item;
        });
        data.nodes = data.nodes.map((item) => {
          item.nodeHeight = heightsMap[item.id] * 1.159;
          item.nodeExitHeight = (heightsExitMap.hasOwnProperty(item.id) ? heightsExitMap[item.id] : 0) * 1.159;
          if (item.id.indexOf('exit') > -1) {
            item.colorIndex = '102';
            item.bakColorIndex = '102';
          } else {
            item.colorIndex = '101';
            item.bakClorIndex = '101';
          }
          if (item.id.endsWith(id)) {
            item.colorIndex = '110';
            item.bakColorIndex = '110';
          }
          return item;
        });
      }
      return data;
    },
    highlight(data, currentHighlightId) {
      if (!data || currentHighlightId.indexOf('exit_') === 0) return;
      data.data = data.data.map((item) => {
        item.highlightValue = null;
        if (item.to.indexOf('exit_') === -1 && data.passData[currentHighlightId] && Object.keys(data.passData[currentHighlightId].data).includes(item.id)) {
          item.colorIndex = '103';
          item.highlightValue = data.passData[currentHighlightId].data[item.id];
        } else {
          item.colorIndex = item.bakColorIndex;
        }
        return item;
      });
      data.nodes = data.nodes.map((item) => {
        item.highlightValue = null;
        if (data.passData[currentHighlightId] && Object.keys(data.passData[currentHighlightId].nodes).includes(item.id)) {
          if (item.id.indexOf('exit') === -1) {
            if (item.id === currentHighlightId) {
              item.colorIndex = '104';
            } else {
              item.colorIndex = '103';
            }
          }
          item.highlightValue = data.passData[currentHighlightId].nodes[item.id];
        } else {
          item.colorIndex = item.bakColorIndex;
        }
        return item;
      });
    },
    clearHighlight(data) {
      if (!data) return;
      data.nodes = data.nodes.map((item) => {
        item.colorIndex = item.bakColorIndex;
        return item;
      });
      data.data = data.data.map((item) => {
        item.colorIndex = item.bakColorIndex;
        return item;
      });
    },
    resetSumData(obj) {
      let sumData = [];
      if (obj.preLength !== undefined && obj.nextLength !== undefined) {
        // 上下游
        if (obj.data && obj.data.length > 0) {
          sumData = obj.data;
          const totolLength = obj.preLength + 1 + obj.nextLength;
          for (let i = -obj.preLength; i < 0; i++) {
            sumData[i + obj.preLength].index = i;
            sumData[i + obj.preLength].deletable = true;
          }
          sumData[obj.preLength].index = 0;
          sumData[obj.preLength].deletable = false;
          for (let i = obj.preLength + 1; i < totolLength; i++) {
            sumData[i].index = i - obj.preLength;
            sumData[i].deletable = true;
          }
        } else {
          for (let i = obj.minShowLength; i <= obj.maxShowLength; i++) {
            if (i === -1 || i === 0 || i === 1) {
              sumData.push({ index: i, deletable: false });
            } else {
              sumData.push({ index: i, deletable: true });
            }
          }
        }
      } else if (obj.showLength !== undefined && obj.data && obj.data.length > 0) {
        if (obj.data[0].name !== undefined && obj.entryTypeName) {
          // 转化路径
          sumData.push({ name: obj.entryTypeName, deletable: false });
          obj.data.forEach((item) => {
            sumData.push({ ...item, ...{ deletable: false } });
          });
          sumData.push({ name: '         ', deletable: false });
        } else {
          // 用户路径
          if (obj.data && obj.data.length > 0) {
            sumData.push({ name: '进入渠道', deletable: false });
            obj.data &&
              obj.data.forEach((item, i) => {
                if (i === 0) {
                  sumData.push({ ...item, ...{ name: '起始页面', deletable: false } });
                } else {
                  sumData.push({ ...item, ...{ index: i, deletable: true } });
                }
              });
          } else {
            for (let i = -1; i < obj.showLength - 1; i++) {
              if (i === -1 || i === 0) {
                sumData.push({ index: i, deletable: false });
              } else {
                sumData.push({ index: i, deletable: true });
              }
            }
          }
        }
      }
      return sumData;
    },
  },
  // 数据有无跨年
  isBeyondYear(arr) {
    return arr.length ? arr[0].substr(0, arr[0].indexOf('年')) !== arr[arr.length - 1].substr(0, arr[arr.length - 1].indexOf('年')) : false;
  },
  addEventListener(n, t, e, i) {
    return n.addEventListener ? (n.addEventListener(t, e, i), !0) : n.attachEvent ? n.attachEvent('on' + t, e) : void (n['on' + t] = e);
  },
  IsPC() {
    const userAgentInfo = navigator.userAgent;
    const Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod');
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },

  isIE() {
    return navigator.userAgent.toLowerCase().indexOf('trident') > -1;
  },
  isSafari() {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  },
  isFirefox() {
    return navigator.userAgent.includes('Firefox');
  },
  // 检查是否需要加密
  checkNeedSignApi(api) {
    // 需要加秘钥sign的接口
    const needSignApi = [
      /\/api\/v3\/views\/.*?\/getdata$/,
      /\/api\/v3\/views\/.*?\/getRData$/,
      /\/api\/v3\/views\/.*?\/getdistinctvalue$/,
      /\/api\/v3\/download\/submit\/view/,
      /\/api\/v3\/views\/infoData/,
      /\/api\/v3\/views\/preview/,
      /\/pack\/dimOptions/,
      /\/tagRule\/blockedNum/,
      /\/tagRule\/save/,
      /\/tagRule\/update/,
    ];
    let flag = false;
    needSignApi.forEach((item) => {
      item.test(api) && (flag = true);
    });
    return flag;
  },
  // 生成加密sign
  encryptionParam(param) {
    return this.rsa(this.JSONToHashCode(param));
  },
  // JSON转hashcode
  JSONToHashCode(json) {
    const str = JSON.stringify(json);
    var hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash.toString();
  },
  // rsa非对称加密
  rsa(password) {
    //之前ssl生成的公钥，复制的时候要小心不要有空格
    var pubKey =
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfnyY7/ysDKH2MITKOvxJixYkWxq8n7iIrj3GlfIo6tC/0UDtUNoRLw1Yt3nj8wuLOoN6d9s0i6G1t2SbH06veP9H9mut1yY6N6EF5Zw9N7pBUYtdwlbKVBxLWq47xPwsWKb15jfLfI+TSlSfXFwAEd6a/BNeY/tzbejLMukWgoQIDAQAB';
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubKey);
    var arr = Array();
    var arr1 = Array();
    var str = '';

    var byteData = str2UTF8(password);

    if (byteData.length > 128) {
      var arr = Array();
      var arr1 = Array();
      for (var i = 0; i < password.length; i += 37) {
        //约定好的用37就算中文也不会超过长度
        arr.push(password.slice(i, i + 37)); //slice比substring更好
      }
      for (var i in arr) {
        arr1.push(encrypt.encrypt(arr[i]));
      }
      str = arr1.join(',');
    } else {
      str = encrypt.encrypt(password);
    }
    return str;
    function str2UTF8(str) {
      var bytes = new Array();
      var len, c;
      len = str.length;
      for (var i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x010000 && c <= 0x10ffff) {
          bytes.push(((c >> 18) & 0x07) | 0xf0);
          bytes.push(((c >> 12) & 0x3f) | 0x80);
          bytes.push(((c >> 6) & 0x3f) | 0x80);
          bytes.push((c & 0x3f) | 0x80);
        } else if (c >= 0x000800 && c <= 0x00ffff) {
          bytes.push(((c >> 12) & 0x0f) | 0xe0);
          bytes.push(((c >> 6) & 0x3f) | 0x80);
          bytes.push((c & 0x3f) | 0x80);
        } else if (c >= 0x000080 && c <= 0x0007ff) {
          bytes.push(((c >> 6) & 0x1f) | 0xc0);
          bytes.push((c & 0x3f) | 0x80);
        } else {
          bytes.push(c & 0xff);
        }
      }
      return bytes;
    }
  },
  // 校验密码格式
  checkPassword(rule, value, callback) {
    if (value && passwordReg.test(value)) {
      callback();
    } else {
      callback('必须包含大小写字母、数字和特殊字符，8-15位');
    }
  },
};

let isUsingIE = util.isIE();

export default util;

// 计算字符串长度
String.prototype.strLen = function() {
  let len = 0;
  for (let i = 0; i < this.length; i++) {
    if (this.charCodeAt(i) > 255 || this.charCodeAt(i) < 0) len += 2;
    else len++;
  }
  return len;
};

// 判断某个字符是否是汉字
String.prototype.strToChars = function() {
  const chars = new Array();
  for (let i = 0; i < this.length; i++) {
    chars[i] = [this.substr(i, 1), this.isCHS(i)];
  }
  String.prototype.charsArray = chars;
  return chars;
};

String.prototype.isCHS = function(i) {
  if (this.charCodeAt(i) > 255 || this.charCodeAt(i) < 0) return true;
  return false;
};

// 截取字符串（从start字节到end字节）
String.prototype.subCHString = function(start, end) {
  let len = 0;
  let str = '';
  this.strToChars();
  for (let i = 0; i < this.length; i++) {
    if (this.charsArray[i][1]) len += 2;
    else len++;
    if (end < len) return str;
    if (start < len) str += this.charsArray[i][0];
  }
  return str;
};
// 截取字符串（从start字节截取length个字节）
String.prototype.subCHStr = function(start, length) {
  return this.subCHString(start, start + length);
};

Date.prototype.format = function(format) {
  let o = {
    'M+': this.getMonth() + 1, // month
    'd+': this.getDate(), // day
    'h+': this.getHours(), // hour
    'm+': this.getMinutes(), // minute
    's+': this.getSeconds(), // second
    'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
    S: this.getMilliseconds(), // millisecond
  };
  if (format.indexOf('H') !== -1) {
    o = {
      'M+': this.getMonth() + 1, // month
      'd+': this.getDate(), // day
      'H+': this.getHours(), // hour
      'm+': this.getMinutes(), // minute
      's+': this.getSeconds(), // second
      'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
      S: this.getMilliseconds(), // millisecond
    };
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    }
  }
  return format;
};
