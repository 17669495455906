import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import Search from '../Search';
import './index.sass';
import { Link } from 'react-router-dom';

@observer
class Main extends React.Component {
  render() {
    return (
      <div className="search-header">
        <div className="head-left">
          {!this.props.hideButton && (
            <div className="search-header-btn">
              {this.props.linkTo ? (
                <Link to={`${this.props.linkTo}`}>
                  <Button type="add">{this.props.title}</Button>
                </Link>
              ) : (
                <Button type="add" onClick={this.props.onClick}>
                  {this.props.title}
                </Button>
              )}
            </div>
          )}
          {this.props.onSearch && (
            <Search onClick={this.props.onSearch} defaultValue={this.props.defaultValue} value={this.props.value} placeholder={this.props.searchPlaceholder} />
          )}
        </div>
      </div>
    );
  }
}

export default Main;
