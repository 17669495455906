export default {
  version: 1,
  themeName: '简洁',
  theme: {
    seriesCnt: '3',
    backgroundColor: 'rgba(255,255,255,0)',
    titleColor: '#050a13',
    subtitleColor: '#7a8493',
    textColorShow: false,
    textColor: '#333',
    markTextColor: '#ffffff',
    color: ['#0B54B2', '#3D76E9', '#53ABFC', '#64CEFC', '#90E4F2', '#958FEB', '#F2AA62', '#EBC15F', '#9FCA5D'],
    borderColor: '#ffffff',
    borderWidth: '0',
    visualMapColor: ['#facd46', '#14c4a2', '#adfff1'],
    legendTextColor: '#050a13',
    kColor: '#d0648a',
    kColor0: 'transparent',
    kBorderColor: '#d0648a',
    kBorderColor0: '#22c3aa',
    kBorderWidth: '1',
    lineWidth: '3',
    symbolSize: '8',
    symbol: 'emptyCircle',
    symbolBorderWidth: '2',
    lineSmooth: false,
    graphLineWidth: '1',
    graphLineColor: '#cccccc',
    mapLabelColor: '#28544e',
    mapLabelColorE: '#349e8e',
    mapBorderColor: '#999999',
    mapBorderColorE: '#22c3aa',
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: '#eeeeee',
    mapAreaColorE: 'rgba(34,195,170,0.25)',
    axes: [
      {
        type: 'all',
        name: '通用坐标轴',
        axisLineShow: true,
        axisLineColor: '#d9d9de',
        axisTickShow: true,
        axisTickColor: '#d9d9de',
        axisLabelShow: true,
        axisLabelColor: '#7a8493',
        splitLineShow: true,
        splitLineColor: ['#e7e7eb'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(207,50,50,0.05)', 'rgba(200,200,200,0.02)'],
      },
      {
        type: 'category',
        name: '类目坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: false,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'value',
        name: '数值坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'log',
        name: '对数坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'time',
        name: '时间坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: '#7a8493',
    toolboxEmpasisColor: '#14c4a2',
    tooltipAxisColor: '#b2b3b9',
    tooltipAxisWidth: 1,
    timelineLineColor: '#14c4a2',
    timelineLineWidth: 1,
    timelineItemColor: '#14c4a2',
    timelineItemColorE: '#14c4a2',
    timelineCheckColor: '#34ac95',
    timelineCheckBorderColor: 'rgba(60,235,210,0.3)',
    timelineItemBorderWidth: 1,
    timelineControlColor: '#14c4a2',
    timelineControlBorderColor: '#14c4a2',
    timelineControlBorderWidth: '0.5',
    timelineLabelColor: '#14c4a2',
    datazoomBackgroundColor: 'rgba(255,255,255,0)',
    datazoomDataColor: '#14c4a2',
    datazoomFillColor: '#eff6fa',
    datazoomHandleColor: '#b2b3b9',
    datazoomHandleWidth: '100',
    datazoomLabelColor: '#7a8493',
  },
};
