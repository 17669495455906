import SurveyTable from '../../containers/Wenjuan'

export default {
  title: '问卷列表',
  components: [
    {
      title: '问卷列表',
      isCard: true,
      className: 'single-card',
      children: [{ component: SurveyTable, props: { type: 'manager' } }]
    }
  ]
}
