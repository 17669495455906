import React, { useState } from 'react';
import { ResizableBox, ResizableProps } from 'react-resizable';
import classnames from 'classnames';
import ResizeHandle from './ResizeHandle';
import './SplitPane.less';

const SplitPane = (props) => {
  const { className, type, invert, spliter, initialSize, maxSize, minSize, children, onResize, onResizeStart, onResizeStop } = props;

  const [child1, child2] = React.Children.toArray(children);
  const [currentSize, setCurrentSize] = useState(initialSize);

  const cls = classnames({
    [className]: !!className,
    'split-pane': true,
    [`split-pane-${type}`]: true,
  });

  const resizableProps = {
    handle: ResizeHandle,
    onResize: (e, { size }) => {
      e.stopPropagation();
      let newSize;
      if (type === 'horizontal') {
        newSize = size.width;
      } else if (type === 'vertical') {
        newSize = size.height;
      }
      setCurrentSize(newSize);
      if (onResize) {
        onResize(newSize);
      }
    },
    onResizeStart: () => {
      if (onResizeStart) {
        onResizeStart();
      }
    },
    onResizeStop: (e, { size }) => {
      let newSize;
      if (type === 'horizontal') {
        newSize = size.width;
      } else if (type === 'vertical') {
        newSize = size.height;
      }
      if (onResizeStop) {
        onResizeStop(newSize);
      }
    },
  };

  if (type === 'horizontal') {
    resizableProps.minConstraints = [minSize, 0];
    resizableProps.maxConstraints = [maxSize, 0];
    resizableProps.resizeHandles = invert ? ['w'] : ['e'];
    resizableProps.width = currentSize;
    resizableProps.axis = 'x';
  } else if (type === 'vertical') {
    resizableProps.minConstraints = [0, minSize];
    resizableProps.maxConstraints = [0, maxSize];
    resizableProps.resizeHandles = invert ? ['s'] : ['n'];
    resizableProps.height = currentSize;
    resizableProps.axis = 'y';
  }

  const paneCls = [
    classnames({
      'split-pane-1': !invert,
      'split-pane-2': invert,
      [`split-pane-${type}-spliter`]: spliter,
    }),
    classnames({
      'split-pane-1': invert,
      'split-pane-2': !invert,
    }),
  ];
  const [pane1Cls, pane2Cls] = paneCls;

  const partitions = [child1, child2];
  if (invert) {
    partitions[1] = <ResizableBox {...resizableProps}>{partitions[1]}</ResizableBox>;
  } else {
    partitions[0] = <ResizableBox {...resizableProps}>{partitions[0]}</ResizableBox>;
  }
  const [content1, content2] = partitions;

  return (
    <div className={cls}>
      <div className={pane1Cls}>{content1}</div>
      <div className={pane2Cls}>{content2}</div>
    </div>
  );
};

export default SplitPane;
