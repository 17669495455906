import React, { Fragment, useState } from 'react';

function MobileContentTable(props) {
  const {
    cols,
    rows,
    metrics,
    fields,
    firstContent,
    secondContent,
    record,
    dataSource,
    id,
    cardRefreshWithoutConfig,
    data,
    queryVariables,
    pagination,
    requestParams,
  } = props;

  const [expand, setExpand] = useState(false);

  return (
    <div className="content-fields">
      <div className="content-fields-block">{record[firstContent[0].randomName || firstContent[0].name]}</div>
      {expand && (
        <Fragment>
          {firstContent.slice(1).map((item) => {
            const fieldName = item.randomName || item.name;
            const displayName = item.fieldDisplay || item.name;
            return (
              <div className="content-fields-inline" key={fieldName}>
                {displayName}: {record[fieldName]}
              </div>
            );
          })}
          {secondContent.map((item, index) => {
            const fieldName = item.randomName || item.name;
            const displayName = item.fieldDisplay || item.name;
            return (
              <div className={`${index === 0 ? 'content-fields-block' : 'content-fields-inline'}`} key={fieldName}>
                {index !== 0 && `${displayName}: `}
                {record[fieldName]}
              </div>
            );
          })}
        </Fragment>
      )}
      <div
        className="main-color-icon"
        onClick={() => {
          setExpand(!expand);
        }}>
        {expand ? '收起' : '展开'}
      </div>
    </div>
  );
}

export default MobileContentTable;
