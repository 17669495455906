import { toJS } from 'mobx';
import mathUtil from './mathUtil';

export default {
  formateSankeyData(res, hasChildrenStep) {
    if (res === null || res === undefined || res.data === null) {
      return { nodes: [], data: [] };
    }
    // 排序nodes的摆放顺序
    let sumData = Object.keys(res.nodes).filter((item) => res.nodes[item].length > 0);
    let nodesArray = [];
    if (hasChildrenStep) {
      const addAttribute = (item, target) => {
        if (!target) {
          return null;
        }
        if (target.includes('exit') || target.includes('summary')) {
          return { ...item, className: 'highcharts-sankey-exit' };
        }
        // if (target.includes('other')) {
        //   return { ...item, className: 'highcharts-sankey-other' };
        // }
        return item;
      };
      sumData = mathUtil.duplicateRemove(sumData.map((item) => item.split('_')[0])).sort();
      Object.keys(res.nodes).forEach((item, index) => {
        // [{ ...res.nodes[item][0], colorIndex: Number(item.split('_')[0]) - 1 }];
        nodesArray = Array.prototype.concat.apply(
          nodesArray,
          res.nodes[item].map((nodeItem) => ({ ...nodeItem, colorIndex: Number(item.split('_')[0]) - 1 }))
        );
      });
      res.data = this.changeDataWithExit(this.changeDataWeight(res.data, res.nodes))
        .map((item) => ({ ...addAttribute(item, item.to), colorIndex: Number(item.from.split('_')[0]) - 1 }))
        .filter((item) => {
          if (item && item.to && item.to.includes('_exit')) {
            return item.from.split('_')[0] === '1';
            // 把除第一步以外的退出线隐藏掉
          }
          return item !== null;
        });
      nodesArray = nodesArray.map((item) => addAttribute(item, item.id)).filter((item) => item !== null);
    } else {
      sumData.sort();
      sumData.forEach((dataItem, dataIndex) => {
        nodesArray = Array.prototype.concat.apply(
          nodesArray,
          res.nodes[`${dataItem}`].map((item) => ({ ...item, colorIndex: dataIndex }))
        );
      });
      res.data = res.data.map((item) => ({ ...item, colorIndex: Number(item.from.split('_')[0]) + 1 }));
    }

    return { nodes: nodesArray, data: res.data, sumData };
  },
  // 把跳过以及退出的线数据重新排到最后
  changeDataWithExit(data) {
    const exitArr = [];
    const skipArr = [];
    return toJS(data)
      .filter((item, index) => {
        if (item.type === 2) {
          skipArr.push(item);
        } else if (item.type === 3) {
          exitArr.push(item);
        }
        return item.type === 1;
      })
      .concat(skipArr, exitArr);
  },
  changeDataWeight(data, node) {
    const MAX = 1000;
    const MIN = 20;

    const arr = [];
    Object.keys(toJS(node)).forEach((nodeKey, index) => {
      if (nodeKey.includes('1_')) {
        toJS(node)[nodeKey].forEach((nodeKeyItem) => {
          arr.push(nodeKeyItem.uv);
        });
      }
    });

    const uvMax = arr.length ? arr.reduce((prev, current) => prev + current) : 0;

    const newData = toJS(data).map((item) => {
      const maxRate = item.weight / uvMax;
      if (uvMax === 0) {
        item.weight = 0;
      } else if (maxRate === 0) {
        item.weight = 0;
      } else if (maxRate * MAX > MIN) {
        item.weight = Number(((item.weight * MAX) / uvMax).toFixed(2));
      } else {
        item.weight = Number(MIN);
      }
      return item;
    });

    return newData;
  },
};
