import CommonMixChart from '../../containers/CommonMixChart/index';
import CommonTable from '../../containers/CommonTable';

export default {
  title: '全部渠道',
  components: [
    {
      col: 24,
      isCard: true,
      title: '全部渠道',
      children: [
        {
          component: CommonMixChart,
          col: 24,
          props: {
            options: ['pv', 'uv', 'uvNew', 'visits'],
            chartProps: [
              {
                col: 6,

                type: 'pie',
                dims: 'channel_type',
                fields: 'pv',
                pageCount: 9,
                orderDesc: true,
                noLegendSelect: true,
                orderColumn: 'pv',
                showAll: true,
              },
              {
                col: 18,
                lineType: 'area',
                dims: 'date,channel_type',
                fields: 'pv',
                pageCount: 10,
                currentPage: 1,
                orderDesc: true,
                orderColumn: 'pv',
                showAll: true,
                ignoreCache: true,
              },
            ],
          },
        },
        // {
        //   component: CommonChart,
        //   col: 24,
        //   props: {
        //     type: 'line',
        //     dims: 'channel_type',
        //     fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages',
        //     pageCount: 5,
        //     orderDesc: true,
        //     orderColumn: 'pv',
        //     showAll: true,
        //   },
        // },
      ],
    },
    {
      isCard: true,
      component: CommonTable,
      col: 24,
      props: {
        dims: 'channel_type',
        fields: 'pv,uv,uvNew,visits,visitorAvgVisits,visitAvgTime,visitAvgPages,bounceRate',

        orderDesc: true,
        currentPage: 1,
        pageCount: 10,
        orderColumn: 'pv',
      },
    },
  ],
};
