import BasicSurveyModal from '../../containers/BasicSurvey/Modal';
import BasicSurveyTable from '../../containers/BasicSurvey/Table';
import CommonChart from '../../containers/Common/CommonChart';
import CommonBreadCrumb from '../../containers/CommonBreadCrumb';
import CommonSummary from '../../containers/CommonSummary';
import CommonTab from '../../containers/CommonTab';
import CommonTable from '../../containers/CommonTable';
import PageDetail from '../../containers/PageDetail';
import PagesetDetailTable from '../../containers/PagesetDetailTable';
import ParamTable from '../../containers/ParameterTable';
import PagePathTable from '../../containers/PagePathTable';
import CommonMixChart from '../../containers/CommonMixChart';
import CommonSankey from '../../containers/CommonSankey';
// import SankeyChart from '../../component/Sankey';
// import SankeyTest from '../../containers/SankeyTest/index';

export default {
  title: '合参页面详情',
  components: [
    {
      component: CommonBreadCrumb,
      props: {
        breadCrumbSecName: 'key',
      },
    },
    {
      component: CommonTab,
      isCard: true,
      props: {
        tab: [
          {
            name: '合参页面详情',
            key: '合参页面详情',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,
                      type: 'pie',
                      addFilterFromUrl: true,
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: false,
                      noLegendSelect: true,
                      orderColumn: 'pv',
                      needOther: true,
                      showAll: true,
                      dimsFromUrl: true,
                      filterDims: true,
                    },
                    {
                      col: 18,

                      lineType: 'area',
                      dims: 'date',
                      fields: 'pv',
                      addFilterFromUrl: true,
                      pageCount: 10,
                      currentPage: 1,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                      dimsFromUrl: true,
                      filterDims: true,
                    },
                  ],
                },
              },
              {
                key: 'CommonTable',
                component: PagesetDetailTable,
                col: 24,
                props: {
                  dimsFromUrl: true,
                  filterDims: true,
                  addFilterFromUrl: true,
                  currentPage: 1,
                  orderDesc: true,
                  pageCount: 10,
                  orderColumn: 'pv',
                },
              },
            ],
          },
          {
            name: '历史趋势',
            key: '历史趋势',
            children: [
              {
                key: 'PageDetail',
                component: PageDetail,
                col: 24,
                props: {
                  children: [
                    {
                      key: 'CommonSummary',
                      component: CommonSummary,
                      col: 24,
                      props: {
                        comparision: 'chain',
                        addFilterFromUrl: true,
                      },
                    },
                    {
                      key: 'CommonChart',
                      component: CommonChart,
                      col: 24,
                      props: {
                        dims: 'date',
                        showAll: true,
                        addFilterFromUrl: true,
                      },
                    },
                    {
                      key: 'CommonTable',
                      component: CommonTable,
                      col: 24,
                      props: {
                        addFilterFromUrl: true,
                        currentPage: 1,
                        dims: 'date',
                        orderDesc: true,
                        pageCount: 10,
                      },
                    },
                  ],
                },
              },
            ],
          },
          {
            name: '页面参数',
            key: '页面参数',
            children: [
              {
                key: 'ParamTable',
                component: ParamTable,
                col: 24,
              },
            ],
          },

          {
            name: '页面上下游',
            isCard: true,

            children: [
              {
                component: CommonSankey,
                props: {
                  dims: 'path_pageset_sanKey',
                  fields: 'id,pagePathPv,pagePathUv,pagePathUvRatio,pagePathPvRatio,pagePathBounces,pagePathExits',
                  pageCount: 4,
                  currentPage: 1,
                  orderColumn: 'pagePathPv',
                  customSetFilter: {
                    dims: 'path_channel',
                    value: false,
                  },
                  nodeFields: ['pagePathUv', 'pagePathUvRatio', 'pagePathPv', 'pagePathUvRatio'],
                },
                col: 24,
              },
              {
                component: CommonTab,
                col: 24,
                props: {
                  mode: 'left',
                  tab: [
                    {
                      name: '流入渠道',
                      key: '流入渠道',
                      children: [
                        {
                          component: PagePathTable,
                          col: 24,
                          props: {
                            dims: 'path_channel',
                          },
                        },
                      ],
                    },
                    {
                      name: '流入页面',
                      key: '流入页面',
                      children: [
                        {
                          component: PagePathTable,
                          col: 24,
                          props: {
                            dims: 'path_page_before',
                            tableHeadKeyForKey: true,
                          },
                        },
                      ],
                    },
                    {
                      name: '退出页面',
                      key: '退出页面',
                      children: [
                        {
                          component: PagePathTable,
                          col: 24,
                          props: {
                            dims: 'path_page_after',
                            tableHeadKeyForKey: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
};
