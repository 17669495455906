import React, { Component } from 'react';
import { message } from 'antd';
import SplitPane from 'react-split-pane';
import BIStore from '../BIChart/store';
import { getInitialItemInfo } from '../BIChart/util';
import CombineChart from './Chart';
import Loading from '../../component/Loading/Loading';
import WidgetOperate from './component/WidgetOperate';
import CombineSetting from './component/CombineSetting';
import './index.sass';
import util from '../../util';
import api from '../../api';
import { defaultSplitSize, maxSplitSize } from '../../config';
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import AnalyseLayout from '../AnalyseLayout/index';

const workbenchSplitSize = localStorage.getItem('workbenchSplitSize');
const splitSize = workbenchSplitSize >= defaultSplitSize ? workbenchSplitSize : defaultSplitSize;

class AnalyseCombination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragItem: null,
      selectStyle: 1,
      loading: true,
      combineConfig: [[], []],
      cardName: '',
      style: { combineStyle: { cardStyles: [[], []] } },
      // 临时测试
      // combineConfig: testData,
    };
    this.id = util.getQueryString('id');
  }

  // 版式切换
  onStyleChange = (value) => {
    this.setState({
      selectStyle: value,
    });
  };

  // 开始拖拽
  dragStart = (dragItem) => {
    dragItem = {
      ...dragItem,
      style: {
        showTitle: true,
        lockProportion: false,
        width: 100,
        height: 100,
      },
    };
    if (dragItem.config) {
      this.setState({
        dragItem,
      });
    } else {
      util.get(`${api.BI.widgets}/${dragItem.id}`, {}, (res) => {
        dragItem.config = res.config;
        this.setState({
          dragItem,
        });
      });
    }
  };

  // 拖拽结束
  dragEnd = () => {
    this.setState({
      dragItem: null,
    });
  };

  // 拖拽到目标区域松开后
  drop = (index, prepareConfig) => {
    this.setLoading(true);
    const combineConfigCopy = this.state.combineConfig;
    combineConfigCopy[index] = prepareConfig;
    this.setState(
      {
        combineConfig: combineConfigCopy,
      },
      () => this.initWidgets(combineConfigCopy)
    );
  };

  setCombineConfig = (combineConfig) => {
    this.setState({
      combineConfig,
    });
  };

  // 初始化卡片配置
  initWidgets = (combineConfig) => {
    combineConfig.forEach((item) => {
      item.forEach((widgetInfo) => {
        if (widgetInfo.type === 10) {
          widgetInfo = {
            ...widgetInfo,
            config: typeof widgetInfo.config === 'string' ? JSON.parse(widgetInfo.config) : widgetInfo.config,
          };
          BIStore.setItemsInfo(getInitialItemInfo(widgetInfo, BIStore.formedViews), widgetInfo.id);
        }
      });
    });
    this.setLoading(false);
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  // 保存设置
  saveOrUpdate = (info, callback) => {
    const { combineConfig, selectStyle, style } = this.state;
    const ruleContent = combineConfig.map((item, index) => {
      return item.map((card, i) => {
        // return { widgetId: card.id };
        style.combineStyle.cardStyles[index][i] = card.combineStyle;
        return card.type === 10 || card.widgetId ? { widgetId: card.id } : { cardId: card.id };
      });
    });
    const param = {
      idsite: PageHeaderStore.parameter.idsite,
      ruleContent: JSON.stringify(ruleContent),
      style: JSON.stringify(style),
      chartType: selectStyle,
      type: 14,
      ...info,
    };
    this.id && (param.id = this.id);
    util.post(api.analysis.saveOrUpdate, param, (res) => {
      callback(res);
    });
  };

  // 校验可否保存
  rulesForSave = () => {
    const { combineConfig, selectStyle } = this.state;
    let compelete = true; //  是否两个选区都有配置
    combineConfig.forEach((item) => {
      item.length === 0 && (compelete = false);
    });
    if (compelete) {
      // 验证通过
      return true;
    } else {
      message.warning('请选择必选项');
      return false;
    }
  };

  // 获取卡片配置
  getCardInfo = (id, name) => {
    if (id !== 'default') {
      // 初始化
      util.get(
        api.analysis.info,
        {
          ids: id,
        },
        async (res) => {
          if (res) {
            let style = res[0].style ? JSON.parse(res[0].style) : {};
            const combineConfig = JSON.parse(res[0].ruleContent);
            const { combineStyle } = style;
            if (combineStyle) {
              combineStyle.cardStyles.forEach((item, index) => {
                item.forEach((e, i) => {
                  combineConfig[index][i] && (combineConfig[index][i].combineStyle = e);
                });
              });
            } else {
              style = { ...style, combineStyle: { cardStyles: [[], []] } };
            }
            this.setState({
              combineConfig: combineConfig,
              selectStyle: res[0].chartType,
              loading: false,
              cardName: res[0].name,
              style,
            });
          }
        }
      );
      // 暂存数据以表示显示不
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  // 组合分析样式修改
  combineStyleChange = (key, value) => {
    const styleCopy = this.state.style;
    styleCopy.combineStyle[key] = value;
    this.setState({
      style: {
        ...styleCopy,
      },
    });
  };

  resizeChart = () => {
    //设置widgetProps，卡片尺寸变化
    this.setState(
      {
        loading: true,
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  saveSplitSize(newSize) {
    localStorage.setItem('workbenchSplitSize', newSize.toString());
  }
  render() {
    const { combineConfig, dragItem, selectStyle, loading, cardName, style } = this.state;
    const layoutContent = (
      <div className="combine-layout">
        <SplitPane
          split="vertical"
          defaultSize={`${splitSize}px`}
          minSize={defaultSplitSize}
          maxSize={maxSplitSize}
          onChange={this.saveSplitSize}
          onDragFinished={this.resizeChart}>
          <div className="operate-bar">
            {/* 卡片列表 */}
            <WidgetOperate onDragStart={this.dragStart} onDragEnd={this.dragEnd} />
            {/* 设置 */}
            <CombineSetting
              selectStyle={selectStyle}
              onStyleChange={this.onStyleChange}
              onDrop={this.drop}
              combineConfig={combineConfig}
              dragItem={dragItem}
              dragStart={this.dragStart}
              dragEnd={this.dragEnd}
              style={style}
              setCombineConfig={this.setCombineConfig}
              combineStyleChange={this.combineStyleChange}
              {...this.props}
            />
          </div>
          {/* 图表显示 */}
          <div className="chart-display">
            <div className="chart-display-content">
              <h5 className="card-name">
                {style.iconClass && (
                  <i
                    className={`iconfont ${style.iconClass}`}
                    style={{
                      color: `rgba(${style.iconColor.r}, ${style.iconColor.g}, ${style.iconColor.b}, ${style.iconColor.a})`,
                    }}
                  />
                )}
                {style.combineStyle && style.combineStyle.showTitle ? cardName || '新建卡片' : ' '}
              </h5>
              {!loading ? (
                <CombineChart
                  combineConfig={combineConfig}
                  selectStyle={selectStyle}
                  type="edit"
                  setCombineConfig={this.setCombineConfig}
                  style={style.combineStyle}
                />
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </SplitPane>
      </div>
    );
    return (
      <AnalyseLayout
        type={14}
        // 保存回调
        saveOrUpdate={this.saveOrUpdate}
        //   // 保存规则回调
        rulesForSave={this.rulesForSave}
        //   // 选择时回调函数
        getEventConfig={this.getCardInfo}
        chartConfig={{ name: cardName }}
        chartName={cardName}
        //   // 卡片id
        id={this.id}
        layoutContent={layoutContent}
        eventName={'组合分析'}
        layoutTitle="组合分析"
        {...this.props}></AnalyseLayout>
    );
  }
}
export default AnalyseCombination;
