import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import BreadCrumb from '../../component/Breadcrumb/index';
import util from '../../util';
@observer
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.parameter = {
      //   actionUrlId: this.props.match.params.actionUrlId ? this.props.match.params.actionUrlId : '',
      pageType: util.getQueryString('pageType'),
      url: util.getQueryString('page_url'),
      // urlIds: util.getQueryString('urlIds'),
      keyDim: util.getQueryString('keyDim'),
    };
  }
  // 该计算只针对页面模块
  @computed get pageTypeName() {
    let name = '';
    if (this.parameter.keyDim) {
      switch (this.parameter.keyDim) {
        case 'page_group':
          name = '页面组';
          break;
        case 'page_entry':
          name = '入口页面';
          break;
        case 'pageset_entry':
          name = '入口页面';
          break;
        case 'page_visited':
          name = '受访页面';
          break;
        case 'pageset_visited':
          name = '受访页面';
          break;
        case 'terminal_browser':
          name = '浏览器';
          break;
        case 'terminal_os':
          name = '操作系统';
          break;
        case 'channel_link':
          name = '外部链接';
          break;
        default:
          break;
      }
    }
    return name;
  }
  @computed get breadCrumbName() {
    // 页面维度特殊处理
    if (util.getQueryString('keyDim') && util.getQueryString('keyDim').includes('page')) {
      if (util.getQueryString('name') === util.getQueryString('key')) {
        return util.getQueryString('key');
      } else if (util.getQueryString('keyDim').includes('page_group')) {
        return `${util.getQueryString('name')}`;
      } else {
        return `${util.getQueryString('name')},${util.getQueryString('key')}`;
      }
    }
    // 其余返回传过来的breadCrumbSecName
    else {
      return util.getQueryString(this.props.breadCrumbSecName);
    }
  }
  render() {
    // 配置文件中可传值到该组件中breadCrumbName为面包屑的返回一级路由的标题
    // 配置文件中可传值到该组件中breadCrumbUrl为面包屑的返回一级路由的链接
    // 配置文件中可传值到该组件中breadCrumbSecName为面包屑进入二级路由的标题
    this.data = [
      { name: this.props.breadCrumbName ? this.props.breadCrumbName : this.pageTypeName },
      {
        name: this.breadCrumbName,
      },
    ];
    return <BreadCrumb className={this.props.className} data={this.data} {...this.props} />;
  }
}
export default Main;
