import BasicSurveyTable from '../../containers/BasicSurvey/Table';

export default {
  title: '调研列表',
  components: [
    {
      title: '调研列表',
      isCard: true,
      className: 'single-card',
      children: [{ component: BasicSurveyTable }],
    },
  ],
};
