import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import './index.sass';
import intl from 'react-intl-universal';
const InputSearch = Input.Search;
@observer
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    return (
      <div className={`uxs-search ${this.props.className}`}>
        <InputSearch
          placeholder={this.props.placeholder || intl.get('Pleaseenterkeywords')}
          allowClear
          onChange={(e) => {
            if (e.target.value.length === 0) {
              this.props.onClick(e.target.value);
            }
            this.setState({
              value: e.target.value,
            });
          }}
          onSearch={(value) => this.props.onClick(value)}
          value={this.state.value}
        />
      </div>
    );
  }
}
export default Search;
