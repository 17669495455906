import React, { Component } from 'react';
import { Card } from '../../component/Card/Card';
import util from '../../util';
import './index.sass';
import { action, observable, computed } from 'mobx';
import SplitPane from 'react-split-pane';
import { observer } from 'mobx-react';
import { Modal, Input, Switch, message, Button } from 'antd';
import api from '../../api';
import { defaultSplitSize, maxSplitSize } from '../../config';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import CustomBoardSelect from '../../component/CustomBoardSelect';
import AuthorityStore from '../../AuthorityStore/store';
import UXSNewSelect from '../../component/UXSNewSelect/index';
import AnalyseLayoutConfig from './AnalyseLayoutConfig/index';
import AnalyseBoardUtil from '../AnalyseBoardTable/util';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

const workbenchSplitSize = localStorage.getItem('workbenchSplitSize');
const splitSize = workbenchSplitSize >= defaultSplitSize ? workbenchSplitSize : defaultSplitSize;

// 弹窗状态管理
class ModalStore {
  @observable modalVisible = false;
  @observable inputValue = '';
  @observable remarks = '';
  @observable modalChecked = false;
  @observable isSaveAs = false;
  @observable targetList = null;
  // 更改弹窗显示隐藏
  @action.bound changeModalVisible(saveAs) {
    this.modalVisible = !this.modalVisible;
    this.isSaveAs = saveAs ? saveAs : false;
    this.modalChecked = false;
    this.changeListTarget(null);
  }
  //设置弹窗的值
  @action.bound setModalValue(value) {
    this.inputValue = value;
  }
  @action.bound setModalRemarks(value) {
    this.remarks = value;
  }
  @action.bound setModalChecked(checked) {
    this.modalChecked = checked;
    if (checked === 'fasle') {
      this.changeListTarget(null);
    }
  }
  @action.bound changeListTarget(value) {
    this.targetList = value;
  }
  @action.bound addChartToListTarget(data, callback) {
    let { type, id, chartType, name } = data;
    util.post(
      api.board.updateDashboard,
      {
        remark: 'test',
        actionType: 'addCard',
        layout: JSON.stringify([
          {
            cardId: id,
          },
        ]),
        idsite: pageHeaderStore.parameter.idsite,
        id: this.targetList['key'],
      },
      callback
    );
  }
  // 弹窗更改分析报告的名字
}
@observer
class AnalyseLayout extends Component {
  constructor(props) {
    super(props);
    this.modalStore = new ModalStore();
  }

  @observable eventOption = [];
  @observable selectOptionValue;
  // @observable analyseOptionValue;
  // @observable isShowTipsModal = false;
  // 挂载前全屏设置
  componentWillMount() {
    document.getElementsByTagName('body')[0].classList.add('analyse-fullScreen');
  }
  // 挂载时
  componentDidMount() {
    // 设置分析下拉框的初始值
    // this.setAnalyseOptionValue();
    // 设置下拉框的初始值
    this.setSelectOptionValue();
    // 设置名称
    this.setEventOptionValue(this.props.chartName || '');
    // 获取下拉列表
    this.props.type !== 14 &&
      this.getOption(this.props.type, (res) => {
        this.setEventOption(res.data);
      });
    // 获取事件配置
    this.props.getEventConfig(
      util.getQueryString('id') ? util.getQueryString('id') : 'default',
      util.getQueryString('eventName') ? util.getQueryString('eventName') : null
    );
    // 设置弹窗中的初始值
  }
  // 卸载时取消全屏
  componentWillUnmount() {
    document.getElementsByTagName('body')[0].classList.remove('analyse-fullScreen');
    document.getElementsByTagName('body')[0].classList.add('analyse-fullScreenCancel');
    setTimeout(() => {
      document.getElementsByTagName('body')[0].classList.remove('analyse-fullScreenCancel');
    }, 2000);
  }

  componentDidUpdate(preProps) {
    if (this.props.chartName !== preProps.chartName)
      // 设置名称
      this.setEventOptionValue(this.props.chartName || '');
  }

  // 判断是否可以另存为
  @computed get isCanSaveAs() {
    return this.props.id || util.getQueryString('id') ? true : false;
  }

  // 设置弹窗中的初始值
  @action.bound setEventOptionValue(value) {
    console.log(value);
    this.modalStore.inputValue = value === '新建分析' ? '' : value;
  }
  // @action.bound tipsModalToggle(bool) {
  //   this.isShowTipsModal = bool;
  // }
  // 获取下拉框数据
  getOption(type, callback) {
    util.get(
      api.analysis.list,
      {
        idsite: pageHeaderStore.parameter.idsite,

        type: type,
      },
      callback,
      null,
      false
    );
  }
  // 设置下拉框的初始值
  @action.bound setSelectOptionValue(
    value = util.getQueryString('id')
      ? { key: util.getQueryString('id'), label: util.getQueryString('eventName') }
      : { key: 'default', label: `${intl.get('Create')}${intl.get(this.props.eventName) || this.props.eventName}` }
  ) {
    this.selectOptionValue = value;
  }
  // 设置当前分析类型下拉框的值
  // @action.bound setAnalyseOptionValue() {
  //   this.analyseOptionValue = { key: this.props.type, label: this.props.eventName };
  // }
  // 设置分析列表管理的下拉列表
  @action.bound setEventOption(res) {
    this.eventOption = res.map((item) => {
      return { value: item.id, label: item.name };
    });
  }
  // 保存或者更新的接口
  @action.bound saveOrUpdate() {
    if (this.modalStore.inputValue.length > 0) {
      // 如果另存为
      if (this.modalStore.modalChecked) {
        // 回调父组件的保存函数
        this.modalStore.targetList
          ? this.props.saveOrUpdate(
              { name: this.modalStore.inputValue, remarks: this.modalStore.remarks },
              (res) => {
                res.code === 200
                  ? this.getOption(this.props.type, (result) => {
                      this.setEventOption(result.data);
                      this.modalStore.addChartToListTarget(
                        result.data.filter((item) => {
                          return item.id === res.data;
                        })[0],
                        () => {
                          message.success(intl.get('Thechartwassavedsuccessfullyandcanbeviewedintheanalysislist'));
                          message.success(intl.get('Addcharttodashboardsuccessfullyviewdashboardininsight'));
                          this.modalStore.changeModalVisible();
                        }
                      );
                    })
                  : message.warning(res.msg || intl.get('Thistypealreadyhasthesamename,pleaseresetthename!'));
              },
              this.modalStore.isSaveAs
            )
          : message.warning(intl.get('Selectthedashboardyouwanttosave'));
      }
      // 不另存为
      else {
        // 回调父组件的保存函数
        this.props.saveOrUpdate(
          { name: this.modalStore.inputValue, remarks: this.modalStore.remarks },
          (res) => {
            res.code === 200
              ? this.getOption(this.props.type, (newRes) => {
                  this.setEventOption(newRes.data);
                  this.modalStore.changeModalVisible();
                  message.success(intl.get('Thechartwassavedsuccessfullyandcanbeviewedintheanalysislist'));
                })
              : message.warning(res.msg || intl.get('Thistypealreadyhasthesamename,pleaseresetthename!'));
          },
          this.modalStore.isSaveAs
        );
      }
    } else {
      message.warning(intl.get('Pleaseenterthenameofthechart'));
    }
  }

  // 提交表单时调用判断表格内容是否符合规范
  handleSubmit = (e, saveAs) => {
    // 回调父组件的保存规则
    if (this.props.rulesForSave ? this.props.rulesForSave() : true) {
      // this.setEventOptionValue(this.props.chartConfig.name ? this.props.chartConfig.name : '');
      this.modalStore.setModalRemarks(this.props.chartConfig.remarks ? this.props.chartConfig.remarks : '');
      this.modalStore.changeModalVisible(saveAs);
    }
  };
  // 导出数据的函数
  exportData = () => {
    this.props.exportData();
  };
  saveSplitSize(newSize) {
    localStorage.setItem('workbenchSplitSize', newSize.toString());
  }
  linkBack = () => {
    const lastLink = util.getQueryString('lastLink');
    if (lastLink) {
      this.props.history.push(decodeURIComponent(lastLink));
    } else {
      window.history.go(-1);
    }
  };
  render() {
    const { getEventConfig, eventName, getChartAndTable, layoutContent } = this.props;
    return (
      <React.Fragment>
        {this.modalStore.modalVisible && (
          <Modal
            title={intl.get('Addthecharttothechartlibrary')}
            visible={this.modalStore.modalVisible}
            onOk={this.saveOrUpdate}
            onCancel={this.modalStore.changeModalVisible}
            okText={intl.get('Confirm')}
            cancelText={intl.get('Cancel')}>
            <div className="analyseLayoutModal">
              <div className="analyseLayoutModal-item">
                <p>{`${intl.get('Chart')}${intl.get('Name')}`}</p>
                <Input
                  placeholder={intl.get('Pleaseenterthenameofthechart')}
                  value={this.modalStore.inputValue}
                  onChange={(e) => this.modalStore.setModalValue(e.target.value)}
                />
              </div>
              <div className="analyseLayoutModal-item">
                <p>{intl.get('Remarks')}</p>
                <Input.TextArea
                  placeholder={intl.get('Pleaseentercardremarks')}
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  value={this.modalStore.remarks}
                  onChange={(e) => this.modalStore.setModalRemarks(e.target.value)}
                />
              </div>
              <div className="analyseLayoutModal-item">
                <p>{intl.get('Addtodashboard')}</p>

                <Switch size="small" checked={this.modalStore.modalChecked} onChange={this.modalStore.setModalChecked} />
              </div>
              {this.modalStore.modalChecked ? (
                <div className="analyseLayoutModal-item">
                  <p></p>

                  <CustomBoardSelect onChange={this.modalStore.changeListTarget}></CustomBoardSelect>
                </div>
              ) : null}
            </div>
          </Modal>
        )}
        {/* 整个大的layout */}
        <div className="analyseLayout">
          {/* layout最顶头的导航栏 */}
          <div className="analyseLayout_title">
            {/* layout的配置项下拉框 */}
            {/* TODO:此地方为了满足视觉以及交互摆放 ，用了绝对定位，可参考css */}
            <div
              className="analyseLayout_title-select"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              {!layoutContent && (
                <UXSNewSelect
                  size={'small'}
                  value={this.selectOptionValue}
                  isLoading={this.isLoading}
                  extraOption={util.getQueryString('id') ? null : [{ label: `${intl.get('Create')}${intl.get(eventName) || eventName}`, value: 'default' }]}
                  selectOption={this.eventOption && this.eventOption}
                  onSelect={(item) => {
                    // 修改弹窗名字
                    this.setSelectOptionValue(item);
                    this.modalStore.setModalValue(item.label === `${intl.get('Create')}${intl.get(eventName) || eventName}` ? '' : item.label);
                    util.blockFetch();
                    getEventConfig(item.key, item.label);
                  }}
                  style={{ width: 120, marginTop: 16 }}></UXSNewSelect>
              )}
            </div>
            {/* 返回按钮 */}
            {util.getQueryString('isCanback') ? (
              <span
                className="analyseLayout_title-back"
                onClick={() => {
                  this.linkBack();
                }}>
                <i className="iconfont icon-fenye-fanye"></i>
              </span>
            ) : (
              <span className="analyseLayout_title-back">
                <Link to={`/analytics/${AnalyseBoardUtil.typeToHref(this.props.type)}`}>
                  <i className="iconfont icon-fenye-fanye"></i>
                </Link>
              </span>
            )}
            {/* 名称输入框 */}
            <div className="name-content">
              <Input
                className="name-input"
                value={this.modalStore.inputValue}
                onChange={(e) => this.setEventOptionValue(e.target.value)}
                placeholder="请输入名称"
              />
              <div className="name-text">{this.modalStore.inputValue || '请输入名称'}</div>
            </div>
            {/* layout最顶头的一栏*/}
            <div
              className="analyseLayout_title-btnGroup"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              {getChartAndTable ? (
                <Button
                  type="primary"
                  className="analyseLayout_title-btnGroup-btn"
                  onClick={
                    // e.stopPropagation();
                    this.props.getChartAndTable
                  }>
                  {/* <i className="iconfont icon-shengchengtubiao"></i> */}
                  {intl.get('Generatethechart')}
                </Button>
              ) : null}

              {this.isCanSaveAs ? (
                <React.Fragment>
                  <Button type="primary" className="analyseLayout_title-btnGroup-btn operateDom-update" onClick={(e) => this.handleSubmit(e, false)}>
                    {/* <i className="iconfont icon-lingcunwei"></i> */}
                    {intl.get('Save')}
                  </Button>
                  {AuthorityStore.checkAuthorityExist('insight', 'dashBoard', 'add') && AuthorityStore.checkAuthorityExist('insight', 'dashBoard', 'update') && (
                    <Button type="primary" className="analyseLayout_title-btnGroup-btn operateDom-update" onClick={(e) => this.handleSubmit(e, true)}>
                      {/* <i className="iconfont icon-baocun"></i> */}
                      {intl.get('Saveas')}
                    </Button>
                  )}
                  {this.props.exportData ? (
                    <Button type="primary" className="analyseLayout_title-btnGroup-btn operateDom-export" onClick={() => this.exportData()}>
                      {/* <i className="iconfont icon-baocun"></i> */}
                      {intl.get('Export')}
                    </Button>
                  ) : null}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button type="primary" className="analyseLayout_title-btnGroup-btn operateDom-add" onClick={(e) => this.handleSubmit(e, false)}>
                    {/* <i className="iconfont icon-lingcunwei"></i> */}
                    {intl.get('Save')}
                  </Button>
                  {this.props.exportData ? (
                    <Button type="primary" className="analyseLayout_title-btnGroup-btn operateDom-export" onClick={() => this.exportData()}>
                      {/* <i className="iconfont icon-baocun"></i> */}
                      {intl.get('Export')}
                    </Button>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </div>
          {/* layout内容区左中右布局 */}
          {!layoutContent ? (
            <div className="analyseLayout_content">
              <SplitPane split="vertical" defaultSize={`${splitSize}px`} minSize={defaultSplitSize} maxSize={maxSplitSize} onChange={this.saveSplitSize}>
                <div className="operate-bar">
                  {/* layout表单 */}
                  <div className="analyseLayout_content-from">{this.props.analyseSelectCard}</div>
                  {/* layout图形配置区 */}
                  <div className="analyseLayout_content-config">
                    <AnalyseLayoutConfig config={this.props.analyseChartConfig}></AnalyseLayoutConfig>
                  </div>
                </div>
                {/* layout图形展示区 */}
                <div className="analyseLayout_content-chart">
                  <div className="analyseLayout_content-chart-toolbar"></div>

                  <Card
                    card={{
                      content: (
                        <div className="analyseLayout_content-chartGroup">
                          <div className="analyseLayout_content-chartGroup-title">
                            <div>{this.props.chartName ? this.props.chartName : intl.get('Unnamedchart')}</div>
                          </div>
                          {this.props.analyseChartContent}
                        </div>
                      ),
                    }}></Card>
                </div>
              </SplitPane>
            </div>
          ) : (
            layoutContent
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default AnalyseLayout;
