import React from 'react';
import DataDrill from './DataDrill';
import { DrillableChart, DrillableChartNeedNotFilter } from '../../config/chart/DrillableChart';
import './index.sass';

class EnhancerPanel extends React.Component {
  isDrillableChart() {
    const { chartStyle } = this.props;
    return DrillableChart.some((drillable) => drillable === chartStyle);
  }
  isDrillableChartNeedNotFilter() {
    const { chartStyle } = this.props;
    return DrillableChartNeedNotFilter.some((drillable) => drillable === chartStyle);
  }

  // 是否显示菜单
  hide = () => {
    let isHide = true;
    const { isSelectedGroup, isSelectedfilter } = this.props;
    const isDrillableChart = this.isDrillableChart();
    const isDrillableChartNeedNotFilter = this.isDrillableChartNeedNotFilter();
    if (!isDrillableChart) {
      isHide = true;
      return isHide;
    }

    if (!(isSelectedfilter && isSelectedfilter.length === 0)) {
      isHide = false;
      return isHide;
    }

    if (!(isSelectedGroup && isSelectedGroup.length === 0)) {
      isHide = false;
      return isHide;
    }

    if (isDrillableChartNeedNotFilter) {
      isHide = false;
      return isHide;
    }

    return isHide;
  };
  render() {
    const isHide = this.hide();
    return (
      <div className={`dataDrillPanel ${isHide ? 'hide' : ''}`}>
        <DataDrill {...this.props} />
      </div>
    );
  }
}

export default EnhancerPanel;
