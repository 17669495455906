import React, { Component } from 'react';
import { observable, toJS, autorun } from 'mobx';
import { observer } from 'mobx-react';
import { Radio } from 'antd';
import customBoardUtil from '../../utils/customBoardUtil';
import { surveyTools } from '../../utils/analyseUtil';
import chartUtil from '../../utils/chartUtil';
import './index.sass';
import intl from 'react-intl-universal';
/*


TODO:
@param {参数类型} @参数名 参数说明
1、{function} @changeChartType 
changeChartType(e.target.value)
该参数为修改父组件的chartType值
2、{string} @chartType
组件类型对应的编号


*/
@observer
class AnalyseChartSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectName: this.props.chartType === 0 ? null : customBoardUtil.changeNumberToType(this.props.chartType),
    };
  }
  @observable chartSelect = {};

  componentDidMount() {
    if (this.props.selectType === 'analyseSurvey') {
      this.disposer = autorun(() => {
        let { eventAndTargetGroup, subDividedDimGroup } = this.props;
        this.chartSelect = toJS(eventAndTargetGroup) ? this.changeChartSelect(toJS(eventAndTargetGroup), toJS(subDividedDimGroup)) : {};
      });
    } else {
      this.disposer = autorun(() => {
        let selectType = this.props.selectType;
        if (this.props.selectType === 'analyseRemain') {
          this.chartSelect = {
            CommonChart_line: { icon: 'icon-qushitu', title: 'LineChart', disabeld: false },
            CommonTable: {
              icon: 'icon-biaoge',
              title: 'Table',
              // '表格',
              disabeld: false,
            },
          };
        } else if (this.props.selectType === 'analysePath') {
          this.chartSelect = {
            CommonSankey: { icon: 'icon-tiaoguo', title: 'Path', disabeld: false }, //'路径',
            CommonFunnel: {
              icon: 'icon-shaixuan',
              title: 'Funnel',
              // '漏斗',
              disabeld: false,
            },
            CommonChart_line: {
              icon: 'icon-qushitu',
              title: 'LineChart',
              // '趋势图',
              disabeld: false,
            },
            CommonTable: {
              icon: 'icon-biaoge',
              title: 'Table',
              // '表格',
              disabeld: false,
            },
          };
        }
        // 分布分析按指标分布
        else if (this.props.selectType === 'analyseDistribute_target') {
          let chartSelect = {
            CommonChart_bar: { icon: 'icon-zhuzhuangtu', title: 'Histogram', disabeld: false },
            CommonTable: {
              icon: 'icon-biaoge',
              title: 'Table',
              // '表格',
              disabeld: false,
            },
          };
          if (this.props.dims[0] !== '') {
            chartSelect['CommonChart_bar']['disabled'] = true;
          }
          if (chartSelect[`${customBoardUtil.changeNumberToType(this.props.chartType)}`].disabled === true) {
            this.props.changeChartType('CommonTable');
            this.setState({ selectName: customBoardUtil.changeNumberToType(this.props.chartType) });
          }

          this.chartSelect = chartSelect;
        }
        // 分布分析按时间分布
        else if (this.props.selectType === 'analyseDistribute_date') {
          this.chartSelect = {
            CommonChart_heatmap: { icon: 'icon-zhuzhuangtu', title: 'TimeHeatMap', disabeld: false }, //'时间热图',
            CommonTable: {
              icon: 'icon-biaoge',
              title: 'Table',
              // '表格',
              disabeld: false,
            },
          };
        }
      });
    }
  }
  changeChartSelect = (eventAndTargetGroup, subDividedDimGroup) => {
    let chartSelect = {
      CommonChart_line: {
        icon: 'icon-qushitu',
        title: 'LineChart',
        // '趋势图',
        disabled: false,
      },
      CommonChart_bar: {
        icon: 'icon-zhuzhuangtu',
        title: 'Histogram',
        // '柱状图',
        disabled: false,
      },
      CommonChart_bar_stack: {
        icon: 'icon-zhuzhuangduidie',
        title: 'StackedHistogram',
        // '堆叠柱状图',
        disabled: false,
      },
      CommonSummary: {
        icon: 'icon-dashuzi',
        title: 'BigDataIndicators',
        // '大数据指标',
        disabled: false,
      },
      CommonChart_line_area: {
        icon: 'icon-duidietu',
        title: 'StackedChart',
        // '堆叠图',
        disabled: false,
      },
      CommonChart_pie: {
        icon: 'icon-bingtu',
        title: 'PieChart',
        // '饼图',
        disabled: true,
      },
      CommonChart_bar_horizontal: {
        icon: 'icon-hengxiangzhuzhuangtu',
        title: 'BarChart',
        // '条形图',
        disabled: false,
      },
      CommonChart_bar_horizontalRankStack: {
        icon: 'icon-tiaoxingduidie',
        title: 'StackedBarChart',
        // '堆叠条形图',
        disabled: false,
      },
      CommonChart_gauge: {
        icon: 'icon-jiliangtu',
        title: 'MeasurementChart',
        // '计量图',
        disabled: false,
      },
      CommonTable: {
        icon: 'icon-biaoge',
        title: 'Table',
        // '表格',
        disabled: false,
      },
      TextTable: {
        icon: 'icon-qingganfenxi',
        title: 'OpenQuestionTable',
        // '开放题表格',
        disabled: true,
      },
      // CommonChart_map: {
      //   icon: 'icon-ditu',
      //   title: 'Map',
      //   // '地图',
      //   disabled: true,
      // },
      CommonChart_scatter: {
        icon: 'icon-sandiantu',
        title: 'ScatterPlot',
        // '散点图',
        disabled: true,
      },
      CommonChart_scatter_quadrant: {
        icon: 'icon-sixiangxian',
        title: 'QuadrantScatterPlot',
        // '四象限散点图',
        disabled: true,
      },
      CommonChart_scatter_custom: {
        icon: 'icon-sandiantu1',
        title: 'QuadrantCustomPlot',
        // '单指标散点图',
        disabled: true,
      },
      CommonChart_treemap: {
        icon: 'icon-juxingshutu',
        title: 'RectangularTree',
        // '矩形树图',
        disabled: true,
      },
      CommonChart_wordCloud: {
        icon: 'icon-ciyun',
        title: 'WordCloud',
        // '词云图',
        disabled: false,
      },
    };

    if (
      !(
        eventAndTargetGroup[0].dimValue === '' ||
        eventAndTargetGroup[0].dimValueName === '' ||
        eventAndTargetGroup[0].target === '' ||
        eventAndTargetGroup[0].targetName === ''
      )
    ) {
      let topicTypeArr = toJS(surveyTools.changeTargetNameToTopicType(eventAndTargetGroup[0].targetName)); //获取题型中文名
      chartSelect = this.changeChartTypeDisabled(eventAndTargetGroup, subDividedDimGroup, topicTypeArr, chartSelect);
      if (chartSelect[`${customBoardUtil.changeNumberToType(this.props.chartType)}`].disabled === true) {
        if (subDividedDimGroup && subDividedDimGroup[0] === '') {
          this.props.changeChartType('CommonSummary');
          this.setState({ selectName: 'CommonSummary' });
        } else if (topicTypeArr.length === 1 && subDividedDimGroup[0] === '' && (topicTypeArr.includes('量表题') || topicTypeArr.includes('NPS题'))) {
          this.props.changeChartType('CommonSummary');
          this.setState({ selectName: 'CommonSummary' });
        } else if (!topicTypeArr.includes('单行文本题') && !topicTypeArr.includes('多行文本题')) {
          this.props.changeChartType('CommonTable');
          this.setState({ selectName: 'CommonTable' });
        }
      }
    }

    return chartSelect;
  };
  changeChartTypeDisabled = (eventAndTargetGroup, subDividedDimGroup, topicTypeArr, select) => {
    //根据题型控制disabled
    let chartSelect = select;
    if (topicTypeArr.length === 1 && subDividedDimGroup[0] === '') {
      chartSelect['CommonChart_line']['disabled'] = true;
      chartSelect['CommonChart_bar']['disabled'] = true;
      chartSelect['CommonChart_bar_stack']['disabled'] = true;
      chartSelect['CommonChart_line_area']['disabled'] = true;
      chartSelect['CommonChart_bar_horizontal']['disabled'] = true;
      chartSelect['CommonChart_bar_horizontalRankStack']['disabled'] = true;
    } else if (subDividedDimGroup.length === 1) {
      if (subDividedDimGroup[0].includes('district_')) {
        chartSelect['CommonChart_map']['disabled'] = false;
      }
      if (chartUtil.getTargetNum(this.props.config) === 1) {
        chartSelect['CommonChart_treemap']['disabled'] = false;
      }
      if (chartUtil.getTargetNum(this.props.config) === 2 && subDividedDimGroup.indexOf('answer_topic_option') > -1) {
        chartSelect['CommonChart_scatter']['disabled'] = false;
      }
    }
    if (chartUtil.getTargetNum(this.props.config) === 1) {
      chartSelect['CommonChart_pie']['disabled'] = false;
      chartSelect['CommonChart_scatter_custom']['disabled'] = false;
    }
    if (
      this.props.eventAndTargetGroup &&
      JSON.stringify(this.props.eventAndTargetGroup).includes('experience') &&
      subDividedDimGroup.indexOf('answer_topic_option') > -1
    ) {
      chartSelect['CommonChart_scatter_quadrant']['disabled'] = false;
    }
    if (topicTypeArr.includes('单行文本题') || topicTypeArr.includes('多行文本题')) {
      // chartSelect['CommonTable']['disabled'] = false;
      chartSelect['TextTable']['disabled'] = false;
    }
    return chartSelect;
  };
  render() {
    return (
      <div id="analyseChartSelect">
        <Radio.Group
          value={this.props.chartType === 0 ? null : customBoardUtil.changeNumberToType(this.props.chartType)}
          onChange={(e) => {
            this.setState({ selectName: e.target.value });
            this.props.changeChartType(e.target.value);
          }}>
          {Object.keys(toJS(this.chartSelect)) &&
            Object.keys(toJS(this.chartSelect)).map((key, index) => {
              return (
                <Radio.Button key={index} value={key} disabled={toJS(this.chartSelect)[key]['disabled']}>
                  <i title={intl.get(toJS(this.chartSelect)[key]['title'])} className={`iconfont ${toJS(this.chartSelect)[key]['icon']}`}></i>
                </Radio.Button>
              );
            })}
        </Radio.Group>
      </div>
    );
  }
}
export default AnalyseChartSelect;
