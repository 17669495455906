/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React, { Fragment, useState } from 'react';
import { OperatorTypes, DefaultTableCellStyle, TableConditionStyleTypes } from '../constants';
import { textAlignAdapter, getFormattedValue, encodeUrlSearch, hasMatchedTheCondition, decodeMetricName } from '../util';
import NlpModal from './NlpModal';
import ClosedLoopStatusModal from './ClosedLoopStatusModal';
import { Tooltip } from 'antd';

function BodyCell(props) {
  const {
    format,
    config,
    cellVal,
    cellValRange,
    linkKey,
    linkAgg,
    record,
    isFirstColumn,
    cardId,
    cardRefreshWithoutConfig,
    metricsDisplay,
    childrenCell,
    isMerge,
    keysDisplay,
    dimensions,
    compareDataFormat,
    expression,
    isChild,
    currentCompareFilterConfig,
    isCompare,
    childrenIndex,
    isContent,
    firstContent,
    secondContent,
    ...rest
  } = props;
  const { onClick } = config || {};

  const { hasNewStyle, conditionStyle } = getNewConditionType((config && config.conditionStyles) || [], cellVal);

  const [modalInfo, setModalInfo] = useState({ visible: false });

  const cellCssStyle = getBodyCellStyle(config, cellVal, cellValRange, isFirstColumn, isChild);
  let formattedVal = format && getFormattedValue(cellVal, format);
  formattedVal = formattedVal || cellVal;

  function showModal(e) {
    e.stopPropagation();
    setModalInfo({
      visible: true,
      config,
      record,
    });
  }

  function getCurrentCompareKey() {
    const dimKeys = dimensions.map((item) => item.randomName || item.formula);
    let currentKey = '';
    dimKeys.forEach((key) => {
      currentKey += record[key];
    });
    return currentKey;
  }

  function renderNewConditionType() {
    const { type } = conditionStyle;
    switch (type) {
      case 'tag':
        const { background, border, black, radius, paddingTopAndBottom, paddingLeftAndRight } = conditionStyle.colors;
        return formattedVal !== '' ? (
          <div
            style={{
              background,
              border: `1px ${border} solid`,
              color: black,
              borderRadius: `${radius || 4}px`,
              padding: `${paddingTopAndBottom || 2}px ${paddingLeftAndRight || 8}px`,
              cursor: 'auto',
              width: 'fit-content',
            }}>
            {formattedVal}
          </div>
        ) : (
          formattedVal
        );
      case 'icon':
        const { icon, iconColor, size, position, way } = conditionStyle.colors;
        return formattedVal !== '' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '3px' }}>{position === 'right' && way === 'text' && formattedVal}</span>
            {way === 'text' ? (
              <i className={`iconfont ${icon}`} style={{ color: iconColor, fontSize: size, paddingTop: '1px' }}></i>
            ) : (
              <Tooltip title={formattedVal}>
                <i className={`iconfont ${icon}`} style={{ color: iconColor, fontSize: size, paddingTop: '1px' }}></i>
              </Tooltip>
            )}
            <span style={{ paddingLeft: '3px' }}>{position === 'left' && way === 'text' && formattedVal}</span>
          </div>
        ) : (
          formattedVal
        );
    }
  }

  function hideModal(e) {
    e && e.stopPropagation();
    setModalInfo({
      visible: false,
    });
  }

  if (onClick && onClick.eventType && onClick.eventType === 'nlpModify') {
    cellCssStyle.padding = 0;
  }
  const bodyContent = getBodyContent();

  return !bodyContent ? (
    <td style={cellCssStyle} {...rest} />
  ) : isMerge ? (
    <div className="mergeBodyCellItem" style={cellCssStyle} {...rest}>
      {bodyContent}
    </div>
  ) : isCompare ? (
    <div style={cellCssStyle} {...rest}>
      {bodyContent}
    </div>
  ) : (
    <td style={cellCssStyle} {...rest}>
      {bodyContent}
    </td>
  );

  function getBodyContent() {
    if (childrenCell) {
      // 合并表头时
      return (
        <div className="mergeBodyCell">
          {childrenCell.map((item, index) => {
            return <BodyCell key={index} {...item.onCell(record)} isChild={true} childrenIndex={index} isMerge />;
          })}
        </div>
      );
    } else if (currentCompareFilterConfig) {
      return (
        <Fragment>
          <BodyCell {...props} cellVal={record[expression] || '-'} currentCompareFilterConfig={null} isMerge={false} isCompare />
          <BodyCell {...props} cellVal={record[`compare_${expression}`] || '-'} currentCompareFilterConfig={null} isMerge={false} isCompare />
          {/* <div>{formattedVal}</div>
          <div>{compareDataFormat[currentKey] ? compareDataFormat[currentKey][expression] : ''}</div> */}
        </Fragment>
      );
    } else if (isContent) {
      // 内容指标
      return (
        <Fragment>
          {firstContent.map((item, index) => {
            return index === 0 ? (
              <p className="contentFieldsBlock">{record[item.formula]}</p>
            ) : (
              <span className="contentFieldsInline">
                {item.fieldDisplay || item.name}: {record[item.formula]}
              </span>
            );
          })}
          {secondContent && secondContent.length ? (
            <div className="secondFieldsContent">
              {secondContent.map((item, index) => {
                return index === 0 ? (
                  <p className="contentFieldsBlock">{record[item.formula]}</p>
                ) : (
                  <span className="contentFieldsInline">
                    {item.fieldDisplay || item.name}: {record[item.formula]}
                  </span>
                );
              })}
            </div>
          ) : null}
        </Fragment>
      );
    } else if (onClick && onClick.eventType) {
      const { eventType, judgeField } = onClick;
      switch (eventType) {
        case 'nlpModify':
          // 观点情感修改
          return (
            <Fragment>
              <a onClick={showModal}>
                {hasNewStyle ? renderNewConditionType() : formattedVal}
                {modalInfo.visible && <NlpModal visible={modalInfo.visible} modalInfo={modalInfo} onCancel={hideModal} {...props} />}
              </a>
            </Fragment>
          );
        case 'closedLoopStatus':
          // 闭环管理
          let disabled = false;
          if (judgeField) {
            const field = keysDisplay.find((item) => item.name === judgeField || item.randomName === judgeField);
            if (field) {
              const { randomName, agg, name } = field;
              const expression = randomName || `${agg}(${decodeMetricName(name)})`;
              disabled = record[expression] === 'true' ? true : false;
            }
          }
          return disabled ? (
            <div className="disabled-btn">{hasNewStyle ? renderNewConditionType() : formattedVal}</div>
          ) : (
            <Fragment>
              <a onClick={showModal}>
                {hasNewStyle ? renderNewConditionType() : formattedVal}
                {modalInfo.visible && <ClosedLoopStatusModal visible={modalInfo.visible} modalInfo={modalInfo} onCancel={hideModal} {...props} />}
              </a>
            </Fragment>
          );
        default:
          // 链接跳转
          return (
            <a target="_blank" href={encodeUrlSearch(record[onClick.linkField].toString())}>
              {hasNewStyle ? renderNewConditionType() : formattedVal}
            </a>
          );
      }
    } else if (hasNewStyle) {
      return renderNewConditionType();
    } else if (linkKey) {
      const expression = !linkAgg ? `${linkKey}` : `${linkAgg}(${linkKey})`;
      return (
        <a target="_blank" href={encodeUrlSearch(record[expression])}>
          {formattedVal}
        </a>
      );
    } else if (typeof formattedVal !== 'undefined') {
      return isMerge && childrenIndex !== 0 ? `/ ${formattedVal}` : formattedVal;
    } else {
      return null;
    }
  }
}

export default BodyCell;

function getBodyCellStyle(columnConfig, cellVal, cellValRange, isFirstColumn, isChild) {
  const basicStyle = getBasicStyledCell(columnConfig, isFirstColumn, isChild);
  const conditionStyle = getMergedConditionStyledCell(basicStyle, cellVal, columnConfig, cellValRange);
  const cellStyle = { ...basicStyle, ...conditionStyle };
  return cellStyle;
}

function getBasicStyledCell(columnConfig, isFirstColumn, isChild) {
  const style = columnConfig && columnConfig.style ? columnConfig.style : DefaultTableCellStyle;
  const {
    fontSize,
    // fontFamily,
    fontWeight,
    fontColor,
    fontStyle,
    justifyContent,
    backgroundPosition,
    padding,
    backgroundColor,
  } = style;
  const cssStyle = {
    fontSize: `${fontSize}px`,
    // fontFamily,
    fontWeight: fontWeight,
    color: fontColor,
    backgroundPosition,
    backgroundColor,
    padding,
    fontStyle,
    textAlign: textAlignAdapter(!columnConfig ? 'flex-start' : justifyContent),
  };
  if (isChild) {
    cssStyle.border = 'none';
  }
  return cssStyle;
}

// function hasMatchedTheCondition(cellVal, operatorType, conditionValues) {
//   let matchTheCondition = false;
//   switch (operatorType) {
//     case OperatorTypes.Between:
//       const [minVal, maxVal] = conditionValues;
//       matchTheCondition = cellVal >= minVal && cellVal <= maxVal;
//       break;
//     case OperatorTypes.Contain:
//       matchTheCondition = cellVal.toString().indexOf(conditionValues[0].toString()) >= 0;
//       break;
//     case OperatorTypes.Equal:
//       matchTheCondition = cellVal === conditionValues[0];
//       break;
//     case OperatorTypes.GreaterThan:
//       matchTheCondition = cellVal > conditionValues[0];
//       break;
//     case OperatorTypes.GreaterThanOrEqual:
//       matchTheCondition = cellVal >= conditionValues[0];
//       break;
//     case OperatorTypes.In:
//       matchTheCondition = conditionValues.findIndex((cVal) => cVal === cellVal) >= 0;
//       break;
//     case OperatorTypes.LessThan:
//       matchTheCondition = cellVal < conditionValues[0];
//       break;
//     case OperatorTypes.LessThanOrEqual:
//       matchTheCondition = cellVal <= conditionValues[0];
//       break;
//     case OperatorTypes.NotEqual:
//       matchTheCondition = cellVal !== conditionValues[0];
//       break;
//   }
//   return matchTheCondition;
// }

function getBackgroundConditionCellStyle(conditionStyle) {
  const { colors } = conditionStyle;
  const { fore, background } = colors;
  const cssStyle = {
    color: fore,
    backgroundColor: background,
  };
  return cssStyle;
}

function getTextConditionCellStyle(conditionStyle) {
  const { colors } = conditionStyle;
  const { fore, textFore } = colors;
  const cssStyle = {
    color: textFore || fore,
  };
  return cssStyle;
}

function getNumericBarConditionCellStyle(basicStyle, conditionStyle, cellVal, maxCellVal, minCellVal) {
  const { zeroPosition, colors } = conditionStyle;
  const { fore, positive, negative } = colors;

  const valRange = Math.max(maxCellVal, 0) - Math.min(0, minCellVal);
  let cellBarPercentage = void 0;
  if (cellVal < minCellVal) {
    cellBarPercentage = 0;
  } else if (cellVal > maxCellVal) {
    cellBarPercentage = 100;
  }
  let barZeroPosition;
  switch (zeroPosition) {
    case 'center':
      if (cellBarPercentage === void 0) {
        cellBarPercentage = (Math.abs(cellVal) / Math.max(Math.abs(minCellVal), Math.abs(maxCellVal))) * 50;
      }
      barZeroPosition = 50;
      break;
    case 'auto':
      if (cellBarPercentage === void 0) {
        cellBarPercentage = (Math.abs(cellVal) / valRange) * 100;
      }
      barZeroPosition = (Math.abs(Math.min(0, minCellVal)) / (Math.abs(minCellVal) + Math.abs(maxCellVal))) * 100;
      break;
  }

  const backgroundColor = basicStyle.backgroundColor || 'transparent';
  const divisions = [`${backgroundColor} 0%`];
  if (cellVal < 0) {
    divisions.push(`${backgroundColor} ${barZeroPosition - cellBarPercentage}%`);
    divisions.push(`${negative} ${barZeroPosition - cellBarPercentage}%`);
    divisions.push(`${negative} ${barZeroPosition}%`);
    divisions.push(`${backgroundColor} ${barZeroPosition}%`);
  } else {
    divisions.push(`${backgroundColor} ${barZeroPosition}%`);
    divisions.push(`${positive} ${barZeroPosition}%`);
    divisions.push(`${positive} ${barZeroPosition + cellBarPercentage}%`);
    divisions.push(`${backgroundColor} ${barZeroPosition + cellBarPercentage}%`);
  }
  divisions.push(`${backgroundColor} 100%`);

  const cssStyle = {
    color: fore,
    background: `linear-gradient(90deg, ${divisions.join(',')})`,
  };
  return cssStyle;
}

function getConditionStyledCell(basicStyle, cellVal, conditionStyle, cellValRange?) {
  const { operatorType, conditionValues, type } = conditionStyle;
  const matchTheCondition = hasMatchedTheCondition(cellVal, operatorType, conditionValues);
  if (!matchTheCondition) {
    return null;
  }

  let cssStyle;
  switch (type) {
    case TableConditionStyleTypes.BackgroundColor:
      cssStyle = getBackgroundConditionCellStyle(conditionStyle);
      break;
    case TableConditionStyleTypes.TextColor:
      cssStyle = getTextConditionCellStyle(conditionStyle);
      break;
    case TableConditionStyleTypes.NumericBar:
      const [minCellVal, maxCellVal] = cellValRange;
      cssStyle = getNumericBarConditionCellStyle(basicStyle, conditionStyle, +cellVal, maxCellVal, minCellVal);
      break;
    case TableConditionStyleTypes.Custom:
      // @TODO
      break;
  }

  return cssStyle;
}

function getMergedConditionStyledCell(basicStyle, cellVal, columnConfig, cellValRange?) {
  if (!columnConfig) {
    return null;
  }

  const { styleType, conditionStyles } = columnConfig;
  let conditionCellStyle;
  if (conditionStyles && conditionStyles.length > 0) {
    conditionCellStyle = conditionStyles.reduce(
      (acc, c) => ({
        ...acc,
        ...getConditionStyledCell(basicStyle, cellVal, c, cellValRange),
      }),
      {}
    );
  }
  return conditionCellStyle;
}

function getNewConditionType(conditionStyle, cellValue) {
  const newType = ['icon', 'tag'];
  const newTypeConditions = conditionStyle.filter((condition) => {
    return newType.includes(condition.type);
  });
  const newTypeMatch = newTypeConditions.filter((con) => {
    const { operatorType, conditionValues, type } = con;
    return hasMatchedTheCondition(cellValue, operatorType, conditionValues);
  });
  if (newTypeMatch.length) {
    return {
      hasNewStyle: true,
      conditionStyle: newTypeMatch[newTypeMatch.length - 1],
    };
  } else {
    return {
      hasNewStyle: false,
      conditionStyle: null,
    };
  }
}
