import { DatePicker, Button } from 'antd';
import React, { Component } from 'react';
import pageHeaderStore from '../PageHeader/PageHeaderStore';
import moment from 'moment';
import './index.sass';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import util from '../../util';
import mathUtil from '../../utils/mathUtil';
import locale from 'antd/es/date-picker/locale/zh_CN';
import intl from 'react-intl-universal';
moment.locale('zh-cn');
const { RangePicker } = DatePicker;
export const selectParams = [
  { key: 'last0', name: 'Today' },
  { key: 'last1', name: 'Yesterday' },
  { key: 'lastWeek0', name: 'ThisWeek' },
  { key: 'lastMonth0', name: 'ThisMonth' },
  { key: 'lastQuarter0', name: 'ThisQuarter' },
  { key: 'lastYear0', name: 'ThisYear' },
  { key: 'last7', name: 'ThePast7Days' },
  { key: 'last30', name: 'ThePast30Days' },
  { key: 'lastWeek4', name: 'ThePast4Weeks' },
  { key: 'lastWeek8', name: 'ThePast8Weeks' },
  { key: 'lastWeek12', name: 'ThePast12Weeks' },
  { key: 'lastMonth12', name: 'ThePast12Months' },
  { key: 'lastYear1', name: 'LastYear' },
  { key: 'startToToday', name: 'UptoNow' },
];
// TODO:
// @param {参数类型} @参数名 参数说明
// 1、{boolean} @useDateByStore
//  该参数为时间的类型false则不是
// true为pageheaderstore里的全局时间
// false为自定义的时间
// 2、{string} @lastDate
// 该参数为请求中的lastDate参数
// 3、{string} @date
// 该参数为日期段
// 4、{function} @getDateAndlastDate
// 接收参数(date,lastDate,pickerCount)
// 该参数为更改日期的函数

@observer
class UXSRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastDate: this.props.lastDate,
      date: this.props.date,
    };
  }

  @observable isOpen = false;
  @computed
  get classNameAndText() {
    let lastDate = pageHeaderStore.parameter.lastDate;
    const judgeFunc = (value) => {
      if (value) {
        return {
          className: 'showText',
          text: this.findLastDateName(value),
        };
      } else {
        return {
          className: '',
          text: '~',
        };
      }
    };
    if (this.props.useDateByStore) {
      return judgeFunc(lastDate);
    } else {
      return judgeFunc(this.state.lastDate);
    }
  }
  // openCalendar(boolean) {
  //   this.isOpen = boolean;
  // }
  componentDidMount() {
    // this.disposer = autorun(() => {
    //   let a = this.props.date;
    if (!this.props.useDateByStore) {
      if (this.props.lastDate && this.props.lastDate.includes(',')) {
        this.setState({ lastDate: null });
      } else if (!this.props.lastDate && !this.props.isBoardPicker) {
        this.setState({ lastDate: 'last7' });
      }
      if (this.props.date && !this.props.date.includes(',')) {
        let dataStr = mathUtil.keyToDate(this.props.lastDate);
        this.setState({ date: dataStr });
      } else if (this.props.date) {
        this.setState({ date: this.props.date });
      }
    }
    // });
  }
  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date || this.props.lastDate !== prevProps.lastDate) {
      if (!this.props.useDateByStore) {
        if (this.props.lastDate && this.props.lastDate.includes(',')) {
          this.setState({ lastDate: null });
        } else if ((this.props.lastDate === '' || !this.props.lastDate) && !this.props.isBoardPicker) {
          this.setState({ lastDate: 'last7' });
        }
        if (this.props.date && !this.props.date.includes(',')) {
          let dataStr = mathUtil.keyToDate(this.props.lastDate);
          this.setState({ date: dataStr, lastDate: this.props.lastDate });
        } else if (this.props.date) {
          this.setState({ date: this.props.date, lastDate: this.props.lastDate });
        } else if (this.props.date === null) {
          this.setState({ date: this.props.date, lastDate: this.props.lastDate });
        }
      }
    }
  }
  // 不可用日期的范围
  disabledDate(current) {
    return (current && current.valueOf() > Date.now()) || (current && current.valueOf() < pageHeaderStore.productCreateTime - 86400000);
  }
  changeDateByKey = (key) => {
    let dateStr = mathUtil.keyToDate(key);

    this.dateChange(null, dateStr.split(','), key);
  };
  // 找到key对应的中文
  findLastDateName(key) {
    if (key === 'startToToday') {
      return `${moment(pageHeaderStore.productCreateTime).format('YYYY-MM-DD')}-至今`;
    }
    let str = selectParams
      .map((item) => {
        if (item['key'] === key) {
          return intl.get(item.name);
        }
      })
      .join('');
    return str;
  }
  getButtonClass(key) {
    const getClass = (currentValue, keyValue) => {
      if (currentValue === keyValue) {
        return 'rangePicker-btn active';
      } else {
        return 'rangePicker-btn';
      }
    };
    if (this.props.useDateByStore) {
      return getClass(pageHeaderStore.parameter.lastDate, key);
    } else {
      return getClass(this.state.lastDate, key);
    }
  }
  // 点击右边按钮改变lastDate的回调
  // 切换日期时的回调
  dateChange = (data, dateString, key = null) => {
    // 判断是否要是用pageheaderstore里面存的日期
    if (this.props.useDateByStore) {
      util.blockFetch();
      if (data) {
        // 如果data存在则说明选的是日历此时lastDate为null
        pageHeaderStore.changeDate(dateString.join(), null);
      }
      pageHeaderStore.changeDate(dateString.join(), key);
    } else {
      if (this.props.styleChange) {
        this.props.styleChange(key);
      }
      this.setState({ lastDate: key, date: dateString[0] ? dateString.join() : null }, () => {
        this.props.getDateAndlastDate && this.props.getDateAndlastDate(this.state.lastDate, this.state.date, this.props.pickerCount);
      });
    }

    // DatePicker.blur();
  };

  render() {
    return (
      <div className={`${this.props.title && 'pageheader-picker'} ${this.props.className}`} style={{ display: 'inline-block' }}>
        <RangePicker
          locale={locale}
          disabled={this.props.disabled}
          size="middle"
          allowClear={this.props.allowClear || false}
          className={`${this.classNameAndText.className} uxs-picker`}
          onChange={this.dateChange}
          suffixIcon={<div style={{ display: 'none' }}></div>}
          value={
            this.props.useDateByStore
              ? pageHeaderStore.parameter.date
                ? [moment(pageHeaderStore.parameter.date.split(',')[0]), moment(pageHeaderStore.parameter.date.split(',')[1])]
                : null
              : this.state.date
              ? [moment(this.state.date.split(',')[0]), moment(this.state.date.split(',')[1])]
              : null
          }
          placeholder={[intl.get('StartDate'), intl.get('EndDate')]}
          disabledDate={this.disabledDate}
          separator={this.classNameAndText.text}
          dropdownClassName={`calendar-dropdown uxs-picker-dropdown ${this.classNameAndText.className} ${this.props.dropdownClassName}`}
          renderExtraFooter={() => (
            <div className="ant-calendar-footer-extra-content">
              <div className="ant-calendar-footer-extra-content-odd">
                {selectParams.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Button
                        className={`${this.getButtonClass(item.key)}`}
                        key={index}
                        onClick={() => {
                          this.changeDateByKey(item.key);
                        }}>
                        {intl.get(item.name)}
                      </Button>
                    );
                  }
                })}
              </div>
              <div className="ant-calendar-footer-extra-content-event">
                {selectParams.map((item, index) => {
                  if (index % 2 === 1) {
                    return (
                      <Button
                        className={`${this.getButtonClass(item.key)}`}
                        key={index}
                        onClick={() => {
                          this.changeDateByKey(item.key);
                        }}>
                        {intl.get(item.name)}
                      </Button>
                    );
                  }
                })}
              </div>
            </div>
          )}
        />
        {this.props.title && <div className="pageheader-picker-title">{this.props.title}</div>}
      </div>
    );
  }
}
export default UXSRangePicker;
