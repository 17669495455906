import React, { Component } from 'react';
import { Input } from 'antd';
import intl from 'react-intl-universal';
import { getTextWidth } from '../../../../containers/BIChart/util';

const { Search } = Input;

class Main extends Component {
  constructor() {
    super();
    this.content = React.createRef();
  }
  componentDidMount() {
    this.setTitleStyle();
  }
  componentDidUpdate(prevProps) {
    if (this.props.item.name !== prevProps.item.name) {
      this.setTitleStyle();
    }
  }
  setTitleStyle = () => {
    const titleWidth = getTextWidth(this.props.item.name);
    const placeholderWidth = getTextWidth(intl.get('Pleaseenterkeywords'));
    this.content.current.getElementsByClassName('ant-input')[0].style.paddingLeft = `${titleWidth + 16}px`;
    // this.content.current.getElementsByClassName('ant-select-selection__rendered')[0].style.paddingLeft = `${titleWidth + 8}px`;
    const calWidth = titleWidth + placeholderWidth + 30;
    this.content.current.getElementsByClassName('ant-input')[0].style.width = calWidth < 180 ? '180px' : `${calWidth}px`;
  };
  render() {
    const { onChange, value, item, index } = this.props;
    return (
      <div className="quick-filter-item pc-quick-filter" key={`${item.name}${index}`} ref={this.content} style={{ ...this.props.style }}>
        <div className="quick-filter-item-title" title={item.name}>
          {item.name}
        </div>
        <Search
          className="quick-filter-select"
          style={{ height: '100%' }}
          placeholder={intl.get('Pleaseenterkeywords')}
          value={value}
          onChange={(e) => onChange(e.target.value, item, index)}
        />
      </div>
    );
  }
}

export default Main;
