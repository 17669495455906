import AnalyseTableLayout from '../../containers/AnalyseTableLayout/index';
export default {
  title: '留存分析',
  components: [
    {
      component: AnalyseTableLayout,
      props: {
        analyseType: 'remainAnalytics',
        type: 3,
        title: '留存分析',
      },
    },
  ],
};
