import React, { Component } from 'react';
import { iconfont } from '../../config';
import { Button, Modal, Switch, message, Select } from 'antd';
import api from '../../api';
import util from '../../util';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';

import './index.sass';

const { Option } = Select;

export default class index extends Component {
  state = {
    shareRole: [],
    shareAccount: [],
    shareAccounts: [],
    shareForm: [
      {
        sharePartOrAll: 'all',
        shareSelectAccount: '',
        shareSelectPeople: [],
        shareisCollaboration: false,
      },
    ],
  };
  componentDidMount() {
    //分享部分成员默认选择账户
    util.get(api.account.all, {}, (res) => {
      this.setState({ shareAccount: res });
    });
    util.get(
      api.role.list,
      {
        idsite: pageHeaderStore.parameter.idsite,
        pageSize: 1000,
        pageNum: 1,
      },
      (res) => {
        this.setState({ shareRole: res.data });
      }
    );
    util.get(
      api.personal.list,
      {
        currentPage: 1,
        pageCount: 10000,
      },
      (res) => {
        this.setState({ shareAccounts: res.data });
      }
    );
    //分享编辑
    util.get(
      api.board.info,
      {
        sourceType: this.props.type,
        sourceId: this.props.haveCurrentBoardId
          ? pageHeaderStore.currentBoardId
          : this.props.myId || this.props.store.parentData[this.props.index].sourceId || this.props.store.parentData[this.props.index].id,
      },
      (res) => {
        if (res.data.length === 0) {
          let returnShareForm = [
            {
              sharePartOrAll: 'onlyMyself',
              shareSelectAccount: '',
              shareSelectPeople: [],
              shareisCollaboration: '',
            },
          ];
          this.setState({ shareForm: returnShareForm });
          this.triggerChange(this.state.shareForm);
        } else {
          let returnShareForm = res.data.map((item, index) => {
            return {
              sharePartOrAll: item.destinationList == 'all' ? 'all' : 'part',
              shareSelectAccount:
                item.destinationList == 'all' ? '' : item.destinationType === 2 ? 'role' : item.destinationType === 1 ? 'account' : 'accounts',
              shareSelectPeople: item.destinationList == 'all' ? [] : item.destinationList.split(','),
              shareisCollaboration: item.editable,
            };
          });
          this.setState({ shareForm: returnShareForm });
          this.triggerChange(this.state.shareForm);
        }
        this.props.setSharePerm(res.isSharePerm);
      }
    );
    this.triggerChange(this.state.shareForm);
  }
  //分享全部成员
  getAllValue = (e) => {
    if (e == 'all') {
      let arr = [
        {
          sharePartOrAll: 'all',
          shareSelectAccount: '',
          shareSelectPeople: [],
          shareisCollaboration: false,
        },
      ];
      this.setState({ shareForm: arr });
      this.triggerChange(arr);
    } else if (e == 'part') {
      let arr = [
        {
          sharePartOrAll: 'part',
          shareSelectAccount: 'account',
          shareSelectPeople: [],
          shareisCollaboration: false,
        },
      ];
      this.setState({ shareForm: arr });
      this.triggerChange(arr);
    } else {
      let arr = [
        {
          sharePartOrAll: 'onlyMyself',
          shareSelectAccount: '',
          shareSelectPeople: [],
          shareisCollaboration: false,
        },
      ];
      this.setState({ shareForm: arr });
      this.triggerChange(arr);
    }
  };
  //全部成员是否协作
  getAllCollaboration = (e) => {
    let arr = this.state.shareForm;
    arr[0].shareisCollaboration = e;
    this.setState({ shareForm: arr });
    this.triggerChange(arr);
  };
  //分享部分成员
  getPartValue = (e, index) => {
    if (e == 'all') {
      let arr = [
        {
          sharePartOrAll: 'all',
          shareSelectAccount: '',
          shareSelectPeople: [],
          shareisCollaboration: false,
        },
      ];
      this.setState({ shareForm: arr });
      this.triggerChange(arr);
    } else {
      let arr = this.state.shareForm;
      arr[index].sharePartOrAll = e;
      this.setState({ shareForm: arr });
      this.triggerChange(arr);
    }
  };
  //删除
  shareDelete = (index) => {
    let arr = this.state.shareForm;
    arr.splice(index, 1);
    this.setState({ shareForm: arr });
    this.triggerChange(this.state.shareForm);
  };
  //增加
  addsharemodal = () => {
    let arr = this.state.shareForm;
    arr.push({
      sharePartOrAll: 'part',
      shareSelectAccount: 'account',
      shareSelectPeople: [],
      shareisCollaboration: false,
    });
    this.setState({ shareForm: arr });
    this.triggerChange(this.state.shareForm);
  };
  //选择账户
  getAccount = (e, index) => {
    let arr = this.state.shareForm;
    arr[index].shareSelectAccount = e;
    arr[index].shareSelectPeople = [];
    this.setState({ shareForm: arr });
    this.triggerChange(arr);
    if (e === 'role') {
      util.get(
        api.role.list,
        {
          idsite: pageHeaderStore.parameter.idsite,
          pageSize: 1000,
          pageNum: 1,
        },
        (res) => {
          this.setState({ shareRole: res.data });
        }
      );
    } else if (e === 'account') {
      util.get(api.account.all, {}, (res) => {
        this.setState({ shareAccount: res });
      });
    } else {
      util.get(
        api.personal.list,
        {
          currentPage: 1,
          pageCount: 10000,
        },
        (res) => {
          this.setState({ shareAccounts: res.data });
        }
      );
    }
  };
  //部分成员是否允许协助
  getSwitch = (e, index) => {
    let arr = this.state.shareForm;
    arr[index].shareisCollaboration = e;
    this.setState({ shareForm: arr });
    this.triggerChange(arr);
  };
  getPeople = (e, index) => {
    let arr = this.state.shareForm;
    arr[index].shareSelectPeople = e;
    this.setState({ shareForm: arr });
    this.triggerChange(arr);
  };
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange([...changedValue]);
    }
  };

  render() {
    //判断是否为分享全部
    let result = this.state.shareForm.some((item) => item.sharePartOrAll == 'all');
    let isOnly = this.state.shareForm.some((item) => item.sharePartOrAll == 'onlyMyself');
    let isPart = this.state.shareForm.some((item) => item.sharePartOrAll == 'part');
    return (
      <div>
        {isOnly === true ? (
          <div>
            <Select className="shareCascader" value={this.state.shareForm[0].sharePartOrAll} onChange={this.getAllValue}>
              <Option value="all">分享全部成员</Option>
              <Option value="part">分享部分成员</Option>
              <Option value="onlyMyself">仅自己可见</Option>
            </Select>
          </div>
        ) : result === true ? (
          this.state.shareForm.map((item, index) => {
            return (
              <div key={`${JSON.stringify(item)}${index}`} className="shareModelDiv">
                <Select className="shareCascader" value={item.sharePartOrAll} onChange={this.getAllValue}>
                  <Option value="all">分享全部成员</Option>
                  <Option value="part">分享部分成员</Option>
                  <Option value="onlyMyself">仅自己可见</Option>
                </Select>
                <div>
                  <span>是否允许协作</span> 
                  <Switch className="iscooperation" defaultChecked={item.shareisCollaboration} size={'small'} onChange={this.getAllCollaboration} />
                </div>
              </div>
            );
          })
        ) : (
          this.state.shareForm.map((item, index) => {
            return (
              <div key={`${JSON.stringify(item)}${index}`} className="shareModelDiv">
                <Select className="shareCascader" value={item.sharePartOrAll} onChange={(e) => this.getPartValue(e, index)}>
                  <Option value="all">分享全部成员</Option>
                  <Option value="part">分享部分成员</Option>
                  <Option value="onlyMyself">仅自己可见</Option>
                </Select>
                <div>
                  <Select className="objCascader" value={item.shareSelectAccount} onChange={(e) => this.getAccount(e, index)}>
                    <Option value="role">选择角色</Option>
                    <Option value="account">选择账号</Option>
                    <Option value="accounts">选择账号组</Option>
                  </Select>
                  <Select
                    className="rolecascader"
                    mode="tags"
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={item.shareSelectPeople.map((item) => {
                      return item.toString();
                    })}
                    onChange={(e) => this.getPeople(e, index)}
                    placeholder="请选择">
                    {item.shareSelectAccount === 'role'
                      ? this.state.shareRole.map((item, index) => {
                          return <Option key={item.roleId.toString()}>{item.roleName}</Option>;
                        })
                      : item.shareSelectAccount === 'account'
                      ? this.state.shareAccount.map((item, index) => {
                          return (
                            <Option key={item.id.toString()}>{`${item.username}(${item.realNameOrUserName ? item.realNameOrUserName : item.username})`}</Option>
                          );
                        })
                      : this.state.shareAccounts.map((item, index) => {
                          return <Option key={item.id.toString()}>{item.name}</Option>;
                        })}
                  </Select>
                  <span className="shareDeleteIcon">
                    <i
                      className={iconfont.delete}
                      title="删除"
                      onClick={() => this.shareDelete(index)}
                      style={{ display: this.state.shareForm.length === 1 ? 'none' : '' }}
                    />
                  </span>
                </div>
                <div className="shareBottom">
                  <span>是否允许协作</span> 
                  <Switch className="iscooperation" defaultChecked={item.shareisCollaboration} size={'small'} onChange={(e) => this.getSwitch(e, index)} />
                </div>
              </div>
            );
          })
        )}
        {isPart === true ? (
          <Button size={'large'} onClick={this.addsharemodal} style={{ fontSize: '12px', width: '72px', height: '24px', padding: '0px', color: '#14C4A2' }}>
            +增加一组
          </Button>
        ) : null}
      </div>
    );
  }
}
