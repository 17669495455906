import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import { observable, action, computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import Sankey from 'highcharts/modules/sankey';
import HighchartsMore from 'highcharts/highcharts-more';
import { Select } from 'antd';
import './index.sass';
import util from '../../util';
import legendUtil from '../../utils/legendUtil';
import TableNullData from '../../component/TableNullData/index';
import Loading from '../Loading/Loading';
import OrderNumber from '../OrderNumber';

const Option = Select.Option;

@observer
class NewSankey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastClickedNode: null,
      lastClickedPointId: null,
    };
    HighchartsMore(ReactHighcharts.Highcharts);
    Sankey(ReactHighcharts.Highcharts);
    // 未知 类似一个方法
    this.handleHighLightClick = this.handleHighLightClick.bind(this);
    this.handleClearHighlight = this.handleClearHighlight.bind(this);
    // this.data赋值传来的data
    // 旧data格式
    // data:{
    //   attrDetails:{
    //     passData:{},
    //     nodes:{},
    //     data:{},
    //     otherData:{},
    //   }
    // }
    this.props.data && (this.data = this.props.data);
    // 下拉框的默认显示
    if (this.props.hidePageShowWay) {
      this.pageShowWay = 'title';
    }
    this.props.dims && (this.dims = this.props.dims);
    this.props.nodeFields && (this.nodeFields = this.props.nodeFields);
  }

  @observable
  data;

  @observable
  pageShowWay = 'name';

  @observable
  dims;

  @observable
  nodeFields;

  @observable
  svgChart;
  @observable
  chartWidth;
  @observable
  chartHeight;
  @observable
  isLoading = false;

  componentDidMount() {
    if (this.props.index === null) {
      this.chartWidth = '100%';
    } else if (this.props.index !== undefined && this.props.index !== undefined && !isNaN(this.props.index)) {
      this.chartHeight = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetHeight - 80;
      this.chartWidth = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetWidth;
      this.eventListen();
    } else {
      this.chartHeight = '100%';
      this.chartWidth = '100%';
    }
  }
  componentWillUnmount() {
    window.onmousemove = null;
    window.onmouseup = null;
  }

  eventListen() {
    const reactResizableHandle = document.querySelectorAll(`#card${this.props.pathId} .react-resizable-handle`);
    reactResizableHandle &&
      (reactResizableHandle.onmousedown = (e) => {
        this.chartHeight = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetHeight - 80;
        this.chartWidth = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetWidth;
        window.onmousemove = (e) => {
          this.chartHeight = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetHeight - 80;
          this.chartWidth = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetWidth;
        };
      });
    window.onmouseup = (e) => {
      this.chartHeight = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetHeight - 80;
      this.chartWidth = document.querySelectorAll('.react-grid-layout .react-grid-item')[this.props.index].offsetWidth;
      window.onmousemove = null;
    };
  }
  // callback引用
  @action.bound
  getSvgChart(chart) {
    this.svgChart = chart;
  }

  @action.bound
  // 下拉框选择的时候调用 更改pageshowway（页面显示方式）
  changePageShowWay(pageShowWay) {
    this.pageShowWay = pageShowWay;
  }
  // 桑基图上的步骤数的宽度
  @computed
  get getHeaderWidth() {
    let svgWidth = null;
    if (this.svgChart && this.svgChart.container) {
      const nodeWidth = 100;
      const pointWidth = 152;
      return 3 * nodeWidth + pointWidth;
    }
    return svgWidth;
  }
  // hightchart宽度
  @computed
  get getSvgWidth() {
    let svgWidth = null;
    if (this.svgChart && this.svgChart.container) {
      const nodeWidth = 100;
      const pointWidth = 152;
      // return this.props.sumData.length * nodeWidth + (this.props.sumData.length - 1) * pointWidth + padding;
      // return 5 * nodeWidth + (5 - 1) * pointWidth + padding;
      return 3 * nodeWidth + pointWidth;
    }
    return svgWidth;
  }
  // hightchart高度
  @computed
  get svgHeight() {
    let height = null;
    if (this.data.otherData.maxBreachNum !== undefined) {
      //const currentBreachNum = parseInt(this.data.otherData.maxBreachNum, 10);
      height = parseInt(this.data.otherData.maxBreachNum, 10) * 70;
    }
    return height;
  }
  // Q：处理高亮点击？
  // 点击每个点会显示路径
  handleHighLightClick(isNode) {
    if (this.data.passData && Object.keys(this.data.passData).length > 0) {
      // 重绘highchart
      let chart = this.refs.chart.getChart();
      // isNode为真
      if (isNode) {
        //Q: 最后一个pointId为null?
        this.setState({ lastClickedPointId: null });
        // 重绘sankey？修改this.data的内容
        util.sankey.highlight(this.data, this.state.lastClickedNode.id);
        // 重修修改data里面的nodes
        this.data.nodes.map((item) => {
          // 如果最后的点击的id等于遍历中的item Id
          if (this.state.lastClickedNode.id === item.id) {
            item.isHighLight = true;
          } else {
            item.isHighLight = false;
          }
          return item;
        });
        // 修改this.streamData
        this.getUpAndDownStream(this.data, this.state.lastClickedNode.id);
      } else {
        util.sankey.highlight(this.data, this.state.lastClickedPointId);
        if (this.state.lastClickedNode) {
          this.data.nodes.map((item) => {
            item.isHighLight = false;
            return item;
          });
        }
      }
      // 更新？？
      chart.series[0].update({
        data: this.data.data,
        nodes: this.data.nodes,
      });
    }
  }
  // Q：处理清除高亮点击？
  handleClearHighlight(isNode) {
    // passData应该是之前表格的数据
    if (this.data.passData && Object.keys(this.data.passData).length > 0) {
      // 取消所有高亮
      util.sankey.clearHighlight(this.data);
      // 如果是节点
      if (isNode) {
        // 遍历节点
        this.data.nodes.map((item) => {
          //
          if (this.state.lastClickedNode.id === item.id) {
            item.isHighLight = false;
          }
          return item;
        });
      } else {
        if (this.state.lastClickedNode) {
          this.data.nodes.map((item) => {
            item.isHighLight = false;
            return item;
          });
        }
      }
      let chart = this.refs.chart && this.refs.chart.getChart();
      chart &&
        chart.series[0].update({
          data: this.data.data,
          nodes: this.data.nodes,
        });
    }
  }
  // Q:观察渠道data？？
  @observable
  streamData;
  // Q:得到上下游？？？
  getUpAndDownStream(data, nodeId) {
    let streamData = { entry: [], from: [], to: [] };
    let fromIds = [],
      entryIds = [],
      toIds = [];
    Object.keys(data.passData[nodeId].nodes).forEach((item) => {
      if (item.indexOf('entry') !== -1 && nodeId.indexOf('entry') === -1) {
        entryIds.push(item);
      }
    });
    data.data.forEach((item) => {
      if (item.to === nodeId && item.from.indexOf('exit') === -1 && item.dataWeight > 0) {
        if (nodeId.match(/-0$/) !== null && item.from.indexOf('entryType') !== -1) {
          entryIds.push(item.from);
        } else {
          fromIds.push(item.from);
        }
      } else if (item.from === nodeId && item.to.indexOf('exit') === -1 && item.dataWeight > 0) {
        toIds.push(item.to);
      }
    });
    data.nodes.forEach((item) => {
      if (fromIds.includes(item.id)) {
        streamData.from.push(item);
      } else if (entryIds.includes(item.id)) {
        streamData.entry.push(item);
      } else if (toIds.includes(item.id)) {
        streamData.to.push(item);
      }
    });

    let totalEntry = 0,
      totalFrom = 0,
      totalTo = 0;
    if (streamData.entry.length > 0) {
      streamData.entry.forEach((item) => (totalEntry = totalEntry + item.highlightValue));
      streamData.entry.forEach((item) => {
        item.streamRate = ((item.highlightValue / totalEntry) * 100).toFixed(2) + '%';
      });
      streamData.entry.push({ name: '合计', value: totalEntry, streamRate: '100%' });
    }
    if (streamData.from.length > 0) {
      streamData.from.forEach((item) => (totalFrom = totalFrom + item.highlightValue));
      streamData.from.forEach((item) => {
        item.streamRate = ((item.highlightValue / totalFrom) * 100).toFixed(2) + '%';
      });
      streamData.from.push({ name: '合计', value: totalFrom, streamRate: '100%' });
    }
    if (streamData.to.length > 0) {
      streamData.to.forEach((item) => (totalTo = totalTo + item.highlightValue));
      streamData.to.forEach((item) => {
        item.streamRate = ((item.highlightValue / totalTo) * 100).toFixed(2) + '%';
      });
      streamData.to.push({ name: '合计', value: totalTo, streamRate: '100%' });
    }
    this.streamData = streamData;
  }

  render() {
    // const tooltipBgColor = 'rgba(5,10,19,1)';
    const data = this.data ? this.data : { data: {}, node: {} };
    let that = this;
    const config = {
      chart: {
        margin: [10, 10, 10, 10],
        spacingTop: 50,
        width: null,
        height: null,
      },
      // colors: ['#14C4A2', '#FACD46', '#FCA460', '#877CF8', '#57ABF8', '#72E1B1', '#34D1DF', '#638FF6', '#F96A91', '#F8893F', '#59C1FE'],

      // 注释
      // 用于配置注释的选项，例如标签，箭头或形状。注释可以绑定到点，轴坐标或图表像素坐标。
      annotations: {
        visible: false,
      },
      // 默认情况下，Highchart在图表的右下角放置一个Credits标签。可以使用这些选项进行更改
      credits: {
        enabled: false,
      },
      // 标题
      title: {
        text: '',
      },
      //tooltip使用html渲染代替 SVG
      tooltip: {
        useHTML: true,
      },
      // 通过单击图形（列，点标记，饼图，地图区域等），可以选择该系列的点。
      plotOptions: {
        series: {
          // 是否允许在点击数据点标记（markers）
          allowPointSelect: true,
          // colorByPoint: true,
          cursor: 'pointer',
        },
      },
      series: [
        {
          states: {
            select: {
              enable: true,
              borderColor: '#ff0000',
            },
          },
          padding: 10,
          // 从哪到哪，权重多少
          keys: ['from', 'to', 'weight'],
          type: 'sankey',
          // 线的透明度
          linkOpacity: 0.8,
          curveFactor: 0.4,
          //
          animation: 0,
          // 每个节点上面的名字
          dataLabels: {
            inside: false,
            padding: 0,
            overflow: 'none',
            // 为什么是bottom
            useHTML: true,
            className: 'node-format-data-label',
            zIndex: 1,
            // <div class=${this.point['className']}>
            nodeFormatter: function() {
              return that.dims
                ? `<p class="node-format-data-label-for-node ${this.point['className']}">${this.point[`${that.dims}_${that.pageShowWay}`]}</p>
                    <p class="node-format-data-label-for-node ${this.point['className']}">${legendUtil.formateTitle(
                    'pagePathPvRatio',
                    this.point.pagePathPvRatio
                  )}</p>`
                : `<p class="node-format-data-label-for-node ${this.point['className']}">${this.point['name']}</p>
                <p class="node-format-data-label-for-node ${this.point['className']}"> ${legendUtil.formateTitle('uvRatio', this.point['uvRatio'])}</p>`;
            },
          },
          point: {},
          // 节点长度
          nodeWidth: 72,
          nodePadding: 35,
          label: {
            enabled: false,
          },
          // 路径数据
          data: data.data,
          // 节点的数据
          nodes: data.nodes,
          // 提示框
          tooltip: {
            useHTML: true,
            headerFormat: '',
            borderRadius: 4,
            // 节点上面的提示框
            nodeFormatter: function() {
              let appendStr = () => {
                //判断是否第0步
                if (!that.dims) {
                  return '';
                }
                if (this.id.includes('0')) {
                  return `<div class="sankey-tooltip-content"><span>退出数</span><span class="text-right">${legendUtil.formateTitle(
                    'pagePathExits',
                    this.pagePathExits
                  )}</span></div>`;
                } else if (!this[`${that.dims}_key`]) {
                  // 判断是否为直接进入
                  return `<div class="sankey-tooltip-content"><span>跳出率</span><span class="text-right">${legendUtil.formateTitle(
                    'pagePathBounces',
                    this.pagePathBounces
                  )}</span></div>`;
                } else {
                  // 其余情况返回空字符串
                  return '';
                }
              };

              let nodeFormatterStr = `<div class="sankey-tooltip">
              
              <div class="sankey-tooltip-header">${that.dims ? this[`${that.dims}_${that.pageShowWay}`] : this.name}</div>
   
              ${
                toJS(that.nodeFields).length > 0
                  ? toJS(that.nodeFields)
                      .map((item) => {
                        return `<div class="sankey-tooltip-content"><span>${legendUtil.getTitle(item)}</span><span class="text-right">${legendUtil.formateTitle(
                          item,
                          this[item]
                        )}</span></div>`;
                      })
                      .join('')
                  : null
              }
              ${appendStr()}
              </div>`;
              return nodeFormatterStr;
            },
            // tooltip框
            pointFormatter: function() {
              return `<div class="sankey-tooltip">
                      <div class="sankey-tooltip-content"><span>占比</span><span class="text-right">${legendUtil.formateTitle('rate', this.rate)}</span></div>
                     </div>`;
            },
          },
        },
      ],
    };

    return (
      <div className="sankey-chart-wrapper" ref="myChart">
        {!this.props.isNotShowOption ? (
          <div className="sankey-chart-header">
            <label key={'pageStep'} style={{ fontSize: 14 }} className="text-gray">
              智能计算步骤
            </label>
            <Select style={{ width: 48 }} onSelect={this.props.changePageStep} value={this.props.pageStep ? this.props.pageStep : 4}>
              <Option key={3} value="3">
                3
              </Option>
              <Option key={4} value="4">
                4
              </Option>
              <Option key={5} value="5">
                5
              </Option>
              <Option key={6} value="6">
                6
              </Option>
              <Option key={7} value="7">
                7
              </Option>
              <Option key={8} value="8">
                8
              </Option>
              <Option key={9} value="9">
                9
              </Option>
              <Option key={10} value="10">
                10
              </Option>
            </Select>
            {/*  页面展示的形式*/}
            {/* {!this.props.hidePageShowWay && (
            <React.Fragment>
              <label key={'pageShowWay'} className="text-gray">
                页面展示形式：
              </label>
              <Select key={'pageShowWay-select'} style={{ width: 96 }} onSelect={this.changePageShowWay} defaultValue={this.pageShowWay}>
                <Option key={1} value="name">
                  页面标题
                </Option>
              </Select>
            </React.Fragment>
          )} */}
          </div>
        ) : null}

        <div className="sankey-chart-content">
          <div
            className={`sankey-chart ${process.env.REACT_APP_BDkey}_style`}
            style={{
              minWidth: (this.data.sumData.length - 1) * 400,
              width: this.chartWidth,
              height: this.chartHeight,
            }}>
            {/* 桑基图上面的步骤数 */}
            <div className="path-level-wrapper">
              <div className="path-level">
                {this.data.sumData &&
                  this.data.sumData.map((item, index) => (
                    <div className="path-level-step" key={index}>
                      第<OrderNumber number={index}></OrderNumber>步
                    </div>
                  ))}
              </div>
            </div>
            <div className="path-level-content">
              {/* 桑基图上面的步骤数 */}
              {this.props.data.nodes.length !== 0 && this.props.data.data.length !== 0 ? (
                !this.isLoading ? (
                  <ReactHighcharts
                    config={config}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    ref="chart"
                    callback={this.getSvgChart}
                  />
                ) : (
                  <Loading></Loading>
                )
              ) : (
                <TableNullData></TableNullData>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewSankey;
