import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import { Select } from 'antd';
import './index.sass';
import store from './store';
import theme from '../../static/echart-config/normal';
import theme_blue from '../../static/echart-config/blue';
import theme_dark from '../../static/echart-config/dark';
import theme_sams from '../../static/echart-config/sams';
import theme_ipsos from '../../static/echart-config/ipsos';
import theme_rainbow from '../../static/echart-config/rainbow';
import ReactEchartsCore from 'echarts-for-react/lib/core';
// import  * as echarts from 'echarts/lib/echarts';
import legendUtil from '../../utils/legendUtil';
import BoardStore from '../../views/BoardCustom/BoardChart/BoardStore';
import * as echarts from 'echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/map';
import 'echarts/lib/chart/effectScatter';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/chart/custom';
import 'echarts/lib/chart/heatmap';
import 'echarts/lib/component/geo';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/legendScroll';
import '../../static/JSON/china.js';
import '../../static/JSON/world.js';
import 'echarts/extension/bmap/bmap';
import 'echarts/dist/extension/bmap';
import 'echarts-wordcloud';
// import './echarts-wordcloud.js';
import Loading from '../Loading/Loading';

const Option = Select.Option;
const BDTheme = {
  sams: theme_sams,
  ipsos: theme_ipsos,
  rainbow: theme_rainbow,
};

@observer
class UXSChart extends Component {
  constructor(props) {
    super(props);
    this.store = new store();
    this.setTheme('简洁', BDTheme[process.env.REACT_APP_BDkey] ? BDTheme[process.env.REACT_APP_BDkey].theme : theme.theme);

    this.setTheme('暗黑', theme_dark.theme);
    this.setTheme('星空蓝', theme_blue.theme);
    this.props.type && (this.store.chartType = this.props.type);
    this.store.getOptionData(this.props.data);
    this.props.mapType && this.store.changeMapType(this.props.mapType);
    this.props.showAll && (this.store.showAll = true);
    this.store.getProps(this.props);
    this.dataSource = [
      {
        title: '折线图',
        type: 'line',
      },
      {
        title: '饼图',
        type: 'pie',
      },
      {
        title: '柱状图',
        type: 'bar',
      },
      {
        title: '标签云',
      },
    ];
  }
  @observable chartHeight;
  @observable isLoading = false;
  @computed get themeName() {
    if (BoardStore.boardStyle === 'dark') {
      return '暗黑';
    } else if (BoardStore.boardStyle === 'blue') {
      return '星空蓝';
    } else {
      return '简洁';
    }
  }
  componentWillReceiveProps(current) {
    this.store.getOptionData(current.data);
    this.store.changeMapType(current.mapType);
  }
  componentDidMount() {
    window.addEventListener('resize', this.changeChartWidth);
    this.changeChartWidth();
  }

  // eventListen() {
  //   document.querySelectorAll('.react-resizable-handle')[this.props.index].onmousedown = (e) => {
  //     window.onmousemove = (e) => {
  //       this.chartHeight = '100%';
  //     };
  //   };
  //   window.onmouseup = (e) => {
  //     window.onmousemove = null;
  //   };
  // }
  @action.bound initChart() {}
  @action.bound changeChartWidth(type) {
    // 判断是否是手机端
    if (window.innerWidth <= 768) {
      // 判断是否是纵向的柱状图
      if (this.props.type === 'bar' && this.props.barType && this.props.barType.includes('horizontal')) {
        // 判断data在不在以及data是否有axis的属性
        if (this.props.data && this.props.data.hasOwnProperty('axis') && this.props.data.axis) {
          // 小于十条就返回统一高度
          if (this.props.data.axis.length <= 10) {
            this.chartHeight = '5rem';
          }
          // 多于十条则高度为条形图个数乘于32
          else {
            this.chartHeight = 32 * this.props.data.axis.length;
          }
        }
        // 无则返回固定高度
        else {
          this.chartHeight = '5rem';
        }
      }
      // 统一返回固定高度
      else {
        this.chartHeight = '5rem';
      }
    } else {
      if (this.props.fullHeight) {
        this.chartHeight = '100%';
      } else if (this.props.index === null) {
        this.chartHeight = '100%';
      } else if (!isNaN(this.props.index)) {
        this.chartHeight = '100%';

        // this.eventListen();
      } else {
        this.chartHeight = 300;
      }
    }
    if (type === 'returnValue') {
      return this.chartHeight;
    }
  }
  @action.bound
  chartClick(params) {
    if (this.props.rank) {
      window.location.href = params.name;
    } else return;
  }
  setTheme = (style, theme) => {
    echarts.registerTheme(style, theme);
    this.store.setWordCloudTheme(theme);
  };
  render() {
    const onChartEvents = {
      click: this.chartClick,
    };
    return (
      <div className="uxs-chart" style={this.props.wrapperStyle}>
        {this.props.chartTitle ? (
          <div className="data-block" style={{ display: this.props.multiple || this.props.order ? 'none' : '' }}>
            <h5 className={`${this.props.chartTitleClass ? this.props.chartTitleClass : 'chart-title'}`}>{this.props.chartTitle}</h5>
          </div>
        ) : null}

        <div className="data-toggle" style={{ display: !this.props.multiple ? 'none' : '' }}>
          {this.props.data.legend && (
            <Select
              size="small"
              style={{ width: 96, fontWeight: 400, border: 'none' }}
              defaultValue={this.props.data.legend && this.props.data.legend[0]}
              onSelect={(value) => {
                this.store.changeDataIndex(value);
              }}>
              {this.props.data.legend.map((item, index) => {
                return (
                  <Option key={index} value={index} className="multipleChartItem">
                    {item}
                  </Option>
                );
              })}
            </Select>
          )}
        </div>
        <div className="data-toggle" style={{ display: !this.props.order ? 'none' : '' }}>
          {this.props.orderSelect && (
            <Select
              size="small"
              style={{ width: 96, fontWeight: 400, border: 'none' }}
              defaultValue={this.props.orderColumn ? legendUtil.getTitle(this.props.orderColumn) : null}
              onSelect={(value, option) => {
                this.props.changeOrderColumn(value);
              }}>
              {this.props.orderSelect.split(',').map((item, index) => {
                return (
                  <Option key={index} value={item} className="multipleChartItem">
                    {legendUtil.getTitle(item)}
                  </Option>
                );
              })}
            </Select>
          )}
        </div>
        <div className={`chart-content ${this.props.barType && this.props.barType.includes('horizontal') ? 'horizontal-style' : ''}`} ref="myChart">
          {!this.isLoading ? (
            <ReactEchartsCore
              echarts={echarts}
              notMerge={true}
              lazyUpdate={true}
              option={this.store.createChartOption}
              onEvents={onChartEvents}
              onChartReady={this.props.onChartReady}
              theme={this.themeName}
              style={{
                width: '100%',
                height: this.changeChartWidth('returnValue'),
              }}
            />
          ) : (
            <Loading></Loading>
          )}
        </div>
      </div>
    );
  }
}

export default UXSChart;
