import React, { Component } from 'react';
import { getInitialItemInfo } from '../../BIChart/util';
import BIChart from '../../BIChart';
import BIStore from '../../BIChart/store';
import AnalyseChart from './AnalyseChart';
import './index.sass';

class CombineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.initWidgets();
  }

  // 初始化卡片配置
  initWidgets = () => {
    this.props.combineConfig.forEach((item) => {
      item.forEach((widgetInfo) => {
        if (widgetInfo.type === 10 || widgetInfo.widgetId) {
          widgetInfo = {
            ...widgetInfo,
            config: JSON.parse(widgetInfo.config),
          };
          BIStore.setItemsInfo(getInitialItemInfo(widgetInfo, BIStore.formedViews), widgetInfo.id || widgetInfo.widgetId);
        }
      });
    });
    this.setLoading(false);
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  getChart(widgetInfo) {
    if (widgetInfo.config) {
      return <BIChart widgetInfo={widgetInfo} id={widgetInfo.id} />;
    } else {
      return <AnalyseChart parentInfo={widgetInfo} {...this.props} />;
    }
  }

  render() {
    const { selectStyle, combineConfig, style } = this.props;
    return (
      <div className={`combine-chart combine-chart-${selectStyle} ${style && style.showTitle ? 'withTitle' : ''}`}>
        {combineConfig &&
          combineConfig.map((item, index) => {
            return (
              <div className={`combine-chart-content combine-chart-content-${index + 1}`} key={index}>
                {item.map((widgetInfo) => {
                  widgetInfo = {
                    ...widgetInfo,
                    config: typeof widgetInfo.config === 'string' ? JSON.parse(widgetInfo.config) : widgetInfo.config,
                  };
                  const combineStyle = widgetInfo.combineStyle || {};
                  const { showTitle } = combineStyle;
                  const height = `${combineStyle.height || 100}%`;
                  const width = `${combineStyle.width || 100}%`;
                  return (
                    <div className={`chart-item ${widgetInfo.config ? `bichart-${widgetInfo.config.selectedChart}` : ''}`} key={`${widgetInfo.id}${index}`}>
                      {showTitle && <h5 className="chart-title">{widgetInfo.name}</h5>}
                      <div className={`chart-content ${showTitle ? 'showTitle-chart-content' : ''}`}>
                        <div style={{ width, height }}>
                          <AnalyseChart parentInfo={widgetInfo} {...this.props} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  }
}

export default CombineChart;
