import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BI_DOMAIN } from '../../../../api';
import AuthorityStore from '../../../../AuthorityStore/store';
import AnalyseBoardUtil from '../../../AnalyseBoardTable/util';

class WidgetItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  //   已配置的卡片拖拽相关回调
  dragStart = (item) => {
    this.props.dragStart(item);
    this.setState({
      dragging: true,
    });
    this.props.setDraggingIndex(this.props.cardIndex);
  };

  dragEnd = () => {
    this.props.dragEnd();
    this.props.setDraggingIndex(-1);
    this.setState({
      dragging: false,
    });
    if (this.props.dragLeaving) {
      let prepareConfig = this.props.prepareConfig;
      prepareConfig.splice(this.props.cardIndex, 1);
      this.props.drop(prepareConfig);
      this.props.dragLeavingToggle(false);
    } else {
      this.props.drop();
    }
  };

  getEditLink = () => {
    let { title, type, id, cardId, viewId } = this.props.item;
    if (viewId) {
      return (
        <a href={`${BI_DOMAIN}/#/project/${AuthorityStore.primaryAccount}/widget/${id}`} title="编辑">
          <i className="iconfont icon-bianji" />
        </a>
      );
    } else {
      return (
        <Link
          to={`/analytics/${AnalyseBoardUtil.typeToHref(type)}/detail?id=${
            cardId ? cardId : id
          }&eventName=${title}&isCanBack=true&lastLink=${encodeURIComponent(this.props.location.pathname)}`}
          title="编辑">
          <i className="iconfont icon-bianji" />
        </Link>
      );
    }
  };

  render() {
    const { prepareIndex, item, cardIndex, prepareConfig, deleteItem, dragLeaving } = this.props;
    const { dragging } = this.state;
    return (
      <div
        className={`drop-card ${
          prepareIndex === cardIndex || (prepareIndex > prepareConfig.length - 1 && prepareConfig.length - 1 == cardIndex) ? 'preparing' : ''
        } ${dragging ? 'dragging' : ''} ${dragLeaving && dragging ? 'leaving' : ''}`}
        onDragStart={() => this.dragStart(item, cardIndex)}
        onDragEnd={this.dragEnd}
        draggable>
        {item.name}
        {this.getEditLink()}
        <i className="iconfont icon-close" onClick={deleteItem} />
      </div>
    );
  }
}

export default WidgetItem;
