import CommonMixChart from '../../containers/CommonMixChart/index';
import ChannelLinkTable from '../../containers/ChannelLinkTable/index';

export default {
  title: '外部链接',
  components: [
    {
      isCard: true,
      title: '外部链接',
      children: [
        {
          component: CommonMixChart,
          col: 24,
          props: {
            options: ['pv', 'uv', 'uvNew', 'visits'],
            chartProps: [
              {
                col: 6,

                type: 'pie',
                dims: 'channel_link',
                fields: 'pv',
                pageCount: 9,
                orderDesc: true,
                noLegendSelect: true,
                orderColumn: 'pv',
                showAll: true,
              },
              {
                col: 18,
                lineType: 'area',
                dims: 'date,channel_link',
                fields: 'pv',
                pageCount: 10,
                orderDesc: true,
                orderColumn: 'pv',
                showAll: true,
                ignoreCache: true,
              },
            ],
          },
        },
      ],
    },
    {
      isCard: true,
      component: ChannelLinkTable,
      props: {
        dims: 'channel_link',
        currentPage: 1,
        orderDesc: true,
        pageCount: 10,
        orderColumn: 'pv',
        expandedRowRender: true,
      },
    },
  ],
};
