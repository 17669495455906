import { action, computed, observable, when } from 'mobx';
import { getInitialItemInfo, getGlobalControlInitialValues } from './util';
import api from '../../api';
import util from '../../util';

class Store {
  @observable currentItemsInfo = {}; //使用到的widgets配置列表
  @observable formedViews = {}; //使用到的views配置列表
  @observable viewsData = []; //views原数据
  @observable isLinkingWidget = {};

  @action.bound setIsLinkingWidget(id, linking) {
    this.isLinkingWidget = { ...this.isLinkingWidget, [id]: linking };
  }

  @action.bound itemsInit(widgets) {
    let currentItemsInfo = this.currentItemsInfo || {};
    widgets.forEach((item) => {
      //遍历报告板卡片列表
      if (!currentItemsInfo[item.id] && item.config) {
        const initialItemInfo = getInitialItemInfo(item, this.formedViews);
        currentItemsInfo[item.id] = initialItemInfo;
      }
    });
    this.currentItemsInfo = currentItemsInfo;
  }
  @action.bound itemsReset() {
    this.currentItemsInfo = {};
  }
  @action.bound setItemsInfo(currentItemsInfo, id) {
    id ? (this.currentItemsInfo[id] = currentItemsInfo) : (this.currentItemsInfo = { ...currentItemsInfo });
  }
  @action.bound addWidget;
  @action.bound viewsInit(views, widgets) {
    let viewIds = [];
    this.viewsData = views;
    widgets.forEach((item) => {
      viewIds.indexOf(item.viewId) < 0 && viewIds.push(item.viewId);
      item.config && item.config.length && (item.config = JSON.parse(item.config));
    });
    this.itemsInit(widgets);
    if (views) {
      let formedViews = this.formedViews;
      views.forEach((view) => {
        if (viewIds.includes(view.id)) {
          formedViews[view.id] = {
            ...view,
            model: JSON.parse(view.model || '{}'),
            variable: JSON.parse(view.variable || '[]'),
          };
        }
      });
      this.formedViews = formedViews;
      const oldViews = ['action', 'answer'];
      Object.keys(formedViews).map((key) => {
        !oldViews.includes(key) && this.getViewDetail(formedViews[key].id);
      });
    }
  }

  @action.bound getViewDetail(id) {
    util.get(`${api.BI.views}/${id}`, {}, (res) => {
      const model = {};
      res.fieldModelList.map((item) => {
        model[item.fieldName] = item;
      });
      res.model = model;
      // const viewDetails = {
      //   ...this.state.viewDetails,
      //   [id]: res,
      // };
      const formedViews = this.formedViews;
      formedViews[id] = {
        ...formedViews[id],
        ...res,
      };
      this.formedViews = formedViews;
    });
  }

  @action.bound addFormedViews(form) {
    this.formedViews = form;
  }

  @action.bound cleanFormedViews() {
    this.formedViews = {};
  }
}

export default new Store();
