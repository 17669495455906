import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, message } from 'antd';
import UXSDrawer from '../../../component/UXSDrawer';
import util from '../../../util';
import api from '../../../api';
import SurveyDetail from './SurveyDetail';
import CleanRuleDetail from './CleanRuleDetail';
import DashboardDetail from './DashboardDetail';

function AuditModal(props) {
  const { show, onHide, form, auditInfo, fetchData, deleteData } = props;
  const { editId, name, auditType, submitType } = auditInfo;
  const { getFieldDecorator } = form;
  const [auditorList, setAuditorList] = useState([]);

  useEffect(() => {
    util.get(
      api.audit.account,
      {
        auditType,
      },
      (res) => {
        res && Array.isArray(res) && setAuditorList(res);
      }
    );
  }, []);

  function submit(e) {
    e.preventDefault();
    form.validateFields((error, values) => {
      if (!error) {
        if (submitType !== 'delete') {
          const param = {
            name,
            auditType,
            sourceIds: editId,
            ...values,
          };
          util.post(api.audit.save, param, (res) => {
            res.code === 200 && (onHide(), fetchData(), message.success('提交审核成功'));
          });
        } else {
          deleteData(editId, values);
        }
      }
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  return (
    <UXSDrawer
      className="full-right scroll-drawer"
      title={
        <div>
          <div>
            提交
            {submitType === 'delete' ? '删除' : '审核'}
            确认
          </div>
          <Button type="primary" onClick={submit}>
            确认
          </Button>
        </div>
      }
      show={show}
      onClose={onHide}
      width={800}>
      {auditType === 1 && <DashboardDetail editInfo={auditInfo} type="apply" />}
      {auditType === 3 && <SurveyDetail editInfo={auditInfo} type="apply" />}
      {auditType === 5 && <CleanRuleDetail editInfo={auditInfo} type="apply" />}
      <Form {...formItemLayout}>
        <Form.Item label="审核人">
          {getFieldDecorator('auditor', {
            rules: [{ required: true, message: '请选择审核人' }],
            initialValue: null,
          })(
            <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} className="lg-select">
              {auditorList.map((item) => {
                return <Select.Option key={item.id}>{`${item.username}(${item.real_name ? item.real_name : item.username})`}</Select.Option>;
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="描述">
          {getFieldDecorator('description', {
            rules: [],
            initialValue: '',
          })(<Input.TextArea rows={4} className="lg-input" />)}
        </Form.Item>
      </Form>
    </UXSDrawer>
  );
}

export default Form.create()(AuditModal);
