import React, { useEffect, useState, Fragment } from 'react';
import api from '../../api';
import { Popconfirm, message } from 'antd';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import Loading from '../../component/Loading/Loading';
import CommonTable from '../../containers/CommonTable';
import util from '../../util';
import intl from 'react-intl-universal';
import TablePageStore from '../../containers/CommonTable/TablePageStore';
import moment from 'moment';
import DetailModal from './Modal/detail';
import { auditStatus, auditStatusClassName } from '../../config';
import AuthorityStore from '../../AuthorityStore/store';

function Audit(props) {
  const [showDetailModal, setShowDetailModal] = useState(false); // 是否显示触点弹窗
  const [loading, setLoading] = useState(true); // 是否加载状态
  const [data, setData] = useState([]); // 审批列表
  const [editInfo, setEditInfo] = useState({}); // 编辑信息

  const auditTypes = {
    1: '报告板',
    2: '触点',
    3: '调研',
    4: '问卷',
    5: '清洗规则',
  };

  useEffect(() => {
    pageHeaderStore.hidePageHeader();
    fetchData();
  }, []);

  function fetchData(orderDesc, orderColumn, currentPage, pageCount) {
    setLoading(true);
    util.get(
      api.audit.list,
      {
        currentPage: TablePageStore.config.currentPage || currentPage || 1,
        pageCount: TablePageStore.config.pageCount || pageCount || 10,
      },
      (res) => {
        res && setData(res);
        setLoading(false);
      }
    );
  }
  function detail(id, auditType, auditStatus) {
    setEditInfo({
      editId: id,
      auditType,
      auditStatus,
    });
    setShowDetailModal(true);
  }
  function reject(id) {
    util.post(
      api.audit.reject,
      {
        id,
      },
      (res) => {
        res.code === 200 ? (message.success('已拒绝'), fetchData()) : message.error(res.msg);
      }
    );
    if (editInfo.editId) {
      hideModal(editInfo.auditType);
    }
  }
  function pass(id) {
    util.post(
      api.audit.pass,
      {
        id,
      },
      (res) => {
        res.code === 200 ? (message.success('已通过'), fetchData()) : message.error(res.msg);
      }
    );
    if (editInfo.editId) {
      hideModal(editInfo.auditType);
    }
  }
  function hideModal(auditType) {
    setShowDetailModal(false);
    setEditInfo({});
  }
  function addContent(store) {
    for (let i = 0; i < store.columns.length; i++) {
      const currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'operate') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: (
              <div className="operateGroup">
                <span
                  className="operateDom-update"
                  onClick={() => {
                    detail(parseInt(record.id.replaceAll(',', '')), record.auditType, record.auditStatus);
                  }}>
                  查看
                </span>
              </div>
            ),
          };
        };
      } else if (currentRowData === 'auditType') {
        store.columns[i].render = (text, record, index) => {
          return auditTypes[text];
        };
      } else if (currentRowData === 'auditStatus') {
        store.columns[i].render = (text, record, index) => {
          return (
            <div className="audit-status-content">
              <div className={`status-circle ${auditStatusClassName[text] || 'orange'}`} />
              <div>{auditStatus[text]}</div>
            </div>
          );
        };
      } else if (currentRowData === 'createdByName') {
        store.columns[i].render = (text, record, index) => {
          const textArr = text.split('(');
          return (
            <Fragment>
              <div>{textArr[0]}</div>
              {textArr[1] ? <div>({textArr[1]}</div> : null}
            </Fragment>
          );
        };
      }
    }
  }

  return (
    <Fragment>
      {/* {!type && <SearchHeader defaultValue={keyword} title="+ 新建触点" onSearch={setKeyword} onClick={() => modalToggle(true)} />}
      {showModal && <Modal show={showModal} onHide={() => modalToggle(false)} fetchData={fetchData} editInfo={editInfo} type={type} />} */}
      {!loading ? (
        <CommonTable
          fetchData={fetchData}
          parentData={data}
          addContent={addContent}
          tableHeadData={[
            ['名称', '描述', '审核类型', '申请时间', '申请人', '审核人', '审核状态', '操作'],
            ['name', 'description', 'auditType', 'createdAt', 'createdByName', 'auditorName', 'auditStatus', 'operate', 'id', 'auditType'],
          ]}
          isSort={false}
        />
      ) : (
        <Loading />
      )}
      {showDetailModal && (
        <DetailModal
          show={showDetailModal}
          onHide={() => {
            hideModal('2');
            setEditInfo({});
          }}
          editInfo={editInfo}
          pass={pass}
          reject={reject}
        />
      )}
    </Fragment>
  );
}

export default Audit;
