import React from 'react';
import BodyCell from '../components/BodyCell';
import ContentCollapse from '../components/ContentCollapse';

function MobileContentTable(props) {
  const {
    cols,
    rows,
    metrics,
    fields,
    firstContent,
    secondContent,
    dataSource,
    id,
    cardRefreshWithoutConfig,
    data,
    queryVariables,
    pagination,
    requestParams,
  } = props;

  const content = firstContent.concat(secondContent);

  return dataSource.map((record, index) => {
    return (
      <div className="mobile-content-item" key={index}>
        <div className="fields-content">
          {fields.map((field) => {
            const { format } = field;
            const fieldName = field.randomName || field.name;
            return (
              <div>
                <BodyCell
                  config={field}
                  format={format}
                  record={record}
                  cardId={id}
                  cardRefreshWithoutConfig={cardRefreshWithoutConfig}
                  cellVal={record[fieldName]}
                />
              </div>
            );
          })}
        </div>
        <ContentCollapse {...props} record={record} />
      </div>
    );
  });
}

export default MobileContentTable;
