import { PIVOT_CHART_FONT_FAMILIES, PIVOT_CHART_FONT_WEIGHTS, PIVOT_DEFAULT_FONT_COLOR, DEFAULT_FONT_STYLE } from '../constants';

export const DEFAULT_TABLE_FIXED_WIDTH = 100;

export const DefaultTableCellStyle = {
  fontSize: '12',
  fontFamily: PIVOT_CHART_FONT_FAMILIES[0].value,
  fontWeight: PIVOT_CHART_FONT_WEIGHTS[0],
  fontColor: PIVOT_DEFAULT_FONT_COLOR,
  fontStyle: DEFAULT_FONT_STYLE,
  backgroundColor: 'transparent',
  justifyContent: 'flex-start',
  inflexible: false,
  width: DEFAULT_TABLE_FIXED_WIDTH,
};
export const FieldSortTypes = {
  Default: 'default',
  Asc: 'asc',
  Desc: 'desc',
  Custom: 'custom',
};

export const MapAntSortOrder = {
  ascend: FieldSortTypes.Asc,
  descend: FieldSortTypes.Desc,
};
