import React, { Component } from 'react';
import './index.sass';
import { Link } from 'react-router-dom';

import { observable } from 'mobx';

class Main extends Component {
  @observable isLoading = true;
  @observable data = null;

  render() {
    const { link } = this.props;
    return (
      <div className={this.props.className}>
        {
          <ul className="uxs-breadcrumb">
            {this.props.data.map((item, index) =>
              index !== this.props.data.length - 1 ? (
                !link ? (
                  <li
                    onClick={() => {
                      window.history.go(-1);
                    }}
                    key={index}>
                    {item.name}
                  </li>
                ) : (
                  <li key={index}>
                    <Link to={link}>{item.name}</Link>
                  </li>
                )
              ) : (
                <li key={index}>
                  <span>{item.name}</span>
                </li>
              )
            )}
          </ul>
        }
      </div>
    );
  }
}

export default Main;

// 参数：
// data：显示的文字列表，格式：[{name: 'xxx'}, {name: 'xxx'}]
