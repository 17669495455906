import React, { FC, memo } from 'react';
import { Checkbox, Input } from 'antd';
import '../../../../../static/less/util.less';
import styles from '../../Control.less';

const GlobalControlRelatedItemForm = ({ form, relatedItems, onItemCheck, onCheckAll }) => {
  const { getFieldDecorator } = form;
  const checkAll = relatedItems.every((item) => item.checked);

  return (
    <div className={'itemContainer'}>
      <div className={'title'}>
        <h2>关联图表</h2>
        <Checkbox className={'checkAll action'} checked={checkAll} onChange={onCheckAll}>
          全选
        </Checkbox>
      </div>
      <ul>
        {relatedItems.map(({ id, name }) => (
          <li key={id}>
            {getFieldDecorator(`relatedItems[${id}].viewId`, {})(<Input className={'hide'} />)}
            {getFieldDecorator(`relatedItems[${id}].type`, {})(<Input className={'hide'} />)}
            {/* {getFieldDecorator(`relatedItems[${id}].id`, {})(<Input className={'hide'} />)} */}
            {getFieldDecorator(`relatedItems[${id}].checked`, {
              valuePropName: 'checked',
            })(
              <Checkbox className={'checkbox'} onChange={onItemCheck(id)}>
                {name}
              </Checkbox>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(GlobalControlRelatedItemForm);
