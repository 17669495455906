import React, { Fragment, useState, useEffect } from 'react';
import { Select, Button, Input } from 'antd';
import api from '../../../api';
import intl from 'react-intl-universal';
import util from '../../../util';

const warningRoleTypes = [
  { label: '所有预警', value: 1 },
  { label: '预警及用户属性对应', value: 2 },
  { label: '预警属性匹配', value: 3 },
];

const { Option } = Select;

function WarningUserGroupComponent(props) {
  const { onChange, warningUserGroups } = props;
  const [userGroups, setUserGroups] = useState(null); //用户组
  const [warningProperties, setWarningProperties] = useState(null); // 预警属性
  const [userProperties, setUserProperties] = useState(null); // 用户属性

  useEffect(() => {
    util.get(api.dataTag.tagOptions, { optionType: 'tag' }, (res) => {
      if (res) {
        setUserGroups(res);
      }
    });
  }, []);

  useEffect(() => {
    util.get(api.dataTag.tagOptions, { optionType: 'user' }, (res) => {
      if (res) {
        setUserProperties(res);
      }
    });
  }, []);
  useEffect(() => {
    util.get(api.dataTag.tagOptions, { optionType: 'warn' }, (res) => {
      if (res) {
        setWarningProperties(res);
      }
    });
  }, []);

  function addChildrenFilter() {
    triggerChange({ dataTag_id: '' }, warningUserGroups ? warningUserGroups.length : 0);
  }
  function deleteFilter(filterIndex) {
    warningUserGroups.splice(filterIndex, 1);
    onChange(warningUserGroups);
  }
  function handleDataTagChange(dataTag_id, index) {
    warningUserGroups[index] = { dataTag_id, warning_role_type: 1 };
    onChange(warningUserGroups);
  }
  function handleFilterTypeChange(warning_role_type, index) {
    warningUserGroups[index] = { warning_role_type, dataTag_id: warningUserGroups[index].dataTag_id };
    onChange(warningUserGroups);
  }
  function handleSourceChange(source_mate, index) {
    triggerChange({ source_mate }, index);
  }
  function handleTargetChange(target_mate, index) {
    triggerChange({ target_mate }, index);
  }
  function triggerChange(changedValue, index) {
    warningUserGroups[index] = warningUserGroups ? { ...warningUserGroups[index], ...changedValue } : { ...changedValue };
    onChange(warningUserGroups);
  }

  return (
    <Fragment>
      {warningUserGroups &&
        warningProperties &&
        userProperties &&
        warningUserGroups.map((item, index) => {
          return (
            <div className="form-item-children" style={{ marginBottom: 10 }}>
              <Select className="lg-select select-group" value={warningUserGroups[index].dataTag_id} onChange={(value) => handleDataTagChange(value, index)}>
                {userGroups &&
                  userGroups.map((el) => {
                    return <Option value={el.column}>{el.columnName}</Option>;
                  })}
              </Select>
              <Select
                className="lg-select select-group"
                value={warningUserGroups[index].warning_role_type}
                onChange={(value) => handleFilterTypeChange(value, index)}>
                {warningRoleTypes &&
                  warningRoleTypes.map((el) => {
                    return <Option value={el.value}>{el.label}</Option>;
                  })}
              </Select>
              {index !== 0 && (
                <i
                  className="iconfont icon-shanchu condition-delete"
                  onClick={() => {
                    deleteFilter(index);
                  }}
                />
              )}
              {warningUserGroups[index].warning_role_type === 2 && (
                <div>
                  <span>预警属性</span>
                  <Select
                    label="预警属性"
                    placeholder="预警属性"
                    className="md-select select-group"
                    value={warningUserGroups[index].source_mate}
                    onChange={(value) => handleSourceChange(value, index)}>
                    {warningProperties &&
                      warningProperties.map((el) => {
                        return <Option value={el.column}>{el.columnName}</Option>;
                      })}
                  </Select>
                  <span>匹配用户属性</span>
                  <Select
                    placeholder="匹配用户属性"
                    className="md-select select-group"
                    value={warningUserGroups[index].target_mate}
                    onChange={(value) => handleTargetChange(value, index)}>
                    {userProperties &&
                      userProperties.map((el) => {
                        return <Option value={el.column}>{el.columnName}</Option>;
                      })}
                  </Select>
                </div>
              )}
              {warningUserGroups[index].warning_role_type === 3 && (
                <div>
                  <span>预警属性</span>
                  <Select
                    placeholder="预警属性"
                    className="md-select select-group"
                    value={warningUserGroups[index].source_mate}
                    onChange={(value) => handleSourceChange(value, index)}>
                    {warningProperties &&
                      warningProperties.map((el) => {
                        return <Option value={el.column}>{el.columnName}</Option>;
                      })}
                  </Select>
                  <span>等于</span>
                  <Input
                    className="md-input"
                    value={warningUserGroups[index].target_mate}
                    onChange={(e) => handleTargetChange(e.target.value, index)}
                    row={1}
                  />
                </div>
              )}
            </div>
          );
        })}
      <Button type="addButton" onClick={addChildrenFilter}>
        +{intl.get('Tianjia')}预警账号
      </Button>
    </Fragment>
  );
}

export { WarningUserGroupComponent };
