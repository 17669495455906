import configChannel from '../views/BoardTemplate/configChannel';
import configChannelAd from '../views/BoardTemplate/configChannelAd';
import configChannelKeyword from '../views/BoardTemplate/configChannelKeyword';
import configChannelLink from '../views/BoardTemplate/configChannelLink';
import configChannelLinkDetail from '../views/BoardTemplate/configChannelLinkDetail';
import configChannelSearch from '../views/BoardTemplate/configChannelSearch';
import configChannelScene from '../views/BoardTemplate/configChannelScene';
import configChannelShare from '../views/BoardTemplate/configChannelShare';
import configEvent from '../views/BoardTemplate/configEvent';
import configEventDetail from '../views/BoardTemplate/configEventDetail';
import configPage from '../views/BoardTemplate/configPage';
import configPageDetail from '../views/BoardTemplate/configPageDetail';
import configPagesetDetail from '../views/BoardTemplate/configPagesetDetail';
import configSurvey from '../views/BoardTemplate/configSurvey';
import configSurveyDetail from '../views/BoardTemplate/configSurveyDetail';
import configAudit from '../views/BoardTemplate/configAudit';
import configVisitDate from '../views/BoardTemplate/configVisitDate';
import configVisitDistrict from '../views/BoardTemplate/configVisitDistrict';
import configVisitor from '../views/BoardTemplate/configVisitor';
import configVisitorInfo from '../views/BoardTemplate/configVisitorInfo';
import configVisitTerminal from '../views/BoardTemplate/configVisitTerminal';
import configVisitTerminalDetail from '../views/BoardTemplate/configVisitTerminalDetail';
import configPageGroup from '../views/BoardTemplate/configPageGroup';
import configPageGroupDetail from '../views/BoardTemplate/configPageGroupDetail';
import configPageGroupsetDetail from '../views/BoardTemplate/configPageGroupsetDetail';
import configAnalyseBoard from '../views/BoardTemplate/configAnalyseBoard';
import configAnalyseRemain from '../views/BoardTemplate/configAnalyseRemain';
import configAnalysePath from '../views/BoardTemplate/configAnalysePath';
import configCustomParameters from '../views/BoardTemplate/configCustomParameters';
import configAnalyseDistribution from '../views/BoardTemplate/configAnalyseDistribution';
import configAnalysePathDetail from '../views/BoardTemplate/configAnalysePathDetail';
import configAnalyseRemainDetail from '../views/BoardTemplate/configAnalyseRemainDetail';
import configAnalyseDistributionDetail from '../views/BoardTemplate/configAnalyseDistributionDetail';
import configAnalyseBI from '../views/BoardTemplate/configAnalyseBI';
import configWenjuan from '../views/BoardTemplate/configWenjuan';
import configWenjuanDownload from '../views/BoardTemplate/configWenjuanDownload';
import configSubscription from '../views/BoardTemplate/configSubscription';
import configShare from '../views/BoardTemplate/configShare';
import configAnalyseCombination from '../views/BoardTemplate/configAnalyseCombination';
import configAnalyseCombinationDetail from '../views/BoardTemplate/configAnalyseCombinationDetail';
import configWarningUserGroup from '../views/BoardTemplate/configWarningUserGroup';

export const BDPermissions = {
  sams: [
    'feedbackData',
    'survey',
    'analyticsTools',
    'surveyAnalytics',
    'newInsight',
    'customBoard',
    'customBoard',
    'shareBoard',
    'action',
    'warningRule',
    'warningLog',
    'warningClosedLoop',
    'productManagement',
    'product',
    'setting',
    'functionSetting',
    'pushSetting',
    'applicationSetting',
    'templateSetting',
    'userCenter',
    'person',
    'account',
    'role',
    'userGroup',
    'eventAnalytics',
    'analyticsChart',
    'analyticsList',
    'pathAnalytics',
    'parameterSetting',
    'exclusionSetting',
    'BIAnalytics',
    'subscription',
    'warningUserGroup',
    'questionnaireList',
    'warningDashboard',
    'audit',
    'data',
    'push',
    'permission',
    'dataCleanRule',
    'modelAnalytics',
    'rwaAnalytics',
    'workview',
    'blacklist',
    // 'indicators',
    // 'indicatorsSystem',
  ],
  rainbow: [
    'feedbackData',
    'indicatorsSystem',
    'survey',
    'feedbackGroup',
    'workview',
    'indicators',
    'analyticsList',
    'analyticsTools',
    'analyticsChart',
    'BIAnalytics',
    'combinationAnalytics',
    'modelAnalytics',
    'algorithmAnalytics',
    'rwaAnalytics',
    'action',
    'warningClosedLoop',
    'warningRule',
    'myWarning',
    'customBoard',
    'productManagement',
    'product',
    'setting',
    'pushSetting',
    'templateSetting',
    'account',
    'accountGroup',
    'role',
    'permission',
    'dataCleanRule',
    'blacklist',
    'blacklistRule',
    'userCenter',
    'person',
    'audit',
    'person',
    'survey',
    'data',
    'parameterSetting',
  ],
};
const normalMenus = {
  boardData: [
    {
      key: 'data',
      limitKey: 'userData',
      name: '用户是谁',
      name1: 'User',
      iconName: 'icon-yonghu',
      children: [
        {
          key: 'visitor',
          limitKey: 'visitorInfo',
          href: '/data/visitor/info',
          name: '访客分析',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('visitorInfo') > -1,
          template: configVisitDate,
        },
        {
          key: 'visitor',
          href: '/data/visitor/record/info',
          name: '访客档案',
          show: false,
          template: configVisitorInfo,
        },
        {
          key: 'visitor',
          limitKey: 'visitorRecord',
          href: '/data/visitor/record',
          name: '访客日志',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('visitorRecord') > -1,
          template: configVisitor,
        },

        {
          key: 'visitor',
          limitKey: 'visitorDistrict',
          href: '/data/visitor/district',
          name: '地域分布',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('visitorDistrict') > -1,
          template: configVisitDistrict,
        },
        {
          key: 'visitor',
          limitKey: 'visitorTerminal',
          href: '/data/visitor/terminal',
          name: '终端详情',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('visitorTerminal') > -1,
          template: configVisitTerminal,
        },
        {
          key: 'visitor',
          href: '/data/visitor/terminal/detail',
          name: '终端详情',
          show: false,
          template: configVisitTerminalDetail,
        },
      ],
    },
    {
      key: 'data',
      limitKey: 'channelData',
      name: '从哪些渠道来',
      name1: 'Channel',
      iconName: 'icon-qudao',
      children: [
        {
          key: 'channel',
          limitKey: 'channelAll',
          href: '/data/channel/all',
          name: '全部渠道',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelAll') > -1,
          template: configChannel,
          type: 'web',
        },
        {
          key: 'channel',
          limitKey: 'channelLink',
          href: '/data/channel/link',
          name: '外部链接',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelLink') > -1,
          template: configChannelLink,
          type: 'web',
        },
        {
          key: 'channel',
          href: '/data/channel/link/detail',
          name: '外部链接',
          show: false,
          template: configChannelLinkDetail,
          type: 'web',
        },
        {
          key: 'channel',
          limitKey: 'channelKeyword',
          href: '/data/channel/keyword',
          name: '搜索词',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelKeyword') > -1,
          template: configChannelKeyword,
          type: 'web',
        },
        {
          key: 'channel',
          limitKey: 'channelSearch',
          href: '/data/channel/search',
          name: '搜索引擎',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelSearch') > -1,
          template: configChannelSearch,
          type: 'web',
        },
        {
          key: 'channel',
          limitKey: 'channelAd',
          href: '/data/channel/ad',
          name: '广告',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelAd') > -1,
          template: configChannelAd,
        },
        {
          key: 'channel',
          limitKey: 'channelScene',
          href: '/data/channel/scene',
          name: '场景',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelScene') > -1,
          template: configChannelScene,
          type: 'mpwechat',
        },
        {
          key: 'channel',
          limitKey: 'channelShare',
          href: '/data/channel/share',
          name: '分享',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('channelShare') > -1,
          template: configChannelShare,
          type: 'mpwechat',
        },
      ],
    },
    {
      key: 'data',
      limitKey: 'productData',
      name: '如何使用产品',
      name1: 'Page',
      iconName: 'icon-hangwei',
      children: [
        {
          key: 'page',
          limitKey: 'dataPage',
          href: '/data/page',
          name: '页面',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('dataPage') > -1,
          template: configPage,
        },
        {
          key: 'page',
          href: '/data/page/detail',
          name: '页面',
          show: false,
          template: configPageDetail,
        },
        {
          key: 'page',
          href: '/data/pageset/detail',
          name: '页面',
          show: false,
          template: configPagesetDetail,
        },
        {
          key: 'page',
          limitKey: 'pageGroup',
          href: '/data/pageGroup',
          name: '页面组',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('pageGroup') > -1,
          template: configPageGroup,
        },
        {
          key: 'page',
          href: '/data/pageGroup/detail',
          name: '页面组',
          show: false,
          template: configPageGroupDetail,
        },
        {
          key: 'page',
          href: '/data/pageGroupset/detail',
          name: '页面组',
          show: false,
          template: configPageGroupsetDetail,
        },
      ],
    },
    {
      key: 'data',
      limitKey: 'analyticsData',
      name: '使用效果如何',
      name1: 'Analysis',
      iconName: 'icon-xiaoguoruhe',
      children: [
        {
          key: 'event',
          limitKey: 'event',
          href: '/data/event',
          name: '事件',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('event') > -1,
          template: configEvent,
        },
        {
          key: 'event',
          href: '/data/event/detail',
          name: '事件',
          show: false,
          template: configEventDetail,
        },
      ],
    },
    {
      key: 'data',
      limitKey: 'feedbackData',
      name: '反馈是怎么样',
      name1: 'Feedback',
      iconName: 'icon-fankui',
      children: [
        // {
        //   key: 'contact',
        //   limitKey: 'contact',
        //   href: '/data/contact',
        //   name: '触点总览',
        //   show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('contact') > -1,
        //   iconName: 'icon-chudianzonglan',
        // },
        // {
        //   key: 'contact',
        //   limitKey: 'contact',
        //   href: '/data/contact/detail',
        //   name: '触点总览',
        //   show: false,
        // },
        {
          key: 'survey',
          limitKey: 'survey',
          href: '/data/survey',
          name: '调研管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('survey') > -1,
          template: configSurvey,
        },
        {
          key: 'survey',
          href: '/data/survey/detail',
          name: '调研管理',
          show: false,
          template: configSurveyDetail,
        },
        {
          key: 'survey',
          href: '/data/survey/detail/visitor',
          name: '调研管理',
          show: false,
          template: configVisitorInfo,
        },
        {
          key: 'feedbackGroup',
          href: '/data/surveySetting',
          name: '调研流量分配',
          show: true,
        },
        {
          key: 'feedbackGroup',
          href: '/data/surveySetting/detail',
          name: '调研流量分配',
          show: false,
        },
        {
          key: 'indicatorsSystem',
          limitKey: 'indicatorsSystem',
          href: '/data/indicatorsSystem',
          name: '指标体系',
          show: process.env.REACT_APP_BDkey
            ? false
            : !BDPermissions[process.env.REACT_APP_BDkey]
            ? true
            : BDPermissions[process.env.REACT_APP_BDkey].indexOf('indicatorsSystem') > -1,
        },
      ],
    },
    {
      key: 'data',
      limitKey: 'feedbackData',
      name: '工作表',
      name1: '工作',
      iconName: 'icon-shujumokuai',
      children: [
        {
          key: 'workview',
          limitKey: 'workview',
          href: '/data/worksheet',
          name: 'Worksheet',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('workview') > -1,
          iconName: 'icon-chudianzonglan',
        },
        {
          key: 'indicators',
          limitKey: 'indicators',
          href: '/data/targetManage',
          name: '自定义字段管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('indicators') > -1,
        },
      ],
    },
  ],
  boardAnalyse: [
    {
      key: 'analytics',
      limitKey: 'analyticsList',
      name: '分析列表',
      name1: 'List',
      iconName: 'icon-fenxiliebiao',
      children: [
        {
          key: 'chart',
          limitKey: 'analyticsChart',
          href: '/analytics/chart',
          name: '分析图表',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('analyticsChart') > -1,
          template: configAnalyseBoard,
        },
      ],
    },
    {
      key: 'analytics',
      limitKey: 'analyticsTools',
      name: '分析工具',
      name1: 'Tools',
      iconName: 'icon-rukoufenxi',
      children: [
        {
          key: 'BIAnalytics',
          limitKey: 'BIAnalytics',
          href: '/analytics/BIAnalytics',
          name: '数据分析',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('BIAnalytics') > -1,
          template: configAnalyseBI,
        },
        {
          key: 'pathAnalytics',
          limitKey: 'pathAnalytics',
          href: '/analytics/pathAnalytics',
          name: '关键任务',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('pathAnalytics') > -1,
          template: configAnalysePath,
        },
        {
          key: 'pathAnalytics',
          href: '/analytics/pathAnalytics/detail',
          name: '关键任务',
          show: false,
          template: configAnalysePathDetail,
        },
        {
          key: 'remainAnalytics',
          limitKey: 'remainAnalytics',
          href: '/analytics/remainAnalytics',
          name: '留存分析',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('remainAnalytics') > -1,
          template: configAnalyseRemain,
        },
        {
          key: 'remainAnalytics',
          href: '/analytics/remainAnalytics/detail',
          name: '留存分析',
          show: false,
          template: configAnalyseRemainDetail,
        },
        // { href: '/analytics/funnel', name: '漏斗分析', show: true, template: configAnalyseFunnel },
        // {
        //   key: 'funnelAnalytics',
        //   href: '/analytics/funnelAnalytics',
        //   name: '漏斗分析',
        //   show: true,
        //   template: configFunnel,
        // },
        // {
        //   key: 'funnelAnalytics',
        //   href: '/analytics/funnelAnalytics/detail',
        //   name: '漏斗分析',
        //   show: false,
        //   template: configFunnelDetail,
        // },
        // {
        //   key: 'surveyAnalytics',
        //   limitKey: 'surveyAnalytics',
        //   href: '/analytics/surveyAnalytics',
        //   name: '调研分析',
        //   show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('surveyAnalytics') > -1,
        //   template: configAnalyseSurvey,
        // },
        // {
        //   key: 'surveyAnalytics',
        //   href: '/analytics/surveyAnalytics/detail',
        //   name: '调研分析',
        //   show: false,
        //   template: configAnalyseSurveyDetail,
        // },
        {
          key: 'distributionAnalytics',
          limitKey: 'distributionAnalytics',
          href: '/analytics/distributionAnalytics',
          name: '分布分析',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('distributionAnalytics') > -1,
          template: configAnalyseDistribution,
        },
        {
          key: 'distributionAnalytics',
          href: '/analytics/distributionAnalytics/detail',
          name: '分布分析',
          show: false,
          template: configAnalyseDistributionDetail,
        },
        {
          key: 'combine',
          limitKey: 'combinationAnalytics',
          href: '/analytics/combinationAnalytics',
          name: '组合分析',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('combinationAnalytics') > -1,
          template: configAnalyseCombination,
        },
        {
          key: 'combine',
          href: '/analytics/combinationAnalytics/detail',
          name: '组合分析',
          show: false,
          template: configAnalyseCombinationDetail,
        },
        {
          key: 'model',
          limitKey: 'modelAnalytics',
          href: '/analytics/model',
          name: 'BusinessModel',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('modelAnalytics') > -1,
        },
        {
          key: 'algorithm',
          limitKey: 'algorithmAnalytics',
          href: '/analytics/algorithmAnalytics',
          name: '算法模型',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('algorithmAnalytics') > -1,
        },
        {
          key: 'algorithm',
          href: '/analytics/algorithmAnalytics/detail',
          name: '算法模型',
          show: false,
        },
        {
          key: 'rwa',
          limitKey: 'rwaAnalytics',
          href: '/analytics/RWA',
          name: 'RWA',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('rwaAnalytics') > -1,
        },
      ],
    },
  ],
  boardInsight: [
    {
      key: 'insight',
      limitKey: 'customBoard',
      name: '自建看板',
      name1: 'customBoard',
      iconName: 'icon-guanjianrenwu',
      editable: true,
      children: [],
    },
  ],
  boardAction: [
    {
      key: 'action',
      limitKey: 'action',
      name: '行动',
      name1: '行动',
      iconName: 'icon-hangdong',
      children: [
        {
          key: 'warningLog',
          limitKey: 'warningLog',
          href: '/action/warningLog',
          name: '预警列表',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('warningLog') > -1,
        },
        {
          key: 'warningClosedLoop',
          limitKey: 'warningClosedLoop',
          href: '/action/warningClosedLoop',
          name: '预警闭环',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('warningClosedLoop') > -1,
        },
        {
          key: 'myWarning',
          limitKey: 'myWarning',
          href: '/action/myWarningList',
          name: '我的预警',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('myWarning') > -1,
        },
        {
          key: 'warningLog',
          limitKey: 'warningLog',
          href: '/action/warningLog/detail',
          name: '预警详情',
          show: false,
          template: configVisitorInfo,
        },
        {
          key: 'warning',
          limitKey: 'warningRule',
          href: '/action/warningRule',
          name: '预警规则',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('warningRule') > -1,
        },
        {
          key: 'warningUserGroup',
          limitKey: 'warningUserGroup',
          href: '/action/warningUserGroup',
          name: '预警用户组',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('warningUserGroup') > -1,
          template: configWarningUserGroup,
        },
        {
          key: 'subscription',
          limitKey: 'subscription',
          href: '/action/subscription',
          name: '订阅列表',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('customBoard') > -1,
          template: configSubscription,
        },
      ],
    },
  ],
  setting: [
    {
      name: '产品管理',
      key: 'set',
      limitKey: 'productManagement',
      name1: 'Management',
      iconName: 'icon-chanpinguanli',
      children: [
        {
          key: 'product',
          limitKey: 'product',
          href: '/product',
          name: '产品列表',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('product') > -1,
        },
      ],
    },
    {
      href: '',
      key: 'set',
      limitKey: 'setting',
      management: 'functionSetting',
      name: '功能设置',
      iconName: 'icon-gongnengshezhi',
      name1: 'Setting',
      children: [
        {
          key: 'pageGroup',
          limitKey: 'pageGroupSetting',
          href: '/set/pageGroup',
          name: '页面组',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('pageGroupSetting') > -1,
        },
        {
          key: 'parameter',
          limitKey: 'parameterSetting',
          href: '/set/parameter',
          name: '参数管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('parameterSetting') > -1,
        },
        {
          key: 'SDK',
          limitKey: 'pointsSetting',
          href: '/set/points',
          name: '埋点管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('pointsSetting') > -1,
        },
        {
          key: 'app',
          limitKey: 'pushSetting',
          href: '/set/push',
          name: '应用中心',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('pushSetting') > -1,
        },
        {
          key: 'template',
          limitKey: 'templateSetting',
          href: '/set/template',
          name: '模板管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('templateSetting') > -1,
        },
        {
          key: 'attribute',
          href: '/set/attribute/create',
          name: '自定义属性',
          show: false,
          template: configCustomParameters,
        },
        {
          key: 'account',
          limitKey: 'account',
          href: '/set/account',
          name: '账号管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('account') > -1,
        },
        {
          key: 'set',
          limitKey: 'accountGroup',
          href: '/set/account/group',
          iconName: 'icon-zu',
          name: '账号组管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('accountGroup') > -1,
        },
        {
          key: 'role',
          limitKey: 'role',
          href: '/set/role',
          name: '角色管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('role') > -1,
        },
        {
          key: 'permission',
          limitKey: 'permission',
          href: '/set/permissions',
          name: '权限管理',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('permission') > -1,
        },
        {
          key: 'excludeSurvey',
          limitKey: 'dataCleanRule',
          href: '/set/dataCleanRule',
          name: '数据过滤规则',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('dataCleanRule') > -1,
        },
        //     {
        //       href: '/set/exclusion',
        //       name: '排除规则',
        //       show: true,
        //     },
        //     {
        //       href: '/set/page',
        //       name: '页面集',
        //       show: true,
        //     },
        {
          key: 'blacklist',
          href: '/set/blacklist',
          limitKey: 'blacklist',
          name: '黑名单',
          show: true,
        },
        {
          key: 'blacklistRule',
          href: '/set/blacklistRule',
          name: '黑名单规则',
          show: true,
        },
        {
          key: 'virtualField',
          href: '/set/virtualField',
          name: 'VirtualField',
          show: true,
        },
      ],
    },
  ],
  account: [
    {
      key: 'user',
      limitKey: 'userCenter',
      exact: true,
      name: '个人中心',
      name1: 'Personal',
      iconName: 'icon-gerenzhongxin',
      children: [
        {
          key: 'person',
          limitKey: 'person',
          href: '/person',
          name: '账号信息',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('person') > -1,
        },
        {
          key: 'person',
          limitKey: 'person',
          href: '/person/workbench',
          name: '个人工作台',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('person') > -1,
        },
        {
          key: 'audit',
          limitKey: 'audit',
          href: '/audit',
          name: '审核通知',
          iconName: 'icon-shenhetongzhi',
          show: true,
          template: configAudit,
        },
        {
          key: 'share',
          limitKey: 'person',
          href: '/share',
          name: '分享管理',
          iconName: 'icon-fenxiang3',
          show: true,
          template: configShare,
        },
      ],
    },
    {
      key: 'user',
      limitKey: 'userCenter',
      exact: true,
      name: '系统日志',
      name1: '系统',
      iconName: 'icon-shenhetongzhi',
      children: [
        {
          key: 'operationLog',
          limitKey: 'person',
          href: '/log',
          name: 'OperationLog',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('person') > -1,
        },
        {
          key: 'person',
          limitKey: 'person',
          href: '/notification',
          name: 'Notification',
          show: !BDPermissions[process.env.REACT_APP_BDkey] ? true : BDPermissions[process.env.REACT_APP_BDkey].indexOf('person') > -1,
        },
      ],
    },
  ],
  questionnaire: [
    {
      key: 'wj',
      limitKey: 'survey',
      href: '/wj/list',
      name: '问卷管理',
      show: true,
      iconName: 'icon-tiaoyan',
      template: configWenjuan,
    },
    // {
    //   key: 'wj',
    //   limitKey: 'survey',
    //   href: '/wj/detail',
    //   name: '问卷编辑',
    //   show: false,
    //   template: configWenjuanDetail,
    // },
    {
      key: 'wj',
      limitKey: 'data',
      href: '/wj/surveyDownload',
      name: '问卷数据',
      show: true,
      iconName: 'icon-piechart',
      template: configWenjuanDownload,
    },
  ],
};

export const unlimitKeys = ['person'];
export const onlyMainCountKeys = [];

const allMenus = normalMenus;
const allMenuTemplates = {};
const moduleMenuTemplates = {};
for (var k in allMenus) {
  moduleMenuTemplates[k] = [];
  allMenus[k].forEach((submenus) => {
    if (submenus.hasOwnProperty('children')) {
      submenus.children.forEach((submenu) => {
        allMenuTemplates[submenu.href] = submenu.template;
        moduleMenuTemplates[k].push(submenu.href);
      });
    } else {
      allMenuTemplates[submenus.href] = submenus.template;
      moduleMenuTemplates[k].push(submenus.href);
    }
  });
}
export function isTopMenuActivity(moduleKey, pathname) {
  let isMatched = false;
  if (moduleKey.indexOf('/') > -1) {
    isMatched = pathname.indexOf(moduleKey) === 0;
  } else {
    moduleMenuTemplates[moduleKey] &&
      moduleMenuTemplates[moduleKey].forEach((modulePath) => {
        if (pathname.indexOf(modulePath) === 0) {
          isMatched = true;
        }
      });
  }

  return isMatched;
}

export default allMenus;
