import React, { Component } from 'react';
import { observable, action, autorun, computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import NewSankey from '../../component/NewSankey/index';
import TableNullData from '../../component/TableNullData';
import util from '../../util';
import api from '../../api';
import pageUtil from '../../utils/pageUtil';
import Loading from '../../component/Loading/Loading';
import sankeyUtil from '../../utils/sankeyUtil';

@observer
class CommonSankey extends Component {
  @observable data;
  @observable isLoading = true;
  @observable pageCount;

  @computed get pageStep() {
    if (this.props.pageCount) {
      return this.props.pageCount;
    } else {
      return this.pageCount;
    }
  }
  // 格式化后台的数据
  @action.bound changePageStep(num) {
    this.pageCount = num;
    this.props.fetchData && this.props.fetchData(null, null, 1, num);
  }

  componentDidMount() {
    if (this.props.parentData) {
      this.isLoading = true;
      this.data = this.props.parentData.data ? sankeyUtil.formateSankeyData(this.props.parentData, this.props.hasChildrenStep) : null;

      this.isLoading = false;
    } else if (!this.props.hasOwnProperty('parentData')) {
      this.disposer = autorun(() => {
        this.isLoading = true;
        console.log(2);
        let fetchParams = pageUtil.getFetchParams(this.props, { pageCount: this.pageCount });
        util.get(api.pack.sankey, fetchParams, (res) => {
          this.data = res ? sankeyUtil.formateSankeyData(res) : null;
          this.isLoading = false;
        });
      });
    }
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  render() {
    return !this.isLoading ? (
      this.data ? (
        <NewSankey
          hasChildrenStep={this.props.hasChildrenStep}
          isNotShowOption={this.props.isNotShowOption}
          index={this.props.index}
          // fullHeight={true}
          dims={this.props.dims}
          nodeFields={this.props.nodeFields}
          data={this.data}
          changePageStep={this.changePageStep}
          pageStep={this.pageCount ? this.pageCount : this.pageStep}
          pathId={this.props.pathId}></NewSankey>
      ) : (
        <TableNullData nullDataType="analysePath" />
      )
    ) : (
      <Loading></Loading>
    );
  }
}
export default CommonSankey;
