import React, { Component } from 'react';
import { Calendar, Mask } from 'antd-mobile';
import mathUtil from '../../utils/mathUtil';
import { message, Tag } from 'antd';
import './index.sass';
import { selectParams } from '../../component/UXSRangePicker';
import intl from 'react-intl-universal';
import MobileArrows from '../MobileArrows';
import moment from 'moment';

class MobileCalander extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      current: 1,
      step: 3,
      showRange: [0, 3],
    };
    this.canFinish = false; // 点击两次后关闭日历
    this.calendar = React.createRef();
    this.chooseDate = null;
  }

  componentDidMount() {
    if (this.props.tagClick) {
      this.setState({
        show: true,
      });
    }
    this.initDate();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.tagClick && nextProps.tagClick) {
      this.setState({
        show: true,
      });
    }
    this.initDate();
  }

  initDate = () => {
    let { step } = this.state;
    const { lastDate } = this.props;
    let showRange = [0, 4];
    if (lastDate) {
      const current = selectParams.findIndex((i) => i.key === lastDate) + 1;
      if (current > step) {
        const page = Math.floor(current / step);
        let destination;
        if (current % step > 0) {
          destination = page * step;
        } else {
          destination = (page - 1) * step;
        }
        showRange = [destination, destination + step];
      }
      this.setState({
        current,
        showRange,
      });
    }
  };

  renderBtn(text) {
    return (
      <React.Fragment>
        <Tag
          className={text === '请选择时间' ? 'mobile-main-color' : 'mobile-time-tag'}
          style={{ fontSize: '14px', padding: '1px 5px' }}
          onClick={() => {
            this.props.onToggle && this.props.onToggle(false);
            this.setState({ show: true });
          }}>
          {text === '请选择时间' ? (
            <>
              <i onClick={() => {}} style={{ fontSize: '14px' }} className="iconfont icon-jia mobile-filter-add-icon" />
              <span style={{ paddingLeft: '5px' }}>选择</span>
            </>
          ) : (
            text
          )}
        </Tag>
      </React.Fragment>
    );
  }
  onConfirm = (time) => {
    const isDateTime = this.props.type !== 'date';
    if (!this.canFinish && isDateTime) {
      this.canFinish = true;
      return;
    }
    if (!isDateTime) {
      const date = mathUtil.formateDateString(time.toLocaleDateString('zh-CN'));
      // this.props.dateChange(null, date);
      this.chooseDate = date;
    } else {
      const startTime = time[0];
      const endTime = time[1];
      const dateArr = [
        `${mathUtil.formateDateString(startTime.toLocaleDateString('zh-CN'))}`,
        `${mathUtil.formateDateString(endTime.toLocaleDateString('zh-CN'))}`,
      ];
      // this.props.dateChange(null, dateArr);
      this.chooseDate = dateArr;
    }
    // this.setState({
    //   show: false,
    // });
    // this.canFinish = false;
    // this.props.onToggle && this.props.onToggle(true);
  };

  handleConfirm = () => {
    const isDateTime = this.props.type !== 'date';
    if (!this.canFinish && isDateTime) {
      message.warn('请选择时间');
      return;
    } else {
      if (isDateTime && !this.chooseDate) {
        message.warn('请选择时间');
        return;
      }
      this.canFinish = false;
      this.props.dateChange(null, this.chooseDate);
      this.setState({
        show: false,
      });
    }
  };

  handleChooseTag = (key) => {
    let dateStr = mathUtil.keyToDate(key);
    const dateArr = dateStr.split(',');
    const dateSecond = dateArr[1];
    const year = moment(dateSecond).get('year');
    const month = moment(dateSecond).get('month') + 1;
    this.props.dateChange(key, dateArr);
    if (this.calendar) {
      this.calendar.current.jumpTo({ year, month });
    }
    this.setState({
      show: false,
    });
    this.props.onToggle && this.props.onToggle(true);
  };

  renderSelectors = () => {
    const { showRange } = this.state;
    const { lastDate } = this.props;
    return selectParams.slice(showRange[0], showRange[1]).map((i) => {
      return (
        <Tag
          className={`tag ${lastDate && lastDate === i.key ? 'tag-choose' : ''}`}
          index={i.key}
          onClick={() => {
            this.handleChooseTag(i.key);
          }}>
          {intl.get(i.name)}
        </Tag>
      );
    });
  };

  handleTurn = (way) => {
    let { current, step } = this.state;
    const isEnd = current % step === 0;
    let page = Math.floor(current / step);
    let destination;
    if (way === 'left') {
      if (isEnd) {
        destination = (page - 2) * step;
      } else {
        destination = (page - 1) * step;
      }
      current = current - step;
    } else {
      if (isEnd) {
        destination = page * step;
      } else {
        destination = (page + 1) * step;
      }
      current = current + step;
    }
    this.setState({
      showRange: [destination, destination + step],
      current: current,
    });
  };

  onCancel = () => {
    this.setState({
      show: false,
    });
    this.props.onToggle && this.props.onToggle(true);
  };
  render() {
    const now = new Date();
    const { current, step } = this.state;
    const { isNewControl, tagClick, closeCalender, index } = this.props;
    return (
      <div>
        {this.renderBtn(
          !this.props.value || typeof this.props.value[0]._i !== 'string'
            ? '请选择时间'
            : this.props.type !== 'date'
            ? `${this.props.value[0]._i}~${this.props.value[1]._i}`
            : this.props.value[0]._i
        )}
        {this.state.show && (
          <Mask
            visible={this.state.show}
            onMaskClick={() => {
              closeCalender && closeCalender(index);
              this.setState({
                show: false,
              });
            }}>
            <div className="mobile-v5-calendar">
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px' }}>
                <div
                  className="MAIN-COLOR"
                  onClick={() => {
                    this.setState({
                      show: false,
                    });
                  }}>
                  取消
                </div>
                <div
                  className="MAIN-COLOR"
                  onClick={() => {
                    this.handleConfirm();
                  }}>
                  确定
                </div>
              </div>
              {this.props.type !== 'date' && (
                <div className="mobile-v5-calendar-selectors">
                  <div className="tag-container">{this.renderSelectors()}</div>
                  <MobileArrows
                    iconType={1}
                    total={selectParams.length}
                    current={current}
                    step={step}
                    iconLeftStyle={{ marginRight: '20px' }}
                    onClickLeft={() => {
                      this.handleTurn('left');
                    }}
                    onClickRight={() => {
                      this.handleTurn('right');
                    }}
                  />
                </div>
              )}

              <div>
                <Calendar
                  selectionMode={this.props.type !== 'date' ? 'range' : 'single'}
                  onCancel={this.onCancel}
                  onChange={this.onConfirm}
                  min={new Date(2015, 1, 1)}
                  max={new Date(now)}
                  allowClear={false}
                  defaultValue={this.props.type !== 'date' ? this.props.value : this.props.value && this.props.value[0]}
                  ref={this.calendar}
                />
              </div>
            </div>
          </Mask>
        )}
      </div>
    );
  }
}
export default MobileCalander;
