import React, { useState, useEffect, Fragment } from 'react';
import { Menu, Input } from 'antd';
import util from '../../../../../util'
import api from '../../../../../api'
import PageHeaderStore from '../../../PageHeaderStore';
import Loading from '../../../../Loading/Loading'

function ControllerList(props) {
    const { controllerList, setControllerList, loading, setIndex, currentIndex, checkForm } = props
    const [editKey, setEdItKey] = useState(null)
    const [editName, setEdItName] = useState('')

    useEffect(() => {
        // setSelectedKeys(controllerList[0].id)
    }, [loading])

    // 菜单切换
    function changeController(key) {
        checkForm(() => {
            setIndex(key)
        })
    }
    // 改名
    function changeName(index) {
        let controllerListCopy = controllerList
        controllerListCopy[index].name = editName;
        setControllerList([...controllerListCopy])
        setEdItName('')
        setEdItKey(null)
    }
    // 删除
    function deleteItem(index) {
        let controllerListCopy = controllerList
        controllerListCopy.splice(index, 1)
        setControllerList([...controllerListCopy])
    }
    // 新增
    function addItem() {
        checkForm(() => {
            const index = controllerList.length;
            let controllerListCopy = controllerList
            controllerListCopy.push({ name: '新建控制器', type: 'select', relatedCards: [], relatedViews: {} })
            setControllerList([...controllerListCopy])
            setIndex(index.toString())
        })
    }
    return (
        <div className="controller-list">
            <div className="controller-header">
                <div>控制器列表</div>
                <i className="iconfont icon-jia hover-icon" onClick={addItem} />
            </div>
            <Menu
                className="controller-menu"
                selectedKeys={currentIndex}
                onClick={({ item, key }) => { changeController(key) }}>
                {loading
                    ? <Loading />
                    : controllerList.map((item, index) => {
                        return (
                            <Menu.Item key={index}>
                                {editKey !== null && editKey === index
                                    ? <Fragment>
                                        <Input className="md-input" value={editName} onChange={(e) => { setEdItName(e.target.value) }} />
                                        <i className="iconfont icon-gou hover-icon" onClick={() => changeName(index)} />
                                    </Fragment>
                                    : <Fragment>
                                        <div className="controller-menu-title">{item.name}</div>
                                        <div className="controller-menu-tools">
                                            <i className="iconfont icon-bianjimingcheng hover-icon" onClick={() => { setEdItKey(index), setEdItName(item.name) }} />
                                            <i className="iconfont icon-shanchu hover-icon" onClick={() => { deleteItem(index) }} />
                                        </div>
                                    </Fragment>}
                            </Menu.Item>
                        )
                    })}
            </Menu>
        </div>
    )
}

export default ControllerList