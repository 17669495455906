import React, { useEffect, useState, Fragment } from 'react';
import { Form, Select, Input, Button, Checkbox } from 'antd';
import intl from 'react-intl-universal';
import util from '../../../util';
import api from '../../../api';
import UXSDrawer from '../../../component/UXSDrawer';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import { DataPeriod, SendTime } from './FormComponent';
import Loading from '../../../component/Loading/Loading';

const { Option } = Select;
function Main(props) {
  const { show, onHide, form, editInfo, fetchData } = props;
  const { editId } = editInfo;
  const { getFieldDecorator } = form;
  const [accepterList, setAccepterList] = useState([]); // 接受人列表
  const [data, setData] = useState({}); // 详情数据
  const [wayList, setWayList] = useState([]); // 发送渠道列表
  const [templateObj, setTemplateObj] = useState({});
  const [boardList, setBoardList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (editId) {
      util.get(api.subscription.info, { id: editId }, (res) => {
        if (res) {
          const formdata = {
            ...res,
            pushDateConfig: JSON.parse(res.pushDateConfig),
            channel: [],
          };
          formdata.dashboard = JSON.stringify({ dashboardId: res.dashboardId, dashboardName: res.dashboardName });
          if (res.dataDateConfig.indexOf('last') < 0) {
            formdata.dataDateConfig = { date: res.dataDateConfig };
          } else {
            const num = res.dataDateConfig.replace(/[^\d]/g, '');
            const unit = res.dataDateConfig.replace('last', '').replace(num, '');
            formdata.dataDateConfig = {
              num,
              unit,
            };
          }
          let pushChannel = null;
          if (res.pushChannel) {
            try {
              pushChannel = JSON.parse(res.pushChannel);
            } catch (error) {}
          }
          if (pushChannel) {
            Array.isArray(pushChannel) &&
              pushChannel.forEach((item) => {
                formdata.channel.push(JSON.stringify({ id: item.channel_id, channel_type: item.channel_type && item.channel_type }));
                const itemCopy = JSON.parse(JSON.stringify(item));
                delete itemCopy.channel_id;
                delete itemCopy.channel_type;
                getChannelFields(item.channel_type, item.channel_id, itemCopy);
                if (item.push_config && item.push_config.templateId) {
                  formdata[`${item.channel_id}templateId`] = item.push_config.templateId.toString();
                } else if (item.template_id) {
                  formdata[`${item.channel_id}templateId`] = item.template_id.toString();
                }
              });
            // const itemCopy = JSON.parse(res.pushChannel);
            // delete itemCopy.id;
            // delete itemCopy.channel_id;
            // delete itemCopy.channel_type;
            // getChannelFields(pushChannel.channel_type, pushChannel.channel_id, itemCopy);
            // if (pushChannel.push_config && pushChannel.push_config.templateId) {
            //   formdata[`${pushChannel.channel_id}templateId`] = pushChannel.push_config.templateId.toString();
            // } else if (formdata.pushChannel.template_id) {
            //   formdata[`${pushChannel.channel_id}templateId`] = pushChannel.template_id.toString();
            // }
            // formdata.channel = JSON.stringify({ channel_id: pushChannel.channel_id, channel_type: pushChannel.channel_type && pushChannel.channel_type });
            // pushChannel.urlQuery && (formdata.urlQuery = pushChannel.urlQuery);
          }
          setData(formdata);
          util.get(
            api.surveyChannel.getChannelList,
            {
              idsite: PageHeaderStore.parameter.idsite,
              search: 'subscribe',
            },
            (result) => {
              const wayData = [];
              const obj = {};
              result.forEach((item) => {
                wayData.push({ value: JSON.stringify({ id: item.id, channel_type: item.channel_type }), label: item.name, id: item.id });
                obj[item.id] = item.channel_type;
              });
              //   if (pushChannel && pushChannel.channel_id) this.getTemplateList(obj[pushChannel.channel_id], pushChannel.channel_id, true);
              // data.channel.forEach((item) => {
              //   const channelItem = JSON.parse(item);
              //   this.getTemplateList(obj[channelItem.id], channelItem.id, true);
              // });
              resetWayList(wayData, formdata.channel);
            }
          );
          setLoading(false);
        }
      });
    } else {
      util.get(
        api.surveyChannel.getChannelList,
        {
          idsite: PageHeaderStore.parameter.idsite,
          search: 'subscribe',
        },
        (result) => {
          const data = [];
          result.forEach((item) => {
            data.push({ value: JSON.stringify({ id: item.id, channel_type: item.channel_type }), label: item.name, id: item.id });
          });
          resetWayList(data);
        }
      );
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    // 接受人列表
    util.get(
      api.account.listContainOwn,
      {
        idsite: PageHeaderStore.parameter.idsite,
        pageSize: 100000,
        pageNum: 1,
      },
      (res) => {
        const data = [];
        res.data.forEach((item) => {
          data.push({ value: item.id, label: item.username });
        });
        data.length && setAccepterList(data);
      }
    );
  }, []);

  useEffect(() => {
    // 看板列表
    let param = {
      idsite: PageHeaderStore.parameter.idsite,
      currentPage: 1,
      pageCount: 10000,
    };
    util.get(
      api.board.listWithoutTemplate,
      param,
      (res) => {
        if (res) {
          setBoardList(res.data);
        }
      },
      null,
      false
    );
  }, []);
  function channelChange(e) {
    // 选择预警方式的回调
    const { id, channel_type } = JSON.parse(e.target.value);
    const templateObjCopy = templateObj;
    if (e.target.checked) {
      //是否选中？控制显隐
      templateObjCopy[id] ? (templateObjCopy[id].isShow = true) : getChannelFields(channel_type, id);
    } else if (!e.target.checked) {
      templateObjCopy[id] && (templateObjCopy[id].isShow = false);
    }
    setTemplateObj(templateObjCopy);
    // wayList.forEach((item, index) => {
    //   if (channel_id == item.id) {
    //     templateObj[item.id] ? (templateObj[item.id].isShow = true) : getChannelFields(channel_type, item.id);
    //     setSelectedWayItem(item);
    //   } else {
    //     templateObj[item.id] && (templateObj[item.id].isShow = false);
    //   }
    // });
  }
  function getChannelFields(channelType, id, editData) {
    if (!channelType || !id) return;
    // 获取子表单项
    util.get(api.warning.getChannelFields, { idsite: PageHeaderStore.parameter.idsite, channelType }, (res) => {
      const data = templateObj;
      data[id] = { isShow: true, format: res, data: {}, formValue: editData || getInitialValue(res) };
      res.length &&
        res.forEach((item) => {
          item.type === 'list' && getFieldOptions(channelType, item.key, null, id);
          editData && item.children && item.children.type && getFieldOptions(channelType, item.children.key, null, id);
        });
      setTemplateObj({ ...data });
    });
  }
  function getFieldOptions(channelType, key, attach, id) {
    // 获取下拉框的选项
    attach = attach || {};
    attach.sceneType = '4';
    attach = JSON.stringify(attach);
    util.get(api.warning.getFieldOptions, { idsite: PageHeaderStore.parameter.idsite, pushChannelId: id, channelType, key, attach }, (res) => {
      const data = templateObj;
      data[id].data[key] = res;
      setTemplateObj({ ...data });
    });
  }
  function getInitialValue(data) {
    // 根据格式设定初始值
    const obj = {};
    data.forEach((item) => {
      obj[item.key] = undefined;
      item.children && (obj[item.children.key] = undefined);
    });
    return obj;
  }
  function resetWayList(data, channel) {
    setWayList(data);
    // if (channel) {
    //   const { id } = JSON.parse(channel);
    //   data.forEach((item, index) => {
    //     if (id == item.id) {
    //       setSelectedWayItem(item);
    //     }
    //   });
    // }
  }
  function getChildForm(channel, item) {
    // 返回子表单组件，channel：渠道信息，item：子表单信息
    const channelObj = JSON.parse(channel);
    return (
      <Fragment>
        {item.type === 'text' ? (
          <Input className="md-input" value={templateObj[channelObj.id].formValue[item.key]} onChange={(e) => setFormValue(e.target.value, channelObj, item)} />
        ) : item.type === 'textarea' ? (
          <Input.TextArea
            className="md-input"
            rows={4}
            value={templateObj[channelObj.id].formValue[item.key]}
            onChange={(e) => setFormValue(e.target.value, channelObj, item)}
          />
        ) : (
          <Select
            className="lg-select select-group"
            value={templateObj[channelObj.id].formValue[item.key]}
            onChange={(value) => setFormValue(value, channelObj, item)}>
            {templateObj[channelObj.id].data[item.key] &&
              templateObj[channelObj.id].data[item.key].map((el) => {
                return <Select.Option key={el.value}>{el.name}</Select.Option>;
              })}
          </Select>
        )}
        {item.children && getChildForm(channel, item.children)}
      </Fragment>
    );
  }
  function setFormValue(value, channel, item) {
    // 子表单onchange的回调
    let attach;
    // 如果有children则获取children的下拉选项
    item.type === 'list' &&
      item.children &&
      item.children.type === 'list' &&
      getFieldOptions(channel.channel_type, item.children.key, { [item.key]: value }, channel.id);
    const data = templateObj;
    data[channel.id].formValue[item.key] = value;
    setTemplateObj({ ...data });
  }
  function getFormItemName(data) {
    // 获取数据最内层的name
    const name = data.children ? getFormItemName(data.children) : data.name;
    return name;
  }
  function getFormValue(id, key, children) {
    // 用于表单验证
    const data = { [key]: templateObj[id].formValue[key] };
    children && (data[children.key] = templateObj[id].formValue[children.key]);
    return data;
  }
  //   function getTemplateList(templateType, key, isEdit) {
  //     util.get(api.warning.template, { idsite: PageHeaderStore.parameter.idsite, templateType, sceneType: 3 }, (res) => {
  //       setTemplateList(key, res, isEdit);
  //     });
  //   }

  function submit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const param = {
          idsite: PageHeaderStore.parameter.idsite,
          ...values,
          ...JSON.parse(values.dashboard),
          dataDateConfig: values.dataDateConfig.date ? values.dataDateConfig.date : `last${values.dataDateConfig.unit}${values.dataDateConfig.num}`,
          pushDateConfig: JSON.stringify(values.pushDateConfig),
          pushChannel: values.channel,
        };
        delete values.dashboard;
        let pushChannel = [];
        values.channel.forEach((item, index) => {
          const channelItem = JSON.parse(item);
          pushChannel.push({
            channel_id: channelItem.id,
            channel_type: channelItem.channel_type,
            ...(templateObj[channelItem.id] && templateObj[channelItem.id].formValue),
          });
        });
        param.pushChannel = JSON.stringify(pushChannel);
        editId && (param.id = editId);
        util.post(api.subscription.save, param, (res) => {
          onHide();
          fetchData();
        });
      }
    });
  }
  function checkForm(rule, value, callback, msg) {
    for (const key in value) {
      if (!value[key]) {
        return callback(msg);
      }
    }
    callback();
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };
  return (
    <UXSDrawer
      className="full-right scroll-drawer"
      title={
        <div>
          <div>订阅规则</div>
          <Button type="primary" onClick={submit}>
            {intl.get('Save')}
          </Button>
        </div>
      }
      show={show}
      onClose={onHide}
      width={800}>
      {!loading ? (
        <Form {...formItemLayout} onSubmit={submit}>
          <Form.Item label="订阅名称：">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入名称' }],
              initialValue: data.name || null,
            })(<Input placeholder="请输入" className="md-input" />)}
          </Form.Item>
          <Form.Item label="订阅看板：">
            {getFieldDecorator('dashboard', {
              rules: [{ required: true, message: '请选择看板' }],
              initialValue: data.dashboard || null,
            })(
              <Select className="lg-select">
                {boardList.length &&
                  boardList.map((item) => {
                    return <Option key={JSON.stringify({ dashboardId: item.id, dashboardName: item.name })}>{item.name}</Option>;
                  })}
              </Select>
            )}
            {data.dashboard &&
              boardList.findIndex((board) => {
                return board.id === JSON.parse(data.dashboard).dashboardId;
              }) === -1 && <span style={{ color: 'red', paddingLeft: '5px' }}>该看板已取消分享</span>}
          </Form.Item>
          <Form.Item label="数据周期：">
            {getFieldDecorator('dataDateConfig', {
              rules: [{ required: true, validator: (rule, value, callback) => checkForm(rule, value, callback, '请选择数据周期') }],
              initialValue: data.dataDateConfig || { unit: null, num: null },
            })(<DataPeriod />)}
          </Form.Item>
          <Form.Item label="发送时间：">
            {getFieldDecorator('pushDateConfig', {
              rules: [{ required: true, validator: (rule, value, callback) => checkForm(rule, value, callback, '请选择发送时间') }],
              initialValue: data.pushDateConfig || { period: null },
            })(<SendTime />)}
          </Form.Item>
          <Form.Item label="接受人：" className="typeItem">
            {/* 接受人 */}
            {getFieldDecorator('pushTo', {
              initialValue: data.pushTo || [],
              rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('Receiver').toLowerCase()}` }],
            })(
              <Select className="lg-select" mode="multiple">
                {accepterList.map((item) => {
                  return (
                    <Option key={item.value.toString()} title={item.label}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="发送渠道：" className="typeItem">
            {getFieldDecorator('channel', {
              initialValue: data.channel ? data.channel : [],
              rules: [{ required: true, message: '请选择发送渠道' }],
            })(
              <Checkbox.Group>
                {wayList.map((item, index) => {
                  return (
                    <div className="info-checkbox-content" key={item.value}>
                      <Checkbox value={item.value} onChange={(e) => channelChange(e)}>
                        {item.label}
                      </Checkbox>
                      {templateObj[item.id] &&
                        templateObj[item.id].isShow &&
                        (templateObj[item.id].format.length
                          ? templateObj[item.id].format.map((el) => {
                              return (
                                <Form.Item label={getFormItemName(el)} className="children-form-item">
                                  {getFieldDecorator(`${item.id}${el.key}`, {
                                    rules: [
                                      {
                                        validator: (rule, value, callback) =>
                                          checkForm(rule, getFormValue(item.id, el.key, el.children), callback, intl.get('Thisitemisrequired')),
                                      },
                                    ],
                                    validateTrigger: 'onSubmit',
                                  })(getChildForm(item.value, el, el.key))}
                                </Form.Item>
                              );
                            })
                          : null)}
                    </div>
                  );
                })}
              </Checkbox.Group>
              // <Select className="lg-select" onChange={(e) => channelChange(e)}>
              //   {wayList.map((item) => {
              //     return <Select.Option key={item.value}>{item.label}</Select.Option>;
              //   })}
              // </Select>
            )}
          </Form.Item>
          {/* {selectedWayItem &&
            templateObj[selectedWayItem.id] &&
            templateObj[selectedWayItem.id].isShow &&
            (templateObj[selectedWayItem.id].format.length ? (
              <Fragment>
                {templateObj[selectedWayItem.id].format.map((el) => {
                  return (
                    <Form.Item label={getFormItemName(el)} className="children-form-item">
                      {getFieldDecorator(`${selectedWayItem.id}${el.key}`, {
                        rules: [
                          {
                            required: true,
                            validator: (rule, value, callback) => checkForm(rule, getFormValue(selectedWayItem.id, el.key, el.children), callback, '此项必填'),
                          },
                        ],
                        validateTrigger: 'onSubmit',
                      })(getChildForm(selectedWayItem.value, el, el.key))}
                    </Form.Item>
                  );
                })}
              </Fragment>
            ) : null)} */}
        </Form>
      ) : (
        <Loading />
      )}
    </UXSDrawer>
  );
}

export default Form.create()(Main);
