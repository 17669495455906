import WarningUserGroup from '../../Report/WarningUserGroup';

export default {
  title: '预警用户组',
  components: [
    {
      title: '预警用户组',
      isCard: true,
      className: 'single-card',
      children: [{ component: WarningUserGroup }],
    },
  ],
};
