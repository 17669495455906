import { action, observable, toJS } from 'mobx';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import util from '../../util';
import api from '../../api';

class Store {
  @observable data = null;

  @action.bound
  fetchList(type, search = '', currentPage = 1, pageCount = 10) {
    let param = {
      idsite: pageHeaderStore.parameter.idsite,
      search,
      currentPage,
      pageCount,
      withFolder: true,
    };
    type && type !== 1 && (param.type = type);
    return util.get(
      api.board.listWithoutTemplate,
      param,
      (res) => {
        if (res) {
          const designTypes = [2, 3];
          const data = res.data
            .map((item) => {
              if (item.isFolder) {
                return { ...item, children: [] };
              }
              return { ...item };
            })
            .filter((item) => {
              return !designTypes.includes(item.type) || item.isFolder || type;
            });
          this.data = { ...res, data };
        }
      },
      null,
      false
    );
  }

  @action.bound
  appendChildren(folderId, res) {
    res = res || [];
    const data = this.data.data;
    const page = this.data.page;
    const { currentPage, pageCount } = page;
    const index = data.findIndex((i) => i.id === folderId);
    if (index > -1) {
      data[index].children = res.map((i) => {
        return { ...i, isChildren: true, key: i.id + new Date(), parentIndex: (currentPage - 1) * pageCount + index + 1 };
      });
    }
    this.data = { ...this.data, data };
  }
}
export default new Store();
