import React, { Component } from 'react';
import UXSRangePicker from '../../../UXSRangePicker';
import { getTextWidth } from '../../../../containers/BIChart/util';

class Main extends Component {
  constructor() {
    super();
    this.content = React.createRef();
  }
  componentDidMount() {
    this.setTitleStyle();
  }
  componentDidUpdate(prevProps) {
    if (this.props.item.name !== prevProps.item.name) {
      this.setTitleStyle();
    }
  }
  setTitleStyle = (lastDate) => {
    const titleWidth = getTextWidth(this.props.item.name);
    this.content.current.getElementsByClassName('ant-calendar-picker-input')[0].style.paddingLeft = `${titleWidth + 28}px`;
    const calWidth = titleWidth + 128;
    this.content.current.getElementsByClassName('ant-calendar-picker-input')[0].style.width = calWidth > 180 ? `${calWidth}px` : '180px';
    if (lastDate === 'startToToday') {
      this.content.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.justifyContent = 'right';
      this.content.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.paddingRight = '5px';
    } else if (lastDate === null) {
      this.content.current.getElementsByClassName('ant-calendar-range-picker-input')[0].style.visibility = 'visible';
      this.content.current.getElementsByClassName('ant-calendar-picker-input')[0].style.width = `${calWidth + 150}px`;
    } else {
      this.content.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.justifyContent = 'center';
      this.content.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.paddingRight = '0';
    }
  };
  render() {
    const { lastDate, getDateAndlastDate, date, item, index } = this.props;
    return (
      <div className="quick-filter-item pc-quick-filter" key={`${item.name}${index}`} ref={this.content} style={{ ...this.props.style }}>
        <div className="quick-filter-item-title" title={item.name}>
          {item.name}
        </div>
        <UXSRangePicker
          getDateAndlastDate={(lastDate, date) => getDateAndlastDate(item, index, date, lastDate)}
          lastDate={lastDate}
          date={date}
          isBoardPicker={true}
          useDateByStore={false}
          dropdownClassName="pageheader-select"
          styleChange={this.setTitleStyle}></UXSRangePicker>
      </div>
    );
  }
}

export default Main;
