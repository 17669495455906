import AnalysePath from '../../containers/AnalysePath/index';

export default {
  title: '转化分析',
  components: [
    {
      component: AnalysePath,
    },
  ],
};
