import React, { Component, Fragment } from 'react';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Select, Modal, Button, Radio, Input, Form, message, Collapse, Switch, Upload, Icon } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import api from '../../../api';
import util from '../../../util';
import pageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import './index.sass';
import UXSDrawer from '../../../component/UXSDrawer';
import Loading from '../../../component/Loading/Loading';
import { Disturb, TriggerTime, EventSelect, MsgValidDays, FixedSurveyDisplay, FixedSurveyStyle } from './FormComponent';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import CollectionChannel from './FormComponents/CollectionChannel';
import CollectionPeriod from './FormComponents/CollectionPeriod';
import CollectionNum from './FormComponents/CollectionNum';
import CollectionLimit from './FormComponents/CollectionLimit';
import RecoveryWeight from './FormComponents/RecoveryWeight';

const { Option } = Select;
const { Panel } = Collapse;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const repeatList = [
  {
    value: 'false',
    label: '不可重复',
  },
  {
    value: 'true',
    label: '可重复',
  },
];
const fixedTypes = [
  {
    value: 'sdk',
    label: '系统自带浮标',
  },
  {
    value: 'code',
    label: '集成第三方浮标',
  },
];
// const fixedSurvey = '{"channel_type":17}';
const passiveWay = [0, 17];

const MetchMhethodOptions = [
  {
    value: 1,
    label: '串行',
  },
  {
    value: 2,
    label: '并行',
  },
];

@observer
class basicSurveyModal extends Component {
  constructor() {
    super();
    this.initialForm = {};
    this.childFormKey = []; //  调研方式子表单
    this.state = {
      selectedWayItem: null,
      btnStyle: { backgroundColor: 'rgba(255,255,255,1)', borderColor: 'rgba(217,217,222,1)', color: 'rgba(5,10,19,1)' }, //浮标按钮默认样式
      btnText: '',
      fixedType: 'sdk',
      surveyLinkChange: '',
      activeKey: 'basic',
      passCheckKeys: [], //校验通过的key
      unPassCheckKeys: [], //校验未通过的key
      openCollection: false,
      surveyImg1: [],
      surveyImg2: [],
      msgValidObj: {},
    };
  }
  componentDidMount() {
    this.reloadMySurveyList();
    this.queryTagOptions();
    let param = { id: this.props.id, idsite: pageHeaderStore.parameter.idsite };
    if (this.props.isNewModalUnedit) {
      param.isView = this.props.isNewModalUnedit;
    }
    this.props.id &&
      util.get(api.trigger.edit, param, (res) => {
        let data = { ...res, channel: {}, param: {} };
        this.onOpenCollectionChange(data.openCollection ? true : false);
        if (res.disturbConfig && res.disturbConfig !== 'notPush' && res.disturbConfig !== 'unlimit') {
          const disturbConfig = JSON.parse(res.disturbConfig);
          if (disturbConfig.msgValidDays) {
            this.setState({ msgValidObj: { msgValidDays: disturbConfig.msgValidDays } });
          } else {
            this.setState({ msgValidObj: { msgValidCounts: disturbConfig.msgValidCounts } });
          }
          data.msgRepeatAnswer = disturbConfig.msgRepeatAnswer.toString();
          disturbConfig.msgRepeatAnswer = disturbConfig.msgRepeatAnswer.toString();
          data.disturb = disturbConfig;
        }
        if (res.eventConfigFilter) {
          let eventConfigFilter = JSON.parse(res.eventConfigFilter);
          eventConfigFilter.rules[0].rules = eventConfigFilter.rules[0].rules.slice(1);
          data.eventConfigFilter = eventConfigFilter;
        }
        let pushChannel = null;
        if (res.pushChannel) {
          try {
            pushChannel = JSON.parse(res.pushChannel);
          } catch (error) {}
        }
        if (pushChannel) {
          const itemCopy = JSON.parse(res.pushChannel);
          delete itemCopy.id;
          delete itemCopy.channel_id;
          delete itemCopy.channel_type;
          // 赋值 matchingType
          data.matchingType = itemCopy.matchingType;
          // 赋值upload
          if (itemCopy.surveyImg1) {
            this.setState({
              surveyImg1: [
                {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: itemCopy.surveyImg1,
                },
              ],
            });
          }
          if (itemCopy.surveyImg2) {
            this.setState({
              surveyImg2: [
                {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: itemCopy.surveyImg2,
                },
              ],
            });
          }
          this.getChannelFields(pushChannel.channel_type, pushChannel.channel_id, itemCopy);
          if (pushChannel.push_config && pushChannel.push_config.templateId) {
            data[`${pushChannel.channel_id}templateId`] = pushChannel.push_config.templateId.toString();
          } else if (data.pushChannel.template_id) {
            data[`${pushChannel.channel_id}templateId`] = pushChannel.template_id.toString();
          }
          data.channel = JSON.stringify({
            channel_id: pushChannel.channel_id,
            channel_type: pushChannel.channel_type && pushChannel.channel_type,
          });
          this.setChannelWay(pushChannel.channel_type);
          pushChannel.urlQuery && (data.urlQuery = pushChannel.urlQuery);
          if (res.buoyConfig) {
            const buoyConfig = JSON.parse(res.buoyConfig);
            data.fixedSurveyType = buoyConfig.fixedSurveyType;
            buoyConfig.fixedSurveyDisplay &&
              ((data = {
                ...data,
                fixedSurveyDisplay: buoyConfig.fixedSurveyDisplay,
                fixedSurveyText: buoyConfig.fixedSurveyText,
                fixedSurveyStyle: buoyConfig.fixedSurveyStyle,
              }),
              this.setState({
                btnStyle: {
                  backgroundColor: this.rgbaJSONToString(buoyConfig.fixedSurveyStyle.backgroundColor),
                  borderColor: this.rgbaJSONToString(buoyConfig.fixedSurveyStyle.borderColor),
                  color: this.rgbaJSONToString(buoyConfig.fixedSurveyStyle.color),
                },
                btnText: buoyConfig.fixedSurveyText,
              }));
          }
        }
        if (res.pushTime) {
          if (res.pushTime === 'realtime') {
            data.pushTime = null;
          } else {
            const initialPushTime = JSON.parse(res.pushTime);
            let pushTime;
            if (!initialPushTime.inviteDelay) {
              for (let key in initialPushTime) {
                pushTime = { inviteDelay: [key], inviteDelayVal: initialPushTime[key], inviteTimeRange: 'unlimit' };
              }
            } else {
              const inviteTimeRange =
                initialPushTime.inviteTimeRange === 'unlimit' ? initialPushTime.inviteTimeRange : initialPushTime.inviteTimeRange.split(',');
              pushTime = { ...initialPushTime, inviteTimeRange };
            }
            data.pushTime = pushTime;
          }
        }
        if (res.collectionConfig) {
          data.collectionConfig = JSON.parse(res.collectionConfig);
        }
        this.initialForm = data;
        this.setForm(data);
        util.get(
          api.surveyChannel.getChannelList,
          {
            idsite: pageHeaderStore.parameter.idsite,
            search: 'survey',
          },
          (result) => {
            const wayData = [],
              obj = {};
            result.forEach((item) => {
              wayData.push({
                value: JSON.stringify({ channel_id: item.id, channel_type: item.channel_type }),
                label: item.name,
                id: item.id,
              });
              obj[item.id] = item.channel_type;
            });
            // if (pushChannel && pushChannel.channel_id) this.getTemplateList(obj[pushChannel.channel_id], pushChannel.channel_id, true);
            this.setWayList(wayData, data.channel);
          }
        );
        this.setIsReady(true);
        this.resetSurveyId();
      });
    !this.props.id &&
      (util.get(
        api.surveyChannel.getChannelList,
        {
          idsite: pageHeaderStore.parameter.idsite,
          search: 'survey',
        },
        (result) => {
          const data = [];
          result.forEach((item) => {
            data.push({
              value: JSON.stringify({ channel_id: item.id, channel_type: item.channel_type }),
              label: item.name,
              id: item.id,
            });
          });
          this.setWayList(data);
        }
      ),
      this.setIsReady(true));
  }
  @observable show = false;
  @observable form = {};
  @observable isReady = false;
  @observable mySurveyList = [];
  @observable wayList = [];
  @observable templateObj = {};
  @observable isButtonDisabled = false;
  @observable surveyLoading = true;
  @observable channelWay = false;
  @observable previewVisible = false;
  @observable previewImage = '';
  @observable hasImageUpload = false;
  @observable tagOptionsList = [];

  @action.bound
  setHasImageUpload(bool) {
    this.hasImageUpload = bool;
  }

  // 预览图片
  @action.bound
  setPreviewVisible(data) {
    this.previewVisible = data;
  }
  // 预览图片地址
  @action.bound
  setPreviewImage(data) {
    this.previewImage = data;
  }
  // 表单图片url
  @action.bound
  setPlaceImageData(data, channelObj, key) {
    this.templateObj[channelObj.channel_id].formValue[key] = data;
  }
  @action.bound
  setShow(data) {
    this.show = data;
  }
  @action.bound
  setForm(data) {
    this.form = data;
  }
  @action.bound
  setIsReady(data) {
    this.isReady = data;
  }
  @action.bound
  setChannelWay(channel_type) {
    this.channelWay = channel_type;
  }
  @action.bound
  setWayList(data, channel) {
    this.wayList = data;
    if (channel) {
      const { channel_id } = JSON.parse(channel);
      this.wayList.forEach((item, index) => {
        if (channel_id == item.id) {
          this.setState({
            selectedWayItem: item,
          });
        }
      });
    }
  }
  @action.bound
  channelChange(e) {
    //选择调研方式的回调
    const { channel_id, channel_type } = JSON.parse(e);
    this.childFormKey = [];
    this.setChannelWay(channel_type);
    this.wayList.forEach((item, index) => {
      if (channel_id == item.id) {
        this.templateObj[item.id] ? (this.templateObj[item.id].isShow = true) : this.getChannelFields(channel_type, item.id);
        this.setState({
          selectedWayItem: item,
        });
      } else {
        this.templateObj[item.id] && (this.templateObj[item.id].isShow = false);
      }
    });
  }
  @action.bound
  queryTagOptions() {
    util.get(api.dataTag.tagOptions, { optionType: 'param', filed: 'action' }, (res) => {
      if (res) {
        this.tagOptionsList = [
          {
            visualType: 'int',
            column: 'all',
            columnName: '回收总量',
          },
          ...res,
        ];
      }
    });
  }
  @action.bound
  reloadMySurveyList() {
    this.surveyLoading = true;
    util.get(api.trigger.mySurveyList, null, (res) => {
      res && (this.mySurveyList = res);
      this.surveyLoading = false;
      this.resetSurveyId();
    });
  }
  @action.bound
  resetSurveyId = () => {
    //检查已选问卷是否被删除，是则清空该项
    if (this.props.id && this.isReady && !this.surveyLoading) {
      const surveyIds = this.mySurveyList.map((item) => item.id);
      surveyIds.indexOf(this.form.surveyId) < 0 &&
        this.setForm({
          ...this.form,
          surveyId: '',
        });
    }
  };
  @action.bound
  submit = (e) => {
    e.preventDefault();
    this.checkCollapsePassOnsubmit();
    this.props.form.validateFields(async (error, values) => {
      if (!error) {
        let form = {
          surveyId: values.surveyId,
          name: values.name,
          idsite: pageHeaderStore.parameter.idsite,
          openCollection: this.state.openCollection,
          pushChannel: values.channel,
          disturbConfig: JSON.stringify({
            msgRepeatAnswer: values.msgRepeatAnswer === 'true' ? true : false,
          }),
        };
        const pushChannel = JSON.parse(form.pushChannel);
        let newObj = {};
        if (this.hasImageUpload) {
          const img1 = this.templateObj[pushChannel.channel_id].formValue.surveyImg1;
          const img2 = this.templateObj[pushChannel.channel_id].formValue.surveyImg2;
          if (!img1 && !img2) {
            message.error('请上传图片');
            return;
          }
          // 拼接图片属性
          const list = [];
          img1 && list.push(img1);
          img2 && list.push(img2);
          if (img1) {
            const obj1 = await this.getImageSize(img1);
            newObj.surveyImg1Width = obj1.width;
            newObj.surveyImg1Height = obj1.height;
          }
          if (img2) {
            const obj2 = await this.getImageSize(img2);
            newObj.surveyImg2Width = obj2.width;
            newObj.surveyImg2Height = obj2.height;
          }
        }
        values.urlQuery && (pushChannel.urlQuery = values.urlQuery);
        this.state.openCollection &&
          (form.collectionConfig = JSON.stringify({
            ...values.collectionPeriod,
            ...values.collectionNum,
            ...values.collectionChannel,
          }));
        if (values.fixedSurveyType) {
          let buoyConfig = {
            fixedSurveyType: values.fixedSurveyType,
          };
          values.fixedSurveyDisplay &&
            (buoyConfig = {
              ...buoyConfig,
              fixedSurveyDisplay: values.fixedSurveyDisplay,
              fixedSurveyText: values.fixedSurveyText,
              fixedSurveyStyle: values.fixedSurveyStyle,
            });
          form.buoyConfig = JSON.stringify(buoyConfig);
        }

        form.pushChannel = JSON.stringify({
          ...pushChannel,
          ...(this.templateObj[pushChannel.channel_id] && this.templateObj[pushChannel.channel_id].formValue),
          ...newObj,
          // 加入 matchingType
          matchingType: values.matchingType,
        });

        if (!passiveWay.includes(this.channelWay)) {
          form.recoveryWeightAdd = values.recovery.recoveryWeightAdd;
          form.recoveryWeight = values.recovery.recoveryWeight ? 1 : 0;
          form.eventName = values.event.eventName;
          form.eventConfigFilter = JSON.stringify({
            ...values.event.eventConfigFilter,
            rules: [
              {
                logic: 'and',
                type: 'rules_event',
                rules: [
                  {
                    conditions: [
                      {
                        dateRange: 'startToToday',
                        dim: 'event',
                        dimValue: values.event.eventName,
                        dimValueName: '事件',
                        formula: '=',
                        rulesType: 'fit',
                        target: '',
                        targetName: '',
                        value: values.event.eventName,
                      },
                    ],
                    type: 'rule_filter',
                  },
                  ...values.event.eventConfigFilter.rules[0].rules,
                ],
              },
            ],
          });
          values.pushTime.inviteTimeRange = values.pushTime.inviteTimeRange === 'unlimit' ? 'unlimit' : values.pushTime.inviteTimeRange.join(',');
          form.pushTime = JSON.stringify(values.pushTime);
          // 删除 values.disturb 中的 msgValidDays 和 msgValidCounts 属性
          const newdisturb = { ...values.disturb };
          newdisturb.msgValidDays && delete newdisturb.msgValidDays;
          newdisturb.msgValidCounts && delete newdisturb.msgValidCounts;
          Object.assign(newdisturb, this.state.msgValidObj);
          // 加入新数据
          form.disturbConfig = JSON.stringify({
            ...newdisturb,
            ...values.collectionLimit,
            msgRepeatAnswer: values.msgRepeatAnswer === 'true' ? true : false,
          });
        }
        this.props.id && (form.id = this.props.id);
        this.isButtonDisabled = true;
        util.post(api.trigger.save, form, (res) => {
          this.isButtonDisabled = false;
          res.code == 200 ? (message.success(`${intl.get('UpdateSucceeded')}!`), this.props.hideNewModal(true)) : message.error(res.msg);
        });
      }
    });
  };
  @action.bound
  valueSelect(target, value) {
    switch (target) {
      case 'type':
        this.form.optimizeDim = value;
      case 'autoValue':
        this.form.optimizeDimDetail = value;
    }
  }
  @action.bound
  handleSelect = (value) => {
    this.form.userGroupId = value;
  };
  @action.bound
  customTimeChange = (value) => {
    this.form = { ...this.form, customTime: value };
  };
  @action.bound
  customUnitChange = (value) => {
    this.form = { ...this.form, customUnit: value };
  };
  @action.bound
  getChannelFields(channelType, id, editData) {
    if (!channelType || !id) return;
    //获取子表单项
    util.get(api.warning.getChannelFields, { idsite: pageHeaderStore.parameter.idsite, channelType }, (res) => {
      let data = this.templateObj;
      data[id] = { isShow: true, format: res, data: {}, formValue: editData ? editData : this.getInitialValue(res) };
      res.length &&
        res.forEach((item) => {
          item.type === 'list' && this.getFieldOptions(channelType, item.key, null, id);
          editData && item.children && item.children.type && this.getFieldOptions(channelType, item.children.key, null, id);
        });
      this.templateObj = { ...data };
    });
  }
  @action.bound
  getFieldOptions(channelType, key, attach, id) {
    //获取下拉框的选项
    attach = attach || {};
    attach.sceneType = '3';
    attach = JSON.stringify(attach);
    util.get(api.warning.getFieldOptions, { idsite: pageHeaderStore.parameter.idsite, pushChannelId: id, channelType, key, attach }, (res) => {
      let data = this.templateObj;
      data[id].data[key] = res;
      this.templateObj = { ...data };
    });
  }
  closeModal = () => {
    var str1 = JSON.stringify(this.form);
    var str2 = JSON.stringify(this.initialForm);
    if (str1 === str2 || this.props.isNewModalUnedit) {
      this.props.hideNewModal && this.props.hideNewModal();
      this.props.closeModal && this.props.closeModal('Survey', true);
    } else {
      this.setShow(true);
    }
  };
  checkDisturb = (rule, value, callback, msg) => {
    switch (value.inviteLimitSurveyAction) {
      case 'unlimit':
        callback();
        break;
      case 'invited':
        if (value.inviteLimitScope && value.inviteLimitDays) callback();
        callback(msg);
        break;
      default:
        if (value.inviteLimitScope && value.inviteLimitDays && value.inviteLimitPushCount && value.inviteDateInterval) callback();
        callback(msg);
        break;
    }
  };
  checkForm = (rule, value, callback, msg) => {
    for (var key in value) {
      // if (((key === 'inviteLimitScope' || key === 'inviteLimitDays') && value.inviteLimitScope === 'unlimit') || value.inviteLimitScope === 'survey') {
      //   if (value.inviteLimitScope !== 'survey' || value.inviteLimitDays) {
      //     return callback();
      //   } else {
      //     return callback(msg);
      //   }
      //   //当选择每次都提醒时校验规则特殊处理
      // } else
      if (key === 'inviteDelay' && value[key] === 'unlimit') {
        //当选择每次都提醒时校验规则特殊处理
        if (value.inviteTimeRange === 'unlimit' || (Array.isArray(value.inviteTimeRange) && value.inviteTimeRange[0] && value.inviteTimeRange[1])) {
          return callback();
        } else {
          return callback(msg);
        }
      } else if (key === 'status') {
        if (!value[key].length) {
          callback(msg);
        } else {
          value[key].forEach((item) => {
            !value[`${item}num`] && callback(msg);
          });
        }
      } else if (key === 'place_images') {
        this.setHasImageUpload(true);
        return callback();
      } else if (!value[key] && value[key] !== 0) {
        return callback(msg);
      }
    }
    callback();
  };
  checkEventForm = (rule, value, callback, msg) => {
    if (!value.eventName) {
      return callback(msg);
    } else {
      const rules = value.eventConfigFilter.rules[0].rules;
      rules.forEach((item) => {
        let data = item.conditions[0];
        if (!data.dim) {
          return callback(msg);
        } else if (data.dim.includes('_dt_period')) {
          if (!data.period || !data.value || !data.formula) {
            return callback(msg);
          }
        } else if (!data.value || !data.formula) {
          return callback(msg);
        }
      });
    }
    callback();
  };
  // 浮标位置校验
  checkFixedDisplay = (rule, value, callback, msg) => {
    if (value.type === 'page') {
      if (!value.formula || !value.url) {
        return callback(msg);
      }
    }
    callback();
  };
  checkNormalFormItem = (rule, value, callback, msg) => {
    for (const key in value) {
      if (!value[key]) return callback(msg);
    }
    callback();
  };
  checkCollectionChannel = (rule, value, callback, msg) => {
    for (const key in value) {
      if (key === 'pushConfig') {
        const { pushConfig } = value;
        if (!pushConfig) {
          return callback(msg);
        } else {
          for (const i in pushConfig[0]) {
            if (!pushConfig[0][i]) return callback(msg);
          }
        }
      } else if (key !== 'openCollection') {
        if (!value[key]) return callback(msg);
      }
    }
    callback();
  };
  getFormItemName(data) {
    //获取数据最内层的name
    const name = data.children ? this.getFormItemName(data.children) : data.name;
    return name;
  }
  // 获取图片宽高
  getImageSize(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  }
  getInitialValue(data) {
    //根据格式设定初始值
    const obj = {};
    data.forEach((item) => {
      obj[item.key] = undefined;
      item.children && (obj[item.children.key] = undefined);
    });
    return obj;
  }
  @action.bound
  setFormValue(value, channel, item) {
    //子表单onchange的回调
    let attach;
    //如果有children则获取children的下拉选项
    item.type === 'list' &&
      item.children &&
      item.children.type === 'list' &&
      this.getFieldOptions(channel.channel_type, item.children.key, { [item.key]: value }, channel.channel_id);
    let data = this.templateObj;
    data[channel.channel_id].formValue[item.key] = value;
    this.templateObj = { ...data };
  }
  getFormValue(id, key, children, obj) {
    //用于表单验证
    const data = { [key]: this.templateObj[id].formValue[key] };
    children && (data[children.key] = this.templateObj[id].formValue[children.key]);
    return data;
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请上传JPG/PNG文件!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('上传文件请小于2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  // 图片预览
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setPreviewImage(file.url || file.preview);
    this.setPreviewVisible(true);
  };
  getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return '';
  }
  handleChange = ({ file, fileList }, channelObj, name) => {
    this.setState({
      [name]: [...fileList],
    });
    if (file.status === 'done') {
      message.success('上传成功');
      const url = file.response.data[0].url;
      this.setPlaceImageData(url, channelObj, name);
      this.setState({
        [name]: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: file.response.data[0].url,
          },
        ],
      });
    }
  };
  handleRemove = (file, channelObj, name) => {
    // state 置空
    this.setState({
      [name]: [],
    });
    // 删除 templateObj 图片相关属性
    delete this.templateObj[channelObj.channel_id].formValue[name];
    delete this.templateObj[channelObj.channel_id].formValue[`${name}Width`];
    delete this.templateObj[channelObj.channel_id].formValue[`${name}Height`];
  };
  handleCancel = () => this.setPreviewVisible(false);
  getChildForm = (selectedWayItem, item) => {
    //返回子表单组件，channel：渠道信息，item：子表单信息
    const channelObj = JSON.parse(selectedWayItem.value);
    const { getFieldDecorator } = this.props.form;
    // 上传url
    const uploadUrl = api.analysis.picture;
    const headers = { token: this.getCookie('token') };
    const extraPramas = { type: 'public' };
    // 获取子表单的值
    // 上传按钮
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    this.childFormKey.push(`${selectedWayItem.id}${item.key}`);
    return (
      <Fragment>
        <Form.Item label={this.getFormItemName(item)} className="children-form-item">
          {getFieldDecorator(`${selectedWayItem.id}${item.key}`, {
            rules: [
              {
                required: true,
                validator: (rule, value, callback) =>
                  this.checkForm(rule, this.getFormValue(selectedWayItem.id, item.key, item.children), callback, '此项必填'),
              },
            ],
            initialValue: this.templateObj[channelObj.channel_id].formValue[item.key],
          })(
            item.type === 'text' ? (
              <Input
                className="md-input"
                value={this.templateObj[channelObj.channel_id].formValue[item.key]}
                onChange={(e) => this.setFormValue(e.target.value, channelObj, item)}
                disabled={this.props.isNewModalUnedit}
              />
            ) : item.type === 'imagePlaceComponent' ? (
              <Select
                className="lg-select select-group"
                value={this.templateObj[channelObj.channel_id].formValue[item.key]}
                onChange={(value) => this.setFormValue(value, channelObj, item)}
                disabled={this.props.isNewModalUnedit}>
                {item.data &&
                  item.data.map((el) => {
                    return <Select.Option key={el.code}>{el.name}</Select.Option>;
                  })}
              </Select>
            ) : item.type === 'imageFileComponent' ? (
              <div>
                <Upload
                  accept=".jpg,.png"
                  action={uploadUrl}
                  headers={headers}
                  data={extraPramas}
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={this.beforeUpload}
                  onPreview={this.handlePreview}
                  onRemove={(e) => this.handleRemove(e, channelObj, 'surveyImg1')}
                  onChange={(e) => this.handleChange(e, channelObj, 'surveyImg1')}
                  fileList={this.state.surveyImg1}>
                  {this.state.surveyImg1.length >= 1 ? null : uploadButton}
                </Upload>
                <Upload
                  accept=".jpg,.png"
                  action={uploadUrl}
                  headers={headers}
                  data={extraPramas}
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={this.beforeUpload}
                  onPreview={this.handlePreview}
                  onRemove={(e) => this.handleRemove(e, channelObj, 'surveyImg2')}
                  onChange={(e) => this.handleChange(e, channelObj, 'surveyImg2')}
                  fileList={this.state.surveyImg2}>
                  {this.state.surveyImg2.length >= 1 ? null : uploadButton}
                </Upload>
                <p style={{ marginTop: '-20px' }}>支持jpg/jpeg/png/gif格式，不超过2M</p>
                {/* <Upload listType="picture-card" className="avatar-uploader" showUploadList={false} action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                  {this.templateObj[channelObj.channel_id].formValue.surveyImg2 ? (
                    <img src={this.templateObj[channelObj.channel_id].formValue.surveyImg2} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload> */}
              </div>
            ) : (
              <Select
                className="lg-select select-group"
                value={this.templateObj[channelObj.channel_id].formValue[item.key]}
                onChange={(value) => this.setFormValue(value, channelObj, item)}
                disabled={this.props.isNewModalUnedit}>
                {this.templateObj[channelObj.channel_id].data[item.key] &&
                  this.templateObj[channelObj.channel_id].data[item.key].map((el) => {
                    return <Select.Option key={el.value}>{el.name}</Select.Option>;
                  })}
              </Select>
            )
          )}
        </Form.Item>

        {item.children && this.getChildForm(selectedWayItem, item.children)}

        <Modal visible={this.previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="preview" style={{ width: '100%' }} src={this.previewImage} />
        </Modal>
      </Fragment>
    );
  };

  // 浮标调研按钮样式修改
  btnStyleChange = (changeValue) => {
    let changeStyle = {};
    for (const key in changeValue) {
      changeStyle[key] = this.rgbaJSONToString(changeValue[key]);
    }
    this.setState({
      btnStyle: {
        ...this.state.btnStyle,
        ...changeStyle,
      },
    });
  };

  // rgba JSON转字符串
  rgbaJSONToString = (rgbaObj) => {
    return `rgba(${rgbaObj.r},${rgbaObj.g},${rgbaObj.b},${rgbaObj.a})`;
  };

  // 浮标文本修改
  btnTextChange = (e) => {
    this.setState({
      btnText: e.target.value,
    });
  };

  // 浮标类型修改
  fixedTypeChange = (e) => {
    this.setState({
      fixedType: e.target.value,
    });
  };

  // 调研链接修改
  surveyLinkChange = (value) => {
    let surveyLink = '';
    this.mySurveyList.forEach((item) => {
      item.id === value && (surveyLink = item.collectorUrlWithDomain);
    });
    this.setState({
      surveyLink,
    });
  };

  // 是否开启催收
  onOpenCollectionChange = (checked) => {
    this.setState({
      openCollection: checked,
    });
  };

  // 折叠面板点击回调
  onCollapseChange = (key) => {
    // 点击折叠收起时检测表单填写
    const { activeKey, passCheckKeys, unPassCheckKeys } = this.state;
    const { validateFields } = this.props.form;
    if (!this.props.isNewModalUnedit) {
      switch (activeKey) {
        // 校验表单
        case 'basic':
          validateFields(['name', 'surveyId'], (errors) => {
            setPassCheckKeys(!errors);
          });
          break;
        case 'trigger':
          validateFields(['channel', 'event', ...this.childFormKey], (errors) => {
            setPassCheckKeys(!errors);
          });
          break;
        case 'disturb':
          validateFields(['disturb', 'pushTime', 'recoveryWeightAdd'], (errors) => {
            setPassCheckKeys(!errors);
          });
          break;
        case 'control':
          validateFields(['msgValidDays', 'msgRepeatAnswer'], (errors) => {
            setPassCheckKeys(!errors);
          });
          break;
        case 'recycle':
          break;
        case 'collection':
          validateFields(['collectionPeriod', 'collectionNum', 'collectionChannel', 'collectionLimit'], (errors) => {
            setPassCheckKeys(!errors);
          });
          break;
        default:
          break;
      }
    }
    this.setState({ activeKey: key, passCheckKeys, unPassCheckKeys });

    function setPassCheckKeys(compelete) {
      if (compelete) {
        passCheckKeys.push(activeKey);
        const index = unPassCheckKeys.indexOf(activeKey);
        index > -1 && unPassCheckKeys.splice(index, 1);
      } else {
        unPassCheckKeys.push(activeKey);
        const index = passCheckKeys.indexOf(activeKey);
        index > -1 && passCheckKeys.splice(index, 1);
      }
    }
  };

  // 提交表单时控制通过icon显隐
  checkCollapsePassOnsubmit = () => {
    const { validateFields } = this.props.form;
    const passCheckKeys = [],
      unPassCheckKeys = [];
    validateFields(['name', 'surveyId'], (errors) => {
      !errors ? passCheckKeys.push('basic') : unPassCheckKeys.push('basic');
    });
    validateFields(['channel', 'event', ...this.childFormKey], (errors) => {
      !errors ? passCheckKeys.push('trigger') : unPassCheckKeys.push('trigger');
    });
    validateFields(['disturb', 'pushTime', 'recoveryWeightAdd'], (errors) => {
      !errors ? passCheckKeys.push('disturb') : unPassCheckKeys.push('disturb');
    });
    validateFields(['msgValidDays', 'msgRepeatAnswer'], (errors) => {
      !errors ? passCheckKeys.push('control') : unPassCheckKeys.push('control');
    });
    validateFields(['collectionPeriod', 'collectionNum', 'collectionChannel', 'collectionLimit'], (errors) => {
      !errors ? passCheckKeys.push('collection') : unPassCheckKeys.push('collection');
    });
    this.setState({ passCheckKeys, unPassCheckKeys });
  };

  // 答题控制选项变化
  handleMsgControlChange = (obj) => {
    this.setState({ msgValidObj: obj });
  };

  getMsgValidInitData(form) {
    // 新增
    if (form) {
      if (Object.keys(form).length === 0) {
        return { msgValidDays: 'unlimit' };
      } else {
        return form;
      }
    } else {
      return { msgValidDays: 'unlimit' };
    }
  }

  getContent() {
    const { getFieldDecorator } = this.props.form;
    const { isNewModalUnedit } = this.props;
    const { selectedWayItem, btnStyle, btnText, fixedType, surveyLink, passCheckKeys, unPassCheckKeys, openCollection } = this.state;
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
    };
    const form = toJS(this.form);
    return (
      <Form id="cemyun-form" className="survey-form-wrapper" onSubmit={this.submit} {...layout}>
        <Collapse bordered={false} accordion defaultActiveKey={['basic']} onChange={this.onCollapseChange}>
          <Panel
            header={
              <div className="form-group-title">
                <div className="title-text">基本信息</div>
                {passCheckKeys.includes('basic') && <i className="iconfont icon-chenggong" />}
                {unPassCheckKeys.includes('basic') && <i className="iconfont icon-tishiicon" />}
              </div>
            }
            key="basic"
            forceRender>
            <Form.Item label={`${intl.get('Research')}${intl.get('Name')}`}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: `${intl.get('Research')}${intl.get('Name')}${intl.get('cannotbeempty')}` }],
                initialValue: form.name ? form.name : '',
              })(
                <Input className="md-input" placeholder={`${intl.get('PleaseEnter')}${intl.get('Research')}${intl.get('Name')}`} disabled={isNewModalUnedit} />
              )}
            </Form.Item>
            <Form.Item label={`${intl.get('Select')}${intl.get('Questionnaire')}`}>
              {getFieldDecorator('surveyId', {
                rules: [{ required: true, message: intl.get('Pleaseselectthequestionnaire') }],
                initialValue: form.surveyId ? form.surveyId : '',
              })(
                <Select
                  placeholder={intl.get('Pleaseselectthequestionnaire')}
                  className="lg-select survey-select"
                  onChange={this.surveyLinkChange}
                  disabled={isNewModalUnedit}>
                  {this.mySurveyList &&
                    this.mySurveyList.map((item, index) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.surveyNameAndRemark}
                          <span className="pull-right">
                            <a
                              className="iconfont icon-card-bianji"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${process.env.REACT_APP_Base_Url}/wj/detail?surveyId=${item.id}&surveyName=${item.surveyNameAndRemark}`}
                            />
                            {/* <Link to={`/wj/detail?surveyId=${item.id}&surveyName=${item.surveyNameAndRemark}`} className="iconfont icon-card-bianji" /> */}
                            <a
                              className="iconfont icon-yanjing"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${process.env.REACT_APP_Base_Url}/survey/survey/${item.id}/test?notOnlyProduction=false`}
                              title={intl.get('Preview')}
                            />
                          </span>
                        </Option>
                      );
                    })}
                </Select>
              )}
              <a className="survey-list-reload" onClick={this.reloadMySurveyList}>
                <i className={`iconfont icon-shuaxin ${this.surveyLoading && 'rotating-icon'}`} />
              </a>
            </Form.Item>
          </Panel>
          <Panel
            header={
              <div className="form-group-title">
                <div className="title-text">触发条件</div>
                {passCheckKeys.includes('trigger') && <i className="iconfont icon-chenggong" />}
                {unPassCheckKeys.includes('trigger') && <i className="iconfont icon-tishiicon" />}
              </div>
            }
            key="trigger"
            forceRender>
            <Form.Item label={intl.get('ResearchMethods')} className="typeItem">
              {/* 调研方式 */}
              {getFieldDecorator('channel', {
                initialValue: form.channel ? form.channel : [],
                rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('ResearchMethods').toLowerCase()}` }],
              })(
                <Select className="lg-select" onChange={(e) => this.channelChange(e)} disabled={isNewModalUnedit}>
                  {this.wayList.map((item) => {
                    return <Select.Option key={item.value}>{item.label}</Select.Option>;
                  })}
                </Select>
              )}
            </Form.Item>
            {selectedWayItem &&
              this.templateObj[selectedWayItem.id] &&
              this.templateObj[selectedWayItem.id].isShow &&
              (this.templateObj[selectedWayItem.id].format.length ? (
                <Fragment>
                  {this.templateObj[selectedWayItem.id].format.map((el) => {
                    return this.getChildForm(selectedWayItem, el);
                  })}
                  <Form.Item label="附加url参数" className="children-form-item">
                    {/* 附加url参数 */}
                    {getFieldDecorator('urlQuery', {
                      initialValue: form.urlQuery ? form.urlQuery : '',
                    })(
                      <Input
                        className="md-input"
                        placeholder="a=xxx&b=xxx"
                        disabled={isNewModalUnedit}
                        onChange={(e) => this.setFormValue(e.target.value, JSON.parse(selectedWayItem.value), { key: 'urlQuery' })}
                      />
                    )}
                  </Form.Item>
                </Fragment>
              ) : null)}
            {!passiveWay.includes(this.channelWay) && (
              <Form.Item label={`${intl.get('Select')}${intl.get('Trigger')}`} className="survey-filter">
                {/* 选择触点 */}
                {getFieldDecorator('event', {
                  rules: [
                    {
                      required: true,
                      validator: (rule, value, callback) => this.checkEventForm(rule, value, callback, intl.get('PleaseselectEvent')),
                    },
                  ],
                  initialValue: {
                    eventName: form.eventName || '',
                    eventConfigFilter: form.eventConfigFilter
                      ? form.eventConfigFilter
                      : {
                          type: 'rules_event',
                          logic: 'and',
                          rules: [
                            {
                              logic: 'and',
                              type: 'rules_event',
                              rules: [],
                            },
                          ],
                        },
                  },
                })(<EventSelect disabled={isNewModalUnedit} />)}
              </Form.Item>
            )}
            {this.channelWay === 17 && (
              <Fragment>
                <Form.Item label={intl.get('FixedType')} className="survey-filter">
                  {/* 浮标类型 */}
                  {getFieldDecorator('fixedSurveyType', {
                    initialValue: form.fixedSurveyType ? form.fixedSurveyType : 'sdk',
                    rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('FixedType').toLowerCase()}` }],
                  })(
                    <Radio.Group onChange={this.fixedTypeChange} disabled={isNewModalUnedit}>
                      {fixedTypes.map((item) => {
                        return (
                          <Radio value={item.value} key={item.value}>
                            {item.label}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  )}
                </Form.Item>
                {fixedType === 'sdk' ? (
                  <Fragment>
                    <Form.Item label={intl.get('Placement')} className="survey-filter">
                      {/* 浮标位置 */}
                      {getFieldDecorator('fixedSurveyDisplay', {
                        initialValue: form.fixedSurveyDisplay ? form.fixedSurveyDisplay : { type: 'all', placement: 'right' },
                        rules: [
                          {
                            required: true,
                            validator: (rule, value, callback) => this.checkFixedDisplay(rule, value, callback, `${intl.get('Thisitemisrequired')}`),
                          },
                        ],
                      })(<FixedSurveyDisplay disabled={isNewModalUnedit} />)}
                    </Form.Item>
                    <Form.Item label={intl.get('FixedSurveyText')} className="survey-filter">
                      {/* 浮标文案 */}
                      {getFieldDecorator('fixedSurveyText', {
                        initialValue: form.fixedSurveyText ? form.fixedSurveyText : null,
                        rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('FixedSurveyText').toLowerCase()}` }],
                      })(
                        <Input
                          className="md-input"
                          placeholder="仅支持六个字以内说明"
                          maxLength={6}
                          onChange={this.btnTextChange}
                          disabled={isNewModalUnedit}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={intl.get('FixedSurveyStyle')} className="survey-filter">
                      {/* 浮标样式 */}
                      {getFieldDecorator('fixedSurveyStyle', {
                        initialValue: form.fixedSurveyStyle
                          ? form.fixedSurveyStyle
                          : {
                              backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
                              borderColor: { r: 217, g: 217, b: 222, a: 1 },
                              color: { r: 5, g: 10, b: 19, a: 1 },
                            },
                        rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('FixedSurveyStyle').toLowerCase()}` }],
                      })(<FixedSurveyStyle btnStyleChange={this.btnStyleChange} disabled={isNewModalUnedit} />)}
                    </Form.Item>
                    <div className="survey-form-preview">
                      <div className="survey-form-preview-label">样式预览</div>
                      <div className="survey-form-preview-wrapper">
                        <div
                          className="survey-form-preview-btn"
                          style={{ backgroundColor: btnStyle.backgroundColor, color: btnStyle.color, borderColor: btnStyle.borderColor }}>
                          <div className="survey-form-preview-btn-text">{btnText}</div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="survey-form-preview">
                      <div className="survey-form-preview-label">代码</div>
                      <div className="survey-form-preview-wrapper">
                        <div className="survey-form-preview-title">浮标调研SDK引入</div>
                        <p className="copyCode">
                          {intl.get('Pleasemakesurethiscodeisoneverypageofyourwebsite.Wesuggestyoupasteitinfrontof&lt;/head&gt;labelimmediately')}
                        </p>
                        <pre>{`<script src="${process.env.REACT_APP_Base_Url}/sdk/webSurvey.js"></script>`}</pre>
                        <CopyToClipboard
                          text={`<script src="${process.env.REACT_APP_Base_Url}/sdk/webSurvey.js"></script>`}
                          onCopy={() => {
                            message.success(intl.get('Copysucceeded'));
                          }}>
                          <a className="copy-btn">{intl.get('CopyCode')}</a>
                        </CopyToClipboard>
                        <div className="survey-form-preview-title">触发调研埋点代码</div>
                        <p className="copyCode">请把此代码放在触发按钮的点击事件回调函数中，并把'elementId'替换成按钮ID</p>
                        <pre>{`window.CMYSurvey.quickSurvey(${surveyLink}, elementId)`}</pre>
                        <CopyToClipboard
                          text={`window.CMYSurvey.quickSurvey(${surveyLink}, elementId)`}
                          onCopy={() => {
                            message.success(intl.get('Copysucceeded'));
                          }}>
                          <a className="copy-btn">{intl.get('CopyCode')}</a>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
            <Form.Item label={`${intl.get('Match')}${intl.get('Mode')}`} className="typeItem">
              {/* 匹配方式 */}
              {getFieldDecorator('matchingType', {
                initialValue: form.matchingType ? form.matchingType : 1,
                rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('Match')}${intl.get('Mode').toLowerCase()}` }],
              })(
                <Select className="sm-select" disabled={isNewModalUnedit}>
                  {MetchMhethodOptions.map((item) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Panel>
          {!passiveWay.includes(this.channelWay) && [
            <Panel
              header={
                <div className="form-group-title">
                  <div className="title-text">防打扰规则</div>
                  {passCheckKeys.includes('disturb') && <i className="iconfont icon-chenggong" />}
                  {unPassCheckKeys.includes('disturb') && <i className="iconfont icon-tishiicon" />}
                </div>
              }
              key="disturb"
              forceRender>
              <Form.Item label={intl.get('NonIntrusiveMechanism')}>
                {/* 免打扰机制 */}
                {getFieldDecorator('disturb', {
                  rules: [
                    {
                      required: true,
                      validator: (rule, value, callback) =>
                        this.checkDisturb(rule, value, callback, `${intl.get('Pleaseselect')}${intl.get('NonIntrusiveMechanism').toLowerCase()}`),
                    },
                  ],
                  initialValue: form.disturb ? form.disturb : { inviteLimitSurveyAction: 'unlimit', inviteLimitDays: null, inviteLimitScope: null },
                })(<Disturb mySurveyList={this.mySurveyList} disabled={isNewModalUnedit} form={this.props.form} />)}
              </Form.Item>
              <Form.Item label={intl.get('IssuingTime')}>
                {/* 触发时间 */}
                {getFieldDecorator('pushTime', {
                  rules: [
                    {
                      required: true,
                      validator: (rule, value, callback) =>
                        this.checkForm(rule, value, callback, `${intl.get('Pleaseselect')}${intl.get('IssuingTime').toLowerCase()}`),
                    },
                  ],
                  initialValue: form.pushTime ? form.pushTime : { inviteDelay: null, inviteTimeRange: null },
                })(<TriggerTime disabled={isNewModalUnedit} />)}
              </Form.Item>
              <Form.Item label={`${intl.get('RecoveryWeightAdd')}`}>
                {/* 回收权重（附加值） */}
                {getFieldDecorator('recovery', {
                  rules: [{ required: true, message: intl.get('PleaseinputRecoveryWeightAdd') }],
                  initialValue: { recoveryWeightAdd: form.recoveryWeightAdd ? form.recoveryWeightAdd : 0, recoveryWeight: form.recoveryWeight || false },
                })(<RecoveryWeight disabled={isNewModalUnedit} />)}
              </Form.Item>
              <Form.Item label="回收限额">
                {/* 回收限额 */}
                {getFieldDecorator('collectionLimit', {
                  initialValue: form.disturb
                    ? {
                        limitDim: form.disturb.limitDim ? form.disturb.limitDim : 'all',
                        limitOperate: '<=',
                        limitCount: form.disturb.limitCount,
                      }
                    : { limitDim: 'all', limitOperate: '<=', limitCount: null },
                })(<CollectionLimit disabled={isNewModalUnedit} options={JSON.parse(JSON.stringify(this.tagOptionsList))} />)}
              </Form.Item>
            </Panel>,
            <Panel
              header={
                <div className="form-group-title">
                  <div className="title-text">答题控制</div>
                  {passCheckKeys.includes('control') && <i className="iconfont icon-chenggong" />}
                  {unPassCheckKeys.includes('control') && <i className="iconfont icon-tishiicon" />}
                </div>
              }
              key="control"
              forceRender>
              <Form.Item label={`${intl.get('MessageValidity')}`}>
                {/* 消息有效期 */}
                {getFieldDecorator('msgValidDays', {
                  rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('MessageValidity')}` }],
                  initialValue: this.getMsgValidInitData(form),
                })(<MsgValidDays disabled={isNewModalUnedit} onChange={this.handleMsgControlChange} />)}
              </Form.Item>
              <Form.Item label={`${intl.get('RepeatAnswer')}`}>
                {/* 重复答题 */}
                {getFieldDecorator('msgRepeatAnswer', {
                  rules: [{ required: true, message: `${intl.get('Pleaseselect')}${intl.get('RepeatAnswer')}` }],
                  initialValue: form.msgRepeatAnswer ? form.msgRepeatAnswer : 'false',
                })(
                  <Radio.Group disabled={isNewModalUnedit}>
                    {repeatList.map((item) => {
                      return (
                        <Radio value={item.value} key={item.value}>
                          {item.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                )}
              </Form.Item>
            </Panel>,
            <Panel
              header={
                <div className="form-group-title">
                  <div className="title-text">催收机制</div>
                  <div className="title-tips">对本调研有效期内未打开调研的用户进行催收</div>
                  {passCheckKeys.includes('collection') && <i className="iconfont icon-chenggong" />}
                  {unPassCheckKeys.includes('collection') && <i className="iconfont icon-tishiicon" />}
                </div>
              }
              key="collection"
              forceRender>
              <Form.Item label={intl.get('IsOpenCollection')}>
                {/* 是否开启催收 */}
                {/* {getFieldDecorator('openCollection', {
                  initialValue: form.openCollection ? form.openCollection : false,
                })(
                  <Switch
                    size="small"
                    checked={openCollection}
                    onChange={this.onOpenCollectionChange}
                    disabled={isNewModalUnedit}
                  />
                )} */}
                <Switch size="small" checked={openCollection} onChange={this.onOpenCollectionChange} disabled={isNewModalUnedit} />
              </Form.Item>
              {openCollection && [
                <Form.Item label={intl.get('CollectionPeriod')}>
                  {/* 催收周期 */}
                  {getFieldDecorator('collectionPeriod', {
                    rules: [
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          this.checkNormalFormItem(rule, value, callback, `${intl.get('Pleaseselect')}${intl.get('CollectionPeriod').toLowerCase()}`),
                      },
                    ],
                    initialValue: form.collectionConfig
                      ? {
                          dim: 'all',
                          period: form.collectionConfig.period,
                          periodNum: form.collectionConfig.periodNum,
                        }
                      : {
                          dim: 'all',
                          period: null,
                          periodNum: null,
                        },
                  })(<CollectionPeriod disabled={isNewModalUnedit} />)}
                </Form.Item>,
                <Form.Item label={intl.get('Amountofrecovery')}>
                  {/* 回收量 */}
                  {getFieldDecorator('collectionNum', {
                    rules: [
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          this.checkNormalFormItem(rule, value, callback, `${intl.get('Pleaseselect')}${intl.get('Amountofrecovery').toLowerCase()}`),
                      },
                    ],
                    initialValue: form.collectionConfig
                      ? {
                          limitDim: form.collectionConfig.limitDim ? form.collectionConfig.limitDim : 'all',
                          limitOperate: '<=',
                          limitCount: form.collectionConfig.limitCount,
                        }
                      : { limitDim: 'all', limitOperate: '<=', limitCount: null },
                  })(<CollectionNum disabled={isNewModalUnedit} options={JSON.parse(JSON.stringify(this.tagOptionsList))} />)}
                </Form.Item>,
                // <Form.Item label={intl.get('CollectionChannel')}>
                //   {getFieldDecorator('collectionChannel', {
                //     rules: [
                //       {
                //         required: true,
                //         validator: (rule, value, callback) =>
                //           this.checkCollectionChannel(rule, value, callback, `${intl.get('Pleaseselect')}${intl.get('CollectionChannel').toLowerCase()}`),
                //       },
                //     ],
                //     initialValue: form.collectionConfig
                //       ? {
                //           pushConfig: form.collectionConfig.pushConfig,
                //           maxNum: form.collectionConfig.maxNum,
                //         }
                //       : {
                //           pushConfig: [{}],
                //           maxNum: null,
                //         },
                //   })(<CollectionChannel disabled={isNewModalUnedit} wayList={this.wayList} />)}
                // </Form.Item>,
              ]}
            </Panel>,
          ]}
        </Collapse>
      </Form>
    );
  }
  render() {
    return (
      <UXSDrawer
        show={this.props.isShowNewModal}
        onHide={this.closeModal}
        className="fill survey-modal scroll-drawer full-right"
        title={
          <div>
            <div>{`${this.props.id || this.props.data ? intl.get(this.props.isNewModalUnedit ? 'View' : 'Edit') : intl.get('Create')}${intl.get(
              'Research'
            )}`}</div>
            {!this.props.isNewModalUnedit && (
              <Button type="primary" disabled={this.isButtonDisabled} htmlType="submit" form="cemyun-form" onClick={this.submit}>
                {this.props.id || this.props.data ? intl.get('Confirm') : `${intl.get('Start')}${intl.get('Research')}`}
              </Button>
            )}
          </div>
        }
        width={800}>
        <Modal
          title={`${intl.get('GiveUp')}${intl.get('Setting')}`}
          width={300}
          centered={true}
          height={132}
          visible={this.show}
          okText={intl.get('GiveUp')}
          cancelText={`${intl.get('Continue')}${intl.get('Edit')}`}
          onCancel={() => this.setShow(false)}
          onOk={() => {
            this.props.hideNewModal && this.props.hideNewModal();
            this.props.closeModal && this.props.closeModal('Survey');
          }}>
          <p>{intl.get('Doyouwanttogiveupeditingtriggeredresearch')}</p>
        </Modal>
        {this.isReady ? this.getContent() : <Loading />}
      </UXSDrawer>
    );
  }
}

export default Form.create()(basicSurveyModal);
