import AnalyseTableLayout from '../../containers/AnalyseTableLayout/index';
export default {
  title: '分布分析',
  components: [
    {
      component: AnalyseTableLayout,
      props: {
        analyseType: 'distributionAnalytics',
        type: 7,
        title: '分布分析',
      },
    },
  ],
};
