import AnalyseDistribution from '../../containers/AnalyseDistribution/index';

export default {
  title: '分布分析',
  components: [
    {
      component: AnalyseDistribution,
    },
  ],
};
