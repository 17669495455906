// import BoardCustom from './views/BoardCustom';
// import BoardCustomList from './views/BoardCustomList';
// import Personal from './Personal/Personal/index.lazy';
// import PersonnalWorkbench from './Personal/PersonnalWorkbench';
// import Role from './Personal/Role';
// import Permissions from './Personal/Permissions';
// import DataCleanRule from './DataCleanRule/index';
// import RolesDetail from './Personal/Role/Detail';
// import Account from './Personal/Account';
// import NotificationList from './Personal/NotificationList';
// import OperationLog from './Personal/OperationLog';
// import AccountDetail from './Personal/Account/Detail';
// // import SDKRemoteConfig from './Setting/SDKRemoteConfig';
// import SurveyChannelTable from './Setting/SurveyChannel';
// import MsgTemplate from './Setting/MsgTemplate';
// import EditMsgTemplate from './Setting/MsgTemplate/Edit';
// import PointsManager from './Setting/PointsManager/index';
// import CustomParameters from './Setting/CustomParameters/index';
// import CreateCustomParameters from './Setting/CustomParameters/CreateCustomParameters/index';
// import ParameterManagement from './Setting/ParameterManagement/index';
// import PageGroup from './Setting/PageGroup/index';
// // import ChannelManage from './Setting/Channel/index.lazy';
// // import VisualBuriedPoint from './Setting/VisualBuriedPoint';
// // import BuriedPoint from './Setting/VisualBuriedPoint/BuriedPoint';
// // import Page from './Setting/Page/index.lazy';
// import ProductCompare from './Product/Compare/index.lazy';
// import ProductManagement from './Product/Management/index.lazy';
// import WarningRule from './Report/EarlyWarning/Overview/index.lazy';
// import WarningList from './Report/WarningList';
// import BuriedPoint from './Setting/VisualBuriedPoint/BuriedPoint';
// import BoardTemplate from './views/BoardTemplate';
// // import RelationshipChart from './containers/RelationshipChart';
// // import EmotionMap from './containers/EmotionMap';
// import AnswerDetail from './containers/Survey/answerDetail';
// import AccountGroup from './component/AccountGroup';
// import Design from './views/Design';
// import WarningClosedLoop from './Report/WarningClosedLoop';
// import AnalyseAlgorithm from './containers/AnalyseAlgorithm/Table';
// import AnalyseAlgorithmDetail from './containers/AnalyseAlgorithm/Detail';
// import RWA from './containers/RWA/Table';
// import RWADetail from './containers/RWA/Detail';
// import BusinessModel from './containers/BusinessModel';
// import Worksheet from './containers/Worksheet/Table';
// import WorksheetDetail from './containers/Worksheet/Detail';
// import TargetManage from './Target/TargetManage';
// import TargetManagehouse from './Target/TargetManage/targetHouseDetail';
// import IndicatorsSystem from './IndicatorsSystem/index';
// import SystemDetail from './IndicatorsSystem/SystemDetail';
// import CreateUpDown from './containers/Worksheet/createUpDown';
// import TreeChart from './TreeChart';
// import TreeAnalyse from './TreeAnalyse';
// import Blacklist from './Setting/Blacklist';
// import BlacklistRule from './Setting/BlacklistRule';
// // import BlacklistVisitor from './Setting/Blacklist/BlacklistVisitor';
// import SurveySetting from './containers/SurveySetting';
// import SurveySettingDetail from './containers/SurveySetting/edit';
import React from 'react';
const BoardCustom = React.lazy(() => import('./views/BoardCustom'));
const BoardCustomList = React.lazy(() => import('./views/BoardCustomList'));
const Personal = React.lazy(() => import('./Personal/Personal/index.lazy'));
const Role = React.lazy(() => import('./Personal/Role'));
const RolesDetail = React.lazy(() => import('./Personal/Role/Detail'));
const Account = React.lazy(() => import('./Personal/Account'));
const AccountDetail = React.lazy(() => import('./Personal/Account/Detail'));
const SurveyChannelTable = React.lazy(() => import('./Setting/SurveyChannel'));
const MsgTemplate = React.lazy(() => import('./Setting/MsgTemplate'));
const EditMsgTemplate = React.lazy(() => import('./Setting/MsgTemplate/Edit'));
const PointsManager = React.lazy(() => import('./Setting/PointsManager/index'));
const CustomParameters = React.lazy(() => import('./Setting/CustomParameters/index'));
const CreateCustomParameters = React.lazy(() => import('./Setting/CustomParameters/CreateCustomParameters/index'));
const ParameterManagement = React.lazy(() => import('./Setting/ParameterManagement/index'));
const PageGroup = React.lazy(() => import('./Setting/PageGroup/index'));
const ProductCompare = React.lazy(() => import('./Product/Compare/index.lazy'));
const ProductManagement = React.lazy(() => import('./Product/Management/index.lazy'));
const WarningRule = React.lazy(() => import('./Report/EarlyWarning/Overview/index.lazy'));
const WarningList = React.lazy(() => import('./Report/WarningList'));
const BuriedPoint = React.lazy(() => import('./Setting/VisualBuriedPoint/BuriedPoint'));
const BoardTemplate = React.lazy(() => import('./views/BoardTemplate'));
const AnswerDetail = React.lazy(() => import('./containers/Survey/answerDetail'));
const AccountGroup = React.lazy(() => import('./component/AccountGroup'));
const TreeChart = React.lazy(() => import('./TreeChart'));
const TreeAnalyse = React.lazy(() => import('./TreeAnalyse'));
const Design = React.lazy(() => import('./views/Design'));
const SurveySetting = React.lazy(() => import('./containers/SurveySetting'));
const SurveySettingDetail = React.lazy(() => import('./containers/SurveySetting/edit'));
const Worksheet = React.lazy(() => import('./containers/Worksheet/Table'));
const WorksheetDetail = React.lazy(() => import('./containers/Worksheet/Detail'));
const CreateUpDown = React.lazy(() => import('./containers/Worksheet/createUpDown'));
const TargetManage = React.lazy(() => import('./Target/TargetManage'));
const TargetManagehouse = React.lazy(() => import('./Target/TargetManage/targetHouseDetail'));
const IndicatorsSystem = React.lazy(() => import('./IndicatorsSystem/index'));
const SystemDetail = React.lazy(() => import('./IndicatorsSystem/SystemDetail'));
const AnalyseAlgorithm = React.lazy(() => import('./containers/AnalyseAlgorithm/Table'));
const AnalyseAlgorithmDetail = React.lazy(() => import('./containers/AnalyseAlgorithm/Detail'));
const RWA = React.lazy(() => import('./containers/RWA/Table'));
const RWADetail = React.lazy(() => import('./containers/RWA/Detail'));
const BusinessModel = React.lazy(() => import('./containers/BusinessModel'));
const PersonnalWorkbench = React.lazy(() => import('./Personal/PersonnalWorkbench'));
const NotificationList = React.lazy(() => import('./Personal/NotificationList'));
const OperationLog = React.lazy(() => import('./Personal/OperationLog'));
const DataCleanRule = React.lazy(() => import('./DataCleanRule'));
const Blacklist = React.lazy(() => import('./Setting/Blacklist'));
const BlacklistRule = React.lazy(() => import('./Setting/BlacklistRule'));
const WarningClosedLoop = React.lazy(() => import('./Report/WarningClosedLoop'));
const Permissions = React.lazy(() => import('./Personal/Permissions'));
const VirtualField = React.lazy(() => import('./Setting/VirtualField'));

// key：权限控制的key，path：路由路径，component：页面级组件

const homeRouteConfig = {
  data: [
    {
      key: 'test',
      path: '/data/test',
      component: TreeChart,
    },
    {
      key: 'tree',
      path: '/data/tree',
      component: TreeAnalyse,
    },
    {
      key: 'contact',
      path: '/data/contact',
      component: Design,
    },
    {
      key: 'feedbackGroup',
      path: '/data/surveySetting',
      component: SurveySetting,
    },
    {
      key: 'feedbackGroup',
      path: '/data/surveySetting/detail',
      component: SurveySettingDetail,
    },
    {
      key: 'workview',
      path: '/data/worksheet',
      component: Worksheet,
    },
    {
      key: 'workview',
      path: '/data/worksheet/detail',
      component: WorksheetDetail,
    },
    {
      key: 'workview',
      path: '/data/worksheet/edit',
      component: WorksheetDetail,
      props: {
        isEdit: true,
      },
    },
    {
      key: 'workview',
      path: '/data/worksheet/createUpDown',
      component: CreateUpDown,
    },
    {
      key: 'indicators',
      path: '/data/targetManage',
      component: TargetManage,
    },
    {
      key: 'targetManageHouse',
      path: '/data/targetManageHouse',
      component: TargetManagehouse,
    },
    {
      key: 'indicatorsSystem',
      path: '/data/indicatorsSystem',
      component: IndicatorsSystem,
    },
    {
      key: 'systemDetail',
      path: '/data/indicatorsSystemDetail',
      component: SystemDetail,
    },
  ],
  analytics: [
    {
      key: 'algorithm',
      path: '/analytics/algorithmAnalytics',
      component: AnalyseAlgorithm,
    },
    {
      key: 'algorithm',
      path: '/analytics/algorithmAnalytics/detail',
      component: AnalyseAlgorithmDetail,
    },
    {
      key: 'rwa',
      path: '/analytics/RWA',
      component: RWA,
    },
    {
      key: 'rwa',
      path: '/analytics/RWA/detail',
      component: RWADetail,
    },
    {
      key: 'model',
      path: '/analytics/model',
      component: BusinessModel,
    },
  ],
  wj: [
    {
      key: 'survey',
      path: '/wj/list',
      component: BoardTemplate,
    },
    {
      key: 'survey',
      path: '/wj/detail',
      component: BoardTemplate,
    },
    {
      key: 'survey',
      path: '/wj/survey/detail/:detail',
      component: AnswerDetail,
    },
    {
      key: 'survey',
      path: '/wj/surveyDownload',
      component: BoardTemplate,
    },
    {
      key: 'push',
      path: '/wj/surveyPush/:detail',
      component: BoardCustom,
    },
    {
      key: 'push',
      path: '/wj/surveyUser/:detail',
      component: BoardCustom,
    },
  ],
  insight: [
    {
      key: 'dashboard',
      path: '/customBoard',
      component: BoardCustomList,
    },
    {
      key: 'dashboard',
      path: '/customDetail/:detail',
      component: BoardCustom,
    },
    {
      key: 'dashboard',
      path: '/customBoard/detail/visitor',
      component: BoardTemplate,
    },
  ],
  design: [
    {
      key: 'customerJourney',
      path: '/design/customerJourney/detail/:detail',
      component: Design,
    },
    {
      key: 'customerJourney',
      path: '/design/customerJourney',
      component: BoardCustomList,
      props: {
        type: 2,
      },
    },
    {
      key: 'blueprint',
      path: '/design/blueprint/detail/:detail',
      component: Design,
    },
    {
      key: 'blueprint',
      path: '/design/blueprint',
      component: BoardCustomList,
      props: {
        type: 3,
      },
    },
    // {
    //   key: 'customerJourney',
    //   path: '/Diagram/picture',
    //   name: '关系图',
    //   component: RelationshipChart,
    // },
    // {
    //   key: 'customerJourney',
    //   path: '/emotion/picture',
    //   name: '情绪图',
    //   component: EmotionMap,
    // },
  ],
  user: [
    {
      key: 'person',
      path: '/person/workbench',
      component: PersonnalWorkbench,
    },
    {
      key: 'person',
      path: '/person',
      component: Personal,
    },
    {
      key: 'person',
      path: '/notification',
      component: NotificationList,
    },
    {
      key: 'operationLog',
      path: '/log',
      component: OperationLog,
    },
  ],
  set: [
    {
      key: 'pageGroup',
      path: '/set/pageGroup',
      name: '页面组',
      component: PageGroup,
    },
    {
      key: 'parameter',
      path: '/set/parameter',
      name: '参数管理',
      component: ParameterManagement,
    },
    {
      key: 'SDK',
      path: '/set/points',
      name: '埋点管理',
      component: PointsManager,
    },
    {
      key: 'SDK',
      path: '/set/customevent/visual-select',
      name: '埋点管理',
      component: BuriedPoint,
    },
    {
      key: 'app',
      path: '/set/push',
      name: '推送渠道',
      component: SurveyChannelTable,
    },
    {
      key: 'template',
      path: '/set/template',
      name: '模板管理',
      component: MsgTemplate,
    },
    {
      key: 'template',
      path: '/set/template/edit',
      name: '模板管理',
      component: EditMsgTemplate,
    },
    {
      key: 'attribute',
      path: '/set/attribute',
      name: '自定义属性',
      component: CustomParameters,
    },
    {
      key: 'attribute',
      path: '/set/attribute/create',
      name: '自定义属性',
      component: CreateCustomParameters,
    },
    {
      key: 'product',
      path: '/product',
      name: '产品列表',
      component: ProductManagement,
    },
    {
      key: 'product',
      path: '/product/compare/:id',
      name: '产品对比',
      component: ProductCompare,
    },
    {
      key: 'role',
      path: '/set/role',
      component: Role,
    },
    {
      key: 'role',
      path: '/set/role/detail',
      component: RolesDetail,
    },
    {
      key: 'permissions',
      path: '/set/permissions',
      component: Permissions,
    },
    {
      key: 'excludeSurvey',
      path: '/set/dataCleanRule',
      component: DataCleanRule,
    },
    {
      key: 'account',
      path: '/set/account',
      component: Account,
    },
    {
      key: 'accountGroup',
      path: '/set/account/group',
      component: AccountGroup,
    },
    {
      key: 'account',
      path: '/set/account/detail',
      component: AccountDetail,
    },
    {
      key: 'blacklist',
      path: '/set/blacklist',
      component: Blacklist,
    },
    {
      key: 'blacklistRule',
      path: '/set/blacklistRule',
      component: BlacklistRule,
    },
    {
      key: 'virtualField',
      path: '/set/virtualField',
      component: VirtualField,
    },
  ],
  action: [
    {
      key: 'warningLog',
      path: '/action/warningLog',
      component: WarningList,
    },
    {
      key: 'warningLog',
      path: '/action/warningLog/dashboard/:detail',
      component: BoardCustom,
    },
    {
      key: 'warningClosedLoop',
      path: '/action/warningClosedLoop',
      component: WarningClosedLoop,
    },
    {
      key: 'myWarningList',
      path: '/action/myWarningList',
      component: WarningClosedLoop,
    },
    {
      key: 'warning',
      path: '/action/warningRule',
      name: '预警',
      component: WarningRule,
    },
    {
      key: 'warningUserGroup',
      path: '/action/warningUserGroup',
      name: '预警用户组',
      component: BoardTemplate,
    },
    {
      key: 'subscription',
      path: '/action/subscription',
      component: BoardTemplate,
    },
  ],
};

export default homeRouteConfig;
