import React from 'react';
import { Pagination } from 'antd';
import { observer } from 'mobx-react';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import './index.sass';

@observer
class Main extends React.Component {
  pageChange = (page) => {
    this.props.paginationChange(page, this.props.pageCount);
  };
  onShowSizeChange = (current, pageSize) => {
    this.props.paginationChange(1, pageSize);
  };

  render() {
    const { pageCount, currentPage, totalCount } = this.props;
    return (
      <div>
        {/* 更改为中文格式 */}
        <ConfigProvider locale={zh_CN}>
          <Pagination
            size="small"
            showSizeChanger
            defaultPageSize={pageCount}
            pageSizeOptions={['10', '20', '50', '100', '200']}
            onShowSizeChange={this.onShowSizeChange}
            current={currentPage}
            total={totalCount}
            onChange={this.pageChange}
          />
        </ConfigProvider>
      </div>
    );
  }
}

export default Main;
