import React, { FC, memo, useCallback } from 'react';
import classnames from 'classnames';
import { Form, Row, Col, Select, Radio, Empty, Tooltip } from 'antd';
import { ControlFieldTypes, IS_DATE_TYPE, IS_NUMBER_TYPE, IS_RANGE_TYPE } from '../../constants';
import { getDragItemIconClass } from '../util';
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const GlobalControlRelatedViewForm = ({ form, relatedViews, controlType, optionWithVariable, onFieldTypeChange, onFieldChange }) => {
  const { getFieldDecorator } = form;

  const viewsClass = classnames({
    views: true,
    empty: !relatedViews.length,
  });

  const columnValidator = useCallback(
    (rule, value, callback) => {
      if ((Array.isArray(value) && !!value.length) || (!Array.isArray(value) && value !== void 0)) {
        const { field } = rule;
        const viewId = field.substring(field.indexOf('[') + 1, field.indexOf(']'));
        const { fieldType, models, variables } = relatedViews.find((v) => v.id == viewId);
        const selectedModel =
          fieldType === ControlFieldTypes.Column
            ? models.find((m) => m.fieldName === value)
            : Array.isArray(value)
            ? value.every((v) => variables.find((vr) => vr.name === v))
            : variables.find((vr) => vr.name === value);
        if (!selectedModel) {
          callback('数据模型已变化，请重新选择');
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    [form, relatedViews]
  );

  const fieldsFilter = (r) => {
    if (IS_DATE_TYPE[controlType]) {
      return r.visualType === 'date' && r.fieldDisplay;
    } else if (IS_NUMBER_TYPE[controlType]) {
      return r.visualType === 'number' && r.fieldDisplay;
    } else if (controlType === 'inputText' || controlType === 'search') {
      return r.visualType === 'string' && r.fieldDisplay;
    } else {
      return r && r.fieldDisplay;
    }
  };

  return (
    <div className={'viewContainer'}>
      <div className={'title'}>
        <h2>关联数据视图</h2>
      </div>
      <div className={viewsClass}>
        {relatedViews.length ? (
          relatedViews.map(({ id, name, fieldType, models, variables }) => {
            const isMultiple = IS_RANGE_TYPE[controlType] && fieldType === ControlFieldTypes.Variable;
            const fieldValues = form.getFieldValue(`relatedViews[${id}].fields`) || [];
            const displayName = ['visit_action_union_optimize', 'answer_visit_user_view'].includes(name)
              ? name === 'answer_visit_user_view'
                ? '答案表'
                : '动作表'
              : name;
            return (
              <div key={id} className={'relatedView'}>
                <div className={'name'}>
                  <Tooltip title={displayName}>
                    <h4>{displayName}</h4>
                  </Tooltip>
                  {getFieldDecorator(`relatedViews[${id}].fieldType`, {})(
                    <RadioGroup size="small" disabled={optionWithVariable} onChange={onFieldTypeChange(id)}>
                      <RadioButton value={ControlFieldTypes.Column}>字段</RadioButton>
                      <RadioButton value={ControlFieldTypes.Variable}>变量</RadioButton>
                    </RadioGroup>
                  )}
                </div>
                <Row gutter={8}>
                  <Col span={24}>
                    <FormItem>
                      {getFieldDecorator(`relatedViews[${id}].fields`, {
                        rules: [
                          {
                            required: true,
                            message: `关联${fieldType === ControlFieldTypes.Column ? '字段' : '变量'}不能为空`,
                          },
                          { validator: columnValidator },
                        ],
                      })(
                        <Select
                          onChange={onFieldChange}
                          showSearch
                          placeholder="请选择"
                          filterOption={(input, option) => {
                            return option.props.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                          {...(isMultiple && { mode: 'multiple' })}
                          disabled={optionWithVariable}>
                          {fieldType === ControlFieldTypes.Column
                            ? models
                                .filter((r) => fieldsFilter(r))
                                .map((m) => (
                                  <Option key={m.fieldName} value={m.fieldName}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <i className={`iconfont ${getDragItemIconClass(m.visualType)}`} style={{ marginRight: '7px' }} />
                                      {m.fieldDisplay}
                                    </div>
                                  </Option>
                                ))
                            : variables.map((v) => (
                                <Option key={v.name} value={v.name} disabled={isMultiple && fieldValues.length === 2 && !fieldValues.includes(v.name)}>
                                  {v.name}
                                </Option>
                              ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            );
          })
        ) : (
          <Empty key="empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};

export default memo(GlobalControlRelatedViewForm);
