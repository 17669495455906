import React, { FC, forwardRef } from 'react';
import { TreeSelect as AntTreeSelect } from 'antd';
import { CONTROL_MAX_TAG_COUNT } from '../constants';
import { filterTreeSelectOption } from '../app/util';

const TreeSelect = ({ control, value, size, onChange, options }, ref) => {
  const { multiple } = control;

  return (
    <AntTreeSelect
      showSearch
      allowClear
      treeCheckable={multiple}
      treeDataSimpleMode
      placeholder="请选择"
      maxTagCount={CONTROL_MAX_TAG_COUNT}
      treeData={options}
      value={value}
      onChange={onChange}
      {...(size && { size })}
      filterTreeNode={filterTreeSelectOption}
      ref={ref}
    />
  );
};

export default forwardRef(TreeSelect);
