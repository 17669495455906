import AnalyseTableLayout from '../../containers/AnalyseTableLayout/index';
export default {
  title: '关键任务',
  components: [
    {
      component: AnalyseTableLayout,
      props: {
        analyseType: 'pathAnalytics',
        type: 2,
        title: '关键任务',
      },
    },
  ],
};
