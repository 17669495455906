import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import util from '../../util';
import api from '../../api';
// import { Modal } from 'antd';
import UXSDrawer from '../../component/UXSDrawer';
import Loading from '../../component/Loading/Loading';
import './index.sass';

@observer
class SurveyDetailModal extends Component {
  componentDidMount() {
    util.get(api.trigger.answerContent, { answerId: this.props.id }, (res) => {
      this.setData(res);
    });
  }

  @observable data;

  @action.bound setData(data) {
    this.data = data;
  }
  render() {
    return (
      <UXSDrawer title={<div>答题详情</div>} show={this.props.visible} onClose={this.props.onClose} width={1049}>
        {this.data ? (
          this.data.map((item, index) => {
            return (
              <div className="survey-item" key={item.topic_num}>
                <p className="survey-question">
                  <span className="number">Q{index + 1}：</span>
                  {item.topic_name}【{item.topic_type}】
                </p>
                <p className="survey-answer">{item.answer_content}</p>
              </div>
            );
          })
        ) : (
          <Loading />
        )}
      </UXSDrawer>
    );
  }
}

export default SurveyDetailModal;
