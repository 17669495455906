import React, { Component, GetDerivedStateFromProps } from 'react';
import ControlPanelComponent from '../Control/Control/Panel';
import { getFormValuesRelatedItems } from '../../../containers/BIChart/util';
import { ControlPanelTypes } from '../Control/constants';
import PageHeaderStore from '../PageHeaderStore';

class GlobalControlPanel extends Component {
  state = {
    prevCurrentDashboard: null,
    isCurrentDashboardUpdated: false,
    globalControlPanelFormValues: {},
    selectOptions: {},
  };

  static getDerivedStateFromProps = (props, state) => {
    return {
      prevCurrentDashboard: props.currentDashboard,
      isCurrentDashboardUpdated:
        !!props.currentDashboard &&
        !!state.prevCurrentDashboard &&
        JSON.stringify(props.currentDashboard) !== JSON.stringify(state.prevCurrentDashboard) &&
        props.currentDashboard.id === state.prevCurrentDashboard.id,
    };
  };

  search = (formValues, type) => {
    // 穷途末路 为了拿到最新的state
    setTimeout(() => {
      // add
      formValues = this.state.globalControlPanelFormValues;
      // ??
      const { currentDashboard, currentItems, onSearch, onMonitoredSearchDataAction, type } = this.props;
      const controls = currentDashboard.config.filters;
      const relatedItems = type === 'insideCard' ? [] : formValues ? getFormValuesRelatedItems(controls, formValues) : currentItems.map((item) => item.id);
      onSearch(ControlPanelTypes.Global, relatedItems, formValues, type);
      if (onMonitoredSearchDataAction) {
        onMonitoredSearchDataAction();
      }
    });
  };

  onSetGlobalControlPanelFormValues = (values) => {
    this.setState({ globalControlPanelFormValues: { ...values } });
    setTimeout(() => {
      PageHeaderStore.setPagePadding();
    });
  };

  onSetOptions = (values) => {
    this.setState({ selectOptions: { ...values } });
    setTimeout(() => {
      PageHeaderStore.setPagePadding();
    });
  };

  render() {
    const { layoutType, currentDashboard, currentItems, formedViews, onGetOptions } = this.props;

    const { isCurrentDashboardUpdated, globalControlPanelFormValues, selectOptions } = this.state;

    const items = currentItems
      ? currentItems
          .map((item) => item.id)
          .sort()
          .join(',')
      : '';

    return (
      JSON.stringify(currentDashboard) !== '{}' && (
        <ControlPanelComponent
          {...this.props}
          controls={currentDashboard.config.filters}
          formedViews={formedViews}
          items={items}
          type={ControlPanelTypes.Global}
          layoutType={layoutType}
          reload={isCurrentDashboardUpdated}
          queryMode={currentDashboard.config.queryMode}
          formValues={globalControlPanelFormValues}
          mapOptions={selectOptions}
          onGetOptions={onGetOptions}
          onSetOptions={this.onSetOptions}
          onChange={this.onSetGlobalControlPanelFormValues}
          onSearch={this.search}
          isCurrentDashboardUpdated={isCurrentDashboardUpdated}
        />
      )
    );
  }
}

export default GlobalControlPanel;
