import React, { Component } from 'react';
import DropItem from './DropItem';

class DropBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const dropList = [1, 2];

    return (
      <div className="drop-box">
        {dropList.map((item, index) => {
          return <DropItem key={index} index={index} {...this.props} />;
        })}
      </div>
    );
  }
}

export default DropBox;
