import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Input, Radio, Cascader, Switch, message, notification } from 'antd';
import api from '../../api';
import util from '../../util';
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import './index.sass';

const cascaderOptions = [
  {
    value: '100%',
    label: '电脑',
  },
  {
    value: 'phone',
    label: '手机',
    children: [
      {
        value: '812px',
        label: '横屏',
      },
      {
        value: '375px',
        label: '竖屏',
      },
    ],
  },
  {
    value: 'pad',
    label: '平板',
    children: [
      {
        value: '100%',
        label: '横屏',
      },
      {
        value: '1024px',
        label: '竖屏',
      },
    ],
  },
];

function CommonIframe(props) {
  const [targetUrl, setTargetUrl] = useState(''); // iframe地址
  const [viewWidth, setViewWidth] = useState('100%'); // iframe窗口宽度
  const [model, setmodel] = useState('select'); // 浏览模式
  const [complete, setComplete] = useState(true); // 浏览模式
  const iframeEl = useRef(); // iframe对象
  let messageHide;

  useEffect(() => {
    let clearError;
    let visualInterval;
    function iframeOnload() {
      visualInterval = setInterval(() => {
        iframeEl.current && iframeEl.current.contentWindow.postMessage('cemyun_visualEvent', '*');
      }, 1000);
      messageHide && messageHide();
      clearError = setTimeout(urlError, 10000);
    }
    function messageListener(e) {
      if (e.data === 'cemyun_visual_complete') {
        setComplete(!complete);
        iframeEl.current && iframeEl.current.contentWindow.postMessage({ type: 'cemyun_tagList', data: props.definedEventList }, '*');
        clearTimeout(clearError);
        clearInterval(visualInterval);
      }
    }
    iframeEl.current.addEventListener('load', iframeOnload);
    window.addEventListener('message', messageListener);
    return function cleanup() {
      iframeEl.current.removeEventListener('load', iframeOnload);
      window.removeEventListener('message', messageListener);
      clearTimeout(clearError);
      clearInterval(visualInterval);
    };
  }, [props.definedEventList]);
  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    iframeEl.current && iframeEl.current.contentWindow.postMessage(`cemyun_${model}`, '*');
  }, [model, complete]);

  // 输入框按下回车的回调
  function linkToPage(e) {
    const regex = /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+))(:\d+)?(\/.*)?(\?.*)?(#.*)?$/;
    const { value } = e.target;
    regex.test(value)
      ? (setTargetUrl(value), (messageHide = message.loading('正在为您跳转')))
      : message.error(`请输入合法的 URL 地址, 例如：https://${window.location.host}/`, 3);
    // setTargetUrl(e.target.value);
  }
  // 检测当前url是否正常
  function urlError() {
    notification.error({
      message: '目前无法使用圈选',
      description: (
        <Fragment>
          <p>可能的原因包括：</p>
          <p>1、该产品未安装SDK，或者安装失败。</p>
          <p>2、产品部分自有禁止iframe加载，请联系工程师修改iframe设置。</p>
          {/* <a>返回修改</a> */}
        </Fragment>
      ),
      className: 'cemyun-error-waring',
    });
  }
  function modelChange(e) {
    setmodel(e.target.value);
  }
  function highlightChange(e) {
    iframeEl.current && iframeEl.current.contentWindow.postMessage(e ? 'cemyun_highlight' : 'cemyun_close_highlight', '*');
  }
  function viewWidthChange(value) {
    const width = value[value.length - 1];
    setViewWidth(width);
  }
  // function fetchData(orderDesc, orderColumn, currentPage = 1, pageCount = 10000) {
  //   util.get(
  //     api.setting.getCustomEvent,
  //     {
  //       idsite: PageHeaderStore.parameter.idsite,
  //       pageCount,
  //       currentPage,
  //       orderColumn: 'created_at',
  //       orderDesc: false,
  //     },
  //     (res) => {
  //       const data = [];
  //       res &&
  //         res.data.forEach((item) => {
  //           data.push(item.elementSelector);
  //         });
  //       iframeEl.current && iframeEl.current.contentWindow.postMessage({ type: 'cemyun_tagList', data }, '*');
  //     }
  //   );
  // }

  return (
    <div className="event-select">
      <div className="event-select-search" style={{ width: '80%' }}>
        <label htmlFor="current_page" style={{ width: '70px' }}>
          当前页面：
        </label>
        <Input id="current_page" suffix="Enter跳转" placeholder="请输入页面地址" onPressEnter={linkToPage} />
      </div>
      <div className="event-select-content">
        <ul className="event-select-tools">
          <li>
            <Radio.Group value={model} onChange={modelChange}>
              <Radio.Button value="select">埋点</Radio.Button>
              <Radio.Button value="browse">浏览</Radio.Button>
            </Radio.Group>
          </li>
          <li>
            <Cascader options={cascaderOptions} onChange={viewWidthChange} placeholder="请选择" defaultValue={['100%']} allowClear={false} />
          </li>
          <li>
            高亮已埋点元素
            <Switch defaultChecked onChange={highlightChange} />
          </li>
          <li>
            <div className="color-block-content">
              <span className="color-block red" />
              <span>已埋点元素</span>
            </div>
            <div className="color-block-content">
              <span className="color-block green" />
              <span>可埋点元素（鼠标悬停后高亮）</span>
            </div>
          </li>
        </ul>
        <div className="event-select-page" style={{ width: `${viewWidth}` }}>
          <iframe ref={iframeEl} src={targetUrl} width="100%" height="100%" name="targetPage" sandbox="allow-same-origin allow-scripts" />
        </div>
      </div>
    </div>
  );
}

export default CommonIframe;
