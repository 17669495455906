/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import mean from 'lodash/mean';
import { CHART_LEGEND_POSITIONS, DEFAULT_SPLITER, ReferenceType, ReferenceValueType } from '../../constants';
import { metricAxisLabelFormatter, decodeMetricName, getTextWidth, getAggregatorLocale, getFormattedValue } from '../../util';
import { FieldSortTypes } from '../../components/Config/Sort';
import { getFieldAlias } from '../../components/Config/Field';
import ChartTypes from '../../config/chart/ChartTypes';
import UXSChartStore from '../../../../component/UXSChart/store';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';

const store = new UXSChartStore();

export function getDimetionAxisOption(dimetionAxisConfig, splitLineConfig, data, extraOption, isLimitLabelLength, miniCharts) {
  const {
    inverse,
    showLine: showLineX,
    lineStyle: lineStyleX,
    lineSize: lineSizeX,
    lineColor: lineColorX,
    showLabel: showLabelX,
    labelFontFamily: labelFontFamilyX,
    labelFontSize: labelFontSizeX,
    labelColor: labelColorX,
    nameLocation,
    nameGap,
    nameRotate,
    showInterval,
    xAxisInterval,
    xAxisRotate,
  } = dimetionAxisConfig;

  const { showLine, lineStyle, lineSize, lineColor } = splitLineConfig;

  const intervalOption = showInterval ? { interval: xAxisInterval } : null;

  return {
    data,
    inverse,
    axisLabel: {
      show: !miniCharts && showLabelX,
      color: labelColorX,
      fontFamily: labelFontFamilyX,
      fontSize: Number(labelFontSizeX),
      rotate: xAxisRotate,
      formatter: (value, index) => {
        return isLimitLabelLength ? store.stringLeaveOut(value) : value;
      },
      ...intervalOption,
    },
    axisLine: {
      show: !miniCharts && showLineX,
      lineStyle: {
        color: lineColorX,
        width: Number(lineSizeX),
        type: lineStyleX,
      },
    },
    axisTick: {
      show: showLabelX,
      lineStyle: {
        color: lineColorX,
      },
    },
    splitLine: {
      show: showLine,
      lineStyle: {
        color: lineColor,
        width: Number(lineSize),
        type: lineStyle,
      },
    },
    nameLocation,
    nameRotate,
    nameGap,
    ...(extraOption || {}),
  };
}

export function getMetricAxisOption(metricAxisConfig, splitLineConfig, title, axis, percentage, miniCharts) {
  const {
    inverse,
    showLine: showLineY,
    lineStyle: lineStyleY,
    lineSize: lineSizeY,
    lineColor: lineColorY,
    showLabel: showLabelY,
    labelFontFamily: labelFontFamilyY,
    labelFontSize: labelFontSizeY,
    labelColor: labelColorY,
    showTitleAndUnit,
    titleFontFamily,
    titleFontSize,
    titleColor,
    nameLocation,
    nameRotate,
    nameGap,
    min,
    max,
    format,
  } = metricAxisConfig;

  const { showLine, lineStyle, lineSize, lineColor } = splitLineConfig;

  return {
    type: 'value',
    inverse,
    min: min,
    max: max,
    axisLabel: {
      show: !miniCharts && showLabelY,
      color: labelColorY,
      fontFamily: labelFontFamilyY,
      fontSize: Number(labelFontSizeY),
      formatter: function(value) {
        // 判断value小数点位数
        const valueArray = value.toString().split('.');
        const n = valueArray[1] ? valueArray[1].length : 0;

        if (percentage) {
          value = n > 2 ? value.toFixed(2) : value;
          return `${value}%`;
        } else if (format === 'percent') {
          value = n > 4 ? value.toFixed(4) : value;
          // * 1000 / 10，防止数值精度变化，小数点位数过多
          return `${(value * 1000) / 10}%`;
        } else if (format === 'NPS') {
          value = n > 4 ? value.toFixed(4) : value;
          return `${(value * 1000) / 10}`;
        } else {
          return metricAxisLabelFormatter(value);
        }
      },
    },
    axisLine: {
      show: !miniCharts && showLineY,
      lineStyle: {
        color: lineColorY,
        width: Number(lineSizeY),
        type: lineStyleY,
      },
    },
    axisTick: {
      show: showLabelY,
      lineStyle: {
        color: lineColorY,
      },
    },
    name: !miniCharts && showTitleAndUnit ? title : '',
    nameLocation,
    nameGap,
    nameRotate,
    nameTextStyle: {
      color: titleColor,
      fontFamily: titleFontFamily,
      fontSize: Number(titleFontSize),
    },
    splitLine: {
      show: showLine,
      lineStyle: {
        color: lineColor,
        width: Number(lineSize),
        type: lineStyle,
      },
    },
  };
}

export function getLabelOption(type, labelConfig, metrics, emphasis, options) {
  const { showLabel, labelPosition, labelFontFamily, labelFontSize, labelColor, pieLabelPosition, funnelLabelPosition } = labelConfig;

  let position;
  switch (type) {
    case 'pie':
      position = pieLabelPosition;
      break;
    case 'funnel':
      position = funnelLabelPosition;
      break;
    default:
      position = labelPosition;
      break;
  }

  let formatter;

  switch (type) {
    case 'line':
      formatter = (params) => {
        const { value, seriesId } = params;
        const m = metrics.find((m) => m.name === seriesId.split(`${DEFAULT_SPLITER}${DEFAULT_SPLITER}`)[0].split('@CEMYUN@')[0]);
        const formattedValue = getFormattedValue(value, m.format);
        return formattedValue;
      };
      break;
    case 'waterfall':
      formatter = (params) => {
        const { value } = params;
        const formattedValue = getFormattedValue(value, metrics[0].format);
        return formattedValue;
      };
      break;
    case 'scatter':
      formatter = (params) => {
        const { value } = params;
        const formattedValue = getFormattedValue(value[0], metrics[0].format);
        return formattedValue;
      };
      break;
    case 'pie':
    case 'funnel':
      formatter = (params) => {
        const { name, value, percent, dataIndex, data } = params;
        const formattedValue = getFormattedValue(value, metrics[metrics.length > 1 ? dataIndex : 0].format);
        const { labelParts } = labelConfig;
        if (!labelParts) {
          return `${name}\n${formattedValue}（${percent}%）`;
        }
        const labels = [];
        const multiRate = labelParts.filter((label) => ['percentage', 'conversion', 'arrival'].includes(label)).length > 1;
        if (labelParts.includes('dimensionValue')) {
          labels.push(name);
        }
        if (labelParts.includes('indicatorValue')) {
          labels.push(formattedValue);
        }
        if (labelParts.includes('conversion') && data.conversion) {
          labels.push(`${multiRate ? '转化率：' : ''}${data.conversion}%`);
        }
        if (labelParts.includes('arrival') && data.arrival) {
          labels.push(`${multiRate ? '到达率：' : ''}${data.arrival}%`);
        }
        if (labelParts.includes('percentage')) {
          labels.push(`${multiRate ? '百分比：' : ''}${percent}%`);
        }
        return labels.join('\n');
      };
      break;
    case 'radar':
      formatter = (params) => {
        const { name, value, dataIndex, data } = params;
        const metricIdx = data.name ? dataIndex : data.value.indexOf(value);
        const formattedValue = getFormattedValue(value, metrics[metricIdx].format);
        const labelName = name || getFieldAlias(metrics[metricIdx].field, {}) || metrics[metricIdx].fieldDisplay || decodeMetricName(metrics[metricIdx].name);
        const { labelParts } = labelConfig;
        if (!labelParts) {
          return `${labelName}\n${formattedValue}`;
        }
        const labels = [];
        if (labelParts.includes('indicatorName')) {
          labels.push(labelName);
        }
        if (labelParts.includes('indicatorValue')) {
          labels.push(formattedValue);
        }
        if (labels.length > 1) {
          labels.splice(1, 0, '\n');
        }
        return labels.join('');
      };
      break;
    case 'lines':
      formatter = (param) => {
        const { name, data } = param;
        return `${name}(${data.value[2]})`;
      };
      break;
    case 'quadrant':
      formatter = '{a}';
      break;
  }

  const labelOption = {
    normal: {
      show: type === 'pie' && pieLabelPosition === 'center' ? false : showLabel,
      position,
      color: BoardStore.boardStyle !== 'terse' ? '#D6D6D6' : labelColor,
      fontFamily: labelFontFamily,
      fontSize: labelFontSize,
      formatter,
      ...options,
    },
    ...(emphasis && {
      emphasis: {
        show: showLabel,
      },
    }),
  };
  return labelOption;
}

export function getLegendOption(
  legendConfig, //样式等配置
  seriesNames, //legend数据
  fieldFormat, //legend中文
  miniCharts
) {
  const { showLegend, legendPosition, selectAll, selectNumber, fontFamily, fontSize, color } = legendConfig;
  const bodyWidth = document.body.offsetWidth;

  let orient;
  let positions;

  switch (legendPosition) {
    case 'top':
      orient = { orient: 'horizontal' };
      positions = { top: 8, height: 32 };
      break;
    case 'bottom':
      orient = { orient: 'horizontal' };
      positions = { bottom: 8, height: 32 };
      break;
    case 'left':
      orient = { orient: 'vertical' };
      positions = { left: 8, top: 16, bottom: 24, width: 96 };
      break;
    default:
      orient = { orient: 'vertical' };
      positions = { right: 8, top: 16, bottom: 24, width: 96 };
      break;
  }

  let selected = {};
  // 增加配置默认数量功能
  // 兼容没有新字段的类型
  if (selectNumber === 'undefined' || selectAll) {
    selected = {
      selected: seriesNames.reduce(
        (obj, name) => ({
          ...obj,
          [name]: selectAll,
        }),
        {}
      ),
    };
  } else {
    selected = {
      selected: seriesNames.reduce(
        (obj, name, index) => ({
          ...obj,
          [name]: index + 1 <= selectNumber,
        }),
        {}
      ),
    };
  }

  return {
    show: !miniCharts && showLegend && seriesNames.length > 1,
    data: seriesNames,
    type: 'scroll',
    textStyle: {
      fontFamily,
      fontSize,
      color: BoardStore.boardStyle !== 'terse' ? '#D6D6D6' : color,
    },
    formatter: (name) => {
      return (fieldFormat && fieldFormat[name]) || name;
    },
    ...orient,
    ...positions,
    ...selected,
  };
}

export function getGridPositions(
  legendConfig,
  seriesNames,
  chartName,
  isHorizontalBar,
  yAxisConfig,
  dimetionAxisConfig,
  xAxisData,
  isLimitLabelLength,
  labelStyleConfig,
  formattedData,
  xAxisConfig,
  markPoint
) {
  const { showLegend, legendPosition, fontSize } = legendConfig;
  const gridOption = CHART_LEGEND_POSITIONS.reduce((grid, pos) => {
    const val = pos.value;
    grid[val] = getGridBase(val, chartName, dimetionAxisConfig, xAxisData, isHorizontalBar, yAxisConfig, seriesNames, isLimitLabelLength, formattedData);
    if (showLegend && seriesNames.length > 1) {
      grid[val] +=
        legendPosition === val ? (['top', 'bottom'].includes(val) ? 64 : 64 + Math.max(...seriesNames.map((s) => getTextWidth(s, '', `${fontSize}px`)))) : 0;
    }
    return grid;
  }, {});
  if (chartName === 'quadrant') {
    let quadrantgridOptionRight = 0;
    let titleTextWidth = 0;
    gridOption.left = 32;
    if (labelStyleConfig.labelLayout) {
      quadrantgridOptionRight = Math.max(...seriesNames.map((s) => getTextWidth(s, '', `${fontSize}px`))) + 16;
    } else if (labelStyleConfig.showLabel) {
      const seriesNamesWidth = Math.max(...seriesNames.map((s) => getTextWidth(s, '', `${fontSize}px`))) / 2;
      gridOption.right = Math.max(gridOption.right, seriesNamesWidth);
      gridOption.left = Math.max(gridOption.left, seriesNamesWidth);
    }
    if (xAxisConfig.showTitleAndUnit) {
      titleTextWidth = getTextWidth(xAxisConfig.title, '', `${fontSize}px`) + 16;
      if (xAxisConfig.nameLocation === 'end') {
        gridOption.right = Math.max(gridOption.right, quadrantgridOptionRight, titleTextWidth);
      } else if (xAxisConfig.nameLocation === 'start') {
        gridOption.right = Math.max(gridOption.right, quadrantgridOptionRight);
        gridOption.left = Math.max(gridOption.left, titleTextWidth);
      } else {
        gridOption.right = Math.max(gridOption.right, quadrantgridOptionRight);
      }
    } else {
      gridOption.right = Math.max(gridOption.right, quadrantgridOptionRight);
    }
    gridOption.bottom = gridOption.bottom > 24 ? gridOption.bottom : 24;
    if (yAxisConfig.showTitleAndUnit) {
      if (yAxisConfig.nameLocation === 'start') {
        gridOption.bottom += 24;
      } else if (yAxisConfig.nameLocation === 'end') {
        gridOption.top += 24;
      }
    }
    if (xAxisConfig.showLabel) {
      gridOption.bottom += 24;
    }
    if (yAxisConfig.showLabel) {
      const labelFontSize = dimetionAxisConfig ? dimetionAxisConfig.labelFontSize : 12;
      gridOption.left = Math.max(...formattedData.map((s) => getTextWidth(s, '', `${labelFontSize}px`))) + 16;
    }
  }
  if (markPoint) {
    gridOption.top += 12;
  }
  return gridOption;
}

function getGridBase(pos, chartName, dimetionAxisConfig, xAxisData, isHorizontalBar, yAxisConfig, seriesNames, isLimitLabelLength, formattedData) {
  const labelFontSize = dimetionAxisConfig ? dimetionAxisConfig.labelFontSize : 12;
  const xAxisRotate = dimetionAxisConfig ? dimetionAxisConfig.xAxisRotate : 0;
  const bodyWidth = document.body.offsetWidth;

  const labelWidth =
    formattedData && formattedData.length
      ? Math.max(...formattedData.map((s) => getTextWidth(isLimitLabelLength ? store.stringLeaveOut(s) : s, '', `${labelFontSize}px`)))
      : 0;
  const maxWidth =
    xAxisData && xAxisData.length
      ? Math.max(...xAxisData.map((s) => getTextWidth(isLimitLabelLength ? store.stringLeaveOut(s) : s, '', `${labelFontSize}px`)))
      : 0;

  const baseLeftPadding = bodyWidth > 768 ? 32 : Math.max(labelWidth, 24);
  const baseRightPadding = bodyWidth > 768 ? 24 : Math.max(maxWidth / 2, 20);
  const baseBottomDistance = seriesNames.length >= 2 ? 0 : 32;
  const bottomDistance =
    dimetionAxisConfig && dimetionAxisConfig.showLabel
      ? isHorizontalBar
        ? baseBottomDistance
        : xAxisRotate
        ? baseBottomDistance + Math.sin((xAxisRotate * Math.PI) / 180) * maxWidth
        : baseBottomDistance
      : baseBottomDistance;

  const yAxisConfigLeft = yAxisConfig && !yAxisConfig.showLabel && !yAxisConfig.showTitleAndUnit ? 8 : baseLeftPadding;
  const leftDistance =
    dimetionAxisConfig && dimetionAxisConfig.showLabel
      ? isHorizontalBar
        ? xAxisRotate === void 0
          ? baseLeftPadding
          : 24 + Math.cos((xAxisRotate * Math.PI) / 180) * maxWidth
        : yAxisConfig && yAxisConfig.format && yAxisConfig.format !== 'default' && labelWidth
        ? labelWidth
        : yAxisConfigLeft
      : isHorizontalBar
      ? 24
      : yAxisConfigLeft;

  switch (pos) {
    case 'top':
      return 24;
    case 'left':
      return leftDistance;
    case 'right':
      return chartName === 'doubleYAxis' ? baseLeftPadding : baseRightPadding;
    case 'bottom':
      return bottomDistance;
  }
}

export function makeGrouped(data, groupColumns, xAxisColumn, metrics, xAxisData) {
  const grouped = {};

  data.forEach((d) => {
    const groupingKey = groupColumns.map((col) => d[col]).join(' ');
    const colKey = d[xAxisColumn] || 'default';

    if (!grouped[groupingKey]) {
      grouped[groupingKey] = {};
    }
    if (!grouped[groupingKey][colKey]) {
      grouped[groupingKey][colKey] = [];
    }
    grouped[groupingKey][colKey].push(d);
  });

  Object.keys(grouped).forEach((groupingKey) => {
    const currentGroupValues = grouped[groupingKey];

    grouped[groupingKey] = xAxisData.length
      ? xAxisData.map((xd) => {
          if (currentGroupValues[xd]) {
            return currentGroupValues[xd][0];
          } else {
            return metrics.reduce(
              (obj, m) => ({
                ...obj,
                [`${m.agg}(${decodeMetricName(m.name)})`]: 0,
              }),
              {
                [xAxisColumn]: xd,
                // []: groupingKey
              }
            );
          }
        })
      : [currentGroupValues['default'][0]];
  });

  return grouped;
}

// TODO: function explanation
export function getGroupedXaxis(data, xAxisColumn, metrics) {
  if (xAxisColumn) {
    const metricsInSorting = metrics.filter(({ sort }) => sort && sort.sortType !== FieldSortTypes.Default);
    const appliedMetric = metricsInSorting.length ? metricsInSorting[0] : void 0;

    const dataGroupByXaxis = data.reduce((grouped, d) => {
      const colKey = d[xAxisColumn];
      if (grouped[colKey] === void 0) {
        grouped[colKey] = 0;
      }
      if (appliedMetric) {
        const { agg, name, customType, randomName } = appliedMetric;
        const expression = decodeMetricName(name);
        grouped[colKey] += d[customType ? randomName : `${agg}(${expression})`];
      }
      return grouped;
    }, {});

    // if (appliedMetric) {
    //   return Object.entries(dataGroupByXaxis)
    //     .sort((p1, p2) => {
    //       return appliedMetric.sort.sortType === FieldSortTypes.Asc
    //         ? p1[1] - p2[1]
    //         : appliedMetric.sort.sortType === FieldSortTypes.Desc ? p2[1] - p1[1] : 0
    //     })
    //     .map(([key, value]) => key)
    // } else {
    return Object.keys(dataGroupByXaxis);
    // }
  }
  return [];
}

export function getSymbolSize(sizeRate, size) {
  return sizeRate ? Math.ceil(size / sizeRate) : size;
}

export function getCartesianChartMetrics(metrics) {
  return metrics.map((metric) => {
    const { name, agg, fieldDisplay, field, formulaExt } = metric;
    const decodedMetricName = getFieldAlias(field, {}) || fieldDisplay || decodeMetricName(name);
    const duplicates = metrics.filter((m) => decodeMetricName(m.name) === decodedMetricName && m.agg === agg);
    const aggName = getAggregatorLocale(agg, metric);
    const prefix = agg !== 'sum' && aggName ? `[${aggName}] ` : '';
    const suffix = duplicates.length > 1 ? (duplicates.indexOf(metric) ? duplicates.indexOf(metric) + 1 : '') : '';
    const aggNameExt = getAggregatorLocale(formulaExt);
    const prefixExt = formulaExt && aggNameExt ? `[${aggNameExt}] ` : '';
    return {
      ...metric,
      displayName: `${prefixExt}${prefix}${decodedMetricName}${suffix}`,
    };
  });
}

export function getCartesianChartReferenceOptions(references, chartType, metrics, sourcedata, barChart) {
  if (references) {
    const markLines = [];
    const markAreas = [];

    references.forEach((ref) => {
      const { name, type, data } = ref;

      if (type === ReferenceType.Line) {
        const { metric, type: valueType, value, label, line } = data;

        const axis = getReferenceDataMetricAxis(chartType, {
          barChart,
          metrics,
          metric,
        });

        if (axis) {
          const metricData = sourcedata.map((d) => {
            const metricObject = metrics.find((m) => m.name === metric);
            return metricObject && d[metricObject.randomName || `${metricObject.agg}(${decodeMetricName(metric)})`];
          });
          markLines.push({
            ...getReferenceDataOptions(metricData, valueType, value, axis),
            name,
            label: {
              show: label.visible,
              position: label.position,
              color: label.font.color,
              fontSize: label.font.size,
              fontFamily: label.font.family,
            },
            lineStyle: {
              color: line.color,
              width: line.width,
              type: line.type,
            },
          });
        }
      } else {
        const areaData = data.map((d, index) => {
          const { metric, type: valueType, value, label, band } = d;

          const axis = getReferenceDataMetricAxis(chartType, {
            barChart,
            metrics,
            metric,
          });

          if (axis) {
            const metricData = sourcedata.map((d) => {
              const metricObject = metrics.find((m) => m.name === metric);
              return metricObject && d[metricObject.randomName || `${metricObject.agg}(${decodeMetricName(metric)})`];
            });
            const dataOptions = getReferenceDataOptions(metricData, valueType, value, axis);
            return !index
              ? dataOptions
              : {
                  ...dataOptions,
                  name,
                  label: {
                    show: label.visible,
                    position: label.position,
                    color: label.font.color,
                    fontSize: label.font.size,
                    fontFamily: label.font.family,
                  },
                  emphasis: {
                    label: {
                      position: label.position,
                    },
                  },
                  itemStyle: {
                    color: band.color,
                    borderColor: band.border.color,
                    borderWidth: band.border.width,
                    borderType: band.border.type,
                  },
                };
          } else {
            return void 0;
          }
        });
        if (areaData.every((d) => !!d)) {
          markAreas.push(areaData);
        }
      }
    });

    return {
      ...(markLines.length && { markLine: { data: markLines } }),
      ...(markAreas.length && { markArea: { data: markAreas } }),
    };
  }
}

export function getFieldFormat(data) {
  let fieldFormat = {};
  data.forEach((item) => {
    const name = `${decodeMetricName(item.name)}${item.agg}`;
    fieldFormat[name] = getFieldAlias(item.field, {}) || item.fieldDisplay || decodeMetricName(item.name);
  });
  return fieldFormat;
}

export function getKey(data, name) {
  Object.keys(data).forEach((key) => {
    const regex = new RegExp(`\\(${name}\\)` + '$');
    regex.test(key) && (name = key);
  });
  return name;
}

function getReferenceDataOptions(metricData, valueType, value, axis) {
  const option = {};
  if (valueType === ReferenceValueType.Constant) {
    option[axis] = value;
  } else {
    option[axis] = calcAggregateReferenceData(valueType, metricData);
  }
  return option;
}

function getReferenceDataMetricAxis(chartType, options) {
  switch (chartType) {
    case ChartTypes.Bar:
      return options.barChart ? 'xAxis' : 'yAxis';
    case ChartTypes.Scatter:
      const axisIndexMapping = ['xAxis', 'yAxis'];
      const metricIndex = options.metrics.findIndex((m) => m.name === options.metric);
      return axisIndexMapping[metricIndex];
    default:
      return 'yAxis';
  }
}

function calcAggregateReferenceData(valueType, metricData) {
  switch (valueType) {
    case ReferenceValueType.Max:
      return Math.max(...metricData);
    case ReferenceValueType.Min:
      return Math.min(...metricData);
    case ReferenceValueType.Average:
      return mean(metricData);
  }
}
