import { Col, Tabs } from 'antd';
import './index.sass';
import React from 'react';
import { Card } from '../../component/Card/Card';
import intl from 'react-intl-universal';
const TabPane = Tabs.TabPane;
class Main extends React.PureComponent {
  static defaultProps = {
    mode: 'top',
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: '1',
    };
  }
  handleTabSelect(key) {
    this.setState({ activeTabKey: key });
  }
  render() {
    return (
      <div className={`mode-${this.props.mode} ${this.props.className}`}>
        <Tabs
          activeKey={this.state.activeTabKey}
          tabPosition={this.props.mode}
          onChange={(key) => this.handleTabSelect(key)}
          animated={false}
          className="page-tabs large-tabs">
          {this.props['tab'].map((item, index) => {
            return (
              !item.hide && (
                <TabPane key={`${index + 1}`} tab={intl.get(item.name) || item.name}>
                  {this.state.activeTabKey === `${index + 1}` && (
                    // 聚合子元素列表减少dom
                    <React.Fragment>
                      {/* tab数组里面包含children */}
                      {item.children.map((item, index) => {
                        // 是否为card
                        return item.isCard ? (
                          <Card
                            key={index}
                            card={{
                              title: item.title ? item.title : '',
                              content: (
                                <Col md={item.col}>
                                  {/* children里面的component的组件名 */}

                                  <item.component {...item.props}></item.component>
                                </Col>
                              ),
                            }}
                          />
                        ) : (
                          // 不为card
                          <Col key={index} md={item.col}>
                            <item.component {...item.props}></item.component>
                          </Col>
                        );
                      })}
                    </React.Fragment>
                  )}
                </TabPane>
              )
            );
          })}
        </Tabs>
      </div>
    );
  }
}
export default Main;
