import CommonChart from '../../containers/Common/CommonChart';
import CommonTable from '../../containers/CommonTable';
import CommonTab from '../../containers/CommonTab';
import CommonMixChart from '../../containers/CommonMixChart/index';
import TerminalTable from '../../containers/TerminalTable/index';

export default {
  title: '终端详情',
  components: [
    {
      isCard: true,
      title: '终端详情',
      component: CommonTab,
      props: {
        tab: [
          {
            name: 'Resolution',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,
                      type: 'pie',
                      dims: 'terminal_resolution',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: false,
                      noLegendSelect: true,
                      orderColumn: 'pv',
                      needOther: true,
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,terminal_resolution',
                      fields: 'pv',
                      pageCount: 10,
                      currentPage: 1,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'terminal_resolution',
                  orderColumn: 'pv',
                  currentPage: 1,
                  pageCount: 10,
                  orderDesc: true,
                },
              },
            ],
          },
          {
            name: 'Broswer',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,

                      type: 'pie',
                      dims: 'terminal_browser',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: true,
                      noLegendSelect: true,
                      orderColumn: 'pv',
                      needOther: true,
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,terminal_browser',
                      fields: 'pv',
                      pageCount: 10,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: TerminalTable,
                col: 24,
                props: {
                  expandedRowRender: true,
                  dims: 'terminal_browser',
                  orderColumn: 'pv',
                  currentPage: 1,
                  pageCount: 10,
                  orderDesc: true,
                },
              },
            ],
          },
          {
            name: 'OS',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,

                      type: 'pie',
                      dims: 'terminal_os',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: true,
                      noLegendSelect: true,
                      needOther: true,
                      orderColumn: 'pv',
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,terminal_os',
                      fields: 'pv',
                      pageCount: 10,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: TerminalTable,
                col: 24,
                props: {
                  expandedRowRender: true,
                  dims: 'terminal_os',
                  orderColumn: 'pv',
                  currentPage: 1,
                  pageCount: 10,
                  orderDesc: true,
                },
              },
            ],
          },
          {
            name: 'Device',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,

                      type: 'pie',
                      dims: 'terminal_type',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: true,
                      noLegendSelect: true,
                      needOther: true,
                      orderColumn: 'pv',
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,terminal_type',
                      fields: 'pv',
                      pageCount: 10,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'terminal_type',
                  orderColumn: 'pv',
                  pageCount: 10,
                  currentPage: 1,
                  orderDesc: true,
                },
              },
            ],
          },
          {
            name: 'Brand',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,

                      type: 'pie',
                      dims: 'terminal_brand',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: true,
                      noLegendSelect: true,
                      needOther: true,
                      orderColumn: 'pv',
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,terminal_brand',
                      fields: 'pv',
                      pageCount: 10,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'terminal_brand',
                  orderColumn: 'pv',
                  pageCount: 10,
                  currentPage: 1,
                  orderDesc: true,
                },
              },
            ],
          },
          {
            name: 'DeviceModel',
            children: [
              {
                component: CommonMixChart,
                col: 24,
                props: {
                  options: ['pv', 'uv', 'uvNew', 'visits'],
                  chartProps: [
                    {
                      col: 6,

                      type: 'pie',
                      dims: 'terminal_model',
                      fields: 'pv',
                      pageCount: 9,
                      orderDesc: true,
                      noLegendSelect: true,
                      needOther: true,
                      orderColumn: 'pv',
                      showAll: true,
                    },
                    {
                      col: 18,
                      lineType: 'area',
                      dims: 'date,terminal_model',
                      fields: 'pv',
                      pageCount: 10,
                      orderDesc: true,
                      orderColumn: 'pv',
                      showAll: true,
                      ignoreCache: true,
                    },
                  ],
                },
              },
              {
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'terminal_model',
                  orderColumn: 'pv',
                  pageCount: 10,
                  currentPage: 1,
                  orderDesc: true,
                },
              },
            ],
          },
        ],
      },
    },
  ],
};
