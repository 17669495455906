import React, { Component } from 'react';
import CommonTable from '../../../CommonTable/index';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import Loading from '../../../../component/Loading/Loading';
import { observer } from 'mobx-react';
import { observable, autorun, action, toJS } from 'mobx';
import api from '../../../../api';
import tableUtil from '../../../../utils/tableUtil';
import PathChildTable from './PathChildTable';
import moment from 'moment';
import UXSRangePicker from '../../../../component/UXSRangePicker';
import mathUtil from '../../../../utils/mathUtil';
@observer
export default class AnalysePathTable extends Component {
  constructor(props) {
    super(props);
    this.pathId = this.props.pathId;
    this.parentData = this.props.parentData;
    this.lastCurrentCommonFilterObject = [];
  }
  @observable isLoading = true;
  @observable isChildLoading = true;
  @observable data = { data: [], page: [] };
  @observable param;
  componentDidMount() {
    this.disposer = autorun(() => {
      let count = this.props.count;
      let userGroupId = pageHeaderStore.parameter.userGroupId;
      let dateObj = this.props.dateObj;
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      let commonFilterObject = pageHeaderStore.commonFilterObject;
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.pathId));

      if (
        (count && (!this.lastLayoutChangeCount || this.lastLayoutChangeCount === this.props.layoutChangeCount)) ||
        JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject)
      ) {
        this.isLoading = true;
        if (this.props.dataFromParentData) {
          this.data = this.parentData;
          this.isLoading = false;
        }
        this.lastCurrentCommonFilterObject = currentCommonFilterObject;
      }
      this.lastLayoutChangeCount !== this.props.layoutChangeCount && (this.lastLayoutChangeCount = this.props.layoutChangeCount);
    });
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  getFetchParam(isExpand, parentData) {
    let fetchParam = {
      noDefaultFields: true,
      orderDesc: true,
      currentPage: 1,
      pageCount: 10,
      pathId: this.props.pathId,
      customApi: api.pack.analyticsPath,
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : this.props.dateObj
          ? this.props.dateObj.lastDate
            ? mathUtil.keyToDate(this.props.dateObj.lastDate)
            : this.props.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      workViewFilters: this.props.pathId ? pageHeaderStore.getCommonFilter(this.props.pathId) : '',
    };
    if (Number(pageHeaderStore.parameter.userGroupId) > 0) {
      fetchParam['userGroupId'] = pageHeaderStore.parameter.userGroupId;
    }
    if (isExpand) {
      fetchParam['orderColumn'] = `funnelSteps_key`;
      fetchParam['actionType'] = 14;
    } else {
      fetchParam['dims'] = this.props.dims;
      fetchParam['orderColumn'] = `${this.props.dims}_key`;
      fetchParam['actionType'] = this.props.actionType;
    }
    return fetchParam;
  }

  @action.bound expandedRowContent(parentData) {
    let tableHeadData = tableUtil.fieldsToTableHeadData(
      `funnelSteps_key,funnelStepConversion,funnelStepConversionAvgTime,funnelStepConversionRate,funnelStepConversionSiteRate,funnelStepConversionVisitors,funnelStepEntryVisitors,funnelStepMiss`
    );
    let filters = [{ dim: this.props.dims, value: parentData[`${this.props.dims}_key`], formula: '=' }];
    if (this.props.dataFromParentData) {
      return <PathChildTable filters={filters} tableHeadData={tableHeadData} expandContentParam={this.props.expandContentParam}></PathChildTable>;
    } else {
      return (
        <CommonTable
          tableType="analysePath"
          id={this.props.pathId}
          isSort={false}
          tableHeadData={tableHeadData}
          nopagination={true}
          {...Object.assign(this.getFetchParam(true, parentData), { filters })}></CommonTable>
      );
    }
  }
  render() {
    let date = `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`;
    let dateStr =
      pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
        ? pageHeaderStore.parameter.date.replace(/-/g, '/').replace(',', ' ~ ')
        : !this.props.setDateObj &&
          (this.props.dateObj
            ? this.props.dateObj.lastDate
              ? mathUtil
                  .keyToDate(this.props.dateObj.lastDate)
                  .replace(/-/g, '/')
                  .replace(',', ' ~ ')
              : this.props.dateObj.date.replace(/-/g, '/').replace(',', ' ~ ')
            : date.replace(/-/g, '/').replace(',', ' ~ '));
    return (
      <React.Fragment>
        {/* 编辑的时候才显示控件，看板时为文字 */}
        {this.props.setDateObj ? (
          this.props.isLoading ? (
            <Loading size="small" />
          ) : (
            <UXSRangePicker
              useDateByStore={false}
              getDateAndlastDate={this.props.setDateObj}
              date={this.props.dateObj ? this.props.dateObj.date : date}
              lastDate={this.props.dateObj ? this.props.dateObj.lastDate : ''}
              isBoardPicker={true}
            />
          )
        ) : (
          <p className="board-chart-date">{dateStr}</p>
        )}
        {this.props.dataFromParentData ? (
          !this.isLoading ? (
            <CommonTable
              tableType="analysePath"
              id={this.props.pathId}
              fetchData={this.props.dataFromParentData && this.props.fetchData}
              isSort={false}
              tableHeadData={tableUtil.fieldsToTableHeadData(
                `${this.props.dims}_key,funnelStepConversion,funnelStepConversionAvgTime,funnelStepConversionRate,funnelStepConversionSiteRate,funnelStepConversionVisitors,funnelStepEntryVisitors,funnelStepMiss`
              )}
              expandedRowRender={true}
              parentData={{ data: this.data.data, page: this.data.page }}
              expandedRowContent={(parentData) => this.expandedRowContent(parentData, this.props.dataFromParentData)}></CommonTable>
          ) : (
            <Loading></Loading>
          )
        ) : (
          <CommonTable
            tableType="analysePath"
            id={this.props.pathId}
            index={this.props.index}
            isSort={false}
            tableHeadData={tableUtil.fieldsToTableHeadData(
              `${this.props.dims}_key,funnelStepConversion,funnelStepConversionAvgTime,funnelStepConversionRate,funnelStepConversionSiteRate,funnelStepConversionVisitors,funnelStepEntryVisitors,funnelStepMiss`
            )}
            {...this.getFetchParam(false)}
            expandedRowRender={true}
            expandedRowContent={(parentData) => this.expandedRowContent(parentData, this.props.dataFromParentData)}></CommonTable>
        )}
      </React.Fragment>
    );
  }
}
