import PageOverviewTable from '../../containers/PageOverviewTable';

export default {
  title: '页面组数据',
  components: [
    {
      isCard: true,
      title: '页面组数据',
      className: 'old-layout-card',
      children: [
        {
          component: PageOverviewTable,
          col: 24,
          props: {
            dims: 'page_group',
            fields: 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,exits,exitRate,isHasParam,operate',
            tableHeadKeyForKey: true,
            currentPage: 1,
            orderDesc: true,
            pageCount: 10,
            orderColumn: 'pv',
            expandedRowRender: true,
            nullDataType: 'pageGroup',
          },
        },
      ],
    },
  ],
};
