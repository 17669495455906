import React, { useEffect, useState, Fragment } from 'react';
import Modal from './Modal';
import SearchHeader from '../../component/SearchHeader';
import pageheaderStore from '../../component/PageHeader/PageHeaderStore';
import CommonTable from '../../containers/CommonTable';
import api from '../../api';
import util from '../../util';
import { Popconfirm } from 'antd';
import intl from 'react-intl-universal';

function Contact(props) {
  const [data, setData] = useState([]); // 列表
  const [keyword, setKeyword] = useState(''); // 搜索词
  const [showModal, setShowModal] = useState(false); // 是否显示弹窗
  const [editInfo, setEditInfo] = useState({}); // 编辑信息
  const [loading, setLoading] = useState(true); // 是否加载状态

  useEffect(() => {
    pageheaderStore.hidePageHeader();
    fetchData();
  }, [keyword]);

  function fetchData(orderDesc = true, orderColumn = 'updatedAt', currentPage = 1, pageCount = 10) {
    setLoading(true);
    util.get(
      api.dataTag.list,
      {
        search: keyword,
        orderDesc,
        orderColumn,
        currentPage,
        pageCount,
      },
      (res) => {
        res && setData(res);
        setLoading(false);
      }
    );
  }

  function modalToggle(bool) {
    setShowModal(bool);
    !bool && setEditInfo({});
  }

  function edit(editId) {
    setEditInfo({
      editId,
    });
    modalToggle(true);
  }

  function deleteData(id) {
    util.delete(api.dataTag.delete, { id }, (res) => {
      fetchData();
    });
  }

  function addContent(store) {
    for (let i = 0; i < store.columns.length; i++) {
      const currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'operate') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: (
              <div className="operateGroup">
                <Popconfirm
                  title={intl.get('DeleteWaring')}
                  okButtonProps={{ type: 'primary' }}
                  cancelButtonProps={{ type: 'cancel' }}
                  onConfirm={() => {
                    deleteData(record.id);
                  }}
                  okButtonProps={{ type: 'primary' }}
                  cancelButtonProps={{ type: 'cancel' }}
                  okText={intl.get('Confirm')}
                  cancelText={intl.get('Cancel')}
                  placement="topLeft">
                  <span className="operateDom-delete" title={intl.get('Delete')}>
                    {intl.get('Delete')}
                    {/* 删除 */}
                  </span>
                </Popconfirm>
                <span
                  className="operateDom-update"
                  onClick={() => {
                    edit(record.id);
                  }}>
                  编辑
                </span>
              </div>
            ),
          };
        };
      }
    }
  }

  return (
    <Fragment>
      <SearchHeader defaultValue={keyword} title="+ 新增用户组" onSearch={setKeyword} onClick={() => modalToggle(true)} />
      {showModal && <Modal show={showModal} onHide={() => modalToggle(false)} fetchData={fetchData} editInfo={editInfo} />}
      <CommonTable
        fetchData={fetchData}
        parentData={data}
        addContent={addContent}
        tableHeadData={[
          ['用户组名称', '创建时间', '最近修改时间', '操作'],
          ['name', 'createdAt', 'updatedAt', 'operate', 'id'],
        ]}
        isLoading={loading}
        isSort={false}
      />
    </Fragment>
  );
}

export default Contact;
