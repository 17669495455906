/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { decodeMetricName, getChartTooltipLabel, getSizeValue, getSizeRate, getFieldAlias, getTextWidth } from '../../util';
import { getMetricAxisOption, getLabelOption, getLegendOption, getGridPositions, getSymbolSize, getCartesianChartReferenceOptions, getKey } from './util';
import { PIVOT_DEFAULT_QUADRANT_SIZE, PIVOT_DEFAULT_FONT_COLOR, DEFAULT_CHART_FONT_SIZE, tooltipStyle } from '../../constants';
import ChartTypes from '../../config/chart/ChartTypes';
import defaultTheme from '../../Chart/echarts_theme';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps, drillOptions, myChart) {
  const { data, cols, metrics, chartStyles, color, tip, size, references, compareData, id } = chartProps;

  const { spec, splitLine, label: labelStyleConfig, legend, grid } = chartStyles;

  let { xAxis, yAxis } = chartStyles;

  const {
    showVerticalLine,
    verticalLineColor,
    verticalLineSize,
    verticalLineStyle,
    showHorizontalLine,
    horizontalLineColor,
    horizontalLineSize,
    horizontalLineStyle,
  } = splitLine;
  const { selectedItems } = drillOptions;
  const labelOption = {
    label: getLabelOption('quadrant', labelStyleConfig, metrics),
  };
  const baseData = data.length ? data : compareData;
  const referenceOptions = getCartesianChartReferenceOptions(references, ChartTypes.Scatter, metrics, data);

  let sizeRate = 0;
  let sizeItemName = '';
  if (size.items.length) {
    const sizeItem = size.items[0];
    sizeItemName = sizeItem.randomName ? sizeItem.randomName : getKey(baseData[0], decodeMetricName(sizeItem.name));
    const sizeValues = baseData.map((d) => d[sizeItemName]);
    sizeRate = getSizeRate(Math.min(...sizeValues), Math.max(...sizeValues));
  }

  const series = [];
  const seriesData = [];

  let xList = [];
  let yList = [];
  baseData.forEach((item) => {
    xList.push(parseFloat(item[metrics[0].randomName || `${metrics[0].agg}(${decodeMetricName(metrics[0].name)})`] || 0));
    yList.push(parseFloat(item[metrics[1].randomName || `${metrics[1].agg}(${decodeMetricName(metrics[1].name)})`] || 0));
  });

  const xMedian = getOrigin(xList, xAxis.origin);
  const yMedian = getOrigin(yList, yAxis.origin);
  const formattedData = yList.map((item) => {
    return getAxisLabel(item + yMedian);
  });

  xAxis = {
    ...xAxis,
    titleColor: BoardStore.boardStyle !== 'terse' ? '#D6D6D6' : xAxis.titleColor,
    max:
      !xAxis.max && xAxis.max !== 0
        ? function(value) {
            const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
            return !maxABS ? 1 : maxABS * 1.2;
          }
        : xAxis.max - xMedian,
    min:
      !xAxis.min && xAxis.min !== 0
        ? function(value) {
            const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
            return !maxABS ? -1 : maxABS * 1.2 * -1;
          }
        : xAxis.min - xMedian,
  };
  yAxis = {
    ...yAxis,
    titleColor: BoardStore.boardStyle !== 'terse' ? '#D6D6D6' : yAxis.titleColor,
    max:
      !yAxis.max && yAxis.max !== 0
        ? function(value) {
            const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
            return !maxABS ? 1 : maxABS * 1.2;
          }
        : yAxis.max - yMedian,
    min:
      !yAxis.min && yAxis.min !== 0
        ? function(value) {
            const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
            return !maxABS ? -1 : maxABS * 1.2 * -1;
          }
        : yAxis.min - yMedian,
  };
  const groupColumns = cols
    .map((c) => c.randomName || c.name)
    .reduce((distinctColumns, col) => {
      if (!distinctColumns.includes(col)) {
        distinctColumns.push(col);
      }
      return distinctColumns;
    }, []);
  const grouped = data.reduce((obj, val) => {
    const groupingKey = groupColumns.reduce((keyArr, col) => keyArr.concat(val[col]), []).join(String.fromCharCode(0));
    if (!obj[groupingKey]) {
      obj[groupingKey] = [];
    }
    obj[groupingKey].push(val);
    return obj;
  }, {});
  const compareGrouped = compareData.reduce((obj, val) => {
    const groupingKey = groupColumns.reduce((keyArr, col) => keyArr.concat(val[col]), []).join(String.fromCharCode(0));
    if (!obj[groupingKey]) {
      obj[groupingKey] = [];
    }
    obj[groupingKey].push(val);
    return obj;
  }, {});

  const labelItemName = cols[0].name;

  const groupedEntries = Object.entries(grouped);
  const compareGroupedEntries = Object.entries(compareGrouped);

  const compareColorGroup = {};
  const seriesNames = groupedEntries.concat(compareGroupedEntries).map(([key]) => key);
  const maxSeriesNamesWidth = Math.max(...seriesNames.map((s) => getTextWidth(s, '', `${legend.fontSize}px`)));
  groupedEntries.forEach(([key, value], gIndex) => {
    series.push(getSeries(key, value, gIndex, false, maxSeriesNamesWidth, myChart, labelStyleConfig));
    seriesData.push(value);
  });
  compareGroupedEntries.forEach(([key, value], gIndex) => {
    series.push(getSeries(key, value, gIndex, true, maxSeriesNamesWidth, myChart, labelStyleConfig));
    seriesData.push(value);
  });
  const xAxisTitle = getFieldAlias(metrics[0].field, {}) || metrics[0].fieldDisplay || decodeMetricName(metrics[0].name);
  const gridPositions = getGridPositions(legend, seriesNames, 'quadrant', false, yAxis, null, null, null, labelStyleConfig, formattedData, {
    ...xAxis,
    title: xAxisTitle,
  });

  const { isDrilling, getDataDrillDetail, instance } = drillOptions;
  if (isDrilling) {
    //  instance.off('brushselected')
    // instance.on('brushselected', brushselected)
    setTimeout(() => {
      instance.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          brushType: 'rect',
          brushMode: 'multiple',
        },
      });
    }, 0);
  }
  const xAxisSplitLineConfig = {
    showLine: showVerticalLine,
    lineColor: verticalLineColor,
    lineSize: verticalLineSize,
    lineStyle: verticalLineStyle,
  };

  const yAxisSplitLineConfig = {
    showLine: showHorizontalLine,
    lineColor: horizontalLineColor,
    lineSize: horizontalLineSize,
    lineStyle: horizontalLineStyle,
  };

  const textStyle = {
    fontSize: DEFAULT_CHART_FONT_SIZE,
    color: BoardStore.boardStyle !== 'terse' ? '#D6D6D6' : PIVOT_DEFAULT_FONT_COLOR,
  };
  const xAxisOption = getMetricAxisOption(xAxis, xAxisSplitLineConfig, xAxisTitle, 'x');
  const yAxisOption = getMetricAxisOption(
    yAxis,
    yAxisSplitLineConfig,
    getFieldAlias(metrics[1].field, {}) || metrics[1].fieldDisplay || decodeMetricName(metrics[1].name)
  );

  return {
    xAxis: {
      ...xAxisOption,
      axisLabel: {
        ...xAxisOption.axisLabel,
        formatter: function(value) {
          value += xMedian;
          value = getAxisLabel(value);
          return value;
        },
      },
    },
    yAxis: {
      ...yAxisOption,
      axisLabel: {
        ...yAxisOption.axisLabel,
        formatter: function(value) {
          value += yMedian;
          value = getAxisLabel(value);
          return value;
        },
      },
    },
    title: [
      {
        text: '优先改善',
        left: getTitlePosition('left', gridPositions, myChart, '优先改善'),
        top: legend.legendPosition === 'top' ? 64 : 0,
        textStyle,
      },
      {
        text: '重点保持',
        right: getTitlePosition('right', gridPositions, myChart, '重点保持'),
        top: legend.legendPosition === 'top' ? 64 : 0,
        textStyle,
      },
      {
        text: '次要改善',
        left: getTitlePosition('left', gridPositions, myChart, '次要改善'),
        bottom: legend.legendPosition === 'bottom' ? 36 : 0,
        textStyle,
      },
      {
        text: '维持现状',
        right: getTitlePosition('right', gridPositions, myChart, '维持现状'),
        bottom: legend.legendPosition === 'bottom' ? 36 : 0,
        textStyle,
      },
    ],
    series,
    tooltip: {
      formatter: getChartTooltipLabel('quadrant', seriesData, { cols, metrics, color, tip }, false, data.length, BoardStore.compareFilterConfig[id]),
      ...tooltipStyle,
    },
    legend: getLegendOption(legend, seriesNames),
    grid: {
      ...gridPositions,
      show: true,
      borderColor: 'transparent',
      backgroundColor: grid ? grid.backgroundColor : 'transparent',
    },
    // ...brushedOptions
  };

  function getSeries(key, value, gIndex, isCompare, maxSeriesNamesWidth, myChart) {
    const itemColor = defaultThemeColors[gIndex % defaultThemeColors.length];
    const itemName = key.replace(String.fromCharCode(0), ' ');
    !isCompare && (compareColorGroup[itemName] = itemColor);
    return {
      name: itemName,
      type: 'scatter',
      data: value.map((v, index) => {
        const [x, y] = metrics;
        const currentSize = size.items.length ? v[sizeItemName] : PIVOT_DEFAULT_QUADRANT_SIZE;
        const sizeValue = getSizeValue(size.value['all']);
        return {
          // ...itemStyleObj,
          // label: { show: labelStyleConfig.showLabel, formatter: '{a}', position: 'top', color: '#B2B3B9' },
          emphasis: {
            focus: 'series',
          },
          value: [
            v[x.randomName || `${x.agg}(${decodeMetricName(x.name)})`] - xMedian,
            v[y.randomName || `${y.agg}(${decodeMetricName(y.name)})`] - yMedian,
            v[labelItemName],
            currentSize,
          ],
          symbolSize: size.items.length ? getSymbolSize(sizeRate, currentSize) * sizeValue : currentSize * sizeValue,
        };
      }),
      itemStyle: {
        normal: {
          color: isCompare && compareColorGroup[itemName] ? compareColorGroup[itemName] : itemColor,
          opacity: isCompare ? 0.5 : 1,
        },
      },
      ...labelOption,
      ...(gIndex === groupedEntries.length - 1 && referenceOptions),
      labelLayout: function() {
        if (labelStyleConfig.labelLayout) {
          return {
            x: myChart.getWidth() - maxSeriesNamesWidth,
            moveOverlap: 'shiftY',
          };
        }
      },
      labelLine: {
        show: labelStyleConfig.labelLayout,
        length2: 5,
        lineStyle: {
          color: '#bbb',
        },
      },
    };
  }
  function getTitlePosition(placement, gridPositions, myChart, text) {
    let position = 0;
    if (placement === 'left') {
      position =
        (myChart.getWidth() - gridPositions.left - gridPositions.right) * 0.25 + gridPositions.left - getTextWidth(text, '', DEFAULT_CHART_FONT_SIZE) * 0.5;
    } else if (placement === 'right') {
      position =
        (myChart.getWidth() - gridPositions.left - gridPositions.right) * 0.25 + gridPositions.right - getTextWidth(text, '', DEFAULT_CHART_FONT_SIZE) * 0.5;
    }
    return position;
  }
}

// 计算原点
function getOrigin(data, type) {
  switch (type) {
    case 'median':
      return getMiddle(data);
    case 'zero':
      return 0;
    default:
      const sum = data.reduce((a, b) => a + (b || 0));
      const avg = sum / data.length;
      return avg;
  }
}

// 取中位数
function getMiddle(data) {
  let args = [...data]; //收集参数转为数组
  args.sort(); //排序
  if (args.length % 2 === 0) {
    //判断数字个数是奇数还是偶数
    return (args[args.length / 2] + args[args.length / 2 - 1]) / 2; //偶数个取中间两个数的平均数
  } else {
    return args[parseInt(args.length / 2)]; //奇数个取最中间那个数
  }
}

// 对坐标轴数值小数点位数处理，最多保留两位小数
function getAxisLabel(value) {
  if (value.toString().split('.')[1] && value.toString().split('.')[1].length > 2 && Math.abs(value) > 0.01) {
    value = value.toFixed(2);
  }
  return value;
}
