import React, { useState, useEffect } from 'react';
import { Modal, Radio, message, Row, Col } from 'antd';
import { decodeMetricName } from '../util';
import Loading from '../../../component/Loading/Loading';
import util from '../../../util';
import api from '../../../api';

function NlpModal(props) {
  const { modalInfo, onCancel, keysDisplay, cardRefreshWithoutConfig, cardId } = props;
  const { visible, config, record } = modalInfo;

  const detailIdField = keysDisplay.find((item) => item.name === config.onClick.detailIdField || item.randomName === config.onClick.detailIdField);

  const [options, setOptions] = useState(null);
  const [selectedKey, setSelectedKey] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (detailIdField) {
      const { randomName, agg, name } = detailIdField;
      const expression = randomName || `${agg}(${decodeMetricName(name)})`;
      util.get(api.nlp.getAnswerClosedLoopStatus, { answerId: record[expression] }, (res) => {
        res && (setOptions(res.closedLoopStatusList || []), setSelectedKey(res.closedLoopStatus));
      });
    }
  }, []);

  function submit() {
    if (detailIdField) {
      setConfirmLoading(true);
      const hide = message.loading('保存中，请勿操作！');
      const { randomName, agg, name } = detailIdField;
      const expression = randomName || `${agg}(${decodeMetricName(name)})`;
      const param = {
        answerId: record[expression],
        closedLoopStatus: selectedKey,
      };

      util.get(api.nlp.updateAnswerClosedLoopStatus, param, (res) => {
        setConfirmLoading(false);
        hide();
        message.success('修改答案闭环状态成功');
        onCancel();
        cardRefreshWithoutConfig(cardId);
      });
    }
  }

  function onChange(value) {
    setSelectedKey(value);
  }

  return (
    <Modal title="闭环管理" visible={visible} onOk={submit} onCancel={onCancel} width={800} confirmLoading={confirmLoading}>
      {options ? (
        <Radio.Group value={selectedKey} onChange={(e) => onChange(e.target.value)}>
          <Row>
            {options.map((item) => (
              <Col md={12} xs={24} style={{ margin: '8px 0' }}>
                <Radio value={item} key={item}>
                  {item}
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      ) : (
        <Loading />
      )}
    </Modal>
  );
}

export default NlpModal;
