import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from 'antd';
import './ListFormLayout.less';

export const List = ({ title, className, onAddItem, children }) => {
  const listClass = classnames({
    list: true,
    [className]: !!className,
  });
  return (
    <div className={'listContainer'}>
      <div className={'title'}>
        <h2>{title}</h2>
        <Icon type="plus" {...(onAddItem && { onClick: onAddItem })} />
      </div>
      <div className={listClass}>{children}</div>
    </div>
  );
};

export default List;
