import { Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import { createForm } from 'rc-form';
import React, { Component } from 'react';
import intl from 'react-intl-universal';

@observer
class Main extends Component {
  render() {
    const { modalInfo } = this.props;
    let modalTitle;
    if (modalInfo.surveyFormType === 'create') {
      modalTitle = `${intl.get('Create')}${intl.get('Questionnaire')}`;
    } else if (modalInfo.surveyFormType === 'copy') {
      modalTitle = `${intl.get('Copy')}${intl.get('Questionnaire')}`;
    } else if (modalInfo.surveyFormType === 'updateRemark') {
      modalTitle = `${intl.get('Modify')}${intl.get('Remarks')}`;
    }
    return (
      <Modal
        title={modalTitle}
        width={500}
        destroyOnClose={true}
        visible={this.props.isShowSurveyForm}
        onCancel={this.props.hideSurveyForm}
        okText={intl.get('Confirm')}
        cancelText={intl.get('Cancel')}
        onOk={this.props.saveSurvey}>
        <Form id="surveyForm">
          {modalInfo.surveyFormType !== 'updateRemark' && (
            <Form.Item>
              <span className="input-name">
                {intl.get('Questionnaire')}
                {intl.get('Name')}：
              </span>
              <Input
                type="text"
                value={modalInfo.surveyName}
                placeholder={intl.get('Pleaseenter')}
                style={{
                  width: 240,
                }}
                onChange={(e) => this.props.modalFormChange({ surveyName: e.target.value })}
              />
            </Form.Item>
          )}
          <Form.Item>
            <span className="input-name">
              {intl.get('Questionnaire')}
              {intl.get('Remarks')}：
            </span>
            <Input
              type="text"
              value={modalInfo.surveyRemark}
              placeholder={intl.get('Pleaseenter')}
              style={{
                width: 240,
              }}
              onChange={(e) => this.props.modalFormChange({ surveyRemark: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default createForm()(Main);
