import React from 'react';
import Xaxis from './Xaxis';
import { getAxisData } from '../util';

export class ColumnFooter extends React.Component {
  render() {
    const { rowKeys, colKeys, rowTree, colTree, tree, metrics, metricAxisConfig, chartStyles, drawingData, dimetionAxis } = this.props;
    const { elementSize } = drawingData;

    let footers = [];
    let tableWidth = 0;

    if (dimetionAxis) {
      const { data, length } = getAxisData('x', rowKeys, colKeys, rowTree, colTree, tree, metrics, drawingData, dimetionAxis);
      footers = data;
      tableWidth = length;
    }

    return (
      <div className={`columnFooter ${dimetionAxis ? '' : 'hideFooter'}`}>
        {dimetionAxis && (
          <Xaxis
            width={tableWidth}
            metrics={metrics}
            data={footers}
            metricAxisConfig={metricAxisConfig}
            chartStyles={chartStyles}
            dimetionAxis={dimetionAxis}
            elementSize={elementSize}
          />
        )}
      </div>
    );
  }
}

export default ColumnFooter;
