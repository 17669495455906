import React, { Component } from 'react';
import { observable, action, toJS } from 'mobx';
import { Cascader, Select } from 'antd';
import { observer } from 'mobx-react';
import api from '../../api';
import util from '../../util';
import SelectStore from './store';
import './index.sass';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import legendUtil from '../../utils/legendUtil';
import Loading from '../Loading/Loading';
import UXSNewSelect from '../UXSNewSelect';

/*

TODO:
@param {参数类型} @参数名 参数说明
1、{string} @useType 
 该参数为事件下拉框的使用类型
'onlyEvent'为只显示事件名
'userGroup'为对应用户分群类型
2、{function} @changeEventAndTarget
该参数为事件下拉框回调的更改事件或事件及指标的函数
返回(value,index,count)
value为{dim: 'event', type: type, dimValue: dimValue}(除漏斗，调研)
index为下拉框的下标（此处考虑到多个事件下拉框）
count为下拉框对应的用户分群count
3、{object} @eventValue
该参数为事件或事件及指标的value
事件value为{dim: 'event', type: type, dimValue: dimValue}(除漏斗，调研)
事件及指标value为{dim: 'event', type: type, dimValue: dimValue,target:target,paramFormula:parmFormula,targetName:targetName}(除漏斗，调研)
4、{boolean} @disableOption
该参数为是否禁用漏斗以及调研的选项
5、{Array.Array}([[]]) targetOption 
该参数指标对应的选项卡
6、{function} setTargetSeletced 
重新对指标对应的选项进行修改
*/

@observer
class EventSelect extends Component {
  constructor(props) {
    super(props);
    // 前端自定义的下拉列表
    // 第一个下拉框

    const eventSelected = [
      {
        value: 'default',
        label: '预置事件',
        isLeaf: false,
      },
      {
        value: 'customize',
        label: '事件',
        isLeaf: false,
      },
      {
        value: 'funnel',
        label: '漏斗',
        isLeaf: false,
      },
      {
        value: 'survey',
        label: '调研',
        isLeaf: false,
      },
    ];
    // 第二个下拉框
    const stringFormula = [{ label: '去重数', value: 'uniq' }];
    const numFormula = [
      { label: '总和', value: 'sum' },
      { label: '平均值', value: 'avg' },
      { label: '最大值', value: 'max' },
      { label: '最小值', value: 'min' },
      { label: '人均', value: 'uvAvg' },
      { label: '去重数', value: 'uniq' },
    ];

    this.disableOption = this.props.disableOption;
    this.state = { eventSelected, numFormula, stringFormula };
  }
  @observable isLoading = false;
  @observable targetLoading = false;

  componentDidMount() {
    // 如果只选事件或者用户分群 所用到则屏蔽漏斗以及调研

    if (this.props.useType === 'onlyEvent' || this.props.useType === 'userGroup') {
      SelectStore.setDisabled([2, 3])
    }
    pageHeaderStore.getEventList()

    this.eventChange([this.props.eventValue.type, this.props.eventValue.dimValue], this.props.eventValue.dimValueName, this.props.index, false);

    // 挂载时改变一次事件
  }
  // 触发选择事件改变
  @action.bound eventChange = (item, dimValueName, index, changeEventTarget) => {
    // {Array} item : ["类型","指标"]
    // {stirng} dimValueName:"事件名称"
    // {boolean} changeEventTarget:是否需要改变事件的指标值

    let type = item[0];
    let dimValue = item[1];
    // item长度大于0表示有值
    if (item.length > 0) {
      //只有事件的时候
      if (this.props.useType === 'onlyEvent') {
        if (type === 'funnel' || type === 'view') {
          this.props.changeEventAndTarget({ dim: 'event', type: type, dimValue: dimValue, dimValueName: dimValueName }, index, this.props.count);
        } else if (type === 'survey') {
          this.props.changeEventAndTarget({ dim: 'event', type: type, dimValue: dimValue, dimValueName: dimValueName }, index, this.props.count);
        } else {
          this.props.changeEventAndTarget({ dim: 'event', type: type, dimValue: dimValue }, index, this.props.count);
        }
      }
      // 其余情况
      else {
        // 选择框选项的event的key赋值
        let data = this.props.targetOption ? this.props.targetOption : [[]];
        data[index] = [];
        this.targetLoading = true;
        util.post(
          api.pack.dimOptions,
          {
            // 用户分群是另外的属性
            dim: this.props.useType === 'userGroup' ? 'analysis_attribute_target' : 'analysis_target',
            search: JSON.stringify({ type: this.props.useType === 'onlySurvey' ? 'survey_anwser' : type, value: dimValue }),
            idsite: pageHeaderStore.parameter.idsite,
            date: pageHeaderStore.parameter.date,
            lastDate: pageHeaderStore.parameter.lastDate,
            analysisType: 1,
          },
          (res) => {
            Object.keys(res.data).forEach((key) => {
              // 如果有自定义参数
              let keyFormula = `${key.split('_')[key.split('_').length - 1]}Formula`
              if (key.includes('_') && this.state[keyFormula]) {
                // 手动添加联级层的选项，见顶部的配置
                data[index].push({
                  label: res.data[key],
                  value: key,
                  selectable: false,
                  children:
                    this.props.useType === 'analyseEvent'
                      ? this.state[keyFormula].map((item) => {
                        return { label: item.label, value: key.concat('-', item.value) };
                      })
                      : this.state[keyFormula],
                });
              } else {
                data[index].push({ label: res.data[key], value: key });
              }
            });

            // 选择指标后默认选择指标
            if (changeEventTarget) {
              if (this.props.useType === 'analyseDistribute_date') {
                if (type === 'funnel' || type === 'view') {
                  this.props.changeEventAndTarget(
                    { dim: 'event', type: type, dimValue: dimValue, dimValueName: dimValueName, target: 'date_hour' },
                    index,
                    this.props.count
                  );
                } else if (type === 'survey') {
                  this.props.changeEventAndTarget(
                    { dim: 'event', type: type, dimValue: dimValue, dimValueName: dimValueName, target: 'date_hour' },
                    index,
                    this.props.count
                  );
                } else {
                  this.props.changeEventAndTarget({ dim: 'event', type: type, dimValue: dimValue, target: 'date_hour' }, index, this.props.count);
                }
              } else {
                if (type === 'funnel' || type === 'view') {
                  this.props.changeEventAndTarget(
                    { dim: 'event', type: type, dimValue: dimValue, dimValueName: dimValueName, target: data[index][0].value },
                    index,
                    this.props.count
                  );
                } else if (type === 'survey') {
                  this.props.changeEventAndTarget(
                    {
                      dim: 'event',
                      type: type,
                      dimValue: dimValue,
                      dimValueName: dimValueName,
                      target: data[index][0].value,
                      targetName: data[index][0].label,
                    },
                    index,
                    this.props.count
                  );
                } else {
                  this.props.changeEventAndTarget({ dim: 'event', type: type, dimValue: dimValue, target: data[index][0].value }, index, this.props.count);
                }
              }
            }
            this.props.setTargetSeletced(data);
            // 重新设置指标下拉框
            this.targetLoading = false;
          },
          null,
          false
        );
      }
    }
  };
  // 改变多选框的值
  @action.bound changeTreeTarget(value, label, extra, index) {
    if (value.length >= 1) {
      let formulaString = [];
      let targetString = value
        .map((item) => {
          // 自定义参数

          if (item.value.includes('-')) {
            formulaString.push(item.value.split('-')[1]);
            return item.value.split('-')[0];
          } else {
            return item.value;
          }
        })
        .join(',');
      let eventAndTargetGroup = this.props.eventAndTargetGroup[index];
      formulaString = formulaString.join(',');

      if (formulaString === '') {
        eventAndTargetGroup['target'] = targetString;
        delete eventAndTargetGroup['paramFormula'];
      } else {
        eventAndTargetGroup['target'] = targetString;
        eventAndTargetGroup['paramFormula'] = formulaString;
      }

      this.props.changeEventAndTarget(eventAndTargetGroup, index, this.props.count);
    }
  }
  // 获取treeTarget的value
  getTreeTargetVal(value) {
    if (!value) {
      return undefined;
    }
    // 把value的target提取出来
    let formulaIndex = 0;
    return value.target.split(',').map((item, index) => {
      if (value.hasOwnProperty('paramFormula') && (item.includes('_string') || item.includes('_num'))) {
        let newValue = { value: `${item}-${value['paramFormula'].split(',')[formulaIndex]}` };
        formulaIndex = formulaIndex + 1;
        return newValue;
      }
      return { value: item };
    });
  }
  // 选择指标时
  targetChange(value, label, index) {
    // 判断是否有联级指标

    let eventAndTargetGroup = this.props.eventAndTargetGroup[index];
    if (label.length === 1) {
      eventAndTargetGroup['target'] = label[label.length - 1]['value'];
      delete eventAndTargetGroup['paramFormula'];
    } else if (label.length === 2) {
      eventAndTargetGroup['target'] = label[0]['value'];
      eventAndTargetGroup['paramFormula'] = label[1]['value'];
    }

    this.props.changeEventAndTarget(eventAndTargetGroup, index, this.props.count);

    // 传值给父组件 父组件必须要有个函数去接值否则报错
  }
  dateTargetChange(value, index) {
    let eventAndTargetGroup = this.props.eventAndTargetGroup[index];
    eventAndTargetGroup['target'] = value.key;
    this.props.changeEventAndTarget(eventAndTargetGroup, index, this.props.count);
  }
  // （事件的下拉框）动态生成下拉框的值
  getEventVal(item) {
    if (!item) {
      return undefined;
    }
    return [item.type, item.dimValue, item.dimValueName];
  }
  // 获取事件名的长度
  getEventValLength(value) {
    if (!value) {
      return 96;
    } else {
      if (value[0] === 'funnel' || value[0] === 'survey' || value[0] === 'view') {
        return value[2] ? (value[2].length * 15 + 36 > 150 ? 150 : value[2].length * 15 + 36) : 150;
      } else {
        return value[1] ? (value[1].length * 15 + 36 > 150 ? 150 : value[1].length * 15 + 36) : 150;
      }
    }
  }
  // （指标的下拉框）动态生成下拉框的值
  getTargetVal(value) {
    if (!value) {
      return undefined;
    }

    if (value.hasOwnProperty('paramFormula')) {
      return [value.target, value.paramFormula, value.targetName];
    } else {
      return [value.target, value.targetName];
    }
  }

  // 获取指标名的长度
  getTargetValLength(value) {
    if (!value[0]) {
      return 96;
    } else {
      let fieldLength = legendUtil.getTitle(value[0]) ? legendUtil.getTitle(value[0]).length : value[0].length;
      if (value[value.length - 1]) {
        return value[value.length - 1].length * 15 + 36 > 150 ? 150 : value[value.length - 1].length * 15 + 36;
      } else if (value.length === 2) {
        return fieldLength * 15 + 36 > 150 ? 150 : fieldLength * 15 + 36;
      } else {
        return (fieldLength / 2) * 15 + 45 > 150 ? (fieldLength / 2) * 15 + 45 : 150;
      }
    }
  }
  translateToTreeData(targetOption) {
    targetOption = targetOption.map((item) => {
      item['title'] = item.label;
      delete item.label;
      return item;
    });
    return targetOption;
  }
  render() {
    const { disabled } = this.props;
    return (
      <React.Fragment>
        {!SelectStore.isLoading ? (
          <React.Fragment>
            <Cascader
              disabled={disabled}
              displayRender={(label) => {
                if (label.length === 2) {
                  return `${label[1]}`;
                }
              }}
              onChange={(value, label) => {
                this.eventChange(value, label[1].label, this.props.index, true);
                // .props.eventChange(value, this.props.index);
              }}
              style={{ width: this.getEventValLength(this.getEventVal(this.props.eventValue)) }}
              value={this.props.eventValue && this.getEventVal(this.props.eventValue)}
              options={[...SelectStore.eventSelected]}
              placeholder="选择事件"
              allowClear={false}
              title={this.getEventVal(this.props.eventValue, this.props.index)}
            />
            {this.props.useType === 'analyseDistribute_date' ? (
              <Select
                disabled={disabled}
                style={{ width: '96px', marginLeft: '8px' }}
                labelInValue
                onChange={(value) => this.dateTargetChange(value, this.props.index)}
                value={{ key: this.props.eventValue['target'] }}>
                <Select.Option key={1} value="date_hour">
                  按时分布
                </Select.Option>
                <Select.Option key={2} value="date_day">
                  按天分布
                </Select.Option>
                <Select.Option key={3} value="date_week">
                  按周分布
                </Select.Option>
                <Select.Option key={4} value="date_month">
                  按月分布
                </Select.Option>
              </Select>
            ) : null}
            {this.props.targetOption ? (
              <React.Fragment>
                <span className="eventSelect_discribe" style={{ margin: '0 8px' }}>
                  的
                </span>
                {!this.targetLoading ? (
                  <React.Fragment>
                    {this.props.useType === 'analyseEvent' ? (
                      <UXSNewSelect
                        disabled={disabled}
                        isLoading={this.targetLoading}
                        selectType="multiple"
                        value={!this.targetLoading && this.getTreeTargetVal(this.props.eventValue)}
                        selectOption={toJS(this.props.targetOption[this.props.index])}
                        style={{ width: 200 }}
                        onChange={(value, label, extra) => {
                          this.changeTreeTarget(value, label, extra, this.props.index);
                        }}></UXSNewSelect>
                    ) : (
                      <Cascader
                        disabled={disabled}
                        displayRender={(label) => {
                          if (label.length === 1) {
                            return label[0];
                          } else if (label.length === 2) {
                            return `${label[0]}的${label[1]}`;
                          }
                        }}
                        onChange={(value, label) => {
                          this.targetChange(value, label, this.props.index);
                        }}
                        value={!this.targetLoading && this.getTargetVal(this.props.eventValue)}
                        style={{ width: this.getTargetValLength(this.getTargetVal(this.props.eventValue)) }}
                        options={!this.targetLoading && this.props.targetOption[this.props.index]}
                        placeholder="选择事件再选指标"
                        allowClear={false}
                        title={this.getTargetVal(this.props.eventValue)}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <Loading size="small"></Loading>
                )}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <Loading size="small"></Loading>
        )}
      </React.Fragment>
    );
  }
}
export default EventSelect;
