/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { toJS } from 'mobx';
import { DEFAULT_SPLITER, FieldFormatTypes, tooltipStyle } from '../../constants';
import {
  getFormattedValue,
  decodeMetricName,
  getChartTooltipLabel,
  getStackName,
  EmptyStack,
  getFieldAlias,
  getCompareText,
  metricAxisLabelFormatter,
} from '../../util';
import {
  getDimetionAxisOption,
  getMetricAxisOption,
  getLabelOption,
  getLegendOption,
  getGridPositions,
  makeGrouped,
  getGroupedXaxis,
  getCartesianChartMetrics,
  getCartesianChartReferenceOptions,
  getFieldFormat,
} from './util';
import { getTextWidth, hasMatchedTheCondition } from '../../util';
import BIStore from '../../store';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';
import { inGroupColorSort } from '../../components/Config/Sort/util';
import { FieldSortTypes } from '../../components/Config/Sort';
import ChartTypes from '../../config/chart/ChartTypes';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps, drillOptions) {
  const { data, cols, chartStyles, color, tip, references, id, compareData } = chartProps;
  const { isDrilling, getDataDrillDetail, instance, selectedItems, callback } = drillOptions;
  const metrics = getCartesianChartMetrics(chartProps.metrics);
  const currentCompareFilterConfig = toJS(BoardStore.compareFilterConfig[id]); //  对比配置

  const { bar, label, legend, xAxis, yAxis, splitLine, grid, markPoint, itemStyleConfig } = chartStyles;
  const { barChart, border: barBorder, gap: barGap, width: barWidth, stack: stackConfig, miniCharts } = bar;
  const { color: borderColor, width: borderWidth, type: borderType, radius: barBorderRadius } = barBorder;

  const { on: turnOnStack, percentage } = stackConfig || EmptyStack;

  const {
    showVerticalLine,
    verticalLineColor,
    verticalLineSize,
    verticalLineStyle,
    showHorizontalLine,
    horizontalLineColor,
    horizontalLineSize,
    horizontalLineStyle,
  } = splitLine;

  const labelOption = {
    label: miniCharts
      ? {
          normal: {
            show: false,
          },
        }
      : {
          ...getLabelOption('bar', label, metrics, false, {
            formatter: (params) => {
              const { value, seriesId } = params;
              if (value === 0) return '';
              const m = metrics.find((m) => m.name === seriesId.split(`${DEFAULT_SPLITER}${DEFAULT_SPLITER}`)[0].split('@CEMYUN@')[0]);
              return barFormatValue(value, m, percentage);
            },
          }),
        },
  };
  const referenceOptions = getCartesianChartReferenceOptions(references, ChartTypes.Bar, metrics, data, barChart);

  const xAxisColumnName = cols.length ? cols[0].randomName || cols[0].name : '';

  let xAxisData = [];
  let grouped = {};
  let groupedCompare = {};
  let percentGrouped = {};
  let percentGroupedCompare = {};

  if (color.items.length) {
    xAxisData = getGroupedXaxis(data, xAxisColumnName, metrics);
    grouped = makeGrouped(data, color.items.map((c) => c.randomName || c.name), xAxisColumnName, metrics, xAxisData);
    if (currentCompareFilterConfig) {
      groupedCompare = makeGrouped(compareData, color.items.map((c) => c.randomName || c.name), xAxisColumnName, metrics, xAxisData);
    }

    const configValue = color.items[0].config.values;
    const configKeys = [];
    Object.entries(configValue).forEach(([k, v]) => {
      configKeys.push(k);
    });
    percentGrouped = makeGrouped(data, cols.map((c) => c.name), color.items[0].name, metrics, configKeys);
    percentGroupedCompare = makeGrouped(compareData, cols.map((c) => c.name), color.items[0].name, metrics, configKeys);
  } else {
    xAxisData = data.map((d) => d[xAxisColumnName] || '');
  }

  // 显示标注点，最大值最小值
  const markPointData = [];
  if (markPoint && !miniCharts) {
    markPoint.showMax && markPointData.push({ name: '最大值', type: 'max' });
    markPoint.showMin && markPointData.push({ name: '最小值', type: 'min' });
  }

  const series = [];
  const seriesData = [];
  const formattedData = [];
  metrics.forEach((m, i) => {
    const decodedMetricName = decodeMetricName(m.name);
    const formatName = m.randomName || `${m.agg}(${decodedMetricName})`;
    const styleName = m.randomName || m.name;
    const itemStyleInfo = (itemStyleConfig && itemStyleConfig[styleName]) || {};
    const stackOption = turnOnStack ? { stack: getStackName(m.name, stackConfig) } : null;
    if (color.items.length) {
      const sumArr = [];
      const sumArrCompare = [];
      Object.entries(percentGrouped).forEach(([k, v]) => {
        sumArr.push(getColorDataSum(v, metrics));
      });
      Object.entries(percentGroupedCompare).forEach(([k, v]) => {
        sumArrCompare.push(getColorDataSum(v, metrics));
      });

      const groupEntries = Object.entries(grouped);
      const groupEntriesCompare = Object.entries(groupedCompare);
      const customColorSort = color.items
        .filter(({ sort }) => sort && sort.sortType === FieldSortTypes.Custom)
        .map(({ name, sort }) => ({ name, list: sort[FieldSortTypes.Custom].sortList }));
      if (customColorSort.length) {
        inGroupColorSort(groupEntries, customColorSort[0]);
      }

      groupEntries.forEach(([k, v], gIndex) => {
        const serieObj = getSeriesObjUseColor(
          m,
          i,
          currentCompareFilterConfig ? 'current' : null,
          formatName,
          itemStyleInfo,
          stackOption,
          k,
          v,
          sumArr,
          gIndex,
          groupEntries
        );
        series.push(serieObj);
        seriesData.push(grouped[k]);
      });
      // if (currentCompareFilterConfig) {
      //   groupEntriesCompare.forEach(([k, v], gIndex) => {
      //     const serieObj = getSeriesObjUseColor(
      //       m,
      //       i,
      //       currentCompareFilterConfig ? 'compare' : null,
      //       formatName,
      //       itemStyleInfo,
      //       stackOption,
      //       k,
      //       v,
      //       sumArrCompare,
      //       gIndex,
      //       groupEntriesCompare
      //     );
      //     series.push(serieObj);
      //     seriesData.push(grouped[k]);
      //   });
      // }
    } else {
      const serieObj = getSeriesObj(m, i, data, currentCompareFilterConfig ? 'current' : null, formatName, itemStyleInfo, stackOption);
      series.push(serieObj);
      seriesData.push([...data]);
      if (currentCompareFilterConfig) {
        const compareSerieObj = getSeriesObj(m, i, compareData, 'compare', formatName, itemStyleInfo, stackOption);
        series.push(compareSerieObj);
        seriesData.push([...compareData]);
      }
    }
  });
  const seriesNames = series.map((s) => s.name);
  if (turnOnStack && stackConfig.sum.show) {
    const { fontFamily, fontStyle, fontColor, fontSize, fontWeight } = stackConfig.sum.font;
    const sumSeries = series.reduce((acc, serie, serieIdx) => {
      const stackName = serie.stack;
      if (acc[stackName]) {
        return acc;
      }

      acc[stackName] = {
        name: stackName,
        type: 'bar',
        stack: stackName,
        label: {
          normal: {
            show: true,
            color: fontColor,
            fontStyle,
            fontWeight,
            fontFamily,
            fontSize,
            position: barChart ? 'right' : 'top',
            formatter: (params) => {
              let val = series
                .filter((s) => s.stack === stackName)
                .reduce((acc, s) => {
                  const dataIndex = params.dataIndex;
                  if (typeof s.data[dataIndex] === 'number') {
                    return acc + s.data[params.dataIndex];
                  } else {
                    const { value } = s.data[dataIndex];
                    return acc + value;
                  }
                }, 0);

              if (val === 0) return '';
              let format = metrics[serieIdx].format;
              if (percentage) {
                format = {
                  formatType: FieldFormatTypes.Percentage,
                  [FieldFormatTypes.Percentage]: {
                    decimalPlaces: 0,
                  },
                };
                val /= 100;
              }
              const formattedValue = getFormattedValue(val, format);
              return formattedValue;
            },
          },
        },
        data: Array.from(xAxisData).fill(0),
      };
      return acc;
    }, {});
    series.push(...Object.values(sumSeries));
  }
  const brushedOptions =
    isDrilling === true
      ? {
          brush: {
            toolbox: ['rect', 'polygon', 'keep', 'clear'],
            throttleType: 'debounce',
            throttleDelay: 300,
            brushStyle: {
              borderWidth: 1,
              color: 'rgba(255,255,255,0.2)',
              borderColor: 'rgba(120,140,180,0.6)',
            },
          },
        }
      : null;

  // if (isDrilling) {
  //   //  instance.off('brushselected')
  //     instance.on('brushselected', brushselected)
  //     setTimeout(() => {
  //         instance.dispatchAction({
  //         type: 'takeGlobalCursor',
  //         key: 'brush',
  //         brushOption: {
  //           brushType: 'rect',
  //           brushMode: 'multiple'
  //         }
  //       })
  //     }, 0)
  //   }
  if (callback) {
    callback.call(null, seriesData);
  }
  function brushselected(params) {
    const brushComponent = params.batch[0];
    const brushed = [];
    const sourceData = seriesData[0];
    let range = [];
    if (brushComponent && brushComponent.areas && brushComponent.areas.length) {
      brushComponent.areas.forEach((area) => {
        range = range.concat(area.range);
      });
    }
    if (brushComponent && brushComponent.selected && brushComponent.selected.length) {
      for (let i = 0; i < brushComponent.selected.length; i++) {
        const rawIndices = brushComponent.selected[i].dataIndex;
        const seriesIndex = brushComponent.selected[i].seriesIndex;
        brushed.push({ [i]: rawIndices });
      }
    }
    if (getDataDrillDetail) {
      getDataDrillDetail(JSON.stringify({ range, brushed, sourceData }));
    }
  }

  const xAxisSplitLineConfig = {
    showLine: !miniCharts && showVerticalLine,
    lineColor: verticalLineColor,
    lineSize: verticalLineSize,
    lineStyle: verticalLineStyle,
  };

  const yAxisSplitLineConfig = {
    showLine: !miniCharts && showHorizontalLine,
    lineColor: horizontalLineColor,
    lineSize: horizontalLineSize,
    lineStyle: horizontalLineStyle,
  };

  const dimetionAxisOption = getDimetionAxisOption(xAxis, xAxisSplitLineConfig, xAxisData, {}, barChart, miniCharts);
  const metricAxisOption = getMetricAxisOption(
    yAxis,
    yAxisSplitLineConfig,
    metrics.map((m) => getFieldAlias(m.field, {}) || m.fieldDisplay || decodeMetricName(m.name)).join(` / `),
    'x',
    percentage,
    miniCharts
  );
  const fieldFormat = getFieldFormat(metrics);
  const gridOption = miniCharts
    ? {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }
    : {
        ...getGridPositions(legend, seriesNames, '', barChart, yAxis, xAxis, xAxisData, barChart, null, formattedData, null, markPoint),
        show: true,
        borderColor: 'transparent',
        backgroundColor: grid ? grid.backgroundColor : 'transparent',
      };
  if (!miniCharts && barChart && label.showLabel && label.labelPosition === 'right') {
    const labelWidth = formattedData && formattedData.length ? Math.max(...formattedData.map((s) => getTextWidth(s, '', `${label.labelFontSize}px`))) : 0;
    gridOption.right = labelWidth + 5;
  }
  return {
    xAxis: barChart ? metricAxisOption : dimetionAxisOption,
    yAxis: barChart ? { ...dimetionAxisOption, inverse: !!barChart } : metricAxisOption,
    series,
    tooltip: {
      trigger: 'axis',
      confine: true,
      formatter: getChartTooltipLabel(
        'bar',
        seriesData,
        {
          cols,
          metrics,
          color,
          tip,
        },
        !!color.items.length,
        data.length,
        currentCompareFilterConfig,
        toJS(BIStore.currentItemsInfo[id].datasource.mappingMap)
      ),
      extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
      ...tooltipStyle,
    },
    legend: getLegendOption(legend, seriesNames, fieldFormat, miniCharts),
    grid: gridOption,
    // ...brushedOptions
  };

  function getSeriesObj(m, i, data, compareType, formatName, itemStyleInfo, stackOption) {
    let compareTextObj;
    const markPointDataCopy = JSON.parse(JSON.stringify(markPointData));
    if (compareType) {
      compareTextObj = getCompareText(currentCompareFilterConfig);
    }
    if (markPoint && !miniCharts && itemStyleInfo.conditionStyle) {
      const itemData = data.map((item) => item[formatName]);
      const max = Math.max(...itemData);
      const min = Math.min(...itemData);
      const maxColors = [];
      itemStyleInfo.conditionStyle.forEach((item) => {
        const matchTheCondition = hasMatchedTheCondition(max, item.operatorType, item.conditionValues);
        matchTheCondition && maxColors.push(item.color);
      });
      const minColors = [];
      itemStyleInfo.conditionStyle.forEach((item) => {
        const matchTheCondition = hasMatchedTheCondition(min, item.operatorType, item.conditionValues);
        matchTheCondition && minColors.push(item.color);
      });
      markPointDataCopy.forEach((item) => {
        item.type === 'max' && maxColors.length && (item.itemStyle = { color: maxColors[0] });
        item.type === 'min' && minColors.length && (item.itemStyle = { color: minColors[0] });
        item.type === 'max' && (item.label = { formatter: getFormattedValue(max, m.format) });
        item.type === 'min' && (item.label = { formatter: getFormattedValue(min, m.format) });
      });
    }
    return {
      id: compareType ? `${m.name}@CEMYUN@${compareTextObj[compareType]}` : m.name,
      name: compareType ? `${compareTextObj[compareType]} - ${m.displayName}` : m.displayName,
      type: 'bar',
      ...stackOption,
      workViewParamId: m.workViewParamId,
      sampling: 'average',
      markPoint: {
        data: markPointDataCopy,
        symbolSize: 40,
      },
      data: data.map((d, index) => {
        const itemStyle = {};
        if (itemStyleInfo.conditionStyle) {
          const matchColors = [];
          itemStyleInfo.conditionStyle.forEach((item) => {
            const matchTheCondition = hasMatchedTheCondition(d[formatName], item.operatorType, item.conditionValues);
            matchTheCondition && matchColors.push(item.color);
          });
          matchColors.length && (itemStyle.color = matchColors[0]);
        }
        if (selectedItems && selectedItems.length && selectedItems.some((item) => item === index)) {
          const value = percentage ? (d[formatName] / getDataSum(data, metrics)[index]) * 100 : d[formatName];
          formattedData.push(barFormatValue(value, m, percentage));
          return {
            value,
            itemStyle: {
              normal: {
                opacity: 1,
              },
              ...itemStyle,
            },
          };
        } else {
          if (percentage) {
            const value = (d[formatName] / getDataSum(data, metrics)[index]) * 100;
            formattedData.push(barFormatValue(value, m, percentage));
            return {
              value,
              itemStyle,
            };
          } else {
            const value = d[formatName];
            formattedData.push(barFormatValue(value, m, percentage));
            return { value, itemStyle };
          }
        }
      }),
      itemStyle: {
        normal: {
          opacity: compareType === 'compare' ? 0.5 : selectedItems && selectedItems.length > 0 ? 0.25 : 1,
          borderColor,
          borderWidth,
          borderType,
          barBorderRadius,
          color: color.value[m.name] || defaultThemeColors[i % defaultThemeColors.length],
        },
      },
      emphasis: {
        focus: 'series',
      },
      barGap: `${barGap}%`,
      barWidth: barWidth ? `${barWidth}%` : undefined,
      // lineStyle: {
      //   normal: {
      //     opacity: interactIndex === undefined ? 1 : 0.25
      //   }
      // },
      // itemStyle: {
      //   normal: {
      // opacity: interactIndex === undefined ? 1 : 0.25
      // color: color.value[m.name] || defaultThemeColors[i]
      // }
      // },
      ...labelOption,
      ...(i === metrics.length - 1 && referenceOptions),
    };
  }

  function getSeriesObjUseColor(m, i, compareType, formatName, itemStyleInfo, stackOption, k, v, sumArr, gIndex, groupEntries) {
    const markPointDataCopy = JSON.parse(JSON.stringify(markPointData));
    if (markPoint && !miniCharts && itemStyleInfo.conditionStyle) {
      const itemData = data.map((item) => item[formatName]);
      const max = Math.max(...itemData);
      const min = Math.min(...itemData);
      const maxColors = [];
      itemStyleInfo.conditionStyle.forEach((item) => {
        const matchTheCondition = hasMatchedTheCondition(max, item.operatorType, item.conditionValues);
        matchTheCondition && maxColors.push(item.color);
      });
      const minColors = [];
      itemStyleInfo.conditionStyle.forEach((item) => {
        const matchTheCondition = hasMatchedTheCondition(min, item.operatorType, item.conditionValues);
        matchTheCondition && minColors.push(item.color);
      });
      markPointDataCopy.forEach((item) => {
        item.type === 'max' && maxColors.length && (item.itemStyle = { color: maxColors[0] });
        item.type === 'min' && minColors.length && (item.itemStyle = { color: minColors[0] });
        item.type === 'max' && (item.label = { formatter: getFormattedValue(max, m.format) });
        item.type === 'min' && (item.label = { formatter: getFormattedValue(min, m.format) });
      });
    }
    return {
      id: `${m.name}${DEFAULT_SPLITER}${DEFAULT_SPLITER}${k}${compareType}`,
      name: `${k}${metrics.length > 1 ? ` ${m.displayName}` : ''}`,
      type: 'bar',
      ...stackOption,
      sampling: 'average',
      markPoint: {
        data: markPointDataCopy,
        symbolSize: 40,
      },
      workViewParamId: m.workViewParamId,
      data: v.map((g, index) => {
        const itemStyle = {};
        if (itemStyleInfo.conditionStyle) {
          const matchColors = [];
          itemStyleInfo.conditionStyle.forEach((item) => {
            const matchTheCondition = hasMatchedTheCondition(g[formatName], item.operatorType, item.conditionValues);
            matchTheCondition && matchColors.push(item.color);
          });
          matchColors.length && (itemStyle.color = matchColors[0]);
        }
        if (selectedItems && selectedItems.length && selectedItems.some((item) => item === index)) {
          return {
            value: percentage ? (g[formatName] / sumArr[index]) * 100 : g[formatName],
            itemStyle: {
              normal: {
                opacity: 1,
              },
              ...itemStyle,
            },
          };
        } else {
          if (percentage) {
            return {
              value: (g[formatName] / sumArr[index]) * 100,
              itemStyle,
            };
          } else {
            return {
              value: g[formatName],
              itemStyle,
            };
          }
        }
      }),
      itemStyle: {
        normal: {
          opacity: selectedItems && selectedItems.length > 0 ? 0.25 : 1,
          color: color.items[0].config.values[k] || defaultThemeColors[gIndex % defaultThemeColors.length],
        },
      },
      emphasis: {
        focus: 'series',
      },
      ...labelOption,
      ...(gIndex === groupEntries.length - 1 && i === metrics.length - 1 && referenceOptions),
    };
  }
}

export function getDataSum(data, metrics) {
  const dataSum = data.map((d, index) => {
    const metricArr = [];
    let maSum = 0;
    metrics.forEach((m, i) => {
      const decodedMetricName = decodeMetricName(m.name);
      const metricName = d[m.randomName || `${m.agg}(${decodedMetricName})`];
      metricArr.push(metricName);
      if (metricArr.length === metrics.length) {
        metricArr.forEach((mr) => {
          maSum += mr;
        });
      }
    });
    return maSum;
  });
  return dataSum;
}

export function getColorDataSum(data, metrics) {
  let maSum = 0;
  const dataSum = data.map((d, index) => {
    let metricArr = 0;
    metrics.forEach((m, i) => {
      const decodedMetricName = decodeMetricName(m.name);
      const metricName = d[m.randomName || `${m.agg}(${decodedMetricName})`];
      metricArr += metricName;
    });
    return metricArr;
  });
  dataSum.forEach((mr) => {
    maSum += mr;
  });
  return maSum;
}

function barFormatValue(value, metric, percentage) {
  let format = metric.format;
  let formattedValue = value;
  let formatted = 0;
  if (percentage) {
    format = {
      formatType: FieldFormatTypes.Percentage,
      [FieldFormatTypes.Percentage]: {
        decimalPlaces: 0,
      },
    };
    formattedValue /= 100;
    formatted = getFormattedValue(formattedValue, format);
  } else if (format.formatType === 'default') {
    formatted = metricAxisLabelFormatter(formattedValue);
  } else {
    formatted = getFormattedValue(formattedValue, format);
  }
  return formatted;
}
