import CommonChart from '../../containers/Common/CommonChart';
import CommonBreadCrumb from '../../containers/CommonBreadCrumb';
import CommonSummary from '../../containers/CommonSummary';
import CommonTable from '../../containers/CommonTable';
import CommonTab from '../../containers/CommonTab/index';
import ParamTable from '../../containers/ParameterTable/index';

export default {
  title: '事件列表详情',
  components: [
    {
      component: CommonBreadCrumb,
      col: 24,
      props: {
        breadCrumbName: '事件列表',
        breadCrumbSecName: 'key',
      },
    },
    {
      isCard: true,
      component: CommonTab,
      col: 24,
      props: {
        tab: [
          {
            name: '事件详情',
            children: [
              {
                component: CommonSummary,
                col: 24,
                props: {
                  fields: 'eventOccurs,eventUv',
                  comparision: 'chain',
                  addFilterFromUrl: true,
                },
              },
              {
                component: CommonChart,
                col: 24,
                props: {
                  dims: 'date',
                  addFilterFromUrl: true,
                  fields: `eventOccurs,eventUv`,
                  showAll: true,
                },
              },
              {
                component: CommonTable,
                col: 24,
                props: {
                  dims: 'date',
                  fields: `eventOccurs,eventUv`,
                  currentPage: 1,
                  pageCount: 10,
                  addFilterFromUrl: true,
                  showAll: true,
                },
              },
            ],
          },
          {
            name: '事件参数',
            children: [
              {
                component: ParamTable,
                col: 24,
              },
            ],
          },
        ],
      },
    },
  ],
};
