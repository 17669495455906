import React, { Component } from 'react'
import './Loading.sass'
import { Skeleton } from 'antd'

class Loading extends Component {
  render() {
    return this.props.type === 'summary' ? (
      <div className="loading-summary">
        <Skeleton title={false} rows={4} loading={true} />
      </div>
    ) : (
      <div
        className={`spinnerBox ${this.props.size === 'small' ? 'small-type' : 'normal-type'} ${this.props.type ===
        'center'
          ? 'center'
          : ''}`}>
        <div className={`ui-spinner ${this.props.size === 'small' && 'small-ui-spinner'}`} {...this.props}>
          <div className="ui-spinner__rect1" />
          <div className="ui-spinner__rect2" />
          <div className="ui-spinner__rect3" />
        </div>
      </div>
    )
  }
}

export default Loading
