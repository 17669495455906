import CommonBreadCrumb from '../../containers/CommonBreadCrumb';
import CommonTable from '../../containers/CommonTable';
import CommonMixChart from '../../containers/CommonMixChart/index';

export default {
  title: '各终端详情',
  components: [
    {
      component: CommonBreadCrumb,
      props: {
        breadCrumbSecName: 'key',
      },
    },
    {
      component: CommonMixChart,
      isCard: true,
      props: {
        options: ['pv', 'uv', 'uvNew', 'visits'],
        chartProps: [
          {
            col: 6,
            type: 'pie',
            fields: 'pv',
            pageCount: 9,
            orderDesc: true,
            noLegendSelect: true,
            orderColumn: 'pv',
            showAll: true,
            dimsFromUrl: true,
            filterDims: true,
            needOther: true,
            addFilterFromUrl: true,
          },
          {
            addFilterFromUrl: true,
            col: 18,
            lineType: 'area',
            dims: 'date',
            fields: 'pv',
            pageCount: 10,
            orderDesc: true,
            orderColumn: 'pv',
            showAll: true,
            ignoreCache: true,
            dimsFromUrl: true,
            filterDims: true,
          },
        ],
      },
    },

    {
      isCard: true,
      component: CommonTable,
      props: {
        dimsFromUrl: true,
        filterDims: true,
        orderColumn: 'pv',
        orderDesc: true,
        pageCount: 10,
        currentPage: 1,
        addFilterFromUrl: true,
      },
    },
  ],
};
