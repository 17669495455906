import React, { Component } from 'react';
import { Row, Col } from 'antd';
import CommonChart from '../Common/CommonChart';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import legendUtil from '../../utils/legendUtil';
import './index.sass';
const Option = Select.Option;
@observer
class CommonMixChart extends Component {
  @observable params;

  componentWillMount() {
    this.params = this.props.chartProps;
  }

  @action.bound changeParams = (value) => {
    let array = toJS(this.params);
    this.params = null;
    this.params = array.map((item) => {
      return Object.assign(item, { fields: value, orderColumn: value });
    });
  };
  render() {
    return (
      <div className="commonMixChart">
        {this.props.options && (
          <Select
            className="commonMixChart_toggle"
            size="small"
            style={{ width: 96, fontWeight: 400, border: 'none' }}
            defaultValue={this.props.options && this.props.options[0]}
            onSelect={(value) => {
              this.changeParams(value);
            }}>
            {this.props.options.map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {legendUtil.getTitle(item)}
                </Option>
              );
            })}
          </Select>
        )}
        <Row>
          {this.params
            ? this.params.map((item, index) => {
                return (
                  <Col key={index} md={24} lg={item.col}>
                    <CommonChart commonChartType={index === 1 ? 'multiDims' : null} {...item}></CommonChart>
                  </Col>
                );
              })
            : null}
        </Row>
      </div>
    );
  }
}
export default CommonMixChart;
