/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React from 'react';
import { toJS } from 'mobx';
import { message } from 'antd';
import omit from 'lodash/omit';
import {
  FieldSortTypes,
  ControlDefaultValueTypes,
  DEFAULT_SPLITER,
  SQL_NUMBER_TYPES,
  ControlTypes,
  ViewVariableValueTypes,
  ChartTypes,
  ControlFieldTypes,
  RelativeDateValueType,
  ControlPanelTypes,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_FAMILY,
  PIVOT_MAX_CONTENT_WIDTH,
  PIVOT_TITLE_SIZE,
  PIVOT_CELL_BORDER,
  PIVOT_CELL_PADDING,
  PIVOT_BORDER,
  PIVOT_YAXIS_SIZE,
  PIVOT_XAXIS_SIZE,
  PIVOT_LINE_HEIGHT,
  PIVOT_CHART_METRIC_AXIS_MIN_SIZE,
  PIVOT_CHART_ELEMENT_MIN_WIDTH,
  PivotTypes,
  PIVOT_CANVAS_AXIS_SIZE_LIMIT,
  PIVOT_XAXIS_TICK_SIZE,
  PIVOT_XAXIS_ROTATE_LIMIT,
  PIVOT_CHART_POINT_LIMIT,
  DefaultTableCellStyle,
  FieldFormatTypes,
  NumericUnit,
  PIVOT_DEFAULT_SCATTER_SIZE_TIMES,
  EmptyFontSetting,
  OperatorTypes,
} from './constants';
import widgetlibs from './config';
import moment from 'moment';
import mathUtil from '../../utils/mathUtil';
import { ViewModelVisualTypes } from './constants';
import { SQL_DATE_TYPES } from '../../config';
import BoardStore from '../../views/BoardCustom/BoardChart/BoardStore';
import util from '../../util';
import api from '../../api';
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';

const pivotlibs = widgetlibs['pivot'];
// const chartlibs = widgetlibs['chart']

export const uuid = (len, radix = 62) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) {
      uuid[i] = chars[Math.floor(Math.random() * radix)];
    }
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = Math.floor(Math.random() * 16);
        uuid[i] = chars[i === 19 ? ((r % 4) % 8) + 8 : r];
      }
    }
  }
  return uuid.join('');
};
export function getInitialPagination(widget) {
  const { mode, selectedChart, chartStyles } = widget.config;
  if (mode === 'chart' && selectedChart === ChartTypes.Table) {
    const { withPaging, pageSize } = chartStyles.table;
    return {
      withPaging,
      pageSize: withPaging ? Number(pageSize) : 0,
      pageNo: withPaging ? 1 : 0,
      totalCount: 0,
    };
  } else {
    return null;
  }
}

export function getInitialNativeQuery(widget) {
  const { mode, selectedChart, chartStyles } = widget.config;
  if (mode === 'chart' && selectedChart === ChartTypes.Table) {
    return chartStyles.table.withNoAggregators;
  } else {
    return false;
  }
}

export function getInitialPaginationAndNativeQuery(widget) {
  return {
    pagination: getInitialPagination(widget),
    nativeQuery: getInitialNativeQuery(widget),
  };
}

export function getUpdatedPagination(pagination, result) {
  if (!pagination) {
    return pagination;
  }
  const { pageNo, pageSize, totalCount } = result;
  return {
    ...pagination,
    pageNo,
    pageSize,
    totalCount,
  };
}

//itemsInfo初始化
export function getInitialItemInfo(widget, formedViews) {
  return {
    datasource: {
      columns: [],
      pageNo: 0,
      pageSize: 0,
      totalCount: 0,
      resultList: [],
    },
    loading: false,
    queryConditions: {
      linkageFilters: [],
      globalFilters: [],
      linkageVariables: [],
      globalVariables: [],
      drillpathInstance: [],
      ...getLocalControlInitialValues(widget.config.controls, formedViews),
      ...getInitialPaginationAndNativeQuery(widget),
    },
    shareToken: '',
    shareLoading: false,
    authorizedShareToken: '',
    downloadCsvLoading: false,
    interactId: '',
    rendered: false,
    renderType: 'rerender',
    selectedItems: [],
    errorMessage: '',
    config: widget.config,
    viewId: widget.viewId,
    name: widget.name,
  };
}

export function getGlobalControlInitialValues(controls, formedViews) {
  const initialValues = {};
  controls.forEach((control) => {
    const { optionWithVariable, relatedItems, relatedViews } = control;
    const defaultValue = getPreciseDefaultValue(control);
    if (defaultValue) {
      Object.entries(relatedItems).forEach(([itemId, config]) => {
        Object.entries(relatedViews).forEach(([viewId, relatedView]) => {
          if (config.checked && config.viewId === Number(viewId) && formedViews[viewId]) {
            const { model, variable } = formedViews[viewId];
            if (!initialValues[itemId]) {
              initialValues[itemId] = {
                globalFilters: [],
                globalVariables: [],
              };
            }
            if (optionWithVariable) {
              const filterValue = getCustomOptionVariableParams(control, Number(viewId), defaultValue, variable);
              initialValues[itemId].globalVariables = initialValues[itemId].globalVariables.concat(filterValue);
            } else {
              if (relatedView.fieldType === ControlFieldTypes.Column) {
                const filterValue = getFilterParams(control, relatedView.fields, defaultValue, model);
                initialValues[itemId].globalFilters = initialValues[itemId].globalFilters.concat(filterValue);
              } else {
                const filterValue = getVariableParams(control, relatedView.fields, defaultValue, variable);
                initialValues[itemId].globalVariables = initialValues[itemId].globalVariables.concat(filterValue);
              }
            }
          }
        });
      });
    }
  });
  return initialValues;
}

export function getLocalControlInitialValues(controls, formedViews) {
  const initialValues = {
    tempFilters: [], // @TODO combine widget static filters with local filters
    variables: [],
  };
  controls.forEach((control) => {
    const { optionWithVariable, relatedViews } = control;
    const defaultValue = getPreciseDefaultValue(control);
    if (defaultValue) {
      Object.entries(relatedViews).forEach(([viewId, relatedView]) => {
        if (formedViews[viewId]) {
          const { model, variable } = formedViews[viewId];
          if (optionWithVariable) {
            const filterValue = getCustomOptionVariableParams(control, Number(viewId), defaultValue, variable);
            initialValues.variables = initialValues.variables.concat(filterValue);
          } else {
            if (relatedView.fieldType === ControlFieldTypes.Column) {
              const filterValue = getFilterParams(control, relatedView.fields, defaultValue, model);
              initialValues.tempFilters = initialValues.tempFilters.concat(filterValue);
            } else {
              const filterValue = getVariableParams(control, relatedView.fields, defaultValue, variable);
              initialValues.variables = initialValues.variables.concat(filterValue);
            }
          }
        }
      });
    }
  });
  return initialValues;
}

export function getRequestParams(widget, cachedQueryConditions, flush, inputQueryConditions, filters, type) {
  const {
    cols,
    rows,
    metrics,
    secondaryMetrics,
    color,
    label,
    size,
    xAxis,
    tip,
    limit,
    cache,
    expired,
    selectedChart,
    mode,
    fields,
    firstContent,
    secondContent,
    excludeFilters,
  } = widget.config;

  const isExtendTable = selectedChart === 21 ? true : false;
  const customOrders = cols
    .concat(rows)
    .filter(({ sort }) => sort && sort.sortType === FieldSortTypes.Custom)
    .map(({ name, sort }) => ({
      name,
      list: sort[FieldSortTypes.Custom].sortList,
    }));
  let columnNames = type === 'download' ? [] : null;
  // @TODO combine widget static filters with local filters
  let tempFilters = cachedQueryConditions.tempFilters;
  let linkageFilters = cachedQueryConditions.linkageFilters;
  let globalFilters = cachedQueryConditions.globalFilters;
  let paginationOrders = cachedQueryConditions.orders;
  let variables = cachedQueryConditions.variables;
  let linkageVariables = cachedQueryConditions.linkageVariables;
  let globalVariables = cachedQueryConditions.globalVariables;
  let pagination = cachedQueryConditions.pagination;
  let nativeQuery = cachedQueryConditions.nativeQuery;
  let drillStatus = cachedQueryConditions.drillStatus;
  let formulas = [];

  const prevDrillHistory = cachedQueryConditions.drillHistory ? cachedQueryConditions.drillHistory[cachedQueryConditions.drillHistory.length - 1] : {};

  if (inputQueryConditions) {
    tempFilters = inputQueryConditions.tempFilters || tempFilters;
    linkageFilters = inputQueryConditions.linkageFilters || linkageFilters;
    globalFilters = inputQueryConditions.globalFilters || globalFilters;
    paginationOrders = inputQueryConditions.orders || paginationOrders;
    variables = inputQueryConditions.variables || variables;
    linkageVariables = inputQueryConditions.linkageVariables || linkageVariables;
    globalVariables = inputQueryConditions.globalVariables || globalVariables;
    drillStatus = inputQueryConditions.drillStatus || prevDrillHistory;
    pagination = inputQueryConditions.pagination || pagination;
    nativeQuery = inputQueryConditions.nativeQuery !== void 0 ? inputQueryConditions.nativeQuery : nativeQuery;
  }

  // 如果是NLP图表，前两个维度不参与数据请求
  const colsCopy = mode === 'chart' && selectedChart === 19 ? cols.slice(2, cols.length) : cols;
  const nlpKeys =
    mode === 'chart' && selectedChart === 19
      ? cols
          .slice(0, 2)
          .map((c) => (c.randomName ? `${c.formula || c.name} AS ${c.randomName}` : c.name))
          .map((item) => (item.includes('AS ') ? item.split('AS ')[1] : item))
      : [];
  let groups = colsCopy
    .concat(rows)
    .filter((g) => g.name !== '指标名称')
    .map((g) => {
      columnNames && columnNames.push(g.field.alias || g.fieldDisplay || g.name);
      return g.randomName ? `${g.formula || g.name} AS ${g.randomName}` : g.name;
    });
  if (color) {
    groups = groups.concat(
      color.items.map((c) => {
        columnNames && columnNames.push(c.fieldDisplay || c.name);
        return c.randomName ? `${c.formula || c.name} AS ${c.randomName}` : c.name;
      })
    );
  }
  if (label) {
    groups = groups.concat(
      label.items
        .filter((l) => l.type === 'category')
        .map((l) => {
          columnNames && columnNames.push(l.field.alias || l.fieldDisplay || l.name);
          return l.name;
        })
    );
  }

  let aggregators = metrics.map((m) => {
    let aItem =
      m.customType === 1
        ? {
            column: decodeMetricName(m.name),
            func: m.agg,
          }
        : m.randomName
        ? {
            formula: `${m.formula} AS ${m.randomName}`,
            column: `${decodeMetricName(m.name)} AS ${m.randomName}`,
            func: m.agg,
          }
        : {
            formula: m.formula,
            column: decodeMetricName(m.name),
            func: m.agg,
          };
    m.yoyMom && (aItem.yoyMom = mathUtil.getYoyMom(m.yoyMom));
    m.formulaExt && (aItem.formulaExt = m.formulaExt);
    columnNames && columnNames.push(m.field.alias || m.fieldDisplay || decodeMetricName(m.name));
    return aItem;
  });
  if((!metrics || !metrics.length) && fields){
    fields.map((m) => {
      columnNames && columnNames.push(m.field.alias || m.fieldDisplay || decodeMetricName(m.name));
    });
  }
  if (fields) {
    formulas = fields
      .concat(firstContent || [], secondContent || [])
      .map((c) => (c.randomName ? `${c.formula || decodeMetricName(c.name)} AS ${c.randomName}` : decodeMetricName(c.name)))
      .filter((g) => g !== '指标名称');
  }

  let [orders, nlpOrder] = getOrders(widget.config.orders, cols.concat(rows, metrics, fields, firstContent || [], secondContent || []), nlpKeys);

  const widgetFilters = widget.config.filters.reduce((a, b) => {
    let filterItem = b.config.sqlModel;
    if (SQL_DATE_TYPES.includes(b.sqlType) && b.config.filterSource.selectedDate !== 'other') {
      const selectedDate = b.config.filterSource.selectedDate;
      filterItem[0].value = `'${mathUtil.keyToDateBI(selectedDate)}'`;
    }
    return a.concat(filterItem);
  }, []);

  filters ? (filters = JSON.parse(filters)) : (filters = []);
  const paramExcludeFilters = excludeFilters ? excludeFilters.map((item) => item.name) : [];
  let compareFilterKeys = [];
  let filterKeys = [];
  // 对比功能
  const compareFilterConfig = BoardStore.compareFilterConfig[widget.id];
  compareFilterConfig && compareFilterKeys.push(compareFilterConfig.filed.fieldName);
  let { currentFilters, compareFilters } = getCompareFilter(compareFilterConfig);
  let widgetFiltersCopy = [];
  filters.forEach((item) => {
    !compareFilterKeys.includes(item.name) && (widgetFiltersCopy.push(item), filterKeys.push(item.name));
  });
  widgetFilters.forEach((item) => {
    !compareFilterKeys.includes(item.name) && !filterKeys.includes(item.name) && widgetFiltersCopy.push(item);
  });
  compareFilters.length && (compareFilters = widgetFiltersCopy.concat(compareFilters));
  filters = widgetFiltersCopy.concat(currentFilters);
  if (secondaryMetrics && secondaryMetrics.length) {
    aggregators = aggregators.concat(
      secondaryMetrics.map((m) => {
        let aItem =
          m.customType === 1
            ? {
                column: decodeMetricName(m.name),
                func: m.agg,
              }
            : m.randomName
            ? {
                formula: `${m.formula} AS ${m.randomName}`,
                column: `${decodeMetricName(m.name)} AS ${m.randomName}`,
                func: m.agg,
              }
            : {
                formula: m.formula,
                column: decodeMetricName(m.name),
                func: m.agg,
              };
        m.yoyMom && (aItem.yoyMom = mathUtil.getYoyMom(m.yoyMom));
        m.formulaExt && (aItem.formulaExt = m.formulaExt);
        columnNames && columnNames.push(m.field.alias || m.fieldDisplay || decodeMetricName(m.name));
        return aItem;
      })
    );
  }

  if (label) {
    aggregators = aggregators.concat(
      label.items
        .filter((l) => l.type === 'value')
        .map((l) => {
          columnNames && columnNames.push(l.field.alias || l.fieldDisplay || l.name);
          return {
            column: decodeMetricName(l.name),
            func: l.agg,
          };
        })
    );
  }
  if (size) {
    aggregators = aggregators.concat(
      size.items.map((l) => {
        let aItem =
          l.customType === 1
            ? {
                column: decodeMetricName(l.name),
                func: 'sum',
              }
            : l.randomName
            ? {
                formula: `${l.formula} AS ${l.randomName}`,
                column: `${decodeMetricName(l.name)} AS ${l.randomName}`,
                func: 'original',
              }
            : {
                formula: l.formula,
                column: decodeMetricName(l.name),
                func: 'original',
              };
        return aItem;
      })
    );
  }
  // if (size) {
  //   aggregators = aggregators.concat(
  //     size.items.map((s) => ({
  //       column: decodeMetricName(s.name),
  //       func: s.agg,
  //     }))
  //   );
  // }
  if (xAxis) {
    aggregators = aggregators.concat(
      xAxis.items.map((x) => {
        columnNames && columnNames.push(x.field.alias || x.fieldDisplay || decodeMetricName(x.name));
        return {
          column: decodeMetricName(x.name),
          func: x.agg,
        };
      })
    );
  }
  if (tip) {
    aggregators = aggregators.concat(
      tip.items.map((t) => {
        let aItem =
          t.customType === 1
            ? {
                column: decodeMetricName(t.name),
                func: t.agg,
              }
            : t.randomName
            ? {
                formula: `${t.formula} AS ${t.randomName}`,
                column: `${decodeMetricName(t.name)} AS ${t.randomName}`,
                func: t.agg,
              }
            : {
                formula: t.formula,
                column: decodeMetricName(t.name),
                func: t.agg,
              };
        t.yoyMom && (aItem.yoyMom = mathUtil.getYoyMom(t.yoyMom));
        t.formulaExt && (aItem.formulaExt = t.formulaExt);
        columnNames && columnNames.push(t.field.alias || t.fieldDisplay || decodeMetricName(t.name));
        return aItem;
      })
    );
  }
  if (paginationOrders && paginationOrders.direction) {
    orders.unshift(paginationOrders);
  }

  const order = [];
  const sonOrders = [];

  const getChart21Orders = () => {
    if (orders.length) {
      orders.forEach((o) => {
        let index = cols.findIndex((i) => {
          if (i.sort) {
            if (i.sort.randomName && i.sort.randomName === o.randomName) {
              return true;
            }
            if (!i.sort.randomName && i.randomName === o.column) {
              return true;
            }
          }
        });
        if (index && index > -1) {
          sonOrders.push(o);
        } else {
          order.push(o);
        }
      });
    }
  };
  getChart21Orders();

  const param = {
    groups: isExtendTable && groups.length && type !== 'download' ? [groups[0]] : groups,
    aggregators,
    filters,
    excludeFilters: paramExcludeFilters,
    tempFilters,
    linkageFilters,
    globalFilters,
    variables,
    linkageVariables,
    globalVariables,
    orders: isExtendTable && type !== 'download' ? order : orders,
    sonOrders,
    nlpOrder,
    cache,
    expired,
    flush,
    pagination,
    nativeQuery,
    customOrders,
    drillStatus,
    columnNames,
  };
  limit !== null && (param.limit = limit);
  formulas.length && (param.formulas = formulas);
  widget.config.hasOwnProperty('noNA') && (param.noNA = widget.config.noNA);
  compareFilters.length && (param.compareFilters = compareFilters);
  return param;
  function getOrders(orders, items, nlpKeys) {
    // 兼容旧数据
    let orderCopy = [],
      nlpOrderCopy = [];
    let itemObj = {};
    items.forEach((item) => {
      if (item) {
        const canUseNew = item.sort && item.sort.randomName;
        if (canUseNew) {
          return (itemObj[item.randomName || item.workViewParamId || item.name] = item);
        }
        return (itemObj[item.workViewParamId || item.name] = item);
      }
    });
    orders.forEach((item) => {
      let orderItem;
      if (item.realName) {
        const column = itemObj[item.column] || itemObj[item.randomName] || itemObj[item.realName];
        orderItem = {
          column: column.randomName,
          direction: item.direction,
        };
      } else {
        orderItem = {
          ...item,
        };
      }
      nlpKeys.indexOf(orderItem.column) < 0 ? orderCopy.push(orderItem) : nlpOrderCopy.push(orderItem);
    });
    return [orderCopy, nlpOrderCopy];
  }
}

function getCompareFilter(compareFilterConfig) {
  if (!compareFilterConfig) return { currentFilters: [], compareFilters: [] };
  const { compare, current, filed } = compareFilterConfig;
  if (SQL_DATE_TYPES.includes(filed.sqlType)) {
    const currentTime = current.lastDate ? mathUtil.keyToDate(current.lastDate) : current.date;
    const compareTime = compare.lastDate ? mathUtil.keyToDate(compare.lastDate) : compare.date;
    const currentTimeArr = currentTime.split(',');
    const compareTimeArr = compareTime.split(',');
    const currentFilters = getDateFilter(currentTimeArr, filed.fieldName, filed.sqlType);
    const compareFilters = getDateFilter(compareTimeArr, filed.fieldName, filed.sqlType);
    return { currentFilters, compareFilters };
  } else {
    const currentFilters = [
      {
        name: filed.fieldName,
        operator: 'in',
        sqlType: filed.sqlType,
        type: 'filter',
        value: [`'${current}'`],
      },
    ];
    const compareFilters = [
      {
        name: filed.fieldName,
        operator: 'in',
        sqlType: filed.sqlType,
        type: 'filter',
        value: [`'${compare}'`],
      },
    ];
    return { currentFilters, compareFilters };
  }
}

function getDateFilter(dateArr, fieldName, sqlType) {
  if (dateArr[0] === dateArr[1]) {
    return [
      {
        name: fieldName,
        operator: '=',
        sqlType: sqlType,
        type: 'filter',
        value: `'${dateArr[0]}'`,
      },
    ];
  } else {
    return [
      {
        name: fieldName,
        operator: '>=',
        sqlType: 'DATETIME',
        type: 'filter',
        value: `'${dateArr[0]}'`,
      },
      {
        name: fieldName,
        operator: '<=',
        sqlType: 'DATETIME',
        type: 'filter',
        value: `'${dateArr[1]}'`,
      },
    ];
  }
}

export function getRequestBody(requestParams) {
  let {
    filters,
    tempFilters, // @TODO combine widget static filters with local filters
    linkageFilters,
    globalFilters,
    variables,
    linkageVariables,
    globalVariables,
    pagination,
    drillStatus,
    groups,
    columnNames,
    nlpOrder,
    ...rest
  } = requestParams;
  const { pageSize, pageNo } = pagination || { pageSize: 0, pageNo: 0 };

  let combinedFilters = filters
    .concat(toJS(tempFilters))
    .concat(toJS(linkageFilters))
    .concat(toJS(globalFilters));
  if (drillStatus && drillStatus.filters) {
    combinedFilters = combinedFilters.concat(drillStatus.filters);
  }
  combinedFilters = combinedFilters.filter((item) => {
    return Array.isArray(item) ? item.value.length : item;
  });
  let param = {
    ...omit(rest, 'customOrders'),
    groups: drillStatus && drillStatus.groups ? drillStatus.groups : groups,
    filters: combinedFilters,
    params: variables ? variables.concat(toJS(linkageVariables)).concat(toJS(globalVariables)) : '',
    pageSize,
    pageNo,
  };
  columnNames && (param.columnNames = columnNames);
  return param;
}

export function getFormValuesRelatedItems(controls, formValues) {
  return Object.keys(formValues).reduce((items, key) => {
    const control = controls.find((c) => c.key === key);
    const { relatedItems } = control;
    const checkedItems = Object.entries(relatedItems)
      .filter(([itemId, config]) => config.checked)
      .map(([itemId]) => itemId);
    return Array.from(new Set([...items, ...checkedItems]));
  }, []);
}

export function getCurrentControlValues(type, controls, formedViews, allFormValues, changedFormValues) {
  const updatedFormValues = {
    ...allFormValues,
    ...changedFormValues,
  };

  if (type === ControlPanelTypes.Global) {
    const changedFormValuesRelatedItems = getFormValuesRelatedItems(controls, changedFormValues || allFormValues);

    const conditionsByItem = {};

    changedFormValuesRelatedItems.forEach((itemId) => {
      Object.entries(updatedFormValues).forEach(([key, value]) => {
        const control = controls.find((c) => c.key === key);

        if (control) {
          const { optionWithVariable, relatedViews, relatedItems } = control;
          const relatedItem = relatedItems[itemId];

          if (relatedItem && relatedItem.checked && formedViews[relatedItem.viewId]) {
            const relatedView = relatedViews[relatedItem.viewId];
            const { model, variable } = formedViews[relatedItem.viewId];
            if (!conditionsByItem[itemId]) {
              conditionsByItem[itemId] = {
                globalVariables: [],
                globalFilters: [],
              };
            }
            if (optionWithVariable) {
              const controlVariables = getCustomOptionVariableParams(control, relatedItem.viewId, value, variable);
              conditionsByItem[itemId].globalVariables = conditionsByItem[itemId].globalVariables.concat(controlVariables);
            } else {
              if (relatedView.fieldType === ControlFieldTypes.Column) {
                const controlFilters = getFilterParams(control, relatedView.fields, value, model);
                conditionsByItem[itemId].globalFilters = conditionsByItem[itemId].globalFilters.concat(controlFilters);
              } else {
                const controlVariables = getVariableParams(control, relatedView.fields, value, variable);
                conditionsByItem[itemId].globalVariables = conditionsByItem[itemId].globalVariables.concat(controlVariables);
              }
            }
          }
        }
      });
    });

    return conditionsByItem;
  } else {
    const conditions = {
      tempFilters: [],
      variables: [],
    };

    Object.entries(updatedFormValues).forEach(([key, value]) => {
      const control = controls.find((c) => c.key === key);

      if (control) {
        const [viewId, relatedView] = Object.entries(control.relatedViews)[0];
        if (formedViews[viewId]) {
          const { model, variable } = formedViews[viewId];
          if (control.optionWithVariable) {
            const controlVariables = getCustomOptionVariableParams(control, Number(viewId), value, variable);
            conditions.variables = conditions.variables.concat(controlVariables);
          } else {
            if (relatedView.fieldType === ControlFieldTypes.Column) {
              const controlFilters = getFilterParams(control, relatedView.fields, value, model);
              conditions.tempFilters = conditions.tempFilters.concat(controlFilters);
            } else {
              const controlVariables = getVariableParams(control, relatedView.fields, value, variable);
              conditions.variables = conditions.variables.concat(controlVariables);
            }
          }
        }
      }
    });

    return conditions;
  }
}

export function encodeMetricName(name) {
  return `${name}${DEFAULT_SPLITER}${uuid(8, 16)}`;
}

export function decodeMetricName(encodedName) {
  return encodedName.split(DEFAULT_SPLITER)[0];
}

export function getPreciseDefaultValue(control) {
  const { type, defaultValueType, defaultValue, multiple } = control;
  switch (type) {
    case ControlTypes.DateRange:
      return defaultValueType === ControlDefaultValueTypes.Dynamic
        ? defaultValue.map((val) => transformRelativeDateValue(val))
        : Array.isArray(defaultValue)
        ? defaultValue.map((val) => moment(val))
        : defaultValue;
    case ControlTypes.Date:
      if (defaultValue) {
        return defaultValueType === ControlDefaultValueTypes.Dynamic
          ? transformRelativeDateValue(defaultValue)
          : multiple
          ? defaultValue
          : moment(defaultValue);
      }
    default:
      return defaultValue;
  }
}

export function transformRelativeDateValue(val) {
  const { type, value, valueType } = val;
  return valueType === RelativeDateValueType.Prev
    ? moment()
        .subtract(value, `${type}s`)
        .startOf(type)
    : moment()
        .add(value, `${type}s`)
        .startOf(type);
}
// 全局过滤器 与 组件控制器 filter 操作
export function getFilterParams(control, fields, value, models) {
  const { type, dateFormat, multiple, operator } = control; // select  ''  true in
  // filter is related with only one field
  const filterFieldName = fields[0];
  const filters = [];

  if (value === void 0 || value === null || (typeof value === 'string' && !value.trim()) || !models[filterFieldName]) {
    return filters;
  }

  const { sqlType } = models[filterFieldName];
  const filterBase = {
    name: filterFieldName,
    type: 'filter',
    value: getValidColumnValue(value, sqlType),
    sqlType,
    operator,
  };
  switch (type) {
    case ControlTypes.InputText:
    case ControlTypes.Radio:
      filters.push(filterBase);
      break;
    case ControlTypes.Select:
    case ControlTypes.TreeSelect:
      if (multiple) {
        if (Array.isArray(value) && value.length > 0) {
          filters.push({
            ...filterBase,
            value: value.map((val) => getValidColumnValue(val, sqlType)),
          });
        }
      } else {
        filters.push(filterBase);
      }
      break;
    case ControlTypes.NumberRange:
    case ControlTypes.Slider:
      value.forEach((val, index) => {
        if (val !== '' && !isNaN(val)) {
          filters.push({
            ...filterBase,
            operator: !index ? '>=' : '<=',
            value: getValidColumnValue(val, sqlType),
          });
        }
      });
      break;
    case ControlTypes.Date:
      filters.push({
        ...filterBase,
        value: multiple ? value.split(',').map((val) => getValidColumnValue(val, sqlType)) : getValidColumnValue(moment(value).format(dateFormat), sqlType),
      });
      break;
    case ControlTypes.DateRange:
      if (value.length) {
        value.forEach((val, index) => {
          filters.push({
            ...filterBase,
            operator: !index ? '>=' : '<=',
            value: getValidColumnValue(moment(val).format(dateFormat), sqlType),
          });
        });
      }
      break;
    default:
      const inputValue = value.target.value.trim();
      if (inputValue) {
        filters.push({
          ...filterBase,
          value: getValidColumnValue(inputValue, sqlType),
        });
      }
      break;
  }

  return filters;
}
export function getValidColumnValue(value, sqlType) {
  if (!value || !sqlType) {
    return value;
  }
  return SQL_NUMBER_TYPES.includes(sqlType) ? value : `'${value}'`;
}
export function getVariableParams(control, fields, value, variables) {
  const { type, dateFormat, multiple } = control;
  const fieldsVariables = fields.map((name) => variables.find((v) => v.name === name)).filter((f) => !!f);
  let params = [];

  if (value === void 0 || value === null || (typeof value === 'string' && !value.trim()) || !fieldsVariables.length) {
    return params;
  }

  switch (type) {
    case ControlTypes.InputText:
    case ControlTypes.Radio:
      params = fieldsVariables.map(({ name, valueType }) => ({
        name,
        value: getValidVariableValue(value, valueType),
      }));
      break;
    case ControlTypes.Select:
    case ControlTypes.TreeSelect:
      if (multiple) {
        if (value.length && value.length > 0) {
          params = fieldsVariables.map(({ name, valueType }) => ({
            name,
            value: value.map((val) => getValidVariableValue(val, valueType)).join(','),
          }));
        }
      } else {
        params = fieldsVariables.map(({ name, valueType }) => ({
          name,
          value: getValidVariableValue(value, valueType),
        }));
      }
      break;
    case ControlTypes.NumberRange:
    case ControlTypes.Slider:
      params = value.reduce((arr, val, index) => {
        if (fieldsVariables[index] && val !== '' && !isNaN(val)) {
          const { name, valueType } = fieldsVariables[index];
          return arr.concat({
            name,
            value: getValidVariableValue(val, valueType),
          });
        }
        return arr;
      }, []);
      break;
    case ControlTypes.Date:
      if (multiple) {
        params = fieldsVariables.map(({ name }) => ({
          name,
          value: value
            .split(',')
            .map((v) => `'${v}'`)
            .join(','),
        }));
      } else {
        params = fieldsVariables.map(({ name }) => ({
          name,
          value: `'${moment(value).format(dateFormat)}'`,
        }));
      }
      break;
    case ControlTypes.DateRange:
      if (value.length) {
        params = value
          .map((v, index) => {
            return fieldsVariables[index]
              ? {
                  name: fieldsVariables[index].name,
                  value: `'${moment(v).format(dateFormat)}'`,
                }
              : null;
          })
          .filter((p) => p);
      }
      break;
    default:
      const val = value.target.value.trim();
      if (val) {
        params = fieldsVariables.map(({ name, valueType }) => ({
          name,
          value: getValidVariableValue(val, valueType),
        }));
      }
      break;
  }
  return params;
}
export function getValidVariableValue(value, valueType) {
  switch (valueType) {
    case ViewVariableValueTypes.String:
    case ViewVariableValueTypes.Date:
      return `'${value}'`;
    case ViewVariableValueTypes.Boolean:
      if (typeof value === 'string') {
        if (value.toLowerCase() === 'false' || value.trim() === '') {
          return false;
        } else {
          return true;
        }
      } else {
        return !!value;
      }
    default:
      return value;
  }
}
export function getCustomOptionVariableParams(control, viewId, value, variables) {
  const { customOptions } = control;
  let params = [];

  if (value === void 0 || value === null || (typeof value === 'string' && !value.trim())) {
    return params;
  }

  Array.from([])
    .concat(value)
    .forEach((val) => {
      const selectedOption = customOptions.find((o) => o.value === val);
      if (selectedOption && selectedOption.variables[viewId]) {
        params = params.concat(getVariableParams({ ...control, multiple: false }, [selectedOption.variables[viewId]], val, variables));
      }
    });

  return params;
}
export const getPivotContentTextWidth = (text, fontWeight = DEFAULT_FONT_WEIGHT, fontSize = DEFAULT_FONT_SIZE, fontFamily = DEFAULT_FONT_FAMILY) => {
  return Math.min(getTextWidth(text, fontWeight, fontSize, fontFamily), PIVOT_MAX_CONTENT_WIDTH);
};

let utilCanvas = null;

export const getTextWidth = (text, fontWeight = DEFAULT_FONT_WEIGHT, fontSize = DEFAULT_FONT_SIZE, fontFamily = DEFAULT_FONT_FAMILY) => {
  const canvas = utilCanvas || (utilCanvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width);
};

export function getAggregatorLocale(agg, metric) {
  switch (agg) {
    case 'sum':
      return '总计';
    case 'avg':
      return '平均数';
    case 'count':
      return '计数';
    case 'COUNTDISTINCT':
      return '去重计数';
    case 'max':
      return '最大值';
    case 'min':
      return '最小值';
    case 'median':
      return '中位数';
    case 'percentile':
      return '百分位';
    case 'stddev':
      return '标准偏差';
    case 'var':
      return '方差';
    case 'hour':
      return '按时';
    case 'day':
      return '按天';
    case 'week':
      return '按周';
    case 'month':
      return '按月';
    case 'quarter':
      return '按季';
    case 'halfyear':
      return '按半年';
    case 'year':
      return '按年';
    case 'mom':
      return metric && metric.yoyMom.compareType === 'momRatio' ? '环比率' : '环比值';
    case 'yoy':
      let start = '';
      switch (metric && metric.yoyMom.period) {
        case 'year':
          start = '年';
          break;
        case 'month':
          start = '月';
          break;
        case 'week':
          start = '周';
          break;
      }
      const end = metric && metric.yoyMom.compareType === 'yoyRatio' ? '率' : '值';
      return `${start}同比${end}`;
    case 'init':
      return '原数据';
    case 'allRegion':
      return '全国值';
    case 'rank':
      return '排名变化';
  }
}

export function getPivotCellWidth(width) {
  return width + PIVOT_CELL_PADDING * 2 + PIVOT_CELL_BORDER * 2;
}

export const getTableBodyWidth = (direction, containerWidth, rowHeaderWidths) => {
  const title = rowHeaderWidths.length && PIVOT_TITLE_SIZE;
  const rowHeaderWidthSum =
    direction === 'row'
      ? rowHeaderWidths.slice(0, rowHeaderWidths.length - 1).reduce((sum, r) => sum + getPivotCellWidth(r), 0)
      : rowHeaderWidths.reduce((sum, r) => sum + getPivotCellWidth(r), 0);
  return containerWidth - PIVOT_BORDER * 2 - rowHeaderWidthSum - PIVOT_YAXIS_SIZE - title;
};

export function getPivotCellHeight(height) {
  return (height || PIVOT_LINE_HEIGHT) + PIVOT_CELL_PADDING * 2 + PIVOT_CELL_BORDER;
}

export const getTableBodyHeight = (direction, containerHeight, columnHeaderCount) => {
  const title = columnHeaderCount && PIVOT_TITLE_SIZE;
  const realColumnHeaderCount = direction === 'col' ? Math.max(columnHeaderCount - 1, 0) : columnHeaderCount;
  return containerHeight - PIVOT_BORDER * 2 - realColumnHeaderCount * getPivotCellHeight() - PIVOT_XAXIS_SIZE - title;
};

export function getChartUnitMetricWidth(tableBodyWidth, colKeyCount, metricCount) {
  const realContainerWidth = Math.max(tableBodyWidth, colKeyCount * metricCount * PIVOT_CHART_METRIC_AXIS_MIN_SIZE);
  return realContainerWidth / colKeyCount / metricCount;
}
export function getChartElementSize(direction, tableBodySideLength, chartElementCountArr, multiCoordinate) {
  let chartElementCount;
  let side;

  if (direction === 'col') {
    chartElementCount = Math.max(1, chartElementCountArr[0]);
    side = tableBodySideLength[0];
  } else {
    chartElementCount = Math.max(1, chartElementCountArr[1]);
    side = tableBodySideLength[1];
  }

  const sizePerElement = side / chartElementCount;
  const limit = multiCoordinate ? PIVOT_CHART_METRIC_AXIS_MIN_SIZE : PIVOT_CHART_ELEMENT_MIN_WIDTH;

  return Math.max(Math.floor(sizePerElement), limit);
}

export function shouldTableBodyCollapsed(direction, elementSize, tableBodyHeight, rowKeyLength) {
  return direction === 'row' && tableBodyHeight > rowKeyLength * elementSize;
}

export function getChartUnitMetricHeight(tableBodyHeight, rowKeyCount, metricCount) {
  const realContainerHeight = Math.max(tableBodyHeight, rowKeyCount * metricCount * PIVOT_CHART_METRIC_AXIS_MIN_SIZE);
  return realContainerHeight / rowKeyCount / metricCount;
}

export function getAxisInterval(max, splitNumber) {
  const roughInterval = Math.floor(max / splitNumber);
  const divisor = Math.pow(10, `${roughInterval}`.length - 1);
  return (Math.floor(roughInterval / divisor) + 1) * divisor;
}

export function getSizeRate(min, max) {
  return Math.max(min / 10, max / 100);
}

export function getStyleConfig(chartStyles) {
  return {
    ...chartStyles,
    pivot: chartStyles.pivot || { ...getPivot().style['pivot'] }, // FIXME 兼容0.3.0-beta 数据库
  };
}

export function getPivot() {
  return pivotlibs.find((p) => p.id === PivotTypes.PivotTable);
}

export function spanSize(arr, i, j) {
  if (i !== 0) {
    let noDraw = true;
    for (let x = 0; x <= j; x += 1) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }

  let len = 0;
  while (i + len < arr.length) {
    let stop = false;
    for (let x = 0; x <= j; x += 1) {
      if (arr[i][x] !== arr[i + len][x]) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
}

export function getAxisData(type, rowKeys, colKeys, rowTree, colTree, tree, metrics, drawingData, dimetionAxis) {
  const { elementSize, unitMetricWidth, unitMetricHeight } = drawingData;
  const data = [];
  const chartLine = [];
  let axisLength = 0;

  let renderKeys;
  let renderTree;
  let sndKeys;
  let sndTree;
  let renderDimetionAxis;
  let unitMetricSide;

  if (type === 'x') {
    renderKeys = colKeys;
    renderTree = colTree;
    sndKeys = rowKeys;
    sndTree = rowTree;
    renderDimetionAxis = 'col';
    unitMetricSide = unitMetricWidth;
  } else {
    renderKeys = rowKeys;
    renderTree = rowTree;
    sndKeys = colKeys;
    sndTree = colTree;
    renderDimetionAxis = 'row';
    unitMetricSide = unitMetricHeight;
  }

  if (renderKeys.length) {
    renderKeys.forEach((keys, i) => {
      const flatKey = keys.join(String.fromCharCode(0));
      const { records } = renderTree[flatKey];

      if (dimetionAxis === renderDimetionAxis) {
        const nextKeys = renderKeys[i + 1] || [];
        let lastUnit = chartLine[chartLine.length - 1];
        if (!lastUnit || lastUnit.ended) {
          lastUnit = {
            width: 0,
            records: [],
            ended: false,
          };
          chartLine.push(lastUnit);
        }
        lastUnit.records.push({
          key: keys[keys.length - 1],
          value: records[0],
        });
        if ((keys.length === 1 && i === renderKeys.length - 1) || keys[keys.length - 2] !== nextKeys[nextKeys.length - 2]) {
          const unitLength = lastUnit.records.length * elementSize;
          axisLength += unitLength;
          lastUnit.width = unitLength;
          lastUnit.ended = true;
        }
        if (!nextKeys.length) {
          data.push({
            key: flatKey,
            data: chartLine.slice(),
          });
        }
      } else {
        axisLength += unitMetricSide;
        chartLine.push({
          width: unitMetricSide,
          records: [
            {
              key: keys[keys.length - 1],
              value: records[0],
            },
          ],
          ended: true,
        });
        if (i === renderKeys.length - 1) {
          data.push({
            key: flatKey,
            data: chartLine.slice(),
          });
        }
      }
    });
  } else {
    if (dimetionAxis !== renderDimetionAxis) {
      data.push({
        key: uuid(8, 16),
        data: [
          {
            width: unitMetricSide,
            records: [
              {
                key: '',
                value: sndKeys.length ? Object.values(sndTree)[0] : tree[0] ? tree[0][0] : [],
              },
            ],
            ended: true,
          },
        ],
      });
      axisLength = unitMetricSide;
    }
  }

  axisLength = dimetionAxis === renderDimetionAxis ? axisLength : axisLength * metrics.length;

  return {
    data: axisDataCutting(type, dimetionAxis, metrics, axisLength, data),
    length: axisLength,
  };
}

export function axisDataCutting(type, dimetionAxis, metrics, axisLength, data) {
  if (axisLength > PIVOT_CANVAS_AXIS_SIZE_LIMIT) {
    const result = [];
    data.forEach((line) => {
      let blockLine = {
        key: `${uuid(8, 16)}${line.key}`,
        data: [],
      };
      let block = {
        key: '',
        length: 0,
        data: [blockLine],
      };
      line.data.forEach((unit, index) => {
        const unitWidth = (type === 'x' && dimetionAxis === 'row') || (type === 'y' && dimetionAxis === 'col') ? unit.width * metrics.length : unit.width;
        if (block.length + unitWidth > PIVOT_CANVAS_AXIS_SIZE_LIMIT) {
          block.key = `${index}${block.data.map((d) => d.key).join(',')}`;
          result.push(block);
          blockLine = {
            key: `${uuid(8, 16)}${line.key}`,
            data: [],
          };
          block = {
            key: '',
            length: 0,
            data: [blockLine],
          };
        }
        block.length += unitWidth;
        blockLine.data.push(unit);
        if (index === line.data.length - 1) {
          block.key = `${index}${block.data.map((d) => d.key).join(',')}`;
          result.push(block);
        }
      });
    });
    return result;
  } else {
    return [
      {
        key: 'block',
        data,
        length: axisLength,
      },
    ];
  }
}

export function getXaxisLabel(elementSize) {
  return function(label) {
    const originLabel = label;
    const ellipsis = '…';
    const limit = elementSize > PIVOT_XAXIS_ROTATE_LIMIT ? elementSize : PIVOT_XAXIS_SIZE - PIVOT_XAXIS_TICK_SIZE;
    while (getTextWidth(label) > limit) {
      label = label.substring(0, label.length - 1);
    }
    return label === originLabel ? label : `${label.substring(0, label.length - 1)}${ellipsis}`;
  };
}

export function metricAxisLabelFormatter(value) {
  if (value >= Math.pow(10, 12)) {
    return `${precision(value / Math.pow(10, 12))}T`;
  } else if (value >= Math.pow(10, 9) && value < Math.pow(10, 12)) {
    return `${precision(value / Math.pow(10, 9))}B`;
  } else if (value >= Math.pow(10, 6) && value < Math.pow(10, 9)) {
    return `${precision(value / Math.pow(10, 6))}M`;
  } else if (value >= Math.pow(10, 3) && value < Math.pow(10, 6)) {
    return `${precision(value / Math.pow(10, 3))}K`;
  } else {
    return value;
  }

  function precision(num) {
    return num >= 10 ? Math.floor(num) : num.toFixed(1);
  }
}

export function getChartPieces(total, lines) {
  if (lines === 1) {
    return lines;
  }
  const eachLine = total / lines;
  const pct = Math.abs(eachLine - PIVOT_CHART_POINT_LIMIT) / PIVOT_CHART_POINT_LIMIT;
  return pct < 0.2 ? lines : eachLine > PIVOT_CHART_POINT_LIMIT ? lines : getChartPieces(total, Math.round(lines / 2));
}
export function getFieldAlias(fieldConfig, queryVariableMap) {
  if (!fieldConfig) {
    return '';
  }

  const { alias, useExpression } = fieldConfig;
  if (!useExpression) {
    return alias;
  }

  const queryKeys = extractQueryVariableNames(alias, true);
  const keys = [];
  const vals = [];
  queryKeys.forEach((queryKey) => {
    keys.push(queryKey);
    const queryValue = queryVariableMap[queryKey];
    if (queryValue === undefined) {
      vals.push('');
    } else {
      vals.push(typeof queryValue === 'number' ? queryValue : queryValue.replace(/^(['"])|(['"])$/g, ''));
    }
  });

  const Moment = moment;
  let funcBody = alias;
  if (!alias.includes('return')) {
    funcBody = 'return ' + funcBody;
  }
  const paramNames = ['Moment', ...keys, funcBody];
  try {
    const func = Function.apply(null, paramNames);
    const params = [Moment, ...vals];
    const dynamicAlias = func(...params);
    return dynamicAlias;
  } catch (e) {
    message.error(`字段别名转换错误：${e.message}`);
  }
}

export function extractQueryVariableNames(expression, withBoundaryToken = false) {
  const names = [];
  if (!expression) {
    return names;
  }
  const varReg = /\$(\w+)\$/g;
  expression.replace(varReg, (match, p) => {
    const name = withBoundaryToken ? match : p;
    if (!names.includes(name)) {
      names.push(name);
    }
    return name;
  });
  return names;
}

export function hasProperty(obj, key) {
  return obj[key] ? obj[key] : false;
}

export function findChildConfig(config, keyName, childrenName, keyValue, callback) {
  if (!Array.isArray(config)) {
    return false;
  }

  const hasFound = config.some((cfg) => {
    if (cfg[keyName] === keyValue) {
      callback(cfg);
      return true;
    }
    const children = cfg[childrenName];
    if (Array.isArray(children) && children.length > 0) {
      return findChildConfig(children, keyName, childrenName, keyValue, callback);
    }
    return false;
  });

  return hasFound;
}

export function traverseConfig(config, childrenName, callback) {
  if (!Array.isArray(config)) {
    return;
  }

  config.forEach((cfg, idx) => {
    if (Array.isArray(cfg[childrenName])) {
      const children = cfg[childrenName];
      if (children.length > 0) {
        traverseConfig(children, childrenName, callback);
      }
    }
    callback(cfg, idx, config);
  });
}

export function computeCellWidth(style, cellValue) {
  const { fontWeight, fontSize, fontFamily } = style || DefaultTableCellStyle;
  const cellWidth = !cellValue ? 0 : getTextWidth(cellValue.toString(), fontWeight, `${fontSize}px`, fontFamily);
  return cellWidth <= 482 ? cellWidth + 16 + 2 : 500; //防止表格单列过长，设置最大值500
}

export function getDataColumnWidth(expression, columnConfig, format, data) {
  if (!data.length) {
    return 0;
  }
  const style = columnConfig && columnConfig.style;
  let values = data.map((record) => record[expression]);
  values = values.filter((value, idx) => values.indexOf(value) === idx);
  const maxCellWidth = values.reduce((w, value) => {
    const formattedValue = getFormattedValue(value, format);
    const cellWidth = computeCellWidth(style, formattedValue);
    return Math.max(w, cellWidth);
  }, 0);
  return maxCellWidth;
}

export function getFormattedValue(value, format, isAxis, noUnit, independentUnit) {
  if (!format || format.formatType === FieldFormatTypes.Default) {
    return value;
  }
  if (value === null || value === undefined) {
    return value;
  }

  const { formatType } = format;
  if (typeof value === 'string' && formatType !== FieldFormatTypes.Date && (!value || isNaN(+value))) {
    return value;
  }

  const config = format[formatType];
  let formattedValue;

  switch (formatType) {
    case FieldFormatTypes.Numeric:
    case FieldFormatTypes.Currency:
      const { decimalPlaces, unit, useThousandSeparator } = config;
      formattedValue = formatByUnit(value, unit);
      formattedValue = formatByDecimalPlaces(formattedValue, decimalPlaces);
      formattedValue = formatByThousandSeperator(formattedValue, useThousandSeparator);
      if (unit !== NumericUnit.None) {
        if (independentUnit) {
          formattedValue = [formattedValue, unit];
        } else {
          if (!noUnit) {
            formattedValue = `${formattedValue}${unit}`;
          }
          if (formatType === FieldFormatTypes.Currency) {
            const { prefix, suffix } = config;
            formattedValue = [prefix, formattedValue, suffix].join('');
          }
        }
      }
      break;
    case FieldFormatTypes.Percentage:
      formattedValue = +value * 100;
      formattedValue = isNaN(formattedValue) ? value : !isAxis ? `${formatByDecimalPlaces(formattedValue, config.decimalPlaces)}%` : `${formattedValue}%`;
      break;
    case FieldFormatTypes.ScientificNotation:
      formattedValue = (+value).toExponential(config.decimalPlaces);
      formattedValue = isNaN(formattedValue) ? value : formattedValue;
      break;
    case FieldFormatTypes.Date:
      const { format } = config;
      formattedValue = moment(value).format(format);
      break;
    case FieldFormatTypes.NPS:
      formattedValue = accMul(value, 100);
      break;
    case FieldFormatTypes.Custom:
      // @TODO
      break;
    default:
      formattedValue = value;
      break;
  }

  return formattedValue;
}

function formatByUnit(value, unit) {
  const numericValue = +value;
  if (isNaN(numericValue)) {
    return value;
  }

  let exponent = 0;

  if (['秒', '分', '小时'].includes(unit)) {
    switch (unit) {
      case NumericUnit.Second:
      case NumericUnit.Millisecond:
        exponent = 1;
        break;
      case NumericUnit.Minute:
        exponent = 60;
        break;
      case NumericUnit.Hour:
        exponent = 3600;
        break;
    }
    return numericValue / exponent;
  }
  switch (unit) {
    case NumericUnit.TenThousand:
      exponent = 4;
      break;
    case NumericUnit.OneHundredMillion:
      exponent = 8;
      break;
    case NumericUnit.Thousand:
      exponent = 3;
      break;
    case NumericUnit.Million:
      exponent = 6;
      break;
    case NumericUnit.Giga:
      exponent = 9;
      break;
  }
  return numericValue / Math.pow(10, exponent);
}

function formatByDecimalPlaces(value, decimalPlaces) {
  if (isNaN(value)) {
    return value;
  }
  if (decimalPlaces < 0 || decimalPlaces > 100) {
    return value;
  }

  return (+value).toFixed(decimalPlaces);
}

function formatByThousandSeperator(value, useThousandSeparator) {
  if (isNaN(+value) || !useThousandSeparator) {
    return value;
  }

  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formatted = parts.join('.');
  return formatted;
}

export function getTableCellValueRange(data, propName, columnConfig) {
  if (data.length <= 0) {
    return [0, 0];
  }

  let minVal = Infinity;
  let maxVal = -Infinity;

  if (columnConfig) {
    const { conditionStyles } = columnConfig;
    conditionStyles.forEach((style) => {
      if (!style.bar) {
        return;
      }
      const { mode, min, max } = style.bar;
      if (mode === 'auto') {
        return;
      }
      if (typeof min === 'number') {
        minVal = min;
      }
      if (typeof max === 'number') {
        maxVal = max;
      }
    });
  } else {
    return [0, 0];
  }

  const validMinVal = minVal !== Infinity;
  const validMaxVal = maxVal !== -Infinity;

  if (validMinVal && validMaxVal) {
    return [minVal, maxVal];
  }

  data.forEach((item) => {
    const cellVal = item[propName];
    if (typeof cellVal !== 'number' && (typeof cellVal !== 'string' || isNaN(+cellVal))) {
      return;
    }

    const cellNumVal = +cellVal;
    if (!validMinVal && cellNumVal < minVal) {
      minVal = cellNumVal;
    }
    if (!validMaxVal && cellNumVal > maxVal) {
      maxVal = cellNumVal;
    }
  });
  return [minVal, maxVal];
}

export function getCellSpanMap(data, dimensions) {
  const map = {};
  const columnsSpanEndIndexRecorder = {};

  dimensions.forEach(({ name, randomName }) => {
    name = decodeMetricName(name);
    map[randomName || name] = [];
    columnsSpanEndIndexRecorder[randomName || name] = -1;
  });

  data.forEach((record, index) => {
    const prevRecord = data[index - 1];

    dimensions.forEach(({ name, randomName }, dIndex) => {
      name = randomName || decodeMetricName(name);
      const prevColumnName = dimensions[dIndex - 1] && dimensions[dIndex - 1].name;

      if (columnsSpanEndIndexRecorder[name] !== index - 1 && prevRecord && prevRecord[name] === record[name]) {
        map[name][index] = 0;
        return;
      }

      if (columnsSpanEndIndexRecorder[prevColumnName] === index) {
        map[name][index] = 1;
        columnsSpanEndIndexRecorder[name] = index;
        return;
      }

      let span = 1;
      while (true) {
        const nextRecord = data[index + span];
        const currentDataIndex = index + span - 1;
        if (nextRecord && nextRecord[name] === record[name] && !(prevColumnName && currentDataIndex >= columnsSpanEndIndexRecorder[prevColumnName])) {
          span += 1;
        } else {
          map[name][index] = span;
          columnsSpanEndIndexRecorder[name] = currentDataIndex;
          break;
        }
      }
    });
  });
  return map;
}

export function textAlignAdapter(justifyContent) {
  switch (justifyContent) {
    case 'flex-start':
      return 'left';
    case 'center':
      return 'center';
    case 'flex-end':
      return 'right';
    default:
      return 'inherit';
  }
}

export function cloneElement(element, props) {
  if (props.style && element.props.style) {
    props.style = { ...element.props.style, ...props.style };
  }
  if (props.className && element.props.className) {
    props.className = `${element.props.className} ${props.className}`;
  }
  return React.cloneElement(element, props);
}

export function getSizeValue(value) {
  return value >= PIVOT_DEFAULT_SCATTER_SIZE_TIMES ? value - PIVOT_DEFAULT_SCATTER_SIZE_TIMES + 1 : 1 / Math.pow(2, PIVOT_DEFAULT_SCATTER_SIZE_TIMES - value);
}

export function getChartLabel(seriesData, labelItem) {
  return function(params) {
    const record = getTriggeringRecord(params, seriesData) || {};
    return labelItem.type === 'category'
      ? Array.isArray(record)
        ? record[0][labelItem.name]
        : record[labelItem.name] || ''
      : Array.isArray(record)
      ? record.reduce(
          (sum, r) => sum + r[labelItem.customType === 2 ? decodeMetricName(labelItem.name) : `${labelItem.agg}(${decodeMetricName(labelItem.name)})`],
          0
        )
      : record[labelItem.customType === 2 ? decodeMetricName(labelItem.name) : `${labelItem.agg}(${decodeMetricName(labelItem.name)})`] || 0;
  };
}

export function getTriggeringRecord(params, seriesData) {
  const { seriesIndex, dataIndex } = params;
  const { type, grouped, records } = seriesData[seriesIndex];
  let record;
  if (type === 'cartesian') {
    record = grouped ? records[dataIndex] : records[dataIndex].value;
  } else if (type === 'polar') {
    record = records[dataIndex];
  } else {
    record = records ? records[0] : {};
  }
  return record;
}
export function getTooltipPosition(point, params, dom, rect, size) {
  const [x, y] = point;
  const { contentSize, viewSize } = size;
  const [cx, cy] = contentSize;
  const [vx, vy] = viewSize;

  const distanceXToMouse = 10;
  return [
    x + cx + distanceXToMouse > vx ? x - distanceXToMouse - cx : x + distanceXToMouse,
    // Math.min(x, vx - cx),
    Math.min(y, vy - cy),
  ];
}

export function getPivotTooltipLabel(seriesData, cols, rows, metrics, color, label, size, scatterXAxis, tip, colsDisplay, rowsDisplay) {
  let dimetionColumns = cols.concat(rows);
  let displayColumns = colsDisplay ? Object.assign(colsDisplay, rowsDisplay) : {};
  let metricColumns = [...metrics];
  if (color) {
    dimetionColumns = dimetionColumns.concat(color.items.map((i) => i.name));
  }
  if (label) {
    dimetionColumns = dimetionColumns.concat(label.items.filter((i) => i.type === 'category').map((i) => i.name));
    metricColumns = metricColumns.concat(label.items.filter((i) => i.type === 'value'));
  }
  if (size) {
    metricColumns = metricColumns.concat(size.items);
  }
  if (scatterXAxis) {
    metricColumns = metricColumns.concat(scatterXAxis.items);
  }
  if (tip) {
    metricColumns = metricColumns.concat(tip.items);
  }

  dimetionColumns = dimetionColumns.reduce((arr, dc) => {
    if (!arr.includes(dc)) {
      arr.push(dc);
    }
    return arr;
  }, []);
  metricColumns = metricColumns.reduce((arr, mc) => {
    const decodedName = decodeMetricName(mc.name);
    if (!arr.find((m) => (mc.customType === 1 ? decodeMetricName(m.name) === decodedName && m.agg === mc.agg : m.fieldCustomizeId === mc.fieldCustomizeId))) {
      arr.push(mc);
    }
    return arr;
  }, []);

  return function(params) {
    const record = getTriggeringRecord(params, seriesData);
    return metricColumns
      .map((mc) => {
        const decodedName = decodeMetricName(mc.name);
        const value = record
          ? Array.isArray(record)
            ? record.reduce((sum, r) => sum + r[mc.randomName || `${mc.agg}(${decodedName})`], 0)
            : record[mc.randomName || `${mc.agg}(${decodedName})`]
          : 0;
        return `${getFieldAlias(mc.field, {}) || mc.fieldDisplay || decodedName}: ${getFormattedValue(value, mc.format)}`;
      })
      .concat(
        dimetionColumns.map((dc) => {
          const value = record ? (Array.isArray(record) ? record[0][dc] : record[dc]) : '';
          return `${displayColumns[dc] || dc}: ${value} `;
        })
      )
      .join('<br/>');
  };
}

const imageReg = /\.(bmp|png|jpg|jpeg|gif)$/;
export function isImagePath(pathname) {
  if (typeof pathname !== 'string') {
    return false;
  }
  return imageReg.test(pathname);
}

const protocolAndDomainReg = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomainReg = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const nonLocalhostDomainReg = /^[^\s\.]+\.\S{2,}$/;
export function isUrl(str) {
  if (typeof str !== 'string') {
    return false;
  }

  const match = str.match(protocolAndDomainReg);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainReg.test(everythingAfterProtocol) || nonLocalhostDomainReg.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

export function getChartTooltipLabel(type, seriesData, options, isUseColor, dataLength, compareConfig, originData) {
  const { cols, metrics, color, size, scatterXAxis, tip } = options;
  let dimentionColumns = cols;
  let metricColumns = [...metrics];
  if (color) {
    dimentionColumns = dimentionColumns.concat(color.items);
  }
  if (size) {
    metricColumns = metricColumns.concat(size.items);
  }
  if (scatterXAxis) {
    metricColumns = metricColumns.concat(scatterXAxis.items);
  }
  if (tip) {
    metricColumns = metricColumns.concat(tip.items);
  }

  dimentionColumns = dimentionColumns.filter((dc, idx) => dimentionColumns.findIndex((c) => c.name === dc.name) === idx);

  // metricColumns = metricColumns.reduce((arr, mc) => {
  //   const decodedName = decodeMetricName(mc.name);
  //   if (!arr.find((m) => decodeMetricName(m.name) === decodedName && m.agg === mc.agg)) {
  //     arr.push(mc);
  //   }
  //   return arr;
  // }, []);

  return function(params) {
    const { componentType } = params;
    if (componentType === 'markLine') {
      const { name, value } = params;
      return `参考线<br/>${name}: ${value}`;
    } else if (componentType === 'markArea') {
      const {
        name,
        data: { coord },
      } = params;
      const valueIndex = coord[0].findIndex((c) => c !== Infinity && c !== -Infinity);
      return `参考区间<br/>${name}: ${coord[0][valueIndex]} - ${coord[1][valueIndex]}`;
    } else if (isUseColor) {
      let tooltipLabels = [];
      let metricColumnsObj = {};
      metricColumns.forEach((item) => {
        const key = decodeMetricName(item.name);
        metricColumnsObj[key] = item;
      });
      params.forEach((item, index) => {
        const { seriesName, value, color, axisValueLabel, seriesId } = item;
        index === 0 && tooltipLabels.push(axisValueLabel);
        if (value || value == 0) {
          const circle = color ? `<span class="widget-tooltip-circle" style="background: ${color}"></span>` : '';
          const formatValue = getFormattedValue(value, metricColumnsObj[decodeMetricName(seriesId)].format);
          tooltipLabels.push(`${circle}${seriesName}：${formatValue}`);
        }
      });
      return tooltipLabels.join('<br/>');
    } else if (!Array.isArray(params)) {
      const { seriesIndex, dataIndex, color } = params;
      const record = type === 'funnel' || type === 'map' ? seriesData[dataIndex] : seriesData[seriesIndex][dataIndex];
      let tooltipLabels = [];
      tooltipLabels = tooltipLabels.concat(
        dimentionColumns.map((dc) => {
          let value = record ? (Array.isArray(record) ? record[0][dc.randomName || dc.name] : record[dc.randomName || dc.name]) : '';
          value = getFormattedValue(value, dc.format);
          return type !== 'quadrant'
            ? `${getFieldAlias(dc.field, {}) || dc.fieldDisplay || dc.name}: ${value}` // @FIXME dynamic field alias by queryVariable in dashboard
            : value;
        })
      );

      type !== 'quadrant' &&
        (tooltipLabels = tooltipLabels.concat(
          metricColumns.map((mc) => {
            const decodedName = decodeMetricName(mc.name);
            let value = record
              ? Array.isArray(record)
                ? record.reduce((sum, r) => sum + r[mc.randomName || `${mc.agg}(${decodedName})`], 0)
                : record[mc.randomName || `${mc.agg}(${decodedName})`]
              : 0;
            value = getFormattedValue(value, mc.format);
            return `${getFieldAlias(mc.field, {}) || mc.fieldDisplay || decodedName}: ${value}`;
          })
        ));

      if (color) {
        const circle = `<span class="widget-tooltip-circle" style="background: ${color}"></span>`;
        if (!dimentionColumns.length) {
          tooltipLabels.unshift(circle);
        } else {
          tooltipLabels[0] = circle + tooltipLabels[0];
        }
      }
      // 显示对比时间
      if (compareConfig) {
        const { current, compare } = getCompareText(compareConfig);
        if (seriesIndex < dataLength) {
          tooltipLabels.unshift(current);
        } else {
          tooltipLabels.unshift(compare);
        }
      }

      return tooltipLabels.join('<br/>');
    } else {
      let tooltipLabels = [];
      params.forEach((item, index) => {
        const { seriesIndex, dataIndex, color } = item;
        const record = type === 'funnel' || type === 'map' ? seriesData[dataIndex] : seriesData[seriesIndex] && seriesData[seriesIndex][dataIndex];
        if (record) {
          index === 0 &&
            (tooltipLabels = tooltipLabels.concat(
              dimentionColumns.map((dc) => {
                let name = dc.randomName || dc.name;
                let value = record ? (Array.isArray(record) ? record[0][name] : record[name]) : '';
                if (originData[name]) {
                  value = originData[name][value];
                } else {
                  value = getFormattedValue(value, dc.format);
                }
                return value;
                // return type !== 'quadrant'
                // ? `${getFieldAlias(dc.field, {}) || dc.fieldDisplay || dc.name}: ${value}` // @FIXME dynamic field alias by queryVariable in dashboard
                // : value
              })
            ));
          let compareSeriesIndex = seriesIndex;
          if (compareConfig) {
            compareSeriesIndex = parseInt(seriesIndex / 2);
          }
          const mc = metricColumns[compareSeriesIndex];
          const decodedName = decodeMetricName(mc.name);
          let value = record
            ? Array.isArray(record)
              ? record.reduce((sum, r) => sum + r[mc.customType === 2 ? decodedName : `${mc.agg}(${decodedName})`], 0)
              : record[mc.randomName || `${mc.agg}(${decodedName})`]
            : 0;
          value = getFormattedValue(value, mc.format);
          const circle = color
            ? `<span class="widget-tooltip-circle" style="background: ${color};${compareConfig && seriesIndex % 2 !== 0 ? 'opacity:0.5' : ''}"></span>`
            : '';
          let displayName = getFieldAlias(mc.field, {}) || mc.fieldDisplay || decodedName;
          // 显示对比时间
          if (compareConfig) {
            const { current, compare } = getCompareText(compareConfig);
            if (seriesIndex % 2 === 0) {
              displayName = `${current} - ${displayName}`;
            } else {
              displayName = `${compare} - ${displayName}`;
            }
          }
          tooltipLabels.push(`${circle}${displayName}: ${value}`);
          if (tip && index === params.length - 1) {
            tip.items.forEach((t) => {
              const decodedName = decodeMetricName(t.name);
              let value = record
                ? Array.isArray(record)
                  ? record.reduce((sum, r) => sum + r[t.randomName ? t.randomName : `${t.agg}(${decodedName})`], 0)
                  : record[t.randomName ? t.randomName : `${t.agg}(${decodedName})`]
                : 0;
              value = getFormattedValue(value, t.format);
              let displayName = getFieldAlias(t.field, {}) || t.fieldDisplay || decodedName;
              // 显示对比时间
              if (compareConfig) {
                const { current, compare } = getCompareText(compareConfig);
                if (seriesIndex < dataLength) {
                  displayName = `${current} - ${displayName}`;
                } else {
                  displayName = `${compare} - ${displayName}`;
                }
              }
              tooltipLabels.push(`${displayName}: ${value}`);
            });
          }
        }
      });

      return tooltipLabels.join('<br/>');
    }
  };
}

const prefixStack = 'stack_';
export function getStackName(metricName, stackConfig) {
  if (!stackConfig || !stackConfig.group) {
    return prefixStack + metricName;
  }

  let stackName = prefixStack;
  stackConfig.group.some((config, idx) => {
    if (config.includes(metricName)) {
      stackName += idx;
      return true;
    }
  });

  return stackName;
}

export const StackItemType = 'MetricCustomStack';
export const EmptyStack = {
  on: false,
  percentage: false,
  group: [],
  sum: {
    show: false,
    font: { ...EmptyFontSetting },
  },
};

export function getCompareText(compareFilterConfig) {
  if (!compareFilterConfig) return null;
  const { compare, current, filed } = compareFilterConfig;
  if (SQL_DATE_TYPES.includes(filed.sqlType)) {
    const currentValue = (current.lastDate ? mathUtil.keyToDate(current.lastDate) : current.date).replace(',', '~');
    const compareValue = (compare.lastDate ? mathUtil.keyToDate(compare.lastDate) : compare.date).replace(',', '~');
    return { current: currentValue, compare: compareValue };
  } else {
    return { current, compare };
  }
}

export function resetWidgetInfo(widgetInfo) {
  let config = widgetInfo.config;
  let { cols, rows, metrics, chartStyles, size, color, selectedChart } = config;
  //生成随机name便于后端sql区分
  let names = [];
  // cols.forEach((item) => {
  //   // cutomType 1: 非自定义字段 2: 自定义字段
  //   // 新增item.customType === 1 && !item.agg ， 判断非自定义字段，原始数据的查询
  //   // bug起因时间类型字段，在bi将 agg = init的时候没有去掉randomName，花了一天定位到了原因
  //   // 具体怎么删懒得继续找下去了，在分析系统这里特殊处理，也兼容了旧数据。
  //   if (item.customType === 2 || item.agg || (item.customType === 1 && !item.agg && [19, 21, 1].includes(selectedChart))) {
  //     // const randomName = getRandomName(names);
  //     // names.push(randomName);
  //     item.originalrandomName = item.originalrandomName || item.randomName;
  //     // item.randomName = randomName;
  //   }
  // });
  // rows.forEach((item) => {
  //   if (item.customType === 2 || item.agg || (item.customType === 1 && !item.agg && [19, 21, 1].includes(selectedChart))) {
  //     // const randomName = getRandomName(names);
  //     // names.push(randomName);
  //     item.originalrandomName = item.originalrandomName || item.randomName;
  //     // item.randomName = randomName;
  //   }
  // });
  // metrics.forEach((item) => {
  //   if (item.customType === 2) {
  //     // const randomName = getRandomName(names);
  //     // names.push(randomName);
  //     item.originalrandomName = item.originalrandomName || item.randomName;
  //     // item.randomName = randomName;
  //   }
  // });
  // color &&
  //   color.items.forEach((item) => {
  //     if (item.customType === 2) {
  //       // const randomName = getRandomName(names);
  //       // names.push(randomName);
  //       item.originalrandomName = item.originalrandomName || item.randomName;
  //       // item.randomName = randomName;
  //     }
  //   });
  let itemObj = {};
  cols.concat(rows, metrics).forEach((item) => {
    itemObj[item.randomName || item.workViewParamId || item.name] = item;
  });
  size &&
    size.items.forEach((item) => {
      itemObj[item.workViewParamId] = item;
    });
  //兼容randomName
  if (chartStyles && chartStyles.table) {
    let headerConfigCopy = [];
    let columnsConfigCopy = [];
    let { headerConfig, columnsConfig } = chartStyles.table;
    headerConfig.forEach((item) => {
      item.realName
        ? headerConfigCopy.push({
            ...item,
            headerName: itemObj[item.headerName] ? itemObj[item.headerName].randomName : item.headerName,
          })
        : headerConfigCopy.push({
            ...item,
          });
    });
    columnsConfig.forEach((item) => {
      item.realName
        ? columnsConfigCopy.push({
            ...item,
            columnName: itemObj[item.columnName] ? itemObj[item.columnName].randomName : item.headerName,
          })
        : columnsConfigCopy.push({
            ...item,
          });
    });
    chartStyles.table = {
      ...chartStyles.table,
      headerConfig: headerConfigCopy,
      columnsConfig: columnsConfigCopy,
    };
  }
  size &&
    size.items.forEach((item) => {
      item.randomName && (item.randomName = itemObj[item.workViewParamId].randomName);
    });
  config = {
    ...config,
    cols,
    rows,
    metrics,
    color,
    chartStyles,
  };
  return {
    ...widgetInfo,
    config,
  };
}
function accMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  ` `;
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
}

export function encodeUrlSearch(url) {
  const [path, search] = url.split('?');
  const searchGroup = search ? search.split('&') : [];
  const encodeSearchGroup = searchGroup.map((item) => {
    let codeIndex = item.indexOf('=');
    let key = item.substring(0, codeIndex);
    let value = item.substring(codeIndex + 1, item.length);
    return `${key}=${encodeURIComponent(value)}`;
  });
  return `${path}?${encodeSearchGroup.join('&')}`;
}

export function getMappingLinkage(itemId, linkages) {
  const mappingLinkage = {};
  if (linkages.length <= 0) {
    return mappingLinkage;
  }

  linkages.forEach((l) => {
    const { trigger, linkager, relation } = l;

    const triggerItemId = +trigger[0];
    const linkagerItemId = linkager[0];

    if (itemId === triggerItemId) {
      const [triggerKey, triggerSqlType, triggerType] = trigger[1].split(DEFAULT_SPLITER);
      const [linkagerKey, linkagerSqlType, linkagerType] = linkager[1].split(DEFAULT_SPLITER);

      if (!mappingLinkage[linkagerItemId]) {
        mappingLinkage[linkagerItemId] = [];
      }

      mappingLinkage[linkagerItemId].push({
        triggerKey,
        triggerSqlType,
        triggerType: triggerType,
        linkagerKey,
        linkagerSqlType,
        linkagerType: linkagerType,
        relation,
      });
    }
  });

  return mappingLinkage;
}

export function processLinkage(itemId, triggerData, mappingLinkage, interactingLinkage, isTable) {
  const stringTypes = ['STRING'];
  Object.keys(mappingLinkage).forEach((linkagerItemId) => {
    const linkage = mappingLinkage[+linkagerItemId];

    const linkageFilters = [];
    const linkageVariables = [];
    linkage.forEach((l) => {
      const { triggerKey, triggerSqlType, triggerType, linkagerKey, linkagerSqlType, linkagerType, relation } = l;
      // const actuallyData = Array.isArray(triggerData) ? triggerData[0][triggerKey] : triggerData[triggerKey];
      const isStringType = stringTypes.includes(linkagerSqlType);
      const actuallyData = isTable ? getTableActuallyData(triggerData, triggerKey, isStringType) : getActuallyData(triggerData, triggerKey, isStringType);
      const interactValue = SQL_NUMBER_TYPES.includes(triggerSqlType) || Array.isArray(actuallyData) ? actuallyData : `'${actuallyData}'`;
      if (typeof actuallyData !== 'undefined') {
        if (linkagerType === 'column') {
          const validLinkagerKey = SQL_NUMBER_TYPES.includes(linkagerSqlType) ? linkagerKey.replace(/\w+\((\w+)\)/, '$1') : linkagerKey;
          const filterJson = {
            name: validLinkagerKey,
            type: 'filter',
            value: !isStringType || Array.isArray(interactValue) ? interactValue : [interactValue],
            sqlType: linkagerSqlType,
            operator: (isStringType && relation === '=') || Array.isArray(actuallyData) ? 'in' : relation,
          };
          linkageFilters.push(filterJson);
          // linkageFilters.push(`${validLinkagerKey} ${relation} ${interactValue}`)   // 联动filter生成在此处
        } else if (linkagerType === 'variable') {
          linkageVariables.push({ name: linkagerKey, value: interactValue });
        }
      }
    });

    const existedQueryConditions = interactingLinkage[linkagerItemId];

    if (existedQueryConditions) {
      const { filters, variables } = existedQueryConditions;
      existedQueryConditions.filters = linkageFilters.length > 0 ? { ...filters, [itemId]: linkageFilters } : filters;
      existedQueryConditions.variables = linkageVariables.length > 0 ? { ...variables, [itemId]: linkageVariables } : variables;
    } else {
      interactingLinkage[linkagerItemId] = {
        filters: linkageFilters.length > 0 ? { [itemId]: linkageFilters } : {},
        variables: linkageVariables.length > 0 ? { [itemId]: linkageVariables } : {},
      };
    }
  });
  return interactingLinkage;
}

function getActuallyData(triggerData, triggerKey, isStringType) {
  let actuallyData;
  if (!Array.isArray(triggerData)) {
    actuallyData = triggerData[triggerKey];
  } else if (triggerData.length < 2) {
    actuallyData = triggerData[0][triggerKey];
  } else if (typeof triggerData[0][triggerKey] !== 'undefined') {
    if (!isStringType) {
      actuallyData = triggerData[triggerData.length - 1][triggerKey];
    } else {
      actuallyData = [];
      triggerData.forEach((item) => {
        actuallyData.push(`'${item[triggerKey]}'`);
      });
    }
  }
  return actuallyData;
}

function getTableActuallyData(triggerData, triggerKey, isStringType) {
  let actuallyData;
  const data = triggerData[triggerKey];
  if (data) {
    if (data.length < 2) {
      actuallyData = data[0].value;
    } else {
      actuallyData = [];
      data.forEach((item) => {
        actuallyData.push(`'${item.value}'`);
      });
    }
  }

  return actuallyData;
}

export const getListsByViewModelTypes = (model, type) => (modelType) => {
  return cacheManager(model, type)((cache) => {
    const target = cache[type];
    return Object.keys(target).reduce((iteratee, current) => {
      return iteratee.concat(target[current] === modelType ? current : []);
    }, []);
  });
};

function cacheManager(model, type) {
  const cache = {};
  return function(callback) {
    if (cache['prevModel'] === model) {
      cache[type] = cache[type] || getTypesOfModelCollect(model, type);
    } else {
      cache['prevModel'] = model;
      cache[type] = getTypesOfModelCollect(model, type);
    }
    return callback(cache);
  };
}

export function getTypesOfModelCollect(model, type) {
  let target = {};
  if (model) {
    return (target = Object.keys(model).reduce((iteratee, current) => {
      iteratee[current] = hasProperty(model[current], type);
      return iteratee;
    }, {}));
  }
  return target;
}

export function getRandomName(names) {
  const randomName = `key_${Math.floor(Math.random() * 99999)}`;
  if (!names || names.indexOf(randomName) < 0) {
    return randomName;
  } else {
    return getRandomName(names);
  }
}

export function randomNameToFieldName(fieldList, randomName) {
  const field = fieldList.find((item) => item.randomName === randomName);
  return field ? field.name : randomName;
}
//合并列标题
export const getDimensionsCombine = (dimensionsList) => {
  if (!dimensionsList.length) {
    return false;
  }
  const column = dimensionsList[0];
  column.title = dimensionsList.reduce((title, item) => title + '/' + item.title, '').substr(1);
  if (column.styleWidth) {
    column.width = column.styleWidth;
  } else {
    column.width = dimensionsList.reduce((total, item) => total + item.width, 0);
  }
  return column;
};

export function getDragItemIconClass(type) {
  switch (type) {
    case ViewModelVisualTypes.Number:
      return 'icon-shuzhi-copy';
    case ViewModelVisualTypes.Date:
      return `icon-riqi-copy`;
    case ViewModelVisualTypes.GeoCountry:
    case ViewModelVisualTypes.GeoProvince:
    case ViewModelVisualTypes.GeoCity:
      return 'icon-map';
    default:
      return 'icon-zifuchuan-copy';
  }
}

export function hasMatchedTheCondition(cellVal, operatorType, conditionValues) {
  let matchTheCondition = false;
  switch (operatorType) {
    case OperatorTypes.Between:
      const [minVal, maxVal] = conditionValues;
      matchTheCondition = cellVal >= minVal && cellVal <= maxVal;
      break;
    case OperatorTypes.Contain:
      matchTheCondition = cellVal.toString().indexOf(conditionValues[0].toString()) >= 0;
      break;
    case OperatorTypes.Equal:
      matchTheCondition = cellVal === conditionValues[0];
      break;
    case OperatorTypes.GreaterThan:
      matchTheCondition = cellVal > conditionValues[0];
      break;
    case OperatorTypes.GreaterThanOrEqual:
      matchTheCondition = cellVal >= conditionValues[0];
      break;
    case OperatorTypes.In:
      matchTheCondition = conditionValues.findIndex((cVal) => cVal == cellVal) >= 0;
      break;
    case OperatorTypes.LessThan:
      matchTheCondition = cellVal < conditionValues[0];
      break;
    case OperatorTypes.LessThanOrEqual:
      matchTheCondition = cellVal <= conditionValues[0];
      break;
    case OperatorTypes.NotEqual:
      matchTheCondition = cellVal !== conditionValues[0];
      break;
  }
  return matchTheCondition;
}

// 指标分析
export function handleAnalysis(workViewParamId, index, callback) {
  util.get(
    api.board.getDashboard,
    {
      idsite: PageHeaderStore.parameter.idsite,
      type: 3,
      paramId: workViewParamId,
    },
    (res) => {
      callback(index, res);
    }
  );
}
