import { action, observable } from 'mobx';
import { message } from 'antd';
import api from '../../api';
import util from '../../util';

class Store {
  constructor() {
    const code = util.getQueryString('code');
    const auth_code = util.getQueryString('auth_code');
    const type = util.getQueryString('type');
    const redirect_url = util.getQueryString('redirect_url');
    const c2 = util.getQueryString('c2');
    const c3 = util.getQueryString('c3');
    // code && c2 && c3 为飞书登录跳转链接
    if ((code && type) || (auth_code && type) || (redirect_url && type) || (code && c2 && c3)) {
      this.doLogin(code, type, auth_code, redirect_url, c2, c3);
    }
  }

  @action.bound setCookie(c_name, value, expireseconds) {
    var exdate = new Date();
    exdate.setTime(exdate.getTime() + expireseconds * 1000);
    document.cookie = c_name + '=' + value + ';path=/' + (expireseconds === null ? '' : ';expires=' + exdate.toGMTString());
  }

  @action.bound getCookie(c_name) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + '=');
      if (c_start !== -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(';', c_start);
        if (c_end === -1) {
          c_end = document.cookie.length;
        }
        return document.cookie.substring(c_start, c_end);
      }
    }
    return null;
  }

  doLogin(code, type, auth_code, redirect_url, c2, c3) {
    const param = {
      code,
      type,
      auth_code,
      redirect_url,
      c2,
      c3,
    };
    c2 && c3 && (param.type = 10);
    util.post(api.login.wx_login, param, (res) => {
      if (res.code == '200' && res.data.token) {
        const newUrl = util.delQueryString(util.delQueryString(window.location.href, 'type'), 'code');
        window.location.href = newUrl;
      } else {
        res.msg && message.error(res.msg);
        util.toCustomerSSO();
      }
    });
  }
  @observable permission = true;
  @action.bound setPermission(value) {
    this.permission = value;
  }
}

export default new Store();
