import React, { FC, forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Select as AntSelect } from 'antd';
import { CONTROL_MAX_TAG_COUNT, CONTROL_MAX_TAG_TEXT_LENGTH } from '../constants';
import { filterSelectOption, uuid } from '../app/util';
import { toJS } from 'mobx';
import './Panel/Layouts/Layouts.less';
import intl from 'react-intl-universal';
import _ from 'lodash';
import { getTextWidth } from '../../../../containers/BIChart/util';

const Option = AntSelect.Option;

const Select = ({ control, value, onSelectSearch, size, onChange, options, onSearch, onClear, isPageHeader }, ref) => {
  const [loading, setLoading] = useState(false);
  const content = useRef();
  const { multiple } = control;
  const debounceOnSearch = _.debounce((value) => {
    onSearch(value);
  }, 200);
  const randomName = uuid(8, 16);
  useEffect(() => {
    const titleWidth = getTextWidth(control.name);
    if (content.current && isPageHeader) {
      content.current.getElementsByClassName('ant-select-selection')[0].style.paddingLeft = `${multiple ? titleWidth + 16 : titleWidth + 8}px`;
    }
  }, [content, control]);
  useEffect(() => {
    if (options) {
      setLoading(false);
    }
  }, [options]);
  return (
    <div className="controlSelect" id={randomName} ref={content}>
      <AntSelect
        onFocus={() => {
          if (options) {
            return;
          }
          onSelectSearch(control);
          setLoading(true);
        }}
        showSearch
        allowClear
        placeholder="请选择"
        value={value}
        onChange={onChange}
        getPopupContainer={() => document.getElementById(randomName)}
        dropdownMatchSelectWidth={false}
        maxTagCount={CONTROL_MAX_TAG_COUNT}
        maxTagTextLength={CONTROL_MAX_TAG_TEXT_LENGTH}
        // filterOption={filterSelectOption}
        onSearch={debounceOnSearch}
        {...(size && { size })}
        {...(multiple && { mode: 'multiple' })}
        ref={ref}
        loading={loading}>
        {options &&
          options.map((o) => (
            <Option key={o.value} value={o.value}>
              {o.text}
            </Option>
          ))}
      </AntSelect>
    </div>
  );
};

export default forwardRef(Select);
