import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import api from '../../../api';
import util from '../../../util';

function NotificationItem(props) {
  const icons = {
    1: 'icon-xitongguanli',
    2: 'icon-shenhe',
    3: 'icon-xiazai3',
    4: 'icon-yujing1',
  };
  let { name, content, updatedAt, noticeType, eventInfo } = props.data;
  try {
    eventInfo = eventInfo ? JSON.parse(eventInfo) : {};
  } catch (error) {
    eventInfo = { id: 0, status: 3, system: 'analytics' };
    console.error(error);
  }
  const { id, status, system, link } = eventInfo;

  //   下载文件
  function download() {
    util.download(
      api.download.download,
      {
        id: id,
        system: system,
      },
      `${content}`,
      () => {
        props.getNotificationList(true, true);
      }
    );
  }

  return (
    <li className="notification-item">
      <i className={`notification-icon iconfont ${icons[noticeType] || 'icon-xitongguanli'}`}></i>
      <div className="notification-detail">
        <div className="notification-name">{name}</div>
        {noticeType === 3 ? (
          <div className="notification-content download-item">
            <div
              className={`file-name ${['4', '2'].indexOf(status) > -1 ? 'active' : ''}`}
              title={content}
              onClick={() => {
                ['4', '2'].indexOf(status) > -1 && download();
              }}>
              {content}
            </div>
            <div className="operate">
              {status === '1' ? (
                <Tag color="blue">下载中</Tag>
              ) : status === '2' ? (
                <Tag color="cyan">成功</Tag>
              ) : status === '3' ? (
                <Tag color="#FF6B5C">失败</Tag>
              ) : status === '5' ? (
                <Tag color="#FF6B5C">下载超时</Tag>
              ) : (
                <Tag color="#D9D9DE">已下载</Tag>
              )}
            </div>
          </div>
        ) : link ? (
          <Link to={link}>{content}</Link>
        ) : (
          <div className="notification-content">{content}</div>
        )}
        <div className="notification-time">{updatedAt}</div>
      </div>
    </li>
  );
}

export default NotificationItem;
