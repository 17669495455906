import React, { Component } from 'react';

import { action, autorun, observable, computed, toJS } from 'mobx';
import { Cascader } from 'antd';
import { observer } from 'mobx-react';
import api from '../../api';
import util from '../../util';
import Loading from '../../component/Loading/Loading';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';

@observer
class DimSelect extends Component {
  constructor() {
    super();
    this.lastEventNameValue = null;
  }
  @observable isLoading = false;
  @observable dimvalue;
  @observable subDimOptions = [];
  @observable optionsObj = {};
  @observable optionsLabelObj = {};
  @computed get eventName() {
    return this.props.eventAndTargetGroup && this.props.eventAndTargetGroup[0] instanceof Array
      ? this.props.eventAndTargetGroup
          .map((eventItem, index) => {
            let eventGroup = [];
            eventItem.forEach((item) => {
              eventGroup.push(item.ruleContent[0].dimValue);
            });
            return eventGroup.join('or');
          })
          .join(',')
      : JSON.stringify(
          toJS(this.props.eventAndTargetGroup).map((item) => {
            return {
              type: item.dimValue === '任意事件' ? 'default' : item.type,
              value: item.dimValue,
            };
          })
        );
  }
  componentWillMount() {
    // 细分维度的请求
    // this.getSubDividedDim(this.eventName);
    this.props.useType === 'authority' && this.getAllSubDividedDim();
    this.props.useType !== 'authority' &&
      (this.disposer = autorun(() => {
        // 每次事件变化再去重新请求维度列表
        const eventNameValue = JSON.parse(this.eventName)[0].value;
        if (this.lastEventNameValue !== JSON.parse(this.eventName)[0].value) {
          this.getSubDividedDim(this.eventName);
          this.lastEventNameValue = eventNameValue;
        }
      }));
    this.props.id && this.disposer();
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  // 格式化细分维度的data为可用的格式（map转化list.map）
  @action.bound formatDimData(res) {
    this.resetSubDimOptions([]);
    let hasDim = false;
    let subDimOptions = [];
    let optionsObj = {};
    let optionsLabelObj = {};
    Object.keys(res).forEach((key, index) => {
      subDimOptions.push({
        value: key,
        label: key.includes('_view_') ? key.split('_view_')[0] : key.includes('_customize') ? key.split('_customize')[0] : key,
        children: [],
      });
      Object.keys(res[key]).forEach((resKey) => {
        subDimOptions[index]['children'].push({ value: resKey, label: res[key][resKey] });
        optionsObj[resKey] = subDimOptions[index].value;
        optionsLabelObj[resKey] = res[key][resKey];
        resKey === this.props.dimItem && (hasDim = true);
      });
    });
    this.props.useType === 'analyseSurvey' && subDimOptions.push({ value: '', label: '所有' });
    this.resetSubDimOptions(subDimOptions);
    this.optionsObj = optionsObj;
    this.optionsLabelObj = optionsLabelObj;
    if (!hasDim && this.props.cleanFilter) {
      this.props.cleanFilter(this.props.dimIndex);
    }
  }
  @action.bound getSubDividedDim(eventName) {
    if (
      (eventName && eventName.includes('[') && eventName.includes(']') && !eventName.includes('{}')) ||
      !(eventName.includes('[') && eventName.includes(']'))
    ) {
      this.isLoading = true;

      let param = {
        dim: this.props.useType === 'userGroup' ? 'analysis_attribute' : 'analysis_dim',
        date: pageHeaderStore.parameter.date,
        lastDate: pageHeaderStore.parameter.lastDate,
        search: eventName,
        idsite: pageHeaderStore.parameter.idsite,
      };
      if (typeof this.props.analysisType != 'undefined') {
        param['analysisType'] = this.props.analysisType;
      } else {
        param['analysisType'] = 1;
      }
      util.post(
        api.pack.dimOptions,
        param,
        (res) => {
          this.formatDimData(res.data);

          this.isLoading = false;
        },
        null,
        false
      );
    }
  }
  @action.bound getAllSubDividedDim() {
    this.isLoading = true;
    let param = {
      dim: 'analysis_all_attribute',
      date: pageHeaderStore.parameter.date,
      lastDate: pageHeaderStore.parameter.lastDate,
      idsite: pageHeaderStore.parameter.idsite,
    };
    util.post(
      api.pack.dimOptions,
      param,
      (res) => {
        this.formatDimData(res.data);

        this.isLoading = false;
      },
      null,
      false
    );
  }
  @action.bound resetSubDimOptions(arr) {
    this.subDimOptions = [...arr];
  }

  @action.bound getVal(value, index, label) {
    if (!value) {
      return this.props.useType === 'analyseSurvey' ? [''] : undefined;
    }
    const getType = (value) => {
      return this.optionsObj[value];
    };

    if (this.judgeSubDimOptions(toJS(this.subDimOptions), value)) {
      if (this.props.useType === 'authority' || label) {
        return [label, value];
      } else {
        return [getType(value), value];
      }
    } else {
      // this.props.setSubDividedDim([undefined, undefined], index);
      return undefined;
    }
  }
  judgeSubDimOptions(options, dims) {
    let result = false;

    options.forEach((item) => {
      item.children &&
        item.children.forEach((item) => {
          if (item.value === dims) {
            result = true;
          }
        });
    });
    return result;
  }
  render() {
    return (
      <React.Fragment>
        {!this.isLoading ? (
          <div title={this.optionsLabelObj[this.props.dimItem]}>
            <Cascader
              disabled={this.props.disabled}
              displayRender={(label) => {
                if (label.length === 2) {
                  return `${label[1]}`;
                } else if (this.props.useType === 'analyseSurvey') {
                  return label[0];
                }
              }}
              style={{ width: this.props.useType === 'userGroup' ? 96 : 120 }}
              onChange={(value) => {
                this.props.setSubDividedDim(value, this.props.dimIndex);
              }}
              disabled={this.props.disabled}
              // this.props.useType === 'authority'
              // ? this.getAuthorityVal(this.props.dimItem, this.props.dimIndex)
              value={this.getVal(this.props.dimItem, this.props.dimIndex, this.props.dimItemValueName)}
              options={[...this.subDimOptions]}
              placeholder={`${this.props.useType === 'userGroup' ? '请选择' : '选择细分维度'}`}
              allowClear={this.props.allowClear ? this.props.allowClear : false}
            />
          </div>
        ) : (
          <Loading size="small"></Loading>
        )}
      </React.Fragment>
    );
  }
}
export default DimSelect;
