import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Loading from '../../../component/Loading/Loading';
import util from '../../../util';
import api from '../../../api';
import { dashboardTemplateTypes } from '../../../config';

function DashboardDetail(props) {
  const { editInfo, type } = props;
  const { editId } = editInfo;

  const [data, setData] = useState();

  useEffect(() => {
    const param = {
      id: editId,
    };
    type === 'detail'
      ? util.get(api.audit.info, param, (res) => {
          if (res && res.auditSourceInfo) {
            const { auditSourceInfo } = res;
            auditSourceInfo.typeName = dashboardTemplateTypes[auditSourceInfo.type];
            setData(auditSourceInfo);
          }
        })
      : util.get(api.globalControl.list, param, (res) => {
          setData(res);
        });
  }, []);

  return data ? (
    <Row className="audit-detail">
      <Col span={24}>
        <Col span={8} className="title">
          模板名称：
        </Col>
        <Col span={16}>{data.name}</Col>
      </Col>
      <Col span={24}>
        <Col span={8} className="title">
          模板类型：
        </Col>
        <Col span={16}>{data.typeName}</Col>
      </Col>
    </Row>
  ) : (
    <Loading />
  );
}

export default DashboardDetail;
