import { OperatorTypes } from './app/operatorTypes';

export const ControlTypes = {
  Select: 'select',
  Radio: 'radio',
  Date: 'date',
  DateRange: 'dateRange',
  QuickDateRange: 'quickDateRange',
  InputText: 'inputText',
  NumberRange: 'numberRange',
  Slider: 'slider',
  // 暂不实现
  // TreeSelect: 'treeSelect',
  OldInputText: 'search',
  OldDateRange: 'datetime',
};

export const ControlTypesLocale = {
  [ControlTypes.Select]: '下拉菜单',
  [ControlTypes.Radio]: '单选按钮',
  [ControlTypes.Date]: '日期选择',
  [ControlTypes.DateRange]: '日期范围选择',
  [ControlTypes.OldDateRange]: '日期范围选择',
  [ControlTypes.InputText]: '文本输入框',
  [ControlTypes.OldInputText]: '文本输入框',
  [ControlTypes.NumberRange]: '数字范围输入框',
  [ControlTypes.Slider]: '数字滑块',
  [ControlTypes.TreeSelect]: '下拉树',
  [ControlTypes.QuickDateRange]: '日期周期范围选择',
};

export const ControlFieldTypes = {
  Column: 'column',
  Variable: 'variable',
};

export const ControlOptionTypes = {
  Auto: 'auto',
  Manual: 'manual',
  Custom: 'custom',
};

export const ControlDefaultValueTypes = {
  Dynamic: 'dynamic',
  Fixed: 'fixed',
};

export const ControlVisibilityTypes = {
  Visible: 'visible',
  Hidden: 'hidden',
  Conditional: 'conditional',
};

export const DatePickerFormats = {
  Date: 'YYYY-MM-DD',
  Datetime: 'YYYY-MM-DD HH:mm:ss',
  DatetimeMinute: 'YYYY-MM-DD HH:mm',
  Month: 'YYYY-MM',
  Week: 'YYYY-ww',
  Year: 'YYYY',
  Quarter: 'YYYY-MM-DD ',
};

export const DatePickerFormatsLocale = {
  [DatePickerFormats.Date]: '日期',
  [DatePickerFormats.Datetime]: '日期时间',
  [DatePickerFormats.DatetimeMinute]: '日期时间分钟',
  [DatePickerFormats.Week]: '周',
  [DatePickerFormats.Month]: '月',
  [DatePickerFormats.Quarter]: '季',
  [DatePickerFormats.Year]: '年',
};

export const DatePickerFormatsSelectSetting = {
  normal: [
    DatePickerFormats.Date,
    DatePickerFormats.Datetime,
    DatePickerFormats.DatetimeMinute,
    DatePickerFormats.Week,
    DatePickerFormats.Quarter,
    DatePickerFormats.Month,
    DatePickerFormats.Year,
  ],
  multiple: [DatePickerFormats.Date, DatePickerFormats.Month, DatePickerFormats.Year],
};

export const SHOULD_LOAD_OPTIONS = {
  [ControlTypes.Select]: true,
  [ControlTypes.Radio]: true,
  [ControlTypes.Date]: false,
  [ControlTypes.DateRange]: false,
  [ControlTypes.OldDateRange]: false,
  [ControlTypes.InputText]: false,
  [ControlTypes.OldInputText]: false,
  [ControlTypes.NumberRange]: false,
  [ControlTypes.Slider]: false,
  [ControlTypes.TreeSelect]: true,
  [ControlTypes.QuickDateRange]: false,
};

export const IS_RANGE_TYPE = {
  [ControlTypes.Select]: false,
  [ControlTypes.Radio]: false,
  [ControlTypes.Date]: false,
  [ControlTypes.DateRange]: true,
  [ControlTypes.OldDateRange]: true,
  [ControlTypes.InputText]: false,
  [ControlTypes.OldInputText]: false,
  [ControlTypes.NumberRange]: true,
  [ControlTypes.Slider]: true,
  [ControlTypes.TreeSelect]: false,
  [ControlTypes.QuickDateRange]: true,
};

export const IS_DATE_TYPE = {
  [ControlTypes.Select]: false,
  [ControlTypes.Radio]: false,
  [ControlTypes.Date]: true,
  [ControlTypes.DateRange]: true,
  [ControlTypes.OldDateRange]: true,
  [ControlTypes.InputText]: false,
  [ControlTypes.OldInputText]: false,
  [ControlTypes.NumberRange]: false,
  [ControlTypes.Slider]: false,
  [ControlTypes.TreeSelect]: false,
  [ControlTypes.QuickDateRange]: true,
};

export const IS_NUMBER_TYPE = {
  [ControlTypes.Select]: false,
  [ControlTypes.Radio]: false,
  [ControlTypes.Date]: false,
  [ControlTypes.DateRange]: false,
  [ControlTypes.OldDateRange]: false,
  [ControlTypes.InputText]: false,
  [ControlTypes.OldInputText]: false,
  [ControlTypes.NumberRange]: true,
  [ControlTypes.Slider]: true,
  [ControlTypes.TreeSelect]: false,
  [ControlTypes.QuickDateRange]: false,
};

export const CHANGE_IMMEDIATELY = {
  [ControlTypes.Select]: true,
  [ControlTypes.Radio]: true,
  [ControlTypes.Date]: true,
  [ControlTypes.DateRange]: true,
  [ControlTypes.OldDateRange]: true,
  [ControlTypes.InputText]: false,
  [ControlTypes.OldInputText]: false,
  [ControlTypes.NumberRange]: false,
  [ControlTypes.Slider]: true,
  [ControlTypes.TreeSelect]: true,
  [ControlTypes.QuickDateRange]: true,
};

export const ControlTypesOperatorSetting = {
  [ControlTypes.Select]: {
    normal: [OperatorTypes.Equal, OperatorTypes.NotEqual],
    multiple: [OperatorTypes.In, OperatorTypes.NotIn],
  },
  [ControlTypes.Radio]: [OperatorTypes.Equal, OperatorTypes.NotEqual],
  [ControlTypes.Date]: {
    normal: [OperatorTypes.Equal, OperatorTypes.LessThan, OperatorTypes.LessThanOrEqual, OperatorTypes.GreaterThan, OperatorTypes.GreaterThanOrEqual],
    multiple: [OperatorTypes.In, OperatorTypes.NotIn],
  },
  [ControlTypes.DateRange]: [],
  [ControlTypes.OldDateRange]: [],
  [ControlTypes.InputText]: [OperatorTypes.Equal, OperatorTypes.NotEqual, OperatorTypes.Contain, OperatorTypes.NotContain],
  [ControlTypes.OldInputText]: [OperatorTypes.Equal, OperatorTypes.NotEqual, OperatorTypes.Contain, OperatorTypes.NotContain],
  [ControlTypes.NumberRange]: [],
  [ControlTypes.Slider]: [],
  [ControlTypes.TreeSelect]: {
    normal: [OperatorTypes.Equal, OperatorTypes.NotEqual],
    multiple: [OperatorTypes.In, OperatorTypes.NotIn],
  },
  [ControlTypes.QuickDateRange]: [],
};

export const ControlPanelTypes = {
  Global: 'global',
  Local: 'local',
};

export const ControlPanelLayoutTypes = {
  Dashboard: 'dashboard',
  Display: 'display',
  Fullscreen: 'fullscreen',
  DashboardItem: 'dashboardItem',
};

export const DEFAULT_DASHBOARD_CONTROL_GRID_WIDTH = {
  xxl: 3,
  xl: 4,
  lg: 6,
  md: 12,
};
export const DEFAULT_DASHBOARD_ITEM_CONTROL_GRID_WIDTH = {
  xxl: 8,
  xl: 12,
  lg: 12,
  md: 12,
};

export const ControlQueryMode = {
  Immediately: 0,
  Manually: 1,
};

export const CONTROL_MAX_TAG_COUNT = 9999;
export const CONTROL_MAX_TAG_TEXT_LENGTH = 10;
