export const warningStatusObj = {
  1: '新预警',
  2: '处理中',
  3: '已完成',
  4: '逾期',
  5: '关闭',
};

export const warningTypeObj = {
  1: '调研预警',
  2: '指标预警',
  3: '行为预警',
};

export const warningClosedLoopStatusObj = {
  1: '新预警',
  2: '处理中',
  3: '已处理',
  4: '挂起',
  5: '已关闭',
};

export const warningStatusTagClass = {
  1: 'red',
  2: 'orange',
  3: 'green',
  4: 'blue',
  5: 'gray',
};

export const warningStatusOptions = [
  { label: 'All', value: '' },
  { label: 'NewWarning', value: '1' },
  { label: 'HandledWarning', value: '2' },
  { label: 'CompletedWarning', value: '3' },
  { label: 'HandOnWarning', value: '4' },
  { label: 'ClosedWarning', value: '5' },
];

export const monitorTypeObj = {
  1: '同比',
  2: '环比',
  3: '特殊值',
};

export const periodListObj = {
  every_time: '每次都预警',
  day_max: '每天最多',
  week_max: '每周最多',
  month_max: '每月最多',
};

export const formulaObj = {
  '=': '等于',
  '<': '小于',
  '>': '大于',
  '<=': '小于等于',
  '>=': '大于等于',
  '!=': '不等于',
  contain: '包含',
  '!contain': '不包含',
  in: '包含',
  '!in': '不包含',
  like: '包含',
  notLike: '不包含',
};

export const handleStatusObj = {
  领取: 2,
  完成: 3,
  关闭: 5,
  挂起: 4,
  重打开: 2,
};
