import CommonChart from '../../containers/Common/CommonChart';
import TableNullData from '../../component/TableNullData/index';
import CreateCustomParameters from '../../Setting/CustomParameters/CreateCustomParameters/index'
import api from '../../api';

export default {
  title: '自定义参数',
  components: [
    {
      component: CreateCustomParameters,
      
    },
    
    
  ],
};
