/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { decodeMetricName, getTextWidth, metricAxisLabelFormatter, getFieldAlias } from '../../util';
import { getLegendOption } from './util';
import defaultTheme from '../../Chart/echarts_theme';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps) {
  const { width, height, data, cols, metrics, chartStyles, color, tip } = chartProps;

  const { legend, axis, areaSelect, spec, toolbox, grid } = chartStyles;

  const { legendPosition, fontSize } = legend;

  const {
    inverse,
    showLine,
    lineStyle,
    lineSize,
    lineColor,
    showLabel,
    labelFontFamily,
    labelFontSize,
    labelColor,
    labelStyle,
    labelWeight,
    titleFontFamily,
    titleFontSize,
    titleFontStyle,
    titleColor,
    nameLocation,
    nameRotate,
    nameGap,
    showTitleAndUnit,
  } = axis;

  const { layout, smooth } = spec;

  const parallelPosition = {
    // by default
    left: 80,
    top: 60,
    right: 80,
    bottom: 60,
  };

  let series;
  let parallel = {
    layout,
    ...parallelPosition,
    parallelAxisDefault: {
      nameLocation,
      nameGap,
      nameRotate,
      inverse,
      nameTextStyle: {
        color: titleColor,
        fontStyle: titleFontStyle,
        fontFamily: titleFontFamily,
        fontSize: titleFontSize,
      },
      axisLabel: {
        show: showLabel,
        color: labelColor,
        fontFamily: labelFontFamily,
        fontSize: labelFontSize,
      },
      axisLine: {
        show: showLine,
        lineStyle: {
          color: lineColor,
          width: lineSize,
          type: lineStyle,
        },
      },
      areaSelectStyle: areaSelect,
    },
  };
  const legendData = [];

  let axisDimensions = [];
  if (cols.length) {
    axisDimensions = axisDimensions.concat(cols);
  }
  const dimensionsData = data.map((row) => axisDimensions.map(({ name, randomName }) => row[randomName || name]));

  if (color.items.length) {
    const grouped = data.reduce((obj, row) => {
      const grpText = color.items
        .map((c) => c.randomName || c.name)
        .map((key) => row[key])
        .join(String.fromCharCode(0));
      if (!obj[grpText]) {
        obj[grpText] = [];
      }
      obj[grpText].push(row);
      return obj;
    }, {});
    series = Object.entries(grouped).map(([grpText, rows], index) => {
      legendData.push(grpText);
      const data = rows.map((r) => {
        const dimData = axisDimensions.map((d) => r[d.randomName || d.name]);
        const metricData = metrics.map((m) => {
          const decodedMetricName = decodeMetricName(m.name);
          return r[m.randomName ? m.randomName : `${m.agg}(${decodedMetricName})`];
        });
        return dimData.concat(metricData);
      });
      return {
        name: grpText,
        type: 'parallel',
        smooth,
        lineStyle,
        data,
        lineStyle: {
          type: lineStyle,
          color: color.items[0].config.values[grpText] || defaultThemeColors[index % defaultThemeColors.length],
        },
      };
    });

    if (legend.showLegend) {
      const legendWidth = 56 + Math.max(...legendData.map((s) => getTextWidth(s, '', `${fontSize}px`)));
      switch (legendPosition) {
        case 'top':
          parallelPosition.top += 32;
          break;
        case 'bottom':
          parallelPosition.bottom += 32;
          break;
        case 'left':
          parallelPosition.left += legendWidth;
          break;
        case 'right':
          parallelPosition.right += legendWidth;
      }
      parallel = {
        ...parallel,
        ...parallelPosition,
      };
    }
  } else {
    series = [
      {
        name: '',
        type: 'parallel',
        smooth: smooth ? 1 : 0,
        lineStyle: {
          type: lineStyle,
          color: defaultThemeColors[0],
        },
        data: data.map((row) => [
          ...axisDimensions.map(({ name, randomName }) => row[randomName || name]),
          ...metrics.map((m) => row[m.randomName || `${m.agg}(${decodeMetricName(m.name)})`]),
        ]),
      },
    ];
  }

  const parallelAxis = [
    ...axisDimensions.map(({ name, fieldDisplay, field }, idx) => ({
      dim: idx,
      name: showTitleAndUnit ? getFieldAlias(field, {}) || fieldDisplay || name : '',
      type: 'category',
      data: dimensionsData.map((d) => d[idx]).filter((d, dIdx, arr) => arr.indexOf(d) === dIdx),
    })),
    ...metrics.map((m, idx) => ({
      dim: axisDimensions.length + idx,
      name: showTitleAndUnit ? getFieldAlias(m.field, {}) || m.fieldDisplay || decodeMetricName(m.name) : '',
      axisLabel: {
        formatter: showLabel ? metricAxisLabelFormatter : '',
      },
    })),
  ];

  const legendOption = getLegendOption(legend, legendData);

  return {
    tooltip: {},
    legend: legendOption,
    parallel,
    parallelAxis,
    series,
    grid: {
      show: true,
      borderColor: 'transparent',
      backgroundColor: grid ? grid.backgroundColor : 'transparent',
    },
  };
}
