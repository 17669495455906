import React, { Fragment, useState, useEffect } from 'react';
import { Select, InputNumber, Input, Switch } from 'antd';
import { weekOption, numberFormulaList } from '../../../../config';
import util from '../../../../util';
import api from '../../../../api';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';

const { Option } = Select;

function Collection(props) {
  const { onChange, value, wayList, disabled } = props;

  const [wayListObj, setWayListObj] = useState({});
  const [templateObj, setTemplateObj] = useState({});

  const monthDays = new Array(31).fill(0);
  // const [period,setPeriod] = useState('')

  useEffect(() => {
    if (wayList) {
      const wayListObjCopy = {};
      wayList.forEach((item) => {
        wayListObjCopy[item.id] = item;
      });
      setWayListObj(wayListObjCopy);
    }
  }, [wayList]);

  useEffect(() => {
    if (value.pushConfig && value.pushConfig[0].channel_id) {
      getChannelFields(value.pushConfig[0].channel_type, value.pushConfig[0].channel_id, true);
    }
  }, []);

  function handleChange(key, changedValue) {
    if (onChange) {
      const data = {
        ...value,
        [key]: changedValue,
      };
      if (key === 'period') {
        data.periodNum = '';
      }
      onChange(data);
    }
  }

  function handleChannelChange(changedValue) {
    let { pushConfig } = value;
    const valueObj = JSON.parse(changedValue);
    const { channel_type, channel_id } = valueObj;
    pushConfig = pushConfig || [];
    pushConfig[0] = valueObj;
    onChange({
      ...value,
      pushConfig,
    });
    if (!templateObj[channel_id]) {
      getChannelFields(channel_type, channel_id);
    }
  }

  function handleChannelChildrenChange(key, changedValue) {
    let { pushConfig } = value;
    pushConfig[0][key] = changedValue;
    onChange({
      ...value,
      pushConfig,
    });
  }

  function getChannelFields(channelType, id, isEdit) {
    //获取子表单项
    util.get(api.warning.getChannelFields, { idsite: pageHeaderStore.parameter.idsite, channelType }, (res) => {
      let data = templateObj;
      data[id] = { format: res, data: {} };
      setTemplateObj({ ...data });
      const formValue = isEdit ? {} : getInitialValue(res);
      let { pushConfig } = value;
      pushConfig[0] = { ...pushConfig[0], ...formValue };
      res.length &&
        res.forEach((item) => {
          item.type === 'list' && getFieldOptions(channelType, item.key, null, id);
          isEdit && item.children && item.children.type && getFieldOptions(channelType, item.children.key, null, id);
        });
    });
  }

  function getFieldOptions(channelType, key, attach, id) {
    //获取下拉框的选项
    attach = attach || {};
    attach.sceneType = '3';
    attach = JSON.stringify(attach);
    util.get(api.warning.getFieldOptions, { idsite: pageHeaderStore.parameter.idsite, pushChannelId: id, channelType, key, attach }, (res) => {
      let data = templateObj;
      data[id].data[key] = res;
      setTemplateObj({ ...data });
    });
  }

  function getInitialValue(data) {
    //根据格式设定初始值
    const obj = {};
    data.forEach((item) => {
      obj[item.key] = undefined;
      item.children && (obj[item.children.key] = undefined);
    });
    return obj;
  }

  function getChildForm(item) {
    const selectedWayItem = wayListObj[value.pushConfig[0].channel_id];
    const channelObj = value.pushConfig[0];
    return (
      <div className="form-row">
        <div className="form-item-children-text">{item.name}</div>
        {item.type === 'text' ? (
          <Input
            className="md-input"
            value={channelObj[item.key]}
            onChange={(e) => handleChannelChildrenChange(item.key, e.target.value)}
            disabled={disabled}
          />
        ) : (
          <Select className="lg-select" value={channelObj[item.key]} onChange={(value) => handleChannelChildrenChange(item.key, value)} disabled={disabled}>
            {templateObj[channelObj.channel_id].data[item.key] &&
              templateObj[channelObj.channel_id].data[item.key].map((el) => {
                return <Select.Option key={el.value}>{el.name}</Select.Option>;
              })}
          </Select>
        )}
        {item.children && getChildForm(selectedWayItem, item.children)}
      </div>
    );
  }

  return (
    <div className="collection-form-item">
      <div className="form-row">
        <Select
          className="lg-select"
          onChange={(value) => handleChannelChange(value)}
          value={
            value.pushConfig && value.pushConfig[0].channel_id
              ? JSON.stringify({ channel_id: value.pushConfig[0].channel_id, channel_type: value.pushConfig[0].channel_type })
              : null
          }
          disabled={disabled}>
          {wayList &&
            wayList.slice(2).map((item) => {
              return <Option key={item.value}>{item.label}</Option>;
            })}
        </Select>
      </div>
      {value.pushConfig &&
      value.pushConfig[0].channel_id &&
      templateObj[value.pushConfig[0].channel_id] &&
      value.pushConfig[0].channel_id &&
      templateObj[value.pushConfig[0].channel_id].format.length ? (
        <Fragment>
          {templateObj[value.pushConfig[0].channel_id].format.map((el) => {
            return getChildForm(el);
          })}
          <div className="form-row">
            <div className="form-item-children-text">附加url参数</div>
            <Input
              className="md-input"
              placeholder="a=xxx&b=xxx"
              value={value.pushConfig ? value.pushConfig[0].urlQuery : null}
              onChange={(value) => handleChannelChildrenChange('urlQuery', value)}
              disabled={disabled}
            />
          </div>
        </Fragment>
      ) : null}
      <div className="form-row">
        <div className="form-item-text-tips">每用户催收不超过</div>
        <InputNumber className="sm-input" min={1} onChange={(value) => handleChange('maxNum', value)} value={value.maxNum} disabled={disabled} />
        <div className="form-item-text-tips">份</div>
      </div>
    </div>
  );
}

export default Collection;
