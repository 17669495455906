import React, { Component } from 'react';
import { Modal, Form, Button, message } from 'antd';
import intl from 'react-intl-universal';
import { toJS } from 'mobx';
import util from '../../../util';
import api from '../../../api';
import tableUtil from '../../../utils/tableUtil';
import { DEFAULT_SPLITER } from '../../../containers/BIChart/constants';
import PageHeaderStore from '../PageHeaderStore';
import Table from '../../../containers/CommonTable';
import FormModal from './FormModal';
import BIStore from '../../../containers/BIChart/store';
import Loading from '../../Loading/Loading';

// config格式
// let config = [{
//     name: '控制1', //控制器名
//     type: 'select', //组件类型
//     relatedCards: [{ type: '10', id: '19' }],    //关联卡片，type：分析类型，id：卡片id
//     relatedViews: { 64: { name: 'var1', sqlType: 'string' } }//{ 64: 'var1' }  //关联工作表，viewName：工作表，fieldName:字段
// }]
class LinkageModal extends Component {
  constructor() {
    super();
    this.state = { data: null, isLoading: true, showFormModal: false, viewDetails: {}, isModalLoading: true };
  }

  componentDidMount() {
    this.fetchData(); //获取已有配置
    // 排除旧版分析
    const oldViews = ['action', 'answer'];
    let hasRequestView = false;
    Object.keys(BIStore.formedViews).map((key) => {
      !oldViews.includes(key) && (this.getViewDetail(BIStore.formedViews[key].id), (hasRequestView = true));
    });
    !hasRequestView &&
      this.setState({
        isModalLoading: false,
      });
  }

  fetchData = () => {
    this.setState({
      isLoading: true,
    });
    util.get(api.globalControl.list, { id: PageHeaderStore.currentBoardId }, (res) => {
      if (res.linkConfig) {
        let data = this.linkageConfigFilter(res.linkConfig ? JSON.parse(res.linkConfig) : []);
        this.setState({
          data: data,
          isLoading: false,
        });
        PageHeaderStore.setLinkageConfig(data);
      } else {
        this.setState({
          data: [],
          isLoading: false,
        });
      }
    });
  };

  // 去除已删除卡片的联动配置
  linkageConfigFilter(config) {
    const data = config.filter((item) => {
      return BIStore.currentItemsInfo[item.trigger[0]];
    });
    return data;
  }
  getViewDetail(id) {
    util.get(`${api.BI.views}/${id}`, {}, (res) => {
      const model = {};
      res.fieldModelList.map((item) => {
        model[item.fieldName] = item;
      });
      res.model = model;
      const viewDetails = {
        ...this.state.viewDetails,
        [id]: res,
      };
      const formedViews = [];
      Object.keys(toJS(BIStore.formedViews)).forEach((item) => {
        if (item !== 'answer' && item !== 'action') {
          formedViews.push(BIStore.formedViews[item]);
        }
      });
      this.setState({
        viewDetails,
        isModalLoading: Object.keys(viewDetails).length !== formedViews.length,
      });
    });
  }

  showFormModalToggle = (bool) => {
    this.setState({
      showFormModal: bool,
    });
  };

  delete = (index) => {
    this.setState({
      isLoading: true,
    });
    let { data } = this.state;
    data.splice(index, 1);
    util.post(
      api.globalControl.save,
      {
        id: PageHeaderStore.currentBoardId,
        linkConfig: JSON.stringify(data),
      },
      (res) => {
        res.code === 200
          ? (message.success('删除成功'),
            this.setState({
              data,
              isLoading: false,
            }))
          : message.error(res.msg);
      }
    );
  };

  addContent = (store) => {
    const config = [
      {
        currentRowData: 'trigger',
        children: (store, text, record, index) => {
          const trigger = record.trigger;
          const field = BIStore.currentItemsInfo[trigger[0]]
            ? this.state.viewDetails[BIStore.currentItemsInfo[trigger[0]].viewId].model[trigger[1].split(DEFAULT_SPLITER)[0]]
            : '';
          return `${BIStore.currentItemsInfo[trigger[0]] ? BIStore.currentItemsInfo[trigger[0]].name : ''} - ${
            field ? field.fieldDisplay || field.filedName : ''
          }`;
        },
      },
      {
        currentRowData: 'linkager',
        children: (store, text, record, index) => {
          const linkager = record.linkager;
          const field = BIStore.currentItemsInfo[linkager[0]]
            ? this.state.viewDetails[BIStore.currentItemsInfo[linkager[0]].viewId].model[linkager[1].split(DEFAULT_SPLITER)[0]]
            : '';
          return `${BIStore.currentItemsInfo[linkager[0]] ? BIStore.currentItemsInfo[linkager[0]].name : ''} - ${
            field ? field.fieldDisplay || field.filedName : ''
          }`;
        },
      },
      {
        currentRowData: 'operate',
        children: (store, text, record, index) => <a onClick={() => this.delete(index)}>删除</a>,
      },
    ];
    return tableUtil.addContentByConfig(store, config);
  };

  render() {
    const { visible, onHide, form } = this.props;
    const { data, isLoading, showFormModal, viewDetails, isModalLoading } = this.state;
    return (
      <Modal
        className="linkager-modal"
        visible={visible}
        title={intl.get('Linkage')}
        width={800}
        onCancel={onHide}
        onOk={onHide}
        centered
        okText="保存"
        cancelText="取消">
        <Button type="primary" onClick={() => this.showFormModalToggle(true)}>
          新增
        </Button>
        {!isLoading && !isModalLoading ? (
          <Table
            parentData={{ data }}
            tableHeadData={[['触发器', '联动卡片', '关系', '操作'], ['trigger', 'linkager', 'relation', 'operate']]}
            isSort={false}
            addContent={this.addContent}
          />
        ) : (
          <Loading />
        )}
        {showFormModal && (
          <FormModal
            visible={showFormModal}
            onHide={() => this.showFormModalToggle(false)}
            isLoading={isModalLoading}
            viewDetails={viewDetails}
            linkageConfig={data}
            fetchData={this.fetchData}
          />
        )}
      </Modal>
    );
  }
}

export default Form.create()(LinkageModal);
