import React, { Component } from 'react'
import CommonTable from '../CommonTable/index'
import SurveyDetailModal from '../SurveyDetailModal'
import { observer } from 'mobx-react'
import { observable, autorun } from 'mobx'
import { Link } from 'react-router-dom'
import util from '../../util'
import api from '../../api'
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore'
import Search from '../../component/Search'
import tableUtil from '../../utils/tableUtil'
import { Object } from 'es6-shim'

const fixedTableHeader = ['visitor_id', 'channel_name', 'invite_time', 'start_time', 'reply_time', 'duration']
const hidekeys = ['answer_id']
@observer
class SurveyVisitorTable extends Component {
  constructor(props) {
    super(props)
    this.visitorId = ''
    this.topics = []
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    this.disposer = autorun(() => {
      let date = PageHeaderStore.parameter.date
      this.fetchData()
    })
  }

  componentWillUnmount() {
    this.disposer()
  }

  @observable visible = false
  @observable id = null
  changeID = (id) => {
    this.id = null
    this.id = id
    this.visible = true
  }
  addContent = (store) => {
    store.columns.forEach((item, index) => {
      if (item.dataIndex === 'visitor_id') {
        item.render = (text, record) => {
          return {
            children: (
              <div className="childrenContent table-tools-content">
                <div className="rateContent">
                  <Link
                    to={`/data/survey/detail/visitor?visitorId=${text}&type=3&answerId=${record.answer_id.replace(
                      /,/g,
                      ''
                    )}`}
                    title={text}>
                    {text}
                  </Link>
                </div>
                <div className="editAndCreateIcon">
                  <i
                    className="iconfont icon-qita"
                    title="打开调研详情"
                    onClick={() => this.changeID(record.answer_id.replace(/,/g, ''))}
                  />
                </div>
              </div>
            )
          }
        }
      }
      if (item.dataIndex === 'answerId') {
        store.columns.splice(index, 1)
      }
    })
  }

  fetchData = (orderDesc = true, orderColumn = 'start_time', currentPage = 1, pageCount = 10) => {
    this.setState({ data: null })
    util.get(
      api.wenjuan.statisticsForAnswerList,
      {
        surveyId: this.props.id || util.getQueryString('key'),
        visitorId: this.visitorId || '',
        date: PageHeaderStore.parameter.date,
        orderDesc,
        orderColumn,
        currentPage,
        pageCount
      },
      (res) => {
        if (res.data && res.data.length) {
          const fixedKeys = [...fixedTableHeader, ...hidekeys]
          const keylist = Object.keys(res.data[0])
          let topics = []
          keylist.forEach((item) => {
            !fixedKeys.includes(item) && topics.push(item)
          })
          this.topics = topics
        }
        this.setState({
          data: res.data ? res : { data: [] }
        })
      }
    )
  }

  onSearch = (value) => {
    this.visitorId = value
    this.fetchData()
  }

  closeModal = () => {
    this.visible = false
    this.id = null
  }
  render() {
    return (
      <React.Fragment>
        <Search onClick={this.onSearch} className="paddingTop" placeholder="请输入完整用户ID" />
        <CommonTable
          tableHeadData={[
            [...(this.state.data ? this.state.data.titles : [])],
            [...fixedTableHeader, ...this.topics, ...hidekeys]
          ]}
          parentData={this.state.data}
          isLoading={!this.state.data}
          addContent={this.addContent}
          fetchData={this.fetchData}
          isSort={false}
        />
        {this.visible && <SurveyDetailModal visible={this.visible} onClose={this.closeModal} id={this.id} />}
      </React.Fragment>
    )
  }
}

export default SurveyVisitorTable
