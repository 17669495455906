import { action, observable } from 'mobx';
import pageHeaderStore from '../../../component/PageHeader/PageHeaderStore';

class BoardStore {
  @observable boardStyle = 'terse';
  @action.bound changeBoardStyle(type) {
    this.setBoardStyle(type);
    this.changeByType(type);
  }
  @action.bound changeByType(type) {
    if (type === 'terse') {
      this.clearBodyStyle();
      // 清除class的时候顺带把主题id清除 避免主题色发生遮盖
      if (process.env.REACT_APP_BDkey) {
        document.getElementsByTagName('body')[0].id = process.env.REACT_APP_BDkey;
      }
    } else {
      this.clearBodyStyle();
      this.changeBodyStyle(type);
    }
  }

  @action.bound changeBodyStyle(type) {
    document.getElementsByTagName('body')[0].classList.add(`customBoard-${type}`);
  }
  @action.bound clearBodyStyle = () => {
    document.getElementsByTagName('body')[0].classList.remove('customBoard-dark');
    document.getElementsByTagName('body')[0].classList.remove('customBoard-blue');
    document.getElementsByTagName('body')[0].classList.remove('customBoard-terse');
    // 清除class的时候顺带把主题id清除 避免主题色发生遮盖
    if (process.env.REACT_APP_BDkey) {
      document.getElementsByTagName('body')[0].id = null;
    }
  };
  @action.bound setBoardStyle = (type) => {
    this.boardStyle = type;
  };

  // 卡片列表
  @observable cardList = [];
  @action.bound setCardList(list) {
    this.cardList = list;
  }

  @observable isFullScreen = false;
  @action.bound resetFullScreen = (type) => {
    // this.isLoading = true;
    if (type === 'open') {
      document.getElementsByTagName('body')[0].classList.add('customBoard-fullScreen');
      // 防止全屏时操作浏览器后退导致页面错位
      window.history.pushState(null, null, document.URL);
      window.addEventListener('popstate', function() {
        window.history.pushState(null, null, document.URL);
      });
    } else {
      pageHeaderStore.setBoardChartFullScreen(false);
      // 防止全屏时操作浏览器后退导致页面错位
      window.removeEventListener('popstate', function() {
        window.history.pushState(null, null, document.URL);
      });
      document.getElementsByTagName('body')[0].classList.remove('customBoard-fullScreen');
      document.getElementsByTagName('body')[0].classList.add('customBoard-fullScreenCancel');
      setTimeout(() => {
        document.getElementsByTagName('body')[0].classList.remove('customBoard-fullScreenCancel');
      }, 2000);
      this.setBoardStyle('terse');
      this.changeByType('terse');
    }
    this.isFullScreen = type === 'open' ? true : false;
    // 重绘屏幕
    // this.delay = setTimeout(() => {
    //   type === 'open' && this.num++;
    //   this.isLoading = false;
    // }, 1000);
  };

  @observable compareFilterConfig = {};
  @action.bound setCompareFilterConfig = (widgetId, filterInfo) => {
    this.compareFilterConfig = {
      ...this.compareFilterConfig,
      [widgetId]: filterInfo,
    };
  };
}
export default new BoardStore();
