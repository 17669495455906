import { Layout, Menu, Dropdown, message, Modal, Input } from 'antd';
import { action, observable, autorun, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import sideMenu, { isTopMenuActivity, BDPermissions, onlyMainCountKeys, unlimitKeys } from '../../config/sideMenu';
import pageHeaderStore from '../PageHeader/PageHeaderStore';
import Scrollbar from '../Scrollbar';
import './Sidebar.sass';
import store from './store';
import AuthorityStore from '../../AuthorityStore/store';
import intl from 'react-intl-universal';
import FolderModal from './components/FolderModal';
import EditDashboardModal from './components/EditDashboardModal';
import { Fragment } from 'react';
import api from '../../api';
import util from '../../util';
import loginStore from '../../Personal/Login/store';
import { detailPaths } from '../../config';
import BoardModal from '../../views/BoardCustomList/Modal';
import BoardCustomStore from '../../views/BoardCustomList/store';
import ManagementUtil from '../../Product/Management/util';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;
const bodyWidth = document.body.offsetWidth;

@withRouter
@observer
class MenuList extends Component {
  constructor() {
    super();
    this.state = {
      currentFolderItem: null,
      showFolderModal: false,
      currentDashboardItem: null,
      showDashboardModal: false,
    };
    this.lastResetSelectkeyCount = 1;
  }
  componentDidMount() {
    if (window.innerWidth < 768) {
      store.isCollapsed = true;
    }
    this.disposer = autorun(() => {
      const resetSelectkeyCount = toJS(store.resetSelectkeyCount);
      if (this.lastResetSelectkeyCount !== resetSelectkeyCount) {
        let dataArr = this.props.navs ? this.props.navs.slice() : [];
        this.initSelectKey(dataArr);
        this.lastResetSelectkeyCount = resetSelectkeyCount;
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.navs) != JSON.stringify(prevProps.navs)) {
      let dataArr = this.props.navs ? this.props.navs.slice() : [];
      this.initSelectKey(dataArr);
    }
    // 看板管理里面跳转侧边栏key值没有刷新
    if (this.getNeedInit(this.props.location.pathname, prevProps.location.pathname)) {
      let dataArr = this.props.navs ? this.props.navs.slice() : [];
      this.initSelectKey(dataArr);
    }
    if (this.props.reloadSelectKeyCount !== prevProps.reloadSelectKeyCount) {
      this.handleSelectKey({ key: '' });
    }
  }

  getNeedInit(pathName, lastPageName) {
    if (!(pathName && lastPageName)) {
      return false;
    }
    const nowPage = pathName.substr(0, pathName.indexOf('?') > '-1' ? pathName.indexOf('?') : pathName.length);
    const lastPage = lastPageName.substr(0, lastPageName.indexOf('?') > '-1' ? lastPageName.indexOf('?') : lastPageName.length);
    return lastPage.includes('/customBoard') && nowPage.includes('/customDetail/');
  }

  @observable selectKey;
  @observable defaultOpenKeys = ['sub0', 'sub1', 'sub2', 'sub3', 'sub4', 'sub5'];

  @action.bound
  handleSelectKey(item) {
    this.selectKey = item.key;
  }

  @action.bound
  handleDefaultOpenKeys(id) {
    this.defaultOpenKeys.push(`sub${id}`);
  }

  @action.bound
  initSelectKey = (data) => {
    let pathname = decodeURI(this.props.location.pathname);
    data.forEach((item) => {
      if (item.children && item.children.length) {
        this.initSelectKey(item.children);
      } else {
        if (item.hasOwnProperty('folder') && item.href && item.href.split('?')[0] === pathname) {
          if (item.parentId) {
            // const isShare = item.share;
            const boardList = this.props.navs;
            const parentKey = item.parentId;
            const parent = boardList.find((i) => i.id === parentKey);
            this.handleDefaultOpenKeys(parent ? parent.id : '');
          }
          this.selectKey = `${item.name}${item.href}`;
        } else if (item.href && !item.hasOwnProperty('folder') && pathname.includes(item.href.split('?')[0])) {
          this.selectKey = `${item.name}${item.href}`;
        }
      }
    });
  };
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  getAllKey(data) {
    let keys = [];
    data.forEach((item) => {
      keys.push(`sub_${item.name}`);
    });
    return keys;
  }

  editFolder(item) {
    this.setState({
      currentFolderItem: item,
      showFolderModal: true,
    });
  }
  deleteFolder = (item) => {
    const that = this;
    Modal.confirm({
      title: '删除这个文件夹将会删除它所属的子页面',
      content: '你还要继续吗？',
      onOk() {
        util.get(api.folder.remove, { id: item.id, idsite: pageHeaderStore.parameter.idsite }, (res) => {
          res ? (that.props.getFolderDashboardList(), message.success('删除成功')) : message.error(res);
        });
      },
      okText: '确定',
      cancelText: '取消',
    });
  };
  editDashboard(item) {
    this.setState({
      currentDashboardItem: item,
      showDashboardModal: true,
    });
  }

  folderModalToggle = (bool) => {
    this.setState({
      showFolderModal: bool,
    });
    !bool &&
      this.setState({
        currentFolderItem: null,
      });
  };

  dashboardModalToggle = (bool) => {
    this.setState({
      showDashboardModal: bool,
    });
    !bool &&
      this.setState({
        currentDashboardItem: null,
      });
  };

  filterChildren = (navs) => {
    if (!navs) return null;
    navs.forEach((item, index) => {
      if (
        !item.folderType &&
        (!BDPermissions[process.env.REACT_APP_BDkey] || BDPermissions[process.env.REACT_APP_BDkey].indexOf(item.limitKey) > -1) &&
        item.children
      ) {
        item.children = item.children.filter(
          (el) =>
            ((el.show && (AuthorityStore.isMainAccount || onlyMainCountKeys.indexOf(el.key) < 0) && el.hasOwnProperty('type') === false) ||
              (el.hasOwnProperty('type') && pageHeaderStore.productType.indexOf(el.type) >= 0)) &&
            (AuthorityStore.checkAuthorityExist(item.key, el.key, 'view') || el.key === 'analyticsChart')
        );
      }
    });
    return navs;
  };

  render() {
    const { dashBoardSearchKey } = this.props;
    const { showFolderModal, currentFolderItem, currentDashboardItem, showDashboardModal } = this.state;
    const navs = this.filterChildren(toJS(this.props.navs));
    return (
      pageHeaderStore.parameter.idsite && (
        <Fragment>
          <Sider trigger={null} collapsible collapsed={store.isCollapsed}>
            <Menu
              mode="inline"
              className="page-sidebar"
              selectedKeys={[this.selectKey]}
              onClick={(item) => {
                this.handleSelectKey(item);
              }}
              defaultOpenKeys={this.defaultOpenKeys}>
              {navs &&
                navs.map((item, index) => {
                  return item.folderType ? (
                    !item.folder ? (
                      AuthorityStore.checkAuthorityExist('insight', 'dashboard', 'view') &&
                      item.name.includes(dashBoardSearchKey) && (
                        <Menu.Item key={`${item.name}${item.href}`}>
                          <div className="editable-menu notFolder">
                            <Link to={item.href} title={item.name ? intl.get(item.name) || item.name : ''}>
                              <i className={`dashboard-type-icon iconfont ${item.share ? 'icon-fenxiangkanban' : 'icon-guanjianrenwu'}`} />
                              <div>{item.name ? intl.get(item.name) || item.name : ''}</div>
                            </Link>
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item key="0">
                                    <a
                                      onClick={() => {
                                        this.editDashboard(item);
                                      }}>
                                      移动
                                    </a>
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={['click']}>
                              <i
                                className="iconfont icon-caozuoxiangqing menu-tool"
                                onClick={(event) => {
                                  event.preventDefault(), event.stopPropagation();
                                }}
                              />
                            </Dropdown>
                            {/* <i className="iconfont icon-caozuoxiangqing menu-tool" onClick={(event) => { this.removeDashboard(event, el) }} /> */}
                          </div>
                        </Menu.Item>
                      )
                    ) : (
                      <SubMenu
                        key={`sub${item.id || index}`}
                        className="dashboard-sub sidebar-popup"
                        popupClassName="sidebar-menu-popup"
                        title={
                          <div className="editable-menu">
                            <div className="title">
                              {/* 文件夹展开 */}
                              <i className="iconfont icon-dakaiwenjianjia" style={{ marginRight: '10px' }}>
                                <span className="nav-label1" title={item.name.slice(0, 2)}>
                                  {item.name.slice(0, 2)}
                                </span>
                              </i>
                              {/* 文件夹收起 */}
                              <i className="iconfont icon-bihewenjianjia" style={{ marginRight: '10px' }}>
                                <span className="nav-label1" title={item.name.slice(0, 2)}>
                                  {item.name.slice(0, 2)}
                                </span>
                              </i>
                              <span className="nav-label" title={item.name}>
                                {item.name}
                              </span>
                            </div>
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item key="0">
                                    <a
                                      onClick={() => {
                                        this.editFolder(item);
                                      }}>
                                      编辑
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item key="1">
                                    <a
                                      onClick={() => {
                                        this.deleteFolder(item);
                                      }}>
                                      删除
                                    </a>
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={['click']}>
                              <i
                                className="iconfont icon-caozuoxiangqing menu-tool"
                                onClick={(event) => {
                                  event.preventDefault(), event.stopPropagation();
                                }}
                              />
                            </Dropdown>
                            {/* <i className="iconfont icon-caozuoxiangqing menu-tool" onClick={(event) => { this.editFolder(event, item) }} /> */}
                          </div>
                        }>
                        {item.children.map((e, n) => {
                          return (
                            !e.folder &&
                            AuthorityStore.checkAuthorityExist('insight', 'dashboard', 'view') &&
                            e.name.includes(dashBoardSearchKey) && (
                              <Menu.Item key={`${e.name}${e.href}`}>
                                <div className="editable-menu dashboard-menu-item notFolder">
                                  <Link to={e.href} title={e.name ? intl.get(e.name) || e.name : ''}>
                                    <i className={`dashboard-type-icon iconfont ${e.share ? 'icon-fenxiangkanban' : 'icon-guanjianrenwu'}`} />
                                    <div>{e.name ? intl.get(e.name) || e.name : ''}</div>
                                  </Link>
                                  <Dropdown
                                    overlay={
                                      <Menu>
                                        <Menu.Item key="0">
                                          <a
                                            onClick={() => {
                                              this.editDashboard(e);
                                            }}>
                                            移动
                                          </a>
                                        </Menu.Item>
                                      </Menu>
                                    }
                                    trigger={['click']}>
                                    <i
                                      className="iconfont icon-caozuoxiangqing menu-tool"
                                      onClick={(event) => {
                                        event.preventDefault(), event.stopPropagation();
                                      }}
                                    />
                                  </Dropdown>
                                  {/* <i className="iconfont icon-caozuoxiangqing menu-tool" onClick={(event) => { this.removeDashboard(event, e) }} /> */}
                                </div>
                              </Menu.Item>
                            )
                          );
                        })}
                      </SubMenu>
                    )
                  ) : (
                    (!BDPermissions[process.env.REACT_APP_BDkey] || BDPermissions[process.env.REACT_APP_BDkey].indexOf(item.limitKey) > -1) &&
                      (item.children ? (
                        item.children.length ? (
                          <SubMenu
                            popupClassName="sidebar-menu-popup"
                            key={`sub${index}`}
                            title={
                              <a>
                                <i className={'iconfont ' + item.iconName}>
                                  <span className="nav-label1" title={item.name1 ? intl.get(item.name1) || item.name1 : ''}>
                                    {item.name1 ? intl.get(item.name1) || item.name1 : ''}
                                  </span>
                                </i>
                                <span className="nav-label" title={item.name ? intl.get(item.name) || item.name : ''}>
                                  {item.name ? intl.get(item.name) || item.name : ''}
                                </span>
                                <span>
                                  <i className={' pull-right iconfont '} />
                                </span>
                              </a>
                            }>
                            {item.children.map((el, i) => {
                              return (
                                el.show && (
                                  <Menu.Item key={`${el.name}${el.href}`}>
                                    <Link to={el.href} title={el.name ? intl.get(el.name) || el.name : ''}>
                                      {el.name ? intl.get(el.name) || el.name : ''}
                                    </Link>
                                  </Menu.Item>
                                )
                              );
                            })}
                          </SubMenu>
                        ) : null
                      ) : (
                        item.show &&
                        (AuthorityStore.checkAuthorityExist(item.key, item.limitKey, 'view') || unlimitKeys.indexOf(item.limitKey) > -1) &&
                        (AuthorityStore.isMainAccount || onlyMainCountKeys.indexOf(item.limitKey) < 0) && (
                          <Menu.Item key={`${item.name}${item.href}`} className="single-title">
                            <i className={'iconfont ' + item.iconName} />
                            <Link to={item.href} title={item.name ? intl.get(item.name) || item.name : ''} className="nav-label">
                              {item.name ? intl.get(item.name) || item.name : ''}
                            </Link>
                          </Menu.Item>
                        )
                      ))
                  );
                })}
            </Menu>
          </Sider>
          {showFolderModal && (
            <FolderModal
              visible={showFolderModal}
              currentFolderItem={currentFolderItem}
              onCancel={() => {
                this.folderModalToggle(false);
              }}
              getFolderDashboardList={this.props.getFolderDashboardList}
            />
          )}
          {showDashboardModal && (
            <EditDashboardModal
              visible={showDashboardModal}
              currentDashboardItem={currentDashboardItem}
              onCancel={() => {
                this.dashboardModalToggle(false);
              }}
              getFolderDashboardList={this.props.getFolderDashboardList}
            />
          )}
        </Fragment>
      )
    );
  }
}

@withRouter
@observer
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      sidebarMenuList: null,
      showFolderModal: false,
      showSearch: false,
      dashBoardSearchKey: '',
      reloadSelectKeyCount: 1,
    };
    this.lastProps = {};
    this.lastFolderListRefreshCount = 1;
    this.lastDashboardType = store.initDashboardType();
    this.boardCustomList = [];
    this.scrollbar = React.createRef();
    this.lastFolderListRefreshCountNoRedirect = 1;
    autorun(() => {
      const length = this.boardCustomList.length;
      if (BoardCustomStore.list && BoardCustomStore.list.length) {
        this.boardCustomList = BoardCustomStore.list;
      }
      if (length) {
        this.getFolderDashboardList();
      }
    });
  }

  componentDidMount() {
    sideMenu.boardInsight = [sideMenu.boardInsight[0]];
    this.allMenus = sideMenu;
    this.getBoard = autorun(() => {
      let idsite = pageHeaderStore.parameter.idsite;
      let pathname = this.props.location.pathname;
      let currentProps = {
        idsite,
      };
      if (JSON.stringify(currentProps) !== JSON.stringify(this.lastProps)) {
        this.getSidebarMenuList();
        this.lastProps = currentProps;
      }
    });
    this.getBoardList = autorun(() => {
      let idsite = pageHeaderStore.parameter.idsite;
      if (idsite !== this.lastIdsite) {
        this.getFolderDashboardList();
        this.lastIdsite = idsite;
      }
    });
    this.refreshBoardList = autorun(() => {
      let folderListRefreshCount = store.folderListRefreshCount;
      if (folderListRefreshCount !== this.lastFolderListRefreshCount) {
        this.getFolderDashboardList(true);
        this.lastFolderListRefreshCount = folderListRefreshCount;
      }
    });
    this.refreshBoardListNoRedirect = autorun(() => {
      let folderListRefreshCountNoRedirect = store.folderListRefreshCountNoRedirect;
      if (folderListRefreshCountNoRedirect !== this.lastFolderListRefreshCountNoRedirect) {
        this.getFolderDashboardList();
        this.lastFolderListRefreshCountNoRedirect = folderListRefreshCountNoRedirect;
      }
    });
  }

  componentDidUpdate(preProps) {
    if (this.props.location.pathname !== preProps.location.pathname && this.allMenus) {
      this.getSidebarMenuList();
      this.scrollbar.current.getElementsByClassName('scrollarea-content')[0].style.marginTop = '0px';
    }
  }

  componentWillUnmount() {
    this.getBoard();
    this.getBoardList();
    this.refreshBoardList();
    this.refreshBoardListNoRedirect();
  }
  @observable data;
  @observable allMenus;
  @observable modalInfo = { isShowAddBoard: false, name: '', id: '', openType: '', type: store.dashboardType };

  @action.bound
  showAddBoard() {
    this.modalInfo = {
      openType: 'create',
      isShowAddBoard: true,
      folderId: null,
      name: '',
      id: '',
      type: store.dashboardType,
      businessType: null,
    };
  }

  @action.bound
  hideAddBoard() {
    this.modalInfo = { isShowAddBoard: false };
  }
  @action.bound
  modalFormChange(value) {
    this.modalInfo = { ...this.modalInfo, ...value };
  }
  @action.bound
  addBoard() {
    this.modalInfo = { ...this.modalInfo, confirmLoading: true };
    if (!this.modalInfo.name || this.modalInfo.name.length === 0) {
      message.info(intl.get('Pleaseenterdashboardname'));
    } else {
      let param = {
        name: this.modalInfo.name,
        idsite: pageHeaderStore.parameter.idsite,
        industry: pageHeaderStore.industry,
        type: this.modalInfo.type,
      };
      this.modalInfo.folderId && (param.folderId = Number(this.modalInfo.folderId));
      this.modalInfo.businessType && (param.businessType = this.modalInfo.businessType);
      if (this.modalInfo.id) {
        param['id'] = this.modalInfo.id;
      }
      util.post(api.board.add, param, (res) => {
        res.code === 200
          ? // pageHeaderStore.getBoardList(),
            (message.success(`${this.modalInfo.openType === 'create' ? '添加' : '编辑'}看板成功`),
            (this.modalInfo = { isShowAddBoard: false }),
            BoardCustomStore.fetchList(store.dashboardType))
          : message.error(res.msg);
        this.modalInfo = { ...this.modalInfo, confirmLoading: false };
        this.getFolderDashboardList();
      });
    }
  }

  getFolderDashboardList = (isRefresh) => {
    let param = {
      idsite: pageHeaderStore.parameter.idsite,
    };
    store.dashboardType !== 1 && (param.type = store.dashboardType);
    pageHeaderStore.parameter.idsite &&
      util.get(api.folder.getFolderDashboardList, param, (res) => {
        if (res) {
          store.setAllFolderList(res);
          let allMenus = this.allMenus;
          let dashboard = [];
          let hasPush = false;
          const isNormalDashboard = store.dashboardType == 1;
          res.forEach((item) => {
            item.key = 'dashboard';
            if (!item.folder) {
              const link = ManagementUtil.getBoardLink({ ...item, type: store.dashboardType });
              item.href = link;
              item.show = true;
              isRefresh && !hasPush && isNormalDashboard && (this.props.history.push({ pathname: link }), (hasPush = true));
            }
            if (item.parentId === null) {
              item.parentId = 0;
            }
            dashboard.push(item);
          });
          if (isRefresh && (!hasPush || !isNormalDashboard)) {
            this.linkToBoardList();
          }
          const customDashboard = this.listToTree(dashboard, 0);
          allMenus.boardInsight = customDashboard;
          this.allMenus = { ...allMenus };
          store.setSidebarAllMenu({ ...allMenus });
          this.getSidebarMenuList();
        } else {
          loginStore.setPermission(true);
        }
      });
  };
  // 跳转到列表页
  linkToBoardList() {
    let link = '';
    switch (store.dashboardType) {
      case 2:
        link = '/design/customerJourney';
        break;
      case 3:
        link = '/design/blueprint';
        break;
      default:
        link = '/customBoard';
        break;
    }
    this.props.history.push({ pathname: link });
  }

  getSidebarMenuList = () => {
    if (detailPaths.indexOf(this.props.location.pathname) < 0) {
      const pathName = this.props.location.pathname;
      let sidebarMenuList = null;
      if (isTopMenuActivity('boardData', pathName)) {
        this.setState({
          editable: false,
        });
        sidebarMenuList = this.allMenus && this.allMenus.boardData;
      } else if (isTopMenuActivity('boardAnalyse', pathName)) {
        this.setState({
          editable: false,
        });
        sidebarMenuList = this.allMenus && this.allMenus.boardAnalyse;
      } else if (isTopMenuActivity('boardInsight', pathName) || pathName.includes('/customDetail') || pathName.includes('/customBoard')) {
        this.setState({
          editable: true,
        });
        sidebarMenuList = this.allMenus && this.allMenus.boardInsight ? this.allMenus.boardInsight : this.allMenus.questionnaire;
      } else if (checkMenu(['/action'], pathName)) {
        this.setState({
          editable: false,
        });
        sidebarMenuList = this.allMenus && this.allMenus.boardAction;
      } else if (checkMenu(['/set', '/product'], pathName)) {
        this.setState({
          editable: false,
        });
        sidebarMenuList = this.allMenus && this.allMenus.setting;
      } else if (checkMenu(['/person', '/audit', '/share', '/notification', '/workbench', '/log'], pathName)) {
        this.setState({
          editable: false,
        });
        sidebarMenuList = this.allMenus && this.allMenus.account;
      } else if (checkMenu(['/design'], pathName)) {
        this.setState({
          editable: true,
        });
        sidebarMenuList = this.allMenus && (this.allMenus.boardDesign ? this.allMenus.boardDesign : this.allMenus.boardInsight);
      } else if (checkMenu(['/wj'], pathName)) {
        this.setState({
          editable: false,
        });
        sidebarMenuList = this.allMenus && this.allMenus.questionnaire;
      }
      if (pageHeaderStore.industry !== 3) {
        let newSidebarMenuList = toJS(sidebarMenuList);
        newSidebarMenuList &&
          newSidebarMenuList.every((item, index) => {
            if (item.name === '转化报告') {
              newSidebarMenuList.splice(index, 1);
              return false;
            } else {
              return true;
            }
          });
        this.setState({
          sidebarMenuList: newSidebarMenuList,
        });
      } else {
        this.setState({
          sidebarMenuList: sidebarMenuList,
        });
      }
    }
    function checkMenu(paths, pathName) {
      let flag = false;
      paths.forEach((item) => {
        pathName.includes(item) && (flag = true);
      });
      return flag;
    }
  };

  folderModalToggle = (bool) => {
    this.setState({
      showFolderModal: bool,
    });
  };

  toBoard = () => {
    // const pathName = this.props.location.pathname;
    // if (pathName !== '/customBoard') {
    let link = '';
    switch (store.dashboardType) {
      case 2:
        link = '/design/customerJourney';
        break;
      case 3:
        link = '/design/blueprint';
        break;
      default:
        link = '/customBoard';
        break;
    }
    this.props.history.push(link);
    this.setState({
      reloadSelectKeyCount: (this.state.reloadSelectKeyCount += 1),
    });
    // }
  };

  // 看板文件夹层级
  listToTree(list, parentId) {
    const ret = [];
    for (const i in list) {
      if (list[i].parentId === parentId) {
        const leftList = list.filter((l) => l.id !== list[i].id);
        list[i].folder && (list[i].children = this.listToTree(leftList, list[i].id));
        ret.push(list[i]);
      }
    }
    return ret;
  }

  searchToggle = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    });
  };

  onDashboardSearch = (e) => {
    this.setState({
      dashBoardSearchKey: e.target.value,
    });
  };

  render() {
    const { showFolderModal, sidebarMenuList, editable, showSearch, dashBoardSearchKey, reloadSelectKeyCount } = this.state;
    const isCustomBoard = ['/customBoard', '/design/customerJourney', '/design/blueprint'].includes(this.props.location.pathname);
    return (
      <nav
        id="sidebar"
        className={`navbar-static-side ${store.isCollapsed ? 'collapsed-sidebar' : 'normal-sidebar'} ${editable ? 'dashboard-sidebar' : ''} ${
          pageHeaderStore.isRestorePosition ? '' : 'top'
        }  `}>
        {editable && (
          <div className="sidebar-tools">
            <div className="sidebar-tools-content">
              <i
                className="iconfont icon-a-2 uxs-pointer"
                title="搜索"
                onClick={() => {
                  this.searchToggle();
                }}
              />
              <i
                className="iconfont icon-zengjia uxs-pointer"
                title="新建看板"
                onClick={() => {
                  this.showAddBoard();
                }}
              />
              <i
                className="iconfont icon-a-1 uxs-pointer"
                title="新建文件"
                onClick={() => {
                  this.folderModalToggle(true);
                }}
              />
              <i
                className={`iconfont icon-shezhi uxs-pointer ${isCustomBoard ? 'active' : ''}`}
                title={store.dashboardType == 1 ? '看板管理' : store.dashboardType == 2 ? '用户旅程地图' : '服务蓝图'}
                onClick={() => {
                  this.toBoard();
                }}
              />
            </div>
            {showSearch && <Input.Search className="dashboard-search" onChange={this.onDashboardSearch} value={dashBoardSearchKey} />}
          </div>
        )}
        <div className="sidebar-scrollbar-content" ref={this.scrollbar}>
          <Scrollbar>
            <div className="sidebar-content">
              <MenuList
                {...this.props}
                navs={sidebarMenuList}
                getFolderDashboardList={this.getFolderDashboardList}
                dashBoardSearchKey={dashBoardSearchKey}
                reloadSelectKeyCount={reloadSelectKeyCount}
              />
            </div>
          </Scrollbar>
        </div>
        {showFolderModal && (
          <FolderModal
            visible={showFolderModal}
            onCancel={() => {
              this.folderModalToggle(false);
            }}
            getFolderDashboardList={this.getFolderDashboardList}
          />
        )}
        {this.modalInfo.isShowAddBoard && (
          <BoardModal
            modalInfo={this.modalInfo}
            hideAddBoard={this.hideAddBoard}
            addBoard={this.addBoard}
            modalFormChange={this.modalFormChange}
            isShowAddBoard={this.modalInfo.showAddBoard}
            confirmLoading={this.modalInfo.confirmLoading}
          />
        )}
      </nav>
    );
  }
}
// 防止手机端出现弹窗时屏幕滑动

export { Sidebar };
