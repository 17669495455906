import React from 'react';
import { Select} from 'antd';
import { weekOption } from '../../../../config';

const { Option } = Select;

function Collection(props) {
  const { onChange, value, disabled } = props;

  const monthDays = new Array(31).fill(0);

  function handleChange(key, changedValue) {
    if (onChange) {
      const data = {
        ...value,
        [key]: changedValue,
      };
      if (key === 'period') {
        data.periodNum = '';
      }
      onChange(data);
    }
  }

  return (
    <div className="collection-form-item">
      <div className="form-row">
        <Select className="md-select" onChange={(value) => handleChange('period', value)} value={value.period} disabled={disabled}>
          <Option key="week">每周</Option>
          <Option key="month">每月</Option>
        </Select>
        <Select
          mode="multiple"
          className="md-multiple-select"
          onChange={(value) => handleChange('periodNum', value.join(','))}
          value={value.periodNum ? value.periodNum.split(',') : []}
          disabled={disabled}>
          {value.period === 'month'
            ? monthDays.map((item, index) => {
                return <Option key={index + 1}>{index + 1}号</Option>;
              })
            : weekOption.map((item) => {
                return <Option key={item.value}>{item.label}</Option>;
              })}
        </Select>
      </div>
    </div>
  );
}

export default Collection;
