import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import util from '../../util';
import api from '../../api';
import chartUtil from '../../utils/chartUtil';
import AuthorityStore from '../../AuthorityStore/store';

export default {
  // 取出对应的值 如拿到
  // [{bounceRate: {key: "bounceRate", value: "0.8137", compare: 0.002, perk: 0.05, valley: -0.05, ratio: null},
  // pv: {key: "pv", value: "780572", compare: -0.1251, perk: 0.05, valley: -0.05, ratio: null}]
  // 通过不同的type可以取其内部的值重新格式为想要的属性，如为空则返回原来的指标所对应的数值

  formulaChartDataByTarget(target, dims, type, data) {
    const newData = chartUtil.getObjectTargetData(type, data);

    return chartUtil.multiDimsCreateAxis({ data: newData }, dims, target, {
      type: 'line',
    });
  },
  redirectUrl(url) {
    window.location.href = url;
  },
  changeLinkPath(history, productId, toWorkbench) {
    if ((!AuthorityStore.checkAuthorityExist('insight', 'dashboard', 'view') || toWorkbench) && process.env.REACT_APP_Land_Workbench) {
      history.push({ pathname: `/person/workbench` });
    } else {
      let param = {
        idsite: productId || pageHeaderStore.parameter.idsite,
        currentPage: 1,
        pageCount: 10000,
        type: 1,
      };
      util.get(
        api.board.listWithoutTemplate,
        param,
        (res) => {
          if (res && res.data) {
            const { data } = res;
            data.forEach((item) => {
              item.isSelect = false;
            });
            // 切换看板后跳转到 能看到的自建类型或者共享类型
            const selfBoard = data.findIndex((i) => i.boardType === '自有' && [0, 1].includes(i.type));
            const shareBoard = data.findIndex((i) => (i.boardType === '分享可协作' || i.boardType === '分享仅查看') && [0, 1].includes(i.type));
            const current = history.location.pathname;
            let link = '';
            if (data.length === 0) {
              link = '/customBoard';
            } else {
              if (selfBoard > -1) {
                link = this.getBoardLink(data[selfBoard]);
              } else if (selfBoard === -1 && shareBoard > -1) {
                link = this.getBoardLink(data[shareBoard]);
              } else {
                link = '/customBoard';
              }
            }
            // 重复路由不跳转，避免页面整个刷新
            if (current !== link) {
              history.push({ pathname: link });
            }
          } else {
            history.push({ pathname: `/person` });
          }
          pageHeaderStore.changeProduct(productId || pageHeaderStore.parameter.idsite);
        },
        null,
        false
      );
    }
  },
  // 根据看板类型跳转不同的链接
  getBoardLink(board) {
    let link = '';
    let id = board.id;
    switch (board.type) {
      case 2:
        link = `/design/customerJourney/detail/${id}?type=2&name=${board.name}`;
        break;
      case 3:
        link = `/design/blueprint/detail/${id}?type=3&name=${board.name}`;
        break;
      case 4:
        link = `/wj/surveyPush/${id}`;
        break;
      case 5:
        link = `/wj/surveyUser/${id}`;
        break;
      case 6:
        link = `/action/warningLog/dashboard/${id}`;
        break;
      default:
        link = `/customDetail/${id}?boardName=${board.name}&productName=${pageHeaderStore.parameter.name}`;
        break;
    }
    return link;
  },
};
