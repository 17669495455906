import React, { Fragment, useState, useEffect } from 'react';
import { Select, InputNumber, DatePicker } from 'antd';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

function DataPeriod(props) {
  const { onChange, value } = props;
  const [type, setType] = useState(null);
  const periodTypes = [
    {
      value: 'dynamic',
      label: '动态时间',
    },
    {
      value: 'fixed',
      label: '固定时间',
    },
  ];
  const unitList = [
    {
      value: 'Day',
      label: '日',
    },
    {
      value: 'Week',
      label: '周',
    },
    {
      value: 'Month',
      label: '月',
    },
    {
      value: 'Year',
      label: '年',
    },
  ];

  useEffect(() => {
    if (value.unit) {
      setType('dynamic');
    } else if (value.date) {
      setType('fixed');
    }
  }, []);

  function typeChange(value) {
    setType(value);
    onChange(value === 'dynamic' ? { unit: null, num: null } : { date: null });
  }
  function onNumChange(value) {
    triggerChange({ num: value });
  }
  function onUnitChange(value) {
    triggerChange({ unit: value });
  }
  function onDateChange(date, dateString) {
    triggerChange({ date: dateString.join(',') });
  }
  function triggerChange(changedValue) {
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <Select value={type} className="md-select" onChange={typeChange} style={{ marginRight: '4px' }}>
        {periodTypes.map((item) => {
          return <Option key={item.value}>{item.label}</Option>;
        })}
      </Select>
      {type === 'dynamic' ? (
        <Fragment>
          <span style={{ lineHeight: '30px' }}>过去</span>
          <InputNumber className="xs-input" value={value.num || null} onChange={onNumChange} min={1} style={{ margin: '0 4px' }} />
          <Select value={value.unit || null} className="sm-select" onChange={onUnitChange}>
            {unitList.map((item) => {
              return <Option key={item.value}>{item.label}</Option>;
            })}
          </Select>
        </Fragment>
      ) : type === 'fixed' ? (
        <RangePicker size="middle" onChange={onDateChange} value={value.date ? [moment(value.date.split(',')[0]), moment(value.date.split(',')[1])] : null} />
      ) : null}
    </div>
  );
}

function SendTime(props) {
  const { onChange, value } = props;
  const [period, setPeriod] = useState(null);
  const weekDays = [
    {
      value: '1',
      label: '星期一',
    },
    {
      value: '2',
      label: '星期二',
    },
    {
      value: '3',
      label: '星期三',
    },
    {
      value: '4',
      label: '星期四',
    },
    {
      value: '5',
      label: '星期五',
    },
    {
      value: '6',
      label: '星期六',
    },
    {
      value: '7',
      label: '星期日',
    },
  ];
  const hours = new Array(24).fill(1);
  const minutes = new Array(60).fill(1);
  const days = new Array(31).fill(1);
  const periods = [
    {
      value: 'day',
      label: '日',
    },
    {
      value: 'week',
      label: '周',
    },
    {
      value: 'month',
      label: '月',
    },
  ];

  useEffect(() => {
    setPeriod(value.period);
  }, []);

  function periodChange(value) {
    setPeriod(value);
    onChange(
      value === 'day'
        ? { period: value, hour: value.hour, min: value.min }
        : value === 'week'
        ? { period: value, week: null, hour: value.hour, min: value.min }
        : { period: value, day: null, hour: value.hour, min: value.min }
    );
  }
  function onWeekDayChange(value) {
    triggerChange({ week: value });
  }
  function onMonthDayChange(value) {
    triggerChange({ day: value });
  }
  function onHourChange(value) {
    triggerChange({ hour: value });
  }
  function onMinChange(value) {
    triggerChange({ min: value });
  }
  function triggerChange(changedValue) {
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  }

  return (
    <Fragment>
      <span>每</span>
      <Select value={value.period} className="md-select" onChange={periodChange} style={{ margin: '0 4px' }}>
        {periods.map((item) => {
          return <Option key={item.value}>{item.label}</Option>;
        })}
      </Select>
      {period === 'week' ? (
        <Select value={(value.week && value.week.toString()) || null} className="sm-select" onChange={onWeekDayChange} style={{ marginRight: '4px' }}>
          {weekDays.map((item, index) => {
            return <Option key={item.value}>{item.label}</Option>;
          })}
        </Select>
      ) : period === 'month' ? (
        <Select value={(value.day && value.day.toString()) || null} className="sm-select" onChange={onMonthDayChange} style={{ marginRight: '4px' }}>
          {days.map((item, index) => {
            const day = `${index + 1}`;
            return <Option key={day}>{day}日</Option>;
          })}
        </Select>
      ) : null}
      <span>的</span>
      <Select value={(value.hour && value.hour.toString()) || null} className="sm-select" onChange={onHourChange} style={{ margin: '0 4px' }}>
        {hours.map((item, index) => {
          const hour = `${index < 10 ? '0' : ''}${index}`;
          return <Option key={hour}>{hour}时</Option>;
        })}
      </Select>
      <Select value={(value.min && value.min.toString()) || null} className="sm-select" onChange={onMinChange}>
        {minutes.map((item, index) => {
          const minutes = `${index < 10 ? '0' : ''}${index}`;
          return <Option key={minutes}>{minutes}分</Option>;
        })}
      </Select>
    </Fragment>
  );
}

export { DataPeriod, SendTime };
