import HeadCell from './HeadCell'
import BodyCell from './BodyCell'

export const tableComponents = {
    header: {
        cell: HeadCell
    },
    body: {
        cell: BodyCell
    }
}
