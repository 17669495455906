import React, { Component } from 'react';
import CommonTable from '../CommonTable/index';
class ShareUserTable extends Component {
  addContent = (store) => {
    for (let i = 0; i < store.columns.length; i++) {
      let currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'shareVisitor_key') {
        store.columns[i].render = (text) => {
          return {
            children: (
              <a href={`${process.env.REACT_APP_Base_Url}/data/visitor/record/info?visitorId=${text}`} target="_blank">
                {text}
              </a>
            ),
          };
        };
      }
    }
  };
  render() {
    return (
      <CommonTable
        addContent={this.addContent}
        dims="channel_share_visitor"
        fields="shareFissionUv,shareVisitorName,shareVisitorGender,shareOneLevelUv,shareTwoLevelUv,shareThreeLevelUv"
        orderDesc={true}
        pageCount={10}
        currentPage={1}
        orderColumn="shareFissionUv"
        showAll={true}></CommonTable>
    );
  }
}
export default ShareUserTable;
