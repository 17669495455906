import React, { Component, PureComponent, Suspense, ReactNode } from 'react';
import './Panel/Layouts/Layouts.less';
import classnames from 'classnames';
import InputText from './InputText';
import NumberRange from '../../../NumberRange';
import Select from './Select';
import Radio from './Radio';
import TreeSelect from './TreeSelect';
import Date from './Date';
import DateRange from './DateRange';
import Slider from './Slider';
import QuickDateRange from './QuickDateRange';
import { ControlTypes, ControlQueryMode } from '../constants';
import { Form } from 'antd';
import { transformOptions } from '../util';
import { getControlStyle, getIsOldStyle, getControlMarginStyle } from '../Config/util';
const FormItem = Form.Item;

export class Control extends PureComponent {
  static defaultProps = {
    size: 'default',
  };

  renderControl = (control) => {
    const { currentOptions, value, size, onSelectSearch, isPageHeader } = this.props;
    let component;

    switch (control.type) {
      case ControlTypes.InputText:
        component = <InputText control={control} isPageHeader={isPageHeader} value={value} size={size} onChange={this.inputChange} onSearch={this.search} />;
        break;
      case ControlTypes.NumberRange:
        component = <NumberRange value={value} size={size} onChange={this.change} onSearch={this.search} />;
        break;
      case ControlTypes.Slider:
        component = <Slider control={control} value={value} onChange={this.change} />;
        break;
      case ControlTypes.Select:
        component = (
          <Select
            isPageHeader={isPageHeader}
            control={control}
            value={value}
            size={size}
            onChange={this.change}
            options={transformOptions(control, currentOptions)}
            onSearch={this.selectSearch}
            onSelectSearch={onSelectSearch}
          />
        );
        break;
      case ControlTypes.Radio:
        component = <Radio control={control} value={value} size={size} onChange={this.radioChange} options={transformOptions(control, currentOptions || [])} />;
        break;
      case ControlTypes.TreeSelect:
        component = <TreeSelect control={control} value={value} size={size} onChange={this.change} options={transformOptions(control, currentOptions || [])} />;
        break;
      case ControlTypes.Date:
        component = <Date isPageHeader={isPageHeader} control={control} value={value} size={size} onChange={this.change} />;
        break;
      case ControlTypes.DateRange:
        component = <DateRange isPageHeader={isPageHeader} control={control} value={value} size={size} onChange={this.change} />;
        break;
      case ControlTypes.QuickDateRange:
        component = <QuickDateRange isPageHeader={isPageHeader} control={control} value={value} size={size} onChange={this.change} />;
    }
    return this.wrapFormItem(control, component);
  };

  wrapFormItem = (control, component) => {
    const { size } = this.props;
    const itemClassNames = classnames({
      controlItem: true,
      small: size === 'small',
    });
    const controlStyle = getControlStyle(control);
    const marginStyle = getControlMarginStyle(control);
    const useOldStyle = getIsOldStyle(control);
    return (
      <div className="controlItem" style={{ ...controlStyle, ...marginStyle }}>
        <div className={`${useOldStyle ? 'controlOldItemName' : 'controlItemName'}`}>{control.name}</div>
        <div className="controlItemComponent">{component}</div>
      </div>
    );
  };

  selectSearch = (val) => {
    const { control, onSelectSearch } = this.props;
    onSelectSearch(control, val);
  };

  change = (val) => {
    const { control, onChange } = this.props;
    // if (control.type === 'dateRange') {
    //   console.log(val);
    //   if (val && val.findIndex((i) => !i) > -1) {
    //     return;
    //   }
    // }
    onChange(control, val);
  };

  inputChange = (e) => {
    const { control, onChange } = this.props;
    onChange(control, e.target.value.trim());
  };

  radioChange = (e) => {
    const { control, onChange } = this.props;
    onChange(control, e.target.value);
  };

  search = (val) => {
    const { queryMode, control, onSearch } = this.props;
    if (queryMode === ControlQueryMode.Immediately) {
      onSearch({ [control.key]: val });
    } else {
      onSearch();
    }
  };

  render() {
    const { control } = this.props;
    return <Suspense fallback={null}>{this.renderControl(control)}</Suspense>;
  }
}

export default Control;
