import React, { Component, Fragment } from 'react';
import CommonTable from '../CommonTable/index';
import { Modal, Popconfirm } from 'antd';
import tableUtil from '../../utils/tableUtil';
import util from '../../util';
import api from '../../api';
import { observer } from 'mobx-react';
import { observable, action, autorun, toJS } from 'mobx';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import Loading from '../../component/Loading/Loading';
import { message, Button } from 'antd';
import { iconfont } from '../../config';
import moment from 'moment';
import AnalyseBoardUtil from './util';
import AuthorityStore from '../../AuthorityStore/store';
import TableNullData from '../../component/TableNullData/index';
import SearchHeader from '../../component/SearchHeader';
import intl from 'react-intl-universal';
import pageUtil from '../../utils/pageUtil';
import { surveyTools } from '../../utils/analyseUtil';
import { Link } from 'react-router-dom';
@observer
class AnalyseBoardTable extends Component {
  @observable data;
  @observable isLoading = true;
  @observable keyword = '';
  componentDidMount() {
    this.disposer = autorun(() => {
      let keyword = this.keyword;
      // 判断权限是否为所有权限 有则发送请求 否则判断props的anlayseType是否为all 若是则isloading为false 否则检查权限当中是否存在view的权限 若有则发送请求 否则isLoading为false
      AuthorityStore.authorityMenu.hasOwnProperty('all')
        ? this.getData()
        : this.props.analyseType === 'all'
        ? (this.isLoading = false)
        : AuthorityStore.checkAuthorityExist('analytics', this.props.analyseType, 'view')
        ? this.getData()
        : (this.isLoading = false);
    });
  }
  componentWillUnmount() {
    this.disposer();
  }

  @observable modalInfo = { isShowModal: false, data: {} };

  @action.bound
  showModal(data, record) {
    if (!Number(record.dashboardNum)) {
      return;
    }
    this.modalInfo = {
      isShowModal: true,
      data: toJS(data.dashboard),
    };
  }
  @action.bound
  hideModal() {
    this.modalInfo = {
      isShowModal: false,
      data: [],
    };
  }

  @action.bound getData(orderDesc, orderColumn, currentPage = 1, pageCount = 10) {
    let param = {
      idsite: pageHeaderStore.parameter.idsite,
      pageCount,
      currentPage,
    };
    if (this.props.type) {
      param['type'] = this.props.type;
    }
    this.isLoading = true;
    util.get(
      api.analysis.list,
      {
        ...param,
        search: this.keyword,
      },
      (res) => {
        this.data = res;
        this.isLoading = false;
      }
    );
  }
  @action.bound onSearch(value) {
    this.keyword = value;
  }

  deleteData(data) {
    this.isLoading = true;
    const dashboard = data.dashboard;
    const list = [];
    dashboard.map((item) => list.push(item.id));
    console.log(list);
    util.post(
      `${api.analysis.delete}?id=${data.id}`,
      list,
      () => {
        message.success('删除成功');
        this.getData();
      },
      true
    );
  }

  dashBoardDetail = (dashboard, type) => {
    const getStyle = (type, index) => {
      if (type === 'showModal') {
        return { cursor: 'pointer', marginLeft: index ? '5px' : '0' };
      } else {
        return { marginLeft: index ? '5px' : '0' };
      }
    };
    return dashboard.map((item, index) => {
      return (
        <Fragment>
          {type === 'showModal' && (
            <Link
              key={index}
              style={getStyle(type, index)}
              to={`/customDetail/${item.id}?boardName=${item.name}&productName=${pageHeaderStore.parameter.name}`}>
              {item.name}
              {index !== 1 && index !== this.modalInfo.data.length - 1 && <span>;</span>}
            </Link>
          )}
          {type === 'deleteModal' && (
            <div key={index} style={getStyle(type, index)}>
              {item.name}
              {index !== 1 && index !== this.modalInfo.data.length - 1 && <span>;</span>}
            </div>
          )}
        </Fragment>
      );
    });
  };

  deleteModal = (item) => {
    const dashboard = toJS(item.dashboard);
    if (dashboard.length) {
      return (
        <Fragment>
          <div>删除提醒</div>
          <div>卡片已被以下看板引用，删除卡片后，同时会删除看板的引用。</div>
          <div style={{ fontWeight: 'bold', marginTop: '20px' }}>引用的看板:</div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>{this.dashBoardDetail(dashboard, 'deleteModal')}</div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div>你确定要删除这行内容吗?</div>
        <div>删除后，系统将无法恢复该内容</div>
      </Fragment>
    );
  };

  //下载调研数据
  downloadSurvey = (data) => {
    util.get(
      api.analysis.info,
      {
        ids: data.id,
      },
      (res) => {
        try {
          const customizeParam = JSON.parse(res[0].customizeParam);
          const pageContent = JSON.parse(res[0].pageContent);
          const config = {
            id: res[0].id,
            chartType: res[0].chartType,
            topicType: surveyTools.changeTargetNameToTopicType(JSON.parse(res[0].ruleContent)[0].targetName),
            isNeedTotal: customizeParam.isNeedTotal,
            ...customizeParam,
            isNeedSamples: customizeParam.isNeedSamples || customizeParam.isNeedSample,
            isNeedSamplesRatio: customizeParam.isNeedSamplesRatio || customizeParam.isNeedSampleRatio,
            dateObj: customizeParam.dateObj
              ? customizeParam.dateObj
              : {
                  lastDate: 'last7',
                  date: `${moment()
                    .subtract(8, 'days')
                    .format('YYYY-MM-DD')},${moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')}`,
                },
            remarks: res[0].remarks,
            name: res[0].name,
          };
          const form = {
            count: 0,
            surveySelectGroup: JSON.parse(res[0].ruleContent),
            subDividedDimGroup: res[0].dims ? (res[0].dims === 'null' ? [''] : res[0].dims.split(',')) : [''],
            pageCount: res[0].pageContent ? pageContent.pageCount : 10,
            internalPageCount: res[0].pageContent ? pageContent.internalPageCount : 10,
            orderDesc: res[0].pageContent ? pageContent.orderDesc : 'default',
            orderDescIn: res[0].pageContent && pageContent.orderDescIn ? pageContent.orderDescIn : 'default',
            surveyAnalyseType: 'surveyAnalyse',
            eventAndTargetGroup: [
              {
                dim: 'survey',
                type: 'survey',
                dimValue: '',
                dimValueName: '',
                target: '',
                targetName: '',
              },
            ],
          };
          const newParam = pageUtil.getSurveyParam(form, config, data.chartType);
          util.download(api.analysis.mixAnalysisExportExcel, newParam, `${data.name}_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
        } catch (error) {
          console.log(error);
        }
      }
    );
  };
  addContent = (store) => {
    const config = [
      {
        currentRowData: 'dashboardNum',
        children: (store, text, record, index) => (
          <div style={{ textAlign: 'right' }}>
            {Number(record.dashboardNum) ? (
              <a
                onClick={() => {
                  this.showModal(store.parentData[index], record);
                }}>
                {text}
              </a>
            ) : (
              text
            )}
          </div>
        ),
      },
      {
        currentRowData: 'operate',
        children: (store, text, record, index) => (
          <div className="operateGroup">
            {this.props.analyseType === 'surveyAnalytics' && (
              <span
                className="operateDom-btn"
                title={intl.get('Download')}
                onClick={() => {
                  this.downloadSurvey(store.parentData[index]);
                }}>
                {intl.get('Download')}
                {/* 下载 */}
              </span>
            )}
            <Popconfirm
              title={this.deleteModal(store.parentData[index])}
              onConfirm={() => {
                this.deleteData(store.parentData[index]);
              }}
              okButtonProps={{ type: 'primary' }}
              cancelButtonProps={{ type: 'cancel' }}
              okText="确认"
              cancelText="取消">
              <span className="operateDom-delete" title={intl.get('Delete')}>
                {intl.get('Delete')}
                {/* 删除 */}
              </span>
            </Popconfirm>
            <Link
              className="operateDom-update"
              title={intl.get('Edit')}
              to={`/analytics/${AnalyseBoardUtil.typeToHref(store.parentData[index].type)}/detail?id=${store.parentData[index].id}&eventName=${
                store.parentData[index].name
              }`}>
              {intl.get('Edit')}
            </Link>
          </div>
        ),
      },
    ];
    return tableUtil.addContentByConfig(store, config);
  };
  render() {
    return (
      <Fragment>
        <SearchHeader
          hideButton={!this.props.noTab}
          onClick={this.props.showFullScreenLayout}
          onSearch={this.onSearch}
          title={`+ ${intl.get('BtnCreate')}${this.props.title && (intl.get(this.props.title) || this.props.title)}`}
        />
        {!this.isLoading ? (
          AuthorityStore.authorityMenu.hasOwnProperty('all') ||
          (this.props.analyseType !== 'all' && AuthorityStore.checkAuthorityExist('analytics', this.props.analyseType, 'view')) ? (
            <CommonTable
              addRowSelect={this.props.addRowSelect}
              rowSelection={this.props.rowSelection}
              fetchData={this.getData}
              addContent={this.addContent}
              fields={'name,dashboardNum,updatedAt,updatedByName,operate'}
              parentData={this.data}
              isSort={false}></CommonTable>
          ) : (
            <TableNullData nullDataType="authority"></TableNullData>
          )
        ) : (
          <Loading />
        )}
        {this.modalInfo.isShowModal ? (
          <Modal
            title="引用的看板"
            visible={this.modalInfo.isShowModal}
            onOk={this.hideModal}
            okText={'知道了'}
            onCancel={this.hideModal}
            destroyOnClose={true}
            mask={false}
            footer={[
              <Button type="primary" onClick={this.hideModal}>
                知道了
              </Button>,
            ]}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{this.dashBoardDetail(this.modalInfo.data, 'showModal')}</div>
          </Modal>
        ) : null}
      </Fragment>
    );
  }
}
export default AnalyseBoardTable;
