import CommonBreadCrumb from '../../containers/CommonBreadCrumb';
import VisitorInfoPage from '../../containers/VisitorInfoPage';

export default {
  title: '访客档案',
  components: [
    {
      component: CommonBreadCrumb,
      props: {
        breadCrumbName: '访客列表',
        breadCrumbSecName: 'visitorId',
        className: 'top-bread-crumb',
      },
    },
    {
      component: VisitorInfoPage,
    },
  ],
};
