import React, { Component } from 'react';
import AnalyseBoardTable from '../AnalyseBoardTable/index';
import AnalyseBITable from '../AnalyseBITable';
import { Card } from '../../component/Card/Card';
import { observer } from 'mobx-react';
import AnalyseBoardUtil from '../../containers/AnalyseBoardTable/util';
@observer
export default class AnalyseTableLayout extends Component {
  showFullScreenLayout = () => {
    if (this.props.type) this.props.history.push({ pathname: `/analytics/${AnalyseBoardUtil.typeToHref(this.props.type)}/detail` });
    return;
  };
  render() {
    return (
      <Card
        card={{
          title: this.props.title,
          content: (
            <React.Fragment>
              {this.props.type !== 9 ?
                <AnalyseBoardTable showFullScreenLayout={this.showFullScreenLayout} noTab={true} {...this.props}></AnalyseBoardTable> :
                <AnalyseBITable showFullScreenLayout={this.showFullScreenLayout} noTab={true} {...this.props}></AnalyseBITable>
              }
            </React.Fragment>
          ),
        }}></Card>
    );
  }
}
