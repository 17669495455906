import React from 'react';
import Scrollbar from 'react-scrollbar';
import './index.sass';

class Picker extends React.Component {
  render() {
    let { children, ...props } = this.props;
    return (
      <Scrollbar
        className={'uxs-scrollbar' + (this.props.className || '')}
        speed={this.props.speed}
        vertical
        horizontal
        smoothScrolling={true}
        stopScrollPropagation={this.props.stopScrollPropagation}
        style={this.props.style}
        {...props}>
        {children}
      </Scrollbar>
    );
  }
}

Picker.defaultProps = {
  speed: 0.8,
  stopScrollPropagation: true,
  style: { height: '100%', width: '100%' },
};

export default Picker;
