import React, { useEffect, useState, Fragment } from 'react';
import { Form, Select, Input, Button, Radio } from 'antd';
import intl from 'react-intl-universal';
import util from '../../../util';
import api from '../../../api';
import UXSDrawer from '../../../component/UXSDrawer';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import { ModelTagRule } from './FormComponent';
import Loading from '../../../component/Loading/Loading';

const { Option } = Select;
function Main(props) {
  const { show, onHide, form, editInfo, fetchData } = props;
  const { editId } = editInfo;
  const { getFieldDecorator } = form;
  const [data, setData] = useState(null); // 详情数据
  const [loading, setLoading] = useState(true);
  const [tagOptions, setTagOptions] = useState(null); // 模型属性列表

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };

  useEffect(() => {
    if (editId) {
      util.get(api.dataTag.info, { id: editId }, (res) => {
        if (res) {
          res.ruleContent = JSON.parse(res.ruleContent);
          setData(res);
          setLoading(false);
        }
      });
    } else {
      setData({ ruleContent: [{ column: '' }] });
    }
  }, []);

  useEffect(() => {
    util.get(api.dataTag.tagOptions, { optionType: 'user' }, (res) => {
      setTagOptions(res);
    });
  }, []);

  function submit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const param = {
          ...values,
          ruleContent: JSON.stringify(values.ruleContent),
        };
        editId && (param.id = editId);
        util.post(api.dataTag.saveOrUpdate, param, (res) => {
          onHide();
          fetchData();
        });
      }
    });
  }

  function checkForm(rule, value, callback, msg) {
    for (const key in value) {
      if (!value[key]) {
        return callback(msg);
      }
    }
    callback();
  }

  return (
    <UXSDrawer
      className="full-right scroll-drawer"
      title={
        <div>
          <div>预警用户组</div>
          <Button type="primary" onClick={submit}>
            {intl.get('Save')}
          </Button>
        </div>
      }
      show={show}
      onClose={onHide}
      width={800}>
      {data && tagOptions ? (
        <Form {...formItemLayout} onSubmit={submit}>
          <Form.Item label="名称：">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请输入名称' }],
              initialValue: data.name || null,
            })(<Input placeholder="请输入" className="md-input" />)}
          </Form.Item>
          <Form.Item label={intl.get('WhenIndicators')} className="typeItem">
            {getFieldDecorator('ruleContent', {
              initialValue: data.ruleContent || null,
            })(<ModelTagRule tagOptions={tagOptions} ruleContent={data.ruleContent} />)}
          </Form.Item>
          <Form.Item label="更新方式：">
            {getFieldDecorator('updateContent', {
              initialValue: data.updateContent || 0,
            })(
              <Radio.Group name="radiogroup">
                <Radio value={'1'}>自动更新</Radio>
                <Radio value={'0'}>手动更新</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </UXSDrawer>
  );
}

export default Form.create()(Main);
