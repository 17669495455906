import React, { Component } from 'react';

class MobileArrows extends Component {
  constructor(props) {
    super(props);
  }
  getCurrentStatus = () => {
    const { total, current, step } = this.props;
    return {
      leftStatus: current - step > 0,
      rightStatus: total - current > 0,
    };
  };
  renderIcon = () => {
    const { iconType, iconStyle, iconLeftStyle, iconRightStyle, onClickLeft, onClickRight, withPage, pageText } = this.props;
    let leftIcon, rightIcon;
    switch (iconType) {
      case 1:
        leftIcon = 'icon-caret-right-copy';
        rightIcon = 'icon-caret-right';
        break;
      case 2:
        leftIcon = 'icon-fenye-fanye';
        rightIcon = 'icon-paging';
        break;
    }
    const { leftStatus, rightStatus } = this.getCurrentStatus();
    return (
      <div style={{ display: 'flex' }}>
        <i
          onClick={() => {
            if (!leftStatus) return;
            onClickLeft();
          }}
          className={`iconfont ${leftIcon} ${leftStatus ? 'iconHover' : ''}`}
          style={{ color: leftStatus ? 'black' : 'grey', fontSize: '12px', marginRight: '13px', ...iconStyle, ...iconLeftStyle }}></i>
        <div style={{ fontSize: '14px' }}>{withPage && pageText}</div>
        <i
          onClick={() => {
            if (!rightStatus) return;
            onClickRight();
          }}
          className={`iconfont ${rightIcon} ${rightStatus ? 'iconHover' : ''}`}
          style={{ color: rightStatus ? 'black' : 'grey', fontSize: '12px', marginLeft: '3px', ...iconStyle, ...iconRightStyle }}></i>
      </div>
    );
  };
  render() {
    return this.renderIcon();
  }
}

export default MobileArrows;
