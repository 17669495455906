import React, { Component, Fragment } from 'react';
import { Button, Dropdown, Icon, Menu, Switch, Badge } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { createForm } from 'rc-form';
import { withRouter } from 'react-router-dom';
import UXSRangePicker from './../UXSRangePicker/index';
import './PageHeader.sass';
import store from './PageHeaderStore';
import Warning from './Warning';
import GlobalControl from './GlobalControl';
import MobileCalandar from '../../component/MobileCalandar/index';
import MobilePicker from '../MobilePicker';
import intl from 'react-intl-universal';
import sidebarStore from '../../component/Sidebar/store';
import BoardStore from '../../views/BoardCustom/BoardChart/BoardStore';
import ShareModelEnhance from '../../component/shareModelEnhance';
import util from '../../util';
import GlobalControlModal from './GlobalControl/Modal';
import LinkageModal from './LinkageModal';
import LayoutModal from './LayoutSetting';
import Wrapper from './Control/Config/Wrapper';

moment.locale('zh-cn');

@withRouter
@observer
class PageHeader extends Component {
  constructor() {
    super();
    this.rangePicker = React.createRef();
  }
  state = {
    ShareModalVisible: false,
    showLinkageModal: false,
    isCustomDetail: false,
  };
  componentDidMount() {
    if (typeof MouseEvent !== 'function') {
      (function() {
        window.MouseEvent = function(type, dict) {
          dict = dict | {};
          var event = document.createEvent('MouseEvents');
          event.initMouseEvent(
            type,
            typeof dict.bubbles === 'undefined' ? true : !!dict.bubbles,
            typeof dict.cancelable === 'undefined' ? false : !!dict.cancelable,
            dict.view || window,
            dict.detail | 0,
            dict.screenX | 0,
            dict.screenY | 0,
            dict.clientX | 0,
            dict.clientY | 0,
            !!dict.ctrlKey,
            !!dict.altKey,
            !!dict.shiftKey,
            !!dict.metaKey,
            dict.button | 0,
            dict.relatedTarget || null
          );
          return event;
        };
      })();
    }
    this.setRangePickerStyle(store.parameter.lastDate);
    this.getIsCustomDetail();
    store.setPagePadding();
  }

  componentDidUpdate(preProps) {
    if (this.rangePicker.current) {
      this.setRangePickerStyle(store.parameter.lastDate);
    }
    if (this.props.location.pathname !== preProps.location.pathname) {
      this.getIsCustomDetail();
    }
  }

  setRangePickerStyle = (lastDate) => {
    const width = 128;
    this.rangePicker.current &&
      (this.rangePicker.current.getElementsByClassName('ant-calendar-picker-input')[0].style.width = width > 180 ? `${width}px` : '180px');
    if (lastDate === 'startToToday') {
      this.rangePicker.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.justifyContent = 'right';
      this.rangePicker.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.paddingRight = '5px';
    } else if (lastDate === null) {
      this.rangePicker.current.getElementsByClassName('ant-calendar-range-picker-input')[0].style.visibility = 'visible';
      this.rangePicker.current.getElementsByClassName('ant-calendar-picker-input')[0].style.width = `${width + 150}px`;
    } else {
      this.rangePicker.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.justifyContent = 'center';
      this.rangePicker.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.paddingRight = '0';
      this.rangePicker.current.getElementsByClassName('ant-calendar-range-picker-separator')[0].style.left = '20px';
    }
  };

  getIsCustomDetail = () => {
    const pathName = this.props.location.pathname;
    const pathNameWithOutParam = pathName.substr(0, pathName.indexOf('?') > '-1' ? pathName.indexOf('?') : pathName.length);
    let isCustomDetail = pathNameWithOutParam.includes('/customDetail/');
    this.setState({
      isCustomDetail,
    });
  };

  // 显示隐藏分享面板
  showShareModel = (store, index) => {
    this.setState({ ShareModalVisible: true });
  };
  // 分享面板取消
  shareOnCancel = () => {
    this.setState({ ShareModalVisible: false });
  };

  // 联动配置
  toggleLinkageModal = (bool) => {
    this.setState({
      showLinkageModal: bool,
    });
  };

  render() {
    // 获取工具栏显示项目数
    this.toolList = [store.isShowDownLoadButton, store.isShowReportButton, store.exportButton.isShow, store.isShowWarningButton];
    this.time = {
      hour: 'AccordingHours',
      day: 'AccordingDays',
      week: 'AccordingWeeks',
      month: 'AccordingMonths',
      default: 'CardBuiltin',
    };
    this.time = {
      hour: 'AccordingHours',
      day: 'AccordingDays',
      week: 'AccordingWeeks',
      month: 'AccordingMonths',
      quarter: 'AccordingQuarters',
      year: 'AccordingYears',
      default: 'CardBuiltin',
    };
    this.mobileTime = [
      { value: 'hour', label: '按时' },
      { value: 'day', label: '按天' },
      { value: 'week', label: '按周' },
      { value: 'month', label: '按月' },
      { value: 'quarter', label: '按季' },
      { value: 'year', label: '按年' },
      { value: 'default', label: '卡片内置周期' },
      // { value: 'year', label: '年报' },
    ];
    const oprateMenus = () => {
      return (
        <div className="board-control">
          {store.authorityObject.edit && [
            // <div className="board-control-item" onClick={() => store.cardCombineModalVisibleToggle(true)}>
            //   <i className="iconfont icon-peizhi board-control-item-icon" />
            //   {intl.get('CombineCard')}
            // </div>,
            <div
              className="board-control-item"
              onClick={() => {
                store.setAddBoardCard(true, 'addChart');
              }}>
              <i className="iconfont icon-tigaokeyongxing board-control-item-icon" />
              {intl.get('AddData')}
            </div>,
            <div
              className="board-control-item"
              onClick={() => {
                store.setAddBoardCard(true, 'addDesc');
              }}>
              <i className="iconfont icon-bianjimingcheng board-control-item-icon" />
              {intl.get('AddDesc')}
            </div>,
          ]}
          {/* 无权限的不显示 */}
          {store.authorityObject.share && (
            <div className="board-control-item" onClick={this.showShareModel}>
              <i className="iconfont icon-fenxiang1 board-control-item-icon" />
              {intl.get('ShareDashboard')}
            </div>
          )}
          {[
            store.authorityObject.edit && [
              util.showNewContorl() && (
                <div
                  className="board-control-item"
                  onClick={() => {
                    store.showNewGlobalControlModal(true);
                  }}>
                  <i className="iconfont icon-shaixuan board-control-item-icon" />
                  {intl.get('GlobalControl')}
                </div>
              ),
              !util.showNewContorl() && (
                <div
                  className="board-control-item"
                  onClick={() => {
                    store.showGlobalControlModal(true);
                  }}>
                  <i className="iconfont icon-shaixuan board-control-item-icon" />
                  {intl.get('GlobalControl')}
                </div>
              ),
              <div
                className="board-control-item"
                onClick={() => {
                  this.toggleLinkageModal(true);
                }}>
                <i className="iconfont icon-liandong board-control-item-icon" />
                {intl.get('Linkage')}
              </div>,
            ],
          ]}
          {/* {process.env.REACT_APP_BDkey !== 'sams' && [
            <div
              className="board-control-item"
              onClick={() => {
                store.exportDashboard('pdf');
              }}>
              <i className="iconfont icon-xiazai1 board-control-item-icon" />
              {intl.get('ExportPDF')}
            </div>,
            <div
              className="board-control-item"
              onClick={() => {
                store.exportDashboard('png');
              }}>
              <i className="iconfont icon-xiazai1 board-control-item-icon" />
              {intl.get('ExportPNG')}
            </div>,
          ]} */}
        </div>
      );
    };
    const { showLinkageModal, isCustomDetail } = this.state;
    return (
      <div>
        {store.dropdownList.product && (
          <div
            className={`page-header-wrapper ${sidebarStore.isCollapsed ? 'collapsed' : 'expanding'}`}
            style={{ display: store.isPageHeaderShow ? '' : 'none' }}>
            <div className={`page-header`}>
              <div className="filters">
                <div className="filters-wrapper clearfix">
                  <div className="mobile-board-title">
                    {!(util.isMobile() && util.showNewMobileStyle()) && isCustomDetail && <span>{store.currentBoardName}</span>}
                  </div>
                  {/* 移动端显示 */}
                  <div className="pageHeader-mobilePicker">
                    {!(util.isMobile() && util.showNewMobileStyle()) && (
                      <Badge count={store.globalControlFilterCount} className="pageHeader-mobilePicker-badge">
                        <div className="mobile-right-pageHeader-icon">
                          {/* {util.isMobile() && util.showNewMobileStyle() && <i className="iconfont icon-shaixuan" onClick={store.mobileQuickFilterToggle} />} */}
                          {!(util.isMobile() && util.showNewMobileStyle()) && (
                            <i
                              className={`iconfont ${store.isShowMobileQuickFilter ? 'icon-basic-shouhui1' : 'icon-shaixuan'}`}
                              onClick={store.mobileQuickFilterToggle}
                            />
                          )}
                        </div>
                      </Badge>
                    )}
                  </div>
                  {!store.isFoldPicker && (
                    <div className="pageHeader-mobileCalandar">
                      <MobileCalandar
                        type="datetime"
                        value={[moment(store.parameter.date.split(',')[0]), moment(store.parameter.date.split(',')[1])]}
                        dateChange={(lastDate, dateArr) => store.changeDate(dateArr.join(','), null)}
                      />
                      <MobilePicker
                        data={this.mobileTime}
                        iconName="icon-zhouqixuanze"
                        value={[store.parameter.period || 'default']}
                        onOk={store.changePeriod}
                        style={{ height: 32, width: 32 }}
                        justifyContent={'center'}
                      />
                    </div>
                  )}
                  {!store.isFoldPicker && (
                    <Fragment>
                      <div ref={this.rangePicker}>
                        <UXSRangePicker
                          dropdownClassName="pageheader-select"
                          useDateByStore={true}
                          lastDate={store.parameter.lastDate}
                          date={store.parameter.date}
                          isBoardPicker={store.isFoldPicker}
                          title={intl.get('Date')}
                        />
                      </div>
                      <div className="time-span-select">
                        <Dropdown
                          className="dropdown"
                          id="time-select-dropdown"
                          overlayClassName="dropdown-menu time-dropdown-menu pageheader-select"
                          trigger={['click']}
                          overlay={
                            <Menu onClick={(e) => store.changePeriod(e.key)}>
                              {Object.keys(this.time).map((item) => {
                                return (
                                  <Menu.Item title={intl.get(this.time[item])} key={item}>
                                    {intl.get(this.time[item])}
                                  </Menu.Item>
                                );
                              })}
                            </Menu>
                          }>
                          <Button title={store.parameter.period}>
                            {store.parameter.period ? intl.get(this.time[store.parameter.period]) : intl.get('CardBuiltin')}
                            <Icon type="down" className="down" />
                          </Button>
                        </Dropdown>
                      </div>
                    </Fragment>
                  )}
                  <div className="board-top" style={{ opacity: !BoardStore.isFullScreen ? 1 : 0 }}>
                    {store.isFoldPicker && !BoardStore.isFullScreen ? (
                      <div style={{ display: 'flex' }}>
                        <div className="board-title">
                          <span>{store.currentBoardName}</span>
                        </div>
                        <div className="icon-right">
                          {store.boardGuide.isShowGuide && (
                            <div
                              className="pointer"
                              onClick={() => {
                                store.showTemplateGuide();
                              }}>
                              <i className="iconfont icon-xinshouyindao" />
                              <span>新手引导</span>
                            </div>
                          )}
                          {/* {store.isShowSurvey && (
                                        [<Button onClick={store.newSurvey}>发布调研</Button>, <Button onClick={store.showAnalyseModal}>分析报告</Button>,]
                                      )} */}
                        </div>
                      </div>
                    ) : null}

                    {store.isFoldPicker && (
                      <div style={{ display: 'flex' }}>
                        <div
                          className="board-control-item"
                          onClick={() => {
                            store.setBoardChartFullScreen(true);
                          }}>
                          <i className="iconfont icon-quanping board-control-item-icon" />
                          {intl.get('FullScreen')}
                        </div>
                        <div className="board-control-item board-control-description">
                          <i className="iconfont icon-tishiicon board-control-item-icon" />
                          {intl.get('Description')} <Switch checked={store.isShowDesc} onChange={store.setShowDesc} size="small" />
                        </div>
                        {store.isBoardEdit && oprateMenus()}
                        <Button
                          style={{ marginTop: -5, height: '32px', fontSize: '12px' }}
                          type="primary"
                          onClick={() => {
                            store.toggleBoardEdit(!store.isBoardEdit);
                          }}>
                          {store.isBoardEdit ? '退出编辑' : '编辑'}
                        </Button>
                      </div>
                    )}
                  </div>
                  {/* 旧版控制器 */}
                  {store.isFoldPicker && (util.isMobile() || !util.showNewContorl()) && (
                    <GlobalControl
                      commonFilterObject={store.commonFilterObject}
                      setCommonFilter={store.setCommonFilter}
                      setCommonFilterValue={store.setCommonFilterValue}
                      lang={this.props.lang}
                    />
                  )}
                  {/* 新版控制器 */}
                  {store.isFoldPicker && util.showNewContorl() && !util.isMobile() && <Wrapper type="GlobalControlPanel" />}
                </div>
              </div>
              {/* {store.isFoldPicker && [2, 3].indexOf(store.currentBoardType) > -1 && (
                <div className="pageheader-toolbar">
                  {BoardStore.isFullScreen ? (
                    <div
                      className="pageheader-tool"
                      onClick={() => {
                        BoardStore.resetFullScreen('close');
                      }}>
                      <i className="iconfont icon-tuichuquanping" />
                      {`${intl.get('Exit')}${intl.get('FullScreen')}`}
                    </div>
                  ) : (
                    <div
                      className="pageheader-tool"
                      onClick={() => {
                        store.setBoardChartFullScreen(true);
                      }}>
                      <i className="iconfont icon-quanping" />
                      {intl.get('FullScreen')}
                    </div>
                  )} */}
              {/* 分享 */}
              {/* {store.authorityObject.share && (
                    <div className="pageheader-tool" onClick={this.showShareModel}>
                      <i className="iconfont icon-fenxiang1" />
                      {intl.get('Share')}
                    </div>
                  )}
                  {store.authorityObject.edit &&
                    AuthorityStore.checkAuthorityExist(
                      differentPermissions[store.currentBoardType][0],
                      differentPermissions[store.currentBoardType][1],
                      'update'
                    ) && (
                      <Fragment>
                        <div
                          className="pageheader-tool"
                          onClick={() => {
                            store.showLayoutModal(true);
                          }}>
                          <i className="iconfont icon-guanlikanban" />
                          {intl.get('Layout')}
                        </div>
                        <div className="pageheader-tool">
                          <Link rel="noopener noreferrer" to={`/Diagram/picture?currentBoardId=${store.currentBoardId}`}>
                            <i className="iconfont icon-xinjiantubiao" />
                            {intl.get('Relationship')}
                          </Link>
                        </div>
                        <div className="pageheader-tool">
                          <Link rel="noopener noreferrer" to={`/emotion/picture?currentBoardId=${store.currentBoardId}`}>
                            <i className="iconfont icon-xiaolian" />
                            {intl.get('EmotionMap')}
                          </Link>
                        </div>
                        <div className="pageheader-tool">
                          <a className="AOperation">
                            <LineCard />
                          </a>
                        </div>
                        <div
                          className="pageheader-tool"
                          onClick={() => {
                            store.setAddBoardCard(true, 'addDesc');
                          }}>
                          <i className="iconfont icon-bianjimingcheng" />
                          {intl.get('AddDesc')}
                        </div>
                      </Fragment>
                    )}
                </div>
              )} */}
            </div>

            {/* {store.isShowReportButton && <ReportSend isShowNewModal={store.isShowReportModal} hideNewModal={store.hideReportModal} />} */}
            {/* {store.exportButton.isShow && (
              <Modal
                title="导出看板"
                className="animated exports-modal"
                destroyOnClose={true}
                width={600}
                height={250}
                visible={store.isExportShow}
                onCancel={store.hideExportShow}
                cancelText="取消"
                okText="确定"
                onOk={store.submitExport}>
                <div className="exports-content-wrapper">
                  <div>
                    <div className={'exports-content'}>
                      {store.exportButton.config.type && (
                        <div className="download">
                          <span>选择下载图表：</span>
                          <div>
                            {store.exportButton.config.type.map((item, index) => (
                              <Button
                                bsClass="uxs"
                                key={index}
                                onClick={() => store.getChartType(index + 1)}
                                className={store.exports.type === index + 1 ? 'active' : ''}>
                                {item}
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                      {store.exportButton.config.exportType && (
                        <div className="chart">
                          <span>选择导出图表：</span>
                          <div>
                            {store.exportButton.config.exportType.map((item, index) => (
                              <Button
                                key={index}
                                bsClass="uxs"
                                onClick={() => store.SelectExportType(item)}
                                className={item.isSelected ? 'active' : ''}
                                title={item.info.title}>
                                {item.info.title}
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                      {store.exportButton.config.api && (
                        <div className="download-button">
                          <span>导出CSV文件：</span>
                          <Button bsClass="uxs" onClick={() => store.exportFile(store.exportButton.config.api)}>
                            下载
                          </Button>
                        </div>
                      )}
                      <div className="board">
                        <span>导出到看板：</span>
                        <div>
                          {store.boardList &&
                            (store.boardList.length
                              ? store.boardList.map((item, index) => (
                                  <Button
                                    key={index}
                                    bsClass="uxs"
                                    onClick={() => store.selectExportButton(item)}
                                    className={item.isSelect ? 'active' : ''}
                                    title={item.name}>
                                    {item.name}
                                  </Button>
                                ))
                              : '无可导出的看板')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )} */}
            {store.isShowWarningModal && <Warning show={store.isShowWarningModal} onHide={store.hideWarningModal} onShow={store.showWarningModel} />}
            {store.isShowGlobalControlModal && !util.showNewContorl() && (
              <GlobalControlModal visible={store.isShowGlobalControlModal} onHide={() => store.showGlobalControlModal(false)} />
            )}
            {/* 新版控制器 */}
            {store.isShowNewGlobalControlModal && util.showNewContorl() && <Wrapper type="GlobalControl" />}
            {showLinkageModal && <LinkageModal visible={showLinkageModal} onHide={() => this.toggleLinkageModal(false)} />}
            {store.isShowLayoutModal && <LayoutModal visible={store.isShowLayoutModal} onHide={() => store.showLayoutModal(false)} />}
            <ShareModelEnhance type={1} visible={this.state.ShareModalVisible} shareOnCancel={this.shareOnCancel} haveCurrentBoardId={true} />
          </div>
        )}
      </div>
    );
  }
}

PageHeader = createForm()(PageHeader);
export { PageHeader };
