import { observer } from 'mobx-react';
import { createForm } from 'rc-form';
import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { message } from 'antd';
import 'react-select/dist/react-select.css';
import 'react-toastify/dist/ReactToastify.css';
import { Footer } from './component/Footer/Footer';
import NavbarTop from './component/NavbarTop/NavbarTop';
import MobileNavbarTop from './component/MobileNavbarTop';
import { PageHeader } from './component/PageHeader/PageHeader';
import PageHeaderStore from './component/PageHeader/PageHeaderStore';
import { Sidebar } from './component/Sidebar/Sidebar';
import { default as sidebarStore } from './component/Sidebar/store';
import './index.sass';
import './static/animate.css';
import './static/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
import BoardTemplate from './views/BoardTemplate';
import SidebarStore from './component/Sidebar/store';
import NotFound from './component/NotFound';
import AuthorityStore from './AuthorityStore/store';
import homeRouteConfig from './homeRouteConfig';
import { toJS } from 'mobx';
import util from './util';
import pageUtil from './utils/pageUtil';
import intl from 'react-intl-universal';
import MobileSidebar from './component/MobileSidebar';

const locales = {
  'en-US': require('./locales/en-US.json'),
  'zh-CN': require('./locales/zh-CN.json'),
};

@observer
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarCollapsed: false,
      sidebarAnimate: '',
      lang: '', //界面语言
    };
    this.handleCollapseSidebar = this.handleCollapseSidebar.bind(this);
    const type = util.getQueryString('type');
    const code = util.getQueryString('code');
    const auth_code = util.getQueryString('auth_code');
    const c2 = util.getQueryString('c2');
    const c3 = util.getQueryString('c3');
    // code && c2 && c3 为飞书登录跳转链接
    this.isLogin = (type && code) || (type && auth_code) || (code && c2 && c3);
  }
  componentDidMount() {
    const lang = (localStorage && localStorage.getItem('Q2VteXVuX2xhbmc=')) || 'zh-CN';
    this.changeLang(lang); // 初始化语言
    // 如果进来的时候不经过登录获取不了权限列表则重新初始化权限列表 有则不需要初始化直接获取产品列表
    !this.isLogin && (!AuthorityStore.authorityMenu ? AuthorityStore.init(true) : PageHeaderStore.init());
    // 实例一个拦截器
    window.controller = window.AbortController ? new window.AbortController() : null;
    message.config({
      rtl: true,
      top: 25,
      duration: 3,
      maxCount: 2,
    });
    const boardName = util.getQueryString('boardName');
    pageUtil.setDocumentTitle(boardName ? `${boardName}-洞察` : '');
  }
  handleCollapseSidebar() {
    this.setState({
      isSidebarCollapsed: !this.state.isSidebarCollapsed,
      sidebarAnimate: this.state.isSidebarCollapsed ? 'slideInLeftFrom70' : 'slideOutRightTo70',
    });
  }
  // 获取权限列表对应的className 对元素进行显示隐藏的控制
  getAuthorityClassName(pathName) {
    //默认的类名
    let defaultString =
      'userAuthority_show-delete userAuthority_show-edit userAuthority_show-share userAuthority_show-update userAuthority_show-add userAuthority_show-view';
    if (AuthorityStore.authorityMenu && pathName) {
      // 全部权限则返回默认的类名
      if (toJS(AuthorityStore.authorityMenu).hasOwnProperty('all')) {
        return defaultString;
      }
      let pathNameArr;
      // 根据url返回对应的权限数组
      if (pathName.includes('customBoard') || pathName.includes('customDetail')) {
        pathNameArr = ['', 'insight', 'dashboard'];
      } else {
        pathNameArr = pathName.split('/');
      }
      Object.keys(homeRouteConfig).every((modulekey, index) => {
        return homeRouteConfig[modulekey].every((item, index) => {
          if (item.path.includes(pathName)) {
            pathNameArr = ['', modulekey, item.key];
            return false;
          } else {
            return true;
          }
        });
      });
      // 找到最底层的权限 没找到则返回空对象
      let moduleAuthority = toJS(AuthorityStore.authorityMenu)[pathNameArr[1]]
        ? toJS(AuthorityStore.authorityMenu)[pathNameArr[1]].hasOwnProperty(pathNameArr[2])
          ? toJS(AuthorityStore.authorityMenu)[pathNameArr[1]][pathNameArr[2]]
          : {}
        : {};
      // 空对象返回默认权限
      if (JSON.stringify(moduleAuthority) === '{}') {
        return defaultString;
      } else {
        let string = '';
        // 遍历对象中的权限 拼接成类的字符串
        Object.keys(moduleAuthority).forEach((key, index) => {
          if (moduleAuthority[key]) {
            string = string.concat(`userAuthority_show-${key}`, ' ');
          }
        });

        return string;
      }
    } else {
      return '';
    }
  }
  // 设置界面语言
  changeLang = (value) => {
    this.setState({ lang: value });
    intl.init({
      currentLocale: value,
      locales,
      warningHandler: () => {},
    });
    localStorage && localStorage.setItem('Q2VteXVuX2xhbmc=', value); //Q2VteXVuX2xhbmc=为Cemyun_lang的base64加密
  };
  render() {
    const mainMargin = sidebarStore.isCollapsed ? '0 0 0 60px' : '0 0 0 160px';
    // 直接用PageHeaderStore.parameter.idsite不能自动
    let idsite = PageHeaderStore.parameter.idsite;
    const { isShowFooter } = PageHeaderStore;
    return (
      idsite && (
        // <ConfigProvider locale={this.state.lang === 'en-US' ? enUS : zhCN}>
        <Route
          render={({ location }) => {
            let currPath = location.pathname;
            return (
              <div id="wrapper" className={this.state.isSidebarCollapsed ? 'mini-sidebar' : ''}>
                {/* 增加两个不显示的输入框，避免浏览器自动给其他输入框回填内容 */}
                <input type="text" name="hidden1" className="stop-autocompelete" />
                <input type="password" name="hidden2" className="stop-autocompelete" />
                {util.isMobile() && util.showNewMobileStyle() ? (
                  <MobileNavbarTop />
                ) : (
                  <NavbarTop onCollapseSidebar={this.handleCollapseSidebar} changeLang={this.changeLang} lang={this.state.lang} />
                )}
                <div id="page-wrapper">
                  {idsite && idsite < 0 && !currPath.startsWith('/person') ? (
                    <Redirect to={{ pathname: '/product/create' }} />
                  ) : (
                    [
                      util.isMobile() && util.showNewMobileStyle() ? <MobileSidebar /> : <Sidebar key="sidebar" lang={this.state.lang} location={location} />,
                      <div className={SidebarStore.isCollapsed ? '' : 'mask'} key="mask" onClick={SidebarStore.collapsedChange}></div>,
                      <div id="main" style={{ margin: mainMargin }} key="main">
                        <PageHeader lang={this.state.lang} />
                        <div id="page-content" key={location.pathname} className={this.getAuthorityClassName(location.pathname)}>
                          {/* {permission ? ( */}
                          <Switch>
                            {Object.keys(homeRouteConfig).map((modulekey, index) => {
                              return homeRouteConfig[modulekey].map((item, index) => {
                                return (
                                  <Route
                                    exact
                                    location={location}
                                    path={item.path}
                                    render={(props) => {
                                      return (
                                        <Suspense fallback={null}>
                                          <item.component lang={this.state.lang} {...props} {...item.props} />
                                        </Suspense>
                                      );
                                    }}
                                  />
                                );
                              });
                            })}
                            <Route location={location} path="/data/:type" component={BoardTemplate} />
                            <Route location={location} path="/analytics/:type" component={BoardTemplate} />
                            <Route location={location} path="/audit" component={BoardTemplate} />
                            <Route location={location} path="/share" component={BoardTemplate} />

                            <Route exact location={location} path="/action/warningLog/detail" component={BoardTemplate} />
                            <Route exact location={location} path="*" component={NotFound} />
                          </Switch>
                          {/* ) : (
                          <NotFound />
                        )} */}
                        </div>
                        {isShowFooter && <Footer />}
                      </div>,
                    ]
                  )}
                </div>
              </div>
            );
          }}
        />
        // </ConfigProvider>
      )
    );
  }
}

export default createForm()(Home);
