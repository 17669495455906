const optionsFormulaList = [
  {
    value: '=',
    label: '等于',
  },
  {
    value: '!=',
    label: '不等于',
  },
  {
    value: 'contain',
    label: '包含',
  },
  {
    value: '!contain',
    label: '不包含',
  },
  {
    value: 'startsWith',
    label: '开始于',
  },
  {
    value: 'endsWith',
    label: '结束于',
  },
];
const numberFormulaList = [
  {
    value: '=',
    label: '等于',
  },
  {
    value: '<',
    label: '小于',
  },
  {
    value: '>',
    label: '大于',
  },
  {
    value: '<=',
    label: '小于等于',
  },
  {
    value: '>=',
    label: '大于等于',
  },
];
const stringFormulaList = [
  {
    value: '=',
    label: '等于',
  },
  {
    value: '!=',
    label: '不等于',
  },
  {
    value: 'contain',
    label: '包含',
  },
  {
    value: '!contain',
    label: '不包含',
  },
];
export const filterObject = {
  options1: [
    {
      value: 'done',
      label: '用户做过',
    },
    {
      value: 'fit',
      label: '用户符合',
    },
  ],
  options: [
    {
      value: 'visitor',
      label: '用户类型',
    },
    {
      value: 'event',
      label: '事件',
    },
    {
      value: 'channel_mix',
      label: '渠道',
      children: [
        {
          value: 'channel_link',
          label: '外部链接',
        },
        {
          value: 'channel_search',
          label: '搜索引擎',
        },
        {
          value: 'channel_keyword',
          label: '搜索词',
        },
        {
          value: 'channel_utm',
          label: '广告',
        },
        {
          value: 'channel_top',
          label: '一级访问来源',
        },
      ],
    },
    {
      value: 'page',
      label: '页面',
      children: [
        { value: 'page_visited', label: '受访页面' },
        // { value: 'page_entry', label: '入口页面' },
        // { value: 'exit', label: '退出页面' }
      ],
    },
    {
      value: 'visitor_behavior',
      label: '用户行为',
      children: [
        {
          value: 'pages',
          label: '访问深度',
        },
        {
          value: 'visits',
          label: '访问次数',
        },
        {
          value: 'visitTime',
          label: '访问时长',
        },
        {
          value: 'dayAvgVisits',
          label: '日均访问次数',
        },
      ],
    },
    {
      value: 'terminal',
      label: '终端详情',
      children: [
        {
          value: 'terminal_type',
          label: '设备类型',
        },
        {
          value: 'terminal_brand',
          label: '设备品牌',
        },
        {
          value: 'terminal_os',
          label: '操作系统',
        },
        {
          value: 'terminal_browser',
          label: '浏览器',
        },
        {
          value: 'terminal_resolution',
          label: '分辨率',
        },
      ],
    },
    {
      value: 'district',
      label: '地域',
      children: [
        {
          value: 'district_country',
          label: '国家',
        },
        {
          value: 'district_province',
          label: '省份',
        },
        {
          value: 'district_city',
          label: '城市',
        },
      ],
    },
    {
      value: 'funnel',
      label: '漏斗',
    },
  ],
  eventSelected: [
    {
      value: 'default',
      label: '预置事件',
      isLeaf: false,
    },
    {
      value: 'customize',
      label: '埋点事件',
      isLeaf: false,
    },
    {
      value: 'funnel',
      label: '漏斗',
      isLeaf: false,
    },
  ],
  childrenFilterOptions: [
    {
      value: 'channel_mix',
      label: '渠道',
      children: [
        {
          value: 'channel_link',
          label: '外部链接',
        },
        {
          value: 'channel_search',
          label: '搜索引擎',
        },
        {
          value: 'channel_keyword',
          label: '搜索词',
        },
        {
          value: 'channel_utm',
          label: '广告',
        },
        {
          value: 'channel_top',
          label: '一级访问来源',
        },
      ],
    },
    {
      value: 'terminal‘',
      label: '终端',
      children: [
        {
          value: 'terminal_type',
          label: '设备类型',
        },
        {
          value: 'terminal_brand',
          label: '设备品牌',
        },
        {
          value: 'terminal_os',
          label: '操作系统',
        },
        {
          value: 'terminal_browser',
          label: '浏览器',
        },
        {
          value: 'terminal_resolution',
          label: '分辨率',
        },
      ],
    },
    {
      value: 'district',
      label: '地域',
      children: [
        {
          value: 'district_country',
          label: '国家',
        },
        {
          value: 'district_province',
          label: '省份',
        },
        {
          value: 'district_city',
          label: '城市',
        },
      ],
    },
  ],
  menu: {
    numberFormulaList: [
      {
        value: '=',
        label: '等于',
      },
      {
        value: '<',
        label: '小于',
      },
      {
        value: '>',
        label: '大于',
      },
      {
        value: '<=',
        label: '小于等于',
      },
      {
        value: '>=',
        label: '大于等于',
      },
    ],
    // funnel: {
    //   type: 'autoComplete',
    //   menuList: [{ value: 1, label: '已转化的用户' }, { value: 2, label: '未转化的用户' }],
    // },

    done: {
      type: 'userHaveDone',
      menuList: [
        {
          value: 'collected',
          label: '收藏',
        },
        {
          value: 'focus',
          label: '关注',
        },
        {
          value: 'search',
          label: '浏览页面',
        },
      ],
      fieldsList: [
        {
          value: 'funnelConversion',
          label: '转化次数',
        },
        {
          value: 'funnelConversionTime',
          label: '转化时长',
        },
      ],
      formulaList: numberFormulaList,
      unitList: {
        funnelConversion: '次',
        funnelConversionTime: 's',
      },
    },

    fit: {
      type: 'userFit',
      menuList: [
        {
          value: 'user',
          label: '用户',
          children: [
            {
              value: 'user_type',
              label: '用户类型',
            },
            {
              value: 'user_group',
              label: '用户分群',
            },
          ],
        },
        {
          value: 'channel_mix',
          label: '渠道',
          children: [
            {
              value: 'channel_link',
              label: '外部链接',
            },
            {
              value: 'channel_search',
              label: '搜索引擎',
            },
            {
              value: 'channel_keyword',
              label: '搜索词',
            },
            {
              value: 'channel_utm',
              label: '广告',
            },
            {
              value: 'channel_top',
              label: '一级访问来源',
              children: [
                {
                  value: 'Direct_access',
                  label: '直接访问',
                },
                {
                  value: 'External_links',
                  label: '外部链接',
                },
                {
                  value: 'Search_Engines',
                  label: '搜索引擎',
                },
                {
                  value: 'key_word',
                  label: '关键词',
                },
                {
                  value: 'Advertisement',
                  label: '广告',
                },
              ],
            },
          ],
        },
        {
          value: 'terminal‘',
          label: '终端',
          children: [
            {
              value: 'terminal_type',
              label: '设备类型',
            },
            {
              value: 'terminal_brand',
              label: '设备品牌',
            },
            {
              value: 'terminal_os',
              label: '操作系统',
            },
            {
              value: 'terminal_browser',
              label: '浏览器',
            },
            {
              value: 'terminal_resolution',
              label: '分辨率',
            },
          ],
        },
        {
          value: 'district',
          label: '地域',
          children: [
            {
              value: 'district_country',
              label: '国家',
            },
            {
              value: 'district_province',
              label: '省份',
            },
            {
              value: 'district_city',
              label: '城市',
            },
          ],
        },
      ],
      fieldsList: [
        {
          value: 'search',
          label: '浏览页面',
        },
      ],
      formulaList: numberFormulaList,
    },

    filter: {
      type: 'select',
      menuList: [
        {
          value: 'page_visited',
          label: '受访页面',
        },
      ],
      formulaList: optionsFormulaList,
    },

    collected: {
      type: 'select',
      menuList: [
        {
          value: 'times',
          label: '触发次数',
        },
      ],
      formulaList: optionsFormulaList,
      parent: '',
    },
    focus: {
      type: 'select',
      menuList: [
        {
          value: 'times',
          label: '触发次数',
        },
      ],
      formulaList: optionsFormulaList,
      parent: '',
    },

    search: {
      type: 'select',
      menuList: [
        {
          value: 'pv',
          label: '浏览量',
        },
        {
          value: 'pvContribute',
          label: '贡献下游浏览量',
        },
        {
          value: 'visitTime',
          label: '访问时长',
        },
      ],
      formulaList: optionsFormulaList,
      parent: '',
    },
    user_type: {
      type: 'select',
      menuList: [
        {
          value: 'new',
          label: '新用户',
        },
        {
          value: 'old',
          label: '老用户',
        },
      ],
      formulaList: optionsFormulaList,
    },
    visitor: {
      type: 'select',
      menuList: [{ value: '1', label: '新用户' }, { value: '0', label: '老用户' }],
      formulaList: optionsFormulaList,
      extensionTarget: 'new_visitor',
    },
    value: {
      type: 'select',
      menuList: [],
      formulaList: optionsFormulaList,
    },
    value_string: {
      type: 'select',
      menuList: [],
      formulaList: stringFormulaList,
    },
    event: {
      type: 'complex',
      menuList: [],
      fieldsList: [
        {
          value: 'eventOccurs',
          label: '事件触发次数',
        },
      ],
      formulaList: numberFormulaList,
      unitList: {
        eventOccurs: '次',
      },
    },
    channel_enter: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_link: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_search: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_keyword: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_utm: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_top: {
      type: 'select',
      menuList: [
        {
          value: 'channel_enter',
          label: '直接进入',
        },
        {
          value: 'channel_link',
          label: '外部链接',
        },
        {
          value: 'channel_search',
          label: '搜索引擎',
        },
        {
          value: 'channel_utm',
          label: '广告',
        },
      ],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_share_visitor: {
      type: 'select',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_share_page: {
      type: 'select',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    channel_scene: {
      type: 'select',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'channel_mix',
    },
    page_visited: {
      type: 'complex',
      menuList: [],
      fieldsList: [
        {
          value: 'pv',
          label: '浏览量',
        },
        {
          value: 'pvContribute',
          label: '贡献下游浏览量',
        },
        {
          value: 'visitTime',
          label: '访问时长',
        },
      ],
      formulaList: optionsFormulaList,
      parent: 'page',
      unitList: {
        pv: '次',
        pvContribute: '次',
        visitTime: 's',
      },
    },
    page_entry: {
      type: 'complex',
      menuList: [],
      fieldsList: [
        {
          value: 'pv',
          label: '浏览量',
        },
        {
          value: 'pvContribute',
          label: '贡献下游浏览量',
        },
        {
          value: 'visitTime',
          label: '访问时长',
        },
      ],
      formulaList: optionsFormulaList,
      parent: 'page',
      unitList: {
        pv: '次',
        pvContribute: '次',
        visitTime: 's',
      },
    },
    funnel: {
      type: 'selectComplex',
      menuList: [],
      fieldsList: [
        {
          value: 'funnelConversion',
          label: '转化次数',
        },
        {
          value: 'funnelConversionTime',
          label: '转化时长',
        },
      ],
      formulaList: numberFormulaList,
      unitList: {
        funnelConversion: '次',
        funnelConversionTime: 's',
      },
    },
    visitor_nl: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
    },
    visitor_behavior: {
      type: 'input',
      formulaList: numberFormulaList,
      unitList: {
        pages: '页',
        visits: '次',
        visitTime: 's',
        dayAvgVisits: '次',
      },
    },
    channel_type: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_type: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_brand: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_model: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_os_version: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_network: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_browser_version: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_os_lang: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_os: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_browser: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    terminal_resolution: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'terminal',
    },
    district_country: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'district',
    },
    district_province: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'district',
    },
    district_city: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'district',
    },
    value: {
      type: 'autoComplete',
      menuList: [],
      formulaList: optionsFormulaList,
      parent: 'district',
    },
  },
  otherOptions: {
    mpwechatChannel: [
      {
        value: 'channel_scene',
        label: '场景',
      },
      {
        value: 'share',
        label: '分享',
      },
      {
        value: 'channel_utm',
        label: '广告',
      },
      {
        value: 'channel_top',
        label: '一级访问来源',
      },
    ],
    mpwechatChannelTop: [
      {
        value: 'channel_scene',
        label: '场景',
      },
      {
        value: 'share',
        label: '分享',
      },
      {
        value: 'channel_utm',
        label: '广告',
      },
    ],
  },
  stringFormulaList,
  optionsFormulaList,
};
