import { Table } from 'antd';
import { autorun, observable, toJS, action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import api from '../../api';
import Dropdown from '../../component/DropDownSelect/index';
import Loading from '../../component/Loading/Loading';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import Search from '../../component/Search/index.js';
import TableNullData from '../../component/TableNullData';
import util from '../../util';
import filtersUtil from '../../utils/filtersUtil';
import legendUtil from '../../utils/legendUtil';
import mathUtil from '../../utils/mathUtil';
import pageUtil from '../../utils/pageUtil';
import CommonPagination from '../CommonPagination';
import CommonTable from './index.js';
import CommonTableStore from './store';
import TablePageStore from './TablePageStore';
import './index.sass';
import tableUtil from '../../utils/tableUtil';

const globalControlAnalysisTypes = ['analyseEvent', 'analyseRemain', 'analysePath'];

@observer
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.store = new CommonTableStore();
    // 调用父组件的addcontent函数
    // 空状态的类型 （现在定义了两种状态的空状态的形式 1、为热图、录像、漏斗、调研的空状态 其表现形式为一张大图右边加文字解释 2、普通antd的空状态）
    this.nullDataType = this.props.nullDataType;
    this.currentPage = this.props.currentPage;
    this.pageCount = this.props.pageCount || 10;
    this.store.isExpandedRowRender = this.props.expandedRowRender || this.props.fixTableHeadKey;
    if (this.store.dataSource === undefined) {
      this.store.dataSource = 0;
    }
    if (this.props.isSort !== undefined) {
      this.store.isSort = this.props.isSort;
    }
    if (this.props.noFormat !== undefined) {
      this.store.noFormat = this.props.noFormat;
    }
    this.tableHeadData = [];
    this.lastProps = {};
    this.pageConfig;
  }
  @observable tableData;
  @observable fetchParams;

  @observable searchValue = util.getQueryString('searchValue') || '';
  @observable orderDesc = (this.hasDetail && TablePageStore.config.orderDesc) || this.props.orderDesc || true;

  //加载动画状态为true的时候加载 false的时候消失
  @observable isLoading = true;

  componentDidMount() {
    const { hasDetail } = this.props;
    hasDetail && TablePageStore.init(this.props.details, this.props.filters);
    // 是否有传过来的data
    if (this.props.parentData) {
      this.props.parentData.data && this.formatParentData(this.props.parentData);
    } else if (!this.props.hasOwnProperty('parentData')) {
      this.disposer = autorun(() => {
        const { date, dims, eventAnalytics, filterObject, selectValue, ruleContent } = this.props;
        const currentProps = {
          props: {
            date,
            dims,
            eventAnalytics,
            filterObject,
            selectValue,
            ruleContent,
          },
          pageHeaderStore: {
            date: date || pageHeaderStore.parameter.date,
            period: pageHeaderStore.parameter.period,
          },
          searchValue: this.searchValue,
          orderDesc: this.orderDesc,
        };
        globalControlAnalysisTypes.includes(this.props.tableType) && (currentProps.workViewFilters = toJS(pageHeaderStore.getCommonFilter(this.props.id)));
        JSON.stringify(currentProps) !== JSON.stringify(this.lastProps) && (this.fetchData(), (this.lastProps = currentProps));
      });
      this.props.runOnce && this.disposer();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.parentData && JSON.stringify(prevProps.parentData) !== JSON.stringify(this.props.parentData) && this.props.parentData.data) {
      this.formatParentData(this.props.parentData);
    }
    // 重刷addContent
    if (this.props.reloadContent && prevProps.reloadContent && JSON.stringify(prevProps.reloadContent) !== JSON.stringify(this.props.reloadContent)) {
      this.formatParentData(this.props.parentData);
      this.props.addContent && this.props.addContent(this.store);
    }
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  // 表头的legend第一是动态的字段需要通过此方法修改
  translateDataTitle = (tableHeadData, param) => {
    if (tableHeadData && tableHeadData[1][0] && tableHeadData[1][0] === 'date') {
      tableHeadData[1][0] = `date_${
        !pageHeaderStore.parameter.period || pageHeaderStore.parameter.period === 'default' ? 'day' : pageHeaderStore.parameter.period
      }_name`;
      this.tableHeadData = tableHeadData;
      return tableHeadData;
    } else if (this.props.tableType !== 'analyseSurvey' && tableHeadData && tableHeadData[1][0] && tableHeadData[1][0].includes('date_')) {
      tableHeadData[1][0] = `date_${
        !pageHeaderStore.parameter.period || pageHeaderStore.parameter.period === 'default' ? 'day' : pageHeaderStore.parameter.period
      }_name`;
      this.tableHeadData = tableHeadData;
      return tableHeadData;
    } else if (
      param &&
      param.hasOwnProperty('dims') &&
      !param.dims.includes(',') &&
      tableHeadData &&
      tableHeadData[1][0] &&
      tableHeadData[1][0].includes('date_')
    ) {
      tableHeadData[1][0] = `${param.dims}_name`;
      return tableHeadData;
    } else {
      this.tableHeadData = tableHeadData;
      return tableHeadData;
    }
  };
  formatParentData(parentData) {
    const { hasDetail } = this.props;
    let res = JSON.parse(JSON.stringify(parentData));
    if (res === null || JSON.stringify(res) === '{}') {
      res = { data: [] };
    }
    this.props.getFieldByRes
      ? this.store.resetStore(this.getResFields(res.data))
      : this.store.resetStore(
          this.props.tableHeadData
            ? this.translateDataTitle(this.props.tableHeadData, this.props.newParam)
            : tableUtil.fieldsToTableHeadData(this.props.fields, null)
        );
    if (this.props.tableType && this.props.tableType === 'analyseSurvey') {
      let data = toJS(parentData.data);
      this.store.setCacheStore(data);
      this.store.setCacheParams(this.props.newParam);
      this.props.customColumns && this.store.setCustomColumns(this.props.customColumns);
      (!res.page || !res.page.totalCount || res.data.length > res.page.pageCount) &&
        (res.page = this.createPage(1, this.props.internalPageCount || 10, data.length, res.page ? res.page.orderDesc : undefined)),
        (res.data = data.splice(0, this.props.internalPageCount || 10));
    }

    this.orderColumn =
      hasDetail && TablePageStore.config.pageCount
        ? TablePageStore.config.pageCount
        : this.props.newParam && this.props.newParam.orderColumn
        ? this.props.newParam.orderColumn
        : undefined;
    // res.page &&
    //   (hasDetail && TablePageStore.config.pageCount && (res.page.pageCount = TablePageStore.config.pageCount),
    //   hasDetail && TablePageStore.config.currentPage && (res.page.currentPage = TablePageStore.config.currentPage),
    //   hasDetail && TablePageStore.config.orderDesc && (res.page.orderDesc = TablePageStore.config.orderDesc),
    //   (res.page.orderColumn =
    //     hasDetail && TablePageStore.config.orderDesc ? TablePageStore.config.orderDesc : res.page.orderColumn ? res.page.orderColumn : this.orderColumn));
    this.store.formatTableData(res, this.props.hiddenKeys, this.props.customColumns, this.props.extraHeaders);
    this.props.newParam && this.changeKeyToName(this.store, this.props.newParam.dims);
    this.tableData = res;
    this.props.fill && this.store.columns && this.addFill();
    this.props.addContent && this.props.addContent(this.store);
    this.props.combineTableHead && this.combineTableHead(this.store, res.data, this.props.newParam.dims);
    this.isLoading = false;
  }
  //根据数据组装表头
  getEventTableHeader = (data, config) => {
    let targets = []; //指标列表
    let headerArr = [];
    let headerArrCn = [];
    let tableHeadData = this.tableHeadData;
    config.forEach((item) => {
      let target = item.paramFormula || item.target;
      target = target.split(',');
      return targets.push(...target);
    });
    targets = mathUtil.duplicateRemove(targets);
    data.forEach((item) => {
      targets.forEach((e) => {
        item.hasOwnProperty(e) &&
          tableHeadData[1].indexOf(`${item.analyticsName},${e}`) < 0 &&
          (headerArr.push(`${item.analyticsName},${e}`), headerArrCn.push(`${item.analyticsName}的${legendUtil.getTitle(e)}`));
      });
    });
    headerArr = mathUtil.duplicateRemove(headerArr);
    headerArrCn = mathUtil.duplicateRemove(headerArrCn);
    tableHeadData = [
      [...tableHeadData[0], ...headerArrCn],
      [...tableHeadData[1], ...headerArr],
    ];
    this.tableHeadData = tableHeadData;
    return tableHeadData;
  };
  // fields传值转化为表头需要的数组
  fieldsToTableHeadData = (fields, dims) => {
    let arr = fields.split(',');

    let fieldsArr = dims
      ? arr.filter((item, index) => {
          return dims.includes(',') ? !item.includes(`_name`) : !item.includes(`${dims}_name`);
        })
      : arr;

    let legendArr = fieldsArr.map((item) => {
      return legendUtil.getTitle(item);
    });
    return [legendArr, fieldsArr];
  };

  // 报告版添加的绿色的表单背景色
  addFill = () => {
    this.store.columns.forEach((item) => {
      if (this.props.fill[0].indexOf(item.dataIndex) > -1) {
        item.render = (text, record, index) => {
          if (text !== undefined || null) {
            return {
              props: {
                style: {
                  background: text && text !== '0' && util.changeTableBgByTheme(mathUtil.formatRgbaAlpha(text)),
                },
              },
              children: (
                <div className="rateContent">
                  <span>{text}</span>
                </div>
              ),
            };
          } else {
            return {
              props: {
                style: { background: '#ffffff' },
              },
              children: <span>-</span>,
            };
          }
        };
      } else if (this.props.fill[1]) {
        if (this.props.fill[1].indexOf(item.dataIndex) > -1) {
          let sum = 0;
          this.store.dataSource.forEach((el) => {
            sum += mathUtil.numberRecover(el[item.dataIndex]);
          });
          item.render = (text, record, index) => {
            if (text !== undefined || null) {
              return {
                props: {
                  style: {
                    background: text && text !== '0' && util.changeTableBgByTheme(mathUtil.formatRgbaAlpha(mathUtil.numberRecover(text) / sum)),
                  },
                },
                children: (
                  <div className="rateContent">
                    <span>{text}</span>
                  </div>
                ),
              };
            } else {
              return {
                props: {
                  style: { background: '#ffffff' },
                },
                children: <span>-</span>,
              };
            }
          };
        }
      }
    });
  };
  createPage(currentPage, pageCount, totalCount, orderDesc) {
    let totalPage = Math.floor(totalCount / pageCount);
    if (totalCount % pageCount > 0) {
      totalPage = totalPage + 1;
    }
    let page = { pageCount, currentPage, totalCount, totalPage };
    typeof orderDesc !== undefined && (page.orderDesc = orderDesc);
    return page;
  }

  fetchData = () => {
    const { hasDetail } = this.props;
    this.isLoading = true;
    // 防止period更改后，表格页数还停在当前页
    if (this.period !== `date_${pageHeaderStore.parameter.period}`) {
      this.currentPage = 1;
      this.period = `date_${pageHeaderStore.parameter.period}`;
    }
    if (this.date !== `date_${pageHeaderStore.parameter.date}`) {
      this.currentPage = 1;
      this.date = `date_${pageHeaderStore.parameter.date}`;
    }
    let fetchParams = pageUtil.getFetchParams(this.props, {
      searchValue:
        hasDetail && TablePageStore.config.searchValue ? TablePageStore.config.searchValue : this.props.searchValue ? this.props.searchValue : this.searchValue,
      currentPage: (hasDetail && TablePageStore.config.currentPage) || this.currentPage,
      pageCount: (hasDetail && TablePageStore.config.pageCount) || this.pageCount,
      orderColumn: (hasDetail && TablePageStore.config.orderColumn) || this.orderColumn,
      orderDesc: (hasDetail && TablePageStore.config.orderDesc) || this.orderDesc,
    });
    this.props.setSearchParam && fetchParams.filters && (fetchParams = this.props.setSearchParam(fetchParams));
    !this.props.getFieldByRes &&
      this.store.resetStore(
        this.props.tableHeadData ? this.translateDataTitle(this.props.tableHeadData) : tableUtil.fieldsToTableHeadData(fetchParams.fields, fetchParams.dims)
      );
    util.get(this.props.customApi ? this.props.customApi : api.pack.newIndex, fetchParams, (res) => {
      // 请求成功后isLoading状态变为false
      if (!res || !res.data || (res.data && res.data === []) || JSON.stringify(res) === '{}') {
        res = { data: [] };
      }
      if (this.props.tableType && this.props.tableType === 'analyseRemain') {
        this.props.getFieldByRes && this.store.resetStore(this.props.getFields(res.data));
        res.data = tableUtil.setRemainRes(res.data, fetchParams.dims);
      }
      if (this.props.tableType && this.props.tableType === 'analyseDistribution') {
        this.props.getFieldByRes && this.store.resetStore(this.props.getFields(res.data));
        res.data = tableUtil.setRemainRes(res.data, fetchParams.dims);
      }

      if (this.props.tableType && this.props.tableType === 'analyseEvent') {
        const tableHeadData = this.getEventTableHeader(res.data, JSON.parse(fetchParams.ruleContent));
        this.store.resetStore(tableHeadData);
        res = tableUtil.combineEventRes(res, fetchParams.dims, tableHeadData[1]);
        this.store.setCacheStore(res.data);
        this.store.setCacheParams(fetchParams);
        res.page = this.createPage(1, 10, res.data.length);
        res.data = res.data.splice(0, 10);
      }

      this.store.formatTableData(res, null, null, this.props.extraHeaders);
      this.changeKeyToName(this.store, fetchParams.dims);
      this.tableData = res;
      this.props.fill && this.store.columns && this.addFill();
      this.props.addContent && this.props.addContent(this.store, this.props);

      this.props.combineTableHead && this.combineTableHead(this.store, res.data, fetchParams.dims);
      this.isLoading = false;
    });
  };
  // 合并表头方法
  combineTableHead = (store, data, dims) => {
    if (dims && dims !== '') {
      let axisObj = {};
      let axiskey = `${dims.split(',')[0]}_name`;
      data.forEach((item) => {
        if (!axisObj.hasOwnProperty(item[axiskey])) {
          axisObj[item[axiskey]] = 1;
        } else {
          axisObj[item[axiskey]]++;
        }
      });
      // store.columns.forEach((item) => {
      //   if (item.key.includes('date_')) {
      //     let axisArr = [];
      //     item.render = (text, record, index) => {
      //       if (!axisArr.includes(text)) {
      //         axisArr.push(text);
      //         return {
      //           children: <span>{text}</span>,
      //           props: {
      //             rowSpan: axisObj[text],
      //           },
      //         };
      //       } else {
      //         return {
      //           children: <span>{text}</span>,
      //           props: {
      //             rowSpan: 0,
      //           },
      //         };
      //       }
      //     };
      //   }
      // });
    }
  };
  getResFields = (data, type) => {
    let fields = [];
    let fieldsCn = [];
    data.length > 0 &&
      Object.keys(data[0]).forEach((key) => {
        fields.push(key);
      });
    fields = fields.filter((item) => (item.includes('page_') ? !item.includes('_name') : !item.includes('_key')));
    fieldsCn = fields.map((item) => {
      if (item.includes('_key') || item.includes('_name')) {
        return legendUtil.getTitle(item);
      } else {
        return item;
      }
    });
    return [fieldsCn, fields];
  };
  // 改变表格的表头的显示（因为后台排序仅支持按照key来排，但是key与name值不一样，则需要显示name）
  @action.bound
  changeKeyToName = (store, dims) => {
    if (dims && !dims.includes(',')) {
      store.columns.forEach((item, itemIndex) => {
        if (itemIndex === 1) {
          item.render = (text, record, index) => {
            return {
              children: (
                <div className="antd-table-column-head" title={text}>
                  {`${store.parentData[index][`${dims}_name`] ? store.parentData[index][`${dims}_name`] : store.parentData[index][`${dims}_key`]}`}
                </div>
              ),
            };
          };
        }
      });
    }
  };

  //  排序改变
  @action.bound
  onChange = (pagination, filters, sorter) => {
    // 表格类型事件分析为自定义不经过后台接口的表格(分页以及排序前端控制)
    if (this.props.tableType === 'analyseEvent' || this.props.tableType === 'analyseSurvey') {
      let cacheData = toJS(this.store.cacheStore);
      let cacheParams = toJS(this.store.cacheParams);
      let cachePage = toJS(this.tableData.page);
      let res = {};
      if (Object.keys(sorter).length !== 0) {
        let orderKey = sorter.columnKey.includes(',') ? sorter.columnKey.split(',')[1] : sorter.columnKey;
        let orderDesc = sorter.order === 'ascend' ? true : false;
        // 维度为包括时间多维度
        if (
          cacheParams.dims.includes('date') &&
          cacheParams.dims.includes(',') &&
          !sorter.columnKey.includes('date') &&
          this.props.tableType !== 'analyseSurvey'
        ) {
          cacheData = mathUtil.sortArrayObjectByDay(orderKey, orderDesc, cacheData);
          this.store.setCacheStore(cacheData);
        } else {
          this.store.setCacheStore(cacheData.sort(mathUtil.sortArrayObject(orderKey, orderDesc)));
        }
      }
      res.page = this.createPage(1, cachePage.pageCount, cacheData.length);
      res.data = cacheData.slice().splice(0, cachePage.pageCount);
      this.store.formatTableData(res, this.props.hiddenKeys, this.props.customColumns, this.props.extraHeaders);
      this.tableData = res;
      this.props.addContent && this.props.addContent(this.store, this.props);
      this.props.combineTableHead && this.combineTableHead(this.store, res.data, cacheParams.dims);
    } else {
      // 其余情况传值排序
      let obj = Object.assign(sorter);
      if (Object.keys(obj).length === 0) {
        obj = {
          order: this.orderDesc ? 'ascend' : 'descend',
          // field: this.orderColumn ? this.orderColumn : this.props.orderColumn,
          columnKey: this.orderColumn ? this.orderColumn : this.props.orderColumn,
        };
      }
      !sorter.order && (obj.order = this.orderDesc ? 'ascend' : 'descend');
      // this.orderDesc = null;
      this.orderDesc = obj.order === 'descend' ? true : false;
      this.orderColumn = obj.columnKey;
      this.props.hasDetail && TablePageStore.setConfig({ orderColumn: this.orderColumn, orderDesc: this.orderDesc });
      if (this.props.parentData) {
        this.props.hasOwnProperty('fetchData') && this.props.fetchData(this.orderDesc, this.orderColumn, this.currentPage, this.pageCount);
      }
    }
  };
  // 表格里面的嵌套
  expandedRowRender = (record, index, indent, expanded) => {
    // 展开才渲染不展开则不渲染
    if (expanded) {
      // 嵌套里面对应显示的维度
      // 每个维度对应的要过滤的维度
      if (this.props.expandedRowContent) {
        return this.props.expandedRowContent(this.store.parentData[index]);
      } else {
        const filterDim = filtersUtil.showFilterName(this.props.dims);
        // 嵌套表格的参数
        const expandedParams = {
          addContent: this.props.expandedRowAddContent,
          dims: filterDim,
          fixTableHeadKey: true,
          orderColumn: 'pv',
          orderDesc: true,
          pageCount: 10,
          currentPage: 1,
          nopagination: true,
          filters: [{ dim: this.props.dims, value: record[`${this.props.dims}_key`], formula: '=' }],
        };
        // 判断是否需要默认的fileds 如果不是则和上层表格一样
        if (this.props.fields) {
          Object.assign(expandedParams, { fields: this.props.fields });
        }
        return <CommonTable {...expandedParams} />;
      }
    }
  };
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.props.addRowSelect(this.store, selectedRowKeys);
    },
  };
  //页码改变时
  handlePaginationChange = (currentPage, pageCount) => {
    this.props.hasDetail && TablePageStore.setConfig({ currentPage, pageCount });
    this.props.changePageCount && this.props.changePageCount(pageCount);
    // 表格类型事件分析为自定义不经过后台接口的表格(分页以及排序前端控制)
    if (
      this.props.tableType === 'analyseEvent' ||
      (this.props.tableType === 'analyseSurvey' && (!this.props.parentData.page || !this.props.parentData.page.totalCount))
    ) {
      // this.store.resetStore(
      //   this.props.tableHeadData
      //     ? this.translateDataTitle(this.props.tableHeadData)
      //     : this.fieldsToTableHeadData(this.store.cacheParams.fields, this.store.cacheParams.dims)
      // );
      let res = {};
      res.page = this.createPage(currentPage, pageCount, toJS(this.store.cacheStore).length);
      res.data = toJS(this.store.cacheStore)
        .slice()
        .splice((currentPage - 1) * pageCount, pageCount);
      this.store.formatTableData(res, this.props.hiddenKeys, this.props.customColumns, this.props.extraHeaders);

      this.tableData = res;

      this.props.addContent && this.props.addContent(this.store, this.props);

      this.props.combineTableHead && this.combineTableHead(this.store, res.data, this.store.cacheParams.dims);
    } else {
      // 其余情况传值排序
      this.currentPage = currentPage;
      this.pageCount = pageCount;
      this.tableData.page = { ...this.tableData.page, currentPage, pageCount };
      if (this.props.parentData) {
        this.isLoading = true;
        this.props.hasOwnProperty('fetchData') && this.props.fetchData(this.orderDesc, this.orderColumn, this.currentPage, this.pageCount, () => {});
      } else {
        this.fetchData();
      }
    }
  };
  // 搜索框搜索功能
  handleSearch = (searchValue) => {
    this.props.hasDetail && TablePageStore.setConfig({ searchValue, currentPage: 1, pageCount: 10 });
    this.currentPage = 1;
    this.searchValue = searchValue;
  };
  render() {
    const isLoading = this.props.hasOwnProperty('isLoading') ? this.props.isLoading : this.isLoading;
    return (
      <React.Fragment>
        {/* 搜索框大部分是和表格联动的所以在此处加了一个状态searchinside 判断需不需要搜索框 */}
        {this.props.searchInside ? <Search className={this.props.searchClassName} defaultValue={this.searchValue} onClick={this.handleSearch} /> : null}
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {this.props.tableOptions ? (
              <div className="options-tableOptions">
                <Dropdown
                  options={this.props.optionsData}
                  onSelect={this.props.onSelect}
                  selectValue={this.props.selectValue}
                  dropdownMatchSelectWidth={false}
                />
              </div>
            ) : null}
            {this.store.columns && toJS(this.store.dataSource) !== 0 && toJS(this.tableData.data).length !== 0 ? (
              <React.Fragment>
                <Table
                  className={this.props.className ? this.props.className : ''}
                  key="common_table"
                  columns={toJS(this.store.columns)}
                  dataSource={toJS(this.store.dataSource)}
                  expandedRowRender={this.props.expandedRowRender ? this.expandedRowRender : undefined}
                  onChange={this.onChange}
                  pagination={false}
                  childrenColumnName={this.props.childrenColumnName}
                  defaultExpandAllRows={this.props.defaultExpandAllRows}
                  rowKey={this.props.rowKey}
                  onExpand={this.props.onExpand}
                  expandIconColumnIndex={this.props.expandIconColumnIndex}
                  rowSelection={
                    this.props.rowSelection
                      ? this.props.addRowSelect
                        ? {
                            type: 'checkbox',
                            ...this.rowSelection,
                            columnWidth: 24,
                          }
                        : { ...this.props.rowSelection, columnWidth: 24 }
                      : null
                  }
                  rowClassName={this.props.rowClassName}
                  scroll={this.props.scroll}
                />
                {this.tableData &&
                  this.tableData.page &&
                  this.tableData.page.totalCount > 10 &&
                  // 当props传值中nopagination为真时隐藏分页
                  (this.props.nopagination ? null : (
                    <CommonPagination
                      key="common_pagination"
                      pageCount={parseInt(this.tableData.page.pageCount)}
                      currentPage={parseInt(this.tableData.page.currentPage)}
                      totalCount={this.tableData.page.totalCount}
                      paginationChange={this.handlePaginationChange}
                    />
                  ))}
              </React.Fragment>
            ) : (
              <TableNullData nullDataType={this.props.nullDataType} />
            )}
          </React.Fragment>
        )}
        {/* tableData在有page属性的前提下 */}
      </React.Fragment>
    );
  }
}

export default Main;
