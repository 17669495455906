import React, { Component } from 'react';
import { Switch, InputNumber } from 'antd';

class CombineStyle extends Component {
  constructor(props) {
    super(props);
  }

  cardStyleChange(key, value, listIndex, cardIndex) {
    let combineConfigCopy = this.props.combineConfig;
    combineConfigCopy[listIndex][cardIndex].combineStyle = combineConfigCopy[listIndex][cardIndex].combineStyle || {};
    combineConfigCopy[listIndex][cardIndex].combineStyle[key] = value;
    this.props.setCombineConfig(combineConfigCopy);
  }

  cardLockProportionChange(value, listIndex, cardIndex) {
    let combineConfigCopy = this.props.combineConfig;
    combineConfigCopy[listIndex][cardIndex].combineStyle = combineConfigCopy[listIndex][cardIndex].combineStyle || {};
    combineConfigCopy[listIndex][cardIndex].combineStyle.lockProportion = value;
    if (value) {
      combineConfigCopy[listIndex][cardIndex].combineStyle.width = combineConfigCopy[listIndex][cardIndex].combineStyle.height || 100;
    }
    this.props.setCombineConfig(combineConfigCopy);
  }

  cardBoxChange(key, value, listIndex, cardIndex) {
    let combineConfigCopy = this.props.combineConfig;
    combineConfigCopy[listIndex][cardIndex].combineStyle = combineConfigCopy[listIndex][cardIndex].combineStyle || {};
    const lockProportion = combineConfigCopy[listIndex][cardIndex].combineStyle.lockProportion;
    if (lockProportion) {
      combineConfigCopy[listIndex][cardIndex].combineStyle.height = value;
      combineConfigCopy[listIndex][cardIndex].combineStyle.width = value;
    } else {
      combineConfigCopy[listIndex][cardIndex].combineStyle[key] = value;
    }
    this.props.setCombineConfig(combineConfigCopy);
  }

  render() {
    const { combineConfig, style, combineStyleChange } = this.props;
    return (
      <div className="combine-style">
        <div className="style-item">
          <h5 className="style-item-title">组合分析样式</h5>
          <div className="style-setting">
            <div className="style-discription">是否显示卡片标题</div>
            <Switch size="small" checked={style.combineStyle.showTitle} onChange={(checked) => combineStyleChange('showTitle', checked)} />
          </div>
        </div>
        {combineConfig.map((cardlist, listIndex) => {
          return cardlist.map((item, index) => {
            const itemStyle = item.combineStyle || {};
            return (
              <div className="style-item" key={`${index}${item.id}`}>
                <h5 className="style-item-title">{item.name}</h5>
                <div className="style-setting">
                  <div className="style-discription">是否显示卡片标题</div>
                  <Switch size="small" checked={itemStyle.showTitle} onChange={(checked) => this.cardStyleChange('showTitle', checked, listIndex, index)} />
                </div>
                <div className="style-setting">
                  <div className="style-discription">是否锁定比例</div>
                  <Switch size="small" checked={itemStyle.lockProportion} onChange={(checked) => this.cardLockProportionChange(checked, listIndex, index)} />
                </div>
                <div className="style-setting">
                  <div className="box-setting">
                    <i className="iconfont icon-colum-height" />
                    <div className="box-setting-text">高度</div>
                    <InputNumber
                      size="small"
                      value={itemStyle.hasOwnProperty('height') ? itemStyle.height : 100}
                      onChange={(value) => this.cardBoxChange('height', value, listIndex, index)}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace('%', '')}
                    />
                  </div>
                  <div className="box-setting">
                    <i className="iconfont icon-shihekuandu" />
                    <div className="box-setting-text">宽度</div>
                    <InputNumber
                      size="small"
                      value={itemStyle.hasOwnProperty('width') ? itemStyle.width : 100}
                      onChange={(value) => this.cardBoxChange('width', value, listIndex, index)}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace('%', '')}
                    />
                  </div>
                </div>
              </div>
            );
          });
        })}
      </div>
    );
  }
}

export default CombineStyle;
