import React, { Component } from 'react';
import CommonTable from '../../../CommonTable/index';
import { autorun, observable, toJS, action } from 'mobx';

import { observer } from 'mobx-react';

import TableNullData from '../../../../component/TableNullData/index';
import tableUtil from '../../../../utils/tableUtil';
import legendUtil from '../../../../utils/legendUtil';

import { remainTools } from '../../../../utils/analyseUtil';
import api from '../../../../api';
import { Tooltip } from 'antd';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import { Link } from 'react-router-dom';
import mathUtil from '../../../../utils/mathUtil';
import util from '../../../../util';
import moment from 'moment';

@observer
class RemainTable extends Component {
  constructor(props) {
    super(props);
    this.lastCurrentCommonFilterObject = [];
  }
  @observable cop = (<TableNullData></TableNullData>);

  // 需要addcontent处理的那些fields的数组
  @observable periodArr;
  // 表格tableHeadData
  @observable tableHeadData;

  @action.bound addContent = (store) => {
    const config = toJS(this.periodArr).map((item) => {
      if (item.includes('page')) {
        // 把带维度的表头切成数组
        let dimArr = item.split('_');
        // 重新拼成维度名
        let dim = `${dimArr[0]}_${dimArr[1]}`;
        return {
          currentRowData: item,
          children: (store, text, record, index, item) =>
            text === '全部用户' ? (
              text
            ) : (
              <div className="childrenContent">
                <div className="rateContent">
                  <div className="rateContent-urlName" title={text}>
                    {mathUtil.spliceHttpAndDomin(text)}
                  </div>

                  <div className="rateContent-title">
                    <Link
                      to={`/data/page/detail?keyDim=${dim}&key=${encodeURIComponent(store.parentData[index][`${dim}_key`])}&name=${encodeURIComponent(text)}`}
                      title={`${store.parentData[index][`${dim}_key`]}的页面详情`}>
                      {mathUtil.spliceHttpAndDomin(store.parentData[index][`${dim}_key`])}
                    </Link>
                  </div>
                </div>
              </div>
            ),
        };
      } else if (item.includes('_key')) {
        let dimArr = item.split('_');
        let dim = dimArr.length === 3 ? `${dimArr[0]}_${dimArr[1]}` : `${dimArr[0]}`;

        return {
          currentRowData: item,
          string: (text, index, store) => {
            return store.parentData[index][`${dim}_name`];
          },
        };
      } else {
        return {
          currentRowData: item,

          style: (store, text, record, index, item) => {
            let ratio = store.parentData[index][item.currentRowData] ? store.parentData[index][item.currentRowData].ratio : 0;
            return { background: util.changeTableBgByTheme(ratio) };
          },

          children: (store, text, record, index, item) => {
            // 当前选择日期

            // 初始行为
            let initAction = toJS(this.props.form.ruleContent)[0]['rules'][0]['conditions'][0]['dimValue'];
            // 后续行为
            let followAction = toJS(this.props.form.ruleContent)[0]['rules'][1]['conditions'][0]['dimValue'];

            let parentData = store.parentData[index];
            const dimsName = store.field[0];
            const currentDim = parentData[`${dimsName}`];
            const renderContent = () => {
              return (
                <div>
                  <div>{parentData[item.currentRowData].value === '-' ? '-' : parentData[item.currentRowData].value}</div>
                  <div>{legendUtil.formateTitle('ratio', parentData[item.currentRowData].ratio)}</div>
                </div>
              );
            };
            const TooltipTitle = (dimsName) => {
              if (dimsName.includes('date')) {
                return `在${currentDim}进行${initAction}的${legendUtil.formateTitle('value', parentData['lifeCycleVisitors'])}人，有${legendUtil.formateTitle(
                  'value',
                  parentData[item.currentRowData].value
                )}人在${remainTools.fieldsToCnName(item.currentRowData)}进行了${followAction}。`;
              } else {
                return `在选定时间段内有${legendUtil.formateTitle(
                  'value',
                  parentData['lifeCycleVisitors']
                )}位${currentDim}的人进行了${initAction}，其中，有${legendUtil.formateTitle(
                  'value',
                  parentData[item.currentRowData].value
                )}人在${remainTools.fieldsToCnName(item.currentRowData)}进行了${followAction}。`;
              }
            };
            return parentData[item.currentRowData] !== undefined ? (
              index === 0 ? (
                renderContent()
              ) : (
                <Tooltip placement="top" mouseEnterDelay={0.5} arrowPointAtCenter title={TooltipTitle(dimsName)}>
                  {renderContent()}
                </Tooltip>
              )
            ) : (
              <div>
                <div>-</div>
                <div>-</div>
              </div>
            );
          },
        };
      }
    });

    tableUtil.addContentByConfig(store, config);
  };
  @action.bound remainGetFields(data) {
    let fieldArr = remainTools.periodFields(data);
    this.periodArr = fieldArr[1].filter((item) => item.includes('remain_') || item.includes('_key'));
    return fieldArr;
  }
  // 判断是否是日期维度
  checkDateDim(dim) {
    return dim.join(',') === '';
  }
  // 根据父子表格传递不同参数
  getFetchParam(form, isExpand, parentData) {
    let fetchParam = {
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : this.props.form.dateObj
          ? this.props.form.dateObj.lastDate
            ? mathUtil.keyToDate(this.props.form.dateObj.lastDate)
            : this.props.form.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      ruleContent: JSON.stringify(toJS(form.ruleContent)),
      noDefaultFields: true,
      getFieldByRes: true,
      pageCount: 10,
      currentPage: 1,
      orderDesc: false,
      customApi: api.analysis.getMixAnalysis,
      tableType: 'analyseRemain',
      workViewFilters: this.props.id ? pageHeaderStore.getCommonFilter(this.props.id) : '',
      analysisType: 3,
    };
    if (isExpand) {
      fetchParam['dims'] = 'date';
      fetchParam['filters'] = [{ dim: form.dim.join(','), value: parentData[`${form.dim.join(',')}_key`], formula: '=' }];
      fetchParam['orderColumn'] = `date_${pageHeaderStore.parameter.period}_key`;
    } else {
      fetchParam['dims'] = this.checkDateDim(form.dim) ? 'date' : `date,${form.dim.join(',')}`;
      fetchParam['orderColumn'] = this.checkDateDim(form.dim) ? `date_${pageHeaderStore.parameter.period}_key` : `${form.dim.join(',')}_key`;
    }
    return fetchParam;
  }
  // 渲染子表格
  expandedRowContent = (form, record) => {
    return (
      <CommonTable
        tableType="analyseRemain"
        id={this.props.id}
        addContent={this.addContent}
        getFields={this.remainGetFields}
        {...this.getFetchParam(form, true, record)}></CommonTable>
    );
  };
  // 渲染父表格
  changeCommonTable(form) {
    return (
      <CommonTable
        tableType="analyseRemain"
        id={this.props.id}
        addContent={this.addContent}
        expandedRowRender={!this.checkDateDim(form.dim)}
        expandedRowContent={(parentData) => this.expandedRowContent(form, parentData)}
        getFields={this.remainGetFields}
        {...this.getFetchParam(form, false)}></CommonTable>
    );
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      let count = this.props.form.count;
      let dateObj = this.props.form.dateObj;
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      let commonFilterObject = pageHeaderStore.commonFilterObject;
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.id));

      if (
        (remainTools.checkSaveData(toJS(this.props.form.ruleContent)) &&
          JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject) &&
          this.props.isVisible) ||
        count !== this.lastCount
      ) {
        clearInterval(this.delay);
        this.delay = setTimeout(() => {
          this.cop = this.changeCommonTable(this.props.form);
          this.lastCurrentCommonFilterObject = currentCommonFilterObject;
          this.lastCount = count;
        }, 800);
      }
    });
  }

  componentDidUpdate(preProps) {
    if (this.props.isVisible !== preProps.isVisible && this.props.isVisible) {
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.id));
      if (
        remainTools.checkSaveData(toJS(this.props.form.ruleContent)) &&
        JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject)
      ) {
        clearInterval(this.delay);
        this.delay = setTimeout(() => {
          this.cop = this.changeCommonTable(this.props.form);
          this.lastCurrentCommonFilterObject = currentCommonFilterObject;
        }, 800);
      }
    }
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }
  render() {
    return this.cop;
  }
}
export default RemainTable;
