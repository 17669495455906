import React, { FC, forwardRef, useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { DatePicker as OldDatePicker } from 'antd';
import { DatePicker } from 'antd4';
import { DatePickerFormats } from '../constants';
import locale from 'antd4/es/date-picker/locale/zh_CN';
import { toJS } from 'mobx';
import './Panel/Layouts/Layouts.less';
import { selectParams } from '../../../UXSRangePicker';
import intl from 'react-intl-universal';
import pageHeaderStore from '../../PageHeaderStore';
import mathUtil from '../../../../utils/mathUtil';
import '../../../../component/UXSRangePicker/index.sass';
import { getTextWidth } from '../../../../containers/BIChart/util';

import moment from 'moment';

const { RangePicker } = DatePicker;

const { RangePicker: OldRangePicker } = OldDatePicker;

const DateRange = ({ control, value, size, onChange, useDateByStore, isPageHeader }, ref) => {
  // 日期单选携带默认值，切换到范围时未及时更新
  if (value && !Array.isArray(value)) {
    value = [];
  }
  const content = useRef();
  const [lastDate, setLastDate] = useState(null);
  // 缓存之前的日期
  const [originValue, setOriValue] = useState([]);
  const { dateFormat } = control;
  const placeholder = ['从', '到'];
  const { Datetime, DatetimeMinute, Date, Month, Week, Year, Quarter } = DatePickerFormats;
  const isDatetimePicker = [Datetime, DatetimeMinute].includes(dateFormat);
  const [type, setType] = useState('date');
  const [withConfirm, setWithConfirm] = useState(false);

  useEffect(() => {
    switch (dateFormat) {
      case Month:
        setType('month');
        break;
      case Week:
        setType('week');
        break;
      case Year:
        setType('year');
        break;
      case Quarter:
        setType('quarter');
        break;
      default:
        setType('date');
    }
    if (dateFormat === Month || dateFormat === Year) {
      setWithConfirm(true);
    } else {
      setWithConfirm(false);
    }
    if (content.current && isPageHeader) {
      const titleWidth = getTextWidth(control.name);
      if (dateFormat === Date) {
        if (!control.width) {
          content.current.getElementsByClassName('controlQuickPicker')[0].style.width = '268px';
        }
        content.current.getElementsByClassName('ant-calendar-picker-input')[0].style.paddingLeft = `${titleWidth + 16}px`;
      } else {
        content.current.getElementsByClassName('controlQuickPicker')[0].style.paddingLeft = `${titleWidth + 16}px`;
      }
    }
  }, [content, control]);

  useEffect(() => {
    if (value && value.every((i) => i)) {
      setOriValue(value);
    }
  }, [value]);

  const getButtonClass = (key) => {
    const getClass = (currentValue, keyValue) => {
      if (currentValue === keyValue) {
        return 'rangePicker-btn active';
      } else {
        return 'rangePicker-btn';
      }
    };
    if (useDateByStore) {
      return getClass(pageHeaderStore.parameter.lastDate, key);
    } else {
      return getClass(lastDate, key);
    }
  };

  const changeDateByKey = (key) => {
    let dateStr = mathUtil.keyToDate(key);
    onChange(dateStr.split(',').map((item) => moment(item)));
  };

  const getIndex = (value) => {
    if (value.findIndex((i) => !i) > -1) {
      return {
        startIndex: 0,
        endIndex: 1,
      };
    }
    const startIndex = moment(value[0]).format('x') < moment(value[1]).format('x') ? 0 : 1;
    const endIndex = startIndex ? 0 : 1;
    return {
      startIndex,
      endIndex,
    };
  };

  const onFocus = (f) => {
    if (!(value && value.length > 1)) {
      return;
    }
    const currentValue = f.target.value;

    if (!currentValue) {
      return;
    }

    const { startIndex, endIndex } = getIndex(value);
    const isStart = moment(value[startIndex]).format(dateFormat) === currentValue;

    // 聚焦在哪一个，就清空哪一个
    onChange([isStart ? '' : value[startIndex], isStart ? value[endIndex] : '']);
  };

  const onBlur = () => {
    if (!(value && value.length > 1)) {
      return;
    }
    const { startIndex, endIndex } = getIndex(value);
    const { startIndex: originStartIndex, endIndex: originEndIndex } = getIndex(originValue);
    onChange([value[startIndex] || originValue[originStartIndex], value[endIndex] || originValue[originEndIndex]]);
  };

  const onOpenChange = (open) => {
    if (!(value && value.length > 1)) {
      return;
    }

    if (!open) {
      const { startIndex, endIndex } = getIndex(value);
      const { startIndex: originStartIndex, endIndex: originEndIndex } = getIndex(originValue);
      onChange([value[startIndex] || originValue[originStartIndex], value[endIndex] || originValue[originEndIndex]]);
    }
  };

  return (
    <div className="controlDate" ref={content}>
      {dateFormat === 'YYYY-MM-DD' ? (
        <OldRangePicker
          className="controlQuickPicker oldControlQuickPicker"
          locale={locale}
          placeholder={placeholder}
          value={value}
          picker={type}
          showTime={isDatetimePicker}
          format={dateFormat}
          {...(size && { size })}
          onChange={onChange}
          onOk={onChange}
          ref={ref}
          dropdownClassName={`calendar-dropdown uxs-picker-dropdown`}
          renderExtraFooter={() => (
            <div className={`ant-calendar-footer-extra-content ${withConfirm ? 'withConfirm' : ''}`}>
              <div className="ant-calendar-footer-extra-content-odd">
                {selectParams.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Button
                        className={`${getButtonClass(item.key)}`}
                        key={index}
                        onClick={() => {
                          changeDateByKey(item.key);
                        }}>
                        {intl.get(item.name)}
                      </Button>
                    );
                  }
                })}
              </div>
              <div className={`ant-calendar-footer-extra-content-event`}>
                {selectParams.map((item, index) => {
                  if (index % 2 === 1) {
                    return (
                      <Button
                        className={`${getButtonClass(item.key)}`}
                        key={index}
                        onClick={() => {
                          changeDateByKey(item.key);
                        }}>
                        {intl.get(item.name)}
                      </Button>
                    );
                  }
                })}
              </div>
            </div>
          )}
        />
      ) : (
        <RangePicker
          className="controlQuickPicker"
          locale={locale}
          placeholder={placeholder}
          value={value}
          picker={type}
          showTime={isDatetimePicker}
          format={dateFormat}
          {...(size && { size })}
          onChange={onChange}
          // onOpenChange={onOpenChange}
          // onFocus={onFocus}
          // onBlur={onBlur}
          onOk={onChange}
          ref={ref}
          dropdownClassName={`calendar-dropdown uxs-picker-dropdown`}
          renderExtraFooter={() => (
            <div className={`ant-calendar-footer-extra-content ${withConfirm ? 'withConfirm' : ''}`}>
              <div className="ant-calendar-footer-extra-content-odd">
                {selectParams.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Button
                        className={`${getButtonClass(item.key)}`}
                        key={index}
                        onClick={() => {
                          changeDateByKey(item.key);
                        }}>
                        {intl.get(item.name)}
                      </Button>
                    );
                  }
                })}
              </div>
              <div className={`ant-calendar-footer-extra-content-event`}>
                {selectParams.map((item, index) => {
                  if (index % 2 === 1) {
                    return (
                      <Button
                        className={`${getButtonClass(item.key)}`}
                        key={index}
                        onClick={() => {
                          changeDateByKey(item.key);
                        }}>
                        {intl.get(item.name)}
                      </Button>
                    );
                  }
                })}
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default forwardRef(DateRange);
