import React, { Component } from 'react';
import './Foooter.sass';

class Footer extends Component {
  render() {
    return (
      <div id="footer" className="footer">
        <p>
          &copy;2023 {process.env.REACT_APP_BDtitle}
          体验管理云 <a href="https://beian.miit.gov.cn">{process.env.REACT_APP_BDbeian}</a>
        </p>
      </div>
    );
  }
}

export { Footer };
