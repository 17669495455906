const baseUrl = process.env.REACT_APP_Base_Url;
const wxUrl = `${process.env.REACT_APP_SDK_Url}/api`;
let API_DOMAIN = `${baseUrl}/api`;
let BI_DOMAIN = `${baseUrl}/bi`;
let SSO_DOMAIN = `${baseUrl}/sso`;
let TOOLS_DOMAIN = 'http://47.107.37.92:3001';
let JOURNEY_DOMAIN = `${baseUrl}/journey`;

// 开发环境
// API_DOMAIN = '/cemyunApi';
// BI_DOMAIN = '/cemyunBi';
// API_DOMAIN = '/vocApi';
// BI_DOMAIN = '/vocBi';
// API_DOMAIN = '/testApi';
// BI_DOMAIN = '/testBi';
//   JOURNEY_DOMAIN = 'http://localhost:3001/journey';

const api = {
  authority: {
    list: `${API_DOMAIN}/menu/findByUser`,
  },
  pack: {
    index: `${API_DOMAIN}/pack`,
    newIndex: `${API_DOMAIN}/pack/bulk`,
    dimOptions: `${API_DOMAIN}/pack/dimOptions`,
    funnelentryoroutlist: `${API_DOMAIN}/pack/funnelentryoroutlist`,
    portrait: `${API_DOMAIN}/pack/portrait`,
    sankey: `${API_DOMAIN}/pack/sankey`,
    analyticsPath: `${API_DOMAIN}/pack/analyticsPath`,
    analyticsPathTable: `${API_DOMAIN}/pack/analyticsPathTable`,
  },
  analysis: {
    list: `${API_DOMAIN}/analysis/list`,
    saveOrUpdate: `${API_DOMAIN}/analysis/saveOrUpdate`,
    info: `${API_DOMAIN}/analysis/info`,
    delete: `${API_DOMAIN}/analysis/delete`,
    mixAnalysisExportExcel: `${API_DOMAIN}/export/mixAnalysisData`,
    remain: `${API_DOMAIN}/analysis/remain`,
    getCard: `${API_DOMAIN}/analysis/getCard`,
    getMixAnalysis: `${API_DOMAIN}/analysis/mixAnalysis`,
    picture: `${API_DOMAIN}/upload/picture`,
  },
  download: {
    submit: `${API_DOMAIN}/export/submit`,
    biSubmit: `${BI_DOMAIN}/api/v3/download/submit/view`,
    list: `${API_DOMAIN}/export/list`,
    download: `${API_DOMAIN}/export/download`,
    clear: `${API_DOMAIN}/export/clear`,
    delete: `${API_DOMAIN}/export/delete`,
  },
  analyticsPathStep: {
    findAll: `${API_DOMAIN}/analyticsPathStep/findAll`,
    saveAll: `${API_DOMAIN}/analyticsPathStep/saveAll`,
    preview: `${API_DOMAIN}/analyticsPathStep/preview`,
  },
  visitor: {
    // analysis: `${API_DOMAIN}/data/visitor/analytics`,
    // log: `${API_DOMAIN}/data/visitor/log/list`,
    info: `${API_DOMAIN}/data/visitor/log/visitorInfo`,
    // district: `${API_DOMAIN}/data/visitor/log/district`,
    // terminal: `${API_DOMAIN}/data/visitor/log/Terminal`,
    liveness: `${API_DOMAIN}/data/visitor/activityTendency`,
    // addFilters: `${API_DOMAIN}/data/visitorAnalytics/saveOrUpdateSegment`,
    filtersAll: `${API_DOMAIN}/visitors_analysis/nameList`,
    filtersTableAll: `${API_DOMAIN}/visitors_analysis/list`,
    filtersAdd: `${API_DOMAIN}/visitors_analysis/saveOrUpdate`,
    groupInfo: `${API_DOMAIN}/visitors_analysis/info`,
    groupDelete: `${API_DOMAIN}/visitors_analysis/delete`,
    visitorInfo: `${API_DOMAIN}/data/visitor/info`,
    dayAndNum: `${API_DOMAIN}/data/visitor/dayAndNum`,
    eventInfo: `${API_DOMAIN}/data/visitor/eventInfo`,
    visitInfo: `${API_DOMAIN}/data/visitor/visitInfo`,
    actionInfo: `${API_DOMAIN}/data/visitor/actionInfo`,
    confirmDelete: `${API_DOMAIN}/visitors_analysis/confirmDelete`,
    answerAndWarningList: `${API_DOMAIN}/data/visitor/answerAndWarningList`,
  },
  form: {
    // overview: `${API_DOMAIN}/plugins/form/overview`,
    list: `${API_DOMAIN}/plugins/form/list`,
    changeStatus: `${API_DOMAIN}/plugins/form/changeStatus`,
    // deleteForm: `${API_DOMAIN}/plugins/form/deleteForm`,
    detail: `${API_DOMAIN}/plugins/form/detail`,
    update: `${API_DOMAIN}/plugins/form/updateForm`,
    edit: `${API_DOMAIN}/plugins/form/editForm`,
    exports: `${API_DOMAIN}/plugins/form/exportCsv`,
  },
  funnel: {
    // overview: `${API_DOMAIN}/plugins/funnel/overview`,
    changeStatus: `${API_DOMAIN}/plugins/funnel/changeStatus`,
    deleteFunnel: `${API_DOMAIN}/plugins/funnel/deleteFunnel`,
    update: `${API_DOMAIN}/plugins/funnel/updateFunnel`,
    edit: `${API_DOMAIN}/plugins/funnel/editFunnel`,
    checkFunnelBeforeDel: `${API_DOMAIN}/plugins/funnel/checkFunnelBeforeDel`,
  },
  setting: {
    channelEdit: `${API_DOMAIN}/channel/channelEdit`,
    channelList: `${API_DOMAIN}/channel/channelList`,
    channelUpdate: `${API_DOMAIN}/channel/channelUpdate`,
    channelDelete: `${API_DOMAIN}/channel/channelDelete`,
    excludedList: `${API_DOMAIN}/excludedRule/excludedList`,
    excludedAdd: `${API_DOMAIN}/excludedRule/excludedAdd`,
    excludedUpdate: `${API_DOMAIN}/excludedRule/excludedUpdate`,
    excludedDelete: `${API_DOMAIN}/excludedRule/excludedDelete`,
    excludedEdit: `${API_DOMAIN}/excludedRule/excludedView`,
    pageGroupList: `${API_DOMAIN}/plugins/pageGroup/overview`,
    pageGroupView: `${API_DOMAIN}/plugins/pageGroup/findOne`,
    pageGroupUpdate: `${API_DOMAIN}/plugins/pageGroup/updatePageGroup`,
    pageGroupDelete: `${API_DOMAIN}/plugins/pageGroup/deletePageGroup`,
    getParameterData: `${API_DOMAIN}/parameter/list`,
    getCustomParameters: `${API_DOMAIN}/custom/parameter/list`,
    editCustomParameters: `${API_DOMAIN}/custom/parameter/edit`,
    createCustomParameters: `${API_DOMAIN}/custom/parameter/save`,
    deleteCustomParameters: `${API_DOMAIN}/custom/parameter/delete`,
    getCustomEvent: `${API_DOMAIN}/custom/event/list`,
    editCustomEvent: `${API_DOMAIN}/custom/event/edit`,
    deleteCustomEvent: `${API_DOMAIN}/custom/event/delete`,
    createCustomEvent: `${API_DOMAIN}/custom/event/save`,
    parameterDelete: `${API_DOMAIN}/parameter/delete`,
    parameterCreate: `${API_DOMAIN}/parameter/create`,
    parameterUpdate: `${API_DOMAIN}/parameter/update`,
    parameterOptions: `${API_DOMAIN}/custom/parameter/options`,
  },
  excludedRule: {
    saveOrUpdate: `${API_DOMAIN}/excludedRule/saveOrUpdate`,
    getInfo: `${API_DOMAIN}/excludedRule/getInfo`,
  },
  filtrate: {
    // list: `${API_DOMAIN}/data/commonFuction/filtrate/getConditionsList`,
    // fieldNameMap: `${API_DOMAIN}/data/commonFuction/filtrate/getConditionsFieldNameMap`,
    // update: `${API_DOMAIN}/data/commonFuction/filtrate/updateConditions`,
    // getValue: `${API_DOMAIN}/data/commonFuction/filtrate/getConditionValue`,
    // getSearchValue: `${API_DOMAIN}/data/commonFuction/filtrate/getConditionValueWithSearch`,
    // delete: `${API_DOMAIN}/data/commonFuction/filtrate/deletedConditions`,
    // filtrateList: `${API_DOMAIN}/data/commonFuction/filtrate/getConditionsListByType`,
  },
  board: {
    getFolderById: `${API_DOMAIN}/getDashboardByFolderId`,
    setAsTemplate: `${API_DOMAIN}/share/setAsTemplate`,
    info: `${API_DOMAIN}/share/info`,
    save: `${API_DOMAIN}/share/save`,
    updateDashboard: `${API_DOMAIN}/updateDashboard`,
    getDashboard: `${API_DOMAIN}/getDashboard`,
    getDashboardBySurveyId: `${API_DOMAIN}/getDashboardBySurveyId`,
    // getCard: `${API_DOMAIN}/getCard`,
    shareAdd: `${API_DOMAIN}/shareDashboardAddOrEdit`,
    add: `${API_DOMAIN}/dashboardAddOrEdit`,
    // list: `${API_DOMAIN}/shareDashboardList`,
    listWithoutTemplate: `${API_DOMAIN}/dashboardMyList`,
    listTemplate: `${API_DOMAIN}/dashboardTemplateList`,
    del: `${API_DOMAIN}/dashboardDelete`,
    realTime: `${API_DOMAIN}/getRealtimeVisitor`,
    copy: `${API_DOMAIN}/copyDashboard`,
    shareList: `${API_DOMAIN}/share/list`,
    shareFromOther: `${API_DOMAIN}/share/shareListFromOther`,
    templateList: `${API_DOMAIN}/dashboardTemplateList`,
    // path: `${API_DOMAIN}/getPopularPathDetail`,
    // funnelList: `${API_DOMAIN}/getfunnelList`,
    // download: `${API_DOMAIN}/exportData`,
    // downloadURL: `${API_DOMAIN}/downloadPdfAndPicture`,
    // accept: `${API_DOMAIN}/acceptDimensionInfoDashboard`,
  },
  report: {
    // reportSend: `${API_DOMAIN}/report/addOrUpdateReport`,
    // reportList: `${API_DOMAIN}/report/reportList`,
    // reprotDelete: `${API_DOMAIN}/report/deleteReport`,
    // reportDetail: `${API_DOMAIN}/report/viewReport`,
    // reprotStatus: `${API_DOMAIN}/report/changeReportStatus`,
    reportPay: `${API_DOMAIN}/pay/insertOrder`,
    reportPayFinished: `${API_DOMAIN}/pay/queryPayStatus`,
    // reportPayConfirm: `${API_DOMAIN}/pay/confirmOrder`,
    // reportPaySearch: `${API_DOMAIN}/pay/unconfirmOrder`,
    reportOptimizePageWithStick: `${API_DOMAIN}/optimize/setting/bulkWithStick`,
    reportOptimizePageWithOutStick: `${API_DOMAIN}/optimize/setting/bulkWithOutStick`,
    reportOptimizeStick: `${API_DOMAIN}/optimize/setting/stick`,
    reportOptimizeUnstick: `${API_DOMAIN}/optimize/setting/unstick`,
    reportOverviewStick: `${API_DOMAIN}/optimize/ProblemBoard/overViewWithStick`,
    reportOverviewUnstick: `${API_DOMAIN}/optimize/ProblemBoard/overViewWithOutStick`,
    reportOverviewSurvey: `${API_DOMAIN}/optimize/ProblemBoard/survey`,
    reportOverviewPloblem: `${API_DOMAIN}/optimize/ProblemBoard/problem`,
    reportOverviewRecord: `${API_DOMAIN}/optimize/ProblemBoard/record`,
  },
  optimize: {
    problemAll: `${API_DOMAIN}/optimize/problem/all`,
    problemDelete: `${API_DOMAIN}/optimize/problem/delete`,
    problemSend: `${API_DOMAIN}/optimize/problem/create`,
    problemDetail: `${API_DOMAIN}/optimize/problem/id`,
    recordAll: `${API_DOMAIN}/optimize/record/all`,
    recordDelete: `${API_DOMAIN}/optimize/record/delete`,
    recordCreate: `${API_DOMAIN}/optimize/record/create`,
    recordCompareChart: `${API_DOMAIN}/optimize/record/compareChart`,
    recordCompareTable: `${API_DOMAIN}/optimize/record/compareTableDetail`,
    purchaseListAll: `${API_DOMAIN}/pay/queryOrder/allpay`,
    purchaseListDelete: `${API_DOMAIN}/pay/deleteOrder`,
    // purchaseListCreate: `${API_DOMAIN}/pay/insertOrder`,
  },
  warningLog: {
    list: `${API_DOMAIN}/forewarningLog/list`,
    delete: `${API_DOMAIN}/forewarningLog/delete`,
    edit: `${API_DOMAIN}/forewarningLog/edit`,
    info: `${API_DOMAIN}/forewarningLog/info`,
    summary: `${API_DOMAIN}/forewarningLog/getSummary`,
    addAll: `${API_DOMAIN}/forewarningLog/addAll`,
    getComment: `${API_DOMAIN}/forewarningLog/getcomment`,
    editComment: `${API_DOMAIN}/forewarningLog/comment`,
    deleteComment: `${API_DOMAIN}/forewarningLog/deletecomment`,
  },
  warning: {
    options: `${API_DOMAIN}/forewarning/options`,
    list: `${API_DOMAIN}/forewarning/list`,
    delete: `${API_DOMAIN}/forewarning/delete`,
    save: `${API_DOMAIN}/forewarning/saveOrUpdate`,
    edit: `${API_DOMAIN}/forewarning/info`,
    status: `${API_DOMAIN}/forewarning/editStatus`,
    template: `${API_DOMAIN}/msgTemplate/list`,
    getChannelFields: `${API_DOMAIN}/survey/pushChannel/getChannelFields`,
    getFieldOptions: `${API_DOMAIN}/survey/pushChannel/getFieldOptions`,
  },
  personal: {
    account: `${API_DOMAIN}/root/personalCenter/account`,
    editPassword: `${API_DOMAIN}/root/personalCenter/confirm_edit_password`,
    list: `${API_DOMAIN}/accountGroup/list`,
    del: `${API_DOMAIN}/accountGroup/delete`,
    save: `${API_DOMAIN}/accountGroup/save`,
    info: `${API_DOMAIN}/accountGroup/info`,
    accountPerms: `${API_DOMAIN}/root/personalCenter/accountPerms`,
    accountDataPerms: `${API_DOMAIN}/root/personalCenter/accountDataPerms`,
    accountDataFieldPerms: `${API_DOMAIN}/root/personalCenter/accountDataFieldPerms`,
    getWorkInfo: `${API_DOMAIN}/root/personalCenter/getWorkInfo`,
  },
  product: {
    compare: `${API_DOMAIN}/setting/product/compareProducts`,
    del: `${API_DOMAIN}/setting/product/delOneProduct`,
    create: `${API_DOMAIN}/setting/product/editProduct`,
    // getAll: `${API_DOMAIN}/setting/product/getAllProductsByUser`,
    getAll: `${API_DOMAIN}/setting/product/getUserAllProductsCompare`,
    getOne: `${API_DOMAIN}/setting/product/getOneProduct`,
    getCode: `${API_DOMAIN}/setting/product/trackerCode`,
    list: `${API_DOMAIN}/setting/product/listProducts`,
    health: `${API_DOMAIN}/setting/product/dashboard`,
    postCode: `${API_DOMAIN}/setting/product/certifiedCheck`,
    quickBoot: `${API_DOMAIN}/quickBoot/setConfig`,
    preview: `${API_DOMAIN}/quickBoot/redirectPreviewSurvey`,
  },
  heatmaps: {
    overview: `${API_DOMAIN}/plugins/heatmap/list`,
    status: `${API_DOMAIN}/plugins/heatmap/statusUpdate`,
    detail: `${API_DOMAIN}/plugins/heatmap/detail`,
    update: `${API_DOMAIN}/plugins/heatmap/addOrUpdate`,
    edit: `${API_DOMAIN}/plugins/heatmap/view`,
    event: `${API_DOMAIN}/plugins/heatmap/eventAndFold`,
    urlList: `${API_DOMAIN}/plugins/heatmap/listUrls`,
  },
  record: {
    overview: `${API_DOMAIN}/plugins/sessionRecord/list`,
    status: `${API_DOMAIN}/plugins/sessionRecord/statusUpdate`,
    page: `${API_DOMAIN}/plugins/sessionRecord/detail`,
    // html: `${API_DOMAIN}/plugins/sessionRecord/initPageview`,
    // pageOverview: `${API_DOMAIN}/plugins/sessionRecord/overview`,
    update: `${API_DOMAIN}/plugins/sessionRecord/addOrUpdate`,
    edit: `${API_DOMAIN}/plugins/sessionRecord/view`,

    event: `${API_DOMAIN}/plugins/sessionRecord/getEventsForPageview`,
    pageview: `${API_DOMAIN}/plugins/sessionRecord/getPageview`,
    record: `${API_DOMAIN}/plugins/sessionRecord/getSessionRecord`,
    init: `${API_DOMAIN}/plugins/sessionRecord/initPageview`,
    baseUrl: `${API_DOMAIN}/plugins/sessionRecord/getBaseUrl`,
    subList: `${API_DOMAIN}/plugins/sessionRecord/getSublist`,
    store: `${API_DOMAIN}/plugins/sessionRecord/addOrRemoveStore`,
    getRemark: `${API_DOMAIN}/plugins/sessionRecord/getEventRemark`,
    addRemark: `${API_DOMAIN}/plugins/sessionRecord/addOrUpdateEventRemark`,
  },
  trigger: {
    mySurveyList: `${API_DOMAIN}/feedback/listMySurvey`,
    getSurvey: `${API_DOMAIN}/feedback/getSurvey`,
    save: `${API_DOMAIN}/feedback/save`,
    checkDisturbConfig: `${API_DOMAIN}/feedback/checkDisturbConfig`,
    edit: `${API_DOMAIN}/feedback/edit`,
    delete: `${API_DOMAIN}/feedback/delete`,
    // surveyAnswer: `${API_DOMAIN}/survey/get_answer_Info`,
    // surveyAnswerPage: `${API_DOMAIN}/survey/get_answer_page`,
    answerContent: `${API_DOMAIN}/survey/get_answer_content`,
    // visitorSurveyList: `${API_DOMAIN}/survey/get_feedback_list`,
    surveyList: `${API_DOMAIN}/feedback/list`,
    surveyVisitorList: `${API_DOMAIN}/data/visitor/surveyVisitorList`,
    download: `${API_DOMAIN}/export/surveyAnswerData`,
    updateStatus: `${API_DOMAIN}/feedback/updateStatus`,
    checkBeforeUpdateStatus: `${API_DOMAIN}/feedback/checkBeforeUpdateStatus`,
  },
  login: {
    confirm_login: `${SSO_DOMAIN}/doLogin`,
    // confirm_logout: `${API_DOMAIN}/logout`,
    confirm_register: `${API_DOMAIN}/auth/confirm_register`,
    wx_login: `${SSO_DOMAIN}/codeLogin`,
    getCodeInfo: `${API_DOMAIN}/root/getCpInfo`,
  },
  applyForUse: {
    applyForUse: `${API_DOMAIN}/root/applyForTrial`,
  },
  sdkConfig: {
    save: `${API_DOMAIN}/anchor/config/save`,
    list: `${API_DOMAIN}/anchor/config`,
    delete: `${API_DOMAIN}/anchor/config/delete`,
    options: `${API_DOMAIN}/anchor/config/options`,
  },
  visual: {
    list: `${API_DOMAIN}/visual/list`,
    create: `${API_DOMAIN}/visual/save`,
    delete: `${API_DOMAIN}/visual/delete`,
    scan: `${API_DOMAIN}/visual/scan`,
    scanSave: `${API_DOMAIN}/visual/scan/save`,
    userSave: `${API_DOMAIN}/visual/user/save`,
    dom: `${API_DOMAIN}/visual/dom`,
    domSave: `${API_DOMAIN}/visual/dom/save`,
    expire: `${API_DOMAIN}/visual/link/expire`,
    user: `${API_DOMAIN}/visual/user`,
    action: `${API_DOMAIN}/visual/user/action`,
  },
  surveyChannel: {
    list: `${API_DOMAIN}/survey/pushChannel/list`,
    getChannelList: `${API_DOMAIN}/survey/pushChannel/getChannelList`,
    getDepartmentList: `${API_DOMAIN}/wx/cp/department/list`,
    save: `${API_DOMAIN}/survey/pushChannel/save`,
    edit: `${API_DOMAIN}/survey/pushChannel/edit`,
    del: `${API_DOMAIN}/survey/pushChannel/del`,
    option: `${API_DOMAIN}/survey/pushChannel/getCreatableAppList`,
    checkAuthorizer: `${API_DOMAIN}/wx/get_authorizer_info`,
    enterpriseCcheckAuthorizer: `${API_DOMAIN}/wx/tp/get_authorizer_info`,
    goto: `${wxUrl}/wx/auth/goto_auth_url`,
    authorizer: `${API_DOMAIN}/wx/auth/authorizer_info`,
    enterpriseGoto: `${API_DOMAIN}/wx/tp/auth/goto_auth_url`,
    enterpriseAuthorizer: `${API_DOMAIN}/wx/tp/auth/authorizer_info`,
  },
  role: {
    list: `${API_DOMAIN}/role/list`,
    save: `${API_DOMAIN}/role/save`,
    delete: `${API_DOMAIN}/role/delete`,
    update: `${API_DOMAIN}/role/update`,
    permissions: `${API_DOMAIN}/menu/list`,
    detail: `${API_DOMAIN}/role/detail`,
    checkRoleName: `${API_DOMAIN}/role/checkRoleName`,
    getRoleDataPermInfo: `${API_DOMAIN}/role/getRoleDataPermInfo`,
    updateRoleDataPerm: `${API_DOMAIN}/role/updateRoleDataPerm`,
    getWorkViewPermList: `${API_DOMAIN}/role/getWorkViewPermList`,
    getDefaultRole: `${API_DOMAIN}/role/getDefaultRole`,
    setDefaultRole: `${API_DOMAIN}/role/setDefaultRole`,
  },
  account: {
    all: `${API_DOMAIN}/user/all`,
    list: `${API_DOMAIN}/user/list`,
    save: `${API_DOMAIN}/user/save`,
    delete: `${API_DOMAIN}/user/delete`,
    update: `${API_DOMAIN}/user/update`,
    detail: `${API_DOMAIN}/user/detail`,
    password: `${API_DOMAIN}/user/password`,
    hasPhoneOrEmail: `${API_DOMAIN}/user/hasPhoneOrEmail`,
    userList: `${API_DOMAIN}/wx/cp/department/user/list`,
    listContainOwn: `${API_DOMAIN}/user/listContainOwn`,
    oppoList: `${SSO_DOMAIN}/oppo/queryUser`,
  },
  wx: {
    callback: `${wxUrl}/wx/auth/callBack`,
  },
  enterPriseWx: {
    callback: `${API_DOMAIN}/wx/tp/auth/callBack`,
    qrcode: `${API_DOMAIN}/wx/tp/bind/qrcode`,
    checkBind: `${API_DOMAIN}/root/personalCenter/checkBind`,
    unBind: `${API_DOMAIN}/wx/tp/bind/unbind`,
    bind: `${API_DOMAIN}/wx/tp/bind/account`,
    getWxAgentConfig: `${API_DOMAIN}/wx/tp/getWxAgentConfig`,
  },
  app: {
    list: `${API_DOMAIN}/setting/app/list`,
    save: `${API_DOMAIN}/setting/app/save`,
    delete: `${API_DOMAIN}/setting/app/del`,
    edit: `${API_DOMAIN}/setting/app/edit`,
    option: `${API_DOMAIN}/setting/app/getCreatableAppList`,
  },
  msgTemplate: {
    customList: `${API_DOMAIN}/msgTemplate/list/page`,
    oaList: `${API_DOMAIN}/msgTemplate/list/mp`,
    mpList: `${API_DOMAIN}/msgTemplate/list/ma`,
    save: `${API_DOMAIN}/msgTemplate/save`,
    delete: `${API_DOMAIN}/msgTemplate/delete`,
    edit: `${API_DOMAIN}/msgTemplate/edit`,
    getCreatableMsgList: `${API_DOMAIN}/survey/pushChannel/getCreatableMsgList`,
  },
  userFilter: {
    info: `${API_DOMAIN}/userFilter/info`,
    save: `${API_DOMAIN}/userFilter/save`,
    list: `${API_DOMAIN}/userFilter/list`,
  },
  BI: {
    widgets: `${BI_DOMAIN}/api/v3/widgets`,
    views: `${BI_DOMAIN}/api/v3/views`,
    download: `${BI_DOMAIN}/api/v3/download`,
    checkWidgetName: `${BI_DOMAIN}/api/v3/widgets/checkWidgetName`,
    functionList: `${BI_DOMAIN}/api/v3/field/function/list`,
  },
  views: {
    list: `${BI_DOMAIN}/api/v3/views/viewsPreview`,
    remove: `${BI_DOMAIN}/api/v3/views/param/remove`,
    info: `${BI_DOMAIN}/api/v3/views/param/info`,
    saveOrUpdate: `${BI_DOMAIN}/api/v3/views/param/saveOrUpdate`,
    fieldList: `${BI_DOMAIN}/api/v3/views/param/fieldList`,
    infoData: `${BI_DOMAIN}/api/v3/views/infoData`,
    viewDataCount: `${BI_DOMAIN}/api/v3/views/viewDataCount`,
    deleteRS: `${BI_DOMAIN}/api/v3/views/deleteRS`,
    batchSaveRData: `${BI_DOMAIN}/api/v3/views/batchSaveRData`,
    getActionWorkViewParams: `${BI_DOMAIN}/api/v3/views/param/getActionWorkViewParams`,
  },
  filterConfig: {
    list: `${BI_DOMAIN}/api/v3/views/filterConfig/list`,
    remove: `${BI_DOMAIN}/api/v3/views/filterConfig/remove`,
    info: `${BI_DOMAIN}/api/v3/views/filterConfig/info`,
    updateStatus: `${BI_DOMAIN}/api/v3/views/filterConfig/updateStatus`,
    saveOrUpdate: `${BI_DOMAIN}/api/v3/views/filterConfig/saveOrUpdate`,
    getFilterData: `${BI_DOMAIN}/api/v3/views/filterConfig/getFilterData`,
  },
  nlp: {
    getNlpPoints: `${API_DOMAIN}/nlp/analytics/getNlpPoints`,
    updateAndEtlNlpData: `${API_DOMAIN}/nlp/analytics/updateAndEtlNlpData`,
    getAnswerClosedLoopStatus: `${API_DOMAIN}/nlp/analytics/getAnswerClosedLoopStatus`,
    updateAnswerClosedLoopStatus: `${API_DOMAIN}/nlp/analytics/updateAnswerClosedLoopStatus`,
  },
  tools: {
    download: `${TOOLS_DOMAIN}/download`,
    open: `${TOOLS_DOMAIN}/open`,
  },
  globalControl: {
    list: `${API_DOMAIN}/getDashboardConfig`,
    save: `${API_DOMAIN}/updateDashboardConfig`,
  },
  folder: {
    saveOrUpdate: `${API_DOMAIN}/analyticsFolder/saveOrUpdate`,
    remove: `${API_DOMAIN}/analyticsFolder/remove`,
    info: `${API_DOMAIN}/analyticsFolder/info`,
    list: `${API_DOMAIN}/analyticsFolder/list`,
    addOrDelete: `${API_DOMAIN}/analyticsFolder/addOrDelete`,
    getFolderDashboardList: `${API_DOMAIN}/getFolderDashboardList`,
    getDashBoardFolderInfo: `${API_DOMAIN}/getDashboardFolderInfo`,
  },
  touchpoint: {
    save: `${API_DOMAIN}/touchpoint/save`,
    delete: `${API_DOMAIN}/touchpoint/delete`,
    info: `${API_DOMAIN}/touchpoint/info`,
    list: `${API_DOMAIN}/touchpoint/list`,
    revert: `${API_DOMAIN}/touchpoint/revert`,
    loadData: `${API_DOMAIN}/touchpoint/loadData`,
    searchInInfo: `${API_DOMAIN}/touchpoint/searchInInfo`,
    analysis: `${API_DOMAIN}/indicators/getDashboardByIndicatorId`,
    updatePoint: `${API_DOMAIN}/touchpoint/updatePoint`,
  },
  wenjuan: {
    list: `${API_DOMAIN}/wj`,
    statisticsList: `${API_DOMAIN}/wj/statisticsList`,
    statisticsForAnswerList: `${API_DOMAIN}/wj/statisticsForAnswerList`,
    create: `${API_DOMAIN}/wj/create`,
    copy: `${API_DOMAIN}/wj/copy`,
    delete: `${API_DOMAIN}/wj/delete`,
    updateRemark: `${API_DOMAIN}/wj/updateRemark`,
  },
  dataTag: {
    list: `${API_DOMAIN}/dataTag/list`,
    info: `${API_DOMAIN}/dataTag/info`,
    saveOrUpdate: `${API_DOMAIN}/dataTag/saveOrUpdate`,
    delete: `${API_DOMAIN}/dataTag/delete`,
    tagOptions: `${API_DOMAIN}/dataTag/tagOptions`,
    tagOptionValues: `${API_DOMAIN}/dataTag/tagOptionValues`,
  },
  subscription: {
    save: `${API_DOMAIN}/subscribe/saveOrUpdate`,
    delete: `${API_DOMAIN}/subscribe/remove`,
    info: `${API_DOMAIN}/subscribe/info`,
    list: `${API_DOMAIN}/subscribe/list`,
    copy: `${API_DOMAIN}/subscribe/copy`,
    status: `${API_DOMAIN}/subscribe/changeStatus`,
  },
  audit: {
    list: `${API_DOMAIN}/audit/list`,
    save: `${API_DOMAIN}/audit/save`,
    pass: `${API_DOMAIN}/audit/pass`,
    reject: `${API_DOMAIN}/audit/reject`,
    info: `${API_DOMAIN}/audit/info`,
    account: `${API_DOMAIN}/audit/auditAccount`,
  },
  delete: {
    checkRelation: `${API_DOMAIN}/share/checkRelation`,
  },
  notice: {
    list: `${API_DOMAIN}/noticeCenter/list`,
    count: `${API_DOMAIN}/noticeCenter/count`,
    clear: `${API_DOMAIN}/noticeCenter/clear`,
    update: `${API_DOMAIN}/noticeCenter/update`,
  },
  operationLog: {
    list: `${API_DOMAIN}/operationLog/list`,
    userNameList: `${API_DOMAIN}/operationLog/userNameList`,
  },
  dataclean: {
    list: `${API_DOMAIN}/data/excludedRule/list`,
    delete: `${API_DOMAIN}/data/excludedRule/delete`,
    edit: `${API_DOMAIN}/data/excludedRule/saveOrUpdate`,
    getDetail: `${API_DOMAIN}/data/excludedRule/get`,
    getDefaultExcludedRule: `${API_DOMAIN}/data/excludedRule/getDefaultExcludedRule`,
    dimOptions: `${API_DOMAIN}/pack/dimOptions`,
    getBySurveyId: `${API_DOMAIN}/data/excludedRule/getBySurveyId`,
    bindSurvey: `${API_DOMAIN}/data/excludedRule/bindSurvey`,
    preview: `${API_DOMAIN}/data/excludedRule/preview`,
    changeStatus: `${API_DOMAIN}/data/excludedRule/setDefaultStatus`,
    bindView: `${API_DOMAIN}/data/excludedRule/bindView`,
  },
  target: {
    save: `${API_DOMAIN}/indicators/save`,
    update: `${API_DOMAIN}/indicators/update`,
    delete: `${API_DOMAIN}/indicators/delete`,
    list: `${API_DOMAIN}/indicators/list`,
    detail: `${API_DOMAIN}/indicators/detail`,
    calculate: `${API_DOMAIN}/indicators/calculate`,
  },
  // 黑名单
  blackList: {
    list: `${API_DOMAIN}/blackList/list`,
    save: `${API_DOMAIN}/blackList/save`,
    update: `${API_DOMAIN}/blackList/update`,
    delete: `${API_DOMAIN}/blackList/delete`,
    info: `${API_DOMAIN}/blackList/info`,
    blackListMember: `${API_DOMAIN}/blackListMember/list`,
    deleteBlackListMember: `${API_DOMAIN}/blackListMember/delete`,
  },
  // 黑名单用户列表
  blackListMember: {
    list: `${API_DOMAIN}/blackListMember/list`,
    delete: `${API_DOMAIN}/blackListMember/delete`,
    memberVisitorId: `${API_DOMAIN}/blackListMember/memberVisitorId`,
  },
  // 黑名单规则
  tagRule: {
    list: `${API_DOMAIN}/tagRule/list`,
    save: `${API_DOMAIN}/tagRule/save`,
    update: `${API_DOMAIN}/tagRule/update`,
    delete: `${API_DOMAIN}/tagRule/delete`,
    info: `${API_DOMAIN}/tagRule/info`,
    blockedNum: `${API_DOMAIN}/tagRule/blockedNum`,
  },
  // 调研设置
  feedbackGroup: {
    list: `${API_DOMAIN}/feedbackGroup/list`,
    saveOrUpdate: `${API_DOMAIN}/feedbackGroup/saveOrUpdate`,
    delete: `${API_DOMAIN}/feedbackGroup/delete`,
    info: `${API_DOMAIN}/feedbackGroup/info`,
    choose: `${API_DOMAIN}/feedback/choose`,
  },
  // 虚拟参数
  virtualField: {
    list: `${API_DOMAIN}/odsTransform/list`,
    saveOrUpdate: `${API_DOMAIN}/odsTransform/saveOrUpdate`,
    delete: `${API_DOMAIN}/odsTransform/remove`,
    info: `${API_DOMAIN}/odsTransform/info`,
  },
};

export { API_DOMAIN, SSO_DOMAIN, BI_DOMAIN, TOOLS_DOMAIN, JOURNEY_DOMAIN };
export default api;
