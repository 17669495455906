import React, { Fragment } from 'react';
import './index.sass';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Row, Col, Card, Menu, Collapse, Dropdown, Popconfirm, Input, Alert, Drawer, Select, Button, Popover, message, Divider } from 'antd';
import { Card as SelfCard } from '../../component/Card/Card';
import api from '../../api';
import util from '../../util';
import Loading from '../../component/Loading/Loading';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import CommonChart from '../../containers/Common/CommonChart';
import CommonTable from '../../containers/CommonTable';
import UserBehavior from './UserBehavior';
import intl from 'react-intl-universal';
import { warningStatusObj, handleStatusObj } from '../../Report/WarningList/warningConfig';

const isCustomVisitorInfo = process.env.REACT_APP_Custom_Visitor_Info;

// type三种类型:
// 1：访客日志，默认
// 2：预警
// 3：调研
const showKeys = process.env.REACT_APP_Custom_Show_Keys ? process.env.REACT_APP_Custom_Show_Keys.split(',') : null;
const showKeyLinks = process.env.REACT_APP_Custom_Show_Key_Links ? JSON.parse(process.env.REACT_APP_Custom_Show_Key_Links) : null;
const hideKeys = [
  'wechat_mp_open_id',
  'wechat_union_id',
  'WechatUnionID',
  'feedbackId',
  'id',
  'uid',
  'idvisitor',
  'login_id',
  'skipStartAnswer',
  'appid',
  'type',
  'state',
  'utm_source',
  'utm_campaign',

  'utm_medium',
  'utm_term',
  'utm_content',
  'sid',
  'code',
  'lastTime',
  'regionEN',
  'clubNameEN',
  'clubNameCN',
  'cityName',
  'PurchasingClubID',
  'Transactiontime',
  'TransactionDateTime',
];

const warningDetails = [
  { key: '解决状态', name: '解决状态' },
  // { key: '回访结果/行动计划/需求支持', name: '回访结果/行动计划/需求支持' },
  { key: 'lastSolveTime', name: '剩余处理时间' },
  { key: '关单人（格式：工号+姓名+职位）', name: '关单人（格式：工号+姓名+职位）' },
  { key: '责任方', name: '责任方' },
  { key: '问卷类型', name: '问卷类型' },
  { key: '问题类型', name: '问题类型' },
];

const customKeys = {
  loginID: '登录ID',
  name: '姓名',
  age: '年龄',
  born: '出生年月',
  city: '城市',
  gender: '性别',
  level: '等级',
  accountType: '账户类型',
  credit: '信用评级',
  integral: '积分数量',
  lastTime: '上一次访问时间',
  idvisitor: '用户id',
  risk: '风险等级',
  creditCards: '当前持有信用卡数量',
  reimbursement: '信用卡还款日',
  overseasMoneyTransfer: '是否有境外转账',
  registeredTime: '首次注册时间',
  updateTime: '用户更新时间',
  channel: '渠道',
  manager: '客户经理',
  branch: '所属分行',
  businessTravel: '是否为商旅客户',
  enterprise: '是否为企业客户',
  balance: '当前账户资金余额',
  branches: '开户营业网点',
  branchOfManager: '客户经理所属分行',
  productSum: '当前持有理财产品数',
  loanAmount: '申请贷款',
  loanType: '贷款类型',
  lines: '额度',
  bindBankCard: '是否绑定银行卡',
  transactionDate: '消费时间',
  clubUniqueID: '门店ID',
  clubName: '门店名',
  browserName: '浏览器',
  city: '城市',
  country: '国家',
  deviceBrand: '设备品牌',
  deviceModel: '设备类型',
  lastTime: '上次访问时间',
  osName: '操作系统',
  osVersion: '操作系统版本',
  province: '省份',
  resolution: '分辨率',
};
@observer
class Main extends React.Component {
  constructor() {
    super();
    this.surveyId = '';
    this.warningId = util.getQueryString('warningId') || util.getQueryString('id');
    this.answerId = util.getQueryString('answerId');
    this.state = {
      basicInfo: null, //基本信息
      feedbackList: null, //历史反馈列表
      feedbackModalDetail: { show: false, data: null }, //是否展开问卷详情弹窗
      chartData: null, //柱状图数据
      eventList: null, //事件序列列表
      showCommentDetail: false, //评论反馈是否展开问卷详情
      visitorId: util.getQueryString('visitorId'),
      // fromClosedLoop: util.getQueryString('from') ? true : false,
      fromClosedLoop: true,
      accepterList: [],
      // current: util.getQueryString('from'),
      current: util.getQueryString('from') || 'closed-up',
      popOverVisible: false,
      acceptor: '',
      currentStatus: '',
      currentList: [],
      showPop: false,
      edit: true,
      comment: [],
      editComment: '',
    };
  }
  @observable visitData;
  @observable visitorInfo;
  @observable livenessData = null;
  @observable counter = 0;
  @observable isShowModal = false;
  componentDidMount() {
    pageHeaderStore.hidePageHeader();
    let count = this.count;
    this.fetchData();
  }

  componentDidUpdate(preProps, prevState) {
    const { basicInfo } = this.state;
    if (prevState.basicInfo !== basicInfo) {
      if (this.state.fromClosedLoop) {
        this.getAccountList();
        this.getButtons();
      }
    }
  }

  fetchData() {
    // 获取访客基本信息;
    let visitorParam = {
      idsite: pageHeaderStore.parameter.idsite,
    };
    this.state.visitorId && (visitorParam.visitorId = this.state.visitorId);
    this.warningId && (visitorParam.warningId = this.warningId);
    this.answerId && (visitorParam.answerId = this.answerId);
    util.get(api.visitor.visitorInfo, visitorParam, (res) => {
      this.setState({
        basicInfo: res,
      });
      !this.state.visitorId &&
        (this.setState({
          visitorId: res.userInfo.idvisitor,
        }),
        this.getChartData(res.userInfo.idvisitor),
        this.getFeedbackList(null, null, null, null, res.userInfo.idvisitor));
      const comment = (res.warningInfo && res.warningInfo.infoData.userInfoData.comment) || [];
      comment.forEach((i) => {
        i.canEdit = res.warningInfo && i.transactorName === res.warningInfo.infoData.userInfoData.transactor;
        i.isEdit = false;
      });
      this.setState({ comment });
    });
    this.state.visitorId && (this.getChartData(), this.getFeedbackList());
  }

  @action.bound refresh() {
    this.counter++;
  }
  @action.bound surveyModalToggle(bool, id) {
    this.isShowModal = bool;
    this.surveyId = id;
  }

  getAccountList() {
    util.get(
      api.account.listContainOwn,
      {
        idsite: pageHeaderStore.parameter.idsite,
        pageSize: 100000,
        pageNum: 1,
      },
      (res) => {
        var data = [];
        res.data.forEach((item) => {
          data.push({ value: item.id, label: `${item.username}${item.realName ? `（${item.realName}）` : ''}` });
        });
        data.length && this.setState({ accepterList: data });
      }
    );
  }

  // handleEdit() {
  //   this.setState({ edit: true });
  // }

  // deletComment() {
  //   const { basicInfo } = this.state;
  //   util.post(
  //     api.warningLog.editComment,
  //     {
  //       id: basicInfo.warningInfo.id,
  //     },
  //     (res) => {
  //       // this.setState({ edit: false, comment: '' });
  //       this.setState({ comment: '' });
  //       message.success('删除成功');
  //     }
  //   );
  // }

  // handleCloseEdit = (index) => {
  //   const { comment } = this.state;
  //   this.setState({ editComment: '' });
  //   comment[index].isEdit = false;
  //   this.setState({ comment });
  // };

  // handleEdit = (index) => {
  //   const { comment } = this.state;
  //   const currentStatus = comment[index].isEdit;
  //   comment.forEach((i) => i.isEdit === false);
  //   if (!currentStatus) {
  //     comment[index].isEdit = true;
  //     this.setState({
  //       editComment: comment[index].comment,
  //     });
  //   }
  //   this.setState({ comment });
  // };

  handleCommentChange(value) {
    this.setState({ editComment: value });
  }

  handleChangeComment(isNew, commentId, index) {
    const { comment, basicInfo, editComment } = this.state;
    if (!editComment) {
      message.error('请输入评论');
      return;
    }
    // this.setState({ edit: false });
    util.post(
      api.warningLog.editComment,
      {
        id: basicInfo.warningInfo.id,
        commentId: isNew ? '' : commentId,
        newComment: editComment,
      },
      (res) => {
        this.setState({ editComment: '' });
        message.success('添加成功');
        this.fetchData();
      }
    );
  }

  // 事件序列堆叠图
  getChartData(visitorId) {
    const chartParam = { idsite: pageHeaderStore.parameter.idsite, visitorId: visitorId || this.state.visitorId };
    util.get(api.visitor.liveness, chartParam, (res) => {
      const activityTendencyData = res.activityTendencyData;
      const axis = [];
      const field = [];
      const legend = [];
      activityTendencyData &&
        activityTendencyData.map((item) => {
          axis.indexOf(item.date_day_name) < 0 && axis.push(item.date_day_name);
          field.indexOf(item.event_key) < 0 && field.push(item.event_key);
          legend.indexOf(item.event_name) < 0 && legend.push(item.event_name);
        });
      const arr = [];
      field.forEach((item) => {
        arr.push(0);
      });
      let data = axis.map((item) => {
        return JSON.parse(JSON.stringify(arr));
      });
      activityTendencyData &&
        activityTendencyData.forEach((item) => {
          const axisIndex = axis.indexOf(item.date_day_name);
          const fieldIndex = field.indexOf(item.event_key);
          data[axisIndex][fieldIndex] = item.eventOccurs;
        });
      this.setState({
        chartData: {
          axis,
          field,
          legend,
          data,
        },
      });
    });
  }

  //历史反馈列表
  getFeedbackList = (orderDesc = null, orderColumn = null, currentPage = 1, pageCount = 5, visitorId) => {
    this.setState({
      feedbackList: null,
    });
    const param = { idsite: pageHeaderStore.parameter.idsite, idvisitor: visitorId || this.state.visitorId, orderDesc, orderColumn, currentPage, pageCount };
    util.get(api.visitor.answerAndWarningList, param, (res) => {
      this.setState({
        feedbackList: res.data ? res : { data: [] },
      });
    });
  };

  getList(data) {
    var dataList = [];
    for (var key in data) {
      dataList.push({ visitTime: key, visitCount: data[key].visitCount, idvisitMapList: data[key].idvisitMapList });
    }
    return dataList;
  }

  surveyAddContent = (store) => {
    for (let i = 0; i < store.columns.length; i++) {
      let currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'surveyName') {
        if (store.parentData[i] && store.parentData[i].hasOwnProperty('answerId')) {
          let answerId = store.dataSource[i].answerId;
          store.columns[i].render = (text) => {
            return {
              children: <a onClick={() => this.surveyModalToggle(true, answerId)}>{text}</a>,
            };
          };
        }
      }
    }
  };

  handleSelect(value) {
    console.log(value);
    this.setState({
      acceptor: value,
    });
  }

  handleShowPop() {
    this.setState({
      showPop: true,
    });
  }

  handlePopConfirm() {
    this.changeStatus(6, true);
    this.setState({
      popOverVisible: false,
    });
  }

  handlePopCancel() {
    this.setState({
      popOverVisible: false,
    });
  }

  handleVisibleChange(visible) {
    this.setState({ popOverVisible: visible });
  }

  content = () => {
    const { accepterList } = this.state;
    return (
      <div>
        <Select
          showSearch
          className="sm-select warning-handle-select"
          onSelect={(value) => {
            this.handleSelect(value);
          }}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {accepterList.map((item) => {
            return (
              <Select.Option key={item.value.toString()} value={item.value} title={item.label}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
        <div className="warning-handle-btns">
          <Button
            type="primary"
            size="small"
            className="warning-handle-btn"
            onClick={() => {
              this.handlePopConfirm();
            }}>
            确定
          </Button>
          <Button
            size="small"
            className="warning-handle-btn warning-handle-cancel-btn"
            onClick={() => {
              this.handlePopCancel();
            }}>
            取消
          </Button>
        </div>
      </div>
    );
  };
  transfer = (children) => {
    const { popOverVisible } = this.state;
    return (
      <>
        <Popover
          title={'选择接收人'}
          trigger="click"
          placement="bottomLeft"
          content={this.content()}
          visible={popOverVisible}
          onVisibleChange={(visible) => {
            this.handleVisibleChange(visible);
          }}>
          {children}
        </Popover>
      </>
    );
  };
  changeStatus = (status, isTrans) => {
    const { acceptor, basicInfo } = this.state;
    let id = basicInfo.warningInfo.id;
    let params = {
      id,
      status: status,
    };
    if (status === 6 && isTrans) {
      if (!acceptor) {
        message.error('请选择转交人');
      }
      params.updatedBy = acceptor;
    }
    this.setState({
      currentList: [],
    });
    util.post(api.warningLog.edit, params, (res) => {
      message.success('操作成功');
      if (status === 6) {
        this.props.history.go(-1);
      }
      this.fetchData();
    });
  };

  getButtons() {
    const { current, basicInfo } = this.state;
    if (JSON.stringify(basicInfo) === '{}') {
      return;
    }
    if (current === 'closed-up') {
      if (basicInfo.warningInfo.warningStatus === 1 && !basicInfo.warningInfo.infoData.userInfoData.transactor) {
        this.setState({ currentStatus: '领取' });
        this.setState({ current: 'my-list' });
      }
    }
    if (current === 'my-list') {
      if (![3, 4, 5].includes(basicInfo.warningInfo.warningStatus)) {
        this.setState({ currentStatus: '完成', currentList: ['关闭', '挂起'], showPop: true });
      } else {
        this.setState({ currentStatus: '重打开', showPop: false });
      }
    }
  }

  menu = () => {
    const { currentList } = this.state;
    return (
      <Menu
        onClick={(e) => {
          this.handleClick('menu', e.key);
        }}>
        {currentList.map((item) => {
          return <Menu.Item key={item}>{item}</Menu.Item>;
        })}
      </Menu>
    );
  };

  handleClick(type, key) {
    const { currentStatus } = this.state;
    let status = 1;
    if (type === 'menu') {
      status = handleStatusObj[key];
    } else {
      status = handleStatusObj[currentStatus];
    }
    this.changeStatus(status);
  }

  //问卷详情收起展开
  showFeedbakModal = (answerId) => {
    this.setState({
      feedbackModalDetail: { show: !this.state.feedbackModalDetail.show, data: null },
    });
    answerId &&
      util.get(api.trigger.answerContent, { answerId: answerId }, (res) => {
        this.setState({
          feedbackModalDetail: { show: true, data: res },
        });
      });
  };

  //历史反馈列表addContent
  feedbakTableAddContent = (store) => {
    for (let i = 0; i < store.columns.length; i++) {
      let currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'surveyDetail') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: (
              <a className="color-link" onClick={() => this.showFeedbakModal(this.state.feedbackList.data[index].answerId)}>
                详情
              </a>
            ),
          };
        };
      }
      if (currentRowData === 'surveyName') {
        store.columns[i].render = (text, record, index) => {
          const visitorId = util.getQueryString('visitorId');
          const warningId = this.state.feedbackList.data[index].warningId;
          return {
            children: (
              <a
                href={`${window.location.origin}${window.location.pathname}?visitorId=${visitorId}&answerId=${this.state.feedbackList.data[index].answerId}${
                  warningId ? `&warningId=${warningId}` : ''
                }`}
                target="_blank">
                {text}
              </a>
            ),
          };
        };
      }
      if (currentRowData === 'isWarning') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: <span>{this.state.feedbackList.data[index].isWarning === true ? '是' : '否'}</span>,
          };
        };
      }
    }
  };

  renderEdit = (isNew, commentId, index) => {
    const { editComment } = this.state;
    return (
      <div>
        <Input.TextArea
          onChange={(e) => {
            this.handleCommentChange(e.target.value);
          }}
          value={editComment}
          rows={4}
          style={{ width: '400px' }}
        />
        <div>
          <Button
            type={'primary'}
            style={{ marginRight: '10px', marginTop: '5px' }}
            onClick={() => {
              this.handleChangeComment(isNew, commentId, index);
            }}>
            确认
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const {
      basicInfo,
      showCommentDetail,
      feedbackList,
      feedbackModalDetail,
      chartData,
      fromClosedLoop,
      current,
      showPop,
      currentStatus,
      edit,
      editComment,
      comment,
      currentList,
    } = this.state;
    return (
      <div className="visitor-info visitor-info-page" type="flex">
        {basicInfo ? (
          <Fragment>
            {basicInfo.warningInfo && basicInfo.warningInfo.warningName && (
              <SelfCard
                className="basic-card"
                card={{
                  title: '预警信息',
                  content: (
                    <Fragment>
                      <Alert
                        message={
                          <div className="warning-content">
                            <div className="warning-score">
                              <i
                                className={`iconfont ${
                                  parseInt(basicInfo.warningInfo.answerContent) <= 6
                                    ? 'icon-ai di'
                                    : parseInt(basicInfo.warningInfo.answerContent) <= 8
                                    ? 'icon-danding zhong'
                                    : 'icon-xi gao'
                                }`}
                              />
                              <div
                                className={
                                  parseInt(basicInfo.warningInfo.answerContent) <= 6
                                    ? 'di'
                                    : parseInt(basicInfo.warningInfo.answerContent) <= 8
                                    ? 'zhong'
                                    : 'gao'
                                }>
                                NPS {basicInfo.warningInfo.answerContent}
                              </div>
                            </div>

                            <div className="warning-info">
                              <div>
                                <div className="warning-info-item">
                                  <div className="visitor-info-title">预警题目</div>：<div className="color-link">{basicInfo.warningInfo.warningTopicName}</div>
                                </div>
                                <div className="warning-info-item">
                                  <div className="visitor-info-title">预警创建时间</div>：<div>{basicInfo.warningInfo.createdAt}</div>
                                </div>
                              </div>
                              <div>
                                <div className="warning-info-item">
                                  <div className="visitor-info-title">预警问卷</div>：<div>{basicInfo.warningInfo.warningSurveyName}</div>
                                </div>
                                <div className="warning-info-item">
                                  <div className="visitor-info-title">预警状态</div>：<div>{warningStatusObj[basicInfo.warningInfo.warningStatus]}</div>
                                </div>
                              </div>
                              <div>
                                <div className="warning-info-item">
                                  <div className="visitor-info-title">预警规则</div>：<div className="color-link">{basicInfo.warningInfo.warningName}</div>
                                </div>
                                {basicInfo.warningInfo.warningStatus == 3 && (
                                  <div className="warning-info-item">
                                    <div className="visitor-info-title">预警完成时间</div>：<div>{basicInfo.warningInfo.updatedAt}</div>
                                  </div>
                                )}
                              </div>

                              {basicInfo.warningInfo.clubName && (
                                <div>
                                  <div className="warning-info-item">
                                    <div className="visitor-info-title">门店名</div>：<div className="color-link">{basicInfo.warningInfo.clubName}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        type="warning"
                      />
                      {fromClosedLoop && (
                        <Alert
                          style={{ marginTop: 8 }}
                          message={
                            <div>
                              <Row>
                                <div>
                                  {current === 'my-list' && basicInfo.warningInfo.warningStatus !== 1 && (
                                    <div>处理人:{basicInfo.warningInfo.infoData.userInfoData.transactor || '-'}</div>
                                  )}
                                </div>
                                {!(basicInfo.warningInfo.warningStatus !== 1 && current === 'closed-up') && (
                                  <div>
                                    {showPop && this.transfer(<Button className="warning-detail-button">转交</Button>)}
                                    {currentList.length > 0 ? (
                                      <Dropdown.Button
                                        onClick={() => {
                                          this.handleClick('drop');
                                        }}
                                        overlay={this.menu}>
                                        {currentStatus}
                                      </Dropdown.Button>
                                    ) : (
                                      <Button
                                        className="warning-mobile-handle"
                                        onClick={() => {
                                          this.handleClick('drop');
                                        }}>
                                        {currentStatus}
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </Row>
                              {current === 'my-list' && [2].indexOf(basicInfo.warningInfo.warningStatus) > -1 && (
                                <Row>
                                  <div>处理情况：</div>
                                  <div style={{ width: '90%', marginTop: '-2px' }}>
                                    {this.renderEdit(true)}
                                    {!!comment.length &&
                                      comment.map((item, index) => {
                                        return (
                                          <div key={item.commentId}>
                                            <div>
                                              <div className="commentHeader">
                                                <div className="commentAuthor">{item.transactorName}</div>
                                                <div style={{ marginRight: '10px' }}>{item.date}</div>
                                                {/* {item.canEdit && (
                                                  <i
                                                    style={{ cursor: 'pointer' }}
                                                    className="iconfont icon-xinjianwenben"
                                                    onClick={() => {
                                                      this.handleEdit(index);
                                                    }}></i>
                                                )} */}
                                              </div>
                                              {/* {!item.isEdit && <div className="commentContent">{item.comment}</div>} */}
                                              <div className="commentContent">{item.comment}</div>
                                            </div>
                                            {/* {item.isEdit && this.renderEdit(false, item.commentId, index)} */}
                                            <Divider style={{ margin: '10px 0' }} />
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Row>
                              )}
                            </div>
                          }
                          type="warning"
                        />
                      )}
                      {basicInfo.warningInfo.warningSolve && (
                        <Fragment>
                          <Row type="flex" className="warning-detail">
                            {warningDetails.map((item, index) => {
                              return (
                                basicInfo.warningInfo.warningSolve[item.key] && (
                                  <Col md={12} xs={24} className="visitor-info-item" key={item.key}>
                                    <div className="visitor-info-title">{item.name}</div>：&nbsp;
                                    <div className="visitor-info-value">{basicInfo.warningInfo.warningSolve[item.key]}</div>
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                          <div className="visitor-info-item">
                            <div className="visitor-info-title">回访结果/行动计划/需求支持</div>：&nbsp;
                            <div className="visitor-info-value">{basicInfo.warningInfo.warningSolve['回访结果/行动计划/需求支持']}</div>
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  ),
                }}
              />
            )}
            {
              <SelfCard
                className="basic-card"
                card={{
                  title: '基础信息',
                  content: (
                    <Row type="flex">
                      {basicInfo.userInfo &&
                        (basicInfo.orderInfo && JSON.stringify(basicInfo.orderInfo) !== '{}' ? (
                          <Col md={8} xs={24}>
                            <h5 className="visitor-title">会员信息</h5>
                            <div>
                              {Object.keys(basicInfo.userInfo).map((item, index) => {
                                return (
                                  ((showKeys && showKeys.indexOf(item) > -1) || (!showKeys && hideKeys.indexOf(item) < 0)) && (
                                    <div className="visitor-info-item" key={basicInfo.userInfo[item] + index}>
                                      <div className="visitor-info-title">{basicInfo.paramNameMap[item] || customKeys[item] || item}</div>：&nbsp;
                                      <div className="visitor-info-value">
                                        {showKeyLinks && showKeyLinks[item] ? (
                                          <a href={`${showKeyLinks[item]}${basicInfo.userInfo[item]}`}>{basicInfo.userInfo[item]}</a>
                                        ) : (
                                          <>{basicInfo.userInfo[item]}</>
                                        )}
                                      </div>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </Col>
                        ) : (
                          <Col xs={24}>
                            <h5 className="visitor-title">{!isCustomVisitorInfo ? '用户信息' : '会员信息'}</h5>
                            <div>
                              {Object.keys(basicInfo.userInfo).map((item, index) => {
                                return (
                                  ((showKeys && showKeys.indexOf(item) > -1) || (!showKeys && hideKeys.indexOf(item) < 0) || !isCustomVisitorInfo) && (
                                    <Col md={8} xs={24} className="visitor-info-item" key={basicInfo.userInfo[item] + index}>
                                      <div className="visitor-info-title">{basicInfo.paramNameMap[item] || customKeys[item] || item}</div>：&nbsp;
                                      <div className="visitor-info-value">
                                        {showKeyLinks && showKeyLinks[item] ? (
                                          <a href={`${showKeyLinks[item]}${basicInfo.userInfo[item]}`}>{basicInfo.userInfo[item]}</a>
                                        ) : (
                                          <>{basicInfo.userInfo[item]}</>
                                        )}
                                      </div>
                                    </Col>
                                  )
                                );
                              })}
                            </div>
                          </Col>
                        ))}
                      {basicInfo.orderInfo && JSON.stringify(basicInfo.orderInfo) !== '{}' && (
                        <Col md={16} xs={24}>
                          <h5 className="visitor-title">订单信息</h5>
                          {Object.keys(basicInfo.orderInfo).map((item, index) => {
                            return (
                              ((showKeys && showKeys.indexOf(item) > -1) || (!showKeys && hideKeys.indexOf(item) < 0)) && (
                                <Col md={12} xs={24} className="visitor-info-item" key={basicInfo.orderInfo[item] + index}>
                                  <div className="visitor-info-title">{basicInfo.paramNameMap[item] || customKeys[item] || item}</div>：&nbsp;
                                  <div className="visitor-info-value">
                                    {showKeyLinks && showKeyLinks[item] ? (
                                      <a href={`${showKeyLinks[item]}${basicInfo.orderInfo[item]}`}>{basicInfo.orderInfo[item]}</a>
                                    ) : (
                                      <>{basicInfo.orderInfo[item]}</>
                                    )}
                                  </div>
                                </Col>
                              )
                            );
                          })}
                        </Col>
                      )}
                      {!isCustomVisitorInfo && basicInfo.otherInfo && JSON.stringify(basicInfo.otherInfo) !== '{}' && (
                        <Col md={16} xs={24} style={{ marginTop: '5px' }}>
                          <h5 className="visitor-title">预置用户属性</h5>
                          {Object.keys(basicInfo.otherInfo).map((item, index) => {
                            return (
                              ((showKeys && showKeys.indexOf(item) > -1) || (!showKeys && hideKeys.indexOf(item) < 0)) && (
                                <Col md={12} xs={24} className="visitor-info-item" key={basicInfo.otherInfo[item] + index}>
                                  <div className="visitor-info-title">{basicInfo.paramNameMap[item] || customKeys[item] || item}</div>：&nbsp;
                                  <div className="visitor-info-value">
                                    {showKeyLinks && showKeyLinks[item] ? (
                                      <a href={`${showKeyLinks[item]}${basicInfo.otherInfo[item]}`}>{basicInfo.otherInfo[item]}</a>
                                    ) : (
                                      <>{basicInfo.otherInfo[item]}</>
                                    )}
                                  </div>
                                </Col>
                              )
                            );
                          })}
                        </Col>
                      )}
                    </Row>
                  ),
                }}
              />
            }
            {basicInfo.sampleAnswerInfo && (
              <SelfCard
                className="feedbak-card"
                card={{
                  title: '评论反馈',
                  content: (
                    <Fragment>
                      {!showCommentDetail && (
                        <Fragment>
                          {!basicInfo.sampleAnswerInfo.topic_name && <p className="feedback-answer empty">暂无文本反馈</p>}
                          <p className="feedback-topic">{basicInfo.sampleAnswerInfo.nps_topic_name}</p>
                          <p className="feedback-answer">{basicInfo.sampleAnswerInfo.nps_answer_content}</p>
                          <p className="feedback-topic">{basicInfo.sampleAnswerInfo.topic_name}</p>
                          <p className="feedback-answer">{basicInfo.sampleAnswerInfo.answer_content}</p>
                        </Fragment>
                      )}
                      {showCommentDetail && basicInfo.answerList && (
                        <ul className="warning-survey">
                          {basicInfo.answerList.map((item, index) => {
                            return (
                              <li className="warning-survey-item" key={`${index}${item.topic_name}`}>
                                <p className="warning-survey-question">
                                  <span>{intl.get('Topic')}：</span>
                                  {item.topic_name}
                                </p>
                                <p className="warning-survey-answer">{item.answer_content}</p>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {/* <a
                        className="color-link"
                        onClick={() => {
                          this.setState({ showCommentDetail: !this.state.showCommentDetail });
                        }}>
                        {showCommentDetail ? '收起问卷详情' : '问卷详情'}
                      </a> */}
                    </Fragment>
                  ),
                }}
                collapsedInBottom={!isCustomVisitorInfo && util.isMobile()}
                collapsed={true}
                isCollapsed={!this.state.showCommentDetail}
                customCollapsedToggle={() => {
                  this.setState({ showCommentDetail: !this.state.showCommentDetail });
                }}
              />
            )}
          </Fragment>
        ) : (
          <Loading />
        )}
        <SelfCard
          card={{
            title: '历史反馈列表',
            content: feedbackList ? (
              <CommonTable
                fetchData={this.getFeedbackList}
                parentData={feedbackList}
                fields="surveyName,answerContent,replyTime,TransactionDateTime,isWarning,clubName,warningRole,surveyDetail"
                isSort={false}
                addContent={this.feedbakTableAddContent}
              />
            ) : (
              <Loading />
            ),
          }}></SelfCard>
        <Drawer title="调研详情" className="survey-detail-modal" visible={feedbackModalDetail.show} onClose={() => this.showFeedbakModal()} width="100vw">
          <ul className="warning-survey">
            {feedbackModalDetail.data ? (
              feedbackModalDetail.data.map((item, index) => {
                return (
                  <li className="warning-survey-item" key={`${index}${item.topic_name}`}>
                    <p className="warning-survey-question">
                      <span>{intl.get('Topic')}：</span>
                      {item.topic_name}
                    </p>
                    <p className="warning-survey-answer">{item.answer_content}</p>
                  </li>
                );
              })
            ) : (
              <Loading />
            )}
          </ul>
        </Drawer>
        {/* <SelfCard
          className="visitor-event-content"
          card={{
            title: '行为序列',
            content: (
              <Fragment>
                {chartData ? <CommonChart parentData={chartData} type="bar" barType="stack" showAll /> : <Loading />}
                {this.state.visitorId && <UserBehavior hideKeys={hideKeys} visitorId={this.state.visitorId} />}
              </Fragment>
            ),
          }}
          collapsedInBottom={!isCustomVisitorInfo && util.isMobile()}
          collapsed={true}
          defaultCollapsed={!isCustomVisitorInfo ? false : true}></SelfCard> */}
      </div>
    );
  }
}

export default Main;
