import EventOverview from '../../containers/EventOverview';

export default {
  title: '事件列表',
  components: [
    {
      isCard: true,
      title: '事件列表',
      className: 'old-layout-card',
      children: [
        {
          component: EventOverview,
        },
      ],
    },
  ],
};
