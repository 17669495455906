import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import util from '../../util';
import api from '../../api';
import CommonTable from '../CommonTable';
import CommonChart from '../Common/CommonChart';
import Loading from '../../component/Loading/Loading';
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';

@observer
class SurveyStatChart extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
      tableHeadData: [],
      fields: '',
    };
    this.lastDate = '';
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      if (PageHeaderStore.parameter.date !== this.lastDate) {
        this.fetchData(null, null, null, null);
        this.lastDate = PageHeaderStore.parameter.date;
      }
    });
  }

  fetchData = (orderDesc, orderColumn, currentPage, pageCount) => {
    this.setState({
      data: null,
    });
    const param = JSON.parse(JSON.stringify(this.props.param));
    currentPage && (param.pageNo = currentPage);
    pageCount && (param.pageSize = pageCount);

    const dateArr = PageHeaderStore.parameter.date.split(',');
    const dateFilter = [
      {
        name: 'reply_time',
        operator: '>=',
        sqlType: 'DATETIME',
        type: 'filter',
        value: `'${dateArr[0]}'`,
      },
      {
        name: 'reply_time',
        operator: '<=',
        sqlType: 'DATETIME',
        type: 'filter',
        value: `'${dateArr[1]}'`,
      },
    ];
    param.filters = param.filters.concat(dateFilter);
    util.post(
      `${api.BI.views}/${param.viewId}/getdata`,
      { ...param, fillGroup: true },
      (res) => {
        // 根据分页判断组装表格或柱状图数据
        param.pageNo ? this.setTableData(res.data) : this.setChartData(res.data);
      },
      true
    );
  };

  setTableData = (res) => {
    const { resultList, columns, pageSize, pageNo, totalCount } = res;
    this.setState({
      data: {
        data: resultList,
        page: {
          pageCount: pageSize,
          currentPage: pageNo,
          totalCount: totalCount,
          totalPage: totalCount ? parseInt(totalCount / pageSize) + 1 : 0,
        },
      },
      tableHeadData: [columns.map((item) => this.getTitle(item.name)), columns.map((item) => item.name)],
    });
  };

  setChartData = (res) => {
    const { param } = this.props;
    const { resultList, columns, pageSize, pageNo, totalCount } = res;
    const column = param.groups[0];
    const metrics = param.aggregators.map((item) => item.formula.split('AS ')[1]);
    const axis = [],
      data = [];
    const field = metrics;
    const legend = metrics.map((item) => this.getTitle(item));
    resultList.forEach((item) => {
      axis.push(item[column]);
      data.push(metrics.map((m) => item[m]));
    });
    this.setState({
      data: { axis, data, field, legend },
    });
  };

  getTitle = (field) => {
    const fieldsObj = {
      answer_content: '答案内容',
      reply_time: '答案提交时间',
      option_name: '选项内容',
      key_100000: '频数',
    };
    return fieldsObj[field];
  };

  render() {
    const { tableHeadData, data } = this.state;
    const { param } = this.props;
    return param.pageNo ? (
      <CommonTable fetchData={this.fetchData} parentData={data} tableHeadData={tableHeadData} isSort={false} />
    ) : data && data.data ? (
      <CommonChart parentData={data} type="bar" barType="horizontal" showAll />
    ) : (
      <Loading />
    );
  }
}

export default SurveyStatChart;
