/**
 * 图表类型
 *
 */
export const ChartTypes = {
  /**
   * 表格
   */
  Table: 1,

  /**
   * 折线图
   */
  Line: 2,

  /**
   * 条形图
   */
  Bar: 3,

  /**
   * 散点图
   */
  Scatter: 4,

  /**
   * 饼图
   */
  Pie: 5,

  /**
   * 地图
   */
  CMap: 7,

  /**
   * 平行坐标图
   */
  Parallel: 8,

  /**
   * 漏斗图
   */
  Funnel: 6,

  /**
   * 桑基图
   */
  Sankey: 9,

  /**
   * 雷达图
   */
  Radar: 10,

  /**
   * 词云图
   */
  WordCloud: 11,

  /**
   * 瀑布图
   */
  Waterfall: 12,

  /**
   * 翻牌器
   */
  Scorecard: 13,

  /**
   * 内嵌网页
   */
  Iframe: 14,

  /**
   * 富文本
   */
  RichText: 15,

  /**
   * 双Y轴
   */
  DoubleYAxis: 16,

  /**
   * 仪表盘
   */
  Gauge: 17,

  /**
   * 四象限图
   */
  Quadrant: 18,

  /**
   * 语义分析表格
   */
  TextTable: 19,

  /**
   * 大指标
   */
  Summary: 20,

  /**
   * 分组表
   */
  ExtendTable: 21,
  /**
   * 原始数据表格
   */
  NativeTable: 22,
  /**
   * 内容表格
   */
  ContentTable: 23,
};

export default ChartTypes;
