import React, { Component } from 'react';
import moment from 'moment';
import util from '../../../util';
import api from '../../../api';
import Loading from '../../../component/Loading/Loading';
import AnalysePathGroup from '../../AnalysePath/AnalysePathGroup';
import RemainChartGroup from '../../AnalyseRemain/RemainChartGroup';
import DistributionChartGroup from '../../AnalyseDistribution/DistributionChartGroup';
import { getInitialItemInfo } from '../../BIChart/util';
import BIStore from '../../BIChart/store';
import BIChart from '../../BIChart';

class CombineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardInfo: null,
    };
  }

  componentDidMount() {
    const { parentInfo } = this.props;
    if (this.props.type === 'edit' && parentInfo.type !== 10 && !parentInfo.customizeParam && !parentInfo.widgetId) {
      util.get(api.analysis.info, { ids: parentInfo.id || parentInfo.cardId }, (res) => {
        if (res) {
          this.setState({
            cardInfo: res[0],
          });
        }
      });
    } else {
      this.setState({
        cardInfo: parentInfo,
      });
    }
  }

  getChart(widgetInfo) {
    switch (widgetInfo.type) {
      case 2:
        return (
          <AnalysePathGroup
            chartType={widgetInfo.chartType}
            pathId={widgetInfo.cardId ? widgetInfo.cardId : widgetInfo.id}
            count={1}
            dims={widgetInfo.dims}
            dateObj={
              widgetInfo.customizeParam
                ? JSON.parse(widgetInfo.customizeParam).dateObj
                : {
                    lastDate: 'last7',
                    date: `${moment()
                      .subtract(8, 'days')
                      .format('YYYY-MM-DD')},${moment()
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD')}`,
                  }
            }
            id={widgetInfo.cardId}
            {...this.props}
          />
        );
      case 3:
        return (
          <RemainChartGroup
            chartType={widgetInfo.chartType}
            form={{
              dateObj: widgetInfo.customizeParam
                ? JSON.parse(widgetInfo.customizeParam).dateObj
                : {
                    lastDate: 'last7',
                    date: `${moment()
                      .subtract(8, 'days')
                      .format('YYYY-MM-DD')},${moment()
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD')}`,
                  },
              ruleContent: JSON.parse(widgetInfo.ruleContent),
              dim: [widgetInfo.dims && widgetInfo.dims.includes('date') ? '' : widgetInfo.dims],
              count: 1,
            }}
            id={widgetInfo.cardId}
            {...this.props}
          />
        );
      case 7:
        return (
          <DistributionChartGroup
            chartType={widgetInfo.chartType}
            form={{
              customizeParam: JSON.parse(widgetInfo.customizeParam),
              ruleContent: JSON.parse(widgetInfo.ruleContent),
              subDividedDimGroup: [widgetInfo.dims && widgetInfo.dims.includes('date') ? '' : widgetInfo.dims],
              filterObject: JSON.parse(widgetInfo.filterObject),
              count: 0,
            }}
            {...this.props}
          />
        );
      default:
        return <BIChart widgetInfo={widgetInfo} id={widgetInfo.id} {...this.props} />;
    }
  }

  render() {
    const { cardInfo } = this.state;
    return cardInfo ? this.getChart(cardInfo) : <Loading />;
  }
}

export default CombineChart;
