import React, { PureComponent } from 'react';
import SplitPane from '../../../../SplitPane';
import GlobalControlRelatedItemForm from './GlobalControlRelatedItemForm';
import GlobalControlRelatedViewForm from './GlobalControlRelatedViewForm';
import LocalControlRelatedInfoForm from './LocalControlRelatedInfoForm';
import BaseForm from './BaseForm';
import ValueForm from './ValueForm';
import { Form } from 'antd';
import { parseDefaultValue } from '../../util';
import { ControlFieldTypes, ControlPanelTypes, IS_RANGE_TYPE } from '../../constants';
import '../../Control.less';

class ControlForm extends PureComponent {
  componentDidMount() {
    const { controlBase, relatedItemList, relatedViewList } = this.props;
    this.initControlForm(controlBase, relatedItemList, relatedViewList);
  }

  componentDidUpdate(prevProps) {
    const { form, controlBase, relatedItemList, relatedViewList, formWillChangeValues } = this.props;
    if (controlBase.key !== prevProps.controlBase.key) {
      this.initControlForm(controlBase, relatedItemList, relatedViewList);
    }
    if (formWillChangeValues !== prevProps.formWillChangeValues) {
      form.setFieldsValue(formWillChangeValues);
    }
  }

  initControlForm(controlBase, relatedItemList, relatedViewList) {
    const { form } = this.props;
    const relatedItemFormValues = relatedItemList.reduce(
      (values, { id, checked, viewId, type }) => ({
        ...values,
        [`relatedItems[${id}].checked`]: checked,
        [`relatedItems[${id}].viewId`]: viewId,
        // [`relatedItems[${id}].id`]: view,
        [`relatedItems[${id}].type`]: type,
      }),
      {}
    );
    const relatedViewFormValues = relatedViewList.reduce(
      (values, { id, fieldType, fields }) => ({
        ...values,
        [`relatedViews[${id}].fieldType`]: fieldType,
        [`relatedViews[${id}].fields`]: fields ? (IS_RANGE_TYPE[controlBase.type] && fieldType === ControlFieldTypes.Variable ? fields : fields[0]) : void 0,
      }),
      {}
    );
    form.resetFields();
    form.setFieldsValue({
      ...controlBase,
      ...parseDefaultValue(controlBase),
      ...relatedItemFormValues,
      ...relatedViewFormValues,
    });
  }

  render() {
    const {
      form,
      type,
      views,
      formedViews,
      controls,
      controlBase,
      relatedItemList,
      relatedViewList,
      defaultValueOptions,
      defaultValueLoading,
      onItemCheck,
      onCheckAll,
      onFieldTypeChange,
      onControlTypeChange,
      onMultipleSettingChange,
      onSliderPropChange,
      onOptionTypeChange,
      onValueViewChange,
      onDefaultValueTypeChange,
      onGetDefaultValueOptions,
      onCommonPropChange,
      onOpenOptionModal,
      onDeleteOption,
      onFieldChange,
    } = this.props;

    const commonForm = (
      <>
        <BaseForm
          form={form}
          controls={controls}
          controlBase={controlBase}
          onControlTypeChange={onControlTypeChange}
          onMultipleSettingChange={onMultipleSettingChange}
          onSliderPropChange={onSliderPropChange}
          onCommonPropChange={onCommonPropChange}
        />
        <ValueForm
          form={form}
          views={views}
          formedViews={formedViews}
          controlBase={controlBase}
          defaultValueOptions={defaultValueOptions}
          defaultValueLoading={defaultValueLoading}
          onOptionTypeChange={onOptionTypeChange}
          onValueViewChange={onValueViewChange}
          onDefaultValueTypeChange={onDefaultValueTypeChange}
          onGetDefaultValueOptions={onGetDefaultValueOptions}
          onCommonPropChange={onCommonPropChange}
          onOpenOptionModal={onOpenOptionModal}
          onDeleteOption={onDeleteOption}
        />
      </>
    );
    const compositeForm =
      type === ControlPanelTypes.Global ? (
        <SplitPane type="horizontal" initialSize={300} minSize={300} maxSize={480} className={'splitPanel'} spliter>
          <div className={'relatedForm'}>
            <GlobalControlRelatedItemForm form={form} relatedItems={relatedItemList} onItemCheck={onItemCheck} onCheckAll={onCheckAll} />
          </div>
          <SplitPane type="horizontal" initialSize={300} minSize={300} maxSize={480} className={'splitPanel'} spliter>
            <div className={'relatedForm'}>
              <GlobalControlRelatedViewForm
                form={form}
                relatedViews={relatedViewList}
                controlType={controlBase.type}
                optionWithVariable={controlBase.optionWithVariable}
                onFieldTypeChange={onFieldTypeChange}
                onFieldChange={onFieldChange}
              />
            </div>
            <div className={'commonForm'}>{commonForm}</div>
          </SplitPane>
        </SplitPane>
      ) : (
        <div className={'commonForm'}>
          <LocalControlRelatedInfoForm
            form={form}
            relatedView={relatedViewList[0]}
            controlType={controlBase.type}
            optionWithVariable={controlBase.optionWithVariable}
            onFieldTypeChange={onFieldTypeChange}
          />
          {commonForm}
        </div>
      );

    return <Form className={'controlForm'}>{compositeForm}</Form>;
  }
}

export default Form.create()(ControlForm);
