import React, { Component } from 'react';
import { Select } from 'antd';
import pageHeaderStore from '../../../../component/PageHeader/PageHeaderStore';
import CommonChart from '../../../../containers/Common/CommonChart';
import util from '../../../../util';
import Loading from '../../../../component/Loading/Loading';
import manageUtil from '../../../../Product/Management/util';
import legendUtil from '../../../../utils/legendUtil';
import mathUtil from '../../../../utils/mathUtil';

import { observer } from 'mobx-react';
import { observable, autorun, toJS, action } from 'mobx';
import api from '../../../../api';
import moment from 'moment';
import UXSRangePicker from '../../../../component/UXSRangePicker';
@observer
export default class AnalysePathFunnel extends Component {
  constructor(props) {
    super(props);
    this.pathId = this.props.pathId;
    this.parentData = this.props.parentData;
    this.lastCurrentCommonFilterObject = [];
  }
  @observable isLoading = true;
  @observable cacheData;
  @observable data = { data: [], page: [] };
  @observable fieldArr;
  @observable chartTarget = 'funnelStepConversionVisitors';
  componentDidMount() {
    this.disposer = autorun(() => {
      let reloadBoardCount = pageHeaderStore.reloadBoardCount;
      let dateObj = this.props.dateObj;
      let commonFilterObject = pageHeaderStore.commonFilterObject;
      const currentCommonFilterObject = toJS(pageHeaderStore.getCommonFilter(this.props.pathId));

      clearTimeout(this.delay);

      if (
        !this.lastLayoutChangeCount ||
        this.lastLayoutChangeCount === this.props.layoutChangeCount ||
        JSON.stringify(currentCommonFilterObject) !== JSON.stringify(this.lastCurrentCommonFilterObject)
      ) {
        this.delay = setTimeout(() => {
          if (this.props.dataFromParentData) {
            this.cacheData = this.parentData;
            this.fieldArr = Object.keys(toJS(this.cacheData)[0]).filter((fieldItem) => {
              if (fieldItem.includes('stepName') || fieldItem.includes('_key') || fieldItem.includes('_name')) {
                return false;
              }

              return true;
            });

            this.changeDataType({ key: this.chartTarget });
          } else {
            this.fetchData(this.cacheData, this.fieldArr);
          }
        }, 30);
        this.lastCurrentCommonFilterObject = currentCommonFilterObject;
      }
      this.lastLayoutChangeCount !== this.props.layoutChangeCount && (this.lastLayoutChangeCount = this.props.layoutChangeCount);
    });
  }
  componentWillUnmount() {
    this.data = { data: [], page: [] };
    this.disposer && this.disposer();
  }
  @action.bound changeDataType(value) {
    this.isLoading = true;
    setTimeout(() => {
      this.chartTarget = value.key;
      this.data = manageUtil.formulaChartDataByTarget(this.chartTarget, `${this.props.dims},funnelSteps`, null, toJS(this.cacheData));
      this.isLoading = false;
    }, 100);
  }
  @action.bound fetchData = (orderDesc, orderColumn, currentPage = 1, pageCount = 10) => {
    let param = {
      idsite: pageHeaderStore.parameter.idsite,
      date:
        pageHeaderStore.isUseParameterDate && !this.props.setDateObj && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : this.props.dateObj
          ? this.props.dateObj.lastDate
            ? mathUtil.keyToDate(this.props.dateObj.lastDate)
            : this.props.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      orderDesc: true,
      dims: this.props.dims,
      pathId: this.props.pathId,
      actionType: this.props.actionType,
      orderColumn: `${this.props.dims}_key`,
      workViewFilters: this.props.pathId ? pageHeaderStore.getCommonFilter(this.props.pathId) : '',
    };
    if (Number(pageHeaderStore.parameter.userGroupId) > 0) {
      param['userGroupId'] = pageHeaderStore.parameter.userGroupId;
    }
    this.isLoading = true;
    util.get(
      api.pack.analyticsPath,
      {
        ...param,
      },
      (res) => {
        res &&
          ((this.cacheData = res),
          (this.fieldArr = Object.keys(toJS(this.cacheData)[0]).filter((fieldItem) => {
            if (fieldItem.includes('stepName') || fieldItem.includes('_key') || fieldItem.includes('_name')) {
              return false;
            }

            return true;
          })),
          this.changeDataType({ key: this.chartTarget }));
      }
    );
  };
  render() {
    let date = `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`;
    let dateStr =
      pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
        ? pageHeaderStore.parameter.date.replace(/-/g, '/').replace(',', ' ~ ')
        : !this.props.setDateObj &&
          (this.props.dateObj
            ? this.props.dateObj.lastDate
              ? mathUtil
                  .keyToDate(this.props.dateObj.lastDate)
                  .replace(/-/g, '/')
                  .replace(',', ' ~ ')
              : this.props.dateObj.date.replace(/-/g, '/').replace(',', ' ~ ')
            : date.replace(/-/g, '/').replace(',', ' ~ '));
    return (
      <React.Fragment>
        {/* 编辑的时候才显示控件，看板时为文字 */}
        {this.props.setDateObj ? (
          this.props.isLoading ? (
            <Loading size="small" />
          ) : (
            <UXSRangePicker
              useDateByStore={false}
              getDateAndlastDate={this.props.setDateObj}
              date={this.props.dateObj ? this.props.dateObj.date : date}
              lastDate={this.props.dateObj ? this.props.dateObj.lastDate : ''}
              isBoardPicker={true}
            />
          )
        ) : (
          <p className="board-chart-date">{dateStr}</p>
        )}
        {this.fieldArr ? (
          <Select
            size="small"
            style={{ width: 96, fontWeight: 400, marginTop: 16 }}
            labelInValue
            border={false}
            value={{ key: this.chartTarget }}
            onChange={this.changeDataType}>
            {this.fieldArr.map((item, index) => (
              <Select.Option value={item} key={index}>
                {legendUtil.getTitle(item)}
              </Select.Option>
            ))}
          </Select>
        ) : null}
        {!this.isLoading ? <CommonChart fullHeight={true} showAll parentData={toJS(this.data)}></CommonChart> : <Loading></Loading>}
      </React.Fragment>
    );

    // return !this.isLoading ? <div>1</div> : <Loading></Loading>;
  }
}
