import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import api from '../../../api';
import util from '../../../util';
import pageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import Loading from '../../../component/Loading/Loading';
import TableNullData from '../../../component/TableNullData';
import { Collapse, Row, Col, Cascader, Button, Radio } from 'antd';
import SelectStore from '../../../component/EventSelect/store';
import UXSRangePicker from '../../../component/UXSRangePicker';
import mathUtil from '../../../utils/mathUtil';
import MobileCalander from '../../../component/MobileCalandar';
import moment from 'moment';

const Panel = Collapse.Panel;

@observer
class UserBehavior extends React.Component {
  constructor(props) {
    super(props);
    this.actionId = '';
    this.state = {
      data: [],
      paramNameMap: { action_type: '事件类型', action_name: '事件名', actionId: '事件Id', msgTime: '事件时间' },
      isLoading: false,
      hasMore: true,
      eventName: '[]',
      dateObj: { date: '', lastDate: '' }, //时间
    };
  }

  componentDidMount() {
    this.init();
    pageHeaderStore.getEventList();
  }
  componentDidUpdate(prevProps, preState) {
    if (this.state.dateObj.date !== preState.dateObj.date) {
      this.init();
    }
  }
  componentWillUnmount() {
    // this.disposer()
  }
  init = () => {
    this.actionId = '';
    this.setState({ hasMore: true, data: [] });
    setTimeout(() => {
      this.fetchData();
    }, 0);
  };
  loadMore = () => {
    this.fetchData();
  };
  fetchData = () => {
    this.setState({ isLoading: true }),
      util.get(
        api.visitor.eventInfo,
        {
          idsite: pageHeaderStore.parameter.idsite,
          date: this.state.dateObj.date,
          idvisitor: this.props.visitorId,
          count: 10,
          actionId: this.actionId,
          eventName: JSON.parse(this.state.eventName)[1] || '',
        },
        (res) => {
          const data = res.data;
          data.length > 0 && (this.actionId = data[data.length - 1].otherInfo.actionId);
          data.length < 10 && this.setState({ hasMore: false });
          this.setState({
            data: [...this.state.data, ...data],
            paramNameMap: { ...this.state.paramNameMap, ...res.paramNameMap },
            isLoading: false,
          });
        }
      );
  };
  eventChange = (value) => {
    value;
    this.setState({ eventName: JSON.stringify(value[1] && value[1] !== '访问' && value[1] !== '任意事件' ? value : []) });
    this.init();
  };
  onRadioChange = (e) => {
    this.setState({ eventName: e.target.value });
    this.init();
  };
  getDateAndlastDate = (lastDate, date) => {
    this.setState({ dateObj: { ...this.state.dateObj, lastDate: lastDate ? lastDate : '', date: lastDate ? mathUtil.keyToDate(lastDate) : date } });
  };

  render() {
    const { data, isLoading, hasMore, eventName, paramNameMap, dateObj } = this.state;
    const { hideKeys } = this.props;
    return (
      <Fragment>
        <div className="visitor-event-operate">
          <Radio.Group onChange={this.onRadioChange} value={eventName}>
            <Radio.Button value={JSON.stringify([])}>全部</Radio.Button>
            <Radio.Button value={JSON.stringify(['default', '$AnswerSurvey'])}>调研</Radio.Button>
            <Radio.Button value={JSON.stringify(['default', '$Forewarning'])}>预警</Radio.Button>
            <Radio.Button value={JSON.stringify(['customize', '下订单'])}>订单</Radio.Button>
          </Radio.Group>
          {SelectStore.eventSelected ? (
            <Cascader
              displayRender={(label) => {
                if (label.length === 2) {
                  return `${label[1]}`;
                }
              }}
              onChange={(value) => {
                this.eventChange(value);
              }}
              value={JSON.parse(eventName)}
              options={[...SelectStore.eventSelected]}
              placeholder="选择事件"
            />
          ) : (
            <Loading size="small" />
          )}
          {util.isMobile() ? (
            <MobileCalander
              type="datetime"
              value={[moment(dateObj.date.split(',')[0]), moment(dateObj.date.split(',')[1])]}
              dateChange={(lastDate, dateArr) => this.getDateAndlastDate(null, dateArr.join(','))}
            />
          ) : (
            <UXSRangePicker
              getDateAndlastDate={this.getDateAndlastDate}
              useDateByStore={false}
              lastDate={dateObj.lastDate || ''}
              date={dateObj.date || ''}
              isBoardPicker={true}></UXSRangePicker>
          )}
        </div>
        <Collapse className="visitor-event-list" bordered={false} defaultActiveKey={['0']}>
          {data.length
            ? data.map((item, index) => {
                return (
                  <Panel
                    forceRender={false}
                    header={
                      <span>
                        <i
                          className={`iconfont ${
                            item.eventKey.indexOf('预警') > -1 ? 'icon-yujing' : item.eventKey.indexOf('订单') > -1 ? 'icon-dingdan' : 'icon-tiaoyan'
                          }`}
                        />
                        {item.eventKey}
                      </span>
                    }
                    key={index === 0 ? '0' : `${item.eventKey}${index}`}>
                    <Row className="event-info" gutter={32}>
                      {item.actionInfo && Object.keys(item.actionInfo).length !== 0 && (
                        <Fragment>
                          <Col span={24}>
                            <h5 className="info-category-title">事件属性</h5>
                          </Col>
                          <Row>
                            {Object.keys(item.actionInfo).map((e) => {
                              return (
                                hideKeys.indexOf(item) < 0 && (
                                  <Col xs={24} className="event-info-item">
                                    <div className="visitor-info-title">{paramNameMap[e] || e}：</div>
                                    <div className="visitor-info-value">{item.actionInfo[e] && item.actionInfo[e].toString()}</div>
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                        </Fragment>
                      )}
                      {item.actionInfo && Object.keys(item.otherInfo).length !== 0 && (
                        <Fragment>
                          <Col span={24}>
                            <h5 className="info-category-title">访问属性</h5>
                          </Col>
                          <Row>
                            {Object.keys(item.otherInfo).map((e) => {
                              return (
                                hideKeys.indexOf(item) < 0 && (
                                  <Col md={12} xs={24} className="event-info-item">
                                    <div className="visitor-info-title">{paramNameMap[e] || e}：</div>
                                    <div className="visitor-info-value">{item.otherInfo[e] && item.otherInfo[e].toString()}</div>
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                        </Fragment>
                      )}
                    </Row>
                  </Panel>
                );
              })
            : !isLoading && !hasMore && <TableNullData />}
        </Collapse>
        {!isLoading && hasMore && (
          <Button type="primary" onClick={this.fetchData}>
            点击加载更多
          </Button>
        )}
        {isLoading && hasMore && <Loading />}
      </Fragment>
    );
  }
}

export default UserBehavior;
