import moment from 'moment';
import pageHeaderStore from '../component/PageHeader/PageHeaderStore';

export default {
  // 简化页面维度中的url
  spliceHttpAndDomin(text) {
    if ((String(text).includes('https:') || String(text).includes('http:')) && text.indexOf('http') === 0) {
      const newText = text.split('//')[1].split('/');
      newText.splice(0, 1);
      return `/${newText.join('/')}`;
    }
    return text;
  },
  // 数据单位只有个数 百分比 时间单位
  percentFrom04f(floatNum) {
    const val = parseFloat(floatNum);

    // if (Number.isNaN(val)) return '-';
    if (Number.isNaN(val)) return '-';
    if (Number(floatNum) === 0) return '0.0%';
    return `${(floatNum * 100).toFixed(2)}%`;
  },
  formateUnit(val, unit) {
    if (val / 10000 >= 1 && val / 10000000 < 1) {
      val = Math.round((val / 10000) * 100) / 100;
      return [val, '万'];
    }
    if (val / 10000000 >= 1) {
      val = Math.round((val / 10000000) * 100) / 100;
      return [val, '千万'];
    }
    return [this.numberFormate(val), unit];
  },
  // 数字单位加逗号
  numberFormate(value) {
    const array = String(parseInt(value)).split('');
    let i = 3;
    let j = 0;
    while (i < array.length) {
      if (value / Math.pow(10, i) >= 1) {
        array.splice(-i - j, 0, ',');
        array.join('');
      }
      j += 1;
      i += 3;
    }
    return array.join('');
  },
  // 数字删除逗号
  numberRecover(value) {
    const num = value.replace(/,/g, '');
    return parseInt(num, 10);
  },
  // 格式化单位函数
  formatVal(target, value) {
    if (value === '-') return value;
    if (!target) return this.numberFormate(value);
    if (target.split('-').length === 2) {
      target = target.split('-')[1];
    }
    if (target.split('~').length > 1) {
      target = target.split('~')[target.split('~').length - 1];
    }
    // 新接口用到的变量

    if (value === null || value === undefined || value === '' || Number.isNaN(value)) {
      return '-';
    }
    // 新接口时间指标转换格式的查询字典
    // 对应停留时长，平均页面停留时长，平均加载时长,平均触发时长
    const version_2_timeFormateTargetsEN = [
      'visitAvgTime',
      'pageVisitAvgTime',
      'funnelConversionAvgTime',
      'eventAvgTime',
      'funnelStepConversionAvgTime',
      'duration',
      'surveyAlltimeAvgTime',
      'visitorVisitAvgTime',
    ];
    const version_2_timeFormateTargetsCN = ['平均转化时长', '平均完成时长', '停留时长', '平均页面访问时长'];
    const milliSecondTimeFormateCn = ['平均加载时长'];
    const milliSecondTimeFormateEn = ['loadAvgTime'];
    // 新接口
    if (milliSecondTimeFormateCn.indexOf(target) > -1 || milliSecondTimeFormateEn.indexOf(target) > -1) {
      return `${Number(value).toFixed(2)}毫秒`;
    }
    if (version_2_timeFormateTargetsEN.indexOf(target) > -1 || version_2_timeFormateTargetsCN.indexOf(target) > -1) {
      if (isNaN(Number(value)) === false) {
        const time = this.formatTime(value);
        return time;
      }
    }
    const percentFormateTargetsEN = [
      'bounceRate',
      'exitRate',
      'funnelConversionRate',
      'funnelConversionSiteRate',
      'funnelStepConversionRate',
      'funnelStepConversionSiteRate',
      'out_rate',
      'period_rate',
      'bring_pv_rate',
      'uniq_visitors_rate',
      'nb_visitors_rate',
    ];
    const percentFormateTargetsCN = ['跳出率', '退出率', '转化率', '月活/年活', '带来浏览量占比', '独立访客数占比', '访客数占比', 'NPS', '汇总'];
    const formPercentFormateTargetsEN = [
      'surveyAlltimeFinishRate',
      'starter_rate',
      'submitter_rate',
      'reSubmitter_rate',
      'conversion_rate',
      'site_conversion_rate',
      'radioUv',
      'pagePathBounceRate',
      'pagePathUvRatio',
      'ratio',
    ];
    const formPercentFormateTargetsCN = ['表单填写率', '表单提交率', '表单重新提交率', '漏斗转化率', '转化率'];
    if (percentFormateTargetsEN.indexOf(target) > -1 || percentFormateTargetsCN.indexOf(target) > -1) {
      return this.percentFrom04f(value);
    }
    // 转化百分比的判断
    if (formPercentFormateTargetsCN.indexOf(target) > -1 || formPercentFormateTargetsEN.indexOf(target) > -1) {
      return `${(value * 100).toFixed(2)}%`;
    }
    // 如果value属于整数返回这个格式
    if (parseInt(Number(value), 10) === Number(value)) {
      if (Number.isInteger(parseInt(value), 10) === true) {
        return this.numberFormate(value);
      }
    }
    if (!Number.isNaN(Number(value)) && String(value).indexOf('.') > 0) {
      return Number(value).toFixed(1);
    }

    return value;
  },

  formatRgbaAlpha(rate) {
    const alpha = rate.toString().indexOf('%') > -1 ? parseFloat(rate.split('%')[0]) / 100 : parseFloat(rate);
    return alpha;
  },

  toPercent(point) {
    if (point !== 0) {
      let str = Number(point * 100).toFixed(2);
      str += '%';
      return str;
    }
    return point;
  },
  formatTime(value, toArray) {
    const version_2_secNum = Number(value);
    const version_2_minutesNum = Math.floor(version_2_secNum / 60);
    const version_2_seconds = Math.ceil(version_2_secNum - version_2_minutesNum * 60);
    const version_2_hours = Math.floor(version_2_minutesNum / 60);
    const version_2_minutes = Math.floor(version_2_minutesNum - version_2_hours * 60);

    if (toArray) {
      if (version_2_hours > 0 && version_2_seconds >= 1) {
        return [`${version_2_hours}`, '时', `${version_2_minutes}`, '分', `${version_2_seconds}`, '秒'];
      }
      if (version_2_hours > 0 && version_2_minutes >= 1) {
        return [`${version_2_hours}`, '时', `${version_2_minutes}`, '分'];
      }
      if (version_2_hours > 0) {
        return [`${version_2_hours}`, '时'];
      }
      if (version_2_minutes > 0 && version_2_seconds >= 1) {
        return [`${version_2_minutes}`, '分', `${version_2_seconds}`, '秒'];
      }
      if (version_2_minutes > 0 && version_2_seconds < 1) {
        return [`${version_2_minutes}`, '分'];
      }
      return [`${Number(version_2_secNum).toFixed(2)}`, '秒'];
    }
    if (version_2_hours > 0 && version_2_seconds >= 1) {
      return `${version_2_hours}时${version_2_minutes}分${version_2_seconds}秒`;
    }
    if (version_2_hours > 0 && version_2_minutes >= 1) {
      return `${version_2_hours}时${version_2_minutes}分`;
    }
    if (version_2_hours > 0) {
      return `${version_2_hours}时`;
    }
    if (version_2_minutes > 0 && version_2_seconds >= 1) {
      return `${version_2_minutes}分${version_2_seconds}秒`;
    }
    if (version_2_minutes > 0 && version_2_seconds < 1) {
      return `${version_2_minutes}分`;
    }
    return `${Number(version_2_secNum).toFixed(2)}秒`;
  },
  formatMiniSecond(value, toArray) {
    if (toArray === true) {
      return [`${Number(value / 1000).toFixed(2)}`, '秒'];
    }
    return `${Number(value / 1000).toFixed(2)}秒`;
  },
  judgeTime(val) {
    let str = String(val);
    if (String(val).indexOf(':') > -1) {
      str = str.split(':');
      const hours = Number(str[0]);
      const minutes = Number(str[1]);
      const seconds = Number(str[2]);
      if (hours >= 0 && minutes > 0) {
        if (seconds > 0) {
          return `${hours * 60 + minutes}分${seconds}秒`;
        }
        return `${hours * 60 + minutes}分`;
      }
      if (minutes === 0 && seconds > 0) {
        return `${seconds}秒`;
      }
      return `0`;
    }
  },
  // 对象数组去重
  removeRepeat(arr, key) {
    const obj = {};
    const newArr = [];
    arr.forEach((item) => {
      obj[item[key]] = item;
    });
    for (const i in obj) {
      newArr.push(obj[i]);
    }
    return newArr;
  },
  // 环比时间段的推移函数
  formateDateText(value) {
    const date = value.split(',');
    const showDate1 = date[0].split('-').join('.');
    const showDate2 = date[1].split('-').join('.');
    return showDate1.concat('-', showDate2);
  },
  formatDate(value) {
    const date = value.split(',');
    const firstDate = date[0].split('-');
    const secondDate = date[1].split('-');
    const date1 = new Date(Number(firstDate[0]), Number(firstDate[1]) - 1, Number(firstDate[2]));
    const date2 = new Date(Number(secondDate[0]), Number(secondDate[1]) - 1, Number(secondDate[2]));
    const dateDifference = (date2 - date1) / (1000 * 60 * 60 * 24) + 1;
    const changeDate1 = new Date(date1 - dateDifference * (1000 * 60 * 60 * 24)).toLocaleDateString('zh-CN');
    const changeDate2 = new Date(date2 - dateDifference * (1000 * 60 * 60 * 24)).toLocaleDateString('zh-CN');
    const showDate1 = changeDate1.split('/').join('.');
    const showDate2 = changeDate2.split('/').join('.');
    return showDate1.concat('-', showDate2);
  },
  formateDateString(value) {
    let arr = [];
    if (value.indexOf('/') > -1) {
      arr = value.split('/').map((item) => {
        if (parseInt(item, 10) < 10) {
          return `0${item}`;
        }
        return item;
      });
    } else if (value.indexOf('-') > -1) {
      arr = value.split('-').map((item) => {
        if (parseInt(item, 10) < 10) {
          return `0${item}`;
        }
        return item;
      });
    }
    return arr.join('-');
  },
  // 将targets转换成charts select options
  getPartialData(data, start, end) {
    let partialData;
    partialData = {
      axis: data.axis,
      data: data.data.map((item) => [...item.slice(start, end), item[item.length - 1]]),
      field: data.field.slice(start, end),
      legend: data.legend.slice(start, end),
    };
    return partialData;
  },

  // 格式后台commonsummary的数据，将其按照fields的参数进行排列
  getSummaryData(res, propsParams, isEventAnalytics) {
    // 初始化
    const newData = { data: [], field: [] };
    // 空判断
    if (res == null || JSON.stringify(res) === JSON.stringify({})) {
      return { data: [], field: [] };
    }

    // 有环比的数据格式为map嵌套map
    // {指标名：
    //   {
    //      value:...,
    //      compare:...,
    //      ......
    //   }
    // }
    // .......
    // 有环比的情况下

    const newFields = propsParams.fields.split(',').filter((s) => s.indexOf('key') < 0);

    newFields.forEach((item) => {
      let key = propsParams.eventAnalytics ? getFormula(item) : item; //事件分析则获取paramFormula
      newData.data.push(
        res[key] ? { compareValue: res[key].compareValue, compare: res[key].compare, value: res[key].value } : { compareValue: '-', compare: '-', value: '-' }
      );
      newData.field.push(key);
    });
    return newData;
    function getFormula(key) {
      let resultKey = key;
      const eventAnalytics = JSON.parse(propsParams.eventAnalytics);
      eventAnalytics.forEach((item) => {
        item.target === key && item.paramFormula && (resultKey = item.paramFormula);
        return;
      });
      return resultKey;
    }
  },
  // 数组去重
  duplicateRemove(array) {
    return [...Array.from(new Set(array))];
  },
  // 数组里面的对象的指定key排序
  sortArrayObject(key, sortOrder) {
    // if (key.includes('date')) {
    //   return (a, b) => {
    //     const val1 = new Date(a[key].split('~')[0]);
    //     const val2 = new Date(b[key].split('~')[0]);
    //     if (sortOrder) {
    //       return val1.getTime() - val2.getTime();
    //     }

    //     return val2.getTime() - val1.getTime();
    //   };
    // }
    return (a, b) => {
      const val1 = a[key].toString();
      const val2 = b[key].toString();
      if (parseFloat(val1) == val1 && parseFloat(val2) == val2) {
        //纯数字
        if (sortOrder) {
          return val1 - val2;
        } else {
          return val2 - val1;
        }
      }
      if (sortOrder) {
        return val1.localeCompare(val2);
      } else {
        return val2.localeCompare(val1);
      }
    };
  },
  sortArrayObjectByDay(key, sortOrder, data) {
    const dataKey = `date_${pageHeaderStore.parameter.period}_name`;
    const axisObj = {};
    let newData = [];
    data.forEach((item) => {
      if (!axisObj.hasOwnProperty(item[dataKey])) {
        axisObj[item[dataKey]] = [];
      }
    });
    Object.keys(axisObj).forEach((axisItem) => {
      data.forEach((item) => {
        if (item[dataKey] === axisItem) {
          axisObj[axisItem].push(item);
        }
      });
      axisObj[axisItem] = axisObj[axisItem].sort(this.sortArrayObject(key, sortOrder));
      newData = newData.concat(axisObj[axisItem]);
    });
    //

    return newData;
  },

  getChannelName(name, dimName) {
    const channelObj = { channel_enter: '直接进入', channel_link: '外部链接', channel_search: '搜索引擎', channel_utm: '广告' };
    if (dimName !== undefined) {
      return dimName === 'channel_enter' ? '直接进入' : `${channelObj[dimName]}${name}`;
    }
    return name;
  },

  // 通过key变化对应的时间
  keyToDate(key) {
    const lastDate = (key) =>
      moment()
        .subtract(key, 'days')
        .format('YYYY-MM-DD');
    const getNumber = (key) =>
      Number(
        key
          .split('')
          .filter((item) => !isNaN(item))
          .join('')
      );
    const date = moment().format('YYYY-MM-DD');
    const regex = /last\d/;
    let resultDate = '';
    if (regex.test(key)) {
      // 天
      if (key === 'last0') {
        resultDate = `${date},${date}`;
      } else if (key === 'last1') {
        resultDate = `${lastDate(1)},${lastDate(1)}`;
      } else if (key.includes('last')) {
        resultDate = `${lastDate(getNumber(key))},${lastDate(1)}`;
      }
    } else {
      // 周
      switch (key) {
        case 'lastWeek0':
          resultDate = `${moment()
            .day(1)
            .format('YYYY-MM-DD')},${date}`;
          break;
        case 'lastWeek4':
          resultDate = `${moment()
            .day(-27)
            .format('YYYY-MM-DD')},${moment()
            .day(0)
            .format('YYYY-MM-DD')}`;
          break;
        case 'lastWeek8':
          resultDate = `${moment()
            .day(-55)
            .format('YYYY-MM-DD')},${moment()
            .day(0)
            .format('YYYY-MM-DD')}`;
          break;
        case 'lastWeek12':
          resultDate = `${moment()
            .day(-83)
            .format('YYYY-MM-DD')},${moment()
            .day(0)
            .format('YYYY-MM-DD')}`;
          break;
        case 'lastMonth0':
          resultDate = `${moment()
            .subtract(0, 'months')
            .date(1)
            .format('YYYY-MM-DD')},${date}`;
          break;
        case 'lastMonth12':
          resultDate = `${moment()
            .subtract(1, 'years')
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD')},${moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD')}`;
          break;
        case 'lastQuarter0':
          const currentQuarter = moment().quarter(); // 当前是第几季度
          const currentYear = moment().year(); // 当前年
          resultDate = `${moment(moment(`${currentYear}-01-01`).toDate())
            .quarter(currentQuarter)
            .format('YYYY-MM-DD')},${date}`;
          break;
        case 'lastYear0':
          resultDate = `${moment()
            .subtract(0, 'years')
            .startOf('year')
            .format('YYYY-MM-DD')},${date}`;
          break;
        case 'lastYear1':
          resultDate = `${moment()
            .subtract(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD')},${moment()
            .subtract(1, 'years')
            .endOf('year')
            .format('YYYY-MM-DD')}`;
          break;
        case 'startToToday':
          resultDate = `${moment(pageHeaderStore.productCreateTime).format('YYYY-MM-DD')},${date}`;
          break;
      }
    }
    const resultDateArr = resultDate.split(',');
    if (moment(resultDateArr[1]).valueOf() < pageHeaderStore.productCreateTime - 86400000) {
      resultDate = `${date},${date}`;
    } else if (moment(resultDateArr[0]).valueOf() < pageHeaderStore.productCreateTime - 86400000) {
      resultDate = `${moment(pageHeaderStore.productCreateTime - 86400000).format('YYYY-MM-DD')},${resultDateArr[1]}`;
    }
    return resultDate;
  },
  // 适用BI的动态日期转换
  keyToDateBI(key) {
    const lastDate = (key) =>
      moment()
        .subtract(key, 'days')
        .format('YYYY-MM-DD');
    let date = '';
    switch (key) {
      case 'today':
        date = moment().format('YYYY-MM-DD');
        break;
      case 'yesterday':
      case 'yesterdayFromNow':
        date = lastDate(1);
        break;
      case '7':
      case '30':
      case '90':
      case '365':
        date = lastDate(parseInt(key));
        break;
      case 'week':
        date = moment()
          .day(1)
          .format('YYYY-MM-DD');
        break;
      case 'month':
        date = moment()
          .subtract(0, 'months')
          .date(1)
          .format('YYYY-MM-DD');
        break;
      case 'quarter':
        const currentQuarter = moment().quarter(); // 当前是第几季度
        const currentYear = moment().year(); // 当前年
        date = moment(moment(`${currentYear}-01-01`).toDate())
          .quarter(currentQuarter)
          .format('YYYY-MM-DD');
        break;
      case 'year':
        date = moment()
          .subtract(0, 'years')
          .startOf('year')
          .format('YYYY-MM-DD');
        break;
    }
    return date;
  },
  getYoyMom(config) {
    let yoyMom = {
      type: config.type,
      compareType: config.compareType,
      dateField: config.dateField,
    };
    if (config.type === 'mom') {
      yoyMom.date = config.lastDate ? this.keyToDate(config.lastDate) : config.date;
      yoyMom.compareDate = config.compareLastDate ? this.keyToDate(config.compareLastDate) : config.compareDate;
    } else {
      yoyMom.period = config.period;
      yoyMom.date = config.lastDate ? this.keyToDate(config.lastDate) : config.date;
    }
    return yoyMom;
  },
};
