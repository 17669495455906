import React, { Component } from 'react';
import { toJS } from 'mobx';
import { Select } from 'antd';
import intl from 'react-intl-universal';
import { viewToName } from '../config';
import { getTextWidth } from '../../../../containers/BIChart/util';
import _ from 'lodash';

class Main extends Component {
  constructor() {
    super();
    this.content = React.createRef();
  }
  componentDidMount() {
    this.setTitleStyle();
  }
  componentDidUpdate(prevProps) {
    if (this.props.item.name !== prevProps.item.name) {
      this.setTitleStyle();
    }
  }
  setTitleStyle = () => {
    const titleWidth = getTextWidth(this.props.item.name);
    const placeholderWidth = getTextWidth(intl.get('Pleaseselect'));
    this.content.current.getElementsByClassName('ant-select-selection__placeholder')[0].style.left = `${titleWidth + 8}px`;
    this.content.current.getElementsByClassName('ant-select-selection__rendered')[0].style.paddingLeft = `${titleWidth + 8}px`;
    this.content.current.getElementsByClassName('ant-select-selection__rendered')[0].style.minWidth = `${titleWidth + placeholderWidth + 23}px`;
  };
  render() {
    const { onFocus, onBlur, onDeselect, value, onSearch, onSelect, optionList, item, index } = this.props;
    const debounceOnSearch = _.debounce((value) => {
      onSearch(value, item.relatedViews, index);
    }, 200);
    return (
      <div className="quick-filter-item pc-quick-filter" key={`${item.name}${index}`} ref={this.content} style={{ ...this.props.style }}>
        <div className="quick-filter-item-title" title={item.name}>
          {item.name}
        </div>
        <Select
          className="quick-filter-select"
          dropdownClassName="pageheader-select"
          mode={'multiple'}
          placeholder={`${intl.get('Pleaseselect')}`}
          onFocus={() => onFocus(item.relatedViews, index)}
          onDeselect={(value) => onDeselect(value, item, index)}
          onSelect={(value) => onSelect(value, item, index)}
          value={value}
          onSearch={debounceOnSearch}>
          {optionList ? (
            Object.keys(optionList).map((key) => {
              const option = toJS(optionList[key]);
              const info = item.relatedViews[viewToName[key] || key];
              let values = [];
              return (
                info &&
                option.map((e) => {
                  const isRepeat = values.includes(e.value);
                  values.push(e.value);
                  return (
                    e.value &&
                    !isRepeat && (
                      <Select.Option key={e.value} title={e.value}>
                        {e.value}
                      </Select.Option>
                    )
                  );
                })
              );
            })
          ) : (
            <Select.Option key="loading" disabled>
              加载中...
            </Select.Option>
          )}
        </Select>
      </div>
    );
  }
}

export default Main;
