import React, { Fragment, Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Popover, Badge, message } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import intl from 'react-intl-universal';
import api from '../../../api';
import util from '../../../util';
import SidebarStore from '../../Sidebar/store';
import TableNullData from '../../TableNullData';
import Loading from '../../Loading/Loading';
import NotificationItem from './NotificationItem';
import NavbarTopStore from '../store';

@observer
class Notification extends Component {
  constructor() {
    super();
    this.state = {
      showCount: 20, //  当前渲染消息条目数
      loading: true, //  是否加载中
      messageList: [], //  消息列表
      visible: false, //  当前气泡是否显示
    };
  }

  componentDidMount() {
    // 查询当前用户的未读通知数量
    NavbarTopStore.getDownloadList();
    setInterval(() => {
      document.visibilityState === 'visible' && NavbarTopStore.getDownloadList();
    }, 30000);
  }

  // 查询消息列表
  getNotificationList = (visible, isReload) => {
    !isReload && this.setState({ visible });
    if (visible) {
      util.get(api.notice.list, { clear: true, currentPage: 1, pageCount: 10000 }, (res) => {
        // res && setMessageList(res.data);
        this.setState({
          loading: false,
          messageList: res.data,
        });
        const isDownloading = res.data.find((item) => {
          let eventInfo;
          try {
            eventInfo = item.eventInfo ? JSON.parse(item.eventInfo) : {};
          } catch (error) {
            eventInfo = {};
            console.error(error);
          }
          return eventInfo.status == '1';
        });
        isDownloading &&
          this.state.visible &&
          setTimeout(() => {
            this.getNotificationList(true, true);
          }, 3000);
        NavbarTopStore.resetNewsCount();
        !isReload &&
          util.post(api.notice.update, {}, () => {
            this.setState({
              hasUpdate: true,
            });
          });
      });
    }
  };

  // 渲染更多条目
  loadMore = () => {
    this.setState({
      showCount: this.state.showCount + 20,
    });
  };
  // 清空通知
  clear = () => {
    util.post(api.notice.clear, {}, (res) => {
      res.code === 200
        ? (message.success('清空成功'),
          this.setState({
            messageList: [],
          }))
        : message.error('清空失败');
    });
  };

  // 查看全部消息
  viewAll = () => {
    this.setState({
      visible: false,
    });
    // 刷新侧边栏选中项
    setTimeout(() => {
      SidebarStore.resetSelectkey();
    }, 0);
  };

  render() {
    const { loading, showCount, messageList, visible } = this.state;
    const content = (
      <Fragment>
        {messageList.length ? (
          <InfiniteScroll initialLoad={false} pageStart={0} loadMore={this.loadMore} hasMore={loading && showCount >= messageList.length} useWindow={false}>
            <ul className="notification-list">
              {messageList.slice(0, showCount).map((item) => {
                return <NotificationItem data={item} getNotificationList={this.getNotificationList} />;
              })}
            </ul>
          </InfiniteScroll>
        ) : (
          <TableNullData />
        )}
        <div className="operate-btn-content">
          <div className="operate-text-btn" onClick={this.clear}>
            清空通知
          </div>
          <Link className="operate-text-btn" to="/notification" onClick={this.viewAll}>
            查看全部消息
          </Link>
        </div>
      </Fragment>
    );
    return (
      <Popover
        overlayClassName="notification-list-card"
        content={loading ? <Loading size="small" /> : content}
        trigger="click"
        onVisibleChange={this.getNotificationList}
        visible={visible}
        getPopupContainer={() => document.getElementById('nav')}>
        <Badge count={NavbarTopStore.newsCount} offset={[0, 16]}>
          <a>
            <i title={intl.get('Notification')} className="iconfont icon-bell-fill navbar-icon-color lg-icon" />
          </a>
        </Badge>
      </Popover>
    );
  }
}

export default Notification;
