import { action, observable, autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { message, Select } from 'antd';
import api from '../../../api';
import { Popconfirm } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import pageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import SearchHeader from '../../../component/SearchHeader';
import CommonTable from '../../../containers/CommonTable';
import CommonTableStore from '../../../containers/CommonTable/store';
import util from '../../../util';
import BasicSurveyModal from '../Modal/index';
import './index.sass';
import intl from 'react-intl-universal';
import TablePageStore from '../../../containers/CommonTable/TablePageStore';
import moment from 'moment';
import tableUtil from '../../../utils/tableUtil';
import AuditModal from '../../../views/Audit/Modal/apply';
import StatusModal from '../Modal/StatusModal';
import { auditStatusCNClassName } from '../../../config';

const statusList = [
  {
    value: 0,
    label: '关闭',
  },
  {
    value: 1,
    label: '开启',
  },
  {
    value: 2,
    label: '暂停',
  },
];

@observer
class Main extends Component {
  constructor(props) {
    super(props);
    this.store = new CommonTableStore();
    this.attrs = this.props.attrs ? this.props.attrs : null;
    this.id = '';
    this.state = {
      auditInfo: {},
      isShowAuditModal: false,
      isShowStatusModal: false,
      selectedStatusId: null,
      statusTotalCount: 0,
    };
    this.auditPermMap = {
      archiveEdit: [1],
      edit: [3, 4, 8],
      switch: [4],
      delete: [1, 3, 4, 8],
      submitAudit: [1, 3, 8],
      deleteAudit: [4, 8],
      rejectAudit: [3],
    };
  }

  componentDidMount() {
    pageHeaderStore.hidePageHeader();
    this.disposer = autorun(() => {
      const count = this.counter;
      const date = pageHeaderStore.parameter.date;
      this.fetchData();
    });
    let showSurveyModal = localStorage && localStorage.getItem('showSurveyModal');
    if (showSurveyModal && showSurveyModal < 2) {
      showSurveyModal = parseInt(showSurveyModal, 10) + 1;
      localStorage && localStorage.setItem('showSurveyModal', showSurveyModal);
      if (showSurveyModal === 1) {
        this.showNewModal();
      }
    }
  }

  componentWillUnmount() {
    pageHeaderStore.showPageHeader();
    this.disposer();
  }

  @observable isShowNewModal = false;
  @observable counter = 0;
  @observable tableData;
  @observable searchValue = util.getQueryString('search') || '';

  @observable isLoading = false;

  @action.bound
  confirmDelete(id, values) {
    util.post(api.trigger.delete, { id: id, idsite: pageHeaderStore.parameter.idsite, ...values }, (res) => {
      this.counter++;
      message.success(res.msg);
      this.onAuditModalHide();
    });
  }

  @action.bound
  search(value) {
    this.searchValue = value;
    TablePageStore.setConfig({ search: value, currentPage: 1, pageCount: 10 });
    this.fetchData();
  }

  @action.bound
  showNewModal(id, isUnedit) {
    this.id = id;
    this.isShowNewModal = true;
    this.isNewModalUnedit = isUnedit;
    if (this.props.isNpsOrSati) {
      this.props.close();
    }
  }

  @action.bound
  hideNewModal(isRefresh) {
    if (isRefresh === true) {
      this.counter++;
    }
    localStorage && localStorage.removeItem('showSurveyModal');
    this.isShowNewModal = false;
  }

  statusChange(value, id) {
    if (value === 0) {
      util.get(
        api.trigger.checkBeforeUpdateStatus,
        {
          id: id,
        },
        (res) => {
          res.totalCount && res.totalCount > 0
            ? this.setState({
                isShowStatusModal: true,
                selectedStatusId: id,
                statusTotalCount: res.totalCount,
              })
            : this.updateStatus(value, id);
        }
      );
    } else {
      this.updateStatus(value, id);
    }
  }

  @action.bound
  updateStatus(value, id, cacheOperationType) {
    this.isLoading = true;
    let param = { id, status: value };
    typeof cacheOperationType !== 'undefined' && (param.cacheOperationType = cacheOperationType);
    util.post(api.trigger.updateStatus, param, () => {
      this.counter++;
    });
  }

  onStatusModalHide = () => {
    this.setState({
      isShowStatusModal: false,
      selectedStatusId: null,
    });
  };

  onStatusModalConfirm = (cacheOperationType) => {
    this.updateStatus(0, this.state.selectedStatusId, cacheOperationType);
    this.onStatusModalHide();
  };

  addContent = (store) => {
    const config = [
      {
        currentRowData: 'name',
        children: (store, text, record, index) => {
          return (
            <div>
              {text}
              <br />
              {store.parentData[index].eventName && <span style={{ fontSize: '10px', color: 'gray' }}>事件：{store.parentData[index].eventName}</span>}
            </div>
          );
        },
      },
      {
        currentRowData: 'surveyName',
        children: (store, text, record, index) => {
          return (
            <div>
              <a target="_blank" href={`${process.env.REACT_APP_Base_Url}/survey/survey/${store.parentData[index].bizId}/test`}>
                {store.parentData[index].surveyName}
              </a>
              <br />
              {store.parentData[index].surveyRemark && <span style={{ fontSize: '10px', color: 'gray' }}>备注：{store.parentData[index].surveyRemark}</span>}
            </div>
          );
        },
      },
      {
        currentRowData: 'displayChannelName',
        children: (store, text, record, index) => {
          return (
            <div>
              {text ? text : '-'}
              {text === '被动调研（链接）' && store.parentData[index].status === 1 && store.parentData[index].surveyUrl ? (
                <CopyToClipboard
                  text={`${store.parentData[index].surveyUrl}`}
                  onCopy={() => {
                    message.success(intl.get('Copysucceeded'));
                  }}>
                  <i className="iconfont icon-shoujitiaoyan" />
                </CopyToClipboard>
              ) : (
                ''
              )}
              <br />
              {store.parentData[index].displayTemplateName && (
                <span style={{ fontSize: '10px', color: 'gray' }}>模板：{store.parentData[index].displayTemplateName}</span>
              )}
            </div>
          );
        },
      },
      {
        currentRowData: 'updatedAt',
        string: (text, index, store) => {
          let { year, monthValue, dayOfMonth, hour, minute, second } = store.parentData[index].updatedAt;
          return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        currentRowData: 'auditTime',
        string: (text, index, store) => {
          if (store.parentData[index].auditTime) {
            let { year, monthValue, dayOfMonth, hour, minute, second } = store.parentData[index].auditTime;
            return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
          } else {
            return '-';
          }
        },
      },
      {
        currentRowData: 'status',
        children: (store, text, record, index) => {
          return (
            <Select
              className="table-select"
              value={parseInt(text)}
              disabled={this.auditPermMap.switch.indexOf(store.parentData[index].auditStatus) === -1}
              onChange={(value) => {
                this.statusChange(value, store.parentData[index].id);
              }}>
              {statusList.map((item) => {
                return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>;
              })}
            </Select>
          );
        },
      },
      {
        currentRowData: 'operate',
        children: (store, text, record, index) => {
          return (
            <div className="operateGroup">
              {this.auditPermMap.submitAudit.indexOf(store.parentData[index].auditStatus) > -1 ? (
                <span
                  className="operateDom-update"
                  onClick={() =>
                    this.submitAudit({
                      name: store.parentData[index].name,
                      auditType: 3,
                      editId: store.parentData[index].id,
                    })
                  }
                  title="提交审核">
                  {intl.get('SubmitAudit')}
                </span>
              ) : (
                <span className="text-disabled">{intl.get('SubmitAudit')}</span>
              )}
              {this.auditPermMap.delete.indexOf(store.parentData[index].auditStatus) > -1 && store.parentData[index].status === 0 ? (
                <Popconfirm
                  placement="topRight"
                  title={
                    this.auditPermMap.deleteAudit.includes(store.parentData[index].auditStatus) ||
                    (this.auditPermMap.rejectAudit.includes(store.parentData[index].auditStatus) && store.parentData[index].displaySourceAuditTime !== '-') ? (
                      <div>
                        <h5 className="delete-confirm-title">{intl.get('DeleteWaring')}</h5>
                        <p>删除需提交审核，通过后将自动删除</p>
                      </div>
                    ) : (
                      <div>
                        <h5 className="delete-confirm-title">{intl.get('DeleteWaring')}</h5>
                        <p>删除后，系统将无法恢复该内容</p>
                      </div>
                    )
                  }
                  okButtonProps={{ type: 'primary' }}
                  cancelButtonProps={{ type: 'cancel' }}
                  onConfirm={() =>
                    this.auditPermMap.deleteAudit.includes(store.parentData[index].auditStatus) ||
                    (this.auditPermMap.rejectAudit.includes(store.parentData[index].auditStatus) && store.parentData[index].displaySourceAuditTime !== '-')
                      ? this.submitAudit({
                          name: store.parentData[index].name,
                          auditType: 3,
                          editId: store.parentData[index].id,
                          submitType: 'delete',
                        })
                      : this.confirmDelete(store.parentData[index].id, {})
                  }
                  okText="确认"
                  cancelText="取消"
                  placement="topLeft">
                  <span className="operateDom-delete" title={intl.get('Delete')}>
                    {intl.get('Delete')}
                    {/* 删除 */}
                  </span>
                </Popconfirm>
              ) : (
                <span className="text-disabled">{intl.get('Delete')}</span>
              )}
              {this.auditPermMap.edit.indexOf(store.parentData[index].auditStatus) > -1 ||
              (this.auditPermMap.archiveEdit.indexOf(store.parentData[index].auditStatus) > -1 && store.parentData[index].status === 0) ? (
                <span className="operateDom-update" onClick={() => this.showNewModal(store.parentData[index].id)} title={intl.get('Edit')}>
                  {intl.get('Edit')}
                </span>
              ) : (
                <span className="text-disabled">{intl.get('Edit')}</span>
              )}
              <a className="operateDom-btn" onClick={() => this.showNewModal(store.parentData[index].id, true)}>
                查看
              </a>
            </div>
          );
        },
      },
      {
        currentRowData: 'displaySourceAuditStatus',
        string: (text, index, store) => {
          return text ? (
            <div className="audit-status-content">
              <div className={`status-circle ${auditStatusCNClassName[text] || 'orange'}`} />
              <div>{text}</div>
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        currentRowData: 'displayCreatedBy',
        children: (store, text, record, index) => {
          const textArr = text.split('(');
          return (
            <Fragment>
              <div>{textArr[0]}</div>
              {textArr[1] ? <div>({textArr[1]}</div> : null}
            </Fragment>
          );
        },
      },
    ];
    return tableUtil.addContentByConfig(store, config);
  };
  @action.bound
  fetchData(orderDesc, orderColumn, currentPage, pageCount) {
    this.isLoading = true;
    util.get(
      api.trigger.surveyList,
      {
        idsite: pageHeaderStore.parameter.idsite,
        search: TablePageStore.config.search || this.searchValue,
        currentPage: TablePageStore.config.currentPage || currentPage || 1,
        pageCount: TablePageStore.config.pageCount || pageCount || 10,
        orderColumn: TablePageStore.config.orderColumn || orderColumn || 'updatedAt',
        orderDesc: TablePageStore.config.orderDesc || orderDesc || true,
        date: pageHeaderStore.parameter.date,
      },
      (res) => {
        res ? (this.tableData = res) : (this.tableData = { data: [] });
        this.isLoading = false;
      }
    );
  }
  submitAudit = (auditInfo) => {
    this.setState({
      auditInfo,
      isShowAuditModal: true,
    });
  };
  onAuditModalHide = () => {
    this.setState({
      auditInfo: {},
      isShowAuditModal: false,
    });
  };
  render() {
    const { isNpsOrSati, isFunnelStepCard } = this.props;
    const { isShowAuditModal, auditInfo, selectedStatusId, isShowStatusModal, statusTotalCount } = this.state;
    return (
      <div>
        {!isNpsOrSati && !isFunnelStepCard && (
          <div>
            <SearchHeader
              defaultValue={this.searchValue}
              title={`+ ${intl.get('CreateResearch')}`}
              onSearch={this.search}
              onClick={() => this.showNewModal()}
            />
          </div>
        )}
        {this.isShowNewModal && (
          <BasicSurveyModal isShowNewModal={this.isShowNewModal} isNewModalUnedit={this.isNewModalUnedit} hideNewModal={this.hideNewModal} id={this.id} />
        )}
        {isShowAuditModal && (
          <AuditModal show={isShowAuditModal} auditInfo={auditInfo} onHide={this.onAuditModalHide} fetchData={this.fetchData} deleteData={this.confirmDelete} />
        )}
        {isShowStatusModal && (
          <StatusModal
            visible={isShowStatusModal}
            id={selectedStatusId}
            statusTotalCount={statusTotalCount}
            onCancel={this.onStatusModalHide}
            onOk={this.onStatusModalConfirm}
          />
        )}
        <div className="content">
          <CommonTable
            isSort={false}
            fetchData={this.fetchData}
            parentData={this.tableData}
            tableHeadData={[
              ['调研名称', '问卷', '调研方式', '创建人', '创建时间', '更新时间', '调研状态', '审核通过时间', '审核状态', '操作'],
              [
                'name',
                'surveyName',
                'displayChannelName',
                'displayCreatedBy',
                'createTime',
                'updateTime',
                'status',
                'displaySourceAuditTime',
                'displaySourceAuditStatus',
                'operate',
                'surveyRemark',
                'bizId',
                'auditStatus',
              ],
            ]}
            addContent={this.addContent}
            hasDetail={true}
            details={['/data/survey/detail']}
            isLoading={this.isLoading}
          />
        </div>
      </div>
    );
  }
}

export default Main;
