import React, { Component } from 'react';
import { Tag, Button } from 'antd';
import { toJS, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import Setting from '../Setting/index';
import './index.sass';
import UXSTag from '../../../component/UXSTag';

@observer
class AnalysePathStep extends Component {
  constructor(props) {
    super(props);
    this.formStore = this.props.formStore;
    this.state = {
      isShowModal: false,
    };
  }
  @observable stepIndex = null;
  @observable itemIndex;
  @action.bound showModal(dataIndex, index) {
    if (index === null && dataIndex === null) {
      this.stepIndex = null;
      this.itemIndex = index;
    } else {
      this.stepIndex = dataIndex;
      this.itemIndex = index;
    }

    this.setState({ isShowModal: !this.state.isShowModal });
  }
  addPathStepEvent(dataIndex, index) {
    this.formStore.addPathStepEvent(dataIndex, index);
  }
  addPathStep() {
    this.formStore.addPathStep();
  }
  delPathStep(dataIndex, index) {
    this.formStore.delPathStep(dataIndex, index);
  }
  changePathShow(index) {
    this.formStore.changePathShow(index);
  }
  changePathSkip(index) {
    this.formStore.changePathSkip(index);
  }
  changePathTarget(index) {
    this.formStore.changePathTarget(index);
  }
  render() {
    return (
      <React.Fragment>
        {this.state.isShowModal ? (
          <Setting
            formStore={this.formStore}
            stepIndex={this.stepIndex}
            itemIndex={this.itemIndex}
            isShowModal={this.state.isShowModal}
            showModal={this.showModal}></Setting>
        ) : null}
        <div className="AnalysePathStep">
          {toJS(this.formStore.pathStepData) &&
            toJS(this.formStore.pathStepData).map((dataItem, dataIndex) => {
              return (
                <div className="AnalysePathStep-item" key={dataIndex}>
                  <div className="AnalysePathStep-item-number">{dataIndex + 1}</div>
                  <div className="AnalysePathStep-item-icon">
                    <i
                      className={`iconfont icon-buxianshi ${dataItem[0].display ? 'gray' : 'black'}`}
                      title={`${dataItem[0].display ? '显示' : '不显示'}`}
                      onClick={() => {
                        this.changePathShow(dataIndex);
                      }}></i>

                    <i
                      className={`iconfont icon-tiaoguo ${dataItem[0].skip ? 'black' : 'gray'}`}
                      title={`${dataItem[0].skip ? '可跳过' : '不跳过'}`}
                      onClick={() => {
                        this.changePathSkip(dataIndex);
                      }}></i>
                    <i
                      className={`iconfont icon-baseline-autorenew- ${dataItem[0].target ? 'black' : 'gray'}`}
                      title={`${dataItem[0].target ? '转化目标' : '非转化目标'}`}
                      onClick={() => {
                        this.changePathTarget(dataIndex);
                      }}></i>
                  </div>
                  {dataItem.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`${index === 0 && dataItem[0].skip ? 'AnalysePathStep-item-tag-skip' : ''} AnalysePathStep-item-tag${
                            dataItem[0].type === 2 ? `-shadow` : ''
                          }`}>
                          <UXSTag
                            visible={true}
                            closeFunc={() => {
                              this.delPathStep(dataIndex, index);
                            }}
                            clickFunc={() => {
                              this.showModal(dataIndex, index);
                            }}
                            closable={true}
                            tagName={item.stepName}></UXSTag>
                        </div>
                        {dataItem[0].type === 2 ? null : (
                          <div className="AnalysePathStep-item-plus">
                            {index + 1 === dataItem.length ? (
                              <i className={` iconfont icon-jia green`} onClick={() => this.addPathStepEvent(dataIndex, index)}></i>
                            ) : (
                              <span className="grey">或</span>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}

          <Button type="addButton" onClick={() => this.addPathStep()}>
            +增加步骤
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
export default AnalysePathStep;
