import React, { PureComponent, createRef, RefObject, MouseEvent } from 'react';
import classnames from 'classnames';
import { Icon, Input, message } from 'antd';
import './ListFormLayout.less';

class ListItem extends PureComponent {
  state = {
    editing: false,
    inputValue: this.props.name,
  };

  container = createRef();
  input = createRef();

  inputValueChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  editStart = () => {
    this.setState(
      {
        editing: true,
      },
      () => {
        this.input.current.input.select();
        window.addEventListener('click', this.editFallback, false);
        window.addEventListener('keydown', this.enterToFinish, false);
      }
    );
  };

  editFallback = (e) => {
    if (!this.container.current.contains(e.target)) {
      this.setState({
        editing: false,
        inputValue: this.props.name,
      });
      window.removeEventListener('click', this.editFallback, false);
      window.removeEventListener('keydown', this.enterToFinish, false);
    }
  };

  editFinish = () => {
    const { id } = this.props;
    const { inputValue } = this.state;
    if (inputValue) {
      if (!this.checkLegalName(inputValue)) {
        message.error('控制器字数已超上限');
        this.input.current.input.focus();
        return;
      }
      this.props.onChange(id, inputValue);
      this.setState({
        editing: false,
      });
      window.removeEventListener('click', this.editFallback, false);
      window.removeEventListener('keydown', this.enterToFinish, false);
    } else {
      message.error('名称不能为空');
      this.input.current.input.focus();
    }
  };

  enterToFinish = (e) => {
    if (e.keyCode === 13) {
      this.editFinish();
    }
  };

  click = () => {
    const { id, onClick } = this.props;
    if (onClick) {
      onClick(id);
    }
  };

  delete = (e) => {
    e.stopPropagation();
    const { id, onDelete } = this.props;
    onDelete(id);
  };

  checkLegalName = (name) => {
    let chartNumber = 0;
    for (let i = 0; i < name.length; i++) {
      if (name.charAt(i).match(/[^x00-xff]/gi) != null) {
        chartNumber += 2;
      } else {
        chartNumber += 1;
      }
    }
    if (chartNumber <= 20) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { name, className, onClick } = this.props;
    const { editing, inputValue } = this.state;

    const contentClass = classnames({
      listItem: true,
      editing: editing,
      [className]: !!className,
    });

    return (
      <div className={contentClass} ref={this.container}>
        <h4 onClick={this.click}>{name}</h4>
        <Input value={inputValue} placeholder="请输入名称" size="small" onChange={this.inputValueChange} ref={this.input} />
        <Icon className={'confirm'} type="check" onClick={this.editFinish} />
        <Icon type="edit" className={'action'} onClick={this.editStart} />
        <Icon type="delete" className={'action'} onClick={this.delete} />
      </div>
    );
  }
}

export default ListItem;
