import { action, observable } from 'mobx';
import util from '../../util';
import moment from 'moment';

class DistributionFormStore {
  // 卡片信息
  @observable config = {
    targetSelected: [[]],
    id: null,
    chartType: 6,
    distributionType: 'analyseDistribute_target',
    customSection: null,
  };
  //表单信息
  @observable form = {
    customizeParam: {
      algorithm: 'fixed',
      groupCount: '',
      customSection: [],
      dateObj: {
        lastDate: 'last7',
        date: `${moment()
          .subtract(8, 'days')
          .format('YYYY-MM-DD')},${moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')}`,
      },
    },
    subDividedDimGroup: [''],
    filterObject: {
      logic: 'and',
      filters: [],
    },
    count: 0,
    ruleContent: [{ dim: 'event', type: 'default', dimValue: '访问', target: 'visits' }],
    dim: [''],
    filterItemLogic: ['and'],
    childrenFilter: [[]],
  };

  @action.bound changeFilterLogic(logic, index) {
    this.form.filterItemLogic[index] = logic;
    this.form.filterObject['logic'] = logic;
  }
  @action.bound setChildrenFilter(filter, index) {
    this.form.childrenFilter[index] = filter;
    this.form.filterObject = {
      logic: this.form.filterItemLogic[index],
      filters: this.form.childrenFilter[index],
    };
    this.form.count++;
  }
  @action.bound setSubDividedDim = (value, index) => {
    if (!value[1]) {
      this.form.subDividedDimGroup[0] = '';
    } else {
      this.form.subDividedDimGroup[0] = value[1];
    }
    this.form.count++;
  };

  @action.bound setDateObj(lastDate, date) {
    this.form = { ...this.form, customizeParam: { ...this.form.customizeParam, dateObj: { lastDate, date } } };
  }
  // 设置筛选条件
  @action.bound setTargetSeletced(data) {
    this.config.targetSelected = data;
  }
  @action.bound changeRuleContent(eventgroup, index) {
    this.form.ruleContent[index] = eventgroup;
    util.blockFetch();
    this.form.count++;
  }
  @action.bound changeTarget(value, index) {
    this.form.ruleContent[index].target = value.key;
    util.blockFetch();
    this.form.count++;
  }

  @action.bound init() {
    const id = this.config.id;
    this.config = {
      targetSelected: [[{ label: '访问次数', value: 'visits' }]],
      distributionType: 'analyseDistribute_target',
      customSection: null,
      chartType: 6,
      id: id,
    };

    this.form = {
      count: 0,
      ruleContent: [{ dim: 'event', type: 'default', dimValue: '访问', target: 'visits' }],
      subDividedDimGroup: [''],
      filterObject: {
        logic: 'and',
        filters: [],
      },
      filterItemLogic: ['and'],
      childrenFilter: [[]],
      customizeParam: { algorithm: 'fixed' },
    };
  }
  @action.bound refresh() {
    this.form.count++;
  }
}
export default DistributionFormStore;
