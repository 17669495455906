import { Col, Row } from 'antd';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import LazyLoad from 'react-lazyload';
import { Card } from '../../component/Card/Card';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import TableNullData from '../../component/TableNullData';
import pageUtil from '../../utils/pageUtil';
import sideMenu from '../../config/sideMenu';
import AuthorityStore from '../../AuthorityStore/store';
@observer
class Main extends React.Component {
  constructor(props) {
    super(props);
    const pathname = this.props.location.pathname;
    let pathTemplates = {};
    const unlimitPages = ['/action/warningLog/detail', '/data/visitor/record/info', '/customBoard/detail/visitor'];
    for (var k in sideMenu) {
      sideMenu[k].forEach((submenus) => {
        if (AuthorityStore.checkAuthorityRoute(submenus.key)) {
          if (submenus.hasOwnProperty('children')) {
            submenus.children.forEach((submenu) => {
              AuthorityStore.checkAuthorityExist(submenus.key, submenu.key, 'view') && (pathTemplates[submenu.href] = submenu.template);
            });
          } else {
            pathTemplates[submenus.href] = submenus.template;
          }
        }
        if (unlimitPages.includes(pathname)) {
          //开放部分详情页的权限
          if (submenus.hasOwnProperty('children')) {
            submenus.children.forEach((submenu) => {
              if (unlimitPages.includes(submenu.href)) {
                pathTemplates[submenu.href] = submenu.template;
              }
            });
          }
        }
      });
    }
    this.pageConfig = pathTemplates[pathname];
    pageUtil.setDocumentTitle(this.pageConfig ? this.pageConfig.title : '-');
  }
  @observable routeParcams = null;

  componentDidMount() {
    pageHeaderStore.showPageHeader();
  }

  render() {
    return (
      <Row gutter={8}>
        {this.pageConfig ? (
          this.pageConfig.components.map((item, index) => {
            if (item.children) {
              return (
                <Col md={item.col} key={index} className="board-card-content">
                  <Card
                    className={item.className}
                    card={{
                      title: item.title,
                      content: (
                        <Row gutter={8}>
                          {item.children.map((el, i) => {
                            const DynamicComponent = el.component;
                            return el.props && el.props.id && el.props.id.indexOf('report') === 0 ? (
                              <Col key={i} md={el.col} style={el.style ? el.style : null}>
                                <DynamicComponent key={index} from="board" {...this.props} {...el.props} />
                                {/* 新添this.props作为传值 因为进入二级目录需要用到传值 */}
                              </Col>
                            ) : (
                              <LazyLoad height={200} key={`lazyload-${i}`}>
                                <Col md={el.col} style={el.style} className={`${el.border ? 'border-chart' : ''}`}>
                                  <DynamicComponent key={index} from="board" {...this.props} {...el.props} />
                                </Col>
                              </LazyLoad>
                            );
                          })}
                        </Row>
                      ),
                    }}
                  />
                </Col>
              );
            } else {
              const DynamicComponent = item.component;
              return (
                <LazyLoad height={200} key={`lazyload-${index}`}>
                  <Col key={index} md={item.col} offset={item.offset && item.offset} className="board-card-content">
                    {item.title || item.isCard ? (
                      <Card
                        className={item.className}
                        key={index}
                        card={{
                          title: item.title,
                          content: (
                            <React.Fragment>
                              <DynamicComponent key={index} from="board" {...this.props} {...item.props} />
                            </React.Fragment>
                          ),
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        <DynamicComponent key={index} from="board" {...this.props} {...item.props} />
                      </React.Fragment>
                    )}
                  </Col>
                </LazyLoad>
              );
            }
          })
        ) : (
          <TableNullData nullDataType="notFound"></TableNullData>
        )}
      </Row>
    );
  }
}

export default Main;
