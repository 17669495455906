import React, { useMemo, useState } from 'react';
import { Menu, Icon, Input } from 'antd';
import intl from 'react-intl-universal';
import { getListsByViewModelTypes } from '../../util';
import { ViewModelTypes, DrillType } from '../../constants';
import BIStore from '../../store';

const { Search } = Input;

function EnhancerPanel(props) {
  const { onDataDrillUp, onDataDrillDown, widgetContentRef, currentData, drillHistory, widgetConfig, viewId } = props;

  const [searchValue, setSearchValue] = useState('');

  const getCategoriesModels = useMemo(() => {
    return getListsByViewModelTypes(BIStore.formedViews[viewId] && BIStore.formedViews[viewId].model, 'modelType')(ViewModelTypes.Category);
  }, [widgetConfig]);

  const drillHistoryGroups = useMemo(() => {
    if (drillHistory && drillHistory.length) {
      return drillHistory[drillHistory.length - 1]['groups'];
    }
  }, [drillHistory]);
  const getDrilledGroup = useMemo(() => {
    return drillHistory && drillHistory.length ? drillHistory.map((history) => history.currentGroup) : [];
  }, [drillHistory]);
  const currentCategories = useMemo(() => {
    return currentData && currentData[0] ? Object.keys(currentData[0]) : [];
  }, [currentData]);
  const modelsfilterDrilledGroup = useMemo(() => {
    return getCategoriesModels.filter((cate) => !getDrilledGroup.includes(cate));
  }, [drillHistory, widgetConfig, getCategoriesModels, getDrilledGroup]);
  const { drilldownCategories, drillupCategories } = useMemo(() => {
    return {
      drillupCategories: modelsfilterDrilledGroup
        .filter((cate) => currentCategories.includes(cate))
        .map((c) => ({
          name: c,
          modelType: ViewModelTypes.Category,
          drillType: DrillType.UP,
        })),
      drilldownCategories: modelsfilterDrilledGroup
        .filter((cate) => !currentCategories.includes(cate))
        .map((c) => ({
          name: c,
          modelType: ViewModelTypes.Category,
          drillType: DrillType.DOWN,
        })),
    };
  }, [widgetConfig, currentData, currentCategories]);
  const drillOtherCategories = useMemo(() => {
    return drillHistoryGroups && drillHistoryGroups.length
      ? modelsfilterDrilledGroup
          .filter((cate) => !drillHistory.some((his) => his.currentGroup === cate))
          .map((name) => ({
            name,
            modelType: ViewModelTypes.Category,
            drillType: drillHistoryGroups.includes(name) ? DrillType.UP : DrillType.DOWN,
          }))
      : drilldownCategories;
  }, [drillHistory]);

  function drill(e) {
    if (e.key !== 'searchField') {
      const path = e.keyPath;
      if (path && path.length > 2) {
        onDataDrillDown(path[1], path[0]);
      } else {
        switch (path[1]) {
          case DrillType.UP:
            onDataDrillUp(path[0]);
            break;
          case 'drillAll':
            const type = path[0].split('|');
            if (type) {
              if (type[1] === DrillType.UP) {
                onDataDrillUp(type[0]);
              } else if (type[1] === DrillType.DOWN) {
                onDataDrillDown(type[0]);
              }
            }
            break;
          default:
            break;
        }
      }
    }
  }

  // 搜索字段
  function searchField(value) {
    setSearchValue(value);
  }

  // 阻止事件冒泡，防止一点击输入框，菜单就隐藏了
  function onSearchClick(e) {
    e.stopPropagation();
  }
  function onSearchFocus() {
    widgetContentRef.getElementsByClassName('drill-dropdown')[0].style.setProperty('display', 'block');
  }
  function onSearchBlur() {
    widgetContentRef.getElementsByClassName('drill-dropdown')[0].style.removeProperty('display');
  }
  return (
    <Menu onClick={drill} style={{ width: 120 }} mode="vertical">
      <Menu.SubMenu
        key="drillAll"
        disabled={drillOtherCategories.length < 1}
        popupClassName="drill-menu"
        // forceSubMenuRender={true}
        title={
          <span style={{ fontSize: '14px' }} className="iconfont icon-iconxiazuan">
            <span style={{ marginLeft: '8px' }}>钻取</span>
          </span>
        }>
        <Menu.Item key="searchField">
          <div className="search-content">
            <Search
              placeholder={intl.get('PleaseEnter')}
              onChange={(e) => searchField(e.target.value)}
              onFocus={onSearchFocus}
              onClick={onSearchClick}
              onBlur={onSearchBlur}
            />
          </div>
        </Menu.Item>
        {drillOtherCategories
          ? drillOtherCategories.map((col) => {
              const name = BIStore.formedViews[viewId].model[col.name].fieldDisplay || col.name;
              return (
                (!searchValue || name.includes(searchValue)) && (
                  <Menu.Item key={`${col.name}|${col.drillType}`} title={BIStore.formedViews[viewId].model[col.name].fieldDisplay || col.name}>
                    <div className="items">{name}</div>
                  </Menu.Item>
                )
              );
            })
          : ''}
      </Menu.SubMenu>
    </Menu>
  );
}

export default EnhancerPanel;
