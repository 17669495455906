import React from 'react';
import { Select, DatePicker, InputNumber } from 'antd';
import util from '../../../../../util';
import api from '../../../../../api';
import pageHeaderStore from '../../../../../component/PageHeader/PageHeaderStore';
import DimSelect from '../../../../../component/DimSelect/index';
import { observable, action, toJS } from 'mobx';
import { filterObject } from '../config.js';
import moment from 'moment';
import './index.sass';
import intl from 'react-intl-universal';
const { Option } = Select;
const { RangePicker } = DatePicker;
const stringFormulaList = [
  {
    value: '=',
    label: '=',
  },
  {
    value: '!=',
    label: '!=',
  },
  {
    value: 'contain',
    label: 'Contain',
  },
  {
    value: '!contain',
    label: 'NotContain',
  },
  {
    value: 'startsWith',
    label: 'StartsWith',
  },
  {
    value: 'endsWith',
    label: 'EndsWith',
  },
];
const targetFormulaList = [
  {
    value: '=',
    label: '=',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '>',
    label: '>',
  },
  {
    value: '<=',
    label: '<=',
  },
  {
    value: '>=',
    label: '>=',
  },
  {
    value: '!=',
    label: '!=',
  },
];
const timeFormulaList = [
  {
    value: '=',
    label: '=',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '>',
    label: '>',
  },
  {
    value: '<=',
    label: '<=',
  },
  {
    value: '>=',
    label: '>=',
  },
  {
    value: '!=',
    label: '!=',
  },
  {
    value: 'between',
    label: 'between',
  },
];
const timeUnitList = [
  {
    value: 'year',
    label: '年',
  },
  {
    value: 'month',
    label: '月',
  },
  {
    value: 'day',
    label: '日',
  },
  {
    value: 'hour',
    label: '时',
  },
  {
    value: 'minute',
    label: '分',
  },
  {
    value: 'second',
    label: '秒',
  },
];
class ChildrenFilter extends React.Component {
  constructor(props) {
    super(props);
    this.menu = filterObject.menu;
    this.interval = [];
    this.filter = this.props.filter;
    this.keywordList = [];
    this.filterItemLogic = this.props.filterItemLogic;
    this.filterDefinition = this.props.filterDefinition;
    this.filterCount = this.props.filterCount;
    this.state = { filter: this.filter, logic: this.filterItemLogic, isLoading: true, target: this.props.target };
  }
  componentDidUpdate(prevProps) {
    if (this.props.reloadCount !== prevProps.reloadCount) {
      this.resetFilters();
    }
  }
  @observable searchSelect = [];
  @observable isFetchLoading = false;
  resetFilters() {
    this.filter = [[]];
    this.setState({ filter: [[]] });
  }
  @action.bound addChildrenFilter() {
    if (this.filter[this.filterCount] === null || !this.filter[this.filterCount]) {
      this.filter[this.filterCount] = [];
    }
    this.filter[this.filterCount].push({});
    this.setState({ filter: this.filter });
    this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
  }

  @action.bound deleteChildrenFilter(index) {
    this.filter[this.filterCount].splice(index, 1);
    if (this.filter[this.filterCount].length < 1) {
      this.filter[this.filterCount] = [];
    }
    this.setState({ filter: this.filter });
    this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
  }
  @action.bound setFilters(key, value, index) {
    key === 'formula' && this.filter[this.filterCount][index][key] && this.setFiltersValue([], index);
    key === 'dim' && this.filter[this.filterCount][index][key] && this.setFiltersValue([], index);
    key === 'dim' && !this.filter[this.filterCount][index].formula && this.setFilters('formula', '=', index);
    var filter = this.filter;
    this.filter = null;

    if (value.constructor === Array) {
      const valueKey = value[1].key !== undefined ? value[1].key.toString() : value[1];
      filter[this.filterCount][index][key] = valueKey;
      if (value.length > 2) {
        filter[this.filterCount][index].dim = value[2];
        filter[this.filterCount][index].dimValueName = value[1];
      } else {
        filter[this.filterCount][index].dimValueName = value[0];
      }
      this.keywordList[index] = valueKey;
      value.label !== undefined ? (filter[this.filterCount][index].dimValueName = value.label) : null;
      this.filter = filter;
      this.searchSelect = [{ key: null, value: '正在加载中...' }];
      !value[1].includes('_dt_period')
        ? (this.interval[index] = setTimeout(() => {
            this.fetchData(this.getParam(this.filter[this.filterCount][index].dim, '', this.filter[this.filterCount][index].dimValueName), 'dimValue', index);
          }, 700))
        : this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
    } else {
      filter[this.filterCount][index][key] = value;
      this.filter = filter;
      this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
      this.setState({ filter: this.filter });
    }
  }

  @action.bound setFiltersValue(value, index, type) {
    var filter = this.filter;
    if (type) {
      if (type === 'one_datetime') {
        //一个日期的格式
        filter[this.filterCount][index].value = value + ':00';
      } else if (type === 'two_datetime') {
        //两个日期的格式
        value[0] += ':00';
        value[1] += ':59';
        filter[this.filterCount][index].value = value.join(',');
      } else if (type === 'number') {
        filter[this.filterCount][index].value = value;
      }
    } else {
      if (value.length === 0) {
        this.fetchData(this.getParam(this.filter[this.filterCount][index].dim, '', this.filter[this.filterCount][index].dimValueName), 'dimValue', index);
      }
      let filterValue = value.map((item) => {
        return item.key;
      });
      let filterDimValue = value.map((item) => {
        return item.label;
      });
      filter[this.filterCount][index].value = filterValue.join(',');
      filter[this.filterCount][index].dimValue = filterDimValue.join(',');
    }
    this.filter = [...filter];
    this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
    this.setState({ filter: this.filter });
  }

  fetchData(param, key, index) {
    this.isFetchLoading = true;
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      util.post(api.pack.dimOptions, param, (res) => {
        this.searchSelect = res.data;
        this.isFetchLoading = false;
        this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
        this.setState({ filter: this.filter });
      });
    }, 500);
  }
  // this.menu[param.dim].menuList = [{ label: '正在加载中...', value: 'null' }];

  getOption(list) {
    const option = list.map((item, index) => {
      return (
        <Option key={item.value} value={item.value} disabled={item.value === 'null'}>
          {item.label}
        </Option>
      );
    });
    return option;
  }
  getFormulaOption(dim) {
    if (dim.includes('_num') || dim.includes('_dt_period')) {
      return targetFormulaList.map((item, index) => {
        return option(item);
      });
    }
    if (dim.includes('_datetime')) {
      return timeFormulaList.map((item, index) => {
        return option(item);
      });
    } else {
      return stringFormulaList.map((item, index) => {
        return option(item);
      });
    }
    function option(item) {
      return (
        <Option key={item.value} value={item.value} disabled={item.value === 'null'}>
          {intl.get(item.label)}
        </Option>
      );
    }
  }
  changeFilterLogic() {
    if (!this.props.disabled) {
      if (this.filterItemLogic[this.filterCount] === 'and') {
        this.filterItemLogic[this.filterCount] = 'or';
      } else {
        this.filterItemLogic[this.filterCount] = 'and';
      }
      this.setState({ logic: this.filterItemLogic });
      this.props.changeFilterLogic(this.filterItemLogic[this.filterCount], this.filterCount);
    }
  }
  getSelectValue(item) {
    if (!item.value || !item.dimValue || item.value === '' || item.dimValue === '' || !item || JSON.stringify(item) === '{}') {
      return [];
    } else {
      let keyArr = item.value.split(',');
      let labelArr = item.dimValue.split(',');
      let arr = keyArr.map((item, index) => {
        return { key: item, label: labelArr[index] };
      });

      return arr;
    }
  }
  getParam = (dim, search, dimValueName) => {
    let param = { idsite: pageHeaderStore.parameter.idsite, limit: 10 };
    param.dim = dim;
    param.search = search;
    if (this.props.type === 'authority') {
      if (dimValueName === '用户属性') {
        param.filters = JSON.stringify({
          type: 'visitor_user_param',
          eventName: dimValueName,
          paramName: dim,
        });
      } else if (dimValueName === '事件属性') {
        param.filters = JSON.stringify({
          type: 'relation_param',
          eventName: dimValueName,
          paramName: dim,
        });
      } else if (dimValueName.includes('_view_')) {
        param.filters = JSON.stringify({
          type: 'view',
          eventName: dimValueName.split('_view_')[1],
          paramName: dim,
        });
      } else if (dimValueName.includes('_customize')) {
        param.filters = JSON.stringify({
          type: 'customize',
          eventName: dimValueName.split('_customize')[0],
          paramName: dim,
        });
      }
    } else {
      if (this.props.eventValue[0]['type'] === 'customize') {
        if (dim.includes('_num')) {
          param.filters = JSON.stringify({
            type: this.props.eventValue[0]['type'],
            eventName: this.props.eventValue[0]['dimValue'],
            paramName: dim.split('_num')[0],
          });
          param.dim = dim.split('_num')[0];
        } else {
          param.filters = JSON.stringify({
            type: this.props.eventValue[0]['type'],
            eventName: this.props.eventValue[0]['dimValue'],
            paramName: dim.split('_string')[0],
          });
          param.dim = dim.split('_string')[0];
        }
      } else if (this.props.eventValue[0]['type'] === 'view') {
        param.filters = JSON.stringify({
          type: this.props.eventValue[0]['type'],
          eventName: this.props.eventValue[0]['dimValue'],
          paramName: dim,
        });
      }
    }
    return param;
  };
  onSearch(value, index) {
    this.isSelectLoading = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      util.post(
        api.pack.dimOptions,
        this.getParam(this.filter[this.filterCount][index].dim, value, this.filter[this.filterCount][index].dimValueName),
        (res) => {
          this.searchSelect = res.data;
          this.isFetchLoading = false;
          this.setState({ filter: this.filter });
        }
      );
    }, 500);
  }
  @action.bound setSubDividedDim = (value, index) => {
    if (value[0] !== undefined && value[1] !== undefined) {
      this.setFilters('dim', value, index);
    }
    // } else {
    //   this.filter[this.filterCount] = [{}];
    //   this.setState({ filter: this.filter });
    //   this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
    // }
  };
  @action.bound cleanFilter(index) {
    this.filter[this.filterCount][index] = {};
    this.props.setChildrenFilter(this.filter[this.filterCount], this.filterCount);
  }
  render() {
    const { disabled } = this.props;
    return (
      <div className={`childrenFilter ${this.filter[this.filterCount] && this.filter[this.filterCount].length < 2 ? 'childrenFilter-empty' : ''}`}>
        {this.props.isShow && !this.isLoading
          ? this.filter[this.filterCount] &&
            this.filter[this.filterCount].map((item, index) => {
              return (
                <div className="childrenFilter-item" key={index}>
                  <DimSelect
                    disabled={disabled}
                    useType={this.props.type === 'authority' ? 'authority' : 'userGroup'}
                    eventAndTargetGroup={toJS(this.props.eventValue)}
                    dimItem={item.dim}
                    dimItemValueName={item.dimValueName}
                    dimIndex={index}
                    setSubDividedDim={this.setSubDividedDim}
                    analysisType={this.props.analysisType}
                    cleanFilter={this.cleanFilter}></DimSelect>
                  {/* 时间周期维度 */}
                  {item.dim && item.dim.includes('_dt_period') && (
                    <Select
                      disabled={disabled}
                      placeholder="请选择"
                      value={item.period}
                      onSelect={(value) => {
                        this.setFilters('period', value, index);
                      }}>
                      {this.getOption(timeUnitList)}
                    </Select>
                  )}
                  <div className="childrenFilter-formula">
                    <Select
                      disabled={disabled}
                      placeholder="请选择"
                      value={item.formula ? item.formula : undefined}
                      onSelect={(value) => {
                        this.setFilters('formula', value, index);
                      }}
                      dropdownMatchSelectWidth={false}>
                      {item.dim ? this.getFormulaOption(item.dim) : ''}
                    </Select>
                  </div>
                  <div className="childrenFilter-dimValue">
                    {item.dim && item.dim.includes('_datetime') ? (
                      item.formula === 'between' ? (
                        <RangePicker
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          allowClear={false}
                          onChange={(value, dateString) => this.setFiltersValue(dateString, index, 'two_datetime')}
                          className="childrenFilter-picker"
                          value={
                            item.value ? [moment(item.value.split(',')[0].replace(/:00$/, '')), moment(item.value.split(',')[1].replace(/:59$/, ''))] : null
                          }
                        />
                      ) : (
                        <DatePicker
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          allowClear={false}
                          onChange={(value, dateString) => this.setFiltersValue(dateString, index, 'one_datetime')}
                          className="childrenFilter-small-picker"
                          value={item.value ? moment(item.value.replace(/:00$/, '')) : null}
                        />
                      )
                    ) : item.dim && item.dim.includes('_dt_period') ? (
                      <InputNumber
                        disabled={disabled}
                        value={item.value}
                        placeholder="请输入"
                        onChange={(value) => {
                          this.setFiltersValue(value, index, 'number');
                        }}
                      />
                    ) : (
                      <Select
                        disabled={disabled}
                        placeholder="请选择"
                        mode="tags"
                        allowClear={true}
                        value={this.getSelectValue(item)}
                        onChange={(value) => {
                          this.setFiltersValue(value, index);
                        }}
                        onFocus={() => {
                          this.filter[this.filterCount][index].dim &&
                            this.fetchData(
                              this.getParam(this.filter[this.filterCount][index].dim, '', this.filter[this.filterCount][index].dimValueName),
                              'dimValue',
                              index
                            );
                        }}
                        onSelect={() => {
                          this.filter[this.filterCount][index].dim &&
                            this.fetchData(
                              this.getParam(this.filter[this.filterCount][index].dim, '', this.filter[this.filterCount][index].dimValueName),
                              'dimValue',
                              index
                            );
                        }}
                        onSearch={(value) => this.filter[this.filterCount][index].dim && this.onSearch(value, index)}
                        labelInValue={true}
                        dropdownMatchSelectWidth={false}>
                        {this.searchSelect &&
                          this.searchSelect.map((item) => {
                            return <Option key={item.key}>{item.value}</Option>;
                          })}
                      </Select>
                    )}
                  </div>
                  <div>
                    {!this.props.disabled && this.props.needLogic && (
                      <span>
                        <i className="condition-delete iconfont icon-shanchu" onClick={() => this.deleteChildrenFilter(index)} />
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          : ''}
        {this.props.needLogic && this.props.isShow && !this.isLoading ? (
          <React.Fragment>
            {this.filter[this.filterCount] && (
              <React.Fragment>
                <span
                  className={`childrenFilter_logic ${this.filter[this.filterCount].length < 2 ? 'childrenFilter_logic-empty' : ''}`}
                  onClick={this.changeFilterLogic.bind(this, this.filterCount)}>
                  {this.filterItemLogic[this.filterCount] && this.filterItemLogic[this.filterCount] === 'and' ? intl.get('Qie') : intl.get('Or')}
                </span>
              </React.Fragment>
            )}
            {!disabled && (
              <div className="childrenFilter_add">
                <i className="iconfont icon-shaixuan" onClick={this.addChildrenFilter} title="添加筛选" />
              </div>
            )}
          </React.Fragment>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default ChildrenFilter;
