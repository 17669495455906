import { toJS } from 'mobx';
import mathUtil from './mathUtil';
import legendUtil, { config } from './legendUtil';
import tableUtil from './tableUtil';
import util from '../util';

export default {
  // 转化后台格式（基本数据）
  createGaugeData(res, eventAndTarget, type = 'value') {
    const field = this.filterTargetByType(eventAndTarget);
    return {
      axis: null,
      data: [[res[field][type]]],
      field,
    };
  },
  createAxis(res, propsDims, propsFields, ratio, axisKey, legendKey, props) {
    if (!res || !res.data || (res.data && res.data === []) || JSON.stringify(res) === '{}') {
      return {
        axis: [],
        legend: [],
        data: [],
      };
    }
    let { data } = res;
    let axis = [];
    const legend = [];
    const newData = [];
    let fieldsArray = [];
    let field = [];
    const key = `${propsDims}_`;
    // 根据传参的fields值建立legend的数组

    if (props && props.regroup) {
      const { regroupAxis, regroupField } = props.regroup;
      // 回收统计定制化图表
      data.forEach((item, index) => {
        propsFields.split(',').forEach((el) => {
          field.indexOf(`${item[regroupField]}_${el}`) < 0 &&
            (field.push(`${item[regroupField]}_${el}`), legend.push(`${item[regroupField]}_${legendUtil.getTitle(el)}`));
          data[index][`${item[regroupField]}_${el}`] = item[el];
        });
      });
      const dataPlus = {};
      data.forEach((item) => {
        dataPlus[item[regroupAxis]]
          ? (dataPlus[item[regroupAxis]] = {
              ...dataPlus[item[regroupAxis]],
              ...item,
            })
          : (dataPlus[item[regroupAxis]] = item);
      });
      data = [];
      for (const key in dataPlus) {
        data.push(dataPlus[key]);
      }
      fieldsArray = field;
      res.data = data;
    } else {
      field = propsFields.split(',').filter((item) => !(item.includes('name') || item.includes('key')));
      fieldsArray = propsFields.split(',');
    }
    (!props || !props.regroup) &&
      fieldsArray.map((item) => {
        if (item.indexOf(key) < 0) {
          legend.push(legendKey || (ratio ? `${legendUtil.getTitle(item)}占比` : legendUtil.getTitle(item)));
        }
      });
    // 根据legend的值相对应建立对应的data数据
    // 雷达图，以及散点图,以及堆叠图，横向堆叠图进这个方法
    if (
      (props && props.barType && (props.barType === 'stack' || props.barType === 'horizontalRankStack')) ||
      (props && props.type && (props.type === 'radar' || props.type === 'scatter'))
    ) {
      const newAxis = [];
      res.data &&
        res.data.forEach((item) => {
          newAxis.push(item[`${propsDims}_name`] ? item[`${propsDims}_name`] : '-');
        });
      axis = mathUtil.duplicateRemove(newAxis);
      axis.forEach((axisItem, axisIndex) => {
        newData.push([]);

        res.data.forEach((dataItem) => {
          field.forEach((fieldItem, filedIndex) => {
            if (dataItem[`${propsDims}_name`] === axisItem && dataItem.hasOwnProperty(fieldItem)) {
              newData[axisIndex][filedIndex] = dataItem[fieldItem];
            }
          });
        });
      });
      // 最终需要返回的格式
      // axis: (3) ["Android", "Windows NT", "iOS"]
      // axisField: (3) ["visits", "pv", "uvNew"]
      // data: (3) [Array(3), Array(3), Array(3)]
      // =
      // [89773, 194729, 65537]
      // [23678, 303126, 7111]
      // [15025, 50262, 7074]
      // field: (3) ["visits", "pv", "uvNew"]
      // legend: (3) ["访问次数", "浏览量", "新访客数"]
    } else {
      res.data &&
        res.data.forEach((item) => {
          const newDataChild = [];
          // 建立axis横坐标的数组
          // 针对页面集中文重名导致axis重复做的兼容
          if (!propsDims.includes('date') && propsDims.includes('page')) {
            axis.push(`${item[`${propsDims}_key`]}${item[`${propsDims}_name`]}`);
          } else if (propsDims.indexOf('channel_mix') > -1) {
            axis.push(mathUtil.getChannelName(item.channel_mix_name, item.dim_name));
          } else if (axisKey) {
            axis.push(item[axisKey] ? item[axisKey] : '-');
          } else {
            axis.push(item[`${propsDims}_name`] ? item[`${propsDims}_name`] : '-');
          }
          // if (!props || !props.regroupKey) {
          fieldsArray.forEach((itemChild) => {
            if (!(itemChild.indexOf(key) >= 0 || item === 'stepName')) {
              newDataChild.push(item[itemChild] === undefined ? 0 : item[itemChild].ratio ? item[itemChild].ratio : item[itemChild]);
            }
          });
          newData.push(newDataChild);
          // }
        });
    }

    if (props && props.lineType && props.lineType === 'area') {
      res.data = this.displaceRowAndColumn(newData);
    } else {
      res.data = newData;
    }

    res.axis = axis;
    res.legend = legend;
    res.field = field;
    res.axisField = field;
    return res;
  },
  // 两个维度的格式处理（Commonmixchart会用到）后期可以考虑并进下面那个方法
  multiDimsCreateAxis(res, propsDims, propsFields, props, isNotNeedNameOrKey) {
    if (!res || !res.data || (res.data && res.data === []) || JSON.stringify(res) === '{}') {
      return {
        axis: [],
        legend: [],
        data: [],
        field: [],
      };
    }

    const resData = res.data;
    const newData = [];
    const dimsArray = propsDims.split(',');
    const fieldCn = legendUtil.getTitle(propsFields);
    const dataObj = {};
    let axis = [];
    let legend = [];
    // 动态生成Axis
    const axisChangeItem = (dataItem) => {
      if (isNotNeedNameOrKey) {
        return dataItem[dimsArray[0]];
      }
      return dataItem[`${dimsArray[0]}_name`];
    };
    // 动态生成legend
    const legendChangeItem = (dataItem) => {
      if (isNotNeedNameOrKey) {
        return dataItem[dimsArray[1]];
      }
      if (dimsArray[1].includes('page')) {
        return `${mathUtil.spliceHttpAndDomin(dataItem[`${dimsArray[1]}_key`])},${dataItem[`${dimsArray[1]}_name`]},${fieldCn}`;
      }
      return dataItem[`${dimsArray[1]}_name`];
    };
    resData.forEach((dataItem) => {
      axis.push(axisChangeItem(dataItem));
      legend.push(legendChangeItem(dataItem));
    });

    legend = mathUtil.duplicateRemove(legend).filter((item) => !(item === null || item.includes('null,null')));
    axis = mathUtil.duplicateRemove(axis);

    legend.forEach((legendItem, legendIndex) => {
      axis.forEach((axisItem) => {
        resData.forEach((dataItem) => {
          let legendKey;

          if (axisChangeItem(dataItem) === axisItem) {
            if (!dataObj.hasOwnProperty(axisItem)) {
              dataObj[axisItem] = [];
            }
            resData.forEach((item) => {
              legendKey = legendChangeItem(dataItem);
            });
            if (legendItem === legendKey && typeof dataItem[propsFields] === 'number') {
              dataObj[axisItem][legendIndex] = dataItem[propsFields];
            }
          }
          // }
        });
        if (dataObj[axisItem][legendIndex] === undefined) {
          dataObj[axisItem][legendIndex] = 0;
        }
      });
    });
    Object.values(dataObj)
      .map((item) => {
        if (item.length < legend.length) {
          item.length = legend.length;
        }
        const arrItem = item;

        return arrItem.map((item, index) => (!item ? 0 : item));
      })
      .forEach((dataItem, dataIndex) =>
        dataItem.forEach((item, index) => {
          if (!(newData[index] instanceof Array)) {
            newData[index] = [];
          }
          newData[index][dataIndex] = item || 0;
        })
      );
    if (props.lineType && props.lineType === 'area') {
      res.data = newData;
    } else {
      res.data = this.displaceRowAndColumn(newData);
    }

    res.axis = axis;
    res.legend = legend.map((item) =>
      item.includes(',')
        ? `${item
            .split(',')
            .slice(0, item.split(',').length - 1)
            .join(',')}`
        : item
    );
    res.axisField = res.legend.map((item) => propsFields);

    return res;
  },

  surveyAnalyticsCreateAxis(res, dims, props, topicType, fieldsArr, legendArr, isCustomTooltip) {
    let dimsArray;
    let resData;
    let newFieldsArr;
    if (!res || !res.data || (res.data && res.data === []) || JSON.stringify(res) === '{}') {
      return {
        axis: [],
        legend: [],
        data: [],
        field: [],
      };
    }

    if (topicType) {
      if (topicType === '8' || topicType === '17') {
        dimsArray = dims.split(',');
        resData = res.data;
        newFieldsArr = (topicType === '8' ? 'markRatio' : 'npsRatio').split(',');
      } else {
        dimsArray = [...dims.split(','), 'content'];
        resData = this.getObjectTargetData('value', res.data);
        newFieldsArr = ['value'];
      }
      // newFieldsArr = ['value'];
      if (mathUtil.duplicateRemove(dimsArray).length === 1 && newFieldsArr.length === 1) {
        return this.createAxis(
          {
            data: resData,
          },
          dimsArray.join(','),
          newFieldsArr.join(','),
          null,
          null,
          null,
          props
        );
      }
      if (mathUtil.duplicateRemove(dimsArray).length === 2 && newFieldsArr.length === 1) {
        return this.multiDimsCreateAxis(
          {
            data: resData,
          },
          dimsArray.join(','),
          newFieldsArr.join(','),
          props
        );
      }
      return this.setMultipleEventData([], newFieldsArr, dimsArray, resData, props, 'survey');
    }
    dimsArray = [...dims.split(',')];
    resData = this.getObjectTargetData('value', res.data, 'samplesValue');
    newFieldsArr = fieldsArr;
    if (props.hasOwnProperty('barType') && props.barType === 'withLine') {
      const dataObj = this.setMultipleEventData([], newFieldsArr, dimsArray, resData, props, 'newSurvey');
      const newData = this.displaceRowAndColumn(dataObj.data);
      return Object.assign(dataObj, {
        data: newData,
      });
    }

    return this.setMultipleEventData([], newFieldsArr, dimsArray, resData, props, 'newSurvey', legendArr, isCustomTooltip);
  },
  // 传参(请求后的数据，维度字符串,事件和指标的那个数组)
  eventAnalyticsCreateAxis(res, dims, eventAndTarget, props) {
    if (props.hasOwnProperty('gaugeType')) {
      return this.createGaugeData(res, eventAndTarget);
    }
    if (!res || !res.data || (res.data && res.data === []) || JSON.stringify(res) === '{}') {
      return {
        axis: [],
        legend: [],
        data: [],
        field: [],
      };
    }
    // const filedsArr = this.filterTargetByType(eventAndTarget)
    //   .map((item) => (item.includes(',') ? item.split(',') : item))
    //   .flat(1);

    const filedsArr = util.customFlat(
      this.filterTargetByType(eventAndTarget).map((item) => (item.includes(',') ? item.split(',') : item)),
      1
    );
    const dimsArray = dims.split(',');
    // 有些图形不需要进下面的方法直接用回createAxis或者multiDimsCreateAxis的普通方法即可
    if (props.hasOwnProperty('pieType') || props.hasOwnProperty('mapType') || props.hasOwnProperty('radarType') || props.hasOwnProperty('scatterType')) {
      return this.createAxis(res, dims, filedsArr.join(','), null, null, null, props);
    }
    if (props.hasOwnProperty('barType')) {
      if (props.barType === 'withLine') {
        return this.getRatioDataForChart(tableUtil.combineEventRes(res, dims, eventAndTarget), dims, filedsArr);
      }
      if (mathUtil.duplicateRemove(dimsArray).length === 1 && filedsArr.length >= 1 && !dimsArray[0].includes('date_')) {
        return this.createAxis(res, dims, filedsArr.join(','), null, null, null, props);
      }
      if (mathUtil.duplicateRemove(dimsArray).length === 2 && filedsArr.length === 1) {
        return this.multiDimsCreateAxis(res, dims, filedsArr.join(','), props);
      }
    }

    const resData = res.data.slice();
    // 获取事件指标的数组，从头开始罗列
    const eventArr = mathUtil.duplicateRemove(eventAndTarget.map((item) => item.dimValue));
    return this.setMultipleEventData(eventArr, filedsArr, dimsArray, resData, props);
  },
  // 混合维度以及混合指标的处理方法
  setMultipleEventData(eventArr, fieldsArr, dimsArray, resData, props, type, legendArr, isCustomTooltip) {
    let axis = [];
    // 指标名
    let legend = legendArr || [];
    // 所有数据
    let data = [];
    // 维度名拼接

    const dimLegend = function(data) {
      let legendStr = '';
      if (type === 'newSurvey') {
        dimsArray
          .filter((item, index) => index !== 0)
          .map((item, index) => {
            legendStr.concat(',', data[`${item}_name`]);
          });
      } else if (type === 'survey') {
        dimsArray
          .filter((item, index) => index !== 0)
          .map((item, index) => {
            legendStr = index === 0 ? legendStr.concat(',', data[`${item}_name`]) : legendStr.concat(',', data[`${item}_name`]);
          });
      } else {
        dimsArray
          .filter((item) => !item.includes('date_'))
          .map((item) => {
            legendStr = item.includes('page_') ? legendStr.concat(',', data[`${item}_key`]) : legendStr.concat(',', data[`${item}_name`]);
          });
      }

      return legendStr;
    };
    // 遍历field的数组
    fieldsArr.forEach((filedItem) => {
      resData.forEach((item) => {
        axis.push(item[`${dimsArray[0]}_name`]);
        // 如果数据item存在该filed值
        if (!legendArr && item.hasOwnProperty(filedItem)) {
          // legend为"事件名+指标名"
          if (type === 'newSurvey') {
            legend.push(`${legendUtil.getTitle(filedItem)}`);
          } else if (type === 'survey') {
            legend.push(`${dimLegend(item)},${legendUtil.getTitle(filedItem)}`);
          } else {
            legend.push(item.analyticsName.concat(dimLegend(item), ',', legendUtil.getTitle(filedItem)));
          }
        }
      });
    });
    // 拿到legend进行去重
    legend = [...Array.from(new Set(legend))];
    // 拿到日期进行去重
    axis = type === 'survey' || type === 'newSurvey' ? [...Array.from(new Set(axis))] : [...Array.from(new Set(axis))].sort();

    const dataObj = {};
    let customTooltip;
    isCustomTooltip && (customTooltip = {});
    legend.forEach((legendItem, legendIndex) => {
      fieldsArr.forEach((filedItem, filedIdex) => {
        axis.forEach((axisItem) => {
          resData.forEach((dataItem) => {
            if (dataItem[`${dimsArray[0]}_name`] === axisItem) {
              let legendKey;
              if (!dataObj.hasOwnProperty(axisItem)) {
                dataObj[axisItem] = [];
                isCustomTooltip && (customTooltip[axisItem] = []);
              }
              if (legendArr) {
                legendKey = legendArr[filedIdex];
              } else if (type === 'newSurvey') {
                legendKey = `${legendUtil.getTitle(filedItem)}`;
              } else if (type === 'survey') {
                legendKey = `${dimLegend(dataItem)},${legendUtil.getTitle(filedItem)}`;
              } else {
                legendKey = dataItem.analyticsName.concat(dimLegend(dataItem), ',', legendUtil.getTitle(filedItem));
              }
              if (legendItem === legendKey && typeof dataItem[filedItem] === 'number') {
                dataObj[axisItem][legendIndex] = dataItem[filedItem];
                isCustomTooltip && (customTooltip[axisItem][legendIndex] = dataItem[`${filedItem}_tooltip`]);
              }
            }
          });
        });
      });
    });
    const legendData = axis.map((item) => {
      let arrItem = dataObj[item];
      if (arrItem.length < legend.length) {
        arrItem.length = legend.length;
      }
      arrItem = arrItem.map((item, index) => (!item ? 0 : item));
      return arrItem;
    });
    let legendCustomTooltip;
    isCustomTooltip && (legendCustomTooltip = axis.map((item) => (customTooltip[item] ? customTooltip[item] : '')));
    // 如果是堆叠图以及柱状堆叠图则不作处理

    if (
      props.hasOwnProperty('barType') &&
      props.barType &&
      (props.barType.includes('stack') || props.barType.includes('Stack') || props.barType.includes('horizontal'))
    ) {
      data = legendData;
    } else if ((props.hasOwnProperty('lineType') && props.lineType) || (props.hasOwnProperty('barType') && props.barType)) {
      legendData.forEach((dataItem, dataIndex) =>
        dataItem.forEach((item, index) => {
          if (!(data[index] instanceof Array)) {
            data[index] = [];
          }
          data[index][dataIndex] = item || 0;
        })
      );
    } else {
      data = legendData;
    }

    const typeForLegend = (item, type) => {
      if (type === 'event') {
        return `${item.split(',')[0]}`;
      }
      if (type === 'target') {
        return `${item.split(',')[item.split(',').length - 1]}`;
      }
      if (type === 'dim') {
        return `${item
          .split(',')
          .slice(1, item.split(',').length - 1)
          .join(',')}`;
      }
      if (type === 'eventDim') {
        return `${item
          .split(',')
          .slice(0, item.split(',').length - 1)
          .join(',')}`;
      }
      if (type === 'dimTarget') {
        return `${item
          .split(',')
          .slice(1, item.split(',').length)
          .join(',')}`;
      }
      return item;
    };

    const result = {
      // axisField: fieldsArr,
      axis,
      data,
      field: [...fieldsArr],
      legend: legend
        .map((item) => {
          if (fieldsArr.length === 1 && fieldsArr.length < eventArr.length) {
            return item;
          }
          if (fieldsArr.length === 1 && eventArr.length >= 2 && dimsArray.length === 1) {
            return typeForLegend(item, 'event');
          }
          if (fieldsArr.length >= 2 && eventArr.length === 1 && dimsArray.length >= 2) {
            return typeForLegend(item, 'dimTarget');
          }
          // if (eventArr.length >= 2 && dimsArray.length >= 2) {
          //   return typeForLegend(item, 'eventDim');
          // }
          if (
            (fieldsArr.length = 1 && eventArr.length === 1 && dimsArray.length >= 2) ||
            ((fieldsArr.length === 1 || eventArr.length === 1) && dimsArray.length === 2)
          ) {
            return typeForLegend(item, 'dim');
          }
          if ((fieldsArr.length === 1 || eventArr.length === 1) && dimsArray.length === 1) {
            return typeForLegend(item, 'target');
          }
          return item;
        })
        .map((item) => {
          if (item.includes('：')) {
            return item.split('：')[1];
          }
          return item;
        }),
    };
    isCustomTooltip && (result.customTooltip = legendCustomTooltip);
    return result;
  },
  getRatioDataForChart(res, dims, field) {
    let newData = res.data.sort(mathUtil.sortArrayObject(`${dims}_key`, true));
    const axis = newData.map((item) => item[`${dims}_name`]);
    const newField = [field[0], 'compare'];
    const legend = [legendUtil.getTitle(field[0]), '环比'];
    newData = newData.map((item) =>
      Object.values({
        value: item[field[0]].value,
        compare: item[field[0]].compare,
      })
    );
    return {
      axis,
      field: newField,
      legend,
      data: newData,
    };
  },
  filterTargetByType(eventAndTarget) {
    return eventAndTarget.map((eventAndTargetItem) => {
      // 通过自定义参数的出现的顺序号
      let customParamsIndex = 0;
      return mathUtil.duplicateRemove(
        eventAndTargetItem.target.split(',').map((item) => {
          if (item.includes('_num') || item.includes('_string')) {
            const customParamFormula = eventAndTargetItem.paramFormula.split(',')[customParamsIndex];
            customParamsIndex++;
            return customParamFormula;
          }

          if (item.type === 'funnel') {
            return item;
          }
          if (item.type === 'survey') {
            return 'uv';
          }
          return item;
        })
      );
    });
  },
  // 二维数组行和列调换
  displaceRowAndColumn(arr) {
    const newArr = [];
    arr.forEach((dataItem, dataIndex) => {
      dataItem.forEach((item, index) => {
        if (dataIndex === 0) {
          newArr.push([]);
        }
        newArr[index][dataIndex] = item;
      });
    });
    return newArr;
  },
  // 获取List.Map对象里指定的属性数据再封装成新的数据块
  getObjectTargetData(type, data, customType = null) {
    if (JSON.stringify(data) === '{}' || !data || data.length === 0) {
      return [];
    }
    return data.map((item) => {
      let objKey;
      objKey = Object.keys(item);
      const newObj = {};
      objKey.forEach((key) => {
        if (type && item[key] instanceof Object) {
          newObj[customType || type] = Number(item[key][type]);
        } else {
          newObj[key] = item[key];
        }
      });
      return newObj;
    });
  },
  // 把所有key进行组合
  getRegroupKey(data, subDividedDimGroup, isNeedFirstKey) {
    let key = '';
    for (const i in data) {
      const item = surveyAnalyseTitleObj[data[i]] ? surveyAnalyseTitleObj[data[i]] : data[i];
      i.includes('_key') && (i !== `${subDividedDimGroup[0]}_key` || isNeedFirstKey) && (key += key === '' ? item : `，${item}`);
    }
    return key;
  },
  // 把所有name进行组合
  getRegroupName(data, subDividedDimGroup, isNeedFirstKey, hiddenKeys) {
    let key = '';
    for (const i in data) {
      if (hiddenKeys.indexOf(i) < 0) {
        const item = surveyAnalyseTitleObj[data[i]] ? surveyAnalyseTitleObj[data[i]] : data[i];
        i.includes('_name') &&
          (isNeedFirstKey || i !== `${subDividedDimGroup[0]}_name` || subDividedDimGroup.length < 2) &&
          (key += key === '' ? item : `，${item}`);
      }
    }
    return key;
  },
  // 需要显示的指标数量
  getTargetNum(config) {
    let num = 0;
    config.isNeedSamples && (num += 1);
    config.isNeedValue && (num += 1);
    config.isNeedNpsValue && (num += 1);
    config.isNeedEValue && (num += 1);
    config.isNeedIEValue && (num += 1);
    config.isNeedUpEValue && (num += 1);
    config.isNeedDownEValue && (num += 1);
    config.isNeedSamplesUp && (num += 1);
    config.isNeedSamplesDown && (num += 1);
    config.isNeedNpsValue && (num += 1);
    config.isNeedMarkValue && (num += 1);
    return num;
  },
  // 设置指标中文名
  getTargetTitle(target, data, isOnlyOneDim) {
    let { title } = config.common[target] || { title: target };
    target === 'value' &&
      (surveyAnalyseTitleObj[data.answer_topic_option_key]
        ? (title = isOnlyOneDim ? surveyAnalyseTitleObj[data.answer_topic_option_key] : '值')
        : (title = '占比'));
    return title;
  },
  isNeedChain(config) {
    const {
      isNeedSamplesChain,
      isNeedValueChain,
      isNeedEValueChain,
      isNeedNpsValueChain,
      isNeedMarkValueChain,
      isNeedIEValueChain,
      isNeedUpEValueChain,
      isNeedDownEValueChain,
      isNeedIEValuChain,
      isNeedSamplesRank,
      isNeedSamplesRatioRank,
      isNeedValueRank,
      isNeedEValueRank,
      isNeedIEValueRank,
      isNeedUpEValueRank,
      isNeedDownEValueRank,
      isNeedSamplesUpRatioRank,
      isNeedSamplesDownRatioRank,
      isNeedSamplesUpRank,
      isNeedSamplesDownRank,
    } = config;
    return isNeedSamplesRank ||
      isNeedSamplesRatioRank ||
      isNeedValueRank ||
      isNeedEValueRank ||
      isNeedIEValueRank ||
      isNeedUpEValueRank ||
      isNeedDownEValueRank ||
      isNeedSamplesUpRatioRank ||
      isNeedSamplesDownRatioRank ||
      isNeedSamplesUpRank ||
      isNeedSamplesDownRank
      ? 'chainRank'
      : isNeedIEValuChain ||
        isNeedNpsValueChain ||
        isNeedMarkValueChain ||
        isNeedSamplesChain ||
        isNeedValueChain ||
        isNeedEValueChain ||
        isNeedUpEValueChain ||
        isNeedDownEValueChain ||
        isNeedIEValueChain
      ? 'chain'
      : null;
  },
  // 一列中所有值都相同时隐藏该列
  checkRepeat(data, key) {
    const hideValue = ['NPS', '影响值', '满意度', '重视度', '推荐者', '被动者', '贬损者'];
    let repeat = true;
    const list = [];
    data.length <= 1
      ? (repeat = false)
      : data.forEach((item, index) => {
          if (list.length > 1) {
            return;
          }
          list.indexOf(item[`${key}_name`]) < 0 && list.push(item[`${key}_name`]);
        });
    (list.length > 1 || hideValue.indexOf(list[0]) < 0) && (repeat = false);
    return repeat;
  },
};
const surveyAnalyseTitleObj = {
  nps: 'NPS',
  mark: '满意度',
  npsDValue: 'NPS影响值',
  markDValue: '满意度影响值',
};
