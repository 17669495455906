import { action, observable, autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { message } from 'antd';
import api from '../../api';
import SearchHeader from '../../component/SearchHeader';
import CommonTable from '../../containers/CommonTable';
import CommonTableStore from '../../containers/CommonTable/store';
import tableUtil from '../../utils/tableUtil';
import util from '../../util';
import intl from 'react-intl-universal';
import TablePageStore from '../../containers/CommonTable/TablePageStore';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import SurveyFormModal from './SurveyFormModal';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ShareModelEnhance from '../../component/shareModelEnhance';
import QuestionnaireDownload from './QuestionnaireDownload';
import DeletePopconfirm from '../DeletePopconfirm';

@observer
class Main extends Component {
  constructor(props) {
    super(props);
    this.store = new CommonTableStore();
    this.attrs = this.props.attrs ? this.props.attrs : null;
    this.id = '';
    this.state = {
      ShareModalVisible: false,
      shareStore: null,
      shareIndex: null,
      downloadModalInfo: {},
    };
    this.sharePermMap = {
      edit: [1, 3],
      remark: [1, 3],
      share: [1],
      copy: [1, 3],
      delete: [1],
    };
  }

  componentDidMount() {
    this.props.type === 'manager' ? pageHeaderStore.hidePageHeader() : pageHeaderStore.showPageHeader();
    this.disposer = autorun(() => {
      const count = this.counter;
      const date = pageHeaderStore.parameter.date;
      this.fetchData();
    });
  }

  componentWillUnmount() {
    pageHeaderStore.showPageHeader();
    this.disposer();
  }

  // 显示隐藏分享面板
  showShareModel = (store, index) => {
    this.setState({ ShareModalVisible: true });
    this.setState({ shareStore: store });
    this.setState({ shareIndex: index });
  };
  // 分享面板取消
  shareOnCancel = () => {
    this.setState({ ShareModalVisible: false });
  };
  // 显示下载弹窗
  showQuestionnaireDownloadModal = (id) => {
    this.setState({ downloadModalInfo: { visible: true, id } });
  };
  // 关闭下载弹窗
  hideQuestionnaireDownloadModal = () => {
    this.setState({ downloadModalInfo: {} });
  };

  @action.bound
  showSurveyForm(surveyFormType, item) {
    if (surveyFormType === 'copy') {
      this.modalInfo = {
        surveyFormType,
        isShowSurveyForm: true,
        surveyName: item.surveyName + '副本',
        surveyRemark: item.surveyRemark,
        id: item.id,
      };
    } else if (surveyFormType === 'updateRemark') {
      this.modalInfo = { surveyFormType, isShowSurveyForm: true, surveyRemark: item.surveyRemark, id: item.id };
    } else if (surveyFormType === 'create') {
      this.modalInfo = { surveyFormType, isShowSurveyForm: true, surveyName: '', id: '' };
    }
  }

  @action.bound
  hideSurveyForm() {
    this.modalInfo = { isShowSurveyForm: false };
  }

  @action.bound
  modalFormChange(value) {
    this.modalInfo = { ...this.modalInfo, ...value };
  }

  @action.bound
  deleteData(id) {
    util.post(api.wenjuan.delete, { surveyId: id }, () => {
      this.counter++;
      message.success('删除成功');
    });
  }

  @action.bound
  saveSurvey(modalForm) {
    if (this.modalInfo.surveyFormType === 'updateRemark') {
      util.post(api.wenjuan.updateRemark, { surveyId: this.modalInfo.id, surveyRemark: this.modalInfo.surveyRemark }, (res) => {
        if (res.code === 200) {
          message.success(`更新问卷备注成功`);
          this.modalInfo = { isShowSurveyForm: false };
          this.fetchData();
          // } else if (res.code.toString().length === 4) {
          //   this.validationMsg[res.code] = res.msg;
        } else {
          message.error(res.msg);
        }
      });
    } else if (!this.modalInfo.surveyName || this.modalInfo.surveyName.length === 0) {
      message.info(intl.get('Pleaseentersurveyname'));
    } else {
      if (this.modalInfo.surveyFormType === 'create') {
        util.post(api.wenjuan.create, { surveyName: this.modalInfo.surveyName, surveyRemark: this.modalInfo.surveyRemark || '' }, (res) => {
          if (res.code === 200) {
            message.success(`新建问卷成功`);
            this.modalInfo = { isShowSurveyForm: false };
            this.props.history.push(`/wj/detail?surveyId=${res.data}`);
            // } else if (res.code.toString().length === 4) {
            // this.validationMsg[res.code] = res.msg;
          } else {
            message.error(res.msg);
          }
        });
      } else if (this.modalInfo.surveyFormType === 'copy') {
        util.post(
          api.wenjuan.copy,
          {
            surveyId: this.modalInfo.id,
            surveyName: this.modalInfo.surveyName,
            surveyRemark: this.modalInfo.surveyRemark || '',
          },
          (res) => {
            if (res.code === 200) {
              message.success(`复制问卷成功`);
              this.modalInfo = { isShowSurveyForm: false };
              this.props.history.push(`/wj/detail?surveyId=${res.data}`);
              // } else if (res.code.toString().length === 4) {
              //   this.validationMsg[res.code] = res.msg;
            } else {
              message.error(res.msg);
            }
          }
        );
      }
    }
  }

  @observable validationMsg = {};
  @observable modalInfo = { isShowSurveyForm: false };
  @observable counter = 0;
  @observable tableData;
  @observable searchValue = util.getQueryString('search') || '';
  @observable isLoading = false;

  @action.bound
  search(value) {
    this.searchValue = value;
    TablePageStore.setConfig({ search: value, currentPage: 1, pageCount: 10 });
    this.fetchData();
  }

  addContent = (store) => {
    const { type } = this.props;
    const config = [
      {
        currentRowData: 'surveyName',
        children: (store, text, record, index) => {
          return (
            <div>
              {type === 'manager' ? <Link to={`/wj/detail?surveyId=${this.tableData.data[index].id}`}>{text}</Link> : text}
              <br />
              {store.parentData[index].surveyRemark && <span style={{ fontSize: '10px', color: 'gray' }}>备注：{store.parentData[index].surveyRemark}</span>}
            </div>
          );
        },
      },
      { currentRowData: 'displaySurveyStatus', string: (text) => `${text}` },
      {
        currentRowData: 'createAt',
        string: (text, index, store) => {
          let { year, monthValue, dayOfMonth, hour, minute, second } = store.parentData[index].createAt ? store.parentData[index].createAt : {};
          return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        currentRowData: 'updateAt',
        string: (text, index, store) => {
          let { year, monthValue, dayOfMonth, hour, minute, second } = store.parentData[index].updateAt ? store.parentData[index].updateAt : {};
          return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        currentRowData: 'displayCreatedBy',
        children: (store, text, record, index) => {
          const textArr = text.split('(');
          return (
            <Fragment>
              <div>{textArr[0]}</div>
              {textArr[1] ? <div>({textArr[1]}</div> : null}
            </Fragment>
          );
        },
      },
      {
        currentRowData: 'displayUpdatedBy',
        children: (store, text, record, index) => {
          const textArr = text.split('(');
          return (
            <Fragment>
              <div>{textArr[0]}</div>
              {textArr[1] ? <div>({textArr[1]}</div> : null}
            </Fragment>
          );
        },
      },
      {
        currentRowData: 'operate',
        children: (store, text, record, index) => {
          return type === 'manager' ? (
            <div className="operateGroup">
              {this.sharePermMap.share.indexOf(store.parentData[index].sourceFromType) > -1 ? (
                <span className="operateDom-update" onClick={() => this.showShareModel(store, index)}>
                  分享
                </span>
              ) : (
                <span className="text-disabled">分享</span>
              )}
              {this.sharePermMap.remark.indexOf(store.parentData[index].sourceFromType) > -1 ? (
                <span className="operateDom-update" onClick={() => this.showSurveyForm('updateRemark', this.tableData.data[index])} title={intl.get('Remarks')}>
                  {intl.get('Remarks')}
                  {/* 备注 */}
                </span>
              ) : (
                <span className="text-disabled">{intl.get('Remarks')}</span>
              )}

              {this.sharePermMap.copy.indexOf(store.parentData[index].sourceFromType) > -1 ? (
                <span className="operateDom-update" onClick={() => this.showSurveyForm('copy', this.tableData.data[index])} title={intl.get('Copy')}>
                  {intl.get('Copy')}
                  {/* 复制 */}
                </span>
              ) : (
                <span className="text-disabled">{intl.get('Copy')}</span>
              )}
              {this.sharePermMap.delete.indexOf(store.parentData[index].sourceFromType) > -1 ? (
                <DeletePopconfirm
                  sourceType={4}
                  onConfirm={() => {
                    this.deleteData(this.tableData.data[index].id);
                  }}
                  sourceId={this.tableData.data[index].id}>
                  <span className="operateDom-delete" title={intl.get('Delete')}>
                    {intl.get('Delete')}
                    {/* 删除 */}
                  </span>
                </DeletePopconfirm>
              ) : (
                <span className="text-disabled">{intl.get('Delete')}</span>
              )}
              {this.sharePermMap.edit.indexOf(store.parentData[index].sourceFromType) > -1 ? (
                <a className="operateDom-update" href={`${process.env.REACT_APP_Base_Url}/wj/detail?surveyId=${this.tableData.data[index].id}`} target="_blank">
                  {intl.get('Edit')}
                </a>
              ) : (
                <span className="text-disabled">{intl.get('Edit')}</span>
              )}
            </div>
          ) : (
            <div className="operateGroup">
              <span className="operateDom-btn" onClick={() => this.showQuestionnaireDownloadModal(this.tableData.data[index].id)}>
                下载
              </span>
              <Link className="operateDom-btn" to={`/wj/survey/detail/${this.tableData.data[index].id}?name=${this.tableData.data[index].surveyNameAndRemark}`}>
                统计
              </Link>
            </div>
          );
        },
      },
    ];
    return tableUtil.addContentByConfig(store, config);
  };

  @action.bound
  fetchData(orderDesc, orderColumn, currentPage, pageCount) {
    this.isLoading = true;
    this.props.type === 'manager'
      ? util.get(
          api.wenjuan.list,
          {
            search: TablePageStore.config.search || this.searchValue,
            currentPage: TablePageStore.config.currentPage || currentPage || 1,
            pageCount: TablePageStore.config.pageCount || pageCount || 10,
            orderColumn: TablePageStore.config.orderColumn || orderColumn || 'updatedAt',
            orderDesc: TablePageStore.config.orderDesc || orderDesc || true,
          },
          (res) => {
            res ? (this.tableData = res) : (this.tableData = { data: [] });
            this.isLoading = false;
          }
        )
      : util.get(
          api.wenjuan.statisticsList,
          {
            search: TablePageStore.config.search || this.searchValue,
            currentPage: TablePageStore.config.currentPage || currentPage || 1,
            pageCount: TablePageStore.config.pageCount || pageCount || 10,
            orderColumn: TablePageStore.config.orderColumn || orderColumn || 'updatedAt',
            orderDesc: TablePageStore.config.orderDesc || orderDesc || true,
            date: pageHeaderStore.parameter.date,
          },
          (res) => {
            res ? (this.tableData = res) : (this.tableData = { data: [] });
            this.isLoading = false;
          }
        );
  }

  render() {
    const { downloadModalInfo } = this.state;
    const { type } = this.props;
    const tableHeadData =
      type === 'manager'
        ? [
            ['问卷名称', '问卷id', '问卷状态', '来源', '创建人', '创建日期', '更新人', '更新时间', '操作'],
            ['surveyName', 'bizId', 'displaySurveyStatus', 'displaySourceFrom', 'displayCreatedBy', 'createdAt', 'displayUpdatedBy', 'updatedAt', 'operate'],
          ]
        : [
            ['问卷名称', '问卷id', '下发量', '打开量', '回收量', '打开率', '回收率', '完成率', '数据更新日期', '操作'],
            ['surveyNameAndRemark', 'bizId', 'inviteCount', 'openCount', 'answerCount', 'openRatio', 'answerRatio', 'completeRatio', 'replyTime', 'operate'],
          ];
    return (
      <div>
        <SurveyFormModal
          modalInfo={this.modalInfo}
          saveSurvey={this.saveSurvey}
          modalFormChange={this.modalFormChange}
          isShowSurveyForm={this.modalInfo.isShowSurveyForm}
          hideSurveyForm={this.hideSurveyForm}
        />
        <SearchHeader
          defaultValue={this.searchValue}
          title={`+ ${intl.get('CreateSurvey')}`}
          onSearch={this.search}
          onClick={() => this.showSurveyForm('create')}
          hideButton={type === 'download'}
        />
        <div className="content">
          <CommonTable
            isSort={false}
            fetchData={this.fetchData}
            parentData={this.tableData}
            tableHeadData={tableHeadData}
            addContent={this.addContent}
            isLoading={this.isLoading}
            hasDetail
          />
          <ShareModelEnhance
            store={this.state.shareStore}
            index={this.state.shareIndex}
            type={4}
            visible={this.state.ShareModalVisible}
            shareOnCancel={this.shareOnCancel}
          />
          {downloadModalInfo.visible && (
            <QuestionnaireDownload visible={downloadModalInfo.visible} id={downloadModalInfo.id} onHide={this.hideQuestionnaireDownloadModal} />
          )}
        </div>
      </div>
    );
  }
}

export default Main;
