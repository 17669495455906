import React, { Component, Fragment } from 'react';
import CommonTable from '../CommonTable/index';
import { Button, Popconfirm } from 'antd';
import tableUtil from '../../utils/tableUtil';
import util from '../../util';
import api, { BI_DOMAIN } from '../../api';
import { observer } from 'mobx-react';
import { action, autorun } from 'mobx';
import Loading from '../../component/Loading/Loading';
import { message } from 'antd';
import { iconfont } from '../../config';
import AuthorityStore from '../../AuthorityStore/store';
import TableNullData from '../../component/TableNullData/index';
import SearchHeader from '../../component/SearchHeader';
import intl from 'react-intl-universal';
import { toJS, observable } from 'mobx';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import ManagementUtil from '../../Product/Management/util';

@observer
class AnalyseBITable extends Component {
  constructor() {
    super();
    this.originData = [];
    this.state = {
      data: [],
      isLoading: true,
    };
    this.searchValue = '';
  }
  componentDidMount() {
    this.disposer = autorun(() => {
      // 判断权限是否为所有权限 有则发送请求 否则判断props的anlayseType是否为all 若是则isloading为false 否则检查权限当中是否存在view的权限 若有则发送请求 否则isLoading为false
      AuthorityStore.authorityMenu.hasOwnProperty('all')
        ? this.getData()
        : this.props.analyseType === 'all'
        ? this.setState({
            isLoading: false,
          })
        : AuthorityStore.checkAuthorityExist('analytics', this.props.analyseType, 'view')
        ? this.getData()
        : this.setState({
            isLoading: false,
          });
    });
  }
  componentWillUnmount() {
    this.disposer();
  }
  @observable modalInfo = { isShowModal: false, data: {} };

  @action.bound
  showModal(data, record) {
    if (!Number(record.dashboardNum)) {
      return;
    }
    this.modalInfo = {
      isShowModal: true,
      data: toJS(data.dashboard),
    };
  }
  @action.bound
  hideModal() {
    this.modalInfo = {
      isShowModal: false,
      data: [],
    };
  }

  @action.bound getData(orderDesc, orderColumn, currentPage = 1, pageCount = 10) {
    this.setState({
      isLoading: true,
    });
    util.get(
      api.BI.widgets,
      {
        projectId: AuthorityStore.primaryAccount,
        type: 1,
        currentPage,
        pageCount,
        search: this.searchValue,
      },
      (res) => {
        if (res) {
          this.originData = res;
          this.setState({
            data: res,
            isLoading: false,
          });
        }
      }
    );
  }
  deleteData(data) {
    this.isLoading = true;
    const dashboard = data.dashboard;
    const list = [];
    dashboard.forEach((item) => list.push(item.id));
    util.post(
      `${api.BI.widgets}/${data.id}`,
      list,
      () => {
        message.success('删除成功');
        this.getData();
      },
      true
    );
  }
  dashBoardDetail = (dashboard, type) => {
    const getStyle = (type, index) => {
      if (type === 'showModal') {
        return { cursor: 'pointer', marginLeft: index ? '5px' : '0' };
      } else {
        return { marginLeft: index ? '5px' : '0' };
      }
    };
    return dashboard.map((item, index) => {
      return (
        <Fragment>
          {type === 'showModal' && (
            <Link key={index} style={getStyle(type, index)} to={ManagementUtil.getBoardLink(item)}>
              {item.name}
              {index !== 1 && index !== this.modalInfo.data.length - 1 && <span>;</span>}
            </Link>
          )}
          {type === 'deleteModal' && (
            <div key={index} style={getStyle(type, index)}>
              {item.name}
              {index !== 1 && index !== this.modalInfo.data.length - 1 && <span>;</span>}
            </div>
          )}
        </Fragment>
      );
    });
  };
  deleteModal = (item) => {
    const dashboard = toJS(item.dashboard);
    if (dashboard.length) {
      return (
        <Fragment>
          <div>删除提醒</div>
          <div>卡片已被以下看板引用，删除卡片后，同时会删除看板的引用。</div>
          <div style={{ fontWeight: 'bold', marginTop: '20px' }}>引用的看板:</div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>{this.dashBoardDetail(dashboard, 'deleteModal')}</div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div>你确定要删除这行内容吗?</div>
        <div>删除后，系统将无法恢复该内容</div>
      </Fragment>
    );
  };

  create = () => {
    window.open(`${BI_DOMAIN}/#/project/${AuthorityStore.primaryAccount}/widget`, '_self');
  };
  onSearch = (name) => {
    this.searchValue = name;
    this.getData('', '', 1, 10);
  };
  addContent = (store) => {
    const config = [
      {
        currentRowData: 'dashboardNum',
        children: (store, text, record, index) => (
          <div style={{ textAlign: 'right' }}>
            {Number(record.dashboardNum) ? (
              <a
                onClick={() => {
                  this.showModal(store.parentData[index], record);
                }}>
                {text}
              </a>
            ) : (
              text
            )}
          </div>
        ),
      },
      {
        currentRowData: 'operate',
        children: (store, text, record, index) => (
          <div className="operateGroup">
            <Popconfirm
              title={this.deleteModal(store.parentData[index])}
              onConfirm={() => {
                this.deleteData(store.parentData[index]);
              }}
              okButtonProps={{ type: 'primary' }}
              cancelButtonProps={{ type: 'cancel' }}
              okText="确认"
              cancelText="取消">
              <span className="operateDom-delete" title={intl.get('Delete')}>
                {intl.get('Delete')}
                {/* 删除 */}
              </span>
            </Popconfirm>
            <a
              className="operateDom-update"
              title={intl.get('Edit')}
              href={`${BI_DOMAIN}/#/project/${AuthorityStore.primaryAccount}/widget/${store.parentData[index].id}`}>
              {intl.get('Edit')}
            </a>
          </div>
        ),
      },
    ];
    return tableUtil.addContentByConfig(store, config);
  };
  render() {
    const { data } = this.state;
    return (
      <Fragment>
        <SearchHeader
          hideButton={!this.props.noTab}
          onClick={this.create}
          onSearch={this.onSearch}
          title={`+ ${intl.get('BtnCreate')}${this.props.title && (intl.get(this.props.title) || this.props.title)}`}
        />
        {!this.state.isLoading ? (
          data.data && data.data.length ? (
            AuthorityStore.authorityMenu.hasOwnProperty('all') ||
            (this.props.analyseType !== 'all' && AuthorityStore.checkAuthorityExist('analytics', 'BIAnalytics', 'view')) ? (
              <CommonTable
                addRowSelect={this.props.addRowSelect}
                rowSelection={this.props.rowSelection}
                addContent={this.addContent}
                fields={'name,typeName,dashboardNum,createTime,updateTime'}
                tableHeadData={[
                  ['名称', '描述', '所引用工作表', '看板引用数', '更新时间', '更新人', '操作'],
                  ['name', 'description', 'parentName', 'dashboardNum', 'updateTime', 'updatedByName', 'operate'],
                ]}
                parentData={data}
                isSort={false}
                tableType="analyseBI"
                fetchData={this.getData}></CommonTable>
            ) : (
              <TableNullData nullDataType="authority"></TableNullData>
            )
          ) : (
            <TableNullData></TableNullData>
          )
        ) : (
          <Loading />
        )}
        {this.modalInfo.isShowModal ? (
          <Modal
            title="引用的看板"
            visible={this.modalInfo.isShowModal}
            onOk={this.hideModal}
            okText={'知道了'}
            onCancel={this.hideModal}
            destroyOnClose={true}
            mask={false}
            footer={[
              <Button type="primary" onClick={this.hideModal}>
                知道了
              </Button>,
            ]}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{this.dashBoardDetail(this.modalInfo.data, 'showModal')}</div>
          </Modal>
        ) : null}
      </Fragment>
    );
  }
}
export default AnalyseBITable;
