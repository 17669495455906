/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React, { Fragment } from 'react';
import { findDOMNode } from 'react-dom';

import { Table as AntTable, Tooltip, Icon, ConfigProvider } from 'antd';
import PaginationWithoutTotal from './PaginationWithoutTotal';
import { ViewModelTypes, TABLE_PAGE_SIZES, SortTypesToOrder } from '../constants';
import {
  getFieldAlias,
  decodeMetricName,
  hasProperty,
  findChildConfig,
  traverseConfig,
  computeCellWidth,
  getDataColumnWidth,
  getTableCellValueRange,
  getCellSpanMap,
  getDimensionsCombine,
  getRequestBody,
  getRequestParams,
} from '../util';
import { getCartesianChartMetrics } from '../render/chart/util';
import { MapAntSortOrder } from './constants';
import { tableComponents } from '../components/tableComponents';
import { resizeTableColumns } from '../components/HeadCell';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import moment from 'moment';
import api from '../../../api';
import util from '../../../util';
import zh_CN from 'antd/lib/locale-provider/zh_CN';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const bodyWidth = document.body.offsetWidth;

export class Table extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      chartStyles: null,
      data: null,
      width: 0,
      pagination: null,
      currentSorter: null,

      tableColumns: [],
      mapTableHeaderConfig: {},
      containerWidthRatio: 1,
      tablePagination: {
        current: void 0,
        pageSize: void 0,
        simple: false,
        total: void 0,
      },
      tableBodyHeight: 0,
      selectedRow: [],
      selectItems: {
        group: [],
        cell: {},
      },
      showRows: false,
    };
    //test
    this.HeaderSorterWidth = 0;
    this.table = React.createRef();
  }

  handleResize = (idx, containerWidthRatio) => (_, { size }) => {
    const nextColumns = resizeTableColumns(this.state.tableColumns, idx, size.width, containerWidthRatio);
    this.setState({ tableColumns: nextColumns });
  };

  tableChange = (pagination, _, sorter) => {
    const nextCurrentSorter = sorter.field ? { column: sorter.field, direction: MapAntSortOrder[sorter.order] } : null;
    this.setState({ currentSorter: nextCurrentSorter });
    const { current, pageSize } = pagination;
    if (!this.state.showRows) {
      this.refreshTable(current || 1, pageSize || 10, nextCurrentSorter);
    }
  };

  refreshTable = (current, pageSize, sorter) => {
    const { tablePagination } = this.state;
    if (pageSize !== tablePagination.pageSize) {
      current = 1;
    }
    const { onPaginationChange } = this.props;
    onPaginationChange(current, pageSize, sorter);
  };

  basePagination = {
    pageSizeOptions: TABLE_PAGE_SIZES.map((s) => s.toString()),
    showSizeChanger: true,
    size: 'small',
  };

  componentDidMount() {
    const { headerFixed, withPaging } = this.props.chartStyles.table;
    this.adjustTableCell(headerFixed, withPaging);
  }

  componentDidUpdate(preProps) {
    const { headerFixed, withPaging } = this.props.chartStyles.table;
    this.adjustTableCell(headerFixed, withPaging, this.state.tablePagination.total);
    if (this.props.isLoading === true && this.props.isLoading !== preProps.isLoading) {
      // 下钻后取消选中项
      this.setState({
        selectItems: {
          group: [],
          cell: {},
        },
      });
    }
  }
  adjustTableCell(headerFixed, withPaging, dataTotal) {
    const tableDom = findDOMNode(this.table.current);
    const excludeElems = [];
    let paginationMargin = 0;
    let paginationWithoutTotalHeight = 0;
    if (headerFixed) {
      excludeElems.push('.ant-table-thead');
    }
    if (withPaging) {
      excludeElems.push('.ant-pagination.ant-table-pagination');
      paginationMargin = 4;

      if (dataTotal === -1) {
        paginationWithoutTotalHeight = 40;
      }
    }
    const excludeElemsHeight = excludeElems.reduce((acc, exp) => {
      const elem = tableDom.querySelector(exp);
      return acc + (elem ? elem.getBoundingClientRect().height : 0);
    }, paginationMargin);
    const tableBodyHeight = this.props.height - excludeElemsHeight - paginationWithoutTotalHeight;
    this.setState({
      tableBodyHeight,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { chartStyles, data, width, rows } = nextProps;
    if (chartStyles !== prevState.chartStyles || data !== prevState.data || width !== prevState.width) {
      const { tableColumns, mapTableHeaderConfig, containerWidthRatio } = getTableColumns(nextProps);
      const tablePagination = getPaginationOptions(nextProps);
      return {
        tableColumns,
        mapTableHeaderConfig,
        containerWidthRatio,
        tablePagination,
        chartStyles,
        data,
        width,
      };
    }
    const showRows = rows && rows.length ? true : false;
    return { chartStyles, data, width, showRows };
  }

  adjustTableColumns(tableColumns, mapTableHeaderConfig, containerWidthRatio) {
    traverseConfig(tableColumns, 'children', (column, idx, siblings) => {
      const canResize = siblings === tableColumns;
      column.onHeaderCell = (col) => ({
        width: col.width,
        onResize: canResize && this.handleResize(idx, containerWidthRatio),
        config: mapTableHeaderConfig[column.key],
      });
    });
    return tableColumns;
  }

  getRowKey = (_, idx) => {
    return _.id;
  };

  getTableScroll(columns, containerWidth, headerFixed, tableBodyHeight) {
    const scroll = {};
    if (bodyWidth < 768) {
      scroll.x = '100%';
      return scroll;
    }
    const columnsTotalWidth = columns.reduce((acc, c) => acc + c.width, 0);
    if (columnsTotalWidth > containerWidth) {
      scroll.x = Math.max(columnsTotalWidth, containerWidth);
    }
    if (headerFixed) {
      scroll.y = tableBodyHeight - 38;
    }
    return scroll;
  }

  isSameObj(prevObj, nextObj, isSourceData) {
    let isb = void 0;
    const clonePrevObj = { ...prevObj };
    const cloneNextObj = { ...nextObj };
    if (isSourceData === true) {
      delete clonePrevObj['key'];
      delete clonePrevObj['value'];
      delete cloneNextObj['key'];
      delete cloneNextObj['value'];
    }
    for (const attr in clonePrevObj) {
      if (clonePrevObj[attr] !== undefined && clonePrevObj[attr] === cloneNextObj[attr]) {
        isb = true;
      } else {
        isb = false;
        break;
      }
    }
    return isb;
  }

  matchAttrInBrackets(attr) {
    const re = /\(\S+\)/;
    const key = re.test(attr) ? attr.match(/\((\S+)\)/)[1] : attr;
    return key;
  }

  rowClick = (record, row, event) => {
    const { getDataDrillDetail, onCheckTableInteract, onDoInteract } = this.props;
    let selectedRow = [...this.state.selectedRow];
    let filterObj = void 0;
    if (event.target && event.target.innerHTML) {
      for (const attr in record) {
        if (record[attr].toString() === event.target.innerText) {
          const key = this.matchAttrInBrackets(attr);
          filterObj = {
            key,
            value: event.target.innerText,
          };
        }
      }
    }
    const recordConcatFilter = {
      ...record,
      ...filterObj,
    };
    const isInteractiveChart = onCheckTableInteract && onCheckTableInteract();
    if (isInteractiveChart && onDoInteract) {
      selectedRow = [recordConcatFilter];
    } else {
      if (selectedRow.length === 0) {
        selectedRow.push(recordConcatFilter);
      } else {
        const isb = selectedRow.some((sr) => this.isSameObj(sr, recordConcatFilter, true));
        if (isb) {
          for (let index = 0, l = selectedRow.length; index < l; index++) {
            if (this.isSameObj(selectedRow[index], recordConcatFilter, true)) {
              selectedRow.splice(index, 1);
              break;
            }
          }
        } else {
          selectedRow.push(recordConcatFilter);
        }
      }
    }

    this.setState(
      {
        selectedRow,
      },
      () => {
        const sourceData = Object.values(this.state.selectedRow);
        const isInteractiveChart = onCheckTableInteract && onCheckTableInteract();
        if (isInteractiveChart && onDoInteract) {
          const triggerData = sourceData;
          onDoInteract(triggerData);
        }
      }
    );
  };

  asyncEmitDrillDetail() {
    const { getDataDrillDetail } = this.props;
    setTimeout(() => {
      if (this.props.getDataDrillDetail) {
        const sourceData = this.combineFilter();
        const sourceGroup = this.combineGroups();
        const brushed = [{ 0: Object.values(sourceData) }];
        getDataDrillDetail(
          JSON.stringify({
            filterObj: sourceData,
            brushed,
            sourceData,
            sourceGroup,
          })
        );
      }
    }, 500);
  }

  combineGroups() {
    const { group } = this.state.selectItems;
    return group;
  }

  combineFilter() {
    const { cell } = this.state.selectItems;
    return Object.keys(cell).reduce((iteratee, target) => {
      const data = cell[target].map((item) => ({
        ...item,
        dataKey: target,
      }));
      iteratee = iteratee.concat(data);
      return iteratee;
    }, []);
  }

  getTableStyle(headerFixed, tableBodyHeight) {
    const tableStyle = {};
    if (!headerFixed || bodyWidth < 768) {
      // tableStyle.height = bodyWidth > 768 ? tableBodyHeight : '100%';
      tableStyle.height = '100%';
      tableStyle.overflowY = 'auto';
      tableStyle.overflowX = 'auto';
    }
    return tableStyle;
  }

  filterSameNeighbourSibings = (arr, targetIndex) => {
    let s = targetIndex;
    let e = targetIndex;
    let flag = -1;
    const orgIndex = targetIndex;

    do {
      const target = arr[targetIndex];
      if (flag === -1 && targetIndex > 0 && arr[targetIndex - 1] === target) {
        s = targetIndex -= 1;
      } else if (flag === 1 && arr[targetIndex + 1] === target) {
        e = targetIndex += 1;
      } else if (flag === -1) {
        flag = 1;
        targetIndex = orgIndex;
      } else {
        break;
      }
    } while (targetIndex > -1 && targetIndex < arr.length);
    return { s, e };
  };

  coustomFilter(array, column, index) {
    const nativeIndex = array.reduce((a, b, c) => (b.index === index ? c : a), 0);
    const columns = array.map((a) => a[column]);
    const { s: start, e: end } = this.filterSameNeighbourSibings(columns, nativeIndex);
    return array.filter((arr) => arr['index'] < array[start]['index'] || arr.index > array[end]['index']);
  }

  isValueModelType = (modelName) => {
    const target = this.getModelTypecollectByModel();
    const result = hasProperty(target, modelName);
    return typeof result !== 'boolean' ? result === ViewModelTypes.Value : false;
  };

  getModelTypecollectByModel = () => {
    const { model } = this.props;
    return Object.keys(model).reduce((iteratee, target) => {
      iteratee[target] = hasProperty(model[target], 'modelType');
      return iteratee;
    }, {});
  };

  loop = (col, i) => {
    if (col && col.dataIndex) {
      return {
        ...col,
        onHeaderCell: (target) => {
          return {
            ...col.onHeaderCell(target),
            isFirstColumn: i === 0 ? true : false,
          };
        },
        onCell: (target, index) => {
          return {
            ...col.onCell(target, index),
            isFirstColumn: i === 0 ? true : false,
          };
        },
      };
    } else {
      return { ...col };
    }
  };

  resetWidth = (column) => {
    const columnsTotalWidth = column.reduce((acc, c) => acc + c.width, 0);
    const width = this.props.width;
    if (columnsTotalWidth <= width) {
      return column.map((col) => {
        if (col.fixed) {
          col.fixed = undefined;
        }
        return col;
      });
    } else {
      return column;
    }
  };

  enhancerColumns = (column) => {
    // 判断是否需要固定列， 当宽度不足时 没必要固定的操作，因为固定列的原理是重复渲染
    column = this.resetWidth(column);
    const columns = column.map((col, index) => {
      if (col.children && col.children.length) {
        return {
          ...this.loop(col, index),
          children: this.enhancerColumns(col.children),
        };
      }
      return this.loop(col, index);
    });
    return columns;
  };

  getEnhancerColumn = (column) => {
    return this.enhancerColumns(column);
  };

  getOrder = (searchIndex, sonOrders) => {
    const { cols } = this.props;
    let group = cols[searchIndex];
    let sonOrder = [];
    let sonOrderIndex;
    if (group.sort) {
      if (group.sort.randomName) {
        sonOrderIndex = sonOrders.findIndex((i) => i.randomName === group.sort.randomName);
      }
      if (!group.sort.randomName) {
        sonOrderIndex = sonOrders.findIndex((i) => group.randomName === i.column);
      }
    }
    if (sonOrderIndex > -1) {
      sonOrder.push(sonOrders[sonOrderIndex]);
    }
    return sonOrder;
  };

  getGroup = (searchIndex) => {
    const groups = [];
    const { cols } = this.props;
    let group = cols[searchIndex].formula;
    if (cols[searchIndex].randomName) {
      group = `${group} AS ${cols[searchIndex].randomName}`;
    }
    groups.push(group);
    return groups;
  };

  getDateFilter = (item, record, key) => {
    let filter = [];
    switch (item.agg) {
      case 'hour':
        return (filter = [
          {
            name: `toHour(${item.name})`,
            operator: '=',
            type: 'filter',
            sqlType: item.sqlType,
            value: record[key],
          },
        ]);
      case 'day':
        return (filter = [
          {
            name: item.name,
            operator: '=',
            type: 'filter',
            sqlType: item.sqlType,
            value: `'${moment(record[key]).format(DEFAULT_DATE_FORMAT)}'`,
          },
        ]);
      default:
        const dateArr = record[key].split('~');
        dateArr.map((date, index) => {
          filter.push({
            name: item.name,
            operator: index === 0 ? '>=' : '<=',
            type: 'filter',
            sqlType: item.sqlType,
            value: `'${moment(date).format(DEFAULT_DATE_FORMAT)}'`,
          });
        });
        return filter;
    }
  };

  getFilter = (searchIndex, record) => {
    const { cols } = this.props;
    const item = cols[searchIndex - 1];
    let key;
    if (cols[0].randomName) {
      key = cols[0].randomName;
    } else {
      key = cols[0].name;
    }
    let filter;
    if (item.visualType === 'date') {
      filter = this.getDateFilter(item, record, key);
      // `'${moment(record[key]).format(DEFAULT_DATE_FORMAT)}'`
    } else {
      filter = [
        {
          name: item.name,
          operator: item.visualType === 'number' ? '=' : 'in',
          type: 'filter',
          sqlType: item.sqlType,
          value: [`'${record[key]}'`],
        },
      ];
    }
    return filter;
  };

  handleData = (children, id, searchIndex, nowFilter) => {
    const { data } = this.state;
    const { cols } = this.props;
    let name;
    if (cols[searchIndex].randomName) {
      name = cols[searchIndex].randomName;
    } else {
      name = cols[searchIndex].name;
    }
    let key;
    if (cols[0].randomName) {
      key = cols[0].randomName;
    } else {
      key = cols[0].name;
    }
    children.forEach((item, index) => {
      item.searchIndex = searchIndex + 1;
      if (searchIndex + 1 !== cols.length) {
        item.children = [];
      }
      item[key] = item[name];
      item.id = `${new Date()} + ${Math.random()}`;
      item.filter = nowFilter;
    });
    const dataMap = (items) => {
      items.find((item) => {
        if (item.id === id) {
          item.children = children;
          return items;
        }
        if (item.children && item.children.length > 0) {
          dataMap(item.children);
        }
      });
    };
    return dataMap(data || []);
  };

  // 展开处理逻辑
  onExpand = (expanded, record) => {
    if (!expanded) return; // 收起操作
    if (record.children && record.children.length > 0) return; // 已经请求过数据
    const { id, searchIndex } = record;
    const { cols, pagination, widgetInfo } = this.props;
    const sonOrders = this.props.requestParams.sonOrders;
    const limit = pagination ? pagination.pageSize : null;
    if (searchIndex >= cols.length) return; // 到了最后一列， 不能展开
    if (cols.length <= 1) return; //只有一列，不能展开
    const beforeFilter = this.props.requestParams.filters;
    const groups = this.getGroup(searchIndex);
    const nowFilter = this.getFilter(searchIndex, record);
    const order = this.getOrder(searchIndex, sonOrders);
    let nowFilters;
    if (!record.filter) {
      nowFilters = beforeFilter.concat(nowFilter);
    } else {
      nowFilters = record.filter.concat(nowFilter);
    }

    let requestParams = {
      groups,
      aggregators: this.props.requestParams.aggregators,
      filters: nowFilters,
      limit,
      cache: false,
      expired: 0,
      flush: false,
      nativeQuery: false,
      orders: order,
    };
    try {
      const a = {
        widgetId: widgetInfo.id,
        dashboardId: PageHeaderStore.currentBoardId,
        ...requestParams,
        title: widgetInfo.name,
      };
      util.post(
        `${api.BI.views}/${widgetInfo.viewId}/getdata`,
        getRequestBody({
          widgetId: widgetInfo.id,
          dashboardId: PageHeaderStore.currentBoardId,
          ...requestParams,
          title: widgetInfo.name,
        }),
        (res) => {
          if (!res.data) return;
          const { resultList: children } = res.data;
          this.setState({
            data: this.handleData(children, id, searchIndex, nowFilters),
          });
        },
        true
      );
    } catch (err) {}
  };

  render() {
    const { chartStyles, width, data, limit } = this.props;
    const { headerFixed, bordered, withPaging, size } = chartStyles.table;
    const { tablePagination, tableColumns, tableBodyHeight, mapTableHeaderConfig, containerWidthRatio, showRows } = this.state;
    const adjustedTableColumns = this.adjustTableColumns(tableColumns, mapTableHeaderConfig, containerWidthRatio);
    const getEnhancerColumn = this.getEnhancerColumn(adjustedTableColumns);
    const paginationConfig = {
      ...this.basePagination,
      ...tablePagination,
    };
    const scroll = this.getTableScroll(adjustedTableColumns, width, headerFixed, tableBodyHeight);
    const style = this.getTableStyle(headerFixed, tableBodyHeight);

    const paginationWithoutTotal =
      withPaging && tablePagination.total === -1 ? <PaginationWithoutTotal dataLength={data.length} size={'small'} {...paginationConfig} /> : null;
    let expandIconKey = 0;
    if (getEnhancerColumn.length) {
      const { cols } = this.props;
      if (cols.length) {
        const colName = cols[0].name;
        getEnhancerColumn.some((col, index) => {
          if (col.originalName === colName) {
            expandIconKey = index;
          }
        });
        delete getEnhancerColumn[expandIconKey].onCell;
      }
    }
    let dataSource = !showRows ? data : getAggData(data, this.props);
    if (dataSource && limit) {
      dataSource = dataSource.slice(0, limit);
    }
    return (
      <Fragment>
        <ConfigProvider locale={zh_CN}>
          <AntTable
            style={style}
            className={`table ${bordered !== undefined && !bordered ? 'noBorder' : ''}`}
            ref={this.table}
            size={bodyWidth <= 768 ? 'default' : size}
            dataSource={dataSource}
            rowKey={this.getRowKey}
            components={tableComponents}
            expandIconColumnIndex={expandIconKey}
            columns={getEnhancerColumn}
            // columns={adjustedTableColumns}
            pagination={!showRows ? (data.length && tablePagination.total > 10 && withPaging ? paginationConfig : false) : { simple: bodyWidth <= 768 }}
            scroll={scroll}
            // bordered={bordered}
            onChange={this.tableChange}
            onExpand={this.onExpand}
          />
        </ConfigProvider>
        {paginationWithoutTotal}
      </Fragment>
    );
  }
}

export default Table;

// 组装columns
function getTableColumns(props) {
  var linkRegex = new RegExp(/链接$/);
  const { chartStyles, width } = props;
  if (!chartStyles.table) {
    return {
      tableColumns: [],
      mapTableHeaderConfig: {},
    };
  }
  const { cols, rows, metrics, data, queryVariables, pagination, requestParams } = props;
  const { headerConfig, columnsConfig, autoMergeCell, leftFixedColumns, rightFixedColumns, withNoAggregators } = chartStyles.table;
  const { orders } = requestParams;

  let defaultOrderKey = null;
  let defaultOrder = '';
  if (orders && orders.length) {
    defaultOrderKey = orders[0].column;
    defaultOrder = SortTypesToOrder[orders[0].direction];
  }

  let tableColumns = [];
  const mapTableHeaderConfig = {};
  const fixedColumnInfo = {};
  let calculatedTotalWidth = 0;
  let fixedTotalWidth = 0;
  let linkKey = '';
  let linkAgg = '';

  // 聚合显示相关变量
  const showRows = rows && rows.length ? true : false;
  const rowsKey = showRows ? rows[0].randomName || rows[0].name : [];
  const secondKeys = []; //二级表头
  const dataSource = !showRows ? data : getAggData(data, props);

  const dimensions = cols.concat(showRows ? [] : rows);

  showRows &&
    data.forEach((item) => {
      secondKeys.indexOf(item[rowsKey]) < 0 && secondKeys.push(item[rowsKey]);
    });

  // 插入序号  展开不需要序号
  // const serialNumberColumn = {
  //   dataIndex: 'serialNumber',
  //   extraKey: 'all',
  //   key: 'serialNumber',
  //   className: 'ant-table-column-serialNumber',
  //   sorter: false,
  //   onCell: (record, rowIndex) => ({
  //     cellVal: (pagination.pageNo - 1) * pagination.pageSize + rowIndex + 1
  //   }),
  //   width: computeCellWidth(null, pagination ? pagination.totalCount : '10000')
  // }
  // tableColumns.push(serialNumberColumn)

  // 处理维度部分，合为一列
  const dimensionsList = [];

  const dimensionsDisplay = getCartesianChartMetrics(dimensions);
  dimensionsDisplay.forEach((dimension) => {
    const { name, field, format, fieldDisplay, randomName, displayName } = dimension;
    if (!linkRegex.test(fieldDisplay || name)) {
      const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || name;
      const column = {
        originalName: name,
        key: randomName || name,
        title:
          field && field.desc ? (
            <Fragment>
              {headerText}
              <Tooltip title={field.desc} placement="top">
                <Icon className="headerIcon" type="info-circle" />
              </Tooltip>
            </Fragment>
          ) : (
            headerText
          ),
        dataIndex: randomName || name,
        sortOrder: defaultOrderKey && (defaultOrderKey === randomName || defaultOrderKey === name) && defaultOrder,
      };
      // if (autoMergeCell) {
      //   column.render = (text, _, idx) => {
      //     // dimension cells needs merge
      //     const rowSpan = cellSpanMap[randomName || name][idx]
      //     return rowSpan === 1 ? text : { children: text, props: { rowSpan } }
      //   }
      // }
      let headerConfigItem = null;
      findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
        headerConfigItem = config;
      });
      const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
      const haveStyle = columnConfigItem && columnConfigItem.style && columnConfigItem.style.width;
      column.styleWidth = haveStyle ? columnConfigItem.style.width : 0;
      const isFixed = columnConfigItem && columnConfigItem.style && columnConfigItem.style.inflexible;
      if (isFixed) {
        column.width = fixedColumnInfo[column.key] = columnConfigItem.style.width;
        fixedTotalWidth += column.width;
      } else {
        column.width = getDataColumnWidth(name, columnConfigItem, format, data);
        let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, headerText) + 14;
        if (dimension.field && dimension.field.desc) {
          headerWidth += 14 + 16;
        }
        if (columnConfigItem && columnConfigItem.sort) {
          headerWidth += 30;
        }
        column.width = Math.max(+column.width, headerWidth);
      }
      calculatedTotalWidth += column.width;

      column.sorter = !columnConfigItem || typeof columnConfigItem.sort === 'undefined' ? true : columnConfigItem.sort;
      mapTableHeaderConfig[randomName || name] = headerConfigItem;
      if (!linkKey) {
        column.onCell = (record) => ({
          config: columnConfigItem,
          format,
          cellVal: record[randomName || name],
          cellValRange: null,
        });
      } else {
        const linkKeyCopy = linkKey;
        linkKey = '';
        column.onCell = (record) => ({
          config: columnConfigItem,
          format,
          cellVal: record[randomName || name],
          cellValRange: null,
          linkKey: linkKeyCopy,
          record,
        });
      }
      dimensionsList.push(column);
    } else {
      linkKey = randomName || name;
    }
  });

  const DimensionsCombine = getDimensionsCombine(dimensionsList);

  if (DimensionsCombine) {
    tableColumns.push(DimensionsCombine);
  }

  const metricsDisplay = getCartesianChartMetrics(metrics);
  metricsDisplay.forEach((metric) => {
    const { name, field, format, agg, fieldDisplay, randomName, displayName } = metric;
    let expression = decodeMetricName(name);
    // 是否使用行列
    if (!showRows) {
      if (!linkRegex.test(fieldDisplay || name)) {
        if (!withNoAggregators) {
          expression = randomName || `${agg}(${expression})`;
        }
        const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || expression;
        const column = {
          originalName: name,
          key: expression,
          title:
            field && field.desc ? (
              <Fragment>
                {headerText}
                <Tooltip title={field.desc} placement="top">
                  <Icon className="headerIcon" type="info-circle" />
                </Tooltip>
              </Fragment>
            ) : (
              headerText
            ),
          dataIndex: expression,
          sortOrder: defaultOrderKey && (defaultOrderKey === randomName || defaultOrderKey === name) && defaultOrder,
        };
        let headerConfigItem = null;
        findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
          headerConfigItem = config;
        });
        const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
        const isFixed = columnConfigItem && columnConfigItem.style && columnConfigItem.style.inflexible;
        if (isFixed) {
          column.width = fixedColumnInfo[column.key] = columnConfigItem.style.width;
          fixedTotalWidth += column.width;
        } else {
          column.width = getDataColumnWidth(expression, columnConfigItem, format, data);
          let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, headerText) + 14;
          if (metric.field && metric.field.desc) {
            headerWidth += 14 + 16;
          }
          column.width = Math.max(+column.width, headerWidth, 96);
        }
        calculatedTotalWidth += column.width;
        column.sorter = !columnConfigItem || typeof columnConfigItem.sort === 'undefined' ? true : columnConfigItem.sort;
        mapTableHeaderConfig[expression] = headerConfigItem;
        if (!linkKey) {
          column.onCell = (record) => ({
            config: columnConfigItem,
            format,
            cellVal: record[expression],
            cellValRange: getTableCellValueRange(data, expression, columnConfigItem),
          });
        } else {
          const linkKeyCopy = linkKey;
          const linkAggCopy = linkAgg;
          linkKey = '';
          linkAgg = '';
          column.onCell = (record) => ({
            config: columnConfigItem,
            format,
            cellVal: record[expression],
            cellValRange: getTableCellValueRange(data, expression, columnConfigItem),
            linkKey: linkKeyCopy,
            linkAgg: linkAggCopy,
            record,
          });
        }
        tableColumns.push(column);
      } else {
        linkKey = expression;
        randomName && (linkAgg = agg);
      }
    } else {
      if (!withNoAggregators) {
        expression = randomName || `${agg}(${expression})`;
      }
      const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || expression;
      const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
      let columnWidth = 0;
      let headerConfigItem = null;
      findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
        headerConfigItem = config;
      });
      secondKeys.map((i) => {
        let key = `${i}^$${expression}`;
        let width = getDataColumnWidth(key, columnConfigItem, format, dataSource);
        let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, i) + 30;
        width = width > headerWidth ? width : headerWidth;
        columnWidth += width;
      });
      const column = {
        key: expression,
        title:
          field && field.desc ? (
            <Fragment>
              {headerText}
              <Tooltip title={field.desc} placement="top">
                <Icon className="headerIcon" type="info-circle" />
              </Tooltip>
            </Fragment>
          ) : (
            headerText
          ),
        dataIndex: expression,
        width: columnWidth,
        align: 'center',
        children: secondKeys.map((i) => {
          let key = `${i}^$${expression}`;
          let width = getDataColumnWidth(key, columnConfigItem, format, dataSource);
          let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, i) + 30;
          width = width > headerWidth ? width : headerWidth;
          return {
            title: i,
            key: key,
            dataIndex: key,
            align: 'right',
            sorter: (a, b) => {
              return a[key] - b[key];
            },
            onCell: (record) => {
              return {
                config: columnConfigItem,
                format,
                cellVal: record[key],
                cellValRange: getTableCellValueRange(dataSource, key, columnConfigItem),
              };
            },
            width,
          };
        }),
      };
      calculatedTotalWidth += columnWidth;
      tableColumns.push(column);
    }
  });
  // adjust column width
  const flexibleTotalWidth = calculatedTotalWidth - fixedTotalWidth;
  const flexibleContainerWidth = width - fixedTotalWidth;
  const containerWidthRatio = flexibleTotalWidth < flexibleContainerWidth ? flexibleContainerWidth / flexibleTotalWidth : 1;
  tableColumns.forEach((column) => {
    if (fixedColumnInfo[column.key] === void 0) {
      // Math.floor to avoid creating float column width value and scrollbar showing
      // not use Math.ceil because it will exceed the container width in total
      column.width = Math.floor(containerWidthRatio * Number(column.width));
    }
  });

  const left = [];
  const middle = [];
  const right = [];
  tableColumns.forEach((column, index) => {
    const name = column.children && column.children.length ? column.title : column.originalName;
    if (leftFixedColumns.includes(name)) {
      column.fixed = 'left';
      left.push(column);
    } else if (rightFixedColumns.includes(name)) {
      column.fixed = 'right';
      right.push(column);
    } else {
      middle.push(column);
    }
  });
  tableColumns = left.concat(middle.concat(right));
  return { tableColumns, mapTableHeaderConfig, containerWidthRatio };
}

function getPaginationOptions(props) {
  const { chartStyles, width, pagination } = props;
  // fixme
  let pageNo = void 0;
  let pageSize = void 0;
  let totalCount = void 0;
  if (pagination) {
    pageNo = pagination.pageNo;
    pageSize = pagination.pageSize;
    totalCount = pagination.totalCount;
  }
  // const { pageNo, pageSize, totalCount } = pagination
  const { pageSize: initialPageSize } = chartStyles.table;

  const paginationOptions = {
    current: pageNo,
    pageSize: pageSize || +initialPageSize,
    total: totalCount,
    simple: bodyWidth <= 768,
  };
  return paginationOptions;
}

function getAggData(data, props) {
  const { cols, rows, metrics } = props;
  if (rows.length) {
    const rowsKey = rows[0].randomName || rows[0].name;
    const metricsKeys = metrics.map((item) => item.randomName || `${item.agg}(${decodeMetricName(item.name)})`);
    const colsKeys = cols.map((item) => item.randomName || item.name);
    const keyList = colsKeys.concat(metricsKeys);
    const secondKeys = []; //二级表头
    const dataObj = {};
    const result = [];
    const fillEmptyKeys = [];
    //把同一行的数据归类
    data.forEach((item) => {
      let key = getDataObjKey(item, colsKeys);
      let dataItem = {};
      for (let key in item) {
        const fillEmptyKey = `${item[rowsKey]}^$${key}`;
        metricsKeys.indexOf(key) > -1 && (dataItem[fillEmptyKey] = item[key]);
        fillEmptyKeys.push(fillEmptyKey);
      }
      secondKeys.indexOf(item[rowsKey]) < 0 && secondKeys.push(item[rowsKey]);
      dataObj[key]
        ? (dataObj[key] = { ...dataObj[key], ...dataItem })
        : (dataObj[key] = {
            ...getDataObjItem(item, keyList),
            ...dataItem,
          });
    });
    for (let key in dataObj) {
      keyList.concat(fillEmptyKeys).forEach((item) => {
        !dataObj[key][item] && (dataObj[key][item] = '');
      });
      result.push(dataObj[key]);
    }
    return result;
  }
}

function getDataObjKey(data, colsKeys) {
  let key = '';
  colsKeys.forEach((item) => {
    key += data[item];
  });
  return key;
}
function getDataObjItem(data, keyList) {
  let obj = {};
  keyList.forEach((item) => {
    obj[item] = data[item];
  });
  return obj;
}
