import React, { Component } from 'react';
import { action, observable, toJS, autorun } from 'mobx';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import util from '../../util';
import api from '../../api';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import TableNullData from '../../component/TableNullData/index';
import { message } from 'antd';
import AnalysePathStep from './AnalysePathStep/index';

import PathFormStore from './store.js';
import customBoardUtil from '../../utils/customBoardUtil';
import Loading from '../../component/Loading/Loading';
import DimSelect from '../../component/DimSelect/index';
import AnalyseForm from '../AnalyseForm';
import AnalyseLayout from '../AnalyseLayout';
import AnalyseChartSelect from '../../component/AnalyseChartSelect/index';
import AnalysePathGroup from './AnalysePathGroup';
import mathUtil from '../../utils/mathUtil';
import moment from 'moment';
@observer
class AnalysePathForm extends Component {
  constructor(props) {
    super(props);
    this.formStore = new PathFormStore();
  }
  // 路径管理的选项
  @observable isShowModal = false;
  @observable count = 0;
  // 路径管理的选项
  @observable isUpdate = true;
  @observable dataFromParentData = true;
  // 步骤外的维度下拉框
  @observable eventStore = {
    form: {
      eventAndTargetGroup: [{ type: 'default', dim: 'event', formula: '=', dimValue: '任意事件' }],
      subDividedDimGroup: [''],
    },
    subDimOptions: [],
  };
  // 预览的数据
  @observable sankeyData;
  @observable parentData = null;
  @observable paramObj;
  @observable dims;

  @observable chartName = null;
  @observable isNotShowOption = true;
  componentDidMount() {
    this.disposer = autorun(() => {
      let dateObj = this.formStore.dateObj;
      this.dataFromParentData && this.getChartAndTable(null, null, 1, 10);
    });
  }
  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  // 获取该事件管理的配置
  @action.bound getEventConfig(id, name) {
    if (id === 'default') {
      this.eventStore.form.subDividedDimGroup[0] = '';
      this.chartName = null;
      this.formStore.setPathId('null');
      this.formStore.initPathStepData();
      this.parentData = null;
      this.dataFromParentData = true;
      this.isUpdate = true;
      // this.count++;
    } else {
      this.isUpdate = false;
      this.formStore.setPathId(id);

      name && (this.chartName = name);
      this.parentData = null;
      this.dataFromParentData = false;

      util.get(
        api.analysis.info,
        {
          ids: id,
        },
        (res) => {
          this.eventStore.form.subDividedDimGroup[0] = res[0].dims;
          this.formStore.chartType = res[0].chartType;
          this.dims = res[0].dims;
          this.formStore.dateObj = res[0].customizeParam ? JSON.parse(res[0].customizeParam).dateObj : '';
          this.formStore.config = {
            remarks: res[0].remarks,
            name: res[0].name,
          };
        }
      );
      util.get(
        api.analyticsPathStep.findAll,
        {
          pathId: id,
        },
        (res) => {
          this.formStore.resetPathStepData(res);

          this.isUpdate = true;
        }
      );
      this.count++;
    }
  }
  //生成图表的方法
  @action.bound getChartAndTable(orderDesc, orderColumn, currentPage = 1, pageCount = 10) {
    this.isUpdate = false;
    this.dataFromParentData = true;
    this.parentData = null;
    let dims = this.eventStore.form.subDividedDimGroup[0] ? this.eventStore.form.subDividedDimGroup[0] : `date_${pageHeaderStore.parameter.period}`;
    let paramObj = {
      idsite: pageHeaderStore.parameter.idsite,
      date:
        pageHeaderStore.isUseParameterDate && pageHeaderStore.parameter.date
          ? pageHeaderStore.parameter.date
          : this.formStore.dateObj
          ? this.formStore.dateObj.lastDate
            ? mathUtil.keyToDate(this.formStore.dateObj.lastDate)
            : this.formStore.dateObj.date
          : `${moment()
              .subtract(8, 'days')
              .format('YYYY-MM-DD')},${moment()
              .subtract(1, 'days')
              .format('YYYY-MM-DD')}`,
      orderDesc: true,
      currentPage,
      pageCount,
      actionType: this.formStore.chartType,
      dims,
    };
    if (Number(pageHeaderStore.parameter.userGroupId) > 0) {
      paramObj['userGroupId'] = pageHeaderStore.parameter.userGroupId;
    }
    if (this.formStore.chartType === 13) {
      delete paramObj.dims;
      paramObj.pageCount = 3;
    }
    if (this.formStore.chartType === 14) {
      delete paramObj.currentPage;
      delete paramObj.pageCount;
      delete paramObj.orderDesc;
      delete paramObj.dims;
    }

    toJS(this.formStore.pathStepData).forEach((stepItem, index) => {
      let stepParams = stepItem.map((item) => {
        let params = item;
        if (!params.filterObject.filters) {
          params.filterObject = '';
        }
        delete params.filterItemLogic;
        delete params.childrenFilter;
        return params;
      });

      paramObj[`step${index + 1}`] = JSON.stringify(stepParams);
    });
    this.paramObj = paramObj;
    this.dims = dims;
    util.post(
      api.analyticsPathStep.preview,
      {
        // 需要一个方法把数组转为{step1:{},step2:{}}
        ...paramObj,
      },
      (res) => {
        if (Object.prototype.toString.call(res.data) === '[object String]') {
          message.info(res.data);
          this.parentData = null;
          this.isUpdate = true;
          this.count++;
        } else {
          this.changeParentData(res.data.data);
        }
      }
    );
  }
  @action.bound changeParentData = (data) => {
    this.parentData = data;
    this.isUpdate = true;
    this.count++;
  };
  // 保存或者更新的接口
  @action.bound saveOrUpdate = (otherParam, callback, saveAs) => {
    let dims = this.eventStore.form.subDividedDimGroup[0] ? this.eventStore.form.subDividedDimGroup[0] : `date_${pageHeaderStore.parameter.period}`;
    let paramObj = {
      pathId: saveAs ? 'null' : this.formStore.pathId,
      idsite: pageHeaderStore.parameter.idsite,
      ...otherParam,
      dims,
      type: 2,
      chartType: this.formStore.chartType,
      customizeParam: JSON.stringify({ dateObj: this.formStore.dateObj }),
    };
    toJS(this.formStore.pathStepData).map((stepItem, index) => {
      let stepParams = stepItem.map((item) => {
        let params = item;
        if (!params.filterObject.filters) {
          params.filterObject = '';
        }
        delete params.filterItemLogic;
        delete params.childrenFilter;
        return params;
      });
      paramObj[`step${index + 1}`] = JSON.stringify(stepParams);
    });
    util
      .post(
        api.analyticsPathStep.saveAll,
        {
          // 需要一个方法把数组转为{step1:{},step2:{}}
          ...paramObj,
        },
        callback
      )
      .then(() => {
        if (this.formStore.pathId !== 'null') {
          this.getEventConfig(this.formStore.pathId);
        }
      });
  };
  // 每个事件有不同的维度选项，所以该变量subDimOptions为事件切换后的维度下拉选项
  // @action.bound resetSubDimOptions = (value) => {
  //   this.eventStore.subDimOptions = value;
  // };
  // 智能路径显示单个表单维度 暂时默认修改第一位（下标为0）
  @action.bound setSubDividedDim = (value, index) => {
    if (!value[1]) {
      this.eventStore.form.subDividedDimGroup[0] = '';
      this.dims = `date_${pageHeaderStore.parameter.period}`;
    } else {
      this.eventStore.form.subDividedDimGroup[0] = value[1];
      this.dims = value[1];
    }
  };

  @action.bound changeChartType(value) {
    this.parentData = null;

    this.formStore.chartType = customBoardUtil.changeNumberToType(value);
    if (this.dataFromParentData === true) {
      this.getChartAndTable(null, null, 1, 10);
    }
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xl: { span: 24 },
        lg: { span: 24 },

        md: { span: 24 },
      },
      wrapperCol: {
        lg: { span: 24 },
        md: { span: 24 },
      },
    };
    return (
      <AnalyseLayout
        {...this.props}
        layoutTitle="关键任务"
        chartName={this.chartName}
        type={2}
        // type:Func
        saveOrUpdate={this.saveOrUpdate}
        // 保存时的方法判断
        rulesForSave={this.rulesForSave}
        // 生成预览图
        getChartAndTable={this.getChartAndTable}
        getEventConfig={this.getEventConfig}
        id={this.formStore.pathId === 'null' ? null : this.formStore.pathId}
        eventName={'关键任务'}
        chartConfig={this.formStore.config}
        analyseSelectCard={
          <Form {...formItemLayout}>
            <AnalyseForm
              formArr={[
                {
                  label: '步骤',
                  component: (
                    <React.Fragment>
                      <AnalysePathStep showModal={this.showModal} formStore={this.formStore}></AnalysePathStep>
                    </React.Fragment>
                  ),
                },

                {
                  label: '细分维度',
                  component: (
                    <DimSelect
                      analysisType={2}
                      dimIndex={0}
                      allowClear={true}
                      dimItem={this.eventStore.form.subDividedDimGroup[0]}
                      setSubDividedDim={this.setSubDividedDim}
                      eventAndTargetGroup={toJS(this.formStore.pathStepData)}></DimSelect>
                  ),
                },
              ]}></AnalyseForm>
          </Form>
        }
        analyseChartContent={
          <React.Fragment>
            <div className={`analyseLayout_content-chartGroup-chart ${this.formStore.chartType === 8 && 'scroll'}`}>
              {this.isUpdate ? (
                this.parentData || (this.formStore.pathId && this.formStore.pathId !== 'null') ? (
                  <AnalysePathGroup
                    chartType={this.formStore.chartType}
                    dataFromParentData={this.dataFromParentData}
                    fetchData={this.getChartAndTable}
                    parentData={toJS(this.parentData)}
                    pathId={this.formStore.pathId}
                    count={this.count}
                    paramObj={this.paramObj}
                    dims={this.dims}
                    dateObj={this.formStore.dateObj}
                    setDateObj={this.formStore.setDateObj}
                    isLoading={!this.isUpdate}></AnalysePathGroup>
                ) : (
                  <TableNullData nullDataType="analysePath"></TableNullData>
                )
              ) : (
                <Loading></Loading>
              )}
            </div>
          </React.Fragment>
        }
        analyseChartConfig={{
          chartTypeSet: {
            show: true,
            configName: '图表类型',
            component: (
              <AnalyseChartSelect chartType={this.formStore.chartType} selectType="analysePath" changeChartType={this.changeChartType}></AnalyseChartSelect>
            ),
          },
        }}></AnalyseLayout>
    );
  }
}
const AnalysePath = Form.create()(AnalysePathForm);
export default AnalysePath;
