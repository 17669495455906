import { Form, Input, InputNumber, Modal, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { createForm } from 'rc-form';
import React, { Component, Fragment } from 'react';
import intl from 'react-intl-universal';
import util from '../../../util';
import api from '../../../api';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import SidebarStore from '../../../component/Sidebar/store';

@observer
class BoardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderList: [],
      folderId: '0',
    };
  }

  componentDidMount() {
    this.getFolderList();
  }

  getFolderList() {
    util.get(
      api.folder.list,
      {
        idsite: PageHeaderStore.parameter.idsite,
        // share: this.props.modalInfo.boardType ? (this.props.modalInfo.boardType === '自有' || this.props.modalInfo.boardType === '模板' ? false : true) : false,
        // dashboardType: SidebarStore.dashboardType !== 1 ? SidebarStore.dashboardType : null,
        type: SidebarStore.dashboardType,
      },
      (res) => {
        this.setState({
          folderList: res,
        });
        if (this.props.modalInfo.id) {
          this.getFolderInfo();
        }
      }
    );
  }

  getFolderInfo() {
    util.get(
      api.folder.getDashBoardFolderInfo,
      {
        idsite: PageHeaderStore.parameter.idsite,
        // share: this.props.modalInfo.boardType ? (this.props.modalInfo.boardType === '自有' || this.props.modalInfo.boardType === '模板' ? false : true) : false,
        folderType: this.props.modalInfo.boardType ? (this.props.modalInfo.boardType === '自有' || this.props.modalInfo.boardType === '模板' ? 1 : 2) : 1,
        id: this.props.modalInfo.id,
      },
      (res) => {
        this.setState({
          folderId: res.id ? res.id : '0',
        });
      }
    );
  }

  getTypes(types) {
    const current = SidebarStore.dashboardType;
    switch (current) {
      case 1:
        return types.filter((i) => i.value !== 2 && i.value !== 3);
      case 2:
        return types.filter((i) => i.value === 2);
      case 3:
        return types.filter((i) => i.value === 3);
    }
  }

  getTitle() {
    const { modalInfo } = this.props;
    let title = '';
    switch (modalInfo.openType) {
      case 'create':
        title += intl.get('Tianjia');
        break;
      case 'edit':
        title += intl.get('Edit');
        break;
      default:
        title += intl.get('Copy');
        break;
    }
    const pathname = location.pathname;
    if (pathname.includes('/design/customerJourney')) {
      title += intl.get('UserJourney');
    } else if (pathname.includes('/design/blueprint')) {
      title += intl.get('ServiceBlueprint');
    } else {
      title += intl.get('Dashboard');
    }
    return title;
  }

  render() {
    const { type, modalInfo } = this.props;
    const { folderList } = this.state;
    let types = [
      {
        value: 1,
        label: '普通看板',
      },
      {
        value: 2,
        label: '用户旅程地图',
      },
      {
        value: 3,
        label: '服务蓝图',
      },
      // {
      //   value: 6,
      //   label: '行动',
      // },
      // {
      //   value: 4,
      //   label: '问卷',
      // },
      // {
      //   value: 5,
      //   label: '用户数据看板',
      // },
      // {
      //   value: 7,
      //   label: 'iframe看板',
      // },
      {
        value: 8,
        label: '看板模板',
      },
      {
        value: 9,
        label: '分析模板',
      },
    ];
    const templateType = [
      {
        value: 0,
        label: '普通看板',
      },
      {
        value: 1,
        label: '看板模板',
      },
      {
        value: 2,
        label: '分析模板',
      },
    ];
    const designTemplateType = [
      {
        value: 0,
        label: '普通',
      },
      {
        value: 1,
        label: '模板',
      },
    ];
    const businessTypes = [
      {
        value: 1,
        label: '产品',
      },
      {
        value: 2,
        label: '零售',
      },
      {
        value: 3,
        label: '服务',
      },
      {
        value: 4,
        label: '营销',
      },
    ];
    return (
      <Modal
        title={this.getTitle()}
        width={500}
        destroyOnClose={true}
        visible={modalInfo.isShowAddBoard}
        onCancel={this.props.hideAddBoard}
        okText={intl.get('Confirm')}
        cancelText={intl.get('Cancel')}
        confirmLoading={modalInfo.confirmLoading}
        onOk={() => this.props.addBoard(this.state.folderId)}>
        <Form id="addborad">
          <Form.Item>
            <span className="input-name">
              {intl.get(SidebarStore.dashboardType === 1 ? 'Dashboard' : SidebarStore.dashboardType === 2 ? 'UserJourney' : 'ServiceBlueprint')}
              {intl.get('Name')}：{/* 看板名称： */}
            </span>
            <Input
              type="text"
              value={modalInfo.name}
              placeholder={intl.get('PleaseEnter')}
              style={{
                width: 240,
              }}
              onChange={(e) => this.props.modalFormChange({ name: e.target.value })}
            />
          </Form.Item>
          {[2, 3].indexOf(SidebarStore.dashboardType) < 0 ? (
            <Fragment>
              <Form.Item>
                <span className="input-name">所属文件夹：</span>
                <Select
                  className="lg-select"
                  defaultValue={String(this.state.folderId)}
                  onChange={(value) => this.props.modalFormChange({ folderId: value })}
                  key={this.state.folderId}>
                  <Select.Option key="0">根目录</Select.Option>
                  {folderList &&
                    folderList.map((item) => {
                      return <Select.Option key={item.id}>{item.name}</Select.Option>;
                    })}
                </Select>
              </Form.Item>
              <Form.Item>
                <span className="input-name">看板类型：</span>
                <Select
                  className="lg-select"
                  value={modalInfo.template ? modalInfo.template.toString() : '0'}
                  onChange={(value) => this.props.modalFormChange({ template: value })}>
                  {templateType.map((item) => {
                    return <Select.Option key={item.value}>{item.label}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Fragment>
          ) : (
            <>
              <Form.Item>
                <span className="input-name">业务类型：</span>
                <Select
                  className="lg-select"
                  value={modalInfo.businessType ? modalInfo.businessType.toString() : 1}
                  onChange={(value) => this.props.modalFormChange({ businessType: value })}>
                  {businessTypes.map((item) => {
                    return <Select.Option key={item.value}>{item.label}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <span className="input-name">模板类型：</span>
                <Select
                  className="lg-select"
                  value={modalInfo.template ? modalInfo.template.toString() : '0'}
                  onChange={(value) => this.props.modalFormChange({ template: value })}>
                  {designTemplateType.map((item) => {
                    return <Select.Option key={item.value}>{item.label}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <span className="input-name">所属文件夹：</span>
                <Select
                  className="lg-select"
                  defaultValue={String(this.state.folderId)}
                  onChange={(value) => this.props.modalFormChange({ folderId: value })}
                  key={this.state.folderId}>
                  <Select.Option key="0">根目录</Select.Option>
                  {folderList &&
                    folderList.map((item) => {
                      return <Select.Option key={item.id}>{item.name}</Select.Option>;
                    })}
                </Select>
              </Form.Item>
            </>
          )}
          {modalInfo.openType !== 'create' && modalInfo.openType !== 'copy' && (
            <Form.Item>
              <span className="input-name">排序:</span>
              <InputNumber
                className="lg-select"
                min={1}
                onChange={(e) => {
                  this.props.modalFormChange({ range: `${modalInfo.currentRange},${e}` });
                }}
                defaultValue={modalInfo.currentRange}
                // style={{
                //   width: 220,
                //   marginRight: '10px',
                // }}
              />
              {/* <Tooltip text title="从小到大排序，数字越小排名越靠前，允许负数">
                    <i className="iconfont icon-bangzhu1"></i>
                  </Tooltip> */}
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

export default createForm()(BoardModal);
