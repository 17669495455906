/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React from 'react';
import Resizable from './Resizable';
import { DefaultTableCellStyle } from '../constants';
import { textAlignAdapter, traverseConfig } from '../util';

function HeadCell(props) {
  const { onResize, width, config, isFirstColumn, onClick, ...rest } = props;
  let resizing = false;
  const cellStyle = config ? config.style : DefaultTableCellStyle;
  const {
    fontColor: color,
    //  fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    justifyContent,
  } = cellStyle || DefaultTableCellStyle;
  const defaultBackgroundColor = process.env.REACT_APP_TableBg_Theme === 'blue' ? '#eff6fa' : '#F3F3F4';
  const backgroundColor = config
    ? config.style.backgroundColor === 'transparent'
      ? defaultBackgroundColor
      : config.style.backgroundColor
    : defaultBackgroundColor; //表头默认背景色
  const cellCssStyle = {
    color: config ? color : '#7A8493',
    // fontFamily,
    fontSize: `${fontSize}px`,
    fontStyle,
    fontWeight: fontWeight,
    textAlign: textAlignAdapter(!config ? 'flex-start' : justifyContent),
    backgroundColor,
  };
  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      onResizeStart={() => {
        resizing = true;
      }}
      onResizeStop={() => {
        resizing = true;

        setTimeout(() => {
          resizing = false;
        }, 100);
      }}>
      <th
        style={cellCssStyle}
        {...rest}
        onClick={(...args) => {
          if (!resizing) {
            onClick && onClick(...args);
          }
        }}
      />
    </Resizable>
  );
}

export function resizeTableColumns(columns, columnIndex, width, ratio) {
  const nextColumns = [...columns];
  const resizedColumn = nextColumns[columnIndex];
  nextColumns[columnIndex] = {
    ...resizedColumn,
    width,
  };
  traverseConfig(resizedColumn.children, 'children', (childColumn) => {
    childColumn.width = ratio * +childColumn.width;
  });
  return nextColumns;
}

export default HeadCell;
