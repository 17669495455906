import AnalyseCombination from '../../containers/AnalyseCombination';

export default {
  title: '组合分析',
  components: [
    {
      component: AnalyseCombination,
    },
  ],
};
