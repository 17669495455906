import { Dropdown, Icon, Menu, Button } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import './index.sass';

@observer
class Main extends Component {
  constructor(props) {
    super(props);

    this.getTitle(this.props.options, this.props.selectValue);
  }

  componentWillReceiveProps(props) {
    this.getTitle(props.options || this.props.options, props.selectValue);
  }
  s;

  @observable isDropUp = false;
  @observable title;

  @action.bound handleSelect(option) {
    this.props.onSelect(option);
    document.getElementsByTagName('body')[0].click();
  }
  @action.bound handleTriggerEnter(e) {
    const dropDown = e.getBoundingClientRect();
    const container =
      this.props.containerSelector &&
      document.querySelectorAll(this.props.containerSelector).length > 0 &&
      document.querySelectorAll(this.props.containerSelector)[0].getBoundingClientRect();
    container && container.bottom - dropDown.top < dropDown.height + 15 ? (this.isDropUp = true) : (this.isDropUp = false);
  }

  @action.bound getTitle(options, selectValue) {
    if (selectValue !== null && selectValue !== undefined && options && options.length > 0) {
      options.forEach((item) => {
        if (item.value.toString() === selectValue.toString()) {
          this.title = item.label;
        }
      });
    } else {
      this.title = '请选择';
    }
  }

  render() {
    const { options } = this.props;

    return (
      <div className={`dropdownselect ${this.props.hasOwnProperty('noborder') ? 'hasNoBorder' : ''}`}>
        <Dropdown
          className="dropdown"
          overlayClassName="dropdown-menu"
          overlay={
            <Menu>
              {options &&
                options.length > 0 &&
                options.map((item, index) => (
                  <Menu.Item key={index} onClick={() => this.handleSelect(item)}>
                    {item.icon ? <i className={`iconfont ${item.icon}`} /> : null}
                    <span>{item.label}</span>
                  </Menu.Item>
                ))}
            </Menu>
          }
          trigger={['click']}
          onVisibleChange={(visible) => {
            visible && this.props.containerSelector && this.handleTriggerEnter;
          }}>
          <Button disabled={options && options.length === 0} title={this.title}>
            {this.props.titleIcon ? this.props.titleIcon : options && options.length > 0 ? this.title : '暂无选项'}
            {this.props.titleIcon ? null : <Icon type="down" className="down" />}
          </Button>
        </Dropdown>
      </div>
    );
  }
}

export default Main;
