// import echarts from 'echarts';
import * as echarts from 'echarts';
import { action, autorun, computed, observable, toJS } from 'mobx';
import legendUtil from '../../utils/legendUtil';
import util from '../../util';
import chartUtil from '../../utils/chartUtil';

const emotionMap = [
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAmRJREFUSEu9ls9LFHEYxj/v7O6siJChnYIsq1MgERRJF83coLqXeQmCoHOJ1j20on+gQxCUZucgdvpxirQgkmK7GSUiUR7yR7rj7MwbM+7a7M46bJq91+eZ5/m+7/fh+44QU/qClhWXc6ocRzggyg6frsIPlJwIL80Ej6SLr+vJSDVAn7FrRRlCOYtgxB0CxUMYNYUB6WaqkhsxsC16gLtAQ6xwFFwELqUzjIShMoO8xRWB20DVzmowVIW+ugx3Stw1oeLJH25CvKSpQG+pk8CgOPPcBsayXlOLph+KbqYCA9tiGILZr5ZRjzSdQBc+QP5L7GRkewe4y+j8m0reSDrDeQmiWOBzOC2pw69UGo+Juks4Y22wPFnVxNhzneS+GwFWyF3Em7n3h6d4ZpJWsS36gaE1RJKYXTbIajoLH3vxvvkNRit5yMJo6g4Ad+Y+bu5CJalf8hZZgUwYSey/RaLlKt7CBIV3HVCYr2ogzadJto2C51B4fwqdGyvjKVi+wbTAzohCogHcX34E4tMpacADdSI8VabFzmIjmPEqG0QVe+sN1h3RBg8d/mx1RBZZKi7ZJ0nzGaR+b002ujSJzj6pxs36IxpAGAyjsq2d1JHXNYmXSM7bdnRuPBpTfcruFYPJsme5roXU0Qkk1ViTiTo/ccYPQj60FhTXNGit/lT4sn5MU801GeDMguu/1qFShtMn6d2ax06ZN5O0+Zvu/zzXpcbyWfpEuLnJnTCQzgQaQf3rlXk5neFBWSKr3eJfL314bJpck04iyyN29xZ3RQ9Cp1L8bRFE4buhfFLhuZlgNO635TcK4uhw7WWNRQAAAABJRU5ErkJggg==',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAuhJREFUSEu9lk1sTGEUhp9z78ydVhq/LUHSIki0qSBp2BDVaMWGEKGNWCGWQqrd2NggQiwFGxE/lS5ZzFCLCuI/UlKxED8l1QbNtJWZuTP3yL0zQ2fmdlI/8e3ud96c9zvveb/vXKHI0i6qEkmaFeoRakSpcOEqDKK8FOG2ZXJVGng3XhrxC+hNKhPKMZTtCEaxQ6A4CB2W0iZNfMjHFhDEIzQDZ4GyookLgyMou0NNdIwN5RDEIhwUOAH4VjYBQlWHAyUbOJ3F/kyUOfmlv0iezakoLaEmrrobHkFG85d/IIt/UcqwZVEt9fR5BPEIl8HTPr2MScj0BnSkB2Jviyoj09ZCahSNPsrHXQk10iIZK74Z65ZA3X2MqavQ1HfsezXjkhjzDxNYeMRLnOzZhdN/8ReJkrIMFkg8TDvC0V+nL8VaNwKSdqf9fBs60OlbRWDFLYwZDV4s1XeeVO+eHJwqhyQWISzQODZiLj6FWbkfZ/gZySf1kIz6EkjFJgK1l8GJkXy6EY0+yCWAiEvgNmJuQQazzNMWtLg7JQQ4oHYBTpU+V6I4gjUBj/8+REmMT1C2DAlMRoe6i7to6mrU/gajLwpxStxfIms2wdVvEcPCfrIe/XrLvwflGwkuv4E6Cew7VZDoz29yn8QjhMlrMsEKgms+IkYQ51s3ycf1aZ1zlkGg7m7azi5B92ywv+YfJFxo0wzErLmAOWdX2oKfO0n17gP7SzoarMBccgZz1paMRc+R6t3rV2Wb70VLJyknuPIRUjrP+1Qnjkafea+LTF7uyeftj77GfrgSkkO5BNmL5u4WPBVZaEklgaWdGFPqfHvgydfTDPFPfg2+FGpi5wQeOxOZuRlj1lakdJHXCx19hTPQiQ5e978nStQyqJX1vP8/z3W2vliYVhGO/8VMUIXWkkZOZnP+25EJe0ONXBnbkH8z9OGaFaDd7++i6Oz1ZkWKHcA6VaoFZrouVRgQcCdgl2XSUey35QfvGSt6xtRwQwAAAABJRU5ErkJggg==',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAuhJREFUSEu1ll2ITGEYx3/POTNn1trCIkn5WBYRF6SEC7trZ8uNUmKFu3WnSHalCCVfUW7linyslOsZ1oXPJF/JRxZZbRKJdtfa2TnnPHrPzDA7c2Z8P5fnec7//7z/9/l4hTKmHUwa9FijSj3CbFHGmnCF98ATEToc4Zw08qYUjIQ59BITB5UDKKsRrHJJoPgI7U6UVqmjuzC2iCCVpBk4DlSVBS50Kr1AS6yJ9nzXEIKBJFsFDgOhJ/sFQlXYUhHnWC72O1A289N/AZ7DVIQ1sUbOmw8BQVbzx78tS6kjKb2OwyxzJwFBKskZCLTPmFWJVDegfY9g4HVZZWTUUvC+oD13CuPOxuKslaAUXV7lV0tkwS2skQtRr5/0zdklSawpO4lM2xsAu4824L879YNE8RyYIqkkbcCBH9kPw6nvA8lUZ/rhKvT9hdBTROZdxhrdEPi87hN4T1uGxKnSKgNJEgLxfI89/Sj2xM34vfdx79aB2xNKIGNXEJlzBvwB3HvL0Z7bQwkgaQjMRUwoQrCrAm2Dvi1nEgN80HRRlCrdkkqQQnDKo/yhV0n9OkF0DFJZG2Sr/S8g/fHnrIagpETZ36V6GfbUPVgjF+VViI//6Rrey13o56sliTISJUlQcMnf26FmF5Ga3SAlJocqbmcbfpeZLqGWMBJtR9hf6JZxzUTnmv4D9Qfx357E/3TFdCFWdT3W+PWIFc2U8oOV6IeLYQxtoY2G2ESXdCEVE1C3F/duY1EJyoiFROZfRuzh6NfXpK9PzVRTzkyjWdSEjgqpnEl08dMg1H3eWlICa/IOIrX7grjBG9OhvzOf4HSsiXUlhp1g1x6CaDXes03g94crbFdhzzgWVJTXaQZCtmeUHsdijtl0/2dcK2tjTZwzWQ1dOAm2iXDwL3aCWTjbKuIcyR35361M6AM2xuKczdfz3yx9OO9E2C4NdBWVe7l+z+6KZoQ6JftsEcQ8WwTMBuxwbNrDgHO43wCuwSJ2egWdDwAAAABJRU5ErkJggg==',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAztJREFUSEu9VktIVGEU/s6dGUct1JJRGmUkITHtQRhkM0XlIqLGaBGZPXCRFFHQS1OwiCjIMqtFD6hFq0ptEaQWM1C5aCZ6LAoyQlFwmqTMlHIc53lP3DuMzOM6QxL9u/+e737fOec//zk/IcEaNm4u8JNQTcAGAKUM0klwAv8A0MvMzzXgNr39ydBMNKRkGDaZDQGmZhCqQBASOQGGCEa7yu9tyHtr/RKLjRNwrN5cDZXqNoC5CYnjjS4w1xpsXe2RpigBx5otJ8DUAiLFyJIKMjODjxfYuq+FsdNEsueCcG/W5GFGWYN3GezdbaHzAiDnnKh3FmlRDIoZE6pAoCT/9VOnLOAwVt6HgOrpsFK10JYtg39gCMFvIwkzo12xBDzlhe9zfzROxAODvXMXSaUYINVgZLXk3LoE7ZJiiB4vvu09NKNIRk0VMmt3y8Q/z1+B29ITKRJUMxfSkMncSEQXpr3XpiDP2gESQtU5eroZUz12xSh0V88hdeVy2ebqtGL80vUoHDGfJIfJbAHRxkhL1uF9mLtjK/x9gxg50gSedCsKpK5ZhewzdWCfD6P1Z+H71BeNY7ZKAk4Q5cVdkLRU8JQnaWVCowZEBoJBBSw7achU6SVCSnKmv0cwwzejgGbRQlB6OnwfpOqdeaUsK4HomkRgML4dMdirmCIhez70D++ANBqMHDsN77sPymdQXgZdyxmw34/h7bUQx8ZjcOxUPGQhKxP6R3dBajU87z/ix5FTgChG/ywIyLnZDG1pcUhgWw3E365YAUtcmYYR85uOYs6mCnnrfmHDeOtNiL8m5L3kwLy6g0hfZwyV6GMLxltuxEXJIjcoXjSZJDMDuXdaoV6QK//IPj98/QNyd0kpKpTTJy2/4yu+H6gDuyZjBUIXTfoa2yrCSFWuDtnnGqBdXKR4BlL6xs5eRnB0LN575nsFtq49yZudICBtbTnS1huhyV8AFhkBhxPuHjs8tjeKwsz8WwMs1du6HP+nXYfd+GIy1zNwcdYzQZoFxPWGl92tYc5/OzKDwf2GV08eRObt3wx94g61KDYqvS4Szl752QLVTiKuAKGEmXKkOiWwNIV6ifFMBbE90bPlD8IzZJsH8p7eAAAAAElFTkSuQmCC',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAqRJREFUSEu9Vk1IVGEUPfe9GWeEzCA005zCVTRhRQk506YWETYuWkRNIkgL0UURhD/RShephVFBEC1alWbQKrUsEwKdgraNUKE4k9qkJGRq8/Ped+O9GHvjPMfRtLc9553z3Z/v3o+Q4pt0le2Mgc4S0TEATgblaHQCTwPwM/OAFfw439cbWE6GzIBJt8ehMLWCcAYEKdUhwBBgdFmIG/OHuoNLuUkGwdIyL2T5PoBNKYWTwTmoarXjbW+nEUowCB45eRlMN0BkGtmKhswM4jrHYE97nLsopJ9ckh6tWTyuqJkIURGPRDfQc07kX0NalgtqzsLs1GqiGwRd5R2Q4F0My26D7eA+xEbGoIamUmbGdmAvOBJFdPhTIk+g0+F7do60VlRIHjV2S+7dVtiK90CEIwhVXYA6ETI1yao8jS3VlTo203IH8739f3kMYWG1iAKu8gaS0LqIyDJ2DDwFSX+683tzOxZevTE1yLnZDHvJfh2bf/4aM9duJ/BIcAMF3Z4+EB03Itm1VcjynkLs8yimLl4Fzy+YGthLD2FrUz2gKJiub0L0w8dEHvNLzWAcRAVJFyTTDv4VXrEzYbUAggFVNeHyOAXc5REiZKystHoGgyMbb7BcilZ/XrM/eNy0yBrV7iqBpSAvLR9lIoSw771ZDfoo4PY0ElGLEc1w7sa2e9fTEo+TvtXUIepP7CLW2vTr4RO7YrJ1xHjR5Lxc5D24BSkrvYEqfs4hdP7S0luvWpiLTEeFdirKtEPK3pxWFOLHbHJLM3c4hrorNmTYMfOslUWxtun+z7iO5+GL21PHQNu/7ASCaCwc7GmLa67ryiQhagt9PQ+NhVufpU/8xKooV7a/ezGWNNNStYn+bCHJS8BRw7OFCDwF0DAx98sQXameLb8B3EccmNcxsVEAAAAASUVORK5CYII=',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAy9JREFUSEu1VltIU3Ec/n5n07ORbYIZeGlSL13UpBIiJ4ERBaW9lc6SyuxCBRJ5CaQkirKiG0RBEPViXnrowUspSBK66iUoUMJI2BpqVmRa6nHb+cU5MdvcObPE/g8bnN93vu//ux9ChDOQtS3FCyogok0AUhkUr8CJeBiMXmZ0RBHqE7ub3Xo0pGUYsOfafEw1IOSDIES6BBgyGA2C31eR/OqJZyY2TMC9YZsDBsNdADERiWcYmTFG4IO27uaGYFOIgDt7+0kwXQGRpmezCjIzE06kdDXfDGCnidSbC0LtnMkDjIoI5IKU7tZGNV/Kjxpzop5/DYueR0q4DD7fKiUnqoA7K+8hBDim3TKJENethveDC/6h4YiREdekgSckTL17H4qTUWdzNhWSUoo+MvQHV8viO5chpq2APClhqOiYrohlbz6sJbtV4q/nr2G8rTNYxG+QpKXkysqrJAE107cXo5HU3ggSflfnl9M1mOh0anoRf/0cTJkZqu1HUzu+Xb4VgiPmCnLbc9tAtCXYEnv8AGJ27YC3rx/DpVXgn+OaAqbs9YirLgNPTeFL+VlM9faF4pjbFQEPiJLCGsRsAk9MzlqZiDICMgN+vwaWPeSy50lEiJ6d6d8RDJb+WkCwWmBckgjIMnyeQcijY7MqqgJ6IQq8LWZmwFpcCDF95TQhyzKkNz0YvfdQ/dc/7NFMcuAFy74CWIodus2t9Oz3Ow8wVvdYR4PbyGXPPUVEF2cizJs3YlF1mfqYvV78fPoM0uu3gEAQ167Ggq05IKNRtX+uuoDJ5y/DRFjmSs1Gg0FAwqN7MMbHQR4fx+cTZ8JKMDp1OZQ+EMwm+AY/YbDgsJqfoOM3Mi/THBVGWzISam+r2JHb93VDsLBoJ2IPFam4QccR+DwDf/LEXJvS3bxHd9hZj+6HYInByI274ElJM8ZkNiO2tATy9zE1F4HDzKNRQLqy6f7LuAZzoc3ZUq8IhiyWj/bccgYuzXknKGVFXG7rarka8GbeVqYy7+D3H7K9aK0Ljuf8LH3iRqMsn0p0trrCZlqkflc/W0hwEJAT9NlCBFa2UA8xOgyQG7SIA7y/AFjgWozyRWqhAAAAAElFTkSuQmCC',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAvpJREFUSEu1lllIlFEUx3/na9CCNmiFwEppYVqghXroIeqhh6CHTC2lxSgMDKLNkrYHizYrIsrIirTQ3Ah6LDCoHipKCCKjqCSzhQrarBj1mxN3rpPOzOdMCZ23+53D/3/Ouf/vnCvEMc1JH01Sn2xgHjAJGNYZ/hF4DHKDto4qqbzyqicY8XLoiswUfHoQnKWAEy8JIAjBamjfLheuvo6OjSHQVUuycZxSkP4JgKPc2orLWrlYW93dEUGgqzK2IFKMiGdlCQlVFXSzlNUdD8f+AQplLk5Fr8HDiJYkR8rqqsynEIHteejS/rEtPdWk32lz/VJxpcUSrM6oBMeoxVpSMvgnQ0szfDKCiWMT/NAWgKYXUUHBy3KhLkd0Tfpogr6XEWrZuRfSxkMgALu39EyyaAkszrLAZ0/CndtdJKouLqmiuZmFiBzoyj4JSsrB6VRnyTF4cM+7hK27wD/F+m7VQ1lpZJzqNkNwDZEFEZ6lK2HBQnjVBMVF8OuXN8G0mZC3Adrb4fgBePk8muC6IWhBZFQMQnKybVEi8/nACMd1PSK1xRAEEElKhNMrv2pbzwQpY6BvP3j2JD72uInw8we8iZkSRp8B7xYNGgzFp8CUf2QfND7yJpk6DTYWQkcHFKyHr1+i4myLYi95wEA4etoSPG2Ew0W2z93NTJMdRVbOhmDTOvjRGp3ItViZhkPW5MOcufZ0/y5cOget3+3ZJLBiLcycbc8366E8SqLmezC43ftHM87+A2DPfhg63IIYKTY3gck8Zaytztj7t7Bvl72H7qa4uJraOSqyKoGuUREOHDIU8jfD2DTvOzDtO3MCvnyO9SsVUlazPPGwMxlPnwUzZsOIkfYu3r2BhnvwsMGbWPmGq1PkUm1zt3GdlY3o/xnX4TQ0N7MAONTrnWB2gWqBlNcdDWN6rMysbBzt3coMSp6U11yOULNXE+0CkoPA3y19pYY+HYVyPvZ1EXf3hnaF61sGOh/BjzI81D7VD6Fni2g9TrDaCzic+G9K1yF82+td1wAAAABJRU5ErkJggg==',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAoJJREFUSEu9lstLVHEUxz/nzjBCSZteiyDD6IGGUf9BLSKIINSZGofQCAJ3gZiKUUiBj4igRWRgadmoo7k3oghy0SZoUZtSVNz4aCOWjM3cE3dezp25zkzT42zP957v75zznfMdIUdoXXUZHvcF0JNAJbAzAV8CPoG8Zj0yLMHx2c3KiFNCL3r34tYuMM4DRq5HACaYI0SkVZ6NzmViswi0vsaPYTwCKc1TOCOtq5jmFRl4MZSesBFofW0TIncQcewsL6GqotosA2N3k9hUodjLxXhedPFkxRiJGUh2EiOIzzy2tN8cy2Y96SoRKq2dxAku1QbB8KfgnhKoOALzc7BsCSZHHKqAn+sw/TUDZA7Jk7E60cvVZZjuaZta2jrgwGEIh+FGMywtODOcOQc1iXc9fgjv3qTjTIxIuWiDrwWhK5VxuaB3EIyEOnvvw/tJZ4Kmdqisiucm30Lfg8wuWkQbvBOInLJlvAE4fRbmZqCnA9bWnAmqjkHjVYhG4V4nTH2x41RfWgTziOzJqlBSEh9RvnC7QTVOkhU6bxGEEfHkq1NcXsP/hcB5RMU9OeOr+Iiyl2zBjh6HXbsLo1lcgI8fnLATFkErIp227P6D0H6rsOJJ1O3rMJ2hItO0ZHpuH+KZsv3Qtu+Ajh7YsrUwkh/f4eY1+La8gVeiRLU8cSp8QWDjVFgwS6al2wojWF3JlrRqUPpHA//m2CkruCJV0jc+m3aufX5E/9K5loAMhGLGYzecBm8z0P1HnqC0Sn+oOzlbB8v0+TG0OMuMSqM8DQ2mLy6H6Yt1YQszfSUERpv0D89kqiKn98a8Iuqy1HUi9bfF8mvVReAzoq8wzBFrmZvJ7RcTD+xyChmePAAAAABJRU5ErkJggg==',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAudJREFUSEutlktIVGEUx3/nOmgLW0REWJBkD8l0EQQR0aIWLoqeNuODSiOqnRHmI7JNDzTMHiAuilCpqRwtgjYZuKigiKKdFVKK4qKsVQUy5twT31zHcWbuzJh0lvf87/mf75zznf8npDCt2J9LpqcMdDuwHlgShgvjKB8Q7WdKHsidntFkYcTNoYe8K/BoM1ilgJUqCcAGu5tJu078j8bisQkEWllSjmXdBMlOEzjOrb+AY9LR0z3bEUOglQdqEGlBxPVkaQlVFTglnT03ItiZQOHMxfLPO3gkoiFRu0y6HgacdgFOzRn497IkO5P+YjJUYHriEBw5cA+s8hl4ZhYUFMLYKPz4nroy+QUwGYThL3E4+7509FaIHt2fi+0ZipmWsxdg1VoIBuFcTXKSXSWwz+cEvtUGr19GSVRDyORK0SpfPUJzNPtMaO8Ca3o626/CuzfupzjdCAVFju9FP3TejMWp1olWefsQKY7xlB6G4h0wMgwt52Fiwp1gw0Y4Xg1//sD1Jhj6HE/wzBCMIbI8IUJWllOidObxgJnOUMgFqWOGIIhIZro48/NrcO4E2QthaQ6oDePf4Le5uOnMIXAvUeRf08S9XlidH41m2zD4CR4HYPBjChanRIlNjvyyuwT2eCHZ5jC17/HD0yfJSPoMQQMiTQmITVvgRLXzeWoKXj2Hj+ayC6wrhM1bwTTYWNsVeP82kcS2690vmrkDLW2waLEzoq0XE0cwbw3UNkLWAvgxDvXVzjRFTAkR0rzpVeG7B0RXRc4yuHTNgQbuJi/Bzn1QUubgzpyEb19nE/ilM3Aw+bLzHoTsbPB3OLvGzUz2FVXORJleRLP/SUiLjNLNWte+ckT/z7pGK6Sz94HhixWcKm8tcHnemhDWAq2Vrt7WyGFcJNNXjqXzkczf2HJcugL3Z1czheiL2bBzE30lQMZUg9x+NBLfqpTaG9aKUIaZrm0zzxaj16rjwED42WLZ3W6BI0R/AYmmHHkpyXZ6AAAAAElFTkSuQmCC',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAzRJREFUSEu1VmtsDFEU/s7cne12zCLeQhUR4hWJH5oIgoQfoiRIaCNCCIkfnilNNN0W8YgI8YdIiFe1RYLEIyUef/wTgihBiKoq9WxHZ/Yx98jMbpvt7nQbDffXZM53z3fPd8655xIyLHN3INeWagHAswAaD0Z/F05oAvg5mO4KEa3KLrHed+aGvAxmKDAspvj2EWgpiJRMhwCzZHC1EPZ2rcT8kIpNIzDKexQwxHEi6BkdpxiZYQByTTBkVCebOhAYu4JbwTgAkGdkXRMyA7RFL20+3IZtd+ScHKRUdN95m0t2RCsMlhlV8XQBiGvuf/63snQWEQMtQkbHaWVmvUtglOvnQUpB+wZVgxg+A/JLLfhXXUZllNxpQLQVsuFRRxzLSj1kFJJbirb6NrlaAqtuQwydAo62wjya1ymJOn0b/DN3uI6tK+tgP3NVSSy2hR0dScbOYDFAe9v/+7KhFTe081mXVsB+cdUzisDyqxAjZrq26OPTiFzbkBIFbyNjp14DKHOTLeqcPfDnrYfd+ATW2Xwg3OxJIEbPQ9aiE0AsDKtyCeTHhyk4ecuJoB6gIWke1B5A9HfXlSn8AEtAxtKxzPVklAfDIPJ37akbCOaIE0EYSCeggRNBWT0h6x5krqKcqWDrJ7ip1gPHYW+J9EHQNjwDCT/Mcwsh3933zsGouQgUXATbEbQemQAYn1OTXO+ZZGj9oG16CRIq7PcPYJ2dH9c5eZGCwMqaeDk7BIdGA+aP1F6oSS/TBMS/4BjUSfHei9VeQfjGZsD8Hrdq/ZA17xB8YxfES/TRKUSub/SSaHu80aT6Fki5lrW+yF59D0rvXHcjx8KQjU/db2XwJFc+Z8lvb2CenA1Yv1IIEo3m/E27KhJQ6pWDrMVnIIZM9syBI1/48mpwyyePEpUVeshY3vVlRwrEmHxXDuozEmCG/PrK7W779U1PYmY0+2RkYna5Vfd/rmtwYbA06bpuO4axK1gExv7uzwRmMIr0UMvBNp//dGQS7LV66Hdlh2r2EtEZQLbi2weipWnVlbbBaRC+IJRYsdfrIuPsTcyKZSCeDaZxIAxITMEv7rMFdEco0epMz5Y/ifdbhDKS7msAAAAASUVORK5CYII=',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAqFJREFUSEu9VktPE1EU/s7cmdLCNGqMKxWE6IrEpfEZYzXqwp0xiGw0MSTExA0hoJFHwcQS4w+QhS7UIrog0Y2oceNOXagRN8ZnIFGJEWGgU6Zzj7mjLe10KBXF2Z7vft95zTmHUOJLnQ/XuK5xFMQxgOrBWOPBCRMAj4LpkRDOzcg5++NCNBRkSHWHqzOaniBQA4i0Uk6AWTJ4SJeZjkjc/uTHFglY8apGhhggglmS2GdkhkVwm83umcF8U4GA1RdtBeMiQIGRLS7IDEKb2Tl9KYvNESnPQdqNpZNnKZnBsikbiSfwK+eh0T9Ny0IRqXTpcq5e1cQTsOJmEqQ15h4YlRC1uyG/jIJ/FNWtgFer2QlkbMjxZ4V6LAfNbusY/W7Fd/ndEjk+wtr6rcTOLFKXt4O/vw901tjRilCsy7Ol755C5vn1eRyzFMKpIysebQdRImfRdFSencjp2cMn4b66HShQ0TQMvS7m2ZwXSczdafH3VjtZveYIoO3Ptxh7exHadhru55ewrx0C0lOBAmLjAVQcvgpIB3byCOT4Ex9O3ierNzoG0NoiBqMKcGYW70wRAlgCMlOMZR5TKUqDKLQ401IQnFYRpIHlFQhO0VIc9r/xUhRQZIUTmw6CVtWWJaPa2H1zL6AGckSlqAOgC/lWbd0WRE48KIs8C0pd2Qc5/rS4TVM94Q2uZrwF5scyrahGpPkxKLyyLBG2J5Ea2OX769kVrlMXPCoUrVEFqlxdnsDst4CWlkmzy2parmE3pYu5zWrT/Z9xnc2D1RdtA6P/r3YCcYfZOd2f5fy3K5PcFrNrJm+kqvsg4FMLyNX0BIga8rsruOJqEPEtITNnIj32Bz+m5O71doU0GgHe450t8M4W9eYrmF+D6KHQnKFSZ8tPYKMajJiiiCQAAAAASUVORK5CYII=',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAydJREFUSEu1VktIFWEU/s7Mnene69yg1yYrtcBFUkRQLnpoRS6CIELwUQQtKmohhPjo5VV7GdGiKIKgRZT5oEWCGysXtahFUUgvWiQpJuFCSkfvY+78J2buo+t1Rk1qlnO+//v+8/3nP+cnzPCFLnhzTFMpB/FOgArAWJaAj4D4E0C9smm0+5rCg2405BQIBb2rYpKnhUBlIJJm2gSYBYM7ZDZr/Y2hoUzsNAG9KauCId8hgjYjcUaQgXGwOBII6h3poSkC+vlANRhXAXLMbHZBZjCd1IJj15PYFJG1c5DUOn/yJCVbppUHGvVO648tEPdc/fi3trhlZNklC2OtdSa2gN6kPQRJFakFih9y7naIkU/gX64FYsOlnK2AMQkx/HaqHos2LahXUqIU+9OrxXv4KeQVm8HGJEK3C11FlG21UIvP2MThx8dgvm9PE2FTkmJ5pDcF6kDUkop4fPDXD6f0wo8Owfzc5eiG92AX5LxiO2a8u4dod1VGFlxLerPWA0gl6RFl9yWohSdg/uhD+P5eIDLmKCDn78GC/XeBWAThtlKI728ycOIJ6c2BIYCypzEoWYAxMXtlyirAAhCx6VjmIcuiCIjU2Znmg+CIlUEEmIOAfwmkxWvs3YrRfiA0OgfFuICzRYnlUl4x1KLTkFcWpgjZEhl4iejzixCDL92FbIscDjm5QtlWB7XolPvlZkaktwGxVzecRVj0WBnUA3Q5EyEXlMJrVQgANqOI9bXB/PbCLl8pdzs868tBshK/A50HYH7pdhDhuvhFE0o/kNaWSYav6gOkhcvBkXGEH+yDGJ5aglL2Jlj3gNQsiJ8DCN3cEK+mP0aasmmsdmwVtDQf/uOvbWjk2TlXC5Qt1VB3Nti4yVsbwaNf0/hFqxbUD7o2O2XXeZBvEaI9tXavcfxUDWpJCzg0CqM3LmRbyhjziOg6a9L9n3YNrgw06HZjyhw4NWBcmf9MsAYOarTg+LVkNv9uZDJ0gnlUC060pdvpOvRNydMCorIp1eV4EFbpcKcsxep9Z8MDmZAZZ2+ihCsA3mE/W2A/W6w1IwB/tJ8tktHhRJwU+g36klWIsBLhgwAAAABJRU5ErkJggg==',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAA0xJREFUSEu9VltIVFEUXfs6oyaaGNkDw8gIdXLsnWP1URYRoX70UWl+RJgEBkFpCj0gI9Iy6qeCgqDAyqCgtIdKUR/WSA9KnaaHaQ/rYyoxHTVn7r077pFb48x1JInOx4Vz9z5r7bPOPnsfQpCR3Lh2uilEygFoBTFmM1Gs5k7MX5ngAOOerCqXnUtvfRgJhowMlieZ8SEylQO0gQApWBAMqABXsySVtC6+/snfN4BgdlNWjqTSGSJEBgP2tzHDzVDzW9Nrq31twwhS7Nm7CHyUQIY7G42QwUzAzmZbzQnd9zeQiJxRNVZwHVAjUaHmOmw3L4vz0j5Cc6/k+FtZRtoRM3qJvJZm2+1OQWC1Z10kUI6+IFwKQ9r4VLzt/4AvHldQZRZGpWBA/QlHX9swPwZfarHV5NJQKprafbPlguUI5kQlYkAZxLrm7SOSFMStR+G0TQJ4z7vjqP12/w8JQ5HNagJZ7ZmlBOmwbgmjUNgXVUOioewseluBhq6Hhrs4k1SGtOg5wnbNVY8DHSeH+5G6m6z2rDoCrfa1FMVvQd6UbDj727HVuRdupd+QYHnMYpTPLIKHPSh8XYYW9xt/meop9VFWJ4ji/BHGSeFC29GGmUxgMGRWAlwZ6CSrPXuQgNDRgMZiZ8AzIkFixAxEhkTgaa8jKPb8KAt6ZDfaBj4G7oB50FCiieYY3Jl7FmbJjALnfjT1vDAkWRa9ACeT9sOrerHmeT6+ebv9zkBIFHjIMaZoNMw7B7NkwtOeVuQ790HVaprPkCDhvKUcqVGJgmDls834obj9A6kLSFPd42DCDmTHZohp/fdGHHp/Gt1yr5hrAeydsQ2rJiwR86uuOpR1nDKQCCWGF20IZDyqUioRFzZZLPSoXrzqaxfFJTkiQcinjfcDn5HnKEav0jecQL9o2l//UqF7Tg2NReWsEqREzjI8A02+0rZjcHm7jKKvakm/kTdqsdO0zphgw6qYdMSHT4UKFlE3dDXiQfdjQ2IG9ygmtr5cWPvx/5RrPYzUpsxiZqoYa0/QegGDilttN47pmP+0ZaoSFzjSai756vZPmj6zekUJU0ud8wNfF0F7r+gVUshGEDIAsgCYRFqiMrvEs4XorizL1cGeLb8ALw9nEMMJOoAAAAAASUVORK5CYII=',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAArRJREFUSEu9Vk1IVGEUPffNjBmo2aLIwgoJUseZJJRmiIJatAhHCvozETcSBBVImApF1CY1JKJNtItKU1qEE5IhLoRwJDc1jn+kaEqJFoVM6uj33o335Mlz5s04mfZt73nn3Hu/8937CDFO1vuTe6yS5QIIx4lhZ6JtKpyYp5kQAKNDKPLL/sOtY9FoyCyQ3VOw2yKoBqDzBEixkmBAAbhJtnJVX96bL+HYCAF7t6dIUugJEZJiEYfHmBFUJL4UOORtNMZWCOT4Cq8T+D6BTCtbTZDBzKCKXldLvY5dJtIyZ7xYK7lOqIoohGK9Ek1A6/miFPjbtkSrSG2XbFPs6p1oAg6fp4FARfoHidImuFIOYGh2FF8XpmJ2Ji85ByFlAf7fQytwDG70u7wXSbOixTpidMvT7BrOTc6iOTmEs/5rGA9NmoqU7TyDq+klWuz28CO8/t6+jFPdJWSRQY4uTyWRasmlYyELevJfQaIld1Z/rkfrj05TgceZd+DekqvFWqY7cGvk4coqCJXk8HnaCHTCGClPL0Vp2mkMzI6grP8mgvKsqcCR1DzU7auAYIErg3fxMTgY3qZ35OzyTIBoVzjDZikRc8r8as6EjaxgMATLEVgGJsjhKwwRkLAq0xoAzBzaeIFoLVpDwtFaFHnJKvJoaj7SE3fEpTM+P4nOXx/MsG3k8BVUEaR7xqgzaT+e2eviItdBJYEb+BTuIkYlZXad2msjZdj40NIStqPZ8QAp1vgG6owI4py/HN+Mr54hC5uSYToq1KxUm261pcRVxc/FmQhLM3OD3+0t3phhB54RCbKz/2Dr2P8Z13ofnN0FFcxU+y87gZmr/G5vrc65riuTCZd7XS3PjRe3LkufWWkWENUD7rej4a6IuXuXdoVUBNAx/beFAALzFBP6QNQuhGiK9dvyBwaXLgYUAoo1AAAAAElFTkSuQmCC',
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAA0ZJREFUSEu1VllsTGEU/s6daTukHQSxtDSWBGWmCO00IsGDiMz0QYROkYjYl4dSJSESXrQE8cADHpBY6gFpS4NYXjAEiU6nE3taQ6NpbNNlpr33P3Jv3baz3CkN5/Ge83/ff75z/nMuIYFNebg40yyZCkBYQIypTDRcC2c0gbgOTHeVJHG5blZVgxEMxXNkPXOONclUCtByAqREl2BAAFzeDrnkraM6EB0bQzD1icstCTpFhNREwNE+ZgQZYl1tXlV5b18EwTRP/g4CHyZQ3Mz6ImQwE6ioxlFxXI/tBtJuzrjQX3AdUCVhRkFtXuUV9ZtGoGneKfn+VhajjFS5iDqzahzVAY3A5nFdJJBbP2CRUpBrteNNWz0+dzQlVGZW2jS0ixB8rW8j4hh8yeuoLCStFU3m97275XzWIWSnTUK7EsaSmq2GJOvTl2FLxgoNeM+7Y6hqftBDwlCEKTSObI9du4jUluyyFEqGZ3Y5JOrqzuI3Zbjz9VHcLE5NPoDcQdma72rTbez/cCIyjkQJ2TyuWwRa2NtTPHYNVo7Mh7/tPdb596JFaYtLMG9IDkonFKODO7Dl1QF4W15Hy3Sb7I9dARClRyMMkCyatn1ZEpnBYMisxIQyECCbJz9MQHJfQP3xM3P4jwkGm63ItIyCYMbHcCO+y8E+OTUCI4n007nWbGzOcGN62pRuQMECL4J1OBm4iOdBnyHRb4lii6yf2JC+HJvS3SCDyaE+2WMfz+Fc4zUjkltk8zh3E6SD0RGLhs5F2cRi7XOn6ERl8308/ekFgZBjtcM5bB6SJLPmL3p9EPe+eWKLzNgV96GZIKF6xhmMSB6KVqUdG/z74G2NbEF76iSo72CAyYJPoS9wvtwIoU5u3RiKnCTGxx0V4ywZuJ7d9WiONpw1lGDt6KXYNmaVFud6uRENocYefMYFb17FSsNht33MaljNqSitP42QCMfVeKBkQUnmWvyQg1otei7PPxUz29RN91/GtYAo9DluXFYJIxaL/YlzJzOV9XcnaLsAtLPWUXFEz+ZfrswWIfF6X27lpd56/pOlzyyuKClit3/mzfroYiXcvV27QnIDNF//bSFVVuYmJvhAdFeW5XL/nFhgnegXYG9lBid01KQAAAAASUVORK5CYII=',
];
// 只针对自定义看板做的需求
const changeLineStyleByTheme = (type) => {
  const bodyClass = document.getElementsByTagName('body')[0].classList;
  const colorResult = (colorArr) => (bodyClass.contains('customBoard-blue') ? colorArr[2] : bodyClass.contains('customBoard-dark') ? colorArr[1] : colorArr[0]);
  let color;
  if (type === 'splitLine') {
    color = ['#f3f3f4', '#262626', '#2f364a'];
  } else if (type === 'label') {
    color = ['#7a8493', '#d6d6d6', '#d3d6e0'];
  } else if (type === 'barLabel') {
    color = ['#ffffff', '#d6d6d6', '#d3d6e0'];
  } else if (type === 'tooltipBgColor') {
    color = ['rgba(5,10,19,1)', '#2E2E2E', '#374057'];
  } else if (type === 'axisLine' || type === 'disabled') {
    color = ['#D9D9DE', '#3D3D3D', '#4e5466'];
  } else if (type === 'mainColor') {
    color = ['#14c4a2', '#14c4a2', '#3d63cc'];
  } else if (type === 'legend') {
    color = ['#050a13', '#d6d6d6', '#d3d6e0'];
  } else if (type === 'diabled') {
    color = ['#B2B3B9', '#3D3D3D', '#4E5466'];
  } else {
    color = ['#7A8493', '#7A8493', '#7A8493'];
  }
  return colorResult(color);
};
const changeLineStyleById = (type) => {
  const bodyId = document.getElementsByTagName('body')[0].id;
  const IDGroup = ['default', 'ipsos', 'sams'];
  const colorResult = (colorArr) => (IDGroup.indexOf(bodyId) >= 0 ? colorArr[IDGroup.indexOf(bodyId)] : colorArr[0]);
  let color;
  if (type === 'range') {
    color = [
      ['#8bbafc', '#26e0ae'],
      ['#1f80dE', '#6FBE4E'],
      ['#1f80dE', '#6FBE4E'],
    ];
  } else if (type === 'heatMapRange') {
    color = [
      ['#8bbafc', '#26e0ae'],
      ['#1f80dE', '#90E4F2'],
      ['#1f80dE', '#90E4F2'],
    ];
  } else {
    color = ['#7A8493', '#7A8493', '#7A8493'];
  }
  return colorResult(color);
};
const mediaConfig = {
  otherline: [
    // 这里定义了 medimina query 的逐条规则。
    {
      query: { minWidth: 768 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        grid: {
          left: 20,
          right: 34,
          top: 48,
          bottom: 34,
          z: 100,
          containLabel: true,
        },
        legend: { Height: 280 },
        series: [
          {
            type: 'line',
            markPoint: {
              symbolSize: 40,
            },
          },
        ],
      },
    },
    {
      query: { maxWidth: 767 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        grid: {
          left: 20,
          right: 40,
          top: 30,
          bottom: 24,
          z: 100,
          containLabel: true,
        },
        legend: { Height: 280 },
        series: [
          {
            type: 'line',
            markPoint: {
              symbolSize: 28,
            },
          },
        ],
      },
    },
  ],
  line: [
    // 这里定义了 media query 的逐条规则。
    {
      query: { minWidth: 768 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        grid: {
          left: 20,
          right: 40,
          top: 48,
          bottom: 34,
          z: 100,
          containLabel: true,
        },
      },
    },
  ],
  pie: [
    // 这里定义了 media query 的逐条规则。
    {
      query: { maxWidth: 1920 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        legend: { width: 250, height: 'auto', padding: 0 },
        series: {
          radius: ['50%', '78%'],
          center: ['50%', '44%'],
        },
        grid: {
          height: 250,
        },
      },
    },
    {
      query: { maxWidth: 400 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        legend: { width: 250, height: 'auto', padding: 0 },
        series: {
          radius: ['20%', '38%'],
          center: ['50%', '44%'],
          label: {
            overflow: 'break',
          },
        },
        grid: {
          height: 250,
        },
      },
    },
    {
      query: { maxWidth: 285 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        legend: { width: 250, height: 'auto', padding: 0 },
        series: {
          radius: ['53%', '81%'],
        },
        grid: {
          height: 250,
        },
      },
    },
    {
      query: { maxWidth: 265 }, // 这条里没有写规则，表示『默认』，
      option: {
        // 即所有规则都不满足时，采纳这个option。
        legend: { width: 250, height: 'auto', padding: 0 },
        series: {
          radius: ['59%', '87%'],
        },
        grid: {
          height: 250,
        },
      },
    },
  ],
  scatter: [
    {
      query: { maxWidth: 768 }, // 这条里没有写规则，表示『默认』，
      option: {
        xAxis: {
          nameRotate: -90,
        },
      },
    },
  ],
};

class Store {
  constructor() {
    autorun(() => {
      this.optionData &&
        this.optionData.legend &&
        (this.isSelect = this.optionData.legend.map((item, index) => {
          if (this.showAll) {
            return true;
          } else {
            if (this.optionData.legend.length > 1) {
              return index === 0;
            } else {
              return !index;
            }
          }
          //return this.showAll ? true : (index === 1)
        }));
    });
  }

  @observable optionData;
  @observable chartType = 'line';
  @observable tableData;
  @observable
  xAxisConfig = {
    type: 'category',
    axisTick: false,
    boundaryGap: true,
    axisLabel: {
      color: changeLineStyleByTheme('label'),
      formatter: (value, index) => {
        return this.stringLeaveOut(value, index, util.isBeyondYear(this.optionData.axis));
      },
      fontSize: 12,
      // rotate: 20
    },
    axisLine: {
      lineStyle: {
        color: changeLineStyleByTheme('axisLine'),
      },
    },
  };

  @observable
  yAxisList = [
    {
      inverse: false,
      dataType: 'number',
      type: 'value',
      splitNumber: 3,
      splitLine: {
        lineStyle: {
          color: changeLineStyleByTheme('splitLine'),
        },
      },
      axisLabel: {
        color: changeLineStyleByTheme('label'),
        fontSize: 12,
        formatter: (params) => {
          let value = String(parseInt(params));
          if (value.length > 7) {
            return Math.round((params / 10000000) * 10) / 10 + '千万';
          } else if (value.length <= 7 && value.length > 4) {
            return Math.round((params / 10000) * 10) / 10 + '万';
          } else {
            return params;
          }
        },
        fontWeight: 400,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    {
      inverse: false,
      dataType: 'rate',
      type: 'value',
      splitNumber: 5,
      splitLine: {
        lineStyle: {
          color: changeLineStyleByTheme('splitLine'),
          fontWeight: 400,
        },
      },
      // max: 1,
      axisLabel: {
        color: changeLineStyleByTheme('label'),
        formatter: (params) => {
          return `${(params * 100).toFixed(0)}%`;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    {
      inverse: false,
      dataType: 'time',
      type: 'value',
      splitNumber: 5,
      splitLine: {
        lineStyle: {
          color: changeLineStyleByTheme('splitLine'),
        },
      },
      min: 0,
      axisLabel: {
        color: changeLineStyleByTheme('label'),
        fontWeight: 400,
        formatter: (value) => {
          return `${value}秒`;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    {
      inverse: false,
      dataType: 'NPS',
      type: 'value',
      splitNumber: 5,
      splitLine: {
        lineStyle: {
          color: changeLineStyleByTheme('splitLine'),
          fontWeight: 400,
        },
      },
      axisLabel: {
        color: changeLineStyleByTheme('label'),
        formatter: (params) => {
          return `${(params * 100).toFixed(1)}`;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
  ];
  @observable isCompare = false;
  @observable isSelect = [];
  @observable showAll;
  @observable mapType;
  @observable props;
  @observable wordCloudTheme; //设置文字云主题

  @action.bound
  setWordCloudTheme = (theme) => {
    this.wordCloudTheme = theme;
  };

  checkChineseAndEnglish(str) {
    let valueLength = 0;
    let reg = /[\u4e00-\u9fa5]/g;
    str.split('').forEach((item) => {
      if (reg.test(item)) {
        valueLength += 3;
      } else {
        valueLength += 1;
      }
    });
    return valueLength;
  }
  @action
  stringLeaveOut(value, index, isBeyondYear) {
    value = value ? (isBeyondYear ? value.toString().replace(/\(\d{1,}\/\d{1,}~\d{1,}\/\d{1,}\)/, '') : value.toString().replace(/^\d{1,}年/, '')) : '';
    if (window.innerWidth <= 768) {
      let newValue = this.checkIsContainDate(value);

      if (this.checkChineseAndEnglish(newValue) > 7) {
        if (value.substr(0, 7) === value) {
          return value.substr(0, 7);
        } else {
          return value.substr(0, 7) + '...';
        }
      } else {
        if (this.checkChineseAndEnglish(value) > 10) {
          value = isBeyondYear ? value.replace(/\(\d{1,}\/\d{1,}~\d{1,}\/\d{1,}\)/, '') : value.replace(/^\d{1,}年/, '');
          if (value.substr(0, 10) === value) {
            return value.substr(0, 10);
          } else {
            return value.substr(0, 10) + '...';
          }
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  }
  @action
  getOptionData(data) {
    this.optionData = data;
  }
  @action.bound
  changeMapType(type) {
    this.mapType = type;
  }

  @action.bound
  getProps(props) {
    this.props = props;
  }
  @action
  changeDataIndex(index) {
    if (this.chartType === 'pie' || this.chartType === 'map' || this.chartType === 'bar') {
      this.isSelect = this.optionData.legend.map(() => false);
      this.isSelect[index] = true;
    } else {
      this.isSelect[index] = !this.isSelect[index];
    }
  }

  @action.bound
  changeIsCompare() {
    this.isCompare = !this.isCompare;
  }

  @action.bound
  changeChartType(type) {
    this.chartType = type;
    this.isCompare = false;
    if (type === 'pie') {
      let i = this.isSelect.indexOf(true);
      this.isSelect = this.isSelect.map((item, index) => {
        return index === i;
      });
    }
  }
  @action.bound
  changeChartType(type) {
    this.chartType = type;
  }

  @action.bound
  autoSetLineYAxis = (optionData) => {
    let { field, axisField, data } = optionData;
    this.axisNum = [];
    this.lineYAxis = [];
    if (axisField && axisField.length === 1) {
      this.axisNum[0] = legendUtil.getType(axisField[0], null, true);

      this.lineYAxis.push(toJS(this.yAxisList[this.axisNum[0]]));
      this.lineYAxis[0]['offset'] = 0;
      this.lineYAxis[0]['position'] = 'left';

      return this.lineYAxis;
    } else if (field && field.length > 0) {
      this.props.stackFeildArr
        ? field.forEach((item) => {
            this.axisNum.push(legendUtil.getType(this.getRealField(item), 'type', true));
          })
        : field.forEach((item) => {
            this.axisNum.push(legendUtil.getType(item, 'type', true));
          });
      this.axisNum = Array.from(new Set(this.axisNum));
      this.axisNum.forEach((item, index) => {
        this.lineYAxis.push(toJS(this.yAxisList[item]));
        if (toJS(this.yAxisList[item]).dataType === 'time' && this.axisNum.length === 3) {
          this.lineYAxis[index]['offset'] = 32;
        }

        if (index === 0) {
          this.lineYAxis[index]['position'] = 'left';
        } else {
          this.lineYAxis[index]['position'] = 'right';
        }
      });
      return toJS(this.lineYAxis);
    } else {
      return toJS(this.yAxisList)[0];
    }
  };
  //调研分析多维度时把组装后的feild还原，用于选择纵坐标、堆叠图分堆
  getRealField(field) {
    if (field.includes('NPS影响值_value')) return 'npsDValue';
    if (field.includes('NPS')) return 'NPS';
    if (field.includes('满意度影响值_value')) return 'markDValue';
    if (field.includes('downEValue') || field.includes('upEValue')) return 'upEValue';
    const stackFeildArr = this.props.stackFeildArr;
    let realFeild = '';
    stackFeildArr.forEach((item) => {
      field.split('_').indexOf(item) > -1 && (realFeild = item);
    });
    return realFeild ? realFeild : field;
  }
  getRealCnField(field, index) {
    let realFeild = '';
    if (index !== undefined) {
      realFeild = chartUtil.getTargetTitle(this.getRealField(this.optionData.field[index]), { answer_topic_option_key: field }, true);
    } else {
      const cnStackFeildArr = this.props.cnStackFeildArr;
      cnStackFeildArr.forEach((item) => {
        field.split('，').indexOf(item) > -1 && (realFeild = item);
      });
    }
    return realFeild ? realFeild : field;
  }
  checkOneDate(axis) {
    if (axis.includes('-') && axis.split('-').length === 3) {
      let axisArr = axis.split('-');
      if (!isNaN(Number(axisArr[0])) && !isNaN(Number(axisArr[1])) && !isNaN(Number(axisArr[2]))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } // 生成seriesName
  getSeriesName(params) {
    let seriesName;
    if (params.seriesName.includes('，')) {
      const field = this.optionData.field[params.seriesIndex];
      seriesName = field.split('_')[field.split('_').length - 1];
      seriesName = seriesName === 'value' && field.includes('NPS') ? 'NPS' : legendUtil.getTitle(seriesName);
    } else {
      seriesName = this.props.stackFeildArr ? this.getRealCnField(params.seriesName, params.seriesIndex) : params.seriesName;
    }
    return seriesName;
  }
  @action
  checkIsContainDate(axis) {
    if (window.innerWidth <= 768) {
      if (axis.includes(',') && axis.split(',').length === 2) {
        let axisArrA = axis.split(',')[0];
        let axisArrB = axis.split(',')[1];
        if (this.checkOneDate(axisArrA) && this.checkOneDate(axisArrB)) {
          return `${axisArrA.split('-')[1]}-${axisArrA.split('-')[2]},${axisArrB.split('-')[1]}-${axisArrB.split('-')[2]}`;
        } else {
          return axis;
        }
      } else {
        if (this.checkOneDate(axis)) {
          return `${axis.split('-')[1]}-${axis.split('-')[2]}`;
        } else {
          return axis;
        }
      }
    } else {
      return axis;
    }
  }

  @action.bound
  autoSetLineXAxis(data, config) {
    config = config || {};
    return {
      ...this.xAxisConfig,
      ...config,
      data: data.axis.slice().map((item) => {
        return this.checkIsContainDate(item);
      }),
    };
  }
  @action.bound
  changeYaxisIndex(legend) {
    let newLegend = legend.includes(',') ? legend.split(',')[legend.split(',').length - 1] : legend;
    let yIndex = 0;
    this.lineYAxis &&
      this.lineYAxis.length > 0 &&
      this.lineYAxis.forEach((item, index) => {
        if (
          item &&
          item.hasOwnProperty('dataType') &&
          item['dataType'] ===
            (legendUtil.getCnName(newLegend, 'type') === 'minisecond'
              ? 'time'
              : legendUtil.getCnName(this.props.cnStackFeildArr ? this.getRealCnField(newLegend) : newLegend, 'type'))
        ) {
          yIndex = index;
        }
      });

    return yIndex;
  }
  @action.bound
  changeIndex(field) {
    let yIndex = 0;
    this.lineYAxis.length > 0
      ? this.lineYAxis.forEach((item, index) => {
          if (
            item &&
            item.hasOwnProperty('dataType') &&
            item['dataType'] === (legendUtil.getType(field, 'type') === 'minisecond' ? 'time' : legendUtil.getType(field, 'type'))
          ) {
            yIndex = index;
          }
        })
      : null;
    return yIndex;
  }

  @action.bound
  tooltipConfig(type, triggerType) {
    const trigger = triggerType;
    return {
      trigger: trigger,
      confine: true,
      backgroundColor: changeLineStyleByTheme('tooltipBgColor'),
      padding: [12, 16, 12, 16],
      textStyle: {
        fontWeight: 400,
      },
      className: 'cemyun-chart-tooltip',
      extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
      // 自定义tooltips 若需要统一需要加类样式
      formatter: (params) => {
        if (type === 'map' || type === 'pie') {
          return this.mapTooltip(params);
        } else if (type === 'radar') {
          return this.radarTooltip(params);
        } else if (type === 'gauge') {
          return this.gaugeTooltip(params);
        } else if (type === 'scatter') {
          return this.scatterTooltip(params);
        } else if (type === 'heatmap') {
          return this.heatmapTooltip(params);
        } else if (type === 'treemap') {
          return this.treemapTooltip(params);
        } else if (type === 'wordCloud') {
          return this.wordCloudTooltip(params);
        }
        return this.currencyTooltip(params);
      },
    };
  }
  @action.bound
  scatterTooltip = (params) => {
    let result = '';
    result += `<div style="line-height:20px;font-size:12px;color:#fff;">${params.seriesName}</div>`;
    this.props.scatterType !== 'quadrant' &&
      toJS(this.optionData.field).forEach((item, index) => {
        let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${params.color}"></span>`;
        //最右边对齐的文字
        let rightText = this.optionData.customTooltip
          ? `<span style="display:inline-block;margin-left:5px;">${legendUtil.formateTitle(item, toJS(this.optionData.data)[params.seriesIndex][index])}${
              this.optionData.customTooltip[params.seriesIndex][index] ? this.optionData.customTooltip[params.seriesIndex][index] : ''
            }</span>`
          : `<span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(
              item,
              toJS(this.optionData.data)[params.seriesIndex][index]
            )}</span>`;

        result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${legendUtil.getTitle(
          item
        )}${rightText}</div>`;
      });
    // : this.optionData.customTooltip &&
    // toJS(this.optionData.customTooltip[params.seriesIndex]).forEach((item, index) => {
    //   let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${params.color}"></span>`;
    //   //最右边对齐的文字
    //   let rightText = `<span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(item.key, item.value)}</span>`;

    //   result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${legendUtil.getTitle(
    //     item.key
    //   )}${rightText}</div>`;
    // });
    return result;
  };
  @action.bound
  treemapTooltip = (params) => {
    let result = '';
    result += `<div style="line-height:20px;font-size:12px;color:#fff;">${params.name}</div>`;
    toJS(this.optionData.field).forEach((item, index) => {
      let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${params.color}"></span>`;
      //最右边对齐的文字
      let rightText = this.optionData.customTooltip
        ? `<span style="display:inline-block;margin-left:5px;">${legendUtil.formateTitle(item, toJS(this.optionData.data)[params.dataIndex - 1][index])}${
            this.optionData.customTooltip[params.dataIndex - 1][index] ? this.optionData.customTooltip[params.dataIndex - 1][index] : ''
          }</span>`
        : `<span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(
            item,
            toJS(this.optionData.data)[params.dataIndex - 1][index]
          )}</span>`;

      result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${legendUtil.getTitle(
        item
      )}${rightText}</div>`;
    });
    return result;
  };
  @action.bound
  gaugeTooltip = (params) => {
    let result = '';
    toJS(this.optionData.field).forEach((item, index) => {
      //最右边对齐的文字
      let rightText = this.optionData.customTooltip
        ? `<span style="display:inline-block;">：${legendUtil.formateTitle(
            params.name.includes('NPS') ? 'NPS' : item,
            toJS(this.optionData.data)[index][index]
          )}${this.optionData.customTooltip[index] ? this.optionData.customTooltip[index] : ''}</span>`
        : `<span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(
            params.name.includes('NPS') ? 'NPS' : item,
            toJS(this.optionData.data)[index][index]
          )}</span>`;
      result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${
        params.name.includes('NPS') ? 'NPS' : params.seriesName
      }${rightText}</div>`;
    });
    return result;
  };
  @action.bound
  radarTooltip = (params) => {
    let result = '';

    result += `<div style="line-height:20px;font-size:12px;color:#fff;">${params.name}</div>`;

    params.value.forEach((item, index) => {
      //自定义圆点
      let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${params.color}"></span>`;
      //最右边对齐的文字
      let rightText = `<span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(this.optionData.field[index], item)}</span>`;
      result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${legendUtil.getTitle(
        this.optionData.field[index]
      )}${rightText}</div>`;
    });
    return result;
  };
  @action.bound
  heatmapTooltip = (params) => {
    let result = '';
    result += `<div style="line-height:20px;font-size:12px;color:#fff;">${params.name}</div>`;
    //自定义圆点
    let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${params.color}"></span>`;
    //最右边对齐的文字
    let rightText = `<span style="display:inline-block;margin-left:5px;float:right">${legendUtil.formateTitle(
      this.optionData.field[0],
      params.value[params.value.length - 1]
    )}</span>`;
    result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${
      this.optionData.legend[params.value[1]]
    }${rightText}</div>`;
    return result;
  };
  @action.bound
  wordCloudTooltip = (params) => {
    let result = '';
    //自定义圆点
    let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${params.color}"></span>`;
    //最右边对齐的文字
    let rightText = `<span style="display:inline-block;">：${params.value} </span>`;
    result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${params.name}${rightText}</div>`;
    return result;
  };
  @action.bound
  currencyTooltip = (params) => {
    let result = '';
    params.forEach((item, index) => {
      if (typeof item.value === 'number' && !isNaN(item.value)) {
        let seriesName;
        if (item.seriesName.includes('，')) {
          const field = this.optionData.field[item.seriesIndex];
          seriesName = field.split('_')[field.split('_').length - 1];
          seriesName = seriesName === 'value' && field.includes('NPS') ? 'NPS' : legendUtil.getTitle(seriesName);
        } else {
          seriesName = this.props.stackFeildArr ? this.getRealCnField(item.seriesName, item.seriesIndex) : item.seriesName;
        }
        if (index === 0) {
          result += `<div style="line-height:20px;font-size:12px;color:#fff;">${item.axisValue}</div>`;
        }
        //自定义圆点
        let dotHtml = `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:4px;width:4px;height:4px;line-height:4px;background-color:${item.color}"></span>`;
        //最右边对齐的文字
        let rightText = this.optionData.customTooltip
          ? `<span style="display:inline-block;">：${
              this.optionData.axisField
                ? `${legendUtil.formateTitle(toJS(this.optionData.axisField[index]), item.value)}`
                : `${legendUtil.formateTitle(legendUtil.getCnName(seriesName, 'field'), item.value)}`
            }${this.optionData.customTooltip[item.dataIndex][item.seriesIndex] ? this.optionData.customTooltip[item.dataIndex][item.seriesIndex] : ''}
        </span>`
          : `<span style="display:inline-block;margin-left:5px;float:right">${
              this.optionData.axisField
                ? `${legendUtil.formateTitle(toJS(this.optionData.axisField[index]), item.value)}`
                : `${legendUtil.formateTitle(legendUtil.getCnName(seriesName, 'field'), item.value)}`
            }
        </span>`;
        result += `<div style="min-width:160px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;color:#fff;">${dotHtml}${item.seriesName}${rightText}</div>`;
      }
    });
    return result;
  };

  @action.bound
  mapTooltip = (params) => {
    let result = '';
    let seriesName;
    if (params.seriesName.includes('，')) {
      seriesName = params.seriesName.split('，')[params.seriesName.split('，').length - 1];
    } else {
      seriesName = this.props.stackFeildArr ? this.getRealCnField(params.seriesName, params.seriesIndex) : params.seriesName;
    }

    result += `<div style="line-height:20px;font-size:12px;color:#fff;">${params.name}</div>`;
    //自定义圆点
    let rightText = this.optionData.customTooltip
      ? `<span style="display:inline-block;">：${
          params.value instanceof Array
            ? legendUtil.formateTitle(legendUtil.getCnName(seriesName, 'field'), params.value[2])
            : legendUtil.formateTitle(legendUtil.getCnName(seriesName, 'field'), params.value)
        }${
          this.optionData.customTooltip[params.dataIndex] && this.optionData.customTooltip[params.dataIndex][params.seriesIndex]
            ? this.optionData.customTooltip[params.dataIndex][params.seriesIndex]
            : ''
        }
  </span>`
      : `<span style="display:inline-block;margin-left:5px;float:right">${
          params.value instanceof Array
            ? legendUtil.formateTitle(legendUtil.getCnName(seriesName, 'field'), params.value[2])
            : legendUtil.formateTitle(legendUtil.getCnName(seriesName, 'field'), params.value)
        }${params.percent ? `(${params.percent}%)` : ''}</span>`;
    result += `<div style="min-width:150px;margin-bottom:2px;font-size:12px;font-weight:400;line-height:20px;z-index:999;color:#fff;">${params.seriesName}${rightText}</div>`;
    return result;
  };
  @action.bound
  legendConfig(legendType) {
    return {
      type: 'scroll',
      animation: true,
      // formatter: function(name) {
      //   return echarts.format.truncateText(name.substring(name.indexOf('/')), 120, '…');
      // },
      data: this.optionData[legendType].slice().map((item) => {
        return {
          name: item,
          icon:
            'path://M896.969659 16.576036 119.702341 16.576036c-61.317329 0-111.038333 49.665189-111.038333 111.038333l0 777.265288c0 61.265573 49.721004 111.039348 111.038333 111.039348l777.266302 0c61.314284 0 111.036304-49.773775 111.036304-111.039348L1008.004947 127.614369C1008.005962 66.241225 958.283943 16.576036 896.969659 16.576036zM877.124894 340.796156 443.975232 773.888988c-11.680554 11.736369-26.883542 17.734958-42.168729 18.026211-15.069031 1.231989-30.562257-3.90096-42.085514-15.423203L133.583021 550.406799c-20.767234-20.820005-20.767234-54.548493 0-75.368498 20.820005-20.81899 54.59416-20.81899 75.414165 0L389.301916 655.385655l401.179853-401.337149c23.964925-23.853295 62.727926-23.853295 86.643125 0C901.092864 278.126075 901.092864 316.834275 877.124894 340.796156z',
          textStyle: {
            width: '10px',
            overflow: 'hidden',
            color: changeLineStyleByTheme('legend'),
          },
        };
      }),
      itemGap: 16,
      itemWidth: 14,
      bottom: 0,
      inativeColor: changeLineStyleByTheme('disabled'),
    };
  }
  @action.bound
  gridConfig(left, right, top, bottom, containLabel = true) {
    return {
      left,
      right,
      top,
      bottom,
      containLabel,
    };
  }
  @computed
  get createChartOption() {
    // 如果图表类型是折线图则发生判断
    const tooltipBgColor = changeLineStyleByTheme('tooltipBgColor');
    if (this.chartType === 'line') {
      let selected = {};
      this.optionData.legend.forEach((item, index) => {
        selected[item] = index < 5;
      });
      return {
        //竖横坐标颜色 #7a8493 legend颜色#5b616c inativeColor#d9d9de

        baseOption: {
          tooltip: this.tooltipConfig(null, 'axis'),
          legend: {
            selected: selected,
            ...this.legendConfig('legend'),
            show: this.props.lineType === 'emotion' ? false : true,
          },
          grid: this.gridConfig(30, 40, 30, 34),
          xAxis: this.autoSetLineXAxis(this.optionData, { boundaryGap: false }),
          yAxis: this.autoSetLineYAxis(this.optionData),
          series: (this.props.lineType === 'area' ? this.optionData.legend.reverse() : this.optionData.legend).map((legendItem, legendIndex) => {
            let data;
            let seriesItem = {
              // 函数判断数字类型或者legend类型进行转化成哪一种yAxias [0,1,2]
              // yAxisIndex: 0,
              yAxisIndex: this.changeYaxisIndex(legendItem),
              name: legendItem,
              type: 'line',
              stack: legendItem,
              top: 30,
            };
            this.props.customColorArr &&
              this.props.customColorArr.fields.indexOf(this.optionData.field[legendIndex]) > -1 &&
              (seriesItem.itemStyle = {
                color: this.props.customColorArr.color[this.props.customColorArr.fields.indexOf(this.optionData.field[legendIndex])],
              });
            // 堆叠图的时候
            if (this.props.lineType === 'area') {
              data = this.optionData.data.reverse()[legendIndex];

              seriesItem = Object.assign(seriesItem, {
                data: data.slice(),
                stack: 1,
                areaStyle: {
                  opacity: 0.4,
                },
              });
            } else if (this.props.lineType === 'emotion') {
              data = this.optionData.data.map((el) => {
                return parseFloat(
                  el.filter((item, index) => {
                    return this.isSelect[index];
                  })[legendIndex]
                );
              });
              seriesItem = Object.assign(seriesItem, {
                data: data.slice(),
                areaStyle: {},
                color: 'white',
                smooth: true,
                symbolSize: '28',
                distance: 20,
                symbolOffset: [0, '-7'],
                // symbol: (value, params) => { return value >= 9 ? emotionMap[1] : value >= 6 ? emotionMap[0] : emotionMap[2] },
                symbol: (value, params) => {
                  let x;
                  switch (value) {
                    case 10:
                    case 9:
                      if (this.optionData.expressionColor[params.dataIndex] === '#F9BA00') {
                        return (x = emotionMap[1]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#E23C4D') {
                        return (x = emotionMap[3]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#FF6156') {
                        return (x = emotionMap[6]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#F4820E') {
                        return (x = emotionMap[9]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#2EC84E') {
                        return (x = emotionMap[12]);
                      } else {
                        return (x = emotionMap[12]);
                      }
                      break;
                    case 8:
                    case 7:
                      if (this.optionData.expressionColor[params.dataIndex] === '#F9BA00') {
                        return (x = emotionMap[0]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#E23C4D') {
                        return (x = emotionMap[4]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#FF6156') {
                        return (x = emotionMap[7]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#F4820E') {
                        return (x = emotionMap[10]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#2EC84E') {
                        return (x = emotionMap[13]);
                      } else {
                        return (x = emotionMap[10]);
                      }
                      break;
                    case 6:
                    case 5:
                    case 4:
                    case 3:
                    case 2:
                    case 1:
                    case 0:
                      if (this.optionData.expressionColor[params.dataIndex] === '#F9BA00') {
                        return (x = emotionMap[2]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#E23C4D') {
                        return (x = emotionMap[5]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#FF6156') {
                        return (x = emotionMap[8]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#F4820E') {
                        return (x = emotionMap[11]);
                      } else if (this.optionData.expressionColor[params.dataIndex] === '#2EC84E') {
                        return (x = emotionMap[14]);
                      } else {
                        return (x = emotionMap[5]);
                      }
                      break;
                  }
                },
                label: {
                  show: true,
                  fontSize: 12,
                  lineHeight: 18,
                  formatter: (params) => {
                    if (this.optionData.emotionalDescription[params.dataIndex]) {
                      var strs = this.optionData.emotionalDescription[params.dataIndex].split(''); //字符串数组
                      var str = '';
                      for (var i = 0, s; (s = strs[i++]); ) {
                        //遍历字符串数组
                        str += s;
                        if (!(i % 5)) str += '\n'; //按需要求余
                      }
                      return str;
                    } else {
                      return this.optionData.emotionalDescription[params.dataIndex];
                    }
                  },
                },
                itemStyle: {
                  // color: this.optionData.emotionalColor
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1.05,
                    colorStops: [
                      {
                        // offset: 0, color: this.optionData.emotionalColor // 100% 处的颜色
                        offset: 0,
                        color:
                          this.optionData.emotionalColor && this.optionData.emotionalColor.r
                            ? `rgba(${this.optionData.emotionalColor.r}, ${this.optionData.emotionalColor.g}, ${this.optionData.emotionalColor.b}, ${this.optionData.emotionalColor.a})`
                            : this.optionData.emotionalColor, // 100% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'white', // 100% 处的颜色
                      },
                    ],
                    global: false,
                  },
                },
              });
            } else {
              data = this.optionData.data.map((el) => {
                return parseFloat(
                  el.filter((item, index) => {
                    return this.isSelect[index];
                  })[legendIndex]
                );
              });
              seriesItem = Object.assign(seriesItem, {
                data: data.slice(),
              });
            }
            if (toJS(this.optionData.legend).length === 1) {
              seriesItem = Object.assign(
                {
                  markPoint: {
                    // symbolSize: 50,
                    label: {
                      fontSize: 9,
                      formatter: (params) => {
                        let field = this.optionData.axisField ? this.optionData.axisField : this.optionData.field;
                        if (field && field[legendIndex]) {
                          return legendUtil.formateTitle(this.props.stackFeildArr ? this.getRealField(field[legendIndex]) : field[legendIndex], params.value);
                        } else {
                          return params.value;
                        }
                      },
                    },
                    data: [
                      {
                        type: 'max',
                        name: '最大值',
                      },
                      {
                        type: 'min',
                        name: '最小值',
                      },
                    ],
                  },
                  // markLine: {
                  //   label: {
                  //     formatter: (params) => {
                  //       let field = this.optionData.axisField ? this.optionData.axisField : this.optionData.field;
                  //       if (field && field[legendIndex]) {
                  //         return legendUtil.formateTitle(this.props.stackFeildArr ? this.getRealField(field[legendIndex]) : field[legendIndex], params.value);
                  //       } else {
                  //         return params.value;
                  //       }
                  //     },
                  //   },
                  //   data: [
                  //     {
                  //       type: 'average',
                  //       name: '平均值',
                  //       label: {
                  //         position: 'middle',
                  //       },
                  //     },
                  //   ],
                  // },
                },
                seriesItem
              );
            }
            return seriesItem;
          }),
        },
        media: this.optionData.legend.length === 1 ? mediaConfig.otherline : mediaConfig.line,
      };
    } else if (this.chartType === 'pie') {
      // 如果图表类型是饼图则发生判断
      let index = this.isSelect.indexOf(true);
      let axis = this.optionData.pieAxis || this.optionData.axis;
      let data = this.optionData.pieData || this.optionData.data;
      const series = {
        name: this.optionData.legend[index],
        type: 'pie',
        radius: ['50%', '50%'],
        labelLine: {
          normal: {
            show: true,
          },
        },
        label: {
          formatter: (params) => {
            return `${params.seriesName}：${legendUtil.formateTitle(legendUtil.getCnName(params.seriesName, 'field'), params.value)}`;
          },
        },
        avoidLabelOverlap: true,
        data:
          data &&
          data.map((el, i) => {
            return {
              name: axis[i],
              value: el[index],
            };
          }),
      };
      this.props.customColorArr &&
        this.props.customColorArr.fields.indexOf(this.optionData.field[index]) > -1 &&
        (series.itemStyle = {
          color: this.props.customColorArr.color[this.props.customColorArr.fields.indexOf(this.optionData.field[index])],
        });
      return {
        baseOption: {
          tooltip: this.tooltipConfig('pie', 'item'),
          legend: { show: !this.props.noLegendSelect, ...this.legendConfig('axis') },
          grid: this.gridConfig(0, 0, 0, 0),
          series: series,
        },
        media: mediaConfig.pie,
      };
    } else if (this.chartType === 'bar') {
      // 如果图表类型是柱状图则发生判断
      const that = this;
      if (this.props.barType === 'horizontal') {
        this.optionData.customTooltip && (this.optionData.customTooltip = this.optionData.customTooltip.reverse());
        const isBeyondYear = util.isBeyondYear(this.optionData.axis);
        return {
          tooltip: this.tooltipConfig(null, 'axis'),
          grid: this.gridConfig(0, 16, 0, 0),
          calculable: true,
          yAxis: {
            type: 'category',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: (value, index) => {
                return this.stringLeaveOut(value, index, isBeyondYear);
              },
              fontSize: 12,
            },
            data: this.optionData.axis
              .slice()
              .reverse()
              .map((item) => {
                return this.checkIsContainDate(item);
              }),
          },
          xAxis: this.autoSetLineYAxis(this.optionData),
          textStyle: {
            color: changeLineStyleByTheme('label'),
          },
          series: this.optionData.legend
            .filter((item, index) => {
              return this.isSelect[index];
            })
            .map((item, i) => {
              let data = this.optionData.data
                .map((el) => {
                  return parseFloat(
                    el.filter((item, index) => {
                      return this.isSelect[index];
                    })[i]
                  );
                })
                .reverse();
              let seriesItem = {
                xAxisIndex: this.changeYaxisIndex(item),
                name: item,
                type: 'bar',
                barGap: 0,
                data: data,
                barMaxWidth: 48,
                label: {
                  // emphasis: {
                  show: true,
                  textBorderWidth: 1,
                  position: 'insideLeft',
                  color: changeLineStyleByTheme('barLabel'),
                  //label 的内容
                  formatter: function(params) {
                    return `${
                      Number(params.value) === 0
                        ? ''
                        : legendUtil.formateTitle(
                            legendUtil.getCnName(that.props.analyseType === 'analyseSurvey' ? that.getSeriesName(params) : params.seriesName, 'field'),
                            params.value
                          )
                    }`;
                  },
                  // },
                },
              };
              this.props.customColorArr &&
                this.props.customColorArr.fields.indexOf(this.optionData.field[i]) > -1 &&
                (seriesItem.itemStyle = {
                  color: this.props.customColorArr.color[this.props.customColorArr.fields.indexOf(this.optionData.field[i])],
                });
              return seriesItem;
            }),
        };
      } else if (this.props.barType === 'horizontalRank') {
        // 横排列
        this.optionData.customTooltip && (this.optionData.customTooltip = this.optionData.customTooltip.reverse());
        const isBeyondYear = util.isBeyondYear(this.optionData.axis);
        return {
          tooltip: this.tooltipConfig(null, 'axis'),
          grid: this.gridConfig(0, 6, 0, 0),
          calculable: true,
          yAxis: {
            type: 'category',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: (value, index) => {
                return this.stringLeaveOut(value, index, isBeyondYear);
              },
              fontSize: 12,
              color: changeLineStyleByTheme('label'),
              // color: '#7a8493',
            },
            data: this.optionData.axis
              .slice()
              .reverse()
              .map((item) => {
                return this.checkIsContainDate(item);
              }),
          },
          xAxis: this.autoSetLineYAxis(this.optionData),

          series: this.optionData.legend
            .filter((item, index) => {
              return this.isSelect[index];
            })
            .map((item, i) => {
              let data = this.optionData.data
                .map((el) => {
                  return parseFloat(
                    el.filter((item, index) => {
                      return this.isSelect[index];
                    })[i]
                  );
                })
                .reverse();
              return {
                name: item,
                type: 'bar',
                data: data,
                barMaxWidth: 48,
                label: {
                  show: true,
                  position: 'insideLeft',
                  //label 的内容
                  formatter: function(params) {
                    return `${
                      Number(params.value) === 0
                        ? null
                        : legendUtil.formateTitle(
                            legendUtil.getCnName(that.props.analyseType === 'analyseSurvey' ? that.getSeriesName(params) : params.seriesName, 'field'),
                            params.value
                          )
                    }`;
                  },
                  //定义不同地方的文字的字体大小和颜色
                  rich: {
                    c: {
                      padding: [0, 0, 0, 0],
                      color: changeLineStyleByTheme('barLabel'),
                      textBorderColor: '#000000',
                    },
                  },
                },
              };
            }),
        };
      } else if (this.props.barType === 'horizontalRankStack') {
        // 横排列
        this.optionData.customTooltip && (this.optionData.customTooltip = this.optionData.customTooltip.reverse());
        const selectedFeilds =
          this.props.stackFeildArr &&
          this.optionData.field.filter((item, index) => {
            return this.isSelect[index];
          });
        const that = this;
        const isBeyondYear = util.isBeyondYear(this.optionData.axis);
        return {
          tooltip: this.tooltipConfig(null, 'axis'),
          grid: this.gridConfig(0, 6, 0, 0),
          calculable: true,
          yAxis: {
            type: 'category',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: (value, index) => {
                return this.stringLeaveOut(value, index, isBeyondYear);
              },
              fontSize: 12,
              color: changeLineStyleByTheme('label'),
            },
            data: this.optionData.axis.slice().reverse(),
          },
          xAxis: this.autoSetLineYAxis(this.optionData),
          series: this.optionData.legend
            .filter((item, index) => {
              return this.isSelect[index];
            })
            .map((item, i) => {
              let data = this.optionData.data
                .map((el) => {
                  return parseFloat(
                    el.filter((item, index) => {
                      return this.isSelect[index];
                    })[i]
                  );
                })
                .reverse();
              let seriesItem = {
                name: item,
                type: 'bar',
                stack: this.props.stackFeildArr ? this.getRealField(selectedFeilds[i]) : '总量',
                data: data,
                xAxisIndex: this.changeYaxisIndex(item),
                barMaxWidth: 48,
                label: {
                  show: true,
                  textBorderWidth: 1,
                  position: 'insideLeft',
                  //label 的内容
                  formatter: function(params) {
                    return `${
                      Number(params.value) === 0
                        ? ''
                        : legendUtil.formateTitle(
                            legendUtil.getCnName(that.props.analyseType === 'analyseSurvey' ? that.getSeriesName(params) : params.seriesName, 'field'),
                            params.value
                          )
                    }`;
                  },
                  //定义不同地方的文字的字体大小和颜色
                  rich: {
                    c: {
                      padding: [0, 0, 0, 0],
                      color: changeLineStyleByTheme('barLabel'),
                    },
                  },
                },
              };
              this.props.customColorArr &&
                this.props.customColorArr.fields.indexOf(this.optionData.field[i]) > -1 &&
                (seriesItem.itemStyle = {
                  color: this.props.customColorArr.color[this.props.customColorArr.fields.indexOf(this.optionData.field[i])],
                });
              return seriesItem;
            }),
        };
      } else if (this.props.barType === 'stack') {
        const selectedFeilds =
          this.props.stackFeildArr &&
          this.optionData.field.filter((item, index) => {
            return this.isSelect[index];
          });
        return {
          tooltip: this.tooltipConfig(null, 'axis'),
          legend: this.legendConfig('legend'),
          grid: this.gridConfig(0, 4, 8, 34),
          calculable: true,
          xAxis: this.autoSetLineXAxis(this.optionData),
          yAxis: this.autoSetLineYAxis(this.optionData),
          series: this.optionData.legend
            .filter((item, index) => {
              return this.isSelect[index];
            })
            .map((item, i) => {
              let data = this.optionData.data.map((el) => {
                return parseFloat(
                  el.filter((item, index) => {
                    return this.isSelect[index];
                  })[i]
                );
              });
              let seriesItem = {
                name: item,
                type: 'bar',
                stack: this.props.stackFeildArr ? this.getRealField(selectedFeilds[i]) : '总量',
                barGap: 0,
                data: data,
                barMaxWidth: 48,
                yAxisIndex: this.changeYaxisIndex(item),
                label: {
                  show: true,
                  textBorderWidth: 1,
                  position: 'inside',
                  color: changeLineStyleByTheme('barLabel'),
                  //label 的内容
                  formatter: function(params) {
                    return `${
                      Number(params.value) === 0
                        ? ''
                        : legendUtil.formateTitle(
                            legendUtil.getCnName(that.props.analyseType === 'analyseSurvey' ? that.getSeriesName(params) : params.seriesName, 'field'),
                            params.value
                          )
                    }`;
                  },
                },
              };
              this.props.customColorArr &&
                this.props.customColorArr.fields.indexOf(this.optionData.field[i]) > -1 &&
                (seriesItem.itemStyle = {
                  color: this.props.customColorArr.color[this.props.customColorArr.fields.indexOf(this.optionData.field[i])],
                });
              return seriesItem;
            }),
        };
      } else if (this.props.barType === 'withDataZoom') {
        return {
          tooltip: this.tooltipConfig(null, 'axis'),

          legend: this.legendConfig('legend'),
          grid: this.gridConfig(0, '5%', 8, 64),
          calculable: true,
          xAxis: this.autoSetLineXAxis(this.optionData),
          yAxis: this.optionData.legend ? this.autoSetLineYAxis(this.optionData) : this.lineYAxis,
          dataZoom: [
            {
              textStyle: false,
              show: this.optionData.legend.slice().length === 0 ? false : true,
              start: 50,
              end: 100,
              bottom: 32,
              zlevel: 2,
            },
          ],
          series:
            this.optionData.legend &&
            this.optionData.legend
              .filter((item, index) => {
                return this.isSelect[index];
              })
              .map((item, i) => {
                let data = this.optionData.data.map((el) => {
                  return parseFloat(
                    el.filter((item, index) => {
                      return this.isSelect[index];
                    })[i]
                  );
                });
                return {
                  yAxisIndex: this.changeYaxisIndex(item),
                  name: item,
                  type: 'bar',
                  barGap: 0,
                  data: data,
                  barMaxWidth: 48,
                  label: {
                    emphasis: {
                      show: true,
                      textBorderWidth: 1,
                      position: 'inside',
                      color: changeLineStyleByTheme('barLabel'),
                      //label 的内容
                      formatter: function(params) {
                        return `${
                          Number(params.value) === 0
                            ? ''
                            : legendUtil.formateTitle(
                                legendUtil.getCnName(that.props.stackFeildArr ? that.getRealCnField(params.seriesName) : params.seriesName, 'field'),
                                params.value
                              )
                        }`;
                      },
                    },
                  },
                };
              }),
        };
      } else if (this.props.barType === 'withLine') {
        return {
          tooltip: this.tooltipConfig(null, 'axis'),
          legend: this.legendConfig('legend'),
          grid: this.gridConfig(0, 4, 8, 34),
          calculable: true,
          xAxis: this.autoSetLineXAxis(this.optionData),
          yAxis: this.autoSetLineYAxis(this.optionData),
          series: this.optionData.legend
            .filter((item, index) => {
              return this.isSelect[index];
            })
            .map((item, i) => {
              let data = this.optionData.data.map((el) => {
                return parseFloat(
                  el.filter((item, index) => {
                    return this.isSelect[index];
                  })[i]
                );
              });
              if (i === 0) {
                return {
                  yAxisIndex: 0,
                  name: item,
                  type: 'bar',

                  barGap: 0,
                  data: data,
                  barMaxWidth: 48,
                };
              } else {
                return {
                  name: item,
                  type: 'line',

                  yAxisIndex: 1,
                  data: data,
                };
              }
            }),
        };
      } else {
        return {
          tooltip: this.tooltipConfig(null, 'axis'),

          legend: {
            type: 'scroll',
            animation: true,
            formatter: function(name) {
              return echarts.format.truncateText(name, 120, '…');
            },
            data: this.optionData.legend.slice().map((item) => {
              return {
                name: item,
                icon:
                  'path://M896.969659 16.576036 119.702341 16.576036c-61.317329 0-111.038333 49.665189-111.038333 111.038333l0 777.265288c0 61.265573 49.721004 111.039348 111.038333 111.039348l777.266302 0c61.314284 0 111.036304-49.773775 111.036304-111.039348L1008.004947 127.614369C1008.005962 66.241225 958.283943 16.576036 896.969659 16.576036zM877.124894 340.796156 443.975232 773.888988c-11.680554 11.736369-26.883542 17.734958-42.168729 18.026211-15.069031 1.231989-30.562257-3.90096-42.085514-15.423203L133.583021 550.406799c-20.767234-20.820005-20.767234-54.548493 0-75.368498 20.820005-20.81899 54.59416-20.81899 75.414165 0L389.301916 655.385655l401.179853-401.337149c23.964925-23.853295 62.727926-23.853295 86.643125 0C901.092864 278.126075 901.092864 316.834275 877.124894 340.796156z',
                textStyle: {
                  width: '10px',
                  overflow: 'hidden',
                  color: changeLineStyleByTheme('legend'),
                },
              };
            }),

            inativeColor: changeLineStyleByTheme('disabled'),
            itemGap: 20,
            bottom: 0,
            textStyle: {
              fontSize: 12,
            },
          },
          grid: this.gridConfig(0, 0, 8, 34),
          calculable: true,
          xAxis: this.autoSetLineXAxis(this.optionData),
          yAxis: this.optionData.legend ? this.autoSetLineYAxis(this.optionData) : this.lineYAxis,
          series:
            this.optionData.legend &&
            this.optionData.legend.map((item, i) => {
              let seriesItem = {
                // 函数判断数字类型或者legend类型进行转化成哪一种yAxias [0,1,2]
                // yAxisIndex: 0,
                yAxisIndex: this.changeYaxisIndex(item),
                name: item,
                type: 'bar',
                barGap: 0,
                data: null,
                barMaxWidth: 48,
                label: {
                  // emphasis: {
                  show: true,
                  textBorderWidth: 1,
                  position: 'inside',
                  color: changeLineStyleByTheme('barLabel'),
                  //label 的内容
                  formatter: function(params) {
                    return `${
                      Number(params.value) === 0
                        ? ''
                        : legendUtil.formateTitle(
                            legendUtil.getCnName(that.props.analyseType === 'analyseSurvey' ? that.getSeriesName(params) : params.seriesName, 'field'),
                            params.value
                          )
                    }`;
                  },
                  // },
                },
              };
              this.props.customColorArr &&
                this.props.customColorArr.fields.indexOf(this.optionData.field[i]) > -1 &&
                (seriesItem.itemStyle = {
                  color: this.props.customColorArr.color[this.props.customColorArr.fields.indexOf(this.optionData.field[i])],
                });
              let data;
              if (this.props.barType === 'area') {
                data = this.optionData.data[i];
                seriesItem = Object.assign(seriesItem, {
                  data: data.slice(),
                  stack: 1,
                });
              } else {
                data = this.optionData.data.map((el) => {
                  return parseFloat(
                    el.filter((item, index) => {
                      return this.isSelect[index];
                    })[i]
                  );
                });
                if (this.props.barType === 'custom') {
                  delete seriesItem.barMaxWidth;
                  seriesItem.barWidth = '99%';
                }
                seriesItem = Object.assign(seriesItem, {
                  data: data.slice(),
                });
              }

              return seriesItem;
            }),
        };
      }
    } else if (this.chartType === 'heatmap') {
      return {
        tooltip: this.tooltipConfig('heatmap', 'item'),
        animation: false,
        grid: this.gridConfig(0, 0, 0, '15%'),
        yAxis: {
          type: 'category',
          data: toJS(this.optionData.legend),

          splitLine: {
            show: false,
          },
          axisLabel: {
            color: changeLineStyleByTheme('label'),
          },
          axisLine: {
            lineStyle: {
              color: changeLineStyleByTheme('axisLine'),
            },
          },
        },
        xAxis: {
          type: 'category',
          data: toJS(this.optionData.axis),

          splitLine: {
            show: false,
          },
          axisLabel: {
            color: changeLineStyleByTheme('label'),
          },
          axisLine: {
            lineStyle: {
              color: changeLineStyleByTheme('axisLine'),
            },
          },
        },
        visualMap: {
          color: changeLineStyleById('heatMapRange'),
          min: 0,
          max: toJS(this.optionData.dataMax),
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: 0,
        },
        series: [
          {
            name: '热力图',
            type: 'heatmap',
            data: toJS(this.optionData.data),
            label: {
              show: true,
            },
          },
        ],
      };
    } else if (this.chartType === 'radar') {
      return {
        grid: this.gridConfig(0, 0, 0, 0),
        tooltip: this.tooltipConfig('radar', null),

        legend: this.legendConfig('axis'),
        radar: [
          {
            indicator: toJS(this.optionData.field).map((item, index) => {
              let maxArr = toJS(this.optionData.data).map((dataItem) => {
                return dataItem[index];
              });

              return {
                text: legendUtil.getTitle(item),
                max: legendUtil.getType(item) === 'rate' ? 1 : Math.max(maxArr),
              };
            }),
            name: {
              textStyle: {
                color: changeLineStyleByTheme('label'),
              },
            },
            axisLine: {
              lineStyle: {
                color: changeLineStyleByTheme('splitLine'),
              },
            },
            splitArea: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: 'radar',
            data: toJS(this.optionData.data).map((item, index) => {
              return {
                value: item,
                name: this.optionData.axis[index],
              };
            }),
          },
        ],
      };
    } else if (this.chartType === 'scatter') {
      const nameTextStyle = {
        color: '#7A8493',
        fontSize: 12,
        fontWeight: 400,
      };
      let xAxis = {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: changeLineStyleByTheme('label'),
          },
          axisLine: {
            lineStyle: {
              color: changeLineStyleByTheme('axisLine'),
            },
          },
          axisLabel: { show: this.props.scatterType !== 'quadrant' },
          nameTextStyle: { ...nameTextStyle },
        },
        yAxis = {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: changeLineStyleByTheme('label'),
          },
          axisLine: {
            lineStyle: {
              color: changeLineStyleByTheme('axisLine'),
            },
          },
          axisLabel: { show: this.props.scatterType !== 'quadrant' },
          nameTextStyle: nameTextStyle,
        };
      this.props.scatterType === 'quadrant' &&
        ((xAxis.max = function(value) {
          const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
          return maxABS === 0 ? 1 : maxABS * 1.2;
        }),
        (xAxis.min = function(value) {
          const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
          return maxABS === 0 ? -1 : maxABS * 1.2 * -1;
        }),
        (yAxis.max = function(value) {
          const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
          return maxABS === 0 ? 1 : maxABS * 1.2;
        }),
        (yAxis.min = function(value) {
          const maxABS = Math.max(Math.abs(value.max), Math.abs(value.min));
          return maxABS === 0 ? -1 : maxABS * 1.2 * -1;
        }));
      this.props.axisName && ((xAxis.name = this.props.axisName.xAxis), (yAxis.name = this.props.axisName.yAxis));
      let scatterOption = {
        grid: this.gridConfig(0, '10%', '12%', '15%'),
        title: this.props.commonChartTitle ? toJS(this.props.commonChartTitle) : [],
        legend: this.legendConfig('axis'),
        tooltip: this.tooltipConfig('scatter', null),
        xAxis: xAxis,
        yAxis: yAxis,
        series: toJS(this.optionData.data).map((item, index) => {
          return {
            type: 'scatter',
            data: [item],
            name: this.optionData.axis[index],
            label: { show: true, formatter: '{a}', position: 'top', color: '#B2B3B9' },
            emphasis: {
              focus: 'series',
            },
            symbolSize: (value, params) => {
              return this.props.sizeList ? (this.props.sizeList[params.seriesIndex] + 0.2) * 20 : 20;
            },
            symbol: 'circle',
          };
        }),
      };
      if (this.props.scatterType === 'withVisualMap') {
        return Object.assign(
          {
            visualMap: [
              {
                left: 'right',
                top: '10%',
                dimension: 2,
                min: 0,

                max: Math.max(util.customFlat(...toJS(this.optionData.data), 1)),
                itemWidth: 30,
                itemHeight: 120,
                calculable: true,
                precision: 0.1,
                text: [`圆形大小：${legendUtil.getTitle(toJS(this.optionData.field)[toJS(this.optionData.field).length - 1])}`],
                textGap: 30,
                textStyle: {
                  color: changeLineStyleByTheme('label'),
                },
                inRange: {
                  symbolSize: [10, 70],
                },
                outOfRange: {
                  symbolSize: [10, 70],
                  color: changeLineStyleByTheme('disabled'),
                },
                controller: {
                  inRange: {
                    color: [`${changeLineStyleByTheme('mainColor')}`],
                  },
                  outOfRange: {
                    color: [`${changeLineStyleByTheme('disabled')}`],
                  },
                },
              },
            ],
          },
          scatterOption
        );
      }
      return { ...scatterOption, media: mediaConfig.scatter };
    } else if (this.chartType === 'gauge') {
      const dataType = legendUtil.getType(this.optionData.field[0]);
      return {
        tooltip: this.tooltipConfig('gauge', null),
        series: [
          {
            name: legendUtil.getTitle(this.optionData.field[0]),
            type: 'gauge',
            radius: '100%',
            startAngle: 250,
            endAngle: -70,
            min: legendUtil.getType(this.optionData.field[0]) === 'rate' || legendUtil.getType(this.optionData.field[0]) === 'NPS' ? -100 : 0,
            max: legendUtil.getType(this.optionData.field[0]) === 'rate' || legendUtil.getType(this.optionData.field[0]) === 'NPS' ? 100 : 1000,
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 10,
              },
            },
            axisLabel: { color: '#718493' },
            title: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              fontWeight: 'bolder',
              fontSize: 16,
              color: changeLineStyleByTheme('label'),
            },
            data: [
              {
                value:
                  dataType === 'rate'
                    ? parseFloat((this.optionData.data[0][0] * 100).toFixed(2))
                    : dataType === 'NPS'
                    ? parseFloat((this.optionData.data[0][0] * 100).toFixed(1))
                    : this.optionData.data[0][0],
                detail: {
                  color: '#050a13',
                  fontWeight: 'normal',
                  fontSize: 28,
                  formatter: dataType === 'rate' ? '{value}%' : '{value}',
                },
                // name: this.optionData.legend && this.optionData.legend[0] ? this.optionData.legend[0] : legendUtil.getUnit(this.optionData.field[0]),
              },
            ],
          },
        ],
      };
    } else if (this.chartType === 'map' && this.mapType === 'city') {
      let json = require('../../static/JSON/china');
      function getCityPosition(json, name) {
        let result;
        for (let i = 0; i < json.length; i++) {
          if (json[i].city === name || json[i].city === name.substring(0, name.length - 1) || json[i].county === name) {
            result = json[i];
            break;
          }
        }
        return result;
      }
      return {
        backgroundColor: 'transparent',
        animation: true,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
        animationDurationUpdate: 1000,
        animationEasingUpdate: 'cubicInOut',
        title: [
          {
            text: '',
            subtext: '',
            left: 'center',
            textStyle: {
              color: '#fff',
            },
          },
        ],
        geo: {
          map: 'china',
          center: [117.98561551896913, 31.205000490896193],
          zoom: 1,
          left: '40%',
          scaleLimit: {
            min: 1,
            max: 10,
          },
          top: 'bottom',
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: '#eeeeee',
              borderColor: '#a5a4a4',
            },
            emphasis: {
              areaColor: '#d4d4d4',
            },
          },
        },
        tooltip: this.tooltipConfig('map', 'item'),
        visualMap: {
          min: 0,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'],
          inRange: {
            color: changeLineStyleById('range'),
          },
          calculable: true,
        },
        left: '300px',
        series: this.optionData.legend
          .filter((item, index) => {
            return this.isSelect[index];
          })
          .map((item, i) => {
            return {
              name: item,
              type: 'scatter',
              coordinateSystem: 'geo',
              symbolSize: function(val) {
                let i = Math.max(val[2] / 10, 8);
                if (i > 20) i = 20;
                return i;
              },
              label: {
                normal: {
                  formatter: '{b}',
                  position: 'right',
                  show: true,
                },
                emphasis: {
                  show: true,
                },
              },
              itemStyle: {
                normal: {
                  color: '#ddb926',
                  position: 'right',
                  show: true,
                },
              },
              data: this.optionData.axis.map((el2, index2) => {
                let arr = {
                  name: el2,
                  value: [],
                };
                if (getCityPosition(json, el2)) {
                  arr.value.push(parseFloat(getCityPosition(json, el2).latitude));
                  arr.value.push(parseFloat(getCityPosition(json, el2).longitude));
                  arr.value.push(
                    parseFloat(
                      this.optionData.data[index2].filter((el3, index) => {
                        return this.isSelect[index];
                      })[i]
                    )
                  );
                } else {
                  console.error('ch.json中文件中没有' + el2);
                }
                return arr;
              }),
              /*data: [{name: '杭州', value: ['120.19','30.26', '225']}]*/
            };
          }),
      };
    } else if (this.chartType === 'map' && this.mapType === 'province') {
      return {
        backgroundColor: 'transparent',
        animation: true,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
        animationDurationUpdate: 1000,
        animationEasingUpdate: 'cubicInOut',
        scaleLimit: {
          min: 1,
          max: 10,
        },
        tooltip: this.tooltipConfig('map', 'item'),
        visualMap: {
          min: 0,
          max: 1500,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'],
          inRange: {
            // color: ['#8bbafc', '#26e0ae'],
            color: changeLineStyleById('range'),
          },
          calculable: true,
        },
        geo: {
          map: 'china',
          center: [117.98561551896913, 31.205000490896193],
          zoom: 1,
          left: '40%',
          top: 'bottom',
          label: {
            emphasis: {
              show: false,
            },
            normal: {
              show: true,
              textStyle: {
                color: 'rgba(0,0,0,0.4)',
              },
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: '#eeeeee',
              borderColor: '#a5a4a4',
            },
            emphasis: {
              areaColor: '#d4d4d4',
            },
          },
        },
        series: this.optionData.legend
          .filter((item, index) => {
            return this.isSelect[index];
          })
          .map((item, i) => {
            return {
              name: item,
              type: 'map',
              geoIndex: 0,
              data: this.optionData.axis.map((el2, index2) => {
                let arr = {
                  name: el2,
                  value: this.optionData.data[index2].filter((el3, index) => {
                    return this.isSelect[index];
                  })[i],
                };
                return arr;
              }),
              itemStyle: {
                emphasis: {
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(52,60,73,0.1)',
                  shadowBlur: 1000,
                },
              },
            };
          }),
      };
    } else if (this.chartType === 'map' && this.mapType === 'country') {
      return {
        backgroundColor: 'transparent',
        animation: true,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
        animationDurationUpdate: 1000,
        animationEasingUpdate: 'cubicInOut',
        scaleLimit: {
          min: 1,
          max: 10,
        },
        tooltip: this.tooltipConfig('map', 'item'),
        visualMap: {
          min: 0,
          max: 1500,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'],
          inRange: {
            color: changeLineStyleById('range'),
          },
          calculable: true,
        },
        geo: {
          map: 'world',
          center: [117.98561551896913, 31.205000490896193],
          zoom: 1,
          left: '40%',
          top: '0',
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: '#eeeeee',
              borderColor: '#a5a4a4',
            },
            emphasis: {
              areaColor: '#d4d4d4',
            },
          },
        },
        /*series : [
                    {
                        name: 'categoryA',
                        type: 'map',
                        mapType: 'world',
                        // tooltip: {show: false},
                        data:[
                            {name: 'China', value: 50},
                        ]
                    }
                ]*/
        series: this.optionData.legend
          .filter((item, index) => {
            return this.isSelect[index];
          })
          .map((item, i) => {
            return {
              name: item,
              type: 'map',
              geoIndex: 0,
              data: this.optionData.axis.map((el2, index2) => {
                let arr = {
                  name: el2,
                  value: this.optionData.data[index2].filter((el3, index) => {
                    return this.isSelect[index];
                  })[i],
                };
                return arr;
              }),
              itemStyle: {
                emphasis: {
                  shadowOffsetX: 0,
                  shadowColor: 'rgb(52,60,73,0.8)',
                },
              },
            };
          }),
      };
    } else if (this.chartType === 'treemap') {
      return {
        tooltip: this.tooltipConfig('treemap'),
        series: [
          {
            type: 'treemap',
            left: 0,
            right: 0,
            data: this.optionData.axis.map((item, index) => {
              return {
                name: item,
                value: this.optionData.data[index][0],
              };
            }),
          },
        ],
      };
    } else if (this.chartType === 'wordCloud') {
      return {
        tooltip: this.tooltipConfig('wordCloud'),
        series: [
          {
            type: 'wordCloud',
            width: '95%',
            height: '100%',
            rotationRange: [0, 0],
            emphasis: {
              focus: 'self',
              textStyle: {
                shadowBlur: 10,
                shadowColor: '#333',
              },
            },
            data: toJS(this.optionData.data),
          },
        ],
      };
    }
  }
}

export default Store;
