import React, { forwardRef, useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { DatePicker as NewDatePicker } from 'antd4';
import { DatePickerFormats } from '../constants';
import MultiDatePicker from '../../../MultiDatePicker';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { getTextWidth } from '../../../../containers/BIChart/util';
const { WeekPicker, MonthPicker } = DatePicker;

const Date = ({ control, value, size, onChange, isPageHeader }, ref) => {
  const { Week, Month, Year, Datetime, DatetimeMinute } = DatePickerFormats;
  const { multiple, dateFormat } = control;
  const content = useRef();
  const [type, setType] = useState('date');

  useEffect(() => {
    if (dateFormat === 'YYYY-MM-DD ') {
      setType('quarter');
    } else {
      setType('date');
    }
    if (content.current && isPageHeader) {
      const titleWidth = getTextWidth(control.name);
      if (dateFormat !== 'YYYY-MM-DD ') {
        content.current.getElementsByClassName('ant-calendar-picker-input')[0].style.paddingLeft = `${titleWidth + 16}px`;
        content.current.getElementsByClassName('ant-calendar-picker-input')[0].style.paddingRight = '0px';
      } else {
        content.current.getElementsByClassName('ant-picker').length &&
          (content.current.getElementsByClassName('ant-picker')[0].style.paddingLeft = `${titleWidth + 16}px`);
      }
    }
  }, [content, control]);

  const controlled = !!onChange;
  if (multiple) {
    value = value || '';
    return (
      <div className="controlDate" ref={content}>
        <MultiDatePicker ref={ref} placeholder="请选择" format={dateFormat} {...(size && { size })} {...(controlled && { value, onChange })} />
      </div>
    );
  } else {
    value = moment.isMoment(value) ? value : null;
    switch (dateFormat) {
      case Week:
        return (
          <div className="controlDate" ref={content}>
            <WeekPicker ref={ref} placeholder="请选择" {...(size && { size })} {...(controlled && { value, onChange })} />
          </div>
        );
      case Month:
      case Year:
        return (
          <div className="controlDate" ref={content}>
            <MonthPicker ref={ref} placeholder="请选择" format={dateFormat} {...(size && { size })} {...(controlled && { value, onChange })} />
          </div>
        );
      default:
        const isDatetimePicker = [Datetime, DatetimeMinute].includes(dateFormat);
        return dateFormat !== 'YYYY-MM-DD ' ? (
          <div className="oldControlDate" ref={content}>
            <DatePicker
              picker={type}
              locale={locale}
              ref={ref}
              placeholder="请选择"
              showTime={isDatetimePicker}
              format={dateFormat}
              {...(size && { size })}
              {...(controlled && { value })}
              {...(controlled && { onChange })}
              {...(controlled && { onOk: onChange })}
            />
          </div>
        ) : (
          <div className="controlDate" ref={content}>
            <NewDatePicker ref={ref} value={value} picker={type} placeholder="请选择" format={dateFormat} onChange={onChange} />
          </div>
        );
    }
  }
};

export default forwardRef(Date);
