import React, { FC, memo, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import ControlComponent from '../../index';
import { Button } from 'antd';
import { ControlQueryMode } from '../../../constants';
import { getControlVisibility } from '../../../util';
import './Layouts.less';

const DashboardControlPanelLayout = ({ queryMode, renderTree, formValues, mapOptions, onChange, onSearch, onReset, onSelectSearch }) => {
  const renderControlComponents = useCallback(
    (controlRenderTreeItems, parents) => {
      let components = [];

      controlRenderTreeItems.forEach((control) => {
        const { key, width, children, ...rest } = control;
        if (getControlVisibility(renderTree, control, formValues)) {
          const controlValue = formValues && formValues[`${control.key}`];

          components = components.concat(
            <ControlComponent
              key={JSON.stringify(control)}
              queryMode={queryMode}
              control={control}
              value={controlValue}
              currentOptions={mapOptions[key]}
              onChange={onChange}
              onSearch={onSearch}
              onSelectSearch={onSelectSearch}
              isPageHeader={true}
            />
          );
          if (children) {
            const controlWithOutChildren = { key, width, ...rest };
            components = components.concat(renderControlComponents(children, parents ? parents.concat(controlWithOutChildren) : [controlWithOutChildren]));
          }
        }
      });
      return components;
    },
    [renderTree, formValues, mapOptions]
  );

  const panelClassNames = useMemo(
    () =>
      classnames({
        dashboardControlPanel: true,
        empty: !renderTree.filter((r) => getControlVisibility(renderTree, r, formValues)).length,
      }),
    [renderTree]
  );

  const manualSearch = useCallback(() => {
    onSearch();
  }, [onSearch]);

  return (
    <div className={panelClassNames}>
      <div className={'controls'}>
        {renderControlComponents(renderTree)}
        {queryMode === ControlQueryMode.Manually && (
          <div className="controlItem">
            <Button type="primary" icon="search" onClick={manualSearch}>
              查询
            </Button>
            <Button icon="reload" onClick={onReset} className="reloadButton">
              重置
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DashboardControlPanelLayout);
