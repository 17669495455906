import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import { Button, Popconfirm, Input, Radio, Cascader, Switch, message, notification, Select, Form, Checkbox } from 'antd';
import { Card } from '../../../component/Card/Card';
// import Loading from '../../component/Loading/Loading';
// import CommonTable from '../../containers/CommonTable';
// import tableUtil from '../../utils/tableUtil';
// import { iconfont } from '../../config';
// import { ConfigContext } from './ConfigContext';
import Breadcrumb from '../../../component/Breadcrumb';
import './index.sass';
import CustomParametersForm from './setting/form';
import CommonIframe from '../../../containers/CommonIframe';
import pageUtil from '../../../utils/pageUtil';

function CreateCustomParameters(props) {
  const [modalVisible, setModalVisible] = useState(false); //抽屉显隐
  const [elementInfo, setElementInfo] = useState(null);
  const [isLoading, setLoading] = useState(true); //元素属性

  useEffect(() => {
    pageUtil.setDocumentTitle('埋点管理 - 功能设置 - 设置');
  }, []);
  useEffect(() => {
    function getmessage(e) {
      if (e.data.type === 'cemyun-select-click' && !modalVisible) {
        setElementInfo(e);
        setModalVisible(true);
      }
    }
    window.addEventListener('message', getmessage, false);
    return () => {
      window.removeEventListener('message', getmessage, false);
    };
  }, [elementInfo]);

  return (
    <div className="event-select">
      <Breadcrumb data={[{ name: '自定义属性' }, { name: '新建属性' }]} breadCrumbName="新建自定义属性" breadCrumbSecName="创建埋点" />
      <Card
        card={{
          title: '新建自定义属性',
          content: (
            <React.Fragment>
              <Button
                type={'add'}
                onClick={() => {
                  setModalVisible(true);
                }}
                style={{ position: 'absolute', right: '38px', top: '132px' }}>
                展开表单
              </Button>
              <CommonIframe />
            </React.Fragment>
          ),
        }}
      />

      {modalVisible && (
        <CustomParametersForm
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
          elementInfo={elementInfo}
        />
      )}
    </div>
  );
}

export default CreateCustomParameters;
