import { observable, action, toJS } from 'mobx';
import moment from 'moment';

class PathFormStore {
  // 暂存信息
  @observable targetData = [];
  @observable data;
  @observable pathStepData = [];
  // 数据结构
  @observable pathId = 'null';
  @observable chartType = 13;
  @observable config = { name: '', remarks: '' };
  //日期数据
  @observable dateObj = {
    lastDate: 'last7',
    date: `${moment()
      .subtract(8, 'days')
      .format('YYYY-MM-DD')},${moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')}`,
  };

  @action.bound setDateObj(lastDate, date) {
    this.dateObj = { lastDate, date };
  }
  @action.bound changeType(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { type: value });
    if (value === 2) {
      this.pathStepData[stepIndex].splice(1, this.pathStepData[stepIndex].length);
    }
  }
  @action.bound changeFilters(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { ruleContent: value });
  }
  @action.bound changeStepName(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { stepName: value });
  }
  @action.bound changeFilterObject(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { filterObject: value });
  }
  @action.bound changeFilterItemLogic(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { filterItemLogic: value });
  }
  @action.bound changeChildrenFilter(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { childrenFilter: value });
  }
  @action.bound changeDimension(value, stepIndex, itemIndex) {
    this.pathStepData[stepIndex][itemIndex] = Object.assign(this.pathStepData[stepIndex][itemIndex], { dimension: value });
  }

  @action.bound delPathStep(dataIndex, index) {
    if (toJS(this.pathStepData).length === 1) {
      return;
    }
    this.pathStepData[dataIndex].splice(index, 1);
    //
    if (this.pathStepData[dataIndex].length === 0) {
      this.pathStepData.splice(dataIndex, 1);
      this.changePathTarget(toJS(this.pathStepData).length - 1);
    }
  }
  @action.bound addPathStep() {
    let nameIndex = toJS(this.pathStepData).length + 1;
    this.pathStepData.push([
      {
        stepName: `步骤${nameIndex}1`,

        ruleContent: [{ type: 'default', dim: 'event', dimValue: '任意事件' }],
        filterObject: {
          filters: null,
          logic: 'and',
        },
        filterItemLogic: ['and'],
        childrenFilter: [[]],
        dimension: '',
        skip: false,
        display: true,
        target: false,
        type: 1,
        // pathId: 500,
      },
    ]);
    this.changePathTarget(toJS(this.pathStepData).length - 1);
  }
  @action.bound addPathStepEvent(dataIndex, index) {
    this.pathStepData[dataIndex].push({
      stepName: `步骤${dataIndex + 1}${index + 2}`,
      ruleContent: [{ type: 'default', dim: 'event', dimValue: '任意事件' }],
      filterObject: {
        filters: null,
        logic: 'and',
      },
      filterItemLogic: ['and'],
      childrenFilter: [[]],
      dimension: '',
      target: this.pathStepData[dataIndex][0].target,
      skip: this.pathStepData[dataIndex][0].skip,
      display: this.pathStepData[dataIndex][0].display,
      type: this.pathStepData[dataIndex][0].type,
      // pathId: 500,
    });
  }
  @action.bound changePathShow(dataIndex) {
    this.pathStepData[dataIndex].forEach((item) => {
      item['display'] = !item['display'];
    });
  }
  @action.bound changePathSkip(dataIndex) {
    this.pathStepData[dataIndex].forEach((item) => {
      item['skip'] = !item['skip'];
    });
  }
  @action.bound changePathTarget(dataIndex) {
    this.pathStepData.forEach((dataItem) => {
      dataItem.forEach((item) => {
        item['target'] = false;
      });
    });
    this.pathStepData[dataIndex].forEach((item) => {
      item['target'] = true;
    });
  }

  @action.bound initPathStepData() {
    this.pathStepData = [
      [
        {
          stepName: '步骤11',
          ruleContent: [{ type: 'default', dim: 'event', dimValue: '任意事件' }],
          filterObject: {
            filters: null,
            logic: 'and',
          },
          filterItemLogic: ['and'],
          childrenFilter: [[]],
          dimension: '',
          skip: false,
          display: true,
          target: false,
          type: 1,
          // pathId: 500,
        },
      ],
      [
        {
          stepName: '步骤21',
          ruleContent: [{ type: 'default', dim: 'event', dimValue: '任意事件' }],
          filterObject: {
            filters: null,
            logic: 'and',
          },
          filterItemLogic: ['and'],
          childrenFilter: [[]],
          dimension: '',
          skip: false,
          display: true,
          target: true,
          type: 1,
          // pathId: 500,
        },
      ],
    ];
  }
  @action.bound resetPathStepData(value) {
    let pathStepData = value;
    pathStepData.map((valueItem) =>
      valueItem.map((item) => {
        const filterObject = !item.filterObject || item.filterObject === '' ? '' : JSON.parse(item.filterObject);

        item.filterObject =
          !filterObject || filterObject === ''
            ? {
                filters: null,
                logic: 'and',
              }
            : filterObject;
        item.filterItemLogic = !filterObject || filterObject === '' ? ['and'] : [filterObject.logic];
        item.childrenFilter = !filterObject || filterObject === '' ? [[]] : [filterObject.filters];
        item.ruleContent = JSON.parse(item.ruleContent);
        return item;
      })
    );
    this.pathStepData = pathStepData;
  }
  @action.bound setPathId(value) {
    this.pathId = value;
  }
}
export default PathFormStore;
