import React, { Component } from 'react';
import './index.sass';
export default class OrderNumber extends Component {
  render() {
    return (
      <div
        className={`orderNumber-text orderNumber-text-${this.props.size ? this.props.size : 'normal'} ${
          this.props.isMainColor ? 'orderNumber-text-mainColor' : ''
        }`}>
        {this.props.number}
      </div>
    );
  }
}
