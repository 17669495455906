import React, { useEffect, useState, Fragment } from 'react';
import { Form, Row, Col } from 'antd';
import intl from 'react-intl-universal';
import Loading from '../../../component/Loading/Loading';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import util from '../../../util';
import api from '../../../api';
import './index.sass';

function AuditModal(props) {
  const { editInfo, type } = props;
  const { editId } = editInfo;

  const [data, setData] = useState([]);
  // const [events, setEvents] = useState({});
  const [eventParam, setEventParam] = useState({});
  const [loading, setLoading] = useState(true); // 是否加载状态

  useEffect(() => {
    type === 'detail'
      ? util.get(
          api.audit.info,
          {
            id: editId,
          },
          (res) => {
            if (res && res.auditSourceInfo) {
              const { auditSourceInfo } = res;
              formatData(auditSourceInfo);
            }
          }
        )
      : util.get(api.trigger.edit, { id: editId, idsite: PageHeaderStore.parameter.idsite, isView: false }, (res) => {
          formatData(res);
        });
    // util.post(
    //   api.pack.dimOptions,
    //   {
    //     idsite: PageHeaderStore.parameter.idsite,
    //     dim: 'analysis_default',
    //   },
    //   (res) => {
    //     setEvents({ ...events, ...res.data });
    //   }
    // );
    // util.post(
    //   api.pack.dimOptions,
    //   {
    //     idsite: PageHeaderStore.parameter.idsite,
    //     dim: 'analysis_customize',
    //   },
    //   (res) => {
    //     setEvents({ ...events, ...res.data });
    //   }
    // );
  }, []);

  function formatData(auditSourceInfo) {
    auditSourceInfo.pushTime && (auditSourceInfo.pushTime = JSON.parse(auditSourceInfo.pushTime));
    auditSourceInfo.disturbConfig && (auditSourceInfo.disturbConfig = JSON.parse(auditSourceInfo.disturbConfig));
    auditSourceInfo.eventConfigFilter && (auditSourceInfo.eventConfigFilter = JSON.parse(auditSourceInfo.eventConfigFilter).rules[0].rules);
    auditSourceInfo.eventConfigFilter &&
      auditSourceInfo.eventConfigFilter.slice(1, auditSourceInfo.eventConfigFilter.length).forEach((item) => {
        util.post(
          api.pack.dimOptions,
          {
            idsite: PageHeaderStore.parameter.idsite,
            dim: 'analysis_attribute',
            search: JSON.stringify([{ value: auditSourceInfo.eventName }]),
          },
          (res) => {
            let eventParamCopy = {};
            for (const key in res.data) {
              eventParamCopy = { ...eventParamCopy, ...res.data[key] };
            }
            setEventParam({ ...eventParam, ...eventParamCopy });
          }
        );
      });
    if (type === 'apply') {
      if (auditSourceInfo.pushChannel) {
        const pushChannel = JSON.parse(auditSourceInfo.pushChannel);
        util.get(api.warning.getChannelFields, { idsite: PageHeaderStore.parameter.idsite, channelType: pushChannel.channel_type }, (res) => {
          const pushConfig = {};
          res.forEach((item) => {
            item.type === 'list'
              ? util.get(
                  api.warning.getFieldOptions,
                  {
                    idsite: PageHeaderStore.parameter.idsite,
                    pushChannelId: pushChannel.channel_id,
                    channelType: pushChannel.channel_type,
                    key: item.key,
                    attach: JSON.stringify({ sceneType: '3' }),
                  },
                  (res) => {
                    res.forEach((e) => {
                      if (pushChannel[item.key] === e.value) {
                        pushConfig[item.name] = e.name;
                        auditSourceInfo.pushConfig = pushConfig;
                        setData({ ...auditSourceInfo });
                      }
                    });
                  }
                )
              : ((pushConfig[item.name] = pushChannel[item.key]), (auditSourceInfo.pushConfig = pushConfig), setData(auditSourceInfo));
          });
        });
      }
      // util.get(api.trigger.mySurveyList, null, (res) => {
      //   const disturbRelateSurveyIds =
      //     auditSourceInfo.disturbConfig && auditSourceInfo.disturbConfig.inviteLimitRelateSurvey ? auditSourceInfo.disturbConfig.inviteLimitRelateSurvey : [];
      //   let disturbRelateSurveys = [];
      //   res &&
      //     res.forEach((item) => {
      //       disturbRelateSurveyIds.indexOf(item.id) > -1 && disturbRelateSurveys.push(item.surveyNameAndRemark);
      //       auditSourceInfo.surveyId == item.id && (auditSourceInfo.feedbackName = item.surveyNameAndRemark);
      //     });
      //   auditSourceInfo.disturbRelateSurveys = disturbRelateSurveys.join('; ');
      //   setData(auditSourceInfo);
      // });
    }
    setData(auditSourceInfo);
    setLoading(false);
  }

  const timeUnitConfig = {
    min: 'NumberofMinutes',
    hour: 'NumberofHours',
    day: 'NumberofDays',
    week: 'NumberofWeeks',
    month: 'NumberofMonths',
  };
  const keyObj = {
    unlimit: 'AllDay',
    custom: 'Periodoftime',
    min: 'NumberofMinutes',
    hour: 'NumberofHours',
    day: 'NumberofDays',
    week: 'NumberofWeeks',
    month: 'NumberofMonths',
    unlimit: 'Unlimit',
    system: 'Participatedinanyinvestigationofthesystem',
    survey: 'Participateinthisquestionnairesurvey',
    '=': '等于',
    '<': '小于',
    '>': '大于',
    '<=': '小于等于',
    '>=': '大于等于',
    '!=': '不等于',
    contain: '包含',
    '!contain': '不包含',
    startsWith: '开始于',
    endsWith: '结束于',
    year: '年',
    month: '月',
    day: '日',
    hour: '时',
    minute: '分',
    second: '秒',
    invited: 'Invited',
    opened: 'Opened',
    answered: 'Answered',
  };
  const hideDisturbChannel = ['被动调研（浮标）', '被动调研（链接）'];
  return loading ? (
    <Loading />
  ) : (
    <Row className="audit-detail">
      <Col span={24}>
        <Col span={8} className="title">
          调研名称：
        </Col>
        <Col span={16}>{data.name}</Col>
      </Col>
      <Col span={24}>
        <Col span={8} className="title">
          问卷：
        </Col>
        <Col span={16}>{data.surveySimple ? data.surveySimple.surveyNameAndRemark : data.surveyName}</Col>
      </Col>
      <Col span={24}>
        <Col span={8} className="title">
          调研方式：
        </Col>
        <Col span={16}>
          <Col span={24}>{data.displayChannelName}</Col>
          {data.pushConfig &&
            !hideDisturbChannel.includes(data.displayChannelName) &&
            Object.keys(data.pushConfig).map((key) => {
              return (
                <Fragment>
                  <Col span={18}>{data.pushConfig[key]}</Col>
                </Fragment>
              );
            })}
        </Col>
      </Col>
      {!hideDisturbChannel.includes(data.displayChannelName) && (
        <Fragment>
          <Col span={24}>
            <Col span={8} className="title">
              事件：
            </Col>
            <Col span={16}>
              <div>{data.eventName}</div>
              {data.eventConfigFilter.slice(1, data.eventConfigFilter.length).map((item) => {
                return (
                  <div className="info-content" key={JSON.stringify(item)}>
                    <div>{item.conditions[0].dim && eventParam[item.conditions[0].dim]}</div>
                    <div>{item.conditions[0].formula && keyObj[item.conditions[0].formula]}</div>
                    <div>{item.conditions[0].value}</div>
                    {item.conditions[0].dim.includes('_dt_period') && <div>{item.conditions[0].period && keyObj[item.conditions[0].period]}</div>}
                  </div>
                );
              })}
            </Col>
          </Col>
          {data.pushTime && (
            <Col span={24}>
              <Col span={8} className="title">
                触发时间：
              </Col>
              <Col span={16}>
                <div className="info-content">
                  {data.pushTime.inviteTimeRange && data.pushTime.inviteTimeRange !== 'unlimit'
                    ? data.pushTime.inviteTimeRange.replace(',', '至')
                    : intl.get('AllDay')}
                </div>
                <div className="info-content">
                  <div>{data.pushTime.inviteDelay === 'unlimit' ? intl.get('NotSpecifiedHoursAndMinutes') : intl.get('SpecifiedHoursAndMinutes')}</div>
                  {data.pushTime.inviteDelay !== 'unlimit' && (
                    <Fragment>
                      <div>
                        {intl.get(timeUnitConfig[data.pushTime.inviteDelay])}：{data.pushTime.inviteDelayVal}
                      </div>
                    </Fragment>
                  )}
                </div>
              </Col>
            </Col>
          )}
          {data.disturbConfig && (
            <Col span={24}>
              <Col span={8} className="title">
                触发防打扰机制：
              </Col>
              <Col span={16}>
                <div className="info-content">
                  {intl.get(keyObj[data.disturbConfig.inviteLimitSurveyAction])}
                  {data.disturbConfig.inviteLimitScope && intl.get(keyObj[data.disturbConfig.inviteLimitScope])}
                </div>
                {data.disturbRelateSurveys && data.disturbRelateSurveys.length > 0 && <div className="info-content">{data.disturbRelateSurveys}</div>}
                {data.disturbConfig.inviteLimitScope && data.disturbConfig.inviteLimitScope !== 'unlimit' && (
                  <Fragment>
                    <div className="info-content">
                      {data.disturbConfig.inviteLimitDays === 'unlimit' || !data.disturbConfig.inviteLimitDays
                        ? intl.get('Unlimit')
                        : `${data.disturbConfig.inviteLimitDays}天内不再触发`}
                    </div>
                    {data.disturbConfig.inviteLimitPushCount && (
                      <div className="info-content">
                        若不满足以上机制，{data.disturbConfig.inviteLimitDays}天内可重复邀请次数：{data.disturbConfig.inviteLimitPushCount}
                        {data.disturbConfig.inviteDateInterval && `，间隔${data.disturbConfig.inviteDateInterval}天`}
                      </div>
                    )}
                  </Fragment>
                )}
              </Col>
            </Col>
          )}
          {data.disturbConfig && (
            <Col span={24}>
              <Col span={8} className="title">
                消息有效期：
              </Col>
              <Col span={16}>
                {data.disturbConfig.msgValidDays && data.disturbConfig.msgValidDays === 'unlimit'
                  ? intl.get('Unlimit')
                  : data.disturbConfig.msgValidDays
                  ? `${data.disturbConfig.msgValidDays}天`
                  : ''}
              </Col>
            </Col>
          )}
          <Col span={24}>
            <Col span={8} className="title">
              回收权重（附加值）：
            </Col>
            <Col span={16}>{data.recoveryWeightAdd}</Col>
          </Col>
          {data.disturbConfig && (
            <Col span={24}>
              <Col span={8} className="title">
                重复答题：
              </Col>
              <Col span={16}>{data.disturbConfig.msgRepeatAnswer === false ? '不可重复' : '可重复'}</Col>
            </Col>
          )}
        </Fragment>
      )}
    </Row>
  );
}

export default Form.create()(AuditModal);
