import React from 'react';
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/index';
import filtersUtil from '../../utils/filtersUtil';
import { computed } from 'mobx';

class Main extends React.Component {
  // 外层表格添加的内容

  @computed get linkName() {
    let dims = this.props.dims;
    if (dims === 'page_group') {
      return {
        rowLink: 'pageGroupset',
        rowExpandedLink: 'pageGroup',
      };
    } else if (dims === 'pageset_visited' || dims === 'ppageset_entry') {
      return {
        rowLink: 'pageset',
        rowExpandedLink: 'page',
      };
    }
  }
  addContent = (store) => {
    store.columns.forEach((item, itemIndex) => {
      if (item.dataIndex === 'operate') {
        item.render = (text, record, index) => {
          return {
            children: (
              <div className="childrenContent">
                <div className="rateContent">
                  <div className="rateContent-title">
                    <Link
                      to={`/data/${this.linkName.rowLink}/detail?keyDim=${this.props.dims}&key=${encodeURIComponent(
                        store.parentData[index][`${this.props.dims}_key`]
                      )}&name=${encodeURIComponent(store.parentData[index][`${this.props.dims}_name`])}`}
                      title={`${store.parentData[index][`${this.props.dims}_name`]}的页面详情`}>
                      查看
                    </Link>
                  </div>
                </div>
              </div>
            ),
          };
        };
      } else if (item.dataIndex === 'isHasParam') {
        item.sorter = false;
        item.render = (text, record, index) => {
          if (text === 1) {
            return {
              children: <i className="iconfont icon-shujumokuai" title="此页面包含参数"></i>,
            };
          } else {
            return null;
          }
        };
      }
    });
  };
  // 嵌套表格的添加的内容
  expandedRowAddContent = (store) => {
    store.columns.forEach((item, itemIndex) => {
      if (item.dataIndex === 'operate') {
        item.render = (text, record, index) => {
          return {
            children: (
              <div className="childrenContent">
                <div className="rateContent">
                  <div className="rateContent-urlName">
                    <Link
                      to={`/data/${this.linkName.rowExpandedLink}/detail?keyDim=${filtersUtil.showFilterName(this.props.dims)}&key=${encodeURIComponent(
                        record[`${this.props.dims.replace('set', '')}_key`]
                      )}&name=${encodeURIComponent(store.parentData[index][`${filtersUtil.showFilterName(this.props.dims)}_name`])}`}
                      title={`${store.parentData[index][`${filtersUtil.showFilterName(this.props.dims)}_name`]}的页面详情`}>
                      查看
                    </Link>
                  </div>
                </div>
                <a style={{ marginLeft: 8 }} onClick={() => window.open(record[`${this.props.dims.replace('set', '')}_key`])}>
                  打开链接
                </a>
                {/* <div className="eventDetailIcon" title="打开链接">
                  <i className="iconfont icon-yemian_" onClick={() => window.open(`${text}`)} />
                </div> */}
              </div>
            ),
          };
        };
      }
      if (item.dataIndex === 'isHasParam') {
        item.sorter = false;
        item.render = (text, record, index) => {
          if (text) {
            return {
              children: <i className="iconfont icon-shujumokuai" title="此页面包含参数"></i>,
            };
          } else {
            return null;
          }
        };
      }
    });
  };

  render() {
    return (
      <CommonTable
        searchInside={true}
        addNumber={true}
        addContent={this.addContent}
        fields={this.props.fields}
        dims={this.props.dims}
        pageCount={this.props.pageCount}
        currentPage={this.props.currentPage}
        orderColumn={this.props.orderColumn}
        expandedRowRender={this.props.expandedRowRender}
        expandedRowAddContent={this.expandedRowAddContent}
        nullDataType={this.props.nullDataType}
        orderDesc={true}
        hasDetail={true}
        details={['/data/pageset/detail']}
      />
    );
  }
}

export default Main;
