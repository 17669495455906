import { action, observable, toJS } from 'mobx';
import util from '../../util';
import moment from 'moment';

class RemainFormStore {
  @observable form = {
    count: 0,
    ruleContent: [
      {
        rules: [
          {
            conditions: [
              {
                dim: 'event',
                type: 'default',
                dimValue: '访问',
                value: '访问',
                formula: '=',
              },
            ],
            filterObject: {
              logic: 'and',
              filters: [],
            },
          },
          {
            conditions: [
              {
                dim: 'event',
                type: 'default',
                dimValue: '访问',
                value: '访问',
                formula: '=',
              },
            ],
            filterObject: {
              logic: 'and',
              filters: [],
            },
          },
        ],
      },
    ],
    filterItemLogic: ['and', 'and'],
    childrenFilter: [[], []],
    dim: [''],
    dateObj: {
      lastDate: 'last7',
      date: `${moment()
        .subtract(8, 'days')
        .format('YYYY-MM-DD')},${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`,
    },
  };
  @observable id = null;
  @observable chartType = 1;
  @action.bound changeBeforeEvent(value, index) {
    this.form.ruleContent[0].rules[index].conditions[0] = { ...value, value: value.dimValue, formula: '=' };
    util.blockFetch();
    this.form.count++;
  }
  @action.bound changeFilterLogic(logic, index) {
    this.form.filterItemLogic[index] = logic;
    this.form.ruleContent[0].rules[index].filterObject['logic'] = logic;
    util.blockFetch();
    this.form.count++;
  }
  @action.bound setChildrenFilter(value, index) {
    this.form.childrenFilter[index] = value;
    this.form.ruleContent[0].rules[index].filterObject = {
      logic: this.form.filterItemLogic[index],
      filters: value,
    };
    util.blockFetch();
    this.form.count++;
  }
  @action.bound setDateObj(lastDate, date) {
    this.form = { ...this.form, dateObj: { lastDate, date } };
    this.form.count++;
  }
  @action.bound setSubDividedDim(value) {
    this.form.dim[0] = value[1];
    util.blockFetch();
    this.form.count++;
  }
  @action.bound init() {
    this.form.ruleContent = [
      {
        rules: [
          {
            conditions: [
              {
                dim: 'event',
                type: 'default',
                dimValue: '访问',
              },
            ],
            filterObject: {
              logic: 'and',
              filters: [],
            },
          },
          {
            conditions: [
              {
                dim: 'event',
                type: 'default',
                dimValue: '访问',
              },
            ],
            filterObject: {
              logic: 'and',
              filters: [],
            },
          },
        ],
      },
    ];
    this.form.filterItemLogic = ['and', 'and'];
    this.form.childrenFilter = [[], []];
  }
  @action.bound setId(id) {
    this.id = id;
    this.form.count++;
  }
  @action.bound setRuleContent(ruleContent) {
    this.form.ruleContent = ruleContent;
    toJS(this.form.ruleContent[0].rules).forEach((item, index) => {
      this.form.childrenFilter[index] = item['filterObject']['filters'];
      this.form.filterItemLogic[index] = item['filterObject']['logic'];
    });
  }
}
export default RemainFormStore;
