import VisitorLogTable from '../../containers/VistorLogTable';

export default {
  title: '访客日志',
  components: [
    {
      title: '访客日志',
      isCard: true,
      col: 24,
      component: VisitorLogTable,
    },
  ],
};
