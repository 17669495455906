import React, { Component } from 'react';
import { Tag } from 'antd';
import './index.sass';
export default class UXSTag extends Component {
  render() {
    return (
      <div className={`uxs-tag ${this.props.isWithShadow ? 'uxs-tag-withShadow' : ''}`} title={this.props.tagName}>
        <Tag
          visible={this.props.visible}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();

            this.props.closeFunc();
          }}
          onClick={(e) => {
            this.props.clickFunc();
          }}
          closable={this.props.closable}>
          <div className="uxs-tag-title">{this.props.tagName}</div>
        </Tag>
      </div>
    );
  }
}
