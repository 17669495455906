import React, { Fragment, useState, useEffect } from 'react';
import { Select, InputNumber, Input, Switch } from 'antd';
import { weekOption, numberFormulaList } from '../../../../config';

const { Option } = Select;

function Collection(props) {
  const { onChange, value, disabled, options } = props;

  function handleChange(key, changedValue) {
    if (onChange) {
      const data = {
        ...value,
        [key]: changedValue,
      };
      onChange(data);
    }
  }

  return (
    <div className="collection-form-item">
      <div className="form-row">
        <Select className="md-select" onChange={(value) => handleChange('limitDim', value)} value={value.limitDim} disabled={disabled}>
          {options.map((item, index) => {
            return (
              <Option key={index} value={item.column}>
                {item.columnName}
              </Option>
            );
          })}
        </Select>
        <div style={{ marginRight: '5px' }}>&#60;=</div>
        <InputNumber className="sm-input" min={1} onChange={(value) => handleChange('limitCount', value)} value={value.limitCount} disabled={disabled} />
      </div>
    </div>
  );
}

export default Collection;
