import theme from '../../../static/echart-config/normal';
import theme_sams from '../../../static/echart-config/sams';
import theme_ipsos from '../../../static/echart-config/ipsos';
import theme_rainbow from '../../../static/echart-config/rainbow';

export default function defualtTheme() {
  let userTheme;

  if (process.env.REACT_APP_BDkey === 'sams') {
    userTheme = theme_sams;
  } else if (process.env.REACT_APP_BDkey === 'ipsos') {
    userTheme = theme_ipsos;
  } else if (process.env.REACT_APP_BDkey === 'rainbow') {
    userTheme = theme_rainbow;
  } else {
    userTheme = theme;
  }
  return userTheme;
}
