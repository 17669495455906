/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import { decodeMetricName, getFormattedValue } from '../../util';
import { tooltipStyle } from '../../constants';
import defaultTheme from '../../Chart/echarts_theme';
import BoardStore from '../../../../views/BoardCustom/BoardChart/BoardStore';
const defaultThemeColors = defaultTheme().theme.color;

export default function(chartProps, drillOptions) {
  const { width, height, data, cols, metrics, chartStyles } = chartProps;

  const { axis, splitLine, gauge, grid } = chartStyles;

  const {
    radius,
    splitNumber,
    startAngle,
    endAngle,
    clockwise,
    prefix,
    suffix,
    showTitle,
    titleFontFamily,
    titleFontSize,
    titleColor,
    titleOffsetLeft,
    titleOffsetTop,
    showDetail,
    detailFontFamily,
    detailFontSize,
    detailColor,
    detailOffsetLeft,
    detailOffsetTop,
    showPointer,
    pointerLength,
    pointerWidth,
    customPointerColor,
    pointerColor,
    pointerBorderStyle,
    pointerBorderWidth,
    pointerBorderColor,
    axisLineSize,
    axisLineColor,
    showAxisTick,
    showAxisLabel,
    axisLabelDistance,
    axisLabelFontFamily,
    axisLabelFontSize,
    axisLabelColor,
    showSplitLine,
    splitLineLength,
    splitLineSize,
    splitLineStyle,
    splitLineColor,
    displayMetrics,
  } = gauge;
  const max = gauge.max || 100;

  let seriesObj = {};
  const seriesArr = [];

  const gaugeData = [];
  metrics.forEach((m, i) => {
    const decodedMetricName = decodeMetricName(m.name);
    const dataItem = {
      value: data.length ? data[0][m.randomName ? m.randomName : `${m.agg}(${decodedMetricName})`] : 0,
      name: m.field.alias || m.fieldDisplay || decodedMetricName,
    };
    if (displayMetrics) {
      const name = m.randomName || m.name;
      displayMetrics.includes(name) && gaugeData.push(dataItem);
    } else if (![1, 2].includes(i)) {
      gaugeData.push(dataItem);
    }
  });
  const allData = metrics.map((m) => {
    const decodedMetricName = decodeMetricName(m.name);
    return {
      value: data.length ? data[0][m.randomName ? m.randomName : `${m.agg}(${decodedMetricName})`] : 0,
      name: m.field.alias || m.fieldDisplay || decodedMetricName,
    };
  });

  function getDetailFormatter() {
    const formatterArray = [];
    const mainValue = getFormattedValue(Number(allData[0].value), metrics[0].format, null, null, true);
    const hasUnit = Array.isArray(mainValue);
    if (hasUnit) {
      formatterArray.push(`{mainText|${prefix}${mainValue[0]}${suffix}}{unitText|${mainValue[1]}}{mainText|${suffix}}`);
    } else {
      formatterArray.push(`{mainText|${prefix}${mainValue}${suffix}}`);
    }
    // 环比
    if (metrics[1]) {
      const ratio = getFormattedValue(Number(allData[1].value), {
        formatType: 'percentage',
        percentage: {
          decimalPlaces: 2,
        },
      });
      if (allData[1].value > 0) {
        formatterArray.push(`{descText|${metrics[1].field.alias || metrics[1].fieldDisplay || decodeMetricName(metrics[1].name)}}{riseText|${ratio}}`);
      } else if (allData[1].value == 0) {
        formatterArray.push(`{descText|${metrics[1].field.alias || metrics[1].fieldDisplay || decodeMetricName(metrics[1].name)}}{sameText|${ratio}}`);
      } else {
        formatterArray.push(`{descText|${metrics[1].field.alias || metrics[1].fieldDisplay || decodeMetricName(metrics[1].name)}}{downText|${ratio}}`);
      }
    }
    // 全国值
    if (metrics[2]) {
      formatterArray.push(`{descText|${metrics[2].field.alias || metrics[2].fieldDisplay || decodeMetricName(metrics[2].name)}}{descText|${allData[2].value}}`);
    }

    return formatterArray.join('\n');
  }

  const borderTypeObj = {
    solid: 'solid',
    dashed: [5, 5],
    dotted: [1, 1],
  };
  const bodyWidth = document.body.offsetWidth;
  seriesObj = {
    type: 'gauge',
    splitNumber,
    startAngle,
    endAngle,
    clockwise,
    radius: `${radius}%`,
    // startAngle: 180,
    // endAngle: 0,
    // radius: '140%',
    // center: ['50%', '75%'],
    progress: {
      show: true,
    },
    title: {
      show: showTitle,
      fontFamily: titleFontFamily,
      fontSize: titleFontSize,
      color: titleColor,
      offsetCenter: [titleOffsetLeft ? `${titleOffsetLeft}%` : 0, titleOffsetTop ? `${bodyWidth > 768 ? titleOffsetTop : -40}%` : 0],
    },
    detail: {
      show: showDetail,
      offsetCenter: [detailOffsetLeft ? `${detailOffsetLeft}%` : 0, detailOffsetTop ? `${detailOffsetTop}%` : 0],
      formatter: getDetailFormatter(),
      rich: {
        mainText: {
          fontFamily: detailFontFamily,
          fontSize: detailFontSize,
          color: BoardStore.boardStyle !== 'terse' ? '#D6D6D6' : detailColor,
        },
        unitText: {
          fontFamily: detailFontFamily,
          fontSize: 12,
          color: '#7a8493',
        },
        descText: {
          color: '#7A8493',
          fontSize: 12,
          lineHeight: 12,
          marginBottom: 8,
          padding: [0, 4, 0, 0],
        },
        riseText: {
          color: '#14c4a2',
          fontSize: 12,
        },
        sameText: {
          color: '#7A8493',
          fontSize: 12,
        },
        downText: {
          color: '#FF6B5C',
          fontSize: 12,
        },
      },
      // formatter: (value) => `${prefix}${getFormattedValue(Number(value) * 100 / max, m.format)}${suffix}`,
      // formatter: (value) =>
      //   `${prefix}${getFormattedValue(Number(value), m.format)}${suffix}`
      // rich: {},
      // width: 240,
      // height: 240,
      // borderRadius: 120,
      // lineHeight: 240,
      // backgroundColor: '#05354a'
    },
    // // animationDuration: 1000,
    // // animationDurationUpdate: 1000,
    data: gaugeData,
    // axisLine: {
    //   lineStyle: {
    //     width: axisLineSize,
    //     color: [
    //       [data.length ? data[0][m.randomName || `${m.agg}(${decodedMetricName})`] / max : 0, axisLineColor],
    //       [1, '#ddd']
    //     ]
    //   }
    // },
    // axisTick: {
    //   show: showAxisTick
    // },
    axisLabel: {
      show: showAxisLabel,
      distance: axisLabelDistance,
      fontSize: axisLabelFontSize,
      fontFamily: axisLabelFontFamily,
      color: axisLabelColor,
      formatter: (value) => `${getFormattedValue(Number(value), metrics[0].format, true, true)}`,
    },
    // splitLine: {
    //   show: showSplitLine,
    //   length: splitLineLength,
    //   lineStyle: {
    //     color: splitLineColor,
    //     width: splitLineSize,
    //     type: splitLineStyle
    //   }
    // },
    progress: {
      show: true,
      overlap: true,
      roundCap: true,
      // itemStyle: {
      //   color: defaultThemeColors[0],
      // },
    },
    pointer: {
      show: showPointer,
      length: pointerLength ? `${pointerLength}%` : 0,
      width: pointerWidth,
      itemStyle: {
        // color: customPointerColor ? pointerColor : defaultThemeColors[0],
        borderType: borderTypeObj[pointerBorderStyle],
        borderWidth: pointerBorderWidth,
        borderColor: pointerBorderColor,
      },
    },
  };
  max > 0 ? (seriesObj.max = max) : (seriesObj = { ...seriesObj, max: 0, min: max });
  seriesArr.push(seriesObj);

  const tooltip = {
    trigger: 'item',
    confine: 'true',
    extraCssText: `${document.body.offsetWidth < 768 ? 'white-space: pre-wrap; width: 200px !important' : ''}`,
    formatter: '{b}: {c}',
    ...tooltipStyle,
  };
  return {
    tooltip,
    series: seriesArr,
    grid: {
      show: true,
      borderColor: 'transparent',
      backgroundColor: grid ? grid.backgroundColor : 'transparent',
      bottom: 32,
    },
  };
}
