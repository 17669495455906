import React, { Component } from 'react';
import { Popover } from 'antd';
import { SketchPicker } from 'react-color';

import './index.sass';

export default class index extends Component {
  render() {
    const { disabled } = this.props;
    const content = (
      <SketchPicker
        presetColors={[
          '#06C13D',
          '#FF8B01',
          '#4554DC',
          '#00B0E9',
          '#EE369E',
          '#785BF6',
          '#E23C4D',
          '#14C4A2',
          '#E5F9EB',
          '#FFF3E5',
          '#ECEDFC',
          '#E5F7FD',
          '#FEEBF6',
          '#F1EEFE',
          '#FCEBED',
          '#E7E7E7',
          '#BABABA',
          '#919191',
          '#FFFFFF',
        ]}
        onChange={this.props.onChange}
        color={this.props.value}></SketchPicker>
    );
    return (
      <Popover
        overlayClassName="ColorSelectorPopover"
        placement={this.props.placement}
        content={content}
        trigger={disabled ? [] : 'click'}
        style={{ padding: '0' }}>
        <div
          className={this.props.className}
          style={{
            display: 'inline-block',
            marginLeft: '8px',
            width: '16px',
            height: '16px',
            backgroundColor: `rgba(${this.props.value.r}, ${this.props.value.g}, ${this.props.value.b}, ${this.props.value.a})`,
            // backgroundColor: `${this.props.value}`
          }}></div>
      </Popover>
    );
  }
}
