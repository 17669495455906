// antd-mobile Popover和picker组件组合使用时会出现选择器无法弹出的bug 故暂用pc端下拉菜单代替
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Popover } from 'antd-mobile';
import MobilePicker from '../../component/MobilePicker/index';
import pageHeaderStore from '../PageHeader/PageHeaderStore';
import './index.sass';
import manageUtil from '../../Product/Management/util';
import { Menu, Dropdown } from 'antd';

@withRouter
@observer
class MobilePopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  changeVisible = () => {
    this.setState({ visible: !this.state.visible });
  };
  render() {
    const pathName = this.props.location.pathname;
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <MobilePicker
            data={pageHeaderStore.dropdownList.product}
            iconName="icon-qiehuan"
            name="切换产品"
            onOk={(key) => {
              manageUtil.changeLinkPath(this.props.history, key);
            }}
            justifyContent={'center'}
            style={{ height: 39, width: '100%', marginLeft: -2 + 'px', color: '#7A8493' }}></MobilePicker>
        </Menu.Item>
        <Menu.Item key="1">
          <NavLink to="/product">
            <i className={`iconfont icon-shezhi1 lg-icon ${pathName.indexOf('/product') === 0 ? 'active' : ''}`} />
            设置
          </NavLink>
        </Menu.Item>
        {process.env.REACT_APP_Docs_Link && (
          <Menu.Item key="3">
            <a href={process.env.REACT_APP_Docs_Link}>
              <i className={`iconfont icon-bangzhu1 lg-icon `} />
              帮助
            </a>
          </Menu.Item>
        )}
        <Menu.Item key="3">
          <NavLink to="/person/workbench">
            <i className={`iconfont icon-gerenzhongxin1 lg-icon ${pathName.indexOf('/person/workbench') === 0 ? 'active' : ''}`} />
            个人中心
          </NavLink>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={['click']} overlayClassName="navbar-dropdown">
        {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> */}
        <div
          onTouchStart={this.changeVisible}
          style={{
            cursor: 'pointer',
            height: '100%',
          }}>
          ...
        </div>
        {/* </a> */}
      </Dropdown>
      // <Popover
      //   destroyTooltipOnHide={true}
      //   visible={this.state.visible}
      //   overlay={[
      //     <Popover.Item key="3" value="scan" data-seed="logId">
      //       <a>
      //         <MobilePicker
      //           data={pageHeaderStore.dropdownList.product}
      //           iconName="icon-qiehuan"
      //           name="切换产品"
      //           onOk={(key) => {
      //             manageUtil.changeLinkPath(this.props.history, key);
      //           }}
      //           justifyContent={'center'}
      //           style={{ height: 39, width: '100%', marginLeft: -2 + 'px', color: '#7A8493' }}></MobilePicker>
      //       </a>
      //     </Popover.Item>,
      //     <Popover.Item key="4" value="scan" data-seed="logId">
      //       <NavLink to="/product">
      //         <i className={`iconfont icon-shezhi1 lg-icon ${pathName.indexOf('/product') === 0 ? 'active' : ''}`} />
      //         设置
      //       </NavLink>
      //     </Popover.Item>,
      //     <React.Fragment>
      //       {process.env.REACT_APP_BDkey === null ? (
      //         <Popover.Item key="5" value="special" style={{ whiteSpace: 'nowrap' }}>
      //           <a href={`/help/`}>
      //             <i className={`iconfont icon-bangzhu1 lg-icon `} />
      //             帮助
      //           </a>
      //         </Popover.Item>
      //       ) : null}
      //     </React.Fragment>,
      //     <Popover.Item key="6" value="button ct">
      //       <span style={{ marginRight: 5 }}>
      //         <NavLink to="/person">
      //           <i className={`iconfont icon-gerenzhongxin1 lg-icon ${pathName.indexOf('/person') === 0 ? 'active' : ''}`} />
      //           个人中心
      //         </NavLink>
      //       </span>
      //     </Popover.Item>,
      //   ]}
      //   align={{
      //     overflow: { adjustY: 0, adjustX: 0 },
      //     offset: [-10, 0],
      //   }}
      //   onSelect={() => this.setState({ visible: false })}>
      //   <div
      //     onTouchStart={this.changeVisible}
      //     style={{
      //       cursor: 'pointer',
      //       height: '100%',
      //     }}>
      //     ...
      //   </div>
      // </Popover>
    );
  }
}
export default MobilePopover;
