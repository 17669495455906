import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/index';
import './index.sass';

@observer
class VistorLogTable extends React.Component {
  addContent = (store) => {
    for (let i = 0; i < store.columns.length; i++) {
      let currentRowData = store.columns[i].dataIndex;
      if (currentRowData === 'operate') {
        store.columns[i].render = (text, record, index) => {
          return {
            children: (
              <Link key={index} to={`/data/visitor/record/info?visitorId=${record.visitor_key}`} title={record.visitor_key}>
                查看
              </Link>
            ),
          };
        };
      }
    }
  };

  setSearchParam = (param) => {
    const value = JSON.parse(param.filters)[0].value;
    param.filters = JSON.stringify([
      {
        dim: 'visitor',
        target: 'visitorId',
        formula: '=',
        value,
      },
    ]);
    return param;
  };
  render() {
    return (
      <CommonTable
        addContent={this.addContent}
        dims="visitor"
        fields="visitorRefererType,visitorCity,visitorVisitAvgTime,visitorPageViews,visitorVisits,visitorVisitTime,operate"
        currentPage={1}
        pageCount={10}
        orderColumn={'visitorVisitTime'}
        orderDesc={true}
        isSort={false}
        hasDetail={true}
        details={['/data/visitor/record/info']}
        setSearchParam={this.setSearchParam}
        searchClassName="paddingTop"
        searchInside></CommonTable>
    );
  }
}

export default VistorLogTable;
