import React, { FC, forwardRef, useEffect, useRef } from 'react';
import { Input } from 'antd';
import './Panel/Layouts/Layouts.less';
import intl from 'react-intl-universal';
import { getTextWidth } from '../../../../containers/BIChart/util';
const Search = Input.Search;

const InputText = ({ control, value, size, onChange, onSearch, isPageHeader }, ref) => {
  const content = useRef();
  useEffect(() => {
    if (content.current && isPageHeader) {
      const titleWidth = getTextWidth(control.name);
      content.current.getElementsByClassName('ant-input')[0].style.paddingLeft = `${titleWidth + 16}px`;
      content.current.getElementsByClassName('ant-input')[0].style.paddingRight = '33px';
    }
  }, [content, control]);
  return (
    <div className="inputText" ref={content}>
      <Search placeholder="请输入" value={value} {...(size && { size })} onChange={onChange} {...(onSearch && { onSearch })} ref={ref} />
    </div>
  );
};

export default forwardRef(InputText);
