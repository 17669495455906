/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React, { Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import { Table as AntTable, Tooltip, Icon, Radio, Dropdown } from 'antd';
import PaginationWithoutTotal from './PaginationWithoutTotal';
import { ViewModelTypes, TABLE_PAGE_SIZES, SortTypesToOrder } from '../constants';
import { MapAntSortOrder } from './constants';
import {
  getFieldAlias,
  decodeMetricName,
  hasProperty,
  findChildConfig,
  traverseConfig,
  getTableCellValueRange,
  getCellSpanMap,
  computeCellWidth,
  getDataColumnWidth,
  randomNameToFieldName,
} from '../util';
import { getCartesianChartMetrics } from '../render/chart/util';
import { tableComponents } from '../components/tableComponents';
import { resizeTableColumns } from '../components/HeadCell';
import Loading from '../../../component/Loading/Loading';
import TableNullData from '../../../component/TableNullData';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import BIStore from '../store';
import util from '../../../util';
import MobileContentTable from './MobileContentTable';

const bodyWidth = document.body.offsetWidth;
const filterPageSize = bodyWidth > 768 ? 10 : 5; //  筛选按钮每次加载条目数
export class Table extends React.PureComponent {
  constructor() {
    super();
  }
  static HeaderSorterWidth = 0;

  state = {
    chartStyles: null,
    data: null,
    width: 0,
    pagination: null,
    currentSorter: null,

    tableColumns: [],
    mapTableHeaderConfig: {},
    containerWidthRatio: 1,
    tablePagination: {
      current: void 0,
      pageSize: void 0,
      simple: false,
      total: void 0,
    },
    tableBodyHeight: 0,
    selectedRow: [],
    selectItems: {
      group: [],
      cell: {},
    },
    filterList: {}, //标签列表
    filterNums: {}, //当前展示多少个标签
    filterValues: {}, //当前选中的标签
  };

  table = React.createRef();
  textTitle = React.createRef();
  pagination = React.createRef();

  handleResize = (idx, containerWidthRatio) => (_, { size }) => {
    const nextColumns = resizeTableColumns(this.state.tableColumns, idx, size.width, containerWidthRatio);
    this.setState({ tableColumns: nextColumns });
  };

  tableChange = (pagination, _, sorter) => {
    const nextCurrentSorter = sorter.field ? { column: sorter.field, direction: MapAntSortOrder[sorter.order] } : null;
    this.setState({ currentSorter: nextCurrentSorter });
    const { current, pageSize } = pagination;
    this.refreshTable(current || 1, pageSize || 10, nextCurrentSorter);
  };

  refreshTable = (current, pageSize, sorter) => {
    const { tablePagination } = this.state;
    if (pageSize !== tablePagination.pageSize) {
      current = 1;
    }
    const { onPaginationChange } = this.props;
    onPaginationChange(current, pageSize, sorter);
  };

  basePagination = {
    pageSizeOptions: TABLE_PAGE_SIZES.map((s) => s.toString()),
    showSizeChanger: true,
    size: 'small',
  };

  componentDidMount() {
    const { headerFixed, withPaging } = this.props.chartStyles.table;
    this.adjustTableCell(headerFixed, withPaging);
    this.setFilterList();
  }

  componentDidUpdate(preProps) {
    const { headerFixed, withPaging } = this.props.chartStyles.table;
    const { preHeaderFixed, preWithPaging } = preProps.chartStyles.table;
    if (headerFixed !== preHeaderFixed || withPaging !== preWithPaging) {
      this.adjustTableCell(headerFixed, withPaging, this.state.tablePagination.total);
    }
    if (JSON.stringify(preProps.NLPData) !== JSON.stringify(this.props.NLPData)) {
      this.setFilterList();
    }
    if (this.props.isLoading === true && this.props.isLoading !== preProps.isLoading) {
      // 下钻后取消选中项
      this.setState({
        selectItems: {
          group: [],
          cell: {},
        },
      });
    }
  }

  adjustTableCell(headerFixed, withPaging, dataTotal) {
    const tableDom = findDOMNode(this.table.current);
    const excludeElems = [];
    let paginationMargin = 0;
    let paginationWithoutTotalHeight = 0;
    if (headerFixed) {
      excludeElems.push('.ant-table-thead');
    }
    if (withPaging) {
      excludeElems.push('.ant-pagination.ant-table-pagination');
      paginationMargin = 4;

      if (dataTotal === -1) {
        paginationWithoutTotalHeight = 40;
      }
    }
    if (tableDom) {
      // 设置表格高度
      // 顶部筛选栏高度
      let textTitleHeight = 0;
      if (this.textTitle && this.textTitle.current) {
        const { clientHeight } = this.textTitle.current;
        textTitleHeight = clientHeight;
      }
      // 20 文本的margin  ， 4 表格的padding
      const trueHeight = this.props.height - textTitleHeight - 20 - 4;
      // let tableBodyHeight = this.props.height - paginationWithoutTotalHeight - textTitleHeight - 30;
      let tableBodyHeight = bodyWidth > 768 ? trueHeight : this.props.height;
      this.setState({
        tableBodyHeight,
      });
    }
  }

  setFilterList() {
    const { NLPData, dynamicFilters } = this.props;
    const dimensions = dynamicFilters;
    // let {  filterNums, filterValues} = this.state;
    let filterNums = this.state.filterNums;
    let filterValues = this.state.filterValues;
    let filterList = {};
    dimensions.forEach((item, index) => {
      let name = item.randomName || item.name;
      (filterNums[name] = filterNums[name] || filterPageSize),
        (filterValues[name] = filterValues[name] || ''),
        (filterList[name] = filterList[name] || {
          fieldName: item.name,
          name,
          fieldDisplay: item.fieldDisplay,
          sqlType: item.sqlType,
          field: item.field,
          data: {},
        });
      NLPData[name] &&
        ((filterList[name].totalCount = NLPData[name].totalCount),
        NLPData[name].data.forEach((i) => {
          // 有些数据为空
          if (i[name] || i[name] === '') {
            filterList[name].data[i[name]] = i['uniq(answer_id)'];
          }
        }));
    });
    this.setState({
      filterList: { ...filterList },
      filterNums: { ...filterNums },
      filterValues: { ...filterValues },
    });
  }

  setFilterValues(value, checked, key) {
    let filterValues = this.state.filterValues;
    checked ? (filterValues[key] = value) : (filterValues[key] = '');
    this.setState({
      filterValues: { ...filterValues },
    });
    let extraFilters = [];
    Object.keys(filterValues).forEach((key) => {
      filterValues[key] && extraFilters.push(JSON.parse(filterValues[key]));
    });
    this.props.setExtraFilters({ [this.props.id]: extraFilters });
  }

  dataFilter(item) {
    let flag = true;
    Object.keys(this.state.filterValues).forEach((key) => {
      this.state.filterValues[key] !== '' && item[key] !== this.state.filterValues[key] && (flag = false);
    });
    return flag;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { chartStyles, data, width } = nextProps;
    if (chartStyles !== prevState.chartStyles || data !== prevState.data || width !== prevState.width) {
      const { tableColumns, mapTableHeaderConfig, containerWidthRatio } = getTableColumns(nextProps);
      const tablePagination = getPaginationOptions(nextProps);
      return {
        tableColumns,
        mapTableHeaderConfig,
        containerWidthRatio,
        tablePagination,
        chartStyles,
        data,
        width,
      };
    }

    return { chartStyles, data, width };
  }

  adjustTableColumns(tableColumns, mapTableHeaderConfig, containerWidthRatio) {
    traverseConfig(tableColumns, 'children', (column, idx, siblings) => {
      const canResize = siblings === tableColumns;
      column.onHeaderCell = (col) => ({
        width: col.width,
        onResize: canResize && this.handleResize(idx, containerWidthRatio),
        config: mapTableHeaderConfig[column.key],
      });
    });
    return tableColumns;
  }

  getRowKey = (_, idx) => idx.toString();

  getTableScroll(columns, containerWidth, headerFixed, tableBodyHeight) {
    const scroll = {};
    if (bodyWidth < 768) {
      scroll.x = '100%';
      return scroll;
    }
    const columnsTotalWidth = columns.reduce((acc, c) => acc + c.width, 0);
    let textTitleHeight = 68;
    if (this.textTitle && this.textTitle.current) {
      const { clientHeight } = this.textTitle.current;
      textTitleHeight = clientHeight;
    }
    if (columnsTotalWidth > containerWidth) {
      scroll.x = Math.max(columnsTotalWidth, containerWidth);
    }
    if (headerFixed) {
      scroll.y = tableBodyHeight - 85;
      if (bodyWidth < 768) {
        scroll.y = scroll.y + 100;
      }
    }
    return scroll;
  }

  isSameObj(prevObj, nextObj, isSourceData) {
    let isb = void 0;
    const clonePrevObj = { ...prevObj };
    const cloneNextObj = { ...nextObj };
    if (isSourceData === true) {
      delete clonePrevObj['key'];
      delete clonePrevObj['value'];
      delete cloneNextObj['key'];
      delete cloneNextObj['value'];
    }
    for (const attr in clonePrevObj) {
      if (clonePrevObj[attr] !== undefined && clonePrevObj[attr] === cloneNextObj[attr]) {
        isb = true;
      } else {
        isb = false;
        break;
      }
    }
    return isb;
  }

  matchAttrInBrackets(attr) {
    const re = /\(\S+\)/;
    const key = re.test(attr) ? attr.match(/\((\S+)\)/)[1] : attr;
    return key;
  }

  rowClick = (record, row, event) => {
    const { id, cols, rows, metrics } = this.props;
    let selectedRow = [...this.state.selectedRow];
    let filterObj = void 0;
    if (event.target && event.target.innerHTML) {
      for (const attr in record) {
        if (record[attr].toString() === event.target.innerText) {
          const key = this.matchAttrInBrackets(attr);
          filterObj = {
            key,
            value: event.target.innerText,
          };
        }
      }
    }
    const recordConcatFilter = {
      ...record,
      ...filterObj,
    };
    const isInteractiveChart = PageHeaderStore.checkLinkageConfig(id);
    if (isInteractiveChart) {
      selectedRow = [recordConcatFilter];
    } else {
      if (selectedRow.length === 0) {
        selectedRow.push(recordConcatFilter);
      } else {
        const isb = selectedRow.some((sr) => this.isSameObj(sr, recordConcatFilter, true));
        if (isb) {
          for (let index = 0, l = selectedRow.length; index < l; index++) {
            if (this.isSameObj(selectedRow[index], recordConcatFilter, true)) {
              selectedRow.splice(index, 1);
              break;
            }
          }
        } else {
          selectedRow.push(recordConcatFilter);
        }
      }
    }
    // 替换selectedRow中的randomKey
    const randomNames = {};
    cols.concat(rows, metrics).forEach((item) => {
      item.randomName && (randomNames[item.randomName] = item.name);
    });
    selectedRow.forEach((item) => {
      for (let key in item) {
        randomNames[key] && (item[randomNames[key]] = item[key]);
      }
    });
    this.setState(
      {
        selectedRow,
      },
      () => {
        const sourceData = Object.values(this.state.selectedRow);
        if (PageHeaderStore.checkLinkageConfig(id, BIStore.isLinkingWidget[id] ? [] : sourceData) && !BIStore.isLinkingWidget[id]) {
          const triggerData = sourceData;
          PageHeaderStore.setCurrentLinkager(id, triggerData);
          BIStore.setIsLinkingWidget(id, true);
        }
      }
    );
  };

  asyncEmitDrillDetail() {
    const { getDataDrillDetail } = this.props;
    setTimeout(() => {
      if (this.props.getDataDrillDetail) {
        const sourceData = this.combineFilter();
        const sourceGroup = this.combineGroups();
        const brushed = [{ 0: Object.values(sourceData) }];
        getDataDrillDetail(
          JSON.stringify({
            filterObj: sourceData,
            brushed,
            sourceData,
            sourceGroup,
          })
        );
      }
    }, 500);
  }

  combineGroups() {
    const { group } = this.state.selectItems;
    return group;
  }

  combineFilter() {
    const { cell } = this.state.selectItems;
    return Object.keys(cell).reduce((iteratee, target) => {
      const data = cell[target].map((item) => ({
        ...item,
        dataKey: target,
      }));
      iteratee = iteratee.concat(data);
      return iteratee;
    }, []);
  }

  getTableStyle(headerFixed, tableBodyHeight) {
    const tableStyle = {};
    if (!headerFixed || bodyWidth < 768) {
      // tableStyle.height = bodyWidth > 768 ? tableBodyHeight : '100%';
      tableStyle.height = '100%';
      tableStyle.overflowY = 'auto';
      tableStyle.overflowX = 'auto';
    }
    return tableStyle;
  }

  getNullStyle(tableBodyHeight) {
    const tableStyle = {};
    tableStyle.height = bodyWidth < 768 ? '100px' : tableBodyHeight;
    return tableStyle;
  }

  filterSameNeighbourSibings = (arr, targetIndex) => {
    let s = targetIndex;
    let e = targetIndex;
    let flag = -1;
    const orgIndex = targetIndex;

    do {
      const target = arr[targetIndex];
      if (flag === -1 && targetIndex > 0 && arr[targetIndex - 1] === target) {
        s = targetIndex -= 1;
      } else if (flag === 1 && arr[targetIndex + 1] === target) {
        e = targetIndex += 1;
      } else if (flag === -1) {
        flag = 1;
        targetIndex = orgIndex;
      } else {
        break;
      }
    } while (targetIndex > -1 && targetIndex < arr.length);
    return { s, e };
  };

  coustomFilter(array, column, index) {
    const nativeIndex = array.reduce((a, b, c) => (b.index === index ? c : a), 0);
    const columns = array.map((a) => a[column]);
    const { s: start, e: end } = this.filterSameNeighbourSibings(columns, nativeIndex);
    return array.filter((arr) => (array[start] && arr['index'] < array[start]['index']) || (array[end] && arr.index > array[end]['index']));
  }

  collectCell = (target, index, dataIndex) => (event) => {
    const { group, cell } = this.state.selectItems;
    const { data, cols, rows, metrics, id } = this.props;
    const groupName = randomNameToFieldName(cols.concat(rows, metrics), this.matchAttrInBrackets(dataIndex));

    if (this.isValueModelType(groupName)) {
      return;
    }

    if (group.includes(dataIndex)) {
      group.forEach((g, i) => (g === dataIndex ? group.splice(i, 1) : void 0));
      const setKeyArray = data.map((obj, index) => ({
        ...obj,
        index: obj.key || index,
        key: groupName,
        value: obj[dataIndex],
      }));
      cell[dataIndex] = this.coustomFilter(setKeyArray, groupName, index);
    } else {
      const sourceCol = cell[dataIndex];
      const currentValue = {
        ...target,
        index,
        key: groupName,
        value: target[dataIndex],
      };

      if (sourceCol && sourceCol.length) {
        const isb = sourceCol.some((col) => col.index === index);
        if (isb) {
          cell[dataIndex] = this.coustomFilter(cell[dataIndex], groupName, index);
        } else {
          sourceCol.push(currentValue);
        }
      } else {
        cell[dataIndex] = [currentValue];
      }
    }

    this.setState(
      {
        selectItems: { ...this.state.selectItems },
      },
      () => {
        this.asyncEmitDrillDetail();
      }
    );

    // 表格联动逻辑重构
    const linkageTriggerList = PageHeaderStore.getLinkageTriggerList(id);
    const triggerData = {};
    // 是否设置了联动触发器
    if (linkageTriggerList.length) {
      const fieldsItem = {};
      cols.concat(rows, metrics).forEach((item) => {
        fieldsItem[item.randomName || item.name] = item;
      });
      for (const key in cell) {
        if (cell[key].length) {
          const name = fieldsItem[key].name;
          if (linkageTriggerList.includes(name)) {
            // 若当前列触发了联动
            triggerData[name] = cell[key];
          }
        }
      }
      if (JSON.stringify(triggerData) != '{}') {
        PageHeaderStore.setCurrentLinkager(id, triggerData, true);
        BIStore.setIsLinkingWidget(id, true);
      } else if (BIStore.isLinkingWidget) {
        PageHeaderStore.cleanCurrentLinkager(id);
        BIStore.setIsLinkingWidget(id, false);
      }
    }
  };

  collectGroups = (target, dataIndex) => (event) => {
    const groupName = this.matchAttrInBrackets(dataIndex);
    if (this.isValueModelType(groupName)) {
      return;
    }
    const { group, cell } = this.state.selectItems;
    if (group.includes(dataIndex)) {
      group.forEach((a, index) => {
        if (a === dataIndex) {
          group.splice(index, 1);
        }
      });
    } else {
      group.push(dataIndex);
    }
    delete cell[dataIndex];
    this.setState(
      {
        selectItems: { ...this.state.selectItems },
      },
      () => {
        this.asyncEmitDrillDetail();
      }
    );
  };

  onCellClassName = (target, index, dataIndex) => {
    const { group, cell } = this.state.selectItems;
    let result = '';
    Object.keys(cell).forEach((key) => {
      if (dataIndex === key) {
        cell[key].forEach((ck) => {
          if (index === ck.index) {
            result = 'select';
          }
        });
      }
    });
    if (group && group.includes(dataIndex)) {
      result = 'select';
    }
    return result;
  };

  isValueModelType = (modelName) => {
    const target = this.getModelTypecollectByModel();
    const result = hasProperty(target, modelName);
    return typeof result !== 'boolean' ? result === ViewModelTypes.Value : false;
  };

  getModelTypecollectByModel = () => {
    const { model } = this.props;
    return Object.keys(model).reduce((iteratee, target) => {
      iteratee[target] = hasProperty(model[target], 'modelType');
      return iteratee;
    }, {});
  };

  onHeadCellClassName = (target, dataIndex) => {
    const { group } = this.state.selectItems;
    if (group && group.includes(dataIndex)) {
      return 'select';
    }
    return '';
  };

  loop = (col, i) => {
    const { chartStyles } = this.props;
    const { withSerial } = chartStyles.table;
    const firstIndex = withSerial || typeof withSerial === 'undefined' ? 1 : 0;
    if (col && col.dataIndex) {
      return {
        ...col,
        onHeaderCell: (target) => {
          return {
            ...col.onHeaderCell(target),
            isFirstColumn: i === firstIndex ? true : false,
            // className: this.onHeadCellClassName(target, col.dataIndex),
            // onClick: this.collectGroups(target, col.dataIndex),
          };
        },
        onCell: (target, index) => {
          // fix index in pagination
          const cellClass = { ...col.onCell(target, index) };
          if (cellClass.className) {
            cellClass.className = cellClass.className + ' ' + this.onCellClassName(target, index, col.dataIndex);
          } else {
            cellClass.className = this.onCellClassName(target, index, col.dataIndex);
          }
          return {
            ...cellClass,
            onClick: this.collectCell(target, index, col.dataIndex),
            isFirstColumn: i === firstIndex ? true : false,
          };
        },
      };
    } else {
      return { ...col };
    }
  };

  resetWidth = (column) => {
    const columnsTotalWidth = column.reduce((acc, c) => acc + c.width, 0);
    const width = this.props.width;
    if (columnsTotalWidth <= width) {
      return column.map((col) => {
        if (col.fixed) {
          col.fixed = undefined;
        }
        return col;
      });
    } else {
      return column;
    }
  };

  enhancerColumns = (column) => {
    // 判断是否需要固定列， 当宽度不足时 没必要固定的操作，因为固定列的原理是重复渲染
    column = this.resetWidth(column);
    // 移动端长度限制
    column.forEach((i) => util.isMobile() && i.width > 200 && (i.width = 200));
    const columns = column.map((col, index) => {
      if (col.children && col.children.length) {
        return {
          ...this.loop(col, index),
          children: this.enhancerColumns(col.children),
        };
      }
      return this.loop(col, index);
    });
    return columns;
  };

  getEnhancerColumn = (column) => {
    return this.enhancerColumns(column);
  };

  loadMore = (name) => {
    const num = this.state.filterNums[name] + filterPageSize;
    this.setState({
      filterNums: { ...this.state.filterNums, [name]: num },
    });
    this.props.getNLPData(null, name, num);
  };

  render() {
    const { data, chartStyles, queryVariables, isLoading, width, dataDrillPanel, widgetContentRef, limit } = this.props;
    const { headerFixed, bordered, withPaging, size, showCount } = chartStyles.table;
    const { tablePagination, tableColumns, tableBodyHeight, mapTableHeaderConfig, containerWidthRatio, filterList, filterNums, filterValues } = this.state;
    const adjustedTableColumns = this.adjustTableColumns(tableColumns, mapTableHeaderConfig, containerWidthRatio);
    const getEnhancerColumn = this.getEnhancerColumn(adjustedTableColumns);
    const paginationConfig = {
      ...this.basePagination,
      ...tablePagination,
    };
    const scroll = this.getTableScroll(adjustedTableColumns, width, headerFixed, tableBodyHeight);
    const style = this.getTableStyle(null, tableBodyHeight);

    const paginationWithoutTotal =
      withPaging && tablePagination.total === -1 ? (
        <PaginationWithoutTotal ref={this.pagination} dataLength={data.length} size={'small'} {...paginationConfig} />
      ) : null;
    let dataSource = data;
    if (dataSource && limit) {
      dataSource = dataSource.slice(0, limit);
    }
    return (
      <div className="text-table">
        <div ref={this.textTitle}>
          {Object.entries(filterList).map(([key, item], index) => {
            let i = -1;
            return (
              item && (
                <div className="bi-table-filter">
                  <Radio.Group className={['sentiment', `sentiment-${index}`]} size="small" value={filterValues[item.name] || ''}>
                    <div className="sentimentTitle">{getFieldAlias(item.field, queryVariables || {}) || item.fieldDisplay || item.name}</div>
                    <Radio.Button value="" onClick={(e) => this.setFilterValues('', e.target.checked, item.name)}>
                      全部
                    </Radio.Button>
                    {Object.keys(item.data).map((e) => {
                      i++;
                      return (
                        i < (filterNums[item.name] || filterPageSize) && (
                          <Radio.Button
                            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            value={JSON.stringify({
                              name: item.name,
                              type: 'filter',
                              operator: '=',
                              sqlType: item.sqlType,
                              value: [`'${e}'`],
                              fieldName: item.fieldName,
                            })}
                            key={e}
                            onClick={(e) => this.setFilterValues(e.target.value, e.target.checked, item.name)}>
                            {e || '-'}
                            {showCount ? `(${item.data[e]})` : null}
                          </Radio.Button>
                        )
                      );
                    })}
                    {!!item.totalCount && item.totalCount > filterPageSize && filterNums[item.name] < item.totalCount && (
                      <a
                        onClick={() => {
                          this.loadMore(item.name);
                        }}>
                        更多
                      </a>
                    )}
                    {!!item.totalCount && item.totalCount > filterPageSize && filterNums[item.name] >= item.totalCount && (
                      <a
                        onClick={() => {
                          this.setState({
                            filterNums: {
                              ...filterNums,
                              [item.name]: filterPageSize,
                            },
                          });
                        }}>
                        收起
                      </a>
                    )}
                  </Radio.Group>
                </div>
              )
            );
          })}
        </div>
        {bodyWidth < 768 ? (
          <MobileContentTable {...this.props} dataSource={dataSource} />
        ) : (
          <ConfigProvider locale={zh_CN}>
            <Dropdown
              overlayClassName="drill-dropdown"
              getPopupContainer={() => widgetContentRef}
              overlay={dataDrillPanel}
              placement="topCenter"
              trigger={['contextMenu']}>
              <div>
                <AntTable
                  style={style}
                  className={`table text-table ${bordered !== undefined && !bordered ? 'noBorder' : ''} ${isLoading || !data.length ? 'hide' : ''}`}
                  ref={this.table}
                  size={bodyWidth <= 768 ? 'default' : size}
                  dataSource={dataSource}
                  rowKey={this.getRowKey}
                  components={tableComponents}
                  columns={getEnhancerColumn}
                  // columns={adjustedTableColumns}
                  pagination={data.length && tablePagination.total > 10 && withPaging ? paginationConfig : false}
                  scroll={scroll}
                  // bordered={bordered}
                  // onRowClick={this.rowClick}
                  onChange={this.tableChange}
                />
              </div>
            </Dropdown>
          </ConfigProvider>
        )}

        {isLoading && <Loading />}
        {!isLoading && !data.length && <TableNullData style={this.getNullStyle(tableBodyHeight)} />}
        {paginationWithoutTotal}
      </div>
    );
  }
}

export default Table;

function getTableColumns(props) {
  var linkRegex = new RegExp(/链接$/);
  const { chartStyles, width } = props;
  if (!chartStyles.table) {
    return {
      tableColumns: [],
      mapTableHeaderConfig: {},
    };
  }
  const { cols, rows, metrics, fields, firstContent, secondContent, data, queryVariables, pagination, requestParams } = props;
  const { headerConfig, columnsConfig, autoMergeCell, leftFixedColumns, rightFixedColumns, withNoAggregators, withStyle, withSerial } = chartStyles.table;
  const { orders } = requestParams;
  const dimensions = JSON.parse(JSON.stringify(fields && fields.length ? fields : cols));
  firstContent && firstContent.length && dimensions.push(firstContent[0]);

  let defaultOrderKey = null;
  let defaultOrder = '';
  if (orders && orders.length) {
    defaultOrderKey = orders[0].column;
    defaultOrder = SortTypesToOrder[orders[0].direction];
  }

  let tableColumns = [];
  const mapTableHeaderConfig = {};
  const fixedColumnInfo = {};
  const cellSpanMap = getCellSpanMap(data, dimensions);
  let calculatedTotalWidth = 0;
  let fixedTotalWidth = 0;
  let linkKey = '';
  let linkAgg = '';

  // 判断序号是否在前三行
  const isTopThree = (rowIndex) => {
    if (pagination && !rows.length) {
      return pagination.pageNo === 1 && [0, 1, 2].includes(rowIndex);
    } else {
      return [0, 1, 2].includes(rowIndex);
    }
  };

  //兼容旧数据
  if (withSerial || typeof withSerial === 'undefined') {
    const serialHeaderConfig = {
      style: {
        justifyContent: 'center',
      },
    };
    const serialColumnStyle = {
      conditionStyles: [],
      style: {
        width: 40,
        justifyContent: 'center',
        padding: '0 6px',
        backgroundPosition: 'center center',
      },
    };
    mapTableHeaderConfig['serialNumber'] = serialHeaderConfig;
    // 插入序号
    const serialNumberColumn = {
      dataIndex: 'serialNumber',
      title: '序号',
      extraKey: 'all',
      key: 'serialNumber',
      className: 'ant-table-column-serialNumber',
      sorter: false,
      onCell: (record, rowIndex) => ({
        cellVal: pagination ? (pagination.pageNo - 1) * pagination.pageSize + rowIndex + 1 : rowIndex + 1,
        className: withStyle && isTopThree(rowIndex) ? `serialNumber-${rowIndex} serialNumberBase` : 'serialNumberBase',
        config: serialColumnStyle,
      }),
      // width: computeCellWidth(null, pagination ? pagination.totalCount : '10000'),
      width: 40,
    };
    tableColumns.push(serialNumberColumn);
    calculatedTotalWidth += serialNumberColumn.width;
  }

  const dimensionsDisplay = getCartesianChartMetrics(dimensions);
  const metricsDisplay = getCartesianChartMetrics(metrics);
  dimensionsDisplay.forEach((dimension, dimIndex) => {
    const { name, field, format, fieldDisplay, randomName, displayName } = dimension;
    if (!linkRegex.test(fieldDisplay || name)) {
      const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || name;
      const column = {
        originalName: name,
        key: randomName || name,
        title:
          field && field.desc ? (
            <Fragment>
              {headerText}
              <Tooltip title={field.desc} placement="top">
                <Icon className="headerIcon" type="info-circle" />
              </Tooltip>
            </Fragment>
          ) : (
            headerText
          ),
        dataIndex: randomName || name,
        sortOrder: defaultOrderKey && (defaultOrderKey === randomName || defaultOrderKey === name) && defaultOrder,
      };
      if (autoMergeCell) {
        column.render = (text, _, idx) => {
          // dimension cells needs merge
          const rowSpan = cellSpanMap[randomName || name][idx];
          return rowSpan === 1 ? text : { children: text, props: { rowSpan } };
        };
      }
      let headerConfigItem = null;
      findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
        headerConfigItem = config;
      });
      const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
      if (!columnConfigItem || !columnConfigItem.hide) {
        const isFixed = columnConfigItem && columnConfigItem.style && columnConfigItem.style.inflexible;
        if (isFixed) {
          column.width = fixedColumnInfo[column.key] = columnConfigItem.style.width;
          fixedTotalWidth += column.width;
        }
        //列宽度计算
        else {
          column.width = getDataColumnWidth(name, columnConfigItem, format, data);
          let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, headerText) + 14;
          if (dimension.field && dimension.field.desc) {
            headerWidth += 14 + 16;
          }
          if (columnConfigItem && columnConfigItem.sort) {
            headerWidth += 30;
          }
          column.width = Math.max(+column.width, headerWidth, 96);
        }
        calculatedTotalWidth += column.width;
        column.sorter = !columnConfigItem || typeof columnConfigItem.sort === 'undefined' ? true : columnConfigItem.sort;
        mapTableHeaderConfig[randomName || name] = headerConfigItem;
        if (!linkKey) {
          column.onCell = (record) => ({
            config: columnConfigItem,
            format,
            record,
            cardId: props.id,
            cardRefreshWithoutConfig: props.cardRefreshWithoutConfig,
            keysDisplay: dimensionsDisplay.concat(metricsDisplay),
            cellVal: record[randomName || name],
            cellValRange: null,
            isContent: dimIndex > fields.length - 1,
            firstContent,
            secondContent,
          });
        } else {
          const linkKeyCopy = linkKey;
          linkKey = '';
          column.onCell = (record) => ({
            config: columnConfigItem,
            format,
            cellVal: record[randomName || name],
            cellValRange: null,
            linkKey: linkKeyCopy,
            record,
            isContent: dimIndex > fields.length - 1,
            firstContent,
            secondContent,
          });
        }
        tableColumns.push(column);
      }
    } else {
      linkKey = randomName || name;
    }
  });

  // adjust column width
  const flexibleTotalWidth = calculatedTotalWidth - fixedTotalWidth;
  const flexibleContainerWidth = width - fixedTotalWidth;
  const containerWidthRatio = flexibleTotalWidth < flexibleContainerWidth ? flexibleContainerWidth / flexibleTotalWidth : 1;
  tableColumns.forEach((column) => {
    if (fixedColumnInfo[column.key] === void 0) {
      if (column.dataIndex === 'serialNumber') {
        return;
      }
      // Math.floor to avoid creating float column width value and scrollbar showing
      // not use Math.ceil because it will exceed the container width in total
      column.width = Math.floor(containerWidthRatio * Number(column.width));
    }
  });

  const groupedColumns = [];
  traverseConfig(headerConfig, 'children', (currentConfig) => {
    const { key, isGroup, headerName, style } = currentConfig;
    if (!isGroup) {
      return;
    }

    const childrenConfig = currentConfig.children.filter(
      ({ isGroup, key, headerName }) =>
        (!isGroup && tableColumns.findIndex((col) => col.key === headerName || col.originalName === headerName) >= 0) ||
        (isGroup && groupedColumns.findIndex((col) => col.key === key) >= 0)
    );
    if (!childrenConfig.length) {
      return;
    }

    const groupedColumn = {
      key,
      title: headerName,
      width: 0,
    };
    const childrenCell = [];

    mapTableHeaderConfig[key] = currentConfig;

    // childrenConfig.sort((cfg1, cfg2) => {
    //   if (cfg1.isGroup || cfg2.isGroup) {
    //     return 0;
    //   }
    //   const cfg1Idx = tableColumns.findIndex((column) => column.key === cfg1.headerName);
    //   const cfg2Idx = tableColumns.findIndex((column) => column.key === cfg2.headerName);
    //   return cfg1Idx - cfg2Idx;
    // });

    let insertIdx = Infinity;
    childrenConfig.forEach(({ isGroup, key, headerName }) => {
      const columnIdx = tableColumns.findIndex((column) =>
        column.children ? column.key === key : column.key === headerName || column.originalName === headerName
      );
      insertIdx = Math.min(insertIdx, columnIdx);
      childrenCell.push(tableColumns[columnIdx]);
      groupedColumn.width = +groupedColumn.width + +tableColumns[columnIdx].width;
      tableColumns.splice(columnIdx, 1);
    });
    groupedColumn.onCell = (record) => ({ record, childrenCell });
    tableColumns.splice(insertIdx, 0, groupedColumn);
    groupedColumns.push(groupedColumn);
  });

  const left = [];
  const middle = [];
  const right = [];
  tableColumns.forEach((column, index) => {
    const name = column.children && column.children.length ? column.title : column.originalName;
    if (leftFixedColumns.includes(name)) {
      column.fixed = 'left';
      left.push(column);
    } else if (rightFixedColumns.includes(name)) {
      column.fixed = 'right';
      right.push(column);
    } else {
      //  将序号列放到首位
      if (leftFixedColumns.length && column.key === 'serialNumber') {
        column.fixed = 'left';
        left.unshift(column);
      } else {
        middle.push(column);
      }
    }
  });
  tableColumns = left.concat(middle.concat(right));

  return { tableColumns, mapTableHeaderConfig, containerWidthRatio };
}

function getPaginationOptions(props) {
  const { chartStyles, width, pagination } = props;
  // fixme
  let pageNo = void 0;
  let pageSize = void 0;
  let totalCount = void 0;
  if (pagination) {
    pageNo = pagination.pageNo;
    pageSize = pagination.pageSize;
    totalCount = pagination.totalCount;
  }
  // const { pageNo, pageSize, totalCount } = pagination
  const { pageSize: initialPageSize } = chartStyles.table;

  const paginationOptions = {
    current: pageNo,
    pageSize: pageSize || +initialPageSize,
    total: totalCount,
    simple: width <= 768,
  };
  return paginationOptions;
}
