import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import CommonTable from '../CommonTable/index';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import util from '../../util';
import api from '../../api';
import TableNullData from '../../component/TableNullData/index';
@observer
class ParamTable extends React.Component {
  @observable optionsData = null;
  @observable selectValue = null;
  @observable data = null;
  componentWillMount() {
    this.fetchData();
  }
  @action.bound onSelect = (selectValue) => {
    this.selectValue = selectValue.value;
  };
  fetchData = () => {
    util.post(
      api.pack.dimOptions,
      {
        idsite: pageHeaderStore.parameter.idsite,
        dim: 'param',
        date: pageHeaderStore.parameter.date,
        lastDate: pageHeaderStore.parameter.lastDate,
        filters: JSON.stringify([{ dim: `${util.getQueryString('keyDim')}`, value: `${util.getQueryString('key')}`, formula: '=' }]),
      },
      (res) => {
        this.data = res.data;
        this.translateToOptions(this.data);
      }
    );
  };
  translateToOptions = (data) => {
    if (data.length === 0) {
      return;
    }
    this.optionsData = data.map((item) => {
      const { param_key, param_name } = item;
      return { label: param_name, value: param_key };
    });
    this.selectValue = this.optionsData[0]['value'];
  };
  render() {
    return (
      <div>
        {this.selectValue ? (
          <CommonTable
            tableOptions={true}
            dims="param"
            fields="paramOccurs,paramUv"
            filters={[
              { dim: `${util.getQueryString('keyDim')}`, value: `${util.getQueryString('key')}`, formula: '=' },
              { dim: `param`, value: `${this.selectValue}`, formula: '=' },
            ]}
            orderDesc={true}
            orderColumn={'paramOccurs'}
            currentPage={1}
            onSelect={this.onSelect}
            optionsData={this.optionsData}
            selectValue={this.selectValue}
            pageCount={10}></CommonTable>
        ) : (
          <TableNullData></TableNullData>
        )}
      </div>
    );
  }
}
export default ParamTable;
