import React, { Component } from 'react';
import './index.sass';
import { Form, Spin } from 'antd';
export default class AnalyseForm extends Component {
  render() {
    return (
      <div className="analyseForm">
        {this.props.formArr.map((item, index) => {
          if (item.hasOwnProperty('show')) {
            return item.show ? (
              <Form.Item key={index} label={item.label}>
                {item.component}
              </Form.Item>
            ) : null;
          } else {
            return (
              <Form.Item key={index} label={item.label}>
                {item.component}
              </Form.Item>
            );
          }
        })}
      </div>
    );
  }
}
