import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Checkbox, Select, Radio } from 'antd';
import BoardStore from '../../../../../views/BoardCustom/BoardChart/BoardStore';

function ControllerConfig(props) {
  const { form, viewOptions, getOptions, controllerList, setControllerList, currentIndex } = props;
  const { getFieldDecorator } = form;
  const { cardList } = BoardStore;
  const cards = getCards(cardList);
  const [views, setViews] = useState([]);
  const [config, setConfig] = useState({});
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [keywords, setKeywords] = useState({});
  const itemCols = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const typeList = [
    { value: 'select', label: '下拉菜单' },
    { value: 'datetime', label: '日期选择' },
    { value: 'search', label: '搜索框' },
  ];
  const actionType = [1, 2, 3];

  useEffect(() => {
    const currentItem = controllerList[currentIndex];
    setConfig(currentItem);
    const selectedCards = currentItem.relatedCards ? currentItem.relatedCards.map((item) => item && item.id) : [];
    selectCards(selectedCards);
  }, [currentIndex]);

  // 把卡片list转map
  function getCards(list) {
    let obj = {};
    list.forEach((item) => {
      obj[item.cardId || item.id] = item;
    });
    return obj;
  }

  // 选择关联图表回调
  function selectCards(value) {
    setIsSelectAll(value.length === cardList.length);
    let viewArr = [],
      checkRepeatArr = [];
    value.forEach((item) => {
      if (cards[item]) {
        let view = actionType.includes(cards[item].type)
          ? 'action'
          : cards[item].type === 8
          ? 'answer'
          : cards[item].type === 10
          ? cards[item].viewId.toString()
          : null;
        view &&
          !checkRepeatArr.includes(view) &&
          (getOptions(cards[item].type, view), checkRepeatArr.push(view), viewArr.push({ type: cards[item].type, view }));
      }
    });
    setViews(viewArr);
    let controllerListCopy = controllerList;
    controllerListCopy[currentIndex].relatedCards = [];
    value.forEach((item) => {
      if (cards[item]) {
        let view = actionType.includes(cards[item].type)
          ? 'action'
          : cards[item].type === 8
          ? 'answer'
          : cards[item].type === 10
          ? cards[item].viewId.toString()
          : null;
        controllerListCopy[currentIndex].relatedCards.push({ type: cards[item].type, id: item, view });
      }
    });
    const relatedViews = controllerListCopy[currentIndex].relatedViews;
    for (let key in relatedViews) {
      !checkRepeatArr.includes(key) && delete relatedViews[key];
    }
    controllerListCopy[currentIndex].relatedViews = relatedViews;
    setControllerList(controllerListCopy);
  }

  // 全选回调
  function selectAll(e) {
    if (e.target.checked) {
      setIsSelectAll(true);
      const relatedCards = cardList.map((item) => item.cardId || item.id);
      form.setFieldsValue({ relatedCards });
      selectCards(relatedCards);
    } else {
      setIsSelectAll(false);
      form.setFieldsValue({ relatedCards: [] });
      selectCards([]);
    }
  }

  // 选择关联数据回调
  function selectField(viewId, value) {
    let item = viewOptions[viewId].list.filter((item) => item.fieldName === value);
    item = item[0];
    let controllerListCopy = controllerList;
    controllerListCopy[currentIndex].relatedViews[viewId] =
      viewId === 'action' || viewId === 'answer'
        ? { name: value, sqlType: item.sqlType }
        : {
            name: value,
            sqlType: item.sqlType,
            viewName: viewOptions[viewId].name,
            viewId: viewId,
            workViewParamId: item.workViewParamId,
            customType: item.type,
          };
    setControllerList(controllerListCopy);
  }

  function onFiledSearch(viewId, value) {
    setKeywords({
      ...keywords,
      viewId: value,
    });
  }

  // 选择类型回调
  function selectType(e) {
    let controllerListCopy = controllerList;
    controllerListCopy[currentIndex].type = e.target.value;
    setControllerList(controllerListCopy);
  }
  return (
    <Form className="config-form">
      <Row type="flex">
        <Col span={8} className="config-form-item">
          <div className="form-item-header">
            <div>请选择控制卡片</div>
          </div>
          <Checkbox className="block" checked={isSelectAll} onChange={selectAll}>
            全选
          </Checkbox>
          {getFieldDecorator('relatedCards', {
            initialValue: config.relatedCards ? config.relatedCards.map((item) => item && item.id) : null,
          })(
            <Checkbox.Group onChange={selectCards}>
              <Row className="checkbox-list">
                {cardList.map((item) => {
                  return (
                    <Col span={24} className="checkbox-item">
                      <Checkbox key={item.cardId} value={item.cardId || item.id}>
                        {item.title || item.name}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          )}
        </Col>
        <Col span={8} className="config-form-item">
          <div className="form-item-header">数据来源</div>
          {views.map((item) => {
            let viewId = item.view.toString();
            return (
              viewOptions[item.view] && (
                <Form.Item label={viewOptions[item.view].name} key={item.view} {...itemCols}>
                  {getFieldDecorator(viewId, {
                    initialValue: config.relatedViews[item.view] ? config.relatedViews[item.view].name : null,
                    rules: [{ required: true, message: '关联字段不能为空' }],
                  })(
                    <Select className="lg-select" onChange={(value) => selectField(viewId, value)}>
                      {viewOptions[item.view].list.map((e) => {
                        return <Select.Option key={e.value}>{e.label}</Select.Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              )
            );
          })}
        </Col>
        <Col span={8} className="config-form-item">
          <div className="form-item-header">控制器类型</div>
          <Form.Item label="" {...itemCols}>
            {getFieldDecorator('type', {
              initialValue: config.type || 'select',
            })(
              // <Select className="md-select" onChange={(value) => selectType(value)}>
              //   {typeList.map((item) => {
              //     return <Select.Option key={item.value}>{item.label}</Select.Option>;
              //   })}
              // </Select>
              <Radio.Group onChange={selectType}>
                <Row className="checkbox-list">
                  {typeList.map((item) => {
                    return (
                      <Col span={24} className="checkbox-item">
                        <Radio key={item.value} value={item.value}>
                          {item.label}
                        </Radio>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ControllerConfig;
