import { action, observable } from 'mobx';
import util from '../../util';
import api from '../../api';

class Store {
  //   下载列表
  @observable newsCount = 0;

  //   查询未读消息条目数
  @action.bound
  getDownloadList() {
    util.get(api.notice.count, {}, (res) => {
      res && (this.newsCount = res.totalCount);
    });
  }
  @action.bound
  resetNewsCount() {
    this.newsCount = 0;
  }
}

export default new Store();
