import { DatePicker, Select, Space, TimePicker } from 'antd4';
import React, { useEffect, useState } from 'react';
import locale from 'antd4/es/date-picker/locale/zh_CN';
import moment from 'moment';
import '../Control/Panel/Layouts/Layouts.less';
import { set } from 'lodash';

const { Option } = Select;

const { RangePicker } = DatePicker;

const PickerWithType = ({ type, value, onChange }) => {
  return <RangePicker value={value} className={'controlQuickPicker'} picker={type} onChange={onChange} locale={locale} />;
};

const QuickDateRange = ({ value, onChange, onTypeChange, control }) => {
  const [type, setType] = useState('date');
  const [canChange, setCanChange] = useState(true);
  useEffect(() => {
    control && control.quickPickerType && (setType(control.quickPickerType), setCanChange(control.canChange));
  }, [control]);

  const transDate = (value) => {
    if (type === 'quarter') {
      return [
        moment()
          .quarter(value[0])
          .startOf(type),
        moment()
          .quarter(value[1])
          .endOf(type),
      ];
    }
    return [moment(value[0]).startOf(type), moment(value[1]).endOf(type)];
  };

  // 业务逻辑需要选的月的起始日期，实际用了当天及首日
  const formatDate = (value, dateString) => {
    const needFormat = ['month', 'quarter', 'year'];
    if (!needFormat.includes(type) || !value || value.every((i) => !i)) {
      return value;
    }
    switch (type) {
      case 'month':
        return transDate(dateString);
      case 'quarter':
        const formatQuarter = dateString.map((i) => i.substring(6));
        return transDate(formatQuarter);
      case 'year':
        return transDate(dateString);
    }
  };

  return (
    <Space>
      <Select
        disabled={!canChange}
        value={type}
        onChange={(value) => {
          setType(value);
          onTypeChange && onTypeChange(value);
        }}
        className={'controlQuick'}>
        <Option value="date">天</Option>
        <Option value="week">周</Option>
        <Option value="month">月</Option>
        <Option value="quarter">季</Option>
        <Option value="year">年</Option>
      </Select>
      <PickerWithType
        type={type}
        value={value}
        onChange={(value, dateString) => {
          onChange(formatDate(value, dateString));
        }}
      />
    </Space>
  );
};

export default QuickDateRange;
