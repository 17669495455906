import BasicSurveyModal from '../../containers/BasicSurvey/Modal';
import BasicSurveyTable from '../../containers/BasicSurvey/Table';
import CommonChart from '../../containers/Common/CommonChart';
import CommonBreadCrumb from '../../containers/CommonBreadCrumb';
import CommonSummary from '../../containers/CommonSummary';
import CommonTab from '../../containers/CommonTab';
import CommonTable from '../../containers/CommonTable';
import PageDetail from '../../containers/PageDetail';
import ParamTable from '../../containers/ParameterTable';
import PagePathTable from '../../containers/PagePathTable';
import CommonSankey from '../../containers/CommonSankey';
// import SankeyChart from '../../component/Sankey';
// import SankeyTest from '../../containers/SankeyTest/index';

export default {
  title: '页面详情',
  components: [
    {
      component: CommonBreadCrumb,
      props: {
        breadCrumbSecName: 'key',
      },
    },
    {
      component: CommonTab,
      isCard: true,
      props: {
        tab: [
          {
            name: '历史趋势',
            key: '历史趋势',
            children: [
              {
                key: 'PageDetail',
                component: PageDetail,
                col: 24,
                props: {
                  children: [
                    {
                      key: 'CommonSummary',
                      component: CommonSummary,
                      col: 24,
                      props: {
                        comparision: 'chain',
                        addFilterFromUrl: true,
                      },
                    },
                    {
                      key: 'CommonChart',
                      component: CommonChart,
                      col: 24,
                      props: {
                        dims: 'date',
                        showAll: true,
                        addFilterFromUrl: true,
                      },
                    },
                    {
                      key: 'CommonTable',
                      component: CommonTable,
                      col: 24,
                      props: {
                        addFilterFromUrl: true,
                        currentPage: 1,
                        dims: 'date',
                        orderDesc: true,
                        pageCount: 10,
                      },
                    },
                  ],
                },
              },
            ],
          },
          {
            name: '页面参数',
            key: '页面参数',
            children: [
              {
                key: 'ParamTable',
                component: ParamTable,
                col: 24,
              },
            ],
          },
          {
            name: '页面上下游',
            isCard: true,

            children: [
              {
                component: CommonSankey,
                props: {
                  dims: 'path_page_sanKey',
                  fields: 'id,pagePathPv,pagePathUv,pagePathUvRatio,pagePathPvRatio,pagePathBounces,pagePathExits',
                  pageCount: 4,
                  currentPage: 1,
                  orderColumn: 'pagePathPv',
                  customSetFilter: {
                    dims: 'path_channel',
                    value: false,
                  },
                  nodeFields: ['pagePathUv', 'pagePathUvRatio', 'pagePathPv', 'pagePathPvRatio'],
                },
                col: 24,
              },
              {
                component: CommonTab,
                col: 24,
                props: {
                  mode: 'left',
                  tab: [
                    {
                      name: '流入渠道',
                      key: '流入渠道',
                      children: [
                        {
                          component: PagePathTable,
                          col: 24,
                          props: {
                            dims: 'path_channel',
                          },
                        },
                      ],
                    },
                    {
                      name: '流入页面',
                      key: '流入页面',
                      children: [
                        {
                          component: PagePathTable,
                          col: 24,
                          props: {
                            dims: 'path_page_before',
                            tableHeadKeyForKey: true,
                          },
                        },
                      ],
                    },
                    {
                      name: '退出页面',
                      key: '退出页面',
                      children: [
                        {
                          component: PagePathTable,
                          col: 24,
                          props: {
                            dims: 'path_page_after',
                            tableHeadKeyForKey: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          // {
          //   name: '操作热图',
          //   isCard: true,
          //   children: [
          //
          //   ],
          // },
          // {
          //   name: '操作录像',
          //   isCard: true,
          //   children: [
          //
          //   ],
          // },
          {
            key: '用户调研',
            name: '用户调研',
            isCard: true,
            children: [
              {
                key: 'BasicSurveyTable',
                component: BasicSurveyTable,
              },
              {
                key: 'BasicSurveyModal',
                component: BasicSurveyModal,
              },
            ],
          },
        ],
      },
    },
  ],
};
