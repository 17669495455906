// 图表驱动
import React, { Component } from 'react';
import Table from './Table';
import TextTable from './TextTable';
import ContentTable from './ContentTable';
import ExtendTable from './ExtendTable';
import Scorecard from './Scorecard';
import Iframe from './Iframe';
// import RichText from './RichText'
import Chart from './Chart';
import ChartTypes from '../config/chart/ChartTypes';
import './index.sass';
import TableNullData from '../../../component/TableNullData';
import Summary from './Summary';

export class CombinedChart extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.renderType !== 'loading';
  }

  render() {
    const { selectedChart } = this.props;

    switch (selectedChart) {
      case ChartTypes.Table:
      case ChartTypes.NativeTable:
        return <Table {...this.props} />;
      case ChartTypes.ExtendTable: //分组表
        return <ExtendTable {...this.props} />;
      case ChartTypes.TextTable:
        return <TextTable {...this.props} />;
      case ChartTypes.ContentTable:
        return <ContentTable {...this.props} />;
      case ChartTypes.Scorecard:
        return (
          <Scorecard {...this.props} /> //翻牌器
        );
      case ChartTypes.Iframe:
        return (
          <Iframe {...this.props} /> //内嵌网页
        );
      case ChartTypes.RichText:
        return (
          // <RichText {...this.props} />
          <TableNullData />
        );
      case ChartTypes.Summary:
        return (
          <Summary {...this.props} /> //大指标
        );
      default:
        return (
          <Chart {...this.props} />
          // <TableNullData />
        );
    }
  }
}

export default CombinedChart;
