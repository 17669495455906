import React from 'react';
import { getPivotCellWidth, getPivotCellHeight, getStyleConfig } from '../util';

export function Corner(props) {
  const { cols, rows, rowWidths, chartStyles, dimetionAxis } = props;
  const { color: fontColor, fontSize, fontFamily, lineColor, lineStyle, headerBackgroundColor } = getStyleConfig(chartStyles).pivot;

  let width;
  let height;
  let marginTop = cols.length && 27;
  let marginLeft = rows.length && 37;

  if (dimetionAxis) {
    if (dimetionAxis === 'col') {
      width = rowWidths.reduce((sum, rw) => sum + getPivotCellWidth(rw), 0) + 64;
      height = Math.max(0, cols.length - 1) * 44;
    } else {
      width = rowWidths.slice(0, rowWidths.length - 1).reduce((sum, rw) => sum + getPivotCellWidth(rw), 0) + 64;
      height = cols.length * 44;
    }
  } else {
    width = rowWidths.reduce((sum, rw) => sum + getPivotCellWidth(rw), 0);
    height = cols.length * 44;
    marginTop;
    marginLeft += 1;
  }

  return (
    <div
      className={`corner ${!dimetionAxis && cols.length && rows.length ? 'raw' : ''}`}
      style={{
        width,
        height,
        marginTop,
        marginLeft,
        ...((cols.length || rows.length) && {
          ...(!dimetionAxis && { backgroundColor: headerBackgroundColor }),
          color: fontColor,
          fontSize: Number(fontSize),
          fontFamily,
          borderColor: lineColor,
          borderStyle: lineStyle,
        }),
      }}
    />
  );
}

export default Corner;
