import React, { PureComponent } from 'react';

import { Input } from 'antd';
const InputGroup = Input.Group;

const styles = require('./NumberRange.less');

export class NumberRange extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value.slice() : ['', ''],
    };
  }

  static defaultProps = {
    placeholder: '',
    size: 'default',
  };

  componentWillReceiveProps(nextProps) {
    const nextValue = nextProps.value;
    const { value } = this.state;

    if (nextValue) {
      if (nextValue[0] !== value[0] || nextValue[1] !== value[1]) {
        this.setState({
          value: nextValue.slice(),
        });
      }
    } else {
      this.setState({
        value: ['', ''],
      });
    }
  }

  inputChange = (dir) => {
    const { onChange } = this.props;
    const { value } = this.state;

    return function(e) {
      if (dir === 'from') {
        onChange([e.target.value.trim(), value[1]]);
      } else {
        onChange([value[0], e.target.value.trim()]);
      }
    };
  };

  inputSearch = () => {
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(this.state.value);
    }
  };

  render() {
    const { placeholder, size } = this.props;
    const { value } = this.state;

    return (
      <InputGroup className={'range'} {...(size && { size })} compact>
        <Input
          className={'number'}
          value={value[0]}
          placeholder={`${placeholder || ''}从`}
          onChange={this.inputChange('from')}
          onPressEnter={this.inputSearch}
        />
        <Input className={'numberDivider'} placeholder="-" readOnly tabIndex={-1} />
        <Input className={'number'} value={value[1]} placeholder="到" onChange={this.inputChange('to')} onPressEnter={this.inputSearch} />
      </InputGroup>
    );
  }
}

export default NumberRange;
