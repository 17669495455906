/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React, { Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import { Table as AntTable, Tooltip, Icon } from 'antd';
import { toJS } from 'mobx';
import PaginationWithoutTotal from './PaginationWithoutTotal';
import { ViewModelTypes, TABLE_PAGE_SIZES, SortTypesToOrder, SERIAL_STYLE } from '../constants';
import { MapAntSortOrder } from './constants';
import {
  getFieldAlias,
  decodeMetricName,
  hasProperty,
  findChildConfig,
  traverseConfig,
  computeCellWidth,
  getTableCellValueRange,
  getCellSpanMap,
  getDataColumnWidth,
  randomNameToFieldName,
  getTextWidth,
  getCompareText,
} from '../util';
import { getCartesianChartMetrics } from '../render/chart/util';
import { tableComponents } from '../components/tableComponents';
import { resizeTableColumns } from '../components/HeadCell';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import PageHeaderStore from '../../../component/PageHeader/PageHeaderStore';
import BIStore from '../store';
import util from '../../../util';
import BoardStore from '../../../views/BoardCustom/BoardChart/BoardStore';
import { handleAnalysis } from '../util';

const bodyWidth = document.body.offsetWidth;

export class Table extends React.PureComponent {
  constructor(props) {
    super();
    this.HeaderSorterWidth = 0;
    this.table = React.createRef();
    const { rows } = props;
    this.state = {
      chartStyles: null,
      data: null,
      width: 0,
      pagination: null,
      currentSorter: null,

      tableColumns: [],
      mapTableHeaderConfig: {},
      containerWidthRatio: 1,
      tablePagination: {
        current: void 0,
        pageSize: void 0,
        simple: false,
        total: void 0,
      },
      tableBodyHeight: 0,
      selectedRow: [],
      selectItems: {
        group: [],
        cell: {},
      },
      showRows: rows && rows.length ? true : false,
    };
  }

  handleResize = (idx, containerWidthRatio) => (_, { size }) => {
    const nextColumns = resizeTableColumns(this.state.tableColumns, idx, size.width, containerWidthRatio);
    this.setState({ tableColumns: nextColumns });
  };

  tableChange = (pagination, _, sorter) => {
    const nextCurrentSorter = sorter.field ? { column: sorter.field, direction: MapAntSortOrder[sorter.order] } : null;
    this.setState({ currentSorter: nextCurrentSorter });
    const { current, pageSize } = pagination;
    if (!this.state.showRows) {
      this.refreshTable(current || 1, pageSize || 10, nextCurrentSorter);
    }
  };

  refreshTable = (current, pageSize, sorter) => {
    const { tablePagination } = this.state;
    if (pageSize !== tablePagination.pageSize) {
      current = 1;
    }
    const { onPaginationChange } = this.props;
    onPaginationChange(current, pageSize, sorter);
  };

  basePagination = {
    pageSizeOptions: TABLE_PAGE_SIZES.map((s) => s.toString()),
    showSizeChanger: true,
    size: 'small',
  };

  componentDidMount() {
    const { headerFixed, withPaging } = this.props.chartStyles.table;
    this.adjustTableCell(headerFixed, withPaging);
    this.getTableConfig(this.props, this.state);
  }

  componentDidUpdate(preProps, prevState) {
    const { headerFixed, withPaging } = this.props.chartStyles.table;
    // const { headerFixed: preHeaderFixed, withPaging: preWithPaging } = preProps.chartStyles.table;
    // if (headerFixed !== preHeaderFixed || withPaging !== preWithPaging || this.state.tablePagination.total !== prevState.tablePagination.total) {
    this.adjustTableCell(headerFixed, withPaging, this.state.tablePagination.total);
    // }
    this.getTableConfig(this.props, prevState);
    const { chartStyles, data, width, rows } = this.props;
    if (chartStyles !== prevState.chartStyles || data !== prevState.data || width !== prevState.width) {
      const { tableColumns, mapTableHeaderConfig, containerWidthRatio } = getTableColumns(this.props);
      const tablePagination = getPaginationOptions(this.props);
      this.setState({
        tableColumns,
        mapTableHeaderConfig,
        containerWidthRatio,
        tablePagination,
        chartStyles,
        data,
        width,
      });
    }
    if (this.props.isLoading === true && this.props.isLoading !== preProps.isLoading) {
      // 下钻后取消选中项
      this.setState({
        selectItems: {
          group: [],
          cell: {},
        },
      });
    }

    const showRows = rows && rows.length ? true : false;
    this.setState({
      chartStyles,
      data,
      width,
      showRows,
    });
  }

  adjustTableCell(headerFixed, withPaging, dataTotal) {
    const tableDom = findDOMNode(this.table.current);
    const excludeElems = [];
    let paginationMargin = 0;
    let paginationWithoutTotalHeight = 0;
    if (headerFixed) {
      excludeElems.push('.ant-table-thead');
    }
    if (withPaging && dataTotal > 10) {
      excludeElems.push('.ant-pagination.ant-table-pagination');
      paginationMargin = 16;

      if (dataTotal === -1) {
        paginationWithoutTotalHeight = 40;
      }
    }
    const excludeElemsHeight = excludeElems.reduce((acc, exp) => {
      const elem = tableDom.querySelector(exp);
      return acc + (elem ? elem.getBoundingClientRect().height : 0);
    }, paginationMargin);
    const tableBodyHeight =
      this.props.height -
      (this.props.chartStyles.table.headerFixed ? excludeElemsHeight : 0) -
      paginationWithoutTotalHeight -
      (BoardStore.compareFilterConfig[this.props.id] ? 24 : 0);
    // const tableBodyHeight = this.props.height - 20 - paginationWithoutTotalHeight;
    this.setState({
      tableBodyHeight,
    });
  }

  //设置表格所需数据
  getTableConfig(props, state) {
    const { chartStyles, data, width } = props;
    if (chartStyles !== state.chartStyles || data !== state.data || width !== state.width) {
      const { tableColumns, mapTableHeaderConfig, containerWidthRatio } = getTableColumns(props);
      const tablePagination = getPaginationOptions(props);
      this.setState({
        tableColumns,
        mapTableHeaderConfig,
        containerWidthRatio,
        tablePagination,
        chartStyles,
        data,
        width,
      });
    }

    this.setState({ chartStyles, data, width });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { chartStyles, data, width, rows } = nextProps;
    if (chartStyles !== prevState.chartStyles || data !== prevState.data || width !== prevState.width) {
      const { tableColumns, mapTableHeaderConfig, containerWidthRatio } = getTableColumns(nextProps);
      const tablePagination = getPaginationOptions(nextProps);
      return {
        tableColumns,
        mapTableHeaderConfig,
        containerWidthRatio,
        chartStyles,
        tablePagination,
        data,
        width,
      };
    }

    const showRows = rows && rows.length ? true : false;
    return { chartStyles, data, width, showRows };
  }

  adjustTableColumns(tableColumns, mapTableHeaderConfig, containerWidthRatio, headerClick) {
    const { showAnalysis, handlePreview, index } = this.props;
    traverseConfig(tableColumns, 'children', (column, idx, siblings) => {
      const canResize = siblings === tableColumns;
      column.onHeaderCell = (col) => {
        if (showAnalysis) {
          headerClick = (column) => {
            if (!column.workViewParamId) {
              return;
            }
            handleAnalysis(column.workViewParamId, index, handlePreview);
          };
        }
        return {
          width: col.width,
          onResize: canResize && this.handleResize(idx, containerWidthRatio),
          config: mapTableHeaderConfig[column.key],
          onClick: headerClick && (() => headerClick(column)),
        };
      };
    });
    return tableColumns;
  }

  getRowKey = (_, idx) => idx.toString();

  getTableScroll(columns, containerWidth, headerFixed, tableBodyHeight) {
    const scroll = {};
    if (bodyWidth < 768) {
      scroll.x = '100%';
      return scroll;
    }
    const columnsTotalWidth = columns.reduce((acc, c) => acc + c.width, 0);
    if (columnsTotalWidth > containerWidth) {
      scroll.x = Math.max(columnsTotalWidth, containerWidth);
    }
    if (headerFixed) {
      // 20 表格边距
      scroll.y = tableBodyHeight - 12;
    }
    return scroll;
  }

  isSameObj(prevObj, nextObj, isSourceData) {
    let isb = void 0;
    const clonePrevObj = { ...prevObj };
    const cloneNextObj = { ...nextObj };
    if (isSourceData === true) {
      delete clonePrevObj['key'];
      delete clonePrevObj['value'];
      delete cloneNextObj['key'];
      delete cloneNextObj['value'];
    }
    for (const attr in clonePrevObj) {
      if (clonePrevObj[attr] !== undefined && clonePrevObj[attr] === cloneNextObj[attr]) {
        isb = true;
      } else {
        isb = false;
        break;
      }
    }
    return isb;
  }

  matchAttrInBrackets(attr) {
    const re = /\(\S+\)/;
    const key = re.test(attr) ? attr.match(/\((\S+)\)/)[1] : attr;
    return key;
  }

  rowClick = (record, row, event) => {
    const { id, cols, rows, metrics } = this.props;
    let selectedRow = [...this.state.selectedRow];
    let filterObj = void 0;
    if (event.target && event.target.innerHTML) {
      for (const attr in record) {
        if (record[attr].toString() === event.target.innerText) {
          const key = this.matchAttrInBrackets(attr);
          filterObj = {
            key,
            value: event.target.innerText,
          };
        }
      }
    }
    const recordConcatFilter = {
      ...record,
      ...filterObj,
    };
    const isInteractiveChart = PageHeaderStore.checkLinkageConfig(id);
    if (isInteractiveChart) {
      selectedRow = [recordConcatFilter];
    } else {
      if (selectedRow.length === 0) {
        selectedRow.push(recordConcatFilter);
      } else {
        const isb = selectedRow.some((sr) => this.isSameObj(sr, recordConcatFilter, true));
        if (isb) {
          for (let index = 0, l = selectedRow.length; index < l; index++) {
            if (this.isSameObj(selectedRow[index], recordConcatFilter, true)) {
              selectedRow.splice(index, 1);
              break;
            }
          }
        } else {
          selectedRow.push(recordConcatFilter);
        }
      }
    }
    // 替换selectedRow中的randomKey
    const randomNames = {};
    cols.concat(rows, metrics).forEach((item) => {
      item.randomName && (randomNames[item.randomName] = item.name);
    });
    selectedRow.forEach((item) => {
      for (let key in item) {
        randomNames[key] && (item[randomNames[key]] = item[key]);
      }
    });
    this.setState(
      {
        selectedRow,
      },
      () => {
        const sourceData = Object.values(this.state.selectedRow);
        if (PageHeaderStore.checkLinkageConfig(id, BIStore.isLinkingWidget[id] ? [] : sourceData) && !BIStore.isLinkingWidget[id]) {
          const triggerData = sourceData;
          PageHeaderStore.setCurrentLinkager(id, triggerData);
          BIStore.setIsLinkingWidget(id, true);
        }
      }
    );
  };

  asyncEmitDrillDetail() {
    const { getDataDrillDetail } = this.props;
    setTimeout(() => {
      if (this.props.getDataDrillDetail) {
        const sourceData = this.combineFilter();
        const sourceGroup = this.combineGroups();
        const brushed = [{ 0: Object.values(sourceData) }];
        getDataDrillDetail(
          JSON.stringify({
            filterObj: sourceData,
            brushed,
            sourceData,
            sourceGroup,
          })
        );
      }
    }, 500);
  }

  combineGroups() {
    const { group } = this.state.selectItems;
    return group;
  }

  combineFilter() {
    const { cell } = this.state.selectItems;
    return Object.keys(cell).reduce((iteratee, target) => {
      const data = cell[target].map((item) => ({
        ...item,
        dataKey: target,
      }));
      iteratee = iteratee.concat(data);
      return iteratee;
    }, []);
  }

  getTableStyle(headerFixed, tableBodyHeight) {
    const tableStyle = {};
    if (!headerFixed || bodyWidth < 768) {
      // tableStyle.height = tableBodyHeight;
      tableStyle.height = '100%';
      tableStyle.overflowY = 'auto';
      tableStyle.overflowX = 'auto';
    }
    return tableStyle;
  }

  filterSameNeighbourSibings = (arr, targetIndex) => {
    let s = targetIndex;
    let e = targetIndex;
    let flag = -1;
    const orgIndex = targetIndex;

    do {
      const target = arr[targetIndex];
      if (flag === -1 && targetIndex > 0 && arr[targetIndex - 1] === target) {
        s = targetIndex -= 1;
      } else if (flag === 1 && arr[targetIndex + 1] === target) {
        e = targetIndex += 1;
      } else if (flag === -1) {
        flag = 1;
        targetIndex = orgIndex;
      } else {
        break;
      }
    } while (targetIndex > -1 && targetIndex < arr.length);
    return { s, e };
  };

  coustomFilter(array, column, index) {
    const nativeIndex = array.reduce((a, b, c) => (b.index === index ? c : a), 0);
    const columns = array.map((a) => a[column]);
    const { s: start, e: end } = this.filterSameNeighbourSibings(columns, nativeIndex);
    return array.filter((arr) => (array[start] && arr['index'] < array[start]['index']) || (array[end] && arr.index > array[end]['index']));
  }

  collectCell = (target, index, dataIndex) => (event) => {
    const { group, cell } = this.state.selectItems;
    const { id, data, cols, rows, metrics } = this.props;
    const groupName = randomNameToFieldName(cols.concat(rows, metrics), this.matchAttrInBrackets(dataIndex));

    if (this.isValueModelType(groupName)) {
      return;
    }

    if (group.includes(dataIndex)) {
      group.forEach((g, i) => (g === dataIndex ? group.splice(i, 1) : void 0));
      const setKeyArray = data.map((obj, index) => ({
        ...obj,
        index: obj.key || index,
        key: groupName,
        value: obj[dataIndex],
      }));
      cell[dataIndex] = this.coustomFilter(setKeyArray, groupName, index);
    } else {
      const sourceCol = cell[dataIndex];
      const currentValue = {
        ...target,
        index,
        key: groupName,
        value: target[dataIndex],
      };

      if (sourceCol && sourceCol.length) {
        const isb = sourceCol.some((col) => col.index === index);
        if (isb) {
          cell[dataIndex] = this.coustomFilter(cell[dataIndex], groupName, index);
        } else {
          sourceCol.push(currentValue);
        }
      } else {
        cell[dataIndex] = [currentValue];
      }
    }
    this.setState(
      {
        selectItems: { group, cell },
      },
      () => {
        this.asyncEmitDrillDetail();
      }
    );

    // 表格联动逻辑重构
    const linkageTriggerList = PageHeaderStore.getLinkageTriggerList(id);
    const triggerData = {};
    // 是否设置了联动触发器
    if (linkageTriggerList.length) {
      const fieldsItem = {};
      cols.concat(rows, metrics).forEach((item) => {
        fieldsItem[item.randomName || item.name] = item;
      });
      for (const key in cell) {
        if (cell[key].length) {
          const name = fieldsItem[key].name;
          if (linkageTriggerList.includes(name)) {
            // 若当前列触发了联动
            triggerData[name] = cell[key];
          }
        }
      }
      if (JSON.stringify(triggerData) != '{}') {
        PageHeaderStore.setCurrentLinkager(id, triggerData, true);
        BIStore.setIsLinkingWidget(id, true);
      } else if (BIStore.isLinkingWidget) {
        PageHeaderStore.cleanCurrentLinkager(id);
        BIStore.setIsLinkingWidget(id, false);
      }
    }
  };

  collectGroups = (target, dataIndex) => (event) => {
    const groupName = this.matchAttrInBrackets(dataIndex);
    if (this.isValueModelType(groupName)) {
      return;
    }
    const { group, cell } = this.state.selectItems;
    if (group.includes(dataIndex)) {
      group.forEach((a, index) => {
        if (a === dataIndex) {
          group.splice(index, 1);
        }
      });
    } else {
      group.push(dataIndex);
    }
    delete cell[dataIndex];
    this.setState(
      {
        selectItems: { ...this.state.selectItems },
      },
      () => {
        this.asyncEmitDrillDetail();
      }
    );
  };

  onCellClassName = (target, index, dataIndex) => {
    const { group, cell } = this.state.selectItems;
    let result = '';
    Object.keys(cell).forEach((key) => {
      if (dataIndex === key) {
        cell[key].forEach((ck) => {
          if (index === ck.index) {
            result = 'select';
          }
        });
      }
    });
    if (group && group.includes(dataIndex)) {
      result = 'select';
    }
    return result;
  };

  isValueModelType = (modelName) => {
    const target = this.getModelTypecollectByModel();
    const result = hasProperty(target, modelName);
    return typeof result !== 'boolean' ? result === ViewModelTypes.Value : false;
  };

  getModelTypecollectByModel = () => {
    const { model } = this.props;
    if (model) {
      return Object.keys(model).reduce((iteratee, target) => {
        iteratee[target] = hasProperty(model[target], 'modelType');
        return iteratee;
      }, {});
    } else {
      return {};
    }
  };

  onHeadCellClassName = (target, dataIndex) => {
    const { group } = this.state.selectItems;
    if (group && group.includes(dataIndex)) {
      return 'select';
    }
    return '';
  };

  loop = (col, i) => {
    const { chartStyles, showAnalysis } = this.props;
    const { withSerial } = chartStyles.table;
    const firstIndex = withSerial || typeof withSerial === 'undefined' ? 1 : 0;
    if (col && col.dataIndex) {
      return {
        ...col,
        sorter: showAnalysis ? false : col.sorter,
        onHeaderCell: (target) => {
          return {
            ...col.onHeaderCell(target),
            isFirstColumn: i === firstIndex ? true : false,
            // className: this.onHeadCellClassName(target, col.dataIndex),
            // onClick: this.collectGroups(target, col.dataIndex),
          };
        },
        onCell: (target, index) => {
          // fix index in pagination
          const cellClass = { ...col.onCell(target, index) };
          if (cellClass.className) {
            cellClass.className = cellClass.className + ' ' + this.onCellClassName(target, index, col.dataIndex);
          } else {
            cellClass.className = this.onCellClassName(target, index, col.dataIndex);
          }
          return {
            ...cellClass,
            onClick: this.collectCell(target, index, col.dataIndex),
            isFirstColumn: i === firstIndex ? true : false,
          };
        },
      };
    } else {
      return { ...col };
    }
  };

  resetWidth = (column) => {
    const columnsTotalWidth = column.reduce((acc, c) => acc + c.width, 0);
    const width = this.props.width;
    if (columnsTotalWidth <= width) {
      return column.map((col) => {
        if (col.fixed) {
          col.fixed = undefined;
        }
        return col;
      });
    } else {
      return column;
    }
  };

  enhancerColumns = (column) => {
    // 判断是否需要固定列， 当宽度不足时 没必要固定的操作，因为固定列的原理是重复渲染
    column = this.resetWidth(column);
    // 移动端长度限制
    column.forEach((i) => util.isMobile() && i.width > 200 && (i.width = 200));
    const columns = column.map((col, index) => {
      if (col.children && col.children.length) {
        return {
          ...this.loop(col, index),
          children: this.enhancerColumns(col.children),
        };
      }
      return this.loop(col, index);
    });
    return columns;
  };

  getEnhancerColumn = (column) => {
    return this.enhancerColumns(column);
  };

  render() {
    const { data, chartStyles, width, limit, headerClick, rows, id } = this.props;
    const { headerFixed, bordered, withPaging, size } = chartStyles.table;
    const { tablePagination, tableColumns, tableBodyHeight, mapTableHeaderConfig, containerWidthRatio, showRows } = this.state;
    const adjustedTableColumns = this.adjustTableColumns(tableColumns, mapTableHeaderConfig, containerWidthRatio, headerClick);
    const getEnhancerColumn = this.getEnhancerColumn(adjustedTableColumns);
    const paginationConfig = {
      ...this.basePagination,
      ...tablePagination,
    };
    const scroll = this.getTableScroll(adjustedTableColumns, width, headerFixed, tableBodyHeight);
    const style = this.getTableStyle(headerFixed, tableBodyHeight);
    const paginationWithoutTotal =
      withPaging && tablePagination.total === -1 ? <PaginationWithoutTotal dataLength={data.length} size={'small'} {...paginationConfig} /> : null;
    const currentCompareFilterConfig = toJS(BoardStore.compareFilterConfig[id]); //  对比配置
    let dataSource = showRows ? getAggData(data, this.props) : currentCompareFilterConfig ? getCompareData(this.props) : data;
    if (dataSource && limit) {
      dataSource = dataSource.slice(0, limit);
    }
    return (
      <Fragment>
        <ConfigProvider locale={zh_CN}>
          <AntTable
            style={style}
            className={`table ${bordered !== undefined && !bordered ? 'noBorder' : ''}`}
            ref={this.table}
            size={bodyWidth <= 768 ? 'default' : size}
            dataSource={dataSource}
            rowKey={this.getRowKey}
            components={tableComponents}
            columns={getEnhancerColumn}
            // columns={adjustedTableColumns}
            pagination={data.length && tablePagination.total > 10 && withPaging && !rows.length ? paginationConfig : false}
            // bordered={bordered}
            scroll={scroll}
            // onRowClick={this.rowClick}
            onChange={this.tableChange}
          />
        </ConfigProvider>
        {paginationWithoutTotal}
      </Fragment>
    );
  }
}

export default Table;

// 组装columns
function getTableColumns(props) {
  var linkRegex = new RegExp(/链接$/);
  const { chartStyles, width, headerClick, id, compareData } = props;
  const currentCompareFilterConfig = toJS(BoardStore.compareFilterConfig[id]); //  对比配置
  if (!chartStyles.table) {
    return {
      tableColumns: [],
      mapTableHeaderConfig: {},
    };
  }
  const { cols, rows, metrics, data, queryVariables, pagination, fields, requestParams, showAnalysis } = props;
  const {
    headerConfig,
    columnsConfig,
    autoMergeCell,
    leftFixedColumns,
    rightFixedColumns,
    withNoAggregators,
    withStyle,
    withPaging,
    withSerial,
  } = chartStyles.table;
  const { orders } = requestParams;

  let defaultOrderKey = null;
  let defaultOrder = '';
  if (orders && orders.length) {
    defaultOrderKey = orders[0].column;
    defaultOrder = SortTypesToOrder[orders[0].direction];
  }

  let tableColumns = [];
  const mapTableHeaderConfig = {};
  const fixedColumnInfo = {};
  let calculatedTotalWidth = 0;
  let fixedTotalWidth = 0;
  let linkKey = '';
  let linkAgg = '';

  // 聚合显示相关变量
  const showRows = rows && rows.length ? true : false;
  const rowsKey = showRows ? rows[0].randomName || rows[0].name : [];
  const secondKeys = []; //二级表头
  const dataSource = !showRows ? data : getAggData(data, props);

  const dimensions = fields && fields.length ? fields : cols.concat(showRows ? [] : rows);
  const cellSpanMap = getCellSpanMap(dataSource, dimensions);

  let compareDataFormat = null;

  showRows &&
    data.forEach((item) => {
      secondKeys.indexOf(item[rowsKey]) < 0 && secondKeys.push(item[rowsKey]);
    });

  // 判断序号是否在前三行
  const isTopThree = (rowIndex) => {
    if (withPaging && !rows.length) {
      return pagination.pageNo === 1 && [0, 1, 2].includes(rowIndex);
    } else {
      return [0, 1, 2].includes(rowIndex);
    }
  };

  //兼容旧数据
  if (withSerial || typeof withSerial === 'undefined') {
    let serialHeaderConfig = headerConfig.find((i) => i.headerName === 'serial');
    if (!serialHeaderConfig) {
      serialHeaderConfig = {
        style: {
          justifyContent: 'center',
        },
      };
    }
    let serialColumnConfig = columnsConfig.find((i) => i.columnName === 'serial') || {};
    let serialColumnStyle = serialColumnConfig.style ? { ...serialColumnConfig } : {};
    if (serialColumnStyle.style) {
      serialColumnStyle.style.backgroundPosition = `${SERIAL_STYLE[serialColumnStyle.style.justifyContent]} center`;
      serialColumnStyle.style.padding = '0 6px';
    } else {
      serialColumnStyle = {
        conditionStyles: [],
        style: {
          width: 40,
          justifyContent: 'center',
          padding: '0 6px',
          backgroundPosition: 'center center',
        },
      };
    }
    const numberColumnConfigItem = columnsConfig.find((cfg) => cfg.columnName === 'serial');
    if (!numberColumnConfigItem || !numberColumnConfigItem.hide) {
      mapTableHeaderConfig['serialNumber'] = serialHeaderConfig;
      // 插入序号
      const serialNumberColumn = {
        dataIndex: 'serialNumber',
        extraKey: 'all',
        key: 'serialNumber',
        title: '序号',
        className: 'ant-table-column-serialNumber',
        sorter: false,
        onCell: (record, rowIndex) => ({
          cellVal: (pagination.pageNo - 1) * pagination.pageSize + rowIndex + 1,
          className: withStyle && isTopThree(rowIndex) ? `serialNumber-${rowIndex} serialNumberBase` : 'serialNumberBase',
          config: serialColumnStyle,
        }),
        width: serialColumnStyle.style.width,
      };
      tableColumns.push(serialNumberColumn);
      calculatedTotalWidth += serialNumberColumn.width;
    }
  }

  if (currentCompareFilterConfig) {
    // 开启对比后插入对比列
    const { current, compare } = getCompareText(currentCompareFilterConfig);
    const width = Math.max(getTextWidth(current), getTextWidth(compare), getTextWidth(currentCompareFilterConfig.filed.fieldDisplay));
    const compareColumn = {
      dataIndex: currentCompareFilterConfig.filed.fieldName,
      extraKey: 'all',
      key: currentCompareFilterConfig.filed.fieldName,
      title: currentCompareFilterConfig.filed.fieldDisplay,
      sorter: false,
      render: () => {
        return (
          <Fragment>
            <div>{current}</div>
            <div>{compare}</div>
          </Fragment>
        );
      },
      onCell: () => {},
      width: width,
    };
    tableColumns.push(compareColumn);
    calculatedTotalWidth += width;
    // 格式化对比数据
    compareDataFormat = getCompareDataFormat(compareData, dimensions);
  }
  function getCompareDataFormat(compareData, dimensions) {
    const dimKeys = dimensions.map((item) => item.randomName || item.formula);
    const dataObj = {};
    compareData.forEach((item) => {
      let currentKey = '';
      dimKeys.forEach((e) => {
        currentKey += item[e];
      });
      dataObj[currentKey] = item;
    });
    return dataObj;
  }

  const dimensionsDisplay = getCartesianChartMetrics(dimensions);
  const metricsDisplay = getCartesianChartMetrics(metrics);
  dimensionsDisplay.forEach((dimension) => {
    const { field, format, fieldDisplay, randomName, displayName, workViewParamId } = dimension;
    const name = decodeMetricName(dimension.name);
    if (!linkRegex.test(fieldDisplay || name)) {
      const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || name;
      const column = {
        originalName: name,
        key: randomName || name,
        title:
          field && field.desc ? (
            <Fragment>
              {headerText}
              <Tooltip title={field.desc} placement="top">
                <Icon className="headerIcon" type="info-circle" />
              </Tooltip>
            </Fragment>
          ) : (
            headerText
          ),
        workViewParamId,
        dataIndex: randomName || name,
        sortOrder: defaultOrderKey && (defaultOrderKey === randomName || defaultOrderKey === name) && defaultOrder,
      };
      if (autoMergeCell) {
        column.render = (text, _, idx) => {
          // dimension cells needs merge
          const rowSpan = cellSpanMap[randomName || name][idx];
          return rowSpan === 1 ? text : { children: text, props: { rowSpan } };
        };
      }
      let headerConfigItem = null;
      findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
        headerConfigItem = config;
      });
      const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
      if (!columnConfigItem || !columnConfigItem.hide) {
        const isFixed = columnConfigItem && columnConfigItem.style && columnConfigItem.style.inflexible;
        if (isFixed) {
          column.width = fixedColumnInfo[column.key] = columnConfigItem.style.width;
          fixedTotalWidth += column.width;
        }
        //列宽度计算，会引发横向滚动条出现
        else {
          column.width = getDataColumnWidth(name, columnConfigItem, format, data);
          let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, headerText) + 14;
          if (dimension.field && dimension.field.desc) {
            headerWidth += 14 + 16;
          }
          if (columnConfigItem && columnConfigItem.sort) {
            headerWidth += 30;
          }
          column.width = Math.max(+column.width, headerWidth, 96);
        }
        calculatedTotalWidth += column.width;

        column.sorter = !headerClick && (!columnConfigItem || typeof columnConfigItem.sort === 'undefined' ? true : columnConfigItem.sort);
        mapTableHeaderConfig[randomName || name] = headerConfigItem;
        if (!linkKey) {
          column.onCell = (record) => ({
            config: columnConfigItem,
            format,
            record,
            cardId: props.id,
            cardRefreshWithoutConfig: props.cardRefreshWithoutConfig,
            keysDisplay: dimensionsDisplay.concat(metricsDisplay),
            cellVal: record[randomName || name],
            cellValRange: null,
          });
        } else {
          const linkKeyCopy = linkKey;
          linkKey = '';
          column.onCell = (record) => ({
            config: columnConfigItem,
            format,
            cellVal: record[randomName || name],
            cellValRange: null,
            linkKey: linkKeyCopy,
            record,
          });
        }
        tableColumns.push(column);
      }
    } else {
      linkKey = randomName || name;
    }
  });
  metricsDisplay.forEach((metric) => {
    const { name, field, format, agg, fieldDisplay, randomName, displayName, id, workViewParamId } = metric;
    let expression = decodeMetricName(name);
    // 是否使用行列
    if (!showRows) {
      if (!linkRegex.test(fieldDisplay || name)) {
        if (!withNoAggregators) {
          expression = randomName || `${agg}(${expression})`;
        }
        const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || expression;
        let column = {
          originalName: name,
          key: expression,
          workViewParamId,
          title:
            field && field.desc ? (
              <Fragment>
                {headerText}
                <Tooltip title={field.desc} placement="top">
                  <Icon className="headerIcon" type="info-circle" />
                </Tooltip>
              </Fragment>
            ) : (
              headerText
            ),
          dataIndex: expression,
          sortOrder: defaultOrderKey && (defaultOrderKey === randomName || defaultOrderKey === name) && defaultOrder,
        };
        let headerConfigItem = null;
        findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
          headerConfigItem = config;
        });
        const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
        if (!columnConfigItem || !columnConfigItem.hide) {
          const isFixed = columnConfigItem && columnConfigItem.style && columnConfigItem.style.inflexible;
          if (isFixed) {
            column.width = fixedColumnInfo[column.key] = columnConfigItem.style.width;
            fixedTotalWidth += column.width;
          }
          //列宽度计算
          else {
            column.width = getDataColumnWidth(expression, columnConfigItem, format, data);
            let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, headerText) + 14;
            if (metric.field && metric.field.desc) {
              headerWidth += 14 + 16;
            }
            column.width = Math.max(+column.width, headerWidth, 96);
          }
          calculatedTotalWidth += column.width;

          column.sorter = !headerClick && (!columnConfigItem || typeof columnConfigItem.sort === 'undefined' ? true : columnConfigItem.sort);
          mapTableHeaderConfig[expression] = headerConfigItem;
          if (!linkKey) {
            column.onCell = (record) => ({
              config: columnConfigItem,
              format,
              record,
              data,
              cardId: id,
              cardRefreshWithoutConfig: props.cardRefreshWithoutConfig,
              keysDisplay: dimensionsDisplay.concat(metricsDisplay),
              cellVal: record[expression],
              cellValRange: getTableCellValueRange(data, expression, columnConfigItem),
              currentCompareFilterConfig,
              dimensions,
              compareDataFormat,
              expression,
            });
          } else {
            const linkKeyCopy = linkKey;
            const linkAggCopy = linkAgg;
            linkKey = '';
            linkAgg = '';
            column.onCell = (record) => ({
              config: columnConfigItem,
              format,
              cellVal: record[expression],
              cellValRange: getTableCellValueRange(data, expression, columnConfigItem),
              linkKey: linkKeyCopy,
              linkAgg: linkAggCopy,
              record,
            });
          }

          tableColumns.push(column);
        }
      } else {
        linkKey = expression;
        randomName && (linkAgg = agg);
      }
    } else {
      if (!withNoAggregators) {
        expression = randomName || `${agg}(${expression})`;
      }
      const headerText = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || expression;
      const columnConfigItem = columnsConfig.find((cfg) => cfg.columnName === (randomName || name));
      if (!columnConfigItem || !columnConfigItem.hide) {
        let columnWidth = 0;
        let headerConfigItem = null;
        findChildConfig(headerConfig, 'headerName', 'children', randomName || name, (config) => {
          headerConfigItem = config;
        });
        secondKeys.map((i) => {
          let key = `${i}^$${expression}`;
          let width = getDataColumnWidth(key, columnConfigItem, format, dataSource);
          let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, i) + 19;
          width = width > headerWidth ? width : headerWidth;
          columnWidth += width;
        });
        const column = {
          key: expression,
          workViewParamId,
          title:
            field && field.desc ? (
              <Fragment>
                {headerText}
                <Tooltip title={field.desc} placement="top">
                  <Icon className="headerIcon" type="info-circle" />
                </Tooltip>
              </Fragment>
            ) : (
              headerText
            ),
          dataIndex: expression,
          width: columnWidth,
          align: 'center',
          originalName: name,
          children: secondKeys.map((i) => {
            let key = `${i}^$${expression}`;
            let width = getDataColumnWidth(key, columnConfigItem, format, dataSource);
            let headerWidth = computeCellWidth(headerConfigItem && headerConfigItem.style, i) + 19;
            width = width > headerWidth ? width : headerWidth;
            return {
              title: i,
              key: key,
              dataIndex: key,
              sorter: (a, b) => {
                return a[key] - b[key];
              },
              onCell: (record) => {
                return {
                  config: columnConfigItem,
                  format,
                  record,
                  cardId: props.id,
                  cardRefreshWithoutConfig: props.cardRefreshWithoutConfig,
                  keysDisplay: dimensionsDisplay.concat(metricsDisplay),
                  cellVal: record[key],
                  cellValRange: getTableCellValueRange(dataSource, key, columnConfigItem),
                  currentCompareFilterConfig,
                  expression: key,
                };
              },
              width,
            };
          }),
        };
        calculatedTotalWidth += columnWidth;
        tableColumns.push(column);
      }
    }
  });

  // adjust column width
  const flexibleTotalWidth = calculatedTotalWidth - fixedTotalWidth;
  const flexibleContainerWidth = width - fixedTotalWidth;
  const containerWidthRatio = flexibleTotalWidth < flexibleContainerWidth ? flexibleContainerWidth / flexibleTotalWidth : 1;
  tableColumns.forEach((column) => {
    if (fixedColumnInfo[column.key] === void 0) {
      if (column.dataIndex === 'serialNumber') {
        return;
      }
      // Math.floor to avoid creating float column width value and scrollbar showing
      // not use Math.ceil because it will exceed the container width in total
      column.width = Math.floor(containerWidthRatio * Number(column.width));
    }
  });

  const groupedColumns = [];
  traverseConfig(headerConfig, 'children', (currentConfig) => {
    const { key, isGroup, headerName, style } = currentConfig;
    if (!isGroup) {
      return;
    }

    const childrenConfig = currentConfig.children.filter(
      ({ isGroup, key, headerName }) =>
        (!isGroup && tableColumns.findIndex((col) => col.key === headerName || col.originalName === headerName) >= 0) ||
        (isGroup && groupedColumns.findIndex((col) => col.key === key) >= 0)
    );
    if (!childrenConfig.length) {
      return;
    }

    const groupedColumn = {
      key,
      title: headerName,
      width: 0,
    };
    const childrenCell = [];

    mapTableHeaderConfig[key] = currentConfig;

    // childrenConfig.sort((cfg1, cfg2) => {
    //   if (cfg1.isGroup || cfg2.isGroup) {
    //     return 0;
    //   }
    //   const cfg1Idx = tableColumns.findIndex((column) => column.key === cfg1.headerName);
    //   const cfg2Idx = tableColumns.findIndex((column) => column.key === cfg2.headerName);
    //   return cfg1Idx - cfg2Idx;
    // });

    let insertIdx = Infinity;
    childrenConfig.forEach(({ isGroup, key, headerName }) => {
      const columnIdx = tableColumns.findIndex((column) =>
        column.children && !showRows ? column.key === key : column.key === headerName || column.originalName === headerName
      );
      insertIdx = Math.min(insertIdx, columnIdx);
      childrenCell.push(tableColumns[columnIdx]);
      groupedColumn.width = +groupedColumn.width + +tableColumns[columnIdx].width;
      tableColumns.splice(columnIdx, 1);
    });
    if (!showRows) {
      groupedColumn.onCell = (record) => ({ record, childrenCell });
    } else {
      const children = JSON.parse(JSON.stringify(childrenCell[0].children));
      children.forEach((item, index) => {
        item.onCell = (record) => ({
          record,
          childrenCell: childrenCell.map((i) => i.children[index]),
        });
      });
      groupedColumn.children = children;
      groupedColumn.align = 'center';
    }
    tableColumns.splice(insertIdx, 0, groupedColumn);
    groupedColumns.push(groupedColumn);
  });

  const left = [];
  const middle = [];
  const right = [];
  tableColumns.forEach((column, index) => {
    const name = column.children && column.children.length ? column.title : column.originalName;
    if (leftFixedColumns.includes(name)) {
      column.fixed = 'left';
      left.push(column);
    } else if (rightFixedColumns.includes(name)) {
      column.fixed = 'right';
      right.push(column);
    } else {
      //  将序号列放到首位
      if (leftFixedColumns.length && column.key === 'serialNumber') {
        column.fixed = 'left';
        left.unshift(column);
      } else {
        middle.push(column);
      }
    }
  });
  tableColumns = left.concat(middle.concat(right));
  return { tableColumns, mapTableHeaderConfig, containerWidthRatio };
}

function getPaginationOptions(props) {
  const { chartStyles, width, pagination } = props;
  // fixme
  let pageNo = void 0;
  let pageSize = void 0;
  let totalCount = void 0;
  if (pagination) {
    pageNo = pagination.pageNo;
    pageSize = pagination.pageSize;
    totalCount = pagination.totalCount;
  }
  // const { pageNo, pageSize, totalCount } = pagination
  const { pageSize: initialPageSize } = chartStyles.table;

  const paginationOptions = {
    current: pageNo,
    pageSize: pageSize || +initialPageSize,
    total: totalCount,
    simple: bodyWidth <= 768,
  };
  return paginationOptions;
}

function getAggData(data, props) {
  // 聚合显示
  const { cols, rows, metrics, compareData, id } = props;
  const fillEmptyKeys = [];
  const currentCompareFilterConfig = toJS(BoardStore.compareFilterConfig[id]); //  对比配置
  if (rows.length) {
    const rowsKey = rows[0].randomName || rows[0].name;
    const metricsKeys = metrics.map((item) => item.randomName || `${item.agg}(${decodeMetricName(item.name)})`);
    const colsKeys = cols.map((item) => item.randomName || item.name);
    const keyList = colsKeys.concat(metricsKeys);
    let dataObj = getAggDataObj(data, colsKeys, rowsKey, metricsKeys, keyList);
    if (currentCompareFilterConfig) {
      // 开启对比后
      let compareDataObj = getAggDataObj(compareData, colsKeys, rowsKey, metricsKeys, keyList);
      for (const key in dataObj) {
        if (compareDataObj[key]) {
          for (const i in compareDataObj[key]) {
            !keyList.includes(i) && (dataObj[key][`compare_${i}`] = compareDataObj[key][i]);
          }
          delete compareDataObj[key];
        }
      }
      for (const key in compareDataObj) {
        for (const i in compareDataObj[key]) {
          !keyList.includes(i) && (compareDataObj[key][`compare_${i}`] = compareDataObj[key][i]);
          delete compareDataObj[key][i];
        }
      }

      dataObj = Object.assign(dataObj, compareDataObj);
    }
    const result = [];
    for (let key in dataObj) {
      keyList.concat(fillEmptyKeys).forEach((item) => {
        !dataObj[key][item] && (dataObj[key][item] = '');
      });
      result.push(dataObj[key]);
    }
    return result;
  }
  function getAggDataObj(data, colsKeys, rowsKey, metricsKeys, keyList) {
    const dataObj = {};
    //把同一行的数据归类
    data.forEach((item) => {
      let key = getDataObjKey(item, colsKeys);
      let dataItem = {};
      for (let key in item) {
        const fillEmptyKey = `${item[rowsKey]}^$${key}`;
        metricsKeys.indexOf(key) > -1 && (dataItem[fillEmptyKey] = item[key]);
        fillEmptyKeys.push(fillEmptyKey);
      }
      dataObj[key]
        ? (dataObj[key] = { ...dataObj[key], ...dataItem })
        : (dataObj[key] = {
            ...getDataObjItem(item, keyList),
            ...dataItem,
          });
    });
    return dataObj;
  }
}

function getCompareData(props) {
  // 组装对比数据
  const { cols, rows, metrics, data, compareData } = props;
  const dimensions = cols.concat(rows);
  const dimKeys = dimensions.map((item) => item.randomName || item.formula);
  const metricKeys = metrics.map((item) => item.randomName || `${item.agg}(${item.formula})`);
  const dataObj = {};
  compareData.forEach((item) => {
    let currentKey = getDimValuekey(item, dimKeys);
    dataObj[currentKey] = item;
  });
  const dataFormatted = data.map((item) => {
    let currentKey = getDimValuekey(item, dimKeys);
    if (dataObj[currentKey]) {
      metricKeys.forEach((key) => {
        item[`compare_${key}`] = dataObj[currentKey][key];
      });
      delete dataObj[currentKey];
    }
    return item;
  });
  for (const key in dataObj) {
    const item = JSON.parse(JSON.stringify(dataObj[key]));
    metricKeys.forEach((m) => {
      item[`compare_${m}`] = dataObj[key][m];
      delete item[m];
    });

    dataFormatted.push(item);
  }
  return dataFormatted;
}

function getDimValuekey(record, keys) {
  let currentKey = '';
  keys.forEach((e) => {
    currentKey += record[e];
  });
  return currentKey;
}

function getDataObjKey(data, colsKeys) {
  let key = '';
  colsKeys.forEach((item) => {
    key += data[item];
  });
  return key;
}
function getDataObjItem(data, keyList) {
  let obj = {};
  keyList.forEach((item) => {
    obj[item] = data[item];
  });
  return obj;
}
