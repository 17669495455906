import { Col, Row, Dropdown, Menu, Button, Icon } from 'antd';
import { observer } from 'mobx-react';
import { autorun, toJS } from 'mobx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import pageHeaderStore from '../PageHeader/PageHeaderStore';
import './NavbarTop.sass';
import sidebarStore from '../Sidebar/store';
import MobilePopover from '../../component/MobilePopover/index';
import manageUtil from '../../Product/Management/util';
import AuthorityStore from '../../AuthorityStore/store';
import intl from 'react-intl-universal';
import Notification from './Notification/index';
import { isTopMenuActivity, BDPermissions, onlyMainCountKeys, unlimitKeys } from '../../config/sideMenu';
import { detailPaths } from '../../config';
import allMenus from '../../config/sideMenu';
import util from '../../util';

const langObj = { 'zh-CN': '中文', 'en-US': 'English' };
@withRouter
@observer
class NavbarTop extends Component {
  constructor(props) {
    super(props);
    this.status = this.props.status;
    // 是否是首次登陆的标志
    this.state = {
      linkConfig: [],
      pathName: this.props.location.pathname,
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.disposer = autorun(() => {
      // 遍历菜单列表，根据权限生成每个按钮跳转的链接
      const links = Object.keys(sidebarStore.sidebarAllMenu).map((key) => {
        return this.getLink(sidebarStore.sidebarAllMenu[key]);
      });
      this.setState({
        linkConfig: links,
      });
    });
  }

  componentDidUpdate(preProps) {
    if (this.props.location.pathname !== preProps.location.pathname && detailPaths.indexOf(this.props.location.pathname) < 0) {
      this.setState({
        pathName: this.props.location.pathname,
      });
    }
  }

  componentWillUnmount() {
    this.disposer();
  }

  getLink(menu) {
    let selectedMenu = [];
    menu.forEach((item) => {
      if (!selectedMenu.length && (!BDPermissions[process.env.REACT_APP_BDkey] || BDPermissions[process.env.REACT_APP_BDkey].indexOf(item.limitKey) > -1)) {
        if (item.children) {
          item.children.forEach((el) => {
            (AuthorityStore.checkAuthorityExist(item.key, el.key, 'view') || unlimitKeys.indexOf(el.key) > -1) &&
              (!BDPermissions[process.env.REACT_APP_BDkey] || BDPermissions[process.env.REACT_APP_BDkey].indexOf(el.limitKey) > -1) &&
              (AuthorityStore.isMainAccount || (onlyMainCountKeys.indexOf(el.key) < 0 && el.show)) &&
              selectedMenu.push(el);
          });
        } else {
          item.show &&
            (AuthorityStore.checkAuthorityExist(item.key, item.limitKey, 'view') || unlimitKeys.indexOf(item.limitKey) > -1) &&
            (AuthorityStore.isMainAccount || onlyMainCountKeys.indexOf(item.limitKey) < 0) &&
            selectedMenu.push(item);
        }
      }
    });
    return !selectedMenu.length ? null : selectedMenu[0].href;
  }

  // 登出
  logout = (e) => {
    e.domEvent.preventDefault();
    pageHeaderStore.reset();
    util.toSSOLogout();
  };

  render() {
    const { pathName, linkConfig } = this.state;
    // 个人菜单
    const personMenu = (
      <Menu>
        {allMenus.account.map((item) => {
          return item.children.map((i) => {
            return (
              i.show &&
              (AuthorityStore.checkAuthorityExist(item.key, i.key, 'view') || unlimitKeys.indexOf(i.key) > -1) && (
                <Menu.Item
                  title={i.name}
                  key={i.name}
                  onClick={() => {
                    sidebarStore.resetSelectkey();
                  }}>
                  <Link to={i.href}>{intl.get(i.name) || i.name}</Link>
                </Menu.Item>
              )
            );
          });
        })}
        <Menu.Item onClick={this.logout}>退出</Menu.Item>
      </Menu>
    );
    // 判断不同的状态来更换头部导航栏
    const LinkConfigLeftSide = [
      {
        key: 'data',
        className: `${isTopMenuActivity('boardData', pathName) && 'active-text'}`,
        pathTo: linkConfig[0],
        linkContent: intl.get('data'),
      },
      {
        key: 'analytics',
        className: `${isTopMenuActivity('boardAnalyse', pathName) && 'active-text'}`,
        pathTo: linkConfig[1],
        linkContent: intl.get('analysis'),
      },
      {
        key: 'insight',
        className: `${(pathName.includes('customBoard') || pathName.includes('customDetail') || pathName.includes('realtime')) && 'active-text'}`,
        linkContent: (
          <span
            onClick={() => {
              // manageUtil.changeLinkPath(this.props.history);
              sidebarStore.refreshFolderList(1);
            }}>
            {intl.get('insight')}
          </span>
        ),
      },
      {
        key: 'action',
        className: `${isTopMenuActivity('/action', pathName) && 'active-text'}`,
        pathTo: linkConfig[3],
        linkContent: intl.get('action'),
      },
    ];

    const LinkConfigRightSide = [
      {
        key: 'user',
        show: toJS(AuthorityStore.authorityMenu) && AuthorityStore.checkAuthorityRoute('user'),
        pathTo: '/person/workbench',
        linkContent: (
          <Dropdown overlay={personMenu}>
            <i
              title={intl.get('personalCenter')}
              className={`iconfont icon-gerenzhongxin1 navbar-icon-color lg-icon ${
                pathName.indexOf('/person') === 0 ||
                pathName.indexOf('/audit') === 0 ||
                pathName.indexOf('/share') === 0 ||
                pathName.indexOf('/log') === 0 ||
                pathName.indexOf('/notification') === 0
                  ? 'active'
                  : ''
              }`}
            />
          </Dropdown>
        ),
      },
      {
        key: 'help',
        show: true,
        linkContent: (
          <a href={process.env.REACT_APP_Docs_Link}>
            <i title={intl.get('help')} className={`navbar-icon-color iconfont icon-bangzhu1 lg-icon `} />
          </a>
        ),
      },
      {
        key: 'set',
        show: toJS(AuthorityStore.authorityMenu) && AuthorityStore.checkAuthorityRoute('set'),
        pathTo: linkConfig[4],
        linkContent: (
          <i
            title={intl.get('setting')}
            className={`iconfont icon-shezhi1 navbar-icon-color lg-icon ${pathName.indexOf('/product') === 0 ? 'active' : ''} ${
              pathName.indexOf('/set') === 0 ? 'active' : ''
            }`}
          />
        ),
      },
      {
        key: 'notification',
        show: true,
        linkContent: <Notification />,
      },
    ];
    // 判断是否是boss看板如果是则最顶部导航栏消失
    const leftSides = LinkConfigLeftSide;
    const rightSides = LinkConfigRightSide;
    const exMenu = toJS(AuthorityStore.authorityMenu) ? (
      <Menu>
        {toJS(AuthorityStore.authorityMenu) &&
          (AuthorityStore.checkAuthorityRoute('data') ? (
            <Menu.Item key="data">
              <NavLink to={linkConfig[0]}>体验管理平台</NavLink>
            </Menu.Item>
          ) : AuthorityStore.checkAuthorityRoute('analytics') ? (
            <Menu.Item key="analytics">
              <NavLink to={linkConfig[1]}>体验管理平台</NavLink>
            </Menu.Item>
          ) : AuthorityStore.checkAuthorityRoute('action') ? (
            <Menu.Item key="action">
              <NavLink to={linkConfig[3]}>体验管理平台</NavLink>
            </Menu.Item>
          ) : AuthorityStore.checkAuthorityRoute('insight') ? (
            <Menu.Item key="action">
              <a
                onClick={() => {
                  sidebarStore.refreshFolderList(1);
                }}>
                体验管理平台
              </a>
            </Menu.Item>
          ) : null)}
        {AuthorityStore.checkAuthorityRoute('wj') && (
          <Menu.Item key="wj">
            <NavLink to="/wj/list">问卷</NavLink>
          </Menu.Item>
        )}
        {process.env.REACT_APP_Show_Journey && [
          AuthorityStore.checkAuthorityRoute('design', 'customerJourney') && (
            <Menu.Item key="journey">
              <div
                onClick={() => {
                  sidebarStore.refreshFolderList(2);
                }}>
                用户旅程地图
              </div>
            </Menu.Item>
          ),
          AuthorityStore.checkAuthorityRoute('design', 'blueprint') && (
            <Menu.Item key="blueprint">
              <div
                onClick={() => {
                  sidebarStore.refreshFolderList(3);
                }}>
                服务蓝图
              </div>
            </Menu.Item>
          ),
        ]}
      </Menu>
    ) : null;
    const exMenuText = pathName.includes('/design/customerJourney')
      ? '用户旅程地图'
      : pathName.includes('/design/blueprint')
      ? '服务蓝图'
      : pathName.includes('/wj')
      ? '问卷'
      : '体验管理平台';
    return (
      <div id="nav">
        {/* className={`${pageHeaderStore.isRestorePosition ? '' : ' top'}`} */}
        <div className="container">
          <Row>
            <Col className="pull-left">
              <a className={`navbar-brand ${process.env.REACT_APP_BDkey ? `${process.env.REACT_APP_BDkey}-style` : ''}`} href={process.env.REACT_APP_Base_Url}>
                <img src={require(`../../static/images/${process.env.REACT_APP_BDlogo}.png`).default} alt="cemyun" className="cemyun-logo" />
                <img src={require(`../../static/images/${process.env.REACT_APP_BDlogo}_sm.png`).default} alt="cemyun" className="visible-xs cemyun-logo1" />
              </a>
              <div className="trigger">
                <i className="iconfont icon-caidan" onClick={sidebarStore.collapsedChange} style={{ fontSize: '24px' }} />
              </div>
              <div className="active-cemyun">
                <ul>
                  {!pathName.includes('/design') &&
                    !pathName.includes('/wj') &&
                    leftSides.map((item) => {
                      if (
                        (toJS(AuthorityStore.authorityMenu) &&
                          // all全部权限 或者 （有当前的二级菜单的key 或者是帮助文档）
                          AuthorityStore.checkAuthorityRoute(item.key)) ||
                        item.key === 'download'
                      ) {
                        return (
                          <li key={`menu-${item.key}`} className={item.className}>
                            {item.pathTo ? <NavLink to={item.pathTo}>{item.linkContent}</NavLink> : item.linkContent}
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            </Col>
            {toJS(AuthorityStore.authorityMenu) &&
              (AuthorityStore.checkAuthorityRoute('wj') ||
                AuthorityStore.checkAuthorityRoute('design', 'customerJourney') ||
                AuthorityStore.checkAuthorityRoute('design', 'blueprint')) && (
                <Col className="pull-right nav-top-menu">
                  <Dropdown overlay={exMenu}>
                    <a className={`nav-top-dropdown-trigger ${pathName.includes('/design') || pathName.includes('/wj') ? 'active' : ''}`}>
                      <i className="iconfont icon-gengduochanpin"></i>
                      {exMenuText}
                    </a>
                  </Dropdown>
                </Col>
              )}
            <Col className="pull-right">
              <div className="navbar-collapse collapse">
                <ul className="pull-right nav-top-menu">
                  {rightSides.map((item) => {
                    if (item.show) {
                      if (item.key === 'help') {
                        return process.env.REACT_APP_Docs_Link ? (
                          <li key={`menu-${item.key}`} className={item.className}>
                            {item.pathTo ? <NavLink to={item.pathTo}>{item.linkContent}</NavLink> : item.linkContent}
                          </li>
                        ) : null;
                      } else {
                        return (
                          <li key={`menu-${item.key}`} className={item.className}>
                            {item.pathTo ? <NavLink to={item.pathTo}>{item.linkContent}</NavLink> : item.linkContent}
                          </li>
                        );
                      }
                    }
                  })}
                </ul>
              </div>
            </Col>
            {
              <Col className="pull-right product-span-select">
                <Dropdown
                  ref="dropdown"
                  disabled={pageHeaderStore.dropdownDisabled}
                  className="dropdown"
                  id="product-select-dropdown"
                  overlayClassName="dropdown-menu top-dropdown"
                  trigger={['click']}
                  overlay={
                    <Menu
                      onClick={(e) => {
                        manageUtil.changeLinkPath(this.props.history, e.key);
                      }}>
                      {pageHeaderStore.dropdownList.product.map((item) => {
                        return (
                          <Menu.Item title={item.label} key={item.value}>
                            {item.label}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }>
                  <Button title={pageHeaderStore.getProductTitle}>
                    {pageHeaderStore.getProductTitle}
                    <Icon type="down" className="down" />
                  </Button>
                </Dropdown>
              </Col>
            }
            {
              <Col className="pull-right product-span-select">
                <Dropdown
                  className="dropdown"
                  overlayClassName="dropdown-menu top-dropdown"
                  trigger={['click']}
                  overlay={
                    <Menu
                      onClick={(e) => {
                        this.props.changeLang(e.key);
                      }}>
                      {Object.keys(langObj).map((item) => {
                        return (
                          <Menu.Item title={langObj[item]} key={item}>
                            {langObj[item]}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }>
                  <Button title={langObj[this.props.lang]}>
                    {langObj[this.props.lang]}
                    <Icon type="down" className="down" />
                  </Button>
                </Dropdown>
              </Col>
            }
            <div className="pull-right product-mobileSelect">
              <MobilePopover />
            </div>
          </Row>
        </div>
      </div>
    );
    // 无文字 以及 右侧无设置无帮助图标的导航栏
  }
}

export default NavbarTop;
