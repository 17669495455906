import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, autorun } from 'mobx';
import api from '../../api';
import util from '../../util';
import Loading from '../../component/Loading/Loading';
import TableNullData from '../../component/TableNullData';
import SurveyStatChart from './SurveyStatChart';
import PageHeaderStore from '../../component/PageHeader/PageHeaderStore';
import LazyLoad from 'react-lazyload';
import './index.sass';

@observer
class Survey extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.id ? this.props.id : util.getQueryString('key');
  }
  componentDidMount() {
    const param = { surveyId: this.id };
    this.props.type && (param.type = this.props.type);
    util.get(api.board.getDashboardBySurveyId, param, (res) => {
      this.data = res;
    });
    this.disposer = autorun(() => {
      const date = PageHeaderStore.parameter.date;
      PageHeaderStore.reloadBoard();
    });
  }

  componentWillUnmount() {
    this.disposer();
  }

  @observable data;

  render() {
    return (
      <div style={{ height: '100%' }}>
        {/* <Row style={{ height: '100%' }} type={this.data && this.data.length && !this.props.componentType && 'flex'}> */}
        {this.data ? (
          this.data.length ? (
            this.data.map((item, index) => {
              const customizeParam = item.customizeParam ? JSON.parse(item.customizeParam) : {};
              return (
                <LazyLoad height={300}>
                  {item.title && <h5 className="report-title">{item.title}</h5>}
                  <div className="survey-statistics">
                    <SurveyStatChart param={item} />
                  </div>
                </LazyLoad>
              );
            })
          ) : (
            <TableNullData />
          )
        ) : (
          <Loading />
        )}
        {/* </Row> */}
      </div>
    );
  }
}

export default Survey;
