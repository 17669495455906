import React from 'react';
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/index';
import mathUtil from '../../utils/mathUtil';
import filtersUtil from '../../utils/filtersUtil';
import util from '../../util';
import { computed } from 'mobx';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.parameter = {
      keyDim: util.getQueryString('keyDim'),
    };
    this.setParams(this.parameter.keyDim);
  }
  @computed get linkName() {
    if (this.parameter.keyDim.includes('_group')) {
      return {
        rowLink: 'pageGroup',
      };
    } else {
      return {
        rowLink: 'page',
      };
    }
  }
  setParams(pageType) {
    if (this.parameter.keyDim.includes('visited')) {
      this.fields = 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,exits,exitRate';
    } else if (this.parameter.keyDim.includes('entry')) {
      this.fields = 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,bounces,bounceRate';
    } else if (this.parameter.keyDim.includes('page_group')) {
      this.fields = 'pv,uv,pvContribute,pageVisitAvgTime,loadAvgTime,exits,exitRate,isHasParam';
    }
  }
  addContent = (store) => {
    store.columns[store.field.length - 1].sorter = false;
    store.columns.forEach((item, itemIndex) => {
      if (itemIndex === 1) {
        item.render = (text, record, index) => {
          return {
            children: (
              <div className="childrenContent">
                <div className="rateContent">
                  <div className="rateContent-title" title={text}>
                    {mathUtil.spliceHttpAndDomin(text)}
                  </div>

                  <div className="rateContent-urlName">
                    <Link
                      to={`/data/${this.linkName.rowLink}/detail?keyDim=${filtersUtil.showFilterName(util.getQueryString('keyDim'))}&key=${encodeURIComponent(
                        text
                      )}&name=${encodeURIComponent(store.parentData[index][`${filtersUtil.showFilterName(util.getQueryString('keyDim'))}_name`])}`}
                      title={`${store.parentData[index][`${filtersUtil.showFilterName(util.getQueryString('keyDim'))}_name`]}的页面详情`}>
                      {mathUtil.spliceHttpAndDomin(store.parentData[index][`${filtersUtil.showFilterName(util.getQueryString('keyDim'))}_name`])}
                    </Link>
                  </div>
                </div>
                <div className="eventDetailIcon" title="打开链接">
                  <i className="iconfont icon-yemian_" onClick={() => window.open(`${text}`)} />
                </div>
              </div>
            ),
          };
        };
      }
      if (item.dataIndex === 'isHasParam') {
        item.sorter = false;
        item.render = (text, record, index) => {
          if (text === 1) {
            return {
              children: <i className="iconfont icon-shujumokuai" title="此页面包含参数"></i>,
            };
          } else {
            return null;
          }
        };
      }
    });
  };
  render() {
    return <CommonTable searchInside={true} addContent={this.addContent} fields={this.fields} {...this.props} />;
  }
}

export default Main;
