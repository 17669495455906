import CommonChart from '../../containers/Common/CommonChart';
import CommonSummary from '../../containers/CommonSummary';
import CommonTable from '../../containers/CommonTable';

export default {
  title: '访客分析',
  components: [
    {
      isCard: true,
      title: '访客分析',
      children: [
        {
          component: CommonSummary,
          col: 24,
          props: {
            // fields: 'pv,uvNew,uv,visits,visitorAvgVisits,visitAvgTime,visitAvgPages,bounceRate',
            comparision: 'chain',
          },
        },
        {
          component: CommonChart,
          col: 24,
          props: {
            dims: 'date',
            // fields: 'pv,uvNew,uv,visits,visitorAvgVisits,visitAvgTime,visitAvgPages,bounceRate',
            showAll: true,
          },
        },
      ],
    },
    {
      isCard: true,
      component: CommonTable,
      props: {
        dims: 'date',
        // fields: 'pv,uvNew,uv,visits,visitorAvgVisits,visitAvgTime,visitAvgPages,bounceRate',

        currentPage: 1,
        pageCount: 10,
        showAll: true,
      },
    },
  ],
};
