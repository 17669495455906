import React, { Fragment, Component } from 'react';
import { Select, Button, Input } from 'antd';
import api from '../../../api';
import { getDragItemIconClass } from '../../../containers/BIChart/util';
import intl from 'react-intl-universal';
import { formulaConfig } from '../../../utils/filtersUtil';

const filterOperators = [
  { value: 'in', operator: 'in' },
  { value: '等于', operator: '=' },
  { value: '不等于', operator: '!=' },
  { value: '包含', operator: 'like' },
  { value: '不包含', operator: 'notLike' },
];

const { Option } = Select;

function ModelTagRule(props) {
  const { onChange, ruleContent, tagOptions } = props;
  // const [ruleContent, setRuleContent] = useState(null); // 详情数据

  function addChildrenFilter() {
    triggerChange({ formula: '', value: '', column: '', columnName: '' }, ruleContent.length);
  }

  function deleteFilter(filterIndex) {
    ruleContent.splice(filterIndex, 1);
    onChange(ruleContent);
  }

  function handletargetChange(column, index) {
    triggerChange({ column: column, columnName: column }, index);
  }

  function handleFormulaChange(formula, index) {
    triggerChange({ formula }, index);
  }

  function handleNumberChange(value, index) {
    triggerChange({ value }, index);
  }

  function triggerChange(changedValue, index) {
    ruleContent[index] = { ...ruleContent[index], ...changedValue };
    onChange(ruleContent);
  }

  return (
    <Fragment>
      {ruleContent &&
        ruleContent.map &&
        ruleContent.map((item, index) => {
          return (
            <div key={`form-item-children-${index}`} className="form-item-children">
              <Select
                value={item.columnName || item.column}
                className={`lg-select ${item.columnName || item.column ? 'noError' : ''}`}
                style={{ marginRight: '8px' }}
                onChange={(value) => handletargetChange(value, index)}>
                {tagOptions &&
                  tagOptions.map((e) => {
                    return (
                      <Option key={e.column}>
                        <i className={`field-type-icon iconfont ${getDragItemIconClass(e.visualType)}`} />
                        {e.columnName || e.column}
                      </Option>
                    );
                  })}
              </Select>
              <Select
                value={item.formula}
                className={`sm-select ${item.formula ? 'noError' : ''}`}
                style={{ marginRight: '8px' }}
                onChange={(value) => handleFormulaChange(value, index)}>
                {formulaConfig.map((e) => {
                  return <Option key={e.key}>{e.value}</Option>;
                })}
              </Select>
              <Input
                className={`xs-input ${item.value ? 'noError' : ''}`}
                value={item.value}
                style={{ marginRight: '8px' }}
                onChange={(e) => handleNumberChange(e.target.value, index)}
              />
              {index !== 0 && (
                <i
                  className="iconfont icon-shanchu condition-delete"
                  onClick={() => {
                    deleteFilter(index);
                  }}
                />
              )}
            </div>
          );
        })}
      <Button type="addButton" onClick={addChildrenFilter}>
        +{intl.get('Tianjia')}
        {intl.get('Target')}
      </Button>
    </Fragment>
  );
}

export { ModelTagRule };
