import React, { Component } from 'react';
import { Checkbox, Divider, Popup, SearchBar } from 'antd-mobile';
import { Tag } from 'antd';
import './index.sass';
import { viewToName } from '../../component/PageHeader/GlobalControl/config';

class MobileSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTag: null,
    };
  }
  handleChooseTag = (value) => {
    const { currentTag } = this.state;
    let valueInfo = value;
    if (currentTag === value) {
      valueInfo = null;
    }
    this.setState({
      currentTag: valueInfo,
    });
  };

  handleCloseTag = (value) => {
    const { onDeselect } = this.props;
    onDeselect(value);
    this.setState({
      currentTag: null,
    });
  };

  handleSearch = (value) => {
    const { onSearch } = this.props;
    onSearch(value);
  };

  renderValues = (canOpen) => {
    const { value, isNewControl, multiple, onToggle } = this.props;
    let FinalValue;
    if (isNewControl && !multiple) {
      FinalValue = [value];
    } else {
      FinalValue = value;
    }
    const { currentTag } = this.state;
    return FinalValue.map((i) => {
      return (
        typeof i === 'string' && (
          <Tag
            style={{ marginBottom: '8px', fontSize: '14px' }}
            className={`mobile-v5-tag ${currentTag === i ? 'mobile-v5-tag-close' : ''}`}
            onClick={() => {
              if (!canOpen) {
                onToggle();
              } else {
                this.handleChooseTag(i);
              }
            }}
            key={i}
            closable={currentTag === i}
            onClose={() => this.handleCloseTag(i)}>
            {i}
          </Tag>
        )
      );
    });
  };
  onChange = (val, value) => {
    const { onDeselect, onSelect, isNewControl, multiple, onToggle } = this.props;
    if (val) {
      onSelect(value);
      if (isNewControl && !multiple) {
        onToggle();
      }
    } else {
      onDeselect(value);
    }
  };
  render() {
    let { visible, onToggle, optionList, index, item, value } = this.props;
    return (
      <div>
        {this.renderValues(false)}
        <Popup
          visible={visible}
          onMaskClick={() => {
            onToggle();
          }}
          destroyOnClose>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px' }}>
            <div
              className="MAIN-COLOR"
              onClick={() => {
                onToggle();
              }}>
              取消
            </div>
            <div
              className="MAIN-COLOR"
              onClick={() => {
                onToggle();
              }}>
              确定
            </div>
          </div>
          <div
            style={{
              padding: '5px 10px',
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            {this.renderValues(true)}
          </div>
          <div className="searchBarContainer">
            <SearchBar onChange={(value) => this.handleSearch(value)} />
          </div>
          <div className="checkListContainer">
            {optionList && optionList[index] ? (
              Object.keys(optionList[index]).map((key) => {
                let el = optionList[index];
                return el[key].map((e) => {
                  let info = item.relatedViews[viewToName[key] || key];
                  return (
                    info &&
                    e.value && (
                      <div className="checkbox">
                        <Checkbox
                          className="needsclick"
                          onChange={(val) => {
                            this.onChange(val, e.value);
                          }}
                          defaultChecked={typeof value === 'object' ? value.find((i) => i === e.value) : value === e.value}
                          key={e.value}>
                          {e.value}
                        </Checkbox>
                        <Divider className="divider" />
                      </div>
                    )
                  );
                });
              })
            ) : (
              <div className="empty">加载中...</div>
            )}
          </div>
        </Popup>
      </div>
    );
  }
}

export default MobileSelect;
