import React, { Component } from 'react';
import AnalysePathSankey from './AnalysePathSankey';
import AnalysePathTable from './AnalysePathTable';
import AnalysePathFunnel from './AnalysePathFunnel';
import AnalysePathLine from './AnalysePathLine';
import TableNullData from '../../../component/TableNullData/index';
export default class AnalysePathGroup extends Component {
  componentDidMount() {
    this.props.boardChart && window.Cemyun && window.Cemyun.trackEvent('卡片浏览', { cardName: this.props.name });
  }
  render() {
    return this.props.chartType === 13 ? (
      <AnalysePathSankey
        index={this.props.index}
        dataFromParentData={this.props.dataFromParentData}
        isNotShowOption={true}
        parentData={this.props.parentData}
        pathId={this.props.pathId}
        actionType={this.props.chartType}
        nodeFields={['avgTime', 'conver', 'converRate', 'uvRatio', 'uv', 'uvExits']}
        hasChildrenStep={true}
        count={this.props.count}
        dateObj={this.props.dateObj}
        setDateObj={this.props.setDateObj}
        layoutChangeCount={this.props.layoutChangeCount}></AnalysePathSankey>
    ) : this.props.chartType === 14 ? (
      <AnalysePathFunnel
        dataFromParentData={this.props.dataFromParentData}
        parentData={this.props.parentData}
        pathId={this.props.pathId}
        actionType={this.props.chartType}
        count={this.props.count}
        dateObj={this.props.dateObj}
        setDateObj={this.props.setDateObj}
        layoutChangeCount={this.props.layoutChangeCount}></AnalysePathFunnel>
    ) : this.props.chartType === 1 ? (
      <AnalysePathLine
        dims={this.props.dims}
        index={this.props.index}
        dataFromParentData={this.props.dataFromParentData}
        parentData={this.props.parentData}
        pathId={this.props.pathId}
        actionType={this.props.chartType}
        count={this.props.count}
        dateObj={this.props.dateObj}
        setDateObj={this.props.setDateObj}
        layoutChangeCount={this.props.layoutChangeCount}></AnalysePathLine>
    ) : this.props.chartType === 8 ? (
      <AnalysePathTable
        index={this.props.index}
        dataFromParentData={this.props.dataFromParentData}
        fetchData={this.props.fetchData}
        parentData={this.props.parentData}
        pathId={this.props.pathId}
        count={this.props.count}
        actionType={this.props.chartType}
        expandContentParam={this.props.paramObj}
        getFieldByRes={true}
        dims={this.props.dims}
        dateObj={this.props.dateObj}
        setDateObj={this.props.setDateObj}
        layoutChangeCount={this.props.layoutChangeCount}></AnalysePathTable>
    ) : (
      <TableNullData nullDataType="analysePath"></TableNullData>
    );
  }
}
