const config = {
  terminal_os: {
    filterName: 'terminal_os_version',
  },
  terminal_browser: {
    filterName: 'terminal_browser_version',
  },
  terminal_type: {
    filterName: 'terminal_model',
  },
  pageset_visited: {
    filterName: 'page_visited',
  },
  page_group: {
    filterName: 'page_visited',
  },
  pageset_entry: {
    filterName: 'page_entry',
  },
  channel_link: {
    filterName: 'channel_link_info',
  },
};
export const formulaConfig = [
  { key: '=', value: '等于' },
  { key: '<', value: '小于' },
  { key: '!=', value: '不等于' },
  { key: '>', value: '等于' },
  { key: '<=', value: '小于等于' },
  { key: '>=', value: '大于等于' },
  { key: 'in', value: '包含' },
  { key: '!in', value: '不包含' },
  { key: '!contain', value: '包含' },
  { key: 'contain', value: '不包含' },
  { key: 'startsWith', value: '开始于' },
  { key: 'endsWith', value: '结束于' },
];

export default {
  showFilterName(dimName) {
    let filterName;
    Object.keys(config).forEach((key) => {
      if (key === dimName) {
        filterName = config[key].filterName;
      }
    });
    return filterName;
  },
  showFormulaName(formula) {
    let formulaName;
    formulaConfig.forEach((item) => {
      if (item.key === formula) {
        formulaName = item.value;
      }
    });
    return formulaName;
  },
};
