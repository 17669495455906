import mathUtil from './mathUtil';
import legendUtil from './legendUtil';

export default {
  //  @addContentByConfig
  //  @desc 一个addContent的配置解析方法
  //  @param {object} store - table中的store
  //  @param {object} config - 传进来的config
  /*
        实例数据格式:
        [
           {

              currentRowData: 'createdAt',//指标名
              （string函数接受三个参数: text文本名称，index下标名，store为table中store）
              string: (text, index, store) => {
                
                let { year, monthValue, dayOfMonth, hour, minute, second } = store.parentData[index].createdAt;
                return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
              },
           }
          ,
           {

              currentRowData: 'createdAt',//指标名
              string函数接受三个参数: text文本名称，index下标名，store为table中store
              children: (store, text, record, index, item) => {
                
                let { year, monthValue, dayOfMonth, hour, minute, second } = store.parentData[index].createdAt;
                return moment([year, monthValue - 1, dayOfMonth, hour, minute, second]).format('YYYY-MM-DD HH:mm:ss');
              },
           }
          ...
        ]
      */
  addContentByConfig(store, config) {
    for (let i = 0; i < store.columns.length; i++) {
      const currentRowData = store.columns[i].dataIndex;
      config.forEach((item) => {
        if (item.currentRowData === currentRowData) {
          store.columns[i].render = (text, record, index) => {
            if (item.children) {
              return {
                props: { style: item.style && item.style(store, text, record, index, item), className: record.isChildren ? 'childrenNoSpace' : null },
                children: item.children(store, text, record, index, item),
              };
            }
            if (item.string) {
              return item.string(text, index, store);
            }
            if (item.callback) {
              item.callback(i);
            }
          };
        }
      });
    }
  },
  // 整合数据为同一个表格
  combineEventRes(res, dims, filedsArr) {
    if (res === null || res.data.length === 0) {
      return { data: [] };
    }
    // “事件名,指标名”
    // const filedsArr = mathUtil.duplicateRemove(util.customFlat(eventAndTarget.map((item) => this.setFieldByType(item)), 1));
    // 维度数组
    const dimsArray = dims.split(',');
    return this.formateTableRes(filedsArr, dimsArray, res);
  },
  combineSurveyRes(res, dims, filedsArr) {
    if (res === null || res.data.length === 0 || JSON.stringify(res.data) === '{}') {
      return { data: [] };
    }
    // 维度数组
    const dimsArray = dims.split(',');
    return this.formateTableRes(filedsArr, dimsArray, res);
  },
  formateTableRes(filedsArr, dimsArray, res) {
    let dimsKey = [];
    let axis = [];
    let data = [];
    // 拼接非时间维度的作为一个唯一的维度名方便接下来的查找
    const dimLegend = function(data, type) {
      const newDimsArray = dimsArray.filter((item) => !item.includes('date_'));
      if (type === 'string') {
        let legendStr = '';
        newDimsArray.forEach((item, index) => {
          const target = item.includes('page_') ? data[`${item}_key`] : data[`${item}_name`];
          if (index === 0) {
            legendStr = legendStr.concat(target);
          } else {
            legendStr = legendStr.concat(',', target);
          }
        });

        return legendStr;
      }

      return newDimsArray;
    };
    // 遍历data，重新修改源数据
    res.data.forEach((item) => {
      // 抽取日期
      // 维度中存在日期才提取日期
      if (dimsArray[0].includes('date')) {
        axis.push(item[`${dimsArray[0]}_name`]);
      }
      // 抽取不同事件和指标进行拼接作为区分
      filedsArr.forEach((filedItem) => {
        // 如果条件吻合
        if (item.analyticsName === `${filedItem.split(',')[0]}` && item.hasOwnProperty(`${filedItem.split(',')[1]}`))
          if (dimsArray.length > 1) {
            // 多个维度
            // 对象中新建"（维度1的名字）,(维度2的名字）......"的键名 和相对应的值
            item[`${dimLegend(item).join(',')}`] = dimLegend(item, 'string');
            item[`${filedItem}`] = item[`${filedItem.split(',')[1]}`];
            // 把所有的维度拼接成的名字放进dimskey中
            dimsKey.push(dimLegend(item, 'string'));
          } else {
            item[filedItem] = item[`${filedItem.split(',')[1]}`];
            dimsKey.push(dimLegend(item, 'string'));
          }
      });
    });

    // 存在时间维度
    if (axis.length > 0) {
      // 维度名去重
      dimsKey = [...mathUtil.duplicateRemove(dimsKey)];
      // 日期去重
      axis = [...mathUtil.duplicateRemove(axis)];
      // 遍历日期

      axis.forEach((axisItem) => {
        // 维度大于1的时候
        if (dimsArray.length > 1) {
          // 拼接好的维度名作为一个唯一的key在data里面比对
          dimsKey.forEach((dimsItem) => {
            let obj = {};
            res.data.forEach((dataItem, dataIndex) => {
              // 如果时间名和维度名一致时候
              if (dataItem[`${dimsArray[0]}_name`] === axisItem && dataItem[`${dimLegend(dataItem).join(',')}`] === dimsItem) {
                // 将他们的对里面的属性拼接起来并去重
                obj = Object.assign(obj, dataItem);
                delete obj[`${dimLegend(dataItem).join(',')}`];
              }
            });
            data.push(obj);
          });
        }
        // 维度为1
        else {
          let obj = {};
          const target = `${dimsArray[0]}_name`;
          res.data.forEach((dataItem, dataIndex) => {
            if (dataItem[target] === axisItem) {
              // 将他们的对里面的属性拼接起来并去重
              obj = Object.assign(obj, dataItem);
            }
          });
          data.push(obj);
        }
      });
    }
    // 不存在时间维度
    else {
      const objData = [];
      mathUtil.duplicateRemove(dimsKey).forEach((keyItem) => {
        let obj = {};
        res.data.forEach((dataItem, dataIndex) => {
          let string = '';
          string = dimsArray
            .map((dimItem, dimIndex) => {
              const dimName = dimItem.includes('page_') ? `${dimItem}_key` : `${dimItem}_name`;

              return dataItem[dimName];
            })
            .join(',');

          if (string === keyItem) {
            obj = Object.assign(obj, dataItem);
          }
        });
        objData.push(obj);
      });

      data = objData;
    }
    return {
      data: data.filter((item) => JSON.stringify(item) !== '{}'),
      page: res.page,
    };
  },
  setRemainRes(data, dims) {
    const newData = data;
    if (dims) {
      if (newData.length > 0) {
        newData.every((item, index) => {
          const newDims = dims.includes(',') ? dims.split(',')[1] : dims;
          if (item[`${newDims}_name`] === '全部用户') {
            const arrItem = newData[index];
            newData.splice(index, 1);
            newData.unshift(arrItem);
            return false;
          }
          return true;
        });
      }
    }

    return newData;
  },
  // 事件分析根据type设定table的field值
  setFieldByType(item) {
    const typeName = (item) => {
      if (item.type === 'funnel') {
        return `漏斗：${item.dimValueName}`;
      }
      if (item.type === 'survey') {
        return `调研问卷：${item.dimValueName}的题目：${item.targetName}`;
      }
      if (item.type === 'view') {
        return `可视化埋点：${item.dimValueName}`;
      }

      return `${item.dimValue}`;
    };
    return item.target.split(',').map((targetItem) => {
      if (targetItem.includes('_num') || targetItem.includes('_string')) {
        return `${typeName(item)},${
          item.paramFormula
            ? item.paramFormula
            : targetItem
                .split('_')
                .slice(0, targetItem.split('_').length - 1)
                .join('_')
        }`;
      }
      return `${typeName(item)},${targetItem}`;
    });
  },
  fieldsToTableHeadData(fields, dims) {
    const arr = fields.split(',');

    const fieldsArr = dims ? arr.filter((item, index) => (dims.includes(',') ? !item.includes(`_name`) : !item.includes(`${dims}_name`))) : arr;

    const legendArr = fieldsArr.map((item) => legendUtil.getTitle(item));
    return [legendArr, fieldsArr];
  },
};
