import Subscription from '../../containers/Subscription';

export default {
  title: '订阅列表',
  components: [
    {
      title: '订阅列表',
      isCard: true,
      className: 'single-card',
      children: [{ component: Subscription }],
    },
  ],
};
