import React, { FC, useMemo, useCallback, memo } from 'react';
import classnames from 'classnames';
import { Form, Row, Col, Input, Checkbox, Select, Radio, Icon, Table, Divider, Tooltip, Popconfirm, Button } from 'antd';
import DefaultValue from './DefaultValue';
import { SHOULD_LOAD_OPTIONS, ControlOptionTypes, ControlTypes } from '../../constants';
import { OperatorTypesLocale } from '../../app/operatorTypes';
import { getOperatorOptions } from '../../util';
import { filterSelectOption } from '../../app/util';
import '../../../../../static/less/util.less';
import { toJS } from 'mobx';
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

const ValueForm = ({
  form,
  views,
  formedViews,
  controlBase,
  defaultValueOptions,
  defaultValueLoading,
  onOptionTypeChange,
  onValueViewChange,
  onDefaultValueTypeChange,
  onGetDefaultValueOptions,
  onCommonPropChange,
  onOpenOptionModal,
  onDeleteOption,
}) => {
  const { getFieldDecorator, setFieldsValue } = form;
  const { type, multiple, optionType, valueViewId, customOptions, optionWithVariable } = controlBase;
  const operatorOptions = useMemo(() => getOperatorOptions(type, multiple), [type, multiple]);

  const fieldOptions = useMemo(() => {
    if (optionType === ControlOptionTypes.Manual && valueViewId) {
      Object.keys(formedViews).map((key) => {});
    }
    return optionType === ControlOptionTypes.Manual && valueViewId && formedViews[valueViewId]
      ? Object.keys(formedViews[valueViewId].model).map((name) => {
          return (
            <Option key={name} value={name}>
              {formedViews[valueViewId].model[name].fieldDisplay}
            </Option>
          );
        })
      : [];
  }, [formedViews, optionType, valueViewId]);

  const optionWithVariableChange = useCallback(
    (e) => {
      onCommonPropChange('optionWithVariable', e.target.checked);
    },
    [onCommonPropChange]
  );

  const addOption = useCallback(() => {
    onOpenOptionModal();
  }, [onOpenOptionModal]);

  const editOption = useCallback(
    (index) => () => {
      onOpenOptionModal(index);
    },
    [onOpenOptionModal]
  );

  const columns = [
    {
      title: '文本',
      key: 'text',
      dataIndex: 'text',
      render: (text) => (text.length > 7 ? `${text.substr(0, 7)}...` : text),
    },
    {
      title: '值',
      key: 'value',
      dataIndex: 'value',
      render: (value) => (value.length > 7 ? `${value.substr(0, 7)}...` : value),
    },
  ];

  if (optionWithVariable) {
    columns.push({
      title: '关联变量',
      key: 'variables',
      dataIndex: 'variables',
      render: (variables) => variables && Object.values(variables),
    });
  }

  columns.push({
    title: '操作',
    width: 100,
    className: 'textAlignCenter',
    render: (_, record, index) => (
      <span className="ant-table-action-column">
        <Tooltip title="修改">
          <Button icon="edit" shape="circle" type="ghost" onClick={editOption(index)} />
        </Tooltip>
        <Popconfirm title="确定删除？" placement="bottom" onConfirm={onDeleteOption(record.value)}>
          <Tooltip title="删除">
            <Button icon="delete" shape="circle" type="ghost" />
          </Tooltip>
        </Popconfirm>
      </span>
    ),
  });

  const colSpan = { xxl: 12, xl: 20 };
  const itemCols = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
  };

  const viewChange = (id) => {
    const view = views.find((i) => i.id === id);
    setFieldsValue({ valueViewName: view.name });
    onValueViewChange(id);
  };

  const valueFieldChange = (value) => {
    setFieldsValue({ valueFieldSqlType: formedViews[valueViewId].model[value].sqlType });
  };

  const textFieldChange = (value) => {
    if (!value) {
      setFieldsValue({ textFieldSqlType: undefined });
      return;
    }
    setFieldsValue({ textFieldSqlType: formedViews[valueViewId].model[value].sqlType });
  };

  return (
    <>
      <Divider orientation="left">取值配置</Divider>
      {!!operatorOptions.length && (
        <Row>
          <Col {...colSpan}>
            <FormItem label="对应关系" {...itemCols}>
              {getFieldDecorator('operator', {
                rules: [{ required: true, message: '对应关系不能为空' }],
              })(
                <Select>
                  {operatorOptions.map((o) => (
                    <Option key={o} value={o}>
                      {OperatorTypesLocale[o]}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      )}
      {SHOULD_LOAD_OPTIONS[type] && (
        <>
          <Row
            key="optionType"
            className={classnames({
              hide: type === ControlTypes.TreeSelect,
            })}>
            <Col {...colSpan}>
              <FormItem label="下拉菜单项" {...itemCols}>
                {getFieldDecorator(
                  'optionType',
                  {}
                )(
                  <RadioGroup onChange={onOptionTypeChange}>
                    <Radio value={ControlOptionTypes.Auto}>自动关联</Radio>
                    <Radio value={ControlOptionTypes.Manual}>手动</Radio>
                    <Radio value={ControlOptionTypes.Custom}>自定义</Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </Col>
          </Row>
          {optionType === ControlOptionTypes.Manual && (
            <>
              <Row key="valueViewId">
                <Col {...colSpan}>
                  <FormItem label="数据视图" {...itemCols}>
                    {getFieldDecorator('valueViewId', {
                      rules: [{ required: true, message: '数据视图不能为空' }],
                    })(
                      <Select showSearch placeholder="请手动关联数据视图" onChange={viewChange} filterOption={filterSelectOption}>
                        {views.map(({ id, name }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    )}
                    {getFieldDecorator(`valueViewName`, {})(<Input className={'hide'} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row key="valueField">
                <Col {...colSpan}>
                  <FormItem label="取值字段" {...itemCols}>
                    {getFieldDecorator('valueField', {
                      rules: [{ required: true, message: '取值字段不能为空' }],
                    })(
                      <Select showSearch placeholder="请选择取值字段" onChange={valueFieldChange} filterOption={filterSelectOption}>
                        {fieldOptions}
                      </Select>
                    )}
                    {getFieldDecorator(`valueFieldSqlType`, {})(<Input className={'hide'} />)}
                  </FormItem>
                </Col>
              </Row>
              {type === ControlTypes.TreeSelect && (
                <Row key="parentField">
                  <Col {...colSpan}>
                    <FormItem label="父ID字段" {...itemCols}>
                      {getFieldDecorator('parentField', {
                        rules: [{ required: true, message: '父ID字段不能为空' }],
                      })(
                        <Select showSearch placeholder="请选择父ID字段" filterOption={filterSelectOption}>
                          {fieldOptions}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              )}
              <Row key="textField">
                <Col {...colSpan}>
                  <FormItem label="文本字段" {...itemCols} help="如不设置文本字段，下拉菜单项则默认显示取值字段的值">
                    {getFieldDecorator(
                      'textField',
                      {}
                    )(
                      <Select showSearch placeholder="请选择文本字段" onChange={textFieldChange} filterOption={filterSelectOption} allowClear>
                        {fieldOptions}
                      </Select>
                    )}
                    {getFieldDecorator(`textFieldSqlType`, {})(<Input className={'hide'} />)}
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
          {optionType === ControlOptionTypes.Custom && (
            <>
              <Row>
                <Col {...colSpan}>
                  <FormItem label="选项关联变量" {...itemCols}>
                    {getFieldDecorator('optionWithVariable', {
                      valuePropName: 'checked',
                    })(<Checkbox onChange={optionWithVariableChange} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col {...colSpan}>
                  <FormItem label=" " colon={false} {...itemCols}>
                    <a onClick={addOption}>
                      <Icon type="plus" /> 点击添加
                    </a>
                  </FormItem>
                  <FormItem className={'hide'}>{getFieldDecorator('customOptions', {})(<Input />)}</FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem label=" " colon={false} labelCol={{ xxl: 4, xl: 6, span: 8 }} wrapperCol={{ xxl: 16, xl: 17, span: 18 }}>
                    <Table size="small" rowKey="value" dataSource={customOptions} columns={columns} pagination={false} bordered />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <DefaultValue
        form={form}
        controlBase={controlBase}
        defaultValueOptions={defaultValueOptions}
        defaultValueLoading={defaultValueLoading}
        onDefaultValueTypeChange={onDefaultValueTypeChange}
        onGetDefaultValueOptions={onGetDefaultValueOptions}
      />
    </>
  );
};

export default memo(ValueForm);
