import AnalyseBoardTable from '../../containers/AnalyseBoardTable';
import AnalyseBITable from '../../containers/AnalyseBITable';
import CommonTab from '../../containers/CommonTab';

const AnalyseTableList = {
  sams: ['All', '事件分析', '调研分析', '数据管理', '关键任务'],
  rainbow: ['All', '组合分析', '数据管理'],
};
const obj = {
  title: '分析图表',
  components: [
    {
      isCard: true,
      title: '分析列表',
      component: CommonTab,
      props: {
        tab: [
          // {
          //   name: 'All',
          //   hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('All') < 0,
          //   children: [
          //     {
          //       component: AnalyseBoardTable,
          //       props: {
          //         analyseType: 'chart',
          //       },
          //       col: 24,
          //     },
          //   ],
          // },
          // {
          //   name: '事件分析',
          //   hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('事件分析') < 0,
          //   children: [
          //     {
          //       component: AnalyseBoardTable,
          //       col: 24,
          //       props: {
          //         analyseType: 'eventAnalytics',
          //         type: 1,
          //       },
          //     },
          //   ],
          // },
          {
            name: '数据分析',
            hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('数据管理') < 0,
            children: [
              {
                component: AnalyseBITable,
                col: 24,
                props: {
                  analyseType: 'BIAnalytics',
                  type: 9,
                },
              },
            ],
          },
          {
            name: '关键任务',
            hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('关键任务') < 0,
            children: [
              {
                component: AnalyseBoardTable,
                col: 24,
                props: {
                  analyseType: 'pathAnalytics',
                  type: 2,
                },
              },
            ],
          },
          {
            name: '留存分析',
            hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('留存分析') < 0,
            children: [
              {
                component: AnalyseBoardTable,
                col: 24,
                props: {
                  analyseType: 'remainAnalytics',
                  type: 3,
                },
              },
            ],
          },
          // {
          //   name: '调研分析',
          //   hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('调研分析') < 0,
          //   children: [
          //     {
          //       component: AnalyseBoardTable,
          //       col: 24,
          //       props: {
          //         analyseType: 'surveyAnalytics',
          //         type: 8,
          //       },
          //     },
          //   ],
          // },
          {
            name: '分布分析',
            hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('分布分析') < 0,
            children: [
              {
                component: AnalyseBoardTable,
                col: 24,
                props: {
                  analyseType: 'distributionAnalytics',
                  type: 7,
                },
              },
            ],
          },
          {
            name: '组合分析',
            hide: AnalyseTableList[process.env.REACT_APP_BDkey] && AnalyseTableList[process.env.REACT_APP_BDkey].indexOf('组合分析') < 0,
            children: [
              {
                component: AnalyseBoardTable,
                props: {
                  analyseType: 'combinationAnalytics',
                  type: 14,
                  title: '组合分析',
                },
                col: 24,
              },
            ],
          },
        ],
      },
    },
  ],
};

export default obj;
