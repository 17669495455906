import React, { createRef } from 'react';
import Chart from '../Chart';
import Pivot from '../Pivot';
import { observer } from 'mobx-react';
import ChartTypes from '../config/chart/ChartTypes';
import BIStore from '../store';

@observer
class Widget extends React.Component {
  constructor() {
    super();
    this.container = createRef();
    this.state = {
      height: 0,
      width: 0,
      loading: true,
    };
  }
  componentDidMount() {
    if (this.props.boardChart) {
      this.getContainerSize();
      window.addEventListener('resize', this.getContainerSize);
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  componentDidUpdate(preProps) {
    if (!this.props.isLoading && preProps.isLoading) {
      this.getContainerSize();
    }
    if (this.props.layoutChangeCount !== preProps.layoutChangeCount) {
      //报告表发生卡片resize时
      this.getContainerSize();
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    // 鼠标hover事件触发时不重新渲染
    if (this.props.showScrollBar !== nextProps.showScrollBar) {
      return false;
    }
    return true;
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getContainerSize, false);
  }

  getContainerSize = () => {
    const { offsetWidth, offsetHeight } = this.container.current;
    const { width, height } = this.state;
    if (offsetWidth !== width || offsetHeight !== height) {
      this.setState({
        width: offsetWidth,
        height: offsetHeight,
        loading: false,
      });
    }
  };

  getChartStyle() {
    const noFixedHeightChart = [ChartTypes.Summary, ChartTypes.Table, ChartTypes.TextTable, ChartTypes.ExtendTable];
    const { canDrill } = this.props;
    if (!noFixedHeightChart.includes(this.props.selectedChart) && window.innerWidth <= 768) {
      if (this.props.selectedChart === ChartTypes.Bar && this.props.chartStyles.bar.barChart && this.props.data && this.props.data.length > 10) {
        return { height: (this.chartHeight = 32 * this.props.data.length) };
      } else return { height: '6rem' };
    }
    if (!canDrill) {
      return { height: '100%' };
    }
    return {};
  }
  render() {
    const { width, height, loading } = this.state;
    const widgetProps = { ...this.props, width, height };
    const widgetContent =
      widgetProps.mode === 'chart' ? (
        // 图表驱动
        <Chart {...widgetProps} />
      ) : (
        // 透视驱动
        <Pivot {...widgetProps} />
      );
    // const isLinkingWidget = BIStore.isLinkingWidget[this.props.id];
    const widgetClass = `${this.props.showScrollBar ? 'showScrollBar' : ''} widget ${this.props.show ? '' : 'hide'} ${
      this.props.selectedChart === ChartTypes.Summary && widgetProps.mode === 'chart' ? 'summary' : ''
    }`;
    return (
      <div className={widgetClass} ref={this.container} style={this.getChartStyle()}>
        {!loading && widgetContent}
        {/* {isLinkingWidget && (
          <div className="cancel-linking" onClick={this.props.cancelLinking}>
            <i className="iconfont icon-changan main-color-icon" />
            <div>点击取消联动</div>
          </div>
        )} */}
      </div>
    );
  }
}

export default Widget;
