import React, { Component } from 'react';
import './index.sass';
import PageHeaderStore from '../PageHeader/PageHeaderStore';
import sidebarStore from '../Sidebar/store';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import store from '../Sidebar/store';
import { autorun, observable } from 'mobx';

@withRouter
@observer
class MobileNavbarTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isBoard: true,
    };
    this.collaspsed = autorun(() => {
      this.checkCurrent(store.isCollapsed);
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getTitleName();
    }, 100);
  }

  componentDidUpdate(preProps) {
    if (this.props.location.pathname !== preProps.location.pathname) {
      this.getTitleName();
    }
  }

  componentWillUnmount() {
    this.collaspsed();
  }

  checkCurrent(isCollapsed) {
    if (isCollapsed) {
      this.getTitleName();
    } else {
      this.setState({
        title: PageHeaderStore.productName,
      });
    }
  }

  getTitleName = () => {
    const pathName = this.props.location.pathname;
    const pathNameWithOutParam = pathName.substr(0, pathName.indexOf('?') > '-1' ? pathName.indexOf('?') : pathName.length);
    let title = '';
    let isBoard = false;
    if (pathNameWithOutParam.includes('/customDetail/')) {
      title = PageHeaderStore.currentBoardName;
      isBoard = true;
    } else {
      title = document.title.substr(0, document.title.indexOf('-') - 1);
      if (pathName === '/person/workbench') {
        title = '个人工作台';
      }
      isBoard = false;
    }
    this.setState({ title, isBoard });
  };

  render() {
    const { title, isBoard } = this.state;
    return (
      <div id="nav">
        <div className="nav-left">
          <i className="iconfont icon-caidan" onClick={sidebarStore.collapsedChange} style={{ fontSize: '24px' }} />
        </div>
        {store.isCollapsed ? (
          <div className="nav-middle">{isBoard ? PageHeaderStore.currentBoardName : title}</div>
        ) : (
          <div className="nav-middle">
            {title}
            <i
              onClick={() => {
                sidebarStore.showCheckList();
              }}
              style={{ color: 'white', paddingLeft: '10px', fontSize: '15px' }}
              className={'iconfont icon-zhihuan'}
            />
          </div>
        )}
        <div className="nav-right">
          <img src={require(`../../static/images/${process.env.REACT_APP_BDlogo}_sm.png`).default} alt="cemyun" className="logo" />
        </div>
      </div>
    );
  }
}

export default MobileNavbarTop;
