import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import CommonTable from '../../../CommonTable/index';
import Loading from '../../../../component/Loading/Loading';
import util from '../../../../util';
import api from '../../../../api';

@observer
export default class PathChildTable extends Component {
  @observable isLoading = true;
  @observable data;
  componentDidMount() {
    this.isLoading = true;
    let newParam = this.props.expandContentParam;
    newParam['actionType'] = 14;
    newParam['filters'] = JSON.stringify(this.props.filters);
    delete newParam.dims;
    util.post(
      api.analyticsPathStep.preview,
      {
        // 需要一个方法把数组转为{step1:{},step2:{}}
        ...newParam,
      },
      (res) => {
        this.data = res.data.data;
        this.isLoading = false;
      }
    );
  }
  render() {
    return !this.isLoading ? (
      <CommonTable
        isSort={false}
        tableHeadData={this.props.tableHeadData}
        nopagination={true}
        parentData={{ data: this.data.data, page: this.data.page }}></CommonTable>
    ) : (
      <Loading></Loading>
    );
  }
}
