import React from 'react'
import { getPivotContentTextWidth, getPivotCellHeight, getStyleConfig } from '../util'

export function RowTitle(props) {
    const { rows, rowKeys, chartStyles, dimetionAxis, drawingData, rowsDisplay } = props
    const { elementSize, unitMetricHeight } = drawingData
    const { color: fontColor } = getStyleConfig(chartStyles).pivot
    let tableHeight = 0

    if (dimetionAxis) {
        tableHeight = dimetionAxis === 'row'
            ? elementSize * rowKeys.length
            : elementSize * unitMetricHeight
    } else {
        tableHeight = rowKeys.length * getPivotCellHeight()
    }

    const titles = rows.map((item) => {
        return rowsDisplay[item] || item
    })
    const content = titles.join(`  /  `)
    const contentLength = getPivotContentTextWidth(content)

    return (
        <div
            className='rowTitle'
            style={{
                width: rowKeys.length && getPivotCellHeight(),
                height: tableHeight,
                color: fontColor
            }}
        >
            <p style={{ transform: `translate(12px, ${contentLength / 2}px) rotate(-90deg)` }}>{content}</p>
        </div>
    )
}

export default RowTitle
