import React, { Fragment, Component } from 'react';
import { Select, InputNumber, Button, Input } from 'antd';
import { observer } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import api from '../../../api';
import { getDragItemIconClass } from '../../../containers/BIChart/util';
import util from '../../../util';
import intl from 'react-intl-universal';
import Loading from '../../../component/Loading/Loading';

const filterOperators = [
  { value: 'in', operator: 'in' },
  { value: '等于', operator: '=' },
  { value: '不等于', operator: '!=' },
  { value: '包含', operator: 'like' },
  { value: '不包含', operator: 'notLike' },
];

const { Option } = Select;

@observer
class KeyTarget extends React.Component {
  componentDidMount() {
    this.props.value.dimValue && (this.setDimValue(this.props.value.dimValue), this.getTargetList(this.props.value.dimValue));
  }
  @observable dimValue;

  @action.bound
  setTargetObj(data, dimValue) {
    this.props.setTargetObj(data, dimValue);
  }

  @action.bound
  setDimValue(data) {
    this.dimValue = data;
  }
  @action.bound
  getTargetList(dimValue) {
    this.props.modalType == '1' &&
      util.get(
        api.warning.options,
        {
          surveyId: dimValue,
        },
        (res) => {
          const data = this.props.targetObj ? JSON.parse(JSON.stringify(this.props.targetObj)) : {};
          data[dimValue] = [];
          res.forEach((item, index) => {
            data[dimValue].push({
              value: item.id,
              label: item.topicName,
              topicType: item.topicType,
              options: item.options,
              formula: item.formula,
            });
          });
          this.setTargetObj(data, dimValue);
        }
      );

    (this.props.modalType == '2' || this.props.modalType == '3') &&
      util.get(`${api.BI.views}/${dimValue}`, {}, (res) => {
        const data = this.props.targetObj ? JSON.parse(JSON.stringify(this.props.targetObj)) : {};
        data[dimValue] = [];
        res.fieldModelList.forEach((item, index) => {
          const needRandom = item.type === 2 && false;
          const RandomName = needRandom ? ` AS ${this.getRandomName()}` : '';
          const isRepeat = this.isRepeat(data[dimValue], item, needRandom);
          if (!isRepeat) {
            data[dimValue].push({
              column: `${item.fieldName}${RandomName}`,
              columnName: item.fieldDisplay,
              sqlType: item.sqlType,
              formula: ['<', '>', '=', '>=', '<='],
              visualType: item.visualType,
              func: item.func,
              aggregatorFormula: `${item.formula}${RandomName}`,
              customType: item.type,
              workViewParamId: item.workViewParamId,
              type: item.modelType === 'category' ? 'category' : 'value',
            });
          }
        });
        this.setTargetObj(data, dimValue);
      });
  }

  isRepeat = (data, item) => {
    let isRepeat = false;
    data.map((i) => {
      if (i.column === item.fieldName) {
        isRepeat = true;
      }
    });
    return isRepeat;
  };

  getRandomName = () => {
    const randomName = `key_${Math.floor(Math.random() * 99999)}`;
    return randomName;
  };

  handleDimValueChange = (dimValue) => {
    this.triggerChange({ dimValue });
    this.setDimValue(dimValue);
    if (!this.props.targetObj || !this.props.targetObj[dimValue]) {
      this.getTargetList(dimValue);
    } else {
      this.props.setDim(dimValue);
    }
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { value, dimValueList, defaultList } = this.props;
    return (
      <Fragment>
        <Select value={value.dimValue.toString()} className="lg-select" onChange={this.handleDimValueChange}>
          {dimValueList.map((item) => {
            return <Option key={item.value.toString()}>{item.label}</Option>;
          })}
        </Select>
      </Fragment>
    );
  }
}

const formulaList = [
  { value: '>', label: '>' },
  { value: '<', label: '<' },
  { value: '=', label: '=' },
  { value: '>=', label: '>=' },
  { value: '<=', label: '<=' },
];
class TargetDetail extends React.Component {
  constructor(props) {
    super(props);
    this.optionObj = this.getOptionObj(props.targetObj[props.targetDimValue]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.targetDimValue !== prevProps.targetDimValue) {
      this.optionObj = this.getOptionObj(this.props.targetObj[this.props.targetDimValue]);
    }
  }

  handletargetChange = (target, index) => {
    const { targetObj, targetDimValue, modalType, value } = this.props;
    if (modalType == '1') {
      this.triggerChange({ target, topicType: this.optionObj[target].topicType, formula: '', value: '' }, index);
    } else {
      const targetList = targetObj[targetDimValue];
      const item = targetList[targetList.findIndex((i) => i.column === target)];
      const { type, customType } = item;
      const func = customType === 2 ? 'original' : type === 'value' ? 'sum' : 'COUNTDISTINCT';
      value[index].func = func;
      value[index].column = item.column;
      value[index].columnName = item.columnName;
      if (customType === 2) {
        value[index].aggregatorFormula = item.aggregatorFormula;
      }
      value[index].workViewParamId = item.workViewParamId;
      this.triggerChange(value[index], index);
    }
  };

  handleLogicChange = (index) => {
    let { value } = this.props;
    value[index].logic = value[index].logic === 'and' ? 'or' : 'and';
    this.triggerChange(value[index], index);
  };

  handleFormulaChange = (formula, index) => {
    this.triggerChange({ formula }, index);
  };

  handleNumberChange = (value, index) => {
    this.triggerChange({ value }, index);
  };

  handleOperationTypeChange = (value, index) => {
    this.triggerChange({ operationType: value }, index);
  };

  handleFilterFocus = (columnIndex, filterIndex) => {
    let { value } = this.props;
    const filterValue = value[columnIndex].filters[filterIndex].name;
    if (!value[columnIndex].filters[filterIndex].values && filterValue) {
      this.handleFilterChange(filterValue, columnIndex, filterIndex);
    }
  };

  handleFilterValueChange = (filterValue, columnIndex, filterIndex) => {
    let { value } = this.props;
    const operator = value[columnIndex].filters[filterIndex].operator;
    const values = toJS(value[columnIndex].filters[filterIndex].values);
    const name = Object.keys(value[columnIndex].filters[filterIndex].values[0])[0];
    if (operator !== 'in' && filterValue.length) {
      value[columnIndex].filters[filterIndex].value = filterValue[filterValue.length - 1];
    } else if (operator === 'in' && filterValue.length) {
      const canAdd = values.findIndex((i) => {
        return i[name] === filterValue[filterValue.length - 1];
      });
      if (canAdd === -1) {
        return;
      }
      value[columnIndex].filters[filterIndex].value = filterValue;
    } else {
      value[columnIndex].filters[filterIndex].value = filterValue;
    }
    this.triggerChange(value[columnIndex], columnIndex);
  };

  handleFilterOperatorChange = (filterValue, columnIndex, filterIndex) => {
    let { value } = this.props;
    const operator = filterOperators.find((i) => i.value === filterValue);
    value[columnIndex].filters[filterIndex].operator = operator.operator;
    value[columnIndex].filters[filterIndex].value = [];
    this.triggerChange(value[columnIndex], columnIndex);
  };

  // @action.bound
  handleFilterChange = (filterValue, columnIndex, filterIndex) => {
    if (!this.props.targetDimValue) return;
    let { value, targetObj, targetDimValue } = this.props;
    const field = targetObj[targetDimValue].find((i) => i.column === filterValue).workViewParamId;
    value[columnIndex].filters[filterIndex].isLoading = true;
    this.triggerChange(value[columnIndex], columnIndex);
    util.post(
      `${api.BI.views}/${this.props.targetDimValue}/getdistinctvalue`,
      {
        cache: false,
        columns: [filterValue],
        expired: 0,
      },
      (res) => {
        value[columnIndex].filters[filterIndex].workViewParamId = field;
        value[columnIndex].filters[filterIndex].values = res.data;
        value[columnIndex].filters[filterIndex].name = filterValue;
        value[columnIndex].filters[filterIndex].isLoading = false;
        this.triggerChange(value[columnIndex], columnIndex);
      },
      true
    );
  };

  addChildrenFilter = () => {
    const { onChange } = this.props;
    let { value } = this.props;
    value = value || [];
    value.push(
      this.props.modalType == '1'
        ? { target: '', formula: '', value: '', topicType: 8 }
        : this.props.modalType == '2'
        ? { formula: '', value: '', column: '', columnName: '', logic: 'and', func: '', operationType: '1' }
        : { formula: '', value: '', column: '', columnName: '', logic: 'and', func: '' }
    );
    onChange([...value]);
  };

  addFilter = (index) => {
    const { onChange } = this.props;
    let { value } = this.props;
    value[index].filters = value[index].filters || [];
    value[index].filters.push({ name: '', operator: 'in', type: 'filter', sqlType: 'string', value: [], values: [], isLoading: false });
    onChange([...value]);
  };

  // 删除某项
  deleteItem = (index) => {
    const { onChange } = this.props;
    let { value } = this.props;
    value.splice(index, 1);
    onChange([...value]);
  };

  deleteFilter = (columnIndex, filterIndex) => {
    const { onChange } = this.props;
    let { value } = this.props;
    value[columnIndex].filters.splice(filterIndex, 1);
    onChange([...value]);
  };

  triggerChange = (changedValue, index) => {
    const { onChange } = this.props;
    let { value } = this.props;
    value[index] = { ...value[index], ...changedValue };
    if (onChange) {
      onChange([...value]);
    }
  };

  getOptionObj(data) {
    let obj = {};
    data &&
      data.forEach((item) => {
        obj[item.value] = item;
      });
    return obj;
  }

  render() {
    const { value, targetObj, targetDimValue, modalType } = this.props;
    return (
      <Fragment>
        {value &&
          value.map &&
          value.map((item, index) => {
            return (
              <div>
                <div className="form-item-children">
                  <Select
                    value={modalType == '1' ? item.target : item.columnName || item.column}
                    className={`lg-select ${(modalType == '1' ? item.target : item.columnName || item.column) ? 'noError' : ''}`}
                    style={{ marginRight: '8px' }}
                    onChange={(value) => this.handletargetChange(value, index)}>
                    {targetObj && targetObj[targetDimValue] && modalType == '1'
                      ? targetObj[targetDimValue].map((e) => {
                          return (
                            <Option key={e.value}>
                              <i className={`field-type-icon iconfont ${getDragItemIconClass(e.visualType)}`} />
                              {e.label}
                            </Option>
                          );
                        })
                      : targetObj[targetDimValue]
                          .filter((t) => (modalType == '2' ? t.visualType === 'number' && t.customType == '2' : t.customType != 2))
                          .map((e) => {
                            return (
                              <Option key={e.column}>
                                <i className={`field-type-icon iconfont ${getDragItemIconClass(e.visualType)}`} />
                                {e.columnName || e.column}
                              </Option>
                            );
                          })}
                  </Select>
                  {modalType == '1' ? (
                    <Select
                      value={item.formula}
                      className={`sm-select ${item.formula ? 'noError' : ''}`}
                      style={{ marginRight: '8px' }}
                      onChange={(value) => this.handleFormulaChange(value, index)}>
                      {targetObj[targetDimValue] &&
                        this.optionObj[item.target] &&
                        this.optionObj[item.target].formula.map((e) => {
                          return <Option key={e}>{intl.get(e)}</Option>;
                        })}
                    </Select>
                  ) : modalType == '3' && item.column && targetObj[targetDimValue].find((i) => i.column === item.column).visualType !== 'number' ? (
                    <Select
                      value={item.formula}
                      className={`sm-select ${item.formula ? 'noError' : ''}`}
                      style={{ marginRight: '8px' }}
                      onChange={(value) => this.handleFormulaChange(value, index)}>
                      {filterOperators.map((e) => {
                        return <Option key={e.operator}>{e.value}</Option>;
                      })}
                    </Select>
                  ) : (
                    <Select
                      value={item.formula}
                      className={`sm-select ${item.formula ? 'noError' : ''}`}
                      style={{ marginRight: '8px' }}
                      onChange={(value) => this.handleFormulaChange(value, index)}>
                      {['<', '>', '=', '>=', '<='].map((e) => {
                        return <Option key={e}>{intl.get(e)}</Option>;
                      })}
                    </Select>
                  )}
                  {targetObj[targetDimValue] && this.optionObj[item.target] && this.optionObj[item.target].options ? (
                    <Select
                      value={item.value}
                      className={`sm-select ${item.value ? 'noError' : ''}`}
                      style={{ marginRight: '8px' }}
                      onChange={(value) => this.handleNumberChange(value, index)}>
                      {this.optionObj[item.target].options.map((e) => {
                        return <Option key={e.id}>{e.name}</Option>;
                      })}
                    </Select>
                  ) : modalType == '1' ? (
                    <InputNumber
                      min={1}
                      max={10}
                      className={`xs-input ${item.value ? 'noError' : ''}`}
                      value={item.value}
                      style={{ marginRight: '8px' }}
                      onChange={(value) => this.handleNumberChange(value, index)}
                    />
                  ) : modalType == '3' && item.column && targetObj[targetDimValue].find((i) => i.column === item.column).visualType !== 'number' ? (
                    <Input
                      className={`xs-input ${item.value ? 'noError' : ''}`}
                      value={item.value}
                      style={{ marginRight: '8px' }}
                      onChange={(e) => this.handleNumberChange(e.target.value, index)}
                    />
                  ) : (
                    <Fragment>
                      <InputNumber
                        className={`xs-input ${item.value ? 'noError' : ''}`}
                        value={item.value}
                        style={{ marginRight: '8px' }}
                        onChange={(value) => this.handleNumberChange(value, index)}
                      />
                      {modalType == '2' && (
                        <Select
                          className={`sm-select ${item.value ? 'noError' : ''}`}
                          value={item.operationType}
                          onChange={(value) => this.handleOperationTypeChange(value, index)}>
                          <Option key={1} value={'1'}>
                            数值
                          </Option>
                          <Option key={2} value={'2'}>
                            百分比
                          </Option>
                        </Select>
                      )}
                    </Fragment>
                  )}
                  {index !== 0 && <i className="form-delete-btn iconfont icon-shanchu" onClick={() => this.deleteItem(index)} />}
                  <i className="iconfont icon-shaixuan shaixuan-btn" onClick={() => this.addFilter(index)} />
                </div>
                <div className="childrenFilter">
                  {item.filters &&
                    item.filters.map((childItem, i) => {
                      return (
                        <div className="childrenFilter-item">
                          {childItem.isLoading && (
                            <div>
                              <Loading size="small" />
                            </div>
                          )}
                          <div>
                            <Select
                              value={childItem.name}
                              className="md-select"
                              style={{ marginRight: '8px' }}
                              onChange={(value) => this.handleFilterChange(value, index, i)}>
                              {targetObj &&
                                targetObj[targetDimValue] &&
                                targetObj[targetDimValue]
                                  .filter((t) => t.visualType === 'string')
                                  .map((e) => {
                                    return <Option key={e.column}>{e.columnName || e.column}</Option>;
                                  })}
                            </Select>
                          </div>
                          <div>
                            <Select
                              value={filterOperators.find((i) => i.operator === childItem.operator).value}
                              className="md-select"
                              style={{ marginRight: '8px' }}
                              onChange={(value) => this.handleFilterOperatorChange(value, index, i)}>
                              {filterOperators.map((operator) => {
                                return (
                                  <Option key={operator.value} value={operator.value}>
                                    {operator.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div>
                            <Select
                              placeholder="请选择"
                              mode="tags"
                              selectType="multiple"
                              showSearch
                              allowClear={true}
                              onFocus={() => this.handleFilterFocus(index, i)}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              className="lg-select"
                              value={toJS(childItem.value)}
                              onChange={(value) => this.handleFilterValueChange(value, index, i)}>
                              {childItem.values &&
                                childItem.values.length &&
                                childItem.values.map((e, childIndex) => {
                                  const name = Object.keys(e)[0];
                                  return (
                                    <Option key={childIndex} value={e[name]}>
                                      {e[name]}
                                    </Option>
                                  );
                                })}
                            </Select>
                            <i className="shaixuan-btn iconfont icon-shanchu" onClick={() => this.deleteFilter(index, i)} />
                          </div>
                          <div>
                            <span
                              className={`childrenFilter_logic ${item.filters.length < 2 ? 'childrenFilter_logic-empty' : ''}`}
                              onClick={() => {
                                this.handleLogicChange(index);
                              }}>
                              {item.logic === 'and' ? '且' : '或'}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        <Button type="addButton" onClick={this.addChildrenFilter}>
          +{intl.get('Tianjia')}
          {intl.get('Target')}
        </Button>
      </Fragment>
    );
  }
}

const periodList = [
  { value: 'every_time', label: 'EveryTime' },
  { value: 'day_max', label: 'EveryDay' },
  { value: 'week_max', label: 'EveryWeek' },
  { value: 'month_max', label: 'EveryMonth' },
];
@observer
class Frequency extends React.Component {
  componentDidMount() {
    this.props.value.period && this.setPeriod(this.props.value.period);
  }
  @observable period;
  @action.bound
  setPeriod(e) {
    this.period = e;
  }
  handlePeriodChange = (period) => {
    this.triggerChange({ period });
    this.setPeriod(period);
  };

  handleNumberChange = (number) => {
    this.triggerChange({ number });
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { value } = this.props;
    return (
      <span>
        <Select value={value.period} className="sm-select" onChange={this.handlePeriodChange}>
          {periodList.map((item) => {
            return (
              <Option key={item.value} value={item.value}>
                {intl.get(item.label)}
              </Option>
            );
          })}
        </Select>
        {this.period !== 'every_time' && (
          <Fragment>
            <InputNumber value={value.number} onChange={this.handleNumberChange} className="xs-input" style={{ margin: '0 4px 0 8px' }} min={1} />
            <span>{intl.get('times')}</span>
          </Fragment>
        )}
      </span>
    );
  }
}

const warningTimeList = [
  { value: 'realtime', label: 'RealTimeDistribution' },
  { value: 'custom', label: 'CustomTime' },
];
@observer
class WarningTime extends React.Component {
  constructor() {
    super();
    this.timeList = [];
  }
  componentDidMount() {
    for (var i = 0; i < 24; i++) {
      this.timeList.push({ value: i + 1, label: i + 1 + '点' });
    }
    this.props.value.warningType && this.setWarningType(this.props.value.warningType);
  }
  @observable warningType;
  @action.bound
  setWarningType(e) {
    this.warningType = e;
  }
  handleWarningTypeChange = (warningType) => {
    this.triggerChange({ warningType });
    this.setWarningType(warningType);
  };

  handleTimeChange = (time) => {
    this.triggerChange({ time });
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { value } = this.props;
    return (
      <span>
        <Select value={value.warningType} style={{ width: '120px', marginRight: '8px' }} onChange={this.handleWarningTypeChange}>
          {warningTimeList.map((item) => {
            return (
              <Option key={item.value} value={item.value}>
                {intl.get(item.label)}
              </Option>
            );
          })}
        </Select>
        {this.warningType && this.warningType === 'custom' && (
          <Select value={value.time} style={{ width: '96px' }} onChange={this.handleTimeChange}>
            {this.timeList.map((item) => {
              return (
                <Option key={item.value} value={item.value.toString()}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        )}
      </span>
    );
  }
}

const timeObj = {
  hour: [
    { value: 'fixed', label: 'FixedValue' },
    { value: 'last_hour', label: 'LastHour' },
    { value: 'yesterday_hoyr', label: 'SameHourYesterday' },
  ],
  day: [
    { value: 'fixed', label: 'FixedValue' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'last_week_day', label: 'SameDayLastWeek' },
  ],
};
@observer
class PeriodTarget extends React.Component {
  componentDidMount() {
    this.props.period && this.setPeriod(this.props.period);
  }
  @observable period = 'hour';
  @action.bound
  setPeriod(e) {
    this.period = e;
  }
  handleFormulaTypeChange = (formula) => {
    this.triggerChange({ formula });
  };

  handleCompareChange = (compare) => {
    this.triggerChange({ compare });
  };
  handleNumberChange = (value) => {
    this.triggerChange({ value });
  };

  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { value } = this.props;
    return (
      <span>
        <Select value={value.formula} className="sm-select" onChange={this.handleFormulaTypeChange}>
          {formulaList.map((item) => {
            return (
              <Option key={item.value} value={item.value}>
                {intl.get(item.label)}
              </Option>
            );
          })}
        </Select>
        <Select value={value.compare} style={{ width: '120px', margin: '0 8px' }} onChange={this.handleCompareChange}>
          {timeObj[this.period] &&
            timeObj[this.period].map((item) => {
              return (
                <Option key={item.value} value={item.value}>
                  {intl.get(item.label)}
                </Option>
              );
            })}
        </Select>
        <InputNumber className="xs-input" value={value.value} onChange={this.handleNumberChange} />
      </span>
    );
  }
}
const triggerTimeObj = [
  {
    value: 'realtime',
    label: 'Immediately',
    // 立即下发
  },
  {
    value: 'custom',
    label: 'SpecifiedHoursAndMinutes',
    // 指定小时分钟数下发
  },
];
const unitObj = [
  {
    value: 'min',
    label: 'Minutes', //'分钟',
  },
  {
    value: 'hour',
    label: 'Hour', //'小时',
  },
  {
    value: 'day',
    label: 'Day', //'天',
  },
  {
    value: 'week',
    label: 'Week', //'周',
  },
  {
    value: 'month',
    label: 'Month', //'月',
  },
];

@observer
class TriggerTime extends Component {
  componentDidMount() {
    this.props.value && this.setSurveyTime(this.props.value.surveyTime);
  }
  @observable surveyTime;
  @action.bound
  setSurveyTime(data) {
    this.surveyTime = data;
  }
  timeChange = (surveyTime) => {
    this.triggerChange({ surveyTime });
    this.setSurveyTime(surveyTime);
  };
  customUnitChange = (customUnit) => {
    this.triggerChange({ customUnit });
  };
  customTimeChange = (customTime) => {
    this.triggerChange({ customTime });
  };
  triggerChange = (changedValue) => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  render() {
    const { value } = this.props;
    return (
      <Fragment>
        <Select className="lg-select" onChange={this.timeChange} value={value.surveyTime}>
          {triggerTimeObj.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                {intl.get(item.label)}
              </Option>
            );
          })}
        </Select>
        {this.surveyTime === 'custom' && (
          <Fragment>
            <InputNumber
              value={value.customTime}
              onChange={this.customTimeChange}
              className="xs-input"
              style={{ marginLeft: '8px', lineHeight: '1.5' }}
              min={0}
            />
            <Select style={{ marginLeft: '8px' }} className="sm-select" value={value.customUnit} onChange={this.customUnitChange}>
              {unitObj.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {intl.get(item.label)}
                  </Option>
                );
              })}
            </Select>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export { KeyTarget, TargetDetail, Frequency, WarningTime, PeriodTarget, TriggerTime };
