import React, { Component } from 'react';
import { Modal, Form, message, Checkbox } from 'antd';
import ShowForm from '../showForm/index';
import api from '../../api';
import util from '../../util';
import pageHeaderStore from '../../component/PageHeader/PageHeaderStore';

import './index.sass';

class Index extends Component {
  state = {
    sharevisible: false,
    isSharePerm: 0,
  };

  setSharePerm = (isSharePerm) => {
    this.setState({
      isSharePerm,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let saveObject = values.select.shareForm
          ? {
              sourceType: this.props.type,
              sourceId: this.props.haveCurrentBoardId
                ? pageHeaderStore.currentBoardId
                : this.props.id || this.props.store.parentData[this.props.index].sourceId || this.props.store.parentData[this.props.index].id,
              destinationType: 1,
              destinationList: 'all',
              editable: values.select.shareForm[0].shareisCollaboration === false ? 0 : 1,
            }
          : values.select[0].sharePartOrAll == 'onlyMyself'
          ? ''
          : values.select.map((item, index) => {
              return {
                sourceType: this.props.type,
                sourceId: this.props.haveCurrentBoardId
                  ? pageHeaderStore.currentBoardId
                  : this.props.id || this.props.store.parentData[this.props.index].sourceId || this.props.store.parentData[this.props.index].id,
                destinationType:
                  item.shareSelectAccount === 'role' ? 2 : item.shareSelectAccount === 'account' ? 1 : item.shareSelectAccount === 'accounts' ? 3 : 1,
                destinationList: item.shareSelectPeople.length === 0 ? 'all' : item.shareSelectPeople.toString(),
                editable: item.shareisCollaboration === false ? 0 : 1,
              };
            });
        const params = {
          sourceShare: values.select.shareForm
            ? JSON.stringify(saveObject)
            : values.select[0].sharePartOrAll == 'onlyMyself'
            ? saveObject
            : JSON.stringify(saveObject),
          sourceType: this.props.type,
          sourceId: this.props.haveCurrentBoardId ? pageHeaderStore.currentBoardId : this.props.id || this.props.store.parentData[this.props.index].id,
        };
        this.props.type == 1 && (params.isSharePerm = values.isSharePerm.length ? 1 : 0);
        if (this.props.folderId) {
          params.folderId = this.props.folderId;
        }
        this.props.type == 11 && (params.folderSourceIds = this.props.sourceIds);
        util.post(
          api.board.save,
          params,
          (res) => {
            message.success('分享成功');
            this.props.reloadData && this.props.reloadData();
          },
          true
        );
        // 弹窗确定
        this.props.shareOnCancel();
      }
    });
  };
  checkPrice = (rule, value, callback) => {
    value.map((item, index) => {
      if (item.sharePartOrAll === 'part') {
        for (let key in item) {
          if (item[key] === false) {
            continue;
          }
          if (!item[key] || item.shareSelectPeople.length === 0) {
            callback('请完整填写表单');
          }
        }
      }
    });
    return callback();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    };
    return (
      <div>
        <Modal
          title="分享设置"
          visible={this.props.visible}
          onOk={this.handleSubmit}
          onCancel={this.props.shareOnCancel}
          destroyOnClose={true}
          okText={'确定'}
          cancelText={'取消'}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item {...formItemLayout}>
              {getFieldDecorator('select', {
                initialValue: {
                  shareForm: [
                    {
                      sharePartOrAll: 'all',
                      shareSelectAccount: '',
                      shareSelectPeople: [],
                      shareisCollaboration: false,
                    },
                  ],
                },
                validateTrigger: 'onClick',
                rules: [{ validator: this.checkPrice }],
              })(
                <ShowForm
                  folderId={this.props.folderId}
                  myId={this.props.id}
                  type={this.props.type}
                  store={this.props.store}
                  index={this.props.index}
                  haveCurrentBoardId={this.props.haveCurrentBoardId}
                  setSharePerm={this.setSharePerm}
                />
              )}
            </Form.Item>
            {this.props.type == 1 && (
              <Form.Item {...formItemLayout}>
                {getFieldDecorator('isSharePerm', {
                  initialValue: this.state.isSharePerm ? ['share'] : [],
                })(
                  <Checkbox.Group>
                    <Checkbox value="share">分享数据权限</Checkbox>
                  </Checkbox.Group>
                )}
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}
export default Form.create()(Index);
